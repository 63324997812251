import moment from 'moment-timezone';
import { FC } from 'react';

import { formatAnswer } from '@payaca/helpers/formHelper';
import CompanyLogo from '../companyLogo/CompanyLogo';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import {
  useCertificateNumber,
  useCommonFormAccountData,
  useInspectionFormAddresses,
} from './hooks';

import './FormTemplateElements.sass';
import './MinorElectricalInstallationWorksCertificate.sass';

import NiceicLogo from './niceic-logo.png';

interface Props {
  data: any;
  account: any;
}

const MinorElectricalInstallationWorksCertificate: FC<Props> = ({
  data,
  account,
}) => {
  const { brandColour } = useCommonFormAccountData(account);
  const {
    accountAddressString,
    inspectionAddressString,
    landlordAddressString,
  } = useInspectionFormAddresses(account, data);
  const certificateNumber = useCertificateNumber(data);

  if (!data || !account) return <></>;

  return (
    <div
      className={
        'form-template-output minor-electrical-works-certificate-template'
      }
    >
      <div className={'form-template-header'}>
        <div>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Minor Electrical Installation Works Certificate</h3>
        </div>
        <div className={'flex-container flex-center'}>
          <div className="header-details-container">
            <LabelValuePair label="Cert No" value={certificateNumber} />
          </div>
        </div>
        {(data.niceic_registered || data.niceic_reg_number) && (
          <img className="niceic-logo" src={NiceicLogo} alt={'NICEIC Logo'} />
        )}
      </div>
      <div className="form-template-row company-inspection-landlord-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          className="company-details-content-panel"
          title={'PART 1a: Company/Installer'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="company-details-content-panel-body">
            <LabelValuePair
              label="Trading Title"
              value={data.contractor_trading_title}
            />
            {data.niceic_reg_number && (
              <LabelValuePair
                label="NICEIC Reg. Number"
                value={data.niceic_reg_number}
              />
            )}
            <LabelValuePair label="Branch Number" value={data.branch_no} />
            <LabelValuePair label="Address" value={accountAddressString} />
            <LabelValuePair label="Contact" value={account?.contactNumber} />
            <LabelValuePair
              label="Registration number"
              value={data.registration_no}
            />
            <LabelValuePair label="E-Mail" value={account?.email} />
          </div>
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 1b: Inspection/Installation Address'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.name} />
          <LabelValuePair label="Address" value={inspectionAddressString} />
          <LabelValuePair label="E-Mail" value={data.email} />
          <LabelValuePair label="Contact" value={data.contact} />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 1c: Landlord/Agent Details'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.landlord_name} />
          <LabelValuePair label="Address" value={landlordAddressString} />
          <LabelValuePair label="E-mail" value={data.landlord_email} />
          <LabelValuePair label="Contact" value={data.landlord_contact} />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row details-of-minor-works no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={
            'PART 2: Details of the minor works, supply characteristics and earthing arrangements'
          }
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="label-values-in-row">
            <LabelValuePair
              label="Description of minor works"
              value={formatAnswer(data.description_of_minor_works)}
            />
            <LabelValuePair
              label="Date completed"
              value={moment(data.date_completed).format('DD/MM/YYYY')}
            />
          </div>
          <div className="label-values-in-row">
            <LabelValuePair
              label="System type and earthing arrangements"
              value={formatAnswer(data.system_type)}
            />
            <LabelValuePair
              label="Zs at Distribution Board / Consumer Unit supplying the final circuit (Ω)"
              value={formatAnswer(data.zs_at_distribution_board)}
            />
          </div>
          <div className="label-values-in-row">
            <div className="label-values-in-row-title">
              Presence of adequate main protective conductors:
            </div>
            <LabelValuePair
              label={'Earthing conductor'}
              value={
                data.main_protective_conductors?.earthing_conductor
                  ? formatAnswer(
                      data.main_protective_conductors.earthing_conductor
                    )
                  : 'N/A'
              }
            />
            <div className="label-values-in-row-title">
              Protective bonding conductor(s) to:
            </div>
            <LabelValuePair
              label={'Water'}
              value={
                data.protective_bonding_conductors_to?.water
                  ? formatAnswer(data.protective_bonding_conductors_to.water)
                  : ''
              }
            />
            <LabelValuePair
              label={'Gas'}
              value={
                data.protective_bonding_conductors_to?.gas
                  ? formatAnswer(data.protective_bonding_conductors_to.gas)
                  : ''
              }
            />
            <LabelValuePair
              label={'Oil'}
              value={
                data.protective_bonding_conductors_to?.oil
                  ? formatAnswer(data.protective_bonding_conductors_to.oil)
                  : ''
              }
            />
            <LabelValuePair
              label={'Other'}
              value={
                data.protective_bonding_conductors_to?.other
                  ? formatAnswer(data.protective_bonding_conductors_to.other)
                  : 'N/A'
              }
            />
          </div>
          <div className="label-values-in-row">
            <div className="label-values-in-row-title">
              Comments on existing installation (seeReg 644.1.2):
            </div>
            <LabelValuePair
              label={'Comment'}
              value={formatAnswer(
                data.comments_on_existing_installation?.comment
              )}
            />
            <LabelValuePair
              label={'Page no.'}
              value={formatAnswer(
                data.comments_on_existing_installation?.page_no
              )}
            />
            <LabelValuePair
              label={'Departures from BS 7671: 2018'}
              value={formatAnswer(
                data.comments_on_existing_installation?.departures_from_bs7671
              )}
            />
            <LabelValuePair
              label={'If yes, details on page no.'}
              value={formatAnswer(
                data.comments_on_existing_installation?.if_yes
              )}
            />
          </div>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 3: Circuit Details'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="label-values-in-row">
            <div className="label-values-in-row-title">Circuit:</div>
            <LabelValuePair
              label={'Description and Ref No'}
              value={formatAnswer(data.circuit?.description_and_ref)}
              noBreak={true}
            />
            <div className="label-values-in-row-title">DB/CU:</div>
            <LabelValuePair
              label={'Ref No.'}
              value={formatAnswer(data.db_cu?.ref_no)}
              noBreak={true}
            />
            <LabelValuePair
              label={'Location and type'}
              value={formatAnswer(data.db_cu?.location_and_type)}
            />
          </div>
          <div className="label-values-in-row">
            <div className="label-values-in-row-title">
              Overcurrent protection device:
            </div>
            <LabelValuePair
              label={'BS EN'}
              value={formatAnswer(data.over_current_protective_device?.bs_en)}
            />
            <LabelValuePair
              label={'Type'}
              value={formatAnswer(data.over_current_protective_device?.type)}
            />
            <LabelValuePair
              label={'Rating'}
              value={formatAnswer(data.over_current_protective_device?.rating)}
            />
            <div className="label-values-in-row-title">Csa of conductors:</div>
            <LabelValuePair
              label={'Live (mm2)'}
              value={formatAnswer(data.csa_of_conductors?.live_mm2)}
            />
            <LabelValuePair
              label={'cpc (mm2)'}
              value={formatAnswer(data.csa_of_conductors?.cpc_mm2)}
            />
          </div>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row test-results-declaration no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 4: Test results for the circuit altered or extended'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="label-values-in-row candy">
            <div className="title-fields-grouped">
              <div className="label-values-in-row-title">Continuity:</div>
              <LabelValuePair
                label={'Protective conductor (R1 + R2) (Ω)'}
                value={
                  data.continuity?.protective_conductor_r1_r2
                    ? formatAnswer(data.continuity.protective_conductor_r1_r2)
                    : 'N/A'
                }
              />
              <LabelValuePair
                label={'or R2 (Ω)'}
                value={
                  data.continuity?.r2 ? formatAnswer(data.continuity.r2) : 'N/A'
                }
              />
            </div>
          </div>
          <div className="label-values-in-row candy">
            <div className="title-fields-grouped">
              <div className="label-values-in-row-title">
                Ring final circuit (loop values):
              </div>
              <LabelValuePair
                label={'L/L (Ω)'}
                value={
                  data.ring_final_circuit_loop?.l_l
                    ? formatAnswer(data.ring_final_circuit_loop.l_l)
                    : 'N/A'
                }
                noBreak={true}
              />
              <LabelValuePair
                label={'N/N (Ω)'}
                value={
                  data.ring_final_circuit_loop?.n_n
                    ? formatAnswer(data.ring_final_circuit_loop.n_n)
                    : 'N/A'
                }
              />
              <LabelValuePair
                label={'cpc/cpc (Ω)'}
                value={
                  data.ring_final_circuit_loop?.cpc_cpc
                    ? formatAnswer(data.ring_final_circuit_loop.cpc_cpc)
                    : 'N/A'
                }
              />
            </div>
          </div>
          <div className="label-values-in-row candy">
            <div className="title-fields-grouped">
              <div className="label-values-in-row-title">
                Insulation resistance:
              </div>
              <LabelValuePair
                label={'L/L (MΩ)'}
                value={
                  data.insulation_resistance?.l_l
                    ? formatAnswer(data.insulation_resistance.l_l)
                    : 'N/A'
                }
                noBreak={true}
              />
              <LabelValuePair
                label={'L/E (MΩ)'}
                value={
                  data.insulation_resistance?.l_e
                    ? formatAnswer(data.insulation_resistance.l_e)
                    : 'N/A'
                }
              />
              <LabelValuePair
                label={'Test Voltage (DC)'}
                value={
                  data.insulation_resistance?.test_voltage
                    ? formatAnswer(data.insulation_resistance.test_voltage)
                    : 'N/A'
                }
              />
            </div>
          </div>
          <div className="label-values-in-row candy">
            <div className="title-fields-grouped">
              <div className="label-values-in-row-title">Polarity:</div>
              <LabelValuePair
                label={'Satisfactory'}
                value={formatAnswer(data.polarity?.satisfactory)}
                noBreak={true}
              />
              <LabelValuePair
                label={'Earth fault loop impedance (Ω)'}
                value={formatAnswer(data.earth_fault_loop_impedance)}
              />
            </div>
          </div>
          <div className="candy">
            <div className="label-values-in-row-title">RCD operation:</div>
            <div className="label-values-in-row">
              <div className="title-fields-grouped">
                <LabelValuePair
                  label={'Rated residual operating current (mA)'}
                  value={formatAnswer(
                    data.rcd_operation?.rated_residual_operating_current
                  )}
                  noBreak={true}
                />
                <LabelValuePair
                  label={'Measured operating time (ms)'}
                  value={formatAnswer(
                    data.rcd_operation?.measured_operating_time
                  )}
                />
              </div>
            </div>
            <div className="label-values-in-row">
              <div className="title-fields-grouped">
                <div className="label-values-in-row-title">
                  Functional Tests:
                </div>
                <LabelValuePair
                  label={'RCD'}
                  value={formatAnswer(data.functional_tests?.rcd1)}
                  noBreak={true}
                />
                <LabelValuePair
                  label={'AFDD'}
                  value={formatAnswer(data.functional_tests?.afdd)}
                />
              </div>
            </div>
          </div>
          <div className="candy">
            <div className="label-values-in-row-title">Test Instrument:</div>
            <div className="label-values-in-row">
              <div className="title-fields-grouped">
                <LabelValuePair
                  label={'Multifunction'}
                  value={
                    data.test_instrument_insert_appropriate?.multifunction
                      ? formatAnswer(
                          data.test_instrument_insert_appropriate.multifunction
                        )
                      : 'N/A'
                  }
                />
                <LabelValuePair
                  label={'Other'}
                  value={
                    data.test_instrument_insert_appropriate?.other_state
                      ? formatAnswer(
                          data.test_instrument_insert_appropriate.other_state
                        )
                      : 'N/A'
                  }
                />
                <LabelValuePair
                  label={'Other'}
                  value={
                    data.test_instrument_insert_appropriate?.other_state_1
                      ? formatAnswer(
                          data.test_instrument_insert_appropriate.other_state_1
                        )
                      : 'N/A'
                  }
                />
              </div>
            </div>
          </div>
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 5: Declaration"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
          className="signatures"
        >
          <div>
            <p className="candy">
              {`I CERTIFY that the work covered by this certificate does not
              impair the safety of the existing installation and that the work
              has been designed, constructed, inspected and tested in accordance
              with BS 7671: 2018, amended to ${
                data.amended_to_date
                  ? moment(data.amended_to_date).format('DD/MM/YYYY')
                  : 'N/A'
              } and that to the best of
              my knowledge and belief, at the time of my inspection, complied
              with BS 7671: 2018 except as detailed in PART 2 of this
              certificate.`}
            </p>
            <div className="label-values-in-row candy">
              <div className="name-position-date">
                <LabelValuePair label="Name" value={data.engineer_name} />
                <LabelValuePair
                  label="Position"
                  value={data.engineer_position}
                />
                <LabelValuePair
                  label="Date"
                  value={moment(data.engineer_date).format('DD/MM/YYYY')}
                />
              </div>
              <LabelValuePair
                label="Signed"
                value={
                  <div className={'form-template-signature'}>
                    {data.engineer_signature && (
                      <img src={data.engineer_signature} />
                    )}
                  </div>
                }
              />
            </div>
            <div className="candy">
              <div className="label-values-in-row-title">
                The results of the inspection and testing reviewed by the
                Qualified Supervisor
              </div>
              <div className="label-values-in-row">
                <div className="name-position-date">
                  <LabelValuePair
                    label="Name"
                    value={data.qualified_supervisor_name}
                  />
                  <LabelValuePair
                    label="Position"
                    value={data.qualified_supervisor_position}
                  />
                  <LabelValuePair
                    label="Date"
                    value={moment(data.qualified_supervisor_date).format(
                      'DD/MM/YYYY'
                    )}
                  />
                </div>
                <LabelValuePair
                  label="Signed"
                  value={
                    <div className={'form-template-signature'}>
                      {data.qualified_supervisor_signature && (
                        <img src={data.qualified_supervisor_signature} />
                      )}
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </OutlinedContentPanel>
      </div>
    </div>
  );
};

export default MinorElectricalInstallationWorksCertificate;
