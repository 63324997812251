import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import * as userActions from '@payaca/store/user/userActions';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import {
  getIsRequiredFieldValidator,
  getPhoneNumberFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import LoqateAdvisoryPhoneInputField from '../loqateAdvisoryPhoneInputField/LoqateAdvisoryPhoneInputField';

import './SetupUserForm.sass';

const initialFormState = {};

const formatContactNumber = (phone?: string) =>
  phone && phone[0] !== '+' ? `+${phone}` : phone;

const SetupUserForm: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [setupUserErrorMessage, setSetupUserErrorMessage] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);

  const fieldValidators = useMemo(() => {
    const isRequiredFieldValidator = getIsRequiredFieldValidator();

    return {
      contactNumber: [isRequiredFieldValidator, getPhoneNumberFieldValidator()],
    };
  }, []);

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      setIsProcessing(true);
      setSetupUserErrorMessage(undefined);
      const payload = {
        contactNumber: formatContactNumber(formState.contactNumber),
      };

      dispatch(
        userActions.requestUpdateProfile(payload, (error: any) => {
          setIsProcessing(false);

          if (!error) {
            history.push('/');
          } else {
            setSetupUserErrorMessage(
              'Sorry, there was an error updating your details. Please try again.'
            );
          }
        })
      );
    },
    [dispatch]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <ValidatedFieldWrapper
            validationResult={validationState['contactNumber']}
            isTouched={touchedState['contactNumber'] || false}
          >
            <LoqateAdvisoryPhoneInputField
              styleVariant={InputStyleVariant.OUTSIZE}
              name="contactNumber"
              value={formState.contactNumber}
              label={'Phone number'}
              isRequired={true}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              disableValidation={
                validationState['contactNumber']
                  ? !validationState['contactNumber'].isValid
                  : false
              }
            />
          </ValidatedFieldWrapper>

          <div className="button-wrapper">
            <Button
              isProcessing={isProcessing}
              onClick={() => !isProcessing && onSubmit(formState)}
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isDisabled={!isValid}
            >
              Continue
            </Button>
            {setupUserErrorMessage && (
              <ErrorMessage message={setupUserErrorMessage} />
            )}
          </div>
        </>
      );
    },
    [isProcessing, setupUserErrorMessage, onSubmit]
  );

  return (
    <div className="setup-user-form-container">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
      />
    </div>
  );
};

export default SetupUserForm;
