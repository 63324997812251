import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar, {
  DayHeaderContentArg,
  EventContentArg,
} from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import React, { FC } from 'react';

import AssignedUsersIndicator from '../assignedUsersIndicator/AssignedUsersIndicator';
import Calendar from './Calendar';
import DayHeader from './DayHeader';

import {
  ScheduledEvent,
  ScheduledEventColourCoding,
} from '@payaca/types/scheduledEventsTypes';

import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { getTimeDuration } from '@payaca/utilities/timeUtilities';

import { isValidColour } from '@payaca/utilities/colourUtilities';

import './GridCalendar.sass';

type Props = {
  additionalCalendarProps?: any;
  onExistingEventSelect: (event: ScheduledEvent) => void;
  onDateSelect: (dateSelectInfo?: {
    beginAt: string;
    endAt?: string;
    userAssignments?: number[];
  }) => void;
  onViewableDateChange?: (dateInfo: any) => void;
  scheduledEvents: ScheduledEvent[];
  eventDrop?: any;
  eventResize?: any;
  editable?: boolean;
  activeUsers?: any;
  onViewTypeChange?: (viewType: string) => void;
  initialView?: string;
  minTime?: string;
  maxTime?: string;
  hiddenDays?: number[];
  scheduledEventColourCoding?: ScheduledEventColourCoding;
  calendarRef?: React.RefObject<FullCalendar>;
  isExternallyControlled?: boolean;
};
const GridCalendar: FC<Props> = ({
  additionalCalendarProps = {},
  onExistingEventSelect,
  onDateSelect,
  onViewableDateChange,
  scheduledEvents,
  eventDrop,
  eventResize,
  editable,
  activeUsers,
  onViewTypeChange,
  initialView,
  minTime,
  maxTime,
  hiddenDays,
  scheduledEventColourCoding,
  calendarRef,
  isExternallyControlled,
}: Props): JSX.Element => {
  const renderEventContent = (eventContent: EventContentArg) => {
    const location = getAddressAsString(
      eventContent?.event?._def?.extendedProps?.location?.address
    );

    const usersAssignedToEvent =
      eventContent?.event?._def?.extendedProps?.userAssignments;

    const getViewableAssignedUsers = () => {
      if (!usersAssignedToEvent || !activeUsers) return [];
      return usersAssignedToEvent
        .map((id: number) => {
          return activeUsers.find((x: any) => x.id === id);
        })
        .filter((x: any) => !!x);
    };

    const viewableAssignedUsers = getViewableAssignedUsers();

    const listView = eventContent?.view?.type === 'listWeek';

    const duration = getTimeDuration(
      eventContent.event.start,
      eventContent.event.end
    );

    const shortDuration =
      duration?.unit === 'minutes' ||
      (duration?.value === 1 && duration?.unit === 'hours');

    return (
      <>
        <div className="title-time-users-container">
          <div
            className="fc-daygrid-event-dot"
            style={{
              borderColor: isValidColour(eventContent.backgroundColor)
                ? eventContent.backgroundColor
                : '#36577d',
            }}
          />
          <div className="event-content-body">
            <span className="event-title">{eventContent.event.title}</span>
            <span className="event-time">{eventContent.timeText}</span>
            {!!viewableAssignedUsers && (
              <AssignedUsersIndicator
                size={listView ? 'sm' : 'xs'}
                assignedUsers={viewableAssignedUsers.map((x: any) => ({
                  firstName: x.firstname,
                  lastName: x.lastname,
                  userColour: x.userColour,
                  imgSrc: x.avatarUrl,
                }))}
              />
            )}
            {!!location && (
              <div
                className={`event-location${shortDuration ? ' truncated' : ''}`}
              >
                {location}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <Calendar
      isExternallyControlled={isExternallyControlled}
      additionalCalendarProps={{
        views: {
          day: {
            titleFormat: 'ddd, D MMM YYYY',
          },
          timeline: {
            titleFormat: 'D MMM YYYY',
          },
        },
        ...additionalCalendarProps,
      }}
      className="grid-calendar"
      initialView={initialView}
      hasBoxShadow={false}
      headerToolbar={{
        left: 'today,jumpToDate',
        center: 'prev title next',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
      }}
      onDateSelect={onDateSelect}
      onExistingEventSelect={onExistingEventSelect}
      onViewableDateChange={onViewableDateChange}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
      scheduledEvents={scheduledEvents}
      renderDayHeaderContent={(dayHeaderContentArg: DayHeaderContentArg) => {
        return <DayHeader dayHeaderContentArg={dayHeaderContentArg} />;
      }}
      renderEventContent={renderEventContent}
      eventDrop={eventDrop}
      eventResize={eventResize}
      editable={editable}
      onViewTypeChange={onViewTypeChange}
      minTime={minTime}
      maxTime={maxTime}
      hiddenDays={hiddenDays}
      scheduledEventColourCoding={scheduledEventColourCoding}
      externalCalendarRef={calendarRef}
    />
  );
};

export default GridCalendar;
