import { Customer } from '@payaca/types/customerTypes';
import { Deal } from '@payaca/types/dealTypes';
import { Invoice } from '@payaca/types/invoiceTypes';
import { JobActionType } from '@payaca/types/jobActionsTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import { Job } from '@payaca/types/jobTypesV2';
import {
  canConvertJobToInvoice,
  canDuplicateJob,
  canEditSentJob,
  canMarkJobAsAccepted,
  canMarkJobAsDeclined,
  canRecordDepositPayment,
  canRecordInvoicePayment,
  canResendJob,
  canUnacceptProposal,
} from './jobHelperV2';
import { isInvoice, jobHasSent } from './jobStatusHelper';

export const getAvailableActionsForJob = (
  job: Job,
  deal: Deal,
  customer: Customer,
  jobPayments: JobPayment[],
  relatedJobs: Job[], // used for v1 only
  invoices: Invoice[]
): JobActionType[] => {
  const jobActionTypes: JobActionType[] = [];

  if (job.inactivatedAt) return jobActionTypes;

  if (job.isProtoInvoice) {
    if (!job.sentAt) {
      jobActionTypes.push(JobActionType.EDIT);
      jobActionTypes.push(JobActionType.SEND);
      jobActionTypes.push(JobActionType.MARK_AS_SENT);
    }
    return jobActionTypes;
  }

  if (job.archivedAt && deal.version < 2) {
    jobActionTypes.push(JobActionType.UNARCHIVE);
    return jobActionTypes;
  }

  const activeRelatedJobs = relatedJobs?.filter((x) => !x.inactivatedAt);

  const jobHasSentSuccessfully = !job.bouncedAt && jobHasSent(job.status);
  const jobHasRelatedInvoice = relatedJobs.some((x) => isInvoice(x.status));

  if (jobHasSentSuccessfully) {
    if (canMarkJobAsAccepted(job)) {
      jobActionTypes.push(JobActionType.MARK_AS_ACCEPTED);
    }
    if (canMarkJobAsDeclined(job)) {
      jobActionTypes.push(JobActionType.MARK_AS_DECLINED);
    }
    if (canRecordDepositPayment(job, deal.version)) {
      jobActionTypes.push(JobActionType.RECORD_DEPOSIT_PAYMENT);
    }
    if (canConvertJobToInvoice(job, jobHasRelatedInvoice, deal.version)) {
      jobActionTypes.push(JobActionType.INVOICE);
    }
    if (canRecordInvoicePayment(job, deal.version)) {
      jobActionTypes.push(JobActionType.RECORD_PAYMENT);
    }
    if (canDuplicateJob(job) && deal.version >= 2) {
      jobActionTypes.push(JobActionType.DUPLICATE);
    }
    if (
      canUnacceptProposal(
        job,
        jobPayments,
        !!relatedJobs.length ||
          !!deal.changeProposalIds.length ||
          !!invoices.filter((x) => !x.voidedAt).length // unvoided invoices
      )
    ) {
      jobActionTypes.push(JobActionType.REMOVE_ACCEPTED);
    }
    if (canResendJob(job, customer, !!activeRelatedJobs.length)) {
      jobActionTypes.push(JobActionType.RESEND);
    }
    if (canEditSentJob(job, jobPayments, !!activeRelatedJobs.length)) {
      jobActionTypes.push(JobActionType.INACTIVATE_AND_EDIT);
    }
  } else if (!jobHasSentSuccessfully && !job.bouncedAt) {
    jobActionTypes.push(JobActionType.EDIT);
    jobActionTypes.push(JobActionType.SEND);
    jobActionTypes.push(JobActionType.MARK_AS_SENT);
  }

  return jobActionTypes;
};
