import React, { isValidElement, PropsWithChildren, ReactNode } from 'react';
import MiniLoader from '../miniLoader/MiniLoader';
import Button, { IButtonProps } from '../plButton/Button';
import { EBtnSize } from '../plButton/useButtonClassName';
import EmptyState from '../plEmptyState/EmptyState';
import QuickActions, { TQuickActions } from './QuickActions';
import './Table.sass';

/**
 * Responsive Data Table with orderable columns
 *
 * const myData = [
 *   {
 *     name: "Ben",
 *     age: 30
 *   },
 *   {
 *     name: "Sam",
 *     age: 25
 *   }
 * ]
 *
 * <Table data={myData}>
 *   <Table.Column heading="Name" field="name"></Table.Column>
 *   <Table.Column heading="Age" field="age"></Table.Column>
 * </Table>
 *
 * RENDERS:
 * +--------+--------+
 * | *Name* | *Age*  |
 * +--------+--------+
 * | Ben    | 30     |
 * +--------+--------+
 * | Sam    | 25     |
 * +--------+--------+
 */

export type TColumnRender<T extends Record<string, any>, F extends keyof T> = (
  field: T[F],
  row: T,
  index: number
) => ReactNode;

export interface IColumnProps<
  T extends Record<string, any>,
  F extends keyof T = keyof T,
> {
  header: string;
  field: F;
  render?: TColumnRender<T, F>;
  headerClassName?: string;
  className?: string;
  hiddenHeader?: boolean;
}

export const Column = <T extends Record<string, any>, F extends keyof T>(
  props: IColumnProps<T, F>
) => <></>;

export interface ITableProps<T extends Record<string, any>> {
  heading?: string;
  subHeading?: string;
  isLoading?: boolean;
  createButton?: {
    label: string;
  } & IButtonProps;
  data: T[];
  uniqueKey?: string;
  quickActions?: TQuickActions<T>;
  onClickRow?: (row: T) => void;
  emptyText?: string;
  footerRow?: JSX.Element | JSX.Element[];
  hideEmptyState?: boolean;
  hideHeaderRow?: boolean;
}

const Table = <T extends Record<string, any>>(
  props: PropsWithChildren<ITableProps<T>>
) => {
  const {
    children,
    data,
    uniqueKey,
    quickActions,
    heading,
    subHeading,
    createButton,
    isLoading = false,
    onClickRow,
    emptyText,
    footerRow,
    hideEmptyState = false,
    hideHeaderRow = false,
  } = props;

  const columns = React.Children.toArray(children).reduce<
    IColumnProps<Record<string, any>>[]
  >((acc, child) => {
    if (
      !isValidElement(child) ||
      typeof child.props.header === 'undefined' ||
      typeof child.props.field === 'undefined'
    ) {
      return acc;
    }

    acc.push(child.props);

    return acc;
  }, []);

  return (
    <div className="w-full overflow-x-auto">
      {(heading?.length || subHeading?.length || createButton) && (
        <div className="prose flex items-center justify-between pb-5 pt-5">
          <div>
            {heading && <h2>{heading}</h2>}
            {subHeading && <p>{subHeading}</p>}
          </div>
          <div className="flex-[0_0_auto]">
            {createButton && (
              <Button size={EBtnSize.Small} {...createButton}>
                {createButton.label}
              </Button>
            )}
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="loader-container">
          <MiniLoader />
        </div>
      ) : !hideEmptyState && data.length === 0 ? (
        <EmptyState text={emptyText} />
      ) : (
        <table className="prose min-w-full border-collapse divide-y divide-gray-200 dark:divide-gray-700">
          {!hideHeaderRow && (
            <thead>
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.field}
                    scope="col"
                    className={
                      'px-6 py-3 text-left' +
                      (column.className ? ' ' + column.className : '') +
                      (column.headerClassName
                        ? ` ${column.headerClassName}`
                        : '')
                    }
                  >
                    <span className={column.hiddenHeader ? ' sr-only' : ''}>
                      {column.header}
                    </span>
                  </th>
                ))}
                {quickActions && <th></th>}
              </tr>
            </thead>
          )}

          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {data.map((row, index) => (
              <TableRow
                key={uniqueKey ? row[uniqueKey] : index}
                row={row}
                columns={columns}
                quickActions={quickActions}
                onClickRow={onClickRow}
                index={index}
              />
            ))}
            {footerRow && (
              <tr key="footer-row">
                <td colSpan={columns.length}>{footerRow}</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

const TableRow = <T extends Record<string, any>>({
  row,
  columns,
  quickActions,
  onClickRow,
  index,
}: {
  row: T;
  quickActions?: TQuickActions<T>;
  columns: IColumnProps<T, string>[];
  onClickRow?: (row: T) => void;
  index: number;
}) => {
  return (
    <tr
      className={
        onClickRow
          ? 'cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700'
          : ''
      }
      onClick={() => onClickRow?.(row)}
    >
      {columns.map((column) => (
        <td
          className={`whitespace-nowrap px-6 py-4 ${column.className || ''}`}
          key={column.field}
        >
          {column.render
            ? column.render(row[column.field], row, index)
            : row[column.field]}
        </td>
      ))}

      {quickActions && <QuickActions row={row} quickActions={quickActions} />}
    </tr>
  );
};

export default Object.assign(Table, {
  Column,
});
