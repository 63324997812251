//prettier-ignore
import DoneIcon from '@material-ui/icons/Done';

const STRIPE_CLIENT_ID = import.meta.env.VITE_STRIPE_CLIENT_ID;

import CreditCardIcon from './credit-card.png';

const Modal = {
  BOUNCE: {
    title: 'Your email bounced',
    text: "Sorry, your job bounced. Please edit your customer's email and try again.",
    primaryText: 'OK',
  },
  VERIFY_EMAIL_SUCCESS: {
    title: 'Verify account',
    text: 'Please check your emails for instructions to verify your account.',
    primaryText: 'OK',
  },
  FORGOT_EMAIL_SUCCESS: {
    title: 'Reset password',
    text: 'Please check your emails for instructions to reset your password.',
    primaryText: 'OK',
  },
  TABLE_DELETE: {
    title: 'Delete $0',
    text: 'Are you sure you want to delete $0?',
    primaryText: 'Delete',
  },
  ARCHIVE_JOB: {
    title: 'Archive job',
    text: 'Archiving this job will also archive any related quotes or invoices. Are you sure you want to continue?',
    primaryText: 'Archive',
  },
  ARCHIVE_JOBS: {
    title: 'Archive jobs',
    text: 'Archiving these jobs will also archive any related quotes or invoices. Are you sure you want to continue?',
    primaryText: 'Archive',
  },
  EMAIL_VERIFICATION_REQUIRED: {
    title: 'Email verification required',
    text: "We have sent you an email to verify the address is correct, please click the link in the email. If you can't find it (even in spam) please contact us.",
    primaryText: 'OK',
    tertiaryText: 'Resend verification',
    classes: 'email-verification',
  },
  EMAIL_VERIFICATION_SENT: {
    title: 'Email sent',
    text: 'A verification email has been sent to your email address.',
    primaryText: 'OK',
    classes: 'email-verification-sent',
  },
  DELETE_USER: {
    title: 'Delete user',
    text: 'Are you sure you want to delete this user?',
    primaryText: 'Delete',
  },
  DELETE_ITEM_GROUP: {
    title: 'Delete item group',
    text: 'Are you sure you want to delete this item group?',
    primaryText: 'Delete',
    secondaryText: 'Cancel',
  },
  SUBSCRIPTION_STARTED: {
    title: 'Subscription started',
    primaryText: 'OK',
  },
  CANCEL_SUBSCRIPTION: {
    title: 'Cancel subscription',
    text: 'Are you sure you want to cancel this subscription?',
    primaryText: 'Yes',
  },
  RESTORE_SUBSCRIPTION: {
    title: 'Restore subscription',
    text: 'Are you sure you want to restore this subscription?',
    primaryText: 'Yes',
    secondaryText: 'Cancel',
  },
  DEMO_ENDED: {
    title: 'Please upgrade',
    text: 'Your demo has ended, please choose a subscription to continue using Payaca',
    primaryText: 'View options',
    secondaryText: 'Contact us',
  },
  EXCEEDED_SUBSCRIPTION: {
    title: 'Please upgrade',
    text: "You've reached the maximum number of Projects for your subscription, please upgrade your subscription to continue using Payaca",
    primaryText: 'Upgrade',
    secondaryText: 'Contact us',
  },
  OUTSTANDING_DEPOSIT_PAYMENT_PROMPT: {
    title: 'Outstanding deposit payment',
    text: 'The outstanding deposit has not been recorded as being paid. Would you like to record a payment now?',
    primaryText: 'Yes, record a payment',
    secondaryText: 'No, raise an invoice anyway',
  },
  ACCEPT_PAYMENTS_WITH_STRIPE: {
    icon: CreditCardIcon,
    title: 'Accept card payments with Stripe',
    text: (
      <div>
        <div style={{ marginBottom: '10px' }}>
          Stripe is a secure payment platform, trusted by over a million
          businesses all over the world.
        </div>
        <div style={{ marginBottom: '10px' }}>
          If you already have a Stripe account you can connect to it or if you
          don‘t you can create one.
        </div>
        <div style={{ textAlign: 'left', marginBottom: '10px' }}>
          <div>
            <DoneIcon style={{ fontSize: 15 }} /> Make it easy for customers to
            pay fast with a button on your quotes and invoices.
          </div>
          <div>
            <DoneIcon style={{ fontSize: 15 }} /> Fast, easy setup
          </div>
          <div>
            <DoneIcon style={{ fontSize: 15 }} /> Fees for European cards are
            1.4% +20p, you can find out more{' '}
            <a
              href="https://stripe.com/gb/pricing"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </div>
        </div>
      </div>
    ),
    hidePrimaryButton: true,
    primaryLink: `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${window.location.origin}/enableCustomerPayments`,
    primaryText: 'Connect with Stripe',
    tertiaryText: 'No thanks',
    size: 'sm',
  },
  ACCEPT_PAYMENTS_WITH_BACS: {
    title: 'Bank details',
    text: "If you enter your bank details below we'll store them securely and add them to quotes and invoices requesting payment.",
    hidePrimaryButton: true,
    primaryTo: '/companySettings',
    primaryText: 'Add your bank details',
  },
  INACTIVE_EMAIL: {
    title: 'Email address not active',
    text: '"$0" has previously rejected messages due to a bounce or spam complaint. Please edit your customer\'s email and try again.',
    primaryText: 'OK',
  },
  UPDATE_ACCOUNT_ERROR_GENERAL: {
    title: 'Update account error',
    text: 'Sorry, there was an error updating your account. Please try again.',
    primaryText: 'OK',
  },
  UPDATE_ACCOUNT_ERROR_LIST: {
    title: 'Update account error',
    text: 'Sorry, there was an error updating the $0 on your account. Please try again.',
    primaryText: 'OK',
  },
  SAVE_CUSTOMER_WITHOUT_EMAIL: {
    title: 'Missing customer email',
    text: "Are you sure you want to save this customer record without an email address? The customer won't receive any communications via Payaca.",
    secondaryText: 'Continue without email',
    primaryText: 'Add email',
    size: 'sm',
    classes: 'no-email-customer',
  },
  UPDATE_CUSTOMER: {
    title: 'Update customer',
    text: 'Are you sure you want to update this customer record? This will update on all related quotes and invoices.',
    secondaryText: 'No, cancel',
    primaryText: 'Yes, update customer',
    size: 'sm',
  },
  ADD_REPEATED_ITEM: {
    title: 'Item already added',
    text: 'Item already added, want to add again?',
    primaryText: 'Yes, add item',
    secondaryText: 'No, cancel',
    size: 'sm',
  },
  ADD_ITEM_GROUP_WITH_OPTIONAL_MULTIPLE_CHOICE: {
    title: 'Item group contains optional or multiple choice items',
    text: 'Item group contains items which are set as optional or multiple choice, these items will be made required if added to the invoice.',
    primaryText: 'Add item group',
    secondaryText: 'Cancel',
    size: 'sm',
  },
};

const ValueModal = {
  DEPOSIT: {
    removeButtonTitle: 'No deposit',
    title: 'Add deposit',
    valueAmountName: 'depositAmount',
    valuePercentageName: 'depositPercentage',
  },
  MARKUP: {
    removeButtonTitle: 'Remove markup',
    title: 'Add markup',
    valueAmountName: 'markupAmount',
    valuePercentageName: 'markupPercentage',
    informationText:
      'This markup is not visible to customers, it will be shown distributed across the items.',
    disallowFullAmount: true,
    infoText:
      'Fixed markup amounts will only be distributed across mandatory items, not multiple choice or optional items.',
  },
  DISCOUNT: {
    removeButtonTitle: 'Remove discount',
    title: 'Add discount',
    valueAmountName: 'discountAmount',
    valuePercentageName: 'discountPercentage',
  },
};

/**
 * getModal
 * @param {string} modalName name of modal to use (from keys in 'Modals')
 * @param {object} options key/values of params for modal
 *   actions passed in complete
 *   text can have dynamic arguments i.e.
 *            { text: 'Delete $0' } in 'Modals'
 *            { text: ['quote'] } or { text: ['inoice'] } in options
 *   text passed in as strings will overwrite any existing text in 'Modals'
 *
 * @return {object} modal paramaters for ConfirmModal
 */
const getModal = (modalName, options = {}) => {
  let modal = { ...Modal[modalName] };
  Object.entries(options).forEach(([k, v]) => {
    if (!modal[k]) {
      // add any actions into modal
      modal[k] = v;
    } else {
      // parse sentences with inputs or overwrite string
      let t = modal[k];
      if (typeof v === 'string') {
        // if string, overwrite modal property
        t = v;
      } else {
        // array, replace string `$x` with string x in array
        v.forEach((r, i) => {
          t = t.replace(`$${i}`, r);
        });
      }
      modal[k] = t;
    }
  });
  return modal;
};

export { ValueModal, getModal };
