import useGetPurchaseOrderDeliveryAddress from '@/api/queries/purchase-orders/useGetPurchaseOrderAddress';
import { useSelector } from '@/api/state';
import PurchaseOrderDocumentComponent from '@payaca/components/purchaseOrderDocument/PurchaseOrderDocument';
import { getDocumentSenderContextFromAccount } from '@payaca/helpers/documentSenderContextHelper';
import { HydratedPurchaseOrder } from '@payaca/types/materialsListTypes';
import { FunctionComponent } from 'react';

type Props = {
  purchaseOrder: HydratedPurchaseOrder;
  project?: {
    reference?: string;
  };
};

const PurchaseOrderDocument: FunctionComponent<Props> = ({
  purchaseOrder,
  project,
}: Props): JSX.Element | null => {
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const { data: deliveryAddress } = useGetPurchaseOrderDeliveryAddress(
    purchaseOrder.id
  );

  if (!purchaseOrder) return null;

  return (
    <PurchaseOrderDocumentComponent
      project={project}
      documentSenderContext={getDocumentSenderContextFromAccount(account)}
      purchaseOrder={purchaseOrder}
      supplier={purchaseOrder.supplier}
      materialPurchaseRecords={purchaseOrder.materialPurchaseRecords}
      materialPurchaseIntents={purchaseOrder.materialPurchaseIntents}
      deliveryAddress={
        deliveryAddress
          ? {
              line1: deliveryAddress.line1,
              line2: deliveryAddress.line2,
              city: deliveryAddress.city,
              postcode: deliveryAddress.postalCode,
              country: deliveryAddress.country,
            }
          : undefined
      }
    />
  );
};

export default PurchaseOrderDocument;
