import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  getScheduledEvent,
  getTasksByScheduledEventId,
  getUserRoles,
} from '@/utils/stateAccessors';

import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import CreateEditTaskModal from '../createEditTaskModal/CreateEditTaskModal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import TaskControl from '../taskControl/TaskControl';

import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';

import * as scheduledEventActions from '@payaca/store/scheduledEvents/scheduledEventsActions';
import * as taskActions from '@payaca/store/tasks/tasksActions';

import { Task } from '@payaca/types/taskTypes';

import { useSelector } from '@/api/state';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { requestGetDocumentForTask } from '@payaca/store/documents/documentActions';
import ScheduledEventTaskStatusBadge from '../scheduledEventTaskStatusBadge/ScheduledEventTaskStatusBadge';
import { SectionDivider, SectionTitle } from './SectionComponents';

type Props = {
  scheduledEventId: number;
  onUpdateTasksSuccess?: () => void;
};
export const TasksSection: FC<Props> = ({
  scheduledEventId,
  onUpdateTasksSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);

  const userRoles = useSelector(getUserRoles);

  const profile = useSelector((state: any) => state.users.myProfile);

  const scheduledEvent = useSelector((state) =>
    getScheduledEvent(state, scheduledEventId)
  );

  const tasks = useSelector((state) => {
    return getTasksByScheduledEventId(state, scheduledEventId);
  });

  const completeTasks = useMemo(
    () => tasks.filter((task) => !!task.completedAt),
    [tasks]
  );

  const incompleteTasks = useMemo(
    () => tasks.filter((task) => !task.completedAt),
    [tasks]
  );

  const isGettingTasksForScheduledEvent = useSelector((state) => {
    return state.tasks.isGettingTasksForScheduledEvent;
  });

  const retrieveScheduledEvent = useCallback(() => {
    dispatch(scheduledEventActions.requestGetScheduledEvent(scheduledEventId));
  }, [scheduledEventId]);

  const retrieveTask = useCallback(
    (taskId: number, isTaskDocument: boolean) => {
      dispatch(taskActions.requestGetTask(taskId));
      if (isTaskDocument) {
        dispatch(requestGetDocumentForTask(taskId));
      }
    },
    []
  );

  const canOnlyViewOwnTasks = useMemo(() => {
    return (
      !userHasRequiredPermission(userRoles, [TasksPermissions.PERSIST_TASK]) &&
      userHasRequiredPermission(userRoles, [
        TasksPermissions.PERSIST_SELF_CREATED_TASK,
      ])
    );
  }, [userRoles]);

  const newTaskBase = useMemo(() => {
    return {
      assignedToUserId: canOnlyViewOwnTasks ? profile.id : undefined,
      scheduledEventIds: [scheduledEventId],
    } as Task;
  }, [canOnlyViewOwnTasks, profile.id, scheduledEventId]);

  if (!scheduledEvent) return null;

  return (
    <div>
      <div className="flex flex-row items-center gap-4">
        <SectionTitle>Tasks</SectionTitle>
        <ScheduledEventTaskStatusBadge status={scheduledEvent.taskStatus} />
        <PermissionGuard
          renderIfHasPermissions={[
            TasksPermissions.PERSIST_TASK,
            TasksPermissions.PERSIST_SELF_CREATED_TASK,
          ]}
        >
          <>
            <div className="ml-auto">
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => setShowCreateTaskModal(true)}
              >
                Add Task
              </Button>
            </div>
            <CreateEditTaskModal
              dealId={scheduledEvent.dealId || undefined}
              isOpen={showCreateTaskModal}
              onClose={() => setShowCreateTaskModal(false)}
              task={newTaskBase}
              onPersistTaskSuccess={(taskId) => {
                setShowCreateTaskModal(false);
                dispatch(
                  scheduledEventActions.requestGetScheduledEvent(
                    scheduledEvent.id
                  )
                );
                dispatch(taskActions.requestGetTask(taskId));
              }}
            />
          </>
        </PermissionGuard>
      </div>
      <SectionDivider />
      {isGettingTasksForScheduledEvent && !tasks?.length ? (
        <div className="flex items-center justify-center">
          <MiniLoader />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          {!!incompleteTasks.length && (
            <div className="flex flex-col gap-2">
              {incompleteTasks.map((task, index) => {
                return (
                  <TaskControl
                    taskId={task.id}
                    key={`incomplete-task-control-${index}`}
                    onPersistTaskSuccess={() => {
                      onUpdateTasksSuccess?.();
                      retrieveTask(task.id, !!task.documentId);
                    }}
                    onDeleteTaskSuccess={() => {
                      onUpdateTasksSuccess?.();
                      retrieveScheduledEvent();
                    }}
                    dealId={scheduledEvent.dealId || undefined}
                  />
                );
              })}
            </div>
          )}
          {!!completeTasks.length && (
            <div className="flex flex-col gap-2">
              {completeTasks.map((task, index) => {
                return (
                  <TaskControl
                    taskId={task.id}
                    key={`complete-task-control-${index}`}
                    onPersistTaskSuccess={() => {
                      onUpdateTasksSuccess?.();
                      retrieveTask(task.id, !!task.documentId);
                    }}
                    onDeleteTaskSuccess={() => {
                      onUpdateTasksSuccess?.();
                      retrieveScheduledEvent();
                    }}
                    dealId={scheduledEvent.dealId || undefined}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
