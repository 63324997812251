import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import useRestoreProject from '@/api/mutations/project/useRestoreProject';
import projectKeys from '@/api/queries/project/keyFactory';
import Button from '@payaca/components/plButton/Button';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';

export interface IProps extends Omit<IModalProps, 'title'> {}

const RestoreProjectModal: FC<IProps> = (props) => {
  const { onClose, ...rest } = props;
  const { dealId } = useParams<{ dealId: string }>();
  const queryClient = useQueryClient();
  const { mutateAsync: restoreProjectMutation, isLoading } =
    useRestoreProject();

  const handleRestore = async () => {
    await restoreProjectMutation(dealId);

    queryClient.invalidateQueries({
      queryKey: projectKeys.project(+dealId),
    });

    onClose?.();
  };

  return (
    <Modal title="Restore Project" onClose={onClose} {...rest}>
      <Modal.Body>
        <p>
          Restoring this Project will also restore any Proposals, Invoices,
          Events, Tasks, Forms and Payments that were active at the time the
          Project was deleted.
        </p>
        <p className="mt-2 font-semibold">
          This action will not be reflected in any third-party integrations.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            isProcessing={isLoading}
            disabled={isLoading}
            onClick={handleRestore}
          >
            Restore
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default RestoreProjectModal;
