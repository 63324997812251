import { AccountRegions } from '@payaca/types/accountTypes';
import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';
  if (phoneNumber.length < 2) return phoneNumber;

  const firstCharacters = phoneNumber.substring(0, 2);
  if (firstCharacters === '44') {
    phoneNumber = '+' + phoneNumber;
  }
  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    if (parsedPhoneNumber) {
      return parsedPhoneNumber.formatInternational();
    }
  } catch (error) {
    return phoneNumber;
  }

  return phoneNumber;
};

export const getParsedPhoneNumber = (
  phoneNumber: string
): PhoneNumber | null => {
  if (!phoneNumber) return null;
  if (phoneNumber.length < 2) return null;

  const firstCharacters = phoneNumber.substring(0, 2);
  if (firstCharacters === '44') {
    phoneNumber = '+' + phoneNumber;
  }
  try {
    return parsePhoneNumber(phoneNumber);
  } catch (error) {
    return null;
  }
};

export const convertToInternationalPhoneNumber = (
  accountRegion: AccountRegions,
  phoneNumber: string
) => {
  let dialingCode = '+44';
  switch (accountRegion) {
    case AccountRegions.AUSTRALIA:
      dialingCode = '+61';
      break;
    case AccountRegions.SOUTH_AFRICA:
      dialingCode = '+27';
      break;
    case AccountRegions.CANADA:
    // fall-through
    case AccountRegions.US:
      dialingCode = '+1';
      break;
  }
  if (phoneNumber.startsWith(dialingCode)) {
    return phoneNumber;
  }
  if (phoneNumber.startsWith(dialingCode.slice(1))) {
    return `+${phoneNumber}`;
  }
  return `${dialingCode}${phoneNumber.slice(1)}`;
};
