import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import FileUploadPersistRemoveControl from '@payaca/components/fileUploadPersistRemoveControl/FileUploadPersistRemoveControl';
import { FormElement } from '@payaca/types/formElementTypes';

import * as uploadsActions from '@payaca/store/uploads/uploadsActions';

import { useSelector } from '@/api/state';

import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { UploadsPermissions } from '@payaca/permissions/uploads/uploads.permissions';

import { getUserRoles } from '@/utils/stateAccessors';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';

const acceptFileTypes = getAcceptedFileTypes(['document', 'image', 'video']);

type Props = {
  elem: FormElement;
  formState: any;
  changeHandler: any;
};

const FileUploadElement: FC<Props> = ({
  elem,
  formState,
  changeHandler,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const userRoles = useSelector(getUserRoles);

  const persistedFiles = useMemo(() => {
    return formState?.[elem.id] || [];
  }, [formState]);

  const persistFile = useCallback(
    (file: File) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(
          uploadsActions.requestPersistUploadAndLinkToEntity(
            {
              file,
              entityType: 'documentForm',
              entityId: formState.id,
            },
            (
              error: string | null,
              upload?: { id: number; url: string; fileName: string }
            ) => {
              if (!error) {
                // save to form
                changeHandler({
                  [elem.id]: [
                    ...(formState?.[elem.id] || []),
                    {
                      identifier: upload?.id,
                      fileName: upload?.fileName,
                      url: upload?.url,
                    },
                  ],
                });
                resolve();
              } else {
                reject();
              }
            }
          )
        );
      });
    },
    [changeHandler, dispatch, formState]
  );

  const removePersistedFile = useCallback(
    (fileIdentifier: number) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(
          uploadsActions.requestDeleteUpload(fileIdentifier, (error) => {
            if (!error) {
              const uploads = formState?.[elem.id] || [];
              // remove from uploads
              const uploadIndexToRemove = uploads.findIndex(
                (u: any) => u.identifier === fileIdentifier
              );
              uploads.splice(uploadIndexToRemove, 1);
              // save to form
              changeHandler({
                [elem.id]: uploads,
              });
              resolve();
            } else {
              reject();
            }
          })
        );
      });
    },
    [changeHandler, dispatch, formState]
  );

  const userCanDeleteAttachment = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      UploadsPermissions.DELETE_UPLOAD,
    ]);
  }, [userRoles]);

  return (
    <FileUploadPersistRemoveControl
      acceptFileTypes={acceptFileTypes}
      enableDragAndDrop={true}
      allowMultipleUploads={true}
      persistFile={persistFile}
      removePersistedFile={removePersistedFile}
      persistedFiles={persistedFiles}
      canRemove={userCanDeleteAttachment}
    />
  );
};

export default FileUploadElement;
