import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import values from 'lodash.values';
import { FunctionComponent, useMemo, useState } from 'react';

import {
  FileSizeLimit,
  FileType,
  getMbFileLimit,
  ImageFileType,
} from '@payaca/helpers/fileHelper';
import { JobAttachment as JobAttachmentType } from '@payaca/types/jobTypes';

import JobAttachment from './JobAttachment';

import './JobAttachments.sass';

type Props = {
  jobAttachments: JobAttachmentType[] | any;
  onAddLabel?: string;
  uploadType?: string;
  readOnly?: boolean;
  onFileChange?: (file: any, remove?: boolean) => void;
  title?: string;
  onError?: (error: string) => void;
  quantityLimit?: number | null;
  imagesOnly?: boolean;
  isDownloadDisabled?: boolean;
};

const JobAttachments: FunctionComponent<Props> = ({
  jobAttachments, // list of files
  onAddLabel, // label for add button
  uploadType, // name of upload type to determine the file size limit
  readOnly = true, // editable-ness for uploading i.e. for admin
  onFileChange,
  title = 'Attachments',
  onError,
  quantityLimit = null,
  imagesOnly = false,
  isDownloadDisabled = false, // disabled download on mobile preview
}: Props): JSX.Element => {
  const [exceededFileLimit, setExceededFileLimit] = useState(false);
  const randomKey = Math.floor(Math.random() * 10000000000); // when multiple instances of component exist on one page

  const canAddFile = useMemo(
    () =>
      onFileChange && !readOnly && quantityLimit !== null
        ? jobAttachments.length < quantityLimit
        : onFileChange && !readOnly,
    [quantityLimit, jobAttachments, onFileChange, readOnly]
  );

  const onNewFile = (e: any) => {
    // clear any errors
    setExceededFileLimit(false);
    const file = e.target.files[0];
    if (file) {
      const fileSizeLimit = uploadType ? FileSizeLimit[uploadType] : null;
      if (fileSizeLimit && file.size > fileSizeLimit) {
        setExceededFileLimit(true);
        // return error to monitor if file limit should be adjusted
        onError &&
          onError(
            `File upload attempt of ${file.size} for ${uploadType} exceeds limit of ${fileSizeLimit}`
          );
      } else {
        setExceededFileLimit(false);
        const defaultFileName = file.name.split('.')[0];
        onFileChange &&
          onFileChange({
            fileName: defaultFileName,
            file,
          });
      }
    }
    // Allow uploading of the same file
    if (e.target && e.target.value) {
      e.target.value = '';
    }
  };

  const renderJobAttachments = () => {
    return jobAttachments.map(
      (attachment: JobAttachmentType | any, i: number) => {
        return (
          <JobAttachment
            file={attachment}
            key={i}
            isUpload={!!onFileChange}
            readOnly={readOnly}
            onFileChange={(change, remove) =>
              onFileChange && onFileChange(change, remove)
            }
            isDownloadDisabled={isDownloadDisabled}
          />
        );
      }
    );
  };

  // list of accepted files for input
  const fileTypesAccepted = imagesOnly ? ImageFileType : FileType;
  const acceptedFiles = values(fileTypesAccepted)
    .map((v) => v.name[0])
    .join(', ');

  return (
    <div className="job-attachments">
      {title && <p>{title}</p>}
      <div className="job-attachments-wrapper">
        {/* Add attachment */}
        {canAddFile && (
          <div className="job-attachment add">
            <div className="file-wrapper">
              <label htmlFor={`file-upload-${randomKey}`}>
                {onAddLabel && <div>{onAddLabel}</div>}
                <FontAwesomeIcon icon={faPlus} className="plus-icon" />
              </label>
              <input
                id={`file-upload-${randomKey}`}
                type="file"
                onChange={(e) => onNewFile(e)}
                accept={acceptedFiles}
                className="hidden"
              />
            </div>
          </div>
        )}
        {/* Added attachments */}
        {renderJobAttachments()}
      </div>
      {exceededFileLimit && uploadType && (
        <div className="error">
          File size exceeds limit of {getMbFileLimit(FileSizeLimit[uploadType])}
        </div>
      )}
    </div>
  );
};

export default JobAttachments;
