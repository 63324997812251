import { currencyPrice } from '@payaca/helpers/financeHelper';
import { BrandingColourContext } from '@payaca/types/accountBrandTypes';
import { AccountRegions } from '@payaca/types/accountTypes';
import { DealTotals } from '@payaca/types/dealTypes';
import { Invoice, InvoiceLine } from '@payaca/types/invoiceTypes';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import moment from 'moment-timezone';
import React, { FunctionComponent, useMemo } from 'react';

type Props = {
  invoiceId: number;
  invoices: Pick<Invoice, 'id' | 'sentAt' | 'voidedAt' | 'totalValue'>[];
  dealTotals: Pick<DealTotals, 'acceptedValue'>;
  jobLineItems: JobLineItem[];
  invoiceLines: InvoiceLine[];
  region?: AccountRegions;
  brandingColourContext: BrandingColourContext;
  isStraightToInvoice?: boolean;
};

const InvoiceSummary: FunctionComponent<React.PropsWithChildren<Props>> = ({
  invoices,
  invoiceId,
  dealTotals,
  invoiceLines,
  jobLineItems,
  region,
  brandingColourContext,
  isStraightToInvoice = false,
}: React.PropsWithChildren<Props>): JSX.Element | null => {
  const invoice = useMemo(() => {
    return invoices.find((x) => x.id == invoiceId);
  }, [invoices, invoiceId]);

  const previouslySentInvoices = useMemo(() => {
    if (!invoice) return [];
    const invoiceSentAt = invoice.sentAt ? moment(invoice.sentAt) : moment();
    return invoices.filter((x) => {
      return (
        moment(x.sentAt).isBefore(invoiceSentAt) &&
        (!x.voidedAt || moment(x.voidedAt).isAfter(invoiceSentAt))
      );
    });
  }, [invoice]);

  const previouslyInvoicedValue = useMemo(() => {
    return previouslySentInvoices.reduce(
      (acc, invoice) => acc + invoice.totalValue,
      0
    );
  }, [previouslySentInvoices]);

  const stillToInvoiceValue = useMemo(() => {
    return (
      dealTotals.acceptedValue -
      previouslyInvoicedValue -
      (invoice?.totalValue || 0)
    );
  }, [previouslySentInvoices]);

  if (!invoice) return null;

  return (
    <>
      <h2 className="mb-5 text-lg">Summary</h2>

      <table className="w-full border-collapse text-base">
        <tbody>
          {!isStraightToInvoice && (
            <tr>
              <td className="pb-1 pr-2">Agreed project total</td>
              <td className="pb-1 text-right align-bottom">
                {currencyPrice(dealTotals.acceptedValue, region)}
              </td>
            </tr>
          )}

          {previouslyInvoicedValue > 0 && (
            <tr>
              <td className="py-1 pr-2">Previously invoiced</td>
              <td className="py-1 text-right align-bottom">
                {currencyPrice(previouslyInvoicedValue, region)}
              </td>
            </tr>
          )}

          {stillToInvoiceValue > 0 && (
            <tr>
              <td className="py-1 pr-2">Yet to invoice</td>
              <td className="py-1 text-right align-bottom">
                {currencyPrice(stillToInvoiceValue, region)}
              </td>
            </tr>
          )}

          <tr>
            <td className="pr-2 pt-1 font-bold">Due on this invoice</td>
            <td className="pt-1 text-right align-bottom font-bold">
              {currencyPrice(invoice.totalValue, region)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default InvoiceSummary;
