import { AccountRegions } from '@payaca/types/accountTypes';
import { CurrencyCodes } from '@payaca/types/currencyTypes';
import { currencyCodeForRegion } from './currencyHelper';

const conditionallyParseStringToNumber = (
  val: number | string
): number | string => (isNaN(Number(val)) ? val : Number(val));
const parsePrice = (price: number): number => price / 100;
const parseDisplayPrice = (price: number): number =>
  price ? parsePrice(price) : price;
const parseVat = (vat: number | string): number | string =>
  conditionallyParseStringToNumber(vat);
// round before trunc because js can't do maths
const formatPreChange = (value: any) =>
  value ? Math.trunc(Math.round(value * 100)) : value;

/**
 * @param {number} price - price
 * @param {?string} currency - specified currency
 *
 * @return {string} currency formatted price
 */
const currencyPrice = (
  price: number,
  region?: AccountRegions,
  includeFractional = true,
  options?: Intl.NumberFormatOptions
): string => {
  return formatPrice(
    getNavigatorLocale() ?? localeForRegion[region!] ?? 'en-GB',
    includeFractional
      ? options
      : {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
          ...options,
        }
  )({
    priceInMinorCurrencyUnits: price,
    exponent: 2,
    currencyCode: currencyCodeForRegion[region!] ?? CurrencyCodes.GBP,
  });
};

const getNavigatorLocale = () =>
  (typeof window !== 'undefined' && window.navigator?.language) || undefined;

const localeForRegion = {
  [AccountRegions.AUSTRALIA]: 'en-AU',
  [AccountRegions.CANADA]: 'en-CA',
  [AccountRegions.NEW_ZEALAND]: 'en-NZ',
  [AccountRegions.SOUTH_AFRICA]: 'af-ZA',
  [AccountRegions.UK]: 'en-GB',
  [AccountRegions.US]: 'en-US',
};

/**
 * currencySymbol
 * @param currencyStr {string} currency - specified currency
 *
 * @return {string} currency symbol string
 */
const currencySymbol = (region?: AccountRegions) => {
  const locale = getNavigatorLocale() ?? localeForRegion[region!] ?? 'en-GB';
  const parts = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCodeForRegion[region!] ?? CurrencyCodes.GBP,
  }).formatToParts();
  return parts.find((t) => t.type === 'currency')?.value || '£';
};

type PriceWithCurrency = {
  priceInMinorCurrencyUnits: number;
  exponent: number;
  currencyCode: string;
};

export const toMajorCurrencyUnits = ({
  priceInMinorCurrencyUnits,
  exponent,
}: PriceWithCurrency) => priceInMinorCurrencyUnits / 10 ** exponent;

export const formatPrice =
  (locale: string, options: Intl.NumberFormatOptions = {}) =>
  (price: PriceWithCurrency) =>
    new Intl.NumberFormat(locale, {
      ...options,
      style: 'currency',
      currency: price.currencyCode,
      currencyDisplay: 'narrowSymbol',
    }).format(toMajorCurrencyUnits(price));

/**
 * formatPercentage - default 2dp, removed trailing zeros
 * @param percentage {number} percentage number to format
 *
 * @return {string}
 */
const formatPercentage = (percentage: number, dp = 2): number =>
  parseFloat(percentage.toFixed(dp));

/**
 * @param {number} price - price of item/job
 * @param {number} parsedVat - vat amount
 *
 * @return {number} total price
 */
const calculatedTotalAmount = (price: number, parsedVat: number): number => {
  return price + price * (parsedVat / 100);
};

/**
 * @param {number} price - price of item/job
 * @param {number} parsedVat - vat amount
 * @param {?string} currency - specified currency
 *
 * @return {string} currency formatted total price
 */
const calculatedTotal = (
  price: number,
  parsedVat: number,
  accountRegion = AccountRegions.UK
): string => {
  const total = calculatedTotalAmount(price, parsedVat);
  return currencyPrice(total, accountRegion);
};

/**
 * @param {number} parsedVat -vat amount
 * @param {boolean} vatIncluded - specify if vat is included
 * @param {boolean} shortened - show shortened format
 *
 * @return {string} formatted vat total
 */
const vatTotalFormatted = (
  parsedVat: number,
  vatIncluded: boolean,
  shortened: boolean,
  isReverseChargeVat = false
): string => {
  const vatIncTotal = `${shortened ? '' : 'VAT'} ${parsedVat}%${
    isReverseChargeVat ? ' REVERSE CHARGE' : ''
  }`;
  return vatIncluded ? vatIncTotal : 'No VAT';
};

/**
 * @param {number} price price of job
 * @param {number} depositPercent deposit percent
 * @param {?string} currency specified currency
 *
 * @return {string} currency formatted total deposit
 */
const depositTotal = (
  price: number,
  depositPercent: number,
  currency?: string
): string => {
  const total = price * (depositPercent / 100);
  return currencyPrice(total);
};

/**
 * itemTotal
 * @param {any} price - price of job
 * @param {number} vatAmount - vat amount
 * @param {boolean} vatIncluded - specify if vat is included
 * @param {number} quantity - quantity of a job line item
 *
 * @return {number} currency formatted item total inc. vat
 */
const itemTotal = (
  price: any,
  vatAmount: number,
  vatIncluded: boolean,
  quantity: number,
  isReverseChargeVat = false,
  accountRegion?: AccountRegions
): string => {
  const priceNo = parseInt(price, 10) || 0; // price is '' for new items, so set to 0 if NaN
  const itemsPrice = priceNo * quantity;
  return calculatedTotal(
    itemsPrice,
    vatIncluded && !isReverseChargeVat ? vatAmount : 0,
    accountRegion
  );
};

export {
  calculatedTotal,
  calculatedTotalAmount,
  currencyPrice,
  currencySymbol,
  depositTotal,
  formatPercentage,
  formatPreChange,
  itemTotal,
  parseDisplayPrice,
  parsePrice,
  parseVat,
  vatTotalFormatted,
};
