import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Field from '@payaca/components/plField/Field';
import FileInput from '@payaca/components/plInput/FileInput';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { z } from 'zod';

export type TLineItemThumbnailFormState = {
  thumbnail?: { id?: string; file?: File; preview: string };
};

export const LINE_ITEM_THUMBNAIL_FORM_SCHEMA = z.object({
  thumbnail: z
    .object({
      id: z.string().optional(),
      file: z.any(),
      preview: z.string(),
    })
    .optional(),
});

type TProps = {
  onDelete?: () => void;
};

const ThumbnailField: FC<TProps> = (props) => {
  const { onDelete } = props;

  const formMethods = useFormContext<TLineItemThumbnailFormState>();

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <Field>
          <Field.Label>Image</Field.Label>

          <Controller
            name="thumbnail"
            control={formMethods.control}
            render={({ field: { onChange, value } }) => {
              return (
                <FileInput
                  label="Thumbnail"
                  url={value?.preview}
                  onDrop={(file) => {
                    onChange({
                      file,
                      preview: URL.createObjectURL(file),
                    });
                  }}
                  onDelete={onDelete}
                  dropzoneOptions={{
                    accept: getAcceptedFileTypes(['image']),
                  }}
                />
              );
            }}
          />
        </Field>
      </Card.Body>
    </Card>
  );
};

export default ThumbnailField;
