import { getTask, getUserRoles } from '@/utils/stateAccessors';
import TooltipUI from '@material-ui/core/Tooltip';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import { requestDeleteTask } from '@payaca/store/tasks/tasksActions';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import CreateEditTaskModal from '../createEditTaskModal/CreateEditTaskModal';

import { useSelector } from '@/api/state';
import { useUserHasPermission } from '@/hooks/usePermissions';
import DueMessage from '@payaca/components/dueMessage/DueMessage';
import QuickActionsElement from '@payaca/components/quickActionsTableCell/QuickActionsElement';
import UserIndicator from '@payaca/components/userIndicator/UserIndicator';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { DocumentsPermissions } from '@payaca/permissions/documents/documents.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';
import {
  createFormInstanceForDocument,
  requestGetDocumentForTask,
} from '@payaca/store/documents/documentActions';
import { Document } from '@payaca/types/documentTypes';
import { Danger, TimeCircle } from 'react-iconly';
import { useHistory } from 'react-router';
import ChecklistItemsProgressIndicator from '../checklistItemsProgressIndicator/ChecklistItemsProgressIndicator';
import ConfirmDeleteTaskModal from '../confirmDeleteTaskModal/ConfirmDeleteTaskModal';
import { DocumentPrefillPreferencesModal } from '../documentPrefillPreferencesControl/DocumentPrefillPreferencesModal';
import { MaterialsListTaskModal } from '../materialsListTaskModal/MaterialsListTaskModal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import ViewCompleteTaskModal from '../viewCompleteTaskModal/ViewCompleteTaskModal';
import './TaskControl.sass';

const renderCustomDueMessage = (dueInDays: number, message: string) => {
  const className =
    dueInDays == 0 ? 'today' : dueInDays > 0 ? 'future' : 'past';

  let icon = undefined;

  if (dueInDays > 0) {
    icon = <TimeCircle set={'light'} size={20} />;
  } else {
    icon = <Danger set={'light'} size={20} />;
  }

  return (
    <div style={{ position: 'relative' }}>
      <TooltipUI
        title={message}
        arrow
        placement="top"
        PopperProps={{
          className: `task-due-message-tooltip MuiTooltip-popper MuiTooltip-popperArrow ${className}`,
        }}
      >
        <div>
          <div className={`task-due-message ${className}`}>{icon}</div>
        </div>
      </TooltipUI>
    </div>
  );
};

type Props = {
  dealId?: number;
  taskId: number;
  onPersistTaskSuccess?: () => void;
  onDeleteTaskSuccess?: () => void;
};
const TaskControl: FC<Props> = ({
  dealId,
  taskId,
  onPersistTaskSuccess,
  onDeleteTaskSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [
    showDocumentPrefillPreferencesControl,
    setShowDocumentPrefillPreferencesControl,
  ] = useState(false);
  const [showDeleteTaskWithFormModal, setShowDeleteTaskWithFormModal] =
    useState(false);
  const [showViewCompleteTaskModal, setShowViewCompleteTaskModal] =
    useState(false);
  const [showMaterialsListTaskModal, setShowMaterialsListTaskModal] =
    useState(false);
  const task = useSelector((state) => {
    return getTask(state, taskId);
  });
  const [document, setDocument] = useState<Document>();
  const history = useHistory();

  const accountUsers: any[] = useSelector(
    (state: any) => state.users.accountUsers
  );

  const profile = useSelector((state: any) => state.users.myProfile);

  const assignedToUser = useMemo(() => {
    if (!task?.assignedToUserId) return;
    const user = accountUsers.find((x) => x.id === task.assignedToUserId);
    return user;
  }, [task, accountUsers]);

  const userCanManagePrefillPreferences = useUserHasPermission({
    permissions: [DocumentsPermissions.MANAGE_PREFILL_PREFERENCES],
  });

  const [isCreatingFormInstance, setIsCreatingFormInstance] = useState(false);

  const userRoles = useSelector(getUserRoles);

  const deleteTask = useCallback(() => {
    dispatch(requestDeleteTask(taskId, onDeleteTaskSuccess));
  }, [onDeleteTaskSuccess, dispatch, taskId]);

  const deleteTaskOrWarnDelete = useCallback(() => {
    if (task) {
      if (task?.documentId) {
        setShowDeleteTaskWithFormModal(true);
      } else {
        deleteTask();
      }
    }
  }, [deleteTask, task]);

  const isDeletingTask = useSelector((state) => {
    return state.tasks.isDeletingTask;
  });

  const quickActions = useMemo(() => {
    const quickActions: {
      isProcessing?: boolean;
      actionName: string;
      actionBehaviour: () => void;
    }[] = [];

    if (task?.type === 'materials-list') {
      return quickActions;
    }

    if (
      userHasRequiredPermission(userRoles, [TasksPermissions.PERSIST_TASK]) ||
      (userHasRequiredPermission(userRoles, [
        TasksPermissions.PERSIST_SELF_CREATED_TASK,
      ]) &&
        task?.createdByUserId == profile.id) ||
      (userHasRequiredPermission(userRoles, [
        TasksPermissions.PERSIST_SELF_ASSIGNED_DEAL_TASK,
      ]) &&
        task?.dealId)
    ) {
      quickActions.push({
        actionName: 'Edit',
        actionBehaviour: () => setShowEditTaskModal(true),
      });
    }

    if (
      userHasRequiredPermission(userRoles, [TasksPermissions.DELETE_TASK]) ||
      (userHasRequiredPermission(userRoles, [
        TasksPermissions.DELETE_SELF_CREATED_TASK,
      ]) &&
        task?.createdByUserId == profile.id)
    ) {
      quickActions.push({
        isProcessing: isDeletingTask,
        actionName: 'Delete',
        actionBehaviour: deleteTaskOrWarnDelete,
      });
    }

    return quickActions;
  }, [userRoles, isDeletingTask, deleteTaskOrWarnDelete, dealId, profile]);

  const showDueMessage = useMemo(() => {
    if (!task) return false;
    if (task.completedAt) return false;
    if (!task.deadlineDate) return false;

    return true;
  }, [task]);
  if (!task) return null;

  const onClickFormTask = useCallback(() => {
    dispatch(
      requestGetDocumentForTask(task.id, (document) => {
        setDocument(document);
        if (document.completedAt) {
          // if the document has already been completed, navigate to it in read view
          history.push(`/documents/${document.id}?taskId=${taskId}`);
        } else if (document.formInstances?.[0]?.previewToken) {
          // if a form instance already exists, navigate to it in 'edit' view
          history.push(
            `/forms/${document.formInstances[0].previewToken}/menu?dealId=${task.dealId}&taskId=${taskId}`
          );
        } else if (userCanManagePrefillPreferences) {
          // if we're here, a form instance doesn't exist yet and the user has permissions to manage how it's prefilled
          // open the form prefill preferences modal
          setShowDocumentPrefillPreferencesControl(true);
        } else if (!isCreatingFormInstance) {
          // if we're here, a form instance doesn't exist yet and the user does not have permissions to manage how it's prefilled
          // create a form instance and navigate to it
          setIsCreatingFormInstance(true);
          dispatch(
            createFormInstanceForDocument.request({
              documentId: document.id,
              callback: (formInstancePreviewToken: string) => {
                setIsCreatingFormInstance(false);
                history.push(
                  `/forms/${formInstancePreviewToken}/menu?dealId=${task.dealId}&taskId=${task.id}`
                );
              },
              onErrorCallback: () => {
                setIsCreatingFormInstance(false);
              },
            })
          );
        }
      })
    );
  }, [task, userCanManagePrefillPreferences, isCreatingFormInstance]);

  const onClickTask = useCallback(() => {
    switch (task.type) {
      case 'materials-list':
        setShowMaterialsListTaskModal(true);
        break;
      case 'checklist':
      case 'legacy-form':
        setShowViewCompleteTaskModal(true);
        break;
      case 'form':
        onClickFormTask();
        break;
    }
  }, [task, onClickFormTask]);

  return (
    <>
      <div className="task-control flex-container flex-center">
        <div
          className="task-control-panel flex-container flex-center clickable flex-grow"
          onClick={onClickTask}
        >
          <div>
            <CheckboxField name="isCompleted" value={!!task.completedAt} />
          </div>
          <div className="flex-container flex-center task-body-container flex-grow">
            <div className="flex-grow">
              <div>
                <span>{task.name}</span>
              </div>
              <div className="checklist-container">
                <ChecklistItemsProgressIndicator
                  checklistItems={task.checklistItems}
                />
              </div>
            </div>
            <div className="task-details-container flex-container flex-center">
              {showDueMessage && task.deadlineDate && (
                <DueMessage
                  dueDate={task.deadlineDate}
                  renderMessage={renderCustomDueMessage}
                />
              )}
              {assignedToUser && (
                <PermissionGuard
                  renderIfHasPermissions={[AccountsPermissions.GET_USERS]}
                >
                  <div>
                    <UserIndicator
                      user={{
                        firstName: assignedToUser.firstname,
                        lastName: assignedToUser.lastname,
                        emailAddress: assignedToUser.email,
                        userColour: assignedToUser.userColour,
                        imgSrc: assignedToUser.avatarUrl,
                      }}
                      isUserInformationHoverPopperEnabled={true}
                    />
                  </div>
                </PermissionGuard>
              )}
            </div>
          </div>
        </div>
        <div>
          {task.createdByUserId != profile.id && !quickActions?.length ? (
            <div style={{ position: 'relative' }}>
              <TooltipUI
                title={`No actions available against tasks you didn't create yourself`}
                arrow
                placement="top"
                PopperProps={{
                  className: `task-disabled-quick-actions-tooltip  MuiTooltip-popper MuiTooltip-popperArrow`,
                }}
              >
                <div>
                  <QuickActionsElement
                    recordId={task.id}
                    quickActions={quickActions}
                    renderDisabledIfNoActions={true}
                  />
                </div>
              </TooltipUI>
            </div>
          ) : (
            <QuickActionsElement
              recordId={task.id}
              quickActions={quickActions}
              renderDisabledIfNoActions={true}
            />
          )}
        </div>
      </div>
      <MaterialsListTaskModal
        isOpen={showMaterialsListTaskModal}
        onClose={() => {
          setShowMaterialsListTaskModal(false);
        }}
        taskId={task.id}
      />
      <CreateEditTaskModal
        dealId={dealId}
        task={task}
        isOpen={showEditTaskModal}
        onClose={() => setShowEditTaskModal(false)}
        onPersistTaskSuccess={() => {
          setShowEditTaskModal(false);
          if (onPersistTaskSuccess) {
            void onPersistTaskSuccess();
          }
        }}
      />
      <ViewCompleteTaskModal
        task={task}
        isOpen={showViewCompleteTaskModal}
        onClose={() => setShowViewCompleteTaskModal(false)}
        onUpdateTaskCompletionSuccess={() => {
          setShowViewCompleteTaskModal(false);
          if (onPersistTaskSuccess) {
            void onPersistTaskSuccess();
          }
        }}
      />
      <ConfirmDeleteTaskModal
        isOpen={showDeleteTaskWithFormModal}
        onClose={() => setShowDeleteTaskWithFormModal(false)}
        taskHasDocument={!!task.documentId}
        onConfirmDeleteTask={deleteTask}
      />
      {task.documentId && (
        <PermissionGuard
          renderIfHasPermissions={[
            DocumentsPermissions.MANAGE_PREFILL_PREFERENCES,
          ]}
        >
          <DocumentPrefillPreferencesModal
            title={document?.title || 'Document Pre-fill Preferences'}
            isOpen={showDocumentPrefillPreferencesControl}
            onClose={() => setShowDocumentPrefillPreferencesControl(false)}
            documentId={task.documentId}
            contextTaskId={task.id}
          />
        </PermissionGuard>
      )}
    </>
  );
};

export default TaskControl;
