import {
  Customer,
  CustomerAddress,
  CustomerContact,
} from '@payaca/types/customerTypes';
import { Address } from '@payaca/types/locationTypes';
import { getAddressAsString } from './locationHelper';

export const getPrimaryContactFromCustomer = (
  customer?: Customer
): null | CustomerContact => {
  if (!customer?.contacts?.length) return null;
  let primaryContact = customer.contacts.find((x) => x.isPrimaryContact);
  if (!primaryContact) {
    primaryContact = customer.contacts[0];
  }

  return primaryContact;
};

export const getNonPrimaryContactsFromCustomer = (customer?: Customer) => {
  if (!customer?.contacts?.length) {
    return [];
  }
  const contacts = customer.contacts.filter((x) => !x.isPrimaryContact);
  if (contacts.length === customer.contacts.length) {
    // no primary found - remove first contact from array
    contacts.splice(0, 1);
  }
  return contacts;
};

/**
 * Get contact associated with job, if no jobContactId primary contact is used
 * @param jobContactId
 * @param customer
 * @returns
 */
export const getJobContactFromCustomer = (
  customer: Customer,
  jobContactId: number | null
): null | CustomerContact => {
  if (!customer?.contacts?.length) {
    return null;
  }
  let jobCustomer = null;
  if (jobContactId) {
    jobCustomer = customer.contacts.find(
      (x: CustomerContact) => x.id === jobContactId
    );
  }
  if (!jobCustomer) {
    jobCustomer = getPrimaryContactFromCustomer(customer);
  }
  return jobCustomer;
};

export const getBillingAddressFromCustomer = (
  customer?: Customer
): null | CustomerAddress => {
  if (!customer?.addresses?.length) return null;
  let billingAddress = customer.addresses.find((x) => x.isBillingAddress);
  if (!billingAddress) {
    const filteredBillingAddresses = customer.addresses.filter(
      (x) => (getAddressAsString(x.address) || '').length > 0
    );

    if (filteredBillingAddresses.length) {
      billingAddress = filteredBillingAddresses[0];
    } else {
      billingAddress = customer.addresses[0];
    }
  }

  return billingAddress;
};

export const getNonBillingAddressesFromCustomer = (
  customer?: Customer
): CustomerAddress[] => {
  if (!customer?.addresses?.length) {
    return [];
  }
  const addresses = customer.addresses.filter((x) => !x.isBillingAddress);
  if (addresses.length === customer.addresses.length) {
    // no billing foud - remove address the substitute billing address
    const filteredBillingAddresses = customer.addresses.filter(
      (x) => (getAddressAsString(x.address) || '').length > 0
    );

    if (filteredBillingAddresses.length) {
      const firstFilteredBillingAddress = filteredBillingAddresses[0];
      const idxOfFirst = addresses.findIndex(
        (a) => a.id === firstFilteredBillingAddress.id
      );
      addresses.splice(idxOfFirst, 1);
    } else {
      addresses.splice(0, 1);
    }
  }
  return addresses;
};

export const getCustomerAddressAsString = (address?: Address) => {
  if (!address) return;

  const addressElements = [];
  addressElements.push(address.line1);
  addressElements.push(address.line2);
  addressElements.push(address.city);
  addressElements.push(address.postcode);

  return addressElements.filter(Boolean).join(', ');
};
