import { call, delay, put, race, takeEvery } from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  LineItem,
  LineItemAttachment,
  LineItemAttachmentMobilePayload,
  LineItemAttachmentPayload,
} from '@payaca/types/lineItemTypes';
import {
  clearCurrentLineItem,
  clearLineItems,
  createUpdateLineItemAttachmentsFailure,
  createUpdateLineItemAttachmentsSuccess,
  deleteLineItemsFailure,
  deleteLineItemsSuccess,
  getLineItemFailure,
  getLineItemSuccess,
  storeCurrentLineItem,
  updateCurrentLineItem,
} from './lineItemsActions';
import { ActionType, SagaConfig } from './lineItemsTypes';

import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const lineItemsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  // GET LINE ITEM
  function* handleGetLineItem(
    action: PayloadAction<
      ActionType.REQUEST_GET_LINE_ITEM,
      {
        lineItemId: number;
        callback: (lineItem: LineItem) => void;
        errorCallback?: (error: string) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getLineItem, action.payload.lineItemId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get line item timed out.';
        yield put(getLineItemFailure(new Error(errorMessage), errorMessage));
      } else {
        action.payload.callback && action.payload.callback(response);
        yield put(getLineItemSuccess(response));
        yield put(storeCurrentLineItem(response));
      }
    } catch (error: any) {
      yield put(getLineItemFailure(error, error.message));
      action.payload?.errorCallback?.(error.message);
    }
  }

  // DELETE LINE ITEMS
  function* handleDeleteLineItems(
    action: PayloadAction<
      ActionType.REQUEST_DELETE_LINE_ITEMS,
      {
        lineItemIds: number[];
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(deleteLineItems, action.payload.lineItemIds),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Delete line items timed out.';
        yield put(
          deleteLineItemsFailure(new Error(errorMessage), errorMessage)
        );
      } else {
        yield put(deleteLineItemsSuccess(response));
        action.payload.callback?.();
      }
    } catch (error: any) {
      yield put(deleteLineItemsFailure(error, error.message));
    }
  }

  // CREATE/UPDATE LINE ITEM ATTACHMENTS
  function* handleCreateUpdateLineItemAttachments(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_UPDATE_LINE_ITEM_ATTACHMENTS,
      {
        lineItemId: number;
        attachments: (LineItemAttachmentPayload | LineItemAttachment)[];
        callback?: () => void;
        errorCallback?: (error: string) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          createUpdateLineItemAttachments,
          action.payload.lineItemId,
          action.payload.attachments
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Create/Update line item timed out.';
        yield put(
          createUpdateLineItemAttachmentsFailure(
            new Error(errorMessage),
            errorMessage
          )
        );
      } else {
        // update current line item with updated attachments
        yield put(updateCurrentLineItem(response));
        yield put(createUpdateLineItemAttachmentsSuccess(response));
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(createUpdateLineItemAttachmentsFailure(error, error.message));
      action.payload.errorCallback &&
        action.payload.errorCallback(error.message);
    }
  }

  // LOGOUT
  function* handleAppLogout() {
    yield put(clearLineItems());
    yield put(clearCurrentLineItem());
  }

  const getLineItem = async (lineItemId: number) => {
    const response = await req.get(`/line_item/${lineItemId}`);
    return await response.json();
  };

  const deleteLineItems = async (lineItemIds: number[]) => {
    return await req.put(`/line_items/bulk_delete`, {
      lineItemIds: lineItemIds,
    });
  };

  const createUpdateLineItemAttachments = async (
    lineItemId: number,
    attachments: (
      | LineItemAttachmentPayload
      | LineItemAttachment
      | LineItemAttachmentMobilePayload
    )[]
  ) => {
    const formData = new FormData();
    attachments.forEach(
      (
        attachmentToUpload: LineItemAttachment | LineItemAttachmentPayload | any
      ) => {
        if (isNativeApp) {
          // isNativeApp will always have LineItemAttachmentMobilePayload, but satisifying ts where the properties are not on the other interfaces
          const attachmentToUploadNative = attachmentToUpload.file;
          if (attachmentToUploadNative?.uri) {
            // file needs uploading
            const localUri = attachmentToUploadNative.uri;
            const filename =
              attachmentToUploadNative.originalName.split('/').pop() || '';

            // Infer the type of the attachmentsToUploadMobile
            const match = /\.(\w+)$/.exec(filename);
            let type = '';
            if (match?.[1] === 'pdf') {
              type = 'application/pdf';
            } else {
              type = match
                ? `image/${match[1] === 'jpg' ? 'jpeg' : match[1]}`
                : 'image';
            }

            formData.append('attachmentsToUpload', {
              uri: localUri,
              name: attachmentToUploadNative.fileName,
              type,
            } as any);
          }
        } else {
          if ('file' in attachmentToUpload) {
            // file needs uploading
            formData.append(
              'attachmentsToUpload',
              attachmentToUpload.file,
              attachmentToUpload.fileName
            );
          }
        }
      }
    );

    formData.append('attachments', JSON.stringify(attachments));

    const response = await req.putForm(
      `/line_item/${lineItemId}/attachments`,
      formData
    );
    return await response.json();
  };

  return function* () {
    yield takeEvery(ActionType.REQUEST_GET_LINE_ITEM, handleGetLineItem);
    yield takeEvery(
      ActionType.REQUEST_DELETE_LINE_ITEMS,
      handleDeleteLineItems
    );
    yield takeEvery(
      ActionType.REQUEST_CREATE_UPDATE_LINE_ITEM_ATTACHMENTS,
      handleCreateUpdateLineItemAttachments
    );

    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default lineItemsSagaCreator;
