import { BaseDispatch } from '@payaca/types/dispatchTypes';
import { DeclareAsyncAction, UnpackAsyncAction } from '../types';

export interface State {}

export enum ActionType {
  GET_LOCATION_SEARCH_REQUEST = 'dispatch.getLocationSearch:request',

  GET_DISPATCH_PAGE_REQUEST = 'dispatch.getDispatchPage:request',
  GET_DISPATCH_PAGE_SUCCESS = 'dispatch.getDispatchPage:success',
  GET_DISPATCH_PAGE_FAILURE = 'dispatch.getDispatchPage:failure',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export type LocationSearchResult = {
  formattedAddress: string;
  lat: number;
  long: number;
}[];
export type GetLocationSearch = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_LOCATION_SEARCH_REQUEST;
      payload: {
        address: string;
        callback: (result: LocationSearchResult) => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type GetDispatchRequestData = {
  assignedTo?: (number | 'unassigned')[];
  startDate: Date;
  endDate: Date;
  pipelineStages?: string[];
};

export type GetDispatchPage = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_DISPATCH_PAGE_REQUEST;
      payload: {
        getDispatchPageRequestData: GetDispatchRequestData;
        callback: (result: BaseDispatch[]) => void;
        onErrorCallback?: () => void;
      };
    };
    success: { type: ActionType.GET_DISPATCH_PAGE_SUCCESS };
    failure: { type: ActionType.GET_DISPATCH_PAGE_FAILURE };
  }
>;

export type AnyAction = UnpackAsyncAction<GetLocationSearch>;
