import {
  invoiceLineItemGroupPositionIndexSort,
  invoiceLineItemPositionIndexSort,
} from '@payaca/helpers/invoiceHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import { InvoiceLine } from '@payaca/types/invoiceTypes';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import React, { FunctionComponent, useMemo } from 'react';
import DocumentCard from '../clientDocument/DocumentCard';
import InvoiceItem from '../clientDocument/InvoiceItem';

interface InvoiceLineWithJobLineItem extends InvoiceLine {
  jobLineItem?: JobLineItem;
}

type Props = {
  invoiceLines: InvoiceLine[];
  jobLineItems: JobLineItem[];
  region?: AccountRegions;
  hideItemPrices?: boolean;
  showZeroValueLines?: boolean;
  showCancelledLines?: boolean;
};

const InvoiceBody: FunctionComponent<React.PropsWithChildren<Props>> = ({
  invoiceLines,
  jobLineItems,
  region,
  hideItemPrices = false,
  showZeroValueLines = false,
  showCancelledLines = true,
  children,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const invoiceLinesWithJobLineItem: InvoiceLineWithJobLineItem[] =
    useMemo(() => {
      return invoiceLines
        .map((x) => {
          const jobLineItem = jobLineItems.find(
            (y) => y.id === x.jobLineItemId
          );
          return {
            ...x,
            jobLineItem: jobLineItem,
          };
        })
        .sort(invoiceLineItemPositionIndexSort)
        .sort(invoiceLineItemGroupPositionIndexSort);
    }, [invoiceLines, jobLineItems]);

  const cancelledLineIds = useMemo(() => {
    const acc = invoiceLinesWithJobLineItem.reduce(
      (
        acc: Record<
          JobLineItem['id'],
          {
            invoiceLines: InvoiceLineWithJobLineItem[];
            value: number;
          }
        >,
        invoiceLineWithJobLineItem
      ) => {
        const jobLineItemId =
          invoiceLineWithJobLineItem.jobLineItem?.amendmentParentId ||
          invoiceLineWithJobLineItem.jobLineItem?.id;

        if (!jobLineItemId) return acc;

        if (!(jobLineItemId in acc)) {
          acc[jobLineItemId] = {
            invoiceLines: [],
            value: 0,
          };
        }

        acc[jobLineItemId].invoiceLines.push(invoiceLineWithJobLineItem);
        acc[jobLineItemId].value += invoiceLineWithJobLineItem.value;

        return acc;
      },
      {}
    );

    return Object.values(acc).flatMap((x) => {
      if (x.value != 0) return [];
      if (x.invoiceLines.every((y) => y.value == 0)) return [];
      return x.invoiceLines.map((y) => y.id);
    });
  }, [invoiceLinesWithJobLineItem]);

  return (
    <DocumentCard avoidPrintBreak={false}>
      <div className="space-y-8">
        {invoiceLinesWithJobLineItem.map((invoiceLine) => {
          const jobLineItem = invoiceLine.jobLineItem;
          if (!jobLineItem) return null;

          if (
            invoiceLine.value === 0 &&
            jobLineItem?.total != 0 &&
            !showZeroValueLines
          ) {
            return null;
          }

          if (
            cancelledLineIds.includes(invoiceLine.id) &&
            !showCancelledLines
          ) {
            return null;
          }

          return (
            <InvoiceItem
              key={invoiceLine.id}
              item={jobLineItem}
              invoiceLine={invoiceLine}
              hideItemPrices={hideItemPrices}
              region={region}
            />
          );
        })}
      </div>
      {children}
    </DocumentCard>
  );
};

export default InvoiceBody;
