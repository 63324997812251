import useGetProjectTasks from '@/api/queries/project/useGetProjectTasks';
import TaskCard from '@/ui/pages/dealPage/components/TaskCard';
import AccordionWithChildCount, {
  IProps as IAccordionWithChildCountProps,
} from '@payaca/components/plAccordionWithChildCount/AccordionWithChildCount';
import { FC } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';

type Props = Pick<IAccordionWithChildCountProps, 'defaultOpen' | 'onToggle'>;

const TaskAccordion: FC<Props> = (props) => {
  const { onToggle, defaultOpen } = props;
  const { dealId } = useParams<{ dealId: string }>();
  const { url } = useRouteMatch();
  // Todo: query to not get all the task information? as we only need the count...
  const { tasks: completeTasks, data: completeTasksData } = useGetProjectTasks(
    +dealId,
    {
      completionStatuses: ['complete'],
      eventRelationshipStatuses: ['unlinked'],
    }
  );
  const { tasks: incompleteTasks, data: incompleteTasksData } =
    useGetProjectTasks(+dealId, {
      completionStatuses: ['to do', 'in progress'],
      sortBy: 'deadlineDate',
      sortDirection: 'asc',
      eventRelationshipStatuses: ['unlinked'],
    });

  return (
    <AccordionWithChildCount
      count={`${incompleteTasksData?.project.tasks.totalCount || 0}`}
      title="Project Tasks"
      titleTooltip="Incomplete Tasks not linked to Events"
      onToggle={onToggle}
      defaultOpen={defaultOpen}
    >
      {!incompleteTasks?.items.length && (
        <p className="mt-2">No project tasks to do.</p>
      )}
      {incompleteTasks?.items.map((task) => {
        return (
          <TaskCard key={task.id} to={`${url}/tasks/${task.id}`} {...task} />
        );
      })}
      {incompleteTasksData &&
        incompleteTasksData.project.tasks.totalCount >
          incompleteTasksData.project.tasks.items.length && (
          <div>
            <Link to={`/deals/${dealId}/tasks`}>
              +{' '}
              {incompleteTasksData.project.tasks.totalCount -
                incompleteTasksData.project.tasks.items.length}{' '}
              more task
              {incompleteTasksData.project.tasks.totalCount -
                incompleteTasksData.project.tasks.items.length ===
              1
                ? ''
                : 's'}
            </Link>
          </div>
        )}

      {incompleteTasksData &&
        completeTasksData &&
        incompleteTasksData.project.tasks.totalCount > 0 &&
        completeTasksData.project.tasks.totalCount > 0 && (
          <div className="mt-2">
            <Link to={`/deals/${dealId}/tasks`}>
              {completeTasksData?.project.tasks.totalCount} completed
            </Link>
          </div>
        )}
    </AccordionWithChildCount>
  );
};

export default TaskAccordion;
