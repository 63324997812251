import { Grow, Paper, Popper } from '@material-ui/core';
import * as d3 from 'd3';
import { FunctionComponent, ReactNode, useMemo, useRef, useState } from 'react';
import tinycolor from 'tinycolor2';
import './SplitBarVisualisation.sass';

type DataElement = {
  value: number;
  label: string;
  id: any;
  className?: string;
  colour?: string;
};

type Props = {
  data: DataElement[];
  formatValue?: (value: number) => string;
  renderPopperContent?: (dataElement: DataElement) => ReactNode;
};

const SplitBarVisualisation: FunctionComponent<Props> = ({
  data,
  formatValue,
  renderPopperContent,
}: Props): JSX.Element => {
  const accumulatedValue = useMemo(() => {
    return data
      .filter((x) => x.value > 0)
      .reduce((acc, dataElement) => acc + dataElement.value, 0);
  }, [data]);

  const xScale = useMemo(() => {
    return d3.scaleLinear().domain([0, accumulatedValue]).range([0, 100]);
  }, []);

  return (
    <figure className="split-bar-visualisation">
      {data.map((dataElement, i) => {
        return (
          <DataElementComponent
            dataElement={dataElement}
            formatValue={formatValue}
            renderPopperContent={renderPopperContent}
            index={i}
            key={dataElement.id}
            scale={xScale}
          />
        );
      })}
    </figure>
  );
};

type DataElementProps = {
  index: number;
  dataElement: DataElement;
  formatValue?: (value: number) => string;
  renderPopperContent?: (dataElement: DataElement) => ReactNode;
  scale: (value: number) => number;
};

const DataElementComponent: FunctionComponent<DataElementProps> = ({
  index,
  dataElement,
  formatValue,
  renderPopperContent,
  scale,
}: DataElementProps): JSX.Element | null => {
  const ref = useRef<HTMLDivElement>(null);
  const [showPopper, setShowPopper] = useState(false);

  if (dataElement.value <= 0) {
    return null;
  }

  const barColor = dataElement.colour || d3.schemeTableau10[index % 10];

  return (
    <div
      className={`${dataElement.className} data-element`}
      key={dataElement.id}
      style={{
        width: `${scale(dataElement.value)}%`,
      }}
    >
      <div className="label-outer-wrapper">
        <div className="label-wrapper">
          <span className="label">{dataElement.label}</span>
        </div>
      </div>
      <div
        className={`bar ${renderPopperContent ? 'hoverable' : ''}`}
        style={{
          backgroundColor: barColor,
        }}
        ref={ref}
        onMouseEnter={() => setShowPopper(true)}
        onMouseLeave={() => setShowPopper(false)}
      >
        <div className="value-wrapper">
          <span
            className="value"
            style={{
              color: tinycolor
                .mostReadable(barColor, ['#263e59', 'white'], {
                  includeFallbackColors: true,
                })
                .toHexString(),
            }}
          >
            {formatValue ? formatValue(dataElement.value) : dataElement.value}
          </span>
        </div>
      </div>

      {renderPopperContent && (
        <Popper
          open={showPopper && !!ref.current}
          anchorEl={ref.current}
          role={undefined}
          transition
          placement={'bottom'}
          className={`split-bar-popper`}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={350}>
              <Paper>
                <div>{renderPopperContent(dataElement)}</div>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </div>
  );
};

export default SplitBarVisualisation;
