import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { useSelector } from '@/api/state';
import { useTranslation } from '@/i18n';

import { getJobLineItem } from '@/utils/stateAccessors';

import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import EditJobLineItemControl from '../../components/viewEditItem/EditJobLineItemControl';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';

import { Deal } from '@payaca/types/dealTypes';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import { JobLineItemAttachment } from '@payaca/types/jobTypes';
import { Job } from '@payaca/types/jobTypesV2';

import { getJobType } from '@payaca/helpers/jobHelperV2';

import { useJob } from '@payaca/store/hooks/appState';

import './ItemPage.sass';

export type FormState = {
  jobLineItemId?: JobLineItem['id'];
  name?: JobLineItem['name'];
  description?: JobLineItem['description'];
  isMultipleChoice?: JobLineItem['isMultipleChoice'];
  isOptional?: JobLineItem['isOptional'];
  isSelected?: JobLineItem['isSelected'];
  quantity?: JobLineItem['quantity'];
  attachments?: JobLineItemAttachment[];
  lineItemId?: JobLineItem['lineItemId'];
  price?: JobLineItem['price'];
  cisApplies?: boolean;
  taxRateId?: JobLineItem['taxRateId'];
  discountPercentage?: JobLineItem['discountPercentage'];
  discountDescription?: JobLineItem['discountDescription'];
};
type Props = {
  jobLineItemId: number;
  jobId: Job['id'];
  dealId?: Deal['id'];
  isAlwaysRequired?: boolean;
};
const JobLineItemPage: FC<Props> = ({
  jobLineItemId,
  jobId,
  dealId,
  isAlwaysRequired = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isDeletingJobLineItem, setIsDeletingJobLineItem] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const job = useJob(jobId);

  useEffect(() => {
    if (jobLineItemId && !hasLoaded) {
      dispatch(
        jobContentActions.requestGetJobLineItem(jobLineItemId, () => {
          setHasLoaded(true);
        })
      );
      dispatch(
        jobContentActions.requestGetJobLineItemAttachmentsForJobLineItem(
          jobLineItemId
        )
      );
    }
  }, [jobLineItemId, hasLoaded]);

  const translate = useTranslation();

  const jobLineItem: JobLineItem | undefined = useSelector((state) =>
    getJobLineItem(state, jobLineItemId)
  );

  useEffect(() => {
    return () => {
      // leaving page - get updated job content
      if (jobLineItem?.jobContentId) {
        dispatch(
          jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
            jobLineItem.jobContentId
          )
        );
      }
    };
  }, []);

  const deleteJobLineItem = useCallback(() => {
    if (jobLineItemId) {
      setIsDeletingJobLineItem(true);
      dispatch(
        jobContentActions.requestDeleteJobLineItem(jobLineItemId, () => {
          setIsDeletingJobLineItem(false);
          history.goBack();
        })
      );
    }
  }, [jobLineItemId]);

  const jobType = useMemo(() => {
    if (!job) return;
    if (job.isProtoInvoice) return 'Invoice';
    return getJobType(job);
  }, [job]);

  return (
    <>
      <Helmet
        title={translate('pages.item.title', {
          reference: jobLineItem?.name || '',
        })}
      />
      <AuthenticatedPageWrapper
        className="job-line-item-page"
        title={
          <>
            <div className="flex-container flex-center flex-grow">
              <h1>Item you sell</h1>
            </div>
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={deleteJobLineItem}
              isProcessing={isDeletingJobLineItem}
              colourVariant={ButtonColourVariant.RED}
            >
              Remove Item
            </Button>
          </>
        }
        previousPageNavigationConfig={{
          navigationPromptName: jobType ? `Back to ${jobType}` : 'Back',
          route: location.pathname.split('/items')[0],
        }}
      >
        {!jobLineItem ? (
          <div className="loading-container">
            <MiniLoader />
          </div>
        ) : (
          <EditJobLineItemControl
            jobLineItemId={jobLineItemId}
            jobId={jobId}
            dealId={dealId}
            isAlwaysRequired={isAlwaysRequired}
          />
        )}
      </AuthenticatedPageWrapper>
    </>
  );
};
export default JobLineItemPage;
