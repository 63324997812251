import Badge from '@payaca/components/plBadge/Badge';
import { FC } from 'react';
import {
  FulfillmentOption,
  LiveMaterialSupplierBadgeProps,
} from './MaterialsPanel/LiveAvailabilityMaterialSupplierBadge/fulfillment';

export type MaterialSuppliersSuppplier = {
  name: string;
  id: string;
};
type Props = {
  suppliers: MaterialSuppliersSuppplier[];
  preferredFulfillment: FulfillmentOption[] | null;
  LiveAvailabilityMaterialSupplierBadge: React.FC<LiveMaterialSupplierBadgeProps>;
};

const MaterialSuppliersBadges: FC<Props> = (props) => {
  const {
    suppliers,
    preferredFulfillment,
    LiveAvailabilityMaterialSupplierBadge,
  } = props;

  const maxSuppliersToShow = 2;
  const suppliersToShow = suppliers.slice(0, maxSuppliersToShow);
  const remainingSuppliers = suppliersToShow.length - maxSuppliersToShow;

  return (
    <span className="inline-flex gap-x-1">
      {suppliersToShow.map((supplier) => {
        if (preferredFulfillment) {
          return (
            <LiveAvailabilityMaterialSupplierBadge
              key={supplier.id}
              supplier={supplier}
              fulfillmentOptions={preferredFulfillment}
            />
          );
        }
        return (
          <Badge key={supplier.id} variant="soft">
            {supplier.name}
          </Badge>
        );
      })}
      {remainingSuppliers > 0 && (
        <Badge variant="soft">+{remainingSuppliers}</Badge>
      )}
    </span>
  );
};

export default MaterialSuppliersBadges;
