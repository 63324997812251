import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as materialActions from '@payaca/store/materials/materialsActions';

import useGetMaterial from '@/api/queries/materials/useGetMaterial';
import useGetMaterialCategories from '@/api/queries/materials/useGetMaterialCategories';
import useGetMaterials from '@/api/queries/materials/useGetMaterials';
import { GetMaterialsInput } from '@/gql/graphql';
import { getMaterialFilterCategoryIdsLocalStorageKey } from '@/helpers/localStorageKeyHelper';
import FieldLabel, {
  LabelStyleVariant,
} from '@payaca/components/fieldLabel/FieldLabel';
import InputFilterPrefix from '@payaca/components/inputFilterPrefix/InputFilterPrefix';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Combobox from '@payaca/components/plCombobox/Combobox';
import Input from '@payaca/components/plInput/Input';
import {
  SelectOption,
  SelectSizeVariant,
} from '@payaca/components/plSelect/Select';
import UntitledIcon from '@payaca/untitled-icons';
import CreateMaterialModal from '../createEditMaterialModal/CreateMaterialModal';
import './MaterialSelectionControl.sass';

const materialFilterCategoryIdsLocalStorageKey =
  getMaterialFilterCategoryIdsLocalStorageKey();

type ListedMaterial = {
  id: number;
  name: string;
};
interface Props {
  label?: string;
  labelStyleVariant?: LabelStyleVariant;
  selectedMaterialId?: number;
  onChange: (selectedMaterialId?: number | null) => void;
  enableMaterialCreation?: boolean;
  styleVariant?: InputStyleVariant;
  placeholder?: string;
  enableCategoryFiltering?: boolean;
  canChangeMaterial?: boolean;
}

const MaterialSelectionControl: FC<Props> = ({
  label,
  labelStyleVariant,
  selectedMaterialId,
  onChange,
  enableMaterialCreation = false,
  enableCategoryFiltering = true,
  canChangeMaterial,
}: Props): JSX.Element => {
  const [showCreateMaterialModal, setShowCreateMaterialModal] = useState(false);

  const dispatch = useDispatch();

  const storedCategoryIds = localStorage.getItem(
    materialFilterCategoryIdsLocalStorageKey
  );

  const { material: selectedMaterial } = useGetMaterial(selectedMaterialId);

  const [getMaterialsInput, setGetMaterialsInput] = useState<GetMaterialsInput>(
    {
      categories: (storedCategoryIds?.length
        ? storedCategoryIds.split(',').map((x) => parseInt(x))
        : []
      ).map((x) => x.toString()),
      searchTerm: undefined,
    }
  );

  const { data: materials, refetch: refetchMaterials } = useGetMaterials(
    {
      offset: 0,
      limit: 20,
    },
    getMaterialsInput
  );

  const { materialCategories } = useGetMaterialCategories();

  const materialSelectOptions = useMemo(() => {
    const o: SelectOption<number, ListedMaterial>[] =
      materials?.materials.items.map((m) => ({
        value: +m.internalId,
        label: m.name,
        groupId: 'materials',
      })) || [];
    if (enableMaterialCreation) {
      o.push({
        groupId: 'other',
        value: -1,
        label: 'Create a new Material',
      });
    }
    return o;
  }, [materials, enableMaterialCreation]);

  const supplierSelectOptionGroups = [
    { label: 'Materials', id: 'materials' },
    ...(enableMaterialCreation
      ? [
          {
            label: 'Other',
            id: 'other',
          },
        ]
      : []),
  ];

  const materialCategoryOptions: { value: any; label: string }[] =
    useMemo(() => {
      const options =
        materialCategories?.map((x) => {
          return {
            label: x.name,
            value: x.id,
          };
        }) || [];
      return options;
    }, [materialCategories]);

  const handleCreateMaterial = useCallback(
    (materialName: string) => {
      dispatch(
        materialActions.requestPersistMaterial(
          { name: materialName },
          (materialId: number) => {
            onChange(materialId);
          }
        )
      );
    },
    [onChange]
  );

  return (
    <>
      {label && <FieldLabel label={label} styleVariant={labelStyleVariant} />}
      {canChangeMaterial ? (
        selectedMaterial ? (
          <div className="flex space-2 items-center">
            <Input value={selectedMaterial?.name} className="grow" disabled />
            <Button
              onClick={() => onChange(null)}
              variant={EBtnVariant.Link}
              className="p-0 ml-3"
            >
              <UntitledIcon name="x-close" className="h-4 w-4" />
            </Button>
          </div>
        ) : (
          <div
            className={`material-selection-inputs-wrapper${
              enableCategoryFiltering ? ' category-filtering' : ''
            }`}
          >
            {enableCategoryFiltering && (
              <InputFilterPrefix
                filterGroups={{
                  categoryIds: {
                    label: 'Categories',
                    options: materialCategoryOptions,
                  },
                }}
                filterValues={{
                  categoryIds: getMaterialsInput.categories || [],
                }}
                onChange={(value) => {
                  setGetMaterialsInput((x) => ({
                    ...x,
                    categories: value.categoryIds,
                  }));
                }}
              />
            )}

            <Combobox
              value={selectedMaterialId}
              options={materialSelectOptions}
              optionGroups={supplierSelectOptionGroups}
              filterFunction={null}
              query={getMaterialsInput.searchTerm || ''}
              setQuery={(value) =>
                setGetMaterialsInput((x) => ({ ...x, searchTerm: value }))
              }
              placeholder="Start typing to search for a Material..."
              inputPlaceholder="Start typing to search for a Material..."
              onOptionClick={(_e, option) => {
                if (option?.value === -1 && enableMaterialCreation) {
                  if (getMaterialsInput.searchTerm) {
                    handleCreateMaterial(getMaterialsInput.searchTerm);
                  } else {
                    setShowCreateMaterialModal(true);
                  }
                } else {
                  onChange(option?.value);
                  setGetMaterialsInput((x) => ({
                    ...x,
                    searchTerm: undefined,
                  }));
                }
              }}
              className="rounded-l-none"
              sizeVariant={SelectSizeVariant.SM}
            />
          </div>
        )
      ) : (
        <Input value={selectedMaterial?.name} disabled />
      )}

      <CreateMaterialModal
        isOpen={showCreateMaterialModal}
        onClose={() => setShowCreateMaterialModal(false)}
        onPersistMaterialSuccess={(material) => {
          void refetchMaterials();
          onChange(+material.internalId);
        }}
      />
    </>
  );
};

export default MaterialSelectionControl;
