import { useTranslation } from '@/i18n';

import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { useSelector } from '@/api/state';
import AutomationsTable from '@/ui/components/automationsTable/AutomationsTable';
import CustomAutomations from '@/ui/components/customAutomations/CustomAutomations';
import DefaultAutomations from '@/ui/components/defaultAutomations/DefaultAutomations';
import { getUserRoles } from '@/utils/stateAccessors';
import Button from '@payaca/components/button/Button';
import { ButtonColourVariant } from '@payaca/components/button/enums';
import DataIndicatorCard from '@payaca/components/dataIndicatorCard/DataIndicatorCard';
import { AutomationsPermissions } from '@payaca/permissions/automations/automations.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { getKeyIndicatorsData } from '@payaca/store/analytics/analyticsActions';
import { requestGetAccountPipelines } from '@payaca/store/pipelines/pipelineActions';
import {
  KeyIndicatorData,
  KeyIndicatorType,
} from '@payaca/types/analyticsTypes';
import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';
import {
  AutomationEntityTypes,
  AutomationTriggerEvents,
} from '@payaca/types/automationTypes';
import moment from 'moment-timezone';
import QueryString from 'qs';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import TabbedContentPageWrapper from '../pageWrappers/tabbedContentPageWrapper/TabbedContentPageWrapper';
import './AutomationsPage.sass';

type AutomationCategory = 'default' | 'custom';

const AutomationsPage: FC = () => {
  const translate = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { tab } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [selectedCategory, setSelectedCategory] = useState<AutomationCategory>(
    tab == 'custom' ? 'custom' : 'default'
  );

  useEffect(() => {
    dispatch(requestGetAccountPipelines());
  }, []);

  const userRoles = useSelector(getUserRoles);

  const accountHasCustomAutomations = userHasRequiredPermission(userRoles, [
    AutomationsPermissions.EDIT_AUTOMATION,
  ]);

  return (
    <>
      <Helmet title={translate('pages.automations.title')} />
      <TabbedContentPageWrapper
        className="automations-page"
        title={
          <div className="w-full">
            <h1>Automations</h1>
            <div className="mt-4">
              <AutomationKeyIndicatorsData />
            </div>
          </div>
        }
        tabbedContentConfig={{
          tabs: [
            {
              label: 'default',
              content: <DefaultAutomations />,
              title: 'Templates',
            },
            {
              label: 'custom',
              content: accountHasCustomAutomations ? (
                <CustomAutomations />
              ) : (
                <CustomAutomationsTeaser />
              ),
              title: accountHasCustomAutomations ? 'Custom' : 'Create your own',
            },
          ],
          activeTabLabel: selectedCategory,
          onChangeTab: (label) => {
            history.push(`/automations?tab=${label}`);
            setSelectedCategory(label as AutomationCategory);
          },
        }}
      />
    </>
  );
};

export default AutomationsPage;

const CustomAutomationsTeaser: FC = () => {
  const history = useHistory();

  return (
    <>
      <div className="custom-automations-teaser">
        <div className="custom-automations-teaser__body">
          <AutomationsTable
            automations={[
              {
                id: 1,
                title: 'Survey event reminder for customer (day of)',
                entityType: AutomationEntityTypes.EVENT,
                triggerEvent: AutomationTriggerEvents.STARTS_TODAY,
                conditions: [],
                actions: [{ title: 'Email customer' }],
                isActive: true,
              },
              {
                id: 2,
                title: 'Ready to invoice',
                entityType: AutomationEntityTypes.DEAL,
                triggerEvent: AutomationTriggerEvents.TAGGED,
                conditions: [
                  {
                    field: AutomationDataAccessType.TRIGGER_TAGTEXT_ARRAY,
                    operator: 'equals',
                    value: 'Ready to invoice',
                    isActive: true,
                  },
                ],
                actions: [{ title: 'Send SMS to customer' }],
                isActive: true,
              },
              {
                id: 3,
                title: 'Booking reminder',
                entityType: AutomationEntityTypes.EVENT,
                triggerEvent: AutomationTriggerEvents.STARTS_IN_1_DAY,
                conditions: [],
                actions: [{ title: 'Email customer' }],
                isActive: true,
              },
              {
                id: 4,
                title: 'Project progression',
                entityType: AutomationEntityTypes.DEAL,
                triggerEvent: AutomationTriggerEvents.TAGGED,
                conditions: [
                  {
                    field: AutomationDataAccessType.DEAL_PIPELINESTAGE,
                    operator: 'equals',
                    value: 'Proposal accepted',
                    isActive: true,
                  },
                  {
                    field: AutomationDataAccessType.TRIGGER_TAGTEXT_ARRAY,
                    operator: 'equals',
                    value: 'Work started',
                    isActive: true,
                  },
                ],
                actions: [{ title: 'Progress Project to "Work in progress"' }],
                isActive: true,
              },
              // {
              //   id: 5,
              //   title: 'Order parts',
              //   entityType: AutomationEntityTypes.DEAL,
              //   triggerEvent:
              //     AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED,
              //   conditions: [
              //     {
              //       field: AutomationDataAccessType.DEAL_PIPELINESTAGE,
              //       operator: 'equals',
              //       value: 'Proposal accepted',
              //       isActive: true,
              //     },
              //   ],
              //   actions: [{ title: 'Internal reminder email' }],
              //   isActive: true,
              // },
              // {
              //   id: 6,
              //   title: 'Customer progress update',
              //   entityType: AutomationEntityTypes.DEAL,
              //   triggerEvent: AutomationTriggerEvents.TAGGED,
              //   conditions: [
              //     {
              //       field: AutomationDataAccessType.TRIGGER_TAGTEXT_ARRAY,
              //       operator: 'equals',
              //       value: 'Work started',
              //       isActive: true,
              //     },
              //   ],
              //   actions: [{ title: 'Email customer' }],
              //   isActive: true,
              // },
            ]}
          />

          <div className="custom-automations-teaser__prompt-outer">
            <div className="custom-automations-teaser__prompt">
              <p>Want to save more time and increase customer satisfaction?</p>
              <p>Explore our Business plan for enhanced automations.</p>

              <Button
                colourVariant={ButtonColourVariant.PRIMARY}
                className="custom-automations-teaser__button"
                onClick={() =>
                  history.push('/upgradeAccount?view=select-subscription')
                }
              >
                Explore options
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AutomationKeyIndicatorsData: FC = () => {
  const dispatch = useDispatch();

  const [keyIndicatorsData, setKeyIndicatorsData] = useState<
    KeyIndicatorData<KeyIndicatorType>[]
  >([]);

  const userRoles = useSelector(getUserRoles);

  const accountHasCustomAutomations = userHasRequiredPermission(userRoles, [
    AutomationsPermissions.EDIT_AUTOMATION,
  ]);

  useEffect(() => {
    dispatch(
      getKeyIndicatorsData.request({
        indicatorTypes: [
          KeyIndicatorType.AUTOMATION_SMS_NOTIFICATIONS,
          KeyIndicatorType.AUTOMATION_EMAIL_NOTIFICATIONS,
          KeyIndicatorType.AUTOMATION_DEAL_PROGRESSION,
        ],
        callback: setKeyIndicatorsData,
      })
    );
  }, []);

  const smsNotificationIndicatorData = keyIndicatorsData?.find(
    (x) => x.type == KeyIndicatorType.AUTOMATION_SMS_NOTIFICATIONS
  );

  const emailNotificationIndicatorData = keyIndicatorsData?.find(
    (x) => x.type == KeyIndicatorType.AUTOMATION_EMAIL_NOTIFICATIONS
  );

  const dealProgressionIndicatorData = keyIndicatorsData?.find(
    (x) => x.type == KeyIndicatorType.AUTOMATION_DEAL_PROGRESSION
  );

  const indicatorCardWrapperClasses =
    'grow basis-0 max-w-[35rem] min-w-[25rem]';

  return (
    <div className="flex flex-row flex-wrap gap-8 w-full">
      {dealProgressionIndicatorData && accountHasCustomAutomations && (
        <div className={indicatorCardWrapperClasses}>
          <DataIndicatorCard
            title="Pipeline automations"
            iconName="dataflow-03"
            periodLengthInDays={dealProgressionIndicatorData.periodLengthInDays}
            percentageChange={dealProgressionIndicatorData.percentageChange}
            sparkLineData={dealProgressionIndicatorData.sparkLineData}
            currentPeriod={dealProgressionIndicatorData.currentPeriod}
            CustomLabelledValue={({ value }) => {
              return (
                <>
                  <DataIndicatorCard.LabelledValue>
                    <DataIndicatorCard.ValueLabel>
                      Progressed
                    </DataIndicatorCard.ValueLabel>
                    <DataIndicatorCard.Value>{value}</DataIndicatorCard.Value>
                  </DataIndicatorCard.LabelledValue>
                  <TimeSavedLabelledValue timeSavedInMinutes={value * 3} />
                </>
              );
            }}
          />
        </div>
      )}
      {emailNotificationIndicatorData && (
        <div className={indicatorCardWrapperClasses}>
          <DataIndicatorCard
            title="Email automations"
            iconName="mail-05.3"
            periodLengthInDays={
              emailNotificationIndicatorData.periodLengthInDays
            }
            percentageChange={emailNotificationIndicatorData.percentageChange}
            sparkLineData={emailNotificationIndicatorData.sparkLineData}
            currentPeriod={emailNotificationIndicatorData.currentPeriod}
            CustomLabelledValue={({ value }) => {
              return (
                <>
                  <DataIndicatorCard.LabelledValue>
                    <DataIndicatorCard.ValueLabel>
                      Sent
                    </DataIndicatorCard.ValueLabel>
                    <DataIndicatorCard.Value>{value}</DataIndicatorCard.Value>
                  </DataIndicatorCard.LabelledValue>
                  <TimeSavedLabelledValue timeSavedInMinutes={value * 10} />
                </>
              );
            }}
          />
        </div>
      )}
      {smsNotificationIndicatorData && (
        <div className={indicatorCardWrapperClasses}>
          <DataIndicatorCard
            title="SMS automations"
            iconName="notification-message"
            periodLengthInDays={smsNotificationIndicatorData.periodLengthInDays}
            percentageChange={smsNotificationIndicatorData.percentageChange}
            sparkLineData={smsNotificationIndicatorData.sparkLineData}
            currentPeriod={smsNotificationIndicatorData.currentPeriod}
            CustomLabelledValue={({ value }) => {
              return (
                <>
                  <DataIndicatorCard.LabelledValue>
                    <DataIndicatorCard.ValueLabel>
                      Sent
                    </DataIndicatorCard.ValueLabel>
                    <DataIndicatorCard.Value>{value}</DataIndicatorCard.Value>
                  </DataIndicatorCard.LabelledValue>
                  <TimeSavedLabelledValue timeSavedInMinutes={value * 5} />
                </>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

const TimeSavedLabelledValue = ({
  timeSavedInMinutes,
}: {
  timeSavedInMinutes: number;
}) => {
  const duration = moment.duration(timeSavedInMinutes, 'minutes');

  const hours = duration.hours();
  const minutes = duration.minutes();

  if (!timeSavedInMinutes) return null;

  return (
    <DataIndicatorCard.LabelledValue>
      <DataIndicatorCard.ValueLabel>Time saved</DataIndicatorCard.ValueLabel>
      <DataIndicatorCard.Value>
        {!!hours && (
          <>
            {hours}
            <span className="text-base font-extralight"> hrs</span>{' '}
          </>
        )}{' '}
        {!!minutes && (
          <>
            {minutes}
            <span className="text-base font-extralight"> mins</span>{' '}
          </>
        )}{' '}
      </DataIndicatorCard.Value>
    </DataIndicatorCard.LabelledValue>
  );
};
