import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  CreateCustomFieldGroupMutation as _CreateCustomFieldGroupMutation,
  CreateCustomFieldGroupInput,
} from '@/gql/graphql';

const CreateCustomFieldGroupMutation = graphql(`
  mutation CreateCustomFieldGroup($input: CreateCustomFieldGroupInput!) {
    createCustomFieldGroup(input: $input) {
      role
      id
      customFields {
        archivedAt
        id
        identifier
        isRequired
        label
        type
        options
      }
    }
  }
`);

const useCreateCustomFieldGroup = (
  options?: UseMutationOptions<
    _CreateCustomFieldGroupMutation,
    unknown,
    CreateCustomFieldGroupInput
  >
) => {
  return useMutation({
    mutationFn: (variables) => {
      return gqlClient.request(CreateCustomFieldGroupMutation, {
        input: variables,
      });
    },
    ...options,
  });
};

export default useCreateCustomFieldGroup;
