import { FC, PropsWithChildren, useState } from 'react';

import Modal from '@payaca/components/plModal/Modal';
import { Deal } from '@payaca/types/dealTypes';
import CreatePurchaseOrderControl, {
  FormSection,
} from '../createPurchaseOrderControl/CreatePurchaseOrderControl';
import './CreatePurchaseOrderModal.sass';

type Props = {
  materialsListId: number;
  isOpen: boolean;
  onCreatePurchaseOrderSuccess?: (purchaseOrderId: number) => void;
  onClose?: () => void;
  projectId?: Deal['id'];
};
const CreatePurchaseOrderModal: FC<Props> = ({
  projectId,
  materialsListId,
  isOpen,
  onCreatePurchaseOrderSuccess,
  onClose,
}: PropsWithChildren<Props>): JSX.Element => {
  const [formSection, setFormSection] = useState(
    FormSection.SUPPLIER_SELECTION
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        formSection === FormSection.SUPPLIER_SELECTION
          ? 'Create a Purchase Order'
          : 'Add Materials to the Purchase Order'
      }
      className="create-purchase-order-modal"
    >
      <CreatePurchaseOrderControl
        projectId={projectId}
        materialsListId={materialsListId}
        onCreatePurchaseOrderSuccess={onCreatePurchaseOrderSuccess}
        onProgressFormSection={setFormSection}
      />
    </Modal>
  );
};

export default CreatePurchaseOrderModal;
