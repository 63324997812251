import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { PropsWithChildren } from 'react';

import './SortableItem.sass';

interface Props {
  id: UniqueIdentifier;
  isDisabled?: boolean;
}

const SortableItem = ({
  children,
  id,
  isDisabled,
}: PropsWithChildren<Props>) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id, disabled: isDisabled });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={itemStyle}
      className={`sortable-item${isDragging ? ' dragging' : ''}`}
    >
      {children}
    </div>
  );
};

export default SortableItem;
