import useGetConnectionApplication from '@/api/queries/connectionApplication/useGetConnectionApplication';
import { FormAndJSONSchema } from '@/api/rest/connectionApplication/useGetConnectionApplicationSchema';
import ConnectionApplicationLayout from '@/ui/pages/connectionApplicationPage/components/ConnectionApplicationLayout';
import DevicesFieldArrayReadOnly from '@/ui/pages/connectionApplicationPage/components/DevicesFieldArrayReadOnly';
import ProjectFileSelectionReadonly from '@/ui/pages/connectionApplicationPage/components/ProjectFileSelectionReadonly';
import FieldSet from '@/ui/pages/connectionApplicationPage/FieldSet';
import { DevicesFieldArrayKeys } from '@/ui/pages/connectionApplicationPage/utils';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import { sentenceCase } from '@payaca/utilities/stringUtilities';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router';

export interface IProps {
  jsonSchema: any;
  formSchema: FormAndJSONSchema;
  subSections: [string, any][];
}

const ConnectionApplicationReadonly: FC<IProps> = (props) => {
  const { jsonSchema, formSchema, subSections } = props;

  /**
   * Router
   */
  const { connectionApplicationId } = useParams<{
    projectId: string;
    connectionApplicationId: string;
  }>();

  /**
   * Queries
   */
  const { data: persistedFormStateData } = useGetConnectionApplication(
    connectionApplicationId,
    false
  );

  const scrollSections = subSections.map(([key]) => {
    const title = sentenceCase(formSchema.properties[key].title);
    return {
      label: title,
      slug: key,
      render: () => {
        return (
          <Card key={key} sizeVariant={CardSizeVariant.SM}>
            <Card.Body>
              <div className="space-y-4">
                <Card.Title>{title}</Card.Title>
                <p>
                  <ReactMarkdown>
                    {formSchema.properties[key].subtitle}
                  </ReactMarkdown>
                </p>
                {formSchema.properties[key].properties && (
                  <FieldSet
                    data={formSchema.properties[key]}
                    namespace={key}
                    readonly
                    definitions={formSchema.definitions}
                  />
                )}

                {DevicesFieldArrayKeys.includes(key) && (
                  <DevicesFieldArrayReadOnly
                    schemaSection={formSchema.properties[key]}
                    fieldKey={key}
                    definitions={formSchema.definitions}
                  />
                )}

                {formSchema.properties[key].items?.title === 'AttachmentV1' && (
                  <ProjectFileSelectionReadonly
                    name={key}
                    formSection={formSchema.properties[key]}
                  />
                )}
              </div>
            </Card.Body>
          </Card>
        );
      },
    };
  });

  return (
    <ConnectionApplicationLayout
      sections={scrollSections}
      status={persistedFormStateData?.connectionApplication.status}
      connectionApplicationStatus={
        persistedFormStateData?.connectionApplication
          .connectionApplicationStatus
      }
    />
  );
};

export default ConnectionApplicationReadonly;
