import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { SectionRadioButtons } from '@/ui/components';

import { useSelector } from '@/api/state';
import { faPercent, faTimes } from '@fortawesome/free-solid-svg-icons';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CurrencyField from '@payaca/components/currencyField/CurrencyField';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import Modal from '@payaca/components/modal/Modal';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import {
  getIsRequiredIfTrueConditionValidator,
  getNumericalRangeFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import './SelectValueModal.css';

const ValueType = {
  AMOUNT: 'amount',
  FULL_AMOUNT: 'fullAmount',
  PERCENTAGE: 'percentage',
};

type Props = {
  onChange: (value: any) => void;
  fieldNamePrefix?: string;
  onClose: any;
  modalOptions: any;
  valueAmount: any;
  valuePercentage: any;
  open: boolean;
};
const SelectValueModal: FC<Props> = ({
  onChange,
  fieldNamePrefix,
  onClose,
  modalOptions,
  valueAmount,
  valuePercentage,
  open,
}: Props): JSX.Element => {
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const [items, setItems] = useState<any[]>([]);
  const [amountName, setAmountName] = useState('');
  const [percentageName, setPercentageName] = useState('');
  const [modalError, setModalError] = useState<string | null>(null);

  useEffect(() => {
    const {
      disallowAmount,
      disallowFullAmount,
      valueAmountName,
      valuePercentageName,
    } = modalOptions || {};

    const items = [{ label: '%', value: ValueType.PERCENTAGE }];
    if (!disallowAmount) {
      items.splice(0, 0, {
        label: getRegionalTextString(
          account.region,
          RegionalStrings.CURRENCY_SYMBOL
        ),
        value: ValueType.AMOUNT,
      });
    }
    if (!disallowFullAmount) {
      items.push({ label: 'Full', value: ValueType.FULL_AMOUNT });
    }

    setItems(items);
    setAmountName(
      fieldNamePrefix
        ? `${fieldNamePrefix}.${valueAmountName}`
        : valueAmountName
    );
    setPercentageName(
      fieldNamePrefix
        ? `${fieldNamePrefix}.${valuePercentageName}`
        : valuePercentageName
    );
  }, [account, modalOptions]);

  const saveValues = useCallback(
    (formState: { [key: string]: any }, remove?: boolean) => {
      setModalError(null);

      onChange({
        [percentageName]: remove ? null : formState.percentage,
        [amountName]: remove ? null : formState.amount,
      });
      onClose();
    },
    [onClose, onChange, modalOptions, amountName, percentageName]
  );

  const initialFormState = useMemo(() => {
    let valueType = ValueType.AMOUNT;
    if (!!valueAmount && (valuePercentage === null || valuePercentage === 0)) {
      valueType = ValueType.AMOUNT;
    } else if (valuePercentage && valuePercentage === 100) {
      valueType = ValueType.FULL_AMOUNT;
    } else {
      valueType = ValueType.PERCENTAGE;
    }

    return {
      amount: valueType === ValueType.AMOUNT ? valueAmount || 0 : null,
      percentage: valueType !== ValueType.AMOUNT ? valuePercentage || 0 : null,
      valueType: valueType,
    };
  }, [valueAmount, valuePercentage]);

  const fieldValidators = useMemo(() => {
    return {
      percentage: [
        getIsRequiredIfTrueConditionValidator(
          (formState: any) =>
            formState.valueType === ValueType.PERCENTAGE ||
            formState.valueType === ValueType.FULL_AMOUNT
        ),
        getNumericalRangeFieldValidator(
          -0.1,
          undefined,
          undefined,
          'Percentage must be greater than 0'
        ),
      ],
      amount: [
        getIsRequiredIfTrueConditionValidator(
          (formState: any) => formState.valueType === ValueType.AMOUNT
        ),
        getNumericalRangeFieldValidator(
          -0.1,
          undefined,
          undefined,
          'Amount must not be less than £0'
        ),
      ],
    };
  }, []);

  return (
    <Modal
      isOpen={open}
      title={modalOptions.title}
      onClose={() => onClose()}
      size="xs"
      className="select-value-modal"
    >
      {modalOptions.infoText && (
        <div className="info-text">{modalOptions.infoText}</div>
      )}
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        fieldValidators={fieldValidators}
        renderFormContents={(
          isValid: boolean,
          formState: {
            [key: string]: any;
          },
          validationState: {
            [key: string]: FieldValidationResult;
          },
          touchedState: {
            [key: string]: boolean;
          },
          onFieldChange: (value: { [key: string]: any }) => void,
          onFieldTouch: (fieldName: string) => void
        ) => {
          return (
            <div>
              <div className="form-contents">
                <div className="input-field-container">
                  {formState.valueType === ValueType.AMOUNT && (
                    <ValidatedFieldWrapper
                      isTouched={true}
                      validationResult={validationState.amount}
                    >
                      <CurrencyField
                        styleVariant={InputStyleVariant.OUTSIZE}
                        value={formState.amount}
                        name={'amount'}
                        onChange={onFieldChange}
                      />
                    </ValidatedFieldWrapper>
                  )}
                  {formState.valueType === ValueType.PERCENTAGE && (
                    <ValidatedFieldWrapper
                      isTouched={true}
                      validationResult={validationState.percentage}
                    >
                      <BasicField
                        styleVariant={InputStyleVariant.OUTSIZE}
                        value={formState.percentage}
                        name={'percentage'}
                        onChange={(value: { [key: string]: any }) => {
                          onFieldChange({
                            percentage: value.percentage
                              ? Math.round(value.percentage * 100) / 100
                              : value.percentage,
                          });
                        }}
                        type="number"
                        additionalInputProps={{
                          pattern: '[0-9]*',
                          min: 0,
                          max: 100,
                          step: 1,
                        }}
                        iconAfter={faPercent}
                      />
                    </ValidatedFieldWrapper>
                  )}
                </div>
                <div>
                  {items.length > 1 && (
                    <SectionRadioButtons
                      value={formState.valueType}
                      items={items}
                      onChange={(value: any) => {
                        let percentage = null;
                        let amount = null;

                        if (value === ValueType.AMOUNT) {
                          amount = 0;
                        } else if (value === ValueType.PERCENTAGE) {
                          percentage = 0;
                        } else if (value === ValueType.FULL_AMOUNT) {
                          percentage = 100;
                        }

                        onFieldChange({
                          percentage: percentage,
                          amount: amount,
                          valueType: value,
                        });
                      }}
                    />
                  )}
                </div>
                {modalError && <ErrorMessage message={modalError} />}
              </div>
              <div className="actions-container">
                <Button
                  isDisabled={!isValid}
                  onClick={() => saveValues(formState)}
                  styleVariant={ButtonStyleVariant.OUTSIZE}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  onClick={() => saveValues(formState, true)}
                  styleVariant={ButtonStyleVariant.ANCHOR}
                  iconAfter={faTimes}
                >
                  {modalOptions.removeButtonTitle}
                </Button>
              </div>
            </div>
          );
        }}
      />
    </Modal>
  );
};

export default SelectValueModal;
