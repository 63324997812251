import { PublicEntityTemplate } from '@payaca/types/entity-templates';
import {
  CreateServicePlanCustomerReminderTemplateInput,
  CreateServicePlanDiscountCodeInput,
  ListedServicePlan,
  PublicHydratedServicePlan,
  PublicHydratedServicePlanCommitment,
  PublicHydratedServicePlanCustomerReminderTemplate,
  PublicHydratedServicePlanDiscountCode,
  PublicHydratedServicePlanPeriod,
  PublicHydratedServicePlanSubscription,
  PublicServicePlan,
  PublicServicePlanPeriod,
  PublicServicePlanPeriodCommitment,
  PublicServicePlanPrice,
  ServicePlan,
  ServicePlanCustomerReminderTemplate,
  ServicePlanDiscountCode,
  ServicePlanPeriod,
  ServicePlanPeriodCommitment,
  ServicePlanPrice,
  ServicePlansAnalyticsData,
  ToggleServicePlanCustomerReminderTemplateInput,
  UpdateServicePlanCustomerReminderTemplateInput,
} from '@payaca/types/service-plans';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { Nullish } from '@payaca/utilities/types';
import { DeclareAsyncAction, UnpackAsyncAction } from '../types';

export interface State {
  servicePlans: {
    [key: string]: FetchedEntity<PublicHydratedServicePlan>;
  };
  servicePlanSubscriptions: {
    [key: string]: FetchedEntity<PublicHydratedServicePlanSubscription>;
  };
  servicePlanPeriods: {
    [key: string]: FetchedEntity<PublicHydratedServicePlanPeriod>;
  };

  isGettingListedDiscountCodes: boolean;
  listedDiscountCodes: PublicHydratedServicePlanDiscountCode[];

  isGettingListedServicePlans: boolean;
  listedServicePlans: ListedServicePlan[];

  isGettingListedServicePlanCustomerReminders: boolean;
  listedServicePlanCustomerReminders: PublicHydratedServicePlanCustomerReminderTemplate[];

  listedServicePlanPeriods: PublicHydratedServicePlanPeriod[];

  isGettingServicePlansAnalytics: boolean;

  isGettingServicePeriods: boolean;
}

export enum ActionType {
  GET_LISTED_DISCOUNT_CODES_REQUEST = 'servicePlans.getListedServicePlansDiscountCodes:request',
  GET_LISTED_DISCOUNT_CODES_SUCCESS = 'servicePlans.getListedServicePlansDiscountCodes:success',
  GET_LISTED_DISCOUNT_CODES_FAILURE = 'servicePlans.getListedServicePlansDiscountCodes:failure',

  GET_LISTED_SERVICE_PLANS_REQUEST = 'servicePlans.getListedServicePlans:request',
  GET_LISTED_SERVICE_PLANS_SUCCESS = 'servicePlans.getListedServicePlans:success',
  GET_LISTED_SERVICE_PLANS_FAILURE = 'servicePlans.getListedServicePlans:failure',

  GET_SERVICE_PERIODS_REQUEST = 'servicePlans.getServicePeriods:request',
  GET_SERVICE_PERIODS_SUCCESS = 'servicePlans.getServicePeriods:success',
  GET_SERVICE_PERIODS_FAILURE = 'servicePlans.getServicePeriods:failure',

  GET_SERVICE_PLANS_ANALYTICS_REQUEST = 'servicePlans.getServicePlansAnalytics:request',
  GET_SERVICE_PLANS_ANALYTICS_SUCCESS = 'servicePlans.getServicePlansAnalytics:success',
  GET_SERVICE_PLANS_ANALYTICS_FAILURE = 'servicePlans.getServicePlansAnalytics:failure',

  GET_SERVICE_PLAN_REQUEST = 'servicePlans.getServicePlan:request',
  GET_SERVICE_PLAN_SUCCESS = 'servicePlans.getServicePlan:success',
  GET_SERVICE_PLAN_FAILURE = 'servicePlans.getServicePlan:failure',

  CREATE_SERVICE_PLAN_REQUEST = 'servicePlans.createServicePlan:request',
  CREATE_SERVICE_PLAN_SUCCESS = 'servicePlans.createServicePlan:success',
  CREATE_SERVICE_PLAN_FAILURE = 'servicePlans.createServicePlan:failure',

  UPDATE_SERVICE_PLAN_REQUEST = 'servicePlans.updateServicePlan:request',
  UPDATE_SERVICE_PLAN_SUCCESS = 'servicePlans.updateServicePlan:success',
  UPDATE_SERVICE_PLAN_FAILURE = 'servicePlans.updateServicePlan:failure',

  DELETE_SERVICE_PLAN_REQUEST = 'servicePlans.deleteServicePlan:request',
  DELETE_SERVICE_PLAN_SUCCESS = 'servicePlans.deleteServicePlan:success',
  DELETE_SERVICE_PLAN_FAILURE = 'servicePlans.deleteServicePlan:failure',

  DELETE_SERVICE_PLAN_DISCOUNT_CODE_REQUEST = 'servicePlans.deleteServicePlanDiscountCode:request',
  DELETE_SERVICE_PLAN_DISCOUNT_CODE_SUCCESS = 'servicePlans.deleteServicePlanDiscountCode:success',
  DELETE_SERVICE_PLAN_DISCOUNT_CODE_FAILURE = 'servicePlans.deleteServicePlanDiscountCode:failure',

  GET_SERVICE_PLAN_DISCOUNT_CODES_REQUEST = 'servicePlans.getServicePlanDiscountCodes:request',
  GET_SERVICE_PLAN_DISCOUNT_CODES_SUCCESS = 'servicePlans.getServicePlanDiscountCodes:success',
  GET_SERVICE_PLAN_DISCOUNT_CODES_FAILURE = 'servicePlans.getServicePlanDiscountCodes:failure',

  CREATE_DISCOUNT_CODE_REQUEST = 'servicePlans.createDiscountCode:request',
  CREATE_DISCOUNT_CODE_SUCCESS = 'servicePlans.createDiscountCode:success',
  CREATE_DISCOUNT_CODE_FAILURE = 'servicePlans.createDiscountCode:failure',

  INVITE_CUSTOMER_TO_SERVICE_PLAN_REQUEST = 'servicePlans.inviteCustomerToServicePlan:request',
  INVITE_CUSTOMER_TO_SERVICE_PLAN_SUCCESS = 'servicePlans.inviteCustomerToServicePlan:success',
  INVITE_CUSTOMER_TO_SERVICE_PLAN_FAILURE = 'servicePlans.inviteCustomerToServicePlan:failure',

  CREATE_SERVICE_PLAN_COMMITMENT_REQUEST = 'servicePlans.createServicePlanCommitment:request',
  UPDATE_SERVICE_PLAN_COMMITMENT_REQUEST = 'servicePlans.updateServicePlanCommitment:request',
  DELETE_SERVICE_PLAN_COMMITMENT_REQUEST = 'servicePlans.deleteServicePlanCommitment:request',

  CREATE_SERVICE_PLAN_PRICE_REQUEST = 'servicePlans.createServicePlanPrice:request',
  UPDATE_SERVICE_PLAN_PRICE_REQUEST = 'servicePlans.updateServicePlanPrice:request',
  DELETE_SERVICE_PLAN_PRICE_REQUEST = 'servicePlans.deleteServicePlanPrice:request',

  GET_SUBSCRIPTIONS_FOR_SERVICE_PLAN_REQUEST = 'servicePlans.getSubscriptionsForServicePlan:request',
  GET_SUBSCRIPTIONS_FOR_SERVICE_PLAN_SUCCESS = 'servicePlans.getSubscriptionsForServicePlan:success',
  GET_SUBSCRIPTIONS_FOR_SERVICE_PLAN_FAILURE = 'servicePlans.getSubscriptionsForServicePlan:failure',

  GET_SUBSCRIPTIONS_FOR_CUSTOMER_REQUEST = 'servicePlans.getSubscriptionsForCustomer:request',
  GET_SUBSCRIPTIONS_FOR_CUSTOMER_SUCCESS = 'servicePlans.getSubscriptionsForCustomer:success',
  GET_SUBSCRIPTIONS_FOR_CUSTOMER_FAILURE = 'servicePlans.getSubscriptionsForCustomer:failure',

  CLEAR_SERVICE_PLAN_SUBSCRIPTIONS = 'servicePlans.clearServicePlanSubscriptions',
  CLEAR_SERVICE_PLANS = 'servicePlans.clearServicePlans',
  CLEAR_SERVICE_PLAN_PERIODS = 'servicePlans.clearServicePlanPeriods',

  GET_SERVICE_PLAN_PERIOD_REQUEST = 'servicePlans.getServicePlanPeriod:request',
  GET_SERVICE_PLAN_PERIOD_SUCCESS = 'servicePlans.getServicePlanPeriod:success',
  GET_SERVICE_PLAN_PERIOD_FAILURE = 'servicePlans.getServicePlanPeriod:failure',

  UPDATE_SERVICE_PLAN_PERIOD_REQUEST = 'servicePlans.updateServicePlanPeriod:request',
  UPDATE_SERVICE_PLAN_PERIOD_COMMITMENT_REQUEST = 'servicePlans.updateServicePlanPeriodCommitment:request',
  DELETE_SERVICE_PLAN_PERIOD_COMMITMENT_REQUEST = 'servicePlans.deleteServicePlanPeriodCommitment:request',

  GET_SERVICE_PLAN_SUBSCRIPTION_REQUEST = 'servicePlans.getServicePlanSubscription:request',
  GET_SERVICE_PLAN_SUBSCRIPTION_SUCCESS = 'servicePlans.getServicePlanSubscription:success',
  GET_SERVICE_PLAN_SUBSCRIPTION_FAILURE = 'servicePlans.getServicePlanSubscription:failure',

  GET_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST = 'servicePlans.getServicePlansCustomerReminder:request',
  GET_SERVICE_PLANS_CUSTOMER_REMINDER_SUCCESS = 'servicePlans.getServicePlansCustomerReminder:success',

  GET_SERVICE_PLANS_CUSTOMER_REMINDERS_REQUEST = 'servicePlans.getServicePlansCustomerReminders:request',
  GET_SERVICE_PLANS_CUSTOMER_REMINDERS_SUCCESS = 'servicePlans.getServicePlansCustomerReminders:success',

  TOGGLE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST = 'servicePlans.toggleServicePlansCustomerReminder:request',
  TOGGLE_SERVICE_PLANS_CUSTOMER_REMINDER_SUCCESS = 'servicePlans.toggleServicePlansCustomerReminder:success',

  CREATE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST = 'servicePlans.createServicePlansCustomerReminders:request',
  CREATE_SERVICE_PLANS_CUSTOMER_REMINDER_SUCCESS = 'servicePlans.createServicePlansCustomerReminders:success',

  UPDATE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST = 'servicePlans.updateServicePlansCustomerReminder:request',
  UPDATE_SERVICE_PLANS_CUSTOMER_REMINDER_SUCCESS = 'servicePlans.updateServicePlansCustomerReminder:success',

  DELETE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST = 'servicePlans.deleteServicePlansCustomerReminder:request',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export type CreateServicePlanRequestPrice = Omit<
  ServicePlanPrice,
  | 'accountId'
  | 'servicePlanId'
  | 'isHidden'
  | 'validFrom'
  | 'id'
  | 'publicId'
  | 'servicePlanPublicId'
>;
type CreateServicePlanRequestData = Omit<
  ServicePlan,
  'id' | 'publicId' | 'downloadTermsUrl' | 'accountId' | 'planCanBeSold'
> & {
  prices?: Nullish<Array<CreateServicePlanRequestPrice>>;
  termsUploadId?: number;
};

export type UpdateServicePlanRequestData = Partial<
  Omit<
    PublicServicePlan,
    | 'publicId'
    | 'accountId'
    | 'stripeAccountId'
    | 'stripeProductId'
    | 'downloadTermsUrl'
  >
>;

export type UpdateServicePlanPeriodCommitmentData = Partial<
  Pick<PublicServicePlanPeriodCommitment, 'entityId' | 'isFulfilledOverride'>
> & {
  publicId: PublicServicePlanPeriodCommitment['publicId'];
};

export type UpdateServicePlanPeriodData = Partial<
  Pick<PublicServicePlanPeriod, 'isFulfilledOverride'>
> & {
  publicId: PublicServicePlanPeriod['publicId'];
};

export type AnyAction =
  | UnpackAsyncAction<ToggleServicePlansCustomerReminder>
  | UnpackAsyncAction<GetServicePlansCustomerReminders>
  | UnpackAsyncAction<GetListedDiscountCodes>
  | UnpackAsyncAction<GetListedServicePlans>
  | UnpackAsyncAction<GetServicePlansAnalytics>
  | UnpackAsyncAction<GetServicePeriods>
  | UnpackAsyncAction<GetServicePlan>
  | UnpackAsyncAction<CreateServicePlan>
  | UnpackAsyncAction<UpdateServicePlan>
  | UnpackAsyncAction<GetSubscriptionsForServicePlan>
  | UnpackAsyncAction<GetServicePlanPeriod>
  | UnpackAsyncAction<GetSubscriptionsForCustomer>
  | UnpackAsyncAction<GetServicePlanSubscription>;

export type GetServicePeriodsRequestData = {
  servicePlanPublicIds: string[];
  statuses: ServicePlanPeriod['status'][];
  when?: ('past' | 'current' | 'upcoming')[];
  customerId: Nullish<number>;
};

export type GetListedDiscountCodes = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_LISTED_DISCOUNT_CODES_REQUEST;
      payload?: {
        callback?: (
          listedServicePlans: PublicHydratedServicePlanDiscountCode[]
        ) => void;
      };
    };
    success: {
      type: ActionType.GET_LISTED_DISCOUNT_CODES_SUCCESS;
      payload: PublicHydratedServicePlanDiscountCode[];
    };
    failure: { type: ActionType.GET_LISTED_DISCOUNT_CODES_FAILURE };
  }
>;

export type GetListedServicePlans = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_LISTED_SERVICE_PLANS_REQUEST;
      payload?: {
        callback?: (listedServicePlans: ListedServicePlan[]) => void;
      };
    };
    success: {
      type: ActionType.GET_LISTED_SERVICE_PLANS_SUCCESS;
      payload: ListedServicePlan[];
    };
    failure: { type: ActionType.GET_LISTED_SERVICE_PLANS_FAILURE };
  }
>;

export type GetServicePlansAnalytics = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_SERVICE_PLANS_ANALYTICS_REQUEST;
      payload: {
        callback: (analytics: ServicePlansAnalyticsData) => void;
      };
    };
    success: {
      type: ActionType.GET_SERVICE_PLANS_ANALYTICS_SUCCESS;
    };
    failure: { type: ActionType.GET_SERVICE_PLANS_ANALYTICS_FAILURE };
  }
>;

export type GetServicePeriods = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_SERVICE_PERIODS_REQUEST;
      payload: GetServicePeriodsRequestData;
    };
    success: {
      type: ActionType.GET_SERVICE_PERIODS_SUCCESS;
      payload: PublicHydratedServicePlanPeriod[];
    };
    failure: { type: ActionType.GET_SERVICE_PERIODS_FAILURE };
  }
>;

export type GetServicePlan = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_SERVICE_PLAN_REQUEST;
      payload: {
        publicId: string;
        callback?: () => void;
      };
      meta: {
        publicId: string;
      };
    };
    success: {
      type: ActionType.GET_SERVICE_PLAN_SUCCESS;
      payload: PublicHydratedServicePlan;
    };
    failure: { type: ActionType.GET_SERVICE_PLAN_FAILURE };
  }
>;

export type CreateServicePlan = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_SERVICE_PLAN_REQUEST;
      payload: {
        createServicePlanRequestData: CreateServicePlanRequestData;
        callback: () => void;
        onErrorCallback: () => void;
      };
    };
    success: {
      type: ActionType.CREATE_SERVICE_PLAN_SUCCESS;
    };
    failure: { type: ActionType.CREATE_SERVICE_PLAN_FAILURE };
  }
>;

export type UpdateServicePlan = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_SERVICE_PLAN_REQUEST;
      payload: {
        servicePlanPublicId: ServicePlan['publicId'];
        updateServicePlanRequestData: UpdateServicePlanRequestData;
        callback: () => void;
        onErrorCallback: () => void;
      };
    };
    success: {
      type: ActionType.UPDATE_SERVICE_PLAN_SUCCESS;
    };
    failure: { type: ActionType.UPDATE_SERVICE_PLAN_FAILURE };
  }
>;

export type DeleteServicePlan = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.DELETE_SERVICE_PLAN_REQUEST;
      payload: {
        servicePlanPublicId: ServicePlan['publicId'];
        callback: () => void;
        onErrorCallback: () => void;
      };
    };
    success: {
      type: ActionType.DELETE_SERVICE_PLAN_SUCCESS;
    };
    failure: { type: ActionType.DELETE_SERVICE_PLAN_FAILURE };
  }
>;

export type DeleteServicePlanDiscountCode = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.DELETE_SERVICE_PLAN_DISCOUNT_CODE_REQUEST;
      payload: {
        servicePlanDiscountCodePublicId: ServicePlanDiscountCode['publicId'];
        callback: () => void;
        onErrorCallback: () => void;
      };
    };
    success: {
      type: ActionType.DELETE_SERVICE_PLAN_DISCOUNT_CODE_SUCCESS;
    };
    failure: { type: ActionType.DELETE_SERVICE_PLAN_DISCOUNT_CODE_FAILURE };
  }
>;

export type GetServicePlanDiscountCodes = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_SERVICE_PLAN_DISCOUNT_CODES_REQUEST;
      payload: {
        publicId: string;
        callback?: (
          discountCodes: PublicHydratedServicePlanDiscountCode[]
        ) => void;
        onErrorCallback?: () => void;
      };
      meta: {
        publicId: string;
      };
    };
    success: {
      type: ActionType.GET_SERVICE_PLAN_DISCOUNT_CODES_SUCCESS;
      payload: PublicHydratedServicePlanDiscountCode[];
    };
    failure: { type: ActionType.GET_SERVICE_PLAN_DISCOUNT_CODES_FAILURE };
  }
>;

export type CreateDiscountCode = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_DISCOUNT_CODE_REQUEST;
      payload: {
        createDiscountCodeRequestData: CreateServicePlanDiscountCodeInput;
        callback: () => void;
        onErrorCallback: () => void;
      };
    };
    success: {
      type: ActionType.CREATE_DISCOUNT_CODE_SUCCESS;
    };
    failure: { type: ActionType.CREATE_DISCOUNT_CODE_FAILURE };
  }
>;

export type InviteCustomerToServicePlan = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.INVITE_CUSTOMER_TO_SERVICE_PLAN_REQUEST;
      payload: {
        inviteCustomerToServicePlanRequestData: {
          customerId: number;
          servicePlanPricePublicId?: Nullish<ServicePlanPrice['publicId']>;
          preButtonEmailText?: Nullish<string>;
          postButtonEmailText?: Nullish<string>;
          servicePlanPublicId: ServicePlan['publicId'];
          servicePlanDiscountCodePublicId?: ServicePlanDiscountCode['publicId'];
          sendMeACopy?: Nullish<boolean>;
        };
        callback: () => void;
        onErrorCallback: () => void;
      };
    };
    success: {
      type: ActionType.INVITE_CUSTOMER_TO_SERVICE_PLAN_SUCCESS;
    };
    failure: { type: ActionType.INVITE_CUSTOMER_TO_SERVICE_PLAN_FAILURE };
  }
>;

export type CreateServicePlanCommitmentInput = Omit<
  PublicHydratedServicePlanCommitment,
  | 'publicId'
  | 'accountId'
  | 'templateId'
  | 'templatePublicId'
  | 'entityTemplate'
  | 'createdAt'
  | 'updatedAt'
> & {
  entityTemplate: Omit<
    PublicEntityTemplate<'task' | 'scheduledEvent'>,
    'publicId'
  >;
};

export type CreateServicePlanCommitment = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_SERVICE_PLAN_COMMITMENT_REQUEST;
      payload: {
        servicePlanPublicId: ServicePlan['publicId'];
        servicePlanCommitment: Omit<
          CreateServicePlanCommitmentInput,
          | 'id'
          | 'publicId'
          | 'accountId'
          | 'templateId'
          | 'templatePublicId'
          | 'servicePlanPublicId'
        >;
        callback?: (
          servicePlanCommitment: PublicHydratedServicePlanCommitment
        ) => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type UpdateServicePlanCommitmentInput = Omit<
  PublicHydratedServicePlanCommitment,
  | 'accountId'
  | 'templateId'
  | 'templatePublicId'
  | 'entityTemplate'
  | 'servicePlanPublicId'
  | 'createdAt'
  | 'updatedAt'
> & {
  entityTemplate: Omit<
    PublicEntityTemplate<'task' | 'scheduledEvent'>,
    'publicId'
  > & {
    publicId?: Nullish<
      PublicEntityTemplate<'task' | 'scheduledEvent'>['publicId']
    >;
  };
};

export type UpdateServicePlanCommitment = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_SERVICE_PLAN_COMMITMENT_REQUEST;
      payload: {
        publicId: Nullish<
          PublicEntityTemplate<'task' | 'scheduledEvent'>['publicId']
        >;
        servicePlanCommitment: Partial<UpdateServicePlanCommitmentInput>;
        callback?: (
          servicePlanCommitment: PublicHydratedServicePlanCommitment
        ) => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type DeleteServicePlanCommitment = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.DELETE_SERVICE_PLAN_COMMITMENT_REQUEST;
      payload: {
        servicePlanCommitmentId: string;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type GetSubscriptionsForServicePlan = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_SUBSCRIPTIONS_FOR_SERVICE_PLAN_REQUEST;
      payload: {
        servicePlanId: string;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
    success: {
      type: ActionType.GET_SUBSCRIPTIONS_FOR_SERVICE_PLAN_SUCCESS;
      payload: PublicHydratedServicePlanSubscription[];
    };
  }
>;

export type GetSubscriptionsForCustomer = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_SUBSCRIPTIONS_FOR_CUSTOMER_REQUEST;
      payload: {
        customerId: number;
        callback?: (
          subscriptions: PublicHydratedServicePlanSubscription[]
        ) => void;
        onErrorCallback?: () => void;
      };
    };
    success: {
      type: ActionType.GET_SUBSCRIPTIONS_FOR_CUSTOMER_SUCCESS;
      payload: PublicHydratedServicePlanSubscription[];
    };
  }
>;

export type ClearServicePlanSubscriptions = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CLEAR_SERVICE_PLAN_SUBSCRIPTIONS;
    };
  }
>;

export type ClearServicePlans = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CLEAR_SERVICE_PLANS;
    };
  }
>;

export type ClearServicePlanPeriods = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CLEAR_SERVICE_PLAN_PERIODS;
    };
  }
>;

export type UpdateServicePlanPriceInput = Omit<
  PublicServicePlanPrice,
  | 'accountId'
  | 'servicePlanPublicId'
  | 'currencyCode'
  | 'stripeAccountId'
  | 'stripeProductId'
  | 'stripePriceId'
  | 'validFrom'
  | 'validTo'
  | 'isHidden'
>;

export type UpdateServicePlanPrice = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_SERVICE_PLAN_PRICE_REQUEST;
      payload: {
        servicePlanPrice: UpdateServicePlanPriceInput;
        callback?: (servicePlanPrice: PublicServicePlanPrice) => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type DeleteServicePlanPrice = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.DELETE_SERVICE_PLAN_PRICE_REQUEST;
      payload: {
        servicePlanPricePublicId: ServicePlanPrice['publicId'];
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type CreateServicePlanPriceInput = Omit<
  PublicServicePlanPrice,
  | 'accountId'
  | 'publicId'
  | 'currencyCode'
  | 'stripeAccountId'
  | 'stripeProductId'
  | 'stripePriceId'
  | 'validFrom'
  | 'validTo'
  | 'isHidden'
>;

export type CreateServicePlanPrice = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_SERVICE_PLAN_PRICE_REQUEST;
      payload: {
        servicePlanPrice: CreateServicePlanPriceInput;
        callback?: (servicePlanPrice: PublicServicePlanPrice) => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type GetServicePlanPeriod = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_SERVICE_PLAN_PERIOD_REQUEST;
      payload: {
        publicId: string;
        callback?: (servicePlanPeriod: PublicHydratedServicePlanPeriod) => void;
      };
      meta: {
        publicId: string;
      };
    };
    success: {
      type: ActionType.GET_SERVICE_PLAN_PERIOD_SUCCESS;
      payload: PublicHydratedServicePlanPeriod;
    };
    failure: { type: ActionType.GET_SERVICE_PLAN_PERIOD_FAILURE };
  }
>;

export type UpdateServicePlanPeriodCommitment = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_SERVICE_PLAN_PERIOD_COMMITMENT_REQUEST;
      payload: {
        servicePlanPeriodCommitment: UpdateServicePlanPeriodCommitmentData;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type UpdateServicePlanPeriod = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_SERVICE_PLAN_PERIOD_REQUEST;
      payload: {
        servicePlanPeriod: UpdateServicePlanPeriodData;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type DeleteServicePlanPeriodCommitment = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.DELETE_SERVICE_PLAN_PERIOD_COMMITMENT_REQUEST;
      payload: {
        servicePlanPeriodCommitmentId: ServicePlanPeriodCommitment['publicId'];
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type GetServicePlanSubscription = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_REQUEST;
      payload: {
        publicId: string;
      };
    };
    success: {
      type: ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_SUCCESS;
      payload: PublicHydratedServicePlanSubscription;
    };
  }
>;

export type GetServicePlansCustomerReminders = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_SERVICE_PLANS_CUSTOMER_REMINDERS_REQUEST;
      payload: {
        onSuccess?: (
          payload: PublicHydratedServicePlanCustomerReminderTemplate[]
        ) => void;
        onError?: (err: unknown) => void;
      };
    };
    success: {
      type: ActionType.GET_SERVICE_PLANS_CUSTOMER_REMINDERS_SUCCESS;
      payload: PublicHydratedServicePlanCustomerReminderTemplate[];
    };
  }
>;

export type ToggleServicePlansCustomerReminder = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.TOGGLE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST;
      payload: {
        publicId: ServicePlanCustomerReminderTemplate['publicId'];
        body: ToggleServicePlanCustomerReminderTemplateInput;
        onSuccess?: (
          payload: PublicHydratedServicePlanCustomerReminderTemplate
        ) => void;
        onError?: (err: unknown) => void;
      };
    };
    success: {
      type: ActionType.TOGGLE_SERVICE_PLANS_CUSTOMER_REMINDER_SUCCESS;
      payload: PublicHydratedServicePlanCustomerReminderTemplate;
    };
  }
>;

export type CreateServicePlansCustomerReminder = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST;
      payload: {
        body: CreateServicePlanCustomerReminderTemplateInput;
        onSuccess?: (
          payload: PublicHydratedServicePlanCustomerReminderTemplate
        ) => void;
        onError?: (err: unknown) => void;
      };
    };
    success: {
      type: ActionType.CREATE_SERVICE_PLANS_CUSTOMER_REMINDER_SUCCESS;
      payload: PublicHydratedServicePlanCustomerReminderTemplate;
    };
  }
>;

export type GetServicePlansCustomerReminder = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST;
      payload: {
        publicId: string;
        onSuccess: (
          payload: PublicHydratedServicePlanCustomerReminderTemplate
        ) => void;
        onError?: (err: unknown) => void;
      };
    };
    success: {
      type: ActionType.GET_SERVICE_PLANS_CUSTOMER_REMINDER_SUCCESS;
      payload: PublicHydratedServicePlanCustomerReminderTemplate;
    };
  }
>;

export type UpdateServicePlansCustomerReminder = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST;
      payload: {
        publicId: string;
        body: UpdateServicePlanCustomerReminderTemplateInput;
        onSuccess: (
          x: PublicHydratedServicePlanCustomerReminderTemplate
        ) => void;
        onError?: (err: unknown) => void;
      };
    };
    success: {
      type: ActionType.UPDATE_SERVICE_PLANS_CUSTOMER_REMINDER_SUCCESS;
      payload: PublicHydratedServicePlanCustomerReminderTemplate;
    };
  }
>;

export type DeleteServicePlansCustomerReminder = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.DELETE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST;
      payload: {
        publicId: string;
        onSuccess: () => void;
        onError?: (err: unknown) => void;
      };
    };
  }
>;
