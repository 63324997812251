import React, {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';
import { IButtonProps } from '../../plButton/Button';

import { Float } from '@headlessui-float/react';
import { Menu } from '@headlessui/react';
import { SortDirection } from '@payaca/types/listViewTypes';
import UntitledIcon from '@payaca/untitled-icons';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import MiniLoader from '../../miniLoader/MiniLoader';
import Checkbox, { ICheckboxProps } from '../../plCheckbox/Checkbox';
import DropdownCore from '../../plDropdown/DropdownCore';
import EmptyState from '../../plEmptyState/EmptyState';
import FloatingTooltip from '../../plTooltip/FloatingTooltip';
import TooltipCore from '../../plTooltip/TooltipCore';
import RowActionDropdown from './RowActionDropdown';

export type TColumnRender<T extends Record<string, any>, F extends keyof T> = (
  field: T[F],
  row: T
) => ReactNode;

export interface IColumnProps<
  T extends Record<string, any>,
  F extends keyof T = keyof T,
  S extends string = string,
> {
  header: string;
  field: F;
  render?: TColumnRender<T, F>;
  className?: string;
  onSort?: (field: F | S, sortDirection: SortDirection) => void;
  sortByName?: S; // alternative sortBy name instead of field
}

export type TTableBulkAction<T extends Record<string, any>> = Omit<
  IButtonProps,
  'onClick' | 'size'
> & {
  label: string;
  onClick: (rows: T[]) => void;
  disabledTooltip?: string;
};

export type TTableFilter<T extends Record<string, any>> = {
  id: string;
  label: string;
  filterFn: (rows: T[]) => T[];
};

export type TTableRowAction<T extends Record<string, any>> = {
  label: string;
  onClick: (row: T) => void;
  isDisabled?: (row: T) => boolean;
  isProcessing?: boolean;
};

export type TTableShouldDisableItem<T extends Record<string, any>> = (
  row: T
) => {
  disabled: boolean;
  tooltip?: string;
};

export interface IAdvancedTableProps<T extends Record<string, any>> {
  items: T[];
  uniqueKey: keyof T;
  isLoading?: boolean;
  selectedItems?: T[];
  setSelectedItems?: (items: T[]) => void;
  enableSelectAll?: boolean;
  // todo: maybe a function that doesn't fire for every row and every render?
  shouldDisableItem?: TTableShouldDisableItem<T>;
  itemActions?: TTableRowAction<T>[] | ((row: T) => TTableRowAction<T>[]);
  onClickRow?: (row: T) => void;
  sortBy?: keyof T;
  sortDirection?: SortDirection;
  emptyText?: string;
}

export const Column = <T extends Record<string, any>, F extends keyof T>(
  props: IColumnProps<T, F>
) => <></>;

const Table_ = <T extends Record<string, any>>(
  props: PropsWithChildren<IAdvancedTableProps<T>>
) => {
  const {
    items,
    uniqueKey,
    selectedItems = [],
    setSelectedItems,
    isLoading = false,
    shouldDisableItem,
    enableSelectAll = false,
    itemActions,
    children,
    onClickRow,
    sortBy,
    sortDirection,
    emptyText,
  } = props;

  const columns = useMemo(() => {
    return React.Children.toArray(children).reduce<IColumnProps<T>[]>(
      (acc, child) => {
        if (
          !React.isValidElement(child) ||
          typeof child.props.header === 'undefined' ||
          typeof child.props.field === 'undefined'
        ) {
          return acc;
        }

        acc.push(child.props);

        return acc;
      },
      []
    );
  }, [children]);

  useEffect(() => {
    // Remove any selected rows that are no longer in the paginated data (data that is visible on the screen)
    setSelectedItems?.(
      selectedItems.filter((i) => !!items.find((j) => j[uniqueKey] === i))
    );
  }, [items, uniqueKey]);

  const enabledItems = useMemo(() => {
    if (!shouldDisableItem) return items;

    return items.filter((row) => !shouldDisableItem(row).disabled);
  }, [items, shouldDisableItem]);

  const renderColumnHeader = (column: IColumnProps<T>, i: number) => {
    const { header, field, className, onSort, sortByName } = column;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const sortingName = sortByName || (field as keyof T);
    return (
      <th
        key={`${String(field)}-${i}`}
        scope="col"
        className={twMerge('text-start', className, onSort ? '' : 'px-6 py-3')}
      >
        {onSort ? (
          <Menu>
            <Float placement="bottom-start" portal>
              <Menu.Button className="flex w-full items-center gap-x-1 bg-transparent px-6 py-3 text-start font-medium uppercase focus:bg-stone-100 focus:outline-none">
                {header}
                <div className="ml-1 flex flex-col">
                  <UntitledIcon
                    name="chevron-up"
                    className={`-mb-[4px] h-3 w-3${
                      !(
                        sortBy === sortingName &&
                        sortDirection === SortDirection.ASCENDING
                      )
                        ? ' text-gray-400'
                        : ''
                    }`}
                  />
                  <UntitledIcon
                    name="chevron-down"
                    className={`h-3 w-3${
                      !(
                        sortBy === sortingName &&
                        sortDirection === SortDirection.DESCENDING
                      )
                        ? ' text-gray-400'
                        : ''
                    }`}
                  />
                </div>
              </Menu.Button>

              <DropdownCore
                items={[
                  {
                    label: (
                      <>
                        <UntitledIcon name="arrow-up" className="h-4 w-4" />
                        Sort ascending
                      </>
                    ),
                    onClick: () =>
                      onSort?.(sortingName, SortDirection.ASCENDING),
                  },
                  {
                    label: (
                      <>
                        <UntitledIcon name="arrow-down" className="h-4 w-4" />
                        Sort descending
                      </>
                    ),
                    onClick: () =>
                      onSort?.(sortingName, SortDirection.DESCENDING),
                  },
                ]}
              />
            </Float>
          </Menu>
        ) : (
          header
        )}
      </th>
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center py-6">
          <MiniLoader />
        </div>
      ) : items.length === 0 ? (
        <EmptyState className="py-6" text={emptyText} />
      ) : (
        <>
          <div className="prose overflow-x-auto">
            <table className="min-w-full border-collapse divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-slate-800">
                <tr>
                  {!!setSelectedItems && (
                    <>
                      {enableSelectAll ? (
                        <CheckboxElement
                          header
                          label="Select all rows"
                          disabled={enabledItems.length === 0}
                          checked={
                            enabledItems.length
                              ? selectedItems.length === enabledItems.length
                              : false
                          }
                          onChange={(e) =>
                            e.target.checked
                              ? setSelectedItems(enabledItems)
                              : setSelectedItems([])
                          }
                        />
                      ) : (
                        <th></th>
                      )}
                    </>
                  )}

                  {columns.map(renderColumnHeader)}

                  {itemActions && (
                    <th
                      scope="col"
                      className="sticky right-0 bg-gray-50 dark:bg-slate-800"
                    >
                      <span className="sr-only">Row actions</span>
                    </th>
                  )}
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                {items.map((row) => {
                  const isRowDisabled = shouldDisableItem?.(row);

                  return (
                    <tr
                      key={row[uniqueKey]}
                      onClick={onClickRow ? () => onClickRow(row) : undefined}
                      className={
                        onClickRow
                          ? 'group/row hover:cursor-pointer hover:bg-gray-100'
                          : ''
                      }
                    >
                      {!!setSelectedItems && (
                        <FloatingTooltip
                          enabled={
                            (isRowDisabled?.disabled &&
                              !!isRowDisabled?.tooltip?.length) ||
                            false
                          }
                          showArrow
                        >
                          <CheckboxElement
                            label={`Select row ${row[uniqueKey]}`}
                            checked={
                              !!selectedItems.find(
                                (i) => i[uniqueKey] === row[uniqueKey]
                              )
                            }
                            onClick={(e) => e.stopPropagation()}
                            // ToDo: update type?
                            onChange={(e: any) =>
                              e.target.checked
                                ? setSelectedItems([...selectedItems, row])
                                : setSelectedItems(
                                    selectedItems.filter(
                                      (i) => i[uniqueKey] !== row[uniqueKey]
                                    )
                                  )
                            }
                            elementClassName={
                              onClickRow ? 'group-hover/row:bg-gray-100' : ''
                            }
                            disabled={isRowDisabled?.disabled}
                          />

                          <TooltipCore>
                            <div className="max-w-[150px]">
                              {isRowDisabled?.tooltip}
                            </div>
                          </TooltipCore>
                        </FloatingTooltip>
                      )}

                      {columns.map(({ field, render, className }, i) => (
                        <td
                          key={`${String(field)}-${i}`}
                          className={twMerge(
                            'whitespace-nowrap px-6 py-4',
                            className
                          )}
                        >
                          {render ? render(row[field], row) : row[field]}
                        </td>
                      ))}

                      {itemActions && (
                        <td
                          className={`sticky right-0 w-[80px] px-6 py-4 bg-white${
                            onClickRow ? ' group-hover/row:bg-gray-100' : ''
                          }`}
                          onClick={(e) => onClickRow && e.stopPropagation()}
                        >
                          <RowActionDropdown
                            actions={(typeof itemActions === 'function'
                              ? itemActions(row)
                              : itemActions
                            ).map(({ onClick, isDisabled, ...rest }) => {
                              const disabled = !!isDisabled?.(row);
                              return {
                                ...rest,
                                disabled,
                                onClick: () => onClick(row),
                              };
                            })}
                            className={onClickRow ? 'hover:!bg-gray-200' : ''}
                            aria-label={`Actions for row ${row[uniqueKey]}`}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

const CheckboxElement = forwardRef<
  HTMLInputElement,
  ICheckboxProps & { header?: boolean; elementClassName?: string }
>(({ header = false, className, elementClassName, ...rest }, ref) => {
  const El = header ? 'th' : 'td';

  return (
    <El
      className={
        'sticky left-0 w-5 py-3 pe-4 ps-4' +
        (header
          ? ' bg-gray-50 dark:bg-slate-800'
          : clsx(' bg-white', elementClassName))
      }
    >
      <div className="flex h-5 items-center">
        <Checkbox
          ref={ref}
          className={'cursor-pointer' + (className ? ' ' + className : '')}
          hiddenLabel
          {...rest}
        />
      </div>
    </El>
  );
});

export const Table = Object.assign(Table_, {
  Column,
});
