import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { actions as usersActions } from '@/api/users';

import {
  ConnectionControl,
  ConnectionControlBenefitList,
} from '@/ui/components';

import { useSelector } from '@/api/state';
import { getUserRoles } from '@/utils/stateAccessors';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';
import StripeLogo from './stripe-logo.svg';

const STRIPE_CLIENT_ID = import.meta.env.VITE_STRIPE_CLIENT_ID;

const StripeConnectionControl: FunctionComponent = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const [checkStripeStatusInterval, setCheckStripeStatusInterval] =
    useState<NodeJS.Timeout>();
  const isConnectedToStripe = useSelector((state: any) => {
    return state.users.myProfile.accounts[0].connectedToStripe;
  });
  const userRoles = useSelector(getUserRoles);
  const isAdmin = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      AccountsPermissions.UPDATE_CONNECTION,
    ]);
  }, [userRoles]);

  const [accountHasServicePlans, setAccountHasServicePlans] = useState(false);

  useEffect(() => {
    dispatch(
      servicePlansActions.getListedServicePlans.request({
        callback: (listedServicePlans) => {
          if (listedServicePlans.length > 0) {
            setAccountHasServicePlans(true);
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    if (isConnectedToStripe && checkStripeStatusInterval)
      clearInterval(checkStripeStatusInterval);
    setCheckStripeStatusInterval(undefined);
  }, [isConnectedToStripe, checkStripeStatusInterval]);

  const handleCheckStripeStatus = useCallback(() => {
    if (checkStripeStatusInterval) clearInterval(checkStripeStatusInterval);
    setCheckStripeStatusInterval(
      setInterval(() => dispatch(usersActions.getProfile()), 3000)
    );
  }, [checkStripeStatusInterval, dispatch]);

  return (
    <ConnectionControl
      disableConnection={false}
      connectionName="Stripe"
      isConnected={isConnectedToStripe}
      allowConnectDisconnect={isAdmin}
      onAddConnection={() => {
        handleCheckStripeStatus();
        window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${window.location.origin}/enableCustomerPayments`;
      }}
      onRemoveConnection={(onConnectionRemoved: () => void) =>
        dispatch(usersActions.removeStripeConnection(onConnectionRemoved))
      }
      logo={StripeLogo}
      disconnectionWarningMessage={
        <ul>
          <li>
            You will no longer be able to take payment via Stripe on your
            Proposals and Invoices.
          </li>
          {accountHasServicePlans && (
            <li>You will no longer be able to create or sell Service Plans.</li>
          )}
        </ul>
      }
    >
      <React.Fragment>
        {!isConnectedToStripe ? (
          <div>
            <div>
              <p>
                Accept payments for invoices and service plans directly through
                Payaca using Stripe, including card payments, Direct Debits, and
                subscriptions, all seamlessly integrated into your workflow
              </p>
              <div style={{ textAlign: 'left', marginBottom: '10px' }}>
                <ConnectionControlBenefitList
                  benefits={[
                    'Make it easy for customers to pay fast with a button on your quotes and invoices.',
                    'Fast, easy setup',
                  ]}
                />
              </div>
            </div>
          </div>
        ) : (
          <p>Your Stripe account has successfully been connected to Payaca.</p>
        )}
      </React.Fragment>
    </ConnectionControl>
  );
};

export default StripeConnectionControl;
