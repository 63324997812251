import { useState } from 'react';

import { PublicEntityTemplate } from '@payaca/types/entity-templates';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import QuickActionsElement from '@payaca/components/quickActionsTableCell/QuickActionsElement';
import CalendarIcon from '../../components/navigationSidebar/icon--cal.svg?react';
import TasksIcon from '../../components/navigationSidebar/icon--tasks.svg?react';

import { UpdateServicePlanCommitmentInput } from '@payaca/store/servicePlans/servicePlansTypes';
import { Nullish } from '@payaca/utilities/types';
import CreateEditEntityTemplateModal from '../createEditEntityTemplateModal/CreateEditEntityTemplateModal';
import CreateEditTaskTemplateControl from '../createEditTaskTemplateControl/CreateEditTaskTemplateControl';
import { ControllableServicePlanCommitment } from './ServicePlanCommitmentsControl';

type Props = {
  commitment: ControllableServicePlanCommitment<'task' | 'scheduledEvent'>;
  onChange: (
    commitment: Omit<
      UpdateServicePlanCommitmentInput,
      'publicId' | 'accountId'
    > & {
      publicId?: Nullish<UpdateServicePlanCommitmentInput['publicId']>;
    }
  ) => Promise<void>;
  onRemove: () => Promise<void>;
};

const ServicePlanCommitmentControl = ({
  commitment,
  onChange,
  onRemove,
}: Props) => {
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);

  return (
    <div className="service-plan-commitment-control">
      {/* scheduled/event task commitment */}
      <CommitmentTemplateControl
        template={commitment.entityTemplate}
        onChange={(template) => {
          return onChange({
            ...commitment,
            scalesWithQuantity: commitment.scalesWithQuantity ?? true,
            entityTemplate: template,
          });
        }}
        onRemove={() => {
          return onRemove();
        }}
      />

      {/* scheduled event linked tasks */}
      {commitment.entityTemplate.entityType === 'scheduledEvent' && (
        <div className="event-tasks-wrapper">
          {(
            commitment as ControllableServicePlanCommitment<'scheduledEvent'>
          ).entityTemplate.data?.tasks?.map(
            (
              task: Omit<PublicEntityTemplate<'task'>, 'publicId'>,
              index: number
            ) => {
              return (
                <CommitmentTemplateControl
                  key={`commitment-template-control-${index}`}
                  template={task}
                  onChange={async (updatedTask) => {
                    const tasks = [
                      ...((
                        commitment as ControllableServicePlanCommitment<'scheduledEvent'>
                      ).entityTemplate.data?.tasks || []),
                    ];
                    // @ts-ignore
                    tasks[index] = updatedTask;

                    return onChange({
                      ...commitment,
                      scalesWithQuantity: commitment.scalesWithQuantity ?? true,
                      entityTemplate: {
                        entityType: commitment.entityTemplate.entityType,
                        data: {
                          ...commitment.entityTemplate.data,
                          tasks: tasks,
                        },
                      },
                    });
                  }}
                  onRemove={async () => {
                    const tasks = [
                      ...((
                        commitment as ControllableServicePlanCommitment<'scheduledEvent'>
                      ).entityTemplate.data?.tasks || []),
                    ];

                    tasks.splice(index, 1);

                    return onChange({
                      ...commitment,
                      scalesWithQuantity: commitment.scalesWithQuantity ?? true,
                      entityTemplate: {
                        entityType: commitment.entityTemplate.entityType,
                        data: {
                          ...commitment.entityTemplate.data,
                          tasks: tasks,
                        },
                      },
                    });
                  }}
                />
              );
            }
          )}
          <div>
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={() => {
                setShowAddTaskModal(true);
              }}
            >
              Add linked Task
            </Button>
            <Modal
              isOpen={showAddTaskModal}
              onClose={() => setShowAddTaskModal(false)}
              title="Task Template"
            >
              <CreateEditTaskTemplateControl
                onRequestSave={(taskTemplate) => {
                  const entityTemplate = commitment.entityTemplate;
                  setShowAddTaskModal(false);
                  return onChange({
                    ...commitment,
                    scalesWithQuantity: commitment.scalesWithQuantity ?? true,
                    entityTemplate: {
                      entityType: entityTemplate.entityType,
                      data: {
                        ...commitment.entityTemplate.data,
                        tasks: [
                          // @ts-ignore todo resolve this
                          ...(commitment?.entityTemplate?.data?.tasks || []),
                          taskTemplate,
                        ],
                      },
                    },
                  });
                }}
              />
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServicePlanCommitmentControl;

type CommitmentTemplateControlProps = {
  template: Omit<
    PublicEntityTemplate<'task' | 'scheduledEvent'>,
    'publicId' | 'accountId'
  >;
  onChange: (
    template: Omit<
      PublicEntityTemplate<'task' | 'scheduledEvent'>,
      'publicId' | 'accountId'
    >
  ) => Promise<void>;
  onRemove: () => Promise<void>;
};

const CommitmentTemplateControl = ({
  template,
  onChange,
  onRemove,
}: CommitmentTemplateControlProps) => {
  const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);
  const templateIcon =
    template.entityType === 'scheduledEvent' ? <CalendarIcon /> : <TasksIcon />;

  const [isRemoving, setIsRemoving] = useState(false);

  return (
    <div className="commitment-entity">
      {templateIcon}

      <div className="name-users-wrapper">
        {/* task/scheduled event name */}
        {template?.data?.name?.length ? (
          <strong>{template.data.name}</strong>
        ) : (
          <em>
            {template.entityType === 'scheduledEvent' ? 'Event' : 'Task'}{' '}
            template
          </em>
        )}
      </div>

      {/* quick actions */}
      <QuickActionsElement
        recordId={''}
        quickActions={[
          {
            actionName: 'Edit',
            actionBehaviour: () => {
              setShowEditTemplateModal(true);
            },
          },
          {
            actionName: 'Delete',
            actionBehaviour: () => {
              setIsRemoving(true);
              onRemove().then(() => {
                setIsRemoving(false);
              });
            },
            isActionProcessing: isRemoving,
          },
        ]}
        renderDisabledIfNoActions={true}
      />

      <CreateEditEntityTemplateModal
        isOpen={showEditTemplateModal}
        onClose={() => setShowEditTemplateModal(false)}
        action="Edit"
        template={template}
        onRequestSave={(t) => {
          setShowEditTemplateModal(false);
          onChange(
            t as Omit<
              PublicEntityTemplate<'task' | 'scheduledEvent'>,
              'publicId' | 'accountId'
            >
          );
        }}
        allowModifyTemplateNameDescription={false}
      />
    </div>
  );
};
