import UntitledIcon from '@payaca/untitled-icons';
import { ComponentPropsWithoutRef, FC } from 'react';

export interface IProps extends ComponentPropsWithoutRef<'svg'> {}

const UntitledLoading: FC<IProps> = (props) => {
  const { className, ...rest } = props;

  return (
    <UntitledIcon
      {...rest}
      name="loading-02.3"
      className={'animate-spin' + (className ? ' ' + className : '')}
    />
  );
};

export default UntitledLoading;
