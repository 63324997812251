import { Reducer } from 'redux';

import { LineItemGroup } from '@payaca/types/lineItemGroupTypes';
import {
  ActionType,
  FetchedEntityLineItemGroup,
  LineItemGroupsState,
} from './lineItemGroupsTypes';

export const initialState: LineItemGroupsState = {
  lineItemGroups: {},

  isGettingLineItemGroups: false,

  isUpdatingLineItemGroup: false,
  updateLineItemGroupErrorMessage: null,
  isUpdateLineItemGroupSuccessful: null,

  isCreatingLineItemGroup: false,
  createLineItemGroupErrorMessage: null,
  createLineItemGroupSuccessId: null,

  isArchivingLineItemGroup: false,
  archiveLineItemGroupErrorMessage: null,
  isArchiveLineItemGroupSuccessful: null,
};

const lineItemGroupsReducer: Reducer<LineItemGroupsState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    // GET LINE ITEM GROUPS
    case ActionType.REQUEST_GET_LINE_ITEM_GROUPS:
      return {
        ...state,
        isGettingLineItemGroups: true,
      };
    case ActionType.GET_LINE_ITEM_GROUPS_SUCCESS: {
      const lineItemGroups = action.payload.lineItemGroups.reduce(
        (
          acc: {
            [key: number]: FetchedEntityLineItemGroup;
          },
          curr: LineItemGroup
        ) => {
          acc[curr.id] = {
            entity: curr,
            isFetching: false,
            fetchSucceededAt: new Date(),
          };
          return acc;
        },
        {}
      );
      return {
        ...state,
        isGettingLineItemGroups: false,
        lineItemGroups,
        gettingLineItemGroupsError: null,
      };
    }
    case ActionType.GET_LINE_ITEM_GROUPS_FAILURE:
      return {
        ...state,
        isGettingLineItemGroups: false,
        gettingLineItemGroupsError: action.payload.errorMessage,
      };

    // GET LINE ITEM GROUP
    case ActionType.REQUEST_GET_LINE_ITEM_GROUP: {
      const fetchedEntity =
        state.lineItemGroups &&
        state.lineItemGroups[action.payload.lineItemGroupId];

      return {
        ...state,
        lineItemGroups: {
          ...state.lineItemGroups,
          [action.payload.lineItemGroupId]: {
            ...fetchedEntity,
            isFetching: true,
            fetchAttemptedAt: new Date(),
          },
        },
      };
    }
    case ActionType.GET_LINE_ITEM_GROUP_SUCCESS: {
      const fetchedEntity =
        state.lineItemGroups &&
        state.lineItemGroups[action.payload.lineItemGroupId];

      return {
        ...state,
        lineItemGroups: {
          ...state.lineItemGroups,
          [action.payload.lineItemGroupId]: {
            ...fetchedEntity,
            entity: action.payload.lineItemGroup,
            isFetching: false,
            fetchSucceededAt: new Date(),
          },
        },
      };
    }
    case ActionType.GET_LINE_ITEM_GROUP_FAILURE: {
      const fetchedEntity =
        state.lineItemGroups &&
        state.lineItemGroups[action.payload.lineItemGroupId];

      return {
        ...state,
        lineItemGroups: {
          ...state.lineItemGroups,
          [action.payload.lineItemGroupId]: {
            ...fetchedEntity,
            isFetching: false,
            fetchFailedAt: new Date(),
            fetchError: action.payload.errorMessage,
          },
        },
      };
    }

    // CREATE LINE ITEM GROUP
    case ActionType.REQUEST_CREATE_LINE_ITEM_GROUP: {
      return {
        ...state,
        isCreatingLineItemGroup: true,
        createLineItemGroupErrorMessage: null,
        createLineItemGroupSuccessId: null,
      };
    }
    case ActionType.CREATE_LINE_ITEM_GROUP_SUCCESS: {
      return {
        ...state,
        isCreatingLineItemGroup: false,
        createLineItemGroupErrorMessage: null,
        createLineItemGroupSuccessId: action.payload.lineItemGroupId,
      };
    }
    case ActionType.CREATE_LINE_ITEM_GROUP_FAILURE: {
      return {
        ...state,
        isCreatingLineItemGroup: false,
        createLineItemGroupErrorMessage: action.payload.errorMessage,
        createLineItemGroupSuccessId: null,
      };
    }
    case ActionType.CLEAR_CREATE_LINE_ITEM_GROUP: {
      return {
        ...state,
        isCreatingLineItemGroup: false,
        createLineItemGroupErrorMessage: null,
        createLineItemGroupSuccessId: null,
      };
    }

    // UPDATE LINE ITEM GROUP
    case ActionType.REQUEST_UPDATE_LINE_ITEM_GROUP: {
      return {
        ...state,
        isUpdatingLineItemGroup: true,
        updateLineItemGroupErrorMessage: null,
        isUpdateLineItemGroupSuccessful: null,
      };
    }
    case ActionType.UPDATE_LINE_ITEM_GROUP_SUCCESS: {
      const fetchedEntity =
        state.lineItemGroups &&
        state.lineItemGroups[action.payload.lineItemGroupId];
      return {
        ...state,
        isUpdatingLineItemGroup: false,
        updateLineItemGroupErrorMessage: null,
        isUpdateLineItemGroupSuccessful: true,
        lineItemGroups: {
          ...state.lineItemGroups,
          [action.payload.lineItemGroupId]: {
            ...fetchedEntity,
            entity: action.payload.lineItemGroup,
            isFetching: false,
            fetchSucceededAt: new Date(),
          },
        },
      };
    }
    case ActionType.UPDATE_LINE_ITEM_GROUP_FAILURE: {
      return {
        ...state,
        isUpdatingLineItemGroup: false,
        isUpdateLineItemGroupSuccessful: false,
        updateLineItemGroupErrorMessage: action.payload.errorMessage,
      };
    }

    case ActionType.CLEAR_UPDATE_LINE_ITEM_GROUP: {
      return {
        ...state,
        isUpdatingLineItemGroup: false,
        isUpdateLineItemGroupSuccessful: null,
        updateLineItemGroupErrorMessage: null,
      };
    }

    // ARCHIVE LINE ITEM GROUP
    case ActionType.REQUEST_ARCHIVE_LINE_ITEM_GROUP: {
      return {
        ...state,
        isArchivingLineItemGroup: true,
        archiveLineItemGroupErrorMessage: null,
        isArchiveLineItemGroupSuccessful: null,
      };
    }
    case ActionType.ARCHIVE_LINE_ITEM_GROUP_SUCCESS: {
      // remove successfully archived line item group from lineItemGroups
      const lineItemGroups = { ...state.lineItemGroups };
      delete lineItemGroups[action.payload.lineItemGroupId];

      return {
        ...state,
        isArchivingLineItemGroup: false,
        isArchiveLineItemGroupSuccessful: true,
        lineItemGroups,
      };
    }
    case ActionType.ARCHIVE_LINE_ITEM_GROUP_FAILURE: {
      return {
        ...state,
        isArchivingLineItemGroup: false,
        archiveLineItemGroupErrorMessage: action.payload.errorMessage,
        isArchiveLineItemGroupSuccessful: false,
      };
    }
    case ActionType.CLEAR_ARCHIVE_LINE_ITEM_GROUP: {
      return {
        ...state,
        isArchivingLineItemGroup: false,
        archiveLineItemGroupErrorMessage: null,
        isArchiveLineItemGroupSuccessful: null,
      };
    }

    // CLEAR LINE ITEM GROUPS
    case ActionType.CLEAR_LINE_ITEM_GROUPS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default lineItemGroupsReducer;
