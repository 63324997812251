import * as dashboardActions from '@payaca/store/dashboard/dashboardActions';
import { ActionRequiredDraftJob } from '@payaca/types/dashboardTypes';
import moment from 'moment-timezone';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import DashboardActionRequiredCarousel from '../dashboardActionRequiredCarousel/DashboardActionRequiredCarousel';

import { useSelector } from '@/api/state';
import DashboardJobActionRequiredItem from '../dashboardJobActionRequiredItem/DashboardJobActionRequiredItem';

const ActionRequiredDraftJobsCarousel: FC = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();

  const actionRequiredDraftJobs = useSelector(
    (state) => state.dashboard.actionRequiredDraftJobs
  );

  const isGettingActionRequiredDraftJobs = useSelector(
    (state) => state.dashboard.isGettingActionRequiredDraftJobs
  );

  useEffect(() => {
    dispatch(dashboardActions.requestGetActionRequiredDraftJobs());
  }, [dispatch]);

  const actionRequiredItems = useMemo(() => {
    const nowMoment = moment();
    return actionRequiredDraftJobs?.map(
      (actionRequiredDraftJob: ActionRequiredDraftJob, index: number) => {
        const updatedAtMoment = moment(actionRequiredDraftJob.updatedAt);
        const daysBetween = nowMoment.diff(updatedAtMoment, 'days');

        let tooltipContent = `Updated ${daysBetween} days ago`;

        const isFlagged = !!actionRequiredDraftJob.bouncedAt;
        if (actionRequiredDraftJob.bouncedAt) {
          const bouncedAtMoment = moment(actionRequiredDraftJob.bouncedAt);
          const bouncedAtDaysBetween = nowMoment.diff(bouncedAtMoment, 'days');
          tooltipContent = `Bounced ${bouncedAtDaysBetween} days ago`;
        }

        return (
          <DashboardJobActionRequiredItem
            tooltipContent={tooltipContent}
            key={index}
            onClick={() =>
              history.push(`deals/${actionRequiredDraftJob.dealId}`)
            }
            isFlagged={isFlagged}
            reference={`${actionRequiredDraftJob.jobType} ${actionRequiredDraftJob.jobReference}`}
            customerName={actionRequiredDraftJob.customerName}
          />
        );
      }
    );
  }, [actionRequiredDraftJobs, history]);

  const title = useMemo(() => {
    return `Waiting to be sent${
      isGettingActionRequiredDraftJobs
        ? ''
        : ` (${
            actionRequiredItems?.length === 20
              ? '20+'
              : actionRequiredItems?.length || 0
          })`
    }`;
  }, [actionRequiredItems, isGettingActionRequiredDraftJobs]);

  return (
    <div className="action-required-draft-jobs-carousel">
      <DashboardActionRequiredCarousel
        actionRequiredItems={
          isGettingActionRequiredDraftJobs ? undefined : actionRequiredItems
        }
        title={title}
        isLoading={isGettingActionRequiredDraftJobs}
      />
    </div>
  );
};

export default ActionRequiredDraftJobsCarousel;
