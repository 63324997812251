import { useSelector } from '@/api/state';
import { useAccount } from '@/utils/storeHooks';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import * as pipelineActions from '@payaca/store/pipelines/pipelineActions';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import './DeletePipelineModal.sass';

interface Props {
  isOpen?: boolean;
  onClose?: any;
  selectedPipelineId: number;
  onPipelineDeleted?: any;
}

export const DeletePipelineModal: FC<Props> = ({
  isOpen = false,
  onClose,
  selectedPipelineId,
  onPipelineDeleted,
}) => {
  const dispatch = useDispatch();

  const isDeletingPipeline = useSelector(
    (state) => state.pipelines.isPerformingPipelineAction
  );

  const handleDeletePipeline = useCallback(() => {
    dispatch(
      pipelineActions.requestDeletePipeline(
        selectedPipelineId,
        onPipelineDeleted
      )
    );
  }, [selectedPipelineId, onPipelineDeleted]);

  const account = useAccount();

  const pipelineIsPrimary = account.primaryPipelineId === selectedPipelineId;

  return (
    <Modal
      className={'delete-pipeline-modal'}
      isOpen={isOpen}
      onClose={onClose}
      title={'Delete this pipeline?'}
      actions={
        pipelineIsPrimary ? undefined : (
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={handleDeletePipeline}
            isProcessing={isDeletingPipeline}
            isDisabled={isDeletingPipeline}
          >
            Yes, delete this pipeline
          </Button>
        )
      }
    >
      {pipelineIsPrimary ? (
        <Alert colour={EAlertColour.SOFT_YELLOW}>
          <p>This is the primary pipeline and cannot be deleted</p>
        </Alert>
      ) : (
        <p>None of your Projects will be deleted.</p>
      )}
    </Modal>
  );
};
