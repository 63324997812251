import { FunctionComponent, useMemo } from 'react';
import './KeyIndicatorData.sass';

import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SparkLine from '@payaca/components/sparkLine/SparkLine';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import { DataPeriod } from '@payaca/types/analyticsTypes';

type Props = {
  title: string;
  explainerText?: string;
  currentPeriod?: DataPeriod<number>;
  previousPeriod?: DataPeriod<number>;
  periodLengthInDays: number;
  percentageChange: number | 'Infinity' | '-Infinity';
  formatValue?: (value?: number) => string;
  sparkLineData?: number[];
};

const KeyIndicatorData: FunctionComponent<Props> = ({
  title,
  explainerText,
  currentPeriod,
  previousPeriod,
  percentageChange,
  formatValue,
  sparkLineData,
  periodLengthInDays,
}: Props): JSX.Element => {
  const canRenderSparkline = useMemo(() => {
    return !!sparkLineData?.length && sparkLineData.length > 1;
  }, [sparkLineData?.length]);

  const canRenderPercentageChange = useMemo(() => {
    return previousPeriod && percentageChange != null;
  }, [previousPeriod, percentageChange]);

  const percentageChangeElement = useMemo(() => {
    const isIncrease =
      percentageChange === 'Infinity' || +percentageChange >= 0;
    if (!canRenderPercentageChange) {
      return (
        <span className={`percentage-change-element`}>
          <span>
            <FontAwesomeIcon icon={faArrowUp} />
            <FontAwesomeIcon icon={faArrowDown} />
          </span>
          <span>%</span>
        </span>
      );
    }

    return (
      <span
        className={`percentage-change-element ${
          isIncrease ? 'increase' : 'decrease'
        }`}
      >
        <FontAwesomeIcon icon={isIncrease ? faArrowUp : faArrowDown} />
        <span>
          {percentageChange === 'Infinity' || percentageChange === '-Infinity'
            ? `∞`
            : (Math.abs(percentageChange) * 100).toFixed(2)}
          %
        </span>
      </span>
    );
  }, [percentageChange, previousPeriod, canRenderPercentageChange]);

  return (
    <div className={`key-indicator-data`}>
      <div className="title-container">
        <h2 className="title flex-container flex-center flex-contiguous">
          <span>{title}</span>{' '}
          {explainerText && <Tooltip text={explainerText} />}
        </h2>
      </div>
      <div className="flex-container flex-center">
        <div className="information-container">
          <span className="current-period-value">
            {formatValue
              ? formatValue(currentPeriod?.value)
              : currentPeriod?.value === undefined
                ? '-'
                : currentPeriod?.value}
          </span>
          <div className="period-description-container">
            <small>Past {periodLengthInDays} days</small>
          </div>
          <div className="previous-period-value-container">
            <small>{percentageChangeElement}</small>
            <small>(prev {periodLengthInDays} days)</small>
          </div>
        </div>
        <div
          className={`spark-line-container flex-shrink flex-grow ${
            canRenderSparkline ? '' : 'placeholder'
          }`}
        >
          {canRenderSparkline && (
            <SparkLine
              data={sparkLineData || []}
              isSmooth={true}
              hasArea={true}
            />
          )}
          {!canRenderSparkline && (
            <>
              <SparkLine
                data={[1, 4, 8, 6, 12, 20, 10, 16, 14, 16, 24, 30]}
                isSmooth={true}
                hasArea={true}
              />
              <div className="demo">
                <span>Requires more data</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default KeyIndicatorData;
