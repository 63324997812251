import { useSelector } from '@/api/state';
import { getScheduledEventsByTaskId, getTask } from '@/utils/stateAccessors';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@payaca/components/button/IconButton';
import { HeaderTagType } from '@payaca/components/headerTag/HeaderTag';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import {
  getContactsToNotify,
  groupScheduledEventsByDate,
} from '@payaca/helpers/scheduledEventsHelper';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import { useCustomer, useDeal } from '@payaca/store/hooks/appState';
import * as taskActions from '@payaca/store/tasks/tasksActions';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';
import { FC, useMemo, useState } from 'react';
import { Calendar } from 'react-iconly';
import { useDispatch } from 'react-redux';
import { CreateScheduledEventModal } from '../createScheduledEventModal/CreateScheduledEventModal';
import GroupedScheduledEventsList from '../groupedScheduledEventsList/GroupedScheduledEventsList';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import ScheduledEventControl from '../scheduledEventControl/ScheduledEventControl';
import './TaskLinkedScheduledEventsPanel.sass';

type Props = {
  taskId: number;
};
const TaskLinkedScheduledEventsPanel: FC<Props> = ({
  taskId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [showCreateScheduledEventModal, setShowCreateScheduledEventModal] =
    useState(false);

  const task = useSelector((state) => getTask(state, taskId));

  const deal = useDeal(task?.dealId);

  const customer = useCustomer(deal?.customerId);

  const scheduledEvents = useSelector((state) => {
    return getScheduledEventsByTaskId(state, taskId);
  });

  const groupedScheduledEvents = useMemo(() => {
    return groupScheduledEventsByDate(scheduledEvents);
  }, [scheduledEvents]);

  const isGettingScheduledEventsForTask = useSelector((state) => {
    return state.scheduledEvents.isGettingScheduledEventsForTask;
  });

  const newScheduledEventBase = useMemo(() => {
    const siteAddress = deal?.siteAddresses?.[0];

    return {
      includeCustomerInformation: !!task?.dealId,
      dealId: task?.dealId,
      taskIds: task?.id ? [task?.id] : undefined,
      userAssignments: task?.assignedToUserId
        ? [task.assignedToUserId]
        : undefined,
      location: siteAddress
        ? {
            id: siteAddress.id,
            address: siteAddress.address,
            contacts: siteAddress.contacts,
          }
        : undefined,
      contactsToNotify: getContactsToNotify({
        customer,
        locationContacts: siteAddress?.contacts,
      }),
    };
  }, [task]);

  if (!task) return null;

  return (
    <div className="task-linked-scheduled-events-panel">
      {isGettingScheduledEventsForTask && !scheduledEvents?.length ? (
        <div className="loader-container">
          <MiniLoader />
        </div>
      ) : (
        <>
          <div className="flex-center flex-container">
            <h2 className="flex-center flex-container flex-grow">
              <span className="scheduled-events-icon-container">
                <Calendar size={18} set="light" />
              </span>
              {scheduledEvents.length} linked{' '}
              {scheduledEvents.length === 1 ? 'event' : 'events'}
            </h2>
            <PermissionGuard
              renderIfHasPermissions={[ScheduledEventsPermissions.ADD_EVENT]}
            >
              <IconButton
                size="xs"
                icon={faPlus}
                onClick={() => setShowCreateScheduledEventModal(true)}
              />
            </PermissionGuard>
          </div>
          {!!scheduledEvents?.length && (
            <div>
              <GroupedScheduledEventsList
                groupHeaderTagType={HeaderTagType.H3}
                groupedScheduledEvents={groupedScheduledEvents.filter(
                  (x) => x.groupName !== 'Past'
                )}
                renderScheduledEvent={(scheduledEvent: ScheduledEvent) => {
                  return (
                    <ScheduledEventControl
                      scheduledEventId={scheduledEvent.id}
                      hideQuickActions={false}
                      hideLinkedTasks={true}
                      disableShowReadDrawer={true}
                      onPersistScheduledEventSuccess={() =>
                        dispatch(taskActions.requestGetTask(taskId))
                      }
                    />
                  );
                }}
              />
              <GroupedScheduledEventsList
                groupedScheduledEvents={groupedScheduledEvents.filter(
                  (x) => x.groupName == 'Past'
                )}
                renderScheduledEvent={(scheduledEvent: ScheduledEvent) => {
                  return (
                    <ScheduledEventControl
                      scheduledEventId={scheduledEvent.id}
                      hideQuickActions={false}
                      hideLinkedTasks={true}
                      disableShowReadDrawer={true}
                      onPersistScheduledEventSuccess={() =>
                        dispatch(taskActions.requestGetTask(taskId))
                      }
                    />
                  );
                }}
              />
            </div>
          )}
        </>
      )}
      <CreateScheduledEventModal
        isOpen={showCreateScheduledEventModal}
        onClose={() => setShowCreateScheduledEventModal(false)}
        hiddenFields={
          task?.dealId ? ['customerIds'] : ['customerIds', 'dealId']
        }
        disabledFields={['dealId']}
        initialEventData={newScheduledEventBase}
        onSuccess={() => {
          dispatch(taskActions.requestGetTask(taskId));
          setShowCreateScheduledEventModal(false);
        }}
      />
    </div>
  );
};
export default TaskLinkedScheduledEventsPanel;
