import { useSelector } from '@/api/state';
import { getRegion, getUserRoles } from '@/utils/stateAccessors';
import Button from '@payaca/components/button/Button';
import Modal from '@payaca/components/modal/Modal';
import QuickActionsElement from '@payaca/components/quickActionsTableCell/QuickActionsElement';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import StatusBadge, {
  StatusBadgeState,
} from '@payaca/components/statusBadge/StatusBadge';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { TimelogsPermissions } from '@payaca/permissions/timelogs/timelogs.permissions';
import { archiveTimelog } from '@payaca/store/timelogs/timelogsActions';
import { PublicHydratedTimelog } from '@payaca/types/timelogs';
import {
  DurationUnit,
  getReadableStringFromDurationString,
} from '@payaca/utilities/timeUtilities';
import moment from 'moment-timezone';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import TimelogLinkedEntity from '../timelogLinkedEntity/TimelogLinkedEntity';
import UpdateTimelogControl from '../updateTimelogControl/UpdateTimelogControl';
import './TimelogRow.sass';

type Props = {
  timelog: PublicHydratedTimelog;
  onUpdateTimelogSuccess?: () => void;
  onArchiveTimelogSuccess?: () => void;
};

const TimelogRow: FC<Props> = ({
  timelog,
  onUpdateTimelogSuccess,
  onArchiveTimelogSuccess,
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const region = useSelector(getRegion);
  const userRoles = useSelector(getUserRoles);

  const [showUpdateTimelogModal, setShowUpdateTimelogModal] = useState(false);
  const [showArchiveTimelogModal, setShowArchiveTimelogModal] = useState(false);

  const currentUserId = useSelector((state: any) => {
    return state.users.myProfile.id;
  });

  const [isArchiving, setIsArchiving] = useState(false);

  const archive = useCallback(() => {
    setIsArchiving(true);
    dispatch(
      archiveTimelog.request({
        timelogPublicId: timelog.publicId,
        callback: () => {
          setIsArchiving(false);
          setShowArchiveTimelogModal(false);
          onArchiveTimelogSuccess?.();
        },
      })
    );
  }, [timelog.publicId, onArchiveTimelogSuccess]);

  const isMultiDay = useMemo(() => {
    if (!timelog.endTime) return false;
    return !moment(timelog.startTime).isSame(timelog.endTime, 'day');
  }, [timelog.startTime, timelog.endTime]);

  const durationString = useMemo(() => {
    return timelog.endTime
      ? moment
          .duration(moment(timelog.endTime).diff(moment(timelog.startTime)))
          .toISOString()
      : null;
  }, [timelog.startTime, timelog.endTime]);

  const quickActions = useMemo(() => {
    const isSelfAssigned =
      timelog.assigneeType === 'user' &&
      timelog.assigneePublicId === currentUserId.toString();

    return [
      ...(userHasRequiredPermission(userRoles, [
        TimelogsPermissions.UPDATE_TIMELOGS,
      ]) ||
      (userHasRequiredPermission(userRoles, [
        TimelogsPermissions.UPDATE_SELF_ASSIGNED_TIMELOGS,
      ]) &&
        isSelfAssigned)
        ? [
            {
              actionName: 'Edit',
              actionBehaviour: () => {
                setShowUpdateTimelogModal(true);
              },
            },
          ]
        : []),
      ...(userHasRequiredPermission(userRoles, [
        TimelogsPermissions.DELETE_TIMELOGS,
      ]) ||
      (userHasRequiredPermission(userRoles, [
        TimelogsPermissions.DELETE_SELF_ASSIGNED_TIMELOGS,
      ]) &&
        isSelfAssigned)
        ? [
            {
              actionName: 'Archive',
              actionBehaviour: () => {
                setShowArchiveTimelogModal(true);
              },
            },
          ]
        : []),
    ];
  }, [userRoles, currentUserId]);

  return (
    <>
      <ResponsiveViewWrapper
        className="timelog-row"
        downBreakpointSm={755}
        downBreakpointXs={600}
      >
        <div className="type">
          {timelog.type?.type && (
            <StatusBadge state={StatusBadgeState.BLUE_NEUTRAL}>
              {timelog.type.type}
            </StatusBadge>
          )}
        </div>
        <div className="start-time">
          {moment(timelog.startTime).format('DD MMM h:mma')}
        </div>
        <div className="end-time">
          {timelog.endTime ? (
            <>
              <span className="date-divider">to</span>
              {moment(timelog.endTime).format(
                isMultiDay ? 'DD MMM h:mma' : 'h:mma'
              )}
            </>
          ) : (
            <>
              {' '}
              <StatusBadge state={StatusBadgeState.AMBER_PENDING}>
                Currently recording
              </StatusBadge>
            </>
          )}
        </div>
        <div className="duration">
          {durationString
            ? getReadableStringFromDurationString(durationString, {
                units: [DurationUnit.HOURS, DurationUnit.MINUTES],
                precision: 0,
                abbreviate: true,
                includeUnitsWithZeroValue: true,
                allowSingular: false,
              })
            : '-'}
        </div>
        <div className="cost">
          {timelog.calculatedCost ? (
            <>{currencyPrice(timelog.calculatedCost, region)}</>
          ) : (
            <>-</>
          )}
        </div>
        {!!timelog.linkedEntities?.length && (
          <div className="linked-entities">
            <ul>
              {timelog.linkedEntities?.map((x, i) => {
                return (
                  <li key={i}>
                    <TimelogLinkedEntity entity={x} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <QuickActionsElement
          recordId={timelog.publicId}
          quickActions={quickActions}
        />
        {!!timelog.notes?.length && (
          <div className="notes">{timelog.notes}</div>
        )}
      </ResponsiveViewWrapper>
      <Modal
        title="Update timelog"
        isOpen={showUpdateTimelogModal}
        onClose={() => setShowUpdateTimelogModal(false)}
        size="xs"
      >
        <UpdateTimelogControl
          timelog={timelog}
          onUpdateTimelogSuccess={() => {
            setShowUpdateTimelogModal(false);
            onUpdateTimelogSuccess?.();
          }}
        />
      </Modal>
      <Modal
        title="Archive Timelog"
        isOpen={showArchiveTimelogModal}
        onClose={() => setShowArchiveTimelogModal(false)}
        size="xs"
        actions={
          <>
            <Button onClick={archive} isProcessing={isArchiving}>
              Archive
            </Button>
          </>
        }
      >
        <p>Are you sure you want to archive this timelog?</p>
      </Modal>
    </>
  );
};

export default TimelogRow;
