import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import { getCustomFieldValueValidationResult } from '@payaca/custom-fields/helpers/validation';
import {
  CustomFieldDefinition,
  CustomFieldType,
  CustomFieldValueHelperType,
} from '@payaca/custom-fields/types/index';
import { Nullish } from '@payaca/utilities/types';
import { useCallback, useEffect, useState } from 'react';
import { CustomField } from './CustomField';

export const AutosaveCustomField = <T extends CustomFieldType>({
  definition,
  initialValue,
  saveFunction,
}: {
  definition: CustomFieldDefinition<T>;
  initialValue?: Nullish<CustomFieldValueHelperType[T]>;
  saveFunction: (
    value?: Nullish<CustomFieldValueHelperType[T]>
  ) => Promise<void>;
}) => {
  const [value, setValue] = useState(initialValue);
  const [isSaving, setIsSaving] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [persistRequired, setPersistRequired] = useState(false);
  const [persistError, setPersistError] = useState<string | null>(null);

  useEffect(() => {
    if (persistRequired) {
      setPersistRequired(false);
      onPersist();
    }
  }, [persistRequired]);

  const onPersist = useCallback(() => {
    const validationResult = getCustomFieldValueValidationResult(
      definition,
      value
    );
    if (!validationResult.isValid) {
      return;
    }

    setIsSaving(true);
    setPersistError(null);
    saveFunction(value)
      ?.then(() => {
        setShowSaveSuccess(true);
        setTimeout(() => {
          setShowSaveSuccess(false);
        }, 500);
      })
      .catch(() => {
        setPersistError('Failed to save');
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [value]);

  return (
    <div>
      <CustomField
        definition={definition}
        value={value}
        onChange={setValue}
        onChangeTimeout={() => setPersistRequired(true)}
      />
      {persistError && (
        <Alert className="mt-2" colour={EAlertColour.SOFT_RED}>
          {persistError}
        </Alert>
      )}
      {/* {isSaving && <div>Saving...</div>}
      {!isSaving && showSaveSuccess && <div>Saved!</div>} */}
    </div>
  );
};
