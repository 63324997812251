import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import meKeys from '@/api/queries/me/keyFactory';
import { graphql } from '@/gql';

const GetMyRegionPreferences = graphql(`
  query GetMyRegionPreferences {
    me {
      user {
        account {
          regionPreferences {
            locale
            timezone
            currency {
              id
              code
              exponent
            }
          }
        }
      }
    }
  }
`);

const useGetMyRegionPreferences = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountRegionPreferences(),
    queryFn: () => {
      return gqlClient.request(GetMyRegionPreferences);
    },
  });

  return { data: data?.me.user.account.regionPreferences, ...rest };
};

export default useGetMyRegionPreferences;
