import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UpdateLineItemMaterialsInput,
  UpdateLineItemMaterialsMutation,
} from '@/gql/graphql';

const UPDATE_LINE_ITEM_MATERIALS_MUTATION = graphql(`
  mutation UpdateLineItemMaterials($input: UpdateLineItemMaterialsInput!) {
    updateLineItemMaterials(input: $input) {
      id
    }
  }
`);

const useUpdateLineItemMaterials = (
  options?: UseMutationOptions<
    UpdateLineItemMaterialsMutation,
    unknown,
    UpdateLineItemMaterialsInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UPDATE_LINE_ITEM_MATERIALS_MUTATION, { input });
    },
    ...options,
  });
};

export default useUpdateLineItemMaterials;
