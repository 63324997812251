import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Address } from '@payaca/types/locationTypes';
import { FunctionComponent, useMemo } from 'react';
import BasicField from '../basicField/BasicField';
import { InputStyleVariant } from '../inputWrapper/InputWrapper';
import ValidatedFieldWrapper from '../validatedFieldWrapper/ValidatedFieldWrapper';
import './AddressInputControl.css';

type Props = {
  styleVariant?: InputStyleVariant;
  address: Address | null;
  isRequired?: boolean;
  isDisabled?: boolean;
  fieldNamePrefix?: string;
  touchedState?: { [fieldName: string]: boolean };
  validationState?: { [fieldName: string]: FieldValidationResult };
  onChange?: (value: { [key: string]: any }) => void;
  onTouch?: (fieldName: string) => void;
};

const AddressInputControl: FunctionComponent<Props> = ({
  styleVariant,
  address,
  isRequired = false,
  isDisabled = false,
  fieldNamePrefix = '',
  touchedState,
  validationState,
  onChange,
  onTouch,
}: Props): JSX.Element => {
  const line1FieldName = useMemo(() => {
    return fieldNamePrefix ? `${fieldNamePrefix}.line1` : 'line1';
  }, [fieldNamePrefix]);

  const line2FieldName = useMemo(() => {
    return fieldNamePrefix ? `${fieldNamePrefix}.line2` : 'line2';
  }, [fieldNamePrefix]);

  const cityFieldName = useMemo(() => {
    return fieldNamePrefix ? `${fieldNamePrefix}.city` : 'city';
  }, [fieldNamePrefix]);

  const postcodeFieldName = useMemo(() => {
    return fieldNamePrefix ? `${fieldNamePrefix}.postcode` : 'postcode';
  }, [fieldNamePrefix]);

  return (
    <div className="address-input-control">
      <ValidatedFieldWrapper
        isTouched={(touchedState && touchedState[line1FieldName]) || false}
        validationResult={validationState && validationState[line1FieldName]}
      >
        <BasicField
          styleVariant={styleVariant}
          label="Address line 1"
          value={address?.line1 || ''}
          isRequired={isRequired}
          isDisabled={isDisabled}
          name={line1FieldName}
          onChange={onChange}
          onTouch={onTouch}
        />
      </ValidatedFieldWrapper>
      <ValidatedFieldWrapper
        isTouched={(touchedState && touchedState[line2FieldName]) || false}
        validationResult={validationState && validationState[line2FieldName]}
      >
        <BasicField
          styleVariant={styleVariant}
          label="Address line 2"
          value={address?.line2 || ''}
          isRequired={false}
          isDisabled={isDisabled}
          name={line2FieldName}
          onChange={onChange}
          onTouch={onTouch}
        />
      </ValidatedFieldWrapper>
      <ValidatedFieldWrapper
        isTouched={(touchedState && touchedState[cityFieldName]) || false}
        validationResult={validationState && validationState[cityFieldName]}
      >
        <BasicField
          styleVariant={styleVariant}
          label="Town / city"
          value={address?.city || ''}
          isRequired={isRequired}
          isDisabled={isDisabled}
          name={cityFieldName}
          onChange={onChange}
          onTouch={onTouch}
        />
      </ValidatedFieldWrapper>
      <ValidatedFieldWrapper
        isTouched={(touchedState && touchedState[postcodeFieldName]) || false}
        validationResult={validationState && validationState[postcodeFieldName]}
      >
        <BasicField
          styleVariant={styleVariant}
          label="Postcode"
          value={address?.postcode || ''}
          isRequired={isRequired}
          isDisabled={isDisabled}
          name={postcodeFieldName}
          onChange={onChange}
          onTouch={onTouch}
        />
      </ValidatedFieldWrapper>
    </div>
  );
};

export default AddressInputControl;
