import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import Modal from '@payaca/components/modal/Modal';

import { useSelector } from '@/api/state';
import { actions as usersActions } from '@/api/users';
import * as authActions from '@payaca/store/auth/authActions';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
const VerifyAccountModal: FC<Props> = ({
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const myProfile = useSelector((state: any) => state.users.myProfile);

  const [hasResentEmail, setHasResentEmail] = useState<boolean>(false);
  const [isResendingVerification, setIsResendingVerification] =
    useState<boolean>(false);
  const [showResendingVerificationError, setShowResendingVerificationError] =
    useState<boolean>(false);

  const resendEmailVerification = useCallback(() => {
    setShowResendingVerificationError(false);
    setIsResendingVerification(true);
    dispatch(
      authActions.requestResendEmailVerification(
        myProfile.email,
        (error: any) => {
          setIsResendingVerification(false);
          if (!error) {
            setHasResentEmail(true);
          } else {
            setShowResendingVerificationError(true);
          }
        }
      )
    );
  }, [dispatch, myProfile]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={'Email verification required'}
      className={'verify-account-modal'}
      actions={
        <>
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={resendEmailVerification}
            isProcessing={isResendingVerification}
          >
            Resend verification
          </Button>
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => {
              dispatch(usersActions.getProfile());
              onClose();
            }}
          >
            {"OK, I've verified"}
          </Button>
        </>
      }
    >
      <p>
        {hasResentEmail
          ? "We have sent you an email to verify the address is correct, please click the link in the email. If you can't find it (even in spam) please contact us."
          : 'A verification email has been sent to your email address'}
      </p>
      {showResendingVerificationError && (
        <ErrorMessage
          message={
            'Sorry, there was an error resending your verification email. Please try again.'
          }
        />
      )}
    </Modal>
  );
};

export default VerifyAccountModal;
