import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import { JobContent, JobLineItemBase } from '@payaca/types/jobContentTypes';

import ItemGroupHeader from '../itemGroupHeader/ItemGroupHeader';
import JobLineItemsControl from '../jobLineItemsControl/JobLineItemsControl';
import ConfirmModal from '../modal/ConfirmModal';

import {
  getJob,
  getJobContent as getJobContentFromState,
  getJobLineItemGroup,
} from '@/utils/stateAccessors';

import { useSelector } from '@/api/state';
import './JobLineItemGroupControl.css';

type Props = {
  canDeleteItemGroup: boolean;
  jobIsInvoice: boolean;
  jobLineItemGroupId: number;
  jobContentId: number;
  handleOpenCreateJobLineItemModal: (
    jobLineItemId: number,
    initialItemData?: Partial<JobLineItemBase>
  ) => void;
  handleCreateJobLineItemAndOpenCreateJobLineItemModal: (
    lineItemId: number | null,
    initialItemData?: Partial<JobLineItemBase>
  ) => void;
  handleOpenAdvancedSearchLineItemModal: (searchTerm?: string) => void;
};

const JobLineItemGroupControl: FC<Props> = ({
  canDeleteItemGroup,
  jobLineItemGroupId,
  jobIsInvoice,
  jobContentId,
  handleOpenCreateJobLineItemModal,
  handleCreateJobLineItemAndOpenCreateJobLineItemModal,
  handleOpenAdvancedSearchLineItemModal,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [
    addLineItemGroupToJobContentCompleteTimestamp,
    setAddLineItemGroupToJobContentCompleteTimestamp,
  ] = useState<Date>();
  const [
    jobLineItemGroupExternalUpdateTimestamp,
    setJobLineItemGroupExternalUpdateTimestamp,
  ] = useState<Date>();

  const jobLineItemGroup = useSelector((state) =>
    getJobLineItemGroup(state, jobLineItemGroupId)
  );

  const jobContent: JobContent | undefined = useSelector((state) =>
    getJobContentFromState(state, jobContentId)
  );

  const jobContentFetchSucceededAt = useSelector(
    (state) =>
      jobLineItemGroup &&
      state.jobContent?.jobContents &&
      state.jobContent.jobContents[jobLineItemGroup.jobContentId]
        ?.fetchSucceededAt
  );

  const jobLineItemGroupFetchSucceededAt = useSelector(
    (state) =>
      state.jobContent?.jobLineItemGroups &&
      state.jobContent.jobLineItemGroups[jobLineItemGroupId]?.fetchSucceededAt
  );

  const isAddingLineItemGroupToJobContent = useSelector(
    (state) => state.jobContent.isAddingLineItemGroupToJobContent
  );

  const isDeletingJobLineItemGroup = useSelector((state) => {
    return state.jobContent.isDeletingJobLineItemGroup;
  });

  useEffect(() => {
    if (!isAddingLineItemGroupToJobContent) {
      setAddLineItemGroupToJobContentCompleteTimestamp(new Date());
    }
  }, [isAddingLineItemGroupToJobContent]);

  useEffect(() => {
    if (
      jobLineItemGroupFetchSucceededAt &&
      addLineItemGroupToJobContentCompleteTimestamp &&
      jobLineItemGroupFetchSucceededAt >
        addLineItemGroupToJobContentCompleteTimestamp
    ) {
      if (
        !jobLineItemGroupExternalUpdateTimestamp ||
        jobLineItemGroupExternalUpdateTimestamp <
          addLineItemGroupToJobContentCompleteTimestamp
      ) {
        setJobLineItemGroupExternalUpdateTimestamp(new Date());
      }
    }
  }, [
    addLineItemGroupToJobContentCompleteTimestamp,
    jobLineItemGroupFetchSucceededAt,
  ]);

  useEffect(() => {
    if (jobContentFetchSucceededAt) {
      setJobLineItemGroupExternalUpdateTimestamp(new Date());
    }
  }, [jobContentFetchSucceededAt]);

  const getJobLineItemGroups = useCallback(() => {
    dispatch(
      jobContentActions.requestGetJobLineItemGroupsForJobContent(jobContentId)
    );
  }, [dispatch, jobLineItemGroup, jobContentId]);

  const getJobContent = useCallback(() => {
    dispatch(jobContentActions.requestGetJobContent(jobContentId));
  }, [dispatch, jobLineItemGroup, jobContentId]);

  const lineItemGroupHasAnyItems = useMemo(() => {
    if (!jobLineItemGroup?.jobLineItemIds) return false;
    return jobLineItemGroup.jobLineItemIds.length > 0;
  }, [jobLineItemGroup]);

  const deleteJobLineItemGroup = useCallback(() => {
    setIsDeleting(true);

    dispatch(
      jobContentActions.requestDeleteJobLineItemGroup(
        jobLineItemGroupId,
        getJobContent
      )
    );
  }, [dispatch, getJob, jobLineItemGroupId]);

  const handleDeleteJobLineItemGroup = useCallback(() => {
    if (lineItemGroupHasAnyItems) {
      setShowConfirmDeleteModal(true);
    } else {
      deleteJobLineItemGroup();
    }
  }, [lineItemGroupHasAnyItems, deleteJobLineItemGroup]);

  const updateJobLineItemGroup = useCallback(
    (description: string) => {
      dispatch(
        jobContentActions.requestUpdateJobLineItemGroup(
          {
            description: description,
            jobLineItemGroupId: jobLineItemGroupId,
          },
          getJobLineItemGroups
        )
      );
    },
    [dispatch, jobLineItemGroupId, getJobLineItemGroups]
  );

  return (
    <div className="job-line-item-group-control">
      {jobLineItemGroup && (
        <>
          <ItemGroupHeader
            canDeleteItemGroup={canDeleteItemGroup}
            isDeleting={isDeleting && isDeletingJobLineItemGroup}
            itemGroup={jobLineItemGroup}
            onDeleteItemGroup={handleDeleteJobLineItemGroup}
            onUpdateItemGroup={(value: { [key: string]: any }) =>
              updateJobLineItemGroup(value.description)
            }
            itemGroupExternalUpdateTimestamp={
              jobLineItemGroupExternalUpdateTimestamp
            }
          />
          <JobLineItemsControl
            jobLineItemGroupId={jobLineItemGroupId}
            jobIsInvoice={jobIsInvoice}
            handleOpenCreateJobLineItemModal={handleOpenCreateJobLineItemModal}
            handleOpenAdvancedSearchLineItemModal={
              handleOpenAdvancedSearchLineItemModal
            }
            handleCreateJobLineItemAndOpenCreateJobLineItemModal={
              handleCreateJobLineItemAndOpenCreateJobLineItemModal
            }
            disabledItemIds={jobContent?.lineItemIds || []}
          />
        </>
      )}
      <ConfirmModal
        title="Confirm group delete"
        text="Are you sure you want to delete the group? This group contains one or more items which will also be deleted."
        onClose={() => setShowConfirmDeleteModal(false)}
        primaryText="Yes, delete the group"
        primaryAction={() => {
          setShowConfirmDeleteModal(false);
          deleteJobLineItemGroup();
        }}
        secondaryText="Don't delete group"
        secondaryAction={() => {
          setShowConfirmDeleteModal(false);
        }}
        open={showConfirmDeleteModal}
      />
    </div>
  );
};
export default JobLineItemGroupControl;
