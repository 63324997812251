import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  CreateCustomerMutation as _CreateCustomerMutation,
  CreateCustomerInput,
} from '@/gql/graphql';

const CreateCustomerMutation = graphql(`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
    }
  }
`);

export type CreateCustomerMutationOptions = UseMutationOptions<
  _CreateCustomerMutation,
  unknown,
  CreateCustomerInput
>;

const useCreateCustomer = (options?: CreateCustomerMutationOptions) => {
  return useMutation({
    mutationFn: (input: CreateCustomerInput) => {
      return gqlClient.request(CreateCustomerMutation, { input });
    },
    ...options,
  });
};

export default useCreateCustomer;
