import { useSelector } from '@/api/state';
import SupplierHeader from '@/ui/components/supplierHeader/SupplierHeader';
import SupplierSupplierMaterials from '@/ui/components/supplierSupplierMaterials/SupplierSupplierMaterials';
import { getSupplier } from '@/utils/stateAccessors';

import { requestGetSupplier } from '@payaca/store/suppliers/suppliersActions';
import { FC, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { usePermissions } from '@/hooks/usePermissions';
import SupplierPurchaseOrders from '@/ui/components/supplierPurchaseOrders/SupplierPurchaseOrders';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import { useLocation } from 'react-router';

import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import RouterTabs from '@payaca/components/plRouterTabs/RouterTabs';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

type Props = {
  supplierId: number;
};

const SupplierPage: FC<Props> = ({ supplierId }: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const { userHasRequiredPermission } = usePermissions();
  const location = useLocation();

  useEffect(() => {
    dispatch(requestGetSupplier(supplierId));
  }, [supplierId]);

  const showPurchaseOrdersTab = userHasRequiredPermission([
    MaterialsListPermissions.GET_PURCHASE_ORDERS,
  ]);

  const routerTabPanels = useMemo(() => {
    const t = [
      {
        slug: 'materials',
        label: 'Materials',
        render: () => (
          <SupplierSupplierMaterials
            supplierId={supplierId}
            onSupplierUpdateSuccess={() =>
              dispatch(requestGetSupplier(supplierId))
            }
          />
        ),
      },
    ];

    if (showPurchaseOrdersTab) {
      t.push({
        slug: 'purchase-orders',
        label: 'Purchase Orders',
        render: () => <SupplierPurchaseOrders supplierId={supplierId} />,
      });
    }

    return t;
  }, [supplierId, dispatch, location.hash, showPurchaseOrdersTab]);

  const supplier = useSelector((state) => getSupplier(state, supplierId));

  return (
    <AuthenticatedPageWrapper
      className="bg-gray-50"
      breadcrumbItems={[
        { label: 'Suppliers', To: '/suppliers' },
        { label: supplier?.name || '' },
      ]}
    >
      <RouterTabs panels={routerTabPanels}>
        <div className="flex flex-col p-6 pb-4">
          <Card sizeVariant={CardSizeVariant.SM}>
            <Card.Body>
              <div className="mb-4">
                <SupplierHeader supplierId={supplierId} />
              </div>

              <RouterTabs.Nav
                className="-mb-4 md:-mb-5"
                defaultTabSlug="materials"
              />
            </Card.Body>
          </Card>
        </div>

        <div className="p-6 pt-0">
          <RouterTabs.Panels />
        </div>
      </RouterTabs>
    </AuthenticatedPageWrapper>
  );
};

export default SupplierPage;
