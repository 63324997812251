import get from 'lodash.get';

// Consider making an exclusive strings file for all copy used in the apps - this would ensure consistency across apps and allow for potential of translations in the future

const getParsedError = (error: string, options = {}) => {
  let parsedError = error;
  Object.entries(options).forEach(([k, v]: any) => {
    parsedError = parsedError.replace(`$${k}`, v);
  });
  return parsedError;
};

const ServiceError: any = {
  login: {
    'User not found.':
      'Please check that your username and password are correct.',
    USER_DEACTIVATED:
      'Your account has been deactivated, please contact your account owner.',
    default: 'Sorry, there was an error logging you in. Please try again.',
  },
  register: {
    default: 'Sorry, there was an error registering you. Please try again.',
  },
  business: {
    upload: ' failed to upload.',
    remove: ' failed to delete.',
    default:
      'Sorry, there was an error updating your business details. Please try again.',
  },
  item: {
    create: 'Sorry, there was an error creating this item. Please try again.',
    update: 'Sorry, there was an error updating this item. Please try again.',
    default: 'Sorry, there was an error saving this item. Please try again.',
  },
  customer: {
    create:
      'Sorry, there was an error creating this customer. Please try again.',
    update:
      'Sorry, there was an error updating this customer. Please try again.',
    default:
      'Sorry, there was an error saving this customer. Please try again.',
  },
  resetPassword: {
    invalid:
      'You have used an invalid reset password link, please request a new one.',
    default:
      'Sorry, there was an error resetting your password. Please try again.',
  },
  verifyAccount: {
    TOKEN_EXPIRED:
      'Sorry, this link has expired. Please resend the verification email.',
    resend:
      'Sorry, there was an error resending your email verification. Please try again',
    default: 'Sorry, this is an invalid verification link.',
  },
  addUser: {
    default: 'Sorry, there was an error inviting this user. Please try again.',
  },
  userInvite: {
    invalid:
      'You have used an invalid invitation link, please contact your account owner to send a new invitation.',
    login: 'Sorry, there was an error logging you in. Please try again.',
    default: 'Sorry, there was an error registering you. Please try again.',
  },
  changeUserRole: {
    default: 'Sorry, there was an error changing users role. Please try again.',
  },
  updatePassword: {
    default:
      'Sorry, there was an error updating your password. Please try again.',
  },
};

const getFormServiceError = (page: string, error: string, options = {}) => {
  let errorString =
    get(ServiceError[page], error) || get(ServiceError[page], 'default', '');
  if (Object.keys(options).length) {
    errorString = getParsedError(errorString, options);
  }
  return errorString;
};

export { ServiceError, getFormServiceError };
