import { FC, PropsWithChildren } from 'react';

import Modal from '@payaca/components/modal/Modal';
import { HydratedPurchaseOrder } from '@payaca/types/materialsListTypes';
import SendPurchaseOrderControl from '../sendPurchaseOrderControl/SendPurchaseOrderControl';
import './SendPurchaseOrderModal.sass';

type Props = {
  purchaseOrder: HydratedPurchaseOrder;
  isOpen: boolean;
  onSendPurchaseOrderSuccess?: () => void;
  onClose?: () => void;
};
const SendPurchaseOrderModal: FC<Props> = ({
  purchaseOrder,
  isOpen,
  onSendPurchaseOrderSuccess,
  onClose,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={'Send purchase order'}
      className="send-purchase-order-modal"
    >
      <SendPurchaseOrderControl
        purchaseOrder={purchaseOrder}
        onSendPurchaseOrderSuccess={onSendPurchaseOrderSuccess}
      />
    </Modal>
  );
};

export default SendPurchaseOrderModal;
