import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { TimelogsPermissions } from '@payaca/permissions/timelogs/timelogs.permissions';
import { FunctionComponent, useCallback, useState } from 'react';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';

import Modal from '@payaca/components/modal/Modal';
import { archiveTimelogType } from '@payaca/store/timelogs/timelogsActions';
import { PublicTimelogType } from '@payaca/types/timelogs';
import { useDispatch } from 'react-redux';
import CreateEditTimelogTypeControl from '../createEditTimelogTypeControl/CreateEditTimelogTypeControl';
import './ManageableTimelogType.sass';

type Props = {
  timelogType: PublicTimelogType;
  isReadOnly?: boolean;
  onUpdateSuccess?: () => void;
  onArchiveSuccess?: () => void;
  isArchivable?: boolean;
};

const ManageableTimelogType: FunctionComponent<Props> = ({
  timelogType,
  isReadOnly,
  onUpdateSuccess,
  onArchiveSuccess,
  isArchivable,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmArchiveModal, setShowConfirmArchiveModal] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);

  const archive = useCallback(() => {
    setIsArchiving(true);
    dispatch(
      archiveTimelogType.request({
        timelogTypePublicId: timelogType.publicId,
        callback: () => {
          setIsArchiving(false);
          setShowConfirmArchiveModal(false);
          onArchiveSuccess?.();
        },
      })
    );
  }, [timelogType.publicId, onArchiveSuccess]);

  return (
    <div className="manageable-timelog-type">
      {!isEditing && (
        <div className="read-view">
          <span className="type">{timelogType.type}</span>
          {!!timelogType.description?.length && (
            <span className="description">{timelogType.description}</span>
          )}
          {!isReadOnly && (
            <PermissionGuard
              renderIfHasPermissions={[
                TimelogsPermissions.MANAGE_TIMELOG_TYPES,
              ]}
            >
              <div className="actions">
                <Button
                  styleVariant={ButtonStyleVariant.ANCHOR}
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </Button>
                {isArchivable && (
                  <Button
                    styleVariant={ButtonStyleVariant.ANCHOR}
                    onClick={() => setShowConfirmArchiveModal(true)}
                  >
                    Archive
                  </Button>
                )}
              </div>
            </PermissionGuard>
          )}
        </div>
      )}
      {isEditing && (
        <CreateEditTimelogTypeControl
          timelogType={timelogType}
          onCreateEditSuccess={() => {
            onUpdateSuccess?.();
            setIsEditing(false);
          }}
        />
      )}
      <Modal
        title="Archive Timelog type"
        isOpen={showConfirmArchiveModal}
        onClose={() => setShowConfirmArchiveModal(false)}
        size={'xs'}
        actions={
          <>
            <Button onClick={archive} isProcessing={isArchiving}>
              Archive
            </Button>
          </>
        }
      >
        <p>Are you sure you want to archive this Timelog type?</p>
      </Modal>
    </div>
  );
};

export default ManageableTimelogType;
