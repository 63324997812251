import { useTranslation } from '@/i18n';

import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import DefaultAutomationControl from '@/ui/components/defaultAutomationControl/DefaultAutomationControl';
import { getDefaultAutomationDefinition } from '@payaca/store/automations/automationActions';
import { requestGetUploadsForEntity } from '@payaca/store/uploads/uploadsActions';
import {
  AutomationActionTypes,
  HydratedDefaultAutomationDefinition,
  PublicHydratedDefaultAutomationDefinition,
} from '@payaca/types/automationTypes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import './DefaultAutomationPage.sass';

type Props = {
  defaultAutomationDefinitionPublicId: HydratedDefaultAutomationDefinition<AutomationActionTypes>['publicId'];
};

const DefaultAutomationPage: FC<Props> = ({
  defaultAutomationDefinitionPublicId,
}): JSX.Element | null => {
  const translate = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [defaultAutomationDefinition, setDefaultAutomationDefinition] =
    useState<
      PublicHydratedDefaultAutomationDefinition<AutomationActionTypes>
    >();

  useEffect(() => {
    if (defaultAutomationDefinitionPublicId?.length) {
      dispatch(
        getDefaultAutomationDefinition.request({
          defaultAutomationDefinitionPublicId:
            defaultAutomationDefinitionPublicId,
          callback: setDefaultAutomationDefinition,
        })
      );
    }
  }, [defaultAutomationDefinitionPublicId]);

  useEffect(() => {
    if (defaultAutomationDefinition?.automation?.actions[0].uploadIds.length) {
      dispatch(
        requestGetUploadsForEntity(
          defaultAutomationDefinition?.automation?.actions[0].id,
          'automationAction'
        )
      );
    }
  }, [defaultAutomationDefinition?.automation?.actions[0].uploadIds]);

  return (
    <>
      <Helmet
        title={
          defaultAutomationDefinition?.title ||
          translate('pages.automation.title')
        }
      />
      <AuthenticatedPageWrapper
        previousPageNavigationConfig={{
          navigationPromptName: 'Back',
          route: '/automations?tab=default',
        }}
        className="default-automation-page"
      >
        {defaultAutomationDefinition && (
          <DefaultAutomationControl
            defaultAutomationDefinition={defaultAutomationDefinition}
            onUpdateAutomationSuccess={(automation) => {
              defaultAutomationDefinition.automation = automation;
              history.push('/automations?tab=default');
            }}
          />
        )}
      </AuthenticatedPageWrapper>
    </>
  );
};

export default DefaultAutomationPage;
