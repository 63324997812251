import { ApexOptions } from 'apexcharts';
import { FC } from 'react';
import Chart from 'react-apexcharts';
import ReactDOMServer from 'react-dom/server';
import ChartTooltip, { ChartTooltipKey } from './ChartTooltip';

const goalColour = '#6941C6';
const barColour = '#DBCDF8';

const fontStyles = {
  fontSize: '12px',
  fontFamily: 'Inter, "Noto Color Emoji", sans-serif',
  cssClass: '!font-sans',
};

type BarData = {
  label: string;
  xLabel: string;
  value: number;
  strokeColourOverride?: string;
  colourOverride?: string;
  goal: {
    label: string;
    value: number;
    strokeColourOverride?: string;
    colourOverride?: string;
  };
  extraTooltipKeys?: ChartTooltipKey[];
};

export interface IProps {
  height?: number | string;
  width?: number | string;
  barData: BarData[];
  options?: ApexOptions;
  valueFormatter?: (val: number) => string;
  tooltipFormatter?: (val: number) => string;
}

function buildTooltip(
  props: any,
  barData: BarData[],
  tooltipFormatter: (val: number) => string = (val) => val.toString()
) {
  const { dataPointIndex } = props;

  const { xLabel, label, value, colourOverride, goal, extraTooltipKeys } =
    barData[dataPointIndex];

  const goalLabel = goal.label;
  const goalValue = tooltipFormatter(goal.value);
  const _goalColour = goal.strokeColourOverride || goalColour;

  return ReactDOMServer.renderToString(
    <ChartTooltip
      removeWrapperStyles={true}
      title={xLabel}
      keys={[
        { label: goalLabel, value: goalValue, colour: _goalColour },
        {
          label,
          value: tooltipFormatter(value),
          colour: colourOverride || barColour,
        },
        ...(extraTooltipKeys || []),
      ]}
    />
  );
}

const HorizontalBarWithGoals: FC<IProps> = (props) => {
  const { height = 230, width = 230, barData, options } = props;

  const noData = barData.every(
    (data) => data.value === 0 && data.goal.value === 0
  );

  const maxValue = barData.reduce((acc, data) => {
    return Math.max(acc, data.value, data.goal.value);
  }, 0);

  const minValue = barData.reduce((acc, data) => {
    return Math.min(acc, data.value, data.goal.value);
  }, 0);

  return (
    <Chart
      options={{
        chart: {
          height: height,
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '70%',
          },
        },
        grid: {
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        annotations: {
          xaxis: [
            {
              x: 0, // Position of the goal line
              strokeDashArray: 0, // no dash
              borderWidth: 4,
              borderColor: '#8d909d',
            },
          ],
        },
        tooltip: {
          enabled: true,
          custom: function (_props) {
            return buildTooltip(
              _props,
              barData,
              props.tooltipFormatter || props.valueFormatter
            );
          },
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        yaxis: {
          min: minValue,
          max: maxValue,
          labels: {
            style: fontStyles,
          },
        },
        stroke: {
          show: true,
          width: 2,
          dashArray: 3,
        },
        xaxis: {
          categories: barData.map((data) => data.xLabel),
          labels: {
            show: !noData,
            formatter: function (val: string) {
              if (props.valueFormatter) {
                return props.valueFormatter(+val);
              }
              return val;
            },
            style: fontStyles,
          },
        },
        noData: {
          text: 'No data',
          style: fontStyles,
        },
        ...options,
      }}
      series={
        noData
          ? []
          : [
              // The actual amount bar
              {
                name: 'STACKED',
                data: barData.map((data) => ({
                  x: data.xLabel,
                  y: data.value,
                  fillColor: data.colourOverride || barColour,
                  strokeColor: data.strokeColourOverride || 'transparent',
                })),
              },
              // The goal bar
              {
                name: 'STACKED',
                data: barData.map((data) => ({
                  x: data.xLabel,
                  y: data.goal.value,
                  barHeightOffset: -2,
                  fillColor: data.goal.colourOverride || 'transparent',
                  strokeColor: data.goal.strokeColourOverride || goalColour,
                })),
              },
            ]
      }
      type="bar"
      width={width}
      height={height}
    />
  );
};

export default HorizontalBarWithGoals;
