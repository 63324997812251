import { FC, PropsWithChildren, ReactNode } from 'react';

import PageWrapper from '../pageWrapper/PageWrapper';

import './UnauthenticatedPageWrapper.sass';

type Props = {
  className?: string;
  headerContent?: ReactNode;
  titleContent?: ReactNode;
  footerContent?: ReactNode;
  hideBody?: boolean;
};
const UnauthenticatedPageWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  footerContent,
  headerContent,
  hideBody = false,
  titleContent,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <PageWrapper
      className={`unauthenticated-page-wrapper ${className ? className : ''}`}
    >
      <div className={`header-content${hideBody ? ' no-body' : ''}`}>
        <img
          className="payaca-logo"
          src="https://storage.googleapis.com/payaca-prod-assets/payaca-logo-white.png"
        />
      </div>
      {headerContent}
      {!hideBody && (
        <div className="body-content">
          {titleContent && (
            <div className="body-title-content">{titleContent}</div>
          )}
          {children}
        </div>
      )}
      <div className="footer-content">{footerContent}</div>
    </PageWrapper>
  );
};

export default UnauthenticatedPageWrapper;
