import { FunctionComponent, useCallback, useMemo } from 'react';

import {
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';
import SubscriptionProductSelectionControl from './SubscriptionProductSelectionControl';

import { isNotNullish } from '@payaca/utilities/guards';
import { omit } from 'lodash-es';
import useGetAvailableProducts from '../../../../api/queries/subscriptions/useGetAvailableProducts';
import { SubscriptionProduct as GqlSubscriptionProduct } from '../../../../gql/graphql';
import SubscriptionProductsSkeleton from './SubscriptionProductsSkeleton';

export const parseSubscriptionProduct = (
  subscriptionProduct: GqlSubscriptionProduct
) => {
  return {
    stripeId: subscriptionProduct.stripeId,
    name: subscriptionProduct.name,
    hasCustomPrice: !!subscriptionProduct.hasCustomPrice,
    minRequiredUserSeats: subscriptionProduct.minRequiredUserSeats || null,
    numberOfJobsPerMonth: isNotNullish(subscriptionProduct.numberOfJobsPerMonth)
      ? subscriptionProduct.numberOfJobsPerMonth
      : undefined,
    numberOfUserSeats: isNotNullish(subscriptionProduct.numberOfUserSeats)
      ? subscriptionProduct.numberOfUserSeats
      : undefined,
    hasZapier: subscriptionProduct.hasZapier || undefined,
    hasForms: subscriptionProduct.hasForms || undefined,
    numberOfSmsPerMonth: isNotNullish(subscriptionProduct.numberOfSmsPerMonth)
      ? subscriptionProduct.numberOfSmsPerMonth
      : undefined,
    hasCustomAutomations: subscriptionProduct.hasCustomAutomations || undefined,
    displayMetdata: {
      description: subscriptionProduct.displayMetdata.description || null,
      featuresList: subscriptionProduct.displayMetdata.featuresList || [],
    },
    ...(subscriptionProduct.monthlyPrice
      ? {
          monthlyPrice: {
            ...omit(subscriptionProduct.monthlyPrice, ['__typename']),
            additionalUserSeatCost:
              subscriptionProduct.monthlyPrice.additionalUserSeatCost ||
              undefined,
          },
        }
      : {}),
    ...(subscriptionProduct.annualPrice
      ? {
          annualPrice: {
            ...omit(subscriptionProduct.annualPrice, ['__typename']),
            additionalUserSeatCost:
              subscriptionProduct.annualPrice.additionalUserSeatCost ||
              undefined,
          },
        }
      : {}),
  };
};

type Props = {
  recurringInterval: RecurringInterval;
  selectedPriceId?: string;
  onChange: (value: {
    productId: string;
    priceId?: string;
    subscriptionProduct?: SubscriptionProduct;
  }) => void;
  onUpdateExistingSubscription?: () => void;
};
const SubscriptionProductsSelectionControl: FunctionComponent<Props> = ({
  recurringInterval,
  selectedPriceId,
  onChange,
  onUpdateExistingSubscription,
}: Props): JSX.Element => {
  const productPriceKey = useMemo<'annualPrice' | 'monthlyPrice'>(
    () =>
      recurringInterval === RecurringInterval.YEAR
        ? 'annualPrice'
        : 'monthlyPrice',
    [recurringInterval]
  );
  const { data } = useGetAvailableProducts();
  const subscriptionAvailableProducts = data?.availableProducts;

  const subscriptionProducts = subscriptionAvailableProducts
    ?.filter((product) => !!product[productPriceKey])
    .sort((a, b) =>
      (a[productPriceKey] || 0) > (b[productPriceKey] || 0) ? 1 : -1
    );

  const renderSubscriptionProduct = useCallback(
    (productName: string) => {
      const subscriptionProduct = subscriptionProducts?.find(
        (product) => product.name === productName
      );
      if (!subscriptionProduct) {
        return null;
      }

      const parsedSubscriptionProduct =
        parseSubscriptionProduct(subscriptionProduct);

      return (
        <SubscriptionProductSelectionControl
          key={productName}
          subscriptionProduct={parsedSubscriptionProduct}
          recurringInterval={recurringInterval}
          onSelect={(isSelected) =>
            isSelected
              ? onUpdateExistingSubscription?.()
              : onChange({
                  productId: subscriptionProduct.stripeId,
                  priceId:
                    recurringInterval === RecurringInterval.MONTH
                      ? subscriptionProduct.monthlyPrice?.stripeId
                      : subscriptionProduct.annualPrice?.stripeId,
                  subscriptionProduct: parsedSubscriptionProduct,
                })
          }
          isSelected={
            selectedPriceId === subscriptionProduct[productPriceKey]?.stripeId
          }
        />
      );
    },
    [
      subscriptionProducts,
      onChange,
      recurringInterval,
      selectedPriceId,
      onUpdateExistingSubscription,
    ]
  );

  const cols = subscriptionProducts?.length ? subscriptionProducts.length : 3;

  return (
    <div className={`grid md:grid-cols-2 xl:grid-cols-${cols} 2xl:gap-6 gap-4`}>
      {!subscriptionProducts?.length ? (
        <SubscriptionProductsSkeleton />
      ) : (
        subscriptionProducts?.map((product) =>
          renderSubscriptionProduct(product.name)
        )
      )}
    </div>
  );
};

export default SubscriptionProductsSelectionControl;
