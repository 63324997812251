import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import useUpdateAddress from '@/api/mutations/addresses/useUpdateAddress';
import complianceKeys from '@/api/queries/compliance/keyFactory';
import projectKeys from '@/api/queries/project/keyFactory';
import {
  ProjectQuery,
  UpdateAddressInput,
  UpdateAddressMutation,
} from '@/gql/graphql';

const useOptimisticUpdateProjectAddress = (
  projectId: number,
  options?: UseMutationOptions<
    UpdateAddressMutation,
    unknown,
    UpdateAddressInput
  >
) => {
  const queryClient = useQueryClient();

  return useUpdateAddress({
    ...options,
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: projectKeys.overview(projectId),
      });

      // Snapshot the previous value
      const previousProjectOverview = queryClient.getQueryData(
        projectKeys.overview(projectId)
      );

      const newAddress = {
        ...variables,
        fullLocalAddress: [
          variables.line1,
          variables.line2,
          variables.city,
          variables.postalCode,
          variables.country,
        ].join(', '),
        contacts:
          variables.contacts?.map((contact) => ({
            ...contact,
            id: contact.id || 'temp-id',
          })) || [],
      };

      // Optimistically update to the new value
      queryClient.setQueryData<ProjectQuery>(
        projectKeys.overview(projectId),
        (old) => {
          if (!old) return;

          return {
            ...old,
            project: {
              ...old.project,
              addresses: {
                ...old.project.addresses,
                site: old.project.addresses.site.map((i) =>
                  i.id === variables.id ? newAddress : i
                ),
              },
              customer: old.project.customer
                ? {
                    ...old.project.customer,
                    addresses: old.project.customer.addresses.map((i) => {
                      return i.id === variables.id ? newAddress : i;
                    }),
                  }
                : undefined,
            },
          };
        }
      );

      options?.onMutate?.(variables);

      // Return a context object with the snapshotted value
      return { previousProjectOverview };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        projectKeys.overview(projectId),
        // @ts-ignore
        context.previousProjectOverview
      );

      options?.onError?.(err, newTodo, context);
    },
    // Always refetch after error or success:
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.overview(projectId),
      });
      queryClient.invalidateQueries({
        queryKey: complianceKeys.formPrefill(projectId),
      });

      options?.onSettled?.(...args);
    },
  });
};

export default useOptimisticUpdateProjectAddress;
