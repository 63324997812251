import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import proposalLineItemKeys from '@/api/queries/proposalLineItems/keyFactory';
import { graphql } from '@/gql';
import { GetProposalLineItemQuery } from '@/gql/graphql';

const GET_PROPOSAL_LINE_ITEM = graphql(`
  query GetProposalLineItem($proposalLineItemId: ID!) {
    proposalLineItem(id: $proposalLineItemId) {
      id
      internalReference
      description
      price {
        unitPriceExcTax {
          value
          currency {
            id
            code
            exponent
          }
        }
        taxRate {
          id
          label
          percentage
        }
        cisDeductionRate
        discount {
          percentage
          description
        }
      }
      quantity
      thumbnail {
        id
        filename
        url
      }
      selection {
        type
        selected
      }
      materials {
        id
        perUnitMaterialQuantity
        material {
          id
          name
          image {
            id
            thumbnailUrl
          }
          suppliedBy {
            supplier {
              id
              name
            }
            price {
              unitPriceExcTax {
                value
                currency {
                  code
                  exponent
                  id
                }
              }
            }
          }
        }
      }
      lineItem {
        id
      }
    }
  }
`);

const useGetProposalLineItem = (
  opts: {
    proposalLineItemId?: string;
  } = {},
  queryOptions?: UseQueryOptions<GetProposalLineItemQuery>
) => {
  return useQuery({
    enabled: !!opts.proposalLineItemId,
    queryKey: proposalLineItemKeys.proposalLineItem(opts.proposalLineItemId!),
    queryFn: () => {
      return gqlClient.request(GET_PROPOSAL_LINE_ITEM, {
        proposalLineItemId: opts.proposalLineItemId!,
      });
    },
    ...queryOptions,
  });
};

export default useGetProposalLineItem;
