import { call, delay, put, race, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions';

import {
  clearListedMaterialLineItemsPage,
  getListedMaterialLineItemsPageFailure,
  getListedMaterialLineItemsPageSuccess,
} from './listedMaterialILinetemsActions';
import {
  ActionType,
  GetListedMaterialLineItemsRequestData,
  SagaConfig,
} from './listedMaterialLineItemsTypes';

import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const listedSupplierMaterialsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetListedSupplierMaterialsPage(
    action: PayloadAction<
      ActionType.REQUEST_GET_LISTED_MATERIAL_ITEMS_PAGE,
      {
        getListedMaterialLineItemsRequestData: GetListedMaterialLineItemsRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getListedSupplierMaterialsPage,
          action.payload.getListedMaterialLineItemsRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getListedMaterialLineItemsPageFailure(
            new Error('Get listed suppliermaterials page timed out.')
          )
        );
      } else {
        yield put(getListedMaterialLineItemsPageSuccess(response));
      }
    } catch (error: any) {
      yield put(getListedMaterialLineItemsPageFailure(error));
    }
  }

  function* handleAppLogout() {
    yield put(clearListedMaterialLineItemsPage());
  }

  const getListedSupplierMaterialsPage = async (
    getListedMaterialLineItemsRequestData: GetListedMaterialLineItemsRequestData
  ) => {
    const response = await req.get(
      `/line_item_materials/list?pageNumber=${
        getListedMaterialLineItemsRequestData.pageNumber
      }&pageSize=${
        getListedMaterialLineItemsRequestData.pageSize
      }&lineItemSearchTerm=${
        getListedMaterialLineItemsRequestData.lineItemSearchTerm || ''
      }&materialSearchTerm=${
        getListedMaterialLineItemsRequestData.materialSearchTerm || ''
      }&sortDirection=${
        getListedMaterialLineItemsRequestData.sortDirection || ''
      }&sortBy=${
        getListedMaterialLineItemsRequestData.sortBy || ''
      }&lineItemId=${
        getListedMaterialLineItemsRequestData.lineItemId || ''
      }&materialId=${getListedMaterialLineItemsRequestData.materialId || ''}`
    );
    return await response.json();
  };

  return function* () {
    yield takeEvery(
      ActionType.REQUEST_GET_LISTED_MATERIAL_ITEMS_PAGE,
      handleGetListedSupplierMaterialsPage
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default listedSupplierMaterialsSagaCreator;
