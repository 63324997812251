import Button from '@payaca/components/plButton/Button';
import { EBtnColour } from '@payaca/components/plButton/useButtonClassName';
import Modal from '@payaca/components/plModal/Modal';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

export type DeleteMaterialListMaterial = {
  id: string;
  material: {
    name: string;
  };
  materialPurchaseIntents: unknown[];
  materialPurchaseRecords: unknown[];
};

export const DeleteMaterialsListMaterialModal: FC<{
  materialsListMaterial: DeleteMaterialListMaterial;
  onClose: () => void;
  isOpen: boolean;
  onDeleteSuccess: () => void;
}> = ({ isOpen, onClose, materialsListMaterial, onDeleteSuccess }) => {
  const canDelete =
    (!materialsListMaterial.materialPurchaseIntents ||
      materialsListMaterial.materialPurchaseIntents.length === 0) &&
    (!materialsListMaterial.materialPurchaseRecords ||
      materialsListMaterial.materialPurchaseRecords.length === 0);
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDelete = useCallback(() => {
    if (isProcessing) return;
    setIsProcessing(true);
    dispatch(
      materialsListActions.requestDeleteMaterialsListMaterial(
        +materialsListMaterial.id,
        () => {
          setIsProcessing(false);
          onDeleteSuccess();
        },
        () => {
          setIsProcessing(false);
          onClose();
        }
      )
    );
  }, [materialsListMaterial.id, onClose, onDeleteSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Remove Material">
      <Modal.Body>
        {canDelete ? (
          <>
            Are you sure you want to remove{' '}
            <strong>{materialsListMaterial.material.name}</strong>?
          </>
        ) : (
          'This material cannot be removed as it has already been purchased or included in a Purchase Order'
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          {canDelete ? (
            <Button
              colour={EBtnColour.Red}
              onClick={handleDelete}
              isProcessing={isProcessing}
            >
              Remove All
            </Button>
          ) : (
            <Button onClick={onClose}>Ok</Button>
          )}
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};
