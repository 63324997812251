import qs from 'qs';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { getUrlSearchParam } from '@payaca/helpers/urlHelper';

import IndustryRegisterForm from '@/ui/components/industryRegisterForm/IndustryRegisterForm';
import UserRegisterForm from '@/ui/components/userRegisterForm/UserRegisterForm';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';

import {
  IndustryProfessionMap,
  IndustryTypes,
  NumberOfEmployees,
} from '@payaca/types/companyInformationTypes';

import SocialAuthButtons from '@/ui/components/socialAuthButtons/SocialAuthButtons';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { useWindowDimensions } from '@payaca/hooks/useWindowDimensions';
import { getCookie } from '@payaca/utilities/cookies';
import './RegisterPage.sass';

enum PageState {
  INDUSTRY_FORM = 'industry-form',
  USER_FORM = 'user-form',
}

type Props = {
  location: any;
  history: any;
};
const RegisterPage: FC<Props> = ({ location, history }: Props): JSX.Element => {
  const { width } = useWindowDimensions();
  const [pageState, setPageState] = useState(PageState.INDUSTRY_FORM);
  const [initialRegisterFormState, setInitialRegisterFormState] = useState<{
    firstName?: string | null;
    lastName?: string | null;
    emailAddress?: string | null;
    contactNumber?: string;
    numberOfEmployees?: NumberOfEmployees;
    industryType?: IndustryTypes | null;
    industryTypeOther?: string;
    referralToken?: string;
  }>({});

  useEffect(() => {
    // If we landed on this page _without_ UTM parameters in the URL,
    // but there are UTMs saved in a cookie from a previous landing page visit,
    // add them to the URL and reload the page, for analytics purposes.
    try {
      const cookie = getCookie('payaca-utms-v0');
      if (!cookie) {
        return;
      }
      const utms = JSON.parse(cookie);
      const searchParams = new URLSearchParams(window.location.search);
      if (
        utms.referralToken !==
          (searchParams.get('referralToken') ?? undefined) ||
        utms.gclid !== (searchParams.get('gclid') ?? undefined) ||
        utms.utm_source !== (searchParams.get('utm_source') ?? undefined) ||
        utms.utm_medium !== (searchParams.get('utm_medium') ?? undefined) ||
        utms.utm_campaign !== (searchParams.get('utm_campaign') ?? undefined) ||
        utms.utm_term !== (searchParams.get('utm_term') ?? undefined) ||
        utms.utm_content !== (searchParams.get('utm_content') ?? undefined)
      ) {
        window.location.search = new URLSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          ...utms,
        }).toString();
      }
    } catch (err) {
      // Just swallow the error; it's not mission-critical :-P
    }
  }, []);

  const [callbackRegisterErrorMessage, setCallbackRegisterErrorMessage] =
    useState<string>();

  // allow for register form to be pre-populated
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);

      // check industryType in url
      let industryType: null | string | IndustryTypes =
        params.get('industryType');
      if (industryType) {
        if (
          Object.values(IndustryTypes).includes(
            industryType.toUpperCase() as unknown as IndustryTypes
          )
        ) {
          // valid industry type
          setPageState(PageState.USER_FORM);
          industryType = industryType.toUpperCase() as IndustryTypes;
        } else {
          // invalid industry type provided
          industryType = null;
        }
      }
      // check numberOfEmployees in url
      let numberOfEmployees: null | string | NumberOfEmployees =
        params.get('numberOfEmployees');
      if (
        !Object.values(NumberOfEmployees).includes(
          numberOfEmployees as unknown as NumberOfEmployees
        )
      ) {
        // unknown number of employees in query, set to null
        numberOfEmployees = null;
      }
      // get user form values
      let contactNumber = params.get('phone');
      if (contactNumber?.startsWith('07')) {
        contactNumber = `+44${contactNumber.slice(1)}`;
      }
      setInitialRegisterFormState({
        firstName: params.get('firstname'),
        lastName: params.get('lastname'),
        emailAddress: params.get('email'),
        contactNumber: contactNumber || undefined,
        industryType: industryType as IndustryTypes,
        numberOfEmployees: numberOfEmployees as NumberOfEmployees,
      });
    }
  }, [location]);

  useEffect(() => {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.error) {
      setCallbackRegisterErrorMessage(
        decodeURIComponent(query.error as string)
      );
    }
  }, []);

  const goToLoginPage = useCallback(() => {
    history.push('/login');
  }, [history]);

  const referralToken = useMemo(() => {
    let token = null;
    token = getUrlSearchParam(location.search, 'referralToken');
    if (!token) {
      token = getUrlSearchParam(document.referrer, 'referralToken');
    }
    return token;
  }, [location]);

  const industryTitle = useMemo(() => {
    const sharedText = "You're 30 seconds away from sending your first quote";
    if (width > 500) {
      return (
        <>
          <h1>{"Try the UK's best job management software for free"}</h1>
          <p>{sharedText}</p>
        </>
      );
    }
    return <h1>{sharedText}</h1>;
  }, [width]);

  return (
    <UnauthenticatedPageWrapper
      className="register-page"
      titleContent={
        pageState === PageState.INDUSTRY_FORM ? (
          industryTitle
        ) : pageState === PageState.USER_FORM ? (
          <h1>
            For{' '}
            <span className="industry-name">
              {initialRegisterFormState?.industryType
                ? IndustryProfessionMap[initialRegisterFormState.industryType]
                : 'business leaders'}
            </span>{' '}
            who want to work smart
          </h1>
        ) : null
      }
      footerContent={
        <>
          {pageState === PageState.USER_FORM && (
            <>
              <div className="social-sign-up-separator-wrapper">
                <span>or sign up with</span>
              </div>
              <SocialAuthButtons
                isLogin={false}
                additionalPayload={{
                  numberOfEmployees: initialRegisterFormState.numberOfEmployees,
                  industryType: initialRegisterFormState.industryType,
                  industryTypeOther: initialRegisterFormState.industryTypeOther,
                  referralToken,
                }}
              />
            </>
          )}
          <span className="login-prompt">
            Already have an account?{' '}
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={goToLoginPage}
              colourVariant={ButtonColourVariant.WHITE}
            >
              Log in
            </Button>
          </span>
        </>
      }
    >
      {pageState === PageState.INDUSTRY_FORM && (
        <>
          <IndustryRegisterForm
            onIndustryInfoChanged={(change) => {
              setInitialRegisterFormState({
                ...initialRegisterFormState,
                ...change,
              });
            }}
            onNextButton={() => setPageState(PageState.USER_FORM)}
          />
        </>
      )}
      {pageState === PageState.USER_FORM && (
        <>
          <UserRegisterForm
            initialFormState={initialRegisterFormState}
            referralToken={referralToken}
          />
        </>
      )}
      {pageState === PageState.USER_FORM && callbackRegisterErrorMessage && (
        <ErrorMessage message={callbackRegisterErrorMessage} />
      )}
    </UnauthenticatedPageWrapper>
  );
};

export default RegisterPage;
