import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, useCallback, useMemo } from 'react';
import DropdownField from '../dropdownField/DropdownField';
import './InputFilterPrefix.sass';

type Props = {
  filterGroups: {
    [key: string]: {
      label: string;
      options: { value: any; label: string }[];
    };
  };
  filterValues: { [key: string]: any[] };
  onChange: (value: { [key: string]: any }) => void;
};

const InputFilterPrefix: FunctionComponent<Props> = ({
  filterGroups,
  filterValues,
  onChange,
}: Props): JSX.Element | null => {
  const optionGroups = useMemo(() => {
    return Object.keys(filterGroups).map((groupName, index) => {
      return {
        name: filterGroups[groupName].label,
        id: index + 1, // groupId can't be zero
      };
    });
  }, [filterGroups]);

  const options = useMemo(() => {
    return Object.keys(filterGroups)
      .map((groupName, index) => {
        const fg = filterGroups[groupName];
        return fg.options.map((o) => {
          return {
            label: o.label,
            value: JSON.stringify({
              groupName: groupName,
              value: o.value,
            }),
            optionGroupId: index + 1, // groupId can't be zero
          };
        });
      })
      .flat();
  }, [filterGroups]);

  const value = useMemo(() => {
    const v: string[] = [];

    for (const key in filterValues) {
      v.push(
        ...filterValues[key].map((x) => {
          return JSON.stringify({
            groupName: key,
            value: x,
          });
        })
      );
    }

    return v;
  }, [filterValues]);

  const renderValue = useCallback((value: any) => {
    return (
      <div>
        <FontAwesomeIcon icon={faFilter} size="lg" />
        {!!value.length && (
          <div className="selected-filters-count">
            <small>{value.length}</small>
          </div>
        )}
      </div>
    );
  }, []);

  const handleOnChange = useCallback(
    (value: any) => {
      const v: { [key: string]: any } = {};

      value.filters.forEach((x: string) => {
        const x2 = JSON.parse(x);
        if (!(x2.groupName in v)) {
          v[x2.groupName] = [x2.value];
        } else {
          v[x2.groupName].push(x2.value);
        }
      });

      onChange(v);
    },
    [onChange]
  );

  return (
    <div className="input-filter-prefix">
      <DropdownField
        name="filters"
        options={options}
        optionGroups={optionGroups}
        multiple={true}
        displayCheckboxes={true}
        value={value}
        onChange={handleOnChange}
        additionalSelectProps={{
          renderValue: renderValue,
          IconComponent: () => null,
          inputProps: { sx: { padding: '0 !important' } },
          title: 'Filters',
        }}
      />
    </div>
  );
};

export default InputFilterPrefix;
