import { useSelector } from '@/api/state';
import { actions as usersActions } from '@/api/users';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Button from '@payaca/components/plButton/Button';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import Field from '@payaca/components/plField/Field';
import Modal from '@payaca/components/plModal/Modal';
import Select from '@payaca/components/plSelect/Select';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import {
  getIsRequiredFieldValidator,
  getMustEqualValueValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { getConnectedAccountingIntegrationData } from '@payaca/store/account/accountActions';
import {
  getTaxRates,
  updateTaxRatesAccountingIntegrationIdentifiers,
} from '@payaca/store/tax-rates/actions';
import {
  AccountingIntegrationAccount,
  AccountingIntegrationTaxRate,
  AccountingIntegrationType,
} from '@payaca/types/accountingIntegrationTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { TaxRate } from '@payaca/types/taxRateTypes';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

type ConfigurationType = {
  integrationTaxRates: AccountingIntegrationTaxRate[];
  integrationAccounts: AccountingIntegrationAccount[];
  showSelectAccount: boolean;
  isSelectAccountDisabled: boolean;
};

type FormState = {
  revenueAccountIdentifier?: AccountingIntegrationAccount['identifier'];
  expenseAccountIdentifier?: AccountingIntegrationAccount['identifier'];
  taxRateIdentifiers: Record<
    TaxRate['id'],
    Partial<
      Record<
        'revenue' | 'expenses',
        AccountingIntegrationTaxRate['identifier'] | null
      >
    >
  >;
  isConfirmedCorrect: boolean;
};

const getOptionFromIntegrationAccount = (a: AccountingIntegrationAccount) => {
  return {
    label: `${a.code ? `${a.code} ` : ``}${a.name}`,
    value: a.identifier,
  };
};

export const ConfigureAccountingIntegrationControl: FC<{
  integrationType: AccountingIntegrationType;
  onSuccess?: () => void;
}> = ({ integrationType, onSuccess }) => {
  const dispatch = useDispatch();

  const [isGettingIntegrationData, setIsGettingIntegrationData] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [integrationAccounts, setIntegrationAccounts] = useState<
    AccountingIntegrationAccount[]
  >([]);
  const [integrationTaxRates, setIntegrationTaxRates] = useState<
    AccountingIntegrationTaxRate[]
  >([]);

  const taxRates = useSelector((state) => state.taxRates.store);

  const xeroConnection = useSelector((state: any) => {
    return state.users.myProfile.accounts[0].integrations?.xero;
  });
  const defaultXeroRevenueAccountCode = useMemo(
    () => xeroConnection?.xeroDefaultRevenueAccountCode,
    [xeroConnection]
  );

  useEffect(() => {
    setIsGettingIntegrationData(true);
    dispatch(
      getConnectedAccountingIntegrationData.request({
        integrationType: integrationType,
        callback: (data) => {
          setIntegrationAccounts(data.accounts);
          setIntegrationTaxRates(data.taxRates);
          setIsGettingIntegrationData(false);
        },
      })
    );
  }, [integrationType]);

  useEffect(() => {
    dispatch(getTaxRates.request());
  }, []);

  const initialTaxRateIdentifiers = useMemo(() => {
    const taxRateIdentifiers: FormState['taxRateIdentifiers'] = {};

    Object.entries(taxRates).forEach(([taxRateId, taxRate]) => {
      taxRateIdentifiers[taxRate.id] =
        taxRate.accountingIntegrationIdentifiers?.[integrationType] || {};
    });

    return taxRateIdentifiers;
  }, [taxRates]);

  useEffect(() => {
    dispatch(getTaxRates.request());
  }, []);

  const onSubmit = (data: FormState) => {
    setIsSubmitting(true);
    const taxRateAccountingIntegrationIdentifiers: Record<
      TaxRate['id'],
      TaxRate['accountingIntegrationIdentifiers']
    > = {};
    Object.entries(data.taxRateIdentifiers).forEach(
      ([taxRateId, integrationIdentifiers]) => {
        taxRateAccountingIntegrationIdentifiers[+taxRateId] = {
          [integrationType]: integrationIdentifiers,
        };
      }
    );

    dispatch(
      updateTaxRatesAccountingIntegrationIdentifiers.request({
        accountingIntegrationIdentifiers:
          taxRateAccountingIntegrationIdentifiers,
        callback: () => {
          if (integrationType === 'xero') {
            dispatch(
              usersActions.updateXeroSettings(
                {
                  xeroDefaultRevenueAccountCode: data.revenueAccountIdentifier,
                },
                () => {
                  setIsSubmitting(false);
                  onSuccess?.();
                }
              )
            );
          } else {
            setIsSubmitting(false);
            onSuccess?.();
          }
        },
      })
    );
  };

  const revenueConfiguration: ConfigurationType = {
    integrationTaxRates: integrationTaxRates.filter((x) => x.canApplyToRevenue),
    integrationAccounts: integrationAccounts.filter((x) => x.isRevenue),
    showSelectAccount: integrationType === 'xero',
    isSelectAccountDisabled: integrationType !== 'xero',
  };

  const expenseConfiguration: ConfigurationType = {
    integrationTaxRates: integrationTaxRates.filter(
      (x) => x.canApplyToExpenses
    ),
    integrationAccounts: integrationAccounts.filter((x) => x.isExpense),
    showSelectAccount: false,
    isSelectAccountDisabled: true,
  };

  const initialFormState: FormState = {
    revenueAccountIdentifier:
      integrationType === 'xero' ? defaultXeroRevenueAccountCode : undefined,
    expenseAccountIdentifier: undefined,
    taxRateIdentifiers: initialTaxRateIdentifiers,
    isConfirmedCorrect: false,
  };

  const fieldValidators = useMemo(() => {
    return {
      revenueAccountIdentifier:
        revenueConfiguration.showSelectAccount &&
        !revenueConfiguration.isSelectAccountDisabled
          ? [
              getIsRequiredFieldValidator({
                customErrorMessage: 'Default account required',
              }),
            ]
          : [],
      expenseAccountIdentifier:
        expenseConfiguration.showSelectAccount &&
        !expenseConfiguration.isSelectAccountDisabled
          ? [
              getIsRequiredFieldValidator({
                customErrorMessage: 'Default account required',
              }),
            ]
          : [],
      isConfirmedCorrect: [
        getMustEqualValueValidator(true, {
          customErrorMessage: 'You must confirm your tax rates are correct',
        }),
      ],
    };
  }, []);

  return (
    <div>
      {isGettingIntegrationData && (
        <div className="flex items-center justify-center">
          <MiniLoader />
        </div>
      )}
      {!isGettingIntegrationData && (
        <ValidatedForm<FormState>
          fieldValidators={fieldValidators}
          initialFormState={initialFormState}
          renderFormContents={(
            isValid,
            formState,
            validationState,
            touchedState,
            onFieldChange,
            onFieldTouch
          ) => {
            return (
              <>
                <Modal.Body>
                  <FormContents
                    isValid={isValid}
                    formState={formState}
                    validationState={validationState}
                    touchedState={touchedState}
                    onFieldChange={onFieldChange}
                    onFieldTouch={onFieldTouch}
                    onSubmit={onSubmit}
                    isSubmitting={isSubmitting}
                    expenseConfiguration={expenseConfiguration}
                    revenueConfiguration={revenueConfiguration}
                  />
                </Modal.Body>

                <Modal.Footer>
                  <Modal.Footer.Actions>
                    <Button
                      disabled={!isValid}
                      isProcessing={isSubmitting}
                      onClick={() => !isSubmitting && onSubmit(formState)}
                    >
                      Save
                    </Button>
                  </Modal.Footer.Actions>
                </Modal.Footer>
              </>
            );
          }}
        />
      )}
    </div>
  );
};

const FormContents: FC<{
  isValid: boolean;
  formState: FormState;
  validationState: {
    [key: string]: FieldValidationResult;
  };
  touchedState: {
    [key: string]: boolean;
  };
  onFieldChange: (value: { [key: string]: any }) => void;
  onFieldTouch: (fieldName: string | string[]) => void;
  onSubmit: (data: any) => void;
  isSubmitting: boolean;
  revenueConfiguration: ConfigurationType;
  expenseConfiguration: ConfigurationType;
}> = ({
  isValid,
  formState,
  validationState,
  touchedState,
  onFieldChange,
  onFieldTouch,
  onSubmit,
  isSubmitting,
  expenseConfiguration,
  revenueConfiguration,
}) => {
  const taxRates = Object.values(useSelector((state) => state.taxRates.store));

  return (
    <div className="flex flex-col gap-6">
      <div className="prose">
        <h3 className="mb-1">{'Sales'}</h3>
        <p>This relates to the things you sell, invoice and quote for.</p>
      </div>
      {revenueConfiguration.showSelectAccount && (
        <Field
          name="revenueAccountIdentifier"
          validationState={
            validationState?.['revenueAccountIdentifier']?.isValid === false
              ? {
                  isValid: false,
                  validationMessages:
                    validationState?.['revenueAccountIdentifier']?.errors,
                }
              : undefined
          }
        >
          <Field.Label>Default account</Field.Label>
          <Select
            options={revenueConfiguration.integrationAccounts.map(
              getOptionFromIntegrationAccount
            )}
            value={formState.revenueAccountIdentifier}
            disabled={revenueConfiguration.isSelectAccountDisabled}
            onChange={(value) => {
              onFieldChange({
                revenueAccountIdentifier: value,
              });
            }}
          />
        </Field>
      )}
      <div className="grid grid-cols-[minmax(0,_100px)_auto_minmax(0,_1fr)] items-center gap-x-6 gap-y-4">
        {taxRates.map((taxRate) => {
          return (
            <TaxRateControl
              key={taxRate.id}
              taxRate={taxRate}
              integrationIdentifier={
                formState.taxRateIdentifiers[taxRate.id].revenue || undefined
              }
              integrationTaxRates={revenueConfiguration.integrationTaxRates}
              onChange={(value) => {
                onFieldChange({
                  [`taxRateIdentifiers[${taxRate.id}].revenue`]: value,
                });
              }}
            />
          );
        })}
      </div>
      <hr />
      <div className="prose">
        <h3 className="mb-1">{'Purchases'}</h3>
        <p>This relates to the purchases you make, such as Materials.</p>
      </div>
      {expenseConfiguration.showSelectAccount && (
        <Field
          name="expenseAccountIdentifier"
          validationState={
            validationState?.['expenseAccountIdentifier']?.isValid === false
              ? {
                  isValid: false,
                  validationMessages:
                    validationState?.['expenseAccountIdentifier']?.errors,
                }
              : undefined
          }
        >
          <Field.Label>Default account</Field.Label>
          <Select
            options={expenseConfiguration.integrationAccounts.map(
              getOptionFromIntegrationAccount
            )}
            value={formState.expenseAccountIdentifier}
            disabled={expenseConfiguration.isSelectAccountDisabled}
            onChange={(value) => {
              onFieldChange({
                expenseAccountIdentifier: value,
              });
            }}
          />
        </Field>
      )}
      <div className="grid grid-cols-[minmax(0,_100px)_auto_minmax(0,_1fr)] items-center gap-x-6 gap-y-4">
        {taxRates
          .filter((taxRate) => !taxRate.isReverseCharge)
          .map((taxRate) => {
            return (
              <TaxRateControl
                key={taxRate.id}
                taxRate={taxRate}
                integrationIdentifier={
                  formState.taxRateIdentifiers[taxRate.id].expenses || undefined
                }
                integrationTaxRates={expenseConfiguration.integrationTaxRates}
                onChange={(value) => {
                  onFieldChange({
                    [`taxRateIdentifiers[${taxRate.id}].expenses`]: value,
                  });
                }}
              />
            );
          })}
      </div>
      <Checkbox
        checked={formState.isConfirmedCorrect}
        label={`I confirm my tax rates are correct. Otherwise I will have to do manual reconciliation.`}
        onChange={() => {
          onFieldChange({
            isConfirmedCorrect: !formState.isConfirmedCorrect,
          });
        }}
      />
    </div>
  );
};

const TaxRateControl: FC<{
  taxRate: TaxRate;
  integrationIdentifier?: string;
  integrationTaxRates: AccountingIntegrationTaxRate[];
  onChange: (value?: string) => void;
}> = ({ taxRate, integrationIdentifier, integrationTaxRates, onChange }) => {
  const taxName = useMemo(() => {
    let name = `${taxRate.name}`;
    if (!taxRate.isIncluded) {
      name = `No ${name}`;
    } else {
      if (taxRate.isReverseCharge) {
        name = `Reverse charge ${name}`;
      }
      name = `${taxRate.percentage}% ${name}`;
    }

    return name;
  }, [taxRate]);

  return (
    <>
      <div className="text-base">{taxName}</div>
      <UntitledIcon name="arrow-right" className="h-4 w-4" />
      <Select
        value={integrationIdentifier}
        options={integrationTaxRates.map((itr) => {
          return {
            label: `${itr.name}`,
            value: itr.identifier,
          };
        })}
        onChange={(value) => onChange(value as string)}
      />
    </>
  );
};
