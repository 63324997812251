import {
  faFile,
  faFileDownload,
  faFileImage,
  faFilePdf,
  faFileWord,
} from '@fortawesome/free-solid-svg-icons';
import { JobAttachment } from '@payaca/types/jobTypes';
import values from 'lodash.values';

export const ImageFileExtensions = ['.png', '.jpeg', '.jpg', '.webp', '.heic'];
export const VideoFileExtensions = ['.mov', '.mp4'];
export const DocumentFileExtensions = ['.doc', '.docx', '.pdf'];
export const OtherFileExtensions = ['.svg', '.txt', '.xls', '.xlsx', '.csv'];

const FileUploadType = {
  JOB_ATTACHMENT: 'jobAttachment',
  BUSINESS_TERMS: 'businessTerms',
  BUSINESS_ATTACHMENTS: 'businessAttachments',
  ACCOUNT_LOGO: 'accountLogo',
  LINE_ITEM_ATTACHMENT: 'lineItemAttachements',
  DEAL_DOCUMENT_FILE: 'dealDocumentFile',
};

const bytesInKb = 1024;

const FileSizeLimit = {
  [FileUploadType.JOB_ATTACHMENT]: 20 * bytesInKb * bytesInKb, // 20mb
  [FileUploadType.BUSINESS_TERMS]: 20 * bytesInKb * bytesInKb, // 20mb
  [FileUploadType.BUSINESS_ATTACHMENTS]: 20 * bytesInKb * bytesInKb, // 20mb
  [FileUploadType.ACCOUNT_LOGO]: 20 * bytesInKb * bytesInKb, // 20mb
  [FileUploadType.LINE_ITEM_ATTACHMENT]: 20 * bytesInKb * bytesInKb, // 20mb
  [FileUploadType.DEAL_DOCUMENT_FILE]: 20 * bytesInKb * bytesInKb, // 20mb
};
// Postmark email has total email size limit of 10mb, temporarily reduced file size to 7mb to allow for file size increase on base64 encoding which is used to attach to postmark email.

const getMbFileLimit = (fileLimit: number): string => {
  return `${fileLimit / bytesInKb / bytesInKb}mb`;
};

const ImageFileType = {
  JPEG: {
    name: ['image/jpeg', '.jpeg'],
    icon: faFileImage,
  },
  PNG: {
    name: ['image/png', '.png'],
    icon: faFileImage,
  },
};

const FileType = {
  ...ImageFileType,
  PDF: {
    name: ['application/pdf', '.pdf'],
    icon: faFilePdf,
  },
  DOC: {
    name: ['application/msword', '.doc'],
    icon: faFileWord,
  },
};

const getFileType = (url: string) => {
  const filename = url && url.split('/').pop();
  // Infer the type of the image
  if (filename) {
    const match = /\.(\w+)$/.exec(filename);
    return match && match[0];
  }
  return null;
};

const getSpecificFileTypeIcon = (file: any | JobAttachment) => {
  let type: string | null = null;

  if (file.file) {
    // locally uploaded file (i.e not uploaded to BE yet)
    type = file.file.type;
  } else {
    // uploaded file with a url
    const url = file.attachmentUrl || file.termUrl;
    if (file.type) {
      type = file.type;
    } else if (url) {
      type = getFileType(url);
    }
  }
  return values(FileType).find((v: { name: string[]; icon: any }) =>
    v.name.find((n) => n === type)
  );
};

// Get file type icon, defaults to generic file icon if type not found
const getFileTypeIcon = (file: any | JobAttachment) => {
  const found = getSpecificFileTypeIcon(file);
  return found ? found.icon : faFile;
};

// Get file type icon, defaults to download file icon if type not found
const getFileTypeIconDownload = (file: JobAttachment) => {
  const found = getSpecificFileTypeIcon(file);
  return found ? found.icon : faFileDownload;
};

const FileTypeExtensionsMap = {
  image: ImageFileExtensions,
  video: VideoFileExtensions,
  document: DocumentFileExtensions,
  other: OtherFileExtensions,
};
export const getAcceptedFileTypes = (
  types: ('video' | 'document' | 'image' | 'other')[]
) => {
  return types.reduce((acc, t) => {
    const typeExtensions = FileTypeExtensionsMap[t];
    if (typeExtensions) {
      acc.push(...typeExtensions);
    }
    return acc;
  }, [] as Array<string>);
};

export {
  FileSizeLimit,
  FileType,
  FileUploadType,
  getFileType,
  getFileTypeIcon,
  getFileTypeIconDownload,
  getMbFileLimit,
  ImageFileType,
};
