import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import useButtonClassName, { TBtnVariantProps } from './useButtonClassName';

export interface ILinkButtonProps extends LinkProps, TBtnVariantProps {
  disabled?: boolean;
}

const LinkButton: FC<ILinkButtonProps> = (props) => {
  const { disabled, variant, size, colour, children, className, ...rest } =
    props;

  const variantClassName = useButtonClassName({
    variant,
    size,
    colour,
  });

  // Anchor Links can't be disabled, render an aria-disabled span instead!
  if (disabled) {
    return (
      <span
        className={variantClassName + (className ? ' ' + className : '')}
        aria-disabled="true"
      >
        {children}
      </span>
    );
  }

  return (
    <Link
      className={variantClassName + (className ? ' ' + className : '')}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default LinkButton;
