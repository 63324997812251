import { FunctionComponent, useMemo } from 'react';

import JobDocumentPaidStamp from '../paidStamp/PaidStamp';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import { isInvoiceConfirmedPaid } from '@payaca/helpers/jobHelperV2';
import {
  getCompletedDepositPaymentValueFromJobPayments,
  getTotalCompletedPaymentValueFromJobPayments,
} from '@payaca/helpers/jobPaymentHelper';

import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { getPaymentScheduleStagesWithValues } from '@payaca/helpers/paymentScheduleHelper';
import { BrandingColourContext } from '@payaca/types/accountBrandTypes';
import { Account } from '@payaca/types/accountTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import {
  JobContent,
  JobLineItem,
  JobLineItemGroup,
} from '@payaca/types/jobContentTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import { Job } from '@payaca/types/jobTypesV2';
import { PaymentSchedule } from '@payaca/types/payment-schedule';
import { sortByKey } from '@payaca/utilities/sortable';
import Alert, { EAlertColour } from '../plAlert/Alert';
import Card from '../plCard/Card';
import './JobDocumentTotals.sass';

type Props = {
  account?: Pick<Account, 'region'>;
  hasAnyReverseChargeVat?: boolean;
  isQuote: boolean;
  job: Job;
  jobContent: JobContent;
  jobPayments: JobPayment[];
  brandingColourContext?: BrandingColourContext;
  jobLineItems: JobLineItem[];
  jobLineItemGroups: JobLineItemGroup[];
  hideVat?: boolean;
  hideItemPrices?: boolean;
  paymentSchedule?: PaymentSchedule;
};

const JobDocumentTotals: FunctionComponent<Props> = ({
  account,
  hasAnyReverseChargeVat = false,
  isQuote,
  job,
  jobContent,
  jobPayments,
  jobLineItems,
  jobLineItemGroups,
  brandingColourContext,
  hideVat = false,
  hideItemPrices = false,
  paymentSchedule,
}: Props): JSX.Element => {
  const completedDepositPaymentValue = useMemo(() => {
    return getCompletedDepositPaymentValueFromJobPayments(jobPayments);
  }, [jobPayments]);

  const totalCompletedPaymentValue = useMemo(() => {
    return getTotalCompletedPaymentValueFromJobPayments(jobPayments);
  }, [jobPayments]);

  const depositRequired = useMemo(() => {
    return !!(
      isQuote &&
      jobContent.calculatedDepositAmount &&
      completedDepositPaymentValue < jobContent.calculatedDepositAmount
    );
  }, [job, isQuote, completedDepositPaymentValue, jobContent]);

  const amountDue = useMemo(() => {
    return jobContent.total - totalCompletedPaymentValue;
  }, [job, totalCompletedPaymentValue, jobContent]);

  const shouldRenderJobOverviewPaidStamp = useMemo(
    () =>
      (!isQuote || depositRequired) &&
      isInvoiceConfirmedPaid(jobContent, jobPayments),
    [jobPayments, jobContent, isQuote, depositRequired]
  );

  const depositPercentage = useMemo(() => {
    return jobContent.depositPercentage
      ? ` (${jobContent.depositPercentage}%)`
      : '';
  }, [jobContent]);

  const vatRegionalLabel = useMemo(
    () =>
      getRegionalTextString(account?.region, RegionalStrings.VALUE_ADDED_TAX),
    [account]
  );

  const depositPaymentScheduleStageWithValue = useMemo(() => {
    if (!paymentSchedule?.stages?.length) return null;

    const depositStageWithValue = getPaymentScheduleStagesWithValues(
      paymentSchedule,
      jobContent.total
    ).find((s) => s.isDeposit);
    return depositStageWithValue || null;
  }, [paymentSchedule, jobContent.total]);

  const selectedItems = useMemo(() => {
    const arr: JobLineItem[] = [];

    jobLineItemGroups
      .sort(sortByKey('positionIndex'))
      .forEach((jobLineItemGroup) => {
        const groupLineItem = jobLineItems
          .sort(sortByKey('positionIndex'))
          .filter(
            (jobLineItem) =>
              jobLineItemGroup.jobLineItemIds.includes(jobLineItem.id) &&
              (jobLineItem.isSelected ||
                (!jobLineItem.isOptional && !jobLineItem.isMultipleChoice))
          );

        arr.push(...groupLineItem);
      });

    return arr;
  }, [jobLineItemGroups, jobLineItems]);

  const hideVatAndIsQuote = hideVat && isQuote;

  return (
    <>
      {shouldRenderJobOverviewPaidStamp && <JobDocumentPaidStamp />}

      <Card.Title className="!font-bold">Summary</Card.Title>

      <hr className="hr my-4" />

      {/*Totals*/}
      <table className="w-full text-base">
        <tbody className="text-right">
          {!hideVatAndIsQuote && (
            <tr>
              <td className="pr-2">Subtotal:</td>
              <td className="align-bottom">
                {currencyPrice(jobContent.subtotalIncMarkup, account?.region)}
              </td>
            </tr>
          )}
          {!!jobContent.calculatedDiscountAmount && (
            <tr>
              <td className="pr-2">
                Discount
                {jobContent.discountPercentage
                  ? ` (${jobContent.discountPercentage}%):`
                  : ':'}
              </td>
              <td className="align-bottom">
                {currencyPrice(
                  -jobContent.calculatedDiscountAmount,
                  account?.region
                )}
              </td>
            </tr>
          )}
          {jobContent.hasAnyCisDeduction && (
            <tr>
              <td className="pr-2">CIS suffered:</td>
              <td className="align-bottom">
                {currencyPrice(-jobContent.cisTotal, account?.region)}
              </td>
            </tr>
          )}
          {!hideVatAndIsQuote && jobContent.isAnyVatIncluded && (
            <tr>
              <td className="pr-2">{vatRegionalLabel}:</td>
              <td className="align-bottom">
                {currencyPrice(jobContent.vatTotal, account?.region)}
              </td>
            </tr>
          )}
          <tr className="font-bold">
            <td className="pr-2">Total payable amount:</td>
            <td className="align-bottom">
              {currencyPrice(jobContent.total, account?.region)}
            </td>
          </tr>
        </tbody>
      </table>
      {job.summaryMessages
        ?.filter((m) => m.message?.length)
        .map((message, index) => {
          return (
            <Alert
              className="mt-4"
              iconName={'info-circle.3'}
              key={index}
              colour={
                message.level === 'info'
                  ? EAlertColour.SOFT_BLUE
                  : EAlertColour.SOFT_YELLOW
              }
            >
              {message.message}
            </Alert>
          );
        })}
      {/* If regular deposit */}
      {depositRequired && !completedDepositPaymentValue && (
        <>
          <hr className="hr my-4" />
          <table className="w-full text-base">
            <tbody className="text-right font-bold">
              <tr>
                <td>Deposit due{depositPercentage}:</td>
                <td>
                  {jobContent.calculatedDepositAmount
                    ? currencyPrice(
                        jobContent.calculatedDepositAmount,
                        account?.region
                      )
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      {/* If deposit is set as part of payment schedule */}
      {depositPaymentScheduleStageWithValue &&
        !completedDepositPaymentValue && (
          <>
            <hr className="hr my-4" />
            <table className="w-full text-base">
              <tbody className="text-right font-bold">
                <tr>
                  <td>
                    Deposit due (
                    {depositPaymentScheduleStageWithValue.percentageDue}%):
                  </td>
                  <td>
                    {currencyPrice(
                      depositPaymentScheduleStageWithValue.value,
                      account?.region
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}

      {!isQuote && !!totalCompletedPaymentValue && (
        <>
          <hr className="hr my-4" />
          <table className="w-full text-base">
            <tbody className="text-right font-bold">
              <tr>
                <td>Amount paid:</td>
                <td>
                  {currencyPrice(totalCompletedPaymentValue, account?.region)}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      {isQuote && !!completedDepositPaymentValue && (
        <>
          <hr className="hr my-4" />
          <table className="w-full text-base">
            <tbody className="text-right font-semibold">
              <tr>
                <td>Deposit paid:</td>
                <td>
                  {currencyPrice(completedDepositPaymentValue, account?.region)}
                </td>
              </tr>
              {depositRequired && !!completedDepositPaymentValue && (
                <tr>
                  <td>Deposit due:</td>
                  <td>
                    {currencyPrice(
                      (jobContent.calculatedDepositAmount || 0) -
                        completedDepositPaymentValue,
                      account?.region
                    )}
                  </td>
                </tr>
              )}
              {!isQuote && !!amountDue && (
                <tr>
                  <td>Amount due:</td>
                  <td>{currencyPrice(amountDue, account?.region)}</td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}

      {hasAnyReverseChargeVat && (
        <Alert className="mt-4" colour={EAlertColour.SOFT_YELLOW}>
          Customer to account to HMRC for the reverse charge output tax on the
          VAT exclusive price of items marked ‘reverse charge’ at the relevant
          VAT rate as displayed.
        </Alert>
      )}
    </>
  );
};

export default JobDocumentTotals;
