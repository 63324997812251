import { RadioGroup } from '@headlessui/react';
import { FC, useContext, useMemo } from 'react';
import { EBtnSize } from '../plButton/useButtonClassName';
import { FieldContext } from '../plField/Field';

type ValueType = number | string;

export type SelectOption<
  TValueType extends ValueType,
  TMetaDataType = object,
> = {
  label: string;
  value: TValueType;
  disabled?: boolean;
  metadata?: TMetaDataType;
};

export enum ButtonRadioGroupOrientationVariant {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

type Props<TValueType extends ValueType> = {
  options: SelectOption<TValueType>[];
  onChange?: (value: TValueType) => void;
  value?: TValueType;
  disabled?: boolean;
  sizeVariant?: EBtnSize;
  orientationVariant?: ButtonRadioGroupOrientationVariant;
  CustomOption?: FC<{
    option: SelectOption<TValueType>;
    checked: boolean;
  }>;
};

const ButtonRadioGroup = <TValueType extends ValueType>({
  options,
  onChange,
  value,
  disabled,
  sizeVariant = EBtnSize.Default,
  orientationVariant = ButtonRadioGroupOrientationVariant.HORIZONTAL,
  CustomOption,
}: Props<TValueType>) => {
  const { id, name } = useContext(FieldContext);

  const sizeClasses = useMemo(() => {
    switch (sizeVariant) {
      case EBtnSize.Small:
        return 'py-2 px-3';
      case EBtnSize.Default:
        return 'py-3 px-4';
      case EBtnSize.Large:
        return 'py-3 px-4 sm:p-5';
    }
  }, [sizeVariant]);

  const { orientationClasses, borderClasses } = useMemo(() => {
    switch (orientationVariant) {
      case ButtonRadioGroupOrientationVariant.HORIZONTAL:
        return {
          orientationClasses: 'flex flex-wrap',
          borderClasses:
            'border rounded-l-none rounded-r-none first:rounded-l-md last:rounded-r-md -ml-1 first:ml-0', //'border-r last:border-0',
        };
      case ButtonRadioGroupOrientationVariant.VERTICAL:
        return {
          orientationClasses: 'flex flex-col w-full',
          borderClasses:
            'border rounded-t-none rounded-b-none first:rounded-t-md last:rounded-b-md -mt-1 first:mt-0', //'border-b last:border-0',
        };
    }
  }, [orientationVariant]);

  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className={`${orientationClasses} flex shadow-sm`}
      id={id}
      name={name}
      disabled={disabled}
    >
      {options.map((option) => (
        <RadioGroup.Option
          disabled={option.disabled}
          key={option.value}
          value={option.value}
          as="button"
          className={({ checked, disabled }) => {
            return `${sizeClasses} ${borderClasses} inline-flex items-center justify-center gap-2 font-medium focus:z-10 focus:ring-4 focus:ring-blue-500/25 ${
              checked ? 'bg-gray-100' : 'bg-white'
            } ${
              disabled
                ? 'cursor-default text-gray-400'
                : 'cursor-pointer text-gray-700 hover:bg-gray-50'
            } font-inherit align-middle text-base transition-all dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-slate-800`;
          }}
        >
          {({ checked }) => {
            return CustomOption ? (
              <CustomOption option={option} checked={checked} />
            ) : (
              <>{option.label}</>
            );
          }}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export default ButtonRadioGroup;
