import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as analyticsActions from '@payaca/store/analytics/analyticsActions';

import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import Button from '@payaca/components/plButton/Button';
import Modal from '@payaca/components/plModal/Modal';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import AddOnSubscriptionControl from '../../addOnSubscriptionControl/AddOnSubscriptionControl';
import CurrentSubscriptionControls from '../../currentSubscriptionControls/CurrentSubscriptionControls';
import SelectAddOnProductsControl from '../../selectAddOnProductsControl/SelectAddOnProductsControl';
import UpdatePaymentMethodControl from './UpdatePaymentMethodControl';

import { useSelector } from '@/api/state';

import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import { requestGetAccountSubscription } from '@payaca/store/subscription/subscriptionActions';
import { requestGetUserRoles } from '@payaca/store/userRoles/userRolesActions';
import './ManageSubscriptionPageContent.sass';

const ContentViewType = {
  DEFAULT: 'default',
  UPDATE_PAYMENT_METHOD: 'updatePaymentMethod',
};

type Props = {
  showSelectSubscriptionView: () => void;
};
const ManageSubscriptionPageContent: FC<Props> = ({
  showSelectSubscriptionView,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [contentView, setContentView] = useState(ContentViewType.DEFAULT);
  const accountSubscription = useSelector(
    (state) => state.subscription.accountSubscription
  );
  const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] =
    useState(false);
  const subscriptionEndsAt = useMemo(() => {
    return accountSubscription?.subscriptionInformation.subscriptionEndDate;
  }, [accountSubscription]);

  const onUpdateSubscriptionSuccess = useCallback(() => {
    setContentView(ContentViewType.DEFAULT);
    dispatch(analyticsActions.requestGetSubscriptionUsageData());
    dispatch(requestGetAccountSubscription());
    dispatch(requestGetUserRoles());
  }, [dispatch]);

  const paymentMethod = useMemo(() => {
    return {
      type: 'card',
      card: {
        brand: accountSubscription?.subscriptionInformation
          .stripePaymentMethodBrand as string,
        expiresMonth: accountSubscription?.subscriptionInformation
          .stripePaymentMethodExpiryMonth as string,
        expiresYear: accountSubscription?.subscriptionInformation
          .stripePaymentMethodExpiryYear as string,
        last4: accountSubscription?.subscriptionInformation
          .stripePaymentMethodLast4 as string,
      },
    };
  }, [accountSubscription]);

  return (
    <ResponsiveViewWrapper
      className={`manage-subscription-page-content`}
      downBreakpointSm={800}
    >
      <CurrentSubscriptionControls
        onUpdateSubscriptionSuccess={onUpdateSubscriptionSuccess}
        showSelectSubscriptionView={showSelectSubscriptionView}
      />
      <div className="current-add-ons-and-payment-method">
        <div className="current-add-ons-wrapper">
          <h3>Enabled add-ons</h3>
          {!accountSubscription?.addOnSubscriptions?.length ? (
            <span>Nothing added yet</span>
          ) : (
            !!accountSubscription?.addOnSubscriptions?.length && (
              <div className="add-on-subscriptions">
                {accountSubscription?.addOnSubscriptions.map(
                  (addOnSubscription, i) => {
                    return (
                      <AddOnSubscriptionControl
                        addOnSubscription={addOnSubscription}
                        key={i}
                        onUpdateSubscriptionSuccess={
                          onUpdateSubscriptionSuccess
                        }
                      />
                    );
                  }
                )}
              </div>
            )
          )}
        </div>

        <div>
          <h3>Payment method</h3>
          <ContentPanel>
            <>
              {paymentMethod.type === 'card' && paymentMethod.card && (
                <div className="payment-method card">
                  <div className="card-brand-and-number">
                    <span className="card-brand">
                      {paymentMethod.card.brand}
                    </span>
                    <span>•••• •••• •••• {paymentMethod.card.last4}</span>
                  </div>
                  <span className="card-expiry">
                    Exp {paymentMethod.card.expiresMonth}/
                    {paymentMethod.card.expiresYear}
                  </span>
                </div>
              )}
              {!subscriptionEndsAt && (
                <Button
                  onClick={() => setShowUpdatePaymentMethodModal(true)}
                  variant={EBtnVariant.LinkInline}
                  className="update-payment-method"
                >
                  Update payment method
                </Button>
              )}
            </>
          </ContentPanel>
        </div>
      </div>
      <div className="separator" />

      <SelectAddOnProductsControl />

      <Modal
        isOpen={showUpdatePaymentMethodModal}
        title={'Update payment method'}
        onClose={() => setShowUpdatePaymentMethodModal(false)}
      >
        <Modal.Body>
          <UpdatePaymentMethodControl
            onUpdatePaymentMethod={() => setShowUpdatePaymentMethodModal(false)}
          />
        </Modal.Body>
      </Modal>
    </ResponsiveViewWrapper>
  );
};

export default ManageSubscriptionPageContent;
