import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useMemo, useRef, useState } from 'react';
import HeaderTag, { HeaderTagType } from '../headerTag/HeaderTag';
import PopupMenu from '../popupMenu/PopupMenu';
import './Tile.sass';

type Props = {
  onClick?: () => void;
  className?: string;
  title?: React.ReactNode;
  titleHeaderTagType?: HeaderTagType;
  footer?: React.ReactNode;
  hasBoxShadow?: boolean;
  quickActions?: {
    actionName: string;
    actionBehaviour: () => void;
    isActionProcessing?: boolean;
  }[];
};

const Tile: FunctionComponent<React.PropsWithChildren<Props>> = ({
  onClick,
  className,
  title,
  children,
  footer,
  hasBoxShadow = false,
  quickActions,
  titleHeaderTagType = HeaderTagType.H2,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const hasAnyQuickActions = useMemo(() => {
    return !!quickActions?.length;
  }, [quickActions]);

  const popupMenuOptions = useMemo(() => {
    return quickActions?.map((quickAction) => {
      return {
        name: quickAction.actionName,
        onClick: () => quickAction.actionBehaviour(),
        isProcessing: quickAction.isActionProcessing || false,
      };
    });
  }, [quickActions]);

  return (
    <div
      onClick={onClick}
      className={`tile ${className ? className : ''} ${
        hasBoxShadow ? 'box-shadow' : ''
      }`}
    >
      {(title || hasAnyQuickActions) && (
        <div className="title-container flex-container flex-center">
          <div className="flex-grow" style={{ overflow: 'hidden' }}>
            <HeaderTag type={titleHeaderTagType} className="title">
              {title}
            </HeaderTag>
          </div>
          {hasAnyQuickActions && (
            <div
              ref={ref}
              onClick={(e) => {
                setIsOpen(true);
                e.stopPropagation();
              }}
            >
              <FontAwesomeIcon icon={faEllipsisV} />
              <PopupMenu
                options={popupMenuOptions || []}
                isOpen={isOpen}
                onClose={() => {
                  setIsOpen(false);
                }}
                anchorElement={ref.current}
              />
            </div>
          )}
        </div>
      )}
      <div className="body-container">{children}</div>

      {footer && <div className="footer-container">{footer}</div>}
    </div>
  );
};

export default Tile;
