import { getRegion } from '@/utils/stateAccessors';
import { FC, useEffect, useMemo, useState } from 'react';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { useJobLineItem } from '@payaca/store/hooks/appState';
import { InvoiceLine } from '@payaca/types/invoiceTypes';

import { useSelector } from '@/api/state';
import { faPercent } from '@fortawesome/free-solid-svg-icons';
import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';
import ValidationFeedbackBlock from '@payaca/components/validationFeedbackBlock/ValidationFeedbackBlock';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getJobLineItemInvoiceLineSummaries } from '@payaca/helpers/invoiceHelper';

type Props = {
  invoiceLine: Pick<
    InvoiceLine,
    'id' | 'value' | 'jobLineItemId' | 'jobLineItemPercentage'
  >;
  invoiceLinesForDeal: InvoiceLine[];
  onChange: (change: { value: number; percentage: number }) => void;
  onSaveRequired?: () => void;
};

const EditInvoiceLineControlRow: FC<Props> = ({
  invoiceLine,
  invoiceLinesForDeal,
  onChange,
  onSaveRequired,
}: Props): JSX.Element | null => {
  const jobLineItem = useJobLineItem(invoiceLine.jobLineItemId);
  const [percentage, setPercentage] = useState('');
  const region = useSelector(getRegion);

  const invoiceLines = invoiceLinesForDeal.filter(
    (x) =>
      x.jobLineItemId === invoiceLine.jobLineItemId && x.id != invoiceLine.id
  );
  const { totalValue, uninvoicedValue } = useMemo(() => {
    if (!jobLineItem)
      return {
        totalValue: 0,
        invoicedValue: 0,
        uninvoicedValue: 0,
      };

    const x = getJobLineItemInvoiceLineSummaries(
      [jobLineItem],
      invoiceLines
    )?.[0];
    return x;
  }, [jobLineItem, invoiceLines]);

  useEffect(() => {
    if (!totalValue) setPercentage('100');
    else {
      setPercentage(Math.round(invoiceLine.jobLineItemPercentage).toString());
    }
  }, [totalValue]);

  const validationMessage = useMemo(() => {
    if (uninvoicedValue > 0) {
      if (invoiceLine.value > uninvoicedValue) {
        return `Cannot invoice for more than ${currencyPrice(
          uninvoicedValue,
          region
        )} of this item's value`;
      }

      if (invoiceLine.value < 0) {
        return `Cannot invoice for less than ${currencyPrice(0, region)}`;
      }
    }

    if (invoiceLine.value < 0) {
      if (invoiceLine.value < uninvoicedValue) {
        return `Cannot invoice for more than than ${currencyPrice(
          uninvoicedValue,
          region
        )} of this item's value`;
      }

      if (invoiceLine.value > 0) {
        return `Cannot invoice for greater than ${currencyPrice(0, region)}`;
      }
    }
  }, [invoiceLine.value, uninvoicedValue]);

  if (!jobLineItem) return null;

  return (
    <tbody className="edit-invoice-line-control-row">
      <tr className="description-container">
        <td className="description-table-cell" colSpan={4}>
          <MarkdownLabel
            markdown={jobLineItem.description}
            className="description"
          />
        </td>
      </tr>
      <tr className="line-value-container">
        <td className="total-table-cell" align="right">
          <span className="sm-view-label">Total</span>
          {currencyPrice(totalValue, region)}
        </td>
        <td className="due-percentage-table-cell" align="right">
          <div className="percentage-input">
            <BasicField
              isDisabled={!totalValue}
              name="percentage"
              value={percentage}
              iconAfter={faPercent}
              onBlur={onSaveRequired}
              onChangeTimeout={onSaveRequired}
              onChange={(value) => {
                let p = parseFloat(value.percentage);
                if (isNaN(p)) {
                  p = 0;
                }
                const v = Math.round((totalValue * p) / 100);

                setPercentage(value.percentage);
                onChange({ value: v, percentage: p });
              }}
            />
            {!!totalValue && (
              <>
                <Button
                  className="min-button"
                  styleVariant={ButtonStyleVariant.ANCHOR}
                  onClick={() => {
                    setPercentage('0');
                    onChange({ value: 0, percentage: 0 });
                    onSaveRequired?.();
                  }}
                >
                  0%
                </Button>
                <Button
                  className="max-button"
                  styleVariant={ButtonStyleVariant.ANCHOR}
                  onClick={() => {
                    const p =
                      Math.round((uninvoicedValue / totalValue) * 10000) / 100;

                    setPercentage(p.toString());
                    onChange({ value: uninvoicedValue, percentage: p });
                    onSaveRequired?.();
                  }}
                >
                  Max
                </Button>
              </>
            )}
          </div>
        </td>
        <td className="due-amount-table-cell" align="right">
          <span className="sm-view-label">Due</span>
          {currencyPrice(invoiceLine.value, region)}
        </td>
      </tr>
      {validationMessage && (
        <tr className="validation-message-container">
          <td>
            <ValidationFeedbackBlock
              validationResults={[
                {
                  isValid: false,
                  errors: [validationMessage],
                },
              ]}
            />
          </td>
        </tr>
      )}
    </tbody>
  );
};
export default EditInvoiceLineControlRow;
