import { FC, useCallback, useMemo, useState } from 'react';

import { getJob } from '@/utils/stateAccessors';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import QuickActionsElement from '@payaca/components/quickActionsTableCell/QuickActionsElement';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';

import { isInvoice } from '@payaca/helpers/jobStatusHelper';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { JobStatus } from '@payaca/types/jobTypes';
import { Job } from '@payaca/types/jobTypesV2';

import { useSelector } from '@/api/state';
import './JobReferenceControl.sass';

type Props = {
  jobId: number;
  updateJob: (
    value: { [key: string]: any },
    onUpdateSuccess?: () => void
  ) => void;
};
const JobReferenceControl: FC<Props> = ({
  jobId,
  updateJob,
}: Props): JSX.Element => {
  const [isEditingJobReference, setIsEditingJobReference] = useState(false);
  const [isSaveRequired, setIsSaveRequired] = useState(false);

  const job: Job | undefined = useSelector((state) => getJob(state, jobId));

  const initialFormState = useMemo(() => {
    return {
      job: {
        customReference: job?.customReference,
        status: job?.status,
      },
    };
  }, [job]);

  const jobIsInvoice = useMemo(
    () => !!job && isInvoice(job.status),
    [job?.status]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      if (isSaveRequired) {
        updateJob(formState);
        setIsSaveRequired(false);
      }
      return (
        <>
          {jobIsInvoice ? (
            <h1>Invoice</h1>
          ) : (
            <DropdownField
              name={'job.status'}
              styleVariant={InputStyleVariant.OUTSIZE}
              value={formState.job.status}
              onChange={(value) => {
                const newStatus = value['job.status'];
                onFieldChange({ ['job.status']: newStatus });
                setIsSaveRequired(true);
              }}
              onTouch={onFieldTouch}
              options={[
                { label: 'Quote', value: JobStatus.NEW_QUOTE },
                { label: 'Estimate', value: JobStatus.NEW_ESTIMATE },
              ]}
            />
          )}

          {isEditingJobReference ? (
            <div className="edit-deal-reference-wrapper flex-container">
              <BasicField
                name="job.customReference"
                styleVariant={InputStyleVariant.OUTSIZE}
                value={formState.job.customReference || ''}
                onChange={(fieldChangeValue: any) => {
                  let value = fieldChangeValue['job.customReference'];
                  value = value.substring(0, 20);
                  onFieldChange({ ['job.customReference']: value });
                }}
                onTouch={onFieldTouch}
                additionalInputProps={{
                  placeholder: formState.reference,
                }}
              />
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => {
                  updateJob(formState);
                  setIsEditingJobReference(false);
                }}
              >
                Save
              </Button>
            </div>
          ) : (
            <h1># {job?.customReference || job?.reference}</h1>
          )}
        </>
      );
    },
    [isEditingJobReference, isSaveRequired, job, jobIsInvoice, updateJob]
  );

  return (
    <div className="job-reference-control">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
      />
      {!job?.archivedAt && !isEditingJobReference && (
        <QuickActionsElement
          recordId={jobId}
          quickActions={[
            {
              actionName: 'Edit reference',
              actionBehaviour: () => {
                setIsEditingJobReference(true);
              },
            },
          ]}
        />
      )}
    </div>
  );
};
export default JobReferenceControl;
