import { faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@payaca/components/button/IconButton';
import Modal from '@payaca/components/modal/Modal';
import { PublicEntityTemplate } from '@payaca/types/entity-templates';
import { FC, useState } from 'react';
import CreateEditTaskTemplateControl from '../createEditTaskTemplateControl/CreateEditTaskTemplateControl';
import EntityTemplateCard from '../entityTemplateCard/EntityTemplateCard';
import './ScheduledEventLinkedTaskTemplatesPanel.sass';

type Props = {
  taskTemplates: Omit<PublicEntityTemplate<'task'>, 'publicId'>[];
  onChange: (
    taskTemplates: Omit<PublicEntityTemplate<'task'>, 'publicId'>[]
  ) => Promise<void>;
};
const ScheduledEventLinkedTaskTemplatesPanel: FC<Props> = ({
  taskTemplates,
  onChange,
}: Props): JSX.Element | null => {
  const [showCreateTaskTemplateModal, setShowCreateTaskTemplateModal] =
    useState(false);

  return (
    <div className={'scheduled-event-linked-task-templates-panel'}>
      <IconButton
        size="xs"
        icon={faPlus}
        onClick={() => setShowCreateTaskTemplateModal(true)}
      />
      {taskTemplates.map((t, i) => {
        return (
          <EntityTemplateCard
            editInModal={false}
            showEntityTypeIcon={true}
            key={i}
            template={t}
            allowedQuickActions={['delete']}
            onChange={(updatedTaskTemplate) =>
              new Promise(() => {
                const updatedTaskTemplates = [...taskTemplates];
                updatedTaskTemplates[i] = updatedTaskTemplate as Omit<
                  PublicEntityTemplate<'task'>,
                  'publicId'
                >;
                onChange(updatedTaskTemplates);
              })
            }
            onRemove={() =>
              new Promise<void>((resolve) => {
                onChange(taskTemplates.filter((_, j) => j !== i));
                resolve();
              })
            }
            onClick={(showEditTemplateModal) => showEditTemplateModal()}
          />
        );
      })}

      <Modal
        isOpen={showCreateTaskTemplateModal}
        onClose={() => setShowCreateTaskTemplateModal(false)}
      >
        <CreateEditTaskTemplateControl
          onRequestSave={(taskTemplate) => {
            onChange([...taskTemplates, taskTemplate]).then(() =>
              setShowCreateTaskTemplateModal(false)
            );
          }}
        />
      </Modal>
    </div>
  );
};
export default ScheduledEventLinkedTaskTemplatesPanel;
