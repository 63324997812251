import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import CurrencyField from '@payaca/components/currencyField/CurrencyField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import TextareaFieldFormatter from '@payaca/components/textareaField/TextareaFieldFormatter';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';

import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';

import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { LabelStyleVariant } from '@payaca/components/fieldLabel/FieldLabel';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import UploadCollectionControl from '../../components/uploadCollectionControl/UploadCollectionControl';
import SalesTaxSettingsField from '../salesTaxSettingsField/SalesTaxSettingsField';
import ServicePlanCommitmentsControl from '../servicePlanCommitmentControl/ServicePlanCommitmentsControl';

import { CreateServicePlanRequestPrice } from '@payaca/store/servicePlans/servicePlansTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { ServiceInterval } from '@payaca/types/service-plans';

import {
  getConditionalValidator,
  getIsRequiredFieldValidator,
  getIsRequiredIfTrueConditionValidator,
  getMustBeUrlValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import { getTaxRateIdLocalStorageKey } from '../../../helpers/localStorageKeyHelper';

import { useAccount } from '@/utils/storeHooks';
import { useDefaultTaxRate } from '@payaca/store/hooks/appState';
import { AccountRegions } from '@payaca/types/accountTypes';

const acceptFileTypes = getAcceptedFileTypes(['document']);
const serviceIntervalOptions: { label: string; value: ServiceInterval }[] = [
  { label: 'year', value: 'year' },
  { label: 'month', value: 'month' },
];
const taxRateIdLocalStorageKey = getTaxRateIdLocalStorageKey();

type Props = {
  onCreateSuccess?: () => void;
};

const CreateServicePlanControl = ({ onCreateSuccess }: Props) => {
  const dispatch = useDispatch();
  const defaultTaxRate = useDefaultTaxRate();

  const [showError, setShowError] = useState<false | string>(false);
  const [isCreatingServicePlan, setIsCreatingServicePlan] =
    useState<boolean>(false);
  const [hasChangesMade, setHasChangesMade] = useState<boolean>(false);
  const account = useAccount();

  const saveServicePlan = (formState: any) => {
    const prices: CreateServicePlanRequestPrice[] = [];
    if (formState.monthlyCost) {
      prices.push({
        basicPrice: formState.monthlyCost,
        priceIncludesTax: formState.monthlyPriceIncludesTax,
        name: 'Monthly cost',
        billingInterval: 'month',
        billingIntervalCount: 1,
        taxRateId: formState.monthlyTaxRateId,
      });
    }
    if (formState.yearlyCost) {
      prices.push({
        basicPrice: formState.yearlyCost,
        priceIncludesTax: formState.yearlyPriceIncludesTax,
        name: 'Yearly cost',
        billingInterval: 'year',
        billingIntervalCount: 1,
        taxRateId: formState.yearlyTaxRateId,
      });
    }
    const requestData = {
      name: formState.name,
      description: formState.description,
      serviceInterval: formState.serviceInterval,
      serviceIntervalCount: 1,
      prices,
      termsUploadId: formState.termsDirectLinkInstead
        ? undefined
        : formState.termsUploadId,
      serviceTermsDirectLink: formState.termsDirectLinkInstead
        ? formState.serviceTermsDirectLink
        : undefined,
      commitments: formState.commitments,
      paymentMethodTypes: formState.paymentMethodTypes,
    };
    setIsCreatingServicePlan(true);
    dispatch(
      servicePlansActions.createServicePlan.request({
        createServicePlanRequestData: requestData,
        callback: () => {
          onCreateSuccess?.();
          setIsCreatingServicePlan(false);
        },
        onErrorCallback: () => {
          setIsCreatingServicePlan(false);
          setShowError(
            'Sorry, there was an error creating your Service Plan. Please try again.'
          );
        },
      })
    );
  };

  const initialFormState = useMemo(() => {
    const localStorageTaxRateId = localStorage.getItem(
      taxRateIdLocalStorageKey
    );
    const defaultTaxRateId = localStorageTaxRateId?.length
      ? parseInt(localStorageTaxRateId, 10)
      : defaultTaxRate?.id;
    return {
      serviceInterval: 'year',
      commitments: [],
      description: '',
      paymentMethodTypes:
        account.region === AccountRegions.UK
          ? ['card', 'bacs_debit']
          : ['card'],
      yearlyTaxRateId: defaultTaxRateId,
      monthlyTaxRateId: defaultTaxRateId,
      monthlyPriceIncludesTax: false,
      yearlyPriceIncludesTax: false,
      serviceTermsDirectLink: '',
      termsDirectLinkInstead: false,
    };
  }, [defaultTaxRate]);

  const fieldValidators = useMemo(() => {
    return {
      name: [getIsRequiredFieldValidator()],
      description: [getIsRequiredFieldValidator()],
      serviceInterval: [getIsRequiredFieldValidator()],
      serviceTermsDirectLink: [
        getConditionalValidator(
          (state) => state.termsDirectLinkInstead,
          getMustBeUrlValidator({
            customErrorMessage:
              'Please enter a valid URL starting with https:// or http://',
          })
        ),
      ],
      monthlyCost: [
        getIsRequiredIfTrueConditionValidator(
          (formState) => !formState.yearlyCost
        ),
      ],
      yearlyCost: [
        getIsRequiredIfTrueConditionValidator(
          (formState) => !formState.monthlyCost
        ),
      ],
      monthlyTaxRateId: [
        getIsRequiredIfTrueConditionValidator(
          (formState) => !!formState.monthlyCost
        ),
      ],
      yearlyTaxRateId: [
        getIsRequiredIfTrueConditionValidator(
          (formState) => !!formState.yearlyCost
        ),
      ],
    };
  }, []);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <BasicField
            label="Plan name"
            name="name"
            placeholder="Catchy Plan name"
            isRequired={true}
            value={formState.name}
            onChange={onFieldChange}
          />
          <TextareaFieldFormatter
            label="Plan description"
            name="description"
            placeholder="Describe the Plan to your Customer"
            isRequired={true}
            value={formState.description}
            onChange={onFieldChange}
          />
          <div>
            <div className="field-label-tooltip-wrapper">
              <FieldLabel label="Terms and conditions" />
              <Tooltip
                text={
                  'You will not be able to sell this service plan until terms and conditions have been added.'
                }
              />
            </div>

            {formState.termsDirectLinkInstead ? (
              <ValidatedFieldWrapper
                validationResult={validationState.serviceTermsDirectLink}
              >
                <BasicField
                  name="serviceTermsDirectLink"
                  placeholder="https://"
                  value={formState.serviceTermsDirectLink}
                  onChange={onFieldChange}
                />
              </ValidatedFieldWrapper>
            ) : (
              <UploadCollectionControl
                uploadIds={
                  formState.termsUploadId ? [formState.termsUploadId] : []
                }
                onUploadCollectionChange={(uploadIds) =>
                  onFieldChange({
                    termsUploadId: uploadIds[0],
                  })
                }
                allowMultipleUploads={false}
                canRemove={true}
                acceptFileTypes={acceptFileTypes}
              />
            )}

            <div className="terms-direct-link-checkbox">
              <CheckboxField
                name="termsDirectLinkInstead"
                label="Use a direct link instead"
                value={formState.termsDirectLinkInstead}
                onChange={(newState: { [key: string]: any }) => {
                  const mutatedState = { ...newState };

                  if (mutatedState.termsDirectLinkInstead) {
                    mutatedState.termsUploadId = null;
                  } else if (!mutatedState.termsDirectLinkInstead) {
                    mutatedState.serviceTermsDirectLink = '';
                  }

                  onFieldChange(mutatedState);
                }}
              />
            </div>
          </div>

          {/* service interval */}
          <div>
            <FieldLabel label="Work will be carried out" isRequired={true} />
            <div className="interval-wrapper">
              every
              <DropdownField
                name="serviceInterval"
                options={serviceIntervalOptions}
                value={formState.serviceInterval}
                onChange={onFieldChange}
              />
            </div>
          </div>

          {/* prices */}
          <div>
            <div className="field-label-tooltip-wrapper">
              <FieldLabel label="Price(s) charged will be" isRequired={true} />
              <Tooltip
                text={
                  'Must have at least one price. If both are added your customer will have the choice.'
                }
              />
            </div>
            <div className="price-billing-wrapper">
              <div>
                <CurrencyField
                  name="yearlyCost"
                  value={formState.yearlyCost}
                  onChange={onFieldChange}
                />
                <SalesTaxSettingsField
                  excludeReverseChargeOptions={true}
                  styleVariant={InputStyleVariant.OUTSIZE}
                  taxRateId={
                    formState?.yearlyTaxRateId
                      ? formState.yearlyTaxRateId
                      : null
                  }
                  onChange={(value: { [key: string]: any }) => {
                    onFieldChange({
                      yearlyTaxRateId: value.taxRateId,
                    });
                  }}
                  labelStyleVariant={LabelStyleVariant.LIGHT}
                  hideLabel={true}
                />
                <span>per year</span>
                <CheckboxField
                  label="Price includes tax?"
                  name="yearlyPriceIncludesTax"
                  value={formState.yearlyPriceIncludesTax}
                  onChange={onFieldChange}
                />
              </div>
              <div>
                <CurrencyField
                  name="monthlyCost"
                  value={formState.monthlyCost}
                  onChange={onFieldChange}
                />
                <SalesTaxSettingsField
                  excludeReverseChargeOptions={true}
                  styleVariant={InputStyleVariant.OUTSIZE}
                  taxRateId={
                    formState?.monthlyTaxRateId
                      ? formState.monthlyTaxRateId
                      : null
                  }
                  onChange={(value: { [key: string]: any }) => {
                    onFieldChange({
                      monthlyTaxRateId: value.taxRateId,
                    });
                  }}
                  labelStyleVariant={LabelStyleVariant.LIGHT}
                  hideLabel={true}
                />
                <span>per month</span>
                <CheckboxField
                  label="Price includes tax?"
                  name="monthlyPriceIncludesTax"
                  value={formState.monthlyPriceIncludesTax}
                  onChange={onFieldChange}
                />
              </div>
            </div>
          </div>
          {account.region === AccountRegions.UK && (
            <div className="payment-method-types">
              <FieldLabel
                label="Payment methods"
                description={
                  "Please ensure that the payment methods you have selected here are activated in your Stripe dashboard. If you select 'Direct debit' for example, but do not have that method active in Stripe, you will not be able to sell your service plan."
                }
              />
              <CheckboxField
                name="card"
                label="Card"
                value={formState.paymentMethodTypes?.includes('card')}
                onChange={(value) => {
                  const paymentMethodTypes: Array<'card' | 'bacs_debit'> =
                    value.card
                      ? [...formState.paymentMethodTypes, 'card']
                      : formState.paymentMethodTypes.filter(
                          (type: string) => type !== 'card'
                        );

                  onFieldChange({ paymentMethodTypes });
                }}
              />
              <CheckboxField
                name="bacsdebit"
                label="Direct debit"
                value={formState.paymentMethodTypes?.includes('bacs_debit')}
                onChange={(value) => {
                  const paymentMethodTypes: Array<'card' | 'bacs_debit'> =
                    value.bacsdebit
                      ? [...formState.paymentMethodTypes, 'bacs_debit']
                      : formState.paymentMethodTypes.filter(
                          (type: string) => type !== 'bacs_debit'
                        );

                  onFieldChange({ paymentMethodTypes });
                }}
              />
            </div>
          )}
          {/* commitments */}
          <div className="commitments-wrapper">
            <div className="field-label-tooltip-wrapper">
              <FieldLabel label="Commitments" />
              <Tooltip
                text={
                  'Events and Tasks that may be done as part of the Service Plan.'
                }
              />
            </div>

            <ServicePlanCommitmentsControl
              commitments={formState.commitments}
              onAddCommitment={(commitment) => {
                return new Promise<void>((resolve) => {
                  onFieldChange({
                    commitments: [...formState.commitments, commitment],
                  });
                  resolve();
                });
              }}
              onRemoveCommitment={(index) => {
                return new Promise<void>((resolve) => {
                  const commitments = [...formState.commitments];
                  commitments.splice(index, 1);

                  onFieldChange({
                    commitments: commitments,
                  });
                  resolve();
                });
              }}
              onChangeCommitment={(index, commitment) => {
                return new Promise<void>((resolve) => {
                  const commitments = [...formState.commitments];
                  commitments[index] = commitment;
                  onFieldChange({
                    commitments: commitments,
                  });
                  resolve();
                });
              }}
            />
          </div>
          {showError && <ErrorMessage message={showError} />}
          <div className="actions-container">
            <Button
              onClick={() => saveServicePlan(formState)}
              isDisabled={!isValid}
              className="save-button"
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isProcessing={isCreatingServicePlan}
            >
              Save
            </Button>
          </div>
        </>
      );
    },
    [isCreatingServicePlan, saveServicePlan, showError]
  );

  return (
    <div>
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
        onFormStateChange={(formState) => {
          const hasChanges =
            !!formState.description ||
            !!formState.name ||
            !!formState.commitments.length ||
            !!formState.yearlyCost ||
            !!formState.monthlyCost;
          setHasChangesMade(hasChanges);
        }}
      />
      {/* warning message if name, description, costs or commitments have been added */}
      <Prompt
        when={!!hasChangesMade && !isCreatingServicePlan}
        message="There are unsaved changes on the page, are you sure you want to leave?"
      />
    </div>
  );
};

export default CreateServicePlanControl;
