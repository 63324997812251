import { JobLineItemAttachment } from '@payaca/types/jobTypes';
import { FC, useMemo } from 'react';

import { BrandingColourContext } from '@payaca/types/accountBrandTypes';
import { Account } from '@payaca/types/accountTypes';
import { Deal } from '@payaca/types/dealTypes';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import { TIconName } from '@payaca/untitled-icons';
import DocumentCard from '../clientDocument/DocumentCard';
import JobDocumentItem from './JobDocumentItem';

type Props = {
  account: Pick<Account, 'region'>;
  icon?: TIconName;
  title?: string;
  hideItemPrices: boolean;
  hideVat: boolean;
  isEditable: boolean;
  isProposition?: boolean;
  jobLineItems: JobLineItem[];
  jobLineItemAttachments: JobLineItemAttachment[];
  onItemClick: (jobLineItem: JobLineItem) => void;
  brandingColourContext?: BrandingColourContext;
  dealVersion: Deal['version'];
  isAnyVatIncluded?: boolean;
};

const JobDocumentItemsSection: FC<Props> = ({
  account,
  icon,
  title,
  hideItemPrices,
  hideVat,
  isEditable,
  isProposition = false,
  jobLineItems,
  jobLineItemAttachments,
  onItemClick,
  brandingColourContext,
  dealVersion,
  isAnyVatIncluded = true,
}: Props): JSX.Element => {
  const selectedMultipleChoiceItem = useMemo(() => {
    if (jobLineItems.find((i) => i.isMultipleChoice)) {
      return jobLineItems.find((i) => i.isSelected);
    }
    return null;
  }, [jobLineItems]);

  return (
    <DocumentCard.Section title={title} icon={icon} items={jobLineItems}>
      {(jobLineItem) => (
        <JobDocumentItem
          account={account}
          hideItemPrices={hideItemPrices}
          item={jobLineItem}
          attachments={jobLineItemAttachments.filter((attachment) =>
            jobLineItem.jobLineItemAttachmentIds.includes(attachment.id)
          )}
          key={jobLineItem.id}
          isEditable={isEditable}
          isProposition={isProposition}
          onItemClick={onItemClick}
          selectedMultipleChoiceItem={selectedMultipleChoiceItem}
          hideVat={hideVat}
          brandingColourContext={brandingColourContext}
          dealVersion={dealVersion}
          isAnyVatIncluded={isAnyVatIncluded}
        />
      )}
    </DocumentCard.Section>
  );
};

export default JobDocumentItemsSection;
