import { format } from 'date-fns';
import { FC, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import useGetCustomerProjects from '@/api/queries/customers/useGetCustomerProjects';
import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import { CustomerProjectsQuery } from '@/gql/graphql';
import CreateDealModal from '@/ui/components/createDealModal/CreateDealModal';
import { useAccount } from '@/utils/storeHooks';
import Address from '@payaca/components/address/Address';
import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import Button from '@payaca/components/plButton/Button';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import {
  DateFormats,
  getInternationalDateFormatByLocale,
} from '@payaca/helpers/internationalHelper';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';

type TData = CustomerProjectsQuery['customer']['projects']['items'][0];

const CustomerProjectTable: FC = () => {
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();

  /**
   * TEMPORARY
   * ToDo: remove redux here
   */
  const account = useAccount();
  /**
   * END: TEMPORARY
   */

  const [isShowingCreateProjectModal, setIsShowingCreateProjectModal] =
    useState(false);
  const [searchTermValue, setSearchTermValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState(PAGINATION_ARG_DEFAULTS);

  const { data: customerData, isLoading } = useGetCustomerProjects(
    customerId,
    { searchTerm },
    pagination
  );
  const { data: accountRegionPreferences } = useGetMyRegionPreferences();

  return (
    <>
      <ManageableItemsList>
        <ManageableItemsList.HeaderBar
          heading="Customer Projects"
          buttons={
            <>
              <Button
                onClick={() => setIsShowingCreateProjectModal(true)}
                size={EBtnSize.Small}
              >
                Create Project
              </Button>
            </>
          }
        />

        <ManageableItemsList.ActionBar>
          <ManageableItemsList.ActionBar.SearchInput
            value={searchTermValue}
            onChange={setSearchTermValue}
            onChangeTimeout={setSearchTerm}
            aria-label="Search by Reference or Site Address"
          />
        </ManageableItemsList.ActionBar>

        <ManageableItemsList.Table<TData>
          items={customerData?.customer.projects.items || []}
          uniqueKey="id"
          isLoading={!customerData || isLoading}
          onClickRow={(item) => {
            history.push(`/deals/${item.id}`);
          }}
        >
          <ManageableItemsList.Table.Column<TData, 'reference'>
            header="Ref"
            field="reference"
            render={(reference, row) => {
              return <Link to={`/deals/${row.id}`}>{reference}</Link>;
            }}
          />
          <ManageableItemsList.Table.Column<TData, 'addresses'>
            header="Site Address"
            field="addresses"
            render={(addresses) => {
              if (!addresses.site.length) return null;

              return (
                <>
                  <Address address={addresses.site[0]} />

                  {addresses.site.length > 1 && (
                    <span className="supporting-body ms-2">
                      +{addresses.site.length - 1} more
                    </span>
                  )}
                </>
              );
            }}
          />
          <ManageableItemsList.Table.Column
            header="Updated"
            field="updatedAt"
            render={(updatedAt) => {
              return format(
                new Date(updatedAt),
                getInternationalDateFormatByLocale(
                  DateFormats.MID,
                  accountRegionPreferences?.locale
                )
              );
            }}
          />
          <ManageableItemsList.Table.Column<TData, 'totals'>
            header="Value"
            field="totals"
            render={(totals) => {
              return (
                <CurrencyValue
                  value={totals.projectValue.value}
                  currency={totals.projectValue.currency}
                />
              );
            }}
          />
          <ManageableItemsList.Table.Column<TData, 'pipeline'>
            header="Pipeline"
            field="pipeline"
            render={(pipeline) => {
              return (
                <div className="flex items-center gap-1.5">
                  <div
                    className="h-3 w-3 rounded-full"
                    style={{ backgroundColor: pipeline.colour }}
                  />
                  <span>{pipeline.name}</span>
                </div>
              );
            }}
          />
          <ManageableItemsList.Table.Column<TData, 'currentPipelineStage'>
            header="Stage"
            field="currentPipelineStage"
            render={(currentPipelineStage) => {
              return <>{currentPipelineStage.title}</>;
            }}
          />
        </ManageableItemsList.Table>

        {customerData && (
          <ManageableItemsList.PaginationBar
            pageSize={customerData.customer.projects.limit}
            currentPage={
              customerData.customer.projects.offset /
                customerData.customer.projects.limit +
              1
            }
            totalItems={customerData.customer.projects.totalCount}
            onPageChange={(page) => {
              setPagination((s) => ({ ...s, offset: (page - 1) * s.limit }));
            }}
          />
        )}
      </ManageableItemsList>

      {customerData && (
        <CreateDealModal
          isOpen={isShowingCreateProjectModal}
          onClose={() => setIsShowingCreateProjectModal(false)}
          initialPipelineId={account.primaryPipelineId}
          initialCustomerId={+customerId}
        />
      )}
    </>
  );
};

export default CustomerProjectTable;
