import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CreateEditSupplierModal from '@/ui/components/createEditSupplierModal/CreateEditSupplierModal';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import { actions as appActions } from '@/api/app';

import { SuppliersPermissions } from '@payaca/permissions/suppliers/suppliers.permissions';

import { SortDirection } from '@payaca/types/listViewTypes';
import { getUserRoles } from '../../../utils/stateAccessors';

import { useSelector } from '@/api/state';
import { useTranslation } from '@/i18n';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { Helmet } from 'react-helmet';
import useGetSuppliers from '../../../api/queries/suppliers/useGetSuppliers';
import {
  GetSuppliersInput,
  GetSuppliersSortBy,
  SortDirection as SortDirectionGql,
  SuppliersQuery,
} from '../../../gql/graphql';
import ConfirmDeleteSuppliersModal from './ConfirmDeleteSuppliersModal';

export type ListedSupplier = SuppliersQuery['suppliers']['items'][number];
const pageSize = 100;

const ListedSuppliersPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userRoles = useSelector(getUserRoles);

  const [showCreateSupplierModal, setShowCreateSupplierModal] = useState(false);
  const [showConfirmDeleteSuppliersModal, setShowConfirmDeleteSuppliersModal] =
    useState(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState<ListedSupplier[]>(
    []
  );
  const initialInput = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);

    return {
      sortBy: searchParams.get('sort') || 'NAME',
      sortDirection: searchParams.get('dir') || 'ASC',
      searchTerm: searchParams.get('term') || undefined,
    } as GetSuppliersInput;
  }, [window.location]);

  const initialPagination = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageNumber = parseInt(searchParams.get('page') || '1');

    return {
      limit: pageSize,
      offset: (pageNumber - 1) * pageSize,
    };
  }, [window.location]);

  const [getSuppliersInput, setGetSuppliersInput] =
    useState<GetSuppliersInput>(initialInput);
  const [getSuppliersPagination, setGetSuppliersPagination] = useState<{
    limit: number;
    offset: number;
  }>(initialPagination);
  const [searchTerm, setSearchTerm] = useState('');

  const {
    data: suppliers,
    isLoading,
    refetch: refetchSuppliers,
  } = useGetSuppliers(getSuppliersInput, getSuppliersPagination);

  const onSort = useCallback(
    (field?: string, sortDirection?: SortDirection) => {
      const searchParams = new URLSearchParams(window.location.search);

      const filters = {
        sortBy: (field as GetSuppliersSortBy) || searchParams.get('sort'),
        sortDirection: sortDirection
          ? sortDirection === SortDirection.ASCENDING
            ? 'ASC'
            : 'DESC'
          : (searchParams.get('dir') as SortDirectionGql) || undefined,
      };
      if (filters.sortDirection) {
        searchParams.set('dir', filters.sortDirection);
      }
      if (filters.sortBy) {
        searchParams.set('sort', filters.sortBy);
      }

      setGetSuppliersInput((s) => ({
        ...s,
        ...filters,
      }));
      setGetSuppliersPagination((s) => ({
        ...s,
        offset: (1 - 1) * s.limit,
      }));

      searchParams.set('page', '1');
      history.push(`/suppliers?${searchParams.toString()}`);
    },
    [getSuppliersInput]
  );
  const translate = useTranslation();

  return (
    <>
      <Helmet title={translate('pages.suppliers.title')} />
      <AuthenticatedPageWrapper className="bg-gray-50">
        <div className="mb-16 p-4">
          <ManageableItemsList>
            <ManageableItemsList.HeaderBar
              heading="Suppliers"
              buttons={
                <>
                  <PermissionGuard
                    renderIfHasPermissions={[
                      SuppliersPermissions.ARCHIVE_SUPPLIER,
                    ]}
                  >
                    {!!selectedSuppliers.length && (
                      <>
                        <Button
                          onClick={() =>
                            setShowConfirmDeleteSuppliersModal(true)
                          }
                          size={EBtnSize.Small}
                          variant={EBtnVariant.Outline}
                          colour={EBtnColour.Red}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </PermissionGuard>
                  <PermissionGuard
                    renderIfHasPermissions={[
                      SuppliersPermissions.PERSIST_SUPPLIER,
                    ]}
                  >
                    <Button
                      size={EBtnSize.Small}
                      onClick={() => setShowCreateSupplierModal(true)}
                    >
                      Create Supplier
                    </Button>
                  </PermissionGuard>
                </>
              }
            />
            <ManageableItemsList.ActionBar>
              <ManageableItemsList.ActionBar.SearchInput
                value={searchTerm}
                onChange={setSearchTerm}
                onChangeTimeout={(value) => {
                  const searchParams = new URLSearchParams(
                    window.location.search
                  );
                  searchParams.set('term', value);
                  searchParams.set('page', '1');
                  setGetSuppliersInput((s) => ({
                    ...s,
                    searchTerm: value,
                  }));
                  setGetSuppliersPagination((s) => ({
                    ...s,
                    offset: 0,
                  }));
                  history.push(`/suppliers?${searchParams.toString()}`);
                }}
                changeTimeoutMs={250}
              />
            </ManageableItemsList.ActionBar>
            <ManageableItemsList.Table
              isLoading={isLoading}
              items={suppliers?.suppliers.items || []}
              uniqueKey="id"
              onClickRow={(supplier) => {
                history.push(`/suppliers/${supplier.id}`);
              }}
              selectedItems={selectedSuppliers}
              setSelectedItems={
                userHasRequiredPermission(userRoles, [
                  SuppliersPermissions.ARCHIVE_SUPPLIER,
                ])
                  ? setSelectedSuppliers
                  : undefined
              }
              enableSelectAll
            >
              <ManageableItemsList.Table.Column<ListedSupplier, 'name'>
                header="Supplier"
                field="name"
                sortByName="NAME"
                onSort={onSort}
              />
              <ManageableItemsList.Table.Column<ListedSupplier, 'email'>
                header="Email"
                field="email"
              />
              <ManageableItemsList.Table.Column<ListedSupplier, 'phone'>
                header="Phone"
                field="phone"
              />
              <ManageableItemsList.Table.Column<ListedSupplier, 'website'>
                header="Website"
                field="website"
              />
              <ManageableItemsList.Table.Column<ListedSupplier, 'addresses'>
                header="Addresses"
                field="addresses"
                render={(addresses, row) => {
                  return (
                    <div>
                      {addresses.map((address, i) => {
                        return (
                          <div key={`${row.id}-address-${i}`}>
                            {getAddressAsString(address.address)}
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
            </ManageableItemsList.Table>

            <ManageableItemsList.PaginationBar
              pageSize={getSuppliersPagination.limit}
              currentPage={
                getSuppliersPagination.offset / getSuppliersPagination.limit + 1
              }
              totalItems={suppliers?.suppliers.totalCount || 0}
              onPageChange={(newPage) => {
                setGetSuppliersPagination((s) => ({
                  ...s,
                  offset: (newPage - 1) * s.limit,
                }));
              }}
            />
          </ManageableItemsList>
        </div>
        <CreateEditSupplierModal
          isOpen={showCreateSupplierModal}
          onClose={() => setShowCreateSupplierModal(false)}
          onPersistSupplierSuccess={(supplierId: number) => {
            history.push(`/suppliers/${supplierId}`);
          }}
        />
        <ConfirmDeleteSuppliersModal
          isOpen={showConfirmDeleteSuppliersModal}
          onClose={() => setShowConfirmDeleteSuppliersModal(false)}
          selectedSuppliers={selectedSuppliers}
          onDeleteCallback={(error?: Error) => {
            if (error) {
              dispatch(appActions.showBanner({ type: 'error' }));
            }
            setShowConfirmDeleteSuppliersModal(false);
            void refetchSuppliers();
          }}
        />
      </AuthenticatedPageWrapper>
    </>
  );
};

export default ListedSuppliersPage;
