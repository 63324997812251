import { DynamicViewField } from '@/ui/pages/connectionApplicationPage/DynamicViewField';
import { FC } from 'react';
import { DynamicField } from './DynamicField';
import { getDependentFields, getSortedFieldSetDataProperties } from './utils';

const excludedFields = [
  'meterNumber', // Equivalent to MPAN
  'installationPostCode', // Copied from mainAddress
];

const FieldSet: FC<{
  data: any;
  namespace?: string;
  readonly?: boolean;
  definitions: Record<string, any>;
}> = ({ data, namespace: fieldNamespace, readonly, definitions }) => {
  const sortedProperties = getSortedFieldSetDataProperties(
    data.properties,
    fieldNamespace
  );

  return Object.entries<any>(sortedProperties).map(([key, value]) => {
    if (excludedFields.includes(key)) return null;

    const dependentFields = getDependentFields(data, key, fieldNamespace);
    const namespaceOverride = value.json_schema_extra?.namespaceOverride;
    const formKey = [namespaceOverride || fieldNamespace, key]
      .filter((x) => x)
      .join('.');
    return readonly ? (
      <DynamicViewField
        fieldSchema={value}
        key={formKey}
        name={formKey}
        namespace={fieldNamespace}
        definitions={definitions}
      />
    ) : (
      <DynamicField
        fieldSchema={value}
        key={formKey}
        name={formKey}
        namespace={fieldNamespace}
        definitions={definitions}
        dependentFields={dependentFields}
      />
    );
  });
};

export default FieldSet;
