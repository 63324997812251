import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import meKeys from '@/api/queries/me/keyFactory';
import { graphql } from '@/gql';
import { GetTagsInput } from '@/gql/graphql';

const GetMyAvailableTags = graphql(`
  query MyAvailableTags($input: GetTagsInput) {
    me {
      user {
        account {
          availableTags(input: $input) {
            items {
              id
              name
              colour
              archivedAt
            }
          }
        }
      }
    }
  }
`);

const useGetMyAvailableTags = (input?: Pick<GetTagsInput, 'status'>) => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountAvailableTags(),
    queryFn: () => {
      return gqlClient.request(GetMyAvailableTags, {
        input,
      });
    },
  });

  return { data: data?.me.user.account.availableTags.items, ...rest };
};

export default useGetMyAvailableTags;
