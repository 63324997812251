import { SortDirection } from '@payaca/types/listViewTypes';
import { FunctionComponent } from 'react';
import SortDirectionControl from '../sortDirectionControl/SortDirectionControl';
import './SortableColumnHeaderTableCell.sass';

type Props = {
  className?: string;
  align?: 'left' | 'right' | 'center';
  columnName: string;
  selectedSortDirection?: SortDirection;
  onChangeSortDirection: (sortDirection: SortDirection) => void;
};

const SortableColumnHeaderTableCell: FunctionComponent<Props> = ({
  className,
  align = 'left',
  columnName,
  selectedSortDirection,
  onChangeSortDirection,
}: Props): JSX.Element | null => {
  return (
    <th className={`sortable-column-header-table-cell ${className}`}>
      <div
        className="sortable-column-header-inner"
        style={{
          textAlign: align,
          justifyContent:
            align === 'left'
              ? 'flex-start'
              : align === 'center'
                ? 'center'
                : 'flex-end',
        }}
      >
        <span>{columnName}</span>
        <SortDirectionControl
          selectedSortDirection={selectedSortDirection}
          onChange={onChangeSortDirection}
        />
      </div>
    </th>
  );
};

export default SortableColumnHeaderTableCell;
