import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import connectionApplicationKeys from '@/api/queries/connectionApplication/keyFactory';
import { graphql } from '@/gql';

const GetConnectionApplicationQuery = graphql(`
  query GetConnectionApplication($id: ID!) {
    connectionApplication(id: $id) {
      id
      status
      data
      submittedAt
      connectionApplicationStatus
    }
  }
`);

const useGetConnectionApplication = (
  connectionApplicationId: string,
  enabled?: boolean
) => {
  return useQuery({
    enabled: enabled || !!connectionApplicationId,
    queryKey: connectionApplicationKeys.connectionApplication(
      connectionApplicationId
    ),
    queryFn: () => {
      return gqlClient.request(GetConnectionApplicationQuery, {
        id: connectionApplicationId,
      });
    },
  });
};

export default useGetConnectionApplication;
