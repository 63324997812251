import { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@payaca/components/modal/Modal';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';

import { actions as jobActions } from '@/api/jobs';

type Props = {
  isOpen: boolean;
  jobId: number | null;
  onClose: () => void;
  onRestoreJobCallback: () => void;
};

const RestoreJobModal: FunctionComponent<Props> = ({
  isOpen,
  jobId,
  onClose,
  onRestoreJobCallback,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasRestoreJobError, setHasRestoreJobError] = useState<boolean>(false);

  const onModalClose = useCallback(() => {
    setHasRestoreJobError(false);
    onClose();
  }, [onClose]);

  const onRestoreJob = useCallback(() => {
    setHasRestoreJobError(false);
    if (jobId) {
      setIsProcessing(true);
      dispatch(
        jobActions.unarchiveJob(jobId, (error: any) => {
          setIsProcessing(false);
          if (error) {
            setHasRestoreJobError(true);
          } else {
            onModalClose();
            onRestoreJobCallback();
          }
        })
      );
    }
  }, [dispatch, jobId, onModalClose]);

  return (
    <Modal
      isOpen={isOpen}
      title="Restore job"
      size="xs"
      onClose={onModalClose}
      className="restore-job-modal"
      actions={
        <>
          <Button
            onClick={onRestoreJob}
            isProcessing={isProcessing}
            styleVariant={ButtonStyleVariant.OUTSIZE}
          >
            Restore
          </Button>
          <Button
            onClick={onModalClose}
            styleVariant={ButtonStyleVariant.ANCHOR}
          >
            Cancel
          </Button>
          {hasRestoreJobError && (
            <ErrorMessage
              message={
                'Sorry, there was an error restoring this job. Please try again.'
              }
            />
          )}
        </>
      }
    >
      <p>
        Restoring this job will also restore any related quotes or invoices. Are
        you sure you want to continue?
      </p>
    </Modal>
  );
};

export default RestoreJobModal;
