import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/RawInput';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

export type TLineItemReferenceFormState = {
  reference: string;
};

export const LINE_ITEM_REFERENCE_FORM_SCHEMA = z.object({
  reference: z.string(),
});

type TProps = {
  className?: string;
};

const ReferenceField: FC<TProps> = (props) => {
  const { className } = props;

  const formMethods = useFormContext<TLineItemReferenceFormState>();

  return (
    <Field
      className={className}
      validationState={
        formMethods.formState.errors.reference
          ? {
              isValid: false,
              validationMessages: ['Reference is required'],
            }
          : undefined
      }
    >
      <Field.Label>Internal reference</Field.Label>

      <Input {...formMethods.register('reference')} />
    </Field>
  );
};

export default ReferenceField;
