import moment from 'moment-timezone';

import { WebAppState } from '@/api/state';
import { isQuoteOrEstimate } from '@payaca/helpers/jobStatusHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import { Customer } from '@payaca/types/customerTypes';
import { Deal } from '@payaca/types/dealTypes';
import { Document } from '@payaca/types/documentTypes';
import { FileDownloadTypes } from '@payaca/types/fileDownloadTypes';
import {
  Invoice,
  InvoiceLine,
  PaymentReconciliationRecord,
} from '@payaca/types/invoiceTypes';
import {
  JobContent,
  JobLineItem,
  JobLineItemGroup,
} from '@payaca/types/jobContentTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import { JobAttachment, JobLineItemAttachment } from '@payaca/types/jobTypes';
import { Job } from '@payaca/types/jobTypesV2';
import { LineItemGroup } from '@payaca/types/lineItemGroupTypes';
import { LineItem } from '@payaca/types/lineItemTypes';
import {
  LineItemMaterial,
  Material,
  SupplierMaterial,
} from '@payaca/types/materialTypes';
import {
  MaterialsList,
  MaterialsListMaterial,
} from '@payaca/types/materialsListTypes';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';
import { Supplier } from '@payaca/types/supplierTypes';
import { Task } from '@payaca/types/taskTypes';
import { Upload } from '@payaca/types/uploadTypes';
import { User } from '@payaca/types/userTypes';

const emptyArray: Array<any> = [];

export const getMaterialsList = (
  state: WebAppState,
  materialsListId: number
): MaterialsList | undefined => {
  return (
    state.materialsLists.materialsLists &&
    state.materialsLists.materialsLists[materialsListId]?.entity
  );
};

export const getMaterialsListMaterial = (
  state: WebAppState,
  materialsListMaterialId: number
): MaterialsListMaterial | undefined => {
  return (
    state.materialsLists.materialsListMaterials &&
    state.materialsLists.materialsListMaterials[materialsListMaterialId]?.entity
  );
};

export const getMaterialsListMaterialsForMaterialsList = (
  state: WebAppState,
  materialsListId: number
): MaterialsListMaterial[] => {
  const materialsList = getMaterialsList(state, materialsListId);

  if (!materialsList?.materialsListMaterialIds?.length) return [];

  return materialsList.materialsListMaterialIds
    .map((id) => getMaterialsListMaterial(state, id))
    .filter(Boolean) as MaterialsListMaterial[];
};

export const getUpload = (
  state: WebAppState,
  uploadId: number
): Upload | undefined => {
  return state.uploads.uploads && state.uploads.uploads[uploadId]?.entity;
};

export const getUploadsByIds = (
  state: WebAppState,
  uploadIds: number[]
): Upload[] => {
  return uploadIds
    .map((uploadId) => getUpload(state, uploadId))
    .filter(Boolean) as Upload[];
};

export const getRegion = (state: any) => {
  return state.users?.myProfile?.accounts?.[0]?.region ?? AccountRegions.UK;
};

export const getScheduledEvent = (
  state: WebAppState,
  scheduledEventId: number
): ScheduledEvent | undefined => {
  return (
    state.scheduledEvents.scheduledEvents &&
    state.scheduledEvents.scheduledEvents[scheduledEventId]?.entity
  );
};

export const getSupplier = (
  state: WebAppState,
  supplierId: number
): Supplier | undefined => {
  return (
    state.suppliers.suppliers && state.suppliers.suppliers[supplierId]?.entity
  );
};

export const getMaterial = (
  state: WebAppState,
  materialId: number
): Material | undefined => {
  return (
    state.materials.materials && state.materials.materials[materialId]?.entity
  );
};

export const getMaterials = (
  state: WebAppState,
  materialIds: number[]
): Material[] => {
  return materialIds
    .map((id) => state.materials.materials[id]?.entity)
    .filter((x) => !!x);
};

export const getSupplierMaterial = (
  state: WebAppState,
  supplierMaterialId: number
): SupplierMaterial | undefined => {
  return (
    state.materials.supplierMaterials &&
    state.materials.supplierMaterials[supplierMaterialId]?.entity
  );
};

export const getLineItemMaterial = (
  state: WebAppState,
  lineItemMaterialId: number
): LineItemMaterial | undefined => {
  return (
    state.materials.lineItemMaterials &&
    state.materials.lineItemMaterials[lineItemMaterialId]?.entity
  );
};

export const getSupplierMaterialsForMaterial = (
  state: WebAppState,
  materialId: number
): SupplierMaterial[] => {
  const material = getMaterial(state, materialId);

  if (!material?.supplierMaterialIds?.length) return [];

  return material.supplierMaterialIds
    .map((id) => getSupplierMaterial(state, id))
    .filter(Boolean) as SupplierMaterial[];
};

export const getSuppliersForMaterial = (
  state: WebAppState,
  materialId: number
): Supplier[] => {
  const material = getMaterial(state, materialId);

  if (!material?.supplierIds?.length) return [];

  return material.supplierIds
    .map((id) => getSupplier(state, id))
    .filter(Boolean) as Supplier[];
};

export const getLineItemMaterialsForLineItem = (
  state: WebAppState,
  lineItemId: number
): LineItemMaterial[] => {
  const lineItem = getLineItem(state, lineItemId);

  if (!lineItem?.lineItemMaterialIds?.length) return [];
  return lineItem.lineItemMaterialIds
    .map((id) => getLineItemMaterial(state, id))
    .filter(Boolean) as LineItemMaterial[];
};

export const getMaterialsForLineItem = (
  state: WebAppState,
  lineItemId: number
): Material[] => {
  const lineItem = getLineItem(state, lineItemId);

  if (!lineItem?.materialIds?.length) return [];
  return lineItem.materialIds
    .map((id) => getMaterial(state, id))
    .filter(Boolean) as Material[];
};

export const getDeal = (
  state: WebAppState,
  dealId: number
): Deal | undefined => {
  return state.deals.deals && state.deals.deals[dealId]?.entity;
};

export const getCustomer = (
  state: WebAppState,
  customerId: number
): Customer | undefined => {
  return (
    state.customer.customers && state.customer.customers[customerId]?.entity
  );
};

export const getCustomerByDealId = (
  state: WebAppState,
  dealId: number
): Customer | undefined => {
  const deal = getDeal(state, dealId);
  if (!deal?.customerId) return;
  return getCustomer(state, deal.customerId);
};

export const getDealByJobId = (
  state: WebAppState,
  jobId: number
): Deal | undefined => {
  const job = getJob(state, jobId);
  if (!job) return;
  return getDeal(state, job.dealId);
};

export const getJob = (state: WebAppState, jobId: number): Job | undefined => {
  return state.jobsStore.jobs && state.jobsStore.jobs[jobId]?.entity;
};

export const getJobContent = (
  state: WebAppState,
  jobContentId: number
): JobContent | undefined => {
  return (
    state?.jobContent?.jobContents &&
    state.jobContent.jobContents[jobContentId]?.entity
  );
};

const sortableJobTimestamps: Array<keyof Job> = ['createdAt'];

type SortableJob = Job & { sortTimestamp: Date | undefined };

export const getLineItemGroup = (
  state: WebAppState,
  lineItemGroupId: number
): LineItemGroup | undefined => {
  return (
    state.lineItemGroups.lineItemGroups &&
    state.lineItemGroups.lineItemGroups[lineItemGroupId]?.entity
  );
};

export const getLineItemsByLineItemGroupId = (
  state: WebAppState,
  lineItemGroupId: number
): LineItem[] => {
  const lineItemGroup = getLineItemGroup(state, lineItemGroupId);
  if (!lineItemGroup) return [];
  if (!state.lineItemsV2.lineItems) return [];

  const lineItemIds =
    lineItemGroup?.lineItemRelations?.map((x: any) => x.lineItemId) ||
    emptyArray;

  return getLineItemsByLineItemIds(state, lineItemIds);
};

export const getLineItemsByLineItemIds = (
  state: WebAppState,
  lineItemIds: number[]
): LineItem[] => {
  const lineItems = lineItemIds.map((lineItemId: number) => {
    const lineItem = state.lineItemsV2.lineItems[lineItemId]?.entity;
    return lineItem;
  });

  return lineItems.filter((x?: LineItem) => !!x);
};

export const getLineItem = (
  state: WebAppState,
  lineItemId: number
): LineItem | undefined => {
  return (
    state.lineItemsV2?.lineItems &&
    state.lineItemsV2.lineItems[lineItemId]?.entity
  );
};

export const getInvoicesByDealId = (
  state: WebAppState,
  dealId: number
): Invoice[] => {
  const deal = getDeal(state, dealId);
  if (!deal || deal.version < 2) return [];
  if (!state.invoices.invoices) return [];

  const invoiceIds = deal?.invoiceIds || emptyArray;

  const invoices = invoiceIds.map((invoiceId: number) => {
    return state.invoices.invoices[invoiceId]?.entity;
  });

  return invoices
    .filter((x?: Invoice) => !!x)
    .sort(
      (a: Invoice, b: Invoice) =>
        new Date(b.sentAt || b.createdAt || '').getTime() -
        new Date(a.sentAt || a.createdAt || '').getTime()
    );
};

export const getJobsByDealId = (state: WebAppState, dealId: number): Job[] => {
  const deal = getDeal(state, dealId);
  if (!deal) return [];
  if (!state.jobsStore.jobs) return [];

  let jobIds = deal?.propositionIds || emptyArray;
  if (deal?.invoiceIds?.length) {
    jobIds = jobIds.concat(deal.invoiceIds);
  }

  const jobs = jobIds.map((jobId: number) => {
    const job = state.jobsStore.jobs[jobId]?.entity;
    if (!job) return null;
    const sortTimestamp = sortableJobTimestamps.find((t) => job[t]);
    return { ...job, sortTimestamp: sortTimestamp ? job[sortTimestamp] : '' };
  });

  return (jobs.filter((x) => !!x) as Array<SortableJob>).sort(
    (a: SortableJob, b: SortableJob) =>
      new Date(b.sortTimestamp || '').getTime() -
      new Date(a.sortTimestamp || '').getTime()
  ) as Array<Job>;
};

export const getInvoice = (
  state: WebAppState,
  invoiceId: number
): Invoice | undefined => {
  return state.invoices.invoices && state.invoices.invoices[invoiceId]?.entity;
};

export const getInvoiceLine = (
  state: WebAppState,
  invoiceLineId: number
): InvoiceLine | undefined => {
  return (
    state.invoices?.invoiceLines &&
    state.invoices.invoiceLines[invoiceLineId]?.entity
  );
};

export const getInvoiceLinesByInvoiceId = (
  state: WebAppState,
  invoiceId: number
): InvoiceLine[] => {
  const invoice = getInvoice(state, invoiceId);
  if (!invoice) return [];

  if (!state.invoices.invoiceLines) return [];

  const invoiceLineIds = invoice.invoiceLineIds || emptyArray;

  const invoiceLines = invoiceLineIds.map((invoiceLineId: number) => {
    return getInvoiceLine(state, invoiceLineId);
  });

  return invoiceLines.filter((x?: InvoiceLine) => !!x);
};

export const getJobLineItemsByInvoiceId = (
  state: WebAppState,
  invoiceId: number
): JobLineItem[] => {
  const invoiceLines = getInvoiceLinesByInvoiceId(state, invoiceId);

  return invoiceLines
    .map((invoiceLine) => getJobLineItem(state, invoiceLine.jobLineItemId))
    .filter(Boolean) as JobLineItem[];
};

export const getInvoiceLinesByDealId = (
  state: WebAppState,
  dealId: number
): InvoiceLine[] => {
  const deal = getDeal(state, dealId);
  if (!deal) return [];

  const invoiceLineIds = deal.invoiceLineIds;

  const invoiceLines = invoiceLineIds.map((invoiceLineId: number) => {
    return getInvoiceLine(state, invoiceLineId);
  });

  return invoiceLines.filter((x?: InvoiceLine) => !!x);
};

export const getJobLineItemsByDealId = (
  state: WebAppState,
  dealId: number
): JobLineItem[] => {
  const deal = getDeal(state, dealId);
  if (!deal) return [];

  const jobLineItemIds = deal.jobLineItemIds;

  const jobLineItems = jobLineItemIds.map((jobLineItemId: number) => {
    return getJobLineItem(state, jobLineItemId);
  });

  return jobLineItems.filter((x?: JobLineItem) => !!x);
};

export const getPaymentReconciliationRecord = (
  state: WebAppState,
  paymentReconciliationRecordId: number
): PaymentReconciliationRecord | undefined => {
  return (
    state.invoices.paymentReconciliationRecords &&
    state.invoices.paymentReconciliationRecords[paymentReconciliationRecordId]
      ?.entity
  );
};

export const getPaymentReconciliationRecordsByDealId = (
  state: WebAppState,
  dealId: number
): PaymentReconciliationRecord[] => {
  const deal = getDeal(state, dealId);
  if (!deal) return [];

  if (!state.invoices.paymentReconciliationRecords) return [];

  const paymentReconciliationrecordIds =
    deal.paymentReconciliationRecordIds || emptyArray;

  const paymentReconciliationRecords = paymentReconciliationrecordIds.map(
    (paymentReconciliationrecordId: number) => {
      return getPaymentReconciliationRecord(
        state,
        paymentReconciliationrecordId
      );
    }
  );

  return paymentReconciliationRecords.filter(
    (x?: PaymentReconciliationRecord) => !!x
  );
};

export const getPaymentReconciliationRecordsByInvoiceId = (
  state: WebAppState,
  invoiceId: number
): PaymentReconciliationRecord[] => {
  const invoice = getInvoice(state, invoiceId);
  if (!invoice) return [];

  if (!state.invoices.paymentReconciliationRecords) return [];

  const paymentReconciliationrecordIds =
    invoice.paymentReconciliationRecordIds || emptyArray;

  const paymentReconciliationRecords = paymentReconciliationrecordIds.map(
    (paymentReconciliationrecordId: number) => {
      return getPaymentReconciliationRecord(
        state,
        paymentReconciliationrecordId
      );
    }
  );

  return paymentReconciliationRecords.filter(
    (x?: PaymentReconciliationRecord) => !!x
  );
};

export const getJobContentsByDealId = (
  state: WebAppState,
  dealId: number
): JobContent[] => {
  const deal = getDeal(state, dealId);
  if (!deal) return [];

  if (!state.jobContent.jobContents) return [];

  const jobContentIds = deal.jobContentIds || emptyArray;

  const jobContents = jobContentIds.map((jobContentId: number) => {
    const jobContent = getJobContent(state, jobContentId);
    if (!jobContent) return null;
    return jobContent;
  });

  return jobContents.filter((x: JobContent | null) => !!x);
};

export const getTasksByScheduledEventId = (
  state: WebAppState,
  scheduledEventId: number
): Task[] => {
  const scheduledEvent = getScheduledEvent(state, scheduledEventId);
  if (!scheduledEvent) return [];

  if (!state.tasks.tasks) return [];

  const taskIds = scheduledEvent.taskIds || emptyArray;

  const tasks = taskIds.map((taskId: number) => {
    const task = getTask(state, taskId);
    if (!task) return null;
    return task;
  });

  return tasks.filter((x: Task | null) => !!x);
};

export const getTasksByDealId = (
  state: WebAppState,
  dealId: number
): Task[] => {
  const deal = getDeal(state, dealId);
  if (!deal) return [];

  if (!state.tasks.tasks) return [];

  const taskIds = deal.taskIds || emptyArray;

  const tasks = taskIds.map((taskId: number) => {
    const task = getTask(state, taskId);
    if (!task) return null;
    return task;
  });

  return tasks.filter((x: Task | null) => !!x);
};

export const getJobPayment = (
  state: WebAppState,
  jobPaymentId: number
): JobPayment | undefined => {
  return (
    state.jobPayments.jobPayments &&
    state.jobPayments.jobPayments[jobPaymentId]?.entity
  );
};

export const getTask = (
  state: WebAppState,
  taskId: number
): Task | undefined => {
  return state.tasks.tasks && state.tasks.tasks[taskId]?.entity;
};

export const getJobContentByJobId = (
  state: WebAppState,
  jobId: number
): JobContent | undefined => {
  const job = getJob(state, jobId);
  if (!job) return;
  return getJobContent(state, job.jobContentId);
};

export const getJobPaymentsByJobId = (
  state: WebAppState,
  jobId: number
): JobPayment[] => {
  const job = getJob(state, jobId);
  if (!job) return [];
  return getJobPaymentsByDealId(state, job.dealId);
};

export const getJobPaymentsByDealId = (
  state: WebAppState,
  dealId: number
): JobPayment[] => {
  const deal = getDeal(state, dealId);
  if (!deal) return [];
  if (!state.jobPayments?.jobPayments) return [];

  const jobPayments = deal.jobPaymentIds.map((jobPaymentId: number) => {
    return state.jobPayments.jobPayments[jobPaymentId]?.entity;
  });

  return jobPayments.filter((x?: JobPayment) => !!x);
};

export const getJobLineItem = (state: WebAppState, jobLineItemId: number) => {
  return (
    state.jobContent.jobLineItems &&
    state.jobContent.jobLineItems[jobLineItemId]?.entity
  );
};

export const getJobLineItemGroup = (
  state: WebAppState,
  jobLineItemGroupId: number
) => {
  return (
    state.jobContent.jobLineItemGroups &&
    state.jobContent.jobLineItemGroups[jobLineItemGroupId]?.entity
  );
};

// This function returns the first JobLineItemAttachment it finds for the given
// JobLineItem ID. Why do we only care about one? There could be many! The use
// case for the function isn't clear to me.
export const getJobLineItemAttachmentByJobLineItemId = (
  state: WebAppState,
  jobLineItemId: number
) => {
  if (!jobLineItemId) return;
  const jobLineItem =
    state.jobContent.jobLineItems &&
    state.jobContent.jobLineItems[jobLineItemId]?.entity;
  const jobLineItemAttachmentIds =
    jobLineItem?.jobLineItemAttachmentIds || emptyArray;

  const jobLineItemAttachments = jobLineItemAttachmentIds.map(
    (jobLineItemAttachmentId) => {
      return (
        state.jobContent.jobLineItemAttachments &&
        state.jobContent.jobLineItemAttachments[jobLineItemAttachmentId]?.entity
      );
    }
  );

  return jobLineItemAttachments.find(Boolean);
};

export const getJobAttachmentsByJobId = (state: WebAppState, jobId: number) => {
  const job = getJob(state, jobId);
  if (!job) return [];
  if (!state.jobsStore.jobAttachments) return [];

  const jobAttachmentIds = job?.jobAttachmentIds || emptyArray;

  const jobAttachments = jobAttachmentIds.map((jobAttachmentId) => {
    return state.jobsStore.jobAttachments[jobAttachmentId]?.entity;
  });

  return jobAttachments.filter(Boolean) as Array<JobAttachment>;
};

export const getJobLineItemGroupsByJobContentId = (
  state: WebAppState,
  jobContentId: number
) => {
  const jobContent = getJobContent(state, jobContentId);
  if (!jobContent) return [];
  const lineItemGroups = jobContent.jobLineItemGroupIds.map((id) =>
    getJobLineItemGroup(state, id)
  );
  return lineItemGroups.filter(Boolean) as Array<JobLineItemGroup>;
};

export const getJobLineItemsByJobContentId = (
  state: WebAppState,
  jobContentId: number
) => {
  const jobContent = getJobContent(state, jobContentId);
  if (!jobContent) return [];
  const lineItems = jobContent.jobLineItemIds.map((id) =>
    getJobLineItem(state, id)
  );
  return lineItems.filter(Boolean) as Array<JobLineItem>;
};

export const getJobLineItemAttachments = (
  state: WebAppState,
  jobLineItemAttachmentIds: number[]
) => {
  return jobLineItemAttachmentIds
    .map(
      (jobLineItemAttachmentId) =>
        state.jobContent?.jobLineItemAttachments &&
        state.jobContent.jobLineItemAttachments[jobLineItemAttachmentId]?.entity
    )
    .filter(Boolean) as Array<JobLineItemAttachment>;
};

export const getJobLineItemAttachmentsByJobContentId = (
  state: WebAppState,
  jobContentId: number
) => {
  const jobLineItems = getJobLineItemsByJobContentId(state, jobContentId);
  const attachments = jobLineItems.reduce(
    (acc: JobLineItemAttachment[], jli: JobLineItem) => {
      return [
        ...acc,
        ...getJobLineItemAttachments(state, jli.jobLineItemAttachmentIds),
      ];
    },
    []
  );
  return attachments.filter(Boolean);
};

export const getRelatedJobByDealId = (
  state: any,
  dealId: number,
  jobId: number
) => {
  const jobs = getJobsByDealId(state, dealId);

  const currentJob = getJob(state, jobId);

  if (!currentJob)
    return jobs.find((job) => job.id != jobId && !job.inactivatedAt);

  const isCurrentJobProposition = isQuoteOrEstimate(currentJob?.status);

  return jobs.find(
    (job) =>
      job.id != jobId &&
      !job.inactivatedAt &&
      isQuoteOrEstimate(job.status) != isCurrentJobProposition
  );
};

export const getJobDownloadByJobId = (
  state: any,
  jobId: number,
  type: FileDownloadTypes
) => {
  if (!jobId) return '';
  return state.fileDownload?.fileDownloads?.[jobId]?.[type]?.entity || '';
};

export const getScheduledEventsByDealId = (
  state: WebAppState,
  dealId: number
): ScheduledEvent[] => {
  const deal = getDeal(state, dealId);
  if (!deal) return [];
  if (!state.scheduledEvents?.scheduledEvents) return [];
  if (!deal?.scheduledEventIds?.length) return [];

  const scheduledEvents = deal.scheduledEventIds.map(
    (scheduledEventId: number) => {
      return state.scheduledEvents.scheduledEvents[scheduledEventId]?.entity;
    }
  );

  // sort by begin at and end at date
  return scheduledEvents
    .filter((x?: ScheduledEvent) => !!x)
    .sort((a: ScheduledEvent, b: ScheduledEvent) => {
      const formattedDate = (date: Date) => moment(date, 'YYYY-MM-DD HH:mm');
      if (formattedDate(a.beginAt) === formattedDate(b.beginAt)) {
        // sort by end date if start date is same
        return formattedDate(b.endAt).isBefore(formattedDate(a.endAt)) ? -1 : 1;
      }
      // sort by start date
      return formattedDate(b.beginAt).isBefore(formattedDate(a.beginAt))
        ? 1
        : -1;
    });
};

export const getScheduledEventsByTaskId = (
  state: WebAppState,
  taskId: number
): ScheduledEvent[] => {
  const task = getTask(state, taskId);
  if (!task) return [];
  if (!state.scheduledEvents?.scheduledEvents) return [];
  if (!task?.scheduledEventIds?.length) return [];

  const scheduledEvents = task.scheduledEventIds
    .map((scheduledEventId: number) => {
      return state.scheduledEvents.scheduledEvents[scheduledEventId]?.entity;
    })
    .filter(Boolean) as Array<ScheduledEvent>;

  return scheduledEvents;
};

export const getScheduledEventsForCalendar = (
  state: WebAppState
): ScheduledEvent[] => {
  const scheduledEventsObj = state.scheduledEvents.scheduledEvents;
  return Object.values(scheduledEventsObj || {})
    .map((se) => se.entity)
    .filter(Boolean) as Array<ScheduledEvent>;
};

export const getActiveUsers = (state: any): User[] => {
  return state.users.accountUsers.filter(
    (accountUser: User) => !accountUser.deactivatedAt || accountUser.inviteToken
  );
};

export const getActiveJobContentByDealId = (
  state: WebAppState,
  dealId: number
) => {
  const jobContents = getJobContentsByDealId(state, dealId);
  return jobContents?.find((x) => x.isActive);
};

export const getDocumentsByDealId = (
  state: WebAppState,
  dealId: number
): any[] => {
  const deal = getDeal(state, dealId);
  if (!deal) return [];
  if (!state.documents?.documentsStore) return [];
  if (!deal?.documentIds?.length) return [];

  const documents = deal.documentIds.map((documentId: number) => {
    return state.documents.documentsStore[documentId]?.entity;
  });

  return documents.filter((x?: any) => !!x);
};

export const getDocument = (
  state: WebAppState,
  documentId: number
): Document | undefined => {
  return (
    state.documents.documentsStore &&
    state.documents.documentsStore[documentId]?.entity
  );
};

export const getUserRoles = (state: WebAppState) => {
  return state?.userRoles?.roles || emptyArray;
};

export const getIsFetchingUserRoles = (state: WebAppState) => {
  return state?.userRoles?.isFetchingUserRoles || false;
};
