import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useUpdateProject from '@/api/mutations/project/useUpdateProject';
import projectKeys from '@/api/queries/project/keyFactory';
import useGetProjectOverview from '@/api/queries/project/useGetProjectOverview';
import { ProjectQuery } from '@/gql/graphql';
import Combobox from '@payaca/components/plCombobox/Combobox';
import { SelectSizeVariant } from '@payaca/components/plSelect/Select';
import { DEFAULT_ACCOUNT_PIPELINE } from '@payaca/constants/defaultPipeline';
import { defaultPipelineStages } from '@payaca/types/pipelineTypes';

const PipelineStageControl: FC = () => {
  const { dealId } = useParams<{ dealId: string }>();
  const { data: projectData } = useGetProjectOverview(+dealId);
  const queryClient = useQueryClient();
  const { mutateAsync: updateProjectMutation } = useUpdateProject({
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: projectKeys.overview(+dealId),
      });

      // Snapshot the previous value
      const previousProjectOverview = queryClient.getQueryData(
        projectKeys.overview(+dealId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<ProjectQuery>(
        projectKeys.overview(+dealId),
        (old) => {
          if (!old) return;

          const newSelectedPipelineStage = old.project.pipeline.stages.find(
            (stage) => stage.title === variables.pipelineStage
          );

          if (!newSelectedPipelineStage) {
            // Should never get here
            return old;
          }

          return {
            ...old,
            project: {
              ...old.project,
              currentPipelineStage: newSelectedPipelineStage,
            },
          };
        }
      );

      // Return a context object with the snapshotted value
      return { previousProjectOverview };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        projectKeys.overview(+dealId),
        // @ts-ignore
        context.previousProjectOverview
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.project(+dealId),
      });
    },
  });

  const isDefaultPipeline = useMemo(() => {
    if (!projectData || !projectData?.project?.pipeline) return false;

    return projectData.project.pipeline.name === DEFAULT_ACCOUNT_PIPELINE.title;
  }, [projectData]);

  const selectedPipelineStage = useMemo(() => {
    if (!projectData || !projectData?.project?.currentPipelineStage) return;

    return projectData.project.currentPipelineStage.title;
  }, [projectData, isDefaultPipeline]);

  const currentPipelineStages = useMemo(() => {
    if (!projectData || !projectData?.project?.pipeline?.stages) return [];

    const currentStageIndex = projectData.project.pipeline.stages.findIndex(
      (s) => s.id === projectData.project.currentPipelineStage.id
    );

    // get indices of default pipeline stages
    const defaultStageIndices = projectData.project.pipeline.stages
      .map((x, i) => ({
        ...x,
        i,
      }))
      .filter((x) => defaultPipelineStages.some((z) => z === x.title))
      .map((x) => x.i);

    // get index of min available pipeline stage - deal cannot be moved to a stage with a lower index than this
    const minStageIndexInclusive =
      [...defaultStageIndices].reverse().find((x) => x <= currentStageIndex) ||
      0;

    // get index of max available pipeline stage - deal cannot be moved to a stage with an equal or higher index than this
    const maxStageIndexExclusive =
      defaultStageIndices.find((x) => x > currentStageIndex) ||
      projectData.project.pipeline.stages.length;

    return (
      projectData.project.pipeline.stages.map((stage, i) => ({
        value: stage.title,
        label: stage.title,
        disabled: isDefaultPipeline
          ? stage.title !== projectData.project.currentPipelineStage.title
          : i < minStageIndexInclusive || i >= maxStageIndexExclusive,
      })) || []
    );
  }, [projectData, isDefaultPipeline]);

  const handleChange = useCallback(
    async (value: string | string[]) => {
      if (Array.isArray(value) || isDefaultPipeline) {
        // Should never get here
        return;
      }

      await updateProjectMutation({
        projectId: dealId,
        pipelineStage: value,
      });
    },
    [updateProjectMutation, dealId, isDefaultPipeline]
  );

  return (
    <Combobox
      CustomSelected={({ selectedOptions }) =>
        selectedOptions[0] ? (
          <>
            <b>Stage:</b> {selectedOptions[0]?.label}
          </>
        ) : null
      }
      sizeVariant={SelectSizeVariant.SM}
      value={selectedPipelineStage}
      options={currentPipelineStages}
      onChange={handleChange}
    />
  );
};

export default PipelineStageControl;
