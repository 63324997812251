import moment from 'moment-timezone';
import { FC } from 'react';

import { formatAnswer } from '@payaca/helpers/formHelper';
import CompanyLogo from '../companyLogo/CompanyLogo';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import {
  useCertificateNumber,
  useCommonFormAccountData,
  useInspectionFormAddresses,
} from './hooks';

import './FormTemplateElements.sass';
import './OilFiringInstallationCompletionReport.sass';

import GasSafeLogo from './gas-safe.png';

interface Props {
  data: any;
  account: any;
}

const OilFiringInstallationCompletionReport: FC<Props> = ({
  data,
  account,
}) => {
  const { brandColour } = useCommonFormAccountData(account);
  const {
    accountAddressString,
    inspectionAddressString,
    landlordAddressString,
  } = useInspectionFormAddresses(account, data);
  const certificateNumber = useCertificateNumber(data);

  if (!data || !account) return <></>;

  return (
    <div
      className={
        'form-template-output oil-firing-installation-completion-report'
      }
    >
      <div className={'form-template-header'}>
        <div>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Oil Firing Installation Completion Report</h3>
        </div>
        <div className={'flex-container flex-center'}>
          <div className="header-details-container">
            <LabelValuePair label="Cert No" value={certificateNumber} />
          </div>
          {data.gas_safe_reg_number && (
            <img
              className="gas-safe-logo"
              src={GasSafeLogo}
              alt={'Gas Safe Logo'}
            />
          )}
        </div>
      </div>
      <div className="form-template-row company-inspection-landlord-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          className="company-details-content-panel"
          title={'Company/Installer'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="company-details-content-panel-body">
            <LabelValuePair label="Engineer" value={data.completed_by} />
            <LabelValuePair label="Company" value={account?.companyName} />
            <LabelValuePair label="Address" value={accountAddressString} />
            <LabelValuePair label="Contact" value={account?.contactNumber} />
            {data.gas_safe_reg_number && (
              <LabelValuePair
                label="Gas Safe Reg"
                value={data.gas_safe_reg_number}
              />
            )}
            {data.id_card_number && (
              <LabelValuePair
                label="ID Card Number"
                value={data.id_card_number}
              />
            )}

            <LabelValuePair label="E-Mail" value={account?.email} />
          </div>
        </OutlinedContentPanel>

        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Inspection/Installation Address'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.name} />
          <LabelValuePair label="Address" value={inspectionAddressString} />
          <LabelValuePair label="E-Mail" value={data.email} />
          <LabelValuePair label="Contact" value={data.contact} />
        </OutlinedContentPanel>

        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Landlord/Agent Details'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.landlord_name} />
          <LabelValuePair label="Address" value={landlordAddressString} />
          <LabelValuePair label="E-mail" value={data.landlord_email} />
          <LabelValuePair label="Contact" value={data.landlord_contact} />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row oil-details">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Oil Storage Details'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="TI/133 Risk assessment completed"
            value={formatAnswer(data.ti_133_risk_assessment)}
          />
          <LabelValuePair
            label="Tank type"
            value={formatAnswer(data.tank_type)}
          />
          <LabelValuePair
            label="Capacity (Litres)"
            value={formatAnswer(data.capacity_litres)}
          />
          <LabelValuePair
            label="OFCERT License No."
            value={formatAnswer(data.ofcert_license_no)}
          />
          <LabelValuePair label="Make" value={formatAnswer(data.make)} />
          <LabelValuePair
            label="Serial no."
            value={formatAnswer(data.serial_no)}
          />
          <LabelValuePair
            label="Remote fill system and pressure tested"
            value={formatAnswer(data.remote_fill_system_and)}
          />
          <LabelValuePair
            label="Overfill alarm facility provided"
            value={formatAnswer(data.overfill_alarm_facility_provided)}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Oil Supply Details'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Oil supply line OD (mm)"
            value={formatAnswer(data.oil_supply_line_od)}
          />
          <LabelValuePair
            label="Material"
            value={formatAnswer(data.material)}
          />
          <LabelValuePair
            label="Pressure tested"
            value={formatAnswer(data.pressure_tested)}
          />
          <LabelValuePair
            label="OFTEC oil pipe installation guidance complied with?"
            value={formatAnswer(data.oftec_oil_pipe_installation_compliance)}
          />
          <LabelValuePair
            label="Remote sensing fire valve fitted"
            value={formatAnswer(data.remote_sensing_fire_valve)}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Flueing Arrangements'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Flue type"
            value={formatAnswer(data.flue_type)}
          />
          <LabelValuePair
            label="Termination to BS 5410-1 and manufacturers installation instructions"
            value={formatAnswer(data.termination_to_bs_5410)}
          />
          <LabelValuePair
            label="Stainless steel liner fitted"
            value={formatAnswer(data.stainless_steel_liner_fitted)}
          />
          <LabelValuePair
            label="Diameter (mm)"
            value={formatAnswer(data.diameter_mm)}
          />
          <LabelValuePair
            label="Location and type of conventional flue installed"
            value={formatAnswer(data.location_and_type_of)}
          />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row appliance-details">
        <OutlinedContentPanel
          colourOverride={brandColour}
          className="appliance-content-panel"
          title={'Appliance Details'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="appliance-content-panel-body">
            <LabelValuePair
              label="Appliance type"
              value={formatAnswer(data.appliance_type)}
            />
            <LabelValuePair
              label="Appliance make"
              value={formatAnswer(data.tank_type)}
            />
            <LabelValuePair
              label="Serial No."
              value={formatAnswer(data.serial_no)}
            />
            <LabelValuePair
              label="Burner make"
              value={formatAnswer(data.burner_make)}
            />
            <LabelValuePair
              label="Burner model"
              value={formatAnswer(data.burner_model)}
            />
            <LabelValuePair
              label="Serial no."
              value={formatAnswer(data.serial_no)}
            />
            <LabelValuePair
              label="Burner type"
              value={formatAnswer(data.burner_type)}
            />
            <LabelValuePair label="Boiler" value={formatAnswer(data.boiler)} />
            <LabelValuePair
              label="Boiler model"
              value={formatAnswer(data.boiler_model)}
            />
            <LabelValuePair
              label="Nozzle size"
              value={formatAnswer(data.nozzle_size)}
            />
            <LabelValuePair
              label="SEDBUK Efficiency (%)"
              value={formatAnswer(data.sedbuk_efficiency)}
            />
          </div>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row air-electrical-controls-system">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Air supply/ Electrical safety/ Controls fitted'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Combustion (mm)"
            value={formatAnswer(data.combustion_mm)}
          />
          <LabelValuePair
            label="Ventilation (mm)"
            value={formatAnswer(data.ventilation_mm)}
          />
          <LabelValuePair
            label="OFTEC guidance complied with if fan has been fitted?"
            value={formatAnswer(data.extract_fan_oftec_compliance)}
          />
          <LabelValuePair
            label="Electrical minor works certificate issued"
            value={formatAnswer(data.electrical_minor_works_certificate)}
          />
          <LabelValuePair
            label="Electrical installation certificate issued"
            value={formatAnswer(
              data.electrical_installation_certificate_issued
            )}
          />
          <LabelValuePair
            label="Independent time and temperature control to C/H"
            value={formatAnswer(data.independent_time_and_temperature)}
          />
          <LabelValuePair
            label="Controls fitted - programmer"
            value={formatAnswer(data.controls_fitted.programmer)}
          />
          <LabelValuePair
            label="Controls fitted - room thermostat (programmable)"
            value={formatAnswer(
              data.controls_fitted.room_thermostat_programmable
            )}
          />
          <LabelValuePair
            label="Controls fitted - cyclinder thermostat"
            value={formatAnswer(data.controls_fitted.cylinder_thermostat)}
          />
          <LabelValuePair
            label="Controls fitted - TRVs"
            value={formatAnswer(data.controls_fitted.trvs)}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'System details'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Preparation - Was the existing system flushed and cleansed?"
            value={formatAnswer(data.preparation_was_the_existing)}
          />
          <LabelValuePair
            label="Completion - has the completed system been flushed and cleansed?"
            value={formatAnswer(data.completion_has_the_completed)}
          />
          <LabelValuePair
            label="Suitable inhibitor been added"
            value={formatAnswer(data.suitable_inhibitor)}
          />
          <LabelValuePair
            label="Suitable cold water treatment system"
            value={formatAnswer(data.suitable_cw_treatment_system)}
          />
          <LabelValuePair
            label="Measurement: differential across appliances flow and return (°C)"
            value={formatAnswer(
              data.measurement_differential_across_appliances
            )}
          />
          <LabelValuePair
            label="Sealed system pressure set to (bar)"
            value={formatAnswer(data.sealed_system_pressure_set)}
          />
          <LabelValuePair
            label="Cold water temp (°C)"
            value={formatAnswer(data.cold_water_temp_c)}
          />
          <LabelValuePair
            label="DHW temp (°C)"
            value={formatAnswer(data.dhw_temp_c)}
          />
          <LabelValuePair
            label="Flow rate (ltrs/min)"
            value={formatAnswer(data.flow_rate_ltrs_min)}
          />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row commissioning no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Commissioning'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <p>
            The correct completion of this report confirms details regarding the
            installation of equipment and does not negate the need for the
            appliance to be commissioned to achieve compliance with the Building
            Regulations and achieve peak efficiency and performance.
            Confirmation of commissioning should be declared on a separate
            certificate. (e.g. OFTEC CD/11).
          </p>
          <div className="commissioning-personnelle">
            <LabelValuePair
              label="Commission arranged by installer"
              value={formatAnswer(
                data.commissioning_arrangements.commission_arranged_by_installer
              )}
            />
            <LabelValuePair
              label="OR commission to be arranged by"
              value={formatAnswer(
                data.commissioning_arrangements.commission_arranged_by
              )}
            />
          </div>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row signatures no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Completed by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.completed_by} />
          <LabelValuePair
            label="Signed"
            value={
              <div className={'form-template-signature'}>
                {data.engineer_signature && (
                  <img src={data.engineer_signature} />
                )}
              </div>
            }
          />
          <LabelValuePair
            label="Inspection conducted"
            value={moment(data.issue_date).format('DD/MM/YYYY')}
            noBreak={true}
          />
          <LabelValuePair
            label="Next Inspection Date"
            value={moment(data.next_inspection_due_date).format('DD/MM/YYYY')}
            noBreak={true}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Received by"
          className="customer-signature"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div>
            <LabelValuePair label="Name" value={data.name} />
            <LabelValuePair
              label="Signed"
              value={
                <div className={'form-template-signature'}>
                  {data.customer_signature && (
                    <img src={data.customer_signature} />
                  )}
                </div>
              }
            />
          </div>
          {data.customer_not_present && <p>Customer not present</p>}
        </OutlinedContentPanel>
      </div>
    </div>
  );
};

export default OilFiringInstallationCompletionReport;
