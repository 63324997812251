import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import RouterTabs, {
  TabPanel,
} from '@payaca/components/plRouterTabs/RouterTabs';
import { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useGetUser } from '../../../api/queries/user/useGetUser';
import { useTranslation } from '../../../i18n';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import UserPageHeaders from './components/UserPageHeaders';
import UserProfile from './components/UserProfileTab';

type Props = {
  userId: number;
};
const UserPage: FC<Props> = (props) => {
  const translate = useTranslation();
  const { userId } = props;
  const { data: userData } = useGetUser(userId);
  const user = userData?.user;

  const routerTabPanels = useMemo<TabPanel[]>(() => {
    return [
      {
        icon: 'user-03',
        label: 'Profile',
        slug: 'profile',
        render: () => <UserProfile />,
      },
    ];
  }, []);

  return (
    <>
      <Helmet
        title={translate('pages.userProfile.title', {
          name: user?.fullName || '',
        })}
      />
      <AuthenticatedPageWrapper
        className="bg-gray-50"
        breadcrumbItems={[
          { label: 'Users', To: '/users' },
          { label: user?.fullName || '' },
        ]}
      >
        <RouterTabs panels={routerTabPanels}>
          <div className="flex flex-col p-6 pb-4">
            <Card sizeVariant={CardSizeVariant.SM}>
              <Card.Body>
                <UserPageHeaders />
                <RouterTabs.Nav
                  className="md:-mb-5 mt-7"
                  defaultTabSlug="profile"
                />
              </Card.Body>
            </Card>
          </div>

          <div className="p-6 pt-0">
            <RouterTabs.Panels />
          </div>
        </RouterTabs>
      </AuthenticatedPageWrapper>
    </>
  );
};

export default UserPage;
