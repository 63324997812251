import { FC } from 'react';

import JobAttachmentsControl from '../jobAttachmentsControl/JobAttachmentsControl';
import EditJobSection from './EditJobSection';

type Props = {
  jobId: number;
};
const JobPromoAttachmentsControl: FC<Props> = ({ jobId }: Props) => {
  return (
    <EditJobSection
      title="Attachments"
      description="Show off some credentials. Photos of your team, completed jobs, awards logos, industry accreditations. It all helps to win you work."
    >
      <JobAttachmentsControl jobId={jobId} />
    </EditJobSection>
  );
};

export default JobPromoAttachmentsControl;
