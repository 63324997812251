import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { RestoreProjectMutation as _RestoreProjectMutation } from '@/gql/graphql';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

const RestoreProjectMutation = graphql(`
  mutation RestoreProject($input: ProjectIdInput!) {
    restoreProject(input: $input) {
      id
    }
  }
`);

const useRestoreProject = (
  options?: UseMutationOptions<_RestoreProjectMutation, unknown, string>
) => {
  return useMutation({
    mutationFn: (projectId: string) => {
      return gqlClient.request(RestoreProjectMutation, {
        input: { projectId },
      });
    },
    ...options,
  });
};

export default useRestoreProject;
