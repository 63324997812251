import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import materialKeys from '@/api/queries/materials/keyFactory';
import { graphql } from '@/gql';

const GetMaterialCategories = graphql(`
  query GetMaterialCategories {
    materialCategories {
      id
      name
    }
  }
`);

const useGetMaterialCategories = () => {
  const { data, ...rest } = useQuery({
    queryKey: materialKeys.categories,
    // Material Categories can not be changed by users, so we can cache them forever
    cacheTime: Infinity,
    queryFn: () => {
      return gqlClient.request(GetMaterialCategories);
    },
  });

  return { materialCategories: data?.materialCategories, ...rest };
};

export default useGetMaterialCategories;
