import { size } from '@floating-ui/react';
import { Float } from '@headlessui-float/react';
import { Menu } from '@headlessui/react';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, Fragment } from 'react';
import Button, { IButtonProps } from '../plButton/Button';
import NumberOfSelectedFilters from '../plManageableItemsList/components/NumberOfSelectedFilters';
import DropdownCore, { TDropdownItem } from './DropdownCore';

export interface IProps extends IButtonProps {
  filters: TDropdownItem[];
  name?: string;
}

const FilterDropdown: FC<IProps> = (props) => {
  const { filters, name = 'Filter', ...buttonProps } = props;

  const numOfSelectedFilters = filters.filter(
    (filter) => filter.selected
  ).length;

  return (
    <Menu>
      <Float
        floatingAs={Fragment}
        portal
        placement="bottom-end"
        autoPlacement={{
          alignment: 'start',
          allowedPlacements: ['top-start', 'bottom-start'],
        }}
        middleware={[
          size({
            apply({ elements, availableHeight }) {
              Object.assign(elements.floating.style, {
                maxHeight: `${availableHeight}px`,
                overflow: 'auto',
              });
            },
            padding: 8,
          }),
        ]}
      >
        <Menu.Button as={Button} {...buttonProps}>
          <UntitledIcon name="filter-lines.3" className="h-3.5 w-3.5" />
          {name}
          {numOfSelectedFilters > 0 && (
            <NumberOfSelectedFilters
              numberOfSelectedFilters={numOfSelectedFilters}
            />
          )}
        </Menu.Button>

        <DropdownCore items={filters} />
      </Float>
    </Menu>
  );
};

export default FilterDropdown;
