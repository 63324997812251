import { FC } from 'react';

import { useTranslation } from '@/i18n';
import AssigneeGroupedListedTimelogsControl from '@/ui/components/assigneeGroupedListedTimelogsControl/AssigneeGroupedListedTimelogsControl';
import { Helmet } from 'react-helmet';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import './TimelogReportingPage.sass';

import { Routes } from '@/routes/RoutesConfig';
import { TimelogsRoles } from '@payaca/permissions/timelogs/timelogs.roles';

const TimelogReportingPage: FC = (): JSX.Element => {
  const translate = useTranslation();

  return (
    <>
      <Helmet title={translate('pages.timelogs.title')} />
      <AuthenticatedPageWrapper
        pageRouteId={Routes.TIMELOG_REPORTING}
        title="Timelog reporting"
        className="timelog-reporting-page"
        requiredRoles={[TimelogsRoles.ASSIGNEE_VIEWER]}
      >
        <AssigneeGroupedListedTimelogsControl />
      </AuthenticatedPageWrapper>
    </>
  );
};

export default TimelogReportingPage;
