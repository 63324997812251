import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as lineItemGroupActions from '@payaca/store/lineItemGroups/lineItemGroupsActions';

import { useSelector } from '@/api/state';
import { HeaderTagType } from '@payaca/components/headerTag/HeaderTag';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import EmptyState from '../emptyState/EmptyState';
import ListedLineItemGroupTile from '../listedLineItemGroupTile/ListedLineItemGroupTile';
import './ListedLineItemGroups.sass';

type Props = {
  onLineItemGroupClick: (lineItemGroupId: number) => void;
};
const ListedLineItemGroups: FC<Props> = ({
  onLineItemGroupClick,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lineItemGroups = useSelector(
    (state) => state.lineItemGroups.lineItemGroups
  );

  const listedLineItemGroups = useMemo(
    () =>
      Object.values(lineItemGroups)
        .map((x) => x.entity)
        .filter((x) => !!x),
    [lineItemGroups]
  );

  useEffect(() => {
    dispatch(lineItemGroupActions.requestGetLineItemGroups());
  }, []);

  return (
    <ResponsiveViewWrapper
      className={`listed-line-item-groups`}
      downBreakpointSm={1000}
      downBreakpointXs={600}
    >
      <>
        <div className="listed-line-item-groups-inner">
          {listedLineItemGroups.map((lineItemGroup: any, index: number) => {
            return (
              <ListedLineItemGroupTile
                onClick={() => onLineItemGroupClick(lineItemGroup.id)}
                key={`listed-line-item-group-tile-${index}`}
                lineItemGroupId={lineItemGroup.id}
                titleHeaderTagType={HeaderTagType.H2}
              />
            );
          })}
        </div>
        {!listedLineItemGroups?.length && (
          <EmptyState configName={'itemgroups'} arrowIndication={false} />
        )}
      </>
    </ResponsiveViewWrapper>
  );
};

export default ListedLineItemGroups;
