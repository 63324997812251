import { ChangeProposal } from '@payaca/types/changeProposalTypes';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  getCustomerByDealId,
  getRegion,
  getUserRoles,
} from '@/utils/stateAccessors';
import './PreviewAndSendChangeProposalControl.sass';

import { useSelector } from '@/api/state';
import MarkChangeProposalAsSentModal from '@/ui/components/previewChangeProposalActionButtons/MarkChangeProposalAsSentModal';
import { downloadFile } from '@/utils/download-file';
import { TDropdownItem } from '@payaca/components/plDropdown/DropdownCore';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import { buildEmailSubjectPrefixForProjectFromProject } from '@payaca/helpers/dealHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { ProposalsPermissions } from '@payaca/permissions/proposals/proposals.permissions';
import { useChangeProposal, useDeal } from '@payaca/store/hooks/appState';
import * as proposalsActions from '@payaca/store/proposals/proposalsActions';
import { getChangeProposalGeneratedPdfUrl } from '@payaca/store/proposals/proposalsActions';
import { useHistory } from 'react-router';
import ChangeProposalDocument from '../changeProposalDocument/ChangeProposalDocument';
import PreviewAndSendSendableDocument from '../previewAndSendSendableDocument/PreviewAndSendSendableDocument';

type Props = {
  changeProposalId: number;
};

const PreviewAndSendChangeProposalControl: FunctionComponent<Props> = ({
  changeProposalId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showMarkAsSentModal, setShowMarkAsSentModal] = useState(false);
  const [isGettingPdfUrl, setIsGettingPdfUrl] = useState(false);

  const userRoles = useSelector(getUserRoles);

  const companyName = useSelector(
    (state: any) => state.users.myProfile.accounts[0].companyName
  );

  const region = useSelector(getRegion);

  const changeProposal: ChangeProposal | undefined =
    useChangeProposal(changeProposalId);

  const deal = useDeal(changeProposal?.dealId);

  const customer = useSelector((state) => {
    if (!changeProposal) return;
    return getCustomerByDealId(state, changeProposal.dealId);
  });

  const customerContact = useMemo(() => {
    if (!customer) return;
    return getJobContactFromCustomer(
      customer,
      changeProposal?.contactId || null
    );
  }, [customer?.contacts, changeProposal?.contactId]);

  const onChangeProposalUpdateSuccess = useCallback(() => {
    dispatch(proposalsActions.requestGetChangeProposal(changeProposalId));
  }, [dispatch, changeProposalId]);

  const navigateToDeal = useCallback(() => {
    history.push(`/deals/${changeProposal?.dealId}?view=proposals`);
  }, [history, changeProposal]);

  const handleDownloadChangeProposal = useCallback(() => {
    if (!changeProposal) return;
    setIsGettingPdfUrl(true);
    dispatch(
      getChangeProposalGeneratedPdfUrl.request({
        changeProposalId: changeProposalId,
        callback: (pdfUrl: string) => {
          setIsGettingPdfUrl(false);
          downloadFile(
            pdfUrl,
            `change-proposal-${
              changeProposal.customReference || changeProposal.reference
            }.pdf`
          );
        },
        onErrorCallback: () => setIsGettingPdfUrl(false),
      })
    );
  }, [setIsGettingPdfUrl, changeProposalId, changeProposal]);

  const availableContextMenuItems = useMemo(() => {
    const actions: TDropdownItem[] = [];

    if (
      userHasRequiredPermission(userRoles, [
        ProposalsPermissions.SEND_CHANGE_PROPOSAL,
      ])
    ) {
      actions.push({
        label: 'Mark as sent',
        onClick: () => setShowMarkAsSentModal(true),
      });
    }

    actions.push({
      label: 'Download',
      onClick: handleDownloadChangeProposal,
    });

    return actions;
  }, [userRoles, handleDownloadChangeProposal, setShowMarkAsSentModal]);

  if (!customer || !changeProposal) return null;

  const emailConfig = useMemo(() => {
    return {
      subject: `${
        deal && buildEmailSubjectPrefixForProjectFromProject(deal)
      }Your change proposal from ${companyName}`,
      bodyTemplate: `
      Dear [customer_name],
      Your change proposal representing a value change of [value_change] can be viewed here:
      [button]
      Thanks,
      [company_name]
      `,
      substitutions: [
        {
          find: 'reference',
          replaceWith:
            changeProposal.customReference || String(changeProposal.reference),
        },
        { find: 'company_name', replaceWith: companyName },
        { find: 'customer_name', replaceWith: customerContact?.name },
        {
          find: 'value_change',
          replaceWith: currencyPrice(changeProposal.valueChangeIncTax, region),
        },
      ],
    };
  }, [
    companyName,
    changeProposal.customReference,
    changeProposal.reference,
    customerContact?.name,
    changeProposal.valueChangeIncTax,
    region,
  ]);

  return (
    <>
      <PreviewAndSendSendableDocument
        documentType="change proposal"
        title={`Change proposal #${
          changeProposal?.customReference || changeProposal?.reference
        }`}
        documentPreview={
          <ChangeProposalDocument changeProposalId={changeProposalId} />
        }
        recipientConfig={{
          customer: customer,
          contactId: changeProposal?.contactId,
        }}
        emailConfig={emailConfig}
        onSend={(payload, callback, onErrorCallback) =>
          dispatch(
            proposalsActions.sendChangeProposal.request({
              changeProposalId: changeProposalId,
              sendMeACopy: payload.sendMeACopy,
              emailCopy: payload.emailCopy,
              callback: () => {
                onChangeProposalUpdateSuccess();
                callback();
                navigateToDeal();
              },
              onErrorCallback,
            })
          )
        }
        actions={availableContextMenuItems}
      />
      <MarkChangeProposalAsSentModal
        isOpen={showMarkAsSentModal}
        changeProposalId={changeProposalId}
        onClose={() => setShowMarkAsSentModal(false)}
        markAsSentCallback={() => {
          onChangeProposalUpdateSuccess();
          navigateToDeal();
        }}
      />
    </>
  );
};

export default PreviewAndSendChangeProposalControl;
