import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import List from '@payaca/components/list/List';
import Badge from '@payaca/components/plBadge/Badge';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import ContextMenu from '@payaca/components/plDropdown/ContextMenu';
import Sidebar, {
  Props as SidebarProps,
} from '@payaca/components/plSidebar/Sidebar';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { clstx } from '@payaca/components/utils';
import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, PropsWithChildren, useMemo, useState } from 'react';
import useGetMaterial from '../../../../api/queries/materials/useGetMaterial';
import { useSelector } from '../../../../api/state';
import { getUserRoles } from '../../../../utils/stateAccessors';
import CreateEditSupplierMaterialModal, {
  CreateEditSupplierMaterial,
} from '../../../components/createEditSupplierMaterialModal/CreateEditSupplierMaterialModal';
import EditMaterialModal from './EditMaterialModal';

const ViewMaterialSidebarContent: FC<{
  materialId?: string;
}> = (props) => {
  const { materialId } = props;
  const userRoles = useSelector(getUserRoles);

  const {
    data,
    isFetching,
    refetch: refetchMaterial,
  } = useGetMaterial(materialId);
  const material = data?.material;
  const [showSupplierMaterialModal, setShowSupplierMaterialModal] =
    useState<Partial<CreateEditSupplierMaterial> | null>(null);
  const [showEditMaterialModal, setShowEditMaterialModal] = useState(false);

  const linkedMaterialSuppliers = material?.suppliedBy || [];

  const materialContextMenuOptions = useMemo(() => {
    const options = [];
    if (
      material?.source === 'SELF' &&
      userHasRequiredPermission(userRoles, [
        MaterialsPermissions.PERSIST_MATERIAL,
      ])
    ) {
      options.push({
        label: 'Edit',
        onClick: () => setShowEditMaterialModal(true),
      });
    }
    return options;
  }, [userRoles, material]);

  if (!material && isFetching) {
    return (
      <div className="grid h-full grid-cols-2">
        <div className="flex flex-col gap-4 px-4 py-3.5">
          <SkeletonLoader.Square className="h-[200px] w-[200px] rounded" />
          <div className="flex flex-col gap-2">
            <SkeletonLoader.Text className="w-20" />
            <SkeletonLoader.Title className="w-1/2" />
          </div>
          <div className="flex flex-col gap-2">
            <SkeletonLoader.Text className="w-20" />
            <SkeletonLoader className="h-16 w-full rounded-lg" />
          </div>
          <div className="flex flex-col gap-2">
            <SkeletonLoader.Text className="w-20" />
            <SkeletonLoader.Title className="w-2/5" />
          </div>
        </div>
        <div className="space-y-4 border-l bg-gray-50 p-3.5 ">
          <SkeletonLoader.SupplierMaterialCard />
          <SkeletonLoader.SupplierMaterialCard />
          <SkeletonLoader.SupplierMaterialCard />
        </div>
      </div>
    );
  }

  return (
    <div className="grid h-full grid-cols-2">
      <div className="flex flex-col space-y-3.5 overflow-auto px-4 py-3.5">
        <div className="flex flex-row items-start justify-between pb-4">
          <div
            className={clstx(
              'flex h-[200px] w-[200px] items-center justify-center overflow-hidden rounded-lg bg-white'
            )}
          >
            {material?.image?.thumbnailUrl ? (
              <img src={material.image.thumbnailUrl} alt={material?.name} />
            ) : (
              <UntitledIcon
                name="image-01.3"
                className="non-scaling-stroke h-20 w-20"
              />
            )}
          </div>
          {!!materialContextMenuOptions.length && (
            <ContextMenu
              variant={EBtnVariant.White}
              size={EBtnSize.Small}
              menuItems={materialContextMenuOptions}
            />
          )}
        </div>
        <View>
          <View.Property>
            <View.Property.Label>Name</View.Property.Label>
            <View.Property.Value>{material?.name}</View.Property.Value>
          </View.Property>
          <View.Property>
            <View.Property.Label>Description</View.Property.Label>
            <View.Property.Value>{material?.description}</View.Property.Value>
          </View.Property>
          <View.Property>
            <View.Property.Label>Category</View.Property.Label>
            <View.Property.Value>
              {material?.category?.name && (
                <Badge variant="soft" colour="gray">
                  {material?.category?.name}
                </Badge>
              )}
            </View.Property.Value>
          </View.Property>
        </View>
      </div>

      {/* linked material suppliers */}
      <div className="flex flex-col overflow-auto border-l bg-gray-50 p-3.5">
        <List
          items={linkedMaterialSuppliers}
          prefix={
            <>
              <h4>Linked Suppliers ({linkedMaterialSuppliers.length})</h4>

              <Button
                className="w-full"
                variant={EBtnVariant.White}
                size={EBtnSize.Small}
                onClick={() => {
                  if (material?.internalId) {
                    setShowSupplierMaterialModal({
                      materialInternalId: +material.internalId,
                      materialId: material.id,
                    });
                  }
                }}
              >
                Link new Supplier
              </Button>
            </>
          }
          item={(supplierMaterial) => {
            const supplierMaterialEl = !!supplierMaterial?.reference && (
              <span className="text-sm">Ref: {supplierMaterial.reference}</span>
            );

            return (
              <Card
                sizeVariant={CardSizeVariant.SM}
                className="cursor-pointer"
                onClick={() => {
                  if (material?.internalId) {
                    setShowSupplierMaterialModal({
                      supplierId: supplierMaterial.supplier.id,
                      supplierInternalId: +supplierMaterial.supplier.id,
                      id: supplierMaterial.id,
                      materialInternalId: +material.internalId,
                      materialId: material.id,
                      price: supplierMaterial.price.unitPriceExcTax.value,
                      url: supplierMaterial.url,
                      reference: supplierMaterial.reference,
                      taxRateId: supplierMaterial.price.taxRate?.id,
                      isPreferredSupplier:
                        material?.preferredSupplierId ===
                        supplierMaterial.supplier.id,
                      isSystemManaged: supplierMaterial.isSystemManaged,
                    });
                  }
                }}
              >
                <Card.Body className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    <span>{supplierMaterial.supplier.name}</span>
                    {supplierMaterial.url ? (
                      <a
                        href={supplierMaterial.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {supplierMaterialEl}
                        <UntitledIcon
                          name="link-external-01.3"
                          className="ml-2 h-3.5 w-3.5"
                        />
                      </a>
                    ) : (
                      supplierMaterialEl
                    )}
                  </div>
                  <div className="flex flex-col items-end">
                    <CurrencyValue
                      value={supplierMaterial.price.unitPriceExcTax.value}
                      currency={supplierMaterial.price.unitPriceExcTax.currency}
                    />
                    {material?.preferredSupplierId ===
                      supplierMaterial.supplier.id && (
                      <Badge variant="soft" colour="yellow" size="sm">
                        Preferred Supplier
                      </Badge>
                    )}
                  </div>
                </Card.Body>
              </Card>
            );
          }}
        />
      </div>
      {material && (
        <EditMaterialModal
          isOpen={showEditMaterialModal}
          onClose={() => setShowEditMaterialModal(false)}
          material={{
            id: material.id,
            internalId: +material.internalId,
            name: material.name,
            description: material?.description || undefined,
            ...(material.image
              ? {
                  image: {
                    id: material.image.id,
                    thumbnailUrl: material.image.thumbnailUrl || '',
                  },
                }
              : {}),
            ...(material?.category?.id
              ? {
                  category: {
                    id: +material.category.id,
                    label: material?.category?.name,
                  },
                }
              : {}),
          }}
          onPersistMaterialSuccess={() => refetchMaterial()}
        />
      )}
      <CreateEditSupplierMaterialModal
        isOpen={!!showSupplierMaterialModal}
        onClose={() => setShowSupplierMaterialModal(null)}
        supplierMaterial={showSupplierMaterialModal || undefined}
        isMaterialSelectionDisabled={true}
        onPersistSupplierMaterialSuccess={() => {
          setShowSupplierMaterialModal(null);
          void refetchMaterial();
        }}
        isSupplierSelectionDisabled={!!showSupplierMaterialModal?.supplierId}
      />
    </div>
  );
};

const ViewForm: FC<PropsWithChildren> = (props) => {
  return (
    <div className="grid grid-cols-[max-content_auto]">{props.children}</div>
  );
};
const ViewFormProperty: FC<PropsWithChildren> = (props) => {
  return <>{props.children}</>;
};
const ViewFormPropertyLabel: FC<PropsWithChildren> = (props) => {
  return (
    <span className="col-auto border-t py-4 pr-4 text-gray-600">
      {props.children}:
    </span>
  );
};
const ViewFormPropertyValue: FC<PropsWithChildren> = (props) => {
  return <span className="border-t py-4">{props.children}</span>;
};
const View = Object.assign(ViewForm, {
  Property: Object.assign(ViewFormProperty, {
    Label: ViewFormPropertyLabel,
    Value: ViewFormPropertyValue,
  }),
});

const ViewMaterialSidebar: FC<
  Omit<SidebarProps, 'size' | 'title' | 'behind'> & { materialId?: string }
> = (props) => {
  const { materialId, isOpen, onClose, ...rest } = props;

  return (
    <>
      <Sidebar
        isOpen={isOpen}
        size="lg"
        title="Material"
        onClose={onClose}
        {...rest}
      >
        <Sidebar.Body>
          <ViewMaterialSidebarContent materialId={materialId} />
        </Sidebar.Body>
      </Sidebar>
    </>
  );
};

export default ViewMaterialSidebar;
