import UntitledIcon from '@payaca/untitled-icons';
import { FunctionComponent, useMemo } from 'react';
import Badge from '../plBadge/Badge';

type valueChangeType = number | 'Infinity' | '-Infinity';

type Props = {
  valueChange: valueChangeType;
  valueChangeFormatter?: (valueChange: valueChangeType) => string;
};

const ValueChangeBadge: FunctionComponent<Props> = ({
  valueChange,
  valueChangeFormatter,
}): JSX.Element | null => {
  const valueChangeString = useMemo(() => {
    return valueChangeFormatter?.(valueChange) || valueChange.toString();
  }, [valueChange, valueChangeFormatter]);

  const isDecrease = useMemo(() => {
    return (
      valueChange == '-Infinity' ||
      (valueChange != 'Infinity' && valueChange < 0)
    );
  }, [valueChange]);

  return (
    <Badge variant="soft" colour={isDecrease ? 'red' : 'teal'}>
      {valueChangeString}
      <UntitledIcon
        name={isDecrease ? 'arrow-down' : 'arrow-up'}
        className={`h-3 w-3`}
      />
    </Badge>
  );
};

export default ValueChangeBadge;
