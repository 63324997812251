import { FC, ReactNode, useMemo } from 'react';

import { getBrandingColourContext } from '@payaca/helpers/brandingColourContextHelper';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
  getRegionalFormattedValue,
  getRegionalTextString,
  RegionalFormattingTypes,
} from '@payaca/helpers/internationalHelper';
import { DocumentSenderContext } from '@payaca/types/accountBrandTypes';
import { ClientInvoice, PaymentMethodConfig } from '@payaca/types/clientTypes';
import {
  Customer,
  CustomerAddress,
  CustomerContact,
} from '@payaca/types/customerTypes';
import { DealAddress, DealTotals } from '@payaca/types/dealTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import { InvoiceLine } from '@payaca/types/invoiceTypes';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import moment from 'moment-timezone';
import ClientDocument from '../clientDocument/ClientDocument';
import DocumentCard from '../clientDocument/DocumentCard';
import InvoiceBody from '../clientDocument/InvoiceBody';
import { TBreadcrumbItem } from '../plBreadcrumb/Breadcrumb';
import './InvoiceDocument.sass';
import InvoiceSummary from './InvoiceSummary';
import InvoiceTotals from './InvoiceTotals';

type Props = {
  documentSenderContext: DocumentSenderContext;
  invoiceLines: InvoiceLine[]; // invoice lines for current invoice
  jobLineItems: JobLineItem[]; // job line items for current invoice
  invoiceId: number;
  invoices: Pick<
    ClientInvoice,
    | 'id'
    | 'sentAt'
    | 'voidedAt'
    | 'totalValue'
    | 'dueAt'
    | 'reference'
    | 'customReference'
    | 'contactId'
    | 'invoiceLineIds'
    | 'notes'
    | 'dueValue'
    | 'clientV2ProjectsViewURL'
    | 'clientV2ProjectViewURL'
    | 'dealReference'
  >[];
  dealTotals: Pick<DealTotals, 'acceptedValue'>;
  isStraightToInvoice?: boolean;
  customer: Pick<Customer, 'name'> & {
    addresses: Pick<CustomerAddress, 'address' | 'isBillingAddress'>[];
    contacts: Pick<
      CustomerContact,
      'name' | 'emailAddress' | 'isPrimaryContact' | 'telephoneNumber' | 'id'
    >[];
  };
  siteAddresses: DealAddress[];
  hideItemPrices?: boolean;
  paymentMethodConfig?: PaymentMethodConfig;
  pdfUrl?: string;
  pdfGenerator?: () => Promise<string>;
  attachments?: any[];
  CTA?: ReactNode;
  isPreview?: boolean;
};
const InvoiceDocument: FC<Props> = ({
  documentSenderContext,
  jobLineItems,
  invoiceLines,
  invoices,
  invoiceId,
  dealTotals,
  customer,
  siteAddresses,
  hideItemPrices = false,
  isStraightToInvoice = false,
  paymentMethodConfig,
  pdfUrl,
  pdfGenerator,
  attachments,
  CTA,
  isPreview = false,
}: Props): JSX.Element | null => {
  const brandingColourContext = getBrandingColourContext(
    documentSenderContext.brandColour
  );

  const invoice = useMemo(() => {
    return invoices.find((x) => x.id == invoiceId);
  }, [invoices, invoiceId]);

  const invoiceLinesForInvoice = useMemo(() => {
    return invoiceLines.filter((x) => invoice?.invoiceLineIds.includes(x.id));
  }, [invoiceLines, invoice]);

  const contact = useMemo(() => {
    if (!invoice) return;
    let c = customer.contacts.find((x) => x.id === invoice.contactId);
    if (c) return c;
    c = customer.contacts.find((x) => x.isPrimaryContact);
    if (c) return c;
    return customer.contacts[0];
  }, [customer, invoice]);

  const billingAddress = useMemo(() => {
    const a = customer.addresses.find((x) => x.isBillingAddress);
    if (a) return a;
    return customer.addresses[0];
  }, [customer]);

  const shortDateRegionalFormat = useMemo(
    () =>
      getInternationalMomentDateFormatByRegion(
        DateFormats.SHORT,
        documentSenderContext.region
      ),
    [documentSenderContext]
  );

  const { documentOriginAddressPart1, documentOriginAddressPart2 } =
    useMemo(() => {
      const part1 = [
        documentSenderContext.address?.line1,
        documentSenderContext.address?.line2,
      ].filter((x) => !!x?.length);
      const part2 = [
        documentSenderContext.address?.city,
        documentSenderContext.address?.postcode,
      ].filter((x) => !!x?.length);

      return {
        documentOriginAddressPart1: part1.length ? part1.join(', ') : undefined,
        documentOriginAddressPart2: part2.length ? part2.join(', ') : undefined,
      };
    }, [documentSenderContext]);

  const keyInformation = useMemo(() => {
    if (!invoice) return [];
    const hd = [
      {
        label: 'Reference',
        value: invoice.customReference || invoice.reference.toString(),
      },
    ];

    if (invoice.sentAt) {
      hd.push({
        label: 'Issued',
        value: moment(invoice.sentAt).format(shortDateRegionalFormat),
      });
    }

    if (!invoice.voidedAt && !!invoice.dueAt) {
      hd.push({
        label: 'Due',
        value: moment(invoice.dueAt).format(shortDateRegionalFormat),
      });
    }

    if (invoice.voidedAt) {
      hd.push({
        label: 'Due',
        value: moment(invoice.voidedAt).format(shortDateRegionalFormat),
      });
    }

    return hd;
  }, [invoice, shortDateRegionalFormat]);

  const vatRegionalLabel = useMemo(
    () =>
      getRegionalTextString(
        documentSenderContext.region,
        RegionalStrings.VALUE_ADDED_TAX
      ),
    [documentSenderContext.region]
  );

  const breadcrumbItems = useMemo(() => {
    if (
      !invoice ||
      (invoice?.clientV2ProjectsViewURL === undefined &&
        invoice?.clientV2ProjectViewURL === undefined)
    ) {
      return undefined;
    }

    const _breadcrumbItems: TBreadcrumbItem[] = [];

    if (invoice.clientV2ProjectsViewURL) {
      _breadcrumbItems.push({
        label: 'All Projects',
        href: invoice.clientV2ProjectsViewURL,
      });
    }

    if (invoice.clientV2ProjectViewURL) {
      _breadcrumbItems.push({
        label: `Project #${invoice.dealReference} overview`,
        href: invoice.clientV2ProjectViewURL,
      });
    }

    _breadcrumbItems.push({
      label: `Invoice #${
        invoice.customReference || invoice.reference.toString()
      }`,
    });

    return _breadcrumbItems;
  }, [invoice]);

  if (!invoice) return null;

  return (
    <ClientDocument
      hasMaxWidth={!isPreview}
      stickyTopClassName={isPreview ? 'top-24' : undefined}
      breadcrumbItems={breadcrumbItems}
      jobType="Invoice"
      documentBadge={
        invoice.voidedAt ? { label: 'Void', colour: 'red' } : undefined
      }
      pdfUrl={pdfUrl}
      pdfGenerator={pdfGenerator}
      pdfFileName={`invoice-${
        invoice.customReference || invoice.reference
      }.pdf`}
      logoUrl={documentSenderContext.logoUrl}
      siteAddresses={siteAddresses}
      billingAddress={billingAddress?.address}
      companyName={documentSenderContext.companyName}
      customer={customer as Customer}
      contact={contact}
      keyInformation={keyInformation}
      notes={invoice.notes}
      paymentTerms={documentSenderContext.invoicePaymentTerms}
      businessTerms={documentSenderContext.businessTerms}
      jobAttachments={attachments}
      documentOriginAddressPart1={documentOriginAddressPart1}
      documentOriginAddressPart2={documentOriginAddressPart2}
      contactSection={{
        email: documentSenderContext.emailAddress,
        phoneNumber: documentSenderContext.phoneNumber,
        registeredCountryName: documentSenderContext.registeredCountryName,
        companyRegistrationNumber:
          documentSenderContext.companyRegistrationNumber,
        vatNumber: documentSenderContext.vatNumber,
        vatRegNoRegionalLabel: vatRegionalLabel,
      }}
      brandingColourContext={brandingColourContext}
      SidebarContent={
        <DocumentCard>
          <InvoiceSummary
            invoices={invoices}
            invoiceId={invoiceId}
            dealTotals={dealTotals}
            invoiceLines={invoiceLinesForInvoice}
            jobLineItems={jobLineItems}
            region={documentSenderContext.region}
            brandingColourContext={brandingColourContext}
            isStraightToInvoice={isStraightToInvoice}
          />
          {paymentMethodConfig?.allowBankTransferPayment && (
            <>
              <table className="hidden text-base print:block">
                <tbody>
                  <tr>
                    <td className="pr-5">Payable to:</td>
                    <td className="font-semibold">
                      {
                        paymentMethodConfig.bankTransferPaymentSettings
                          .accountName
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="pr-5">
                      {getRegionalTextString(
                        documentSenderContext.region,
                        RegionalStrings.SORT_CODE
                      )}
                      :
                    </td>
                    <td className="font-semibold">
                      {getRegionalFormattedValue(
                        documentSenderContext.region,
                        RegionalFormattingTypes.SORT_CODE,
                        paymentMethodConfig.bankTransferPaymentSettings
                          .sortCode || ''
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="pr-5">Account number:</td>
                    <td className="font-semibold">
                      {getRegionalFormattedValue(
                        documentSenderContext.region,
                        RegionalFormattingTypes.ACCOUNT_NUMBER,
                        paymentMethodConfig.bankTransferPaymentSettings
                          .accountNumber || ''
                      )}
                    </td>
                  </tr>
                  {documentSenderContext.vatNumber && (
                    <tr>
                      <td className="pr-5">
                        {getRegionalTextString(
                          documentSenderContext.region,
                          RegionalStrings.VALUE_ADDED_TAX_NUMBER
                        )}
                        :
                      </td>
                      <td className="font-semibold">
                        {documentSenderContext.vatNumber}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}

          {CTA}
        </DocumentCard>
      }
    >
      <InvoiceBody
        invoiceLines={invoiceLinesForInvoice}
        jobLineItems={jobLineItems}
        hideItemPrices={hideItemPrices}
        showCancelledLines={false}
        region={documentSenderContext.region}
      >
        <InvoiceTotals
          invoices={invoices}
          invoiceId={invoiceId}
          dealTotals={dealTotals}
          invoiceLines={invoiceLinesForInvoice}
          jobLineItems={jobLineItems}
          region={documentSenderContext.region}
        />
      </InvoiceBody>
    </ClientDocument>
  );
};

export default InvoiceDocument;
