import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import meKeys from '@/api/queries/me/keyFactory';
import { graphql } from '@/gql';
import { UsersInput } from '@/gql/graphql';

const GetMyAccountUsersQuery = graphql(`
  query MyAccountUsers($input: UsersInput) {
    me {
      user {
        account {
          users(input: $input) {
            id
            fullName
            systemRole
            deactivatedAt
            colour
            avatarUrl
            email
            firstName
            lastName
            status
            isPrimaryUser
            sortOrder
            costPerHour {
              value
              currency {
                id
                code
                exponent
              }
            }
          }
        }
      }
    }
  }
`);

const useGetMyAccountUsers = (userInput?: UsersInput) => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountUsers(userInput),
    queryFn: () => {
      return gqlClient.request(GetMyAccountUsersQuery, {
        input: userInput,
      });
    },
  });

  return { data: data?.me.user.account.users, ...rest };
};

export default useGetMyAccountUsers;
