import { useEffect, useState } from 'react';

import {
  PublicHydratedEntityTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';

import {
  deleteTemplate,
  updateTemplate,
} from '@payaca/store/templates/templatesActions';
import { useDispatch } from 'react-redux';
import EntityTemplateReadDrawer from '../entityTemplateReadDrawer/EntityTemplateReadDrawer';
import EntityTemplateCard from './EntityTemplateCard';

type Props = {
  allowedQuickActions?: ('edit' | 'delete')[];
  template: PublicHydratedEntityTemplate<TemplatableEntity>;
  onDeleteEntityTemplateSuccess?: () => void;
  onUpdateEntityTemplateSuccess?: () => void;
  allowModifyTemplateNameDescription?: boolean;
  showEntityTypeIcon?: boolean;
};

const PersistedEntityTemplateCard = ({
  template: initialTemplate,
  allowedQuickActions = ['edit', 'delete'],
  onDeleteEntityTemplateSuccess,
  onUpdateEntityTemplateSuccess,
  allowModifyTemplateNameDescription,
  showEntityTypeIcon,
}: Props) => {
  const dispatch = useDispatch();
  const [showReadDrawer, setShowReadDrawer] = useState(false);

  const [template, setTemplate] = useState(initialTemplate);

  useEffect(() => {
    setTemplate(initialTemplate);
  }, [initialTemplate]);

  return (
    <>
      <EntityTemplateCard
        editInModal={template.entityType !== 'proposal'}
        showEntityTypeIcon={showEntityTypeIcon}
        template={template}
        allowedQuickActions={allowedQuickActions.filter((action) => {
          if (action === 'delete' && !!template.servicePlans?.length) {
            return false;
          }
          return true;
        })}
        onChange={(updatedTemplate) => {
          return new Promise<void>((resolve, reject) => {
            dispatch(
              updateTemplate.request({
                publicId: template.publicId,
                template: updatedTemplate,
                callback: (updatedTemplate) => {
                  setTemplate(updatedTemplate);
                  onUpdateEntityTemplateSuccess?.();
                  resolve();
                },
                onErrorCallback: (error) => {
                  reject();
                },
              })
            );
          });
        }}
        onRemove={() => {
          return new Promise<void>((resolve, reject) => {
            dispatch(
              deleteTemplate.request({
                publicId: template.publicId,
                callback: () => {
                  onDeleteEntityTemplateSuccess?.();
                  resolve();
                },
                onErrorCallback: (error) => {
                  reject();
                },
              })
            );
          });
        }}
        onClick={() => setShowReadDrawer(true)}
        allowModifyTemplateNameDescription={allowModifyTemplateNameDescription}
      />
      <EntityTemplateReadDrawer
        entityTemplate={template}
        isOpen={showReadDrawer}
        onClose={() => setShowReadDrawer(false)}
        onUpdateEntityTemplateSuccess={(updatedTemplate) => {
          setTemplate(updatedTemplate);
        }}
        onDeleteEntityTemplateSuccess={() => {
          setShowReadDrawer(false);
          onDeleteEntityTemplateSuccess?.();
        }}
        allowModifyTemplateNameDescription={allowModifyTemplateNameDescription}
      />
    </>
  );
};

export default PersistedEntityTemplateCard;
