import { useMutation, useQueryClient } from '@tanstack/react-query';

import connectionApplicationKeys from '@/api/queries/connectionApplication/keyFactory';
import { GetConnectionApplicationQuery } from '@/gql/graphql';
import { apiFetch } from '@/utils/apiFetch';
import { ConnectionApplication as _ConnectionApplication } from '@payaca/types/connectionApplication';

type ConnectionApplication = _ConnectionApplication & {
  createdAt: string;
  submittedAt: string;
};

type Variables = {
  connectionApplicationId: string;
  data: ConnectionApplication['data'];
};

const usePatchConnectionApplication = () => {
  const queryClient = useQueryClient();

  return useMutation<ConnectionApplication, unknown, Variables>({
    onSuccess: (data) => {
      queryClient.setQueryData<GetConnectionApplicationQuery>(
        connectionApplicationKeys.connectionApplication(data.id),
        { connectionApplication: data }
      );
    },
    mutationFn: async ({ connectionApplicationId, data }) => {
      const response = await apiFetch(
        `/provider/rest/ena/connection-application/${connectionApplicationId}`,
        {
          method: 'PATCH',
          body: JSON.stringify(data),
        }
      );

      return response.json();
    },
  });
};

export default usePatchConnectionApplication;
