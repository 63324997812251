import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { clearLocalStorage } from '../../helpers/localStorageKeyHelper';

const ClearCachePage = () => {
  const history = useHistory();
  useEffect(() => {
    clearLocalStorage();
    history.push('/');
  }, []);
  return null;
};

export default ClearCachePage;
