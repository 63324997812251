import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import Button from './Button';
import './Button.sass';
import { ButtonColourVariant, ButtonStyleVariant } from './enums';
// @ts-ignore
import { Iconly } from 'react-iconly';

type Props = {
  isDisabled?: boolean;
  onClick?: () => void;
  onClickDisabled?: () => void;
  isProcessing?: boolean;
  colourVariant?: ButtonColourVariant;
  hasBoxShadow?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  isOutlined?: boolean;
  label?: string;
  icon: IconDefinition | string | React.ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  styleVariant?: ButtonStyleVariant;
};

const IconButton: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const iconlySize = useCallback((size: Props['size']) => {
    if (size) {
      switch (size) {
        case 'xs':
        case 'sm':
          return 'small';
        case 'md':
          return 'medium';
        case 'lg':
          return 'large';
        case 'xl':
          return 'xlarge';
      }
    }
  }, []);

  const renderedIcon = useMemo(() => {
    if (typeof props.icon === 'string') {
      return (
        <Iconly
          // @ts-ignore
          className="react-iconly"
          name={props.icon}
          set="light"
          size={iconlySize(props.size)}
        />
      );
    } else if (React.isValidElement(props.icon)) {
      return props.icon;
    } else {
      //@ts-ignore
      return <FontAwesomeIcon icon={props.icon} size={props.size} />;
    }
  }, [iconlySize, props.icon]);

  /* eslint-disable react/no-children-prop */
  return (
    <div className="icon-button-container">
      <Button
        {...props}
        children={renderedIcon}
        className={`icon-button ${props.size ? `icon-${props.size}` : ''} ${
          props.className ? props.className : ''
        }`}
        styleVariant={props.styleVariant || ButtonStyleVariant.CIRCULAR}
      />
      {props.label && (
        <span className="text-pc-blue-darker font-normal">{props.label}</span>
      )}
    </div>
  );
};

export default IconButton;
