import { formatAnswer } from '@payaca/helpers/formHelper';
import moment from 'moment-timezone';
import { FC } from 'react';
import CompanyLogo from '../companyLogo/CompanyLogo';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import './FormTemplateElements.sass';
import { useCertificateNumber, useCommonFormAccountData } from './hooks';
import './JobReportFormTemplate.sass';

import GasSafeLogo from './gas-safe.png';

interface Props {
  data: any;
  account: any;
}

const JobReportFormTemplate: FC<Props> = ({ data, account }) => {
  const { brandColour } = useCommonFormAccountData(account);
  const certificateNumber = useCertificateNumber(data);

  if (!data || !account) return <></>;

  return (
    <div className={'form-template-output job-report-form'}>
      <div className={'form-template-header'}>
        <div className={'flex-container'}>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Job Report</h3>
        </div>
        <div className={'flex-container flex-center'}>
          <div className="header-details-container">
            <LabelValuePair label="Cert No" value={certificateNumber} />
          </div>
          {data.gas_safe_reg_number && (
            <img
              className="gas-safe-logo"
              src={GasSafeLogo}
              alt={'Gas Safe Logo'}
            />
          )}
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <OutlinedContentPanel
          className="company-details"
          title="Company Details"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label={'Engineer'} value={data.completed_by} />
          <LabelValuePair label={'Company'} value={account?.companyName} />
          <LabelValuePair
            label={'Address'}
            value={`${account?.address} ${account?.city} ${account?.postcode}`}
          />
          <LabelValuePair label={'Contact'} value={account?.contactNumber} />
          {data.gas_safe_reg_number && (
            <LabelValuePair
              label={'Gas Safe Reg'}
              value={data.gas_safe_reg_number}
            />
          )}
          {data.id_card_number && (
            <LabelValuePair
              label={'ID Card Number'}
              value={data.id_card_number}
            />
          )}
          <LabelValuePair label={'E-mail'} value={account?.email} />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          className="client-details"
          title="Client Details"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label={'Name'} value={data.name} />
          <LabelValuePair
            label={'Address'}
            value={`${data.building}, ${data.street}, ${data.city}, ${
              data.region || ''
            }`}
          />
          <LabelValuePair label={'Postcode'} value={data.postcode} />
          <LabelValuePair label={'E-mail'} value={data.email} />
          <LabelValuePair label={'Contact'} value={data.contact} />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          className="job-details"
          title="Job Details"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label={'Reference'} value={data.reference} />
          <LabelValuePair
            label={'Date'}
            value={moment(data.completed_date).format('DD/MM/YYYY')}
          />
          <LabelValuePair
            label="Arrival Time"
            value={moment(data.arrival_time).format('HH:mm')}
          />
          <LabelValuePair
            label="Departure Time"
            value={moment(data.departure_time).format('HH:mm')}
          />
          <LabelValuePair
            label="Return visit required?"
            value={formatAnswer(data.return_required)}
          />
          <LabelValuePair
            label="Additional parts required"
            value={formatAnswer(data.parts_required)}
          />
        </OutlinedContentPanel>
      </div>
      <div className={'form-template-row no-page-break'}>
        <OutlinedContentPanel
          className="works-carried-out"
          title="Works carried out"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div>{data.works_carried_out}</div>
        </OutlinedContentPanel>
      </div>
      <div className={'form-template-row'}>
        <OutlinedContentPanel
          className="parts-required"
          title="Parts required"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="flex-container">
            <table className="parts-table">
              <thead style={{ borderColor: brandColour }}>
                <tr>
                  <td>Description</td>
                  <td>Supplier</td>
                  <td>Number</td>
                </tr>
              </thead>
              <tbody>
                {data?.item_groups?.map((part: any, i: number) => (
                  <tr key={i}>
                    <td>{part.description}</td>
                    <td>{part.supplier}</td>
                    <td>{part.number}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Completed by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.completed_by} />
          <LabelValuePair
            label="Signed"
            value={
              <div className={'form-template-signature'}>
                {data.engineer_signature && (
                  <img src={data.engineer_signature} />
                )}
              </div>
            }
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Received by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
          className="customer-signature"
        >
          <div>
            <LabelValuePair label="Name" value={data.name} />
            <LabelValuePair
              label="Signed"
              value={
                <div className={'form-template-signature'}>
                  {data.customer_signature && (
                    <img src={data.customer_signature} />
                  )}
                </div>
              }
            />
          </div>
          {data.customer_not_present && <p>Customer not present</p>}
        </OutlinedContentPanel>
      </div>
    </div>
  );
};

export default JobReportFormTemplate;
