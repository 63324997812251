import { Float } from '@headlessui-float/react';
import { Menu, MenuButtonProps } from '@headlessui/react';
import UntitledIcon from '@payaca/untitled-icons';
import { FC } from 'react';

import Button, { IButtonProps } from '../plButton/Button';
import InputGroup from '../plInputGroup/InputGroup';
import DropdownCore, { TDropdownItem } from './DropdownCore';

export interface IProps extends IButtonProps {
  menuItems: TDropdownItem[];
  as?: MenuButtonProps<typeof Button | typeof InputGroup.Button>['as'];
}

const ContextMenu: FC<IProps> = (props) => {
  const { menuItems, as = Button, ...buttonProps } = props;

  return (
    <Menu>
      <Float placement="bottom-end" portal>
        <Menu.Button as={as} {...buttonProps}>
          <UntitledIcon name="dots-vertical.3" className="h-5 w-5" />
        </Menu.Button>

        <DropdownCore items={menuItems} />
      </Float>
    </Menu>
  );
};

export default ContextMenu;
