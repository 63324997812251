import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UpdateAddressMutation as _UpdateAddressMutation,
  UpdateAddressInput,
} from '@/gql/graphql';

const UpdateAddressMutation = graphql(`
  mutation UpdateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      id
    }
  }
`);

const useUpdateAddress = (
  options?: UseMutationOptions<
    _UpdateAddressMutation,
    unknown,
    UpdateAddressInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UpdateAddressMutation, {
        input,
      });
    },
    ...options,
  });
};

export default useUpdateAddress;
