import UpdateCustomerControl, {
  IProps as IUpdateCustomerControlProps,
} from '@/ui/components/updateCustomerControl/UpdateCustomerControl';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import { FC } from 'react';

export interface IProps
  extends IModalProps,
    Omit<IUpdateCustomerControlProps, 'isInModal' | 'onCancel'> {}

const UpdateCustomerModal: FC<IProps> = (props) => {
  const { onClose, customerName, onSubmit, ...rest } = props;

  return (
    <Modal onClose={onClose} {...rest}>
      <UpdateCustomerControl
        customerName={customerName}
        isInModal
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default UpdateCustomerModal;
