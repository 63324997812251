import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  DeleteCustomerMutation as _DeleteCustomerMutation,
  DeleteCustomerInput,
} from '@/gql/graphql';

const DeleteCustomerMutation = graphql(`
  mutation DeleteCustomer($input: DeleteCustomerInput!) {
    deleteCustomer(input: $input) {
      id
    }
  }
`);

const useDeleteCustomer = (
  options?: UseMutationOptions<
    _DeleteCustomerMutation,
    unknown,
    DeleteCustomerInput
  >
) => {
  return useMutation({
    mutationFn: (update: DeleteCustomerInput) => {
      return gqlClient.request(DeleteCustomerMutation, {
        input: update,
      });
    },
    ...options,
  });
};

export default useDeleteCustomer;
