import * as Sentry from '@sentry/react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { FC, useState } from 'react';

import Button from '../plButton/Button';
import { EBtnColour } from '../plButton/useButtonClassName';

import './AddPaymentMethodForm.sass';

type Props = {
  submitStripeAttempted: (confirmPayment: () => Promise<void>) => void;
  returnUrlParams?: URLSearchParams;
};
const AddPaymentMethodForm: FC<Props> = ({
  submitStripeAttempted,
  returnUrlParams,
}: Props): JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmittingPayment] = useState(false);
  const [formComplete, setFormComplete] = useState(false);

  const checkFormIsComplete = (event: any) => {
    if (event.complete) {
      setFormComplete(true);
    }
  };

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded - disable form submission until Stripe.js has loaded.
      return;
    }
    setSubmittingPayment(true);

    submitStripeAttempted(async () => {
      // confirm payment intent with Stripe
      const returnUrl = new URL(window.location.href);
      if (returnUrlParams) {
        returnUrlParams.forEach((value, key) => {
          returnUrl.searchParams.append(key, value);
        });
      }
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl.href, // no error, redirect and payment intent id returned in query
        },
      });

      setSubmittingPayment(false);
      if (result.error) {
        Sentry.captureException(result.error);
        // Throw error (e.g., insufficient funds)
        throw new Error(result.error.message);
      }
    });

    return false;
  };

  return (
    <div className="checkout">
      {/* Payment form */}
      <form>
        <PaymentElement onChange={(event) => checkFormIsComplete(event)} />
        <div className="mt-4">
          <Button
            onClick={handleSubmit}
            colour={EBtnColour.JobBrand}
            className="w-full"
            isProcessing={submitting}
            disabled={submitting || !formComplete}
          >
            Pay now
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddPaymentMethodForm;
