import { FunctionComponent } from 'react';
import './TrialPeriodExpiryHoldingPage.css';

import { TrialPeriodExpiryCallout } from '@/ui/components';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

const TrialPeriodExpiryHoldingPage: FunctionComponent = (): JSX.Element => {
  return (
    <AuthenticatedPageWrapper className="trial-period-expiry-holding-page">
      <div className="trial-period-expiry-callout-container">
        <TrialPeriodExpiryCallout />
      </div>
    </AuthenticatedPageWrapper>
  );
};

export default TrialPeriodExpiryHoldingPage;
