import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@payaca/components/plButton/Button';

import { actions as usersActions } from '@/api/users';
import { useAccount } from '@/utils/storeHooks';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import Card from '@payaca/components/plCard/Card';
import Select from '@payaca/components/plSelect/Select';
import { setPrimaryPipeline } from '@payaca/store/account/accountActions';
import { usePipelines } from '@payaca/store/hooks/appState';
import { requestGetAccountPipelines } from '@payaca/store/pipelines/pipelineActions';

type Props = {
  readOnly: boolean;
};

const CompanySettingsPrimaryPipeline: FC<Props> = ({ readOnly }) => {
  const dispatch = useDispatch();
  const pipelines = usePipelines();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const account = useAccount();
  const [primaryPipelineId, setPrimaryPipelineId] = useState<number>(
    account.primaryPipelineId || 0
  );

  useEffect(() => {
    dispatch(requestGetAccountPipelines());
  }, []);

  const options = useMemo(() => {
    const o = pipelines.map((x: any) => {
      return { label: x.title, value: x.id };
    });

    if (!pipelines.find((x) => x.id === 0)) {
      o.push({ label: 'Default', value: 0 });
    }

    return o;
  }, [pipelines]);

  const selectedOption = options.find((x) => x.value === primaryPipelineId);

  const onSubmit = () => {
    setIsSuccess(false);
    setIsError(false);
    setIsProcessing(true);
    dispatch(
      setPrimaryPipeline.request({
        pipelineId: primaryPipelineId || null,
        callback: () => {
          dispatch(usersActions.getProfile());
          setIsProcessing(false);
          setIsSuccess(true);
        },
        onErrorCallback: () => {
          setIsProcessing(false);
          setIsError(true);
        },
      })
    );
  };

  return (
    <Card>
      <Card.Body className="space-y-4">
        <h2>Primary Pipeline</h2>
        <div className="flex gap-4">
          <Select
            className="max-w-[250px]"
            options={options}
            value={primaryPipelineId}
            onChange={setPrimaryPipelineId}
            disabled={readOnly}
          />
          {!readOnly && (
            <Button
              onClick={isProcessing ? undefined : onSubmit}
              isProcessing={isProcessing}
              disabled={primaryPipelineId == account.primaryPipelineId}
            >
              Save
            </Button>
          )}
        </div>

        {primaryPipelineId != account.primaryPipelineId && (
          <>
            {!!primaryPipelineId && !account.primaryPipelineId && (
              <Alert colour={EAlertColour.SOFT_YELLOW}>
                <ul>
                  <li>
                    The Payaca <strong>Default</strong> pipeline will no longer
                    be visible for you
                  </li>
                  <li>
                    Any Projects currently in the <strong>Default</strong>{' '}
                    pipeline will be moved to{' '}
                    <strong>{selectedOption?.label}</strong>
                  </li>
                  <li>
                    Any automation conditions currently referencing the{' '}
                    <strong>Default</strong> pipeline will be changed to
                    reference <strong>{selectedOption?.label}</strong>. You may
                    need to revisit these automations to check that they are
                    still useful.
                  </li>
                </ul>
              </Alert>
            )}
            {account.primaryPipelineId && (
              <Alert colour={EAlertColour.SOFT_YELLOW}>
                <ul>
                  {!primaryPipelineId && (
                    <li>
                      The Payaca <strong>Default</strong> pipeline will be added
                      to your account.
                    </li>
                  )}
                  <li>
                    No Projects will move pipeline as a result of this change.
                  </li>
                </ul>
              </Alert>
            )}
          </>
        )}

        {isSuccess && (
          <Alert colour={EAlertColour.SOFT_TEAL}>Save successful!</Alert>
        )}
        {isError && (
          <Alert colour={EAlertColour.SOFT_RED}>
            Something went wrong updating the primary pipeline
          </Alert>
        )}
      </Card.Body>
    </Card>
  );
};

export default CompanySettingsPrimaryPipeline;
