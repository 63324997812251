import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import CreateEditLineItemGroupControl from '@/ui/components/createEditLineItemGroupControl/CreateEditLineItemGroupControl';

import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import './NewEditItemGroupPage.sass';

const NewEditItemGroupPage: FC = (): JSX.Element => {
  const params: any = useParams();

  const lineItemGroupId = useMemo(() => params.lineItemGroupId, [params]);

  return (
    <AuthenticatedPageWrapper
      className="edit-item-group-page"
      previousPageNavigationConfig={{ route: '/items/groups' }}
    >
      <CreateEditLineItemGroupControl lineItemGroupId={lineItemGroupId} />
    </AuthenticatedPageWrapper>
  );
};

export default NewEditItemGroupPage;
