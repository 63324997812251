import { FC, useMemo } from 'react';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import Modal from '@payaca/components/modal/Modal';
import TextareaField from '@payaca/components/textareaField/TextareaField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';

import {
  finalCircuitFieldLabels,
  finalCircuitValidators,
} from '@payaca/helpers/electrical-forms-helpers';

import { selectOptionsPresets } from '@payaca/constants/form-fields';

import {
  ElectricalFormVersion,
  FinalCircuit,
  currents,
  ratings,
  rcdBss,
  rcdTypes,
} from '@payaca/types/electrical-forms-types';

import './CreateEditFinalCircuitModal.sass';

const resultBasicFieldPlaceholder = 'N/A';
type CreateEditFinalCircuitModalProps = {
  circuit?: FinalCircuit;
  isOpen: boolean;
  onClose: () => void;
  onPersist: (db: FinalCircuit) => void;
  version: ElectricalFormVersion;
};

export const CreateEditFinalCircuitModal: FC<
  CreateEditFinalCircuitModalProps
> = ({ circuit, isOpen, onClose, onPersist, version }) => {
  const initialFormState = useMemo(() => {
    if (!circuit) {
      return { circuit: {} };
    }
    return circuit;
  }, [circuit]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Add circuit"
      className="create-edit-final-circuit-modal"
    >
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        fieldValidators={finalCircuitValidators}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => {
          const handleClickSave = () => {
            if (isValid) {
              onPersist(formState as FinalCircuit);
              onClose();
            }
          };
          return (
            <>
              <ValidatedFieldWrapper
                validationResult={validationState.number}
                isTouched={touchedState.number ?? false}
              >
                <BasicField
                  label={finalCircuitFieldLabels.number}
                  name="number"
                  value={formState.number}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  isRequired
                />
              </ValidatedFieldWrapper>
              <ValidatedFieldWrapper
                validationResult={validationState.description}
                isTouched={touchedState.description ?? false}
              >
                <BasicField
                  label={finalCircuitFieldLabels.description}
                  name="description"
                  value={formState.description}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  isRequired
                />
              </ValidatedFieldWrapper>
              <ValidatedFieldWrapper
                validationResult={validationState.wiringType}
                isTouched={touchedState.wiringType ?? false}
              >
                <DropdownField
                  label={finalCircuitFieldLabels.wiringType}
                  name="wiringType"
                  value={formState.wiringType}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  isRequired
                  options={[
                    { label: 'Please select...', value: undefined },
                    ...selectOptionsPresets['electrical:wiringType'],
                  ]}
                />
              </ValidatedFieldWrapper>
              <ValidatedFieldWrapper
                validationResult={validationState.wiringTypeOther}
                isTouched={touchedState.wiringTypeOther ?? false}
              >
                <BasicField
                  label={finalCircuitFieldLabels.wiringTypeOther}
                  name="wiringTypeOther"
                  value={formState.wiringTypeOther}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                />
              </ValidatedFieldWrapper>
              <ValidatedFieldWrapper
                validationResult={validationState.referenceMethod}
                isTouched={touchedState.referenceMethod ?? false}
              >
                <DropdownField
                  label={finalCircuitFieldLabels.referenceMethod}
                  name="referenceMethod"
                  value={formState.referenceMethod}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  isRequired
                  options={[
                    { label: 'Please select...', value: undefined },
                    ...selectOptionsPresets[
                      'electrical:wiringReferenceMethods'
                    ],
                  ]}
                />
              </ValidatedFieldWrapper>
              <ValidatedFieldWrapper
                validationResult={validationState.numPointsServed}
                isTouched={touchedState.numPointsServed ?? false}
              >
                <BasicField
                  type="number"
                  label={finalCircuitFieldLabels.numPointsServed}
                  name="numPointsServed"
                  value={formState.numPointsServed}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  isRequired
                />
              </ValidatedFieldWrapper>
              <section>
                <h3>Circuit conductor CSA</h3>
                <ValidatedFieldWrapper
                  validationResult={validationState.liveConductorCsa}
                  isTouched={touchedState.liveConductorCsa ?? false}
                >
                  <DropdownField
                    label={finalCircuitFieldLabels.liveConductorCsa}
                    name="liveConductorCsa"
                    value={formState.liveConductorCsa}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    options={[
                      { label: 'Please select...', value: undefined },
                      ...selectOptionsPresets['electrical:conductorCsa'],
                    ]}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={validationState.cpcConductorCsa}
                  isTouched={touchedState.cpcConductorCsa ?? false}
                >
                  <DropdownField
                    label={finalCircuitFieldLabels.cpcConductorCsa}
                    name="cpcConductorCsa"
                    value={formState.cpcConductorCsa}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    options={[
                      { label: 'Please select...', value: undefined },
                      ...selectOptionsPresets['electrical:conductorCsa'],
                    ]}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={validationState.maxDisconnectionTime}
                  isTouched={touchedState.maxDisconnectionTime ?? false}
                >
                  <DropdownField
                    label={finalCircuitFieldLabels.maxDisconnectionTime}
                    name="maxDisconnectionTime"
                    value={formState.maxDisconnectionTime}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    options={[
                      { label: 'Please select...', value: undefined },
                      ...selectOptionsPresets[
                        'electrical:maxDisconnectionTime'
                      ],
                    ]}
                  />
                </ValidatedFieldWrapper>
              </section>
              <section>
                <h3>Overcurrent protective devices</h3>
                <ValidatedFieldWrapper
                  validationResult={validationState.overcurrentProtectiveDevice}
                  isTouched={touchedState.overcurrentProtectiveDevice ?? false}
                >
                  <DropdownField
                    label={finalCircuitFieldLabels.overcurrentProtectiveDevice}
                    name="overcurrentProtectiveDevice"
                    value={formState.overcurrentProtectiveDevice}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    options={[
                      { label: 'Please select...', value: undefined },
                      ...selectOptionsPresets[
                        'electrical:overcurrentProtectiveDevice'
                      ],
                    ]}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={
                    validationState.overcurrentProtectiveDeviceType
                  }
                  isTouched={
                    touchedState.overcurrentProtectiveDeviceType ?? false
                  }
                >
                  <BasicField
                    label={
                      finalCircuitFieldLabels.overcurrentProtectiveDeviceType
                    }
                    name="overcurrentProtectiveDeviceType"
                    value={formState.overcurrentProtectiveDeviceType}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    placeholder={resultBasicFieldPlaceholder}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={validationState.currentRating}
                  isTouched={touchedState.currentRating ?? false}
                >
                  <DropdownField
                    label={finalCircuitFieldLabels.currentRating}
                    name="currentRating"
                    value={formState.currentRating}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    options={[
                      { label: 'Please select...', value: undefined },
                      ...selectOptionsPresets[
                        'electrical:supplierProtectiveDeviceRatedCurrent'
                      ],
                    ]}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={validationState.shortCircuitCapacity}
                  isTouched={touchedState.shortCircuitCapacity ?? false}
                >
                  <DropdownField
                    label={finalCircuitFieldLabels.shortCircuitCapacity}
                    name="shortCircuitCapacity"
                    value={formState.shortCircuitCapacity}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    options={[
                      { label: 'Please select...', value: undefined },
                      ...selectOptionsPresets[
                        'electrical:shortCircuitCapacity'
                      ],
                    ]}
                  />
                </ValidatedFieldWrapper>
                {version == '18' && (
                  <ValidatedFieldWrapper
                    validationResult={validationState.rcdOperatingCurrent}
                    isTouched={touchedState.rcdOperatingCurrent ?? false}
                  >
                    <DropdownField
                      label={finalCircuitFieldLabels.rcdOperatingCurrent}
                      name="rcdOperatingCurrent"
                      value={formState.rcdOperatingCurrent}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                      options={currents.map((x) => ({
                        label: x,
                        value: x,
                      }))}
                    />
                  </ValidatedFieldWrapper>
                )}
                <ValidatedFieldWrapper
                  validationResult={validationState.maxPermittedZs}
                  isTouched={touchedState.maxPermittedZs ?? false}
                >
                  <BasicField
                    type="number"
                    label={finalCircuitFieldLabels.maxPermittedZs}
                    name="maxPermittedZs"
                    value={formState.maxPermittedZs}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    placeholder={resultBasicFieldPlaceholder}
                  />
                </ValidatedFieldWrapper>
              </section>
              {version == '18.2' && (
                <section>
                  <h3>RCD</h3>
                  <ValidatedFieldWrapper
                    validationResult={validationState.rcdBs}
                    isTouched={touchedState.rcdBs ?? false}
                  >
                    <DropdownField
                      label={finalCircuitFieldLabels.rcdBs}
                      name="rcdBs"
                      value={formState.rcdBs}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                      options={rcdBss.map((x) => ({
                        label: x,
                        value: x,
                      }))}
                    />
                  </ValidatedFieldWrapper>
                  <ValidatedFieldWrapper
                    validationResult={validationState.rcdType}
                    isTouched={touchedState.rcdType ?? false}
                  >
                    <DropdownField
                      label={finalCircuitFieldLabels.rcdType}
                      name="rcdType"
                      value={formState.rcdType}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                      options={rcdTypes.map((x) => ({
                        label: x,
                        value: x,
                      }))}
                    />
                  </ValidatedFieldWrapper>
                  <ValidatedFieldWrapper
                    validationResult={validationState.rcdRating}
                    isTouched={touchedState.rcdRating ?? false}
                  >
                    <DropdownField
                      label={finalCircuitFieldLabels.rcdRating}
                      name="rcdRating"
                      value={formState.rcdRating}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                      options={ratings.map((x) => ({
                        label: x.toString(),
                        value: x.toString(),
                      }))}
                    />
                  </ValidatedFieldWrapper>
                  <ValidatedFieldWrapper
                    validationResult={validationState.rcdOperatingCurrent}
                    isTouched={touchedState.rcdOperatingCurrent ?? false}
                  >
                    <DropdownField
                      label={finalCircuitFieldLabels.rcdOperatingCurrent}
                      name="rcdOperatingCurrent"
                      value={formState.rcdOperatingCurrent}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                      options={currents.map((x) => ({
                        label: x,
                        value: x,
                      }))}
                    />
                  </ValidatedFieldWrapper>
                </section>
              )}
              <section>
                <h3>Circuit impedances (Ohms)</h3>
                <ValidatedFieldWrapper
                  validationResult={validationState.lineImpedance}
                  isTouched={touchedState.lineImpedance ?? false}
                >
                  <BasicField
                    type="number"
                    label={finalCircuitFieldLabels.lineImpedance}
                    name="lineImpedance"
                    value={formState.lineImpedance}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    placeholder={resultBasicFieldPlaceholder}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={validationState.neutralImpedance}
                  isTouched={touchedState.neutralImpedance ?? false}
                >
                  <BasicField
                    type="number"
                    label={finalCircuitFieldLabels.neutralImpedance}
                    name="neutralImpedance"
                    value={formState.neutralImpedance}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    placeholder={resultBasicFieldPlaceholder}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={validationState.cpcImpedance}
                  isTouched={touchedState.cpcImpedance ?? false}
                >
                  <BasicField
                    type="number"
                    label={finalCircuitFieldLabels.cpcImpedance}
                    name="cpcImpedance"
                    value={formState.cpcImpedance}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    placeholder={resultBasicFieldPlaceholder}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={validationState.r1PlusR2Impedance}
                  isTouched={touchedState.r1PlusR2Impedance ?? false}
                >
                  <BasicField
                    type="number"
                    label={finalCircuitFieldLabels.r1PlusR2Impedance}
                    name="r1PlusR2Impedance"
                    value={formState.r1PlusR2Impedance}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    placeholder={resultBasicFieldPlaceholder}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={validationState.r2Impedance}
                  isTouched={touchedState.r2Impedance ?? false}
                >
                  <BasicField
                    type="number"
                    label={finalCircuitFieldLabels.r2Impedance}
                    name="r2Impedance"
                    value={formState.r2Impedance}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    placeholder={resultBasicFieldPlaceholder}
                  />
                </ValidatedFieldWrapper>
              </section>
              <section>
                <h3>Insulation resistance</h3>
                <ValidatedFieldWrapper
                  validationResult={
                    validationState.liveLiveInsulationResistance
                  }
                  isTouched={touchedState.liveLiveInsulationResistance ?? false}
                >
                  <BasicField
                    type="number"
                    label={finalCircuitFieldLabels.liveLiveInsulationResistance}
                    name="liveLiveInsulationResistance"
                    value={formState.liveLiveInsulationResistance}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    placeholder={resultBasicFieldPlaceholder}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={
                    validationState.liveEarthInsulationResistance
                  }
                  isTouched={
                    touchedState.liveEarthInsulationResistance ?? false
                  }
                >
                  <BasicField
                    type="number"
                    label={
                      finalCircuitFieldLabels.liveEarthInsulationResistance
                    }
                    name="liveEarthInsulationResistance"
                    value={formState.liveEarthInsulationResistance}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    placeholder={resultBasicFieldPlaceholder}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={validationState.testVoltage}
                  isTouched={touchedState.testVoltage ?? false}
                >
                  <DropdownField
                    label={finalCircuitFieldLabels.testVoltage}
                    name="testVoltage"
                    value={formState.testVoltage}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    options={[
                      { label: 'Please select...', value: undefined },
                      ...selectOptionsPresets['electrical:testVoltageDc'],
                    ]}
                  />
                </ValidatedFieldWrapper>
              </section>
              <section>
                <h3>Polarity</h3>
                <ValidatedFieldWrapper
                  validationResult={validationState.polarity}
                  isTouched={touchedState.polarity ?? false}
                >
                  <DropdownField
                    label={finalCircuitFieldLabels.polarity}
                    name="polarity"
                    value={formState.polarity}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    options={[
                      { label: 'Please select...', value: undefined },
                      ...selectOptionsPresets['electrical:simpleCheckResult'],
                    ]}
                  />
                </ValidatedFieldWrapper>
              </section>
              <section>
                <h3>Earth fault loop impedance</h3>
                <ValidatedFieldWrapper
                  validationResult={
                    validationState.maxMeasuredEarthFaultLoopImpedance
                  }
                  isTouched={
                    touchedState.maxMeasuredEarthFaultLoopImpedance ?? false
                  }
                >
                  <BasicField
                    type="number"
                    label={
                      finalCircuitFieldLabels.maxMeasuredEarthFaultLoopImpedance
                    }
                    name="maxMeasuredEarthFaultLoopImpedance"
                    value={formState.maxMeasuredEarthFaultLoopImpedance}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    placeholder={resultBasicFieldPlaceholder}
                  />
                </ValidatedFieldWrapper>
              </section>
              <section>
                <h3>RCD</h3>
                <ValidatedFieldWrapper
                  validationResult={validationState.rcdOperatingTime}
                  isTouched={touchedState.rcdOperatingTime ?? false}
                >
                  <BasicField
                    type="number"
                    label={finalCircuitFieldLabels.rcdOperatingTime}
                    name="rcdOperatingTime"
                    value={formState.rcdOperatingTime}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    placeholder={resultBasicFieldPlaceholder}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={validationState.rcdTestButtonOperation}
                  isTouched={touchedState.rcdTestButtonOperation ?? false}
                >
                  <DropdownField
                    label={finalCircuitFieldLabels.rcdTestButtonOperation}
                    name="rcdTestButtonOperation"
                    value={formState.rcdTestButtonOperation}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    options={[
                      { label: 'Please select...', value: undefined },
                      ...selectOptionsPresets['electrical:simpleCheckResult'],
                    ]}
                  />
                </ValidatedFieldWrapper>
                <ValidatedFieldWrapper
                  validationResult={validationState.afddTestButtonOperation}
                  isTouched={touchedState.afddTestButtonOperation ?? false}
                >
                  <DropdownField
                    label={finalCircuitFieldLabels.afddTestButtonOperation}
                    name="afddTestButtonOperation"
                    value={formState.afddTestButtonOperation}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    options={[
                      { label: 'Please select...', value: undefined },
                      ...selectOptionsPresets['electrical:simpleCheckResult'],
                    ]}
                  />
                </ValidatedFieldWrapper>
              </section>
              {version == '18' && (
                <ValidatedFieldWrapper
                  validationResult={validationState.vulnerableToDamage}
                  isTouched={touchedState.vulnerableToDamage ?? false}
                >
                  <CheckboxField
                    label={finalCircuitFieldLabels.vulnerableToDamage}
                    name="vulnerableToDamage"
                    value={formState.vulnerableToDamage}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                  />
                </ValidatedFieldWrapper>
              )}
              {version == '18.2' && (
                <ValidatedFieldWrapper
                  validationResult={validationState.comments}
                  isTouched={touchedState.comments ?? false}
                >
                  <TextareaField
                    label={finalCircuitFieldLabels.comments}
                    name="comments"
                    value={formState.comments}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                  />
                </ValidatedFieldWrapper>
              )}
              <div className="actions-container">
                <Button
                  isDisabled={!isValid}
                  onClick={handleClickSave}
                  styleVariant={ButtonStyleVariant.OUTSIZE}
                >
                  Save
                </Button>
              </div>
            </>
          );
        }}
      />
    </Modal>
  );
};
