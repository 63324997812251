import { FC, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';

import useGetUploads from '@/api/queries/useGetUploads';

import { File as _File } from '@/gql/graphql';
import ProjectFileCard from '@/ui/pages/connectionApplicationPage/components/ProjectFileCard';
import AddCardButton from '@payaca/components/addCard/AddCardButton';
import Button from '@payaca/components/plButton/Button';
import Field from '@payaca/components/plField/Field';
import ImageGroupFieldInput from '@payaca/components/plImageGroupField/ImageGroupField';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import { Link, useParams } from 'react-router-dom';
import { fieldValidationState, isMultipleFileSelection } from './utils';

type File = Pick<_File, 'id'> & Partial<Omit<_File, 'id'>>;

export interface IProps {
  name: string;
  formSection: any;
}

const ProjectFileSelection: FC<IProps> = ({ name, formSection }) => {
  /**
   * React Form Hook
   */
  const form = useFormContext();

  /**
   * Router
   */
  const { projectId } = useParams<{
    projectId: string;
    connectionApplicationId: string;
  }>();

  /**
   * Queries
   */
  const { data } = useGetUploads(
    {
      sourceEntities: [
        {
          type: 'deal',
          id: projectId,
        },
      ],
    },
    {
      offset: 0,
      limit: 100,
    }
  );

  /**
   * State
   */
  const [isLinkFilesModalOpen, setIsLinkFilesModalOpen] = useState(false);

  const multiple = isMultipleFileSelection(formSection);

  const fieldName = multiple
    ? `${name}.projectFileIDs`
    : `${name}.projectFileID`;
  const error = get(form.formState.errors, fieldName);

  return (
    <>
      <Field
        name={fieldName}
        validationState={error && fieldValidationState(error)}
      >
        <Controller
          control={form.control}
          name={fieldName}
          render={({ field }) => {
            const { onChange, value, disabled, ref, ...rest } = field;

            // https://react-hook-form.com/faqs#Howtosharerefusage
            const htmlRef = useRef<HTMLDivElement>(null);
            useImperativeHandle(ref, () => ({
              focus: () => {
                htmlRef.current?.scrollIntoView();
              },
            }));

            return (
              <>
                <ProjectFileCard.Wrapper ref={htmlRef}>
                  {data?.uploads?.items
                    .filter((file) => value?.includes(file.id))
                    .map((file) => (
                      <ProjectFileCard
                        key={file.id}
                        thumbnailUrl={file.thumbnailUrl || ''}
                        fileName={file.fileName}
                        onRemove={() =>
                          onChange(
                            multiple
                              ? (value as string[]).filter(
                                  (id) => id !== file.id
                                )
                              : ''
                          )
                        }
                      />
                    ))}

                  <AddCardButton
                    disabled={disabled}
                    text={multiple ? 'Add files' : 'Add file'}
                    onClick={() => setIsLinkFilesModalOpen(true)}
                  />
                </ProjectFileCard.Wrapper>

                <ImageSelectModal
                  title={multiple ? 'Add files' : 'Add file'}
                  multiple={multiple}
                  isOpen={isLinkFilesModalOpen}
                  files={data?.uploads?.items || []}
                  onClose={() => setIsLinkFilesModalOpen(false)}
                  initialSelectedFiles={multiple ? value : [value]}
                  onSubmit={(fileIds) => {
                    onChange(multiple ? fileIds : fileIds[0]);

                    setIsLinkFilesModalOpen(false);
                  }}
                  projectId={projectId}
                />
              </>
            );
          }}
        />
      </Field>
    </>
  );
};

const ImageSelectModal: FC<
  Omit<IModalProps, 'title'> & {
    title?: string;
    files: File[];
    multiple?: boolean;
    onSubmit: (selectedFileIds: string[]) => void;
    initialSelectedFiles?: string[];
    projectId: string;
  }
> = (props) => {
  const {
    title = 'Select files',
    isOpen,
    onClose,
    files,
    multiple,
    onSubmit,
    initialSelectedFiles = [],
    projectId,
  } = props;

  const [selectedFileIds, setSelectedFileIds] = useState<
    string[] | undefined | null
  >(initialSelectedFiles);

  useEffect(() => {
    if (isOpen) {
      setSelectedFileIds(initialSelectedFiles);
    }
  }, [isOpen]);

  return (
    <Modal
      size={files.length ? 'lg' : 'sm'}
      title={title}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Body>
        {files.length ? (
          <ImageGroupFieldInput.Group>
            {files.map((file) => {
              return (
                <ImageGroupFieldInput
                  key={file.id}
                  label={file.fileName}
                  src={file.thumbnailUrl || ''}
                  multiple={multiple}
                  checked={selectedFileIds?.includes(file.id)}
                  onChange={(event) => {
                    if (event.currentTarget.checked) {
                      setSelectedFileIds((prevState) =>
                        multiple
                          ? [...new Set([...(prevState || []), file.id])]
                          : [file.id]
                      );
                    } else {
                      setSelectedFileIds((prevState) =>
                        (prevState || []).filter((id) => id !== file.id)
                      );
                    }
                  }}
                />
              );
            })}
          </ImageGroupFieldInput.Group>
        ) : (
          <p>
            No images available to select from yet. Head to the{' '}
            <Link
              to={`/deals/${projectId}/files`}
              onClick={(e) => e.stopPropagation()}
            >
              files
            </Link>{' '}
            tab on this Project to upload them.
          </p>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Actions>
          {files.length ? (
            <Button onClick={() => onSubmit(selectedFileIds || [])}>
              {title}
            </Button>
          ) : (
            <Button onClick={onClose}>Ok</Button>
          )}
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectFileSelection;
