import { Req } from '@payaca/helpers/storeHelper';
import {
  ListedServicePlan,
  PublicHydratedServicePlan,
  PublicHydratedServicePlanCommitment,
  PublicHydratedServicePlanDiscountCode,
  PublicHydratedServicePlanPeriod,
  PublicServicePlanPrice,
  ServicePlansAnalyticsData,
} from '@payaca/types/service-plans';
import { put, takeEvery } from 'redux-saga/effects';

import { handleAsyncAction } from '../utils';
import {
  createDiscountCode,
  createServicePlan,
  createServicePlanCommitment,
  createServicePlanPrice,
  createServicePlansCustomerReminder,
  deleteServicePlan,
  deleteServicePlanCommitment,
  deleteServicePlanDiscountCode,
  deleteServicePlanPeriodCommitment,
  deleteServicePlanPrice,
  deleteServicePlansCustomerReminder,
  getListedDiscountCodes,
  getListedServicePlans,
  getServicePeriods,
  getServicePlan,
  getServicePlanDiscountCodes,
  getServicePlanPeriod,
  getServicePlansAnalytics,
  getServicePlansCustomerReminder,
  getServicePlansCustomerReminders,
  getServicePlanSubscription,
  getSubscriptionsForCustomer,
  getSubscriptionsForServicePlan,
  inviteCustomerToServicePlan,
  toggleServicePlansCustomerReminder,
  updateServicePlan,
  updateServicePlanCommitment,
  updateServicePlanPeriod,
  updateServicePlanPeriodCommitment,
  updateServicePlanPrice,
  updateServicePlansCustomerReminder,
} from './servicePlansActions';

import { PayloadAction } from 'typesafe-actions';
import { getUploadsForEntitySuccess } from '../uploads/uploadsActions';
import { ActionType, SagaConfig } from './servicePlansTypes';

const tagsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  const handleGetListedDiscountCodes = handleAsyncAction(
    getListedDiscountCodes,
    async () => {
      try {
        const response = await req.get(`/service-plans/discount-codes`);
        return await response.json();
      } catch (err) {
        console.log(
          `Get listed service plans discount codes failed: ${JSON.stringify(
            err
          )}`
        );
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as PublicHydratedServicePlanDiscountCode[]
      );
    }
  );
  const handleGetListedServicePlans = handleAsyncAction(
    getListedServicePlans,
    async () => {
      try {
        const response = await req.get(`/service-plans/listed-service-plans`);
        return (await response.json()).items;
      } catch (err) {
        console.log(`Get listed service plans failed: ${JSON.stringify(err)}`);
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as ListedServicePlan[]);
    }
  );
  const handleGetServicePeriods = handleAsyncAction(
    getServicePeriods,
    async (payload) => {
      try {
        const urlSearchParams = new URLSearchParams();
        if (payload.servicePlanPublicIds?.length) {
          urlSearchParams.set(
            'servicePlanPublicIds',
            payload.servicePlanPublicIds.join(',')
          );
        }
        if (payload.when?.length) {
          urlSearchParams.set('when', payload.when.join(','));
        }
        if (payload.customerId) {
          urlSearchParams.set('customerId', String(payload.customerId));
        }
        if (payload.statuses?.length) {
          urlSearchParams.set('statuses', payload.statuses.join(','));
        }
        const response = await req.get(
          `/service-plans/listed-service-plan-periods?${urlSearchParams.toString()}`
        );
        return (await response.json()).items;
      } catch (err) {
        console.log(`Get service periods failed: ${JSON.stringify(err)}`);
      }
    }
  );

  const handleGetServicePlansAnalytics = handleAsyncAction(
    getServicePlansAnalytics,
    async () => {
      try {
        // TODO: endpoint not checked
        const response = await req.get(`/service-plans/analytics`);
        return await response.json();
      } catch (err) {
        console.log(
          `Get service plans analytics failed: ${JSON.stringify(err)}`
        );
      }
    },
    async (response, requestData) => {
      requestData.payload.callback?.(response as ServicePlansAnalyticsData);
    }
  );

  const handleGetServicePlan = handleAsyncAction(
    getServicePlan,
    async (payload) => {
      try {
        const response = await req.get(`/service-plans/${payload.publicId}`);
        return await response.json();
      } catch (err) {
        console.log(`Get service plan failed: ${JSON.stringify(err)}`);
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    }
  );

  const handleGetServicePlanDiscountCodes = handleAsyncAction(
    getServicePlanDiscountCodes,
    async (payload) => {
      try {
        const response = await req.get(
          `/service-plans/${payload.publicId}/discount-codes`
        );
        return await response.json();
      } catch (err) {
        console.log(
          `Get service plan discount codes failed: ${JSON.stringify(err)}`
        );

        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response);
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleGetServicePlanPeriod = handleAsyncAction(
    getServicePlanPeriod,
    async (payload) => {
      try {
        const response = await req.get(
          `/service-plans/periods/${payload.publicId}`
        );
        return await response.json();
      } catch (err) {
        console.log(`Get service plan period failed: ${JSON.stringify(err)}`);
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as PublicHydratedServicePlanPeriod
      );
    }
  );

  const handleCreateServicePlan = handleAsyncAction(
    createServicePlan,
    async (payload) => {
      try {
        const response = await req.post(
          `/service-plans`,
          payload.createServicePlanRequestData
        );
        return await response.json();
      } catch (err) {
        console.log(`Create service plan failed: ${JSON.stringify(err)}`);
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleUpdateServicePlan = handleAsyncAction(
    updateServicePlan,
    async (payload) => {
      try {
        const response = await req.patch(
          `/service-plans/${payload.servicePlanPublicId}`,
          payload.updateServicePlanRequestData
        );
        return;
        // return await response.json();
      } catch (err) {
        console.log(`Update service plan failed: ${JSON.stringify(err)}`);
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleDeleteServicePlan = handleAsyncAction(
    deleteServicePlan,
    async (payload) => {
      try {
        await req.del(`/service-plans/${payload.servicePlanPublicId}`);
      } catch (err) {
        console.log(`Delete service plan failed: ${JSON.stringify(err)}`);
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleDeleteDiscountCode = handleAsyncAction(
    deleteServicePlanDiscountCode,
    async (payload) => {
      try {
        await req.del(
          `/service-plans/discount-codes/${payload.servicePlanDiscountCodePublicId}`
        );
      } catch (err) {
        console.log(`Delete service plan failed: ${JSON.stringify(err)}`);
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleCreateDiscountCode = handleAsyncAction(
    createDiscountCode,
    async (payload) => {
      try {
        const response = await req.post(
          `/service-plans/discount-codes`,
          payload.createDiscountCodeRequestData
        );
        return await response.json();
      } catch (err) {
        console.log(
          `Create service plan discount code failed: ${JSON.stringify(err)}`
        );
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleUpdateServicePlanCommitment = handleAsyncAction(
    updateServicePlanCommitment,
    async (payload) => {
      try {
        const response = await req.put(
          `/service-plans/commitments/${payload.publicId}`,
          payload.servicePlanCommitment
        );
        return await response.json();
      } catch (err) {
        console.log(
          `Update service plan commitment failed: ${JSON.stringify(err)}`
        );
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as PublicHydratedServicePlanCommitment
      );
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleCreateServicePlanCommitment = handleAsyncAction(
    createServicePlanCommitment,
    async (payload) => {
      try {
        const response = await req.post(
          `/service-plans/${payload.servicePlanPublicId}/commitments`,
          payload.servicePlanCommitment
        );
        return await response.json();
      } catch (err) {
        console.log(`Create service plan failed: ${JSON.stringify(err)}`);
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as PublicHydratedServicePlanCommitment
      );
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleDeleteServicePlanCommitment = handleAsyncAction(
    deleteServicePlanCommitment,
    async (payload) => {
      try {
        const response = await req.del(
          `/service-plans/commitments/${payload.servicePlanCommitmentId}`
        );
        return;
      } catch (err) {
        console.log(
          `Delete service plan commitment failed: ${JSON.stringify(err)}`
        );
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleGetSubscriptionsForServicePlan = handleAsyncAction(
    getSubscriptionsForServicePlan,
    async (payload) => {
      try {
        const response = await req.get(
          `/service-plans/${payload.servicePlanId}/subscriptions`
        );
        return await response.json();
      } catch (err) {
        console.log(
          `Get subscriptions for service plan failed: ${JSON.stringify(err)}`
        );
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleGetSubscriptionsForCustomer = handleAsyncAction(
    getSubscriptionsForCustomer,
    async (payload) => {
      try {
        const response = await req.get(
          `/customer/${payload.customerId}/service-plan-subscriptions`
        );
        return await response.json();
      } catch (err) {
        console.log(
          `Get subscriptions for customer failed: ${JSON.stringify(err)}`
        );
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response);
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleGetServicePlanSubscription = handleAsyncAction(
    getServicePlanSubscription,
    async (payload) => {
      try {
        const response = await req.get(
          `/service-plans/subscriptions/${payload.publicId}`
        );
        return await response.json();
      } catch (err) {
        console.log(
          `Get service plan subscription by ID failed: ${JSON.stringify(err)}`
        );
        throw err;
      }
    }
  );

  const handleGetServicePlansCustomerReminder = handleAsyncAction(
    getServicePlansCustomerReminder,
    async (payload) => {
      const response = await req.get(
        `/service-plans/customer-reminders/${payload.publicId}`
      );
      return await response.json();
    },
    (x, requestData) => {
      requestData.payload.onSuccess?.(x);
    },
    (err, requestData) => {
      requestData.payload.onError?.(err);
    }
  );

  const handleGetServicePlansCustomerReminders = handleAsyncAction(
    getServicePlansCustomerReminders,
    async (payload) => {
      const response = await req.get(`/service-plans/customer-reminders`);
      return await response.json();
    },
    (x, requestData) => {
      requestData.payload.onSuccess?.(x);
    },
    (err, requestData) => {
      requestData.payload.onError?.(err);
    }
  );

  const handleToggleServicePlansCustomerReminders = handleAsyncAction(
    toggleServicePlansCustomerReminder,
    async (payload) => {
      const response = await req.patch(
        `/service-plans/customer-reminders/${payload.publicId}/toggle`,
        payload.body
      );
      return await response.json();
    },
    (x, requestData) => {
      requestData.payload.onSuccess?.(x);
    },
    (err, requestData) => {
      requestData.payload.onError?.(err);
    }
  );

  const handleCreateServicePlansCustomerReminder = handleAsyncAction(
    createServicePlansCustomerReminder,
    async (payload) => {
      const response = await req.post(
        `/service-plans/customer-reminders`,
        payload.body
      );
      return await response.json();
    },
    (x, requestData) => {
      requestData.payload.onSuccess?.(x);
    },
    (err, requestData) => {
      requestData.payload.onError?.(err);
    }
  );

  const handleUpdateServicePlansCustomerReminder = handleAsyncAction(
    updateServicePlansCustomerReminder,
    async (payload) => {
      const response = await req.put(
        `/service-plans/customer-reminders/${payload.publicId}`,
        payload.body
      );
      return await response.json();
    },
    (x, requestData) => {
      requestData.payload.onSuccess(x as any);
    },
    (err, requestData) => {
      requestData.payload.onError?.(err);
    }
  );

  const handleDeleteServicePlansCustomerReminder = handleAsyncAction(
    deleteServicePlansCustomerReminder,
    async (payload) => {
      await req.del(`/service-plans/customer-reminders/${payload.publicId}`);
    },
    (x, requestData) => {
      requestData.payload.onSuccess();
    },
    (err, requestData) => {
      requestData.payload.onError?.(err);
    }
  );

  const handleUpdateServicePlanPrice = handleAsyncAction(
    updateServicePlanPrice,
    async (payload) => {
      try {
        const { publicId, ...servicePlanPrice } = payload.servicePlanPrice;
        const response = await req.patch(
          `/service-plans/prices/${payload.servicePlanPrice.publicId}`,
          servicePlanPrice
        );
        return await response.json();
      } catch (err) {
        console.log(`Update service plan price failed: ${JSON.stringify(err)}`);
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as PublicServicePlanPrice);
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleDeleteServicePlanPrice = handleAsyncAction(
    deleteServicePlanPrice,
    async (payload) => {
      try {
        await req.del(
          `/service-plans/prices/${payload.servicePlanPricePublicId}`
        );
      } catch (err) {
        console.log(`Delete service plan price failed: ${JSON.stringify(err)}`);
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleUpdateServicePlanPeriodCommitment = handleAsyncAction(
    updateServicePlanPeriodCommitment,
    async (payload) => {
      try {
        const { publicId, ...servicePlanPeriodCommitment } =
          payload.servicePlanPeriodCommitment;
        const response = await req.patch(
          `/service-plans/period-commitments/${publicId}`,
          servicePlanPeriodCommitment
        );
        return;
      } catch (err) {
        console.log(
          `Update service plan period commitment failed: ${JSON.stringify(err)}`
        );
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleUpdateServicePlanPeriod = handleAsyncAction(
    updateServicePlanPeriod,
    async (payload) => {
      try {
        const { publicId, ...servicePlanPeriod } = payload.servicePlanPeriod;
        const response = await req.patch(
          `/service-plans/periods/${publicId}`,
          servicePlanPeriod
        );
        return;
      } catch (err) {
        console.log(
          `Update service plan period failed: ${JSON.stringify(err)}`
        );
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleDeleteServicePlanPeriodCommitment = handleAsyncAction(
    deleteServicePlanPeriodCommitment,
    async (payload) => {
      try {
        const response = await req.del(
          `/service-plans/period-commitments/${payload.servicePlanPeriodCommitmentId}`
        );
        return;
      } catch (err) {
        console.log(
          `Delete service plan period commitment failed: ${JSON.stringify(err)}`
        );
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleCreateServicePlanPrice = handleAsyncAction(
    createServicePlanPrice,
    async (payload) => {
      try {
        const { servicePlanPublicId, ...servicePlanPrice } =
          payload.servicePlanPrice;
        const response = await req.post(
          `/service-plans/${servicePlanPublicId}/prices`,
          servicePlanPrice
        );
        return await response.json();
      } catch (err) {
        console.log(`Create service plan price failed: ${JSON.stringify(err)}`);
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as PublicServicePlanPrice);
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleInviteCustomerToServicePlan = handleAsyncAction(
    inviteCustomerToServicePlan,
    async (payload) => {
      try {
        const { servicePlanPublicId, ...body } =
          payload.inviteCustomerToServicePlanRequestData;
        const response = await req.post(
          `/service-plans/${servicePlanPublicId}/invitations`,
          body
        );
        return;
      } catch (err) {
        console.log(
          `Invite customer to service plan failed: ${JSON.stringify(err)}`
        );
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  function* handleGetListedServicePlansSuccess({
    payload,
  }: PayloadAction<
    ActionType.GET_LISTED_SERVICE_PLANS_SUCCESS,
    PublicHydratedServicePlan[]
  >) {
    const uploads = payload.flatMap((sp) =>
      sp.commitments.flatMap((c) => c.entityTemplate.uploads)
    );
    yield put(getUploadsForEntitySuccess(uploads));
  }

  function* handleGetServicePlanSuccess({
    payload,
  }: PayloadAction<
    ActionType.GET_SERVICE_PLAN_SUCCESS,
    PublicHydratedServicePlan
  >) {
    const uploads = payload.commitments.flatMap(
      (c) => c.entityTemplate.uploads
    );
    yield put(getUploadsForEntitySuccess(uploads));
  }

  return function* () {
    yield takeEvery(
      ActionType.GET_LISTED_DISCOUNT_CODES_REQUEST,
      handleGetListedDiscountCodes
    );
    yield takeEvery(
      ActionType.GET_LISTED_SERVICE_PLANS_REQUEST,
      handleGetListedServicePlans
    );
    yield takeEvery(
      ActionType.GET_LISTED_SERVICE_PLANS_SUCCESS,
      handleGetListedServicePlansSuccess
    );
    yield takeEvery(
      ActionType.GET_SERVICE_PLAN_SUCCESS,
      handleGetServicePlanSuccess
    );
    yield takeEvery(
      ActionType.GET_SERVICE_PERIODS_REQUEST,
      handleGetServicePeriods
    );
    yield takeEvery(
      ActionType.GET_SERVICE_PLAN_DISCOUNT_CODES_REQUEST,
      handleGetServicePlanDiscountCodes
    );
    yield takeEvery(
      ActionType.GET_SERVICE_PLANS_ANALYTICS_REQUEST,
      handleGetServicePlansAnalytics
    );
    yield takeEvery(ActionType.GET_SERVICE_PLAN_REQUEST, handleGetServicePlan);
    yield takeEvery(
      ActionType.CREATE_SERVICE_PLAN_REQUEST,
      handleCreateServicePlan
    );
    yield takeEvery(
      ActionType.UPDATE_SERVICE_PLAN_REQUEST,
      handleUpdateServicePlan
    );
    yield takeEvery(
      ActionType.DELETE_SERVICE_PLAN_REQUEST,
      handleDeleteServicePlan
    );
    yield takeEvery(
      ActionType.DELETE_SERVICE_PLAN_DISCOUNT_CODE_REQUEST,
      handleDeleteDiscountCode
    );
    yield takeEvery(
      ActionType.CREATE_DISCOUNT_CODE_REQUEST,
      handleCreateDiscountCode
    );
    yield takeEvery(
      ActionType.UPDATE_SERVICE_PLAN_COMMITMENT_REQUEST,
      handleUpdateServicePlanCommitment
    );
    yield takeEvery(
      ActionType.CREATE_SERVICE_PLAN_COMMITMENT_REQUEST,
      handleCreateServicePlanCommitment
    );
    yield takeEvery(
      ActionType.DELETE_SERVICE_PLAN_COMMITMENT_REQUEST,
      handleDeleteServicePlanCommitment
    );
    yield takeEvery(
      ActionType.GET_SUBSCRIPTIONS_FOR_SERVICE_PLAN_REQUEST,
      handleGetSubscriptionsForServicePlan
    );
    yield takeEvery(
      ActionType.GET_SUBSCRIPTIONS_FOR_CUSTOMER_REQUEST,
      handleGetSubscriptionsForCustomer
    );

    yield takeEvery(
      ActionType.UPDATE_SERVICE_PLAN_PRICE_REQUEST,
      handleUpdateServicePlanPrice
    );
    yield takeEvery(
      ActionType.DELETE_SERVICE_PLAN_PRICE_REQUEST,
      handleDeleteServicePlanPrice
    );
    yield takeEvery(
      ActionType.CREATE_SERVICE_PLAN_PRICE_REQUEST,
      handleCreateServicePlanPrice
    );
    yield takeEvery(
      ActionType.INVITE_CUSTOMER_TO_SERVICE_PLAN_REQUEST,
      handleInviteCustomerToServicePlan
    );
    yield takeEvery(
      ActionType.GET_SERVICE_PLAN_PERIOD_REQUEST,
      handleGetServicePlanPeriod
    );
    yield takeEvery(
      ActionType.UPDATE_SERVICE_PLAN_PERIOD_REQUEST,
      handleUpdateServicePlanPeriod
    );
    yield takeEvery(
      ActionType.UPDATE_SERVICE_PLAN_PERIOD_COMMITMENT_REQUEST,
      handleUpdateServicePlanPeriodCommitment
    );
    yield takeEvery(
      ActionType.DELETE_SERVICE_PLAN_PERIOD_COMMITMENT_REQUEST,
      handleDeleteServicePlanPeriodCommitment
    );
    yield takeEvery(
      ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_REQUEST,
      handleGetServicePlanSubscription
    );
    yield takeEvery(
      ActionType.GET_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST,
      handleGetServicePlansCustomerReminder
    );
    yield takeEvery(
      ActionType.GET_SERVICE_PLANS_CUSTOMER_REMINDERS_REQUEST,
      handleGetServicePlansCustomerReminders
    );
    yield takeEvery(
      ActionType.TOGGLE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST,
      handleToggleServicePlansCustomerReminders
    );
    yield takeEvery(
      ActionType.CREATE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST,
      handleCreateServicePlansCustomerReminder
    );

    yield takeEvery(
      ActionType.UPDATE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST,
      handleUpdateServicePlansCustomerReminder
    );
    yield takeEvery(
      ActionType.DELETE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST,
      handleDeleteServicePlansCustomerReminder
    );
  };
};

export default tagsSagaCreator;
