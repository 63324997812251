import * as appActionsV2 from './appActions';
import {
  actions as appActions,
  saga as appSagaCreator,
  selectors as appSelectors,
} from './appActionsAndSaga';
import appReducer from './appReducer';
import * as appTypes from './appTypes';

export const actions = { ...appActions, ...appActionsV2 };
export const types = appTypes;
export const sagaCreator = appSagaCreator;
export const reducer = appReducer;
export const selectors = appSelectors;
