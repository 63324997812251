import { type FC, useCallback, useEffect, useRef, useState } from 'react';

import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';

import Button from '../button/Button';
import PlButton from '../plButton/Button';
import UntitledLoading from '../plUntitledLoading/UntitledLoading';

import { Buffer } from 'buffer';
import Modal from '../plModal/Modal';

export type Props = {
  pdfUrl?: string;
  templatePdfBase64?: string;
  initialFormData?: Partial<Record<string, string | boolean>>;
  onFormFieldChanged?: (changedField: {
    name: string;
    value: string | boolean;
  }) => void;
  onFinalise?: () => void;
  readOnly?: boolean; // this is not a full read-only mode - it hides the finalise button (readOnly whilst disabling all the fields with initialFormData population lead to not all fields being populated)
  style?: React.CSSProperties;
};

const initialiseWebviewer = async (el: HTMLDivElement) => {
  const instance = await WebViewer(
    {
      fullAPI: true,
      path: '/webviewer',
      licenseKey: import.meta.env.VITE_APRYSE_LICENCE_KEY,
    },
    el
  );
  await instance.Core.PDFNet.initialize(
    import.meta.env.VITE_APRYSE_LICENCE_KEY
  );
  return instance;
};

export const AprysePdfViewer: FC<Props> = ({
  pdfUrl,
  templatePdfBase64,
  initialFormData,
  onFormFieldChanged,
  onFinalise,
  readOnly,
  style = { height: 'calc(100vh - 80px)' },
}) => {
  const webviewerInstancePromise = useRef<Promise<WebViewerInstance>>();
  const webviewerInstance = useRef<WebViewerInstance>();
  const [isLoading, setIsLoading] = useState(false);
  const [webviewerInstanceExists, setWebviewerInstanceExists] = useState(false);
  const [showRequiredFieldsAlert, setShowRequiredFieldsAlert] = useState(false);

  const configureWebviewer = useCallback(
    (instance: WebViewerInstance) => {
      // Set Apryse's active toolbar group to 'view'.
      // This is the mode to which we want to constrain the user.
      // It allows them to view the PDF and fill form fields, and nothing more.
      instance.UI.setToolbarGroup('toolbarGroup-View');

      // Disable (and hide) various Apryse controls:
      instance.UI.disableElements([
        'menuButton',
        'saveAsButton',

        // Hide Apryse's notes control.
        // We don't want to allow users to pepper PDF forms with arbitrary notes,
        // as we don't support saving/restoring them.
        'toggleNotesButton',

        // Hide all of Apryse's toolbar group controls
        // (we want the user to be constrained to 'view' mode).
        'toolbarGroup-View',
        'toolbarGroup-Annotate',
        'toolbarGroup-Shapes',
        'toolbarGroup-Insert',
        'toolbarGroup-Edit',
        'toolbarGroup-FillAndSign',
        'toolbarGroup-Forms',
      ]);

      // Disable (and hide) various other Apryse controls/features:
      instance.UI.disableFeatures([
        // Disable (and hide) Apryse's button to 'download' a PDF,
        // as we want to proxy this functionality via Payaca's feature set.
        instance.UI.Feature.Download,
        instance.UI.Feature.Copy,
      ]);

      // Disable Apryse's default contextual menu,
      // as it exposes functionality we want to hide from the user.
      instance.UI.contextMenuPopup.update([]);

      instance.UI.setHeaderItems((header) => {
        // Having disabled and hidden some of Apryse's default controls,
        // the first item in its toolbar is a separator. Remove it, as it looks daft:
        const dividerIdx = header
          .getItems()
          .findIndex((item: any) => item.type === 'divider');
        header.update(
          header
            .getItems()
            .filter((_item: any, idx: number) => idx !== dividerIdx)
        );

        if (!readOnly) {
          header.push({
            type: 'customElement',
            render: () => (
              <>
                <Button
                  onClick={async () => {
                    const instance = webviewerInstance.current;

                    const fieldManager =
                      await instance?.Core.annotationManager.getFieldManager();
                    const areRequiredFieldsFilled =
                      await fieldManager?.areRequiredFieldsFilled();

                    if (!areRequiredFieldsFilled) {
                      setShowRequiredFieldsAlert(true);
                    } else {
                      onFinalise?.();
                    }
                  }}
                >
                  Finalise
                </Button>
              </>
            ),
          });
        }
      });
    },
    [onFinalise, readOnly]
  );

  const onRenderWebviewerElement = useCallback(
    async (el: HTMLDivElement) => {
      try {
        if (!webviewerInstancePromise.current) {
          setIsLoading(true);
          const instancePromise = initialiseWebviewer(el);
          webviewerInstancePromise.current = instancePromise;
          const instance = await instancePromise;
          webviewerInstance.current = instance;
          setWebviewerInstanceExists(true);
          configureWebviewer(instance);
        }
      } catch (err) {
        console.error('Failed to initialise webviewer', { err });
        setIsLoading(false);
      }
    },
    [configureWebviewer]
  );

  useEffect(() => {
    const handleDocumentLoaded = async () => {
      setIsLoading(false);
    };

    if (webviewerInstance.current) {
      const instance = webviewerInstance.current;

      instance.Core.documentViewer.addEventListener(
        'documentLoaded',
        handleDocumentLoaded
      );
    }

    return () => {
      if (webviewerInstance.current) {
        const instance = webviewerInstance.current;
        instance.Core.documentViewer.removeEventListener(
          'documentLoaded',
          handleDocumentLoaded
        );
      }
    };
  }, [webviewerInstanceExists, initialFormData]);

  // Side effect to register a form field change listener on the Apryse webviewer.
  useEffect(() => {
    const handleFieldChanged = async (firstarg: any, value: string) => {
      const name = firstarg.name;
      const type = firstarg.type;
      const flags = firstarg.flags;
      const isRadio = flags?.get('Radio');

      let valueToSave: string | boolean = value;

      if (type === 'Btn' && !isRadio) {
        valueToSave = value !== 'Off';
      }

      onFormFieldChanged?.({ name, value: valueToSave });
    };

    const handleAnnotationChanged = (...args: Array<any>) => {
      if (webviewerInstance.current && args[2]?.imported === false) {
        (async () => {
          const instance = webviewerInstance.current;

          const opts = {
            fields: false,
            widgets: false,
            links: false,
          };

          const annotations =
            await instance?.Core.annotationManager.exportAnnotations(opts);
          const encodedAnnotations = Buffer.from(annotations || '').toString(
            'base64'
          );
          onFormFieldChanged?.({
            name: '__annotations',
            value: encodedAnnotations,
          });
        })();
      }
    };

    if (webviewerInstance.current && onFormFieldChanged) {
      const instance = webviewerInstance.current;
      instance.Core.annotationManager.addEventListener(
        'fieldChanged',
        handleFieldChanged
      );

      instance.Core.annotationManager.addEventListener(
        'annotationChanged',
        handleAnnotationChanged
      );
    }
    return () => {
      if (webviewerInstance.current) {
        const instance = webviewerInstance.current;
        instance.Core.annotationManager.removeEventListener(
          'fieldChanged',
          handleFieldChanged
        );

        instance.Core.annotationManager.removeEventListener(
          'annotationChanged',
          handleAnnotationChanged
        );
      }
    };
  }, [onFormFieldChanged, webviewerInstanceExists]);

  // Side effect to tear down the Apryse webviewer when this component unmounts.
  useEffect(() => {
    return () => {
      if (webviewerInstance.current) {
        const instance = webviewerInstance.current;
        instance.Core.PDFNet.deallocateAllObjects();
        instance.UI.dispose()
          .catch((err) => {
            console.warn(
              'Encountered an error disposing of Apryse webviewer instance',
              { err }
            );
          })
          .then(() => {
            webviewerInstance.current = undefined;
            setWebviewerInstanceExists(false);
          });
      }
    };
  }, []);

  useEffect(() => {
    if (webviewerInstancePromise.current) {
      (async () => {
        const instance = await webviewerInstancePromise.current!;
        if (pdfUrl) {
          instance.UI.loadDocument(pdfUrl);
        } else if (templatePdfBase64) {
          const chars = atob(templatePdfBase64);
          const bytes: Array<number> = [];
          for (let i = 0; i < chars.length; ++i) {
            bytes.push(chars.charCodeAt(i));
          }
          const u8Arr = new Uint8Array(bytes);
          const blob = new Blob([u8Arr], { type: 'application/pdf' });
          instance.Core.createDocument(blob)
            .then((doc) => {
              doc.getPDFDoc().then(async (pdfDoc) => {
                for (
                  const it = await pdfDoc.getFieldIteratorBegin();
                  await it.hasNext();
                  await it.next()
                ) {
                  const current = await it.current();
                  const name = await current.getName();
                  const fieldType = await current.getType();
                  const readOnlyFlag = await current.getFlag(
                    instance.Core.PDFNet.Field.Flag.e_read_only
                  );

                  if (fieldType === 5) {
                    // If the fieldType is 5, the field is an E-signature field.
                  } else {
                    if (readOnlyFlag) {
                      await current.setFlag(
                        instance.Core.PDFNet.Field.Flag.e_read_only,
                        false
                      );
                    }
                    const fieldValue = initialFormData?.[name];

                    if (fieldValue !== undefined) {
                      if (typeof fieldValue === 'boolean') {
                        await current.setValueAsBool(fieldValue);
                      } else {
                        await current.setValueAsString(
                          fieldValue?.toString() || ''
                        );
                      }
                    }
                  }
                }

                const annots = Buffer.from(
                  (initialFormData?.__annotations as string) || '',
                  'base64'
                ).toString();

                if (annots?.length) {
                  const fdfDocument =
                    await instance.Core.PDFNet.FDFDoc.createFromXFDF(annots);

                  await pdfDoc.fdfMerge(fdfDocument);
                }

                instance.UI.loadDocument(doc);
              });
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }
      })();
    }
  }, [pdfUrl, templatePdfBase64, initialFormData]);

  useEffect(() => {
    if (webviewerInstance.current) {
      const instance = webviewerInstance.current;
      if (readOnly) {
        instance.Core.annotationManager.enableReadOnlyMode();
      } else {
        instance.Core.annotationManager.disableReadOnlyMode();
      }
    }
  }, [readOnly]);

  return (
    <>
      {isLoading && (
        <UntitledLoading className="absolute left-1/2 top-1/2 h-20 w-20 -translate-x-1/2 -translate-y-1/2 transform" />
      )}
      <Modal
        isOpen={showRequiredFieldsAlert}
        onClose={() => setShowRequiredFieldsAlert(false)}
        title="Unable to finalise"
      >
        <Modal.Body>
          <p>Please fill in all required fields before finalising.</p>
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            <PlButton onClick={() => setShowRequiredFieldsAlert(false)}>
              Okay
            </PlButton>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </Modal>
      <div
        className={`webviewer ${isLoading ? 'invisible' : ''}`}
        ref={onRenderWebviewerElement}
        style={style}
      />
    </>
  );
};
