import { Account } from '@payaca/types/accountTypes';
import { BusinessHours, Days } from '@payaca/types/scheduleTypes';

export const defaultBusinessHourStart = '07:00';
export const defaultBusinessHourEnd = '18:00';

export const DaysOfWeekMap = [
  Days.SUNDAY,
  Days.MONDAY,
  Days.TUESDAY,
  Days.WEDNESDAY,
  Days.THURSDAY,
  Days.FRIDAY,
  Days.SATURDAY,
];
export const defaultBusinessHours: BusinessHours = {
  [Days.MONDAY]: {
    start: defaultBusinessHourStart,
    end: defaultBusinessHourEnd,
  },
  [Days.TUESDAY]: {
    start: defaultBusinessHourStart,
    end: defaultBusinessHourEnd,
  },
  [Days.WEDNESDAY]: {
    start: defaultBusinessHourStart,
    end: defaultBusinessHourEnd,
  },
  [Days.THURSDAY]: {
    start: defaultBusinessHourStart,
    end: defaultBusinessHourEnd,
  },
  [Days.FRIDAY]: {
    start: defaultBusinessHourStart,
    end: defaultBusinessHourEnd,
  },
};

/**
 * Get start and end times for accounts business hours
 * Assumption that business hours are the same for all days
 */
export const getBusinessHoursStartEndTimes = (
  businessHours: Account['businessHours']
): { businessHoursStart: string; businessHoursEnd: string } => {
  const allSetBusinessHours = Object.values(businessHours || {}).reduce(
    (acc: { startHours: string[]; endHours: string[] }, { start, end }) => {
      if (start) {
        acc.startHours.push(start);
      }
      if (end) {
        acc.endHours.push(end);
      }
      return acc;
    },
    { startHours: [], endHours: [] }
  );

  return {
    businessHoursStart:
      allSetBusinessHours.startHours[0] || defaultBusinessHourStart,
    businessHoursEnd: allSetBusinessHours.endHours[0] || defaultBusinessHourEnd,
  };
};

/**
 * Get days that have business hours set
 */
export const getBusinessHoursDays = (
  businessHours: Account['businessHours']
): Days[] => {
  const bh =
    businessHours && Object.keys(businessHours).length
      ? businessHours
      : defaultBusinessHours;

  return Object.keys(bh).filter(
    (day) => !!(bh && bh?.[day as Days]?.start && bh?.[day as Days]?.end)
  ) as Days[];
};

export const timeToHoursMins = (
  time: string
): { hours: number; minutes: number } => {
  const [hours, minutes] = time.split(':');
  return { hours: +hours, minutes: +minutes };
};
