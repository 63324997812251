import { FC } from 'react';

import UntitledIcon from '@payaca/untitled-icons';

type Props = {
  benefits: (string | JSX.Element)[];
};
const ConnectionControlBenefitList: FC<Props> = ({
  benefits,
}: Props): JSX.Element => {
  return (
    <ul
      className="list-none py-6 ps-0"
      style={{
        marginBlock: 0,
      }}
    >
      {benefits.map((benefit, i) => {
        return (
          <li key={i}>
            <UntitledIcon
              name="check"
              className="mr-2 h-5 w-5 align-middle text-green-600"
            />
            <span>{benefit}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default ConnectionControlBenefitList;
