import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { graphql } from '../../../gql';
import { PaginationInput } from '../../../gql/graphql';
import { gqlClient } from '../../graphql-client';
import notificationKeys from './keyFactory';

const FetchUnreadNotificationCount = graphql(`
  query FetchUnreadNotificationCount {
    me {
      user {
        id
      }
      unreadNotificationCount
    }
  }
`);
const FetchNotifications = graphql(`
  query FetchNotifications(
    $input: NotificationsInput!
    $pagination: PaginationInput!
  ) {
    me {
      user {
        id
      }
      unreadNotificationCount
      notifications(input: $input, pagination: $pagination) {
        limit
        offset
        totalCount
        items {
          id
          title
          body
          timestamp
          viewedAt
          notificationType
          metadata
          linkedEntity {
            __typename
            ... on Note {
              id
              event {
                id
                name
                beginAt
              }
              project {
                id
              }
            }
            ... on IntegratedEmail {
              id
              project {
                id
              }
            }
            ... on ScheduledEvent {
              id
              name
              beginAt
              project {
                id
              }
            }
            ... on ChecklistTask {
              id
              events {
                id
                name
                beginAt
              }
            }
            ... on FormTask {
              id
              events {
                id
                name
                beginAt
              }
            }
            ... on MaterialsListTask {
              id
              events {
                id
                name
                beginAt
              }
            }
            ... on ConnectionApplication {
              id
              project {
                id
              }
            }
          }
        }
      }
    }
  }
`);

const useFetchNotifications = (pagination?: PaginationInput) => {
  return useInfiniteQuery({
    queryKey: notificationKeys.notifications(pagination),
    queryFn: ({ pageParam }) => {
      return gqlClient.request(FetchNotifications, {
        input: {
          excludeDealNotes: false,
        },
        pagination: {
          offset:
            pageParam || pagination?.offset || PAGINATION_ARG_DEFAULTS.offset,
          limit: pagination?.offset || PAGINATION_ARG_DEFAULTS.limit,
        },
      });
    },
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.me.notifications) {
        return null;
      }

      const newOffset =
        lastPage.me.notifications.offset + lastPage.me.notifications.limit;

      if (lastPage.me.notifications.totalCount <= newOffset) {
        return null;
      }

      return newOffset;
    },
    refetchInterval: 1000 * 30, // poll every 30 seconds
  });
};

export const useFetchPollUnreadNotificationCount = (isLoggedIn?: boolean) => {
  return useQuery({
    queryKey: notificationKeys.notificationCount(),
    queryFn: () => {
      return gqlClient.request(FetchUnreadNotificationCount);
    },
    enabled: isLoggedIn,
    networkMode: 'always',
    refetchInterval: 60000, // poll every minute
  });
};
export const useFetchUnreadNotificationCount = () => {
  return useQuery({
    queryKey: notificationKeys.notificationCount(),
    queryFn: () => {
      return gqlClient.request(FetchUnreadNotificationCount);
    },
    enabled: false,
  });
};

export default useFetchNotifications;
