import { FC, Fragment, ReactElement, useRef, useState } from 'react';

import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';

export enum TooltipPositionVariant {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export interface IProps {
  positionVariant?: TooltipPositionVariant;
  children: ReactElement[];
  showArrow?: boolean;
  arrowClassName?: string;
  enabled?: boolean;
}

const ARROW_HEIGHT = 7;
const GAP = 3;

const FloatingTooltip: FC<IProps> = (props) => {
  const {
    positionVariant = TooltipPositionVariant.TOP,
    showArrow = false,
    enabled = true,
    arrowClassName,
    children,
  } = props;

  if (children.length !== 2) {
    console.warn(
      '<FloatingTooltip /> requires exactly two children. The first child is the reference element and the second child is the floating element.'
    );
    return <Fragment />;
  }

  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    placement: positionVariant,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      arrow({
        element: arrowRef,
      }),
      flip({
        // https://floating-ui.com/docs/flip#fallbackaxissidedirection
        fallbackAxisSideDirection: 'start',
      }),
      offset(showArrow ? ARROW_HEIGHT + GAP : GAP),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    // If your reference element has its own label (text).
    role: 'tooltip',
  });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  const [ReferenceNode, FloatingNode] = props.children;

  return (
    <>
      <ReferenceNode.type
        {...getReferenceProps(ReferenceNode.props)}
        ref={refs.setReference}
      />

      {enabled && isOpen && (
        <FloatingPortal>
          <FloatingNode.type
            style={floatingStyles}
            {...getFloatingProps(FloatingNode.props)}
            ref={refs.setFloating}
          >
            {FloatingNode.props.children}

            {showArrow && (
              <FloatingArrow
                ref={arrowRef}
                context={context}
                className={arrowClassName}
              />
            )}
          </FloatingNode.type>
        </FloatingPortal>
      )}
    </>
  );
};

export default FloatingTooltip;
