import MaterialUiSlider from '@material-ui/core/Slider';
import { FC, useCallback } from 'react';
import NumberFormat from 'react-number-format';

import './Slider.sass';

type Props = {
  description?: string;
  hideLabels?: boolean;
  hideCurrentValueLabel?: boolean;
  inputLabel?: string;
  inputValue?: number;
  isDisabled?: boolean;
  minSliderValue: number;
  maxSliderValue: number;
  onInputBlur?: () => void;
  onInputChange?: (change: any) => void;
  onSliderChange: (change: any) => void;
  sliderStep?: number | null;
  sliderValue?: number;
  sliderLabelFormat?: (val: any) => string | number;
};
const Slider: FC<Props> = ({
  description,
  hideCurrentValueLabel = false,
  hideLabels = false,
  inputValue,
  inputLabel,
  isDisabled = false,
  minSliderValue,
  maxSliderValue,
  onInputBlur,
  onInputChange,
  onSliderChange,
  sliderStep = null,
  sliderLabelFormat,
  sliderValue,
}: Props): JSX.Element => {
  const sliderLabel = useCallback(
    (val: number | string) =>
      sliderLabelFormat ? sliderLabelFormat(val) : val,
    [sliderLabelFormat]
  );
  return (
    <div className="slider-wrapper">
      <div className="slider-input-and-description">
        {description && <span>{description}</span>}
        {inputValue !== undefined && !!onInputChange && (
          <div className="input-control">
            {inputLabel && (
              <span className="input-control-label">{inputLabel}</span>
            )}
            <NumberFormat
              value={inputValue / 100}
              thousandSeparator={true}
              onValueChange={(values) => {
                if (values && (values.floatValue || values.floatValue === 0)) {
                  onInputChange(values.floatValue * 100);
                }
              }}
              onBlur={() => onInputBlur && onInputBlur()}
              disabled={isDisabled}
            />
          </div>
        )}
      </div>
      <div className="slider-control">
        <MaterialUiSlider
          value={sliderValue}
          step={sliderStep}
          min={minSliderValue}
          max={maxSliderValue}
          onChange={(e, newValue) => onSliderChange(newValue)}
          valueLabelDisplay={hideCurrentValueLabel ? 'off' : 'auto'}
          valueLabelFormat={sliderLabelFormat}
          disabled={isDisabled}
        />
        {!hideLabels && (
          <div className="slider-labels">
            <span className="slider-control-label min">
              {sliderLabel(minSliderValue)}
            </span>
            <span className="slider-control-label max">
              {sliderLabel(maxSliderValue)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Slider;
