import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IntercomAPI } from 'react-intercom';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { actions as appActions } from '@/api/app';
import { actions as usersActions } from '@/api/users';
import * as customerActions from '@payaca/store/customer/customerActions';
import * as dealsActions from '@payaca/store/deals/dealsActions';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as jobPaymentsActions from '@payaca/store/jobPayments/jobPaymentsActions';
import * as jobsActions from '@payaca/store/jobs/jobsActions';

import EditJobControl from '@/ui/components/editJobControl/EditJobControl';
import PreviewAndSendJobControl from '@/ui/components/previewAndSendJobControl/PreviewAndSendJobControl';
import PreviewJobControl from '@/ui/components/previewJobControl/PreviewJobControl';
import Modal from '@payaca/components/modal/Modal';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import { getModal } from '@/helpers/modalHelper';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';

import { getCustomerByDealId, getDealByJobId } from '@/utils/stateAccessors';

import { useSelector } from '@/api/state';
import EditProposalLineItemSidebar from '@/ui/pages/newEditQuotePage/components/EditProposalLineItemSidebar';
import {
  isEstimate,
  isInvoice,
  isQuote,
} from '@payaca/helpers/jobStatusHelper';
import * as fileDownloadActions from '@payaca/store/fileDownload/fileDownloadActions';
import { FileDownloadTypes } from '@payaca/types/fileDownloadTypes';
import UntitledIcon from '@payaca/untitled-icons';
import { downloadPdfData } from '@payaca/utilities/fileUtilities';
import './EditJobPage.sass';

export enum ViewType {
  EDIT = 'create',
  PREVIEW = 'preview',
  SEND = 'send',
}

type Props = {
  jobId: number;
  viewType?: ViewType;
  sendJobToCustomer: (
    id: number,
    payload: {
      sendMeACopy: boolean;
      preButtonEmailText: string;
      postButtonEmailText: string;
    },
    cb: (err: any, response: any) => void
  ) => void;
  dealId?: number;
};

const EditJobPage: FC<Props> = ({
  jobId,
  viewType,
  sendJobToCustomer,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

  const match = useRouteMatch();
  const isEditProposalLineItemDrawOpen = useRouteMatch<{
    proposalLineItemId: string;
  }>(`${match.url}/items/:proposalLineItemId`);

  const [view, setView] = useState<ViewType>(viewType || ViewType.EDIT);
  const [validationResult, setValidationResult] = useState<{
    isValid: boolean;
    errors: string[];
  }>();

  const job = useSelector((state) => {
    return state.jobsStore.jobs && state.jobsStore.jobs[jobId]?.entity;
  });

  const deal = useSelector((state) => {
    return getDealByJobId(state, jobId);
  });

  const accountId = useSelector(
    (state: any) => state.users.myProfile.accounts[0].id
  );

  useEffect(() => {
    if (job && job.bouncedAt) {
      setView(ViewType.SEND);
    }
  }, [job?.bouncedAt]);

  const navigateToView = useMemo(() => {
    return getUrlSearchParam(location.search, 'view');
  }, [location]);
  const dealId = useMemo(
    () => getUrlSearchParam(location.search, 'dealId'),
    [location]
  );

  useEffect(() => {
    if (
      navigateToView === ViewType.SEND &&
      validationResult &&
      validationResult.isValid
    ) {
      setView(ViewType.SEND);
    }
  }, [navigateToView, validationResult]);

  useEffect(() => {
    dispatch(usersActions.getAccountTerms(accountId));
  }, [accountId]);

  useEffect(() => {
    dispatch(
      jobsActions.requestGetJobValidationResult(jobId, (validationResult) => {
        setValidationResult(validationResult);
      })
    );
    dispatch(usersActions.getBusinessAttachments());

    return () => {
      dispatch(jobsActions.clearJobUpdateResults());
      dispatch(jobContentActions.clearJobLineItemUpdateResults());
      dispatch(jobContentActions.clearJobLineItemGroupUpdateResults());
    };
  }, []);

  useEffect(() => {
    if (jobId) {
      dispatch(jobsActions.requestGetJob(jobId));
      dispatch(jobsActions.requestGetJobAttachmentsForJob(jobId));
    }
  }, [jobId]);

  useEffect(() => {
    if (job?.jobContentId) {
      dispatch(
        jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
          job.jobContentId
        )
      );
    }
  }, [job?.jobContentId]);

  useEffect(() => {
    if (
      job &&
      !job.sentAt &&
      validationResult &&
      !validationResult.isValid &&
      view == ViewType.PREVIEW
    ) {
      setView(ViewType.EDIT);
    }
  }, [validationResult, view, job]);

  const customer = useSelector((state) => {
    if (!job) return;
    return getCustomerByDealId(state, job.dealId);
  });

  const isJobMarkedAsSentSuccessfully = useSelector((state) => {
    return state.jobsStore.isJobMarkedAsSentSuccessfully;
  });

  const markJobAsSentErrorMessage = useSelector((state) => {
    return state.jobsStore.markJobAsSentErrorMessage;
  });

  useEffect(() => {
    if (job && job?.dealId) {
      dispatch(dealsActions.requestGetDeal(job?.dealId));
      dispatch(jobPaymentsActions.requestGetJobPaymentsForDeal(job?.dealId));
      dispatch(jobsActions.requestGetJobsForDeal(job?.dealId));
    }
  }, [job?.dealId]);

  useEffect(() => {
    if (deal?.customerId) {
      dispatch(customerActions.requestGetCustomer(deal?.customerId));
    }
  }, [deal?.customerId]);

  const isJobSentSuccessfully = useMemo(() => {
    if (!job) return false;
    return !job.bouncedAt && !!job.sentAt;
  }, [job]);

  const handleErrorMessageOnSendAttempt = useCallback(
    (errorMessage: string) => {
      if (!job || !customer) return;
      if (errorMessage === 'DEMO_ENDED') {
        dispatch(
          appActions.showModal(
            getModal('DEMO_ENDED', {
              primaryAction: () => {
                dispatch(appActions.hideModal());
                history.push('/upgradeAccount');
              },
              secondaryAction: () => {
                IntercomAPI('show');
                dispatch(appActions.hideModal());
              },
              onClose: () => dispatch(appActions.hideModal()),
            })
          )
        );
      } else if (errorMessage === 'You have exceeded your subscription plan') {
        dispatch(
          appActions.showModal(
            getModal('EXCEEDED_SUBSCRIPTION', {
              primaryAction: () => {
                dispatch(appActions.hideModal());
                history.push('/upgradeAccount');
              },
              secondaryAction: () => {
                IntercomAPI('show');
                dispatch(appActions.hideModal());
              },
              onClose: () => dispatch(appActions.hideModal()),
            })
          )
        );
      } else if (errorMessage === 'EMAIL_INACTIVE') {
        const primaryContact = getJobContactFromCustomer(
          customer,
          job?.contactId || null
        );
        dispatch(
          appActions.showModal(
            getModal('INACTIVE_EMAIL', {
              primaryAction: () => {
                dispatch(appActions.hideModal());
              },
              text: [primaryContact?.emailAddress],
            })
          )
        );
      }
    },
    [dispatch, history, customer]
  );

  const navigateToDeal = useCallback(() => {
    history.replace({
      pathname: `/deals/${job?.dealId}`,
    });
  }, [job]);

  const markAsSentCallback = useCallback(() => {
    if (isJobMarkedAsSentSuccessfully) {
      navigateToDeal();
    } else if (!isJobMarkedAsSentSuccessfully && markJobAsSentErrorMessage) {
      handleErrorMessageOnSendAttempt(markJobAsSentErrorMessage);
    }
  }, [
    markJobAsSentErrorMessage,
    isJobMarkedAsSentSuccessfully,
    handleErrorMessageOnSendAttempt,
    navigateToDeal,
  ]);

  const pdfFileName = useMemo(() => {
    if (!job) return '';
    if (isInvoice(job.status)) {
      return `invoice_${job.reference}.pdf`;
    } else if (isQuote(job.status)) {
      return `quote_${job.reference}.pdf`;
    } else if (isEstimate(job.status)) {
      return `estimate_${job.reference}.pdf`;
    } else {
      return `job_${job.reference}.pdf`;
    }
  }, [job]);

  const triggerFileDownload = useCallback(
    (fileContent: string) => {
      downloadPdfData(fileContent, pdfFileName);
      setIsDownloadingPdf(false);
    },
    [pdfFileName]
  );

  const handleDownloadDocument = useCallback(() => {
    if (!job) return;

    setIsDownloadingPdf(true);
    dispatch(
      fileDownloadActions.requestDownloadFile(
        jobId,
        isInvoice(job.status)
          ? FileDownloadTypes.INVOICE
          : FileDownloadTypes.QUOTE,
        triggerFileDownload
      )
    );
  }, [dispatch, jobId, job, triggerFileDownload]);

  const handleProposalLineItemPersist = () => {
    if (job) {
      dispatch(
        jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
          job.jobContentId
        )
      );
    }

    if (isEditProposalLineItemDrawOpen?.params.proposalLineItemId !== 'new') {
      dispatch(
        jobContentActions.requestGetJobLineItemAttachmentsForJobLineItem(
          +isEditProposalLineItemDrawOpen!.params.proposalLineItemId
        )
      );
    }

    history.push(match.url);
  };

  return (
    <>
      <AuthenticatedPageWrapper
        className={
          job && (isJobSentSuccessfully || job?.archivedAt)
            ? 'bg-gray-50'
            : 'edit-job-page'
        }
        previousPageNavigationConfig={{
          route: `/deals/${job ? job.dealId : dealId ? dealId : ''}/${
            deal?.version === 1 ? 'proposals-and-invoices' : 'proposals'
          }`,
        }}
      >
        {job && (isJobSentSuccessfully || job?.archivedAt) && (
          <div className="p-4">
            <PreviewJobControl
              stickyTopClassName="top-24"
              jobId={jobId}
              primaryAction={{
                onClick: handleDownloadDocument,
                children: (
                  <UntitledIcon name="download-01.3" className="h-5 w-5" />
                ),
                isProcessing: isDownloadingPdf,
                disabled: isDownloadingPdf,
              }}
              onJobUpdateSuccess={() => {
                dispatch(jobsActions.requestGetJob(jobId));
              }}
              onDealPaymentsUpdateSuccess={() => {
                if (job) {
                  dispatch(dealsActions.requestGetDeal(job.dealId));

                  dispatch(
                    jobPaymentsActions.requestGetJobPaymentsForDeal(job.dealId)
                  );
                }
              }}
              markAsSentCallback={markAsSentCallback}
            />
          </div>
        )}
        {job && !isJobSentSuccessfully && (
          <>
            {(view === ViewType.EDIT ||
              (view === ViewType.SEND && !job.bouncedAt)) && (
              <>
                <EditJobControl
                  jobId={jobId}
                  proceedToPreview={() => {
                    setView(ViewType.SEND);
                  }}
                />
                <Modal
                  className="preview-and-send-job-modal"
                  isOpen={view === ViewType.SEND}
                  size="lg"
                  onClose={() => setView(ViewType.EDIT)}
                >
                  <PreviewAndSendJobControl
                    jobId={jobId}
                    sendJobToCustomer={sendJobToCustomer}
                  />
                </Modal>
              </>
            )}

            {job.bouncedAt && (
              <PreviewAndSendJobControl
                jobId={jobId}
                sendJobToCustomer={sendJobToCustomer}
              />
            )}
          </>
        )}
      </AuthenticatedPageWrapper>

      <EditProposalLineItemSidebar
        allowProposalLineItemSelectionType
        isOpen={!!isEditProposalLineItemDrawOpen}
        proposalLineItemId={
          isEditProposalLineItemDrawOpen?.params.proposalLineItemId &&
          isEditProposalLineItemDrawOpen.params.proposalLineItemId !== 'new'
            ? isEditProposalLineItemDrawOpen.params.proposalLineItemId
            : undefined
        }
        onSaveSuccess={handleProposalLineItemPersist}
        onRemoveSuccess={handleProposalLineItemPersist}
        onClose={() => history.push(match.url)}
      />
    </>
  );
};
export default EditJobPage;
