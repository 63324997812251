import { FC, useCallback, useMemo } from 'react';

import TextareaFieldFormatter from '@payaca/components/textareaField/TextareaFieldFormatter';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import EditJobSection from './EditJobSection';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Job } from '@payaca/types/jobTypesV2';

import { getJob } from '@/utils/stateAccessors';

import { useSelector } from '@/api/state';
import { isInvoice } from '@payaca/helpers/jobStatusHelper';

type Props = {
  jobId: number;
  updateJob: (
    value: { [key: string]: any },
    onUpdateSuccess?: () => void
  ) => void;
};
const JobIntroControl: FC<Props> = ({ jobId, updateJob }: Props) => {
  const job: Job | undefined = useSelector((state) => getJob(state, jobId));

  const initialFormState = useMemo(() => {
    return {
      job: {
        jobDescription: job?.jobDescription,
      },
    };
  }, [job]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <TextareaFieldFormatter
          name="job.jobDescription"
          value={formState.job?.jobDescription}
          onChange={onFieldChange}
          placeholder={`Start your ${
            !!job && isInvoice(job?.status) ? 'invoice' : 'proposal'
          } off with a personalised message, greet your customer by their first name.`}
          changeTimeoutMs={2000}
        />
      );
    },
    [job?.status]
  );

  return (
    <EditJobSection title="Intro" className="job-intro-control">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
        onFormStateChange={updateJob}
      />
    </EditJobSection>
  );
};

export default JobIntroControl;
