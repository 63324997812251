import { FC } from 'react';

import SetupUserForm from '@/ui/components/setupUserForm/SetupUserForm';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';

const SetupUserPage: FC = () => {
  return (
    <UnauthenticatedPageWrapper
      className="setup-user-page"
      titleContent={<h1>Join Payaca</h1>}
    >
      <SetupUserForm />
    </UnauthenticatedPageWrapper>
  );
};

export default SetupUserPage;
