import { FC, useEffect, useMemo, useState } from 'react';

import { useAccount } from '@/utils/storeHooks';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import Button from '@payaca/components/plButton/Button';
import Modal from '@payaca/components/plModal/Modal';
import TextareaField from '@payaca/components/textareaField/TextareaField';
import ResetIcon from './reset-icon.svg';

type Props = {
  buttonText: string | null;
  defaultValue: string;
  isOpen: boolean;
  title: string;
  name: string;
  onClose: () => void;
  onSubmit: (value: string) => void;
  readOnly: boolean;
  value: string;
};
const EmailTemplateModal: FC<Props> = ({
  buttonText,
  defaultValue,
  isOpen,
  title,
  name,
  onClose,
  onSubmit,
  readOnly,
  value,
}: Props): JSX.Element => {
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const account = useAccount();
  const [emailCopy, setEmailCopy] = useState({ preButton: '', postButton: '' });

  const newValue = useMemo(() => {
    return `${emailCopy.preButton}\n[button]\n${emailCopy.postButton}`;
  }, [emailCopy]);

  useEffect(() => {
    if (isResetting) {
      setTimeout(() => setIsResetting(false), 400);
    }
  }, [isResetting]);

  const showReset = useMemo(
    () => (newValue && newValue !== defaultValue) || isResetting,
    [newValue, isResetting, defaultValue]
  );

  const accountLogoUrl = useMemo(() => account.logoUrl, [account]);

  useEffect(() => {
    if (!value) {
      setEmailCopy({
        preButton: '',
        postButton: '',
      });
    }
    let splitEmail;
    if (value.includes('\n[button]\n')) {
      splitEmail = value.split('\n[button]\n');
    } else {
      splitEmail = value.split('[button]');
    }
    setEmailCopy({
      preButton: splitEmail[0],
      postButton: splitEmail[1],
    });
  }, [value]);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      size="md"
      onClose={onClose}
      className="email-template-modal"
    >
      <Modal.Body>
        <div className="template-wrapper">
          {/* Account logo */}
          {accountLogoUrl && (
            <img src={accountLogoUrl} className="account-logo" />
          )}
          {/* Pre button text */}
          <TextareaField
            autoSize={true}
            isRequired={true}
            value={emailCopy?.preButton}
            name={name}
            onChange={(change) => {
              setEmailCopy((ec) => ({
                ...ec,
                preButton: change[name],
              }));
            }}
            rows={3}
            isDisabled={readOnly}
            styleVariant={InputStyleVariant.UNSTYLED}
          />
          <Button disabled>{buttonText}</Button>
          {/* Post button text */}
          <TextareaField
            autoSize={true}
            isRequired={true}
            value={emailCopy?.postButton}
            name={name}
            onChange={(change) => {
              setEmailCopy((ec) => ({
                ...ec,
                postButton: change[name],
              }));
            }}
            rows={2}
            isDisabled={readOnly}
            styleVariant={InputStyleVariant.UNSTYLED}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          {/* Buttons */}
          {showReset && (
            <div
              className="reset-container pl-4"
              onClick={() => {
                let splitEmail = undefined;
                if (defaultValue.includes('\n[button]\n')) {
                  splitEmail = value.split('\n[button]\n');
                } else {
                  splitEmail = value.split('[button]');
                }
                setEmailCopy({
                  preButton: splitEmail[0],
                  postButton: splitEmail[1],
                });
              }}
            >
              <img
                src={ResetIcon}
                className={`reset-icon${isResetting ? ' spin' : ''}`}
              />
              <span>Reset template</span>
            </div>
          )}

          <Button onClick={() => onSubmit(newValue)}>Continue</Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailTemplateModal;
