import { DealTemplate } from '@payaca/types/dealTemplateTypes';
import { action } from 'typesafe-actions';
import { ActionType } from './dealTemplateTypes';

export const requestGetDealTemplateItems = (dealId: number) => {
  return action(ActionType.REQUEST_GET_DEAL_TEMPLATE_ITEMS, {
    dealId,
  });
};

export const getDealTemplateItemsSuccess = (templateItems: any) => {
  return action(ActionType.GET_DEAL_TEMPLATE_ITEMS_SUCCESS, templateItems);
};

export const getDealTemplateItemsFailure = (dealId: number, error: Error) => {
  return action(
    ActionType.GET_DEAL_TEMPLATE_ITEMS_FAILURE,
    { dealId },
    null,
    error
  );
};

export const requestPersistDealTemplate = (
  dealTemplateTitle: string,
  dealTemplateItems: any[],
  dealTemplateId: number | null,
  callback?: any
) => {
  return action(ActionType.REQUEST_PERSIST_DEAL_TEMPLATE, {
    dealTemplateTitle,
    dealTemplateItems,
    dealTemplateId,
    callback,
  });
};

export const persistDealTemplateSuccess = () => {
  return action(ActionType.PERSIST_DEAL_TEMPLATE_SUCCESS);
};

export const persistDealTemplateFailure = (error: Error) => {
  return action(ActionType.PERSIST_DEAL_TEMPLATE_FAILURE, null, error);
};

export const requestGetDealTemplates = (searchTerms: string) => {
  return action(ActionType.REQUEST_GET_DEAL_TEMPLATES, {
    searchTerms,
  });
};

export const getDealTemplatesSuccess = (dealTemplates: DealTemplate[]) => {
  return action(ActionType.GET_DEAL_TEMPLATES_SUCCESS, dealTemplates);
};

export const getDealTemplatesFailure = (error: Error) => {
  return action(ActionType.GET_DEAL_TEMPLATES_FAILURE, null, null, error);
};

export const requestApplyDealTemplate = (
  dealId: number,
  dealTemplateId: number,
  callback: any
) => {
  return action(ActionType.REQUEST_APPLY_DEAL_TEMPLATE, {
    dealId,
    dealTemplateId,
    callback,
  });
};

export const applyDealTemplateSuccess = () => {
  return action(ActionType.APPLY_DEAL_TEMPLATE_SUCCESS);
};

export const applyDealTemplateFailure = (error: Error) => {
  return action(ActionType.APPLY_DEAL_TEMPLATE_FAILURE, null, null, error);
};

export const clearDealTemplates = () => {
  return action(ActionType.CLEAR_DEAL_TEMPLATES);
};
