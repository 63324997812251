import * as pdfDocumentDataActions from '@payaca/store/pdfDocumentData/pdfDocumentDataActions';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import InvoiceDocument from '@payaca/components/invoiceDocument/InvoiceDocument';
import { InvoicePdfDocumentData } from '@payaca/types/invoicePdfDocumentTypes';
import { IntercomAPI } from 'react-intercom';
import './ProtoInvoicePage.sass';

type Props = {
  previewToken: string;
  protoInvoiceId: number;
};

const ProtoInvoicePage: FC<Props> = ({
  previewToken,
  protoInvoiceId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [protoInvoiceData, setProtoInvoiceData] =
    useState<InvoicePdfDocumentData>();

  useEffect(() => {
    dispatch(
      pdfDocumentDataActions.getProtoInvoiceData.request({
        previewToken: previewToken,
        protoInvoiceId: protoInvoiceId,
        callback: setProtoInvoiceData,
      })
    );

    IntercomAPI('update', {
      hide_default_launcher: true,
    });

    return () => {
      IntercomAPI('update', {
        hide_default_launcher: false,
      });
    };
  }, []);

  if (!protoInvoiceData) return null;

  return (
    <div className="pdf-proto-invoice-page">
      <InvoiceDocument
        documentSenderContext={protoInvoiceData.documentSenderContext}
        invoiceId={protoInvoiceId}
        jobLineItems={protoInvoiceData.jobLineItems}
        invoices={protoInvoiceData.invoices}
        invoiceLines={protoInvoiceData.invoiceLines}
        customer={protoInvoiceData.customer}
        siteAddresses={protoInvoiceData.siteAddresses}
        dealTotals={protoInvoiceData.dealTotals}
        hideItemPrices={protoInvoiceData.hideItemPrices}
        paymentMethodConfig={protoInvoiceData.paymentMethodConfig}
        isStraightToInvoice={protoInvoiceData.isStraightToInvoice}
      />
    </div>
  );
};

export default ProtoInvoicePage;
