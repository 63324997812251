import Tile from '@payaca/components/tile/Tile';
import * as lineItemActions from '@payaca/store/lineItemsV2/lineItemsActions';
import { LineItemGroup } from '@payaca/types/lineItemGroupTypes';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@/api/state';
import { currencyPrice } from '@/helpers/financeHelper';
import { getLineItemsByLineItemGroupId } from '@/utils/stateAccessors';
import { HeaderTagType } from '@payaca/components/headerTag/HeaderTag';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import { calculateLineItemGroupTotals } from '@payaca/helpers/lineItemGroupTotalsHelper';
import './ListedLineItemGroupTile.sass';

type Props = {
  lineItemGroupId: number;
  onClick?: () => void;
  titleHeaderTagType?: HeaderTagType;
};
const ListedLineItemGroupTile: FC<Props> = ({
  lineItemGroupId,
  onClick,
  titleHeaderTagType = HeaderTagType.H2,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const lineItemGroup: LineItemGroup | undefined = useSelector(
    (state) => state.lineItemGroups?.lineItemGroups[lineItemGroupId]?.entity
  );

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const lineItems = useSelector((state) => {
    return getLineItemsByLineItemGroupId(state, lineItemGroupId);
  });

  useEffect(() => {
    lineItemGroupId &&
      dispatch(
        lineItemActions.requestGetLineItemsForLineItemGroup(lineItemGroupId)
      );
  }, [lineItemGroupId]);

  const groupHasAnyNonRequiredItems = useMemo(() => {
    if (!lineItemGroup) return false;
    return lineItemGroup.lineItemRelations.some(
      (x) => x.isMultipleChoice || x.isOptional
    );
  }, [lineItemGroup]);

  const groupTotals = useMemo(() => {
    if (!lineItemGroup) return;
    return calculateLineItemGroupTotals(lineItemGroup, lineItems);
  }, [lineItemGroup, lineItems]);

  const totalElement = useMemo(() => {
    if (!groupTotals) return;

    let totalValue = '';
    if (groupHasAnyNonRequiredItems) {
      totalValue = `${currencyPrice(
        groupTotals.minTotal,
        account.region
      )} - ${currencyPrice(groupTotals.maxTotal, account.region)}`;
    } else {
      totalValue = currencyPrice(groupTotals.total, account.region);
    }

    return <LabelValuePair label="Total" value={totalValue} />;
  }, [groupTotals, groupHasAnyNonRequiredItems, account]);

  return (
    <Tile
      onClick={onClick}
      className={`listed-line-item-group-tile ${onClick ? 'clickable' : ''}`}
      title={lineItemGroup?.name}
      titleHeaderTagType={titleHeaderTagType}
      footer={
        <div className="flex-container flex-center">
          <span className="flex-grow">{totalElement}</span>
        </div>
      }
    >
      <p className="description">{lineItemGroup?.description}</p>
      {!!lineItems.length && (
        <ul>
          {lineItems.map((lineItem: any, index: number) => {
            return (
              <li key={`line-item-relation-${index}`}>
                <div className="line-item-description">
                  {lineItem.description}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </Tile>
  );
};

export default ListedLineItemGroupTile;
