import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  FocusEventHandler,
  FunctionComponent,
  useContext,
  useMemo,
  useRef,
} from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { FieldContext } from '../plField/Field';

export enum TextareaSizeVariant {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum TextareaStyleVariant {
  BASIC = 'basic',
  GREY = 'grey',
  UNDERLINE = 'underline',
}

export type IProps = Omit<
  ComponentPropsWithoutRef<'textarea'>,
  'onChange' | 'name' | 'id' | 'style'
> & {
  sizeVariant?: TextareaSizeVariant;
  styleVariant?: TextareaStyleVariant;
  value?: string;
  onChange?: (value: string) => void;
  onTouch?: () => void;
  className?: string;
  changeTimeoutMs?: number;
  onChangeTimeout?: () => void;
  autoHeight?: boolean;
};

export const Textarea: FunctionComponent<IProps> = ({
  sizeVariant = TextareaSizeVariant.MD,
  styleVariant = TextareaStyleVariant.BASIC,
  value,
  onChange,
  onTouch,
  onChangeTimeout,
  changeTimeoutMs = 1000,
  disabled,
  className,
  onFocus,
  autoHeight = false,
  ...rest
}) => {
  const { id, name, validationState } = useContext(FieldContext);

  const changeTimeout = useRef<NodeJS.Timeout | null>(null);

  const isUnderline = styleVariant === TextareaStyleVariant.UNDERLINE;

  const borderClasses = useMemo(() => {
    if (isUnderline) {
      return 'border-0 border-b-2 focus:ring-0';
    }

    return 'border focus:ring-1';
  }, [styleVariant]);

  const borderColourClasses = useMemo(() => {
    switch (validationState?.isValid) {
      case true:
        return 'border-teal-500';
      case false:
        return 'border-red-500';
      default:
        return styleVariant === TextareaStyleVariant.GREY
          ? 'border-transparent'
          : 'border-gray-200';
    }
  }, [validationState?.isValid, styleVariant, isUnderline]);

  const sizeClasses = useMemo(() => {
    switch (sizeVariant) {
      case TextareaSizeVariant.SM:
        return isUnderline ? 'pe-0 py-2 px-0' : 'py-2 px-3';
      case TextareaSizeVariant.MD:
        return isUnderline ? 'pe-0 py-3 px-0' : 'py-3 px-4';
      case TextareaSizeVariant.LG:
        return isUnderline ? 'pe-0 py-3 sm:py-5 px-0' : 'py-3 px-4 sm:p-5';
    }
  }, [sizeVariant]);

  const shapeClasses = useMemo(() => {
    if (isUnderline) {
      return 'rounded-none';
    }

    return 'rounded-lg';
  }, [styleVariant]);

  const backgroundColourClasses = useMemo(() => {
    switch (styleVariant) {
      case TextareaStyleVariant.GREY:
        return 'bg-gray-100';
      default:
        return 'bg-white';
    }
  }, [styleVariant]);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    onChange?.(e.target.value);

    if (changeTimeout?.current) {
      clearTimeout(changeTimeout.current);
    }
    changeTimeout.current = setTimeout(() => {
      onChangeTimeout && onChangeTimeout();
    }, changeTimeoutMs);
  };

  const handleTouch: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    onTouch?.();
    // Normal on focus event
    onFocus?.(e);
  };

  if (autoHeight) {
    return (
      <ReactTextareaAutosize
        disabled={disabled}
        className={`pl-form-textarea ${sizeClasses} relative focus:border-blue-500 focus:ring-blue-500 focus-visible:outline-none disabled:pointer-events-none  disabled:opacity-50 ${backgroundColourClasses} border-solid  ${borderClasses} ${
          disabled ? 'pointer-events-none opacity-70' : ''
        } ${borderColourClasses} ${shapeClasses} ${className || ''}`}
        value={value}
        name={name}
        id={id}
        onChange={handleChange}
        onFocus={handleTouch}
        minRows={rest.rows || 3}
        {...rest}
      />
    );
  }

  return (
    <textarea
      disabled={disabled}
      className={`pl-form-textarea ${sizeClasses} relative focus:border-blue-500 focus:ring-blue-500 focus-visible:outline-none disabled:pointer-events-none  disabled:opacity-50 ${backgroundColourClasses} border-solid  ${borderClasses} ${
        disabled ? 'pointer-events-none opacity-70' : ''
      } ${borderColourClasses} ${shapeClasses} ${className || ''}`}
      value={value}
      name={name}
      id={id}
      onChange={handleChange}
      onFocus={handleTouch}
      rows={3}
      {...rest}
    />
  );
};
