import useGetProjectOverview from '@/api/queries/project/useGetProjectOverview';
import ProjectProgress, {
  IProps as IProjectProgressProps,
} from '@/ui/pages/dealPage/components/ProjectHeader/ProjectProgress';
import Card from '@payaca/components/plCard/Card';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { pick } from 'lodash-es';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useGetProjectProfitBreakdown from '../../../../api/queries/project/useGetProjectProfitBreakdown';
import ProfitBreakdown, {
  Props as ProfitBreakdownProps,
} from './ProjectHeader/ProfitBreakdown';

const ProjectOverviewCards: FC = () => {
  const { dealId } = useParams<{ dealId: string }>();
  const { data: projectData, isLoading: isProjectLoading } =
    useGetProjectOverview(+dealId);
  const {
    data: projectProfitBreakdownData,
    // isLoading
  } = useGetProjectProfitBreakdown(+dealId);

  const financialSummary = useMemo(() => {
    const totals = projectData?.project.totals;

    if (!totals) return;

    const _financialSummary: IProjectProgressProps['financialSummary'] = {
      totalProjectValue: totals.projectValue.value,
      projectProgressPercentage: Math.round(
        (totals.sentInvoicedValue.value / totals.projectValue.value) * 100
      ),
      currency: totals.acceptedValue.currency,
    };

    if (projectData.project.version > 1) {
      _financialSummary.toBeInvoiced =
        totals.acceptedValue.value - totals.sentInvoicedValue.value;
      _financialSummary.toBePaid =
        totals.sentInvoicedValue.value - totals.completedPaymentValue.value;
      _financialSummary.paid = totals.completedPaymentValue.value;
    }

    return _financialSummary;
  }, [projectData]);

  const profitData = useMemo(() => {
    const profitBreakdown = projectProfitBreakdownData?.project.profitBreakdown;
    if (!profitBreakdown) {
      return;
    }

    const profitData: ProfitBreakdownProps['profitData'] = {
      revenue: pick(profitBreakdown.revenue, ['actual', 'forecast']),
      materials: pick(profitBreakdown.materials, ['actual', 'forecast']),
      labour: pick(profitBreakdown.labour, ['actual', 'forecast']),
      profit: pick(profitBreakdown.profit, ['actual', 'forecast']),
    };
    return profitData;
  }, [projectProfitBreakdownData]);

  if (isProjectLoading) {
    return (
      <div className="flex flex-col gap-4 sm:flex-row">
        <Card className="flex-[1_1_0px]">
          <Card.Body className="space-y-2">
            <SkeletonLoader.Text className="mb-4 w-1/4" />
            <SkeletonLoader.Text />
            <SkeletonLoader.Text />
            <SkeletonLoader.Text />
          </Card.Body>
        </Card>

        <Card className="flex-[1_1_0px]">
          <Card.Body className="space-y-2">
            <SkeletonLoader.Text className="mb-4 w-1/4" />
            <SkeletonLoader.Text />
            <SkeletonLoader.Text />
            <SkeletonLoader.Text />
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4 sm:flex-row-reverse">
        <ProjectProgress
          className="sm:w-1/3 lg:w-1/3 xl:w-1/4"
          financialSummary={financialSummary}
        />
        <ProfitBreakdown
          className="hidden sm:block sm:flex-auto"
          profitData={profitData}
        />
      </div>
    </>
  );
};

export default ProjectOverviewCards;
