import { FC } from 'react';

import Modal from '@payaca/components/plModal/Modal';
import { PersistTaskRequestData } from '@payaca/store/tasks/tasksTypes';
import CreateEditTaskControl from '../createEditTaskControl/CreateEditTaskControl';

import './CreateEditTaskModal.sass';

type Props = {
  dealId?: number;
  isOpen: boolean;
  onClose: () => void;
  onPersistTaskSuccess?: (taskId: number) => void;
  task?: PersistTaskRequestData;
};
const CreateEditTaskModal: FC<Props> = ({
  dealId,
  isOpen,
  onClose,
  onPersistTaskSuccess,
  task,
}: Props): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      className="create-edit-task-modal"
      title={task?.id ? 'Edit Task' : 'Create Task'}
      onClose={onClose}
      disableBackdropClick={true}
    >
      <CreateEditTaskControl
        dealId={dealId}
        task={task}
        onPersistTaskSuccess={onPersistTaskSuccess}
      />
    </Modal>
  );
};
export default CreateEditTaskModal;
