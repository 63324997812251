import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { ActionType, SagaConfig } from './userRolesTypes';

import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import {
  clearUserRoles,
  getUserRolesFailure,
  getUserRolesSuccess,
} from './userRolesActions';

const userRolesSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  function* handleGetUserRoles() {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(getUserRoles),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getUserRolesFailure(new Error('Get user roles request timed out.'))
        );
      } else {
        yield put(getUserRolesSuccess(response.roles));
      }
    } catch (error: any) {
      yield put(getUserRolesFailure(error));
    }
  }

  const getUserRoles = async () => {
    return fetch(`${apiBaseurl}/api/users/me/roles`, {
      method: 'GET',
      headers: {
        Authorization: await getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetUserRoles failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleAppLogout() {
    yield put(clearUserRoles());
  }

  return function* () {
    yield takeLatest(ActionType.REQUEST_GET_USER_ROLES, handleGetUserRoles);
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default userRolesSagaCreator;
