import qs from 'qs';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';

import { useTranslation } from '../../../i18n';
import ServicePlansHeader from '../../components/servicePlansHeader/ServicePlansHeader';

import { Routes } from '@/routes/RoutesConfig';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import ServicePlansCustomerRemindersListed from '@/ui/components/servicePlansCustomerRemindersListed/ServicePlansCustomerRemindersListed';
import ServicePlansSubscriptionsTable from '@/ui/components/servicePlansSubscriptionsTable/ServicePlansSubscriptionsTable';
import { useAccount } from '@/utils/storeHooks';
import { ServicePlanPermissions } from '@payaca/permissions/service-plans/service-plans.permissions';
import { ServicePlanRoles } from '@payaca/permissions/service-plans/service-plans.roles';
import { Link } from 'react-router-dom';
import ServicePlanDiscountCodesListed from '../../components/servicePlanDiscountCodesListed/ServicePlanDiscountCodesListed';
import ServicePlansListedPlans from '../../components/servicePlansListedPlans/ServicePlansListedPlans';
import ServicePlansServicePeriods from '../../components/servicePlansServicePeriods/ServicePlansServicePeriods';
import TabbedContentPageWrapper from '../pageWrappers/tabbedContentPageWrapper/TabbedContentPageWrapper';
import './ServicePlansPage.sass';

enum ViewType {
  LISTED_PLANS = 'listedPlans',
  SERVICE_PERIODS = 'servicePeriods',
  DISCOUNT_CODES = 'discountCodes',
  CUSTOMER_REMINDERS = 'customerReminders',
  SUBSCRIPTIONS = 'subscriptions',
}

const ServicePlansPage = () => {
  const translate = useTranslation();
  const history = useHistory();
  const { tab } = useParams<{ tab?: string }>();
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const account = useAccount();
  const queryString = qs.stringify(query);
  if (!tab) {
    history.replace(
      `/service-plans/listed-plans${queryString ? `?${queryString}` : ''}`
    );
  }

  const tabs = useMemo(() => {
    return [
      {
        label: ViewType.LISTED_PLANS,
        title: 'Service Plans',
        urlSlug: 'listed-plans',
        content: <ServicePlansListedPlans />,
      },
      {
        label: ViewType.SUBSCRIPTIONS,
        title: 'Subscriptions',
        urlSlug: 'subscriptions',
        content: <ServicePlansSubscriptionsTable />,
      },
      {
        label: ViewType.SERVICE_PERIODS,
        title: 'Service Periods',
        urlSlug: 'service-periods',
        content: <ServicePlansServicePeriods />,
      },
      {
        label: ViewType.DISCOUNT_CODES,
        title: 'Discount Codes',
        urlSlug: 'discount-codes',
        content: <ServicePlanDiscountCodesListed />,
      },
      {
        label: ViewType.CUSTOMER_REMINDERS,
        title: 'Customer Reminders',
        urlSlug: 'customer-reminders',
        content: <ServicePlansCustomerRemindersListed />,
      },
    ];
  }, [location.hash]);

  const activeTabLabel = tabs.find((x) => x.urlSlug === tab)?.label;

  const stripeBanner = useMemo(() => {
    if (!account.connectedToStripe) {
      return (
        <PermissionGuard
          renderIfHasPermissions={[ServicePlanPermissions.GET_SERVICE_PLANS]}
        >
          <div className="stripe-connection-banner">
            <span>
              You must be <Link to="/connections">connected to Stripe</Link> in
              order to create and sell Service Plans.
            </span>
          </div>
        </PermissionGuard>
      );
    }
  }, [account.connectedToStripe]);

  return (
    <>
      <Helmet title={translate('pages.servicePlans.title')} />

      <TabbedContentPageWrapper
        className="service-plans-page"
        pageRouteId={Routes.SERVICE_PLANS}
        requiredRoles={[ServicePlanRoles.VIEWER]}
        pageBanners={stripeBanner ? [stripeBanner] : []}
        title={
          <div className="title-bar-inner flex-grow">
            <ServicePlansHeader />
          </div>
        }
        tabbedContentConfig={{
          tabs: tabs,
          activeTabLabel: activeTabLabel,
          onChangeTab: (label: string) => {
            const newTabSlug = tabs.find((x) => x.label === label)?.urlSlug;
            if (newTabSlug) {
              history.push(`/service-plans/${newTabSlug}`);
            }
          },
        }}
      />
    </>
  );
};

export default ServicePlansPage;
