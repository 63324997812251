import {
  JobContent,
  JobLineItem,
  JobLineItemGroup,
} from '@payaca/types/jobContentTypes';
import { JobLineItemAttachment } from '@payaca/types/jobTypes';
import { FetchedEntity, UpdateResult } from '@payaca/types/storeTypes';
import { Nullable } from '@payaca/utilities/types';
import { DeclareAsyncAction } from '../types';

export interface State {
  jobContents: { [key: number]: FetchedEntity<JobContent> };
  jobLineItemGroups: { [key: number]: FetchedEntity<JobLineItemGroup> };
  jobLineItems: { [key: number]: FetchedEntity<JobLineItem> };
  jobLineItemAttachments: {
    [key: string]: FetchedEntity<JobLineItemAttachment>;
  };
  jobContentUpdateResults: { [key: number]: UpdateResult };
  jobLineItemGroupUpdateResults: { [key: number]: UpdateResult };
  jobLineItemUpdateResults: { [key: number]: UpdateResult };

  isUpdatingJobContent: boolean;

  isCreatingJobLineItemGroup: boolean;
  isUpdatingJobLineItemGroup: boolean;
  isDeletingJobLineItemGroup: boolean;
  isAddingLineItemGroupToJobContent: boolean;
  isAddingAttachmentToJobLineItem: boolean;
  isRemovingAttachmentFromJobLineItem: boolean;

  isCreatingJobLineItem: boolean;
  isUpdatingJobLineItem: boolean;
  isDeletingJobLineItem: boolean;
}

export enum ActionType {
  REQUEST_GET_JOB_CONTENTS_FOR_DEAL = 'jobContents.requestGetJobContentsForDeal',
  GET_JOB_CONTENTS_FOR_DEAL_SUCCESS = 'jobContents.getJobContentsForDealSuccess',

  REQUEST_GET_JOB_CONTENT_WITH_JOB_GROUPS_AND_JOB_LINE_ITEMS = 'jobContents.requestGetJobContentWithJobGroupsAndJobLineItems',
  GET_JOB_CONTENT_WITH_JOB_GROUPS_AND_JOB_LINE_ITEMS_SUCCESS = 'jobContents.getJobContentWithJobGroupsAndJobLineItemsSuccess',

  REQUEST_GET_JOB_CONTENT = 'jobContents.requestGetJobContent',
  GET_JOB_CONTENT_SUCCESS = 'jobContents.getJobContentSuccess',
  GET_JOB_CONTENT_FAILURE = 'jobContents.getJobContentFailure',
  CLEAR_JOB_CONTENT = 'jobContents.clearJobContent',
  CLEAR_JOB_CONTENTS = 'jobContents.clearJobContents',

  REQUEST_UPDATE_JOB_CONTENT = 'jobContents.requestUpdateJobContent',
  UPDATE_JOB_CONTENT_SUCCESS = 'jobContents.updateJobContentSuccess',
  UPDATE_JOB_CONTENT_FAILURE = 'jobContents.updateJobContentFailure',
  CLEAR_JOB_CONTENT_UPDATE_RESULTS = 'jobContents.clearJobContentUpdateResults',

  // JOB LINE ITEM ATTACHMENTS

  REQUEST_GET_JOB_LINE_ITEM_ATTACHMENTS_FOR_JOB_LINE_ITEM = 'jobContents.requestGetJobLineItemAttachmentsForJobLineItem',
  GET_JOB_LINE_ITEM_ATTACHMENTS_FOR_JOB_LINE_ITEM_SUCCESS = 'jobContents.getJobLineItemAttachmentsForJobLineItemSuccess',

  GET_JOB_LINE_ITEM_ATTACHMENT_SUCCESS = 'jobContents.getJobLineItemAttachmentSuccess',
  GET_JOB_LINE_ITEM_ATTACHMENT_FAILURE = 'jobContents.getJobLineItemAttachmentFailure',
  CLEAR_JOB_LINE_ITEM_ATTACHMENT = 'jobContents.clearJobLineItemAttachment',
  CLEAR_JOB_LINE_ITEM_ATTACHMENTS = 'jobContents.clearJobLineItemAttachments',

  REQUEST_ADD_ATTACHMENT_TO_JOB_LINE_ITEM = 'jobContents.requestAddAttachmentToJobLineItem',
  ADD_ATTACHMENT_TO_JOB_LINE_ITEM_SUCCESS = 'jobContents.addAttachmentToJobLineItemSuccess',
  ADD_ATTACHMENT_TO_JOB_LINE_ITEM_FAILURE = 'jobContents.addAttachmentToJobLineItemFailure',
  REQUEST_REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM = 'jobContents.requestRemoveAttachmentFromJobLineItem',
  REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM_SUCCESS = 'jobContents.removeAttachmentFromJobLineItemSuccess',
  REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM_FAILURE = 'jobContents.removeAttachmentFromJobLineItemFailure',

  // JOB LINE ITEM GROUPS

  REQUEST_GET_JOB_LINE_ITEM_GROUPS_FOR_JOB_CONTENT = 'jobContents.requestGetJobLineItemGroupsForJobContent',
  GET_JOB_LINE_ITEM_GROUPS_FOR_JOB_CONTENT_SUCCESS = 'jobContents.getJobLineItemGroupsForJobContentSuccess',

  GET_JOB_LINE_ITEM_GROUP_SUCCESS = 'jobContents.getJobLineItemGroupSuccess',
  GET_JOB_LINE_ITEM_GROUP_FAILURE = 'jobContents.getJobLineItemGroupFailure',
  CLEAR_JOB_LINE_ITEM_GROUP = 'jobContents.clearJobLineItemGroup',
  CLEAR_JOB_LINE_ITEM_GROUPS = 'jobContents.clearJobLineItemGroups',

  REQUEST_ADD_LINE_ITEM_GROUP_TO_JOB_CONTENT = 'jobContents.requestAddLineItemGroupToJobContent',
  ADD_LINE_ITEM_GROUP_TO_JOB_CONTENT_SUCCESS = 'jobContents.addLineItemGroupToJobContentSuccess',
  ADD_LINE_ITEM_GROUP_TO_JOB_CONTENT_FAILURE = 'jobContents.addLineItemGroupToJobContentFailure',

  REQUEST_CREATE_JOB_LINE_ITEM_GROUP = 'jobContents.requestCreateJobLineItemGroup',
  CREATE_JOB_LINE_ITEM_GROUP_SUCCESS = 'jobContents.createJobLineItemGroupSuccess',
  CREATE_JOB_LINE_ITEM_GROUP_FAILURE = 'jobContents.createJobLineItemGroupFailure',
  REQUEST_UPDATE_JOB_LINE_ITEM_GROUP = 'jobContents.requestUpdateJobLineItemGroup',
  UPDATE_JOB_LINE_ITEM_GROUP_SUCCESS = 'jobContents.updateJobLineItemGroupSuccess',
  UPDATE_JOB_LINE_ITEM_GROUP_FAILURE = 'jobContents.updateJobLineItemGroupFailure',
  REQUEST_DELETE_JOB_LINE_ITEM_GROUP = 'jobContents.requestDeleteJobLineItemGroup',
  DELETE_JOB_LINE_ITEM_GROUP_SUCCESS = 'jobContents.deleteJobLineItemGroupSuccess',
  DELETE_JOB_LINE_ITEM_GROUP_FAILURE = 'jobContents.deleteJobLineItemGroupFailure',
  CLEAR_JOB_LINE_ITEM_GROUP_UPDATE_RESULTS = 'jobContents.clearJobLineItemGroupUpdateResults',

  // JOB LINE ITEMS
  REQUEST_GET_JOB_LINE_ITEMS_FOR_DEAL = 'jobContents.requestGetJobLineItemsForDeal',
  GET_JOB_LINE_ITEMS_FOR_DEAL_SUCCESS = 'jobContents.getJobLineItemsForDealSuccess',
  REQUEST_GET_JOB_LINE_ITEMS_FOR_INVOICE = 'jobContents.requestGetJobLineItemsForInvoice',
  GET_JOB_LINE_ITEMS_FOR_INVOICE_SUCCESS = 'jobContents.getJobLineItemsForInvoiceSuccess',
  REQUEST_GET_JOB_LINE_ITEMS_FOR_JOB_CONTENT = 'jobContents.requestGetJobLineItemsForJobContent',
  GET_JOB_LINE_ITEMS_FOR_JOB_CONTENT_SUCCESS = 'jobContents.getJobLineItemsForJobContentSuccess',
  REQUEST_GET_JOB_LINE_ITEM = 'jobContents.requestGetJobLineItem',
  GET_JOB_LINE_ITEM_SUCCESS = 'jobContents.getJobLineItemSuccess',
  GET_JOB_LINE_ITEM_FAILURE = 'jobContents.getJobLineItemFailure',
  CLEAR_JOB_LINE_ITEM = 'jobContents.clearJobLineItem',
  CLEAR_JOB_LINE_ITEMS = 'jobContents.clearJobLineItems',

  CREATE_JOB_LINE_ITEM_FROM_AMENDMENT_PARENT_REQUEST = 'jobContents.requestCreateJobLineItemFromAmendmentParent:request',
  REQUEST_CREATE_JOB_LINE_ITEM = 'jobContents.requestCreateJobLineItem',
  CREATE_JOB_LINE_ITEM_SUCCESS = 'jobContents.createJobLineItemSuccess',
  CREATE_JOB_LINE_ITEM_FAILURE = 'jobContents.createJobLineItemFailure',
  REQUEST_UPDATE_JOB_LINE_ITEM = 'jobContents.requestUpdateJobLineItem',
  UPDATE_JOB_LINE_ITEM_SUCCESS = 'jobContents.updateJobLineItemSuccess',
  UPDATE_JOB_LINE_ITEM_FAILURE = 'jobContents.updateJobLineItemFailure',
  REQUEST_DELETE_JOB_LINE_ITEM = 'jobContents.requestDeleteJobLineItem',
  DELETE_JOB_LINE_ITEM_SUCCESS = 'jobContents.deleteJobLineItemSuccess',
  DELETE_JOB_LINE_ITEM_FAILURE = 'jobContents.deleteJobLineItemFailure',
  REQUEST_UPDATE_JOB_LINE_ITEM_FROM_LINE_ITEM = 'jobContents.requestUpdateJobLineItemFromLineItem',
  CLEAR_JOB_LINE_ITEM_UPDATE_RESULTS = 'jobContents.clearJobLineItemUpdateResults',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
  storage: {
    getItem: (key: string) => Promise<string>;
    setItem: (key: string, value: string) => Promise<void>;
  };
  storageKeys?: {
    vatAmount: string;
    vatIncluded: string;
    isReverseChargeVat: string;
    taxRateId: string;
  };
}

export interface UpdateJobLineItemGroupRequestData {
  jobLineItemGroupId: number;
  description?: string;
  positionIndex?: number;
}

export interface CreateJobLineItemGroupRequestData {
  jobContentId: number;
}

export interface UpdateJobLineItemRequestData {
  jobLineItemId: number;
  description?: string;
  reference?: string;
  isMultipleChoice: boolean;
  isOptional: boolean;
  isSelected: boolean;
  quantity: number;
  price: number;
  taxRateId?: number;
  cisApplies: boolean;
  lineItemId: number;
  updateLineItem: boolean;
  positionIndex?: number;
  discountPercentage?: Nullable<number>;
  discountDescription?: Nullable<string>;
}

export interface CreateJobLineItemRequestData {
  jobContentId?: number;
  jobLineItemGroupId?: number;
  changeProposalId?: number;
  lineItemId?: number | null;
  description?: string;
}

export interface CreateJobLineItemFromAmendmentParentRequestData {
  amendmentParentJobLineItemId: number;
  changeProposalId: number;
  quantity: number;
}

export type CreateJobLineItemFromAmendmentParent = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_JOB_LINE_ITEM_FROM_AMENDMENT_PARENT_REQUEST;
      payload: {
        data: CreateJobLineItemFromAmendmentParentRequestData;
        callback?: (jobLineItemId: number) => void;
      };
    };
    success: { type: ActionType.CREATE_JOB_LINE_ITEM_SUCCESS };
    failure: { type: ActionType.CREATE_JOB_LINE_ITEM_FAILURE };
  }
>;
