import { FC } from 'react';

import CompanyLogo from '../companyLogo/CompanyLogo';
import './FormTemplateElements.sass';
import './SalvumTemplate.sass';

interface Props {
  data: any;
  account: any;
}

const SalvumFireRiskDesktopStudyTemplate: FC<Props> = ({ data, account }) => {
  if (!data || !account) return <></>;
  return (
    <div className={'form-template-output salvum'}>
      <div className={'form-template-header'}>
        <div>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Fire Risk - Desktop Study</h3>
        </div>
        <div>
          <span>Doc No</span>: {data.certificate_number}
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <div
          className={'form-template-bubble'}
          style={{ borderColor: account?.brandColour }}
        >
          <ul>
            <li>
              <span>Client:</span> {data.customer_name}
            </li>
            <li>
              <span>Client Address:</span> {data.customer_address}
            </li>
            <li>
              <span>Client E-mail:</span> {data.customer_email}
            </li>
            <li>
              <span>Client Telephone:</span> {data.customer_telephone}
            </li>
            <li>
              <span>Main Contact:</span> {data.contact_name}
            </li>
          </ul>
        </div>
        <div
          className={'form-template-bubble'}
          style={{ borderColor: account?.brandColour }}
        >
          <ul>
            <li>
              <span>Site Address:</span> {data.site_address}
            </li>
            <li>
              <span>Work Type:</span> {data.work_type}
            </li>
            <li>
              <span>Details of Work:</span> {data.work_details}
            </li>
            <li>
              <span>Price:</span> {data.work_price}
            </li>
          </ul>
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <div
          className={'form-template-bubble'}
          style={{ borderColor: account?.brandColour }}
        >
          <ul>
            <li>
              <span>Scope of Works:</span> {data.scope_of_works}
            </li>
            <li>
              <span>Exclusions:</span> {data.exclusions}
            </li>
            <li>
              <span>Notes, site and safety arrangements:</span>{' '}
              {data.notes_and_safety_arrangements}
            </li>
            <li>
              <span>Survey Type:</span> {data.survey_type}
            </li>
            <li>
              <span>Survey Scope:</span> {data.survey_scope}
            </li>
            <li>
              <span>Building Type (Residential):</span>{' '}
              {data.building_type_residential}
            </li>
            <li>
              <span>Number of Bedrooms (Residential only)</span>{' '}
              {data.num_bedrooms}
            </li>
            <li>
              <span>Building Type (Commercial)</span>{' '}
              {data.building_type_commercial}
            </li>
            <li>
              <span>Building Status</span> {data.building_status}
            </li>
            <li>
              <span>Assessment Type</span> {data.assessment_type}
            </li>
            <li>
              <span>Primary Statutory Requirement</span>{' '}
              {data.primary_statutory_requirement}
            </li>
          </ul>
        </div>{' '}
        <div
          className={'form-template-bubble'}
          style={{ borderColor: account?.brandColour }}
        >
          <ul>
            <li>
              <span>Officer Inspection:</span> {data.officer_inspection}
            </li>
            <li>
              <span>Size of building (Approx Sqm):</span>{' '}
              {data.size_of_building_sqm}
            </li>
            <li>
              <span>Number of floors to be assessed:</span> {data.num_floors}
            </li>
            <li>
              <span>Age of Building (Approx):</span> {data.building_age}
            </li>
            <li>
              <span>Approximate number of Occupants:</span> {data.num_occupants}
            </li>
            <li>
              <span>Pre-Assessment Information:</span>{' '}
              {data.pre_assessment_information}
            </li>
            <li>
              <span>Required Resource:</span> {data.required_resource}
            </li>
            <li>
              <span>Site visit required:</span> {data.site_visit_required}
            </li>
            <li>
              <span>Terms of Business:</span> {data.terms_of_business}
            </li>
            <li>
              <span>Assessment Exclusions:</span> {data.assessment_exclusions}
            </li>
            <li>
              <span>Online Chat Reference Number:</span>{' '}
              {data.online_chat_reference_num}
            </li>
            <li>
              <span>Purchase Order Number:</span> {data.purchase_order_number}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SalvumFireRiskDesktopStudyTemplate;
