import { FC } from 'react';

import DashboardActionRequiredItem from '../dashboardActionRequiredItem/DashboardActionRequiredItem';
import './DashboardJobActionRequiredItem.sass';

interface Props {
  isFlagged?: boolean;
  tooltipContent?: string;
  reference: string;
  customerName?: string;
  value?: string;
  onClick?: () => void;
}

const DashboardJobActionRequiredItem: FC<Props> = ({
  isFlagged,
  tooltipContent,
  reference,
  customerName,
  value,
  onClick,
}: Props) => {
  return (
    <DashboardActionRequiredItem
      isFlagged={isFlagged}
      tooltipContent={tooltipContent}
      onClick={onClick}
      className="dashboard-job-action-required-item"
      content={
        <>
          <small className="reference">{reference}</small>
          <span className="customer-name">{customerName || '-'}</span>
          <span className="value">{value}</span>
        </>
      }
    />
  );
};

export default DashboardJobActionRequiredItem;
