import { currencyPrice, formatPercentage } from '@payaca/helpers/financeHelper';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import { InvoiceLineWithJobLineItem } from '@payaca/types/invoiceTypes';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import { isNullish } from '@payaca/utilities/guards';
import { FC, useMemo } from 'react';
import MarkdownLabel from '../markdownLabel/MarkdownLabel';

const vatFormatter = new Intl.NumberFormat('en-GB');

export interface IProps {
  item: JobLineItem;
  invoiceLine?: InvoiceLineWithJobLineItem;
  region?: AccountRegions;
  hideItemPrices?: boolean;
  hideVatBreakdown?: boolean;
}

const InvoiceItem: FC<IProps> = (props) => {
  const {
    item,
    invoiceLine,
    region,
    hideItemPrices = false,
    hideVatBreakdown = false,
  } = props;
  const cisApplies = !isNullish(item.cisDeductionRate);
  const itemPriceIncVat =
    item.priceIncMarkup + (item.priceIncMarkup * item.vatAmount) / 100;

  const vatRegionalLabel = useMemo(
    () => getRegionalTextString(region, RegionalStrings.VALUE_ADDED_TAX),
    [region]
  );

  const duePercentage = invoiceLine?.jobLineItemPercentage;

  return (
    <div className="border-pc-job-brand break-inside-avoid border-b-2 print:mb-8">
      <div className="mb-6">
        <MarkdownLabel
          className="markdown-font-size-reset"
          markdown={item.description}
        />

        {!!item.discountAmount && item.discountDescription && (
          <p className="text-base">
            Discount description: {item.discountDescription}
          </p>
        )}
      </div>

      <div className="mb-5 flex flex-wrap justify-end gap-x-6 gap-y-2 text-right">
        {!hideItemPrices && (
          <div>
            <h5 className="mb-1 text-base font-semibold">Unit price</h5>
            <p className="m-0 text-base">
              {hideVatBreakdown
                ? currencyPrice(itemPriceIncVat, region)
                : item && currencyPrice(item.priceIncMarkup, region)}
            </p>
          </div>
        )}

        <div className="print:ml-4">
          <h5 className="mb-1 text-base font-semibold">Qty</h5>
          <p className="m-0 text-base">{item && item.quantity}</p>
        </div>

        {item.discountPercentage && (
          <div className="print:ml-4">
            <h5 className="mb-1 text-base font-semibold">Discount</h5>
            <p className="m-0 text-base">
              {vatFormatter.format(item.discountPercentage)}%
            </p>
          </div>
        )}

        {!hideItemPrices && !hideVatBreakdown && (
          <div className="print:ml-4">
            <h5 className="mb-1 text-base font-semibold">{vatRegionalLabel}</h5>
            <p className="m-0 text-base">
              {item &&
                (item.vatIncluded
                  ? `${vatFormatter.format(item.vatAmount)}%${
                      item.isReverseChargeVat ? ' (RC)' : ''
                    }`
                  : `No ${vatRegionalLabel}`)}
            </p>
          </div>
        )}

        {cisApplies && item.cisTotal && (
          <div className="print:ml-4">
            <h5 className="mb-1 text-base font-semibold">% CIS suffered</h5>
            <p className="m-0 text-base">{item.cisDeductionRate}%</p>
          </div>
        )}

        {!hideItemPrices && (
          <div className="print:ml-4">
            <h5 className="mb-1 text-base font-semibold">Total</h5>
            <p className="m-0 text-base">
              {item && currencyPrice(item.total, region)}
            </p>
          </div>
        )}

        {duePercentage && (
          <div className="print:ml-4">
            <h5 className="mb-1 text-base font-semibold">% Due</h5>
            <p className="m-0 text-base">{formatPercentage(duePercentage)}%</p>
          </div>
        )}

        {!hideItemPrices && invoiceLine && (
          <div className="print:ml-4">
            <h5 className="mb-1 text-base font-semibold">Due</h5>
            <p className="m-0 text-base">
              {currencyPrice(invoiceLine.value, region)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceItem;
