import FileDropZoneModal from '@payaca/components/plFileDropZone/FileDropZoneModal';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import { requestPersistUploadAndLinkToEntity } from '@payaca/store/uploads/uploadsActions';
import { Document } from '@payaca/types/documentTypes';
import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

const acceptFileTypes = getAcceptedFileTypes([
  'document',
  'image',
  'video',
  'other',
]);
export const getDocumentThumbnailUrl = (dealDocument: Document) => {
  switch (true) {
    case dealDocument?.bucketKey?.endsWith('.png'):
    case dealDocument?.bucketKey?.endsWith('.jpg'):
    case dealDocument?.bucketKey?.endsWith('.jpeg'):
    case dealDocument?.bucketKey?.endsWith('.gif'):
    case dealDocument?.bucketKey?.endsWith('.bmp'):
    case dealDocument?.bucketKey?.endsWith('.heic'):
    case dealDocument?.bucketKey?.endsWith('.webp'):
      return `${
        import.meta.env.VITE_DOCUMENT_UPLOAD_STORAGE_BUCKET_URL
      }/thumbnail_${dealDocument.bucketKey}`;
    default:
      return;
  }
};

interface Props {
  dealId: number;
  isOpen: boolean;
  onClose: () => void;
  onUploadSuccess: () => void;
}

export const DealFileUploadModal: FC<Props> = ({
  dealId,
  isOpen,
  onClose,
  onUploadSuccess,
}) => {
  const dispatch = useDispatch();

  const [showUploadError, setShowUploadError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const onUploadFile = useCallback(
    async (files: File[]) => {
      setShowUploadError(false);
      setIsLoading(true);

      const uploadedFilePromises = await Promise.allSettled(
        files.map(
          (file) =>
            new Promise<void>((resolve, reject) => {
              dispatch(
                requestPersistUploadAndLinkToEntity(
                  {
                    file,
                    fileName: file.name,
                    entityId: dealId,
                    entityType: 'deal',
                  },
                  (error) => {
                    if (error) {
                      reject();
                    } else {
                      resolve();
                    }
                  }
                )
              );
            })
        )
      );

      if (uploadedFilePromises.find((p) => p.status === 'rejected')) {
        setShowUploadError(true);
      }

      if (uploadedFilePromises.find((p) => p.status === 'fulfilled')) {
        onUploadSuccess();
      }

      setIsLoading(false);
    },
    [dispatch, onUploadSuccess]
  );

  return (
    <FileDropZoneModal
      isLoading={isLoading}
      hasError={showUploadError}
      isOpen={isOpen}
      onClose={onClose}
      dropzoneOptions={{
        onDrop: onUploadFile,
        accept: acceptFileTypes.join(','),
      }}
    />
  );
};
