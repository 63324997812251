import { UserAssignmentEntityType } from '@payaca/types/userAssignmentTypes';
import { DeclareAsyncAction } from '../types';

export interface State {}

export enum ActionType {
  ASSIGN_USER_REQUEST = 'userAssignments.assignUser:request',
  ASSIGN_USER_SUCCESS = 'userAssignments.assignUser:success',
  ASSIGN_USER_FAILURE = 'userAssignments.assignUser:failure',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}

export type AssignUserAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.ASSIGN_USER_REQUEST;
      payload: {
        entityId: number;
        entityType: UserAssignmentEntityType;
        assigneeUserId: number | null;
        callback?: (err?: Error) => void;
      };
    };
    success: {
      type: ActionType.ASSIGN_USER_SUCCESS;
    };
    failure: {
      type: ActionType.ASSIGN_USER_FAILURE;
    };
  }
>;
