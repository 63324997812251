import { isEqual } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import { UpdateJobLineItemRequestData } from '@payaca/store/jobContent/jobContentTypes';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { JobLineItem, JobLineItemBase } from '@payaca/types/jobContentTypes';

import Checkbox from '@payaca/components/checkbox/Checkbox';
import RadioButton from '@payaca/components/radioButton/RadioButton';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import JobLineItemCard from '../jobLineItemCard/JobLineItemCard';

import { getJobLineItem } from '../../../utils/stateAccessors';
import {
  getRequiredSettingsType,
  RequiredSettingsType,
} from '../requiredSettingsDropdown/RequiredSettingsDropdown';

import { useSelector } from '@/api/state';

import './JobLineItemControl.sass';

const getFormStateFromJobLineItem = (jobLineItem?: JobLineItemBase) => {
  if (!jobLineItem) return {};

  return {
    jobLineItemId: jobLineItem.id,
    description: jobLineItem.description,
    reference: jobLineItem.name,
    isMultipleChoice: jobLineItem.isMultipleChoice,
    isOptional: jobLineItem.isOptional,
    isSelected: jobLineItem.isSelected,
    quantity: jobLineItem.quantity,
    price: jobLineItem.price,
    vatAmount: jobLineItem.vatAmount,
    vatIncluded: jobLineItem.vatIncluded,
    isReverseChargeVat: jobLineItem.isReverseChargeVat,
    taxRateId: jobLineItem.taxRateId,
    cisApplies: !!jobLineItem.cisDeductionRate,
    lineItemId: jobLineItem.lineItemId,
    discountPercentage: jobLineItem.discountPercentage,
    discountDescription: jobLineItem.discountDescription,
  };
};

type Props = {
  jobLineItemId: number;
  handleOpenCreateJobLineItemModal?: (
    jobLineItemId: number,
    initialItemData?: Partial<JobLineItemBase>
  ) => void;
};

const JobLineItemControl: FC<Props> = ({
  jobLineItemId,
  handleOpenCreateJobLineItemModal,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const jobLineItem: JobLineItem | undefined = useSelector((state) =>
    getJobLineItem(state, jobLineItemId)
  );

  useEffect(() => {
    if (jobLineItemId) {
      dispatch(
        jobContentActions.requestGetJobLineItemAttachmentsForJobLineItem(
          jobLineItemId
        )
      );
    }
  }, [jobLineItemId]);

  const initialFormState = useMemo(() => {
    return getFormStateFromJobLineItem(jobLineItem);
  }, [jobLineItem]);

  const onJobLineItemUpdateSuccess = useCallback(() => {
    if (!jobLineItem?.jobContentId) return;
    dispatch(
      jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
        jobLineItem.jobContentId
      )
    );
  }, [jobLineItem?.jobContentId]);

  const isUpdatingJobLineItem: boolean = useSelector((state) => {
    return state.jobContent.isUpdatingJobLineItem;
  });

  const isFetchingJobLineItem: boolean = useSelector((state) => {
    return (
      state.jobContent.jobLineItems &&
      state.jobContent.jobLineItems[jobLineItemId]?.isFetching
    );
  });

  const requiresUpdateJobLineItem = useCallback(
    (formState: { [key: string]: any }) => {
      if (isFetchingJobLineItem || isUpdatingJobLineItem) return true;
      return !isEqual(formState, getFormStateFromJobLineItem(jobLineItem));
    },
    [isFetchingJobLineItem, isUpdatingJobLineItem, jobLineItem]
  );

  const updateJobLineItem = useCallback(
    (formState: { [key: string]: any }) => {
      const updateJobLineItemRequestData =
        formState as UpdateJobLineItemRequestData;
      if (!updateJobLineItemRequestData.jobLineItemId) return;
      if (requiresUpdateJobLineItem(formState)) {
        dispatch(
          jobContentActions.requestUpdateJobLineItem(
            updateJobLineItemRequestData,
            onJobLineItemUpdateSuccess
          )
        );
      }
    },
    [onJobLineItemUpdateSuccess, requiresUpdateJobLineItem]
  );

  const renderIsSelectedContainer = useCallback(
    (
      formState: {
        [key: string]: any;
      },
      onFieldChange: (value: { [key: string]: any }) => void
    ) => {
      const requiredSettingsType = getRequiredSettingsType(formState);

      return (
        requiredSettingsType !== RequiredSettingsType.REQUIRED && (
          <div className="is-selected-container">
            {requiredSettingsType === RequiredSettingsType.OPTIONAL && (
              <Checkbox
                isChecked={formState.isSelected}
                onChange={() => {
                  const updateItem = {
                    isSelected: !formState.isSelected,
                  };
                  onFieldChange(updateItem);
                  updateJobLineItem({ ...formState, ...updateItem });
                }}
              />
            )}
            {requiredSettingsType === RequiredSettingsType.MULTIPLE_CHOICE && (
              <RadioButton
                isSelected={formState.isSelected}
                onClick={() => {
                  if (formState.isSelected) return;
                  const updateItem = {
                    isSelected: !formState.isSelected,
                  };
                  onFieldChange(updateItem);
                  updateJobLineItem({ ...formState, ...updateItem });
                }}
              />
            )}
          </div>
        )
      );
    },
    [updateJobLineItem]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      const requiredSettingsType = getRequiredSettingsType(formState);

      if (requiredSettingsType === RequiredSettingsType.REQUIRED) {
        return <></>;
      }
      return (
        <div className="is-selected-container">
          {requiredSettingsType === RequiredSettingsType.OPTIONAL && (
            <Checkbox
              isChecked={formState.isSelected}
              onChange={() => {
                const updateItem = {
                  isSelected: !formState.isSelected,
                };
                onFieldChange(updateItem);
                updateJobLineItem({ ...formState, ...updateItem });
              }}
            />
          )}
          {requiredSettingsType === RequiredSettingsType.MULTIPLE_CHOICE && (
            <RadioButton
              isSelected={formState.isSelected}
              onClick={() => {
                if (formState.isSelected) return;
                const updateItem = {
                  isSelected: !formState.isSelected,
                };
                onFieldChange(updateItem);
                updateJobLineItem({ ...formState, ...updateItem });
              }}
            />
          )}
        </div>
      );
    },
    [renderIsSelectedContainer]
  );

  return (
    <ResponsiveViewWrapper
      className="job-line-item-control"
      downBreakpointSm={700}
      downBreakpointXs={400}
    >
      <>
        <ValidatedForm<{ [key: string]: any }>
          initialFormState={initialFormState}
          renderFormContents={renderFormContents}
        />
        {jobLineItem && (
          <JobLineItemCard
            jobLineItem={jobLineItem}
            onClick={
              handleOpenCreateJobLineItemModal
                ? () => handleOpenCreateJobLineItemModal(jobLineItemId)
                : undefined
            }
          />
        )}
      </>
    </ResponsiveViewWrapper>
  );
};
export default JobLineItemControl;
