import { BadgeColourVariant } from '@payaca/types/plBadge';
import { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import tinycolor from 'tinycolor2';

type BadgeStandardProps<T> = {
  className?: string;
  rounded?: boolean;
  size?: 'sm' | 'md';
} & T;

type WhiteBadgeProps = BadgeStandardProps<{
  variant: 'white';
}>;

export type Props =
  | BadgeStandardProps<{
      colour?: BadgeColourVariant;
      variant?: 'solid' | 'soft' | 'outline';
    }>
  | BadgeStandardProps<{
      colour: string;
      variant: 'custom';
    }>
  | WhiteBadgeProps;

const useBadgeStyles = (args: { rounded?: boolean; size?: 'sm' | 'md' }) => {
  const { rounded = false, size } = args;

  const styleClasses = useMemo(() => {
    switch (rounded) {
      case true:
        return 'rounded-lg';
      default:
        return 'rounded-full';
    }
  }, [rounded]);

  const sizeClasses = useMemo(() => {
    switch (size) {
      case 'sm':
        return 'py-[2px] px-2 gap-1';
      case 'md':
        return 'py-1.5 px-3 gap-1.5';
    }
  }, [size]);

  return styleClasses + ' ' + sizeClasses;
};

const WhiteBadge: FunctionComponent<PropsWithChildren<WhiteBadgeProps>> = (
  props
) => {
  const { className, rounded = false, size = 'md', children } = props;

  const styleClasses = useBadgeStyles({
    rounded,
    size,
  });

  return (
    <span
      className={`${
        className || ''
      } inline-flex items-center text-sm font-medium ${styleClasses} border border-gray-200 bg-white text-gray-800 shadow-sm`}
    >
      {children}
    </span>
  );
};

const Badge: FunctionComponent<PropsWithChildren<Props>> = (props) => {
  if (props.variant === 'white') {
    return <WhiteBadge {...props} />;
  }

  const {
    className,
    rounded = false,
    variant = 'solid',
    colour = 'gray',
    size = 'md',
    children,
  } = props;

  const variantStyles = useMemo(() => {
    if (colour === 'job-brand') {
      switch (variant) {
        case 'solid':
          return `bg-pc-job-brand text-pc-readable-text-job-brand`;
        case 'soft':
          return `bg-pc-lighter-job-brand text-pc-readable-text-job-brand`;
        case 'outline':
          return `border border-pc-job-brand text-pc-job-brand`;
      }
    }

    if (colour === 'job-brand-white') {
      return `bg-white text-pc-job-brand`;
    }

    switch (variant) {
      case 'solid':
        switch (colour) {
          case 'black':
            return `bg-gray-800 text-white`;
          case 'gray':
            return `bg-gray-500 text-white`;
          case 'teal':
            return `bg-teal-500 text-white`;
          case 'blue':
            return `bg-blue-600 text-white`;
          case 'red':
            return `bg-red-500 text-white`;
          case 'yellow':
            return `bg-yellow-500 text-white`;
          case 'white':
            return 'bg-white text-gray-600';
          case 'purple':
            return 'bg-purple-500 text-white';
          default:
            return '';
        }
      case 'soft':
        switch (colour) {
          case 'black':
            return `bg-gray-100 text-gray-800`;
          case 'gray':
            return `bg-gray-50 text-gray-800`;
          case 'teal':
            return `bg-teal-100 text-teal-800`;
          case 'blue':
            return `bg-blue-100 text-blue-800`;
          case 'red':
            return `bg-red-100 text-red-800`;
          case 'yellow':
            return `bg-yellow-100 text-yellow-800`;
          case 'white':
            return 'bg-white/10 text-white';
          case 'purple':
            return 'bg-purple-500 text-white';
          case 'orange':
            return 'bg-orange-100 text-orange-800';
          case 'green':
            return 'bg-green-100 text-green-800';
          default:
            return '';
        }
      case 'outline':
        switch (colour) {
          case 'black':
            return `border border-gray-800 text-gray-800`;
          case 'gray':
            return `border border-gray-500 text-gray-500`;
          case 'teal':
            return `border border-teal-500 text-teal-500`;
          case 'blue':
            return `border border-blue-500 text-blue-600`;
          case 'red':
            return `border border-red-500 text-red-500`;
          case 'yellow':
            return `border border-yellow-500 text-yellow-500`;
          case 'white':
            return 'border border-white text-white';
          case 'purple':
            return 'border border-purple-500 text-white';
          default:
            return ``;
        }
      case 'custom':
        return '';
    }
  }, [colour, variant]);

  const styleClasses = useBadgeStyles({
    rounded,
    size,
  });

  const readableTextColour = useMemo(() => {
    if (variant === 'custom') {
      return tinycolor
        .mostReadable(colour || 'black', ['#263e59', 'white'], {
          includeFallbackColors: true,
        })
        .toHexString();
    }
  }, [variant, colour]);

  return (
    <span
      className={`${
        className || ''
      } inline-flex items-center text-sm font-medium ${styleClasses} ${variantStyles}`}
      style={
        variant === 'custom'
          ? { backgroundColor: colour, color: readableTextColour }
          : {}
      }
    >
      {children}
    </span>
  );
};

export default Badge;
