import {
  init,
  reactRouterV5BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';
import history from '../services/history';

if (import.meta.env.VITE_SENTRY_CLIENT_ID) {
  init({
    dsn: import.meta.env.VITE_SENTRY_CLIENT_ID,
    environment: import.meta.env.VITE_ENV || import.meta.env.VITE_APP_ENV,
    ignoreErrors: [
      /t.getElementsByTagName/, // ???
      /Cannot read properties of null (reading 'querySelectorAll')/, // trello-smooth-dnd
      /Failed to execute 'removeChild' on 'Node'/, // react-draggable
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    maxValueLength: 1_000, // Avoid truncating really long error messages
    integrations: [
      reactRouterV5BrowserTracingIntegration({ history }),
      replayIntegration({}),
    ],
  });
}
