import { action } from 'typesafe-actions';

import { Document } from '@payaca/types/documentTypes';

import { createAsyncAction } from '../utils';
import {
  ActionType,
  CreateFormInstanceForDocument,
  GetDocumentPrefillPreferences,
  SetDocumentPrefillPreferences,
  UploadDocumentsV2,
} from './documentTypes';

export const requestGetDocumentsForDeal = (dealId: number) =>
  action(ActionType.REQUEST_GET_DOCUMENTS_FOR_DEAL, { dealId });

export const getDocumentsForDealSuccess = (documents: Array<Document>) =>
  action(ActionType.GET_DOCUMENTS_FOR_DEAL_SUCCESS, documents);

export const getDocumentsForDealFailure = (error: Error) =>
  action(ActionType.GET_DOCUMENTS_FOR_DEAL_FAILURE, null, null, error);

export const requestGetDocument = (documentId: number) =>
  action(ActionType.REQUEST_GET_DOCUMENT, {
    documentId,
  });

export const requestGetDocumentForTask = (
  taskId: number,
  callback?: (document: Document) => void
) =>
  action(ActionType.REQUEST_GET_DOCUMENT_FOR_TASK, {
    taskId,
    callback,
  });

export const requestGetDocumentPreview = (
  documentId: number,
  previewToken: number
) =>
  action(ActionType.REQUEST_GET_DOCUMENT_PREVIEW, {
    documentId,
    previewToken,
  });

export const getDocumentSuccess = (documentId: number, document: Document) =>
  action(ActionType.GET_DOCUMENT_SUCCESS, {
    documentId,
    document,
  });

export const getDocumentFailure = (documentId: number, error: Error) =>
  action(ActionType.GET_DOCUMENT_FAILURE, { documentId }, null, error);

export const requestUploadDocument = (
  dealId: number,
  upload: { file: File; fileName: string },
  callback?: (errorMessage: string | null) => void
) =>
  action(ActionType.REQUEST_UPLOAD_DOCUMENT, {
    dealId,
    upload,
    callback,
  });

export const uploadDocumentSuccess = (documentId: number) =>
  action(ActionType.UPLOAD_DOCUMENT_SUCCESS, {
    documentId,
  });

export const uploadDocumentFailure = (error: Error) =>
  action(ActionType.UPLOAD_DOCUMENT_FAILURE, null, error);

export const requestRenameDocument = (
  documentId: number,
  newTitle: string,
  callback?: (error?: string) => void
) =>
  action(ActionType.REQUEST_RENAME_DOCUMENT, {
    documentId,
    newTitle,
    callback,
  });

export const renameDocumentSuccess = (document: Document) =>
  action(ActionType.RENAME_DOCUMENT_SUCCESS, {
    documentId: document.id,
    document,
  });

export const renameDocumentFailure = (error: Error) =>
  action(ActionType.RENAME_DOCUMENT_FAILURE, null, error);

export const requestRemoveDocument = (documentId: number, callback?: any) =>
  action(ActionType.REQUEST_REMOVE_DOCUMENT, {
    documentId,
    callback,
  });

export const removeDocumentSuccess = () =>
  action(ActionType.REMOVE_DOCUMENT_SUCCESS);

export const removeDocumentFailure = (error: Error) =>
  action(ActionType.REMOVE_DOCUMENT_FAILURE, null, error);

export const requestCreateDocument = (
  formId: number,
  dealId: number,
  willBeSent: boolean,
  onDocumentCreated?: any
) =>
  action(ActionType.REQUEST_CREATE_DOCUMENT, {
    formId,
    dealId,
    willBeSent,
    onDocumentCreated,
  });

export const createDocumentSuccess = (formInstance: any) =>
  action(ActionType.CREATE_DOCUMENT_SUCCESS, {
    formInstance,
  });

export const createDocumentFailure = (error: Error) =>
  action(ActionType.CREATE_DOCUMENT_FAILURE, null, error);

export const requestSendDocument = (
  documentId: number,
  sendToEmail: string,
  emailBody: string,
  sendACopy: boolean,
  callback?: any
) =>
  action(ActionType.REQUEST_SEND_DOCUMENT, {
    documentId,
    emailBody,
    sendToEmail,
    sendACopy,
    callback,
  });

export const sendDocumentSuccess = () =>
  action(ActionType.SEND_DOCUMENT_SUCCESS);

export const sendDocumentFailure = (error: Error) =>
  action(ActionType.SEND_DOCUMENT_FAILURE, null, null, error);

export const requestCreateDocumentLink = (
  dealId: number,
  title: string,
  url: string,
  callback: (resp: any) => void
) =>
  action(ActionType.REQUEST_CREATE_DOCUMENT_LINK, {
    dealId,
    title,
    url,
    callback,
  });

export const createDocumentLinkSuccess = (documentId: number) =>
  action(ActionType.CREATE_DOCUMENT_LINK_SUCCESS, {
    documentId,
  });

export const createDocumentLinkFailure = (error: Error) =>
  action(ActionType.CREATE_DOCUMENT_LINK_FAILURE, null, error);

export const requestEditDocument = (
  documentId: number,
  callback: (err: string | null, resp?: any) => void
) => action(ActionType.REQUEST_EDIT_DOCUMENT, { documentId, callback });

export const editDocumentSuccess = () =>
  action(ActionType.EDIT_DOCUMENT_SUCCESS, {});

export const editDocumentFailure = (error: Error) =>
  action(ActionType.EDIT_DOCUMENT_FAILURE, null, error);

export const clearDocuments = () => action(ActionType.CLEAR_DOCUMENTS);

export const uploadDocumentsV2 = createAsyncAction<UploadDocumentsV2>({
  request: ActionType.UPLOAD_DOCUMENTS_V2_REQUEST,
  success: ActionType.UPLOAD_DOCUMENTS_V2_SUCCESS,
  failure: ActionType.UPLOAD_DOCUMENTS_V2_FAILURE,
})((payload: UploadDocumentsV2['request']['payload']) => ({ payload }));

export const createFormInstanceForDocument =
  createAsyncAction<CreateFormInstanceForDocument>({
    request: ActionType.CREATE_FORM_INSTANCE_FOR_DOCUMENT_REQUEST,
    success: ActionType.CREATE_FORM_INSTANCE_FOR_DOCUMENT_SUCCESS,
    failure: ActionType.CREATE_FORM_INSTANCE_FOR_DOCUMENT_FAILURE,
  })((payload: CreateFormInstanceForDocument['request']['payload']) => ({
    payload,
  }));

export const getDocumentPrefillPreferences =
  createAsyncAction<GetDocumentPrefillPreferences>({
    request: ActionType.GET_DOCUMENT_PREFILL_PREFERENCES_REQUEST,
  })((payload: GetDocumentPrefillPreferences['request']['payload']) => ({
    payload,
  }));

export const setDocumentPrefillPreferences =
  createAsyncAction<SetDocumentPrefillPreferences>({
    request: ActionType.SET_DOCUMENT_PREFILL_PREFERENCES_REQUEST,
  })((payload: SetDocumentPrefillPreferences['request']['payload']) => ({
    payload,
  }));
