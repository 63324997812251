import {
  all,
  call,
  delay,
  put,
  race,
  take,
  takeEvery,
} from 'redux-saga/effects';

import {
  getTaxRateIdLocalStorageKey,
  getVatAmountLocalStorageKey,
} from '@/helpers/localStorageKeyHelper';
import { refreshAuthToken } from '@payaca/store/auth/refreshAuthToken';
import * as api from '../services/api';
import { ActionType } from './jobsTypes';

const initialState = {
  currentLineItem: {},
  currentGroupIndex: null,
  currentItemIndex: null,
  jobs: [],
  currentJob: {},
  currentJobLineItemGroups: [],
  sendInvoiceToCustomerError: '',
};
const vatAmountLocalStorageKey = getVatAmountLocalStorageKey();

const taxRateIdLocalStorageKey = getTaxRateIdLocalStorageKey();

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.CLEAR_JOBS: {
      return { ...initialState };
    }

    case ActionType.CLEAR_JOB: {
      return { ...state, currentJob: {}, currentJobLineItemGroups: [] };
    }

    case ActionType.STORE_JOB_LINE_ITEM_GROUPS: {
      return {
        ...state,
        currentJobLineItemGroups: action.jobLineItemGroups,
      };
    }

    case ActionType.EDIT_CURRENT_JOB: {
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          ...action.updatedJob,
        },
      };
    }

    case ActionType.STORE_CURRENT_LINE_ITEM: {
      return {
        ...state,
        currentItemIndex: action.itemIndex,
        currentGroupIndex: action.groupIndex,
        currentLineItem: action.lineItem,
      };
    }

    case ActionType.UPDATE_CURRENT_LINE_ITEM: {
      return {
        ...state,
        currentLineItem: {
          ...state.currentLineItem,
          ...action.lineItem,
        },
      };
    }

    case ActionType.CLEAR_CURRENT_LINE_ITEM: {
      return {
        ...state,
        currentItemIndex: null,
        currentGroupIndex: null,
        currentLineItem: null,
      };
    }

    // update description on item group (also updates first item in group with isSelected)
    case ActionType.UPDATE_ITEM_GROUP: {
      if (action.groupIndex !== null) {
        const itemGroup = state.currentJobLineItemGroups[action.groupIndex];
        return {
          ...state,
          currentJobLineItemGroups: state.currentJobLineItemGroups
            .slice(0, action.groupIndex)
            .concat([
              {
                ...itemGroup,
                ...action.payload,
              },
            ])
            .concat(
              state.currentJobLineItemGroups.slice(action.groupIndex + 1)
            ),
        };
      }
      return state;
    }

    case ActionType.REMOVE_ITEM_GROUP: {
      if (action.groupIndex !== null) {
        return {
          ...state,
          currentJobLineItemGroups: state.currentJobLineItemGroups
            .slice(0, action.groupIndex)
            .concat(
              state.currentJobLineItemGroups.slice(action.groupIndex + 1)
            ),
        };
      }
      return state;
    }

    case ActionType.UPDATE_ITEM: {
      if (action.itemIndex !== null && action.groupIndex !== null) {
        // update/add item to item group
        const itemGroup = state.currentJobLineItemGroups[action.groupIndex];

        return {
          ...state,
          currentJobLineItemGroups: state.currentJobLineItemGroups
            .slice(0, action.groupIndex)
            .concat([
              {
                ...itemGroup,
                jobLineItems: itemGroup.jobLineItems
                  .slice(0, action.itemIndex)
                  .concat([action.payload])
                  .concat(itemGroup.jobLineItems.slice(action.itemIndex + 1)),
              },
            ])
            .concat(
              state.currentJobLineItemGroups.slice(action.groupIndex + 1)
            ),
        };
      }
      return state;
    }
    case ActionType.ADD_ITEM: {
      if (action.groupIndex !== null) {
        const vatAmount =
          localStorage.getItem(vatAmountLocalStorageKey) &&
          parseInt(localStorage.getItem(vatAmountLocalStorageKey), 10);
        const taxRateId =
          localStorage.getItem(taxRateIdLocalStorageKey) &&
          parseInt(localStorage.getItem(taxRateIdLocalStorageKey), 10);
        const itemGroup = state.currentJobLineItemGroups[action.groupIndex];
        const updatedItemGroup = itemGroup
          ? {
              // add new item to existing group
              ...itemGroup,
              jobLineItems: [...itemGroup.jobLineItems, action.payload],
            }
          : {
              // add new item to new group
              description: '',
              isMultipleChoice: false,
              jobLineItems: [action.payload],
            };

        return {
          ...state,
          currentJobLineItemGroups: state.currentJobLineItemGroups
            .slice(0, action.groupIndex)
            .concat([updatedItemGroup])
            .concat(
              action.groupIndex >= state.currentJobLineItemGroups.length
                ? []
                : state.currentJobLineItemGroups.slice(action.groupIndex + 1)
            ),
          currentLineItem: {
            ...action.currentLineItem,
            vatAmount,
            vatIncluded: vatAmount !== null ? true : false,
            isReverseChargeVat: false,
            taxRateId,
            cisDeductionRate: null,
          },
          currentGroupIndex: action.groupIndex,
          currentItemIndex: updatedItemGroup.jobLineItems.length - 1,
        };
      }
      return state;
    }

    case ActionType.REMOVE_ITEM: {
      if (action.groupIndex !== null && action.itemIndex !== null) {
        const itemGroup = state.currentJobLineItemGroups[action.groupIndex];
        const updatedItemGroup = {
          ...itemGroup,
          jobLineItems: itemGroup.jobLineItems
            .slice(0, action.itemIndex)
            .concat(itemGroup.jobLineItems.slice(action.itemIndex + 1)),
        };

        return {
          ...state,
          currentJobLineItemGroups: state.currentJobLineItemGroups
            .slice(0, action.groupIndex)
            .concat(updatedItemGroup)
            .concat(
              action.groupIndex >= state.currentJobLineItemGroups.length
                ? []
                : state.currentJobLineItemGroups.slice(action.groupIndex + 1)
            ),
          currentItemIndex:
            action.itemIndex < state.currentItemIndex
              ? state.currentItemIndex - 1
              : state.currentItemIndex,
          currentGroupIndex:
            action.groupIndex < state.currentGroupIndex
              ? state.currentGroupIndex - 1
              : state.currentGroupIndex,
          currentLineItem:
            action.itemIndex === state.currentItemIndex &&
            action.groupIndex === state.currentGroupIndex
              ? {}
              : { ...state.currentLineItem },
        };
      }
      return state;
    }

    case ActionType.STORE_SEND_INVOICE_TO_CUSTOMER_ERROR: {
      console.log('Store error', action.errorMessage);
      return { ...state, sendInvoiceToCustomerError: action.errorMessage };
    }

    case ActionType.ADD_ATTACHMENT_TO_CURRENT_JOB: {
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          jobAttachments: [
            action.attachment,
            ...state.currentJob.jobAttachments,
          ],
        },
      };
    }
    case ActionType.REMOVE_ATTACHMENT_FROM_CURRENT_JOB: {
      const existingAttachments = [...state.currentJob.jobAttachments];
      const updatedAttachments = existingAttachments.filter(
        (a) => a.id !== action.attachmentId
      );
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          jobAttachments: updatedAttachments,
        },
      };
    }

    default: {
      return state;
    }
  }
}

export const actions = {
  clearJobs: () => ({ type: ActionType.CLEAR_JOBS }),
  clearJob: () => ({ type: ActionType.CLEAR_JOB }),
  updateJob: (jobId, job, callback) => ({
    type: ActionType.UPDATE_JOB,
    jobId,
    job,
    callback,
  }),
  createJob: (isProposition, callback, dealId = null) => ({
    type: ActionType.CREATE_JOB,
    isProposition,
    callback,
    dealId,
  }),
  getJobPayments: (jobId, callback) => ({
    type: ActionType.GET_JOB_PAYMENTS,
    jobId,
    callback,
  }),
  storeJobLineItemGroups: (jobLineItemGroups) => ({
    type: ActionType.STORE_JOB_LINE_ITEM_GROUPS,
    jobLineItemGroups,
  }),
  editCurrentJob: (updatedJob) => ({
    type: ActionType.EDIT_CURRENT_JOB,
    updatedJob,
  }),
  sendQuoteToCustomer: (jobId, params, callback) => ({
    type: ActionType.SEND_QUOTE_TO_CUSTOMER,
    jobId,
    params,
    callback,
  }),
  archiveJob: (jobId, callback) => ({
    type: ActionType.ARCHIVE_JOB,
    jobId,
    callback,
  }),
  archiveJobs: (selectedJobs, callback) => ({
    type: ActionType.ARCHIVE_JOBS,
    selectedJobs,
    callback,
  }),
  unarchiveJob: (jobId, callback) => ({
    type: ActionType.UNARCHIVE_JOB,
    jobId,
    callback,
  }),
  unarchiveJobs: (selectedJobs, callback) => ({
    type: ActionType.UNARCHIVE_JOBS,
    selectedJobs,
    callback,
  }),
  acceptQuote: (jobId, callback) => ({
    type: ActionType.ACCEPT_QUOTE,
    jobId,
    callback,
  }),
  unacceptQuote: (jobId, callback) => ({
    type: ActionType.UNACCEPT_QUOTE,
    jobId,
    callback,
  }),
  declineJob: (jobId, callback) => ({
    type: ActionType.DECLINE_JOB,
    jobId,
    callback,
  }),
  convertJobToInvoice: (jobId, callback) => ({
    type: ActionType.CONVERT_JOB_TO_INVOICE,
    jobId,
    callback,
  }),
  deleteJob: (jobId, callback) => ({
    type: ActionType.DELETE_JOB,
    jobId,
    callback,
  }),
  deleteJobs: (jobIds, callback) => ({
    type: ActionType.DELETE_JOBS,
    jobIds,
    callback,
  }),
  sendInvoiceToCustomer: (jobId, params, callback) => ({
    type: ActionType.SEND_INVOICE_TO_CUSTOMER,
    jobId,
    params,
    callback,
  }),
  storeSendInvoiceToCustomerError: (errorMessage) => ({
    type: ActionType.STORE_SEND_INVOICE_TO_CUSTOMER_ERROR,
    errorMessage,
  }),
  duplicateJob: (jobId, callback) => ({
    type: ActionType.DUPLICATE_JOB,
    jobId,
    callback,
  }),
  storeCurrentLineItem: (groupIndex, itemIndex, lineItem) => ({
    type: ActionType.STORE_CURRENT_LINE_ITEM,
    groupIndex,
    itemIndex,
    lineItem,
  }),
  updateCurrentLineItem: (lineItem) => ({
    type: ActionType.UPDATE_CURRENT_LINE_ITEM,
    lineItem,
  }),
  clearCurrentLineItem: () => ({
    type: ActionType.CLEAR_CURRENT_LINE_ITEM,
  }),
  addLineItemToNewJob: (lineItem) => ({
    type: ActionType.ADD_LINE_ITEM_TO_NEW_JOB,
    lineItem,
  }),
  addLineItemToPendingJob: (lineItem) => ({
    type: ActionType.ADD_LINE_ITEM_TO_PENDING_JOB,
    lineItem,
  }),
  updateItemGroup: (payload, groupIndex) => ({
    type: ActionType.UPDATE_ITEM_GROUP,
    payload,
    groupIndex,
  }),
  removeItemGroup: (groupIndex) => ({
    type: ActionType.REMOVE_ITEM_GROUP,
    groupIndex,
  }),
  updateItem: (payload, groupIndex, itemIndex) => ({
    type: ActionType.UPDATE_ITEM,
    payload,
    groupIndex,
    itemIndex,
  }),
  addItem: (payload, groupIndex) => ({
    type: ActionType.ADD_ITEM,
    payload,
    groupIndex,
  }),
  removeItem: (groupIndex, itemIndex) => ({
    type: ActionType.REMOVE_ITEM,
    groupIndex,
    itemIndex,
  }),
  addAttachmentToJob: (jobId, jobAttachment, callback) => ({
    type: ActionType.ADD_ATTACHMENT_TO_JOB,
    jobId,
    jobAttachment,
    callback,
  }),
  removeAttachmentFromJob: (jobId, jobAttachmentId, callback) => ({
    type: ActionType.REMOVE_ATTACHMENT_FROM_JOB,
    jobId,
    jobAttachmentId,
    callback,
  }),
  addAttachmentToCurrentJob: (attachment) => ({
    type: ActionType.ADD_ATTACHMENT_TO_CURRENT_JOB,
    attachment,
  }),
  removeAttachmentFromCurrentJob: (attachmentId) => ({
    type: ActionType.REMOVE_ATTACHMENT_FROM_CURRENT_JOB,
    attachmentId,
  }),
};

const watchAddAttachmentToJob = function* watchAddAttachmentToJob() {
  yield takeEvery(ActionType.ADD_ATTACHMENT_TO_JOB, function* (action) {
    const jobId = action.jobId;
    const jobAttachment = action.jobAttachment;
    const callback = action.callback;
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.addAttachmentToJob, jobId, jobAttachment),
        timeout: delay(60000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Add attachment to job request timed out.'
        );
      }

      yield put(actions.addAttachmentToCurrentJob(response));

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        console.log(e);
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  });
};

const watchRemoveAttachmentFromJob = function* watchRemoveAttachmentFromJob() {
  while (true) {
    const { jobId, jobAttachmentId, callback } = yield take(
      ActionType.REMOVE_ATTACHMENT_FROM_JOB
    );
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.removeAttachmentFromJob, jobId, jobAttachmentId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Remove attachment from job request timed out.'
        );
      }
      yield put(actions.removeAttachmentFromCurrentJob(jobAttachmentId));

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        console.log(e);
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchCreateJob = function* watchCreateJob() {
  while (true) {
    const { isProposition, callback, dealId } = yield take(
      ActionType.CREATE_JOB
    );
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.createJob, isProposition, dealId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Create new job request timed out.');
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        console.log(e);
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchSendQuoteToCustomer = function* watchSendQuoteToCustomer() {
  while (true) {
    const { jobId, params, callback } = yield take(
      ActionType.SEND_QUOTE_TO_CUSTOMER
    );
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.sendQuoteToCustomer, jobId, params),
        timeout: delay(30000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Send quote to customer request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchArchiveJob = function* watchArchiveJob() {
  while (true) {
    const { jobId, callback } = yield take(ActionType.ARCHIVE_JOB);
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.archiveJob, jobId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Archive job request timed out.');
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchArchiveJobs = function* watchArchiveJobs() {
  while (true) {
    const { selectedJobs, callback } = yield take(ActionType.ARCHIVE_JOBS);
    yield call(refreshAuthToken);
    try {
      for (let i = 0; i < selectedJobs.length; i++) {
        const { timeout } = yield race({
          response: call(api.archiveJob, selectedJobs[i]),
          timeout: delay(15000),
        });
        if (timeout) {
          throw new api.TimeoutException('Archive jobs request timed out.');
        }
      }

      if (callback) {
        callback(null);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchUnarchiveJob = function* watchUnarchiveJob() {
  while (true) {
    const { jobId, callback } = yield take(ActionType.UNARCHIVE_JOB);
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.unarchiveJob, jobId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Unarchive job request timed out.');
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchUnarchiveJobs = function* watchUnarchiveJobs() {
  while (true) {
    const { selectedJobs, callback } = yield take(ActionType.UNARCHIVE_JOBS);
    yield call(refreshAuthToken);
    try {
      for (let i = 0; i < selectedJobs.length; i++) {
        const { timeout } = yield race({
          response: call(api.unarchiveJob, selectedJobs[i]),
          timeout: delay(15000),
        });
        if (timeout) {
          throw new api.TimeoutException('Unarchive jobs request timed out.');
        }
      }

      if (callback) {
        callback(null);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchAcceptJob = function* watchAcceptJob() {
  while (true) {
    const { jobId, callback } = yield take(ActionType.ACCEPT_QUOTE);
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.acceptQuote, jobId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Accept job request timed out.');
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchUnacceptJob = function* watchUnacceptJob() {
  while (true) {
    const { jobId, callback } = yield take(ActionType.UNACCEPT_QUOTE);
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.unacceptQuote, jobId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Unaccept quote request timed out.');
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchDeclineJob = function* watchDeclineJob() {
  while (true) {
    const { jobId, callback } = yield take(ActionType.DECLINE_JOB);
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.declineJob, jobId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Decline job request timed out.');
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchConvertJobToInvoice = function* watchConvertJobToInvoice() {
  while (true) {
    const { jobId, callback } = yield take(ActionType.CONVERT_JOB_TO_INVOICE);
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.convertJobToInvoice, jobId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Convert job to invoice request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchSendInvoiceToCustomer = function* watchSendInvoiceToCustomer() {
  while (true) {
    const { jobId, params, callback } = yield take(
      ActionType.SEND_INVOICE_TO_CUSTOMER
    );
    yield call(refreshAuthToken);
    try {
      yield put(actions.storeSendInvoiceToCustomerError(''));
      const { response, timeout } = yield race({
        response: call(api.sendInvoiceToCustomer, jobId, params),
        timeout: delay(30000),
      });
      if (timeout) {
        throw new api.TimeoutException('Send invoice request timed out.');
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      yield put(actions.storeSendInvoiceToCustomerError(e.message.errors));
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchDuplicateJob = function* watchDuplicateJob() {
  while (true) {
    const { jobId, callback } = yield take(ActionType.DUPLICATE_JOB);
    yield call(refreshAuthToken);
    try {
      console.log('Duplicating the job');
      const { response, timeout } = yield race({
        response: call(api.duplicateJob, jobId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Duplicate job request timed out.');
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchLogout = function* watchLogout() {
  while (true) {
    const { callback } = yield take('auth.logout');
    yield put(actions.clearJobs());
  }
};

export const saga = function* saga() {
  yield all([
    watchLogout(),
    watchAddAttachmentToJob(),
    watchRemoveAttachmentFromJob(),
    watchCreateJob(),
    watchSendQuoteToCustomer(),
    watchArchiveJob(),
    watchArchiveJobs(),
    watchUnarchiveJob(),
    watchUnarchiveJobs(),
    watchAcceptJob(),
    watchUnacceptJob(),
    watchDeclineJob(),
    watchConvertJobToInvoice(),
    watchSendInvoiceToCustomer(),
    watchDuplicateJob(),
  ]);
};
