import { Note } from '@payaca/types/notesTypes';
import { action } from 'typesafe-actions';
import { createAsyncAction } from '../utils';
import {
  ActionType,
  ArchiveScheduledEventNote,
  CreateScheduledEventNote,
  GetNotesForDeal,
  GetNotesForScheduledEvent,
  UpdateScheduledEventNote,
} from './notesTypes';

// CREATE NOTE
export const requestCreateNote = (note: Note, dealId: number) => {
  return action(ActionType.REQUEST_CREATE_NOTE, { note, dealId });
};
export const createNoteSuccess = () => {
  return action(ActionType.CREATE_NOTE_SUCCESS);
};
export const createNoteFailure = (error: Error, errorMessage: string) => {
  return action(ActionType.CREATE_NOTE_FAILURE, { errorMessage }, null, error);
};

// UDPATE NOTE
export const requestUpdateNote = (note: Note, dealId: number) => {
  return action(ActionType.REQUEST_UPDATE_NOTE, { note, dealId });
};
export const updateNoteSuccess = () => {
  return action(ActionType.UPDATE_NOTE_SUCCESS);
};
export const updateNoteFailure = (error: Error, errorMessage: string) => {
  return action(ActionType.UPDATE_NOTE_FAILURE, { errorMessage }, null, error);
};

// ARCHIVE NOTE
export const requestArchiveNote = (
  noteId: number,
  dealId: number,
  callback?: () => void,
  errorCallback?: (error: unknown) => void
) => {
  return action(ActionType.REQUEST_ARCHIVE_NOTE, {
    noteId,
    dealId,
    callback,
    errorCallback,
  });
};

export const archiveNoteSuccess = () => {
  return action(ActionType.ARCHIVE_NOTE_SUCCESS);
};
export const archiveNoteFailure = (error: unknown, errorMessage: string) => {
  return action(ActionType.ARCHIVE_NOTE_FAILURE, { errorMessage }, null, error);
};

// CLEAR NOTES
export const clearNote = () => {
  return action(ActionType.CLEAR_NOTE);
};

// CLEAR NOTES STORE
export const clearNotesStore = () => {
  return action(ActionType.CLEAR_NOTES_STORE);
};

// GET NOTES FOR DEAL
export const getNotesForDeal = createAsyncAction<GetNotesForDeal>({
  request: ActionType.GET_NOTES_FOR_DEAL_REQUEST,
  success: ActionType.GET_NOTES_FOR_DEAL_SUCCESS,
  failure: ActionType.GET_NOTES_FOR_DEAL_FAILURE,
})((payload: GetNotesForDeal['request']['payload']) => ({ payload }));

// GET NOTES FOR SCHEDULED EVENT
export const getNotesForScheduledEvent =
  createAsyncAction<GetNotesForScheduledEvent>({
    request: ActionType.GET_NOTES_FOR_SCHEDULED_EVENT_REQUEST,
    success: ActionType.GET_NOTES_FOR_SCHEDULED_EVENT_SUCCESS,
    failure: ActionType.GET_NOTES_FOR_SCHEDULED_EVENT_FAILURE,
  })((payload: GetNotesForScheduledEvent['request']['payload']) => ({
    payload,
  }));

export const createScheduledEventNote =
  createAsyncAction<CreateScheduledEventNote>({
    request: ActionType.CREATE_SCHEDULED_EVENT_NOTE_REQUEST,
    success: ActionType.CREATE_SCHEDULED_EVENT_NOTE_SUCCESS,
    failure: ActionType.CREATE_SCHEDULED_EVENT_NOTE_FAILURE,
  })((payload: CreateScheduledEventNote['request']['payload']) => ({
    payload,
  }));

export const updateScheduledEventNote =
  createAsyncAction<UpdateScheduledEventNote>({
    request: ActionType.UPDATE_SCHEDULED_EVENT_NOTE_REQUEST,
    success: ActionType.UPDATE_SCHEDULED_EVENT_NOTE_SUCCESS,
    failure: ActionType.UPDATE_SCHEDULED_EVENT_NOTE_FAILURE,
  })((payload: UpdateScheduledEventNote['request']['payload']) => ({
    payload,
  }));

export const archiveScheduledEventNote =
  createAsyncAction<ArchiveScheduledEventNote>({
    request: ActionType.ARCHIVE_SCHEDULED_EVENT_NOTE_REQUEST,
    success: ActionType.ARCHIVE_SCHEDULED_EVENT_NOTE_SUCCESS,
    failure: ActionType.ARCHIVE_SCHEDULED_EVENT_NOTE_FAILURE,
  })((payload: ArchiveScheduledEventNote['request']['payload']) => ({
    payload,
  }));
