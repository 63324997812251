import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import useLinkSiteAddressToProject from '@/api/mutations/project/useLinkSiteAddressToProject';
import complianceKeys from '@/api/queries/compliance/keyFactory';
import projectKeys from '@/api/queries/project/keyFactory';
import {
  LinkSiteAddressToProjectInput,
  LinkSiteAddressToProjectMutation,
  ProjectQuery,
  UnlinkSiteAddressFromProjectInput,
} from '@/gql/graphql';

const useOptimisticLinkSiteAddressToProject = (
  projectId: number,
  options?: UseMutationOptions<
    LinkSiteAddressToProjectMutation,
    unknown,
    {
      unlinkSiteAddressFromProjectInput?: UnlinkSiteAddressFromProjectInput;
      linkSiteAddressToProjectInput: LinkSiteAddressToProjectInput;
    }
  >
) => {
  const queryClient = useQueryClient();

  return useLinkSiteAddressToProject({
    ...options,
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: projectKeys.overview(projectId),
      });

      // Snapshot the previous value
      const previousProjectOverview = queryClient.getQueryData(
        projectKeys.overview(projectId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<ProjectQuery>(
        projectKeys.overview(projectId),
        (old) => {
          if (!old) return;

          const newAddress = old.project.customer?.addresses.find(
            (i) => i.id === variables.linkSiteAddressToProjectInput?.addressId
          );

          if (!newAddress) {
            // Should never get here
            return old;
          }

          return {
            ...old,
            project: {
              ...old.project,
              addresses: {
                ...old.project.addresses,
                site: [newAddress],
              },
            },
          };
        }
      );

      options?.onMutate?.(variables);

      // Return a context object with the snapshotted value
      return { previousProjectOverview };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        projectKeys.overview(projectId),
        // @ts-ignore
        context.previousProjectOverview
      );

      options?.onError?.(err, newTodo, context);
    },
    // Always refetch after error or success:
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.overview(projectId),
      });
      queryClient.invalidateQueries({
        queryKey: complianceKeys.formPrefill(projectId),
      });

      options?.onSettled?.(...args);
    },
  });
};

export default useOptimisticLinkSiteAddressToProject;
