import { createReducer } from '../utils';
import { ActionType, AnyAction, State } from './listedFilesTypes';

export const initialState: State = {
  deals: {},
  isGettingListedFilesForDeal: false,
  isSharingFilesForDeal: false,
  isUnsharingFilesForDeal: false,
};

const setIsGettingListedFilesForDeal = (value: boolean) => (state: State) => ({
  ...state,
  isGettingListedFilesForDeal: value,
});

const listedFilesReducer = createReducer<State, ActionType, AnyAction>(
  initialState,
  {
    [ActionType.GET_LISTED_FILES_FOR_DEALS_REQUEST]:
      setIsGettingListedFilesForDeal(true),
    [ActionType.GET_LISTED_FILES_FOR_DEALS_FAILURE]:
      setIsGettingListedFilesForDeal(false),
    [ActionType.GET_LISTED_FILES_FOR_DEALS_SUCCESS]: (state, action) => {
      return {
        ...state,
        isGettingListedFilesForDeal: false,
        deals: {
          ...state.deals,
          [action.payload.dealId]: action.payload.files,
        },
        listedFiles: action.payload.files,
      };
    },
    [ActionType.CLEAR_LISTED_FILES_PAGE]: (state) => {
      return {
        ...state,
        isGettingListedFilesForDeal: false,
        listedFiles: [],
      };
    },
    [ActionType.SHARE_LISTED_FILES_REQUEST]: (state) => ({
      ...state,
      isSharingFilesForDeal: true,
    }),
    [ActionType.UNSHARE_LISTED_FILES_REQUEST]: (state) => ({
      ...state,
      isUnsharingFilesForDeal: true,
    }),
    [ActionType.SHARE_LISTED_FILES_SUCCESS]: (state, action) => {
      return {
        ...state,
        isSharingFilesForDeal: false,
        deals: {
          ...state.deals,
          [action.payload.dealId]: action.payload.files,
        },
      };
    },
    [ActionType.UNSHARE_LISTED_FILES_SUCCESS]: (state, action) => {
      return {
        ...state,
        isUnsharingFilesForDeal: false,
        deals: {
          ...state.deals,
          [action.payload.dealId]: action.payload.files,
        },
      };
    },
    [ActionType.SHARE_LISTED_FILES_FAILURE]: (state) => ({
      ...state,
      isSharingFilesForDeal: false,
    }),
    [ActionType.UNSHARE_LISTED_FILES_FAILURE]: (state) => ({
      ...state,
      isUnsharingFilesForDeal: false,
    }),
  }
);

export default listedFilesReducer;
