import SideDrawer from '@payaca/components/sideDrawer/SideDrawer';
import { FC } from 'react';
import ScheduledEventReadView from '../scheduledEventReadView/ScheduledEventReadView';
import './ScheduledEventReadDrawer.sass';

type Props = {
  scheduledEventId?: number;
  hideDealLink?: boolean;
  onDeleteScheduledEventSuccess?: () => void;
  onUpdateScheduledEventSuccess?: () => void;
  onUpdateTasksSuccess?: () => void;
  isOpen: boolean;
  onClose?: () => void;
  hideNotes?: boolean;
};

const ScheduledEventReadDrawer: FC<Props> = ({
  scheduledEventId,
  hideDealLink,
  onDeleteScheduledEventSuccess,
  onUpdateScheduledEventSuccess,
  onUpdateTasksSuccess,
  isOpen,
  onClose,
  hideNotes,
}: Props): JSX.Element | null => {
  return (
    <SideDrawer
      isOpen={isOpen}
      onClose={onClose}
      className="scheduled-event-read-drawer"
    >
      {scheduledEventId && (
        <ScheduledEventReadView
          scheduledEventId={scheduledEventId}
          hideDealLink={hideDealLink}
          hideNotes={hideNotes}
          onDeleteScheduledEventSuccess={() => {
            onClose && onClose();
            onDeleteScheduledEventSuccess && onDeleteScheduledEventSuccess();
          }}
          onUpdateScheduledEventSuccess={onUpdateScheduledEventSuccess}
          onUpdateTasksSuccess={onUpdateTasksSuccess}
        />
      )}
    </SideDrawer>
  );
};
export default ScheduledEventReadDrawer;
