import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  LinkSiteAddressToProjectMutation as _LinkSiteAddressToProjectMutation,
  LinkSiteAddressToProjectInput,
  UnlinkSiteAddressFromProjectInput,
} from '@/gql/graphql';

const LinkSiteAddressToProjectMutation = graphql(`
  mutation LinkSiteAddressToProject(
    $unlinkSiteAddressFromProjectInput: UnlinkSiteAddressFromProjectInput!
    $linkSiteAddressToProjectInput: LinkSiteAddressToProjectInput!
  ) {
    unlinkSiteAddressFromProject(input: $unlinkSiteAddressFromProjectInput) {
      id
    }
    linkSiteAddressToProject(input: $linkSiteAddressToProjectInput) {
      id
    }
  }
`);

const useLinkSiteAddressToProject = (
  options?: UseMutationOptions<
    _LinkSiteAddressToProjectMutation,
    unknown,
    {
      unlinkSiteAddressFromProjectInput?: UnlinkSiteAddressFromProjectInput;
      linkSiteAddressToProjectInput: LinkSiteAddressToProjectInput;
    }
  >
) => {
  return useMutation({
    mutationFn: (variables) => {
      return gqlClient.request(LinkSiteAddressToProjectMutation, {
        unlinkSiteAddressFromProjectInput:
          variables.unlinkSiteAddressFromProjectInput ||
          variables.linkSiteAddressToProjectInput,
        linkSiteAddressToProjectInput: variables.linkSiteAddressToProjectInput,
      });
    },
    ...options,
  });
};

export default useLinkSiteAddressToProject;
