import { FC, useEffect, useReducer, useState } from 'react';

import { GetMaterialsInput, MaterialsQuery } from '@/gql/graphql';
import CreateMaterialModal from '@/ui/components/createEditMaterialModal/CreateMaterialModal';
import InfiniteMaterialsList from '@/ui/components/infiniteMaterialsList/InfiniteMaterialsList';
import Conditional from '@payaca/components/conditional/Conditional';
import EclipseBadge from '@payaca/components/plBadge/EclipseBadge';
import Button from '@payaca/components/plButton/Button';
import EmptyState from '@payaca/components/plEmptyState/EmptyState';
import Sidebar, {
  Props as TSidebarProps,
} from '@payaca/components/plSidebar/Sidebar';
import { MaterialCardWithTransition } from '../materialCard/MaterialCard';

type AddMaterialSidebarProps = Pick<
  TSidebarProps,
  'isOpen' | 'onClose' | 'title' | 'zIndexLevel' | 'warnUserContent'
> & {
  onAddMaterials: (materials: MaterialState[]) => Promise<void>;
  emptyStatePromptText?: string;
  primaryActionText?: string;
  showPriceIncludingTax?: boolean;
  defaultFilters?: GetMaterialsInput;
  disabledMaterialIds?: string[];
};

type Material = MaterialsQuery['materials']['items'][number];

export type MaterialState = Material & { quantity: number };

type ModalState = {
  activeModal: 'createMaterial' | 'blockEdit' | null;
};

type ModalAction =
  | {
      type: 'openCreateModalModal';
    }
  | {
      type: 'openBlockEditModal';
    }
  | {
      type: 'closeModal';
    };

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case 'openCreateModalModal':
      return { activeModal: 'createMaterial' };
    case 'openBlockEditModal':
      return { activeModal: 'blockEdit' };
    case 'closeModal':
      return { activeModal: null };
    default:
      return state;
  }
};

const BuildMaterialsListSidebar: FC<AddMaterialSidebarProps> = (props) => {
  const {
    isOpen,
    onClose,
    title,
    zIndexLevel,
    onAddMaterials,
    emptyStatePromptText,
    primaryActionText,
    warnUserContent,
    showPriceIncludingTax = true,
    defaultFilters,
    disabledMaterialIds = [],
  } = props;

  const [modalState, modalDispatch] = useReducer(modalReducer, {
    activeModal: null,
  });
  const [isSaving, setIsSaving] = useState(false);

  const [selectedMaterials, setSelectedMaterials] = useState<MaterialState[]>(
    []
  );

  useEffect(() => {
    if (isOpen) {
      setSelectedMaterials([]);
    }
  }, [isOpen]);

  const totalQuantity = selectedMaterials.reduce((acc, selectedMaterial) => {
    return acc + selectedMaterial.quantity;
  }, 0);

  return (
    <>
      <Sidebar
        isOpen={isOpen}
        onClose={
          modalState.activeModal === 'createMaterial' ? undefined : onClose
        }
        size="lg"
        zIndexLevel={zIndexLevel}
        title={title}
        warnUserOnClose={selectedMaterials.length > 0}
        warnUserContent={warnUserContent}
      >
        <Sidebar.Body>
          <div className="grid h-full grid-cols-2">
            <InfiniteMaterialsList
              showPriceIncludingTax={showPriceIncludingTax}
              onAddMaterial={() => {
                modalDispatch({ type: 'openCreateModalModal' });
              }}
              onMaterialClick={(material) => {
                setSelectedMaterials((state) => [
                  ...state,
                  { ...material, quantity: 1 },
                ]);
              }}
              disabledMaterials={[
                ...disabledMaterialIds,
                ...selectedMaterials.map((i) => i.id),
              ]}
              defaultFilters={defaultFilters}
            />

            <div className="flex flex-col gap-2.5 overflow-auto border-l bg-gray-50 p-3.5">
              <Conditional condition={selectedMaterials.length === 0}>
                <EmptyState
                  className="my-auto"
                  iconName="arrow-block-left.3"
                  text={emptyStatePromptText}
                />
              </Conditional>

              {selectedMaterials.map((material) => (
                <MaterialCardWithTransition
                  key={material.id}
                  show={material.quantity > 0}
                  afterLeave={() => {
                    setSelectedMaterials((state) =>
                      state.filter((i) => i.quantity > 0)
                    );
                  }}
                  name={material.name}
                  price={
                    showPriceIncludingTax
                      ? material.suppliedBy[0]?.price.unitPrice
                      : material.suppliedBy[0]?.price.unitPriceExcTax
                  }
                  thumbnailUrl={material.image?.thumbnailUrl || ''}
                  quantity={material.quantity}
                  onChangeQuantity={(newQuantity) => {
                    setSelectedMaterials((state) =>
                      state.map((i) =>
                        i.id === material.id
                          ? { ...i, quantity: newQuantity }
                          : i
                      )
                    );
                  }}
                >
                  <EclipseBadge
                    badges={material.suppliedBy.map((i) => i.supplier.name)}
                    variant="soft"
                    colour="gray"
                  />
                </MaterialCardWithTransition>
              ))}

              <Conditional condition={selectedMaterials.length !== 0}>
                <Button
                  className="sticky bottom-0 mt-auto"
                  isProcessing={isSaving}
                  disabled={isSaving}
                  onClick={async () => {
                    setIsSaving(true);

                    await onAddMaterials(selectedMaterials);

                    setIsSaving(false);

                    onClose?.();
                  }}
                >
                  {primaryActionText} ({totalQuantity})
                </Button>
              </Conditional>
            </div>
          </div>
        </Sidebar.Body>
        <CreateMaterialModal
          isOpen={modalState.activeModal === 'createMaterial'}
          onClose={() => {
            modalDispatch({ type: 'closeModal' });
          }}
          onPersistMaterialSuccess={(material) => {
            setSelectedMaterials((state) => [
              ...state,
              { ...material, quantity: 1 },
            ]);
            modalDispatch({ type: 'closeModal' });
          }}
          enableSupplierMaterialInput={true}
        />
      </Sidebar>
    </>
  );
};

export default BuildMaterialsListSidebar;
