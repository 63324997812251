import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as validationActions from '@payaca/store/validation/validationActions';

import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';

import {
  LoqateAddressResult,
  LoqateAddressResultType,
} from '@payaca/types/addressTypes';

import { useSelector } from '@/api/state';
import { useAccount } from '../../../utils/storeHooks';

import {
  getAddressAsString,
  loqateAddressToAddress,
} from '@payaca/helpers/locationHelper';

interface Props {
  inputStyleVariant?: InputStyleVariant;
  label?: string;
  onChange: (address: { [key: string]: any }) => void;
  addressFieldName?: string;
  onEnterManually?: () => void;
  placeholder?: string;
}

const AddressLookupControl: FC<Props> = ({
  inputStyleVariant,
  label,
  onChange,
  addressFieldName,
  onEnterManually,
  placeholder,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const account = useAccount();

  const [addressLookupResults, setAddressLookupResults] =
    useState<LoqateAddressResult[]>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [disableNoOptionsText, setDisableNoOptionsText] = useState(true);

  const isLookingUpAddressBySearchTerm = useSelector(
    (state) => state.validation.isLookingUpAddressBySearchTerm
  );

  const addressOptions = useMemo(() => {
    let options = addressLookupResults || [];
    if (onEnterManually) {
      options = [...options, { id: '', label: 'Enter manually...' }];
    }
    return options;
  }, [addressLookupResults]);

  const lookupAddress = useCallback(
    (_searchTerm: string, containerId = '') => {
      setDisableNoOptionsText(!_searchTerm);
      setSearchTerm(_searchTerm);
      dispatch(
        validationActions.requestLookupAddressBySearchTerm(
          _searchTerm,
          containerId,
          account.region,
          (error: Error, response: any) => {
            setAddressLookupResults(response);
          }
        )
      );
    },
    [account]
  );

  const renderAddressLookupOption = useCallback(
    (addressLookupOption: LoqateAddressResult) => {
      let label = addressLookupOption.label;
      let subLabel = null;
      if (addressLookupOption.type !== LoqateAddressResultType.ADDRESS) {
        const splitLabel = label.split(' - ');
        label = splitLabel[0];
        subLabel = splitLabel[1];
      }
      return (
        <div className="address-lookup-option">
          <div>
            <span>{label}</span>
            {subLabel && <span className="sub-label">{subLabel}</span>}
          </div>
          {addressLookupOption.type !== LoqateAddressResultType.ADDRESS && (
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          )}
        </div>
      );
    },
    []
  );

  const keyName = (fieldName: string) =>
    addressFieldName ? `${addressFieldName}.${fieldName}` : fieldName;

  const selectAddressFromLookup = useCallback(
    (addressResult?: LoqateAddressResult) => {
      if (!addressResult) return;
      if (!addressResult?.id && !!onEnterManually) {
        // Selected "Enter manually"
        return onEnterManually();
      }
      if (addressResult.type === LoqateAddressResultType.ADDRESS) {
        // selected an address - retrieve full address and set
        dispatch(
          validationActions.requestRetrieveAddress(
            addressResult.id,
            (error: Error, completeAddress: any) => {
              const { line1, line2, city, postcode } =
                loqateAddressToAddress(completeAddress);
              onChange({
                [keyName('line1')]: line1,
                [keyName('line2')]: line2,
                [keyName('city')]: city,
                [keyName('postcode')]: postcode,
                [keyName('countryName')]: completeAddress.countryName,
              });
              setAddressLookupResults(undefined);
              const address =
                getAddressAsString({
                  line1,
                  line2,
                  city,
                  postcode,
                }) || '';
              setSearchTerm(address);
              setDisableNoOptionsText(true);
            }
          )
        );
      } else {
        // selected a postcode with multiple addresses - lookup address result to get nested addresses
        lookupAddress(searchTerm, addressResult.id);
      }
    },
    [
      keyName,
      onChange,
      addressFieldName,
      lookupAddress,
      onEnterManually,
      searchTerm,
    ]
  );

  return (
    <div className="address-lookup-control">
      <TypeToSearchField
        disableCloseOnSelect={true}
        label={label}
        styleVariant={inputStyleVariant}
        onSearchTermChange={lookupAddress}
        options={addressOptions}
        isLoadingOptions={isLookingUpAddressBySearchTerm}
        renderOption={renderAddressLookupOption}
        disableFilteringInComponent={true}
        onSelectOption={selectAddressFromLookup}
        placeholder={placeholder}
        noOptionsText={
          !disableNoOptionsText
            ? "We couldn't find any addresses matching your search."
            : undefined
        }
        value={searchTerm}
        blockInputChangeReasons={['reset', 'clear']}
      />
    </div>
  );
};

export default AddressLookupControl;
