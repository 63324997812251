import { FunctionComponent, useCallback } from 'react';

import { useFieldId } from '@payaca/hooks/useFieldId';
import FieldLabel from '../fieldLabel/FieldLabel';
import Switch from '../switch/Switch';

import './SwitchField.sass';

type Props = {
  name: string;
  value?: boolean;
  label?: string | JSX.Element;
  description?: string | JSX.Element;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange?: (value: { [key: string]: boolean }) => void;
  onTouch?: (fieldName: string) => void;
};

const SwitchField: FunctionComponent<Props> = ({
  name,
  value = false,
  label,
  description,
  isDisabled,
  isRequired,
  onChange,
  onTouch,
}: Props): JSX.Element => {
  const onSwitchChange = useCallback(() => {
    onChange && onChange({ [name]: !value });
    onTouch && onTouch(name);
  }, [onChange, onTouch, value, name]);

  const id = useFieldId(name);

  return (
    <div className="switch-field">
      <div className="switch-wrapper">
        <Switch
          isChecked={value}
          isDisabled={isDisabled}
          onChange={onSwitchChange}
          id={id}
        />
      </div>
      <FieldLabel
        label={label}
        id={id}
        description={description}
        isRequired={isRequired}
      />
    </div>
  );
};

export default SwitchField;
