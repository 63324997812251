import { PartialUpdateJobRequestData } from '@payaca/types/jobRequestTypes';
import { JobAttachment } from '@payaca/types/jobTypes';
import { Job } from '@payaca/types/jobTypesV2';
import { FetchedEntity, UpdateResult } from '@payaca/types/storeTypes';
import { DeclareAsyncAction } from '../types';

export interface State {
  isMarkingJobAsSent: boolean;
  isJobMarkedAsSentSuccessfully?: boolean;
  markJobAsSentErrorMessage?: string;

  isResendingJob: boolean;
  isResentJobSuccessfully?: boolean;
  resendJobErrorMessage?: string;

  isEditingJob: boolean;
  editJobSuccess?: Job;
  editJobErrorMessage?: string;

  jobs: { [key: number]: FetchedEntity<Job> };
  jobAttachments: { [key: string]: FetchedEntity<JobAttachment> };
  jobUpdateResults: { [key: number]: UpdateResult };

  isUpdatingJob: boolean;
  isUpdatingJobAttachment: boolean;

  isGettingJobValidationResult: boolean;

  isGettingJobsForDeal: boolean;

  isSendingQuoteToCustomer: boolean;
  isSendingInvoiceToCustomer: boolean;
  isArchivingJob: boolean;
  isAccpetingQuote: boolean;
  isDecliningJob: boolean;
  isConvertingJobToInvoice: boolean;
  isDuplicatingJob: boolean;

  isCreatingJob: boolean;
}

export enum ActionType {
  REQUEST_GET_JOBS_FOR_DEAL = 'jobs.requestGetJobsForDeal',
  GET_JOBS_FOR_DEAL_SUCCESS = 'jobs.getJobsForDealSuccess',
  GET_JOBS_FOR_DEAL_FAILURE = 'jobs.getJobsForDealFailure',

  REQUEST_GET_JOB = 'jobs.requestGetJob',
  GET_JOB_SUCCESS = 'jobs.getJobSuccess',
  GET_JOB_FAILURE = 'jobs.getJobFailure',
  CLEAR_JOB = 'jobs.clearJob',
  CLEAR_JOBS = 'jobs.clearJobs',

  PARTIAL_UPDATE_JOB_REQUEST = 'jobs.partialUpdateJob:request',

  REQUEST_UPDATE_JOB = 'jobs.requestUpdateJob',
  UPDATE_JOB_SUCCESS = 'jobs.updateJobSuccess',
  UPDATE_JOB_FAILURE = 'jobs.updateJobFailure',

  REQUEST_GET_JOB_VALIDATION_RESULT = 'jobs.requestGetJobValidationResult',
  GET_JOB_VALIDATION_RESULT_SUCCESS = 'jobs.getJobValidationResultSuccess',
  GET_JOB_VALIDATION_RESULT_FAILURE = 'jobs.getJobValidationResultFailure',

  REQUEST_MARK_JOB_AS_SENT = 'jobs.requestMarkJobAsSent',
  MARK_JOB_AS_SENT_SUCCESS = 'jobs.markJobAsSentSuccess',
  MARK_JOB_AS_SENT_FAILURE = 'jobs.markJobAsSentFailure',
  CLEAR_MARK_JOB_AS_SENT = 'jobs.clearMarkJobAsSent',

  REQUEST_RESEND_JOB = 'jobs.requestResendJob',
  RESEND_JOB_SUCCESS = 'jobs.resentJobSuccess',
  RESEND_JOB_FAILURE = 'jobs.resentJobFailure',
  CLEAR_RESEND_JOB = 'jobs.clearResendJob',

  REQUEST_EDIT_JOB = 'jobs.requestEditJob',
  EDIT_JOB_SUCCESS = 'jobs.editJobSuccess',
  EDIT_JOB_FAILURE = 'jobs.editJobFailure',
  CLEAR_EDIT_JOB = 'jobs.clearEditJob',
  CLEAR_JOB_UPDATE_RESULTS = 'jobs.clearJobUpdateResults',

  REQUEST_SEND_QUOTE_TO_CUSTOMER = 'jobs.requestSendQuoteToCustomer',
  SEND_QUOTE_TO_CUSTOMER_SUCCESS = 'jobs.sendQuoteToCustomerSuccess',
  SEND_QUOTE_TO_CUSTOMER_FAILURE = 'jobs.sendQuoteToCustomerFailure',

  REQUEST_SEND_INVOICE_TO_CUSTOMER = 'jobs.requestSendInvoiceToCustomer',
  SEND_INVOICE_TO_CUSTOMER_SUCCESS = 'jobs.sendInvoiceToCustomerSuccess',
  SEND_INVOICE_TO_CUSTOMER_FAILURE = 'jobs.sendInvoiceToCustomerFailure',

  // JOB ATTACHMENTS
  REQUEST_GET_JOB_ATTACHMENTS_FOR_JOB = 'jobs.requestGetJobAttachmentsForJob',
  GET_JOB_ATTACHMENTS_FOR_JOB_SUCCESS = 'jobs.getJobAttachmentsForJobSuccess',

  GET_JOB_ATTACHMENT_SUCCESS = 'jobs.getJobAttachmentSuccess',
  GET_JOB_ATTACHMENT_FAILURE = 'jobs.getJobAttachmentFailure',
  CLEAR_JOB_ATTACHMENT = 'jobs.clearJobAttachment',
  CLEAR_JOB_ATTACHMENTS = 'jobs.clearJobAttachments',

  REQUEST_UPDATE_JOB_ATTACHMENT = 'jobs.requestUpdateJobAttachment',
  UPDATE_JOB_ATTACHMENT_SUCCESS = 'jobs.updateJobAttachmentSuccess',
  UPDATE_JOB_ATTACHMENT_FAILURE = 'jobs.updateJobAttachmentFailure',

  REQUEST_ARCHIVE_JOB = 'jobs.requestArchiveJob',
  ARCHIVE_JOB_SUCCESS = 'jobs.archiveJobSuccess',
  ARCHIVE_JOB_FAILURE = 'jobs.archiveJobFailure',

  REQUEST_ACCEPT_QUOTE = 'jobs.requestAcceptQuote',
  ACCEPT_QUOTE_SUCCESS = 'jobs.acceptQuoteSuccess',
  ACCEPT_QUOTE_FAILURE = 'jobs.acceptQuoteFailure',

  REQUEST_DECLINE_JOB = 'jobs.requestDeclineJob',
  DECLINE_JOB_SUCCESS = 'jobs.declineJobSuccess',
  DECLINE_JOB_FAILURE = 'jobs.declineJobFailure',

  REQUEST_CONVERT_JOB_TO_INVOICE = 'jobs.requestConvertJobToInvoice',
  CONVERT_JOB_TO_INVOICE_SUCCESS = 'jobs.convertJobToInvoiceSuccess',
  CONVERT_JOB_TO_INVOICE_FAILURE = 'jobs.convertJobToInvoiceFailure',

  REQUEST_DUPLICATE_JOB = 'jobs.requestDuplicateJob',
  DUPLICATE_JOB_SUCCESS = 'jobs.duplicateJobSuccess',
  DUPLICATE_JOB_FAILURE = 'jobs.duplicateJobFailure',

  REQUEST_CREATE_JOB = 'jobs.requestCreateJob',
  CREATE_JOB_SUCCESS = 'jobs.createJobSuccess',
  CREATE_JOB_FAILURE = 'jobs.createJobFailure',
}
export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}

export interface UpdateJobAttachmentRequestData {
  jobAttachmentId: string;
  jobId: number;
  fileName: string;
}

export type PartialUpdateJob = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.PARTIAL_UPDATE_JOB_REQUEST;
      payload: {
        jobId: Job['id'];
        data: PartialUpdateJobRequestData;
        callback?: () => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
    success: {
      type: ActionType.UPDATE_JOB_SUCCESS;
      payload: {
        jobId: Job['id'];
      };
    };
    failure: {
      type: ActionType.UPDATE_JOB_FAILURE;
    };
  }
>;
