import { FC, useState } from 'react';

import { ServicePlanPermissions } from '@payaca/permissions/service-plans/service-plans.permissions';
import {
  PublicEntityTemplate,
  PublicHydratedEntityTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';
import { Link } from 'react-router-dom';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import EntityTemplateProposalReadView from './EntityTemplateProposalReadView';
import './EntityTemplateReadView.sass';
import EntityTemplateScheduledEventReadView from './EntityTemplateScheduledEventReadView';
import EntityTemplateTaskReadView from './EntityTemplateTaskReadView';
import EntityTemplateViewHeader from './EntityTemplateViewHeader';

type Props = {
  initialEntityTemplate: PublicHydratedEntityTemplate<TemplatableEntity>;
  onDeleteEntityTemplateSuccess?: () => void;
  onUpdateEntityTemplateSuccess?: (
    updatedEntityTemplate: PublicHydratedEntityTemplate<TemplatableEntity>
  ) => void;
  allowModifyTemplateNameDescription?: boolean;
};
const EntityTemplateReadView: FC<Props> = ({
  initialEntityTemplate,
  onDeleteEntityTemplateSuccess,
  onUpdateEntityTemplateSuccess,
  allowModifyTemplateNameDescription,
}: Props): JSX.Element | null => {
  const [entityTemplate, setEntityTemplate] = useState<
    PublicHydratedEntityTemplate<TemplatableEntity>
  >(initialEntityTemplate);

  if (!entityTemplate) return null;

  return (
    <div className={'entity-template-read-view'}>
      <EntityTemplateViewHeader
        entityTemplate={entityTemplate}
        onDeleteEntityTemplateSuccess={onDeleteEntityTemplateSuccess}
        onUpdateEntityTemplateSuccess={(et) => {
          setEntityTemplate(et);
          onUpdateEntityTemplateSuccess?.(et);
        }}
        allowModifyTemplateNameDescription={allowModifyTemplateNameDescription}
      />
      {entityTemplate.entityType == 'scheduledEvent' && (
        <EntityTemplateScheduledEventReadView
          entityTemplate={
            entityTemplate as PublicHydratedEntityTemplate<'scheduledEvent'>
          }
          onUpdateEntityTemplateSuccess={(et) => {
            setEntityTemplate(et);
            onUpdateEntityTemplateSuccess?.(et);
          }}
        />
      )}
      {entityTemplate.entityType == 'task' && (
        <EntityTemplateTaskReadView
          entityTemplate={entityTemplate as PublicEntityTemplate<'task'>}
          onUpdateEntityTemplateSuccess={(et) => {
            setEntityTemplate(et);
            onUpdateEntityTemplateSuccess?.(et);
          }}
        />
      )}
      {entityTemplate.entityType == 'proposal' && (
        <EntityTemplateProposalReadView
          entityTemplate={entityTemplate as PublicEntityTemplate<'proposal'>}
          onUpdateEntityTemplateSuccess={(et) => {
            setEntityTemplate(et);
            onUpdateEntityTemplateSuccess?.(et);
          }}
        />
      )}

      {!!entityTemplate.servicePlans?.length && (
        <PermissionGuard
          renderIfHasPermissions={[ServicePlanPermissions.PERSIST_SERVICE_PLAN]}
        >
          <div className="linked-service-plans">
            <div className="label">
              This Template is in use by the following Service Plans
            </div>
            <ul>
              {entityTemplate.servicePlans.map((sp, i) => {
                return (
                  <li key={i}>
                    <Link
                      to={`/service-plans/service-plan/${sp.publicId}`}
                      target="_blank"
                    >
                      {sp.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </PermissionGuard>
      )}
    </div>
  );
};
export default EntityTemplateReadView;
