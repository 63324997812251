import {
  Banner,
  ConfirmModal,
  MaintenanceBanner,
  OfflineBanner,
} from '@/ui/components';
import { FC, PropsWithChildren, useEffect } from 'react';
import DevelopmentToolsInitialiser from '../developmentToolsInitialiser/DevelopmentToolsInitialiser';

import { useSelector } from '@/api/state';
import DynamicFeedbackOverlay from '@/ui/components/dynamicFeedbackOverlay/DynamicFeedbackOverlay';

const ENABLE_AWIN_TRACKING =
  import.meta.env.VITE_ENABLE_AWIN_TRACKING === 'true';

type Props = {
  pageBanners?: JSX.Element[];
  className?: string;
};
const PageWrapper: FC<PropsWithChildren<Props>> = ({
  pageBanners,
  children,
  className,
}: PropsWithChildren<Props>): JSX.Element => {
  const banner = useSelector((state: any) => state.app.banner);
  const modal = useSelector((state: any) => state.app.modal);

  // Awin say to include their 'master tag' on every page.
  // Their docs don't acknowledge the existence of these things called 'SPA's,
  // so we need to dynamically add/remove it with every page-level component.
  useEffect(() => {
    let awMastertag: HTMLScriptElement;
    if (ENABLE_AWIN_TRACKING) {
      awMastertag = document.createElement('script');
      awMastertag.setAttribute('defer', 'defer');
      awMastertag.src = '/awin-master-tag.js';

      awMastertag.type = 'text/javascript';
      document.getElementsByTagName('body')[0].appendChild(awMastertag);
    }
    return () => {
      if (ENABLE_AWIN_TRACKING) {
        try {
          document.body.removeChild(awMastertag);
        } catch (err) {}
      }
    };
  }, []);

  return (
    <div
      className={`relative flex h-screen max-h-screen w-full flex-col ${
        className ? className : ''
      }`}
    >
      <DevelopmentToolsInitialiser />
      <div className="sticky top-0">
        <OfflineBanner />
        <MaintenanceBanner />
        {pageBanners}
        {banner && <Banner options={banner} />}
      </div>
      <div className="page-wrapper-inner relative grow overflow-y-auto">
        {children}
      </div>
      <DynamicFeedbackOverlay />
      {modal && <ConfirmModal {...modal} open={true} />}
    </div>
  );
};

export default PageWrapper;
