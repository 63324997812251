import { VALID_PHONE_NUMBER_REGEX } from '@payaca/constants';
import {
  getAllowEmptyValidator,
  getEmailFieldValidator,
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
  getRegexMatchFieldValidator,
  getValueMustNotExistInArrayFieldValidator,
} from './fieldValidationHelper';

export const allowEmptyCustomerContactPhoneNumberFieldValidator =
  getAllowEmptyValidator(
    // Don't use standard phone number validation - this validation is slightly less strict and allows for numbers without country codes
    getRegexMatchFieldValidator(VALID_PHONE_NUMBER_REGEX)
  );
const isRequiredFieldValidator = getIsRequiredFieldValidator();
export const customerContactDescriptionFieldValidator = getLengthFieldValidator(
  { min: 0, max: 200 }
);

export const getCustomerContactEmailFieldValidator = ({
  blockedEmails,
  existingCustomerContactEmails,
}: {
  blockedEmails?: string[];
  existingCustomerContactEmails?: string[];
}) => {
  const emailFieldValidator = getEmailFieldValidator();
  const emailMustNotBeBlockedValidator =
    getValueMustNotExistInArrayFieldValidator(blockedEmails || [], {
      customErrorMessage: 'This email is not allowed',
    });

  const emailMustNotBeExistingValidator =
    getValueMustNotExistInArrayFieldValidator(
      existingCustomerContactEmails || [],
      {
        customErrorMessage:
          'This email is already assigned to another Customer Contact',
      },
      {
        caseSensitive: false,
      }
    );

  return getAllowEmptyValidator(
    (fieldName: string, formState: { [key: string]: any }) => {
      let validationResult = emailFieldValidator(fieldName, formState);
      if (!validationResult.isValid) {
        return validationResult;
      }
      validationResult = emailMustNotBeBlockedValidator(fieldName, formState);
      if (!validationResult.isValid) {
        return validationResult;
      }
      return emailMustNotBeExistingValidator(fieldName, formState);
    }
  );
};
