import React, { FC, useMemo } from 'react';

import { getRegion } from '@/utils/stateAccessors';
import { faMinus, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';
import { getTotalsFromJobLineItems } from '@payaca/helpers/changeProposalHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import * as dealActions from '@payaca/store/deals/dealsActions';
import {
  useAcceptedJobContentForDeal,
  useChangeProposal,
  useChangeProposalsForDeal,
  useDeal,
  useJobLineItemsForDeal,
  useSelector,
} from '@payaca/store/hooks/appState';
import { requestGetJobLineItemsForDeal } from '@payaca/store/jobContent/jobContentActions';
import { requestGetChangeProposal } from '@payaca/store/proposals/proposalsActions';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import { useDispatch } from 'react-redux';
import SetChangeProposalDiscountModal from '../setChangeProposalDiscountModal/SetChangeProposalDiscountModal';
import './ChangeProposalValueSummary.sass';

type Props = {
  changeProposalId: number;
};

const ChangeProposalValueSummary: FC<Props> = ({
  changeProposalId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [showSetDiscountModal, setShowSetDiscountModal] = React.useState(false);
  const changeProposal = useChangeProposal(changeProposalId);
  const changeProposals = useChangeProposalsForDeal(changeProposal?.dealId);
  const deal = useDeal(changeProposal?.dealId);
  const acceptedJobContent = useAcceptedJobContentForDeal(
    changeProposal?.dealId
  );
  const region = useSelector(getRegion);

  const jobLineItems = useJobLineItemsForDeal(changeProposal?.dealId);

  const acceptedJobLineItems = useMemo(() => {
    if (!changeProposal) return [];
    if (!acceptedJobContent) return [];

    const previousChangeProposals = changeProposals.filter((x) => {
      return (
        x.acceptedAt &&
        !x.voidedAt &&
        new Date(x.acceptedAt) < new Date(changeProposal.createdAt)
      );
    });

    const jliIds = [
      ...acceptedJobContent.jobLineItemIds,
      ...previousChangeProposals.flatMap((x) => x.jobLineItemIds),
    ];

    return jliIds
      .map((x) => jobLineItems.find((y) => y.id == x))
      .filter(
        (x) => x && (x.isSelected || (!x.isMultipleChoice && !x.isOptional))
      ) as JobLineItem[];
  }, [acceptedJobContent, changeProposals, jobLineItems]);

  const changeProposalJobLineItems = useMemo(() => {
    if (!changeProposal) return [];

    return changeProposal.jobLineItemIds
      .sort((a, b) => a - b)
      .map((x: number) => jobLineItems.find((y) => y.id == x))
      .filter(
        (x) => x && (x.isSelected || (!x.isMultipleChoice && !x.isOptional))
      ) as JobLineItem[];
  }, [changeProposal?.jobLineItemIds, jobLineItems]);

  const previousTotals = useMemo(
    () => getTotalsFromJobLineItems([...acceptedJobLineItems]),
    [jobLineItems]
  );

  const newTotals = useMemo(
    () =>
      getTotalsFromJobLineItems([
        ...acceptedJobLineItems,
        ...changeProposalJobLineItems,
      ]),
    [jobLineItems]
  );

  if (!deal || !changeProposal) return null;

  return (
    <div className="change-proposal-value-summary">
      <dl className="accepted-total">
        <dt>Accepted total</dt>
        <dd>{currencyPrice(previousTotals.total, region)}</dd>
      </dl>

      {!!changeProposalJobLineItems?.length && (
        <>
          <hr />
          <ul className="job-line-item-value-summaries">
            {changeProposalJobLineItems.map(
              (jobLineItem: JobLineItem, i: number) => {
                return (
                  <li key={i}>
                    <div className="job-line-item-value-summary">
                      <div className="quantity-container">
                        <FontAwesomeIcon
                          icon={jobLineItem.quantity < 0 ? faMinus : faPlus}
                        />
                        <span className="quantity">
                          {Math.abs(jobLineItem.quantity)}
                        </span>
                      </div>
                      <div className="name-description-container">
                        <small className="name">{jobLineItem.name}</small>
                        <MarkdownLabel
                          markdown={jobLineItem.description}
                          className="description"
                        />
                      </div>
                      <div className="price-container">
                        <span>{currencyPrice(jobLineItem.total, region)}</span>
                      </div>
                    </div>
                  </li>
                );
              }
            )}
          </ul>
          <hr />
          <div>
            <h3 className="new-proposal-title">New Proposal</h3>
            <dl className="new-proposal-totals">
              <dt>Subtotal</dt>
              <dd>{currencyPrice(newTotals.subtotal, region)}</dd>
              {!!newTotals.markupTotal && (
                <>
                  <dt>Markup</dt>
                  <dd>{currencyPrice(newTotals.markupTotal, region)}</dd>
                </>
              )}
              {(!!newTotals.discountTotal && (
                <>
                  <dt className="edit-discount">
                    Discount
                    <Button
                      styleVariant={ButtonStyleVariant.ANCHOR}
                      onClick={() => setShowSetDiscountModal(true)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </dt>
                  <dd>
                    {newTotals.discountTotal < 0 ? '+' : '-'}
                    {currencyPrice(Math.abs(newTotals.discountTotal), region)}
                  </dd>
                </>
              )) || (
                <>
                  <dt>
                    <Button
                      styleVariant={ButtonStyleVariant.ANCHOR}
                      onClick={() => setShowSetDiscountModal(true)}
                    >
                      Set discount{' '}
                    </Button>
                  </dt>
                  <dd></dd>
                </>
              )}
              <dt>Tax</dt>
              <dd>{currencyPrice(newTotals.vatTotal, region)}</dd>
              {newTotals.cisTotal != 0 && (
                <>
                  <dt>CIS suffered</dt>
                  <dd>
                    {newTotals.cisTotal < 0 ? '+' : '-'}
                    {currencyPrice(Math.abs(newTotals.cisTotal), region)}
                  </dd>
                </>
              )}
              <dt>Total</dt>
              <dd>{currencyPrice(newTotals.total, region)}</dd>
            </dl>
          </div>
        </>
      )}
      <dl>
        <hr />
        <dt className="value-change">Value change</dt>
        <dd
          className={`value-change ${
            changeProposal.valueChangeIncTax < 0 ? 'negative' : 'positive'
          }`}
        >
          {currencyPrice(changeProposal.valueChangeIncTax, region)}
        </dd>
      </dl>

      <SetChangeProposalDiscountModal
        changeProposalId={changeProposalId}
        isOpen={showSetDiscountModal}
        onClose={() => setShowSetDiscountModal(false)}
        onSetChangeProposalDiscountSuccess={() => {
          dispatch(requestGetChangeProposal(changeProposalId));
          if (changeProposal) {
            dispatch(dealActions.requestGetDeal(changeProposal.dealId));
            dispatch(requestGetJobLineItemsForDeal(changeProposal.dealId));
          }
        }}
      />
    </div>
  );
};

export default ChangeProposalValueSummary;
