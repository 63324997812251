import { Account } from './accountTypes';

export enum DefaultPipelineStages {
  NEW_LEAD = 'New lead',
  PROPOSAL_SENT = 'Proposal sent',
  PROPOSAL_ACCEPTED = 'Proposal accepted',
  INVOICED = 'Invoiced',
  COMPLETE = 'Complete - fully paid',
  EXPIRED_DECLINED = 'Expired / Declined',
}

export const defaultPipelineStages = [
  DefaultPipelineStages.NEW_LEAD,
  DefaultPipelineStages.PROPOSAL_SENT,
  DefaultPipelineStages.PROPOSAL_ACCEPTED,
  DefaultPipelineStages.INVOICED,
  DefaultPipelineStages.COMPLETE,
  DefaultPipelineStages.EXPIRED_DECLINED,
];

export const defaultPipelineAutomations: any = {
  [DefaultPipelineStages.NEW_LEAD]: {
    triggers: ['It contains a draft Invoice/Proposal/Estimate'],
    actions: ['None'],
  },
  [DefaultPipelineStages.PROPOSAL_SENT]: {
    triggers: ['It contains a Proposal that has the "Sent" status badge'],
    actions: ['None'],
  },
  [DefaultPipelineStages.PROPOSAL_ACCEPTED]: {
    triggers: [
      'It contains a Proposal that has been accepted by the customer',
      'It contains a Proposal has been marked as accepted by a Payaca user',
    ],
    actions: ['Proposal accepted e-mail notification'],
  },
  [DefaultPipelineStages.INVOICED]: {
    triggers: ['It contains an Invoice with the "Sent" status badge'],
    actions: ['Payment reminder e-mails'],
  },
  [DefaultPipelineStages.COMPLETE]: {
    triggers: ['It contains an Invoice that has been fully paid'],
    actions: ['Payment receipt e-mails'],
  },
  [DefaultPipelineStages.EXPIRED_DECLINED]: {
    triggers: [
      `It contains a Quote/Estimate that has passed it's "Valid Until" date`,
      `It contains a Quote/Estimate that the customer has declined`,
    ],
    actions: ['None'],
  },
};

export interface PipelineStage {
  id: number;
  title: string;
  index: number;
}

export interface Pipeline {
  id: number;
  title: string;
  colour: string;
  accountId?: Account['id'];
  stages: PipelineStage[];
}

export type PipelineRow = {
  id: number;
  accountId: number;
  title: string;
  colour: string | null;
  created_at: Date;
  updated_at: Date;
};

export enum PipelineUpdates {
  ADD_COLUMN = 'create',
  MOVE_COLUMN = 'move',
  DELETE_COLUMN = 'delete',
  RENAME_COLUMN = 'title',
}
