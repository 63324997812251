import {
  ActionRequiredDraftJob,
  ActionRequiredPaymentOutstandingInvoice,
  ActionRequiredPendingPayment,
  ActionRequiredPendingProposal,
} from '@payaca/types/dashboardTypes';

export interface State {
  isGettingActionRequiredPendingPayments: boolean;
  actionRequiredPendingPayments: ActionRequiredPendingPayment[];

  isGettingActionRequiredDraftJobs: boolean;
  actionRequiredDraftJobs: ActionRequiredDraftJob[];

  isGettingActionRequiredPendingProposals: boolean;
  actionRequiredPendingProposals: ActionRequiredPendingProposal[];

  isGettingActionRequiredPaymentOutstandingInvoices: boolean;
  actionRequiredPaymentOutstandingInvoices: ActionRequiredPaymentOutstandingInvoice[];
}

export enum ActionType {
  REQUEST_GET_ACTION_REQUIRED_PENDING_PROPOSALS = 'dashboard.requestGetActionRequiredPendingProposals',
  GET_ACTION_REQUIRED_PENDING_PROPOSALS_SUCCESS = 'dashboard.getActionRequiredPendingProposalsSuccess',
  GET_ACTION_REQUIRED_PENDING_PROPOSALS_FAILURE = 'dashboard.getActionRequiredPendingProposalsFailure',
  CLEAR_ACTION_REQUIRED_PENDING_PROPOSALS = 'dashboard.clearActionRequiredPendingProposals',

  REQUEST_GET_ACTION_REQUIRED_PENDING_PAYMENTS = 'dashboard.requestGetActionRequiredPendingPayments',
  GET_ACTION_REQUIRED_PENDING_PAYMENTS_SUCCESS = 'dashboard.getActionRequiredPendingPaymentsSuccess',
  GET_ACTION_REQUIRED_PENDING_PAYMENTS_FAILURE = 'dashboard.getActionRequiredPendingPaymentsFailure',
  CLEAR_ACTION_REQUIRED_PENDING_PAYMENTS = 'dashboard.clearActionRequiredPendingPayments',

  REQUEST_GET_ACTION_REQUIRED_DRAFT_JOBS = 'dashboard.requestGetActionRequiredDraftJobs',
  GET_ACTION_REQUIRED_DRAFT_JOBS_SUCCESS = 'dashboard.getActionRequiredDraftJobsSuccess',
  GET_ACTION_REQUIRED_DRAFT_JOBS_FAILURE = 'dashboard.getActionRequiredDraftJobsFailure',
  CLEAR_ACTION_REQUIRED_DRAFT_JOBS = 'dashboard.clearActionRequiredDraftJobs',

  REQUEST_GET_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES = 'dashboard.requestGetActionRequiredPaymentOutstandingInvoices',
  GET_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES_SUCCESS = 'dashboard.getActionRequiredPaymentOutstandingInvoicesSuccess',
  GET_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES_FAILURE = 'dashboard.getActionRequiredPaymentOutstandingInvoicesFailure',
  CLEAR_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES = 'dashboard.clearActionRequiredPaymentOutstandingInvoices',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
}
