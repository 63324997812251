import Alert, { IProps as IAlertProps } from '@payaca/components/plAlert/Alert';
import Badge, { Props as BadgeProps } from '@payaca/components/plBadge/Badge';
import { IButtonProps } from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import ContextMenu from '@payaca/components/plDropdown/ContextMenu';
import { TDropdownItem } from '@payaca/components/plDropdown/DropdownCore';
import { InputSizeVariant } from '@payaca/components/plInput/Input';
import InputGroup from '@payaca/components/plInputGroup/InputGroup';
import { FC, PropsWithChildren } from 'react';

export interface IProps {
  title?: string;
  badge?: PropsWithChildren<BadgeProps>;
  firstAction?: PropsWithChildren<IButtonProps>;
  actions?: TDropdownItem[];
  alerts?: PropsWithChildren<IAlertProps>[];
}

const PreviewDocument: FC<PropsWithChildren<IProps>> = (props) => {
  const {
    title,
    badge,
    firstAction,
    actions = [],
    alerts = [],
    children,
  } = props;

  return (
    <div className="flex">
      <div className="prose mx-auto w-full max-w-[1200px]">
        {/*Alerts*/}
        {!!alerts.length && (
          <div className="mb-4">
            {alerts.map((alert, index) => (
              <Alert key={index} {...alert} />
            ))}
          </div>
        )}

        {/*Header*/}
        <div className="mb-4 flex items-center justify-between gap-4">
          <h1>{title}</h1>

          {badge && <Badge {...badge} />}

          <div className="ml-auto">
            <InputGroup className="bg-white" sizeVariant={InputSizeVariant.MD}>
              {firstAction && (
                <InputGroup.Button
                  variant={EBtnVariant.Outline}
                  {...firstAction}
                />
              )}

              {actions.length > 0 && (
                <ContextMenu
                  as={InputGroup.Button}
                  variant={EBtnVariant.Outline}
                  menuItems={actions}
                />
              )}
            </InputGroup>
          </div>
        </div>

        {/*Main Context*/}
        {children}
      </div>
    </div>
  );
};

export default PreviewDocument;
