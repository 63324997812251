import React, { FunctionComponent } from 'react';
import PlusInCircleIcon from '../svgAssets/PlusInCircleIcon';
import ClickableArea, { ClickableAreaColourVariant } from './ClickableArea';
import './ClickableArea.sass';

type Props = {
  onClick?: () => void;
  className?: string;
  isProcessing?: boolean;
  colourVariant?: ClickableAreaColourVariant;
  isDisabled?: boolean;
  icon?: string;
};

const CreateArea: FunctionComponent<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>
): JSX.Element => {
  return (
    <ClickableArea
      {...props}
      className={`create-area ${props.className ? props.className : ''}`}
    >
      <div className="flex-container flex-center">
        <div className="create-icon-wrapper">
          {props.icon ? (
            typeof props.icon === 'string' ? (
              <img src={props.icon} />
            ) : (
              props.icon
            )
          ) : (
            <PlusInCircleIcon />
          )}
        </div>
        <span>{props.children}</span>
      </div>
    </ClickableArea>
  );
};

export default CreateArea;
