import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import {
  ElectricalFormVersion,
  FinalCircuit,
} from '@payaca/types/electrical-forms-types';
import { FC, useState } from 'react';
import { CreateEditFinalCircuitModal } from './CreateEditFinalCircuitModal';

type Props = {
  circuit: FinalCircuit;
  onPersist: (circuit: FinalCircuit) => void;
  onDelete: () => void;
  onDuplicate: (circuit: FinalCircuit) => void;
  version: ElectricalFormVersion;
};

export const FinalCircuitSummary: FC<Props> = ({
  circuit,
  onPersist,
  onDelete,
  onDuplicate,
  version,
}) => {
  const [shouldShowEditCircuitModal, setShouldShowEditCircuitModal] =
    useState(false);
  const handleEditButtonClicked = () => {
    setShouldShowEditCircuitModal(true);
  };

  const handleDeleteButtonClicked = () => {
    onDelete();
  };

  const handleDuplicateButtonClicked = () => {
    onDuplicate(circuit);
  };

  return (
    <tr>
      <td>{circuit.number}</td>
      <td>{circuit.description}</td>
      <td>
        <Button
          styleVariant={ButtonStyleVariant.ANCHOR}
          onClick={handleDuplicateButtonClicked}
        >
          Duplicate
        </Button>
        <Button
          styleVariant={ButtonStyleVariant.ANCHOR}
          onClick={handleEditButtonClicked}
        >
          Edit
        </Button>
        <Button
          styleVariant={ButtonStyleVariant.ANCHOR}
          onClick={handleDeleteButtonClicked}
        >
          Delete
        </Button>
      </td>
      <CreateEditFinalCircuitModal
        circuit={circuit}
        isOpen={shouldShowEditCircuitModal}
        onClose={() => setShouldShowEditCircuitModal(false)}
        onPersist={onPersist}
        version={version}
      />
    </tr>
  );
};
