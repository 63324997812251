import { Document, FormPrefillPreferences } from '@payaca/types/documentTypes';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { DeclareAsyncAction } from '../types';
export interface State {
  documentsStore: { [key: number]: FetchedEntity<Document> };
  isGettingDocuments: boolean;
  isCreatingDocument: boolean;
  isSendingDocument: boolean;
  isEditingDocument: boolean;
}

export enum ActionType {
  REQUEST_GET_DOCUMENTS_FOR_DEAL = 'documents.requestGetDocumentsForDeal',
  GET_DOCUMENTS_FOR_DEAL_SUCCESS = 'documents.getDocumentsForDealSuccess',
  GET_DOCUMENTS_FOR_DEAL_FAILURE = 'documents.getDocumentsForDealFailure',

  REQUEST_GET_DOCUMENT = 'documents.requestGetDocument',
  REQUEST_GET_DOCUMENT_PREVIEW = 'documents.requestGetDocumentPreview',
  GET_DOCUMENT_SUCCESS = 'documents.getDocumentSuccess',
  GET_DOCUMENT_FAILURE = 'documents.getDocumentFailure',
  CLEAR_DOCUMENTS = 'documents.clearDocuments',

  REQUEST_CREATE_DOCUMENT = 'documents.requestCreateDocument',
  CREATE_DOCUMENT_SUCCESS = 'documents.createDocumentSuccess',
  CREATE_DOCUMENT_FAILURE = 'documents.createDocumentFailure',

  REQUEST_UPLOAD_DOCUMENT = 'documents.requestUploadDocument',
  UPLOAD_DOCUMENT_SUCCESS = 'documents.uploadDocumentSuccess',
  UPLOAD_DOCUMENT_FAILURE = 'documents.uploadDocumentFailure',

  REQUEST_RENAME_DOCUMENT = 'documents.requestRenameDocument',
  RENAME_DOCUMENT_SUCCESS = 'documents.renameDocumentSuccess',
  RENAME_DOCUMENT_FAILURE = 'documents.renameDocumentFailure',

  REQUEST_REMOVE_DOCUMENT = 'documents.requestRemoveDocument',
  REMOVE_DOCUMENT_SUCCESS = 'documents.removeDocumentSuccess',
  REMOVE_DOCUMENT_FAILURE = 'documents.removeDocumentFailure',

  REQUEST_SEND_DOCUMENT = 'documents.requestSendDocument',
  SEND_DOCUMENT_SUCCESS = 'documents.sendDocumentSuccess',
  SEND_DOCUMENT_FAILURE = 'documents.sendDocumentFailure',

  REQUEST_CREATE_DOCUMENT_LINK = 'documents.requestCreateDocumentLink',
  CREATE_DOCUMENT_LINK_SUCCESS = 'documents.createDocumentLinkSuccess',
  CREATE_DOCUMENT_LINK_FAILURE = 'documents.createDocumentLinkFailure',

  REQUEST_GET_DOCUMENT_FOR_TASK = 'documents.requestGetDocumentForTask',

  REQUEST_EDIT_DOCUMENT = 'documents.requestEditDocument',
  EDIT_DOCUMENT_SUCCESS = 'documents.editDocumentSuccess',
  EDIT_DOCUMENT_FAILURE = 'documents.editDocumentFailure',

  UPLOAD_DOCUMENTS_V2_REQUEST = 'document.uploadDocumentsV2:request',
  UPLOAD_DOCUMENTS_V2_SUCCESS = 'document.uploadDocumentsV2:success',
  UPLOAD_DOCUMENTS_V2_FAILURE = 'document.uploadDocumentsV2:failure',

  CREATE_FORM_INSTANCE_FOR_DOCUMENT_REQUEST = 'document.createFormInstanceForDocument:request',
  CREATE_FORM_INSTANCE_FOR_DOCUMENT_SUCCESS = 'document.createFormInstanceForDocument:success',
  CREATE_FORM_INSTANCE_FOR_DOCUMENT_FAILURE = 'document.createFormInstanceForDocument:failure',

  GET_DOCUMENT_PREFILL_PREFERENCES_REQUEST = 'document.getPrefillPreferences:request',
  SET_DOCUMENT_PREFILL_PREFERENCES_REQUEST = 'document.setPrefillPreferences:request',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}

export type GetDocumentPrefillPreferences = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_DOCUMENT_PREFILL_PREFERENCES_REQUEST;
      payload: {
        documentId: number;
        callback?: (prefillPreferences: FormPrefillPreferences) => void;
      };
    };
  }
>;

export type SetDocumentPrefillPreferences = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.SET_DOCUMENT_PREFILL_PREFERENCES_REQUEST;
      payload: {
        documentId: number;
        prefillPreferences: Pick<
          FormPrefillPreferences,
          | 'formApproverUserId'
          | 'customerId'
          | 'customerAddressId'
          | 'siteAddressId'
        >;
        callback?: (prefillPreferences: FormPrefillPreferences) => void;
      };
    };
  }
>;

export type UploadDocumentsV2 = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPLOAD_DOCUMENTS_V2_REQUEST;
      payload: {
        files: {
          uri: string;
          originalName: string;
          fileName: string;
          size: number;
          type: string;
        }[];
        dealId: number;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
    success: { type: ActionType.UPLOAD_DOCUMENTS_V2_SUCCESS };
    failure: { type: ActionType.UPLOAD_DOCUMENTS_V2_FAILURE };
  }
>;

export type CreateFormInstanceForDocument = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_FORM_INSTANCE_FOR_DOCUMENT_REQUEST;
      payload: {
        documentId: number;
        callback?: (formInstancePreviewToken: string) => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;
