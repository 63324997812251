import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector } from '@/api/state';
import { getUserRoles } from '@/utils/stateAccessors';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { AutomationsPermissions } from '@payaca/permissions/automations/automations.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import {
  requestArchiveAutomations,
  requestCreateAutomation,
  requestGetListedAutomationsPage,
} from '@payaca/store/automations/automationActions';
import {
  GetListedAutomationsRequestData,
  ListedAutomation,
  ListedAutomationsListViewPage,
  SortBy,
} from '@payaca/types/automationTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import AutomationsTable from '../automationsTable/AutomationsTable';
import ListedAutomationsFilterControls from '../listedAutomationsFilterControls/ListedAutomationsFilterControls';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import SearchField from '../searchField/SearchField';
import './CustomAutomations.sass';

const CustomAutomations: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userRoles = useSelector(getUserRoles);

  const [automationsPage, setAutomationsPage] =
    useState<ListedAutomationsListViewPage>();

  const [isArchivingAutomation, setIsArchivingAutomation] = useState(false);
  const [isGettingListedAutomationsPage, setIsGettingListedAutomationsPage] =
    useState(false);

  const onClickRow = useCallback(
    (automationId: number) => history.push(`/automations/${automationId}`),
    [history]
  );

  const [
    showConfirmDeleteAutomationsModal,
    setShowConfirmDeleteAutomationsModal,
  ] = useState(false);
  const [selectedAutomationId, setSelectedAutomationId] =
    useState<ListedAutomation['id']>();

  const [getListedAutomationsRequestData, setGetListedAutomationsRequestData] =
    useState<GetListedAutomationsRequestData>({
      sortBy: SortBy.UPDATED_AT,
      sortDirection: SortDirection.DESCENDING,
      pageSize: 20,
      pageNumber: 1,
      searchTerm: '',
    });

  useEffect(() => {
    getListedAutomationsPage();
  }, [getListedAutomationsRequestData]);

  const getListedAutomationsPage = useCallback(() => {
    setIsGettingListedAutomationsPage(true);
    dispatch(
      requestGetListedAutomationsPage(
        getListedAutomationsRequestData,
        (page) => {
          setAutomationsPage(page);
          setIsGettingListedAutomationsPage(false);
        }
      )
    );
  }, [getListedAutomationsRequestData, dispatch]);

  const onChangeFilters = (value: { [key: string]: any }) => {
    setGetListedAutomationsRequestData(
      (getListedAutomationsRequestData: GetListedAutomationsRequestData) => {
        return {
          ...getListedAutomationsRequestData,
          ...value,
          pageNumber: 1,
        };
      }
    );
  };

  const quickActionDefinitions = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      AutomationsPermissions.DELETE_AUTOMATION,
    ])
      ? [
          {
            actionName: 'Delete',
            actionBehaviour: (automationId: number) => {
              setSelectedAutomationId(automationId);
              setShowConfirmDeleteAutomationsModal(true);
            },
          },
        ]
      : [];
  }, [userRoles]);

  const onDeleteAutomation = useCallback(
    (automationId: ListedAutomation['id']) => {
      setIsArchivingAutomation(true);
      dispatch(
        requestArchiveAutomations([automationId], () => {
          setIsArchivingAutomation(false);
          setShowConfirmDeleteAutomationsModal(false);
          setSelectedAutomationId(undefined);
          getListedAutomationsPage();
        })
      );
    },
    [dispatch, getListedAutomationsPage]
  );

  return (
    <ResponsiveViewWrapper
      className="custom-automations"
      downBreakpointSm={800}
    >
      <PermissionGuard
        renderIfHasPermissions={[AutomationsPermissions.ADD_AUTOMATION]}
      >
        <div className="create-button">
          <Button
            onClick={() => {
              dispatch(
                requestCreateAutomation({}, (automationId: number) => {
                  history.push(`/automations/${automationId}`);
                })
              );
            }}
          >
            Create custom automation
          </Button>
        </div>
      </PermissionGuard>
      <SearchField
        placeholder="Search by automation name"
        onSearchTermChange={(searchTerm) => {
          setGetListedAutomationsRequestData((x) => ({
            ...x,
            searchTerm: searchTerm,
            pageNumber: 1,
          }));
        }}
      />
      <ListedAutomationsFilterControls
        getListedAutomationsRequestData={getListedAutomationsRequestData}
        onChange={onChangeFilters}
      />
      <div className="automations-table-and-pagination">
        {automationsPage && (
          <>
            <AutomationsTable
              automations={automationsPage.items}
              onClickRow={onClickRow}
              quickActionDefinitions={quickActionDefinitions}
              isLoading={isGettingListedAutomationsPage}
            />
            <PaginationControl
              pageNumber={automationsPage.pageNumber}
              totalItemCount={automationsPage.totalItemCount}
              pageSize={automationsPage.pageSize}
              onSelectPage={(pageNumber) => {
                setGetListedAutomationsRequestData((x) => ({
                  ...x,
                  pageNumber: pageNumber,
                }));
              }}
            />
          </>
        )}
      </div>
      <Modal
        isOpen={showConfirmDeleteAutomationsModal}
        onClose={() => {
          setShowConfirmDeleteAutomationsModal(false);
        }}
        title={`Are you sure you want to delete 1 automation?`}
        actions={
          <>
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              onClick={() => {
                if (isArchivingAutomation) return;
                selectedAutomationId &&
                  onDeleteAutomation(selectedAutomationId);
              }}
              isProcessing={isArchivingAutomation}
            >
              Delete
            </Button>
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={() => {
                setShowConfirmDeleteAutomationsModal(false);
              }}
            >
              Cancel
            </Button>
          </>
        }
      ></Modal>
    </ResponsiveViewWrapper>
  );
};

export default CustomAutomations;
