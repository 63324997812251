import { getRegion } from '@/utils/stateAccessors';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import { getAvailableTagMetricAnalyticsConfigsForEntityType } from '@payaca/helpers/tagAnalyticsHelper';
import { getTagMetricAnalyticsData } from '@payaca/store/analytics/analyticsActions';
import { useSelector } from '@payaca/store/hooks/appState';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import {
  DataType,
  MetricType,
  TagMetricAnalyticsData,
} from '@payaca/types/tagAnalyticsTypes';
import { TaggableEntityType } from '@payaca/types/tagTypes';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import TagMetricAnalyticsDataVisualisation from '../tagMetricAnalyticsDataVisualisation/TagMetricAnalyticsDataVisualisation';
import './TagAnalyticsControl.sass';

const taggableEntityTypeOptions = [
  { label: 'Project', value: TaggableEntityType.DEAL },
  { label: 'Customer', value: TaggableEntityType.CUSTOMER },
  { label: 'Event', value: TaggableEntityType.SCHEDULED_EVENT },
  { label: 'Line Item', value: TaggableEntityType.LINE_ITEM },
  { label: 'Material', value: TaggableEntityType.MATERIAL },
  { label: 'Supplier', value: TaggableEntityType.SUPPLIER },
];

const TagAnalyticsControl: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch();
  const [selectedEntityType, setSelectedEntityType] = useState<
    TaggableEntityType | undefined
  >(TaggableEntityType.DEAL);

  const [selectedMetricType, setSelectedMetricType] = useState<
    MetricType | undefined
  >(MetricType.TAG_USAGE_SUMMARY);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);

  const [tagMetricAnalyticsData, setTagMetricAnalyticsData] =
    useState<TagMetricAnalyticsData<DataType>>();

  const metricTypeOptions = useMemo(() => {
    if (!selectedEntityType) return [];

    const tagMetricAnalyticsConfigs =
      getAvailableTagMetricAnalyticsConfigsForEntityType(selectedEntityType);

    return Object.keys(tagMetricAnalyticsConfigs).map((metricType: string) => {
      const config = tagMetricAnalyticsConfigs[metricType as MetricType];

      return {
        label: config?.name || '',
        value: metricType,
      };
    });
  }, [selectedEntityType]);

  useEffect(() => {
    if (selectedEntityType && selectedMetricType) {
      setErrorMessage(undefined);
      setIsProcessing(true);
      dispatch(
        getTagMetricAnalyticsData.request({
          entityType: selectedEntityType,
          metricType: selectedMetricType,
          callback: (data) => {
            setIsProcessing(false);
            setTagMetricAnalyticsData(data);
          },
          onErrorCallback: () => {
            setIsProcessing(false);
            setErrorMessage('Something went wrong');
          },
        })
      );
    }
  }, [selectedEntityType, selectedMetricType]);

  const region = useSelector(getRegion);

  return (
    <div className={`tag-analytics-control`}>
      <div className="tag-analytics-control-dropdowns">
        <DropdownField
          emptyText="Select an entity type"
          options={taggableEntityTypeOptions}
          name="entityType"
          onChange={(value) => {
            setSelectedEntityType(value.entityType);
            setSelectedMetricType(undefined);
          }}
          value={selectedEntityType}
        />
        {selectedEntityType && (
          <DropdownField
            emptyText="Select a metric"
            options={metricTypeOptions}
            name="metricType"
            onChange={(value) => setSelectedMetricType(value.metricType)}
            value={selectedMetricType}
          />
        )}
        {isProcessing && <MiniLoader />}
      </div>

      {errorMessage && (
        <FeedbackBlock feedbackLevel={FeedbackLevel.ERROR}>
          {errorMessage}
        </FeedbackBlock>
      )}
      {tagMetricAnalyticsData && (
        <TagMetricAnalyticsDataVisualisation
          tagMetricAnalyticsData={tagMetricAnalyticsData}
          region={region}
        />
      )}
    </div>
  );
};

export default TagAnalyticsControl;
