import Loader from '../loader/Loader';

import './LoaderOverlay.sass';

const LoaderOverlay = () => {
  return (
    <div className="loader-overlay">
      <Loader />
    </div>
  );
};

export default LoaderOverlay;
