import useUpdateProject from '@/api/mutations/project/useUpdateProject';
import projectKeys from '@/api/queries/project/keyFactory';
import useGetProjectOverview from '@/api/queries/project/useGetProjectOverview';
import { ProjectQuery } from '@/gql/graphql';
import Combobox from '@payaca/components/plCombobox/Combobox';
import { SelectSizeVariant } from '@payaca/components/plSelect/Select';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const PipelineControl: FC = () => {
  const { dealId } = useParams<{ dealId: string }>();
  const { data: projectData } = useGetProjectOverview(+dealId);
  const queryClient = useQueryClient();
  const { mutateAsync: updateProjectMutation } = useUpdateProject({
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: projectKeys.overview(+dealId),
      });

      // Snapshot the previous value
      const previousProjectOver = queryClient.getQueryData(
        projectKeys.overview(+dealId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<ProjectQuery>(
        projectKeys.overview(+dealId),
        (old) => {
          if (!old) return;

          let newSelectedPipeline = old.me.user.account.pipelines.find(
            (i) => i.id === variables.pipelineId
          );

          if (!newSelectedPipeline) {
            // Set as "Default" pipeline, which has the id of "0"
            newSelectedPipeline = old.me.user.account.pipelines.find(
              (i) => i.id === '0'
            );
          }

          if (!newSelectedPipeline) {
            // Should never get here
            return old;
          }

          return {
            ...old,
            project: {
              ...old.project,
              pipeline: newSelectedPipeline,
            },
          };
        }
      );

      // Return a context object with the snapshotted value
      return { previousProjectOver };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        projectKeys.overview(+dealId),
        // @ts-ignore
        context.previousProjectOver
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.project(+dealId),
      });
    },
  });

  const selectedPipelineId = useMemo(() => {
    if (!projectData || !projectData?.project?.pipeline) return;

    return projectData.project.pipeline.id;
  }, [projectData]);

  const availablePipelineStages = useMemo(() => {
    if (!projectData || !projectData?.me?.user?.account?.pipelines) return [];

    return projectData.me.user.account.pipelines.map((pipeline) => ({
      value: pipeline.id,
      label: pipeline.name,
      metadata: pipeline,
    }));
  }, [projectData]);

  const handleChange = useCallback(
    async (value: string | string[]) => {
      if (Array.isArray(value)) {
        // Should never get here
        return;
      }

      await updateProjectMutation({
        projectId: dealId,
        pipelineId: value === '0' ? null : value,
      });
    },
    [updateProjectMutation, dealId]
  );

  return (
    <Combobox
      CustomSelected={({ selectedOptions }) =>
        selectedOptions[0] ? (
          <div className="flex items-center gap-1.5">
            <b>Pipeline:</b>
            <div
              className="h-3 w-3 rounded-full"
              style={{ backgroundColor: selectedOptions[0].metadata?.colour }}
            />
            {selectedOptions[0].label}
          </div>
        ) : null
      }
      CustomOption={({ option }) => (
        <div className="flex items-center gap-1.5">
          <div
            className="h-3 w-3 rounded-full"
            style={{ backgroundColor: option.metadata?.colour }}
          />
          {option.label}
        </div>
      )}
      sizeVariant={SelectSizeVariant.SM}
      value={selectedPipelineId}
      options={availablePipelineStages}
      onChange={handleChange}
    />
  );
};

export default PipelineControl;
