import { useSelector } from '@/api/state';
import ListedLineItemGroups from '@/ui/components/listedLineItemGroups/ListedLineItemGroups';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import Button from '@payaca/components/button/Button';
import { LineItemsPermissions } from '@payaca/permissions/lineItems/line-items.permissions';
import * as lineItemGroupActions from '@payaca/store/lineItemGroups/lineItemGroupsActions';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ListViewPageWrapper from '../pageWrappers/listViewPageWrapper/ListViewPageWrapper';
import './ListedItemGroupsPage.sass';

const ListedItemGroupsPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lineItemGroups = useSelector(
    (state) => state.lineItemGroups.lineItemGroups
  );

  const createdLineItemGroupId = useSelector(
    (state) => state.lineItemGroups.createLineItemGroupSuccessId
  );

  const listedLineItemGroups = useMemo(
    () => Object.values(lineItemGroups),
    [lineItemGroups]
  );

  useEffect(() => {
    dispatch(lineItemGroupActions.requestGetLineItemGroups());
    return () => {
      dispatch(lineItemGroupActions.clearCreateLineItemGroup());
    };
  }, []);

  useEffect(() => {
    if (createdLineItemGroupId) {
      history.push(`/items/groups/${createdLineItemGroupId}`);
    }
  }, [createdLineItemGroupId]);

  return (
    <ListViewPageWrapper
      previousPageNavigationConfig={{
        route: '/items',
        navigationPromptName: 'Back to items',
      }}
      className="listed-item-groups-page"
      title={
        <div className="flex-container flex-center">
          <div>
            <h1>Item Groups</h1>
          </div>
          <PermissionGuard
            renderIfHasPermissions={[LineItemsPermissions.ADD_LINE_ITEM_GROUP]}
          >
            <Button
              onClick={() =>
                dispatch(lineItemGroupActions.requestCreateLineItemGroup())
              }
            >
              Create Item Group
            </Button>
          </PermissionGuard>
        </div>
      }
      paginationConfig={{
        pageNumber: 1,
        totalItemCount: listedLineItemGroups.length,
        pageSize: listedLineItemGroups.length,
        onSelectPage: () => null,
      }}
    >
      <ListedLineItemGroups
        onLineItemGroupClick={(lineItemGroupId: number) =>
          history.push(`/items/groups/${lineItemGroupId}`)
        }
      />
    </ListViewPageWrapper>
  );
};

export default ListedItemGroupsPage;
