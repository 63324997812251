import moment from 'moment-timezone';
import { FC, useCallback } from 'react';

import { formatAnswer } from '@payaca/helpers/formHelper';
import CompanyLogo from '../companyLogo/CompanyLogo';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import {
  useCertificateNumber,
  useCommonFormAccountData,
  useInspectionFormAddresses,
} from './hooks';

import './FormTemplateElements.sass';
import './GasSafetyCertificateTemplate.sass';

import GasSafeLogo from './gas-safe.png';

interface Props {
  data: any;
  account: any;
}

const NonDomesticGasSafetyCertificateTemplate: FC<Props> = ({
  data,
  account,
}) => {
  const { brandColour } = useCommonFormAccountData(account);
  const {
    accountAddressString,
    inspectionAddressString,
    landlordAddressString,
  } = useInspectionFormAddresses(account, data);
  const certificateNumber = useCertificateNumber(data);

  const renderAppliancePanel = useCallback(
    (appliance: any, applianceIndex: number) => {
      return (
        <OutlinedContentPanel
          colourOverride={brandColour}
          className="appliance-content-panel"
          key={`appliance-${applianceIndex}`}
          title={`Appliance ${applianceIndex + 1}`}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="appliance-content-panel-body">
            {/* <div> */}
            <LabelValuePair
              label={'Location'}
              value={formatAnswer(appliance, `location`)}
            />
            <LabelValuePair
              label={'Make'}
              value={formatAnswer(
                appliance,
                `boiler_model_search.make`,
                'make'
              )}
            />
            <LabelValuePair
              label={'Model'}
              value={formatAnswer(
                appliance,
                `boiler_model_search.model`,
                'model'
              )}
            />
            <LabelValuePair
              label={'Type'}
              value={formatAnswer(
                appliance,
                `boiler_model_search.type`,
                'type'
              )}
            />
            <LabelValuePair
              label={'Flue Type'}
              value={formatAnswer(appliance, `flue_type`)}
            />
            <LabelValuePair
              label={'GC Number'}
              value={formatAnswer(appliance, `gc_number`)}
            />
            <LabelValuePair
              label={'Operating Pressure (mBar) or heat input (kW/h or Btu/h)'}
              value={formatAnswer(appliance, `pressure`)}
            />
            <LabelValuePair
              label={'Safety Device(s) correct operation'}
              value={formatAnswer(appliance, `safety_devices`)}
            />
            <LabelValuePair
              label={'Spillage test'}
              value={formatAnswer(appliance, `spillage_test`)}
            />
            <LabelValuePair
              label={'Smoke Pellet Flue Flow Test'}
              value={formatAnswer(appliance, `smoke_pellet_flue_flow_test`)}
            />
            <LabelValuePair
              label={'Satisfaction Termination'}
              value={formatAnswer(appliance, `satisfactory_termination`)}
            />

            <LabelValuePair
              label={'Flue visual condition'}
              value={formatAnswer(appliance, `flue_visual_condition`)}
            />
            <LabelValuePair
              label={'Adequate ventilation'}
              value={formatAnswer(appliance, `adequate_ventilation`)}
            />
            <LabelValuePair
              label={`Landlord's appliance`}
              value={formatAnswer(appliance, `landlords_appliance`)}
            />
            {formatAnswer(appliance, `inspected`) && (
              <LabelValuePair
                label={'Inspected'}
                value={formatAnswer(appliance, `inspected`)}
              />
            )}
            <LabelValuePair
              label={'Appliance visual check'}
              value={formatAnswer(appliance, `appliance_visual_check`)}
            />
            <LabelValuePair
              label={'Appliance serviced'}
              value={formatAnswer(appliance, `appliance_serviced`)}
            />
            <LabelValuePair
              label={'Appliance safe to use'}
              value={formatAnswer(appliance, `appliance_safe_to_use`)}
            />
            <LabelValuePair
              label={'Interlocks'}
              value={formatAnswer(appliance, `interlocks_satisfactory`)}
            />
            <LabelValuePair
              label={'Comments'}
              value={formatAnswer(appliance, `interlocks_comments`)}
            />
            <LabelValuePair
              label={'NOx Readings'}
              value={formatAnswer(appliance, `nox_readings`)}
            />
            <LabelValuePair
              label={'Plant room ventilation'}
              value={formatAnswer(appliance, `plant_room_ventilation`)}
            />
            <LabelValuePair
              label={'FGA min'}
              value={
                <div>
                  <LabelValuePair
                    label={'Ratio'}
                    value={formatAnswer(
                      appliance,
                      `initial_combustion_reading.initial_combustion_ratio`
                    )}
                  />
                  <LabelValuePair
                    label={'CO ppm'}
                    value={formatAnswer(
                      appliance,
                      `initial_combustion_reading.initial_combustion_co_ppm`
                    )}
                  />
                  <LabelValuePair
                    label={'CO2 (%)'}
                    value={formatAnswer(
                      appliance,
                      `initial_combustion_reading.initial_combustion_co2_percentage`
                    )}
                  />
                  <LabelValuePair
                    label={'O2'}
                    value={formatAnswer(
                      appliance,
                      `initial_combustion_reading.initial_combustion_o2`
                    )}
                  />
                  <LabelValuePair
                    label={'Flue temp'}
                    value={formatAnswer(
                      appliance,
                      `initial_combustion_reading.initial_combustion_flue_temp`
                    )}
                  />
                  <LabelValuePair
                    label={'Efficiency (%)'}
                    value={formatAnswer(
                      appliance,
                      `initial_combustion_reading.initial_combustion_efficiency`
                    )}
                  />
                </div>
              }
            />
            <LabelValuePair
              label={'FGA max'}
              value={
                <div>
                  <LabelValuePair
                    label={'Ratio'}
                    value={formatAnswer(
                      appliance,
                      `final_combustion_reading.final_combustion_ratio`
                    )}
                  />
                  <LabelValuePair
                    label={'CO ppm'}
                    value={formatAnswer(
                      appliance,
                      `final_combustion_reading.final_combustion_co_ppm`
                    )}
                  />
                  <LabelValuePair
                    label={'CO2 (%)'}
                    value={formatAnswer(
                      appliance,
                      `final_combustion_reading.final_combustion_co2_percentage`
                    )}
                  />
                  <LabelValuePair
                    label={'O2'}
                    value={formatAnswer(
                      appliance,
                      `final_combustion_reading.final_combustion_o2`
                    )}
                  />
                  <LabelValuePair
                    label={'Flue temp'}
                    value={formatAnswer(
                      appliance,
                      `final_combustion_reading.final_combustion_flue_temp`
                    )}
                  />
                  <LabelValuePair
                    label={'Efficiency (%)'}
                    value={formatAnswer(
                      appliance,
                      `final_combustion_reading.final_combustion_efficiency`
                    )}
                  />
                </div>
              }
            />
          </div>
        </OutlinedContentPanel>
      );
    },
    []
  );

  if (!data || !account) return <></>;

  return (
    <div
      className={
        'form-template-output non-domestic-gas-safety-certificate-template'
      }
    >
      <div className={'form-template-header'}>
        <div>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Non Domestic Gas Safety Record</h3>
        </div>
        <div className={'flex-container flex-center'}>
          <div className="header-details-container">
            <LabelValuePair label="Cert No" value={certificateNumber} />
            <LabelValuePair
              label="No of Appliances"
              value={data.appliances?.length}
            />
          </div>
          {data.gas_safe_reg_number && (
            <img
              className="gas-safe-logo"
              src={GasSafeLogo}
              alt={'Gas Safe Logo'}
            />
          )}
        </div>
      </div>
      <div className="form-template-row company-inspection-landlord-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          className="company-details-content-panel"
          title={'Company/Installer'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="company-details-content-panel-body">
            <LabelValuePair label="Engineer" value={data.completed_by} />
            <LabelValuePair label="Company" value={account?.companyName} />
            <LabelValuePair label="Address" value={accountAddressString} />
            <LabelValuePair label="Contact" value={account?.contactNumber} />
            {data.gas_safe_reg_number && (
              <LabelValuePair
                label="Gas Safe Reg"
                value={data.gas_safe_reg_number}
              />
            )}
            {data.id_card_number && (
              <LabelValuePair
                label="ID Card Number"
                value={data.id_card_number}
              />
            )}

            <LabelValuePair label="E-Mail" value={account?.email} />
          </div>
        </OutlinedContentPanel>

        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Inspection/Installation Address'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.name} />
          <LabelValuePair label="Address" value={inspectionAddressString} />
          <LabelValuePair label="E-Mail" value={data.email} />
          <LabelValuePair label="Contact" value={data.contact} />
        </OutlinedContentPanel>

        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Landlord/Agent Details'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.landlord_name} />
          <LabelValuePair label="Address" value={landlordAddressString} />
          <LabelValuePair label="E-mail" value={data.landlord_email} />
          <LabelValuePair label="Contact" value={data.landlord_contact} />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row appliances-details">
        <div className="appliances-details-content-panel-body">
          {data?.appliances?.map(renderAppliancePanel)}
        </div>
      </div>
      <div className="form-template-row findings-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Gas Installation Pipework'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Satisfactory Visual Inspection"
            value={formatAnswer(data.satisfactory_visual_inspection)}
          />
          <LabelValuePair
            label="Emergency Control Accessible"
            value={formatAnswer(data.emergency_control_accessible)}
          />
          <LabelValuePair
            label="Satisfactory Gas Tightness Test"
            value={formatAnswer(data.satisfactory_gas_tightness)}
          />
          <LabelValuePair
            label="Equipotential Bonding Satisfactory"
            value={formatAnswer(data.equipotential_bonding)}
          />
        </OutlinedContentPanel>

        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Findings and Actions'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Faults" value={data.fault_details || 'None'} />
          <LabelValuePair
            label="Rectification Work Carried Out"
            value={data.rectification_work || 'None'}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Completed by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.completed_by} />
          <LabelValuePair
            label="Signed"
            value={
              <div className={'form-template-signature'}>
                {data.engineer_signature && (
                  <img src={data.engineer_signature} />
                )}
              </div>
            }
          />
          <LabelValuePair
            label="Inspection conducted"
            value={moment(data.issue_date).format('DD/MM/YYYY')}
            noBreak={true}
          />
          <LabelValuePair
            label="Next Inspection Date"
            value={moment(data.next_inspection_due_date).format('DD/MM/YYYY')}
            noBreak={true}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Received by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
          className="customer-signature"
        >
          <div>
            <LabelValuePair label="Name" value={data.name} />
            <LabelValuePair
              label="Signed"
              value={
                <div className={'form-template-signature'}>
                  {data.customer_signature && (
                    <img src={data.customer_signature} />
                  )}
                </div>
              }
            />
          </div>
          {data.customer_not_present && <p>Customer not present</p>}
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row form-footer">
        <p>
          Safety Inspection and reporting carried out in accordance with the Gas
          Safety (Installation and Use) Regulations and the Gas Industry Unsafe
          Situations Procedure.
        </p>
      </div>
    </div>
  );
};

export default NonDomesticGasSafetyCertificateTemplate;
