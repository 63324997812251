import { Menu } from '@headlessui/react';
import UntitledIcon from '@payaca/untitled-icons';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import UntitledLoading from '../plUntitledLoading/UntitledLoading';

export type TDropdownItem = {
  label: string | JSX.Element;
  onClick: (selected: boolean) => void;
  selected?: boolean;
  disabled?: boolean;
  isProcessing?: boolean;
};

export interface IProps extends ComponentPropsWithoutRef<'div'> {
  items: TDropdownItem[];
  static?: boolean;
}

const DropdownCore = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { items, className, static: _static = false, ...rest } = props;

  return (
    <Menu.Items static={_static}>
      <div
        ref={ref}
        className={
          'duration mt-2 min-w-[15rem] rounded-lg bg-white p-2 shadow-md transition-[opacity,margin] before:absolute before:-top-4 before:start-0 before:h-4 before:w-full after:absolute after:-bottom-4 after:start-0 after:h-4 after:w-full' +
          (className ? ' ' + className : '')
        }
        {...rest}
      >
        {items.map(
          ({ onClick, label, selected, disabled, isProcessing }, index) => (
            <Menu.Item key={index} disabled={disabled}>
              {({ active, disabled, close }) => (
                <button
                  className={
                    'flex w-full cursor-pointer items-center gap-x-3.5 rounded-lg px-3 py-2 text-base hover:bg-gray-100 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50' +
                    (active ? ' bg-gray-100' : ' bg-transparent')
                  }
                  onClick={() => onClick(!selected)}
                  disabled={disabled}
                >
                  {label}{' '}
                  {isProcessing && (
                    <UntitledLoading className="ml-auto h-4 w-4" />
                  )}
                  {selected && (
                    <UntitledIcon
                      name="check"
                      className="ml-auto h-3.5 w-3.5 shrink-0 text-blue-600"
                    />
                  )}
                </button>
              )}
            </Menu.Item>
          )
        )}
      </div>
    </Menu.Items>
  );
});

export default DropdownCore;
