import { FC } from 'react';

import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import Modal from '@payaca/components/modal/Modal';

import { Nullish } from '@payaca/utilities/types';

export type ConfirmDeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmDelete: () => void;
  entityName: {
    singular: string;
    plural: string;
  };
  isBusy?: Nullish<boolean>;
  errorMessage?: Nullish<string>;
  numSelected?: Nullish<number>;
};

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({
  isOpen,
  onClose,
  onConfirmDelete,
  entityName,
  isBusy,
  errorMessage,
  numSelected,
}: ConfirmDeleteModalProps) => {
  return (
    <Modal
      className="confirm-delete-modal"
      isOpen={isOpen}
      onClose={onClose}
      title={`Delete ${entityName.singular}`}
      size="xs"
      actions={
        <>
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            colourVariant={ButtonColourVariant.RED}
            onClick={onConfirmDelete}
            isProcessing={Boolean(isBusy)}
          >
            Yes, delete
          </Button>
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            colourVariant={ButtonColourVariant.SECONDARY}
            onClick={onClose}
            isDisabled={Boolean(isBusy)}
          >
            No, cancel
          </Button>
        </>
      }
    >
      <p style={{ textAlign: 'center' }}>
        Are you sure you want to delete{' '}
        {(numSelected ?? 1) === 1
          ? `this ${entityName.singular}`
          : `the selected ${entityName.plural}`}
        ?
      </p>

      {errorMessage && <ErrorMessage message={errorMessage} />}
    </Modal>
  );
};

export default ConfirmDeleteModal;
