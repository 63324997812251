import { FC, useMemo } from 'react';

import { getRegion } from '@/utils/stateAccessors';

import BasicField from '@payaca/components/basicField/BasicField';
import FormattedNumberField, {
  NumberFormatVariant,
} from '@payaca/components/formattedNumberField/FormattedNumberField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';

import { useSelector } from '@/api/state';
import { AccountRegions } from '@payaca/types/accountTypes';

type Props = {
  isReadOnly?: boolean;
  name: string;
  onChange: (value: { [key: string]: any }) => void;
  value: string;
};
const AccountNumberField: FC<Props> = ({
  isReadOnly = false,
  name,
  onChange,
  value,
}: Props): JSX.Element => {
  const region = useSelector(getRegion);

  const accountNumberField = useMemo(() => {
    switch (region) {
      case AccountRegions.AUSTRALIA:
        return (
          <FormattedNumberField
            name={name}
            label="Account number"
            value={value}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={onChange}
            formatVariant={NumberFormatVariant.AU_ACCOUNT_NUMBER}
            isDisabled={isReadOnly}
          />
        );
      case AccountRegions.CANADA:
        return (
          <BasicField
            name={name}
            label="Account number"
            value={value}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={onChange}
            isDisabled={isReadOnly}
          />
        );
      case AccountRegions.NEW_ZEALAND:
        return (
          <FormattedNumberField
            name={name}
            label="Account number"
            value={value}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={onChange}
            formatVariant={NumberFormatVariant.NZ_ACCOUNT_NUMBER}
            isDisabled={isReadOnly}
          />
        );
      case AccountRegions.SOUTH_AFRICA:
        return (
          <BasicField
            name={name}
            label="Account number"
            value={value}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={onChange}
            isDisabled={isReadOnly}
          />
        );
      case AccountRegions.UK:
        return (
          <FormattedNumberField
            name={name}
            label="Account number"
            value={value}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={onChange}
            formatVariant={NumberFormatVariant.ACCOUNT_NUMBER}
            isDisabled={isReadOnly}
          />
        );
      case AccountRegions.US:
        return (
          <BasicField
            name={name}
            label="Account number"
            value={value}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={onChange}
            isDisabled={isReadOnly}
          />
        );
      default:
        return <></>;
    }
  }, [onChange, value, region, isReadOnly]);

  return accountNumberField;
};

export default AccountNumberField;
