import { getReadableTextColour } from '@payaca/utilities/colourUtilities';
import { ApexOptions } from 'apexcharts';
import { FC, useMemo } from 'react';
import Chart from 'react-apexcharts';

export const blueScaleOrder = [900, 800, 700, 600, 500, 400, 300, 200, 100, 50];

export const blueScale: Record<number, string> = {
  900: '#1D2540',
  800: '#23304F',
  700: '#2A3C5F',
  600: '#30496E',
  500: '#36577D',
  400: '#55769B',
  300: '#7796B6',
  200: '#9BB5CE',
  100: '#C2D4E4',
  50: '#ECF2F7',
};

export type DonutSlice = {
  value: number;
  label: string;
  colour?: string;
  textColour?: string;
};

export interface IProps {
  slices: DonutSlice[];
  height?: number | string;
  width?: number | string;
  options?: ApexOptions;
}

const DonutChartTooltip = ({ series, seriesIndex, w }: any, textColor: any) => {
  const { globals } = w;
  const { colors } = globals;

  return `<div class='apexcharts-tooltip-series-group z-popover' style='background-color: ${colors[seriesIndex]}; display: block;'>
    <div class='apexcharts-tooltip-text' style='font-family: Helvetica, Arial, sans-serif; font-size: 12px;'>
      <div class='apexcharts-tooltip-y-group' style='color: ${textColor[seriesIndex]}'>
        <span class='apexcharts-tooltip-text-y-label'>${globals.labels[seriesIndex]}</span>
      </div>
    </div>
  </div>`;
};

const DonutChart: FC<IProps> = (props) => {
  const { slices, options, height = 230, width = 230 } = props;

  const [sliceColours, sliceReadableColours] = useMemo(() => {
    const blueScaleInOrder = blueScaleOrder.map((i) => blueScale[i]);
    const len = blueScaleInOrder.length;

    const _sliceColours: string[] = [];
    const _sliceReadableColours: string[] = [];

    for (let i = 0; i < slices.length; i++) {
      let colour = slices[i].colour;
      let readableColour = slices[i].textColour;
      if (!colour) {
        const colourIndex = ((i % len) + len) % len;
        colour = blueScaleInOrder[colourIndex];
      }
      if (!readableColour) {
        readableColour = getReadableTextColour(colour)!;
      }

      _sliceColours.push(colour);
      _sliceReadableColours.push(readableColour);
    }

    return [_sliceColours, _sliceReadableColours];
  }, [slices]);

  return (
    <Chart
      options={{
        grid: {
          padding: {
            top: -12,
            bottom: -9,
            left: -12,
            right: -12,
          },
        },
        stroke: {
          width: 5,
          colors: ['rgb(255, 255, 255)'],
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '50%',
            },
          },
        },
        labels: slices.map((s) => s.label),
        colors: sliceColours,
        tooltip: {
          enabled: true,
          custom: function (_props) {
            return DonutChartTooltip(_props, sliceReadableColours);
          },
        },
        ...options,
      }}
      series={slices.map((s) => s.value)}
      type="donut"
      width={width}
      height={height}
    />
  );
};

export default DonutChart;
