import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  cancelJobPaymentIntent,
  clearJobPayments,
  confirmJobPaymentFailure,
  confirmJobPaymentSuccess,
  getJobPaymentFailure,
  getJobPaymentsForDealSuccess,
  getJobPaymentSuccess,
  payInvoices,
  persistJobPayment,
  persistJobPaymentIntent,
  recordJobPaymentFailure,
  recordJobPaymentSuccess,
} from './jobPaymentsActions';
import {
  ActionType,
  CancelJobPaymentIntent,
  ConfirmJobPaymentRequestData,
  PersistJobPayment,
  PersistJobPaymentIntent,
  RecordJobPaymentRequestData,
  SagaConfig,
} from './jobPaymentsTypes';

import { Req } from '@payaca/helpers/storeHelper';
import { StripePaymentIntentInformation } from '@payaca/types/jobPaymentTypes';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { handleAsyncAction } from '../utils';

const jobPaymentsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);
  const providerReq = Req(
    `${apiBaseurl}/provider/rest`,
    getAuthHeader,
    isNativeApp
  );

  function* handleRecordJobPayment(
    action: PayloadAction<
      ActionType.REQUEST_RECORD_JOB_PAYMENT,
      {
        recordJobPaymentRequestData: RecordJobPaymentRequestData;
        callback?: (error?: string) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          recordJobPayment,
          action.payload.recordJobPaymentRequestData
        ),
        timeout: delay(20000),
      });

      if (timeout) {
        yield put(
          recordJobPaymentFailure(new Error('Record job payment timed out.'))
        );
        action.payload?.callback?.('Record job payment timed out.');
      } else {
        yield put(recordJobPaymentSuccess());
        action.payload?.callback?.();
      }
    } catch (error: any) {
      yield put(recordJobPaymentFailure(error));
      action.payload?.callback?.(error.message);
    }
  }

  function* handleConfirmJobPayment(
    action: PayloadAction<
      ActionType.REQUEST_CONFIRM_JOB_PAYMENT,
      {
        confirmJobPaymentRequestData: ConfirmJobPaymentRequestData;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          confirmJobPayment,
          action.payload.confirmJobPaymentRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          confirmJobPaymentFailure(new Error('Confirm job payment timed out.'))
        );
      } else {
        yield put(confirmJobPaymentSuccess());
        action.payload.callback?.();
      }
    } catch (error: any) {
      yield put(confirmJobPaymentFailure(error));
    }
  }

  function* handleGetJobPayment(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_PAYMENT,
      {
        jobPaymentId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getJobPayment, action.payload.jobPaymentId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get job payment timed out.';
        yield put(
          getJobPaymentFailure(
            action.payload.jobPaymentId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(getJobPaymentSuccess(action.payload.jobPaymentId, response));
      }
    } catch (error: any) {
      yield put(getJobPaymentFailure(action.payload.jobPaymentId, error));
    }
  }

  function* handleGetJobPaymentsForDeal(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_PAYMENTS_FOR_DEAL,
      {
        dealId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getJobPaymentsForDeal, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getJobPaymentsForDeal request timed out');
      }

      yield put(getJobPaymentsForDealSuccess(response));
    } catch (error: any) {}
  }

  const getJobPaymentsForDeal = async (dealId: number) => {
    return req.get(`/job_payments/deal/${dealId}`).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobPaymentsForDeal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobPayment = async (jobPaymentId: number) => {
    return req.get(`/job_payments/${jobPaymentId}`).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobPayment failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const recordJobPayment = async (
    recordJobPaymentRequestData: RecordJobPaymentRequestData
  ) => {
    return req
      .post(`/job_payments/record`, recordJobPaymentRequestData)
      .then((response) => {
        if (response.ok) {
          return;
        } else {
          throw new Error(
            `Record job payment failed: ${response.status} ${response.statusText}`
          );
        }
      });
  };

  const confirmJobPayment = async (
    confirmJobPaymentRequestData: ConfirmJobPaymentRequestData
  ) => {
    return req
      .post('/job_payments/confirm', confirmJobPaymentRequestData)
      .then((response) => {
        if (response.ok) {
          return;
        } else {
          throw new Error(
            `Confirm job payment failed: ${response.status} ${response.statusText}`
          );
        }
      });
  };

  const handlePersistJobPaymentIntent =
    handleAsyncAction<PersistJobPaymentIntent>(
      persistJobPaymentIntent,
      async (payload) => {
        const response = await req.post(
          '/job_payments/payment_intent',
          payload
        );
        return response.json();
      },
      (response, payload) => {
        payload.payload?.callback?.(response as StripePaymentIntentInformation);
      },
      (_, payload) => {
        payload.payload?.callback?.();
      }
    );

  const handleCancelJobPaymentIntent =
    handleAsyncAction<CancelJobPaymentIntent>(
      cancelJobPaymentIntent,
      async (payload) => {
        const response = await req.put(
          '/job_payments/payment_intent/cancel',
          payload
        );
        return response;
      },
      (_, payload) => {
        payload.payload?.callback?.();
      },
      (_, payload) => {
        payload.payload?.onErrorCallback?.();
      }
    );

  const handlePersistJobPayment = handleAsyncAction<PersistJobPayment>(
    persistJobPayment,
    async (payload) => {
      const response = await req.post('/job_payments/persist', payload);
      return response;
    },
    (_, payload) => {
      payload.payload?.callback?.();
    }
  );

  const handlePayInvoices = handleAsyncAction(
    payInvoices,
    async (payload) => {
      const response = await providerReq.post(
        `/payments/bulk/pay-invoices`,
        payload.requestData
      );
      return;
    },
    async (response: any, requestData) => {
      requestData.payload.callback?.();
    },
    async (error: any, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  function* handleAppLogout() {
    yield put(clearJobPayments());
  }

  return function* () {
    yield takeEvery(ActionType.PAY_INVOICES_REQUEST, handlePayInvoices);
    yield takeEvery(
      ActionType.REQUEST_RECORD_JOB_PAYMENT,
      handleRecordJobPayment
    );
    yield takeEvery(
      ActionType.REQUEST_CONFIRM_JOB_PAYMENT,
      handleConfirmJobPayment
    );
    yield takeLatest(ActionType.REQUEST_GET_JOB_PAYMENT, handleGetJobPayment);
    yield takeLatest(
      ActionType.REQUEST_GET_JOB_PAYMENTS_FOR_DEAL,
      handleGetJobPaymentsForDeal
    );
    yield takeEvery(
      ActionType.PERSIST_JOB_PAYMENT_INTENT_REQUEST,
      handlePersistJobPaymentIntent
    );
    yield takeEvery(
      ActionType.PERSIST_JOB_PAYMENT_REQUEST,
      handlePersistJobPayment
    );
    yield takeEvery(
      ActionType.CANCEL_JOB_PAYMENT_INTENT_REQUEST,
      handleCancelJobPaymentIntent
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default jobPaymentsSagaCreator;
