import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MarkerWrapper from './MarkerWrapper';

import './AddressMarker.sass';

type Props = {
  onClick?: () => void;
  lat: number; // required for positioning
  lng: number; // required for positioning
};
const AddressMarker = ({ onClick }: Props) => {
  return (
    <MarkerWrapper onClick={onClick} className="address-marker">
      <div className="address-indicator">
        <FontAwesomeIcon icon={faHouse} />
      </div>
      <div className="triangle" />
    </MarkerWrapper>
  );
};
export default AddressMarker;
