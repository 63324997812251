import { faChevronUp, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import useSupercluster from 'use-supercluster';

import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import AddressMarker from './AddressMarker';
import MarkerWrapper from './MarkerWrapper';
import UserMarker from './UserMarker';

import './Map.sass';

const GOOGLE_MAPS_KEY = import.meta.env.VITE_GOOGLE_MAPS_KEY;

const markerSize = 35;
type BBox =
  | [number, number, number, number]
  | [number, number, number, number, number, number];
export type MarkerType = {
  lat: number;
  long: number;
  onClick?: () => void;
  userIds?: number[];
  dealId?: number;
  scheduledEventId?: number;
};
type LatLong = {
  lat: number;
  long: number;
};

const generateCirclularCenteredStyle = (
  size: number,
  adjustForTrianglePointer = false
) => ({
  height: size,
  width: size,
  marginTop: adjustForTrianglePointer ? -size : -size / 2,
  marginLeft: -size / 2,
});

type Props = {
  clusteredMarkers?: MarkerType[]; // markers which get clustered on zoom out
  center?: LatLong;
  independentMarkers?: MarkerType[]; // markers which are not clustered
};
const Map = ({
  center,
  clusteredMarkers = [],
  independentMarkers = [],
}: Props) => {
  const mapRef = useRef<any | null>(null);
  const mapWrapperRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [bounds, setBounds] = useState<BBox | undefined>();
  const [zoom, setZoom] = useState(10);

  const points = useMemo(
    () =>
      clusteredMarkers.map((marker) => {
        return {
          type: 'Feature',
          properties: {
            cluster: false,
            onClick: marker.onClick,
            userIds: marker.userIds,
            dealId: marker.dealId,
            scheduledEventId: marker.scheduledEventId,
          },
          geometry: {
            type: 'Point',
            coordinates: [marker.long, marker.lat],
          },
        };
      }),
    [clusteredMarkers]
  );

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const [showClusterBreakdown, setShowClusterBreakdown] = useState<any>();

  const renderSpecificMarker = useCallback((cluster: any, key: number) => {
    const [longitude, latitude] = cluster.geometry.coordinates;
    if (cluster.properties.scheduledEventId) {
      // assigned user(s) marker on scheduled event
      return (
        <UserMarker
          userIds={cluster.properties.userIds}
          key={`user-marker-${key}`}
          onClick={cluster.properties.onClick}
          lat={latitude}
          lng={longitude}
        />
      );
    } else if (cluster.properties.dealId) {
      // deal site address marker
      return (
        <AddressMarker
          key={`deal-address-marker-${key}`}
          onClick={cluster.properties.onClick}
          lat={latitude}
          lng={longitude}
        />
      );
    }
  }, []);

  const renderClusters = useMemo(() => {
    return clusters.map((cluster, i) => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const { cluster: isCluster, point_count: pointCount } =
        cluster.properties;

      if (isCluster) {
        const size = 25 + (pointCount / points.length) * 20;
        let clusterPoints: any[] = [];
        if (cluster.id) {
          // get all points in cluster
          clusterPoints = supercluster.getLeaves(cluster.id);
        }
        return (
          <MarkerWrapper
            onClick={() => {
              // zoom into cluster
              const expansionZoom = Math.min(
                supercluster.getClusterExpansionZoom(cluster.id),
                20
              );
              if (mapRef?.current?.getZoom() >= 20) {
                // zoomed into max on a cluster - show/hide all markers (locations are likely in the same lat/long)
                setShowClusterBreakdown(
                  showClusterBreakdown === cluster.id ? null : cluster.id
                );
              }
              mapRef?.current?.setZoom(expansionZoom);
              mapRef?.current?.panTo({ lat: latitude, lng: longitude });
            }}
            className="cluster-marker"
            key={`cluster-marker-${i}`}
            lat={latitude}
            lng={longitude}
            style={{
              ...generateCirclularCenteredStyle(size),
            }}
          >
            {/* number of points / breakdown of points indicator */}
            {showClusterBreakdown === cluster.id ? (
              <FontAwesomeIcon icon={faChevronUp} size="sm" />
            ) : (
              pointCount
            )}
            {/* breakdown of points in cluster */}
            {showClusterBreakdown === cluster.id && (
              <div className="cluster-breakdown">
                {clusterPoints?.map((clusterPoint) => (
                  <div
                    key={`cp-${i}`}
                    className="cluster-breakdown-marker-container"
                  >
                    {renderSpecificMarker(clusterPoint, i)}
                  </div>
                ))}
              </div>
            )}
          </MarkerWrapper>
        );
      }
      return renderSpecificMarker(cluster, i);
    });
  }, [clusters, points, showClusterBreakdown]);

  const renderMarkers = useMemo(() => {
    return independentMarkers.map((marker, i) => {
      // location search marker
      return (
        <MarkerWrapper
          onClick={marker.onClick}
          key={`location-marker-${i}`}
          lat={marker.lat}
          lng={marker.long}
          className="location-search-marker"
        >
          <FontAwesomeIcon
            icon={faLocationDot}
            style={{
              ...generateCirclularCenteredStyle(markerSize, true),
            }}
          />
        </MarkerWrapper>
      );
    });
  }, [independentMarkers]);

  const centerLatLong = useMemo(
    () =>
      center
        ? {
            lat: center.lat,
            lng: center.long,
          }
        : undefined,
    [center]
  );

  return (
    <div className="relative w-full min-h-[400px]">
      <div
        className="map-wrapper absolute top-0 bottom-0 left-0 right-0"
        ref={mapWrapperRef}
      >
        {isLoading && (
          <div className={'loading-container'}>
            <MiniLoader />
          </div>
        )}
        <GoogleMapReact
          options={{ maxZoom: 20 }}
          onZoomAnimationStart={() => {
            // clear cluster breakdown on zoom
            setShowClusterBreakdown(null);
          }}
          bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY || '' }}
          center={centerLatLong}
          defaultCenter={{ lat: 51.4538022, lng: -2.5972985 }}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }}
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
            setIsLoading(false);
          }}
        >
          {renderClusters}
          {renderMarkers}
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default Map;
