import { FunctionComponent, useCallback } from 'react';

import { useFieldId } from '@payaca/hooks/useFieldId';
import Checkbox from '../checkbox/Checkbox';
import FieldLabel from '../fieldLabel/FieldLabel';
import './CheckboxField.css';

type Props = {
  name: string;
  value?: boolean;
  label?: string | JSX.Element;
  description?: string | JSX.Element;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange?: (value: { [key: string]: boolean }) => void;
  onTouch?: (fieldName: string) => void;
  preventClickPropagation?: boolean;
};

const CheckboxField: FunctionComponent<Props> = ({
  name,
  value = false,
  label,
  description,
  isDisabled,
  isRequired,
  onChange,
  onTouch,
  preventClickPropagation,
}: Props): JSX.Element => {
  const onCheckboxChange = useCallback(() => {
    onChange && onChange({ [name]: !value });
    onTouch && onTouch(name);
  }, [onChange, onTouch, value, name]);

  const id = useFieldId(name);

  return (
    <div className="checkbox-field">
      <div className="checkbox-wrapper">
        <Checkbox
          isChecked={value}
          isDisabled={isDisabled}
          onChange={onCheckboxChange}
          preventClickPropagation={preventClickPropagation}
          id={id}
        />
      </div>
      <FieldLabel
        label={label}
        id={id}
        description={description}
        isRequired={isRequired}
      />
    </div>
  );
};

export default CheckboxField;
