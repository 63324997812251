import { useSelector } from '@/api/state';
import { actions as usersActions } from '@/api/users';
import { getUserRoles } from '@/utils/stateAccessors';
import { useAccountUsers } from '@/utils/storeHooks';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrencyField from '@payaca/components/currencyField/CurrencyField';
import DateTimeField from '@payaca/components/dateTimeField/DateTimeField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import DurationInputControl from '@payaca/components/durationInputControl/DurationInputControl';
import TextareaField from '@payaca/components/textareaField/TextareaField';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasAllRequiredPermissions } from '@payaca/permissions/permissions.utils';
import { TimelogsPermissions } from '@payaca/permissions/timelogs/timelogs.permissions';
import { getTimelogTypes } from '@payaca/store/timelogs/timelogsActions';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { PublicTimelogType } from '@payaca/types/timelogs';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import './PersistTimelogFields.sass';

type Props = {
  formState: {
    [key: string]: any;
  };
  validationState: {
    [key: string]: FieldValidationResult;
  };
  touchedState: {
    [key: string]: boolean;
  };
  onFieldChange: (value: { [key: string]: any }) => void;
  onFieldTouch: (fieldName: string) => void;
  persistType: 'create' | 'update';
};

const PersistTimelogFields: FunctionComponent<Props> = ({
  formState,
  validationState,
  touchedState,
  onFieldChange,
  onFieldTouch,
  persistType,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [timelogTypes, setTimelogTypes] = useState<PublicTimelogType[]>([]);
  const accountUsers = useAccountUsers();
  const userRoles = useSelector(getUserRoles);

  useEffect(() => {
    dispatch(usersActions.getAccountUsers());
    dispatch(
      getTimelogTypes.request({
        callback: (tt) => {
          setTimelogTypes(tt);
        },
      })
    );
  }, []);

  const userAssignmentOptions = useMemo(() => {
    const options: { label: string; value: number }[] = accountUsers
      .filter((x: any) => {
        return !x.deactivatedAt || x.inviteToken;
      })
      .map((accountUser: any) => {
        return {
          label: `${accountUser.firstname} ${accountUser.lastname}`,
          value: accountUser.id.toString(),
        };
      });

    return options;
  }, [accountUsers]);

  return (
    <div className="persist-timelog-fields">
      <ValidatedFieldWrapper
        validationResult={validationState['startTime']}
        isTouched={touchedState['startTime'] || false}
      >
        <DateTimeField
          name="startTime"
          value={formState.startTime}
          isRequired={true}
          onChange={onFieldChange}
          onTouch={onFieldTouch}
          label="Start time"
        />
      </ValidatedFieldWrapper>
      {formState.startTime && (
        <DurationInputControl
          name="duration"
          value={formState.duration}
          onChange={onFieldChange}
          onTouch={onFieldTouch}
          label={'Duration'}
        />
      )}

      <PermissionGuard
        renderIfHasPermissions={[
          persistType === 'create'
            ? TimelogsPermissions.CREATE_TIMELOGS
            : TimelogsPermissions.UPDATE_TIMELOGS,
        ]}
      >
        <ValidatedFieldWrapper
          validationResult={validationState['assigneePublicId']}
          isTouched={touchedState['assigneePublicId'] || false}
        >
          <DropdownField
            value={formState.assigneePublicId || []}
            name="assigneePublicId"
            label="Assignee"
            options={userAssignmentOptions}
            onChange={({ assigneePublicId }) => {
              const assignee = accountUsers.find(
                (x) => x.id === +assigneePublicId
              );

              onFieldChange({
                assigneePublicId,
                assigneeType: 'user',
                ...(userHasAllRequiredPermissions(userRoles, [
                  TimelogsPermissions.SET_TIMELOG_COST_PER_HOUR,
                  AccountsPermissions.GET_USER_COST_PER_HOUR,
                ])
                  ? { costPerHour: assignee?.costPerHour }
                  : {}),
              });
            }}
            onTouch={onFieldTouch}
          />
        </ValidatedFieldWrapper>
      </PermissionGuard>
      <PermissionGuard
        renderIfHasPermissions={[TimelogsPermissions.SET_TIMELOG_COST_PER_HOUR]}
      >
        <ValidatedFieldWrapper
          validationResult={validationState['costPerHour']}
          isTouched={touchedState['costPerHour'] || false}
        >
          <CurrencyField
            name="costPerHour"
            value={formState.costPerHour}
            onChange={onFieldChange}
            onTouch={onFieldTouch}
            label="Cost per hour"
          />
        </ValidatedFieldWrapper>
      </PermissionGuard>
      {!!timelogTypes?.length && (
        <PermissionGuard
          renderIfHasPermissions={[TimelogsPermissions.GET_TIMELOG_TYPES]}
        >
          <ValidatedFieldWrapper
            validationResult={validationState['typePublicId']}
            isTouched={touchedState['typePublicId'] || false}
          >
            <DropdownField
              value={formState.typePublicId || []}
              name="typePublicId"
              label="Type"
              options={timelogTypes.map((timelogType) => {
                return {
                  label: timelogType.type,
                  value: timelogType.publicId,
                };
              })}
              renderOption={(option) => {
                const type = timelogTypes.find(
                  (x) => x.publicId == option.value
                );
                return (
                  <div className="timelog-types-dropdown-option">
                    <span>{option.label}</span>

                    {!!type?.description?.length && (
                      <span className="description">
                        <Tooltip text={type.description}>
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </Tooltip>
                      </span>
                    )}
                  </div>
                );
              }}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
            />
          </ValidatedFieldWrapper>
        </PermissionGuard>
      )}
      <ValidatedFieldWrapper
        validationResult={validationState['notes']}
        isTouched={touchedState['notes'] || false}
      >
        <TextareaField
          name="notes"
          value={formState.notes || ''}
          onChange={onFieldChange}
          onTouch={onFieldTouch}
          label="Notes"
        />
      </ValidatedFieldWrapper>
    </div>
  );
};

export default PersistTimelogFields;
