import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UpdateProposalLineItemMaterialsInput,
  UpdateProposalLineItemMaterialsMutation,
} from '@/gql/graphql';

const UPDATE_PROPOSAL_LINE_ITEM_MATERIALS_MUTATION = graphql(`
  mutation UpdateProposalLineItemMaterials(
    $input: UpdateProposalLineItemMaterialsInput!
  ) {
    updateProposalLineItemMaterials(input: $input) {
      id
    }
  }
`);

const useUpdateProposalLineItemMaterials = (
  options?: UseMutationOptions<
    UpdateProposalLineItemMaterialsMutation,
    unknown,
    UpdateProposalLineItemMaterialsInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UPDATE_PROPOSAL_LINE_ITEM_MATERIALS_MUTATION, {
        input,
      });
    },
    ...options,
  });
};

export default useUpdateProposalLineItemMaterials;
