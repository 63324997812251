import Button from '@payaca/components/plButton/Button';
import { EBtnColour } from '@payaca/components/plButton/useButtonClassName';
import Modal, { IProps as ModalProps } from '@payaca/components/plModal/Modal';
import * as suppliersActions from '@payaca/store/suppliers/suppliersActions';
import { singularPlural } from '@payaca/utilities/stringUtilities';
import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListedSupplier } from './ListedSuppliersPage';

type Props = {
  onDeleteCallback?: () => void;
  selectedSuppliers: ListedSupplier[];
} & Omit<ModalProps, 'title'>;
const ConfirmDeleteSuppliersModal: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, onDeleteCallback, selectedSuppliers } = props;
  const [isDeletingSuppliers, setIsDeletingSuppliers] =
    useState<boolean>(false);

  const onBulkDeleteSuppliers = useCallback(
    (suppliers: ListedSupplier[]) => {
      setIsDeletingSuppliers(true);
      const supplierIds = suppliers.map((li) => +li.id);
      dispatch(
        suppliersActions.requestArchiveSuppliers(supplierIds, () => {
          onDeleteCallback?.();
          setIsDeletingSuppliers(false);
        })
      );
    },
    [onDeleteCallback]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Delete ${singularPlural(
        selectedSuppliers.length,
        'Supplier',
        'Suppliers'
      )}`}
    >
      <Modal.Body>
        <p>
          Are you sure you want to delete{' '}
          {singularPlural(selectedSuppliers.length, 'Supplier', 'Suppliers')}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            onClick={() => {
              if (!isDeletingSuppliers) {
                onBulkDeleteSuppliers(selectedSuppliers);
              }
            }}
            isProcessing={isDeletingSuppliers}
            colour={EBtnColour.Red}
          >
            Delete
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteSuppliersModal;
