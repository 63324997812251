import React, { FC, useMemo } from 'react';

import EntityCard from '@payaca/components/entityCard/EntityCard';
import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';
import StatusBadge, {
  StatusBadgeState,
} from '@payaca/components/statusBadge/StatusBadge';

import { JobLineItem } from '@payaca/types/jobContentTypes';

import { useAccount } from '@/utils/storeHooks';

import { currencyPrice } from '@payaca/helpers/financeHelper';

import { useJobLineItemAttachments } from '@payaca/store/hooks/appState';

import { roundToDecimalPlaces } from '@payaca/helpers/numberHelper';
import './JobLineItemCard.sass';

type Props = {
  jobLineItem: JobLineItem;
  quantitySectionComponent?: React.ReactNode;
  onClick?: () => void;
};
const JobLineItemCard: FC<Props> = ({
  jobLineItem,
  onClick,
  quantitySectionComponent,
}: Props) => {
  const account = useAccount();
  const jobLineItemAttachments = useJobLineItemAttachments(jobLineItem);

  const totalsContainer = useMemo(() => {
    const vatMultiplier =
      1 +
      ((jobLineItem.vatIncluded &&
        !jobLineItem.isReverseChargeVat &&
        jobLineItem.vatAmount / 100) ||
        0);
    let totalExcVat = jobLineItem.calculatedPrice * jobLineItem.quantity;
    let totalIncVat = totalExcVat * vatMultiplier;
    let cisTotal = jobLineItem.cisDeductionRate
      ? (jobLineItem.calculatedPrice *
          jobLineItem.quantity *
          jobLineItem.cisDeductionRate) /
        100
      : null;

    if (jobLineItem.discountPercentage || jobLineItem.markupPercentage) {
      totalExcVat = jobLineItem.subtotalIncMarkupDiscount;
      totalIncVat =
        jobLineItem.subtotalIncMarkupDiscount + jobLineItem.vatTotal;
      cisTotal = jobLineItem.cisTotal || null;
    }

    if (cisTotal) {
      totalIncVat = totalIncVat - cisTotal;
    }

    return (
      <div className="item-totals-container">
        <h5 className="item-total">
          {currencyPrice(totalIncVat, account.region)}
        </h5>
        <span className="item-total-exc-vat">
          <>{`${currencyPrice(totalExcVat, account.region)} ex tax`}</>
        </span>
        {cisTotal && (
          <small className="item-cis">
            {currencyPrice(cisTotal || 0, account.region)} total CIS
          </small>
        )}
      </div>
    );
  }, [jobLineItem, account]);

  return (
    <EntityCard
      className="listed-job-line-item-card"
      imageConfig={{
        imageSrc: jobLineItemAttachments?.[0]?.attachmentUrl,
        renderBlockIfNoImageSrc: true,
      }}
      onClick={onClick}
    >
      <div className="item-name-description-wrapper">
        <span className="item-name">{jobLineItem.name}</span>
        <MarkdownLabel
          markdown={jobLineItem.description}
          className="item-description"
          limitLines={1}
        />
      </div>

      <div className="mr-4 flex shrink-0 flex-col items-end gap-2">
        {!!jobLineItem.discountPercentage && (
          <StatusBadge state={StatusBadgeState.GREY_INITIAL}>
            {`${jobLineItem.discountPercentage}% discount applied`}
          </StatusBadge>
        )}
        {!!jobLineItem.markupPercentage && (
          <StatusBadge state={StatusBadgeState.GHOST}>
            {`${roundToDecimalPlaces(
              jobLineItem.markupPercentage,
              1
            )}% markup applied`}
          </StatusBadge>
        )}
      </div>

      {/* price, vat, cis, qty */}
      {totalsContainer}
      <span className="item-quantity">
        {quantitySectionComponent ? (
          quantitySectionComponent
        ) : (
          <>
            <span>Qty</span>
            {jobLineItem.quantity}
          </>
        )}
      </span>
    </EntityCard>
  );
};

export default JobLineItemCard;
