import { RangeData } from '@payaca/types/tagAnalyticsTypes';
import * as d3 from 'd3';
import React, { useMemo } from 'react';
import './HorizontalChart.sass';

type DataType = RangeData | number;

export type Datapoint<T extends DataType> = {
  label: string;
  colour?: string;
  data: T;
};

export type RangeDatapoint = Datapoint<RangeData>;

export type ValueDatapoint = Datapoint<number>;

type Props<T extends DataType> = {
  datapoints: Datapoint<T>[];
  axisTickFormatter?: (value: number) => string;
  renderLabel?: (datapoint: Datapoint<T>) => React.ReactNode;
  isDiscrete?: boolean;
  renderDatapoint: (
    datapoint: Datapoint<T>,
    i: number,
    xScale: d3.ScaleLinear<number, number, never>
  ) => React.ReactNode;
  className?: string;
};
const HorizontalChart = <T extends DataType>({
  datapoints,
  axisTickFormatter = (value) => value.toString(),
  renderLabel = (datapoint: Datapoint<DataType>) => (
    <span>{datapoint.label}</span>
  ),
  isDiscrete = false,
  renderDatapoint,
  className,
}: Props<T>): JSX.Element | null => {
  const maxValue =
    d3.max(
      // @ts-ignore
      datapoints.map((x) =>
        typeof x.data === 'object' ? x.data.maxLim : x.data
      )
    ) || 0;
  const xScale = useMemo(() => {
    // @ts-ignore
    return d3.scaleLinear().domain([0, maxValue]).range([0, 100]);
  }, [maxValue]);

  const ticks = xScale
    .ticks()
    .filter((x) => (isDiscrete ? Number.isInteger(x) : true));

  return (
    <figure
      className={`horizontal-chart ${className || ''}`}
      style={{
        gridTemplateRows: `[ticks-start] repeat(${datapoints.length}, auto) [ticks-end] auto [end]`,
      }}
    >
      <div className="ticks">
        {ticks.map((tick, i) => {
          return (
            <div
              className="tick"
              key={i}
              style={{
                width: `${xScale(tick)}%`,
              }}
            ></div>
          );
        })}
      </div>
      <div className="tick-labels">
        {ticks.map((tick, i) => {
          return (
            <div
              className="tick-label"
              key={i}
              style={{
                left: `${xScale(tick)}%`,
              }}
            >
              <span className="tick-label-inner">
                {axisTickFormatter(tick)}
              </span>
            </div>
          );
        })}
      </div>
      {datapoints.map((datapoint, i) => {
        const gridRow = `${i + 1} / ${i + 2}`;
        return (
          <>
            <div
              className="label"
              style={{
                gridRow,
              }}
            >
              {renderLabel(datapoint)}
            </div>
            <div
              className="data-parent"
              style={{
                gridRow,
              }}
            >
              {renderDatapoint(datapoint, i, xScale)}
            </div>
          </>
        );
      })}
    </figure>
  );
};

export default HorizontalChart;
