import '../../slateEditor/types';

import { useCallback } from 'react';
import { RenderElementProps } from 'slate-react';
import DefaultElement from './Default';
import VariableElement from './Variable';

const useRenderElements = () => {
  return useCallback((props: RenderElementProps) => {
    switch (props.element.type) {
      case 'variable':
        return <VariableElement {...props} />;
      default:
        return <DefaultElement {...props} />;
    }
  }, []);
};

export default useRenderElements;
