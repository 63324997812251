import { FunctionComponent } from 'react';
import './AnalyticsDashboardContent.sass';

import {
  KeyIndicatorsData,
  SentInvoicesChart,
  SentInvoiceValueChart,
  SentQuotesChart,
  SentQuoteValueChart,
} from '@/ui/components';

import ContentPanel from '@payaca/components/contentPanel/ContentPanel';

const AnalyticsDashboardContent: FunctionComponent = (): JSX.Element => {
  return (
    <div className="analytics-dashboard-content">
      <div className="analytics-dashboard-content-inner">
        <div className="full-width">
          <KeyIndicatorsData />
        </div>
        <ContentPanel className="chart-container">
          <div className="flex-container flex-center">
            <h2 className="flex-grow">Sent quotes</h2>
          </div>

          <SentQuotesChart />
        </ContentPanel>
        <ContentPanel className="chart-container">
          <div className="flex-container flex-center">
            <h2 className="flex-grow">Quote value</h2>
            <span className="microcopy">Tax excluded</span>
          </div>
          <SentQuoteValueChart />
        </ContentPanel>
        <ContentPanel className="chart-container">
          <div className="flex-container flex-center">
            <h2 className="flex-grow">Sent invoices</h2>
          </div>
          <SentInvoicesChart />
        </ContentPanel>
        <ContentPanel className="chart-container">
          <div className="flex-container flex-center">
            <h2 className="flex-grow">Invoice value</h2>
            <span className="microcopy">Tax included</span>
          </div>
          <SentInvoiceValueChart />
        </ContentPanel>
      </div>
    </div>
  );
};

export default AnalyticsDashboardContent;
