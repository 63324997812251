import qs from 'qs';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getDeal, getDocument } from '@/utils/stateAccessors';

import * as customerActions from '@payaca/store/customer/customerActions';
import * as dealActions from '@payaca/store/deals/dealsActions';
import * as documentActions from '@payaca/store/documents/documentActions';

import PreviewControlWrapper from '@/ui/components/previewControlWrapper/PreviewControlWrapper';
import PreviewDocumentActionButtons from '@/ui/components/previewDocumentActionButtons/PreviewDocumentActionButtons';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import LandlordOilInstallationCertificate from '@payaca/components/formTemplates//LandlordOilInstallationCertificate';
import DomesticElectricalInstallationCertificate from '@payaca/components/formTemplates/DomesticElectricalInstallationCertificate';
import DomesticElectricalInstallationCertificate18x2 from '@payaca/components/formTemplates/DomesticElectricalInstallationCertificate18-2';
import DomesticElectricalInstallationConditionReport from '@payaca/components/formTemplates/DomesticElectricalInstallationConditionReport';
import ElectricalInstallationConditionReport18x2 from '@payaca/components/formTemplates/ElectricalInstallationConditionReport18-2';
import GasSafetyCertificateTemplate from '@payaca/components/formTemplates/GasSafetyCertificateTemplate';
import GasWarningNoticeTemplate from '@payaca/components/formTemplates/GasWarningNoticeTemplate';
import JobReportFormTemplate from '@payaca/components/formTemplates/JobReportFormTemplate';
import JobSheetWintecFormTemplate from '@payaca/components/formTemplates/jobSheetWintecFormTemplate/JobSheetWintecFormTemplate';
import MinorElectricalInstallationWorksCertificate from '@payaca/components/formTemplates/MinorElectricalInstallationWorksCertificate';
import OilCommissioningReportTemplate from '@payaca/components/formTemplates/OilCommissioningReportTemplate';
import OilFiringInstallationCompletionReport from '@payaca/components/formTemplates/OilFiringInstallationCompletionReport';
import SalvumAsbestosDesktopStudyTemplate from '@payaca/components/formTemplates/SalvumAsbestosDesktopStudyTemplate';
import SalvumFireRiskDesktopStudyTemplate from '@payaca/components/formTemplates/SalvumFireRiskDesktopStudyTemplate';
import SalvumLegionellaDesktopStudyTemplate from '@payaca/components/formTemplates/SalvumLegionellaDesktopStudyTemplate';
import UnventedHotWaterCylinderCertificate from '@payaca/components/formTemplates/UnventedHotWaterCylinderCertificate';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import BoilerServiceRecord from '@payaca/components/formTemplates/BoilerServiceRecord';
import CommericalCateringInspectionRecord from '@payaca/components/formTemplates/CommericalCateringInspectionRecord';
import NonDomesticGasSafetyCertificateTemplate from '@payaca/components/formTemplates/NonDomesticGasSafetyCertificateTemplate';
import JobAttachments from '@payaca/components/jobAttachments/JobAttachments';
import ScalingContentWrapper from '@payaca/components/scalingContentWrapper/ScalingContentWrapper';

import { useSelector } from '@/api/state';
import { PdfFormPreview } from '@payaca/components/pdf-forms/PdfFormPreview';
import './DocumentOverviewPage.sass';

const DocumentOverviewPage: FC = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const params = useParams<{ documentId: string }>();

  const documentId = useMemo(() => {
    return params.documentId;
  }, [params]);

  const document = useSelector((state) => getDocument(state, +documentId));

  const deal = useSelector((state) =>
    document?.dealId ? getDeal(state, document.dealId) : undefined
  );
  const taskId = useMemo(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    return query?.taskId ? +query.taskId : null;
  }, [location]);

  useEffect(() => {
    if (taskId) {
      dispatch(documentActions.requestGetDocumentForTask(+taskId));
    } else {
      dispatch(documentActions.requestGetDocument(+documentId));
    }
  }, [documentId, taskId]);

  useEffect(() => {
    if (document?.dealId) {
      dispatch(dealActions.requestGetDeal(document.dealId));
    }
  }, [document?.dealId]);

  useEffect(() => {
    if (deal?.customerId) {
      dispatch(customerActions.requestGetCustomer(deal.customerId));
    }
  }, [deal?.customerId]);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const formTemplate = useMemo(() => {
    const formId = document?.formInstances?.[0]?.formId;
    if (!document || typeof formId !== 'number') {
      return <>Template could not be loaded.</>;
    }
    if (document.template?.type === 'pdf') {
      const previewToken = document.formInstances?.at(0)?.previewToken;
      if (!previewToken) {
        // FIXME: error state
        return null;
      }
      return (
        <>
          <PdfFormPreview
            pdfUrl={`${
              import.meta.env.VITE_API_HOST
            }/provider/rest/forms/revisions/preview/${previewToken}/pdf`}
          />
        </>
      );
    }
    switch (formId) {
      case 0:
        return (
          <GasSafetyCertificateTemplate
            account={account}
            data={document.formData}
          />
        );
      case 1:
        return (
          <BoilerServiceRecord account={account} data={document.formData} />
        );
      case 2:
        return (
          <JobReportFormTemplate account={account} data={document.formData} />
        );
      case 3:
        return (
          <LandlordOilInstallationCertificate
            account={account}
            data={document.formData}
          />
        );
      case 4:
        return (
          <JobSheetWintecFormTemplate
            account={account}
            data={document.formData}
          />
        );
      case 5:
        return (
          <NonDomesticGasSafetyCertificateTemplate
            account={account}
            data={document.formData}
          />
        );
      case 6:
        return (
          <CommericalCateringInspectionRecord
            account={account}
            data={document.formData}
          />
        );
      case 7:
        return (
          <OilFiringInstallationCompletionReport
            account={account}
            data={document.formData}
          />
        );
      case 8:
        return (
          <GasWarningNoticeTemplate
            account={account}
            data={document.formData}
          />
        );
      case 9:
        return (
          <OilCommissioningReportTemplate
            account={account}
            data={document.formData}
          />
        );
      case 10:
        return (
          <MinorElectricalInstallationWorksCertificate
            account={account}
            data={document.formData}
          />
        );
      case 11:
        return (
          <DomesticElectricalInstallationConditionReport
            account={account}
            data={document.formData}
          />
        );
      case 12:
        return (
          <DomesticElectricalInstallationCertificate
            account={account}
            data={document.formData}
          />
        );
      case 13:
        return (
          <DomesticElectricalInstallationCertificate18x2
            account={account}
            data={document.formData}
          />
        );
      case 14:
        return (
          <UnventedHotWaterCylinderCertificate
            account={account}
            data={document.formData}
          />
        );
      case 15:
        return (
          <ElectricalInstallationConditionReport18x2
            account={account}
            data={document.formData}
          />
        );
      case 101:
        return (
          <SalvumFireRiskDesktopStudyTemplate
            account={account}
            data={document.formData}
          />
        );
      case 102:
        return (
          <SalvumLegionellaDesktopStudyTemplate
            account={account}
            data={document.formData}
          />
        );
      case 103:
        return (
          <SalvumAsbestosDesktopStudyTemplate
            account={account}
            data={document.formData}
          />
        );
      default:
        return <>Error: encountered unsupported form type</>;
    }
  }, [account, document]);

  const uploadAttachments = useMemo(() => {
    return document?.formData?.upload_attachments?.map(
      (a: { id: number; url: string; fileName: string }) => ({
        ...a,
        attachmentUrl: a.url,
      })
    );
  }, [document?.formData?.upload_attachments]);

  return (
    <AuthenticatedPageWrapper
      className="document-overview-page"
      previousPageNavigationConfig={
        document?.dealId ? { route: `/deals/${document?.dealId}` } : undefined
      }
    >
      <PreviewControlWrapper
        className="preview-job-control"
        previewContent={
          <ContentPanel hasBoxShadow={true}>
            <ScalingContentWrapper naturalWidth={1200}>
              <>{formTemplate}</>
            </ScalingContentWrapper>
          </ContentPanel>
        }
        title={<h3>Document Overview</h3>}
        sidebarContent={
          <PreviewDocumentActionButtons
            documentId={+documentId}
            taskId={taskId}
          />
        }
        footer={
          !!uploadAttachments?.length && (
            <JobAttachments jobAttachments={uploadAttachments} />
          )
        }
      ></PreviewControlWrapper>
    </AuthenticatedPageWrapper>
  );
};
export default DocumentOverviewPage;
