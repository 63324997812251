import { FC, PropsWithChildren } from 'react';

import Modal from '@payaca/components/plModal/Modal';
import { Deal } from '@payaca/types/dealTypes';
import EditPurchaseOrderControl from '../editPurchaseOrderControl/EditPurchaseOrderControl';
import './EditPurchaseOrderModal.sass';

type Props = {
  purchaseOrderId: number;
  isOpen: boolean;
  onEditPurchaseOrderSuccess?: () => void;
  onClose?: () => void;
  projectId?: Deal['id'];
};
const EditPurchaseOrderModal: FC<Props> = ({
  purchaseOrderId,
  isOpen,
  onEditPurchaseOrderSuccess,
  onClose,
  projectId,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Add materials to the purchase order`}
      className="edit-purchase-order-modal"
    >
      <EditPurchaseOrderControl
        purchaseOrderId={purchaseOrderId}
        onEditPurchaseOrderSuccess={onEditPurchaseOrderSuccess}
        projectId={projectId}
      />
    </Modal>
  );
};

export default EditPurchaseOrderModal;
