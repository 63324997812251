import { CurrencyValue as TCurrencyValue } from '@payaca/types/currencyTypes';
import UntitledIcon from '@payaca/untitled-icons';
import { FC } from 'react';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';
import CurrencyValue from '../currencyValue/CurrencyValue';
import MarkdownLabel from '../markdownLabel/MarkdownLabel';
import Card, { CardSizeVariant } from '../plCard/Card';
import CardButton from '../plCard/CardButton';

export interface IProps {
  reference?: string;
  description: string;
  priceIncludingTax?: TCurrencyValue;
  taxRateLabel?: string;
  thumbnailUrl?: string;
  onClick?: () => void;
}

const LineItemCard: FC<IProps> = (props) => {
  const {
    reference,
    description,
    priceIncludingTax,
    taxRateLabel,
    thumbnailUrl,
    onClick,
  } = props;

  const wrapperProps = {
    sizeVariant: CardSizeVariant.SM,
    onClick: onClick,
  };

  return (
    <ConditionalWrapper
      condition={!!onClick}
      Wrapper={CardButton}
      FallbackWrapper={Card}
      wrapperProps={wrapperProps}
      fallbackWrapperProps={wrapperProps}
    >
      <Card.Body>
        <div className="flex gap-3.5">
          <div className="w-14 flex-shrink-0">
            {thumbnailUrl ? (
              <img
                className="overflow-hidden rounded"
                src={thumbnailUrl}
                alt={reference}
              />
            ) : (
              <UntitledIcon
                name="image-01.3"
                vectorEffect="non-scaling-stroke"
                className="h-10 w-10 text-gray-700"
              />
            )}
          </div>

          <div className="flex flex-auto justify-between gap-4">
            <div>
              {reference && <p className="supporting-body mb-2">{reference}</p>}
              <MarkdownLabel
                className="markdown-font-size-reset"
                truncate
                limitLines={5}
                hideShowMoreButton
                markdown={description}
              />
            </div>

            <div className="text-right">
              {priceIncludingTax && (
                <>
                  <p className="whitespace-nowrap font-medium">
                    <CurrencyValue {...priceIncludingTax} />
                  </p>
                  <p className="supporting-body whitespace-nowrap">
                    inc {taxRateLabel}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </Card.Body>
    </ConditionalWrapper>
  );
};

export default LineItemCard;
