import { useSelector } from '@/api/state';
import { getUserRoles } from '@/utils/stateAccessors';
import Conditional from '@payaca/components/conditional/Conditional';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Modal from '@payaca/components/plModal/Modal';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { TemplatesPermissions } from '@payaca/permissions/templates/templates.permissions';
import { requestCreateScheduledEvent } from '@payaca/store/scheduledEvents/scheduledEventsActions';
import { CreateScheduledEventRequestData } from '@payaca/store/scheduledEvents/scheduledEventsTypes';
import { getListedTemplatesPage } from '@payaca/store/templates/templatesActions';
import { PublicHydratedEntityTemplate } from '@payaca/types/entity-templates';
import { SortBy } from '@payaca/types/listedTemplateTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';
import { isNotNullish, isNullish } from '@payaca/utilities/guards';
import { singularPlural } from '@payaca/utilities/stringUtilities';
import { roundDateTimeUpToHalfHour } from '@payaca/utilities/timeUtilities';
import { DeepPartial } from '@payaca/utilities/types';
import moment from 'moment-timezone';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Props as FieldsetProps,
  ScheduledEventFieldset,
  getFieldValidatorsForScheduledEvent,
} from '../scheduledEventFieldset/ScheduledEventFieldset';
import TemplateSelectionControl from '../templateSelectionControl/TemplateSelectionControl';

type ControlStage = 'templateSelect' | 'eventInput';

type EventData = DeepPartial<CreateScheduledEventRequestData>;

type Props = {
  initialEventData?: EventData;
  onSuccess?: (scheduledEventId: ScheduledEvent['id']) => void;
  disableTemplateSelect?: boolean;
} & Pick<FieldsetProps, 'hiddenFields' | 'disabledFields'>;

const CreateScheduledEventControl: FC<Props> = ({
  initialEventData = {},
  onSuccess,
  hiddenFields,
  disabledFields,
  disableTemplateSelect,
}) => {
  const dispatch = useDispatch();

  const [stage, setStage] = useState<ControlStage>('eventInput');

  const userRoles = useSelector(getUserRoles);
  const [hasAnyTemplates, setHasAnyTemplates] = useState<boolean>(false);
  const [isCheckingForTemplates, setIsCheckingForTemplates] =
    useState<boolean>(false);

  const userCanSeeTemplates = userHasRequiredPermission(userRoles, [
    TemplatesPermissions.GET_TEMPLATES,
  ]);

  useEffect(() => {
    if (!userCanSeeTemplates) {
      setHasAnyTemplates(false);
      return;
    }

    setIsCheckingForTemplates(true);
    dispatch(
      getListedTemplatesPage.request({
        queryParams: {
          pageSize: 1,
          pageNumber: 1,
          entityTypes: ['scheduledEvent'],
          sortBy: SortBy.NAME,
          sortDirection: SortDirection.ASCENDING,
        },
        callback: (page) => {
          setHasAnyTemplates(false);
          setHasAnyTemplates(!!page.items.length);
          setIsCheckingForTemplates(false);
        },
        onErrorCallback: () => {
          setIsCheckingForTemplates(false);
        },
      })
    );
  }, [userCanSeeTemplates]);

  useEffect(() => {
    if (userCanSeeTemplates && !disableTemplateSelect && hasAnyTemplates) {
      setStage('templateSelect');
    }
  }, [userCanSeeTemplates, hasAnyTemplates]);

  const currentUserId = useSelector((state: any) => state.users.myProfile.id);

  const beginAt =
    initialEventData.beginAt || roundDateTimeUpToHalfHour(new Date()).toDate();

  const [eventData, setEventData] = useState<
    DeepPartial<CreateScheduledEventRequestData>
  >({
    contactsToNotify: [],
    ...initialEventData,
    beginAt: beginAt,
    endAt: initialEventData.endAt || moment(beginAt).add(1, 'hour').toDate(),
    userAssignments: initialEventData.userAssignments?.length
      ? initialEventData.userAssignments
      : [currentUserId],
  });

  const errorMessage = useSelector((state) => {
    return state.scheduledEvents.createScheduledEventErrorMessage
      ? 'Sorry, there was an error creating this Event. Please try again.'
      : null;
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (data: EventData) => {
    setIsSubmitting(true);

    const contactsToNotify = data.contactsToNotify?.map((contact) => ({
      ...contact,
      id: typeof contact.id === 'number' ? contact.id : undefined,
    }));

    const eventToCreate = {
      ...eventData,
      name: data.name,
      description: data.description,
      beginAt: data.beginAt,
      endAt: data.endAt,
      userAssignments: data.userAssignments,
      dealId: data.dealId,
      tagIds: data.tagIds,
      includeCustomerInformation: data.includeCustomerInformation,
      location: data.location,
      customerIds: data.customerIds,
      uploadIds: data.uploadIds,
      tasksToCreate: data.tasksToCreate
        ? data.tasksToCreate.map((t) => ({
            ...t,
            deadlineDate: data.endAt,
          }))
        : undefined,
      contactsToNotify: contactsToNotify,
    } as CreateScheduledEventRequestData;

    dispatch(
      requestCreateScheduledEvent(eventToCreate, (scheduledEventId) => {
        setIsSubmitting(false);
        onSuccess?.(scheduledEventId);
      })
    );
  };

  if (isCheckingForTemplates) {
    return null;
  }

  return (
    <div className="flex w-full flex-col gap-6">
      {stage === 'templateSelect' && (
        <TemplateSelect
          onApplyTemplate={(template) => {
            if (!template) {
              setEventData((e) => ({
                ...e,
              }));
            } else {
              const data = template.data;

              setEventData((e) => ({
                ...e,
                name: data.name || e.name,
                description: data.description || e.description,
                includeCustomerInformation: !isNullish(
                  data.includeCustomerInformation
                )
                  ? (data.includeCustomerInformation)
                  : e.includeCustomerInformation,
                tagIds: [...(data.tagIds || []), ...(e.tagIds || [])],
                uploadIds: [...(data.uploadIds || []), ...(e.uploadIds || [])],
                tasksToCreate: [
                  ...(data.tasks?.map((task) => ({
                    name: task.data.name,
                    notes: task.data.notes,
                    checklistItems: task.data.checklistItems,
                    formTemplateId: isNotNullish(task.data.formTemplateId)
                      ? +task.data.formTemplateId
                      : undefined,
                  })) || []),
                  ...(e.tasksToCreate || []),
                ],
                endAt: moment(e.beginAt).add(data.duration).toDate(),
              }));
            }

            setStage('eventInput');
          }}
        />
      )}
      {stage === 'eventInput' && (
        <CreateScheduledEventForm
          eventData={eventData}
          onSubmit={onSubmit}
          hiddenFields={hiddenFields}
          disabledFields={disabledFields}
          isSubmitting={isSubmitting}
        />
      )}
      {errorMessage && (
        <Alert colour={EAlertColour.SOFT_RED}>{errorMessage}</Alert>
      )}
    </div>
  );
};

export default CreateScheduledEventControl;

const TemplateSelect: FC<{
  onApplyTemplate: (
    template?: PublicHydratedEntityTemplate<'scheduledEvent'>
  ) => void;
}> = ({ onApplyTemplate }) => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<PublicHydratedEntityTemplate<'scheduledEvent'>>();

  const selectedTemplateHasUploads =
    !!selectedTemplate?.data?.uploadIds?.length;
  const selectedTemplateHasTasks = !!selectedTemplate?.data?.tasks?.length;

  const selectedTemplateAdds = [
    selectedTemplateHasUploads
      ? singularPlural(
          selectedTemplate?.data?.uploadIds?.length || 0,
          'attachment',
          'attachments'
        )
      : null,
    selectedTemplateHasTasks
      ? singularPlural(
          selectedTemplate?.data?.tasks?.length || 0,
          'task',
          'tasks'
        )
      : null,
  ]
    .filter((x) => x)
    .join(' and ');

  return (
    <>
      <Modal.Body>
        <TemplateSelectionControl<'scheduledEvent'>
          selectedTemplate={selectedTemplate}
          label="Choose a template"
          onChange={setSelectedTemplate}
          allowedEntityTypes={['scheduledEvent']}
        />
        {selectedTemplate &&
          (selectedTemplateHasUploads || selectedTemplateHasTasks) && (
            <div className="mt-2 text-gray-500">
              <span>
                This template adds <strong>{selectedTemplateAdds}</strong> which
                will be visible once the event has been created
              </span>
            </div>
          )}
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            variant={EBtnVariant.Outline}
            onClick={() => onApplyTemplate()}
          >
            Start from scratch
          </Button>
          <Button
            disabled={!selectedTemplate}
            onClick={() => {
              onApplyTemplate(selectedTemplate);
            }}
          >
            Apply template
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </>
  );
};

const CreateScheduledEventForm: FC<
  {
    eventData: EventData;
    onSubmit: (data: EventData) => void;
    isSubmitting: boolean;
  } & Pick<FieldsetProps, 'hiddenFields' | 'disabledFields'>
> = ({ eventData, onSubmit, hiddenFields, disabledFields, isSubmitting }) => {
  const [locationContactsCount, setLocationContactsCount] = useState(
    eventData.location?.contacts?.length || 0
  );

  const fieldValidators = useMemo(() => {
    return getFieldValidatorsForScheduledEvent({ locationContactsCount });
  }, [locationContactsCount]);

  return (
    <ValidatedForm<EventData>
      initialFormState={eventData}
      fieldValidators={fieldValidators}
      renderFormContents={(
        isValid,
        formState,
        validationState,
        touchedState,
        onFieldChange,
        onFieldTouch
      ) => {
        useEffect(() => {
          setLocationContactsCount(formState.location?.contacts?.length || 0);
        }, [formState.location?.contacts?.length]);

        const [showValidationErrorsWarning, setShowValidationErrorsWarning] =
          useState(false);

        const isInPast = formState.beginAt
          ? moment(formState.beginAt).isBefore(moment())
          : false;

        return (
          <>
            <Modal.Body>
              <div className="flex flex-col items-start gap-6">
                <ScheduledEventFieldset
                  eventData={formState}
                  onChange={onFieldChange}
                  validationState={validationState}
                  touchedState={touchedState}
                  onTouch={onFieldTouch}
                  hiddenFields={hiddenFields}
                  disabledFields={disabledFields}
                />
              </div>
            </Modal.Body>
            <Modal.Footer className="flex flex-col gap-y-4">
              <Conditional condition={isInPast}>
                <Alert colour={EAlertColour.SOFT_YELLOW}>
                  You are creating a past event
                </Alert>
              </Conditional>
              <Conditional condition={showValidationErrorsWarning && !isValid}>
                <div className="w-full">
                  <Alert colour={EAlertColour.SOFT_RED}>
                    Form cannot be submitted whilst there are validation errors
                  </Alert>
                </div>
              </Conditional>

              <Modal.Footer.Actions>
                <Button
                  isProcessing={isSubmitting}
                  onClick={() => {
                    if (isSubmitting) return;
                    if (!isValid) {
                      setShowValidationErrorsWarning(true);
                      onFieldTouch(
                        Object.entries(validationState)
                          .filter(([fieldName, validationResult]) => {
                            return !validationResult.isValid;
                          })
                          .map(([fieldName]) => fieldName)
                      );
                    } else {
                      onSubmit(formState);
                    }
                  }}
                >
                  Create Event
                </Button>
              </Modal.Footer.Actions>
            </Modal.Footer>
          </>
        );
      }}
    />
  );
};
