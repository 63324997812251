import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBrandingColourContext } from '@payaca/helpers/brandingColourContextHelper';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { formatPhoneNumber } from '@payaca/helpers/phoneNumberHelper';
import { DocumentSenderContext } from '@payaca/types/accountBrandTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import { Address } from '@payaca/types/locationTypes';
import CompanyLogo from '../companyLogo/CompanyLogo';
import './GenericDocument.sass';

type Props = {
  className?: string;
  documentSenderContext: DocumentSenderContext;
  documentRecipient?: {
    name: string;
    emailAddress?: string;
    telephoneNumber?: string;
    address?: Address;
  };
  title?: string;
  keyInformation?: { label: string; value: string }[];
};
const GenericDocument: FunctionComponent<PropsWithChildren<Props>> = ({
  className,
  documentSenderContext,
  documentRecipient,
  title,
  keyInformation,
  children,
}: PropsWithChildren<Props>): JSX.Element | null => {
  const footerRef = useRef<HTMLDivElement>(null);
  const brandingColourContext = getBrandingColourContext(
    documentSenderContext.brandColour
  );
  const [footerHeight, setFooterHeight] = useState(0);

  const showTradingAs = useMemo(
    () =>
      documentSenderContext.legalBusinessName &&
      documentSenderContext.legalBusinessName !==
        documentSenderContext.companyName,
    [documentSenderContext.companyName, documentSenderContext.legalBusinessName]
  );

  const vatRegNoRegionalLabel = useMemo(
    () =>
      getRegionalTextString(
        documentSenderContext.region,
        RegionalStrings.VALUE_ADDED_TAX_NUMBER
      ),
    [documentSenderContext]
  );

  const { documentOriginAddressPart1, documentOriginAddressPart2 } =
    useMemo(() => {
      const part1 = [
        documentSenderContext.address?.line1,
        documentSenderContext.address?.line2,
      ].filter((x) => !!x?.length);
      const part2 = [
        documentSenderContext.address?.city,
        documentSenderContext.address?.postcode,
      ].filter((x) => !!x?.length);

      return {
        documentOriginAddressPart1: part1.length ? part1.join(', ') : null,
        documentOriginAddressPart2: part2.length ? part2.join(', ') : null,
      };
    }, [documentSenderContext]);

  const showFooter = useMemo(() => {
    return showTradingAs || !!documentSenderContext.vatNumber;
  }, [showTradingAs, documentSenderContext]);

  useEffect(() => {
    setFooterHeight(footerRef?.current?.offsetHeight || 0);
  }, []);

  return (
    <article className={`generic-document ${className ? className : ''}`}>
      <table>
        <thead>
          <tr>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="content">
                <div className="generic-document-main">
                  <div className="split-column-container">
                    <div className="logo-container">
                      <CompanyLogo logoUrl={documentSenderContext.logoUrl} />
                    </div>
                    <div className="header-information-container">
                      {title && <h1>{title}</h1>}
                      <address>
                        <p>
                          <strong>{documentSenderContext.companyName}</strong>
                        </p>
                        {documentOriginAddressPart1 && (
                          <p>{documentOriginAddressPart1}</p>
                        )}
                        {documentOriginAddressPart2 && (
                          <p>{documentOriginAddressPart2}</p>
                        )}
                      </address>
                    </div>
                  </div>
                  <hr
                    className="header-divider"
                    style={{ borderColor: brandingColourContext.brandColour }}
                  ></hr>
                  <div className="split-column-container">
                    {documentRecipient && (
                      <div className="to-container">
                        <h2
                          style={{
                            borderColor: brandingColourContext.brandColour,
                          }}
                        >
                          To
                        </h2>
                        <address>
                          <p>
                            <strong>{documentRecipient.name}</strong>
                          </p>
                          {documentRecipient.address && (
                            <p>
                              {getAddressAsString(documentRecipient.address)}
                            </p>
                          )}
                          {documentRecipient.emailAddress && (
                            <p>{documentRecipient.emailAddress}</p>
                          )}
                          {documentRecipient.telephoneNumber && (
                            <p>
                              {formatPhoneNumber(
                                documentRecipient.telephoneNumber
                              )}
                            </p>
                          )}
                        </address>
                      </div>
                    )}
                    {!!keyInformation?.length && (
                      <div className="key-information-container">
                        <dl className="key-information">
                          {keyInformation.map((x, i) => {
                            return (
                              <>
                                <dt key={`key-info-dt-${i}`}>{x.label}</dt>
                                <dd key={`key-info-dd-${i}`}>{x.value}</dd>
                              </>
                            );
                          })}
                        </dl>
                      </div>
                    )}
                  </div>
                  <div className="body-container">{children}</div>
                  {(documentSenderContext.emailAddress ||
                    documentSenderContext.phoneNumber) && (
                    <div className="contact-container">
                      <h2>Contact us</h2>
                      <address>
                        {documentSenderContext.emailAddress && (
                          <p>
                            <FontAwesomeIcon icon={faEnvelope} />
                            {documentSenderContext.emailAddress}
                          </p>
                        )}
                        {documentSenderContext.phoneNumber && (
                          <p>
                            <FontAwesomeIcon icon={faPhone} />
                            {formatPhoneNumber(
                              documentSenderContext.phoneNumber
                            )}
                          </p>
                        )}
                      </address>
                    </div>
                  )}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        {showFooter && (
          <tfoot>
            <tr>
              <td>
                <div
                  className="footer-space"
                  style={{
                    height: footerHeight,
                  }}
                >
                  &nbsp;
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      {showFooter && (
        <div
          className="footer-container"
          style={{
            height: footerHeight,
          }}
        >
          <footer
            ref={footerRef}
            className="footer"
            style={{
              borderColor: brandingColourContext.brandColour,
            }}
          >
            {showTradingAs && (
              <span>
                <strong>{documentSenderContext.legalBusinessName}</strong>{' '}
                trading as <strong>{documentSenderContext.companyName}</strong>
              </span>
            )}
            {!!documentSenderContext.registeredCountryName && (
              <span>
                Registered in {documentSenderContext.registeredCountryName}
              </span>
            )}
            {!!documentSenderContext.companyRegistrationNumber && (
              <span>
                Company registration no:{' '}
                {documentSenderContext.companyRegistrationNumber}
              </span>
            )}
            {!!documentSenderContext.vatNumber && (
              <span>
                {vatRegNoRegionalLabel}: {documentSenderContext.vatNumber}
              </span>
            )}
          </footer>
        </div>
      )}
    </article>
  );
};

export default GenericDocument;
