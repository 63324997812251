import { action } from 'typesafe-actions';

import {
  Deal,
  NewDealConfig,
  UpdateDealRequestData,
} from '@payaca/types/dealTypes';
import {
  GetListedDealsRequestData,
  ListedDealsListViewPage,
} from '@payaca/types/listedDealTypes';
import { createAsyncAction } from '../utils';
import {
  ActionType,
  AddDealAddressRequestData,
  GetDealAcceptedItemsCopy,
  GetListedPipelineDealsAction,
  SetDealPipelineAction,
} from './dealsTypes';

// GET DEALS
export const requestGetDeal = (
  dealId: number,
  callback?: (deal: Deal) => void
) => {
  return action(ActionType.REQUEST_GET_DEAL, {
    dealId,
    callback,
  });
};

export const getDealSuccess = (dealId: number, deal: Deal) => {
  return action(ActionType.GET_DEAL_SUCCESS, {
    dealId,
    deal,
  });
};

export const getDealFailure = (dealId: number, error: Error) => {
  return action(ActionType.GET_DEAL_FAILURE, { dealId }, null, error);
};

export const requestCreateDeal = (
  config?: NewDealConfig,
  onCreateDealSuccess?: (dealId: number) => void,
  onCreateDealFailure?: (error: Error) => void
) => {
  return action(ActionType.REQUEST_CREATE_DEAL, {
    onCreateDealSuccess,
    config,
    onCreateDealFailure,
  });
};

export const createDealSuccess = (dealId: number) => {
  return action(ActionType.CREATE_DEAL_SUCCESS, {
    dealId,
  });
};

export const createDealFailure = (error: Error) => {
  return action(ActionType.CREATE_DEAL_FAILURE, null, error);
};

export const requestActivateDealView = (
  accountId: number,
  onActivated?: any
) => {
  return action(ActionType.REQUEST_ACTIVATE_DEAL_VIEW, {
    accountId,
    onActivated,
  });
};

export const activateDealViewSuccess = () => {
  return action(ActionType.ACTIVATE_DEAL_VIEW_SUCCESS);
};

export const activateDealViewFailure = (error: Error) => {
  return action(ActionType.ACTIVATE_DEAL_VIEW_FAILURE, null, null, error);
};

export const clearDeal = (dealId: number) => {
  return action(ActionType.CLEAR_DEAL, {
    dealId,
  });
};

export const clearDeals = () => {
  return action(ActionType.CLEAR_DEALS);
};

// UPDATE DEAL
export const requestUpdateDeal = (
  dealId: number,
  dealUpdates: UpdateDealRequestData,
  callback?: (updatedDeal: Deal) => void
) => {
  return action(ActionType.REQUEST_UPDATE_DEAL, {
    dealId,
    dealUpdates,
    callback,
  });
};

export const updateDealSuccess = (dealId: number, updatedDeal: Deal) => {
  return action(ActionType.UPDATE_DEAL_SUCCESS, {
    dealId,
    deal: updatedDeal,
  });
};

export const updateDealFailure = (dealId: number, error: Error) => {
  return action(ActionType.UPDATE_DEAL_FAILURE, null, null, error);
};

export const requestSetDealCustomer = (dealId: number, customerId?: number) => {
  return action(ActionType.REQUEST_SET_DEAL_CUSTOMER, {
    dealId,
    customerId,
  });
};

export const setDealCustomerSuccess = (dealId: number) => {
  return action(ActionType.SET_DEAL_CUSTOMER_SUCCESS, {
    dealId,
  });
};

export const setDealCustomerFailure = (error: Error) => {
  return action(ActionType.SET_DEAL_CUSTOMER_FAILURE, null, null, error);
};

export const requestUnsetDealCustomer = (dealId: number) => {
  return action(ActionType.REQUEST_UNSET_DEAL_CUSTOMER, {
    dealId,
  });
};

export const unsetDealCustomerSuccess = (dealId: number) => {
  return action(ActionType.UNSET_DEAL_CUSTOMER_SUCCESS, {
    dealId,
  });
};

export const unsetDealCustomerFailure = (error: Error) => {
  return action(ActionType.UNSET_DEAL_CUSTOMER_FAILURE, null, null, error);
};

export const requestGetListedDealsPage = (
  getListedDealsRequestData: GetListedDealsRequestData,
  callback?: (listedDealsPage: ListedDealsListViewPage) => void
) => {
  return action(ActionType.REQUEST_GET_LISTED_DEALS_PAGE, {
    getListedDealsRequestData,
    callback,
  });
};

export const clearListedDealsPage = () => {
  return action(ActionType.CLEAR_LISTED_DEALS_PAGE);
};

export const getListedDealsPageSuccess = (
  listedDealsPage: ListedDealsListViewPage
) => {
  return action(ActionType.GET_LISTED_DEALS_PAGE_SUCCESS, {
    listedDealsPage,
  });
};

export const getListedDealsPageFailure = (error: Error) => {
  return action(ActionType.GET_LISTED_DEALS_PAGE_FAILURE, null, null, error);
};

export const requestArchiveDeals = (
  dealIds: number[],
  onArchiveSuccess?: () => void
) => {
  return action(ActionType.REQUEST_ARCHIVE_DEALS, {
    dealIds,
    onArchiveSuccess,
  });
};

export const archiveDealsSuccess = () => {
  return action(ActionType.ARCHIVE_DEALS_SUCCESS);
};

export const archiveDealsFailure = (error: Error) => {
  return action(ActionType.ARCHIVE_DEALS_FAILURE, null, null, error);
};

export const requestRestoreDeals = (
  dealIds: number[],
  onRestoreSuccess?: () => void
) => {
  return action(ActionType.REQUEST_RESTORE_DEALS, {
    dealIds,
    onRestoreSuccess,
  });
};

export const restoreDealsSuccess = () => {
  return action(ActionType.RESTORE_DEALS_SUCCESS);
};

export const restoreDealsFailure = (error: Error) => {
  return action(ActionType.RESTORE_DEALS_FAILURE, null, null, error);
};

export const requestAddDealAddress = (
  addDealAddressRequestData: AddDealAddressRequestData,
  callback?: () => void
) => {
  return action(ActionType.REQUEST_ADD_DEAL_ADDRESS, {
    addDealAddressRequestData,
    callback,
  });
};

export const addDealAddressSuccess = () => {
  return action(ActionType.ADD_DEAL_ADDRESS_SUCCESS);
};

export const addDealAddressFailure = (error: Error) => {
  return action(ActionType.ADD_DEAL_ADDRESS_FAILURE, null, null, error);
};

export const requestRemoveDealAddress = (
  dealId: number,
  dealAddressId: number,
  callback?: () => void
) => {
  return action(ActionType.REQUEST_REMOVE_DEAL_ADDRESS, {
    dealId,
    dealAddressId,
    callback,
  });
};

export const removeDealAddressSuccess = () => {
  return action(ActionType.REMOVE_DEAL_ADDRESS_SUCCESS);
};

export const removeDealAddressFailure = (error: Error) => {
  return action(ActionType.REMOVE_DEAL_ADDRESS_FAILURE, null, null, error);
};

export const setDealPipeline = createAsyncAction<SetDealPipelineAction>({
  request: ActionType.SET_DEAL_PIPELINE_REQUEST,
  success: ActionType.SET_DEAL_PIPELINE_SUCCESS,
  failure: ActionType.SET_DEAL_PIPELINE_FAILURE,
})((payload: SetDealPipelineAction['request']['payload']) => ({ payload }));

export const getDealAcceptedItemsCopy =
  createAsyncAction<GetDealAcceptedItemsCopy>({
    request: ActionType.GET_DEAL_ACCEPTED_ITEMS_COPY_REQUEST,
  })((payload: GetDealAcceptedItemsCopy['request']['payload']) => ({
    payload,
  }));

export const getListedPipelineDeals =
  createAsyncAction<GetListedPipelineDealsAction>({
    request: ActionType.GET_LISTED_PIPELINE_DEALS_REQUEST,
    success: ActionType.GET_LISTED_PIPELINE_DEALS_SUCCESS,
    failure: ActionType.GET_LISTED_PIPELINE_DEALS_FAILURE,
  })((payload: GetListedPipelineDealsAction['request']['payload']) => ({
    payload,
  }));
