import { call, delay, put, race, takeEvery } from 'redux-saga/effects';

import { Action, PayloadAction } from 'typesafe-actions';

import { LineItemGroup } from '@payaca/types/lineItemGroupTypes';
import { getLineItem } from '../lineItemsV2/lineItemsSaga';
import {
  archiveLineItemGroupFailure,
  archiveLineItemGroupSuccess,
  clearLineItemGroups,
  createLineItemGroupFailure,
  createLineItemGroupSuccess,
  getLineItemGroupFailure,
  getLineItemGroupsFailure,
  getLineItemGroupsSuccess,
  getLineItemGroupSuccess,
  updateLineItemGroupFailure,
  updateLineItemGroupSuccess,
} from './lineItemGroupsActions';
import { ActionType, SagaConfig } from './lineItemGroupsTypes';

import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const lineItemGroupsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig): any => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  // GET LINE ITEM GROUPS
  function* handleGetLineItemGroups() {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getLineItemGroups),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get line item groups timed out.';
        yield put(
          getLineItemGroupsFailure(new Error(errorMessage), errorMessage)
        );
      } else {
        yield put(getLineItemGroupsSuccess(response));
      }
    } catch (error: any) {
      yield put(getLineItemGroupsFailure(error, error.message));
    }
  }

  // GET LINE ITEM GROUP
  function* handleGetLineItemGroup(
    action: PayloadAction<
      ActionType.REQUEST_GET_LINE_ITEM_GROUP,
      { lineItemGroupId: number }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getLineItemGroup, action.payload.lineItemGroupId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get line item group timed out.';
        yield put(
          getLineItemGroupFailure(
            action.payload.lineItemGroupId,
            new Error(errorMessage),
            errorMessage
          )
        );
      } else {
        // get line items on line item group
        for (const lineItemRelation of response.lineItemRelations) {
          yield call(getLineItem, lineItemRelation.lineItemId);
        }
        yield put(getLineItemGroupSuccess(response));
      }
    } catch (error: any) {
      yield put(
        getLineItemGroupFailure(
          action.payload.lineItemGroupId,
          error,
          error.message
        )
      );
    }
  }

  // CREATE LINE ITEM GROUP
  function* handleCreateLineItemGroup(
    action: Action<ActionType.REQUEST_CREATE_LINE_ITEM_GROUP>
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(createLineItemGroup),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Create line item group timed out.';
        yield put(
          createLineItemGroupFailure(new Error(errorMessage), errorMessage)
        );
      } else {
        yield put(createLineItemGroupSuccess(response));
      }
    } catch (error: any) {
      yield put(createLineItemGroupFailure(error, error.message));
    }
  }

  // UPDATE LINE ITEM GROUP
  function* handleUpdateLineItemGroup(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_LINE_ITEM_GROUP,
      { lineItemGroupId: number; lineItemGroup: LineItemGroup }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          updateLineItemGroup,
          action.payload.lineItemGroupId,
          action.payload.lineItemGroup
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Create line item group timed out.';
        yield put(
          updateLineItemGroupFailure(
            action.payload.lineItemGroupId,
            new Error(errorMessage),
            errorMessage
          )
        );
      } else {
        yield put(updateLineItemGroupSuccess(response));
      }
    } catch (error: any) {
      yield put(
        updateLineItemGroupFailure(
          action.payload.lineItemGroupId,
          error,
          error.message
        )
      );
    }
  }

  // ARCHIVE LINE ITEM GROUP
  function* handleArchiveLineItemGroup(
    action: PayloadAction<
      ActionType.REQUEST_ARCHIVE_LINE_ITEM_GROUP,
      { lineItemGroupId: number }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(archiveLineItemGroup, action.payload.lineItemGroupId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Archive line item group timed out.';
        yield put(
          archiveLineItemGroupFailure(
            action.payload.lineItemGroupId,
            new Error(errorMessage),
            errorMessage
          )
        );
      } else {
        yield put(archiveLineItemGroupSuccess(action.payload.lineItemGroupId));
      }
    } catch (error: any) {
      yield put(
        archiveLineItemGroupFailure(
          action.payload.lineItemGroupId,
          error,
          error.message
        )
      );
    }
  }

  // LOGOUT
  function* handleAppLogout() {
    yield put(clearLineItemGroups());
  }

  const getLineItemGroups = async () => {
    const response = await req.get(`/line_item_groups`);
    return await response.json();
  };
  const getLineItemGroup = async (lineItemGroupId: number) => {
    const response = await req.get(`/line_item_groups/${lineItemGroupId}`);
    return await response.json();
  };
  const createLineItemGroup = async () => {
    const response = await req.post('/line_item_groups', {});
    return await response.json();
  };
  const updateLineItemGroup = async (
    lineItemGroupId: number,
    lineItemGroup: LineItemGroup
  ) => {
    const response = await req.put(`/line_item_groups/${lineItemGroupId}`, {
      lineItemGroup,
    });
    return await response.json();
  };
  const archiveLineItemGroup = async (lineItemGroupId: number) => {
    const response = await req.put(
      `/line_item_groups/${lineItemGroupId}/archive`
    );
    return await response;
  };

  return function* () {
    yield takeEvery(
      ActionType.REQUEST_GET_LINE_ITEM_GROUPS,
      handleGetLineItemGroups
    );
    yield takeEvery(
      ActionType.REQUEST_GET_LINE_ITEM_GROUP,
      handleGetLineItemGroup
    );
    yield takeEvery(
      ActionType.REQUEST_CREATE_LINE_ITEM_GROUP,
      handleCreateLineItemGroup
    );
    yield takeEvery(
      ActionType.REQUEST_UPDATE_LINE_ITEM_GROUP,
      handleUpdateLineItemGroup
    );
    yield takeEvery(
      ActionType.REQUEST_ARCHIVE_LINE_ITEM_GROUP,
      handleArchiveLineItemGroup
    );

    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default lineItemGroupsSagaCreator;
