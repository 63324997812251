import { FC, useCallback, useMemo } from 'react';
import { User } from 'react-iconly';

import Badge from '@payaca/components/badge/Badge';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import AddressInfo from '../addressInfo/AddressInfo';
import ContactDetailInfo from '../contactDetailInfo/ContactDetailInfo';

import {
  getBillingAddressFromCustomer,
  getCustomerAddressAsString,
  getPrimaryContactFromCustomer,
} from '@payaca/helpers/customerHelper';

import { Customer, CustomerContact } from '@payaca/types/customerTypes';
import { DealAddresses } from '@payaca/types/dealTypes';

import './CustomerInformation.sass';

type Props = {
  customer?: Customer;
  showPrimaryContactOnly?: boolean;
  showBillingAddressOnly?: boolean;
  hideAddresses?: boolean;
  showContactDescriptions?: boolean;
  renderCustomerContact?: (
    customerContact: CustomerContact,
    index: number,
    originalComponent: JSX.Element
  ) => JSX.Element;
  dealAddresses?: DealAddresses; // only for ScheduledEventReadView
  showDealAddressesOnly?: boolean;
};
const CustomerInformation: FC<Props> = ({
  customer,
  renderCustomerContact,
  showPrimaryContactOnly = false,
  showBillingAddressOnly = false,
  hideAddresses = false,
  showContactDescriptions = false,
  dealAddresses,
  showDealAddressesOnly = false,
}: Props): JSX.Element => {
  const primaryContact = useMemo(() => {
    return customer && getPrimaryContactFromCustomer(customer);
  }, [customer]);

  const addresses = useMemo(() => {
    return customer?.addresses?.filter((address) => {
      const addressAsString = getCustomerAddressAsString(address.address);
      return !!addressAsString && addressAsString !== '';
    });
  }, [customer?.addresses]);

  const billingAddress = useMemo(() => {
    return customer && getBillingAddressFromCustomer(customer);
  }, [customer]);

  const hasAnyAddresses = useMemo(() => {
    return !!addresses?.length || !!dealAddresses?.siteAddresses?.length;
  }, [addresses]);

  const contactDetailMethodsElement = useCallback(
    (contact: CustomerContact) => {
      return (
        <ContactDetailInfo
          contact={{
            emailAddress: contact.emailAddress,
            telephoneNumber: contact.telephoneNumber,
          }}
        />
      );
    },
    []
  );

  const renderCustomerContactDefault = useCallback(
    (contact: CustomerContact, index: number) => {
      return (
        <div className="customer-contact" key={`customer-contact-${index}`}>
          <p className="contact-name">
            <User set="light" size="small" />
            <span>{contact.name} </span>
            {!showPrimaryContactOnly && contact.isPrimaryContact && (
              <Badge>Primary contact</Badge>
            )}
          </p>
          {showContactDescriptions && contact.description && (
            <p className="contact-description">
              <small>{contact.description}</small>
            </p>
          )}
          {contactDetailMethodsElement(contact)}
        </div>
      );
    },
    [
      showPrimaryContactOnly,
      showContactDescriptions,
      contactDetailMethodsElement,
    ]
  );

  return (
    <ResponsiveViewWrapper
      className="customer-information"
      downBreakpointSm={500}
    >
      <>
        {customer?.name && <p className="customer-name">{customer.name}</p>}
        <>
          <hr />
          <div className="customer-contacts">
            {!showPrimaryContactOnly && (
              <div className="customer-contacts-inner">
                {customer?.contacts.map((contact, index) => {
                  if (renderCustomerContact) {
                    return renderCustomerContact(
                      contact,
                      index,
                      renderCustomerContactDefault(contact, index)
                    );
                  } else {
                    return renderCustomerContactDefault(contact, index);
                  }
                })}
              </div>
            )}
            {customer && showPrimaryContactOnly && primaryContact && (
              <>
                {primaryContact.name !== customer.name && (
                  <p className="contact-name">{primaryContact.name}</p>
                )}
                {contactDetailMethodsElement(primaryContact)}
              </>
            )}
          </div>
        </>
        {hasAnyAddresses && !hideAddresses && (
          <>
            {customer?.name && <hr />}
            <div className="customer-addresses">
              {!showBillingAddressOnly &&
                !showDealAddressesOnly &&
                addresses?.map((address, index) => {
                  return (
                    <AddressInfo
                      key={`customer-address-${index}`}
                      address={address.address}
                      badgeLabel={
                        address.isBillingAddress ? 'Billing address' : null
                      }
                      description={address.description}
                      contacts={address.contacts}
                    />
                  );
                })}
              {showBillingAddressOnly &&
                !showDealAddressesOnly &&
                billingAddress && (
                  <div className="addresses-wrapper">
                    <span>Billing address</span>
                    <AddressInfo
                      address={billingAddress.address}
                      description={billingAddress.description}
                      contacts={billingAddress.contacts}
                    />
                  </div>
                )}

              {/* Site addresses */}
              {!showBillingAddressOnly && dealAddresses?.siteAddresses && (
                <div className="addresses-wrapper">
                  <span>{`Site address${
                    dealAddresses.siteAddresses?.length > 1 ? 'es' : ''
                  }`}</span>
                  {dealAddresses.siteAddresses.map((dealAddress, index) => {
                    return (
                      <AddressInfo
                        key={`deal-address-${index}`}
                        address={dealAddress.address}
                        contacts={dealAddress.contacts}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </>
    </ResponsiveViewWrapper>
  );
};

export default CustomerInformation;
