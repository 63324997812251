import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { FC } from 'react';

type Props = { className?: string };
const SearchedDeviceDetailsCardSkeleton: FC<Props> = (props) => {
  const { className } = props;
  return (
    <Card sizeVariant={CardSizeVariant.SM} className={className}>
      <Card.Body className="space-y-4">
        <SkeletonLoader.Title className="w-1/6" />
        <SkeletonLoader.Text className="w-1/3" />
        <SkeletonLoader.Text className="w-1/3" />
        <SkeletonLoader.Text className="w-1/3" />
        <SkeletonLoader.Text className="w-1/3" />
        <SkeletonLoader.Text className="w-1/3" />
      </Card.Body>
    </Card>
  );
};

export default SearchedDeviceDetailsCardSkeleton;
