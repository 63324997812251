// ======================================================================================================
// Public routing
// - Routes/redirects accessible to unauthorised users
// ======================================================================================================

import { PublicRoutingConfig } from './publicRoutingConfig';
import RoutesAndRedirects from './RoutesAndRedirects';

const PublicRouting = ({ match }) => {
  return (
    <RoutesAndRedirects
      availableRoutes={PublicRoutingConfig.Routes}
      availableRedirects={PublicRoutingConfig.Redirects}
      matchPath={match.path}
    />
  );
};

export default PublicRouting;
