import { FunctionComponent } from 'react';
import Checkbox from '../checkbox/Checkbox';
import './RowSelectionTableCell.css';

type Props = {
  isSelected: boolean;
  isDisabled?: boolean;
  onChange: () => void;
  isTrExclude?: boolean;
};

const RowSelectionTableCell: FunctionComponent<Props> = ({
  isSelected,
  isDisabled = false,
  onChange,
  isTrExclude = true,
}: Props): JSX.Element | null => {
  return (
    <td
      className={`row-selection-table-cell ${isTrExclude ? 'tr-exclude' : ''}`}
      onClick={(e) => e.stopPropagation()}
    >
      <Checkbox
        isChecked={isSelected}
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </td>
  );
};

export default RowSelectionTableCell;
