import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
  getInvoicesByDealId,
  getJob,
  getJobContentByJobId,
  getJobPaymentsByJobId,
  getRelatedJobByDealId,
} from '@/utils/stateAccessors';
import * as invoiceActions from '@payaca/store/invoices/invoicesActions';
import JobOverview from '../jobOverview/JobOverviewRetrievalWrapper';
import './PreviewJobControl.sass';

import { actions as appActions } from '@/api/app';
import { actions as jobActions } from '@/api/jobs';
import { useSelector } from '@/api/state';
import { actions as userActions } from '@/api/users';
import { getModal } from '@/helpers/modalHelper';
import EditJobModal from '@/ui/components/editJobModal/EditJobModal';
import MarkAsSentModal from '@/ui/components/markAsSentModal/MarkAsSentModal';
import PreviewDocument, {
  IProps as IPreviewQuoteInvoiceProps,
} from '@/ui/components/PreviewQuoteInvoice/PreviewDocument';
import RecordPaymentModal from '@/ui/components/recordPaymentModal/RecordPaymentModal';
import ResendJobModal from '@/ui/components/resendJobModal/ResendJobModal';
import RestoreJobModal from '@/ui/components/restoreJobModal/RestoreJobModal';
import { getAvailableActionsForJob } from '@payaca/helpers/jobActionsHelper';
import {
  getJobStatusPLBadgeState,
  getJobType,
} from '@payaca/helpers/jobHelperV2';
import { getCompletedDepositPaymentValueFromJobPayments } from '@payaca/helpers/jobPaymentHelper';
import {
  isEstimate,
  isInvoice,
  isQuote,
  isQuoteOrEstimate,
} from '@payaca/helpers/jobStatusHelper';
import * as fileDownloadActions from '@payaca/store/fileDownload/fileDownloadActions';
import { useDeal } from '@payaca/store/hooks/appState';
import { FileDownloadTypes } from '@payaca/types/fileDownloadTypes';
import { JobActionType } from '@payaca/types/jobActionsTypes';
import { downloadPdfData } from '@payaca/utilities/fileUtilities';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import RemoveAcceptedModal from './RemoveAcceptedModal';

type Props = {
  jobId: number;
  onJobUpdateSuccess?: () => void;
  onDealPaymentsUpdateSuccess?: () => void;
  markAsSentCallback?: () => void;
  primaryAction?: IPreviewQuoteInvoiceProps['firstAction'];
  prefixContent?: React.ReactNode;
  stickyTopClassName?: string;
  includeDownloadPDFAction?: boolean;
};

const PreviewJobControl: FC<Props> = ({
  jobId,
  onJobUpdateSuccess,
  onDealPaymentsUpdateSuccess,
  markAsSentCallback,
  primaryAction,
  prefixContent,
  stickyTopClassName,
  includeDownloadPDFAction = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isAcceptingProposition, setIsAcceptingProposition] = useState(false);
  const [isDeclineProposition, setIsDeclineProposition] = useState(false);
  const [isDuplicatingJob, setIsDuplicatingJob] = useState(false);
  const [isDeletingJob, setIsDeletingJob] = useState(false);
  const [isInvoicingJob, setIsInvoicingJob] = useState(false);

  const [showRecordPaymentModal, setShowRecordPaymentModal] = useState(false);
  const [showResendJobModal, setShowResendJobModal] = useState(false);
  const [showEditWarningModal, setShowEditWarningModal] = useState(false);
  const [showMarkAsSentModal, setShowMarkAsSentModal] = useState(false);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [showRestoreJobModal, setShowRestoreJobModal] = useState(false);
  const [showRemoveAcceptanceJobModal, setShowRemoveAcceptanceModal] =
    useState(false);

  const customer = useSelector((state) => {
    return state.customer.currentCustomer;
  });

  const job = useSelector((state) => {
    return getJob(state, jobId);
  });

  const jobTitle = useMemo(() => {
    if (!job) return;
    return `${getJobType(job)} #${job.customReference || job.reference}`;
  }, [job]);

  const jobContent = useSelector((state) => {
    return getJobContentByJobId(state, jobId);
  });

  const relatedJob = useSelector((state) => {
    return !!job && getRelatedJobByDealId(state, job.dealId, jobId);
  });

  const jobPayments = useSelector((state) =>
    getJobPaymentsByJobId(state, jobId)
  );

  const deal = useDeal(job?.dealId);

  useEffect(() => {
    if (job) {
      dispatch(invoiceActions.requestGetInvoicesForDeal(job.dealId));
    }
  }, [job?.dealId]);

  const invoices = useSelector((state) => {
    return !!job && getInvoicesByDealId(state, job.dealId);
  });

  const availableActions: JobActionType[] = useMemo(() => {
    if (!job || !customer || !deal) return [];
    return getAvailableActionsForJob(
      job,
      deal,
      customer,
      jobPayments || [],
      relatedJob ? [relatedJob] : [],
      invoices || []
    );
  }, [job, deal, customer, jobPayments, relatedJob, invoices]);

  const jobIsQuoteOrEstimate = useMemo(() => {
    if (!job) return false;
    return isQuoteOrEstimate(job.status);
  }, [job]);

  const acceptProposition = useCallback(() => {
    setIsAcceptingProposition(true);
    dispatch(
      jobActions.acceptQuote(jobId, () => {
        onJobUpdateSuccess?.();
        setIsAcceptingProposition(false);
      })
    );
  }, [dispatch, jobId, onJobUpdateSuccess, setIsAcceptingProposition]);

  const declineProposition = useCallback(() => {
    setIsDeclineProposition(true);
    dispatch(
      jobActions.declineJob(jobId, () => {
        onJobUpdateSuccess?.();
        setIsDeclineProposition(false);
      })
    );
  }, [dispatch, jobId, onJobUpdateSuccess, setIsDeclineProposition]);

  const duplicateJob = useCallback(() => {
    setIsDuplicatingJob(true);
    dispatch(
      jobActions.duplicateJob(jobId, (error: any, response: any) => {
        if (!error) {
          history.push('/'); // this is needed so the page actually reloads - otherwise it sees that the route hasn't changed and doesn't do anything
          if (jobIsQuoteOrEstimate) {
            history.push(`/quotes/${response.newJobId}`);
          } else {
            history.push(`/invoices/${response.newJobId}`);
          }
        }
        setIsDuplicatingJob(false);
      })
    );
  }, [jobIsQuoteOrEstimate, jobId, history, setIsDuplicatingJob]);

  const deleteJob = useCallback(() => {
    dispatch(
      appActions.showModal(
        getModal('ARCHIVE_JOB', {
          primaryAction: () => {
            setIsDeletingJob(true);
            dispatch(
              jobActions.archiveJob(jobId, () => {
                dispatch(appActions.hideModal());
                setIsDeletingJob(false);

                history.push(`/deals`);
              })
            );
          },
          secondaryAction: () => dispatch(appActions.hideModal()),
          onClose: () => dispatch(appActions.hideModal()),
        })
      )
    );
  }, [dispatch, job, history, setIsDeletingJob]);

  const recordPaymentCallback = useCallback(() => {
    onDealPaymentsUpdateSuccess?.();
    onJobUpdateSuccess?.();
  }, [onDealPaymentsUpdateSuccess, onJobUpdateSuccess]);

  const jobHasOutstandingDepositPayment = useMemo(() => {
    if (!job || !jobContent) return false;
    if (isInvoice(job.status)) {
      return false;
    } else {
      const value =
        (jobContent.depositAmount || 0) -
        getCompletedDepositPaymentValueFromJobPayments(jobPayments);
      return value > 0;
    }
  }, [job, jobPayments, jobContent]);

  const invoiceJob = useCallback(() => {
    if (!job) return;
    setIsInvoicingJob(true);
    dispatch(
      jobActions.convertJobToInvoice(job.id, (err: Error, resp: any) => {
        if (!err) {
          dispatch(
            userActions.getAccountTerms(job.accountId, () => {
              history.push({
                pathname: `/invoices/${resp.invoiceId}`,
              });
              setIsInvoicingJob(false);
            })
          );
        } else {
          setIsInvoicingJob(false);
        }
      })
    );
  }, [dispatch, job, history, setIsInvoicingJob]);

  const promptRecordDepositPaymentBeforeInvoice = useCallback(() => {
    dispatch(
      appActions.showModal(
        getModal('OUTSTANDING_DEPOSIT_PAYMENT_PROMPT', {
          primaryAction: () => {
            setShowRecordPaymentModal(true);
            dispatch(appActions.hideModal());
          },
          secondaryAction: () => {
            invoiceJob();
            dispatch(appActions.hideModal());
          },
          onClose: () => dispatch(appActions.hideModal()),
        })
      )
    );
  }, [invoiceJob, dispatch]);

  const handleInvoiceJob = useCallback(() => {
    if (jobHasOutstandingDepositPayment) {
      promptRecordDepositPaymentBeforeInvoice();
    } else {
      invoiceJob();
    }
  }, [
    jobHasOutstandingDepositPayment,
    promptRecordDepositPaymentBeforeInvoice,
    invoiceJob,
  ]);

  const pdfFileName = useMemo(() => {
    if (!job) return '';
    if (isInvoice(job.status)) {
      return `invoice_${job.reference}.pdf`;
    } else if (isQuote(job.status)) {
      return `quote_${job.reference}.pdf`;
    } else if (isEstimate(job.status)) {
      return `estimate_${job.reference}.pdf`;
    } else {
      return `job_${job.reference}.pdf`;
    }
  }, [job]);

  const triggerFileDownload = useCallback(
    (fileContent: string) => {
      downloadPdfData(fileContent, pdfFileName);
      setIsDownloadingPdf(false);
    },
    [pdfFileName]
  );

  const handleDownloadDocument = useCallback(() => {
    if (!job) return;

    setIsDownloadingPdf(true);
    dispatch(
      fileDownloadActions.requestDownloadFile(
        jobId,
        isInvoice(job.status)
          ? FileDownloadTypes.INVOICE
          : FileDownloadTypes.QUOTE,
        triggerFileDownload
      )
    );
  }, [dispatch, jobId, job, triggerFileDownload]);

  const availableContextMenuItems = useMemo<
    IPreviewQuoteInvoiceProps['actions']
  >(() => {
    const items = [];

    if (includeDownloadPDFAction) {
      items.push({
        label: 'Download PDF',
        onClick: handleDownloadDocument,
        isProcessing: isDownloadingPdf,
        disabled: isDownloadingPdf,
      });
    }

    if (availableActions.includes(JobActionType.UNARCHIVE)) {
      items.push({
        label: 'Restore',
        onClick: () => setShowRestoreJobModal(true),
      });
    }

    if (availableActions.includes(JobActionType.MARK_AS_ACCEPTED)) {
      items.push({
        label: 'Mark as accepted',
        onClick: acceptProposition,
        isProcessing: isAcceptingProposition,
        disabled: isAcceptingProposition,
      });
    }

    if (availableActions.includes(JobActionType.MARK_AS_DECLINED)) {
      items.push({
        label: 'Mark as declined',
        onClick: declineProposition,
        isProcessing: isDeclineProposition,
        disabled: isDeclineProposition,
      });
    }

    if (availableActions.includes(JobActionType.RECORD_DEPOSIT_PAYMENT)) {
      items.push({
        label: 'Record deposit payment',
        onClick: () => setShowRecordPaymentModal(true),
      });
    }

    if (availableActions.includes(JobActionType.INVOICE) && !!jobContent) {
      items.push({
        label: 'Invoice',
        onClick: handleInvoiceJob,
        isProcessing: isInvoicingJob,
        disabled: isInvoicingJob,
      });
    }

    if (availableActions.includes(JobActionType.RECORD_PAYMENT)) {
      items.push({
        label: 'Record payment',
        onClick: () => setShowRecordPaymentModal(true),
      });
    }

    if (availableActions.includes(JobActionType.DUPLICATE)) {
      items.push({
        label: 'Duplicate',
        onClick: duplicateJob,
        isProcessing: isDuplicatingJob,
        disabled: isDuplicatingJob,
      });
    }

    if (availableActions.includes(JobActionType.ARCHIVE)) {
      items.push({
        label: 'Delete',
        onClick: deleteJob,
        isProcessing: isDeletingJob,
        disabled: isDeletingJob,
      });
    }

    if (availableActions.includes(JobActionType.REMOVE_ACCEPTED)) {
      items.push({
        label: 'Remove accepted',
        onClick: () => setShowRemoveAcceptanceModal(true),
      });
    }

    if (availableActions.includes(JobActionType.RESEND)) {
      items.push({
        label: 'Resend',
        onClick: () => setShowResendJobModal(true),
      });
    }

    if (availableActions.includes(JobActionType.INACTIVATE_AND_EDIT)) {
      items.push({
        label: 'Edit',
        onClick: () => setShowEditWarningModal(true),
      });
    }

    if (availableActions.includes(JobActionType.MARK_AS_SENT)) {
      items.push({
        label: 'Mark as sent',
        onClick: () => setShowMarkAsSentModal(true),
      });
    }

    return items;
  }, [
    includeDownloadPDFAction,
    availableActions,
    setShowRestoreJobModal,
    setShowRecordPaymentModal,
    setShowResendJobModal,
    setShowEditWarningModal,
    setShowMarkAsSentModal,
    acceptProposition,
    declineProposition,
    duplicateJob,
    deleteJob,
    jobContent,
    handleInvoiceJob,
    handleDownloadDocument,
    isDownloadingPdf,
    isAcceptingProposition,
    isDeclineProposition,
    isDuplicatingJob,
    isDeletingJob,
    isInvoicingJob,
  ]);

  if (!job || !jobContent) return null;

  return (
    <>
      <PreviewDocument
        title={jobTitle}
        firstAction={primaryAction}
        actions={availableContextMenuItems}
        badge={{
          variant: 'soft',
          colour: getJobStatusPLBadgeState(job.readableStatus),
          children: job.readableStatus,
        }}
      >
        {prefixContent}
        <JobOverview stickyTopClassName={stickyTopClassName} jobId={jobId} />
      </PreviewDocument>

      <RecordPaymentModal
        dealId={job.dealId}
        paymentTarget={{
          jobId: jobId,
        }}
        isOpen={showRecordPaymentModal}
        onClose={() => setShowRecordPaymentModal(false)}
        recordPaymentCallback={recordPaymentCallback}
        confirmPaymentCallback={recordPaymentCallback}
      />
      <ResendJobModal
        isOpen={showResendJobModal}
        onClose={() => {
          setShowResendJobModal(false);
          onJobUpdateSuccess?.();
        }}
        jobId={jobId}
      />
      <EditJobModal
        isOpen={showEditWarningModal}
        onClose={() => setShowEditWarningModal(false)}
        jobId={jobId}
      />

      <MarkAsSentModal
        jobId={job.id}
        isOpen={showMarkAsSentModal}
        jobType={getJobType(job)}
        onClose={() => setShowMarkAsSentModal(false)}
        markAsSentCallback={markAsSentCallback}
      />
      <RestoreJobModal
        isOpen={showRestoreJobModal}
        jobId={job.id}
        onClose={() => setShowRestoreJobModal(false)}
        onRestoreJobCallback={() => {
          setShowRestoreJobModal(false);
          onJobUpdateSuccess?.();
        }}
      />
      <RemoveAcceptedModal
        isOpen={showRemoveAcceptanceJobModal}
        proposalId={job.id}
        onClose={() => setShowRemoveAcceptanceModal(false)}
        projectVersion={deal?.version}
        removeAcceptedCallback={() => {
          setShowRemoveAcceptanceModal(false);
          onJobUpdateSuccess?.();
        }}
      />
    </>
  );
};

export default PreviewJobControl;
