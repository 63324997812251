import { ListViewPage, SortDirection } from './listViewTypes';

import { Customer } from './customerTypes';
import { Deal } from './dealTypes';
import {
  BasePurchaseOrder,
  PurchaseOrderStatus,
  PurchaseOrderTotals,
} from './materialsListTypes';
import { Supplier } from './supplierTypes';
import { User } from './userTypes';

export type ListedPurchaseOrder = BasePurchaseOrder &
  PurchaseOrderTotals & {
    predictedTotalExcludingTax?: number;
    predictedTotal?: number;
    status: PurchaseOrderStatus;
    supplier: Pick<Supplier, 'id' | 'name'>;
    deal?: Pick<Deal, 'id' | 'reference' | 'customReference'> & {
      customer?: Pick<Customer, 'id' | 'name'>;
    };
  };

export enum SortBy {
  REFERENCE = 'reference',
  SENT_AT = 'sentAt',
}

export type GetListedPurchaseOrdersQueryParams = {
  pageNumber: number;
  pageSize: number;
  sortBy: SortBy;
  sortDirection: SortDirection;
  searchTerm?: string;
  supplierIds?: Supplier['id'][];
  dealAssigneeIds?: User['id'][];
};

export type ListedPurchaseOrdersPage = ListViewPage<ListedPurchaseOrder>;
