import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { DeleteLineItemMutation } from '@/gql/graphql';

const DELETE_LINE_ITEM_MUTATION = graphql(`
  mutation DeleteLineItem($id: ID!) {
    deleteLineItem(id: $id)
  }
`);

const useDeleteLineItem = (
  options?: UseMutationOptions<DeleteLineItemMutation, unknown, string>
) => {
  return useMutation({
    mutationFn: (id) => {
      return gqlClient.request(DELETE_LINE_ITEM_MUTATION, { id });
    },
    ...options,
  });
};

export default useDeleteLineItem;
