import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { Action, PayloadAction } from 'typesafe-actions';

import {
  CancelAddOnSubscription,
  CreateAddOnSubscription,
  CreateSubscriptionRequestData,
  GetAddOnSubscriptionPaymentPreview,
  GetSubscriptionPaymentPreviewData,
  RestoreAddOnSubscription,
  SubscriptionActionTypes,
  SubscriptionSagaConfig,
  UpdateAddOnSubscription,
  UpdatePaymentMethodRequestData,
  UpdateSubscriptionRequestData,
} from './subscriptionTypes';

import {
  cancelAddOnSubscription,
  cancelSubscription,
  clearAccountSubscription,
  clearAddOnProducts,
  createAddOnSubscription,
  createSubscriptionFailure,
  createSubscriptionSuccess,
  getAccountSubscriptionFailure,
  getAccountSubscriptionSuccess,
  getAddOnProducts,
  getAddOnSubscriptionPaymentPreview,
  getSubscriptionPaymentPreviewFailure,
  getSubscriptionPaymentPreviewSuccess,
  requestGetAccountSubscription,
  restoreAddOnSubscription,
  restoreSubscriptionFailure,
  restoreSubscriptionSuccess,
  updateAddOnSubscription,
  updatePaymentMethodFailure,
  updatePaymentMethodSuccess,
  updateSubscriptionFailure,
  updateSubscriptionSuccess,
} from './subscriptionActions';

import { Req } from '@payaca/helpers/storeHelper';
import { SubscriptionPaymentPreview } from '@payaca/types/subscriptionTypes';
import take from 'lodash.take';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { handleAsyncAction } from '../utils';

const subscriptionSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SubscriptionSagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetAccountSubscription(
    action: Action<SubscriptionActionTypes.REQUEST_GET_ACCOUNT_SUBSCRIPTION>
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getAccountSubscription),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getAccountSubscriptionFailure(
            new Error('Get account subscription request timed out.')
          )
        );
      } else {
        yield put(getAccountSubscriptionSuccess(response));
      }
    } catch (error: any) {
      yield put(getAccountSubscriptionFailure(error));
    }
  }

  function* handleCreateSubscription(
    action: PayloadAction<
      SubscriptionActionTypes.REQUEST_CREATE_SUBSCRIPTION,
      {
        createSubscriptionRequestData: CreateSubscriptionRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          createSubscription,
          action.payload.createSubscriptionRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          createSubscriptionFailure(
            new Error('Create subscription request timed out.')
          )
        );
      } else {
        yield put(
          createSubscriptionSuccess(
            action.payload.createSubscriptionRequestData
          )
        );
      }
    } catch (error: any) {
      yield put(createSubscriptionFailure(error));
    }
  }

  function* handleUpdateSubscription(
    action: PayloadAction<
      SubscriptionActionTypes.REQUEST_UPDATE_SUBSCRIPTION,
      {
        updateSubscriptionRequestData: UpdateSubscriptionRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          updateSubscription,
          action.payload.updateSubscriptionRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          updateSubscriptionFailure(
            new Error('Update subscription request timed out.')
          )
        );
      } else {
        yield put(
          updateSubscriptionSuccess(
            action.payload.updateSubscriptionRequestData
          )
        );
      }
    } catch (error: any) {
      yield put(updateSubscriptionFailure(error));
    }
  }

  function* handleUpdatePaymentMethod(
    action: PayloadAction<
      SubscriptionActionTypes.REQUEST_UPDATE_PAYMENT_METHOD,
      {
        updatePaymentMethodRequestData: UpdatePaymentMethodRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          updatePaymentMethod,
          action.payload.updatePaymentMethodRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          updatePaymentMethodFailure(
            new Error('Update payment method request timed out.')
          )
        );
      } else {
        yield put(updatePaymentMethodSuccess());
      }
    } catch (error: any) {
      yield put(updatePaymentMethodFailure(error));
    }
  }

  function* handleRestoreSubscription(
    action: Action<SubscriptionActionTypes.REQUEST_RESTORE_SUBSCRIPTION>
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(restoreSubscription),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          restoreSubscriptionFailure(
            new Error('Update subscription request timed out.')
          )
        );
      } else {
        yield put(restoreSubscriptionSuccess());
      }
    } catch (error: any) {
      yield put(restoreSubscriptionFailure(error));
    }
  }

  function* handleGetSubscriptionPaymentPreview(
    action: PayloadAction<
      SubscriptionActionTypes.REQUEST_GET_SUBSCRIPTION_PAYMENT_PREVIEW,
      {
        getSubscriptionPaymentPreviewData: GetSubscriptionPaymentPreviewData;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getSubscriptionPaymentPreview,
          action.payload.getSubscriptionPaymentPreviewData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getSubscriptionPaymentPreviewFailure(
            new Error('Get subscription payment preview request timed out.')
          )
        );
      } else {
        yield put(getSubscriptionPaymentPreviewSuccess(response));
      }
    } catch (error: any) {
      yield put(getSubscriptionPaymentPreviewFailure(error));
    }
  }

  const getProducts = async () => {
    return fetch(`${apiBaseurl}/api/subscription/products`, {
      method: 'GET',
      headers: {
        Authorization: await getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetProducts failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getAccountSubscription = async () => {
    return fetch(`${apiBaseurl}/api/subscription`, {
      method: 'GET',
      headers: {
        Authorization: await getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetAccountSubscription failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const createSubscription = async (
    createSubscriptionRequestData: CreateSubscriptionRequestData
  ) => {
    return fetch(`${apiBaseurl}/api/subscription/create`, {
      method: 'POST',
      headers: {
        Authorization: await getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
      body: JSON.stringify(createSubscriptionRequestData),
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `CreateSubscription failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const updateSubscription = async (
    updateSubscriptionRequestData: UpdateSubscriptionRequestData
  ) => {
    return fetch(`${apiBaseurl}/api/subscription/update`, {
      method: 'POST',
      headers: {
        Authorization: await getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
      body: JSON.stringify(updateSubscriptionRequestData),
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `UpdateSubscription failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const updatePaymentMethod = async (
    updatePaymentMethodRequestData: UpdatePaymentMethodRequestData
  ) => {
    return fetch(`${apiBaseurl}/api/subscription/payment_method/update`, {
      method: 'POST',
      headers: {
        Authorization: await getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
      body: JSON.stringify(updatePaymentMethodRequestData),
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `UpdatePaymentMethod failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getSubscriptionPaymentPreview = async (
    getSubscriptionPaymentPreviewData: GetSubscriptionPaymentPreviewData
  ) => {
    return fetch(`${apiBaseurl}/api/subscription/update/payment_preview`, {
      method: 'POST',
      headers: {
        Authorization: await getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
      body: JSON.stringify(getSubscriptionPaymentPreviewData),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetSubscriptionPaymentPreview failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const restoreSubscription = async () => {
    return fetch(`${apiBaseurl}/api/subscription/restore`, {
      method: 'POST',
      headers: {
        Authorization: await getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `RestoreSubscription failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleLogout() {
    yield put(clearAccountSubscription());
    yield put(clearAddOnProducts.request());
  }

  function* handleAppLoaded() {
    yield put(requestGetAccountSubscription());
  }

  function* handleLoginSuccess() {
    yield take('auth.storeTokensSuccess');
    yield put(requestGetAccountSubscription());
  }

  function* handleCreateSubscriptionSuccess() {
    yield put(requestGetAccountSubscription());
  }

  function* handleUpdateSubscriptionSuccess() {
    yield put(requestGetAccountSubscription());
  }

  function* handleUpdatePaymentMethodSuccess() {
    yield put(requestGetAccountSubscription());
  }

  function* handleRestoreSubscriptionSuccess() {
    yield put(requestGetAccountSubscription());
  }

  const handleCancelSubscriptionRequest = handleAsyncAction(
    cancelSubscription,
    async (accountId, cb) => {
      return fetch(`${apiBaseurl}/api/accounts/${accountId}/subscription`, {
        method: 'DELETE',
        headers: {
          Authorization: await getAuthHeader(),
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
          'X-Native-App': `${isNativeApp}`,
        },
      }).then((response) => {
        if (response.ok) {
          cb();
          return;
        } else {
          const err = new Error(
            `CancelSubscription failed: ${response.status} ${response.statusText}`
          );
          cb(err);
          throw err;
        }
      });
    }
  );

  const handleGetAddOnProducts = handleAsyncAction(
    getAddOnProducts,
    async (payload) => {
      try {
        const response = await req.get(`/add-on-products`);
        return await response.json();
      } catch (err) {
        console.log(`Get add on products failed: ${JSON.stringify(err)}`);
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response);
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleCreateAddOnSubscription =
    handleAsyncAction<CreateAddOnSubscription>(
      createAddOnSubscription,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/add-on-products/subscriptions`,
          {
            method: 'POST',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
            body: JSON.stringify({
              addOnProductPricePublicId: payload.addOnProductPriceId,
            }),
          }
        );
        if (response.ok) {
          return await response.json();
        } else {
          throw new Error('Failed to create add-on subscription');
        }
      },
      (response, requestData) => {
        const r = response as Record<string, any>;

        requestData.payload.callback?.({
          stripeClientSecret: r.stripeClientSecret
            ? (r.stripeClientSecret as string)
            : undefined,
          addOnSubscriptionId: r.addOnSubscriptionPublicId as string,
        });
      },
      (_, requestData) => {
        requestData.payload.onErrorCallback?.();
      }
    );

  const handleUpdateAddOnSubscription =
    handleAsyncAction<UpdateAddOnSubscription>(
      updateAddOnSubscription,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/add-on-products/subscriptions/${payload.addOnSubscriptionId}`,
          {
            method: 'PATCH',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
            body: JSON.stringify({
              addOnProductPricePublicId: payload.addOnProductPriceId,
            }),
          }
        );
        if (response.ok) {
          return await response.json();
        } else {
          throw new Error('Failed to update add-on subscription');
        }
      },
      (response, requestData) => {
        const r = response as Record<string, any>;

        requestData.payload.callback?.({
          stripeClientSecret: r.stripeClientSecret
            ? (r.stripeClientSecret as string)
            : undefined,
          addOnSubscriptionId: r.addOnSubscriptionPublicId as string,
        });
      },
      (_, requestData) => {
        requestData.payload.onErrorCallback?.();
      }
    );

  const handleCancelAddOnSubscription =
    handleAsyncAction<CancelAddOnSubscription>(
      cancelAddOnSubscription,
      async (payload) => {
        const response = await req.del(
          `/add-on-products/subscriptions/${payload.addOnSubscriptionId}`
        );

        if (response.ok) {
          return;
        } else {
          throw new Error('Failed to cancel add-on subscription');
        }
      },
      (response, requestData) => {
        requestData.payload.callback?.();
      },
      (_, requestData) => {
        requestData.payload.onErrorCallback?.();
      }
    );

  const handleRestoreAddOnSubscription =
    handleAsyncAction<RestoreAddOnSubscription>(
      restoreAddOnSubscription,
      async (payload) => {
        const response = await req.post(
          `/add-on-products/subscriptions/${payload.addOnSubscriptionPublicId}/restore`,
          {}
        );

        if (response.ok) {
          return;
        } else {
          throw new Error('Failed to restore add-on subscription');
        }
      },
      (_, requestData) => {
        requestData.payload.callback?.();
      },
      (err, requestData) => {
        requestData.payload.callback?.(err);
      }
    );

  const handleGetAddOnSubscriptionPaymentPreview =
    handleAsyncAction<GetAddOnSubscriptionPaymentPreview>(
      getAddOnSubscriptionPaymentPreview,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/add-on-products/prices/${payload.addOnProductPriceId}/preview`,
          {
            method: 'GET',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
          }
        );
        if (response.ok) {
          return await response.json();
        } else {
          throw new Error('Failed to get add on subscription payment preview');
        }
      },
      (response, requestData) => {
        const r = response as Record<string, any>;
        requestData.payload.callback?.(r as SubscriptionPaymentPreview);
      },
      (_, requestData) => {
        requestData.payload.onErrorCallback?.();
      }
    );

  return function* () {
    yield takeLatest(
      SubscriptionActionTypes.REQUEST_GET_ACCOUNT_SUBSCRIPTION,
      handleGetAccountSubscription
    );

    yield takeLatest(
      SubscriptionActionTypes.REQUEST_CREATE_SUBSCRIPTION,
      handleCreateSubscription
    );
    yield takeLatest(
      SubscriptionActionTypes.REQUEST_UPDATE_SUBSCRIPTION,
      handleUpdateSubscription
    );
    yield takeLatest(
      SubscriptionActionTypes.REQUEST_UPDATE_PAYMENT_METHOD,
      handleUpdatePaymentMethod
    );
    yield takeLatest(
      SubscriptionActionTypes.REQUEST_GET_SUBSCRIPTION_PAYMENT_PREVIEW,
      handleGetSubscriptionPaymentPreview
    );
    yield takeLatest(
      SubscriptionActionTypes.REQUEST_RESTORE_SUBSCRIPTION,
      handleRestoreSubscription
    );
    yield takeEvery(
      SubscriptionActionTypes.CREATE_SUBSCRIPTION_SUCCESS,
      handleCreateSubscriptionSuccess
    );
    yield takeEvery(
      SubscriptionActionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
      handleUpdateSubscriptionSuccess
    );
    yield takeEvery(
      SubscriptionActionTypes.UPDATE_PAYMENT_METHOD_SUCCESS,
      handleUpdatePaymentMethodSuccess
    );
    yield takeEvery(
      SubscriptionActionTypes.RESTORE_SUBSCRIPTION_SUCCESS,
      handleRestoreSubscriptionSuccess
    );
    yield takeEvery(
      SubscriptionActionTypes.CANCEL_SUBSCRIPTION_REQUEST,
      handleCancelSubscriptionRequest
    );
    yield takeEvery(
      SubscriptionActionTypes.GET_ADD_ON_PRODUCTS_REQUEST,
      handleGetAddOnProducts
    );
    yield takeLatest(
      SubscriptionActionTypes.CREATE_ADD_ON_SUBSCRIPTION_REQUEST,
      handleCreateAddOnSubscription
    );
    yield takeLatest(
      SubscriptionActionTypes.UPDATE_ADD_ON_SUBSCRIPTION_REQUEST,
      handleUpdateAddOnSubscription
    );
    yield takeLatest(
      SubscriptionActionTypes.CANCEL_ADD_ON_SUBSCRIPTION_REQUEST,
      handleCancelAddOnSubscription
    );
    yield takeLatest(
      SubscriptionActionTypes.GET_ADD_ON_SUBSCRIPTION_PAYMENT_PREVIEW_REQUEST,
      handleGetAddOnSubscriptionPaymentPreview
    );
    yield takeLatest(
      SubscriptionActionTypes.RESTORE_ADD_ON_SUBSCRIPTION_REQUEST,
      handleRestoreAddOnSubscription
    );
    yield takeEvery('auth.logout', handleLogout);
    yield takeEvery('app.loaded', handleAppLoaded);
    yield takeEvery('auth.loginSuccess', handleLoginSuccess);
  };
};

export default subscriptionSagaCreator;
