import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import filesKeys from '@/api/queries/files/keyFactory';
import { graphql } from '@/gql';
import { GetIncompleteUploadIntentsQuery } from '@/gql/graphql';

const GetIncompleteUploadIntents = graphql(`
  query GetIncompleteUploadIntents($ids: [ID!]!) {
    uploadIntents(ids: $ids) {
      id
      publicId
      status
    }
  }
`);

const useGetIncompleteUploadIntents = (
  uploadIntentIds: string[],
  queryOptions?: UseQueryOptions<GetIncompleteUploadIntentsQuery>
) => {
  return useQuery({
    enabled: uploadIntentIds.length > 0,
    queryKey: filesKeys.uploadIntents(uploadIntentIds),
    queryFn: () => {
      return gqlClient.request(GetIncompleteUploadIntents, {
        ids: uploadIntentIds,
      });
    },
    refetchInterval: 5000,
    ...queryOptions,
  });
};

export default useGetIncompleteUploadIntents;
