import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { useSelector } from '@/api/state';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import TextareaFieldFormatter from '@payaca/components/textareaField/TextareaFieldFormatter';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ValidationFeedbackBlock from '@payaca/components/validationFeedbackBlock/ValidationFeedbackBlock';
import { VALID_EMAIL_REGEX } from '@payaca/constants';
import {
  getIsRequiredFieldValidator,
  getRegexMatchFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { formatPhoneNumber } from '@payaca/helpers/phoneNumberHelper';
import {
  requestMarkPurchaseOrderAsSent,
  requestSendPurchaseOrder,
} from '@payaca/store/materialsList/materialsListActions';
import { SendPurchaseOrderRequestData } from '@payaca/store/materialsList/materialsListTypes';
import { requestPersistSupplier } from '@payaca/store/suppliers/suppliersActions';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { HydratedPurchaseOrder } from '@payaca/types/materialsListTypes';
import { useDispatch } from 'react-redux';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import './SendPurchaseOrderControl.sass';

const supplierEmailAddressFieldValidator =
  getRegexMatchFieldValidator(VALID_EMAIL_REGEX);

type Props = {
  purchaseOrder: HydratedPurchaseOrder;
  onSendPurchaseOrderSuccess?: () => void;
};
const SendPurchaseOrderControl: FC<Props> = ({
  purchaseOrder,
  onSendPurchaseOrderSuccess,
}: PropsWithChildren<Props>): JSX.Element => {
  const dispatch = useDispatch();
  const supplier = purchaseOrder.supplier;
  const [showValidationFeedback, setShowValidationFeedback] = useState(false);
  const [submissionError, setSubmissionError] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isMarkAsSentProcessing, setIsMarkAsSentProcessing] = useState(false);
  const [overrideEmail, setOverrideEmail] = useState(false);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const myProfile = useSelector((state: any) => state.users.myProfile);

  const defaultEmailBody = useMemo(() => {
    return `Hi ${supplier?.name}\n\nPlease find attached **Purchase Order ${purchaseOrder?.reference}** for **${
      account.companyName
    }**\n\n${
      account.email || account.contactNumber
        ? `Please confirm our order using these details:\n${
            account.email
              ? `- [${account.email}](mailto:${account.email})\n`
              : ''
          }${
            account.contactNumber
              ? `- [${formatPhoneNumber(account.contactNumber)}](tel:${
                  account.contactNumber
                })\n\n\n`
              : ''
          }`
        : ``
    }Kind regards,\n\n${myProfile.firstname} ${myProfile.lastname}\n\n${
      account.companyName
    }`;
  }, [myProfile, account, supplier]);

  const fieldValidators = useMemo(() => {
    return {
      emailSubject: [
        getIsRequiredFieldValidator({
          customErrorMessage: 'Email subject is required',
        }),
      ],
      emailBody: [
        getIsRequiredFieldValidator({
          customErrorMessage: 'Email body is required',
        }),
      ],
      supplierEmailAddress: [
        getIsRequiredFieldValidator({
          customErrorMessage:
            'Please add a supplier email address or mark as sent offline',
        }),
        supplierEmailAddressFieldValidator,
      ],
    };
  }, []);

  const initialFormState = useMemo(() => {
    return {
      id: purchaseOrder.id,
      emailSubject: `Purchase order ${purchaseOrder?.reference} from ${account.companyName}`,
      emailBody: defaultEmailBody,
      copyMeIn: true,
      supplierEmailAddress: supplier?.emailAddress,
    };
  }, [purchaseOrder, account, defaultEmailBody]);

  const onSubmit = useCallback(
    (formState: SendPurchaseOrderRequestData) => {
      setIsProcessing(true);
      dispatch(
        requestSendPurchaseOrder(
          formState,
          () => {
            setIsProcessing(false);
            onSendPurchaseOrderSuccess && onSendPurchaseOrderSuccess();
          },
          () => {
            setIsProcessing(false);
            setSubmissionError(
              'Something went wrong sending this purchase order.'
            );
          }
        )
      );
    },
    [onSendPurchaseOrderSuccess]
  );

  const onMarkAsSentSubmit = useCallback(() => {
    setIsMarkAsSentProcessing(true);
    dispatch(
      requestMarkPurchaseOrderAsSent(
        purchaseOrder.id,
        () => {
          setIsMarkAsSentProcessing(false);
          onSendPurchaseOrderSuccess && onSendPurchaseOrderSuccess();
        },
        () => {
          setIsMarkAsSentProcessing(false);
          setSubmissionError(
            'Something went wrong marking this purchase order as sent.'
          );
        }
      )
    );
  }, [onSendPurchaseOrderSuccess]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <div className="form-body">
            <div>
              Sending to <strong>{supplier?.name} </strong>
              {!overrideEmail && (
                <>
                  {supplier?.emailAddress && (
                    <>
                      ({supplier?.emailAddress})
                      <Button
                        styleVariant={ButtonStyleVariant.ANCHOR}
                        onClick={() => setOverrideEmail(true)}
                      >
                        Override email address for this PO
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
            {(overrideEmail ||
              (!supplier?.emailAddress && !supplier?.isSystemManaged)) && (
              <ValidatedFieldWrapper
                isTouched={!!touchedState?.supplierEmailAddress}
                validationResult={{
                  isValid: !!validationState.supplierEmailAddress?.isValid,
                }}
              >
                <LoqateAdvisoryEmailInputField
                  label={`${
                    overrideEmail ? 'Email address' : 'Supplier email address'
                  }`}
                  name="supplierEmailAddress"
                  value={formState.supplierEmailAddress}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                />
              </ValidatedFieldWrapper>
            )}
            <ValidatedFieldWrapper
              isTouched={!!touchedState?.emailSubject}
              validationResult={{
                isValid: !!validationState.emailSubject?.isValid,
              }}
            >
              <BasicField
                label="Email subject"
                name="emailSubject"
                value={formState.emailSubject}
                onChange={onFieldChange}
                onTouch={onFieldTouch}
              />
            </ValidatedFieldWrapper>
            <ValidatedFieldWrapper
              isTouched={!!touchedState?.emailBody}
              validationResult={{
                isValid: !!validationState.emailBody?.isValid,
              }}
            >
              <TextareaFieldFormatter
                label="Email body"
                name={'emailBody'}
                value={formState.emailBody}
                onChange={onFieldChange}
                onTouch={onFieldTouch}
              />
            </ValidatedFieldWrapper>
            {purchaseOrder?.pdfUrl && (
              <a
                href={purchaseOrder.pdfUrl}
                className="download-pdf-link"
                target="_blank"
                rel="noopener noreferrer"
                download={`purchase-order-${purchaseOrder.reference}.pdf`}
              >
                <FontAwesomeIcon icon={faPaperclip} />
                <span>purchase-order-{purchaseOrder.reference}.pdf</span>
              </a>
            )}

            <CheckboxField
              name="copyMeIn"
              value={formState.copyMeIn}
              label={'Copy me in to this email'}
              onChange={onFieldChange}
            />
          </div>
          <div className="actions-container">
            {submissionError && (
              <FeedbackBlock
                isDismissable={true}
                onDismissed={() => setSubmissionError('')}
                feedbackLevel={FeedbackLevel.ERROR}
              >
                <span>{submissionError}</span>
              </FeedbackBlock>
            )}
            {showValidationFeedback && (
              <ValidationFeedbackBlock
                validationResults={Object.values(validationState)}
                isDismissable={true}
                onDismissed={() => setShowValidationFeedback(false)}
              />
            )}
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isProcessing={isProcessing}
              onClick={() => {
                if (isProcessing) return;
                if (isValid) {
                  if (!supplier?.emailAddress) {
                    setIsProcessing(true);
                    dispatch(
                      requestPersistSupplier(
                        {
                          id: supplier?.id,
                          emailAddress: formState.supplierEmailAddress,
                        },
                        () => {
                          onSubmit(formState as SendPurchaseOrderRequestData);
                        }
                      )
                    );
                  } else {
                    onSubmit(formState as SendPurchaseOrderRequestData);
                  }
                } else {
                  onFieldTouch('supplierEmailAddress');
                  setShowValidationFeedback(true);
                }
              }}
            >
              Send
            </Button>
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              isProcessing={isMarkAsSentProcessing}
              onClick={() => !isMarkAsSentProcessing && onMarkAsSentSubmit()}
            >
              Mark as sent offline
            </Button>
          </div>
        </>
      );
    },
    [
      supplier,
      showValidationFeedback,
      onSubmit,
      onMarkAsSentSubmit,
      isProcessing,
      submissionError,
      isMarkAsSentProcessing,
      overrideEmail,
    ]
  );

  return (
    <div className="send-purchase-order-control">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
      />
    </div>
  );
};

export default SendPurchaseOrderControl;
