import { action } from 'typesafe-actions';

import { FileDownloadTypes } from '@payaca/types/fileDownloadTypes';
import { ActionType } from './fileDownloadTypes';

export const requestDownloadFile = (
  entityId: number,
  type: FileDownloadTypes,
  callback?: (value: string) => void
) => {
  return action(ActionType.REQUEST_FILE_DOWNLOAD, {
    entityId,
    type,
    callback,
  });
};

export const fileDownloadSuccess = (
  entityId: number,
  type: FileDownloadTypes,
  value: string
) => {
  return action(ActionType.FILE_DOWNLOAD_SUCCESS, {
    entityId,
    type,
    value,
  });
};

export const fileDownloadFailure = (
  entityId: number,
  type: FileDownloadTypes,
  error: Error
) => {
  return action(
    ActionType.FILE_DOWNLOAD_FAILURE,
    { entityId, type },
    null,
    error
  );
};

export const clearFileDownloads = () => {
  return action(ActionType.CLEAR_FILE_DOWNLOADS);
};
