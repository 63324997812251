import { createAsyncAction } from '../utils';

import {
  ActionType,
  CreateTaxRateAction,
  DeleteTaxRateAction,
  GetTaxRatesAction,
  UpdateTaxRateAction,
  UpdateTaxRatesAccountingIntegrationIdentifiers,
} from './types';

export const getTaxRates = createAsyncAction<GetTaxRatesAction>({
  request: ActionType.GET_TAX_RATES_REQUEST,
  success: ActionType.GET_TAX_RATES_SUCCESS,
  failure: ActionType.GET_TAX_RATES_FAILURE,
})();

export const createTaxRate = createAsyncAction<CreateTaxRateAction>({
  request: ActionType.CREATE_TAX_RATE_REQUEST,
  success: ActionType.CREATE_TAX_RATE_SUCCESS,
  failure: ActionType.CREATE_TAX_RATE_FAILURE,
})(
  (
    data: CreateTaxRateAction['request']['payload'],
    callback: CreateTaxRateAction['request']['meta']
  ) => ({
    payload: data,
    meta: callback,
  })
);

export const updateTaxRate = createAsyncAction<UpdateTaxRateAction>({
  request: ActionType.UPDATE_TAX_RATE_REQUEST,
  success: ActionType.UPDATE_TAX_RATE_SUCCESS,
  failure: ActionType.UPDATE_TAX_RATE_FAILURE,
})(
  (
    data: UpdateTaxRateAction['request']['payload'],
    callback: UpdateTaxRateAction['request']['meta']
  ) => ({
    payload: data,
    meta: callback,
  })
);

export const deleteTaxRate = createAsyncAction<DeleteTaxRateAction>({
  request: ActionType.DELETE_TAX_RATE_REQUEST,
  success: ActionType.DELETE_TAX_RATE_SUCCESS,
  failure: ActionType.DELETE_TAX_RATE_FAILURE,
})(
  (
    taxRateId: DeleteTaxRateAction['request']['payload'],
    callback: DeleteTaxRateAction['request']['meta']
  ) => ({
    payload: taxRateId,
    meta: callback,
  })
);

export const updateTaxRatesAccountingIntegrationIdentifiers =
  createAsyncAction<UpdateTaxRatesAccountingIntegrationIdentifiers>({
    request:
      ActionType.UPDATE_TAX_RATES_ACCOUNTING_INTEGRATION_IDENTIFIERS_REQUEST,
  })((
    payload: UpdateTaxRatesAccountingIntegrationIdentifiers['request']['payload']
  ) => {
    return {
      payload,
    };
  });
