import { call, delay, put, race, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions';

import {
  clearListedSupplierMaterialsPage,
  getListedSupplierMaterialsPageFailure,
  getListedSupplierMaterialsPageSuccess,
} from './listedSupplierMaterialsActions';
import {
  ActionType,
  GetListedSupplierMaterialsRequestData,
  SagaConfig,
} from './listedSupplierMaterialsTypes';

import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const listedSupplierMaterialsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetListedSupplierMaterialsPage(
    action: PayloadAction<
      ActionType.REQUEST_GET_LISTED_SUPPLIERMATERIALS_PAGE,
      {
        getListedSupplierMaterialsRequestData: GetListedSupplierMaterialsRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getListedSupplierMaterialsPage,
          action.payload.getListedSupplierMaterialsRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getListedSupplierMaterialsPageFailure(
            new Error('Get listed suppliermaterials page timed out.')
          )
        );
      } else {
        yield put(getListedSupplierMaterialsPageSuccess(response));
      }
    } catch (error: any) {
      yield put(getListedSupplierMaterialsPageFailure(error));
    }
  }

  function* handleAppLogout() {
    yield put(clearListedSupplierMaterialsPage());
  }

  const getListedSupplierMaterialsPage = async (
    getListedSupplierMaterialsRequestData: GetListedSupplierMaterialsRequestData
  ) => {
    const response = await req.get(
      `/supplier_materials/list?pageNumber=${
        getListedSupplierMaterialsRequestData.pageNumber
      }&pageSize=${
        getListedSupplierMaterialsRequestData.pageSize
      }&supplierSearchTerm=${
        getListedSupplierMaterialsRequestData.supplierSearchTerm || ''
      }&materialSearchTerm=${
        getListedSupplierMaterialsRequestData.materialSearchTerm || ''
      }&sortDirection=${
        getListedSupplierMaterialsRequestData.sortDirection || ''
      }&sortBy=${
        getListedSupplierMaterialsRequestData.sortBy || ''
      }&supplierId=${
        getListedSupplierMaterialsRequestData.supplierId || ''
      }&materialId=${getListedSupplierMaterialsRequestData.materialId || ''}`
    );
    return await response.json();
  };

  return function* () {
    yield takeEvery(
      ActionType.REQUEST_GET_LISTED_SUPPLIERMATERIALS_PAGE,
      handleGetListedSupplierMaterialsPage
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default listedSupplierMaterialsSagaCreator;
