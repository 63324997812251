import { useChangeProposal } from '@payaca/store/hooks/appState';
import * as proposalsActions from '@payaca/store/proposals/proposalsActions';
import {
  requestGetChangeProposal,
  requestGetChangeProposalsForDeal,
} from '@payaca/store/proposals/proposalsActions';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import ChangeProposalSidePanel from '@/ui/components/changeProposalSidePanel/ChangeProposalSidePanel';
import EditChangeProposalControl from '@/ui/components/editChangeProposalControl/EditChangeProposalControl';
import EditChangeProposalPreviewButton from '@/ui/components/editChangeProposalPreviewButton/EditChangeProposalPreviewButton';
import EditChangeProposalSaveFeedback from '@/ui/components/editChangeProposalSaveFeedback/EditChangeProposalSaveFeedback';
import PreviewAndSendChangeProposalControl from '@/ui/components/previewAndSendChangeProposalControl/PreviewAndSendChangeProposalControl';
import EditProposalLineItemSidebar from '@/ui/pages/newEditQuotePage/components/EditProposalLineItemSidebar';
import Modal from '@payaca/components/modal/Modal';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import {
  requestGetJobContentsForDeal,
  requestGetJobLineItem,
  requestGetJobLineItemsForDeal,
} from '@payaca/store/jobContent/jobContentActions';
import { requestGetJobsForDeal } from '@payaca/store/jobs/jobsActions';
import { requestGetUploadsForEntity } from '@payaca/store/uploads/uploadsActions';
import { useRouteMatch } from 'react-router-dom';
import './EditChangeProposalPage.sass';

export enum ViewType {
  EDIT = 'edit',
  SEND = 'send',
}

type Props = {
  changeProposalId: number;
  dealId?: number;
  viewType?: ViewType;
};

const EditChangeProposalPage: FC<Props> = ({
  changeProposalId,
  dealId,
  viewType,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const isEditProposalLineItemDrawOpen = useRouteMatch<{
    proposalLineItemId: string;
  }>(`${match.url}/items/:proposalLineItemId`);

  const [view, setView] = useState<ViewType>(ViewType.EDIT);
  const [validationResult, setValidationResult] = useState<{
    isValid: boolean;
    errors: string[];
  }>();
  const changeProposal = useChangeProposal(changeProposalId);

  useEffect(() => {
    dispatch(requestGetChangeProposal(changeProposalId));
    dispatch(requestGetUploadsForEntity(changeProposalId, 'changeProposal'));
  }, []);

  useEffect(() => {
    if (changeProposal?.dealId) {
      dispatch(requestGetDeal(changeProposal.dealId));
      dispatch(requestGetJobsForDeal(changeProposal.dealId));
      dispatch(requestGetJobContentsForDeal(changeProposal.dealId));
      dispatch(requestGetChangeProposalsForDeal(changeProposal.dealId));
      dispatch(requestGetJobLineItemsForDeal(changeProposal?.dealId));
    }
  }, [changeProposal?.dealId]);

  useEffect(() => {
    if (
      viewType === ViewType.SEND &&
      validationResult &&
      validationResult.isValid
    ) {
      setView(ViewType.SEND);
    }
  }, [viewType, validationResult]);

  useEffect(() => {
    dispatch(
      proposalsActions.getChangeProposalValidationResult.request(
        changeProposalId,
        (validationResult) => {
          setValidationResult(validationResult);
        }
      )
    );
  }, [changeProposalId]);

  useEffect(() => {
    if (changeProposal?.sentAt) {
      history.push(`/deals/${dealId}/changeproposals/${changeProposalId}`);
    }
  }, [changeProposal?.sentAt]);

  const handleProposalLineItemPersist = () => {
    dispatch(requestGetChangeProposal(changeProposalId));

    if (isEditProposalLineItemDrawOpen?.params.proposalLineItemId !== 'new') {
      dispatch(
        requestGetJobLineItem(
          +isEditProposalLineItemDrawOpen!.params.proposalLineItemId
        )
      );
      dispatch(
        jobContentActions.requestGetJobLineItemAttachmentsForJobLineItem(
          +isEditProposalLineItemDrawOpen!.params.proposalLineItemId
        )
      );
    }

    history.push(match.url);
  };

  return (
    <>
      <AuthenticatedPageWrapper
        className="edit-change-proposal-page"
        previousPageNavigationConfig={{
          route: `/deals/${dealId}/proposals`,
          navigationPromptName: 'Back',
        }}
        sidebarContent={
          <div className="edit-change-proposal-sidebar">
            <EditChangeProposalPreviewButton
              changeProposalId={changeProposalId}
              proceedToPreview={() => setView(ViewType.SEND)}
            />
            <EditChangeProposalSaveFeedback
              changeProposalId={changeProposalId}
            />
            <ChangeProposalSidePanel changeProposalId={changeProposalId} />
          </div>
        }
      >
        {changeProposal && (
          <>
            <EditChangeProposalControl changeProposalId={changeProposalId} />
            <Modal
              className="preview-and-send-change-proposal-modal"
              isOpen={view === ViewType.SEND}
              size="lg"
              onClose={() => setView(ViewType.EDIT)}
            >
              <PreviewAndSendChangeProposalControl
                changeProposalId={changeProposalId}
              />
            </Modal>
          </>
        )}
      </AuthenticatedPageWrapper>

      <EditProposalLineItemSidebar
        isOpen={!!isEditProposalLineItemDrawOpen}
        proposalLineItemId={
          isEditProposalLineItemDrawOpen?.params.proposalLineItemId &&
          isEditProposalLineItemDrawOpen.params.proposalLineItemId !== 'new'
            ? isEditProposalLineItemDrawOpen.params.proposalLineItemId
            : undefined
        }
        onSaveSuccess={handleProposalLineItemPersist}
        onRemoveSuccess={handleProposalLineItemPersist}
        onClose={() => history.push(match.url)}
      />
    </>
  );
};

export default EditChangeProposalPage;
