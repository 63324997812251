import FileSaver from 'file-saver';

export const downloadPdfData = (pdfData: string, pdfFileName: string): void => {
  // convert pdfData to blob
  const binary = atob(pdfData.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }

  // create the blob object with content-type "application/pdf"
  const blob = new Blob([view], { type: 'application/pdf' });

  // Ensure the file name always has `.pdf` suffix
  const pdfFileNameWithSuffix = pdfFileName.replace(/\.pdf$/, '') + '.pdf';

  // use filesaver to download the blob
  FileSaver.saveAs(blob, pdfFileNameWithSuffix);
};

export const downloadFile = (url: string, fileName: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
