import { createValidatedField } from '@/ui/pages/formPage/formUtils/createValidatedField';
import { FormElement } from '@payaca/types/formElementTypes';
import { FC } from 'react';

interface Props {
  elem: FormElement;
  formState: any;
  changeHandler: any;
  onFieldTouch: any;
  touchedState: any;
  validationState: any;
  uiHandlers: any;
  name?: string;
}

export const MultiInputFormElement: FC<Props> = ({
  elem,
  formState,
  changeHandler,
  onFieldTouch,
  touchedState,
  validationState,
  uiHandlers,
  name = '',
}) => {
  const [root, index, prop] = (name || elem.id).split('.');

  return (
    <>
      <div className={'multi-input-title'}>{elem.title}</div>
      <div className={'multi-input-container'}>
        {elem.children &&
          elem.children.map((childElem: FormElement, ind: number) => (
            <div key={ind}>
              {createValidatedField(
                childElem,
                formState,
                prop
                  ? formState?.[root]?.[index]?.[prop]?.[childElem.id] || ''
                  : formState?.[root]?.[childElem.id],
                changeHandler,
                onFieldTouch,
                touchedState,
                validationState,
                uiHandlers,
                ind,
                name ? `${name}.${childElem.id}` : `${elem.id}.${childElem.id}`
              )}
            </div>
          ))}
      </div>
    </>
  );
};
