import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { actions as appActions } from '@/api/app';
import * as lineItemV2Actions from '@payaca/store/lineItemsV2/lineItemsActions';

import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import BulkUpdateModal from '@/ui/components/bulkUploadDownloadModals/BulkUpdateModal';
import BulkUploadModal from '@/ui/components/bulkUploadDownloadModals/BulkUploadModal';

import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { LineItemsPermissions } from '@payaca/permissions/lineItems/line-items.permissions';

import lineItemKeys from '@/api/queries/lineItems/keyFactory';
import { useSelector } from '@/api/state';
import {
  getItemsFiltersLocalStorageKey,
  getTaxRateIdLocalStorageKey,
} from '@/helpers/localStorageKeyHelper';
import { useTranslation } from '@/i18n';
import AddEditItemSidebar from '@/ui/pages/listedItemsPage/components/AddEditItemSidebar';
import { useHashFragment } from '@/utils/customHooks';
import Button from '@payaca/components/plButton/Button';
import LinkButton from '@payaca/components/plButton/LinkButton';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import { TTableRowAction } from '@payaca/components/plManageableItemsList/components/Table';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { useDefaultTaxRate } from '@payaca/store/hooks/appState';
import { SortDirection } from '@payaca/types/listViewTypes';
import UntitledIcon from '@payaca/untitled-icons';
import { useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import useGetLineItems from '../../../api/queries/lineItems/useGetLineItems';
import {
  GetLineItemsInput,
  GetLineItemsSortBy,
  SortDirection as SortDirectionGql,
} from '../../../gql/graphql';
import { getUserRoles } from '../../../utils/stateAccessors';
import ListedLineItemsTable, {
  ListedLineItem,
} from '../../components/listedLineItemsTable/ListedLineItemsTable';
import ConfirmDeleteLineItemsModal from './ConfirmDeleteLineItemsModal';

const taxRateIdLocalStorageKey = getTaxRateIdLocalStorageKey();

type StoredItemsFilter = Pick<GetLineItemsInput, 'sortBy' | 'sortDirection'>;
const pageSize = 100;
const ListedItemsPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultTaxRate = useDefaultTaxRate();
  const userRoles = useSelector(getUserRoles);

  const { path, url } = useRouteMatch();
  const isAddEditLineItemModalOpen = useRouteMatch<{
    lineItemId: string;
  }>(`${path}/:lineItemId`);

  const [showBulkUploadModal, toggleBulkUploadModal] =
    useHashFragment('#bulk-upload');
  const [showBulkUpdateModal, toggleBulkUpdateModal] =
    useHashFragment('#bulk-update');
  const [selectedLineItems, setSelectedLineItems] = useState<ListedLineItem[]>(
    []
  );

  const initialInput = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const storedInput: StoredItemsFilter = JSON.parse(
      localStorage.getItem(getItemsFiltersLocalStorageKey()) || '{}'
    );

    return {
      sortBy: searchParams.get('sort') || storedInput.sortBy || 'NAME',
      sortDirection:
        searchParams.get('dir') || storedInput.sortDirection || 'ASC',
      searchTerm: searchParams.get('term') || undefined,
    } as GetLineItemsInput;
  }, [window.location]);

  const [getLineItemsInput, setGetLineItemsInput] =
    useState<GetLineItemsInput>(initialInput);

  const initialPagination = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageNumber = parseInt(searchParams.get('page') || '1');

    return {
      limit: pageSize,
      offset: (pageNumber - 1) * pageSize,
    };
  }, [window.location]);

  const [getLineItemsPagination, setGetLineItemsPagination] = useState<{
    limit: number;
    offset: number;
  }>(initialPagination);

  const {
    data: lineItems,
    isLoading,
    refetch: refetchLineItems,
  } = useGetLineItems(getLineItemsPagination, getLineItemsInput);
  const queryClient = useQueryClient();

  const [showConfirmDeleteItemsModal, setShowConfirmDeleteItemsModal] =
    useState(false);

  const [searchTerm, setSearchTerm] = useState(initialInput.searchTerm || '');

  const itemActionCallback = (error?: Error) => {
    if (error) {
      dispatch(appActions.showBanner({ type: 'error' }));
    }
    setShowConfirmDeleteItemsModal(false);
    setSelectedLineItems([]);
    void refetchLineItems();
  };

  const navigateToItemPage = useCallback(
    (itemId: string) => {
      history.push(`/items/${itemId}`);
    },
    [history]
  );

  const onClickRow = useCallback(
    (itemId: string) => {
      navigateToItemPage(itemId);
    },
    [navigateToItemPage]
  );

  const quickDuplicateItem = (itemId: number) => {
    dispatch(
      lineItemV2Actions.duplicateLineItem.request({
        itemId,
        callback: (newItemId: number) => {
          void refetchLineItems();
          history.push(`items/${newItemId}`);
        },
      })
    );
  };

  const onPageChange = (pageNumber: number) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', String(pageNumber));

    setGetLineItemsPagination((s) => ({
      ...s,
      offset: (pageNumber - 1) * s.limit,
    }));

    history.push(`/items?${searchParams.toString()}`);
  };

  const itemActions = useMemo(() => {
    const actions: TTableRowAction<ListedLineItem>[] = [];
    if (
      userHasRequiredPermission(userRoles, [LineItemsPermissions.ADD_LINE_ITEM])
    ) {
      actions.push({
        label: 'Duplicate',
        onClick: (lineItem) => quickDuplicateItem(+lineItem.id),
      });
    }
    return actions;
  }, [userRoles, quickDuplicateItem]);

  const onSort = useCallback(
    (field?: string, sortDirection?: SortDirection) => {
      const searchParams = new URLSearchParams(window.location.search);

      const filters: StoredItemsFilter = {
        sortBy: (field as GetLineItemsSortBy) || searchParams.get('sort'),
        sortDirection: sortDirection
          ? sortDirection === SortDirection.ASCENDING
            ? 'ASC'
            : 'DESC'
          : (searchParams.get('dir') as SortDirectionGql) || undefined,
      };
      if (filters.sortDirection) {
        searchParams.set('dir', filters.sortDirection);
      }
      if (filters.sortBy) {
        searchParams.set('sort', filters.sortBy);
      }
      if (searchTerm !== undefined) {
        searchParams.set('term', searchTerm);
      }

      setGetLineItemsInput((s) => ({
        ...s,
        ...filters,
      }));
      setGetLineItemsPagination((s) => ({
        ...s,
        offset: (1 - 1) * s.limit,
      }));

      // update local storage
      localStorage.setItem(
        getItemsFiltersLocalStorageKey(),
        JSON.stringify(filters)
      );
      searchParams.set('page', '1');
      history.push(`/items?${searchParams.toString()}`);
    },
    [getLineItemsInput]
  );

  const translate = useTranslation();

  return (
    <>
      <Helmet title={translate('pages.items.title')} />
      <AuthenticatedPageWrapper className="bg-gray-50">
        <div className="mb-16 p-4">
          <ManageableItemsList>
            <ManageableItemsList.HeaderBar
              heading="Items you sell"
              subHeading="What your Customer sees on their Proposals and Invoices."
              buttons={
                <>
                  {!!selectedLineItems.length && (
                    <>
                      <Button
                        onClick={() => setShowConfirmDeleteItemsModal(true)}
                        size={EBtnSize.Small}
                        variant={EBtnVariant.Outline}
                        colour={EBtnColour.Red}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                  <PermissionGuard
                    renderIfHasPermissions={[
                      LineItemsPermissions.GET_LINE_ITEM_GROUPS,
                    ]}
                  >
                    <Button
                      onClick={() => history.push('/items/groups')}
                      size={EBtnSize.Small}
                      variant={EBtnVariant.Outline}
                    >
                      <UntitledIcon name="grid-01.3" className="h-4 w-4" />
                      Item Groups
                    </Button>
                  </PermissionGuard>
                  <PermissionGuard
                    renderIfHasPermissions={[
                      LineItemsPermissions.ADD_LINE_ITEM,
                    ]}
                  >
                    <LinkButton size={EBtnSize.Small} to={`${url}/new`}>
                      Create Item
                    </LinkButton>
                  </PermissionGuard>
                </>
              }
            />
            <ManageableItemsList.ActionBar>
              <ManageableItemsList.ActionBar.SearchInput
                value={searchTerm}
                onChange={setSearchTerm}
                onChangeTimeout={(value) => {
                  const searchParams = new URLSearchParams(
                    window.location.search
                  );
                  searchParams.set('term', value);
                  searchParams.set('page', '1');
                  setGetLineItemsInput((s) => ({
                    ...s,
                    searchTerm: value,
                  }));
                  setGetLineItemsPagination((s) => ({
                    ...s,
                    offset: 0,
                  }));
                  history.push(`/items?${searchParams.toString()}`);
                }}
                changeTimeoutMs={500}
              />
            </ManageableItemsList.ActionBar>
            <ListedLineItemsTable
              isLoading={isLoading}
              lineItems={lineItems?.lineItems.items || []}
              onSort={onSort}
              onClickRow={(lineItem: ListedLineItem) => onClickRow(lineItem.id)}
              itemActions={itemActions}
              selectedLineItems={selectedLineItems}
              setSelectedLineItems={
                userHasRequiredPermission(userRoles, [
                  LineItemsPermissions.DELETE_LINE_ITEM,
                ])
                  ? setSelectedLineItems
                  : undefined
              }
            />
            <ManageableItemsList.PaginationBar
              pageSize={getLineItemsPagination.limit}
              currentPage={
                getLineItemsPagination.offset / getLineItemsPagination.limit + 1
              }
              totalItems={lineItems?.lineItems.totalCount || 0}
              onPageChange={onPageChange}
            />
          </ManageableItemsList>
        </div>

        {/* Bulk upload modal */}
        <BulkUploadModal
          isOpen={showBulkUploadModal}
          onClose={toggleBulkUploadModal}
          title="Upload items"
          dataType="lineItems"
        ></BulkUploadModal>
        <BulkUpdateModal
          isOpen={showBulkUpdateModal}
          onClose={toggleBulkUpdateModal}
          title="Update items"
          dataType="lineItems"
        ></BulkUpdateModal>

        {/* Confirm delete items modal */}
        <ConfirmDeleteLineItemsModal
          isOpen={showConfirmDeleteItemsModal}
          onClose={() => setShowConfirmDeleteItemsModal(false)}
          selectedLineItems={selectedLineItems}
          onDeleteCallback={itemActionCallback}
        />
      </AuthenticatedPageWrapper>

      <AddEditItemSidebar
        isOpen={!!isAddEditLineItemModalOpen}
        lineItemId={
          isAddEditLineItemModalOpen?.params.lineItemId &&
          isAddEditLineItemModalOpen.params.lineItemId !== 'new'
            ? isAddEditLineItemModalOpen.params.lineItemId
            : undefined
        }
        onClose={() => {
          void queryClient.invalidateQueries({
            queryKey: lineItemKeys.lineItems(),
          });

          history.push('/items');
        }}
      />
    </>
  );
};

export default ListedItemsPage;
