import { FC, useMemo } from 'react';

import Badge from '@payaca/components/plBadge/Badge';
import { getInvoicePLBadgeState } from '@payaca/helpers/invoiceHelper';
import { ReadableInvoiceStatus } from '@payaca/types/invoiceTypes';
import { BadgeColourVariant } from '@payaca/types/plBadge';

type Props = {
  status: ReadableInvoiceStatus;
};

const InvoiceStatusBadge: FC<Props> = ({ status }: Props): JSX.Element => {
  const badgeColour = useMemo<BadgeColourVariant>(
    () => getInvoicePLBadgeState(status),
    [status]
  );

  return (
    <Badge variant="soft" colour={badgeColour}>
      {status}
    </Badge>
  );
};

export default InvoiceStatusBadge;
