import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import { DealHideItemPricesControl } from '../dealHideItemPricesControl/DealHideItemPricesControl';
import { HideVatBreakdownControl } from '../hideVatBreakdownControl/HideVatBreakdownControl';

import './DealPriceDetailControl.sass';

type Props = { canHideVat: boolean; dealId: number };
const DealPriceDetailControl = ({ canHideVat, dealId }: Props) => {
  return (
    <div className="deal-price-detail-control">
      <FieldLabel label="Price detail" />
      <div className="price-checkbox-options-wrapper">
        <DealHideItemPricesControl dealId={dealId} />
        {canHideVat && <HideVatBreakdownControl dealId={dealId} />}
      </div>
    </div>
  );
};

export default DealPriceDetailControl;
