import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import React, { FunctionComponent, useMemo } from 'react';
import { ErrorMessage } from '../feedbackMessage/FeedbackMessage';
import './ValidatedFieldWrapper.sass';

type Props = {
  children: JSX.Element | (JSX.Element | undefined)[];
  validationResult?: FieldValidationResult;
  isTouched?: boolean;
};

const ValidatedFieldWrapper: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  children,
  validationResult,
  isTouched = true,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const isInvalid = useMemo(() => {
    if (!validationResult) return false;
    if (!isTouched) return false;
    return !validationResult.isValid;
  }, [validationResult, isTouched]);

  const validationErrors = useMemo(() => {
    return validationResult?.errors || [];
  }, [validationResult]);

  return (
    <div className={`validated-field-wrapper${isInvalid ? ' invalid' : ''}`}>
      {children}
      {validationErrors && !!validationErrors.length && (
        <div className="validation-errors">
          {validationErrors.map((error, index) => {
            return <ErrorMessage message={error} key={index} />;
          })}
        </div>
      )}
    </div>
  );
};

export default ValidatedFieldWrapper;
