import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  getUploadsWithRoleForEntity,
  requestDeleteUpload,
  requestPersistUploadAndLinkToEntity,
} from '@payaca/store/uploads/uploadsActions';
import { Upload, UploadAttachableEntityType } from '@payaca/types/uploadTypes';
import UploadCollectionControl from '../uploadCollectionControl/UploadCollectionControl';

export const EntityUploadCollectionControl: FC<{
  onPersistUploadSuccess?: () => void;
  entityId: number;
  entityType: UploadAttachableEntityType;
  role: string;
  acceptFileTypes?: string[];
  continueDeleteIfAnyLinkedEntities?: boolean;
}> = ({
  onPersistUploadSuccess,
  entityId,
  entityType,
  role,
  acceptFileTypes,
  continueDeleteIfAnyLinkedEntities = false,
}) => {
  const [uploads, setUploads] = useState<Upload[]>([]);

  const dispatch = useDispatch();

  const getUploads = useCallback(() => {
    dispatch(
      getUploadsWithRoleForEntity.request({
        entityId,
        entityType,
        uploadRole: role,
        callback: setUploads,
      })
    );
  }, [entityId, entityType, role]);

  useEffect(() => {
    getUploads();
  }, []);

  const handlePersistUploadSuccess = useCallback(() => {
    onPersistUploadSuccess?.();
    getUploads();
  }, [getUploads, onPersistUploadSuccess]);

  const persistUpload = useCallback(
    (file: File) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(
          requestPersistUploadAndLinkToEntity(
            {
              file: file,
              entityType: 'account',
              entityId: entityId,
              entityRole: role,
            },
            () => {
              resolve();
            }
          )
        );
      });
    },
    [entityId, entityType, role]
  );

  const removeUpload = (uploadId: number) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        requestDeleteUpload(
          uploadId,
          (error) => {
            if (!error) {
              resolve();
            } else {
              reject();
            }
          },
          continueDeleteIfAnyLinkedEntities
        )
      );
    });
  };

  return (
    <UploadCollectionControl
      uploadIds={uploads.map((upload) => upload.id)}
      customPersistUpload={persistUpload}
      customRemoveUpload={removeUpload}
      onUploadCollectionChange={handlePersistUploadSuccess}
      allowMultipleUploads={true}
      // 10mb
      fileSizeLimitBytes={10 * 1024 * 1024}
      acceptFileTypes={acceptFileTypes}
      showFileNames={true}
    />
  );
};
