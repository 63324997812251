import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import Button from '@payaca/components/plButton/Button';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import { DateInput } from '@payaca/components/plDateInput/DateInput';
import Field from '@payaca/components/plField/Field';
import Modal from '@payaca/components/plModal/Modal';
import Select from '@payaca/components/plSelect/Select';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import ValidatedForm, {
  TFieldValidators,
} from '@payaca/components/validatedForm/ValidatedForm';
import {
  getDateRangeFieldValidator,
  getIsRequiredFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { payInvoices } from '@payaca/store/jobPayments/jobPaymentsActions';
import { PayInvoicesRequestData } from '@payaca/store/jobPayments/jobPaymentsTypes';
import { Customer } from '@payaca/types/customerTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Invoice } from '@payaca/types/invoiceTypes';
import { PaymentMethodType } from '@payaca/types/jobPaymentTypes';
import { startOfDay } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

export const BulkMarkInvoicesAsPaidControl: FC<{
  invoices: (Pick<Invoice, 'id' | 'dueValue'> & { reference: string })[];
  customer: Pick<Customer, 'id' | 'name'>;
  onSuccess?: () => void;
}> = ({ invoices, customer, onSuccess }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const initialFormState = useMemo(() => {
    return {
      customerId: customer.id,
      invoiceIds: invoices.map((invoice) => invoice.id),
      paymentMethodType: PaymentMethodType.BACS,
      paymentDate: startOfDay(new Date()),
      sendReceipt: true,
    };
  }, [customer, invoices]);

  const fieldValidators: TFieldValidators<Partial<PayInvoicesRequestData>> =
    useMemo(() => {
      return {
        paymentMethodType: [
          getIsRequiredFieldValidator({ readableName: 'Payment method' }),
        ],
        paymentDate: [
          getIsRequiredFieldValidator({ readableName: 'Payment date' }),
          getDateRangeFieldValidator(
            undefined,
            new Date(),
            undefined,
            undefined,
            undefined,
            'You may not record a payment dated in the future'
          ),
        ],
      };
    }, []);

  const onSubmit = useCallback((data: PayInvoicesRequestData) => {
    setIsSubmitting(true);
    dispatch(
      payInvoices.request({
        requestData: data,
        callback: () => {
          console.log('callback');
          onSuccess?.();
          setIsSubmitting(false);
        },
        onErrorCallback: () => {
          console.log('error callbacl');
          setErrorMessage('An error occurred while marking invoices as paid');
          setIsSubmitting(false);
        },
      })
    );
  }, []);

  return (
    <ValidatedForm<Partial<PayInvoicesRequestData>>
      initialFormState={initialFormState}
      fieldValidators={fieldValidators}
      renderFormContents={(
        isValid: boolean,
        formState,
        validationState,
        touchedState,
        onFieldChange,
        onFieldTouch
      ) => {
        return (
          <>
            <Modal.Body className="flex flex-col gap-4">
              <FormContents
                isValid={isValid}
                formState={formState}
                validationState={validationState}
                touchedState={touchedState}
                onFieldChange={onFieldChange}
                onFieldTouch={onFieldTouch}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                customer={customer}
                invoices={invoices}
              />
            </Modal.Body>

            <Modal.Footer className="flex flex-col gap-y-4">
              {!!errorMessage?.length && (
                <Alert colour={EAlertColour.SOFT_RED}>{errorMessage}</Alert>
              )}
              <p className="supporting-body m-0 p-0">
                By continuing, I confirm that {customer.name} has fully paid the
                following Invoices:{' '}
                {invoices.map((i) => i.reference).join(', ')}
              </p>
              <Modal.Footer.Actions>
                <Button
                  disabled={!isValid}
                  isProcessing={isSubmitting}
                  onClick={() => {
                    if (isSubmitting) return;
                    onSubmit(formState as PayInvoicesRequestData);
                  }}
                >
                  Continue
                </Button>
              </Modal.Footer.Actions>
            </Modal.Footer>
          </>
        );
      }}
    />
  );
};

const paymentMethodTypeOptions = [
  {
    label: 'BACS transfer',
    value: PaymentMethodType.BACS,
  },
  {
    label: 'Cash',
    value: PaymentMethodType.CASH,
  },
  {
    label: 'Cheque',
    value: PaymentMethodType.CHEQUE,
  },
  {
    label: 'Card',
    value: PaymentMethodType.CARD,
  },
  {
    label: 'Other',
    value: PaymentMethodType.OTHER,
  },
];

const FormContents: FC<{
  isValid: boolean;
  formState: Partial<PayInvoicesRequestData>;
  validationState: {
    [key: string]: FieldValidationResult;
  };
  touchedState: {
    [key: string]: boolean;
  };
  onFieldChange: (value: { [key: string]: any }) => void;
  onFieldTouch: (fieldName: string | string[]) => void;
  onSubmit: (data: PayInvoicesRequestData) => void;
  isSubmitting: boolean;
  invoices: (Pick<Invoice, 'id' | 'dueValue'> & { reference: string })[];
  customer: Pick<Customer, 'id' | 'name'>;
}> = ({
  isValid,
  formState,
  validationState,
  touchedState,
  onFieldChange,
  onFieldTouch,
  onSubmit,
  isSubmitting,
  customer,
  invoices,
}) => {
  return (
    <div className="prose flex flex-col gap-4">
      <div className="flex flex-row gap-4">
        <div className="grow basis-0">
          <Field
            name="paymentMethodType"
            validationState={
              validationState?.['paymentMethodType']?.isValid === false
                ? {
                    isValid: false,
                    validationMessages:
                      validationState?.['paymentMethodType']?.errors,
                  }
                : undefined
            }
          >
            <Field.Label>Payment method</Field.Label>
            <Select
              options={paymentMethodTypeOptions}
              value={formState.paymentMethodType}
              onChange={(value) => {
                onFieldTouch?.('paymentMethodType');
                onFieldChange({ paymentMethodType: value });
              }}
            />
          </Field>
        </div>
        <div className="grow basis-0">
          <Field
            name="paymentDate"
            validationState={
              validationState?.['paymentDate']?.isValid === false
                ? {
                    isValid: false,
                    validationMessages:
                      validationState?.['paymentDate']?.errors,
                  }
                : undefined
            }
          >
            <Field.Label>Payment date</Field.Label>
            <DateInput
              showTimeSelect={false}
              value={formState.paymentDate}
              onChange={(value) => {
                onFieldChange({
                  paymentDate: value,
                });
                onFieldTouch?.('paymentDate');
              }}
            />
          </Field>
        </div>
      </div>
      <Field
        name="paymentNote"
        validationState={
          validationState?.['paymentNote']?.isValid === false
            ? {
                isValid: false,
                validationMessages: validationState?.['paymentNote']?.errors,
              }
            : undefined
        }
      >
        <Field.Label>Internal note</Field.Label>
        <Textarea
          rows={2}
          value={formState.paymentNote || ''}
          onChange={(value) => onFieldChange({ paymentNote: value })}
          onBlur={() => onFieldTouch('paymentNote')}
        />
      </Field>
      <Field
        name="sendReceipt"
        validationState={
          validationState?.['sendReceipt']?.isValid === false
            ? {
                isValid: false,
                validationMessages: validationState?.['sendReceipt']?.errors,
              }
            : undefined
        }
      >
        <Checkbox
          label="I would like to send a receipt to the customer"
          checked={formState.sendReceipt}
          onChange={() =>
            onFieldChange({ sendReceipt: !formState.sendReceipt })
          }
        />
      </Field>
    </div>
  );
};
