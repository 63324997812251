import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { Action } from 'typesafe-actions';

import { ActionType, SagaConfig } from './dashboardTypes';

import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import {
  clearActionRequiredDraftJobs,
  clearActionRequiredPaymentOutstandingInvoices,
  clearActionRequiredPendingPayments,
  clearActionRequiredPendingProposals,
  getActionRequiredDraftJobsFailure,
  getActionRequiredDraftJobsSuccess,
  getActionRequiredPaymentOutstandingInvoicesFailure,
  getActionRequiredPaymentOutstandingInvoicesSuccess,
  getActionRequiredPendingPaymentsFailure,
  getActionRequiredPendingPaymentsSuccess,
  getActionRequiredPendingProposalsFailure,
  getActionRequiredPendingProposalsSuccess,
} from './dashboardActions';

const dashboardSagaCreator = ({ apiBaseurl, getAuthHeader }: SagaConfig) => {
  function* handleGetActionRequiredDraftJobs(action: Action) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getActionRequiredDraftJobs),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getActionRequiredDraftJobsFailure(
            new Error('Get action required draft jobs timed out.')
          )
        );
      } else {
        yield put(getActionRequiredDraftJobsSuccess(response));
      }
    } catch (error) {
      yield put(getActionRequiredDraftJobsFailure(error as Error));
    }
  }

  const getActionRequiredDraftJobs = async () => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/dashboard/action_required_draft_jobs?limit=20`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get action required draft jobs failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetActionRequiredPendingPayments(action: Action) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getActionRequiredPendingPayments),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getActionRequiredPendingPaymentsFailure(
            new Error('Get action required pending payments timed out.')
          )
        );
      } else {
        yield put(getActionRequiredPendingPaymentsSuccess(response));
      }
    } catch (error) {
      yield put(getActionRequiredPendingPaymentsFailure(error as Error));
    }
  }

  const getActionRequiredPendingPayments = async () => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/dashboard/action_required_pending_payments?limit=20`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get action required pending payments failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetActionRequiredPendingProposals(action: Action) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getActionRequiredPendingProposals),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getActionRequiredPendingProposalsFailure(
            new Error('Get action required pending proposals timed out.')
          )
        );
      } else {
        yield put(getActionRequiredPendingProposalsSuccess(response));
      }
    } catch (error) {
      yield put(getActionRequiredPendingProposalsFailure(error as Error));
    }
  }

  const getActionRequiredPendingProposals = async () => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/dashboard/action_required_pending_proposals?limit=20`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get action required pending proposals failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetActionRequiredPaymentOutstandingInvoices(action: Action) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getActionRequiredPaymentOutstandingInvoices),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getActionRequiredPaymentOutstandingInvoicesFailure(
            new Error('Get action required payment outstanding jobs timed out.')
          )
        );
      } else {
        yield put(getActionRequiredPaymentOutstandingInvoicesSuccess(response));
      }
    } catch (error) {
      yield put(
        getActionRequiredPaymentOutstandingInvoicesFailure(error as Error)
      );
    }
  }

  const getActionRequiredPaymentOutstandingInvoices = async () => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/dashboard/action_required_payment_outstanding_invoices?limit=20`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get action required payment outstanding jobs failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleLogout() {
    yield put(clearActionRequiredPendingPayments());
    yield put(clearActionRequiredDraftJobs());
    yield put(clearActionRequiredPendingProposals());
    yield put(clearActionRequiredPaymentOutstandingInvoices());
  }

  return function* () {
    yield takeLatest(
      ActionType.REQUEST_GET_ACTION_REQUIRED_PENDING_PAYMENTS,
      handleGetActionRequiredPendingPayments
    );
    yield takeLatest(
      ActionType.REQUEST_GET_ACTION_REQUIRED_DRAFT_JOBS,
      handleGetActionRequiredDraftJobs
    );
    yield takeLatest(
      ActionType.REQUEST_GET_ACTION_REQUIRED_PENDING_PROPOSALS,
      handleGetActionRequiredPendingProposals
    );
    yield takeLatest(
      ActionType.REQUEST_GET_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES,
      handleGetActionRequiredPaymentOutstandingInvoices
    );

    yield takeEvery('auth.logout', handleLogout);
  };
};

export default dashboardSagaCreator;
