import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ProgressIndicator.sass';

type Props = {
  className?: string;
};
const ProgressIndicator = ({ className }: Props) => {
  return (
    <FontAwesomeIcon
      className={`progress-indicator${className ? ` ${className}` : ''}`}
      icon={faCircle}
    />
  );
};

export default ProgressIndicator;
