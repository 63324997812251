import { BrandingColourContext } from '@payaca/types/accountBrandTypes';
import tinycolor from 'tinycolor2';

export const getBrandReadableTextColour = (
  brandColour?: string
): string | undefined => {
  if (!brandColour) return undefined;

  return tinycolor
    .mostReadable(brandColour, ['#263e59', 'white'], {
      includeFallbackColors: true,
    })
    .toHexString();
};

export const getBrandingColourContext = (
  brandColour?: string
): BrandingColourContext => {
  if (!brandColour) {
    return {};
  }

  const c = tinycolor(brandColour);

  const hsl = c.toHsl();

  const lightBrandColour = tinycolor(
    `hsl ${hsl.h} ${hsl.s} 0.8 `
  ).toHexString();

  const brandColourL95 = tinycolor(`hsl ${hsl.h} 0.20 0.95 `).toHexString();

  const brandColourL10 = tinycolor(`hsl ${hsl.h} 0.40 0.10 `).toHexString();

  let clampLightnessBrandColour = brandColour;

  if (hsl.l > 0.8) {
    clampLightnessBrandColour = lightBrandColour;
  }

  return {
    brandColour: brandColour,
    brandReadableTextColour: getBrandReadableTextColour(brandColour),
    clampLightnessBrandColour: clampLightnessBrandColour,
    lightBrandColour: lightBrandColour,
    lightBrandReadableTextColour: getBrandReadableTextColour(lightBrandColour),

    brandColourL95: brandColourL95,
    brandColourL10: brandColourL10,

    darkerBrandColour: c.darken().toString(),
  };
};
