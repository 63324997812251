import { isEqual } from 'lodash-es';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import TaskChecklistCompletionControl from '../taskChecklistCompletionControl/TaskChecklistCompletionControl';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Task } from '@payaca/types/taskTypes';

import { getDocument, getUserRoles } from '@/utils/stateAccessors';

import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';

import * as documentActions from '@payaca/store/documents/documentActions';
import {
  requestGetTask,
  requestUpdateTaskCompletion,
} from '@payaca/store/tasks/tasksActions';
import { UpdateTaskCompletionRequestData } from '@payaca/store/tasks/tasksTypes';

import { useSelector } from '@/api/state';
import { MaterialsListTaskMaterialsList } from '../materialsListTaskMaterialsList/MaterialsListTaskMaterialsList';
import TaskFormCompletionControl from '../taskFormCompletionControl/TaskFormCompletionControl';
import './DynamicTaskChecklistCompletionForm.sass';

const getFormStateFromTask = (task: Task) => {
  return {
    id: task.id,
    isCompleted: !!task.completedAt,
    checklistItems: task.checklistItems.map((x) => {
      return {
        id: x.id,
        name: x.name,
        isCompleted: !!x.completedAt,
      };
    }),
  };
};

type Props = {
  task: Task;
  onUpdateTaskCompletionSuccess?: () => void;
};
const DynamicTaskChecklistCompletionForm: FunctionComponent<Props> = ({
  task,
  onUpdateTaskCompletionSuccess,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [fetchTaskTimeout, setFetchTaskTimeout] = useState<NodeJS.Timeout>();

  const [isSaveRequired, setIsSaveRequired] = useState(false);

  const isUpdatingTaskCompletion = useSelector((state) => {
    return state.tasks.isUpdatingTaskCompletion;
  });

  const isFetchingTask = useSelector((state) => {
    return state.tasks.tasks && state.tasks.tasks[task.id]?.isFetching;
  });

  const document = useSelector((state) => {
    return task?.documentId && getDocument(state, task.documentId);
  });

  useEffect(() => {
    if (task.documentId) {
      dispatch(documentActions.requestGetDocumentForTask(task.id));
    }
  }, [task?.documentId]);

  const userRoles = useSelector(getUserRoles);

  const profile = useSelector((state: any) => state.users.myProfile);

  const userHasCompleteTaskPermission = useMemo(() => {
    return (
      userHasRequiredPermission(userRoles, [TasksPermissions.COMPLETE_TASK]) ||
      (userHasRequiredPermission(userRoles, [
        TasksPermissions.COMPLETE_SELF_ASSIGNED_TASK,
      ]) &&
        task.assignedToUserId === profile.id)
    );
  }, [userRoles, task, profile]);

  const initialFormState = useMemo(() => {
    return getFormStateFromTask(task);
  }, [task]);

  const requiresUpdateTaskCompletion = useCallback(
    (formState: UpdateTaskCompletionRequestData) => {
      if (isFetchingTask || isUpdatingTaskCompletion) return true;
      return !isEqual(formState, getFormStateFromTask(task));
    },
    [isFetchingTask, isUpdatingTaskCompletion, task]
  );

  const handleUpdateTaskCompletionSuccess = useCallback(() => {
    if (onUpdateTaskCompletionSuccess) {
      void onUpdateTaskCompletionSuccess();
    }
    setFetchTaskTimeout((timeout) => {
      if (timeout) clearTimeout(timeout);
      return setTimeout(() => {
        dispatch(requestGetTask(task.id));
      }, 500);
    });
  }, [task.id, onUpdateTaskCompletionSuccess]);

  const updateTaskCompletion = useCallback(
    (updateTaskCompletionRequestData: UpdateTaskCompletionRequestData) => {
      if (requiresUpdateTaskCompletion(updateTaskCompletionRequestData)) {
        dispatch(
          requestUpdateTaskCompletion(
            updateTaskCompletionRequestData,
            handleUpdateTaskCompletionSuccess
          )
        );
      }
    },
    [dispatch, handleUpdateTaskCompletionSuccess]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      if (isSaveRequired) {
        updateTaskCompletion(formState as UpdateTaskCompletionRequestData);
        setIsSaveRequired(false);
      }

      return (
        <React.Fragment>
          {task.type === 'checklist' && (
            <TaskChecklistCompletionControl
              task={task}
              formState={formState as any}
              isEditable={userHasCompleteTaskPermission}
              onChange={(value) => {
                onFieldChange(value);
                setIsSaveRequired(true);
              }}
            />
          )}
          {(task.type === 'form' || task.type == 'legacy-form') && document && (
            <TaskFormCompletionControl
              task={task}
              document={document}
              includeTaskIdInUrl={true}
            />
          )}
          {task.type === 'materials-list' && (
            <MaterialsListTaskMaterialsList taskId={task.id} />
          )}
        </React.Fragment>
      );
    },
    [
      document,
      isSaveRequired,
      task,
      isUpdatingTaskCompletion,
      updateTaskCompletion,
      userHasCompleteTaskPermission,
    ]
  );

  return (
    <div className="dynamic-task-checklist-completion-form">
      {isUpdatingTaskCompletion && <MiniLoader />}
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
      />
    </div>
  );
};

export default DynamicTaskChecklistCompletionForm;
