import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getAccountRegionFromCurrencyCodeLegacy } from '@payaca/helpers/internationalHelper';
import { AccountRegions } from '@payaca/types/accountTypes';

/**
 * Formats a Provider GraphQL API's currency object into a human-readable string.
 */
export const humanizeCurrency = (
  currency: {
    value: number;
    currency?: {
      code: string;
    };
  },
  includeFractional = true,
  options?: Intl.NumberFormatOptions
) => {
  const region = currency.currency
    ? getAccountRegionFromCurrencyCodeLegacy(currency.currency.code)
    : AccountRegions.UK;

  return currencyPrice(currency.value, region, includeFractional, options);
};

export const currencyLabel = (
  val: number,
  currencyCode?: string,
  includeFractional = true
) => {
  const fractionDigits = val >= 100000 || val <= -100000 ? 0 : 2; // over £1000 in pennies

  return `${humanizeCurrency(
    {
      value: val,
      ...(currencyCode
        ? {
            currency: {
              code: currencyCode,
            },
          }
        : {}),
    },
    false,
    {
      // Will use the compact notation if the number is over 1000
      // e.g. £1.5k, £2.3m, £3.4b
      notation: 'compact',
      minimumFractionDigits: includeFractional ? fractionDigits : 0,
    }
  )}`;
};
