import { FC, useState } from 'react';
import { useScroll } from 'react-use';

import { useGetInfiniteLineItems } from '@/api/queries/lineItems/useGetLineItems';
import { GetLineItemsInput, LineItemsQuery } from '@/gql/graphql';
import usePreferredNumberFormat from '@/hooks/usePreferredNumberFormat';
import Conditional from '@payaca/components/conditional/Conditional';
import InfiniteList from '@payaca/components/infiniteList/InfiniteList';
import LineItemCard from '@payaca/components/lineItemCard/LineItemCard';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { clstx } from '@payaca/components/utils';
import { singularPlural } from '@payaca/utilities/stringUtilities';

type LineItem = LineItemsQuery['lineItems']['items'][number];

export interface IProps {
  onLineItemClick?: (lineItem: LineItem) => void;
}

const InfiniteLineItemsList: FC<IProps> = (props) => {
  const { onLineItemClick } = props;

  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  const { y: scrollY } = useScroll({ current: scrollRef });

  const compactNumberFormat = usePreferredNumberFormat({
    notation: 'compact',
    compactDisplay: 'short',
  });

  const [getLineItemsInput, setGetLineItemsInput] = useState<GetLineItemsInput>(
    {}
  );

  const {
    data: lineItems,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useGetInfiniteLineItems(getLineItemsInput);

  const totalLineItemsFound = lineItems?.pages[0]?.lineItems.totalCount || 0;

  return (
    <InfiniteList ref={setScrollRef}>
      <InfiniteList.Header className={clstx(scrollY > 0 && 'shadow')}>
        <InfiniteList.Search
          placeholder="Search Items "
          onChangeTimeout={(value) => {
            setGetLineItemsInput((s) => ({
              ...s,
              searchTerm: value,
            }));
          }}
        />
      </InfiniteList.Header>

      <InfiniteList.List
        skeletonItem={<SkeletonLoader.MaterialCard />}
        isLoading={isLoading}
        prefix={
          <Conditional condition={!isLoading && totalLineItemsFound > 0}>
            <p className="supporting-body text-right">
              {singularPlural(
                totalLineItemsFound,
                'Item found',
                'Items found',
                {
                  lengthFormatted:
                    compactNumberFormat.format(totalLineItemsFound),
                }
              )}
            </p>
          </Conditional>
        }
        emptyStateText="No Items found"
        numOfSkeletonLoaders={12}
        canLoadMore={hasNextPage}
        onLoadMore={() => {
          if (hasNextPage) {
            void fetchNextPage();
          }
        }}
        items={
          lineItems?.pages.map((m) => m.lineItems.items.map((i) => i)).flat() ||
          []
        }
        item={(lineItem) => {
          return (
            <LineItemCard
              key={lineItem.id}
              taxRateLabel={lineItem.price?.taxRate?.label || ''}
              reference={lineItem.name || undefined}
              description={lineItem.description || ''}
              priceIncludingTax={lineItem.price?.unitPrice}
              thumbnailUrl={lineItem.thumbnailUrl || ''}
              onClick={() => {
                onLineItemClick?.(lineItem);
              }}
            />
          );
        }}
      />
    </InfiniteList>
  );
};

export default InfiniteLineItemsList;
