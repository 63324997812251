import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { getActiveUsers, getUserRoles } from '@/utils/stateAccessors';
import { EventDropArg } from '@fullcalendar/react';
import GridCalendar from '@payaca/components/calendar/GridCalendar';
import UserResourceCalendar from '@payaca/components/calendar/UserResourceCalendar';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';
import * as scheduledEventActions from '@payaca/store/scheduledEvents/scheduledEventsActions';
import * as taskActions from '@payaca/store/tasks/tasksActions';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';

import { useSelector } from '@/api/state';
import { useCalendarSettings } from '../../../hooks/useCalendarSettings';
import { useAccount } from '../../../utils/storeHooks';
import { ScheduleContext } from '../contextProviders/ScheduleContextProvider';

const Schedule: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const account = useAccount();
  const history = useHistory();
  const { url } = useRouteMatch();

  const onDateSelect = (dateInfo?: {
    beginAt: string;
    endAt?: string;
    userAssignments?: number[];
  }) => {
    if (
      !userHasRequiredPermission(userRoles, [
        ScheduledEventsPermissions.ADD_EVENT,
        ScheduledEventsPermissions.ADD_MY_EVENT,
      ])
    ) {
      return;
    }
    // open modal with date selected or now
    history.push(`${url}/new`, {
      eventToCreate: {
        beginAt: dateInfo?.beginAt ? new Date(dateInfo?.beginAt) : undefined,
        endAt: dateInfo?.endAt ? new Date(dateInfo?.endAt) : undefined,
        userAssignments: dateInfo?.userAssignments,
      },
    });
  };

  // open modal to update event
  const onExistingEventSelect = (scheduledEvent: ScheduledEvent) => {
    history.push(`${url}/${scheduledEvent.id}`);
  };

  //update event by drag and drop
  const onEventDrop = useCallback((info: EventDropArg) => {
    const existingUserAssignments = info.event.extendedProps.userAssignments;

    //@ts-ignore -- oldResource exists on type EventDropArg but typing is complaining
    const oldUserAssignment = info.oldResource?.id
      ? //@ts-ignore
        parseInt(info.oldResource.id)
      : undefined;

    //@ts-ignore
    const newUserAssignment = info.newResource?.id
      ? //@ts-ignore
        parseInt(info.newResource.id)
      : undefined;

    let updatedUserAssignments = existingUserAssignments;

    //@ts-ignore
    if (info.oldResource) {
      if (oldUserAssignment) {
        updatedUserAssignments = existingUserAssignments.filter(
          (x: number) => x != oldUserAssignment
        );
      }
      if (newUserAssignment) {
        updatedUserAssignments.push(newUserAssignment);
      }
    }

    const id = parseInt(info.event.id);

    const newEvent = {
      id: id,
      beginAt: info.event.start || undefined,
      endAt: info.event.end || undefined,
      userAssignments: updatedUserAssignments,
    };

    dispatch(scheduledEventActions.requestUpdateScheduledEvent(newEvent));
  }, []);

  // update event by drag to resize end time
  const onEventResize = (info: any) => {
    const id = parseInt(info.event.id);

    const newEvent = {
      id: id,
      endAt: info.event.end,
    };
    dispatch(scheduledEventActions.requestUpdateScheduledEvent(newEvent));
  };

  const calendarRef = useContext(ScheduleContext).calendarRef;

  const { excludeNonBusinessHours, calendarType } =
    useContext(ScheduleContext).calendarViewData;
  const scheduledEvents = useContext(ScheduleContext).scheduledEvents;
  const { assignedUserIds } = useContext(ScheduleContext).requestData;

  const viewType = calendarRef?.current?.getApi()?.view.type;

  const {
    businessStartTime,
    businessEndTime,
    hiddenDays,
    slotDuration,
    slotDurationClass,
  } = useCalendarSettings(excludeNonBusinessHours, viewType as string);

  const activeUsers = useSelector(getActiveUsers);
  const userRoles = useSelector(getUserRoles);

  const { filteredUsers, showUnassigned } = useMemo(() => {
    const showUnassigned = assignedUserIds?.length
      ? assignedUserIds?.includes(-1)
      : userHasRequiredPermission(userRoles, [AccountsPermissions.GET_USERS]);
    const filteredUsers = activeUsers.filter((user) =>
      assignedUserIds?.length
        ? assignedUserIds.find((x) => x === user.id)
        : true
    );
    return {
      filteredUsers,
      showUnassigned,
    };
  }, [activeUsers, assignedUserIds]);

  const canOnlyViewOwnTasks = useMemo(() => {
    return (
      !userHasRequiredPermission(userRoles, [TasksPermissions.PERSIST_TASK]) &&
      userHasRequiredPermission(userRoles, [
        TasksPermissions.PERSIST_SELF_CREATED_TASK,
      ])
    );
  }, [userRoles]);

  useEffect(() => {
    if (canOnlyViewOwnTasks) {
      dispatch(taskActions.clearTasks());
    }
  }, [canOnlyViewOwnTasks]);

  return (
    <>
      {calendarType === 'userResource' ? (
        <UserResourceCalendar
          scheduledEvents={scheduledEvents || []}
          onDateSelect={onDateSelect}
          onExistingEventSelect={onExistingEventSelect}
          users={filteredUsers}
          showUnassigned={
            userHasRequiredPermission(userRoles, [
              ScheduledEventsPermissions.GET_EVENTS,
            ]) && showUnassigned
          }
          eventDrop={onEventDrop}
          eventResize={onEventResize}
          additionalCalendarProps={{
            slotDuration,
            slotMinWidth: 10,
          }}
          minTime={businessStartTime}
          maxTime={businessEndTime}
          hiddenDays={hiddenDays}
          className={slotDurationClass}
          scheduledEventColourCoding={account.scheduledEventColourCoding}
          calendarRef={calendarRef}
          isExternallyControlled={true}
        />
      ) : (
        // !isUpdatingView && (
        <GridCalendar
          scheduledEvents={scheduledEvents || []}
          onDateSelect={onDateSelect}
          onExistingEventSelect={onExistingEventSelect}
          eventDrop={onEventDrop}
          eventResize={onEventResize}
          editable={userHasRequiredPermission(userRoles, [
            ScheduledEventsPermissions.UPDATE_EVENT,
          ])}
          activeUsers={
            userHasRequiredPermission(userRoles, [
              AccountsPermissions.GET_USERS,
            ])
              ? activeUsers
              : null
          }
          minTime={businessStartTime}
          maxTime={businessEndTime}
          hiddenDays={hiddenDays}
          additionalCalendarProps={{
            contentHeight: businessStartTime ? 'auto' : undefined,
          }}
          scheduledEventColourCoding={account.scheduledEventColourCoding}
          calendarRef={calendarRef}
          isExternallyControlled={true}
        />
      )}
    </>
  );
};

export default Schedule;
