import { Tag as TagType } from '@payaca/types/tagTypes';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { InputStyleVariant } from '../inputWrapper/InputWrapper';
import Tag from '../tag/Tag';

import { VALID_TAG_CHARACTERS_REGEX } from '@payaca/constants/regexConstants';
import {
  getLengthFieldValidator,
  getRegexMatchFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { validateForm } from '@payaca/helpers/formValidationHelper';
import TypeToSearchField from '../typeToSearchField/TypeToSearchField';
import ValidatedFieldWrapper from '../validatedFieldWrapper/ValidatedFieldWrapper';

const tagLengthFieldValidator = getLengthFieldValidator(
  { min: 1, max: 30 },
  { customErrorMessage: 'Max 30 chars' }
);

const allowedCharactersFieldValidator = getRegexMatchFieldValidator(
  VALID_TAG_CHARACTERS_REGEX,
  { customErrorMessage: 'Invalid tag' }
);

type Props = {
  availableTags: TagType[];
  onAddAvailableTag: (tagId: number) => void;
  onAddNewTag?: (tagText: string) => void;
  onBlur?: () => void;
  userCanCreateNewTag: boolean;
};
const AddTagControl: FC<Props> = ({
  availableTags,
  onAddAvailableTag,
  onAddNewTag,
  onBlur,
  userCanCreateNewTag,
}: Props): JSX.Element => {
  const [tagText, setTagText] = useState('');
  const [requiresAddNewTag, setRequiresAddNewTag] = useState(false);

  const trimmedTagtext = useMemo(() => {
    return tagText.trim();
  }, [tagText]);

  const tagTextValidationResult = useMemo(() => {
    return validateForm(
      { tagText: trimmedTagtext },
      {
        tagText: [tagLengthFieldValidator, allowedCharactersFieldValidator],
      }
    ).tagText;
  }, [trimmedTagtext]);

  const options = useMemo(() => {
    return availableTags
      .sort((a: TagType, b: TagType) => {
        const textA = b.tagText.toUpperCase();
        const textB = a.tagText.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map((tag: TagType) => {
        return {
          label: tag.tagText,
          value: tag.id,
          colour: tag.tagColour,
        };
      });
  }, [availableTags]);

  const handleAddNewTag = useCallback(() => {
    const availableTag = availableTags.find(
      (x) => x.tagText === trimmedTagtext
    );
    availableTag
      ? onAddAvailableTag(availableTag.id)
      : userCanCreateNewTag && onAddNewTag?.(trimmedTagtext);
  }, [trimmedTagtext, onAddNewTag, availableTags, onAddAvailableTag]);

  useEffect(() => {
    if (requiresAddNewTag) {
      handleAddNewTag();
      setRequiresAddNewTag(false);
    }
  }, [requiresAddNewTag]);

  return (
    <div className="add-tag-control">
      <ValidatedFieldWrapper
        isTouched={!!tagText?.length}
        validationResult={tagTextValidationResult}
      >
        <TypeToSearchField
          styleVariant={InputStyleVariant.STANDARD}
          value={tagText}
          options={options}
          onSelectOption={(option) => {
            if (option) {
              const tag = availableTags.find((x) => x.id == option.value);
              tag && setTagText(tag.tagText);
              tag && onAddAvailableTag(tag.id);
            }
          }}
          onSearchTermChange={setTagText}
          changeTimeoutMs={3000}
          onEnter={() => {
            tagTextValidationResult.isValid && setRequiresAddNewTag(true);
          }}
          iconAfter={null}
          placeholder={'Add a tag'}
          autofocus={true}
          onBlur={onBlur}
          renderOption={(option) => {
            return (
              <div
                style={{
                  marginLeft: -10,
                  marginRight: -10,
                  marginTop: -3,
                  marginBottom: -3,
                  overflow: 'hidden',
                }}
              >
                <Tag
                  tagText={option.label}
                  colour={option.colour}
                  showTagIcon={false}
                />
              </div>
            );
          }}
        />
      </ValidatedFieldWrapper>
    </div>
  );
};

export default AddTagControl;
