import { Pipeline, PipelineStage } from '@payaca/types/pipelineTypes';
import { action } from 'typesafe-actions';
import { ActionType } from './pipelineTypes';

export const requestGetAccountPipelines = () => {
  return action(ActionType.REQUEST_GET_ACCOUNT_PIPELINES);
};

export const getAccountPipelinesSuccess = (pipelines: Pipeline[]) => {
  return action(ActionType.GET_ACCOUNT_PIPELINES_SUCCESS, pipelines);
};

export const getAccountPipelinesFailure = (error: Error) => {
  return action(ActionType.GET_ACCOUNT_PIPELINES_FAILURE, null, null, error);
};

export const requestCreatePipeline = (
  title: string,
  templatePipelineId: number,
  callback?: any
) => {
  return action(ActionType.REQUEST_CREATE_PIPELINE, {
    title,
    templatePipelineId,
    callback,
  });
};

export const createPipelineSuccess = (pipeline: Pipeline) => {
  return action(ActionType.CREATE_PIPELINE_SUCCESS, pipeline);
};

export const createPipelineFailure = (error: Error) => {
  return action(ActionType.CREATE_PIPELINE_FAILURE, null, error);
};

export const requestUpdatePipeline = (
  pipelineId: number,
  pipeline: Omit<Pipeline, 'stages'> & {
    stages: Partial<PipelineStage>[];
  }
) => {
  return action(ActionType.REQUEST_UPDATE_PIPELINE, { pipelineId, pipeline });
};

export const updatePipelineSuccess = (pipelines: Pipeline[]) => {
  return action(ActionType.UPDATE_PIPELINE_SUCCESS, pipelines);
};

export const updatePipelineFailure = (pipelineId: number, error: Error) => {
  return action(ActionType.UPDATE_PIPELINE_FAILURE, pipelineId, null, error);
};

export const requestDeletePipeline = (pipelineId: number, callback?: any) => {
  return action(ActionType.REQUEST_DELETE_PIPELINE, { pipelineId, callback });
};

export const deletePipelineSuccess = (pipelines: Pipeline[]) => {
  return action(ActionType.DELETE_PIPELINE_SUCCESS, pipelines);
};

export const deletePipelineFailure = (error: Error) => {
  return action(ActionType.DELETE_PIPELINE_FAILURE, null, error);
};

export const clearPipelines = () => {
  return action(ActionType.CLEAR_PIPELINES);
};
