import { FC, useEffect } from 'react';

import { FIELD_OPTIONS } from '@/ui/pages/automationsPageOld/automation.utils';
import { useAccount } from '@/utils/storeHooks';
import BasicField from '@payaca/components/basicField/BasicField';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import TextareaFieldFormatter from '@payaca/components/textareaField/TextareaFieldFormatter';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import VariableInsertControl from '@payaca/components/variableInsertControl/VariableInsertControl';
import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';
import {
  AutomationEntityTypes,
  EmailNotificationActionConfig,
} from '@payaca/types/automationTypes';
import UploadCollectionControl from '../uploadCollectionControl/UploadCollectionControl';

import { useProjectCustomFields } from '@/hooks/useProjectCustomFields';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import { useUploads } from '@payaca/store/hooks/appState';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Upload } from '@payaca/types/uploadTypes';
import './EmailNotificationAutomationActionConfigFieldset.sass';
import { useActionVariablesForAutomationEntityType } from './useActionVariablesForAutomationEntityType';

const acceptFileTypes = getAcceptedFileTypes([
  'document',
  'image',
  'video',
  'other',
]);

const sendToDataAccessTypes = [
  AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS,
  AutomationDataAccessType.USER_EMAILADDRESS,
  AutomationDataAccessType.SCHEDULEDEVENT_ASSIGNEDUSERS_EMAILADDRESS_ARRAY,
  AutomationDataAccessType.CUSTOM_EMAILADDRESS,
  AutomationDataAccessType.SCHEDULEDEVENT_LOCATION_CONTACTS_EMAILADDRESS_ARRAY,
  AutomationDataAccessType.DEAL_SITEADDRESSES_CONTACTS_EMAILADDRESS_ARRAY,
  AutomationDataAccessType.DEAL_CUSTOMFIELD_VALUE,
];

type Props = {
  automationEntityType: AutomationEntityTypes;
  config: EmailNotificationActionConfig;
  onChange: (changeValue: { [key: string]: any }) => void;
  isDefaultAutomation?: boolean;
  uploadIds?: Upload['id'][];
  onUploadCollectionChange?: (uploadIds: Upload['id'][]) => void;
  validationState?: Record<string, FieldValidationResult>;
  touchedState?: Record<string, boolean>;
  onTouch?: (fieldName: string) => void;
  showFieldValidationMessagesUnconditionally?: boolean;
};

const EmailNotificationAutomationActionConfigFieldset: FC<Props> = ({
  automationEntityType,
  config,
  onChange,
  isDefaultAutomation,
  uploadIds,
  onUploadCollectionChange,
  validationState,
  touchedState,
  onTouch,
  showFieldValidationMessagesUnconditionally,
}) => {
  const customFields = useProjectCustomFields();
  const { variables: allowableEmailVariables, variableReadableNameMap } =
    useActionVariablesForAutomationEntityType(automationEntityType);

  const account = useAccount();

  const sendToOptions = FIELD_OPTIONS.filter((o) => {
    if (automationEntityType in o.entityColumns) {
      const dataAccessType = o.entityColumns[automationEntityType];
      if (dataAccessType) {
        return sendToDataAccessTypes.includes(dataAccessType);
      }
    }
  }).reduce(
    (
      acc: {
        label: string;
        value: string;
      }[],
      elem
    ) => {
      if (
        elem.entityColumns[automationEntityType] ===
        AutomationDataAccessType.DEAL_CUSTOMFIELD_VALUE
      ) {
        customFields
          .filter((cf) => !cf.archivedAt && cf.type === 'email')
          .forEach((cf) => {
            acc.push({
              label: `${elem.label}: ${cf.identifier}`,
              value: `${elem.entityColumns[automationEntityType]}:${cf.identifier}`,
            });
          });
      } else {
        acc.push({
          label: elem.label,
          value: elem.entityColumns[automationEntityType] || '',
        });
      }

      return acc;
    },
    []
  );

  // .map((o) => {
  //   return {
  //     label: o.label,
  //     value: o.entityColumns[automationEntityType],
  //   };
  // });

  const uploads = useUploads(uploadIds || []);

  useEffect(() => {
    onChange({
      emailAttachments: uploads?.map((upload) => ({
        url: upload.url,
        fileName: upload.fileName,
        mimeType: upload.mimeType,
        fileSize: upload.fileSize,
      })),
    });
  }, [uploads?.length]);

  return (
    <fieldset className="automation-action-config-fieldset email-notification-automation-action-config-fieldset">
      <ResponsiveViewWrapper
        className="recipient-fields"
        downBreakpointSm={700}
        downBreakpointXs={500}
      >
        <div className="to">
          <ValidatedFieldWrapper
            validationResult={validationState?.sendToEmail}
            isTouched={
              !!touchedState?.sendToEmail ||
              showFieldValidationMessagesUnconditionally
            }
          >
            <DropdownField
              label={'To'}
              name={`sendToEmail`}
              value={config.sendToEmail}
              options={sendToOptions}
              onChange={onChange}
              isDisabled={isDefaultAutomation}
              onTouch={onTouch}
            />
          </ValidatedFieldWrapper>
        </div>
        <div className="cc">
          <ValidatedFieldWrapper
            validationResult={validationState?.ccEmails}
            isTouched={
              !!touchedState?.ccEmails ||
              showFieldValidationMessagesUnconditionally
            }
          >
            <BasicField
              name={'ccEmails'}
              value={config.ccEmails}
              onChange={onChange}
              label={'Cc'}
              onTouch={onTouch}
            />
          </ValidatedFieldWrapper>
        </div>
        <div className="bcc">
          <ValidatedFieldWrapper
            validationResult={validationState?.bccEmails}
            isTouched={
              !!touchedState?.bccEmails ||
              showFieldValidationMessagesUnconditionally
            }
          >
            <BasicField
              name={'bccEmails'}
              value={config.bccEmails}
              onChange={onChange}
              label={'Bcc'}
              onTouch={onTouch}
            />
          </ValidatedFieldWrapper>
        </div>
      </ResponsiveViewWrapper>
      {config.sendToEmail === AutomationDataAccessType.CUSTOM_EMAILADDRESS &&
        !isDefaultAutomation && (
          <ValidatedFieldWrapper
            validationResult={validationState?.customEmail}
            isTouched={
              !!touchedState?.customEmail ||
              showFieldValidationMessagesUnconditionally
            }
          >
            <BasicField
              label={'Custom email'}
              name={`customEmail`}
              value={config.customEmail}
              onChange={onChange}
              onTouch={onTouch}
              isDisabled={isDefaultAutomation}
            />
          </ValidatedFieldWrapper>
        )}
      <div className="sendMeACopy">
        <CheckboxField
          value={config.sendACopy}
          name="sendACopy"
          label="Send me a copy"
          onChange={onChange}
        />
      </div>
      <VariableInsertControl
        availableVariables={allowableEmailVariables}
        variableEnabledCopy={[config.emailSubject, config.emailBody]}
        variableReadableNameMap={variableReadableNameMap}
        enableVariableSearch={true}
        onUsedVariablesChange={(usedVariables) => {
          onChange({
            emailVariables: usedVariables,
          });
        }}
      >
        {({ setEventHandlers }) => (
          <div className="variable-insert-input-fields">
            <ValidatedFieldWrapper
              validationResult={validationState?.emailSubject}
              isTouched={
                !!touchedState?.emailSubject ||
                showFieldValidationMessagesUnconditionally
              }
            >
              <BasicField
                label="Subject"
                value={config.emailSubject}
                name="emailSubject"
                bindEventHandlers={(x) => {
                  setEventHandlers(x);
                }}
                onChange={onChange}
                onTouch={onTouch}
              />
            </ValidatedFieldWrapper>
            <ValidatedFieldWrapper
              validationResult={validationState?.emailBody}
              isTouched={
                !!touchedState?.emailBody ||
                showFieldValidationMessagesUnconditionally
              }
            >
              <TextareaFieldFormatter
                label="Message"
                name={'emailBody'}
                value={config.emailBody}
                bindEventHandlers={(x) => {
                  setEventHandlers(x);
                }}
                onChange={onChange}
                onTouch={onTouch}
              />
            </ValidatedFieldWrapper>
            {/* Temporarily hidden; we may choose to include this again later */}
            {/* <div className="email-preview-container">
              <h3>Email preview</h3>
              <span>This is the email your customers will see</span>
              <div className="email-preview">
                <CompanyLogo logoUrl={account.logoUrl} />
                <MarkdownLabel markdown={config.emailBody} />
              </div>
            </div> */}
            {onUploadCollectionChange && (
              <UploadCollectionControl
                label="Attachment"
                uploadIds={uploadIds || []}
                onUploadCollectionChange={onUploadCollectionChange}
                allowMultipleUploads={false}
                // 10mb
                fileSizeLimitBytes={10 * 1024 * 1024}
                acceptFileTypes={acceptFileTypes}
              />
            )}
          </div>
        )}
      </VariableInsertControl>
    </fieldset>
  );
};

export default EmailNotificationAutomationActionConfigFieldset;
