import { PurchaseOrderPdfDocumentData } from '@payaca/types/purchaseOrderPdfDocumentTypes';
import { action } from 'typesafe-actions';
import {
  ActionType,
  GetChangeProposalDataAction,
  GetInvoiceDataAction,
  GetProtoInvoiceDataAction,
} from './pdfDocumentDataTypes';

import { ChangeProposalPdfDocumentData } from '@payaca/types/changeProposalPdfDocumentTypes';
import { createAsyncAction } from '../utils';

export const requestGetPurchaseOrderData = (
  previewToken: string,
  callback: (data: PurchaseOrderPdfDocumentData) => void,
  errorCallback?: (error: Error) => void
) => {
  return action(ActionType.GET_PURCHASE_ORDER_DATA_REQUEST, {
    previewToken,
    callback,
    errorCallback,
  });
};

export const getChangeProposalData =
  createAsyncAction<GetChangeProposalDataAction>({
    request: ActionType.GET_CHANGE_PROPOSAL_DATA_REQUEST,
  })(
    (
      previewToken: string,
      callback?: (data: ChangeProposalPdfDocumentData) => void,
      onErrorCallback?: (err: Error) => void
    ) => ({
      payload: { previewToken, callback, onErrorCallback },
    })
  );

export const getInvoiceData = createAsyncAction<GetInvoiceDataAction>({
  request: ActionType.GET_INVOICE_DATA_REQUEST,
})((payload: GetInvoiceDataAction['request']['payload']) => ({
  payload,
}));

export const getProtoInvoiceData = createAsyncAction<GetProtoInvoiceDataAction>(
  {
    request: ActionType.GET_PROTO_INVOICE_DATA_REQUEST,
  }
)((payload: GetProtoInvoiceDataAction['request']['payload']) => ({
  payload,
}));
