import * as clipboard from 'clipboard-polyfill/text';
import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { PayacaPagesConfigControl } from '@/ui/components/payacaPages/PayacaPagesConfigControl';
import Button from '@payaca/components/button/Button';
import CollapsiblePanel, {
  CollapsiblePanelStyleVariant,
} from '@payaca/components/collapsiblePanel/CollapsiblePanel';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import { PayacaPageRenderer } from '@payaca/components/payacaPages/PayacaPageRenderer';
import { PayacaPagesNewLead } from '@payaca/components/payacaPages/PayacaPagesNewLead';
import PillSelectionField from '@payaca/components/pillSelectionField/PillSelectionField';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import {
  PayacaPagesConfig,
  PayacaPagesTemplates,
  PayacaPagesTitles,
} from '@payaca/types/payacaPagesTypes';

import { useSelector } from '@/api/state';
import TagSelectionControl from '@/ui/components/tagSelectionControl/TagSelectionControl';
import AuthenticatedPageWrapper from '@/ui/pages/pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import { TagControlAlignment } from '@payaca/components/tagControl/TagControl';
import './PayacaPagesPage.sass';

const pages = [PayacaPagesTitles.NEW_LEAD];
const PAYACA_PAGES_URL =
  import.meta.env.VITE_PAYACA_PAGES_URL || 'https://pages.l.payaca.com';

export const PayacaPagesPage: FC = () => {
  const [pageConfig, setPageConfig] = useState<PayacaPagesConfig>({
    token: '',
    template: PayacaPagesTemplates.NEW_LEAD,
    isEmbedded: false,
    color: '#000',
    cellPadding: 10,
    cellSpacing: 16,
    borderWidth: 1,
    borderRadius: 0,
    tagIds: [],
    questions: ['0'],
  });

  const pipelines = useSelector((state) => state.pipelines.pipelines);

  const pipelineOptions = useMemo(() => {
    return pipelines
      .filter((x) => !!x.id)
      .map((x) => {
        return {
          value: x.id,
          label: x.title,
        };
      });
  }, [pipelines]);

  const sections = useMemo(
    () => [
      {
        name: PayacaPagesTemplates.NEW_LEAD,
      },
    ],
    []
  );
  const [openSection, setOpenSection] = useState<string | null>(
    PayacaPagesTemplates.NEW_LEAD
  );

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  useEffect(
    () =>
      setPageConfig({
        ...pageConfig,
        token: account.leadCaptureToken,
        logoURL: account.logoUrl,
        color: account.brandColour || '#000',
      }),
    [account]
  );
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);

  const clipboardOutput = useMemo(() => {
    // we'll base64 encode ONLY the values of our config - this will help us keep URLs as short as possible
    // TODO: expand to handle multiple forms
    const url = `${PAYACA_PAGES_URL}/new_lead/${pageConfig.token}/${btoa(
      JSON.stringify([
        pageConfig.logoURL,
        pageConfig.color,
        pageConfig.cellSpacing,
        pageConfig.cellPadding,
        pageConfig.borderWidth,
        pageConfig.borderRadius,
        pageConfig.tagIds,
        pageConfig.questions,
        pageConfig.pipelineId,
      ])
    )}`;
    return pageConfig.isEmbedded
      ? `<iframe src="${url}?embed=true" width="400" height="600" allowtransparency="true" style="border: none" scrolling="no"></iframe>`
      : url;
  }, [account, pageConfig]);

  return (
    <AuthenticatedPageWrapper title="My Pages" className="pages-page">
      <div className="payaca-pages">
        <PayacaPagesConfigControl value={pageConfig} onChange={setPageConfig} />
        <div className={'payaca-pages-preview-container'}>
          {pages.map((pageTitle: string, i: number) => (
            <CollapsiblePanel
              key={i}
              title={pageTitle}
              isOpen={openSection === sections[0].name}
              onClose={() => setOpenSection(null)}
              onOpen={() => setOpenSection(sections[0].name)}
              styleVariant={CollapsiblePanelStyleVariant.OUTSIZE}
            >
              <div className={'payaca-pages-preview-template'}>
                <div className={'payaca-pages-preview-header'}>
                  <div className={'payaca-pages-preview-header-type'}>
                    <PillSelectionField
                      name={'isEmbedded'}
                      value={pageConfig.isEmbedded}
                      onChange={({ isEmbedded }) =>
                        setPageConfig({
                          ...pageConfig,
                          isEmbedded: Boolean(isEmbedded),
                        })
                      }
                      options={[
                        {
                          label: 'Create link to a page',
                          value: false,
                        },
                        {
                          label: 'Create website code snippet',
                          value: true,
                        },
                      ]}
                    />
                  </div>
                  <div className={'payaca-pages-preview-header-target'}>
                    {!!pipelineOptions.length && (
                      <div className="pipeline-dropdown-field">
                        <DropdownField
                          options={pipelineOptions}
                          name="pipelineId"
                          onChange={(value) =>
                            setPageConfig((c) => ({
                              ...c,
                              pipelineId: value.pipelineId,
                            }))
                          }
                          value={pageConfig.pipelineId ?? undefined}
                          label={'Target pipeline'}
                          description={
                            'Submissions will be added to this pipeline'
                          }
                          emptyText="None selected"
                        />
                      </div>
                    )}
                    <div>
                      <FieldLabel
                        label={'Tags'}
                        description={
                          'Submissions will be tagged with the following'
                        }
                      />
                      <TagSelectionControl
                        selectedTagIds={pageConfig.tagIds || []}
                        onSelectedTagsChange={(tagIds: number[]) =>
                          setPageConfig((c) => ({ ...c, tagIds }))
                        }
                        alignment={TagControlAlignment.LEFT}
                      />
                    </div>
                    <Button
                      className="copy-to-clipboard-trigger"
                      onClick={async () => {
                        await clipboard.writeText(clipboardOutput);
                        showDynamicFeedbackMessage({
                          lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
                          title: 'Copied!',
                          body: pageConfig.isEmbedded
                            ? 'You can now paste the code into your website or landing page'
                            : 'You can now paste your link into any e-mails/your social media accounts',
                          feedbackLevel: FeedbackLevel.NEUTRAL,
                          isCancellable: true,
                        });
                      }}
                    >
                      Copy to clipboard
                    </Button>
                  </div>
                  <h4>Preview</h4>
                </div>
                <div className={'payaca-pages-preview-output'}>
                  <PayacaPageRenderer {...pageConfig}>
                    {pageTitle === PayacaPagesTitles.NEW_LEAD && (
                      <PayacaPagesNewLead
                        isComplete={false}
                        isLoading={false}
                        onSubmit={() => null}
                        {...pageConfig}
                      />
                    )}
                  </PayacaPageRenderer>
                </div>
              </div>
            </CollapsiblePanel>
          ))}
        </div>
      </div>
    </AuthenticatedPageWrapper>
  );
};

export default PayacaPagesPage;
