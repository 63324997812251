import { LineItemGroupLineItem } from '@payaca/types/lineItemGroupTypes';
import { FunctionComponent, useMemo } from 'react';
import RequiredSettingsDropdown, {
  getRequiredSettingsType,
  RequiredSettingsType,
} from '../requiredSettingsDropdown/RequiredSettingsDropdown';

import { useSelector } from '@/api/state';
import { currencyPrice } from '@/helpers/financeHelper';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '@payaca/components/checkbox/Checkbox';
import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';
import RadioButton from '@payaca/components/radioButton/RadioButton';
import { useTaxRate } from '@payaca/store/hooks/appState';
import QuantityControl from '../quantityControl/QuantityControl';
import './LineItemGroupLineItemControl.sass';

type Props = {
  lineItemGroupLineItem: LineItemGroupLineItem;
  fieldNamePrefix: string;
  onChange: (value: { [key: string]: any }) => void;
  onTouch: (fieldName: string) => void;
  onDelete: () => void;
};

const LineItemGroupLineItemControl: FunctionComponent<Props> = ({
  lineItemGroupLineItem,
  fieldNamePrefix,
  onChange,
  onTouch,
  onDelete,
}: Props): JSX.Element | null => {
  const lineItem = useSelector((state) => {
    return state.lineItemsV2.lineItems[lineItemGroupLineItem.lineItemId]
      ?.entity;
  });

  const taxRate = useTaxRate(lineItem?.taxRateId);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const isSelectedContainer = useMemo(() => {
    const requiredSettingsType = getRequiredSettingsType(lineItemGroupLineItem);

    return (
      requiredSettingsType !== RequiredSettingsType.REQUIRED && (
        <div className="is-selected-container">
          {requiredSettingsType === RequiredSettingsType.OPTIONAL && (
            <Checkbox
              isChecked={lineItemGroupLineItem.isSelected}
              onChange={() => {
                const updateItem = {
                  [`${fieldNamePrefix}.isSelected`]:
                    !lineItemGroupLineItem.isSelected,
                };
                onChange(updateItem);
              }}
            />
          )}
          {requiredSettingsType === RequiredSettingsType.MULTIPLE_CHOICE && (
            <RadioButton
              isSelected={lineItemGroupLineItem.isSelected}
              onClick={() => {
                if (lineItemGroupLineItem.isSelected) return;
                const updateItem = {
                  [`${fieldNamePrefix}.isSelected`]:
                    !lineItemGroupLineItem.isSelected,
                };
                onChange(updateItem);
              }}
            />
          )}
        </div>
      )
    );
  }, [onChange, lineItemGroupLineItem, lineItemGroupLineItem.isSelected]);

  return (
    <div className="line-item-group-line-item-control">
      {isSelectedContainer}
      <div className="line-item-group-line-item-control-inner">
        <div className="actions-container">
          <div className="delete-container">
            <FontAwesomeIcon icon={faTimes} onClick={onDelete} />
          </div>
        </div>
        <div className="name-description-container">
          <p>
            <strong>{lineItem?.name}</strong>
          </p>
          <MarkdownLabel
            markdown={lineItem?.description || ''}
            className="item-description"
          />
        </div>
        <hr />
        <div className="price-quantity-required-container flex-container flex-center">
          <div>
            <RequiredSettingsDropdown
              fieldNamePrefix={fieldNamePrefix}
              value={{
                isMultipleChoice: lineItemGroupLineItem.isMultipleChoice,
                isOptional: lineItemGroupLineItem.isOptional,
                isSelected: lineItemGroupLineItem.isSelected,
              }}
              onChange={(value: { [key: string]: any }) => {
                onChange(value);
              }}
            />
          </div>
          <div className="flex-grow"></div>
          <div className="quantity-control-container flex-container flex-center">
            <label>Qty</label>
            <QuantityControl
              value={lineItemGroupLineItem.quantity}
              minimumValue={1}
              onChange={(value: number) =>
                onChange({ [`${fieldNamePrefix}.quantity`]: value })
              }
              showInputField={true}
            />
          </div>
          {lineItem && (
            <div className="price-and-vat-container">
              <div>
                <strong>
                  {currencyPrice(lineItem.calculatedPrice, account.region)}
                </strong>
              </div>
              <div>
                <small>{taxRate?.name ?? '-'}</small>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LineItemGroupLineItemControl;
