import { FC, PropsWithChildren } from 'react';

import Modal from '@payaca/components/modal/Modal';
import { HydratedPurchaseOrder } from '@payaca/types/materialsListTypes';
import ConfirmPurchaseOrderControl from '../confirmPurchaseOrderControl/ConfirmPurchaseOrderControl';
import './ConfirmPurchaseOrderModal.sass';

type Props = {
  purchaseOrder: HydratedPurchaseOrder;
  isOpen: boolean;
  onConfirmPurchaseOrderSuccess?: () => void;
  onClose?: () => void;
};
const ConfirmPurchaseOrderModal: FC<Props> = ({
  purchaseOrder,
  isOpen,
  onConfirmPurchaseOrderSuccess,
  onClose,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={'Record purchases'}
      className="confirm-purchase-order-modal"
    >
      <ConfirmPurchaseOrderControl
        purchaseOrder={purchaseOrder}
        onConfirmPurchaseOrderSuccess={onConfirmPurchaseOrderSuccess}
      />
    </Modal>
  );
};

export default ConfirmPurchaseOrderModal;
