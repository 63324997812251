import { useSelector } from '@/api/state';
import { getScheduleExpandToBeScheduledLocalStorageKey } from '@/helpers/localStorageKeyHelper';
import ScheduleContextProvider, {
  EventToCreate,
  ScheduleContext,
  TScheduleContextProviderLocationState,
} from '@/ui/components/contextProviders/ScheduleContextProvider';
import ScheduleDispatchFilters from '@/ui/components/scheduleDispatchFilters/ScheduleDispatchFilters';
import UnscheduledDeals from '@/ui/components/unscheduledDeals/UnscheduledDeals';
import { getUserRoles } from '@/utils/stateAccessors';
import CollapsibleBlock from '@payaca/components/collapsibleBlock/CollapsibleBlock';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { PipelinesPermissions } from '@payaca/permissions/pipelines/pipelines.permissions';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import { ListedDeal } from '@payaca/types/listedDealTypes';
import { Address } from '@payaca/types/locationTypes';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import './ScheduleDispatchPage.sass';

import { ScheduleDispatchMain } from '@/ui/components/scheduleDispatchMain/ScheduleDispatchMain';
import { getContactsToNotify } from '@payaca/helpers/scheduledEventsHelper';
import { requestGetCustomer } from '@payaca/store/customer/customerActions';
import { Customer } from '@payaca/types/customerTypes';
import { useLocation, useRouteMatch } from 'react-router-dom';

const expandToBeScheduledStorageKey =
  getScheduleExpandToBeScheduledLocalStorageKey();

const ScheduleDispatchPage: FC = () => {
  const currentUserId = useSelector((state: any) => state.users.myProfile.id);

  const createEventBase = useMemo(
    () => ({
      userAssignments: [currentUserId],
    }),
    [currentUserId]
  );

  return (
    <>
      <AuthenticatedPageWrapper className="schedule-dispatch-page">
        <ScheduleContextProvider
          createEventBase={createEventBase}
          hiddenFields={['customerIds']}
        >
          <ScheduleDispatchPageContent />
        </ScheduleContextProvider>
      </AuthenticatedPageWrapper>
    </>
  );
};

const ScheduleDispatchPageContent: FC = () => {
  const { displayType, setPendingEventToCreate, onUpdate } =
    useContext(ScheduleContext);

  const userRoles = useSelector(getUserRoles);

  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { state } = useLocation<TScheduleContextProviderLocationState>();

  const [isHandlingDealClick, setIsHandlingDealClick] = useState<boolean>();

  const getCustomer = async (customerId?: Customer['id']) => {
    return new Promise<Customer | undefined>((resolve) => {
      if (!customerId) resolve(undefined);
      else {
        dispatch(requestGetCustomer(customerId, resolve));
      }
    });
  };

  const getEventToCreateFromListedDeal = async (listedDeal: ListedDeal) => {
    const customer = await getCustomer(listedDeal.customerId);

    return new Promise<EventToCreate>((resolve) => {
      const eventToCreate = {
        userAssignments: listedDeal.assignedToUserId
          ? [listedDeal.assignedToUserId]
          : undefined,
        dealId: listedDeal.dealId,
        name: listedDeal.customerName,
        includeCustomerInformation: true,
      };

      const siteAddress = listedDeal.siteAddresses?.[0];

      if (siteAddress) {
        dispatch(
          requestGetDeal(listedDeal.dealId, (deal) => {
            const siteAddressContacts = deal.siteAddresses?.find(
              (x) => x.address.id === siteAddress.id
            )?.contacts;

            resolve({
              ...eventToCreate,
              location: {
                id: siteAddress.id,
                address: siteAddress,
                contacts: siteAddressContacts,
              },
              contactsToNotify: getContactsToNotify({
                customer,
                locationContacts: siteAddressContacts,
              }),
            });
          })
        );
      } else
        resolve({
          ...eventToCreate,
          location: siteAddress
            ? { address: siteAddress, contacts: [] }
            : undefined,
          contactsToNotify: getContactsToNotify({
            customer,
          }),
        });
    });
  };

  const onClickDeal = async (listedDeal: ListedDeal) => {
    if (isHandlingDealClick) {
      return;
    }

    const hasPermission = userHasRequiredPermission(userRoles, [
      ScheduledEventsPermissions.ADD_EVENT,
      ScheduledEventsPermissions.ADD_MY_DEAL_EVENT,
    ]);

    if (!hasPermission) {
      return;
    }

    setIsHandlingDealClick(true);

    const siteAddress = listedDeal.siteAddresses?.[0];

    const event = await getEventToCreateFromListedDeal(listedDeal);

    if (displayType === 'map') {
      onUpdate({ location: siteAddress || ({} as Address) });
      setPendingEventToCreate(event);
    } else {
      history.push(`${url}/new`, {
        eventToCreate: event,
      });
    }

    setIsHandlingDealClick(false);
  };

  const [externalDealChangeTimestamp, setExternalDealChangeTimestamp] =
    useState<Date>();

  useEffect(() => {
    if (!state?.eventToCreate) {
      setExternalDealChangeTimestamp(new Date());
    }
  }, [state?.eventToCreate]);

  const showSidebar = userHasRequiredPermission(userRoles, [
    DealsPermissions.GET_LISTED_DEALS,
    DealsPermissions.GET_MY_LISTED_DEALS,
    PipelinesPermissions.GET_PIPELINES_FOR_ACCOUNT,
  ]);

  const expandToBeScheduled: boolean =
    localStorage.getItem(expandToBeScheduledStorageKey) !== 'false';

  const [isExpanded, setIsExpanded] = useState(expandToBeScheduled);

  return (
    <div className={`@container/pagecontent bg-pc-blue-lighter flex w-full`}>
      {showSidebar && (
        <div className="@4xl/pagecontent:block sticky top-[70px] hidden h-[calc(100vh-70px)] shrink-0 grow-0">
          <CollapsibleBlock
            isExpanded={isExpanded}
            setIsExpanded={(x) => {
              localStorage.setItem(expandToBeScheduledStorageKey, `${x}`);
              setIsExpanded(x);
            }}
            CustomCollapsedView={() => {
              return (
                <div className="flex flex-col items-center gap-5 p-8">
                  <CollapsibleBlock.ExpandButton />
                  <CollapsibleBlock.CollapsedTitle>
                    To be scheduled
                  </CollapsibleBlock.CollapsedTitle>
                </div>
              );
            }}
          >
            <div className="no-scrollbar h-[calc(100vh-70px)] w-[350px] overflow-y-auto p-8">
              <h1 className="m-0 mb-4 p-0 text-2xl">To be scheduled</h1>
              <div className="mb-4 flex flex-row items-start gap-4">
                <div className="mr-auto">
                  <p className="text-base">Projects without upcoming Events</p>
                </div>
                <CollapsibleBlock.CollapseButton />
              </div>

              <UnscheduledDeals
                onClickDeal={onClickDeal}
                externalDealChangeTimestamp={externalDealChangeTimestamp}
              />
            </div>
          </CollapsibleBlock>
        </div>
      )}
      <div className={`flex grow bg-white`}>
        <div className={`flex grow flex-col gap-4 p-8`}>
          <h1
            className={`block ${
              showSidebar ? '@4xl/pagecontent:hidden' : ''
            } m-0 mb-4 p-0 text-2xl`}
          >
            To be scheduled
          </h1>
          <ScheduleDispatchFilters />
          <ScheduleDispatchMain />
        </div>
      </div>
    </div>
  );
};

export default ScheduleDispatchPage;
