import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  CreateCustomerContactMutation as _CreateCustomerContactMutation,
  CreateCustomerContactInput,
} from '@/gql/graphql';

const CreateCustomerContactMutation = graphql(`
  mutation CreateCustomerContact($input: CreateCustomerContactInput!) {
    createCustomerContact(input: $input) {
      id
    }
  }
`);

const useCreateCustomerContact = (
  options?: UseMutationOptions<
    _CreateCustomerContactMutation,
    unknown,
    CreateCustomerContactInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(CreateCustomerContactMutation, {
        input,
      });
    },
    ...options,
  });
};

export default useCreateCustomerContact;
