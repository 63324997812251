import { useSelector } from '@/api/state';
import {
  getCalendarFilterAssignedUserIdsLocalStorageKey,
  getCalendarHideNonBusinessHoursLocalStorageKey,
  getCalendarViewLocalStorageKey,
  getScheduleDisplayTypeLocalStorageKey,
  getScheduleRangeUnitStorageKey,
} from '@/helpers/localStorageKeyHelper';
import { Props as TScheduledEventFieldsetProps } from '@/ui/components/scheduledEventFieldset/ScheduledEventFieldset';
import { getUserRoles } from '@/utils/stateAccessors';
import FullCalendar from '@fullcalendar/react';
import { DispatchPermissions } from '@payaca/permissions/dispatch/dispatch.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import { requestGetListedScheduledEvents } from '@payaca/store/scheduledEvents/scheduledEventsActions';
import { CreateScheduledEventRequestData } from '@payaca/store/scheduledEvents/scheduledEventsTypes';
import { Address } from '@payaca/types/locationTypes';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';
import { User } from '@payaca/types/userTypes';
import { isNotNullish } from '@payaca/utilities/guards';
import { DeepPartial, Nullish } from '@payaca/utilities/types';
import moment from 'moment-timezone';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import useGetMyAccountUsers from '../../../api/queries/me/useGetMyAccountUsers';
import { CreateScheduledEventModal } from '../createScheduledEventModal/CreateScheduledEventModal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import ScheduledEventReadDrawer from '../scheduledEventReadDrawer/ScheduledEventReadDrawer';
import { RangeUnitMomentMap } from '../scheduleFilters/ScheduleFilters';

const displayTypeStorageKey = getScheduleDisplayTypeLocalStorageKey();
const rangeUnitStorageKey = getScheduleRangeUnitStorageKey();
const calendarViewStorageKey = getCalendarViewLocalStorageKey();
const calendarHideNonBusinessHoursStorageKey =
  getCalendarHideNonBusinessHoursLocalStorageKey();
const calendarFilterAssignedUserIdsStorageKey =
  getCalendarFilterAssignedUserIdsLocalStorageKey();

export type CalendarType = 'grid' | 'userResource';
export type DisplayType = 'map' | 'calendar';
export type ScheduleRangeUnit = 'day' | 'week' | 'month';

type onUpdateProps = Partial<{
  selectedUserIds: User['id'][];
  startAt: Date;
  endAt: Date;
  scheduleRangeUnit: ScheduleRangeUnit;
  displayType: DisplayType;
  excludeNonBusinessHours: boolean;
  calendarType: CalendarType;
  location: Nullish<
    Pick<Address, 'line1' | 'line2' | 'city' | 'country' | 'postcode'>
  >;
}>;

export type EventToCreate = DeepPartial<CreateScheduledEventRequestData>;

type ScheduleContextType = {
  requestData: {
    startAt?: Date;
    endAt?: Date;
    assignedUserIds?: User['id'][];
  };
  displayType: DisplayType;
  scheduleRangeUnit: ScheduleRangeUnit;
  calendarViewData: {
    excludeNonBusinessHours: boolean;
    calendarType: CalendarType;
  };
  mapViewData: {
    location?: Nullish<
      Pick<Address, 'line1' | 'line2' | 'city' | 'country' | 'postcode'>
    >;
  };
  onUpdate: (props: onUpdateProps) => void;

  hideCreateEventButton?: boolean;

  scheduledEvents?: ScheduledEvent[];

  pendingEventToCreate?: EventToCreate;
  setPendingEventToCreate: (eventToCreate?: EventToCreate) => void;

  calendarRef?: React.RefObject<FullCalendar>;

  focusedDate: Date;
  setFocusedDate: (date: Date) => void;
  listenerSubscribe: (
    type: TListenerType,
    identifier: string,
    func: () => void
  ) => void;
  listenerUnsubscribe: (type: TListenerType, identifier: string) => void;
};

export const ScheduleContext = React.createContext<ScheduleContextType>({
  requestData: {},
  displayType: 'calendar',
  scheduleRangeUnit: 'week',
  calendarViewData: {
    excludeNonBusinessHours: false,
    calendarType: 'grid',
  },
  mapViewData: {},
  onUpdate: () => null,
  setPendingEventToCreate: () => null,
  focusedDate: new Date(),
  setFocusedDate: () => null,
  listenerSubscribe: () => null,
  listenerUnsubscribe: () => null,
});

type TListenerType = 'onEventCreated' | 'onEventUpdated' | 'onEventDeleted';

export type TScheduleContextProviderLocationState =
  | {
      eventToCreate?: EventToCreate;
    }
  | undefined;

const ScheduleContextProvider: FC<
  PropsWithChildren<{
    allowEventCreation?: boolean;
    hideCreateEventButton?: boolean;
    createEventBase?: EventToCreate;
    disabledFields?: TScheduledEventFieldsetProps['disabledFields'];
    hiddenFields?: TScheduledEventFieldsetProps['hiddenFields'];
  }>
> = ({
  children,
  createEventBase,
  hideCreateEventButton = false,
  allowEventCreation = true,
  disabledFields,
  hiddenFields,
}) => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { state } = useLocation<TScheduleContextProviderLocationState>();
  const isCreatingOrEditingEvent = useRouteMatch<{
    eventId: string;
  }>({
    path: `${path}/:eventId`,
    exact: true,
  });

  const userRoles = useSelector(getUserRoles);
  const { data: accountUsers } = useGetMyAccountUsers();
  const activeAccountUserIds = useMemo(() => {
    return accountUsers
      ? accountUsers.filter((u) => u.status !== 'Deactivated').map((u) => +u.id)
      : [];
  }, [accountUsers]);

  const filterValidSelectedUserIds = (userIds: (-1 | User['id'])[]) => {
    return userIds.filter(
      (id) => id === -1 || activeAccountUserIds.includes(id) // "unassigned" selected or userId must be for an active account user
    );
  };

  const listeners = useRef<Record<TListenerType, Record<string, () => void>>>({
    onEventCreated: {},
    onEventUpdated: {},
    onEventDeleted: {},
  });

  const listenerSubscribe = useCallback(
    (type: TListenerType, identifier: string, func: () => void) => {
      listeners.current[type][identifier] = func;
    },
    []
  );

  const listenerUnsubscribe = useCallback(
    (type: TListenerType, identifier: string) => {
      delete listeners.current[type][identifier];
    },
    []
  );

  const listenerTrigger = useCallback((type: TListenerType) => {
    Object.values(listeners.current[type]).forEach((func) => func());
  }, []);

  const initialUserAssignments = userHasRequiredPermission(userRoles, [
    ScheduledEventsPermissions.GET_EVENTS,
  ])
    ? filterValidSelectedUserIds(
        localStorage
          .getItem(calendarFilterAssignedUserIdsStorageKey)
          ?.split(',')
          .map((x) => (x === 'unassigned' ? -1 : +x))
          .filter((x) => !!x) || []
      )
    : [];

  const initialExcludeNonBusinessHours: boolean =
    localStorage.getItem(calendarHideNonBusinessHoursStorageKey) === 'true';

  const initialCalendarType: CalendarType =
    localStorage.getItem(calendarViewStorageKey) === 'userResource' &&
    userHasRequiredPermission(userRoles, [
      ScheduledEventsPermissions.GET_EVENTS,
      ScheduledEventsPermissions.GET_MY_DEAL_EVENTS,
    ])
      ? 'userResource'
      : 'grid';

  const initialDisplayType: DisplayType =
    localStorage.getItem(displayTypeStorageKey) === 'map' &&
    userHasRequiredPermission(userRoles, [DispatchPermissions.GET_EVENTS])
      ? 'map'
      : 'calendar';

  const initialRangeUnit: ScheduleRangeUnit = localStorage.getItem(
    rangeUnitStorageKey
  )?.length
    ? (localStorage.getItem(rangeUnitStorageKey) as ScheduleRangeUnit)
    : 'week';

  const dispatch = useDispatch();
  const isMountedRef = useRef(false);

  const [hasSetInitialAssignedUsers, setHasSetInitialAssignedUsers] =
    useState<boolean>(!!accountUsers?.length);
  const [focusedDate, setFocusedDate] = useState(new Date());
  const [scheduledEvents, setScheduledEvents] = useState<ScheduledEvent[]>();
  const [pendingEventToCreate, setPendingEventToCreate] =
    useState<EventToCreate>();
  const calendarRef = useRef<FullCalendar>(null);
  const [scheduleRangeUnit, setScheduleRangeUnit] =
    useState<ScheduleRangeUnit>(initialRangeUnit);
  const [displayType, setDisplayType] =
    useState<DisplayType>(initialDisplayType);
  const [requestData, setRequestData] = useState<
    ScheduleContextType['requestData']
  >({
    assignedUserIds: initialUserAssignments,
  });
  const [calendarViewData, setCalendarViewdata] = useState<
    ScheduleContextType['calendarViewData']
  >({
    excludeNonBusinessHours: initialExcludeNonBusinessHours,
    calendarType: initialCalendarType,
  });
  const [mapViewData, setMapViewData] = useState<
    ScheduleContextType['mapViewData']
  >({
    location: undefined,
  });

  const getListedScheduledEvents = () => {
    dispatch(
      requestGetListedScheduledEvents(
        {
          pageNumber: 1,
          start: requestData.startAt?.toISOString(),
          end: requestData.endAt?.toISOString(),
          userAssignments: requestData.assignedUserIds?.map((x) =>
            x === -1 ? 'unassigned' : x
          ),
        },
        (scheduledEvents) => {
          if (isMountedRef.current) {
            setScheduledEvents(scheduledEvents);
          }
        }
      )
    );
  };

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    getListedScheduledEvents();
  }, [requestData]);

  useEffect(() => {
    if (!hasSetInitialAssignedUsers && accountUsers?.length) {
      // on page reload, accountUsers will not be initially loaded so we cannot filter active users in assignedUserIds
      // this will set request data with initialUsersAssignments in this case, after accounts users exist
      setRequestData({ assignedUserIds: initialUserAssignments });
      setHasSetInitialAssignedUsers(true);
    }
  }, [hasSetInitialAssignedUsers, accountUsers]);

  const onUpdate = ({
    selectedUserIds,
    startAt,
    endAt,
    scheduleRangeUnit,
    displayType,
    excludeNonBusinessHours,
    calendarType,
    location,
  }: onUpdateProps) => {
    const requestDataUpdates: Partial<ScheduleContextType['requestData']> = {};

    if (isNotNullish(selectedUserIds)) {
      // Filter out any users that are not active
      const activeSelectedUserIds = filterValidSelectedUserIds(selectedUserIds);
      requestDataUpdates.assignedUserIds = activeSelectedUserIds;
      localStorage.setItem(
        calendarFilterAssignedUserIdsStorageKey,
        activeSelectedUserIds.join(',')
      );
    }

    if (isNotNullish(startAt)) {
      requestDataUpdates.startAt = startAt;
    }

    if (isNotNullish(endAt)) {
      requestDataUpdates.endAt = endAt;
    }

    setRequestData((x) => ({ ...x, ...requestDataUpdates }));

    if (isNotNullish(scheduleRangeUnit)) {
      setScheduleRangeUnit(scheduleRangeUnit);
      localStorage.setItem(rangeUnitStorageKey, scheduleRangeUnit);
    }

    if (isNotNullish(displayType)) {
      setDisplayType(displayType);
      localStorage.setItem(displayTypeStorageKey, displayType);
    }

    const calendarViewDataUpdates: Partial<
      ScheduleContextType['calendarViewData']
    > = {};

    if (isNotNullish(excludeNonBusinessHours)) {
      calendarViewDataUpdates.excludeNonBusinessHours = excludeNonBusinessHours;
      localStorage.setItem(
        calendarHideNonBusinessHoursStorageKey,
        `${excludeNonBusinessHours}`
      );
    }

    if (isNotNullish(calendarType)) {
      calendarViewDataUpdates.calendarType = calendarType;
      localStorage.setItem(calendarViewStorageKey, calendarType);
    }

    setCalendarViewdata((x) => ({ ...x, ...calendarViewDataUpdates }));

    if (!!location || location === null) {
      setMapViewData({ location: location || undefined });
    }
  };

  const beginAt = useMemo(() => {
    let beginAt: Date | undefined;

    if (focusedDate) {
      const startAt = moment(focusedDate).startOf(
        RangeUnitMomentMap[scheduleRangeUnit]
      );
      if (startAt.toDate() > new Date()) {
        beginAt = startAt.set({ hour: 8, minute: 0, second: 0 }).toDate();
      }
    }

    return beginAt;
  }, [scheduleRangeUnit, focusedDate]);

  return (
    <ScheduleContext.Provider
      value={{
        requestData: requestData,
        displayType,
        scheduleRangeUnit,
        calendarViewData,
        mapViewData,
        onUpdate,
        scheduledEvents,
        hideCreateEventButton,
        calendarRef,
        pendingEventToCreate,
        setPendingEventToCreate: (eventToCreate) => {
          if (eventToCreate === undefined) {
            setPendingEventToCreate(undefined);
          } else {
            setPendingEventToCreate({ ...createEventBase, ...eventToCreate });
          }
        },
        focusedDate,
        setFocusedDate,
        listenerSubscribe,
        listenerUnsubscribe,
      }}
    >
      {children}
      {allowEventCreation && (
        <PermissionGuard
          renderIfHasPermissions={[
            ScheduledEventsPermissions.ADD_EVENT,
            ScheduledEventsPermissions.ADD_MY_EVENT,
            ScheduledEventsPermissions.ADD_MY_DEAL_EVENT,
          ]}
        >
          <CreateScheduledEventModal
            onClose={() => {
              history.push(url);
            }}
            isOpen={
              !!isCreatingOrEditingEvent &&
              isCreatingOrEditingEvent.params.eventId === 'new'
            }
            initialEventData={{
              beginAt,
              ...createEventBase,
              ...state?.eventToCreate,
              ...(calendarViewData.calendarType === 'grid'
                ? { userAssignments: requestData.assignedUserIds }
                : {}),
            }}
            onSuccess={(createdScheduledEventId) => {
              getListedScheduledEvents();
              listenerTrigger('onEventCreated');
              history.push(`${url}/${createdScheduledEventId}`);
            }}
            disabledFields={
              !disabledFields &&
              (createEventBase?.dealId || state?.eventToCreate?.dealId)
                ? ['dealId']
                : disabledFields || []
            }
            hiddenFields={hiddenFields}
          />
        </PermissionGuard>
      )}
      <ScheduledEventReadDrawer
        onUpdateTasksSuccess={getListedScheduledEvents}
        onUpdateScheduledEventSuccess={() => {
          getListedScheduledEvents();
          listenerTrigger('onEventUpdated');
        }}
        onDeleteScheduledEventSuccess={() => {
          getListedScheduledEvents();
          listenerTrigger('onEventDeleted');
        }}
        scheduledEventId={
          isCreatingOrEditingEvent
            ? parseInt(isCreatingOrEditingEvent.params.eventId)
            : undefined
        }
        isOpen={
          !!isCreatingOrEditingEvent &&
          isCreatingOrEditingEvent.params.eventId !== 'new'
        }
        onClose={() => {
          history.replace(url);
        }}
      />
    </ScheduleContext.Provider>
  );
};

export default ScheduleContextProvider;
