import { ArchivableEntity } from './archivableEntityTypes';
import { Contact } from './contactTypes';
import { Customer } from './customerTypes';
import { SortDirection } from './listViewTypes';
import { Address } from './locationTypes';
import { TaggableEntity } from './tagTypes';
import { User } from './userTypes';

export type TContactToNotify = {
  id: number;
  name?: Contact['name'];
  emailAddress?: Contact['emailAddress'];
  telephoneNumber?: Contact['telephoneNumber'];
  type: 'contact' | 'customerContact';
  description?: Contact['description'];
};

export interface ScheduledEvent extends TaggableEntity, ArchivableEntity {
  id: number;
  accountId: number;
  beginAt: Date;
  endAt: Date;
  allDay: boolean;
  type: ScheduledEventType;
  name: string;
  description: string;
  dealId: number | null;
  taskIds: number[];
  noteIds: number[];
  userAssignments: number[];
  includeCustomerInformation: boolean;
  location?: ScheduledEventLocation;
  uploadIds: number[];
  customerIds?: number[];
  pipelineStage?: string | null;
  taskStatus: ScheduledEventTaskStatus;
  createdByUserId?: User['id'];
  colour?: {
    [key in ScheduledEventColourCoding]: {
      text?: string;
      background?: string;
    };
  };
  dealReference?: string;
  customerName?: Customer['name'];
  createdAt: Date;
  updatedAt?: Date;
  contactsToNotify: TContactToNotify[];
}

export type BaseScheduledEvent = Omit<
  ScheduledEvent,
  | 'taskIds'
  | 'userAssignments'
  | 'location'
  | 'uploadIds'
  | 'customerIds'
  | 'noteIds'
>;

export interface ScheduledEventLocation {
  id?: number; // is an addressId but indicates if location is new or not
  address: Address;
  contacts: Contact[];
}

export interface ScheduledEventPayload extends ScheduledEvent {
  isDisabled?: boolean;
  userAssignment?: number;
}

export enum ScheduledEventType {}

export enum ScheduledEventStatus {
  CURRENT = 'current',
  UPCOMING = 'upcoming',
  PAST = 'past',
}

export type ScheduledEventTaskStatus =
  | 'No tasks'
  | 'Completed'
  | 'Partially completed'
  | 'Not started';

export enum SortBy {
  START_AT = 'startAt',
  END_AT = 'endAt',
}
export interface GetListedScheduledEventsRequestData {
  start?: string;
  end?: string;
  statuses?: ScheduledEventStatus[];
  userAssignments?: (number | 'unassigned')[];
  pageSize?: number;
  pageNumber?: number;
  sortDirection?: SortDirection;
  sortBy?: SortBy;
  customerId?: number;
  dealId?: number;
}

export enum ScheduledEventColourCoding {
  PIPELINE = 'pipeline',
  TASK_STATUS = 'task status',
}

export type EventContactRole =
  | 'accessContact'
  | 'primaryContact'
  | 'contactToNotify';

export type SimpleEventContactWithRoles = {
  id: number;
  name?: Contact['name'];
  emailAddress?: Contact['emailAddress'];
  telephoneNumber?: Contact['telephoneNumber'];
  type: 'contact' | 'customerContact';
  description?: Contact['description'];
  roles: EventContactRole[];
};
