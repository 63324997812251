import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { UpdateUserInput } from '@/gql/graphql';
import { useMutation } from '@tanstack/react-query';

const UpdateUserMutation = graphql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`);

export const useUpdateUser = () => {
  return useMutation({
    mutationFn: (update: UpdateUserInput) => {
      return gqlClient.request(UpdateUserMutation, {
        input: update,
      });
    },
  });
};
