import {
  Invoice,
  InvoiceLine,
  ReadableInvoiceStatus,
} from '@payaca/types/invoiceTypes';
import { JobLineItem, JobLineItemBase } from '@payaca/types/jobContentTypes';
import { BadgeColourVariant } from '@payaca/types/plBadge';
import { StatusBadgeState } from './statusBadgeHelper';

interface JobLineItemInvoiceLineSummary {
  jobLineItem: Pick<
    JobLineItemBase,
    'id' | 'isMultipleChoice' | 'isOptional' | 'isSelected' | 'total'
  >;
  invoiceLines: InvoiceLine[];
  totalValue: number;
  invoicedValue: number;
  uninvoicedValue: number;
}

export const getReadableInvoiceStatusStyle = (
  status: ReadableInvoiceStatus
) => {
  switch (status) {
    case ReadableInvoiceStatus.VOID:
      return {
        colour: '#ffffff',
        backgroundColour: '#919191',
      };
    case ReadableInvoiceStatus.PAID:
      return {
        colour: '#263E59',
        backgroundColour: '#75E582',
      };
    case ReadableInvoiceStatus.PARTIALLY_PAID:
      return {
        backgroundColour: '#FABB00',
        colour: '#263E59',
      };
    case ReadableInvoiceStatus.SENT:
      return {
        backgroundColour: '#DBE5F0',
        colour: '#263E59',
      };
    case ReadableInvoiceStatus.DRAFT:
    default:
      return {
        backgroundColour: '#607387',
        colour: '#ffffff',
      };
  }
};

export const getInvoicePLBadgeState = (
  status?: ReadableInvoiceStatus
): BadgeColourVariant => {
  switch (status) {
    case ReadableInvoiceStatus.PAID:
      return 'teal';
    case ReadableInvoiceStatus.PARTIALLY_PAID:
      return 'yellow';
    case ReadableInvoiceStatus.VOID:
      return 'red';
    case ReadableInvoiceStatus.DRAFT:
      return 'black';
    default:
      return 'blue';
  }
};

export const getInvoicedValue = (invoices: Invoice[]) => {
  const unvoidedInvoices = invoices.filter((x) => !x.voidedAt);
  return unvoidedInvoices.reduce((acc, invoice) => acc + invoice.totalValue, 0);
};

export const getSentInvoicedValue = (invoices: Invoice[]) => {
  const unvoidedSentInvoices = invoices.filter(
    (x) => !x.voidedAt && !!x.sentAt
  );
  return unvoidedSentInvoices.reduce(
    (acc, invoice) => acc + invoice.totalValue,
    0
  );
};

export const getDueDatePassedInvoicedValue = (invoices: Invoice[]) => {
  const unvoidedSentOverdueInvoices = invoices.filter(
    (x) => !x.voidedAt && !!x.sentAt && x.dueAt && x.dueAt < new Date()
  );
  return unvoidedSentOverdueInvoices.reduce(
    (acc, invoice) => acc + invoice.totalValue,
    0
  );
};

export const getInvoiceLinesValue = (
  invoiceLines: Pick<InvoiceLine, 'value'>[]
): number => {
  return invoiceLines.reduce((acc, invoiceLine) => acc + invoiceLine.value, 0);
};

export const getJobLineItemInvoiceLineSummaries = (
  jobLineItems: Pick<
    JobLineItemBase,
    'id' | 'isMultipleChoice' | 'isOptional' | 'isSelected' | 'total'
  >[],
  invoiceLines: InvoiceLine[]
): JobLineItemInvoiceLineSummary[] => {
  const jobLineItemInvoiceLineSummaries: JobLineItemInvoiceLineSummary[] = [];

  const selectedJobLineItems = jobLineItems.filter(
    (jobLineItem) =>
      !(jobLineItem.isMultipleChoice || jobLineItem.isOptional) ||
      jobLineItem.isSelected
  );

  selectedJobLineItems.forEach((jobLineItem) => {
    const jobLineItemInvoiceLines = invoiceLines.filter(
      (invoiceLine: InvoiceLine) => invoiceLine.jobLineItemId == jobLineItem.id
    );

    const invoicedValue = getInvoiceLinesValue(jobLineItemInvoiceLines);

    jobLineItemInvoiceLineSummaries.push({
      jobLineItem: jobLineItem,
      invoiceLines: invoiceLines.filter(
        (x) => x.jobLineItemId == jobLineItem.id
      ),
      totalValue: jobLineItem.total,
      invoicedValue: invoicedValue,
      uninvoicedValue: jobLineItem.total - invoicedValue,
    });
  });

  return jobLineItemInvoiceLineSummaries;
};

export const getInvoiceLinesDataForInvoiceValue = (
  invoiceValue: number,
  jobLineItems: Pick<
    JobLineItemBase,
    'id' | 'isMultipleChoice' | 'isOptional' | 'isSelected' | 'total'
  >[],
  existingInvoiceLines: InvoiceLine[]
) => {
  const jobLineItemInvoiceLineSummaries = getJobLineItemInvoiceLineSummaries(
    jobLineItems,
    existingInvoiceLines
  );

  const allItemsAreInvoiced = jobLineItemInvoiceLineSummaries.every(
    (x) => !x.uninvoicedValue && !!x.invoiceLines.length
  );

  // if no items have any uninvoiced value, there is no need to return any invoice lines.
  if (allItemsAreInvoiced) return [];

  const negUninvoicedValue = jobLineItemInvoiceLineSummaries.reduce(
    (acc, a) => acc + Math.min(a.uninvoicedValue, 0),
    0
  );

  const posUninvoicedValue = jobLineItemInvoiceLineSummaries.reduce(
    (acc, a) => acc + Math.max(a.uninvoicedValue, 0),
    0
  );

  const uninvoicedValue = posUninvoicedValue + negUninvoicedValue;

  // if invoiceValue is equal to uninvoicedValue, return invoiceLine for invoiceValue of each line
  if (invoiceValue === uninvoicedValue) {
    const hasUninvoicedValueJobLineItemInvoiceLineSummaries =
      jobLineItemInvoiceLineSummaries.filter(
        (x) =>
          x.uninvoicedValue !== 0 || (!x.totalValue && !x.invoiceLines.length)
      );

    return hasUninvoicedValueJobLineItemInvoiceLineSummaries.map(
      (jobLineItemInvoiceLineSummary) => {
        return {
          value: jobLineItemInvoiceLineSummary.uninvoicedValue,
          jobLineItemId: jobLineItemInvoiceLineSummary.jobLineItem.id,
        };
      }
    );
  }

  const negPosEquivalentValue = Math.min(
    posUninvoicedValue,
    Math.abs(negUninvoicedValue)
  );

  let posInvoiceValue;
  let negInvoiceValue;

  if (negPosEquivalentValue > invoiceValue) {
    const absInvoiceValue = negPosEquivalentValue - invoiceValue;
    posInvoiceValue = Math.max(0, invoiceValue) + absInvoiceValue;
    negInvoiceValue = Math.min(0, invoiceValue) - absInvoiceValue;
  } else {
    posInvoiceValue = Math.max(0, invoiceValue) + negPosEquivalentValue;
    negInvoiceValue = Math.min(0, invoiceValue) - negPosEquivalentValue;
  }

  const posInvoiceLines =
    getInvoiceLinesForSignedValueFromJobLineItemInvoiceLineSummaries(
      posInvoiceValue,
      jobLineItemInvoiceLineSummaries
    );

  const negInvoiceLines =
    getInvoiceLinesForSignedValueFromJobLineItemInvoiceLineSummaries(
      negInvoiceValue,
      jobLineItemInvoiceLineSummaries
    );

  // Include invoice lines for zero-value jlis
  const zeroInvoiceLines = jobLineItemInvoiceLineSummaries
    .filter((x) => x.totalValue === 0 && !x.invoiceLines.length)
    .map((x) => {
      return {
        value: 0,
        jobLineItemId: x.jobLineItem.id,
      };
    });

  // If creating a zero-value invoice, add all lines to it for zero value
  if (posInvoiceValue === 0 && negInvoiceValue === 0) {
    zeroInvoiceLines.push(
      ...jobLineItemInvoiceLineSummaries
        .filter((x) => x.uninvoicedValue > 0 && x.totalValue > 0)
        .map((x) => {
          return {
            value: 0,
            jobLineItemId: x.jobLineItem.id,
          };
        })
    );
  }

  return [...posInvoiceLines, ...negInvoiceLines, ...zeroInvoiceLines].sort(
    (a, b) => a.jobLineItemId - b.jobLineItemId
  );
};

const getInvoiceLinesForSignedValueFromJobLineItemInvoiceLineSummaries = (
  signedInvoiceValue: number,
  jobLineItemInvoiceLineSummaries: JobLineItemInvoiceLineSummary[]
) => {
  if (!signedInvoiceValue) return [];

  const isPositive = signedInvoiceValue > 0;

  const filteredJobLineItemInvoiceLineSummaries =
    jobLineItemInvoiceLineSummaries.filter((x) => {
      return isPositive ? x.uninvoicedValue > 0 : x.uninvoicedValue < 0;
    });

  const uninvoicedValue = filteredJobLineItemInvoiceLineSummaries.reduce(
    (acc, a) => acc + a.uninvoicedValue,
    0
  );

  const invoicePercentage = signedInvoiceValue / uninvoicedValue;

  const invoiceLines: {
    value: number;
    jobLineItemId: number;
  }[] = [];
  let invoiceValueAccumulator = 0;

  filteredJobLineItemInvoiceLineSummaries.forEach(
    (jobLineItemInvoiceLineSummary, index) => {
      const isLastItem =
        index === filteredJobLineItemInvoiceLineSummaries.length - 1;

      let invoiceLineValue = 0;

      if (isLastItem) {
        invoiceLineValue = signedInvoiceValue - invoiceValueAccumulator;
      } else {
        invoiceLineValue = Math.round(
          jobLineItemInvoiceLineSummary.uninvoicedValue * invoicePercentage
        );
      }

      invoiceValueAccumulator += invoiceLineValue;
      invoiceLines.push({
        value: invoiceLineValue,
        jobLineItemId: jobLineItemInvoiceLineSummary.jobLineItem.id,
      });
    }
  );

  return invoiceLines;
};

export const getInvoiceTotalBreakdown = (
  invoiceLines: InvoiceLine[],
  jobLineItems: JobLineItem[]
) => {
  let numOfItems = 0;
  let subtotal = 0;
  let cisTotal = 0;
  let vatTotal = 0;
  let total = 0;
  let discount = 0;

  invoiceLines.forEach((invoiceLine) => {
    const jobLineItem = jobLineItems.find(
      (x) => x.id === invoiceLine.jobLineItemId
    );

    if (jobLineItem) {
      if (
        [
          'jobLineItemPercentage',
          'subTotalValue',
          'cisValue',
          'taxValue',
        ].every((p) => Object.prototype.hasOwnProperty.call(invoiceLine, p))
      ) {
        const invoiceLinePercentage = invoiceLine.jobLineItemPercentage / 100;

        numOfItems += 1;
        discount += Math.round(
          (jobLineItem.discountAmount || 0) * invoiceLinePercentage
        );
        subtotal += invoiceLine.subTotalValue;
        cisTotal += invoiceLine.cisValue;
        vatTotal += invoiceLine.taxValue;
        total += Math.round(invoiceLine.value);
      } else {
        // If proto invoice (straight to invoice). Calculate values based on job line item total,
        // as invoice line calculated values are not yet created
        const invoiceLinePercentage =
          !jobLineItem.total && !invoiceLine.value
            ? 1
            : invoiceLine.value / jobLineItem.total;

        numOfItems += 1;
        discount += Math.round(
          (jobLineItem.discountAmount || 0) * invoiceLinePercentage
        );
        subtotal += Math.round(
          jobLineItem.subtotalIncMarkupDiscount * invoiceLinePercentage
        );
        cisTotal += Math.round(
          (jobLineItem.cisTotal || 0) * invoiceLinePercentage
        );
        vatTotal += Math.round(jobLineItem.vatTotal * invoiceLinePercentage);
        total += Math.round(invoiceLine.value);
      }
    }
  });

  return {
    numOfItems,
    subtotal: subtotal,
    discount: discount,
    cisTotal: cisTotal,
    vatTotal: vatTotal,
    total: total,
  };
};

export const isAnyUninvoicedValue = (
  jobLineItems: JobLineItemBase[],
  existingInvoiceLines: InvoiceLine[]
) => {
  const jobLineItemInvoiceLineSummaries = getJobLineItemInvoiceLineSummaries(
    jobLineItems,
    existingInvoiceLines
  );

  const isAnyUninvoicedValue = jobLineItemInvoiceLineSummaries.some(
    (x) =>
      x.uninvoicedValue !== 0 || (x.totalValue === 0 && !x.invoiceLines.length)
  );

  return isAnyUninvoicedValue;
};

export const isAnyOverinvoicedJobLineItem = (
  jobLineItems: JobLineItemBase[],
  existingInvoiceLines: InvoiceLine[]
) => {
  const jobLineItemInvoiceLineSummaries = getJobLineItemInvoiceLineSummaries(
    jobLineItems,
    existingInvoiceLines
  );

  const isAnyOverinvoiced = jobLineItemInvoiceLineSummaries.some(
    (jobLineItemInvoiceLineSummary) => {
      const isOverInvoiced =
        (jobLineItemInvoiceLineSummary.totalValue === 0 &&
          jobLineItemInvoiceLineSummary.uninvoicedValue !== 0) ||
        jobLineItemInvoiceLineSummary.totalValue *
          jobLineItemInvoiceLineSummary.uninvoicedValue <
          0;

      return isOverInvoiced;
    }
  );

  return isAnyOverinvoiced;
};

export const isSentInvoice = (invoice: Invoice): boolean => !!invoice.sentAt;

export const getInvoiceStatusBadgeState = (
  readableStatus: ReadableInvoiceStatus
) => {
  switch (readableStatus) {
    case ReadableInvoiceStatus.PAID:
      return StatusBadgeState.GREEN_SUCCESS;
    case ReadableInvoiceStatus.PARTIALLY_PAID:
      return StatusBadgeState.AMBER_PENDING;
    case ReadableInvoiceStatus.VOID:
      return StatusBadgeState.RED_FAILURE;
    case ReadableInvoiceStatus.DRAFT:
      return StatusBadgeState.GREY_INITIAL;
    default:
      return StatusBadgeState.BLUE_NEUTRAL;
  }
};

export const invoiceLineItemGroupPositionIndexSort = (a: any, b: any) => {
  // sort by jli group positionIndex if exists
  if (
    a.jobLineItem?.jobLineItemGroupIndex !== undefined &&
    b.jobLineItem?.jobLineItemGroupIndex !== undefined
  ) {
    return a.jobLineItem?.jobLineItemGroupIndex <
      b.jobLineItem?.jobLineItemGroupIndex
      ? -1
      : 1;
  }
  // if no group position index sorting, don't implement any additional sorting
  return 0;
};

export const invoiceLineItemPositionIndexSort = (a: any, b: any) => {
  // sort by jli positionIndex if exists
  if (
    a.jobLineItem?.positionIndex !== undefined &&
    b.jobLineItem?.positionIndex !== undefined
  ) {
    return a.jobLineItem?.positionIndex < b.jobLineItem?.positionIndex ? -1 : 1;
  } else {
    return a.jobLineItemId < b.jobLineItemId ? -1 : 1;
  }
};

export const calculateInvoiceLineValues = (
  value: number,
  jobLineItem: Pick<
    JobLineItemBase,
    | 'id'
    | 'quantity'
    | 'total'
    | 'subtotalIncMarkupDiscount'
    | 'vatTotal'
    | 'cisTotal'
  >
) => {
  const quantity = Number(jobLineItem.quantity);

  const percentage = jobLineItem.total === 0 ? 0 : value / jobLineItem.total;

  return {
    value,
    jobLineItemQuantity: (quantity * percentage).toFixed(6),
    jobLineItemPercentage: Math.round(percentage * 100),
    subTotalValue: Math.round(
      jobLineItem.subtotalIncMarkupDiscount * percentage
    ),
    taxValue: Math.round(jobLineItem.vatTotal * percentage),
    cisValue: Math.round((jobLineItem.cisTotal || 0) * percentage),
  };
};
