import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  GetProjectMaterialsListMaterialsQuery,
  PaginationInput,
} from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import { useQuery } from '@tanstack/react-query';
import projectKeys from './keyFactory';

const GetProjectMaterialsListMaterials = graphql(`
  query GetProjectMaterialsListMaterials(
    $projectId: Int!
    $pagination: PaginationInput!
    $input: Void
  ) {
    project(id: $projectId) {
      __typename
      materialsList {
        id
        materialsListMaterials(input: $input, pagination: $pagination) {
          limit
          offset
          totalCount
          items {
            id
            quantity
            quantityOrdered: quantity(statuses: [PURCHASED, ORDERED])
            material {
              name
              internalId
              suppliedBy {
                price {
                  unitPriceExcTax {
                    currency {
                      code
                    }
                    value
                  }
                }
              }
            }
            materialPurchaseIntents {
              id
            }
            materialPurchaseRecords {
              price {
                totalPriceExcTax {
                  currency {
                    code
                  }
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`);

export type MaterialsListMaterials = NonNullable<
  GetProjectMaterialsListMaterialsQuery['project']['materialsList']
>['materialsListMaterials'];

const useGetProjectMaterialsListMaterials = (
  projectId: number,
  pagination?: PaginationInput
) => {
  const { data, ...rest } = useQuery({
    queryKey: projectKeys.materialsListMaterials(projectId, pagination),
    queryFn: () => {
      return gqlClient.request(GetProjectMaterialsListMaterials, {
        projectId: projectId,
        input: null,
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
      });
    },
  });

  return {
    materialsListMaterials: data?.project.materialsList?.materialsListMaterials,
    data,
    ...rest,
  };
};

export default useGetProjectMaterialsListMaterials;
