export const DATE_TODAY_FORMAT = 'iii dd MMM yyyy';
export const DATE_DAY_MONTH_FORMAT = 'dd MMM';
export const DATE_SHORT_FORMAT = "dd MMM ''yy";
export const DATE_MID_FORMAT = 'dd MMM yyyy';
export const DATE_SHORT_READABLE_FORMAT = 'do MMMM yyyy';
export const DATE_TIMESTAMP_FORMAT = 'dd MMM yyyy HH:mm:ss';
export const DATE_TIMESTAMP_SHORT_FORMAT = 'iii d MMM HH:mm';
export const DATE_TIMESTAMP_DAY_MONTH_FORMAT = 'd MMM HH:mm';
export const DATE_SHORT_DELIMITED_FORMAT = 'dd/MM/yyyy';

export const CA_DATE_TODAY_FORMAT = 'iii MMM dd yyyy';
export const CA_DATE_DAY_MONTH_FORMAT = 'MMM dd';
export const CA_DATE_SHORT_FORMAT = "MMM dd ''yy";
export const CA_DATE_MID_FORMAT = 'MMM dd yyyy';
export const CA_DATE_SHORT_READABLE_FORMAT = 'MMMM do yyyy';
export const CA_DATE_TIMESTAMP_FORMAT = 'MMM dd yyyy HH:mm:ss';
export const CA_DATE_TIMESTAMP_SHORT_FORMAT = 'iii MMM d HH:mm';
export const CA_DATE_TIMESTAMP_DAY_MONTH_FORMAT = 'MMM d HH:mm';
export const CA_DATE_SHORT_DELIMITED_FORMAT = 'MM/dd/yyyy';

export const US_DATE_TODAY_FORMAT = 'iii MMM dd yyyy';
export const US_DATE_DAY_MONTH_FORMAT = 'MMM dd';
export const US_DATE_SHORT_FORMAT = "MMM dd ''yy";
export const US_DATE_MID_FORMAT = 'MMM dd yyyy';
export const US_DATE_SHORT_READABLE_FORMAT = 'MMMM do yyyy';
export const US_DATE_TIMESTAMP_FORMAT = 'MMM dd yyyy HH:mm:ss';
export const US_DATE_TIMESTAMP_SHORT_FORMAT = 'iii MMM d HH:mm';
export const US_DATE_TIMESTAMP_DAY_MONTH_FORMAT = 'MMM d HH:mm';
export const US_DATE_SHORT_DELIMITED_FORMAT = 'MM/dd/yyyy';

// TODO: These are copy-pasta'd from the generic format strings.
// 1. Update them for correctness for South Africa;
// 2. Factor out all this stuff in favour of Intl.DateTimeFormat;
export const ZA_DATE_TODAY_FORMAT = 'iii dd MMM yyyy';
export const ZA_DATE_DAY_MONTH_FORMAT = 'dd MMM';
export const ZA_DATE_SHORT_FORMAT = "dd MMM ''yy";
export const ZA_DATE_MID_FORMAT = 'dd MMM yyyy';
export const ZA_DATE_SHORT_READABLE_FORMAT = 'do MMMM yyyy';
export const ZA_DATE_TIMESTAMP_FORMAT = 'dd MMM yyyy HH:mm:ss';
export const ZA_DATE_TIMESTAMP_SHORT_FORMAT = 'iii d MMM HH:mm';
export const ZA_DATE_TIMESTAMP_DAY_MONTH_FORMAT = 'iii d MMM HH:mm';
export const ZA_DATE_SHORT_DELIMITED_FORMAT = 'dd/MM/yyyy';
