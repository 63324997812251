import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import noteKeys from '@/api/queries/notes/keyFactory';
import { graphql } from '@/gql';
import { GetNoteQuery } from '@/gql/graphql';

const GetNote = graphql(`
  query GetNote($noteId: ID!) {
    note(id: $noteId) {
      id
      body
      project {
        id
      }
      event {
        id
      }
    }
  }
`);

const useGetNote = (
  notePublicId?: string,
  options?: UseQueryOptions<GetNoteQuery>
) => {
  return useQuery({
    queryKey: noteKeys.note(notePublicId!),
    enabled: !!notePublicId,
    queryFn: () => {
      return gqlClient.request(GetNote, {
        noteId: notePublicId!,
      });
    },
    ...options,
  });
};

export default useGetNote;
