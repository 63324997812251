import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import projectKeys from '@/api/queries/project/keyFactory';
import { graphql } from '@/gql';
import { GetScheduledEventsForProjectInput } from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';

const GetProjectEvents = graphql(`
  query GetProjectEvents(
    $projectId: Int!
    $pagination: PaginationInput!
    $input: GetScheduledEventsForProjectInput
  ) {
    project(id: $projectId) {
      events(pagination: $pagination, input: $input) {
        items {
          id
          name
          description
          beginAt
          endAt
        }
      }
    }
  }
`);

const useGetProjectEvents = (
  projectId?: number,
  input?: GetScheduledEventsForProjectInput
) => {
  const { data, ...rest } = useQuery({
    enabled: !!projectId,
    queryKey: projectKeys.events(projectId!, input),
    queryFn: () => {
      return gqlClient.request(GetProjectEvents, {
        projectId: projectId!,
        pagination: PAGINATION_ARG_DEFAULTS,
        input,
      });
    },
  });

  return { events: data?.project.events, data, ...rest };
};

export default useGetProjectEvents;
