import { FC, useState } from 'react';

import { useSelector } from '@/api/state';
import { usePrevious } from '@/utils/customHooks';
import {
  ErrorMessage,
  SuccessMessage,
} from '@payaca/components/feedbackMessage/FeedbackMessage';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import { useChangeProposal } from '@payaca/store/hooks/appState';
import { clearJobContentUpdateResults } from '@payaca/store/jobContent/jobContentActions';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import './EditChangeProposalSaveFeedback.sass';

type Props = {
  changeProposalId: number;
};
const EditChangeProposalSaveFeedback: FC<Props> = ({
  changeProposalId,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [showSavedMessageTimeout, setShowSavedMessageTimeout] = useState<any>();

  const changeProposal = useChangeProposal(changeProposalId);

  useEffect(() => {
    return () => {
      dispatch(clearJobContentUpdateResults());
    };
  }, []);

  const persistAttempt = useSelector((state) => {
    return (
      state.proposals.changeProposals &&
      state.proposals.changeProposals[changeProposalId]?.persistAttempt
    );
  });

  const jobLineItemUpdateResults = useSelector((state) => {
    if (!state.jobContent.jobLineItemUpdateResults || !changeProposal)
      return false;
    const jobLineItemIds = changeProposal.jobLineItemIds;

    return jobLineItemIds
      .map(
        (jobLineItemId: number) =>
          state.jobContent.jobLineItemUpdateResults[jobLineItemId]
      )
      .filter((x) => !!x);
  });

  const hasAnyJobLineItemUpdateErrors = useMemo(() => {
    if (!jobLineItemUpdateResults) return false;
    return jobLineItemUpdateResults.some(
      (x) => x?.isUpdatedSuccessfully === false
    );
  }, [jobLineItemUpdateResults]);

  const hasPersistError = useMemo(() => {
    return !!persistAttempt?.failedAt;
  }, [persistAttempt]);

  const hasAnyErrors = useMemo(() => {
    return hasPersistError || hasAnyJobLineItemUpdateErrors;
  }, [hasPersistError, hasAnyJobLineItemUpdateErrors]);

  const changeProposalUpdateErrorMessage = useMemo(() => {
    if (hasAnyErrors) {
      return "Something went wrong - some of your changes haven't been saved";
    }
  }, [hasAnyErrors]);

  const isModifyingJobLineItems: boolean = useSelector((state) => {
    return (
      state.jobContent.isUpdatingJobLineItem ||
      state.jobContent.isDeletingJobLineItem ||
      state.jobContent.isCreatingJobLineItem ||
      state.jobContent.isAddingAttachmentToJobLineItem ||
      state.jobContent.isRemovingAttachmentFromJobLineItem
    );
  });

  const isProcessing = useMemo(() => {
    return persistAttempt?.isInProgress || isModifyingJobLineItems;
  }, [persistAttempt?.isInProgress, isModifyingJobLineItems]);

  const previousIsProcessing = usePrevious(isProcessing);

  useEffect(() => {
    if (!isProcessing && previousIsProcessing && !hasAnyErrors) {
      setShowSavedMessage(true);
      setShowSavedMessageTimeout(
        setTimeout(() => {
          setShowSavedMessage(false);
        }, 1000)
      );
    }
  }, [isProcessing]);

  return (
    <div className="edit-change-proposal-save-feedback flex-container flex-center">
      <div
        className={`saved-message-container ${
          showSavedMessage && !isProcessing ? '' : ' hidden'
        }`}
      >
        <SuccessMessage message={'Progress saved!'} />
      </div>

      <div
        className={`error-message-container ${
          changeProposalUpdateErrorMessage ? '' : ' hidden'
        }`}
      >
        {changeProposalUpdateErrorMessage && (
          <ErrorMessage message={changeProposalUpdateErrorMessage} />
        )}
      </div>
      {isProcessing && (
        <div className="processing-container">
          <MiniLoader />
        </div>
      )}
    </div>
  );
};

export default EditChangeProposalSaveFeedback;
