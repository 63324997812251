import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@/api/state';
import { EditPipelineControl } from '@/ui/components/editPipelineControl/EditPipelineControl';
import {
  requestGetAccountPipelines,
  requestUpdatePipeline,
} from '@payaca/store/pipelines/pipelineActions';
import {
  Pipeline,
  PipelineStage,
  PipelineUpdates,
} from '@payaca/types/pipelineTypes';
import { useHistory } from 'react-router';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

const ManagePipelinePage: FunctionComponent<{ pipelineId: Pipeline['id'] }> = ({
  pipelineId,
}) => {
  const dispatch = useDispatch();
  const pipelines = useSelector((state) => state.pipelines.pipelines);
  const pipeline = pipelines.find((p) => p.id === pipelineId);
  const history = useHistory();

  const [editPipelineData, setEditPipelineData] = useState<
    | (Omit<Pipeline, 'stages'> & { stages: Partial<PipelineStage>[] })
    | undefined
  >();

  useEffect(() => {
    if (pipeline) {
      setEditPipelineData({
        ...pipeline,
        // we'll need to clone each stage - otherwise the reference will be preserved between edit/real data
        stages: pipeline.stages.map((s) => ({ ...s })),
      });
    }
  }, [pipeline]);

  useEffect(() => {
    dispatch(requestGetAccountPipelines());
  }, []);

  const onEditPipeline = useCallback(
    (action: PipelineUpdates, value: any, stageIndex?: number) => {
      if (!editPipelineData) return;

      if (typeof stageIndex !== 'number') {
        return setEditPipelineData({
          ...editPipelineData,
          [action]: value,
        });
      }
      let stages = [...editPipelineData.stages];
      switch (action) {
        case PipelineUpdates.ADD_COLUMN:
          stages = [
            ...stages.slice(0, stageIndex + 1),
            {
              title: 'New column',
            },
            ...stages.slice(stageIndex + 1),
          ];
          break;
        case PipelineUpdates.MOVE_COLUMN: {
          const stageToMove = stages[stageIndex + 1];
          const stagesWithoutMovingColumn = stages.filter(
            (s, i) => i !== stageIndex + 1
          );
          stages = [
            ...stagesWithoutMovingColumn.slice(0, stageIndex),
            stageToMove,
            ...stagesWithoutMovingColumn.slice(stageIndex),
          ];
          break;
        }
        case PipelineUpdates.DELETE_COLUMN:
          delete stages[stageIndex];
          stages = [...stages.filter((i) => !!i)];
          // have we just deleted the last remaining pipeline column? We'll add in a new empty one if so
          if (stages?.length === 0) {
            stages.push({
              title: 'New column',
            });
          }
          break;
        default:
          stages[stageIndex][action] = value;
          break;
      }
      setEditPipelineData({
        ...editPipelineData,
        stages,
      });
    },
    [editPipelineData]
  );

  const returnToDeals = () => history.push('/deals');

  if (!pipeline) {
    return null;
  }

  return (
    <AuthenticatedPageWrapper
      previousPageNavigationConfig={{
        route: '/deals',
      }}
    >
      {!!editPipelineData && (
        <div className="h-[calc(100vh-70px)]">
          <EditPipelineControl
            onEditPipeline={onEditPipeline}
            editPipelineData={editPipelineData}
            onPipelineChangesSaved={() => {
              if (editPipelineData) {
                dispatch(
                  requestUpdatePipeline(editPipelineData.id, editPipelineData)
                );
                returnToDeals();
              }
            }}
            onPipelineChangesDiscarded={returnToDeals}
            onPipelineDeleted={returnToDeals}
          />
        </div>
      )}
    </AuthenticatedPageWrapper>
  );
};

export default ManagePipelinePage;
