import { isEqual } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as dealsActions from '@payaca/store/deals/dealsActions';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as jobsActions from '@payaca/store/jobs/jobsActions';
import * as lineItemGroupActions from '@payaca/store/lineItemGroups/lineItemGroupsActions';

import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { useMutation } from '@tanstack/react-query';
import BodyWithSidePanelContentWrapper from '../bodyWithSidePanelContentWrapper/BodyWithSidePanelContentWrapper';
import JobAdditionalNotesControl from '../editJob/JobAdditionalNotesControl';
import JobCustomerControl from '../editJob/JobCustomerControl';
import JobIntroControl from '../editJob/JobIntroControl';
import JobPromoAttachmentsControl from '../editJob/JobPromoAttachmentsControl';
import JobReferenceControl from '../editJob/JobReferenceControl';
import EditJobPreviewButton from '../editJobPreviewButton/EditJobPreviewButton';
import EditJobSaveFeedback from '../editJobSaveFeedback/EditJobSaveFeedback';
import InvoiceLineItemsControl from '../invoiceLineItemsControl/InvoiceLineItemsControl';
import JobLineItemGroupsControl from '../jobLineItemGroupsControl/JobLineItemGroupsControl';
import JobSidePanel from '../jobSidePanel/JobSidePanel';

import { Deal } from '@payaca/types/dealTypes';
import { UpdateJobContentRequestData } from '@payaca/types/jobContentRequestTypes';
import { JobContent } from '@payaca/types/jobContentTypes';
import {
  PartialUpdateJobRequestData,
  UpdateJobRequestData,
} from '@payaca/types/jobRequestTypes';
import { Job } from '@payaca/types/jobTypesV2';

import { isInvoice } from '@/helpers/jobHelper';
import { canSetCustomerForDeal } from '@payaca/helpers/dealHelper';
import {
  getInitialUpdateJobContentRequestDataFromBaseJobContent,
  getInitialUpdateJobRequestDataFromBaseJob,
  getJobRequiresUpdate,
} from '@payaca/helpers/jobHelperV2';

import {
  getDealByJobId,
  getJob,
  getJobContent,
  getJobsByDealId,
} from '@/utils/stateAccessors';

import { gqlClient } from '@/api/graphql-client';
import { useSelector } from '@/api/state';
import { graphql } from '@/gql';
import { UpdateProjectInput } from '@/gql/graphql';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import PaymentScheduleSummary from '@payaca/components/paymentScheduleSummary/PaymentScheduleSummary';
import PLButton from '@payaca/components/plButton/Button';
import Card from '@payaca/components/plCard/Card';
import Modal from '@payaca/components/plModal/Modal';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import {
  getPaymentScheduleFieldValidator,
  isPaymentScheduleValid,
} from '@payaca/helpers/paymentScheduleHelper';
import { useDeal } from '@payaca/store/hooks/appState';
import { PaymentSchedule } from '@payaca/types/payment-schedule';
import CreatePaymentScheduleControl, {
  PaymentScheduleInput,
} from '../createEditProposalTemplateControl/CreatePaymentScheduleControl';
import EditJobSection from '../editJob/EditJobSection';
import './EditJobControl.sass';

type Props = {
  jobId: number;
  proceedToPreview: () => void;
};

const EditJobControl: FC<Props> = ({
  jobId,
  proceedToPreview,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const job: Job | undefined = useSelector((state) => getJob(state, jobId));

  const jobContent: JobContent | undefined = useSelector((state) => {
    if (!job?.jobContentId) return;
    return getJobContent(state, job.jobContentId);
  });

  const deal: Deal | undefined = useSelector((state) =>
    getDealByJobId(state, jobId)
  );

  const isUpdatingJob: boolean = useSelector((state) => {
    return state.jobsStore.isUpdatingJob;
  });

  const isFetchingJob: boolean = useSelector((state) => {
    return state.jobsStore.jobs && state.jobsStore.jobs[jobId]?.isFetching;
  });

  const isUpdatingJobContent: boolean = useSelector((state) => {
    return state.jobContent.isUpdatingJobContent;
  });

  const isFetchingJobContent: boolean = useSelector((state) => {
    if (!job?.jobContentId) return false;
    return (
      state.jobContent.jobContents &&
      state.jobContent.jobContents[job.jobContentId]?.isFetching
    );
  });

  const onSelectCustomer = useCallback(
    (customerId?: number) => {
      if (!job) return;
      dispatch(dealsActions.requestSetDealCustomer(job.dealId, customerId));
    },
    [job?.dealId, dispatch]
  );

  const jobIsInvoice = useMemo(() => {
    if (!job) return false;
    return isInvoice(job.status);
  }, [job?.status]);

  const onJobUpdateSuccess = useCallback(() => {
    dispatch(jobsActions.requestGetJob(jobId));
  }, [dispatch, jobId]);

  const onJobContentUpdateSuccess = useCallback(() => {
    if (!job?.jobContentId) return;
    dispatch(jobContentActions.requestGetJobContent(job.jobContentId));
    dispatch(
      jobContentActions.requestGetJobLineItemsForJobContent(job.jobContentId)
    );
    dispatch(dealsActions.requestGetDeal(job?.dealId));
  }, [dispatch, job?.jobContentId]);

  const jobsBelongingToDeal: Job[] = useSelector((state) =>
    deal ? getJobsByDealId(state, deal.id) : []
  );

  const canSelectCustomer: boolean = useMemo(() => {
    return canSetCustomerForDeal(jobsBelongingToDeal);
  }, [jobsBelongingToDeal]);

  const [updateJobRequestData, setUpdateJobRequestData] =
    useState<UpdateJobRequestData>();

  useEffect(() => {
    if (job) {
      setUpdateJobRequestData(getInitialUpdateJobRequestDataFromBaseJob(job));
    }
  }, [job]);

  useEffect(() => {
    dispatch(lineItemGroupActions.requestGetLineItemGroups());
  }, []);

  const onSelectContact = useCallback(
    (contactId: number) => {
      if (!updateJobRequestData) return;

      const newUpdateJobRequestData = {
        ...updateJobRequestData,
        contactId: contactId,
      };

      setUpdateJobRequestData(newUpdateJobRequestData);
      dispatch(
        jobsActions.requestUpdateJob(newUpdateJobRequestData, () =>
          dispatch(jobsActions.requestGetJob(updateJobRequestData.jobId))
        )
      );
    },
    [updateJobRequestData, dispatch]
  );

  const requiresUpdateJob = useCallback(
    (updateJobRequestData: PartialUpdateJobRequestData) => {
      if (!job) return false;
      if (isFetchingJob || isUpdatingJob) return true;

      return getJobRequiresUpdate(job, updateJobRequestData);
    },
    [isFetchingJob, isUpdatingJob, job]
  );

  const requiresUpdateJobContent = useCallback(
    (formState: { [key: string]: any }) => {
      if (!jobContent) return false;
      if (isFetchingJobContent || isUpdatingJobContent) return true;
      return !isEqual(
        formState.jobContent,
        getInitialUpdateJobContentRequestDataFromBaseJobContent(jobContent)
      );
    },
    [isFetchingJobContent, isUpdatingJobContent, jobContent]
  );

  const updateJob = useCallback(
    (changes: { [key: string]: any }, onUpdateSuccess?: () => void) => {
      const updateJobRequestData = changes.job;
      const updateJobContentRequestData =
        changes.jobContent as UpdateJobContentRequestData;

      if (updateJobRequestData && requiresUpdateJob(updateJobRequestData)) {
        dispatch(
          jobsActions.partialUpdateJob.request({
            jobId: jobId,
            data: updateJobRequestData,
            callback: () => {
              onJobUpdateSuccess();
              onUpdateSuccess?.();
            },
          })
        );
      }
      if (
        updateJobContentRequestData &&
        updateJobContentRequestData.jobContentId
      ) {
        if (requiresUpdateJobContent(changes)) {
          dispatch(
            jobContentActions.requestUpdateJobContent(
              updateJobContentRequestData,
              () => {
                onJobContentUpdateSuccess();
                onUpdateSuccess?.();
              }
            )
          );
        }
      }
    },
    [
      onJobContentUpdateSuccess,
      onJobUpdateSuccess,
      requiresUpdateJobContent,
      updateJobRequestData,
    ]
  );

  const sidebarContent = useMemo(() => {
    return (
      <>
        <div className="static-content-container">
          <EditJobPreviewButton
            jobId={jobId}
            proceedToPreview={proceedToPreview}
          />
        </div>
        <EditJobSaveFeedback jobId={jobId} />
        <div className="sidebar-scrollable-content-container">
          <JobSidePanel jobId={jobId} />
        </div>
      </>
    );
  }, [jobId, proceedToPreview]);

  return (
    <ResponsiveViewWrapper
      className="edit-job-control"
      downBreakpointSm={700}
      downBreakpointXs={450}
    >
      <BodyWithSidePanelContentWrapper sidebarContent={sidebarContent}>
        {/* Title section */}
        <div className="job-reference-feedback-wrapper">
          <JobReferenceControl jobId={jobId} updateJob={updateJob} />
        </div>

        {/* Customer */}
        <JobCustomerControl
          canSelectCustomer={canSelectCustomer}
          customerId={deal?.customerId}
          onSelectCustomer={onSelectCustomer}
          jobContactId={job?.contactId || null}
          onSelectContact={onSelectContact}
          onPersistCustomerSuccess={() => {
            dispatch(jobsActions.requestGetJob(jobId));
          }}
        />

        {/* Intro - Job description */}
        <JobIntroControl jobId={jobId} updateJob={updateJob} />

        {/* Items */}
        {!!job &&
          (jobIsInvoice ? (
            <InvoiceLineItemsControl
              jobContentId={job.jobContentId}
              jobIsInvoice={jobIsInvoice}
            />
          ) : (
            <JobLineItemGroupsControl
              updateJobRequestData={updateJobRequestData}
              updateJob={updateJob}
              jobContentId={job.jobContentId}
              jobIsInvoice={jobIsInvoice}
            />
          ))}

        {/* Additional notes - Customer notes */}
        <JobAdditionalNotesControl jobId={jobId} updateJob={updateJob} />

        {deal?.paymentSchedule && job?.dealId && (
          <ProjectPaymentScheduleControl
            dealId={job.dealId}
            projectValue={jobContent?.total || 0}
          />
        )}

        {/* Promo attachments */}
        <JobPromoAttachmentsControl jobId={jobId} />
      </BodyWithSidePanelContentWrapper>
    </ResponsiveViewWrapper>
  );
};
export default EditJobControl;

const ProjectPaymentScheduleControl: FC<{
  dealId: Deal['id'];
  projectValue: number;
}> = ({ dealId, projectValue }) => {
  const deal = useDeal(dealId);
  const [showModifyPaymentScheduleModal, setShowModifyPaymentScheduleModal] =
    useState(false);
  const dispatch = useDispatch();

  if (!deal?.paymentSchedule) return null;

  return (
    <>
      <EditJobSection
        title={
          <div className="flex flex-row items-baseline justify-between">
            <h2>Payment Schedule</h2>
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={() => setShowModifyPaymentScheduleModal(true)}
            >
              Edit Payment Schedule
            </Button>
          </div>
        }
      >
        <Card>
          <PaymentScheduleSummary
            paymentSchedule={deal?.paymentSchedule}
            projectValue={projectValue}
          />
        </Card>
      </EditJobSection>
      <Modal
        size="md"
        isOpen={showModifyPaymentScheduleModal}
        onClose={() => setShowModifyPaymentScheduleModal(false)}
        title={'Edit Payment Schedule'}
      >
        <EditProjectPaymentScheduleModalBody
          projectId={dealId}
          paymentSchedule={deal.paymentSchedule}
          onSuccess={() => {
            setShowModifyPaymentScheduleModal(false);
            dispatch(dealsActions.requestGetDeal(dealId));
          }}
        />
      </Modal>
    </>
  );
};

const UpdateProjectPaymentScheduleMutation = graphql(`
  mutation UpdateProjectMutation($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
    }
  }
`);

const EditProjectPaymentScheduleModalBody: FC<{
  projectId: Deal['id'];
  paymentSchedule: PaymentSchedule;
  onSuccess?: () => void;
}> = ({ projectId, paymentSchedule, onSuccess }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const { mutateAsync: mutateUpdateProjectPaymentSchedule } = useMutation({
    mutationFn: (input: UpdateProjectInput) => {
      return gqlClient.request(UpdateProjectPaymentScheduleMutation, { input });
    },
  });

  const initialFormState = useMemo(() => {
    return { paymentSchedule };
  }, []);

  const fieldValidators = useMemo(() => {
    return {
      paymentSchedule: [getPaymentScheduleFieldValidator()],
    };
  }, []);

  const onSubmit = useCallback(
    async (schedule: PaymentScheduleInput) => {
      const validStages = (schedule?.stages || [])
        .map((x) => ({ ...x, percentageDue: +x.percentageDue }))
        .filter((stage) => stage.description && stage.percentageDue);

      const cleanedPaymentSchedule = {
        explainer: validStages?.length ? schedule?.explainer : null,
        stages: validStages,
      };
      const paymentScheduleToSave = isPaymentScheduleValid(
        cleanedPaymentSchedule
      )
        ? cleanedPaymentSchedule
        : null;

      setIsProcessing(true);
      await mutateUpdateProjectPaymentSchedule({
        projectId: projectId.toString(),
        paymentSchedule: paymentScheduleToSave,
      })
        .then((res) => {
          onSuccess?.();
          setIsProcessing(false);
        })
        .catch((err) => {
          setIsProcessing(false);
        });
    },
    [projectId]
  );

  return (
    <ValidatedForm<{ paymentSchedule: PaymentScheduleInput }>
      fieldValidators={fieldValidators}
      initialFormState={initialFormState}
      renderFormContents={(
        isValid,
        formState,
        validationState,
        touchedState,
        onFieldChange
      ) => {
        return (
          <>
            <Modal.Body>
              <CreatePaymentScheduleControl
                paymentSchedule={formState.paymentSchedule}
                onChange={(paymentSchedule) =>
                  onFieldChange({ paymentSchedule: paymentSchedule })
                }
                fieldValidationState={
                  isValid
                    ? undefined
                    : {
                        isValid: false,
                        validationMessages:
                          validationState.paymentSchedule?.errors,
                      }
                }
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.Footer.Actions>
                <PLButton
                  isProcessing={isProcessing}
                  disabled={!isValid}
                  onClick={() => onSubmit(formState.paymentSchedule)}
                >
                  Save
                </PLButton>
              </Modal.Footer.Actions>
            </Modal.Footer>
          </>
        );
      }}
    />
  );
};
