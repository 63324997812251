import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UpdateCustomerContactMutation as _UpdateCustomerContactMutation,
  UpdateCustomerContactInput,
} from '@/gql/graphql';

const UpdateCustomerContactMutation = graphql(`
  mutation UpdateCustomerContact($input: UpdateCustomerContactInput!) {
    updateCustomerContact(input: $input) {
      id
      description
      email
      name
      phone
    }
  }
`);

const useUpdateCustomerContact = (
  options?: UseMutationOptions<
    _UpdateCustomerContactMutation,
    unknown,
    UpdateCustomerContactInput
  >
) => {
  return useMutation({
    mutationFn: (update: UpdateCustomerContactInput) => {
      return gqlClient.request(UpdateCustomerContactMutation, {
        input: update,
      });
    },
    ...options,
  });
};

export default useUpdateCustomerContact;
