import { FC, useState } from 'react';

import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import EditJobSection from '../editJob/EditJobSection';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import {
  useAcceptedJobLineItemsForDeal,
  useChangeProposal,
} from '@payaca/store/hooks/appState';
import AcceptedJobLineItemEditControl from '../acceptedJobLineItemEditControl/AcceptedJobLineItemEditControl';
import DealMaterialsListDrawer from '../dealMaterialsListDrawer/DealMaterialsListDrawer';
import './ChangeProposalAcceptedJobLineItemsControl.sass';

type Props = {
  changeProposalId: number;
};

const ChangeProposalAcceptedJobLineItemsControl: FC<Props> = ({
  changeProposalId,
}: Props): JSX.Element | null => {
  const changeProposal = useChangeProposal(changeProposalId);

  const acceptedJobLineItems = useAcceptedJobLineItemsForDeal(
    changeProposal?.dealId
  );

  const [showMaterialsListDrawer, setShowMaterialsListDrawer] = useState(false);

  return (
    <EditJobSection
      title={
        <div className="title-wrapper">
          <h2>Items</h2>
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => setShowMaterialsListDrawer(true)}
          >
            Show Materials required
          </Button>
        </div>
      }
      className="change-proposal-accepted-job-line-items-control"
    >
      <ContentPanel hasBoxShadow={false}>
        <>
          {acceptedJobLineItems
            ?.filter((x) => !x.amendmentParentId)
            ?.map((jobLineItem, index) => {
              return (
                <AcceptedJobLineItemEditControl
                  key={`job-line-item-control-${jobLineItem.id}`}
                  jobLineItemId={jobLineItem.id}
                  changeProposalId={changeProposalId}
                />
              );
            })}
        </>
      </ContentPanel>
      {changeProposal?.dealId && (
        <DealMaterialsListDrawer
          isOpen={showMaterialsListDrawer}
          onClose={() => setShowMaterialsListDrawer(false)}
          dealId={changeProposal.dealId}
        />
      )}
    </EditJobSection>
  );
};
export default ChangeProposalAcceptedJobLineItemsControl;
