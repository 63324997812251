import {
  PublicHydratedTimelog,
  PublicTimelogType,
  Timelog,
  TimelogLinkedEntity,
  TimelogType,
} from '@payaca/types/timelogs';
import { DeclareAsyncAction, UnpackAsyncAction } from '../types';

import {
  AssigneeGroupedListedTimelogs,
  GetAssigneeGroupedListedTimelogsQueryParams,
  GetListedTimelogsQueryParams,
  ListedTimelogsPage,
} from '@payaca/types/listedTimelogTypes';

export interface State {
  timelogsToCreate: {
    timelog: CreateTimelogData;
    identifier: string;
  }[];
  activeTimelog?: ActiveTimelogData;
}

export enum ActionType {
  GET_LISTED_TIMELOGS_PAGE_REQUEST = 'timelogs.getListedTimelogsPage:request',
  GET_LISTED_TIMELOGS_PAGE_SUCCESS = 'timelogs.getListedTimelogsPage:success',

  GET_ASSIGNEE_GROUPED_LISTED_TIMELOGS_REQUEST = 'timelogs.getAssigneeGroupedListedTimelogs:request',

  CREATE_TIMELOG_REQUEST = 'timelogs.createTimelog:request',
  CREATE_TIMELOG_SUCCESS = 'timelogs.createTimelog:success',
  CREATE_TIMELOG_FAILURE = 'timelogs.createTimelog:failure',
  BATCH_CREATE_TIMELOGS_REQUEST = 'timelogs.batchCreateTimelogs:request',
  BATCH_CREATE_TIMELOGS_SUCCESS = 'timelogs.batchCreateTimelogs:success',
  BATCH_CREATE_TIMELOGS_FAILURE = 'timelogs.batchCreateTimelogs:failure',
  UPDATE_TIMELOG_REQUEST = 'timelogs.updateTimelog:request',
  ARCHIVE_TIMELOG_REQUEST = 'timelogs.archiveTimelog:request',

  GET_TIMELOG_TYPES_REQUEST = 'timelogs.getTimelogTypes:request',

  CREATE_TIMELOG_TYPE_REQUEST = 'timelogs.createTimelogType:request',
  UPDATE_TIMELOG_TYPE_REQUEST = 'timelogs.updateTimelogType:request',
  ARCHIVE_TIMELOG_TYPE_REQUEST = 'timelogs.archiveTimelogType:request',

  INITIATE_ACTIVE_TIMELOG = 'timelogs.initiateActiveTimelog',
  UPDATE_ACTIVE_TIMELOG = 'timelogs.updateActiveTimelog',
  CLEAR_ACTIVE_TIMELOG = 'timelogs.clearActiveTimelog',
  CLEAR_TIMELOGS_TO_CREATE = 'timelogs.clearTimelogsToCreate',
  ENQUEUE_TIMELOG_TO_CREATE = 'timelogs.enqueueTimelogToCreate',
  DEQUEUE_TIMELOGS_TO_CREATE = 'timelogs.dequeueTimelogToCreate',
  BATCH_CREATE_QUEUED_TIMELOGS_REQUEST = 'timelogs.batchCreateQueuedTimelogs:request',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export type ActiveTimelogData = Omit<
  CreateTimelogData,
  'endTime' | 'assigneePublicId' | 'assigneeType'
> & {
  linkedEntityReadableIdentifier?: string;
};

export type InitiateActiveTimelog = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.INITIATE_ACTIVE_TIMELOG;
      payload: ActiveTimelogData;
    };
  }
>;

export type EnqueueTimelogToCreate = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.ENQUEUE_TIMELOG_TO_CREATE;
      payload: {
        timelog: CreateTimelogData;
      };
    };
  }
>;

export type DequeueTimelogsToCreate = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.DEQUEUE_TIMELOGS_TO_CREATE;
      payload: {
        identifiers: string[];
      };
    };
  }
>;

export type BatchCreateTimelogs = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.BATCH_CREATE_TIMELOGS_REQUEST;
      payload: CreateTimelogData[];
    };
    success: {
      type: ActionType.BATCH_CREATE_TIMELOGS_SUCCESS;
    };
    failure: {
      type: ActionType.BATCH_CREATE_TIMELOGS_FAILURE;
    };
  }
>;

export type UpdateActiveTimelog = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_ACTIVE_TIMELOG;
      payload: Omit<
        ActiveTimelogData,
        | 'endTime'
        | 'entityType'
        | 'entityPublicId'
        | 'linkedEntityReadableIdentifier'
      >;
    };
  }
>;

export type ClearActiveTimelog = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CLEAR_ACTIVE_TIMELOG;
    };
  }
>;

export type ClearTimelogsToCreate = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CLEAR_TIMELOGS_TO_CREATE;
    };
  }
>;

export type BatchCreateQueuedTimelogs = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.BATCH_CREATE_QUEUED_TIMELOGS_REQUEST;
      payload: {
        timelogsToCreate: { timelog: CreateTimelogData; identifier: string }[];
      };
    };
  }
>;

export type GetListedTimelogsPage = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_LISTED_TIMELOGS_PAGE_REQUEST;
      payload: {
        queryParams: GetListedTimelogsQueryParams;
        callback?: (listedTimelogsPage: ListedTimelogsPage) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
    success: {
      type: ActionType.GET_LISTED_TIMELOGS_PAGE_SUCCESS;
      payload: ListedTimelogsPage;
    };
  }
>;

export type GetAssigneeGroupedListedTimelogs = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_ASSIGNEE_GROUPED_LISTED_TIMELOGS_REQUEST;
      payload: {
        queryParams: GetAssigneeGroupedListedTimelogsQueryParams;
        callback?: (
          assigneeGroupedListedTimelogs: AssigneeGroupedListedTimelogs
        ) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type CreateTimelogData = Pick<
  Timelog,
  'startTime' | 'endTime' | 'notes' | 'costPerHour' | 'typePublicId'
> & {
  assigneePublicId?: Timelog['assigneePublicId'];
  assigneeType?: Timelog['assigneeType'];
  primaryLinkedEntity?: Pick<TimelogLinkedEntity, 'entityId' | 'entityType'>;
};

export type CreateTimelog = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_TIMELOG_REQUEST;
      payload: {
        timelog: CreateTimelogData;
        callback?: (timelog: PublicHydratedTimelog) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
    success: {
      type: ActionType.CREATE_TIMELOG_SUCCESS;
      payload: PublicHydratedTimelog;
    };
    failure: {
      type: ActionType.CREATE_TIMELOG_FAILURE;
    };
  }
>;

export type UpdateTimelogData = Pick<
  Timelog,
  | 'startTime'
  | 'endTime'
  | 'notes'
  | 'costPerHour'
  | 'typePublicId'
  | 'assigneePublicId'
  | 'assigneeType'
>;

export type UpdateTimelog = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_TIMELOG_REQUEST;
      payload: {
        timelog: UpdateTimelogData & { publicId: Timelog['publicId'] };
        callback?: (timelog: PublicHydratedTimelog) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type ArchiveTimelog = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.ARCHIVE_TIMELOG_REQUEST;
      payload: {
        timelogPublicId: Timelog['publicId'];
        callback?: () => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type GetTimelogTypes = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_TIMELOG_TYPES_REQUEST;
      payload: {
        callback?: (timelogTypes: PublicTimelogType[]) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type PersistTimelogTypeData = Pick<TimelogType, 'type' | 'description'>;

export type CreateTimelogType = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_TIMELOG_TYPE_REQUEST;
      payload: {
        timelogType: PersistTimelogTypeData;
        callback?: (timelog: PublicTimelogType) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type UpdateTimelogType = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_TIMELOG_TYPE_REQUEST;
      payload: {
        timelogType: PersistTimelogTypeData & {
          publicId: TimelogType['publicId'];
        };
        callback?: (timelog: PublicTimelogType) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type ArchiveTimelogType = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.ARCHIVE_TIMELOG_TYPE_REQUEST;
      payload: {
        timelogTypePublicId: TimelogType['publicId'];
        callback?: () => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type AnyAction =
  | UnpackAsyncAction<CreateTimelog>
  | UnpackAsyncAction<GetTimelogTypes>
  | UnpackAsyncAction<InitiateActiveTimelog>
  | UnpackAsyncAction<UpdateActiveTimelog>
  | UnpackAsyncAction<ClearActiveTimelog>
  | UnpackAsyncAction<EnqueueTimelogToCreate>
  | UnpackAsyncAction<DequeueTimelogsToCreate>;
