import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import projectKeys from '@/api/queries/project/keyFactory';
import { graphql } from '@/gql';
import {
  UpdateMaterialsListMaterialsInput,
  UpdateMaterialsListMaterialsMutation,
} from '@/gql/graphql';

const UPDATE_MATERIALS_LIST_MATERIALS_MUTATION = graphql(`
  mutation UpdateMaterialsListMaterials(
    $input: UpdateMaterialsListMaterialsInput!
  ) {
    updateMaterialsListMaterials(input: $input) {
      id
    }
  }
`);

const useUpdateMaterialsListMaterials = (
  dealId: number,
  options?: UseMutationOptions<
    UpdateMaterialsListMaterialsMutation,
    unknown,
    UpdateMaterialsListMaterialsInput
  >
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UPDATE_MATERIALS_LIST_MATERIALS_MUTATION, {
        input,
      });
    },
    ...options,
    onSettled: async (...args) => {
      void queryClient.invalidateQueries(projectKeys.materialsList(dealId));
      void queryClient.invalidateQueries(projectKeys.profitBreakdown(dealId));
      if (options?.onSettled) await options?.onSettled(...args);
    },
  });
};

export default useUpdateMaterialsListMaterials;
