import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import meKeys from '@/api/queries/me/keyFactory';
import { graphql } from '@/gql';

const GET_MY_CIS_DEDUCTION_RATE = graphql(`
  query GetMyCisDeductionRate {
    me {
      user {
        account {
          cisDeductionRate
        }
      }
    }
  }
`);

const useGetMyCisDeductionRate = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountCisDeductionRate(),
    queryFn: () => {
      return gqlClient.request(GET_MY_CIS_DEDUCTION_RATE);
    },
  });

  return {
    isCisSubcontractor: !!data?.me.user.account.cisDeductionRate,
    cisDeductionRate: data?.me.user.account.cisDeductionRate,
    ...rest,
  };
};

export default useGetMyCisDeductionRate;
