import { useSelector } from '@/api/state';
import { getRegion } from '@/utils/stateAccessors';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';
import SortDirectionControl from '@payaca/components/sortDirectionControl/SortDirectionControl';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { TimelogsPermissions } from '@payaca/permissions/timelogs/timelogs.permissions';
import { getListedTimelogsPage } from '@payaca/store/timelogs/timelogsActions';
import {
  GetListedTimelogsQueryParams,
  ListedTimelogsPage,
  SortBy,
} from '@payaca/types/listedTimelogTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import { TimelogLinkedEntity } from '@payaca/types/timelogs';
import {
  DurationUnit,
  getReadableStringFromDurationString,
} from '@payaca/utilities/timeUtilities';
import moment from 'moment-timezone';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import EmptyState from '../emptyState/EmptyState';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import TimelogCard from '../timelogCard/TimelogCard';
import TimelogFilters from '../timelogFilters/TimelogFilters';
import './ListedTimelogsControl.sass';

const sortByLabelMap = {
  [SortBy.COST]: 'Cost',
  [SortBy.END_TIME]: 'End Time',
  [SortBy.COST_PER_HOUR]: 'Cost/hr',
};

type Props = {
  entity?: Pick<TimelogLinkedEntity, 'entityType' | 'entityId'>;
  disableFilters?: ('periodStart' | 'periodEnd')[];
  triggerRefreshDataAt?: Date;
};

const ListedTimelogsControl: FC<Props> = ({
  entity,
  disableFilters,
  triggerRefreshDataAt,
}): JSX.Element | null => {
  const dispatch = useDispatch();

  const [getListedTimelogsRequestData, setGetListedTimelogsRequestData] =
    useState<GetListedTimelogsQueryParams>({
      pageNumber: 1,
      pageSize: 100,
      sortBy: SortBy.END_TIME,
      sortDirection: SortDirection.DESCENDING,
      periodStart: disableFilters?.includes('periodStart')
        ? undefined
        : moment().startOf('month').toDate(),
      periodEnd: disableFilters?.includes('periodEnd')
        ? undefined
        : moment().endOf('month').toDate(),
      entity: entity,
    });

  const [listedTimelogsPage, setListedTimelogsPage] = useState<
    ListedTimelogsPage | undefined
  >(undefined);

  const [isGettingPage, setIsGettingPage] = useState(false);

  const region = useSelector(getRegion);

  const handleGetListedTimelogsPage = useCallback(
    (queryParams: GetListedTimelogsQueryParams) => {
      setIsGettingPage(true);

      dispatch(
        getListedTimelogsPage.request({
          queryParams: queryParams,
          callback: (page: ListedTimelogsPage) => {
            setListedTimelogsPage(page);
            setIsGettingPage(false);
          },
          onErrorCallback: () => {
            setIsGettingPage(false);
          },
        })
      );
    },
    []
  );

  useEffect(() => {
    handleGetListedTimelogsPage(getListedTimelogsRequestData);
  }, [getListedTimelogsRequestData]);

  useEffect(() => {
    if (triggerRefreshDataAt && triggerRefreshDataAt <= new Date()) {
      handleGetListedTimelogsPage(getListedTimelogsRequestData);
    }
  }, [triggerRefreshDataAt]);

  return (
    <div className="listed-timelogs-control">
      <div className="filters-container">
        <TimelogFilters
          disableFilters={disableFilters}
          onChange={(value) => {
            setGetListedTimelogsRequestData((d) => ({
              ...d,
              ...value,
            }));
          }}
          appliedFilters={{
            periodStart: getListedTimelogsRequestData.periodStart,
            periodEnd: getListedTimelogsRequestData.periodEnd,
            assignees: getListedTimelogsRequestData.assignees,
            typePublicIds: getListedTimelogsRequestData.typePublicIds,
          }}
        />
        <div className="sort-controls-container">
          <DropdownField
            name="sortBy"
            value={getListedTimelogsRequestData.sortBy}
            options={Object.values(SortBy).map((sortBy) => ({
              label: sortByLabelMap[sortBy],
              value: sortBy,
            }))}
            label="Sort by"
            onChange={(value) => {
              setGetListedTimelogsRequestData((d) => ({
                ...d,
                sortBy: value.sortBy,
              }));
            }}
          />

          <SortDirectionControl
            selectedSortDirection={getListedTimelogsRequestData.sortDirection}
            onChange={(sortDirection) =>
              setGetListedTimelogsRequestData((d) => ({ ...d, sortDirection }))
            }
          />
        </div>
      </div>
      {listedTimelogsPage && (
        <>
          <div className="filtered-period-totals">
            <h2>Totals</h2>

            {listedTimelogsPage.totalDuration && (
              <div>
                <span className="label">Time spent:</span>{' '}
                <span className="value">
                  {getReadableStringFromDurationString(
                    listedTimelogsPage.totalDuration,
                    { units: [DurationUnit.HOURS, DurationUnit.MINUTES] }
                  )}
                </span>
              </div>
            )}
            <PermissionGuard
              renderIfHasPermissions={[
                TimelogsPermissions.GET_TIMELOG_COST_PER_HOUR,
              ]}
            >
              <div>
                <span className="label">Cost:</span>{' '}
                <span className="value">
                  {currencyPrice(
                    listedTimelogsPage.totalCalculatedCost,
                    region
                  )}
                </span>
              </div>
            </PermissionGuard>
          </div>
          <div className="listed-timelogs-container">
            {listedTimelogsPage.items.map((timelog, i) => {
              return (
                <TimelogCard
                  timelog={timelog}
                  key={i}
                  onUpdateTimelogSuccess={() =>
                    handleGetListedTimelogsPage(getListedTimelogsRequestData)
                  }
                  onArchiveTimelogSuccess={() =>
                    handleGetListedTimelogsPage(getListedTimelogsRequestData)
                  }
                />
              );
            })}
          </div>
        </>
      )}
      {!isGettingPage && !listedTimelogsPage?.items?.length && (
        <EmptyState configName={'timelogs'} arrowIndication={false} />
      )}
      {listedTimelogsPage && (
        <PaginationControl
          pageNumber={listedTimelogsPage.pageNumber}
          pageSize={listedTimelogsPage.pageSize}
          totalItemCount={listedTimelogsPage.totalItemCount}
          onSelectPage={(pageNumber) => {
            setGetListedTimelogsRequestData((requestData) => ({
              ...requestData,
              pageNumber,
            }));
          }}
          allowPageSizeSelection={false}
        />
      )}
    </div>
  );
};

export default ListedTimelogsControl;
