import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useEffect, useReducer, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { z } from 'zod';

import useCreateLineItem from '@/api/mutations/lineItems/useCreateLineItem';
import useDeleteLineItem from '@/api/mutations/lineItems/useDeleteLineItem';
import useUpdateLineItem from '@/api/mutations/lineItems/useUpdateLineItem';
import useUpdateLineItemMaterials from '@/api/mutations/lineItems/useUpdateLineItemMaterials';
import lineItemKeys from '@/api/queries/lineItems/keyFactory';
import useGetLineItem from '@/api/queries/lineItems/useGetLineItem';
import useGetMyAccountAccountingIntegrations from '@/api/queries/me/useGetMyAccountAccountingIntegrations';
import useGetMyTaxRates from '@/api/queries/me/useGetMyTaxRates';
import { UpdateLineItemInput } from '@/gql/graphql';
import LineItemPriceCard, {
  LINE_ITEM_PRICE_FORM_SCHEMA,
  TLineItemPriceCardFormState,
} from '@/ui/components/addEditItemSidebar/fields/LineItemPriceCard';
import LineItemTotalsCard, {
  LINE_ITEM_TAXES_FORM_SCHEMA,
  TLineItemTaxesFormState,
} from '@/ui/components/addEditItemSidebar/fields/LineItemTotalsCard';
import ReferenceField, {
  LINE_ITEM_REFERENCE_FORM_SCHEMA,
  TLineItemReferenceFormState,
} from '@/ui/components/addEditItemSidebar/fields/ReferenceField';
import ThumbnailField, {
  LINE_ITEM_THUMBNAIL_FORM_SCHEMA,
  TLineItemThumbnailFormState,
} from '@/ui/components/addEditItemSidebar/fields/ThumbnailField';
import TitleDescriptionField, {
  LINE_ITEM_TITLE_DESCRIPTION_FORM_SCHEMA,
  TLineItemTitleDescriptionFormState,
} from '@/ui/components/addEditItemSidebar/fields/TitleDescriptionField';
import LinkedMaterialsList from '@/ui/components/addEditItemSidebar/LinkedMaterialsList';
import {
  calculateMarkupChange,
  calculatePredictedMaterialCosts,
} from '@/ui/components/addEditItemSidebar/utils';
import BuildMaterialsListSidebar, {
  MaterialState,
} from '@/ui/components/buildMaterialsListSidebar/BuildMaterialsListSidebar';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import Conditional from '@payaca/components/conditional/Conditional';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Field from '@payaca/components/plField/Field';
import Modal from '@payaca/components/plModal/Modal';
import Select from '@payaca/components/plSelect/Select';
import Sidebar, {
  Props as SidebarProps,
} from '@payaca/components/plSidebar/Sidebar';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { clstx } from '@payaca/components/utils';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { LineItemsPermissions } from '@payaca/permissions/lineItems/line-items.permissions';
import * as lineItemActions from '@payaca/store/lineItems/lineItemsActions';
import { useDispatch } from 'react-redux';
import { useScroll } from 'react-use';

export type TFormState = {
  materials: Pick<
    MaterialState,
    'id' | 'name' | 'image' | 'suppliedBy' | 'quantity'
  >[];
  accountingIntegrationSyncData?: {
    xero?: {
      accountIdentifier?: string;
    };
    quickbooks?: {
      accountIdentifier?: string;
    };
  };
} & TLineItemReferenceFormState &
  TLineItemPriceCardFormState &
  TLineItemTitleDescriptionFormState &
  TLineItemThumbnailFormState &
  TLineItemTaxesFormState;

const FORM_SCHEMA = z
  .object({
    materials: z.array(z.any()),
    accountingIntegrationSyncData: z
      .object({
        xero: z.object({ accountIdentifier: z.string() }).optional(),
        quickbooks: z.object({ accountIdentifier: z.string() }).optional(),
      })
      .optional(),
  })
  .merge(LINE_ITEM_REFERENCE_FORM_SCHEMA)
  .merge(LINE_ITEM_PRICE_FORM_SCHEMA)
  .merge(LINE_ITEM_TITLE_DESCRIPTION_FORM_SCHEMA)
  .merge(LINE_ITEM_THUMBNAIL_FORM_SCHEMA)
  .merge(LINE_ITEM_TAXES_FORM_SCHEMA);

type ModalState = {
  modal?: 'CONFIRM_DELETE';
};

type ModalAction = { type: 'OPEN_CONFIRM_DELETE' } | { type: 'RESET' };

const modalStateReducer = (state: ModalState, action: ModalAction) => {
  switch (action.type) {
    case 'OPEN_CONFIRM_DELETE':
      return { modal: 'CONFIRM_DELETE' as const };
    case 'RESET':
      return {};
    default:
      return state;
  }
};

const AddEditItemSidebarContent: FC<{
  lineItemId?: string;
  isLinkMaterialsSidebarOpen: boolean;
  setIsLinkMaterialSidebarOpen: (state: boolean) => void;
  setIsDirty: (isDirty: boolean) => void;
  onSaveSuccess?: () => void;
  onDeleteSuccess?: () => void;
}> = (props) => {
  const {
    lineItemId,
    isLinkMaterialsSidebarOpen,
    setIsLinkMaterialSidebarOpen,
    onSaveSuccess,
    onDeleteSuccess,
    setIsDirty,
  } = props;

  const isEditLineItem = !!lineItemId;

  /**
   * State
   */
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  useScroll({ current: scrollRef });
  const [isSaving, setIsSaving] = useState(false);
  const [modalState, modalStateDispatch] = useReducer(modalStateReducer, {});

  const { defaultTaxRate } = useGetMyTaxRates();

  const formMethods = useForm<TFormState>({
    resolver: zodResolver(FORM_SCHEMA),
    defaultValues: {
      reference: '',
      description: lineItemId ? undefined : '',
      taxRateId: defaultTaxRate?.id || '',
      cis: 'no',
      markupTotals: {
        markupType: 'price',
        profitPercentage: 0,
        profitAmount: 0,
        price: 0,
      },
      materials: [],
      autoUpdatePrice: true,
      hasDiscount: false,
    },
  });

  // Annoying to have to cascade this up.
  // But we need to reset the form state when the user closes the sidebar
  // and the best way to do that is for the `useForm` hook to unmount and
  // mount each time the sidebar opens
  // (meaning the useForm hook can't be in the parent component)
  useEffect(() => {
    setIsDirty(formMethods.formState.isDirty);
  }, [formMethods.formState.isDirty]);

  /**
   * Queries
   */
  const queryClient = useQueryClient();
  const { data: originalLineItemData, isFetching } = useGetLineItem(
    {
      lineItemId,
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      // Set the initial form state, when the line item data is fetched
      // We don't want cached data to be used within the form state.
      // If the user made a change to the **cached data** in the form state,
      // their data would be lost when the re-fetch data is returned.
      onSuccess: (data) => {
        const predictedMaterialCosts = calculatePredictedMaterialCosts(
          data.lineItem.materials.map((material) => ({
            materialQuantity: material.materialQuantity,
            // The backend orders the suppliers by 'preferred supplier' or lowest price
            price:
              material.material.suppliedBy[0]?.price.unitPriceExcTax.value || 0,
          }))
        );

        let markupTotals: TFormState['markupTotals'];
        if (typeof data.lineItem.price?.markupPercentage === 'number') {
          const profitPercentage =
            Math.round(
              (data.lineItem.price.markupPercentage /
                (100 + data.lineItem.price.markupPercentage)) *
                100 *
                100000
            ) / 100000;

          markupTotals = {
            markupType: 'profitPercentage',
            ...calculateMarkupChange(
              'profitPercentage',
              profitPercentage,
              predictedMaterialCosts
            ),
          };
        } else if (data.lineItem.price?.markupAmount) {
          markupTotals = {
            markupType: 'profitAmount',
            ...calculateMarkupChange(
              'profitAmount',
              data.lineItem.price.markupAmount.value,
              predictedMaterialCosts
            ),
          };
        } else {
          markupTotals = {
            markupType: 'price',
            ...calculateMarkupChange(
              'price',
              data.lineItem.price?.unitPriceExcTax.value || 0,
              predictedMaterialCosts
            ),
          };
        }

        let accountingIntegrationSyncData: TFormState['accountingIntegrationSyncData'];
        if (data.lineItem.accountingIntegrationSyncData) {
          accountingIntegrationSyncData = {};
          if (
            data.lineItem.accountingIntegrationSyncData.xero?.accountIdentifier
          ) {
            accountingIntegrationSyncData.xero = {
              accountIdentifier:
                data.lineItem.accountingIntegrationSyncData.xero
                  .accountIdentifier,
            };
          }
          if (
            data.lineItem.accountingIntegrationSyncData.quickbooks
              ?.accountIdentifier
          ) {
            accountingIntegrationSyncData.quickbooks = {
              accountIdentifier:
                data.lineItem.accountingIntegrationSyncData.quickbooks
                  .accountIdentifier,
            };
          }
        }

        formMethods.reset({
          thumbnail: data.lineItem.thumbnailUrl
            ? { preview: data.lineItem.thumbnailUrl }
            : undefined,
          reference: data.lineItem.name || '',
          description: data.lineItem.description || '',
          taxRateId: data.lineItem.price?.taxRate?.id || '',
          cis:
            typeof data.lineItem.price?.cisDeductionRate === 'number'
              ? 'yes'
              : 'no',
          markupTotals,
          predictedMaterialCost: predictedMaterialCosts,
          materials: data.lineItem.materials.map((i) => ({
            id: i.material.id,
            name: i.material.name,
            image: i.material.image,
            suppliedBy: i.material.suppliedBy,
            quantity: i.materialQuantity,
          })),
          autoUpdatePrice: true,
          hasDiscount: false,
          accountingIntegrationSyncData,
        });
      },
    }
  );

  /**
   * Mutations
   */
  const { mutateAsync: createLineItemMutation } = useCreateLineItem();
  const { mutateAsync: updateLineItemMutation } = useUpdateLineItem();
  const { mutateAsync: updateLineItemMaterialsMutation } =
    useUpdateLineItemMaterials();
  const { mutateAsync: deleteLineItemMutation, isLoading: isDeleteLineItem } =
    useDeleteLineItem();

  /**
   * Redux
   */
  const reduxDispatch = useDispatch();

  const linkedMaterials = formMethods.watch('materials');
  const { price: unitPriceExcTax } = formMethods.watch('markupTotals');

  const reCalculateMarkupTotals = (
    newLinkedMaterials: TFormState['materials']
  ) => {
    const predictedMaterialCosts = calculatePredictedMaterialCosts(
      newLinkedMaterials.map((material) => ({
        materialQuantity: material.quantity,
        // The backend orders the suppliers by 'preferred supplier' or lowest price
        price: material.suppliedBy[0]?.price.unitPriceExcTax.value || 0,
      }))
    );

    formMethods.setValue('materials', newLinkedMaterials, {
      shouldDirty: true,
    });

    let markupTotalsType = formMethods.getValues('markupTotals.markupType');
    let calculatedMarkupChange = calculateMarkupChange(
      markupTotalsType,
      formMethods.getValues(`markupTotals.${markupTotalsType}`),
      predictedMaterialCosts
    );

    if (newLinkedMaterials.length === 0) {
      markupTotalsType = 'price';
      calculatedMarkupChange = {
        profitPercentage: 0,
        profitAmount: 0,
        price: 0,
      };
    }

    formMethods.setValue(
      'markupTotals',
      {
        markupType: markupTotalsType,
        ...calculatedMarkupChange,
      },
      {
        shouldDirty: true,
      }
    );

    formMethods.setValue('predictedMaterialCost', predictedMaterialCosts, {
      shouldDirty: true,
    });
  };

  const onSubmit = async (state: TFormState) => {
    setIsSaving(true);

    const price: UpdateLineItemInput['price'] = {};
    switch (true) {
      case state.markupTotals.markupType === 'profitPercentage' &&
        state.autoUpdatePrice: {
        const profitFactor = (state.markupTotals.profitPercentage || 0) / 100;
        const markupPercentage =
          profitFactor !== 0 ? (profitFactor / (1 - profitFactor)) * 100 : 0;

        price['markupPercentage'] = markupPercentage;
        break;
      }
      case state.markupTotals.markupType === 'profitAmount' &&
        state.autoUpdatePrice:
        price['markupAmount'] = state.markupTotals.profitAmount;
        break;
      default:
        price['unitPriceExcTax'] = state.markupTotals.price;
        break;
    }

    let newOrCurrentLineItemId = lineItemId;
    if (isEditLineItem) {
      const removeLinkedMaterials =
        originalLineItemData?.lineItem.materials.filter((material) => {
          return !state.materials.some(
            (newMaterial) => newMaterial.id === material.material.id
          );
        }) || [];

      await updateLineItemMaterialsMutation({
        lineItemId,
        lineItemMaterials: [
          ...removeLinkedMaterials.map((material) => ({
            materialId: material.material.id,
            quantityChange: {
              absolute: 0,
            },
          })),
          ...state.materials.map((material) => ({
            materialId: material.id,
            quantityChange: {
              absolute: material.quantity,
            },
          })),
        ],
      });

      await updateLineItemMutation({
        id: lineItemId,
        name: state.reference,
        description: state.description,
        price,
        taxRateId: state.taxRateId,
        accountingIntegrationSyncData: state.accountingIntegrationSyncData,
      });
    } else {
      const { createLineItem } = await createLineItemMutation({
        name: state.reference,
        description: state.description,
        price,
        taxRateId: state.taxRateId,
        materials: state.materials.map((material) => ({
          materialId: material.id,
          quantityChange: {
            absolute: material.quantity,
          },
        })),
        accountingIntegrationSyncData: state.accountingIntegrationSyncData,
      });

      newOrCurrentLineItemId = createLineItem.id;
    }

    // Any attachments to upload?
    if (state.thumbnail?.file) {
      await new Promise<void>((resolve, reject) => {
        reduxDispatch(
          lineItemActions.requestCreateUpdateLineItemAttachments(
            Number(newOrCurrentLineItemId),
            [
              {
                file: state.thumbnail!.file!,
                fileName: state.thumbnail!.file!.name,
              },
            ],
            () => {
              resolve();
            },
            (e) => {
              reject(new Error(e));
            }
          )
        );
      });
    }

    await queryClient.invalidateQueries({
      queryKey: lineItemKeys.lineItems(),
    });

    setIsSaving(false);
    onSaveSuccess?.();
  };

  const handleThumbnailDelete = async () => {
    formMethods.setValue('thumbnail', undefined, {
      shouldDirty: true,
    });

    if (lineItemId) {
      await new Promise<void>((resolve, reject) => {
        reduxDispatch(
          lineItemActions.requestCreateUpdateLineItemAttachments(
            Number(lineItemId),
            // that's correct, we're sending an empty array to delete the attachment 🤢
            [],
            () => {
              resolve();
            },
            (e) => {
              reject(new Error(e));
            }
          )
        );
      });
    }
  };

  const handleDeleteItem = async () => {
    if (!lineItemId) {
      return;
    }

    await deleteLineItemMutation(lineItemId);

    modalStateDispatch({ type: 'RESET' });

    onDeleteSuccess?.();
  };

  const numOfLinkedMaterials = linkedMaterials.reduce((acc, linkedMaterial) => {
    return acc + linkedMaterial.quantity;
  }, 0);

  if (isFetching) {
    return (
      <>
        <div className="grid h-full grid-cols-2">
          <div className="flex flex-col gap-4 p-3.5">
            <SkeletonLoader.Input />
            <SkeletonLoader.Textarea />
            <SkeletonLoader.Input />
            <SkeletonLoader.Input />

            <div className="mt-auto">
              <SkeletonLoader.Button />
            </div>
          </div>
          <div className="space-y-4 border-l bg-gray-50  p-3.5">
            <SkeletonLoader.MaterialCard />
            <SkeletonLoader.MaterialCard />
            <SkeletonLoader.MaterialCard />
            <SkeletonLoader.MaterialCard />
            <SkeletonLoader.MaterialCard />
            <SkeletonLoader.MaterialCard />
            <SkeletonLoader.MaterialCard />
          </div>
        </div>
      </>
    );
  }

  const hasScrollShadow = !(
    scrollRef &&
    scrollRef.scrollHeight - scrollRef.scrollTop === scrollRef.clientHeight
  );

  return (
    <>
      <div className="grid h-full grid-cols-2">
        <div className="overflow-auto" ref={setScrollRef}>
          <FormProvider {...formMethods}>
            <form
              className="flex h-full flex-col"
              onSubmit={formMethods.handleSubmit(onSubmit, console.error)}
            >
              <div className="flex flex-col gap-4 p-3.5">
                <ReferenceField />

                <TitleDescriptionField />

                <LineItemPriceCard
                  hasLinkedMaterials={numOfLinkedMaterials > 0}
                />

                <LineItemTotalsCard unitPriceExcTax={unitPriceExcTax} />

                <EditAccountingIntegrations />

                <ThumbnailField onDelete={handleThumbnailDelete} />
              </div>

              <div
                className={clstx(
                  'sticky bottom-0 z-10 mt-auto w-full p-3.5 bg-white',
                  hasScrollShadow && 'shadow-flipped-lg'
                )}
              >
                <Button
                  className="w-full"
                  type="submit"
                  disabled={isSaving || !formMethods.formState.isDirty}
                  isProcessing={isSaving}
                >
                  {isEditLineItem ? 'Update Item' : 'Create Item'}
                </Button>

                <PermissionGuard
                  renderIfHasPermissions={[
                    LineItemsPermissions.DELETE_LINE_ITEM,
                  ]}
                >
                  <Conditional condition={isEditLineItem}>
                    <Button
                      className="w-full mt-2"
                      variant={EBtnVariant.Outline}
                      colour={EBtnColour.Red}
                      onClick={() => {
                        modalStateDispatch({ type: 'OPEN_CONFIRM_DELETE' });
                      }}
                    >
                      Delete Item
                    </Button>
                  </Conditional>
                </PermissionGuard>
              </div>
            </form>
          </FormProvider>
        </div>
        <div className="flex flex-col overflow-auto border-l bg-gray-50 p-3.5">
          <LinkedMaterialsList
            linkedMaterials={linkedMaterials}
            onLinkMaterialsRequest={() => setIsLinkMaterialSidebarOpen(true)}
            onChange={reCalculateMarkupTotals}
          />
        </div>
      </div>

      <Modal
        isOpen={modalState.modal === 'CONFIRM_DELETE'}
        title="Remove Item"
        onClose={() => {
          modalStateDispatch({ type: 'RESET' });
        }}
      >
        <Modal.Body>
          <p>
            Are you sure you wish to delete this Item? This will not remove the
            Item from any Proposals it is linked to.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            <Button
              variant={EBtnVariant.Outline}
              onClick={() => {
                modalStateDispatch({ type: 'RESET' });
              }}
            >
              Cancel
            </Button>
            <Button
              colour={EBtnColour.Red}
              onClick={handleDeleteItem}
              disabled={isDeleteLineItem}
              isProcessing={isDeleteLineItem}
            >
              Delete Item
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </Modal>

      <BuildMaterialsListSidebar
        title="Link Materials"
        showPriceIncludingTax={false}
        emptyStatePromptText="Link Materials to Item"
        primaryActionText="Link Materials to Item"
        warnUserContent={{
          body: "You have selected Materials that you haven't linked to the Item.",
        }}
        zIndexLevel={2}
        onAddMaterials={(incomingMaterials) => {
          const merged = new Map(linkedMaterials.map((i) => [i['id'], i]));

          // Add or merge quantities with incoming materials
          incomingMaterials.forEach((i) => {
            merged.set(i['id'], {
              ...merged.get(i['id']),
              ...i,
              quantity: (merged.get(i['id'])?.quantity || 0) + i.quantity,
            });
          });

          reCalculateMarkupTotals(Array.from(merged.values()));

          return Promise.resolve();
        }}
        isOpen={isLinkMaterialsSidebarOpen}
        onClose={() => setIsLinkMaterialSidebarOpen(false)}
      />
    </>
  );
};

const AddEditItemSidebar: FC<
  Omit<SidebarProps, 'size' | 'title' | 'behind'> & { lineItemId?: string }
> = (props) => {
  const { lineItemId, isOpen, onClose, ...rest } = props;

  const isEditLineItem = !!lineItemId;

  const [isLinkMaterialsSidebarOpen, setIsLinkMaterialsSidebarOpen] =
    useState(false);
  const [shouldWarnUserOnClose, setShouldWarnUserOnClose] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsLinkMaterialsSidebarOpen(false);
    }
  }, [isOpen]);

  return (
    <>
      <Sidebar
        isOpen={isOpen}
        size="lg"
        title={isEditLineItem ? 'Edit Item' : 'Create Item'}
        behind={isLinkMaterialsSidebarOpen}
        onClose={!isLinkMaterialsSidebarOpen ? onClose : undefined}
        warnUserOnClose={shouldWarnUserOnClose}
        warnUserContent={{
          body: 'The changes to this Item are not saved and will be lost.',
          confirm: 'Discard changes',
        }}
        {...rest}
      >
        <Sidebar.Body>
          <AddEditItemSidebarContent
            lineItemId={lineItemId}
            isLinkMaterialsSidebarOpen={isLinkMaterialsSidebarOpen}
            setIsLinkMaterialSidebarOpen={setIsLinkMaterialsSidebarOpen}
            setIsDirty={setShouldWarnUserOnClose}
            onSaveSuccess={onClose}
            onDeleteSuccess={onClose}
          />
        </Sidebar.Body>
      </Sidebar>
    </>
  );
};

export default AddEditItemSidebar;

const EditAccountingIntegrations: FC = () => {
  const { data, isLoading } = useGetMyAccountAccountingIntegrations();

  if (isLoading) {
    return (
      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body className="space-y-4">
          <SkeletonLoader.Title className="w-1/6" />
          <SkeletonLoader.Input />
        </Card.Body>
      </Card>
    );
  }

  if (!data?.quickbooks?.accounts.length && !data?.xero?.accounts.length) {
    return null;
  }

  return (
    <PermissionGuard
      renderIfHasPermissions={[AccountsPermissions.UPDATE_ACCOUNT]}
    >
      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body className="space-y-2.5">
          <h4>Revenue account</h4>
          {!!data?.quickbooks?.accounts.length && (
            <AccountingIntegrationAccountInput type={'quickbooks'} />
          )}
          {!!data?.xero?.accounts.length && (
            <AccountingIntegrationAccountInput type={'xero'} />
          )}
        </Card.Body>
      </Card>
    </PermissionGuard>
  );
};

const AccountingIntegrationAccountInput: FC<{
  type: 'xero' | 'quickbooks';
}> = ({ type }) => {
  const { data, isLoading } = useGetMyAccountAccountingIntegrations();

  const options = data?.[type]?.accounts
    .filter((x) => x.isRevenue)
    .map((x) => ({
      label: x.code ? `${x.code} - ${x.name}` : x.name,
      value: x.identifier,
    }));

  const formMethods = useFormContext<TFormState>();

  if (!options) return null;

  return (
    <Field>
      <div className="grid grid-cols-[1fr_220px] items-center gap-10">
        <div>
          <p>{type === 'xero' ? 'Xero' : 'Quickbooks'}</p>
        </div>
        <Controller
          name={`accountingIntegrationSyncData.${type}.accountIdentifier`}
          defaultValue=""
          control={formMethods.control}
          render={({ field: { onChange, onBlur, value, ref } }) => {
            return (
              <Select
                options={options}
                value={value}
                onChange={onChange}
              ></Select>
            );
          }}
        />
      </div>
    </Field>
  );
};
