import { Req } from '@payaca/helpers/storeHelper';
import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions';
import { ActionType, SagaConfig } from './documentTypes';

import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { handleAsyncAction } from '../utils';
import {
  clearDocuments,
  createDocumentFailure,
  createDocumentSuccess,
  createFormInstanceForDocument,
  editDocumentFailure,
  editDocumentSuccess,
  getDocumentFailure,
  getDocumentPrefillPreferences,
  getDocumentsForDealFailure,
  getDocumentsForDealSuccess,
  getDocumentSuccess,
  removeDocumentFailure,
  removeDocumentSuccess,
  renameDocumentFailure,
  renameDocumentSuccess,
  sendDocumentFailure,
  sendDocumentSuccess,
  setDocumentPrefillPreferences,
  uploadDocumentFailure,
  uploadDocumentSuccess,
  uploadDocumentsV2,
} from './documentActions';

const documentSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);
  const providerReq = Req(
    `${apiBaseurl}/provider/rest`,
    getAuthHeader,
    isNativeApp
  );

  function* handleGetDocumentsForDeal(
    action: PayloadAction<
      ActionType.REQUEST_GET_DOCUMENTS_FOR_DEAL,
      { dealId: number }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getDocumentsForDeal, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getDocumentsForDeal request timed out');
      }

      yield put(getDocumentsForDealSuccess(response));
    } catch (error) {
      yield put(getDocumentsForDealFailure(error as Error));
    }
  }

  const getDocumentsForDeal = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/documents/deal/${dealId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getDocumentsForDeal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetDocument(
    action: PayloadAction<
      ActionType.REQUEST_GET_DOCUMENT,
      { documentId: number }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getDocument, action.payload.documentId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get document timed out.';
        yield put(
          getDocumentFailure(action.payload.documentId, new Error(errorMessage))
        );
      } else {
        yield put(getDocumentSuccess(action.payload.documentId, response));
      }
    } catch (error) {
      yield put(getDocumentFailure(action.payload.documentId, error as Error));
    }
  }

  const getDocument = async (documentId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/documents/${documentId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getDocument failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetDocumentPreview(
    action: PayloadAction<
      ActionType.REQUEST_GET_DOCUMENT_PREVIEW,
      { documentId: number; previewToken: string }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getDocumentPreview,
          action.payload.documentId,
          action.payload.previewToken
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get document preview timed out.';
        yield put(
          getDocumentFailure(action.payload.documentId, new Error(errorMessage))
        );
      } else {
        yield put(getDocumentSuccess(action.payload.documentId, response));
      }
    } catch (error) {
      yield put(getDocumentFailure(action.payload.documentId, error as Error));
    }
  }

  const getDocumentPreview = async (
    documentId: number,
    previewToken: string
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/documents/preview?documentId=${documentId}&token=${previewToken}`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getDocumentPreview failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleCreateDocument(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_DOCUMENT,
      {
        formId: number;
        dealId: number;
        willBeSent: boolean;
        onDocumentCreated: any;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          createDocument,
          action.payload.formId,
          action.payload.dealId,
          action.payload.willBeSent
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Create document timed out.';
        yield put(createDocumentFailure(new Error(errorMessage)));
      } else {
        yield put(createDocumentSuccess(response));
        action.payload.onDocumentCreated &&
          action.payload.onDocumentCreated(response);
      }
    } catch (error) {
      yield put(createDocumentFailure(error as Error));
    }
  }

  const createDocument = async (
    formId: number,
    dealId: number,
    willBeSent: boolean
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/documents`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
      body: JSON.stringify({
        formId,
        dealId,
        willBeSent,
      }),
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `createDocument failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleCreateDocumentLink(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_DOCUMENT_LINK,
      {
        dealId: number;
        title: string;
        url: string;
        callback: (resp: any) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          createDocumentLink,
          action.payload.dealId,
          action.payload.title,
          action.payload.url
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Create document link timed out.';
        yield put(createDocumentFailure(new Error(errorMessage)));
      } else {
        yield put(createDocumentSuccess(response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error) {
      yield put(createDocumentFailure(error as Error));
    }
  }

  const createDocumentLink = async (
    dealId: number,
    title: string,
    url: string
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/documents/link`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
      body: JSON.stringify({
        dealId,
        title,
        url,
      }),
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `createDocumentLink failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleSendDocument(
    action: PayloadAction<
      ActionType.REQUEST_SEND_DOCUMENT,
      {
        documentId: number;
        sendToEmail: string;
        emailBody: string;
        sendACopy: boolean;
        callback?: any;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          sendDocument,
          action.payload.documentId,
          action.payload.sendToEmail,
          action.payload.emailBody,
          action.payload.sendACopy
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Send document timed out.';
        yield put(sendDocumentFailure(new Error(errorMessage)));
      } else {
        yield put(sendDocumentSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error) {
      yield put(sendDocumentFailure(error as Error));
    }
  }

  const sendDocument = async (
    documentId: number,
    sendToEmail: string,
    emailBody: string,
    sendACopy: boolean
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/documents/${documentId}/send`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
      body: JSON.stringify({
        sendToEmail,
        emailBody,
        sendACopy,
      }),
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `sendDocument failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleRenameDocument(
    action: PayloadAction<
      ActionType.REQUEST_RENAME_DOCUMENT,
      {
        documentId: number;
        newTitle: string;
        callback?: (error?: string) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          renameDocument,
          action.payload.documentId,
          action.payload.newTitle
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Rename document timed out.';
        yield put(renameDocumentFailure(new Error(errorMessage)));
        action.payload.callback?.(errorMessage);
      } else {
        yield put(renameDocumentSuccess(response));
        action.payload.callback?.();
      }
    } catch (error) {
      yield put(renameDocumentFailure(error as Error));
      action.payload.callback?.((error as Error).message);
    }
  }

  const renameDocument = async (documentId: number, newTitle: string) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/documents/${documentId}`, {
      method: 'PATCH',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
      body: JSON.stringify({
        newTitle,
      }),
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `renameDocument failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleUploadDocument(
    action: PayloadAction<
      ActionType.REQUEST_UPLOAD_DOCUMENT,
      {
        dealId: number;
        upload: { file: File; fileName: string };
        callback?: (errorMessage: string | null) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          uploadDocument,
          action.payload.dealId,
          action.payload.upload
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Upload document against Project failed.';
        yield put(uploadDocumentFailure(new Error(errorMessage)));
        action.payload.callback && action.payload.callback(errorMessage);
      } else {
        yield put(uploadDocumentSuccess(response.id));
        action.payload.callback && action.payload.callback(null);
      }
    } catch (error) {
      yield put(uploadDocumentFailure(error as Error));
      action.payload.callback &&
        action.payload.callback((error as Error).message);
    }
  }

  const removeDocument = async (documentId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/documents/${documentId}`, {
      method: 'DELETE',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `removeDocument failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleRemoveDocument(
    action: PayloadAction<
      ActionType.REQUEST_REMOVE_DOCUMENT,
      {
        documentId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(removeDocument, action.payload.documentId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Add attachment to job line item.';
        yield put(removeDocumentFailure(new Error(errorMessage)));
      } else {
        yield put(removeDocumentSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error) {
      yield put(removeDocumentFailure(error as Error));
    }
  }

  const uploadDocument = async (
    dealId: number,
    upload: { file: File; fileName: string }
  ) => {
    const response = await req.postFile(
      `/documents/upload?dealId=${dealId}`,
      upload,
      isNativeApp
    );
    if (response.ok) {
      return true;
    } else {
      throw new Error(
        `uploadDocument failed: ${response.status} ${response.statusText}`
      );
    }
  };

  function* handleGetDocumentForTask(
    action: PayloadAction<
      ActionType.REQUEST_GET_DOCUMENT_FOR_TASK,
      {
        taskId: number;
        callback: (document: Document) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getDocumentForTask, action.payload.taskId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get document for task timed out.';
        console.log(errorMessage);
      } else {
        yield put(getDocumentSuccess(response.id, response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error) {
      console.log(`Get document for task failed: ${JSON.stringify(error)}`);
    }
  }

  const getDocumentForTask = async (taskId: number) => {
    const response = await req.get(`/document/task/${taskId}`);
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(
        `getDocumentForTask failed: ${response.status} ${response.statusText}`
      );
    }
  };
  function* handleEditDocument(
    action: PayloadAction<
      ActionType.REQUEST_EDIT_DOCUMENT,
      {
        documentId: number;
        callback: (error: string | null, response?: any) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(editDocument, action.payload.documentId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Edit document timed out.';
        yield put(editDocumentFailure(new Error(errorMessage)));
        action.payload.callback(errorMessage);
      } else {
        yield put(editDocumentSuccess());
        action.payload.callback(null, response);
      }
    } catch (error) {
      yield put(editDocumentFailure(error as Error));
      action.payload.callback((error as Error).message);
    }
  }

  const editDocument = async (documentId: number) => {
    const response = await req.post(`/document/${documentId}/edit`, {});
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(
        `editDocument failed: ${response.status} ${response.statusText}`
      );
    }
  };

  const handleUploadDocumentsV2 = handleAsyncAction(
    uploadDocumentsV2,
    async (payload) => {
      try {
        const files = payload.files;

        const response: { url: string; bucketKey: string }[] = await req
          .post(
            `/documents/create_uploads_intents?dealId=${payload.dealId}`,
            files.map((f) => ({
              fileName: f.fileName,
              fileSize: f.size,
              fileType: f.type,
            }))
          )
          .then((response) => {
            return response.json();
          })
          .catch(() => {
            throw new Error('uploadDocumentIntentFailed');
          });

        // upload to bucket using signed urls
        await Promise.all(
          response.map(async (r, i: number) => {
            await fetch(r.url, {
              method: 'PUT',
              headers: {
                'content-type': files[i].type,
              },
              body: files[i] as any,
            });
          })
        ).catch(() => {
          throw new Error('uploadDocumentFailed');
        });

        // create documents in db and create thumbnail
        const documentIds = await req
          .post(
            `/documents/create_uploads?dealId=${payload.dealId}`,
            files.map((f, i) => ({
              fileName: f.fileName,
              bucketKey: response[i].bucketKey,
            }))
          )
          .then((response) => {
            return response.json();
          })
          .catch(() => {
            throw new Error('uploadDocumentCreateFailed');
          });

        return documentIds;
      } catch (err) {
        console.log(`Upload document v2 failed: ${JSON.stringify(err)}`);
        payload.onErrorCallback?.();
      }
    },
    async (_, requestData) => {
      requestData.payload.callback?.();
    }
  );

  const handleCreateFormInstanceForDocument = handleAsyncAction(
    createFormInstanceForDocument,
    async (payload) => {
      try {
        const response = await req.get(`/document/${payload.documentId}/form`);
        return await response.json();
      } catch (err) {
        console.log(
          `Create form instance for document failed: ${JSON.stringify(err)}`
        );
        payload.onErrorCallback?.();
      }
    },
    async (response: any, requestData) => {
      requestData.payload.callback?.(response.previewToken as string);
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleGetDocumentPrefillPreferences = handleAsyncAction(
    getDocumentPrefillPreferences,
    async (payload) => {
      const response = await providerReq.get(
        `/forms/prefill-preferences/${payload.documentId}`
      );
      return await response.json();
    },
    async (response: any, requestData) => {
      requestData.payload.callback?.(response);
    }
  );

  const handleSetDocumentPrefillPreferences = handleAsyncAction(
    setDocumentPrefillPreferences,
    async (payload) => {
      const response = await providerReq.put(
        `/forms/prefill-preferences/${payload.documentId}`,
        payload.prefillPreferences
      );
      return await response.json();
    },
    async (response: any, requestData) => {
      requestData.payload.callback?.(response);
    }
  );

  function* handleAppLogout() {
    yield put(clearDocuments());
  }

  return function* () {
    yield takeEvery('auth.logout', handleAppLogout);

    yield takeEvery(ActionType.REQUEST_UPLOAD_DOCUMENT, handleUploadDocument);
    yield takeEvery(ActionType.REQUEST_REMOVE_DOCUMENT, handleRemoveDocument);
    yield takeLatest(
      ActionType.REQUEST_GET_DOCUMENTS_FOR_DEAL,
      handleGetDocumentsForDeal
    );
    yield takeLatest(ActionType.REQUEST_GET_DOCUMENT, handleGetDocument);
    yield takeLatest(ActionType.REQUEST_EDIT_DOCUMENT, handleEditDocument);
    yield takeLatest(
      ActionType.REQUEST_GET_DOCUMENT_PREVIEW,
      handleGetDocumentPreview
    );
    yield takeLatest(ActionType.REQUEST_CREATE_DOCUMENT, handleCreateDocument);
    yield takeLatest(ActionType.REQUEST_RENAME_DOCUMENT, handleRenameDocument);
    yield takeLatest(ActionType.REQUEST_SEND_DOCUMENT, handleSendDocument);
    yield takeLatest(
      ActionType.REQUEST_CREATE_DOCUMENT_LINK,
      handleCreateDocumentLink
    );
    yield takeEvery(
      ActionType.REQUEST_GET_DOCUMENT_FOR_TASK,
      handleGetDocumentForTask
    );
    yield takeEvery(
      ActionType.UPLOAD_DOCUMENTS_V2_REQUEST,
      handleUploadDocumentsV2
    );
    yield takeEvery(
      ActionType.CREATE_FORM_INSTANCE_FOR_DOCUMENT_REQUEST,
      handleCreateFormInstanceForDocument
    );
    yield takeEvery(
      ActionType.GET_DOCUMENT_PREFILL_PREFERENCES_REQUEST,
      handleGetDocumentPrefillPreferences
    );
    yield takeEvery(
      ActionType.SET_DOCUMENT_PREFILL_PREFERENCES_REQUEST,
      handleSetDocumentPrefillPreferences
    );
  };
};

export default documentSagaCreator;
