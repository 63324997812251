import React, { FC, useCallback, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';

import FieldLabel from '../fieldLabel/FieldLabel';
import InputWrapper, { InputStyleVariant } from '../inputWrapper/InputWrapper';

import './FormattedNumberField.sass';

export enum NumberFormatVariant {
  SORT_CODE = 'sortCode',
  BSB = 'BSB',
  ACCOUNT_NUMBER = 'accountNumber',
  AU_ACCOUNT_NUMBER = 'auAccountNumber',
  CA_ROUTING_NUMBER = 'caRoutingNumber',
  ZA_ACCOUNT_NUMBER = 'zaAccountNumber',
  ZA_BRANCH_CODE = 'zaBranchCode',
  GC_NUMBER = 'gcNumber',
  NZ_BSB = 'nzBSB',
  NZ_ACCOUNT_NUMBER = 'nzAccountNumber',
}

const numberFormat = {
  [NumberFormatVariant.SORT_CODE]: '##-##-##',
  [NumberFormatVariant.BSB]: '###-###',
  [NumberFormatVariant.ACCOUNT_NUMBER]: '########',
  [NumberFormatVariant.AU_ACCOUNT_NUMBER]: '#### ####',
  [NumberFormatVariant.CA_ROUTING_NUMBER]: '0#######',
  [NumberFormatVariant.ZA_BRANCH_CODE]: '### ###',
  [NumberFormatVariant.ZA_ACCOUNT_NUMBER]: '#########',
  [NumberFormatVariant.GC_NUMBER]: '##-###-##',
  [NumberFormatVariant.NZ_BSB]: '######',
  [NumberFormatVariant.NZ_ACCOUNT_NUMBER]: '################',
};

type Props = {
  changeTimeoutMs?: number;
  description?: string | JSX.Element;
  formatVariant: NumberFormatVariant;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string | JSX.Element;
  name: string;
  onBlur?: () => void;
  onChange?: (value: { [key: string]: string }) => void;
  onChangeTimeout?: () => void;
  onTouch?: (fieldName: string) => void;
  styleVariant?: InputStyleVariant;
  value?: any;
};

const FormattedNumberField: FC<React.PropsWithChildren<Props>> = ({
  changeTimeoutMs = 1000,
  description,
  formatVariant,
  isDisabled = false,
  isRequired = false,
  label,
  name,
  onBlur,
  onChange,
  onChangeTimeout,
  onTouch,
  styleVariant,
  value,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const [changeTimeout, setChangeTimeout] = useState<any>();

  const onInputChange = useCallback(
    (value: string) => {
      onChange &&
        onChange({
          [name]: value,
        });

      changeTimeout && clearTimeout(changeTimeout);
      setChangeTimeout(
        setTimeout(() => {
          onChangeTimeout && onChangeTimeout();
        }, changeTimeoutMs)
      );
    },
    [onChange, onChangeTimeout, changeTimeout, changeTimeoutMs]
  );

  const onInputTouch = useCallback(() => {
    onTouch && onTouch(name);
  }, [onTouch]);

  const format = useMemo(() => {
    return numberFormat[formatVariant];
  }, [formatVariant]);

  return (
    <div className={`formatted-number-field`}>
      <FieldLabel
        label={label}
        isRequired={isRequired}
        description={description}
      />
      <InputWrapper isDisabled={isDisabled} styleVariant={styleVariant}>
        <>
          <NumberFormat
            value={value}
            onValueChange={(values) => onInputChange(values.value)}
            name={name}
            format={format}
            allowEmptyFormatting
            onBlur={() => {
              onInputTouch && onInputTouch();
              onBlur && onBlur();
            }}
            isNumericString={true}
            className={'input-wrapper__input'}
          />
        </>
      </InputWrapper>
    </div>
  );
};

export default FormattedNumberField;
