import { Deal } from '@payaca/types/dealTypes';
import {
  GetListedPurchaseOrdersQueryParams,
  ListedPurchaseOrdersPage,
} from '@payaca/types/listedPurchaseOrderTypes';
import { Address } from '@payaca/types/locationTypes';
import {
  HydratedPurchaseOrder,
  MaterialPurchaseIntent,
  MaterialPurchaseRecord,
  MaterialsList,
  MaterialsListMaterial,
  PurchaseOrder,
} from '@payaca/types/materialsListTypes';
import { Material } from '@payaca/types/materialTypes';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { Supplier } from '@payaca/types/supplierTypes';
import { Nullish } from '@payaca/utilities/types';
import { DeclareAsyncAction } from '../types';

export interface State {
  isPersistingMaterialsList: boolean;
  isPopulatingDealMaterialsList: boolean;
  isPersistingMaterialsListMaterial: boolean;
  isDeletingMaterialsListMaterial: boolean;
  isPersistingMaterialPurchaseRecord: boolean;
  isDeletingMaterialPurchaseRecord: boolean;
  isPersistingPurchaseOrder: boolean;

  materialsLists: { [key: number]: FetchedEntity<MaterialsList> };
  materialsListMaterials: {
    [key: number]: FetchedEntity<MaterialsListMaterial>;
  };
  materialPurchaseRecords: {
    [key: number]: FetchedEntity<MaterialPurchaseRecord>;
  };
  materialPurchaseIntents: {
    [key: number]: FetchedEntity<MaterialPurchaseIntent>;
  };
  purchaseOrders: {
    [key: number]: FetchedEntity<PurchaseOrder>;
  };
}

export enum ActionType {
  REQUEST_CREATE_DEAL_MATERIALS_LIST = 'materialsLists.requestCreateMaterialsListForDeal',
  PERSIST_MATERIALS_LIST_SUCCESS = 'materialsLists.persistMaterialsListSuccess',
  PERSIST_MATERIALS_LIST_FAILURE = 'materialsLists.persistMaterialsListFailure',

  REQUEST_SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL = 'materialsLists.requestPopulateDealMaterialsListFromDeal',
  SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL_SUCCESS = 'materialsLists.populateDealMaterialsListFromDealSuccess',
  SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL_FAILURE = 'materialsLists.populateDealMaterialsListFromDealFailure',

  CLEAR_MATERIALS_LIST_REQUEST = 'materialsLists.clearMaterialsList:request',
  CLEAR_MATERIALS_LIST_SUCCESS = 'materialsLists.clearMaterialsList:success',
  CLEAR_MATERIALS_LIST_FAILURE = 'materialsLists.clearMaterialsList:failure',

  REQUEST_GET_MATERIALS_LIST = 'materialsLists.requestGetMaterialsList',
  GET_MATERIALS_LIST_SUCCESS = 'materialsLists.getMaterialsListSuccess',
  GET_MATERIALS_LIST_FAILURE = 'materialsLists.getMaterialsListFailure',

  REQUEST_GET_MATERIALS_LIST_WITH_RELATED_ENTITIES = 'materialsLists.requestGetMaterialsListWithRelatedEntities',
  GET_MATERIALS_LIST_WITH_RELATED_ENTITIES_SUCCESS = 'materialsLists.getMaterialsListWithRelatedEntitiesSuccess',

  REQUEST_GET_MATERIALS_LIST_MATERIALS_FOR_MATERIALS_LIST = 'materialsLists.requestGetMaterialsListMaterialsForMaterialsList',
  GET_MATERIALS_LIST_MATERIALS_FOR_MATERIALS_LIST_SUCCESS = 'materialsLists.getMaterialsListMaterialsForMaterialsListSuccess',

  REQUEST_GET_MATERIALS_LIST_MATERIAL_WITH_RELATED_ENTITIES = 'materialsLists.requestGetMaterialsListMaterialWithRelatedEntities',
  GET_MATERIALS_LIST_MATERIAL_WITH_RELATED_ENTITIES_SUCCESS = 'materialsLists.getMaterialsListMaterialWithRelatedEntitiesSuccess',

  REQUEST_GET_MATERIALS_LIST_MATERIAL = 'materialsLists.requestGetMaterialsListMaterial',
  GET_MATERIALS_LIST_MATERIAL_SUCCESS = 'materialsLists.getMaterialsListMaterialSuccess',
  GET_MATERIALS_LIST_MATERIAL_FAILURE = 'materialsLists.getMaterialsListMaterialFailure',

  REQUEST_GET_MATERIAL_PURCHASE_RECORDS_FOR_MATERIALS_LIST = 'materialsLists.requestGetMaterialPurchaseRecordsForMaterialsList',
  REQUEST_GET_MATERIAL_PURCHASE_RECORD = 'materialsLists.requestGetMaterialPurchaseRecord',
  GET_MATERIAL_PURCHASE_RECORD_SUCCESS = 'materialsLists.getMaterialPurchaseRecordSuccess',
  GET_MATERIAL_PURCHASE_RECORD_FAILURE = 'materialsLists.getMaterialPurchaseRecordFailure',

  CLEAR_MATERIALS_LISTS = 'materialsLists.clearMaterialsLists',
  CLEAR_MATERIALS_LIST_MATERIALS = 'materialsLists.clearMaterialsListMaterials',
  CLEAR_MATERIAL_PURCHASE_RECORDS = 'materialsLists.clearMaterialPurchaseRecords',
  CLEAR_MATERIAL_PURCHASE_INTENTS = 'materialsLists.clearMaterialPurchaseIntents',
  CLEAR_PURCHASE_ORDERS = 'materialsLists.clearPurchaseOrders',

  REQUEST_PERSIST_MATERIALS_LIST_MATERIAL = 'materialsLists.requestPersistMaterialsListMaterial',
  PERSIST_MATERIALS_LIST_MATERIAL_SUCCESS = 'materialsLists.persistMaterialsListMaterialSuccess',
  PERSIST_MATERIALS_LIST_MATERIAL_FAILURE = 'materialsLists.persistMaterialsListMaterialFailure',

  REQUEST_DELETE_MATERIALS_LIST_MATERIAL = 'materialsLists.requestDeleteMaterialsListMaterial',
  DELETE_MATERIALS_LIST_MATERIAL_SUCCESS = 'materialsLists.deleteMaterialsListMaterialSuccess',
  DELETE_MATERIALS_LIST_MATERIAL_FAILURE = 'materialsLists.deleteMaterialsListMaterialFailure',

  REQUEST_PERSIST_MATERIAL_PURCHASE_RECORD = 'materialsLists.requestPersistMaterialPurchaseRecord',
  PERSIST_MATERIAL_PURCHASE_RECORD_SUCCESS = 'materialsLists.persistMaterialPurchaseRecordSuccess',
  PERSIST_MATERIAL_PURCHASE_RECORD_FAILURE = 'materialsLists.persistMaterialPurchaseRecordFailure',

  REQUEST_DELETE_MATERIAL_PURCHASE_RECORD = 'materialsLists.requestDeleteMaterialPurchaseRecord',
  DELETE_MATERIAL_PURCHASE_RECORD_SUCCESS = 'materialsLists.deleteMaterialPurchaseRecordSuccess',
  DELETE_MATERIAL_PURCHASE_RECORD_FAILURE = 'materialsLists.deleteMaterialPurchaseRecordFailure',

  REQUEST_PERSIST_PURCHASE_ORDER = 'materialsLists.requestPersistPurchaseOrder',
  PERSIST_PURCHASE_ORDER_SUCCESS = 'materialsLists.persistPurchaseOrderSuccess',
  PERSIST_PURCHASE_ORDER_FAILURE = 'materialsLists.persistPurchaseOrderFailure',

  GET_HYDRATED_PURCHASE_ORDER_REQUEST = 'materialsLists.getHydratedPurchaseOrder:request',

  REQUEST_GET_PURCHASE_ORDER = 'materialsLists.requestGetPurchaseOrder',
  GET_PURCHASE_ORDER_SUCCESS = 'materialsLists.getPurchaseOrderSuccess',
  GET_PURCHASE_ORDER_FAILURE = 'materialsLists.getPurchaseOrderFailure',

  REQUEST_GET_MATERIAL_PURCHASE_INTENT = 'materialsLists.requestGetMaterialPurchaseIntent',
  GET_MATERIAL_PURCHASE_INTENT_SUCCESS = 'materialsLists.getMaterialPurchaseIntentSuccess',
  GET_MATERIAL_PURCHASE_INTENT_FAILURE = 'materialsLists.getMaterialPurchaseIntentFailure',

  REQUEST_SEND_PURCHASE_ORDER = 'materialsLists.requestSendPurchaseOrder',
  REQUEST_MARK_PURCHASE_ORDER_AS_SENT = 'materialsLists.requestMarkPurchaseOrderAsSent',
  REQUEST_VOID_PURCHASE_ORDER = 'materialsLists.requestVoidPurchaseOrder',
  REQUEST_CONFIRM_PURCHASE_ORDER = 'materialsLists.requestConfirmPurchaseOrder',

  GET_LISTED_PURCHASE_ORDERS_PAGE_REQUEST = 'materialsLists.getListedPurchaseOrdersPage:request',

  CREATE_ISOLATED_PURCHASE_ORDER_REQUEST = 'materialsLists.createIsolatedPurchaseOrder:request',
  UPDATE_ISOLATED_PURCHASE_ORDER_REQUEST = 'materialsLists.updateIsolatedPurchaseOrder:request',
}

export interface CreateIsolatedPurchaseOrderRequestData {
  supplierId: Supplier['id'];
}

export type CreateIsolatedPurchaseOrder = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_ISOLATED_PURCHASE_ORDER_REQUEST;
      payload: {
        payload: CreateIsolatedPurchaseOrderRequestData;
        callback?: (purchaseOrder: HydratedPurchaseOrder) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export interface UpdateIsolatedPurchaseOrderRequestData {
  additionalNotes: PurchaseOrder['additionalNotes'];
  dueAt?: Date;
  deliveryAddressId?: Address['id'];
  materialPurchaseIntents: {
    id?: MaterialPurchaseIntent['id'];
    materialId?: Material['id']; // must have either id or materialId, not both
    materialQuantity: MaterialPurchaseIntent['materialQuantity'];
  }[];
  showMaterialPrices?: boolean;
}

export type UpdateIsolatedPurchaseOrder = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_ISOLATED_PURCHASE_ORDER_REQUEST;
      payload: {
        payload: UpdateIsolatedPurchaseOrderRequestData & {
          purchaseOrderId: PurchaseOrder['id'];
        };
        callback?: (purchaseOrder: HydratedPurchaseOrder) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type GetListedPurchaseOrdersPage = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_LISTED_PURCHASE_ORDERS_PAGE_REQUEST;
      payload: {
        queryParams: GetListedPurchaseOrdersQueryParams;
        callback?: (listedTimelogsPage: ListedPurchaseOrdersPage) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type GetHydratedPurchaseOrder = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_HYDRATED_PURCHASE_ORDER_REQUEST;
      payload: {
        purchaseOrderId: PurchaseOrder['id'];
        callback?: (purchaseOrder: HydratedPurchaseOrder) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type PopulateDealMaterialsListFromDeal = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.REQUEST_SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL;
      payload: {
        dealId: Deal['id'];
        callback?: (result: {
          createdMaterialsListMaterialsCount: number;
          updatedMaterialsListMaterialsCount: number;
          deletedMaterialsListMaterialsCount: number;
        }) => void;
        onErrorCallback?: () => void;
      };
    };
    success: {
      type: ActionType.SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL_SUCCESS;
    };
    failure: {
      type: ActionType.SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL_FAILURE;
    };
  }
>;

export type ClearMaterialsList = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CLEAR_MATERIALS_LIST_REQUEST;
      payload: {
        materialsListId: MaterialsList['id'];
        callback?: (result: {
          deletedMaterialsListMaterialsCount: number;
          updatedMaterialsListMaterialsCount: number;
        }) => void;
        onErrorCallback?: () => void;
      };
    };
    success: {
      type: ActionType.CLEAR_MATERIALS_LIST_SUCCESS;
    };
    failure: {
      type: ActionType.CLEAR_MATERIALS_LIST_FAILURE;
    };
  }
>;

export type MaterialToCreatePayload = {
  materialId: number;
  quantity?: number;
};

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export interface PersistMaterialsListMaterialRequestData {
  materialsListMaterialId?: number;
  materialId?: number;
  materialsListId?: number;
  materialQuantity?: number;
}

export interface PersistMaterialPurchaseRecordRequestData {
  materialPurchaseRecordId?: number;
  materialsListMaterialId?: number;
  materialsListId?: number;
  materialQuantity?: number;
  supplierId?: number | null;
  price?: number | null;
  taxPercentage?: number | null;
  isTaxIncluded?: boolean | null;
  taxRateId?: number;
}

export interface PersistPurchaseOrderMaterialPurchaseIntentRequestData {
  id?: number;
  materialsListMaterialId: number;
  materialQuantity: number;
}

export interface PersistPurchaseOrderRequestData {
  id?: number;
  materialsListId?: number;
  supplierId?: number;
  materialPurchaseIntents?: PersistPurchaseOrderMaterialPurchaseIntentRequestData[];
  dueAt?: Date;
  additionalNotes?: Nullish<string>;
  deliveryAddressId?: Address['id'];
  showMaterialPrices?: boolean;
}

export interface SendPurchaseOrderRequestData {
  id: number;
  emailBody: string;
  emailSubject: string;
  copyMeIn: boolean;
  supplierEmailAddress: string;
}

export interface ConfirmPurchaseOrderMaterialPurchaseRecordRequestData {
  materialsListMaterialId: number;
  materialQuantity: number;
  price: number | null;
  taxRateId: number | null;
}

export interface ConfirmPurchaseOrderRequestData {
  id?: number;
  materialPurchaseRecords?: ConfirmPurchaseOrderMaterialPurchaseRecordRequestData[];
}
