import { DistributionBoardListField } from '@/ui/components/distribution-board-list-field/DistributionBoardListField';
import { CollapsableGroupElement } from '@/ui/pages/formPage/customFormComponents/CollapsableGroupElement';
import { MultiInputFormElement } from '@/ui/pages/formPage/customFormComponents/MultiInputFormElement';
import { MultiItemFormElement } from '@/ui/pages/formPage/customFormComponents/MultiItemFormElement';
import { SearchGroupFormElement } from '@/ui/pages/formPage/customFormComponents/SearchGroupFormElement';
import { getFormElement } from '@/ui/pages/formPage/formUtils/getFormElement';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import FileUploadElement from '../customFormComponents/FileUploadElement';

export const createValidatedField = (
  formElement: any,
  formState: any,
  value: any,
  onFieldChange: any,
  onFieldTouch: any,
  touchedState: any,
  validationState: any,
  uiHandlers: any,
  index: number,
  name?: string
) => {
  switch (formElement.type) {
    case 'multi-items':
      return (
        <MultiItemFormElement
          elem={formElement}
          formState={formState}
          changeHandler={onFieldChange}
          onFieldTouch={onFieldTouch}
          touchedState={touchedState}
          validationState={validationState}
          uiHandlers={uiHandlers}
        />
      );
    case 'multi-input':
      return (
        <MultiInputFormElement
          elem={formElement}
          formState={formState}
          changeHandler={onFieldChange}
          onFieldTouch={onFieldTouch}
          touchedState={touchedState}
          validationState={validationState}
          uiHandlers={uiHandlers}
          name={name}
        />
      );
    case 'search-group':
      return (
        <SearchGroupFormElement
          elem={formElement}
          formState={formState}
          changeHandler={onFieldChange}
          onFieldTouch={onFieldTouch}
          touchedState={touchedState}
          validationState={validationState}
          uiHandlers={uiHandlers}
          name={name}
        />
      );
    case 'collapsable-group':
      return (
        <CollapsableGroupElement
          elem={formElement}
          formState={formState}
          changeHandler={onFieldChange}
          onFieldTouch={onFieldTouch}
          touchedState={touchedState}
          validationState={validationState}
          uiHandlers={uiHandlers}
        />
      );
    case 'file-upload':
      return (
        <FileUploadElement
          elem={formElement}
          formState={formState}
          changeHandler={onFieldChange}
        />
      );
    case 'electrical:distributionBoards': {
      return (
        <DistributionBoardListField
          version={'18'}
          distributionBoards={formState?.[formElement.id]}
          onChange={(x) => {
            onFieldChange({ [formElement.id]: x });
          }}
        />
      );
    }
    case 'electrical:distributionBoards:18-2': {
      return (
        <DistributionBoardListField
          version="18.2"
          distributionBoards={formState?.[formElement.id]}
          onChange={(x) => {
            onFieldChange({ [formElement.id]: x });
          }}
        />
      );
    }
    default:
      return (
        <div className={'form-element'}>
          <ValidatedFieldWrapper
            isTouched={
              (name ? touchedState[name] : touchedState[formElement.id]) ||
              !!value
            }
            validationResult={
              validationState &&
              (name ? validationState[name] : validationState[formElement.id])
            }
          >
            {getFormElement(
              formElement,
              value,
              onFieldChange,
              onFieldTouch,
              uiHandlers,
              name
            )}
          </ValidatedFieldWrapper>
        </div>
      );
  }
};
