import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useUpdateProject from '@/api/mutations/project/useUpdateProject';
import useGetMyAccountUsers from '@/api/queries/me/useGetMyAccountUsers';
import projectKeys from '@/api/queries/project/keyFactory';
import useGetProjectOverview from '@/api/queries/project/useGetProjectOverview';
import { ProjectQuery } from '@/gql/graphql';
import Combobox from '@payaca/components/plCombobox/Combobox';
import { SelectSizeVariant } from '@payaca/components/plSelect/Select';

const ProjectOwnerControl: FC = () => {
  const { dealId } = useParams<{ dealId: string }>();
  const queryClient = useQueryClient();
  const { data: projectData } = useGetProjectOverview(+dealId);
  const { data: accountUsers } = useGetMyAccountUsers({
    hasAnyPermission: ['deals.getMyDeal'],
  });
  const { mutateAsync: updateProjectMutation } = useUpdateProject({
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: projectKeys.overview(+dealId),
      });

      // Snapshot the previous value
      const previousProjectOver = queryClient.getQueryData(
        projectKeys.overview(+dealId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<ProjectQuery>(
        projectKeys.overview(+dealId),
        (old) => {
          if (!old) return;

          const newSelectedAccountUser = accountUsers?.find(
            (accountUser) => accountUser.id === variables.ownerId
          );

          if (!newSelectedAccountUser) {
            // Should never get here
            return old;
          }

          return {
            ...old,
            project: {
              ...old.project,
              owner: newSelectedAccountUser,
            },
          };
        }
      );

      // Return a context object with the snapshotted value
      return { previousProjectOver };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        projectKeys.overview(+dealId),
        // @ts-ignore
        context.previousProjectOver
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.project(+dealId),
      });
    },
  });

  const selectedOwnerId = useMemo(() => {
    if (!projectData || !projectData?.project?.owner) return;

    return projectData.project.owner.id;
  }, [projectData]);

  const availableProjectOwners = useMemo(() => {
    if (!accountUsers) return [];

    const _availableProjectOwners = accountUsers
      .filter((user) => !user.deactivatedAt)
      .map((user) => ({
        value: user.id,
        label: user.fullName,
      }));

    _availableProjectOwners.push({ value: 'UNASSIGNED', label: 'Unassigned' });

    return _availableProjectOwners;
  }, [accountUsers]);

  const handleChange = useCallback(
    async (value: string | string[]) => {
      if (Array.isArray(value)) {
        // Should never get here
        return;
      }

      await updateProjectMutation({
        projectId: dealId,
        ownerId: value === 'UNASSIGNED' ? null : value,
      });
    },
    [updateProjectMutation]
  );

  return (
    <Combobox
      CustomSelected={({ selectedOptions }) =>
        selectedOptions[0] ? (
          <>
            <b>Owner:</b> {selectedOptions[0]?.label}
          </>
        ) : null
      }
      sizeVariant={SelectSizeVariant.SM}
      value={selectedOwnerId}
      options={availableProjectOwners}
      onChange={handleChange}
    />
  );
};

export default ProjectOwnerControl;
