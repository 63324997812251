import { Tag as TagType } from '@payaca/types/tagTypes';
import { useMemo } from 'react';
import Combobox from '../plCombobox/Combobox';
import { SelectOption, Props as SelectProps } from '../plSelect/Select';
import Tag from '../tag/Tag';

type Props<TMultipleBoolean extends boolean> = Omit<
  SelectProps<
    TagType['id'],
    TMultipleBoolean,
    Pick<TagType, 'tagText' | 'tagColour'>
  >,
  'options' | 'listboxOptionClassName' | 'CustomOption'
> & {
  availableTags: Pick<TagType, 'id' | 'tagText' | 'tagColour'>[];
};

const TagSelectCombobox = <TMultipleBoolean extends boolean>({
  availableTags,
  value,
  multiple,
  onChange,
  CustomSelected,
  ...props
}: Props<TMultipleBoolean>) => {
  const sortedTags: SelectOption<
    TagType['id'],
    Pick<TagType, 'tagText' | 'tagColour'>
  >[] = useMemo(() => {
    return availableTags
      .sort((a, b) => {
        return a.tagText.localeCompare(b.tagText);
      })
      .map((tag) => {
        return {
          label: tag.tagText,
          value: tag.id,
          metadata: tag,
        };
      });
  }, [availableTags]);

  return (
    <Combobox
      options={sortedTags}
      comboboxOptionClassName="pl-2 pt-1 pb-1"
      CustomSelected={
        CustomSelected
          ? CustomSelected
          : ({ selectedOptions }) => {
              return (
                <div className="flex flex-row flex-wrap gap-1">
                  {selectedOptions?.map((o) => {
                    return (
                      <span key={o.value} className="min-w-0">
                        <Tag
                          tagText={o?.metadata?.tagText || ''}
                          colour={o?.metadata?.tagColour}
                          isRemovable={multiple}
                          onRemoveTag={
                            multiple
                              ? () => {
                                  onChange?.(
                                    (value as TagType['id'][]).filter(
                                      (v) => v !== o.value
                                    ) as TMultipleBoolean extends true
                                      ? TagType['id'][]
                                      : TagType['id']
                                  );
                                }
                              : undefined
                          }
                        />
                      </span>
                    );
                  })}
                </div>
              );
            }
      }
      CustomOption={({ option }) => {
        return (
          <span className="min-w-0">
            <Tag
              tagText={option?.metadata?.tagText || ''}
              colour={option?.metadata?.tagColour}
            />
          </span>
        );
      }}
      onChange={onChange}
      value={value}
      multiple={multiple}
      {...props}
    />
  );
};

export default TagSelectCombobox;
