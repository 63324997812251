import { FunctionComponent, useCallback, useState } from 'react';

import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from '@payaca/components/button/IconButton';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import ChecklistItemControl from './ChecklistItemControl';
import './ChecklistItemsControl.sass';

type Props = {
  checklistItems: {
    id?: number;
    name: string;
    completedAt?: Date;
  }[];
  fieldNamePrefix: string;
  touchedState?: { [fieldName: string]: boolean };
  validationState?: { [fieldName: string]: FieldValidationResult };
  onChange: (value: { [key: string]: any }) => void;
  onTouch?: (fieldName: string) => void;
};

const ChecklistItemsControl: FunctionComponent<Props> = ({
  checklistItems,
  fieldNamePrefix,
  touchedState,
  validationState,
  onTouch,
  onChange,
}: Props): JSX.Element | null => {
  const [autofocusIndex, setAutofocusIndex] = useState<number>();

  const onAdd = useCallback(() => {
    onChange({
      [fieldNamePrefix]: [...(checklistItems ?? []), {}],
    });
    setAutofocusIndex(checklistItems?.length || 0);
  }, [onChange, checklistItems, fieldNamePrefix]);

  const onRemove = useCallback(
    (index: number) => {
      checklistItems.splice(index, 1);
      onChange({ [fieldNamePrefix]: checklistItems });
    },
    [onChange, checklistItems, fieldNamePrefix]
  );

  const renderChecklistItemControl = useCallback(
    (checklistItem: { id?: number; name: string }, index: number) => {
      return (
        <div
          key={`checklist-item-control-${index}`}
          className="checklist-item-control-container flex-container"
        >
          <div className="flex-grow">
            <ChecklistItemControl
              fieldNamePrefix={`${fieldNamePrefix}[${index}]`}
              checklistItem={checklistItem}
              validationState={validationState}
              touchedState={touchedState}
              onChange={onChange}
              onTouch={onTouch}
              autofocus={autofocusIndex === index}
            />
          </div>
          <div className="delete-trigger-container">
            <span
              className="delete-checklist-item-trigger"
              onClick={() => onRemove(index)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
        </div>
      );
    },
    [
      onTouch,
      onChange,
      onRemove,
      validationState,
      touchedState,
      fieldNamePrefix,
      autofocusIndex,
    ]
  );

  return (
    <div className="checklist-items-control">
      {!!checklistItems?.length && (
        <div className="checklist-item-controls-wrapper">
          <div className="relationship-line-container">
            <div className="relationship-line"></div>
          </div>
          <div className="checklist-item-controls-container">
            {checklistItems.map(renderChecklistItemControl)}
          </div>
        </div>
      )}
      <div className="add-checklist-item-trigger-container">
        <div className="relationship-line-container">
          <div className="relationship-line"></div>
        </div>
        <div className="flex-container flex-center clickable" onClick={onAdd}>
          <IconButton icon={faPlus} size={'sm'} />
          <span>Add item</span>
        </div>
      </div>
    </div>
  );
};

export default ChecklistItemsControl;
