import { takeLatest } from 'redux-saga/effects';

import { BaseDispatch } from '@payaca/types/dispatchTypes';
import { ActionType, LocationSearchResult, SagaConfig } from './dispatchTypes';

import { Req } from '@payaca/helpers/storeHelper';

import { handleAsyncAction } from '../utils';

import { getDispatchPage, getLocationSearch } from './dispatchActions';

const listedTasksSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  const handleGetLocationSearch = handleAsyncAction(
    getLocationSearch,
    async (payload) => {
      try {
        const response = await req.get(
          `/dispatch/location_search?address=${payload.address}`
        );
        return await response.json();
      } catch (err) {
        console.log(
          `Get dispatch location search failed: ${JSON.stringify(err)}`
        );
        payload.onErrorCallback?.();
      }
    },
    async (response, requestData) => {
      requestData.payload.callback?.(response as LocationSearchResult);
    }
  );

  const handleGetDispatchPage = handleAsyncAction(
    getDispatchPage,
    async (payload) => {
      try {
        const response = await req.get(
          `/dispatch?startDate=${
            payload.getDispatchPageRequestData.startDate
          }&endDate=${payload.getDispatchPageRequestData.endDate}&${(
            payload.getDispatchPageRequestData.assignedTo || []
          )
            .map((at) => `assignedTo[]=${at}`)
            .join('&')}&${(
            payload.getDispatchPageRequestData.pipelineStages || []
          )
            .map((ps) => `pipelineStages[]=${ps}`)
            .join('&')}`
        );
        return await response.json();
      } catch (err) {
        console.log(`Get dispatch page failed: ${JSON.stringify(err)}`);
        payload.onErrorCallback?.();
      }
    },
    async (response, requestData) => {
      requestData.payload.callback?.(response as BaseDispatch[]);
    },
    undefined,
    250 // debounce so when skimming dates, we don't make unecessary requests
  );

  return function* () {
    yield takeLatest(
      ActionType.GET_LOCATION_SEARCH_REQUEST,
      handleGetLocationSearch
    );
    yield takeLatest(
      ActionType.GET_DISPATCH_PAGE_REQUEST,
      handleGetDispatchPage
    );
  };
};

export default listedTasksSagaCreator;
