import { action } from 'typesafe-actions';

import {
  AccountAccessInformation,
  AccountEmailTemplates,
} from '@payaca/types/accountTypes';
import { User } from '@payaca/types/userTypes';
import { createAsyncAction } from '../utils';
import {
  AccountActionTypes,
  AddOutboundEmailAddressAction,
  CheckOutboundEmailAddressConfirmation,
  CheckOutboundEmailDomainDkimVerification,
  DeleteOutboundEmailAddressAction,
  DeleteOutboundEmailDomainAction,
  GetConnectedAccountingIntegrationData,
  GetScheduledEventRemindersConfig,
  ResendOutboundEmailAddressConfirmationEmailAction,
  SetDefaultOutboundEmailAddressAction,
  SetPrimaryPipeline,
  UpdateScheduledEventRemindersConfig,
} from './accountTypes';

// Account users
export const requestGetAccountUsers = () => {
  return action(AccountActionTypes.REQUEST_GET_ACCOUNT_USERS);
};

export const getAccountUsersSuccess = (accountUsers: User[]) => {
  return action(AccountActionTypes.GET_ACCOUNT_USERS_SUCCESS, {
    accountUsers,
  });
};

export const getAccountUsersFailure = (error: Error) => {
  return action(
    AccountActionTypes.GET_ACCOUNT_USERS_FAILURE,
    null,
    null,
    error
  );
};

// Account access information
export const requestGetAccountAccessInformation = () => {
  return action(AccountActionTypes.REQUEST_GET_ACCOUNT_ACCESS_INFORMATION);
};

export const clearAccountAccessInformation = () => {
  return action(AccountActionTypes.CLEAR_ACCOUNT_ACCESS_INFORMATION);
};

export const getAccountAccessInformationSuccess = (
  accountAccessInformation: AccountAccessInformation
) => {
  return action(AccountActionTypes.GET_ACCOUNT_ACCESS_INFORMATION_SUCCESS, {
    accountAccessInformation,
  });
};

export const getAccountAccessInformationFailure = (error: Error) => {
  return action(
    AccountActionTypes.GET_ACCOUNT_ACCESS_INFORMATION_FAILURE,
    null,
    null,
    error
  );
};

// Update business settings prompt
export const setRequiresUpdateBusinessSettingsPrompt = (
  requiresUpdateBusinessSettingsPrompt: boolean
) => {
  return action(
    AccountActionTypes.SET_REQUIRES_UPDATE_BUSINESS_SETTINGS_PROMPT,
    {
      requiresUpdateBusinessSettingsPrompt,
    }
  );
};

// Book demo
export const requestBookDemo = (
  bookDemoFor: Date | null,
  isImmediateCallback: boolean
) => {
  return action(AccountActionTypes.REQUEST_BOOK_DEMO, {
    bookDemoFor,
    isImmediateCallback,
  });
};

export const bookDemoSuccess = () => {
  return action(AccountActionTypes.BOOK_DEMO_SUCCESS);
};

export const bookDemoFailure = (error: Error) => {
  return action(AccountActionTypes.BOOK_DEMO_FAILURE, null, null, error);
};

// Email templates
export const requestUpdateEmailTemplates = (
  accountId: number,
  emailTemplates: {
    sendQuote?: string;
    sendInvoice?: string;
    sendEstimate?: string;
  },
  callback: (err: any) => void
) => {
  return action(AccountActionTypes.REQUEST_UPDATE_EMAIL_TEMPLATES, {
    accountId,
    emailTemplates,
    callback,
  });
};

export const requestGetEmailTemplates = (
  accountId: number,
  includeDefaults = false
) => {
  return action(AccountActionTypes.REQUEST_GET_EMAIL_TEMPLATES, {
    accountId,
    includeDefaults,
  });
};

export const getEmailTemplatesSuccess = (
  emailTemplates: AccountEmailTemplates
) => {
  return action(AccountActionTypes.GET_EMAIL_TEMPLATES_SUCCESS, {
    emailTemplates,
  });
};

export const getEmailTemplatesFailure = (
  error: Error,
  errorMessage: string
) => {
  return action(
    AccountActionTypes.GET_EMAIL_TEMPLATES_FAILURE,
    { errorMessage },
    null,
    error
  );
};

export const storeEmailTemplates = (emailTemplates: AccountEmailTemplates) => {
  return action(AccountActionTypes.STORE_EMAIL_TEMPLATES, { emailTemplates });
};

export const clearEmailTemplates = () => {
  return action(AccountActionTypes.CLEAR_EMAIL_TEMPLATES);
};

export const addOutboundEmailAddress =
  createAsyncAction<AddOutboundEmailAddressAction>({
    request: AccountActionTypes.ADD_OUTBOUND_EMAIL_ADDRESS_REQUEST,
  })((email: string, cb?: (err?: Error) => void) => ({
    payload: {
      email,
      cb,
    },
  }));

export const deleteOutboundEmailAddress =
  createAsyncAction<DeleteOutboundEmailAddressAction>({
    request: AccountActionTypes.DELETE_OUTBOUND_EMAIL_ADDRESS_REQUEST,
  })((email: string, cb?: (err?: Error) => void) => ({
    payload: {
      email,
      cb,
    },
  }));

export const deleteOutboundEmailDomain =
  createAsyncAction<DeleteOutboundEmailDomainAction>({
    request: AccountActionTypes.DELETE_OUTBOUND_EMAIL_DOMAIN_REQUEST,
  })((domain: string, cb?: (err?: Error) => void) => ({
    payload: {
      domain,
      cb,
    },
  }));

export const resendOutboundEmailAddressConfirmationEmail =
  createAsyncAction<ResendOutboundEmailAddressConfirmationEmailAction>({
    request:
      AccountActionTypes.RESEND_OUTBOUND_EMAIL_ADDRESS_CONFIRMATION_EMAIL_REQUEST,
  })((email: string, cb?: (err?: Error) => void) => ({
    payload: {
      email,
      cb,
    },
  }));

export const checkOutboundEmailAddressConfirmation =
  createAsyncAction<CheckOutboundEmailAddressConfirmation>({
    request:
      AccountActionTypes.CHECK_OUTBOUND_EMAIL_ADDRESS_CONFIRMATION_REQUEST,
  })((email: string, cb?: (err?: Error) => void) => ({
    payload: {
      email,
      cb,
    },
  }));

export const checkOutboundEmailDomainDkimVerification =
  createAsyncAction<CheckOutboundEmailDomainDkimVerification>({
    request:
      AccountActionTypes.CHECK_OUTBOUND_EMAIL_DOMAIN_DKIM_VERIFICATION_REQUEST,
  })((domain: string, cb?: (err?: Error) => void) => ({
    payload: {
      domain,
      cb,
    },
  }));

export const setDefaultOutboundEmailAddress =
  createAsyncAction<SetDefaultOutboundEmailAddressAction>({
    request: AccountActionTypes.SET_DEFAULT_OUTBOUND_EMAIL_ADDRESS_REQUEST,
  })((purpose: string, email: string, cb?: (err?: Error) => void) => ({
    payload: {
      purpose,
      email,
      cb,
    },
  }));

export const setPrimaryPipeline = createAsyncAction<SetPrimaryPipeline>({
  request: AccountActionTypes.SET_PRIMARY_PIPELINE_REQUEST,
})((payload: SetPrimaryPipeline['request']['payload']) => {
  return {
    payload,
  };
});

export const getConnectedAccountingIntegrationData =
  createAsyncAction<GetConnectedAccountingIntegrationData>({
    request:
      AccountActionTypes.GET_CONNECTED_ACCOUNTING_INTEGRATION_DATA_REQUEST,
  })((payload: GetConnectedAccountingIntegrationData['request']['payload']) => {
    return {
      payload,
    };
  });

export const getScheduledEventRemindersConfig =
  createAsyncAction<GetScheduledEventRemindersConfig>({
    request: AccountActionTypes.GET_SCHEDULED_EVENT_REMINDERS_CONFIG_REQUEST,
  })((payload: GetScheduledEventRemindersConfig['request']['payload']) => {
    return {
      payload,
    };
  });

export const updateScheduledEventRemindersConfig =
  createAsyncAction<UpdateScheduledEventRemindersConfig>({
    request: AccountActionTypes.UPDATE_SCHEDULED_EVENT_REMINDERS_CONFIG_REQUEST,
  })((payload: UpdateScheduledEventRemindersConfig['request']['payload']) => {
    return {
      payload,
    };
  });
