import qs from 'qs';
import { FC, useEffect, useState } from 'react';

import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import LoginForm from '../loginForm/LoginForm';
// @ts-ignore
import scriptjs from 'scriptjs';

const ADDRESS = import.meta.env.VITE_API_HOST;

type Props = {
  hideForgotPassword?: boolean;
  onLoginCallback?: () => void;
};
const LoginControl: FC<Props> = ({
  hideForgotPassword = false,
  onLoginCallback,
}: Props): JSX.Element => {
  const [callbackLoginErrorMessage, setCallbackLoginErrorMessage] =
    useState<string>();

  useEffect(() => {
    scriptjs.get(
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
      () => {
        const params = {
          clientId: window.location.hostname.startsWith('localhost')
            ? 'staging.webapp.payaca.com'
            : window.location.hostname,
          redirectURI: `${ADDRESS}/api/apple/callback`,
          scope: 'name email',
          responseMode: 'form_post',
        };
        // @ts-ignore
        window.AppleID.auth.init(params);
      }
    );

    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.error) {
      // @ts-ignore
      setCallbackLoginErrorMessage(decodeURIComponent(query.error));
    }
  }, []);

  return (
    <div className="login-control">
      <LoginForm
        hideForgotPassword={hideForgotPassword}
        onLoginCallback={onLoginCallback}
      />
      {callbackLoginErrorMessage && (
        <ErrorMessage message={callbackLoginErrorMessage} />
      )}
    </div>
  );
};

export default LoginControl;
