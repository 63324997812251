import Modal from '@payaca/components/modal/Modal';
import { FunctionComponent } from 'react';
import { Call, Message } from 'react-iconly';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

import FacebookLogo from './facebook-logo.svg';
import InstagramLogo from './instagram-logo.svg';
import LinkedInLogo from './linkedIn-logo.svg';
import TwitterLogo from './twitter-logo.svg';

import './ContactUsModal.sass';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const ContactUsModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
}: Props): JSX.Element => {
  return (
    <Modal
      className="contact-us-modal"
      isOpen={isOpen}
      onClose={onClose}
      title="Contact us"
    >
      <div className="contact-methods-container">
        <a href="mailto:info@payaca.com" className="contact-method">
          <Message set="light" size="xlarge" />
          <span>info@payaca.com</span>
        </a>
        <a href="tel:03330509475" className="contact-method">
          <Call set="light" size="xlarge" />
          <span>0333 050 9475</span>
        </a>
      </div>
      <div className="feedback-prompt-container">
        <h3>Got something to mention?</h3>
        <p>We value all feedback left by our clients</p>
        <a
          href="https://review.capterra.com/Feedback-Payaca-235537-3210374114"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button styleVariant={ButtonStyleVariant.OUTSIZE}>
            Leave a review
          </Button>
        </a>
      </div>
      <div className="socials-container">
        <h3>Follow us on socials</h3>
        <div className="social-icons-container flex-container flex-center">
          <a
            href="https://www.facebook.com/app.payaca"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={FacebookLogo} />
          </a>
          <a
            href="https://www.linkedin.com/company/payaca/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={LinkedInLogo} />
          </a>
          <a
            href="https://twitter.com/payaca_app"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={TwitterLogo} />
          </a>
          <a
            href="https://www.instagram.com/payaca_app/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={InstagramLogo} />
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ContactUsModal;
