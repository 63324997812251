import { useSelector } from '@/api/state';
import Conditional from '@payaca/components/conditional/Conditional';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import Button from '@payaca/components/plButton/Button';
import Modal from '@payaca/components/plModal/Modal';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { requestUpdateScheduledEvent } from '@payaca/store/scheduledEvents/scheduledEventsActions';
import { UpdateScheduledEventRequestData } from '@payaca/store/scheduledEvents/scheduledEventsTypes';
import moment from 'moment-timezone';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Props as FieldsetProps,
  ScheduledEventFieldset,
  getFieldValidatorsForScheduledEvent,
} from '../scheduledEventFieldset/ScheduledEventFieldset';

type Props = {
  initialEventData: UpdateScheduledEventRequestData;
  onSuccess?: () => void;
} & Pick<FieldsetProps, 'hiddenFields' | 'disabledFields'>;

export const EditScheduledEventControl: FC<Props> = ({
  initialEventData,
  onSuccess,
  hiddenFields,
  disabledFields,
}) => {
  const dispatch = useDispatch();

  const errorMessage = useSelector((state) => {
    return state.scheduledEvents.updateScheduledEventErrorMessage
      ? 'Sorry, there was an error updating this Event. Please try again.'
      : null;
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [locationContactsCount, setLocationContactsCount] = useState(
    initialEventData?.location?.contacts?.length || 0
  );

  const fieldValidators = useMemo(() => {
    return getFieldValidatorsForScheduledEvent({ locationContactsCount });
  }, [locationContactsCount]);

  const onSubmit = (data: UpdateScheduledEventRequestData) => {
    setIsSubmitting(true);

    const contactsToNotify = data.contactsToNotify?.map((contact) => ({
      ...contact,
      id: typeof contact.id === 'number' ? contact.id : undefined,
    }));

    dispatch(
      requestUpdateScheduledEvent({ ...data, contactsToNotify }, () => {
        setIsSubmitting(false);
        onSuccess?.();
      })
    );
  };

  return (
    <div className="flex w-full flex-col gap-6">
      <ValidatedForm<UpdateScheduledEventRequestData>
        initialFormState={initialEventData}
        fieldValidators={fieldValidators}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => {
          useEffect(() => {
            setLocationContactsCount(formState.location?.contacts?.length || 0);
          }, [formState.location?.contacts?.length]);

          const [showValidationErrorsWarning, setShowValidationErrorsWarning] =
            useState(false);

          const isInPast = formState.endAt
            ? moment(formState.endAt).isBefore(moment())
            : false;

          return (
            <>
              <Modal.Body>
                <div className="flex flex-col items-start gap-6">
                  <ScheduledEventFieldset
                    eventData={formState}
                    onChange={onFieldChange}
                    validationState={validationState}
                    touchedState={touchedState}
                    onTouch={onFieldTouch}
                    hiddenFields={hiddenFields}
                    disabledFields={disabledFields}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer className="flex flex-col gap-y-4">
                <Conditional condition={isInPast}>
                  <Alert colour={EAlertColour.SOFT_YELLOW}>
                    You are editing a past event
                  </Alert>
                </Conditional>

                <Conditional
                  condition={showValidationErrorsWarning && !isValid}
                >
                  <Alert colour={EAlertColour.SOFT_RED}>
                    Form cannot be submitted whilst there are validation errors
                  </Alert>
                </Conditional>

                <Conditional condition={!!errorMessage}>
                  <Alert colour={EAlertColour.SOFT_RED}>{errorMessage}</Alert>
                </Conditional>

                <Modal.Footer.Actions>
                  <Button
                    isProcessing={isSubmitting}
                    onClick={() => {
                      if (isSubmitting) return;
                      if (!isValid) {
                        setShowValidationErrorsWarning(true);
                        onFieldTouch(
                          Object.entries(validationState)
                            .filter(([fieldName, validationResult]) => {
                              return !validationResult.isValid;
                            })
                            .map(([fieldName]) => fieldName)
                        );
                      } else {
                        onSubmit(formState);
                      }
                    }}
                  >
                    Update Event
                  </Button>
                </Modal.Footer.Actions>
              </Modal.Footer>
            </>
          );
        }}
      />
    </div>
  );
};
