import Button from '@payaca/components/plButton/Button';
import { EBtnColour } from '@payaca/components/plButton/useButtonClassName';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { DispatchPermissions } from '@payaca/permissions/dispatch/dispatch.permissions';
import { FC, useContext } from 'react';
import { useHistory } from 'react-router';
import { ScheduleContext } from '../contextProviders/ScheduleContextProvider';
import DispatchMap from '../dispatchMap/DispatchMap';
import Map from '../map/Map';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import Schedule from '../schedule/Schedule';

export const ScheduleDispatchMain = () => {
  const { displayType } = useContext(ScheduleContext);

  return (
    <div className="relative flex grow items-stretch">
      {displayType === 'map' ? <DispatchMapOrPreview /> : <Schedule />}
    </div>
  );
};

const DispatchMapOrPreview: FC = () => {
  const history = useHistory();
  return (
    <>
      <PermissionGuard
        renderIfMissingPermissions={[DispatchPermissions.GET_EVENTS]}
      >
        <>
          <Map />
          <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-gray-500 bg-opacity-5 p-4">
            <div className="bg-pc-blue-dark max-w-[675px] rounded-lg bg-opacity-80 px-10 py-8 text-white">
              <p className="mb-4 text-lg">
                Plan work more efficiently to optimise travel time with our map
                based dispatch feature.
              </p>
              <PermissionGuard
                renderIfHasPermissions={[AccountsPermissions.UPDATE_ACCOUNT]}
              >
                <div className="flex items-center justify-center">
                  <Button
                    colour={EBtnColour.Yellow}
                    onClick={() => history.push('/upgradeAccount')}
                  >
                    Explore options
                  </Button>
                </div>
              </PermissionGuard>
              <PermissionGuard
                renderIfMissingPermissions={[
                  AccountsPermissions.UPDATE_ACCOUNT,
                ]}
              >
                <p className="mb-0 text-base">
                  Contact your account admin to be able to use this feature
                </p>
              </PermissionGuard>
            </div>
          </div>
        </>
      </PermissionGuard>
      <PermissionGuard
        renderIfHasPermissions={[DispatchPermissions.GET_EVENTS]}
      >
        <DispatchMap />
      </PermissionGuard>
    </>
  );
};
