import { action } from 'typesafe-actions';

import { AvailableForm } from '@payaca/types/formTypes';
import { ActionType } from './formsTypes';

export const requestGetAvailableForms = () => {
  return action(ActionType.REQUEST_GET_AVAILABLE_FORMS);
};

export const getAvailableFormsSuccess = (forms: AvailableForm[]) => {
  return action(ActionType.GET_AVAILABLE_FORMS_SUCCESS, { forms });
};

export const getAvailableFormsFailure = (error: Error) => {
  return action(ActionType.GET_AVAILABLE_FORMS_FAILURE, null, null, error);
};

// GET form data
export const requestGetFormData = (formPreviewToken: string) => {
  return action(ActionType.REQUEST_GET_FORM_DATA, {
    formPreviewToken,
  });
};

export const getFormDataSuccess = (formPreviewToken: string, formData: any) => {
  return action(ActionType.GET_FORM_DATA_SUCCESS, {
    formPreviewToken,
    formData,
  });
};

export const getFormDataFailure = (error: Error) => {
  return action(ActionType.GET_FORM_DATA_FAILURE, null, null, error);
};

export const setFormProgress = (progress: any) => {
  return action(ActionType.SET_FORM_PROGRESS, progress);
};

export const requestCreateFormInstance = (onFormInstanceCreated?: any) => {
  return action(ActionType.REQUEST_CREATE_FORM_INSTANCE, {
    onFormInstanceCreated,
  });
};

export const createFormInstanceSuccess = (formPreviewToken: string) => {
  return action(ActionType.CREATE_FORM_INSTANCE_SUCCESS, {
    formPreviewToken,
  });
};

export const createFormInstanceFailure = (error: Error) => {
  return action(ActionType.CREATE_FORM_INSTANCE_FAILURE, null, error);
};

export const clearFormData = (formPreviewToken: string) => {
  return action(ActionType.CLEAR_FORM_DATA, {
    formPreviewToken,
  });
};

export const requestUpdateFormData = (
  formPreviewToken: string,
  formData: any,
  cb: any
) => {
  return action(ActionType.REQUEST_UPDATE_FORM_DATA, {
    formPreviewToken,
    formData,
    cb,
  });
};

export const updateFormDataSuccess = (
  formPreviewToken: string,
  formData: any
) => {
  return action(ActionType.UPDATE_FORM_DATA_SUCCESS, {
    formPreviewToken,
    formData,
  });
};

export const updateFormDataFailure = (
  formPreviewToken: string,
  error: Error
) => {
  return action(ActionType.UPDATE_FORM_DATA_FAILURE, null, null, error);
};

export const requestSubmitForm = (
  formPreviewToken: string,
  callback: (error?: string) => void
) => {
  return action(ActionType.REQUEST_SUBMIT_FORM, {
    formPreviewToken,
    callback,
  });
};

export const submitFormSuccess = (formPreviewToken: string) => {
  return action(ActionType.SUBMIT_FORM_SUCCESS, {
    formPreviewToken,
  });
};

export const submitFormFailure = (formPreviewToken: string, error: Error) => {
  return action(ActionType.SUBMIT_FORM_FAILURE, null, null, error);
};

export const requestSearchFormItems = (
  type: string,
  targetCategory: string,
  ...searchTerms: any
) => {
  return action(ActionType.REQUEST_SEARCH_FORM_ITEMS, {
    type,
    targetCategory,
    one: searchTerms[0],
    two: searchTerms[1],
    three: searchTerms[2],
    four: searchTerms[3],
    five: searchTerms[4],
  });
};

export const searchFormItemsSuccess = (type: string, items: any[]) => {
  return action(ActionType.SEARCH_FORM_ITEMS_SUCCESS, {
    type,
    items,
  });
};

export const searchFormItemsFailure = (error: Error) => {
  return action(ActionType.SEARCH_FORM_ITEMS_FAILURE, null, null, error);
};

export const requestSendForm = (
  formInstanceId: number,
  sendToEmail: string,
  emailBody: string,
  sendACopy: boolean,
  callback?: any
) => {
  return action(ActionType.REQUEST_SEND_FORM, {
    formInstanceId,
    sendToEmail,
    emailBody,
    sendACopy,
    callback,
  });
};

export const sendFormSuccess = () => {
  return action(ActionType.SEND_FORM_SUCCESS);
};

export const sendFormFailure = (error: Error) => {
  return action(ActionType.SEND_FORM_FAILURE, null, null, error);
};
