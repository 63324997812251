import { FunctionComponent, useRef, useState } from 'react';

import QuickAction from './QuickAction';

import './QuickActionsTableCell.sass';

type Props = {
  recordId: any;
  quickActions: {
    actionName: string;
    actionBehaviour: (recordId: any) => void;
    isActionProcessing?: boolean;
  }[];
  isTrExclude?: boolean;
};

const QuickActionsTableCell: FunctionComponent<Props> = ({
  recordId,
  quickActions,
  isTrExclude = true,
}: Props): JSX.Element | null => {
  const ref = useRef<HTMLTableDataCellElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <td
      className={`quick-actions-table-cell${isTrExclude ? ' tr-exclude' : ''}${
        quickActions.length ? ' clickable' : ''
      }`}
      ref={ref}
      onClick={(e) => {
        setIsOpen(true);
        e.stopPropagation();
      }}
    >
      <QuickAction
        recordId={recordId}
        quickActions={quickActions}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        anchor={ref}
      />
    </td>
  );
};

export default QuickActionsTableCell;
