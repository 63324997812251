import { action } from 'typesafe-actions';

import { Upload, UploadAttachableEntityType } from '@payaca/types/uploadTypes';

import { createAsyncAction } from '../utils';
import {
  ActionType,
  GetUploads,
  GetUploadsWithRoleForEntity,
  LinkUploadsToEntity,
  PersistUploadAndLinkToEntityRequestData,
  PersistUploadRequestData,
  PersistUploadsAndLinkToEntity,
  RenameUpload,
  UnlinkUploadFromEntity,
} from './uploadsTypes';

export const requestPersistUpload = (
  persistUploadRequestData: PersistUploadRequestData,
  callback?: (uploadId: number) => void
) =>
  action(ActionType.REQUEST_PERSIST_UPLOAD, {
    persistUploadRequestData,
    callback,
  });

export const persistUploadSuccess = () =>
  action(ActionType.PERSIST_UPLOAD_SUCCESS);

export const persistUploadFailure = (error: Error) =>
  action(ActionType.PERSIST_UPLOAD_FAILURE, null, null, error);

export const requestPersistUploadAndLinkToEntity = (
  persistUploadAndLinkToEntityRequestData: PersistUploadAndLinkToEntityRequestData,
  callback?: (
    error: string | null,
    upload?: { id: number; url: string; fileName: string }
  ) => void
) =>
  action(ActionType.REQUEST_PERSIST_UPLOAD_AND_LINK_TO_ENTITY, {
    persistUploadAndLinkToEntityRequestData,
    callback,
  });

export const persistUploadAndLinkToEntitySuccess = () =>
  action(ActionType.PERSIST_UPLOAD_AND_LINK_TO_ENTITY_SUCCESS);

export const persistUploadAndLinkToEntityFailure = (error: Error) =>
  action(
    ActionType.PERSIST_UPLOAD_AND_LINK_TO_ENTITY_FAILURE,
    null,
    null,
    error
  );

export const requestDeleteUpload = (
  uploadId: number,
  callback?: (error: string | null) => void,
  continueDeleteIfAnyLinkedEntities?: boolean
) =>
  action(ActionType.REQUEST_DELETE_UPLOAD, {
    uploadId,
    callback,
    continueDeleteIfAnyLinkedEntities,
  });

export const deleteUploadSuccess = (uploadId: number) =>
  action(ActionType.DELETE_UPLOAD_SUCCESS, { uploadId });

export const deleteUploadFailure = (error: Error) =>
  action(ActionType.DELETE_UPLOAD_FAILURE, null, null, error);

export const requestGetUpload = (uploadId: number) =>
  action(ActionType.REQUEST_GET_UPLOAD, {
    uploadId,
  });

export const requestGetUploadsForEntity = (
  entityId: number,
  entityType: UploadAttachableEntityType,
  callback?: (uploads: Upload[]) => void
) =>
  action(ActionType.REQUEST_GET_UPLOADS_FOR_ENTITY, {
    entityId,
    entityType,
    callback,
  });

export const getUploadsForEntitySuccess = (uploads: Array<Upload>) =>
  action(ActionType.GET_UPLOADS_FOR_ENTITY_SUCCESS, uploads);

export const getUploadSuccess = (uploadId: number, upload: Upload) =>
  action(ActionType.GET_UPLOAD_SUCCESS, {
    uploadId,
    upload,
  });

export const getUploadFailure = (uploadId: number, error: Error) =>
  action(
    ActionType.GET_UPLOAD_FAILURE,
    {
      uploadId,
    },
    null,
    error
  );

export const clearUploads = () => action(ActionType.CLEAR_UPLOADS);

export const persistUploadsAndLinkToEntity =
  createAsyncAction<PersistUploadsAndLinkToEntity>({
    request: ActionType.PERSIST_UPLOADS_AND_LINK_TO_ENTITY_REQUEST,
    success: ActionType.PERSIST_UPLOADS_AND_LINK_TO_ENTITY_SUCCESS,
    failure: ActionType.PERSIST_UPLOADS_AND_LINK_TO_ENTITY_FAILURE,
  })((payload: PersistUploadsAndLinkToEntity['request']['payload']) => ({
    payload,
  }));

export const linkUploadsToEntity = createAsyncAction<LinkUploadsToEntity>({
  request: ActionType.LINK_UPLOADS_TO_ENTITY_REQUEST,
})((payload: LinkUploadsToEntity['request']['payload']) => ({
  payload,
}));

export const getUploads = createAsyncAction<GetUploads>({
  request: ActionType.GET_UPLOADS_REQUEST,
  success: ActionType.GET_UPLOADS_SUCCESS,
})((payload: GetUploads['request']['payload']) => ({
  payload,
}));

export const getUploadsWithRoleForEntity =
  createAsyncAction<GetUploadsWithRoleForEntity>({
    request: ActionType.GET_UPLOADS_WITH_ROLE_FOR_ENTITY_REQUEST,
    success: ActionType.GET_UPLOADS_SUCCESS,
  })((payload: GetUploadsWithRoleForEntity['request']['payload']) => ({
    payload,
  }));

export const renameUpload = createAsyncAction<RenameUpload>({
  request: ActionType.RENAME_UPLOAD_REQUEST,
})((payload: RenameUpload['request']['payload']) => ({
  payload,
}));

export const unlinkUpload = createAsyncAction<UnlinkUploadFromEntity>({
  request: ActionType.UNLINK_UPLOAD,
})((payload: UnlinkUploadFromEntity['request']['payload']) => ({
  payload,
}));
