import { currencyLabel } from '@/helpers/currency';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import ChartTooltip from '@payaca/components/plCharts/ChartTooltip';
import Progress from '@payaca/components/plProgress/Progress';
import FloatingTooltip from '@payaca/components/plTooltip/FloatingTooltip';
import { FC } from 'react';

export interface IProps {
  className?: string;
  financialSummary?: {
    totalProjectValue: number;
    projectProgressPercentage: number;
    toBeInvoiced?: number;
    toBePaid?: number;
    paid?: number;
    currency: { code: string; exponent: number };
  };
}

const ProjectProgress: FC<IProps> = (props) => {
  const { className, financialSummary } = props;

  const progressBars = (() => {
    const bars = [];

    if (!financialSummary || financialSummary.totalProjectValue <= 0) {
      return [];
    }

    if (financialSummary.paid && financialSummary.paid > 0) {
      bars.push({
        progress: financialSummary.paid,
        colour: 'custom' as const,
        customColourClass: 'bg-purple-800',
        total: financialSummary.totalProjectValue,
      });
    }

    if (financialSummary.toBePaid && financialSummary.toBePaid > 0) {
      bars.push({
        progress:
          financialSummary.paid && financialSummary.paid > 0
            ? financialSummary.paid + financialSummary.toBePaid
            : financialSummary.toBePaid,
        colour: 'custom' as const,
        customColourClass: 'bg-purple-300',
        total: financialSummary.totalProjectValue,
      });
    }

    return bars;
  })();

  const toolTipKeys = [
    {
      label: 'Paid',
      value: currencyLabel(
        financialSummary?.paid || 0,
        financialSummary?.currency.code
      ),
      colour: '#5532a5',
    },
    {
      label: 'To be paid',
      value: currencyLabel(
        financialSummary?.toBePaid || 0,
        financialSummary?.currency.code
      ),
      colour: '#c4bdf5',
    },
    {
      label: 'To be invoiced',
      value: currencyLabel(
        financialSummary?.toBeInvoiced || 0,
        financialSummary?.currency.code
      ),
      colour: '#e5e7eb',
    },
  ];

  return (
    <Card className={className} sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <h3>Project progress</h3>
        <h1>{financialSummary?.projectProgressPercentage || 0}%</h1>

        <FloatingTooltip>
          <Progress className="my-3" size="lg" bars={progressBars} />

          <ChartTooltip title="Progress" keys={toolTipKeys} />
        </FloatingTooltip>

        <p>Project progress is tracked by the percentage invoiced</p>
      </Card.Body>
    </Card>
  );
};

export default ProjectProgress;
