import { Account } from './accountTypes';
import { Address } from './locationTypes';
import { BaseMaterial, BaseSupplierMaterial, Material } from './materialTypes';
import { ReferencedEntity } from './referencedEntityTypes';
import { SendableEntity } from './sendableEntityTypes';
import { Supplier } from './supplierTypes';
import { TaxRate } from './taxRateTypes';
import { TimestampedEntity } from './timestampedEntityTypes';
import { VoidableEntity } from './voidableEntityTypes';

export interface BaseMaterialsList extends TimestampedEntity {
  id: number;
  accountId: number;
}

export interface MaterialsListRelationships {
  materialsListMaterialIds: number[];
  materialPurchaseRecordIds: number[];
  purchaseOrderIds: number[];
  materialPurchaseIntentIds: number[];
}

export interface MaterialsListTotals {
  purchasedValueExcludingTax: number;
  outstandingPredictedValueExcludingTax: number;
  predictedValueExcludingTax: number;
  isFullyPurchased: boolean;

  isFullyPurchaseIntended: boolean;
}

export interface MaterialsList
  extends BaseMaterialsList,
    MaterialsListRelationships,
    MaterialsListTotals {}

export interface BaseMaterialsListMaterial extends TimestampedEntity {
  id: number;
  materialId: number;
  materialsListId: number;
  materialQuantity: number;
  autopopulatedQuantity: number;
}

export interface MaterialsListMaterialRelationships {
  materialPurchaseRecordIds: number[];
  materialPurchaseIntentIds: number[];
}

export interface MaterialsListMaterialTotals {
  purchasedMaterialQuantity: number;
  outstandingMaterialQuantity: number;
  purchasedValueExcludingTax: number;
  outstandingPredictedValueExcludingTax: number;
  predictedValueExcludingTax: number;
  isFullyPurchased: boolean;

  purchaseIntendedMaterialQuantity: number;
  isFullyPurchaseIntended: boolean;
}

export interface MaterialsListMaterial
  extends BaseMaterialsListMaterial,
    MaterialsListMaterialTotals,
    MaterialsListMaterialRelationships {}

export interface MaterialPurchaseRecordTotals {
  totalPrice: number;
  totalPriceExcludingTax: number;
}

export interface MaterialPurchaseRecord
  extends TimestampedEntity,
    MaterialPurchaseRecordTotals {
  id: number;
  materialsListMaterialId: number;
  supplierId?: number;
  materialQuantity: number;
  price: number | null;
  taxPercentage: number | null;
  isTaxIncluded: boolean | null;
  taxRateId: number | null;
  materialsListId: number;
  purchaseOrderId?: number;
}

export interface BasePurchaseOrder
  extends TimestampedEntity,
    SendableEntity,
    VoidableEntity,
    ReferencedEntity {
  id: number;
  materialsListId: number;
  supplierId: number;
  confirmedAt: Date | null;
  additionalNotes: string | null;
  xeroPurchaseOrderId: string | null;
  isIsolated: boolean | null;
  deliveryAddressId: Address['id'] | null;
  dueAt: Date | null;
  showMaterialPrices: boolean | null;
}

export enum PurchaseOrderStatus {
  SENT = 'Sent',
  VOID = 'Void',
  PURCHASED = 'Purchased',
  DRAFT = 'Draft',
}

export interface PurchaseOrderRelationships {
  materialPurchaseIntentIds: number[];
  materialPurchaseRecordIds: number[];
  pdfUrl: string;
}

export interface PurchaseOrderTotals {
  confirmedTotal?: number;
  confirmedTotalExcludingTax?: number;
}

export interface PurchaseOrder
  extends BasePurchaseOrder,
    PurchaseOrderRelationships,
    PurchaseOrderTotals {
  status: PurchaseOrderStatus;
}

export interface MaterialPurchaseIntentTotals {
  predictedUnitPriceExcludingTax: number;
  predictedTotalPriceExcludingTax: number;
}

export interface MaterialPurchaseIntent
  extends TimestampedEntity,
    VoidableEntity,
    MaterialPurchaseIntentTotals {
  id: number;
  materialsListId: number;
  materialsListMaterialId: number;
  supplierId?: number;
  purchaseOrderId?: number;
  materialQuantity: number;
  confirmedAt?: Date;
}

export enum PurchaseOrderUploadRoles {
  GENERATED_PDF = 'generated-pdf',
}

export type HydratedMaterialPurchaseIntent = MaterialPurchaseIntent & {
  materialsListMaterial: BaseMaterialsListMaterial;
  material: BaseMaterial & {
    thumbnailUrl: Material['thumbnailUrl'];
    category: Material['category'];
  };
  supplierMaterialReference: BaseSupplierMaterial['reference'];
  predictedTaxRateId?: TaxRate['id'];
};

export type HydratedMaterialPurchaseRecord = MaterialPurchaseRecord & {
  materialsListMaterial: BaseMaterialsListMaterial;
  material: BaseMaterial & {
    thumbnailUrl: Material['thumbnailUrl'];
    category: Material['category'];
  };
};

// this is a really tricky type to define, as the relationships between a lot of these entities could be circular
// This form is useful to me for the moment, but we may have to change the definition in the future
export type HydratedPurchaseOrder = BasePurchaseOrder & {
  pdfUrl: PurchaseOrderRelationships['pdfUrl'];
  status: PurchaseOrderStatus;
  accountId: Account['id'];
  supplier: Supplier;

  materialPurchaseRecords: HydratedMaterialPurchaseRecord[];
  materialPurchaseIntents: HydratedMaterialPurchaseIntent[];
};

export type BaseSupplierMaterialFulfillment = {
  isFullFulfillment: boolean;
  supplierId: number;
  branch: {
    id: string;
    name: string;
  };
};

export type SupplierMaterialDeliveryFulfillment =
  BaseSupplierMaterialFulfillment & { deliveryFromDate: Date };
export type SupplierMaterialCollectionFulfillment =
  BaseSupplierMaterialFulfillment & { collectionFromDate: Date };
