import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { actions as appActions } from '@/api/app';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

import './DownloadAppPage.sass';

import AppleIcon from '@/assets/images/apple.svg';
import GooglePlayIcon from '@/assets/images/google-play.svg';
import IphoneIcon from '@/assets/images/iphone-image.png';

type Props = {
  history: any;
  pathname: string;
};
const DownloadAppPage: FC<Props> = ({
  history,
  pathname,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  return (
    <div className="download-app-page">
      <div className="download-app-page-inner">
        <div className="download-app-title">
          For the best experience please download our mobile app or visit this
          website from a computer or tablet.
        </div>
        <div className="download-app-button-container">
          <Button
            onClick={() => {
              dispatch(appActions.dismissMobileMode());
              if (pathname) {
                history.push(pathname);
              }
            }}
            styleVariant={ButtonStyleVariant.OUTSIZE}
          >
            Proceed anyway
          </Button>
        </div>
        <div className="download-apps">
          <a href="https://apps.apple.com/us/app/payaca/id1455123031?ls=1">
            <img alt="Download iPhone App" src={AppleIcon} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.payaca.mobileapp">
            <img alt="Download Android App" src={GooglePlayIcon} />
          </a>
        </div>
        <img className="phone-image" alt="Download app" src={IphoneIcon} />
      </div>
    </div>
  );
};

export default DownloadAppPage;
