import { AvatarSizeVariant } from '@payaca/components/plAvatar/Avatar';
import Button from '@payaca/components/plButton/Button';
import Modal from '@payaca/components/plModal/Modal';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import UserAvatar from '@payaca/components/userAvatar/UserAvatar';
import {
  getAcceptedFileTypes,
  getMbFileLimit,
} from '@payaca/helpers/fileHelper';
import { requestPersistUploadAndLinkToEntity } from '@payaca/store/uploads/uploadsActions';
import {
  ImageEditConfig,
  UPLOAD_MAX_FILESIZE_LIMIT,
} from '@payaca/types/uploadTypes';
import UntitledIcon from '@payaca/untitled-icons';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper, { Area } from 'react-easy-crop';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import useGetMyAccountBranding from '../../../../api/queries/me/useGetMyAccountBranding';
import { useGetUser } from '../../../../api/queries/user/useGetUser';

const avatarOverlayClassName =
  'absolute rounded-full flex justify-center items-center w-[9rem] h-[9rem]';

const imageFileTypes = getAcceptedFileTypes(['image']);

const UserPageHeaders: FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const dispatch = useDispatch();

  const { data: userData, refetch: refetchUserData } = useGetUser(+userId);
  const { data: accountBranding } = useGetMyAccountBranding();
  const { pushToast } = useToastContext();

  const [image, setImage] = useState<string>();
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);

  const user = userData?.user;

  const onUploadFile = (editConfig: ImageEditConfig) => {
    if (!file) {
      return;
    }
    setIsLoading(true);

    dispatch(
      requestPersistUploadAndLinkToEntity(
        {
          file,
          fileName: file.name,
          entityId: +userId,
          entityType: 'user',
          entityRole: 'user-avatar',
          editConfig,
        },
        (error) => {
          if (error) {
            pushToast({
              variant: 'white',
              icon: 'error',
              message: 'Failed to upload avatar',
            });
          } else {
            void refetchUserData();
          }
        }
      )
    );

    setIsLoading(false);
  };
  const { getRootProps, getInputProps } = useDropzone({
    disabled: isLoading,
    maxFiles: 1,
    accept: imageFileTypes,
    onDrop: (files) => {
      const file = files[0];
      if (file.size > UPLOAD_MAX_FILESIZE_LIMIT) {
        pushToast({
          variant: 'white',
          icon: 'error',
          message: `File size exceeds the ${getMbFileLimit(
            UPLOAD_MAX_FILESIZE_LIMIT
          )} limit.`,
        });
        return;
      }
      setFile(files[0]);
      setImage(URL.createObjectURL(files[0]));
    },
  });

  return (
    <div>
      <div
        className="w-full h-[160px] rounded-xl"
        style={{
          background:
            (userData?.user.avatarUrl
              ? userData.user.colour
              : accountBranding?.theme?.primaryColour) || '',
        }}
      />
      <div className="flex flex-col items-center justify-items-center -mt-[90px]">
        {!user ? (
          <>
            <SkeletonLoader.Circle className="w-[9rem] h-[9rem]" />
            <SkeletonLoader.Title className="w-1/6 mt-2" />
          </>
        ) : (
          <div className="flex flex-col items-center">
            {isLoading ? (
              <SkeletonLoader.Circle className="w-[9rem] h-[9rem]" />
            ) : (
              <>
                <div>
                  <UserAvatar
                    sizeVariant={AvatarSizeVariant.XL}
                    user={{
                      firstName: user.firstName,
                      lastName: user.lastName,
                      emailAddress: user.email || '',
                      userColour: user.colour || undefined,
                      imgSrc: user.avatarUrl || undefined,
                    }}
                  />
                </div>
                {/* hover peer overlay */}
                <div
                  className={clsx(
                    avatarOverlayClassName,
                    'peer z-10 cursor-pointer'
                  )}
                  {...getRootProps()}
                />
                <input {...getInputProps()} />

                {/* transparent overlay */}
                <div
                  className={clsx(
                    avatarOverlayClassName,
                    'opacity-0 peer-hover:opacity-40 transition-opacity bg-white'
                  )}
                />

                {/* pencil overlay */}
                <div
                  className={clsx(
                    avatarOverlayClassName,
                    'opacity-0 peer-hover:opacity-100 transition-all'
                  )}
                >
                  <UntitledIcon
                    name="edit-01.3"
                    className="h-6 w-6 text-blue-900"
                  />
                </div>

                <h1 className="mt-2">{user.fullName}</h1>
              </>
            )}
          </div>
        )}
      </div>
      <ImageCropModal
        isOpen={!!image}
        onClose={() => setImage(undefined)}
        image={image}
        onUploadFile={onUploadFile}
      />
    </div>
  );
};

type ImageCropModalProps = {
  isOpen: boolean;
  onClose: () => void;
  image?: string;
  onUploadFile: (editConfig: ImageEditConfig) => void;
};
const ImageCropModal: FC<ImageCropModalProps> = (props) => {
  const { isOpen, onClose, image, onUploadFile } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  if (!image) {
    return null;
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Change profile photo">
      <Modal.Body>
        <div className="relative h-[300px]">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={(_croppedArea: Area, croppedAreaPixels: Area) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
            objectFit="cover"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            onClick={() => {
              if (croppedAreaPixels) {
                onUploadFile({
                  crop: croppedAreaPixels,
                  resize: { width: 200, height: 200 },
                });
                onClose();
              }
            }}
            disabled={!croppedAreaPixels}
          >
            Upload
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default UserPageHeaders;
