import Tooltip from '@payaca/components/plTooltip/Tooltip';
import { differenceInDays } from 'date-fns';
import { FC } from 'react';
import useGetMyRegionPreferences from '../../../../../../api/queries/me/useGetMyRegionPreferences';
import {
  DeliveryFulfillmentOption,
  FulfillmentOption,
  getFromAvailabilityDate,
  getFulfillmentDate,
  isCollectionFulfillment,
  LiveMaterialSupplierBadgeProps,
} from './fulfillment';
import LiveAvailabilityMaterialSupplierBadgeTooltipContent from './LiveAvailabilityMaterialSupplierBadgeTooltipContent';
import LiveAvailabilitySupplierBadge from './LiveAvailabiltySupplierBadge';

/**
 * Get delivery options - the soonest full delivery option, and soonest partial delivery if earlier than full delivery
 */
const getSpecifiedQuantityDeliveryOptions = (
  fulfillments: FulfillmentOption[]
) => {
  const f = fulfillments
    .sort(
      (a, b) =>
        getFulfillmentDate(a).getTime() - getFulfillmentDate(b).getTime()
    )
    .filter((f) => !isCollectionFulfillment(f)) as DeliveryFulfillmentOption[];
  const options: DeliveryFulfillmentOption[] = [];
  const firstFullFulfillment = f.find((f) => f.isFullFulfillment);
  if (firstFullFulfillment) {
    options.push(firstFullFulfillment);
  }
  const firstPartialFulfillment = f.find((f) => !f.isFullFulfillment);
  if (firstPartialFulfillment) {
    let addPartialDeliveryToOptions = false;
    if (firstFullFulfillment) {
      const deliveryDaysDifference = differenceInDays(
        firstFullFulfillment?.deliveryFromDate,
        firstPartialFulfillment.deliveryFromDate
      );
      if (deliveryDaysDifference > 0) {
        addPartialDeliveryToOptions = true;
      }
    } else {
      addPartialDeliveryToOptions = true;
    }
    if (addPartialDeliveryToOptions) {
      options.push(firstPartialFulfillment);
    }
  }

  return options;
};

const getSpecifiedQuantityFulfillmentOptionText = (
  f: FulfillmentOption,
  locale?: string
) => {
  const isCollection = isCollectionFulfillment(f);

  const dateString = getFromAvailabilityDate(
    getFulfillmentDate(f),
    f.__typename === 'SupplierMaterialCollectionFulfillment',
    locale
  );
  const branchLocationString = isCollection ? `at ${f.branch.name} ` : '';
  if (f?.isFullFulfillment) {
    return `Available ${branchLocationString}${dateString}`;
  }
  return `Partial order available ${branchLocationString}${dateString}`;
};

const LiveSpecifiedQuantityMaterialSupplierBadge: FC<
  LiveMaterialSupplierBadgeProps
> = (props) => {
  const { supplier, fulfillmentOptions } = props;
  const { data: regionPreferences } = useGetMyRegionPreferences();

  const deliveryOptions =
    getSpecifiedQuantityDeliveryOptions(fulfillmentOptions);
  const collectionOptions = fulfillmentOptions.filter(isCollectionFulfillment);

  return (
    <Tooltip
      className="mr-auto"
      tooltipContent={
        <LiveAvailabilityMaterialSupplierBadgeTooltipContent
          deliveryOptions={deliveryOptions}
          collectionOptions={collectionOptions}
          getFulfillmentOptionText={(f) =>
            getSpecifiedQuantityFulfillmentOptionText(
              f,
              regionPreferences?.locale
            )
          }
        />
      }
    >
      <LiveAvailabilitySupplierBadge
        supplier={{
          id: supplier.id,
          name: supplier.name,
        }}
        deliveryOptions={deliveryOptions}
        collectionOptions={collectionOptions}
      />
    </Tooltip>
  );
};

export default LiveSpecifiedQuantityMaterialSupplierBadge;
