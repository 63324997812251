import { FC } from 'react';

import EmailPreview from './EmailPreview';

import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import './SendableDocumentEmailControl.sass';

type Props = {
  emailSubject: string;
  emailState: {
    sendMeACopy: boolean;
    emailCopy: {
      preButton: string;
      postButton: string;
    };
  };
  onChange: (value?: { [key: string]: any }) => void;
  sendToAddress?: string;
  buttonText: string;
};
const SendableDocumentEmailControl: FC<Props> = ({
  emailSubject,
  emailState,
  onChange,
  sendToAddress,
  buttonText,
}: Props): JSX.Element => {
  return (
    <div className="sendable-document-email-control">
      <div className="addressee-and-subject-container">
        <div className="addressee-container">
          {sendToAddress && (
            <>
              <div>
                <strong>To:</strong>
              </div>
              <span>{sendToAddress}</span>
            </>
          )}
        </div>
        <div className="subject-container">
          <div>
            <strong>Subject:</strong>
          </div>
          <div>{emailSubject}</div>
        </div>
      </div>
      <div className="email-options-container">
        <Checkbox
          label="Send me a copy"
          onChange={(e) => onChange({ sendMeACopy: e.target.checked })}
          checked={emailState.sendMeACopy}
        />
      </div>

      <EmailPreview
        buttonText={buttonText}
        emailCopy={emailState.emailCopy}
        onChange={(value) =>
          onChange({ emailCopy: { ...emailState.emailCopy, ...value } })
        }
      />
    </div>
  );
};

export default SendableDocumentEmailControl;
