import { actions as jobActions } from '@/api/jobs';
import Button from '@payaca/components/plButton/Button';
import { EBtnColour } from '@payaca/components/plButton/useButtonClassName';
import Modal, { IProps as ModalProps } from '@payaca/components/plModal/Modal';
import { Deal } from '@payaca/types/dealTypes';
import { Job } from '@payaca/types/jobTypesV2';
import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import useRemoveAccepted from '../../../api/mutations/proposals/useRemoveAccepted';

type Props = {
  removeAcceptedCallback: () => void;
  proposalId: Job['id'];
  projectVersion?: Deal['version'];
} & Omit<ModalProps, 'title'>;
const RemoveAcceptedModal: FC<Props> = (props) => {
  const { proposalId, removeAcceptedCallback, projectVersion, ...rest } = props;
  const dispatch = useDispatch();
  const [isUnacceptingJob, setIsUnacceptingJob] = useState(false);

  const { mutateAsync: removeAcceptedMutation } = useRemoveAccepted({
    onSuccess: () => {
      setIsUnacceptingJob(false);
      removeAcceptedCallback();
    },
  });

  const unacceptJob = useCallback(async () => {
    setIsUnacceptingJob(true);
    if (projectVersion && projectVersion > 1) {
      await removeAcceptedMutation({ proposalId: proposalId.toString() });
    } else {
      dispatch(
        jobActions.unacceptQuote(proposalId, () => {
          removeAcceptedCallback();
          setIsUnacceptingJob(false);
        })
      );
    }
  }, [proposalId, removeAcceptedCallback]);

  return (
    <Modal {...rest} title="Remove acceptance">
      <Modal.Body>
        <p>
          {
            "This will remove the signature from the Proposal and can't be undone."
          }
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            onClick={unacceptJob}
            isProcessing={isUnacceptingJob}
            colour={EBtnColour.Red}
          >
            Remove signature
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};
export default RemoveAcceptedModal;
