import Button from '@payaca/components/plButton/Button';
import { EBtnColour } from '@payaca/components/plButton/useButtonClassName';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import { FC } from 'react';

type Props = {
  isProcessing?: boolean;
  onConfirm: () => void;
} & Omit<IModalProps, 'title'>;

const ConfirmDeleteSupplierMaterialModal: FC<Props> = (props) => {
  const { isProcessing = false, onConfirm, ...rest } = props;

  return (
    <Modal title="Delete Supplier Material" {...rest}>
      <Modal.Body>
        <p>Are you sure you want to delete this Supplier Material?</p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            isProcessing={isProcessing}
            onClick={onConfirm}
            colour={EBtnColour.Red}
          >
            Delete
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteSupplierMaterialModal;
