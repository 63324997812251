import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useChangeProposal } from '@payaca/store/hooks/appState';
import {
  requestGetChangeProposal,
  requestPersistChangeProposal,
} from '@payaca/store/proposals/proposalsActions';

import { ChangeProposal } from '@payaca/types/changeProposalTypes';

import BasicField from '@payaca/components/basicField/BasicField';
import ChangeProposalValueSummary from '../changeProposalValueSummary/ChangeProposalValueSummary';
import DealPaymentOptionsControl from '../dealPaymentOptionsControl/DealPaymentOptionsControl';
import DealPriceDetailControl from '../dealPriceDetailControl/DealPriceDetailControl';

import './ChangeProposalSidePanel.sass';

type Props = {
  changeProposalId: number;
};

const ChangeProposalSidePanel: FC<Props> = ({
  changeProposalId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const changeProposal: ChangeProposal | undefined =
    useChangeProposal(changeProposalId);

  const [expiresInDays, setExpiresInDays] = useState<number>();
  const [isSaveRequired, setIsSaveRequired] = useState(false);

  useEffect(() => {
    if (changeProposal?.id) {
      setExpiresInDays(changeProposal?.expiresInDays);
    }
  }, [changeProposal?.id]);

  useEffect(() => {
    if (isSaveRequired) {
      setIsSaveRequired(false);
      dispatch(
        requestPersistChangeProposal(
          {
            id: changeProposalId,
            expiresInDays: expiresInDays,
          },
          () => {
            dispatch(requestGetChangeProposal(changeProposalId));
          }
        )
      );
    }
  }, [isSaveRequired]);

  return (
    <div className="change-proposal-side-panel">
      <div className="change-proposal-expire-days-wrapper">
        Change proposal expires in{' '}
        <BasicField
          name="expiresInDays"
          value={expiresInDays}
          type="number"
          onChange={(value: { [key: string]: any }) => {
            let v = value.expiresInDays;
            if (
              !isNaN(Number(value.expiresInDays)) &&
              value.expiresInDays !== ''
            ) {
              v = Math.round(Number(value.expiresInDays));
            }
            setExpiresInDays(v);
          }}
          changeTimeoutMs={2000}
          onBlur={() => setIsSaveRequired(true)}
          onChangeTimeout={() => setIsSaveRequired(true)}
        />{' '}
        days
      </div>
      <ChangeProposalValueSummary changeProposalId={changeProposalId} />
      {changeProposal && (
        <DealPaymentOptionsControl dealId={changeProposal?.dealId} />
      )}
      {changeProposal && (
        <DealPriceDetailControl
          dealId={changeProposal?.dealId}
          canHideVat={true}
        />
      )}
    </div>
  );
};
export default ChangeProposalSidePanel;
