import { useSelector } from '@/api/state';
import Field, { ValidationMessages } from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import TagSelectCombobox from '@payaca/components/tagSelectCombobox/TagSelectCombobox';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import { UpdateScheduledEventRequestData } from '@payaca/store/scheduledEvents/scheduledEventsTypes';
import { requestGetTags } from '@payaca/store/tags/tagsActions';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import DealSelect from '../dealSelect/DealSelect';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';

import { actions as usersActions } from '@/api/users';
import { getUserRoles } from '@/utils/stateAccessors';
import { useAccountUsers } from '@/utils/storeHooks';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import Select, {
  DismissableSelectTag,
  OptionGroup,
  SelectOption,
} from '@payaca/components/plSelect/Select';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import {
  getContactsToNotify,
  getContactsToNotifyFromLocationContacts,
} from '@payaca/helpers/scheduledEventsHelper';
import { CustomersPermissions } from '@payaca/permissions/customers/customers.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { requestGetCustomer } from '@payaca/store/customer/customerActions';
import {
  getDealAcceptedItemsCopy,
  requestGetDeal,
} from '@payaca/store/deals/dealsActions';
import { useCustomer, useDeal } from '@payaca/store/hooks/appState';
import { Contact } from '@payaca/types/contactTypes';
import { Customer } from '@payaca/types/customerTypes';
import { Deal } from '@payaca/types/dealTypes';
import {
  FieldValidationResult,
  FieldValidator,
} from '@payaca/types/fieldValidationTypes';
import {
  LocationOption,
  LocationOptionType,
} from '@payaca/types/locationTypes';
import { TContactToNotify } from '@payaca/types/scheduledEventsTypes';
import UntitledIcon from '@payaca/untitled-icons';
import { DeepPartial } from '@payaca/utilities/types';
import moment from 'moment-timezone';
import { ContactFieldset } from '../contactGroupControl/ContactGroupControl';
import { CustomerSelect } from '../customerSelect/CustomerSelect';
import { DateInput } from '../dateInput/DateInput';
import ScheduledEventLocationControl from '../scheduledEventLocationControl/ScheduledEventLocationControl';

const endAtFieldValidator: FieldValidator<DeepPartial<ScheduledEventData>> = (
  fieldName: string,
  formState
) => {
  if (!formState.beginAt || !formState.endAt) {
    return { isValid: false };
  }

  const startAtMoment = moment(formState.beginAt);
  const endAtMoment = moment(formState.endAt);

  const isValid = startAtMoment.isBefore(endAtMoment);

  if (isValid) {
    return { isValid: true };
  } else {
    return {
      isValid: false,
      errors: ['End time must be after start time'],
    };
  }
};

export const getFieldValidatorsForScheduledEvent = ({
  locationContactsCount,
}: {
  locationContactsCount: number;
}): Record<string, FieldValidator<DeepPartial<ScheduledEventData>>[]> => {
  const v: Record<string, FieldValidator<DeepPartial<ScheduledEventData>>[]> = {
    name: [getIsRequiredFieldValidator()],
    beginAt: [getIsRequiredFieldValidator({ readableName: 'Start time' })],
    endAt: [
      getIsRequiredFieldValidator({ readableName: 'End time' }),
      endAtFieldValidator,
    ],
  };

  for (let i = 0; i < locationContactsCount; i++) {
    v[`location.contacts[${i}].name`] = [
      getIsRequiredFieldValidator({
        customErrorMessage: 'Contact must have a name',
      }),
    ];
  }

  return v;
};

type ScheduledEventData = DeepPartial<
  Pick<
    UpdateScheduledEventRequestData,
    | 'name'
    | 'description'
    | 'beginAt'
    | 'endAt'
    | 'userAssignments'
    | 'dealId'
    | 'tagIds'
    | 'includeCustomerInformation'
    | 'location'
    | 'customerIds'
  > & {
    contactsToNotify: (Omit<TContactToNotify, 'id'> & {
      id?: number | string;
    })[];
  }
>;

export type Props = {
  eventData: ScheduledEventData;
  onChange: (changeValue: Record<string, any>) => void;
  onTouch?: (fieldName: string) => void;
  validationState?: {
    [key: string]: FieldValidationResult;
  };
  touchedState?: {
    [key: string]: boolean;
  };
  disabledFields?: ('dealId' | 'customerIds')[];
  hiddenFields?: ('dealId' | 'customerIds' | 'tagIds')[];
};

export const ScheduledEventFieldset: FC<Props> = ({
  eventData,
  onChange,
  onTouch,
  disabledFields = [],
  hiddenFields = [],
  validationState,
  touchedState,
}) => {
  const availableTags = useSelector((state) => state.tags.tags);
  const accountUsers = useAccountUsers();
  const userRoles = useSelector(getUserRoles);
  const [deal, setDeal] = useState<Deal>();

  const handleSetLocationOptions = (deal?: Deal, customer?: Customer) => {
    const locationOptions: LocationOption[] = [];

    if (deal?.siteAddresses?.length) {
      locationOptions.push(
        ...deal.siteAddresses.map((a) => ({
          id: a.address.id,
          address: a.address,
          contacts: a.contacts,
          type: LocationOptionType.SITE,
        }))
      );
    } else if (customer?.addresses?.length) {
      locationOptions.push(
        ...customer.addresses.map((a) => ({
          id: a.address.id,
          address: a.address,
          contacts: a.contacts,
          type: LocationOptionType.CUSTOMER,
        }))
      );
    }

    setLocationOptions(locationOptions);
    return locationOptions;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestGetTags());
    dispatch(usersActions.getAccountUsers());
    if (eventData.dealId) {
      dispatch(
        requestGetDeal(eventData.dealId, (deal) => {
          setDeal(deal);
          if (deal.customerId) {
            dispatch(
              requestGetCustomer(deal.customerId, (customer) => {
                handleSetLocationOptions(deal, customer);
              })
            );
          } else {
            handleSetLocationOptions(deal);
          }
        })
      );
    }
  }, []);

  const currentUserId = useSelector((state: any) => state.users.myProfile.id);

  const [locationOptions, setLocationOptions] = useState<LocationOption[]>([]);

  const userAssignmentOptions = useMemo(() => {
    const canUnselectSelf =
      userHasRequiredPermission(userRoles, [
        ScheduledEventsPermissions.ADD_EVENT,
      ]) ||
      (userHasRequiredPermission(userRoles, [
        ScheduledEventsPermissions.ADD_MY_DEAL_EVENT,
      ]) &&
        deal?.assignedToUserId === currentUserId);

    const options: { label: string; value: number }[] = accountUsers
      .filter((x: any) => {
        return !x.deactivatedAt || x.inviteToken;
      })
      .map((accountUser: any) => {
        return {
          disabled: canUnselectSelf ? false : accountUser.id === currentUserId,
          label: `${accountUser.firstname} ${accountUser.lastname}`,
          value: accountUser.id,
        };
      });

    return options;
  }, [accountUsers]);

  return (
    <fieldset className="flex w-full min-w-0 flex-col gap-4 border-0 p-0">
      {!hiddenFields.includes('dealId') && (
        <PermissionGuard
          renderIfHasPermissions={[
            DealsPermissions.GET_LISTED_DEALS,
            DealsPermissions.GET_MY_LISTED_DEALS,
          ]}
        >
          <Field
            name="dealId"
            validationState={
              validationState?.['dealId']?.isValid === false &&
              touchedState?.['dealId']
                ? {
                    isValid: false,
                    validationMessages: validationState?.['dealId']?.errors,
                  }
                : undefined
            }
          >
            <Field.Label>Project</Field.Label>
            <DealSelect
              dealId={eventData.dealId}
              onChange={(value, metadata) => {
                onTouch?.('dealId');
                const customerId = metadata?.deal?.customerId;
                setDeal(metadata?.deal);

                const locationOptions = handleSetLocationOptions(
                  metadata?.deal,
                  metadata?.customer
                );

                onChange({
                  dealId: value,
                  customerIds: customerId ? [customerId] : undefined,
                  location: locationOptions.length
                    ? locationOptions[0]
                    : undefined,
                  includeCustomerInformation: !!value,
                  contactsToNotify: getContactsToNotify({
                    customer: metadata?.customer,
                    locationContacts: locationOptions.length
                      ? locationOptions[0]?.contacts
                      : [],
                  }),
                });
              }}
              disabled={disabledFields.includes('dealId')}
              showCardIfDisabled={true}
            />
          </Field>
        </PermissionGuard>
      )}
      {!hiddenFields.includes('customerIds') && (
        <PermissionGuard
          renderIfHasPermissions={[CustomersPermissions.GET_LISTED_CUSTOMERS]}
        >
          <Field
            name="customerIds"
            validationState={
              validationState?.['customerIds']?.isValid === false &&
              touchedState?.['customerIds']
                ? {
                    isValid: false,
                    validationMessages:
                      validationState?.['customerIds']?.errors,
                  }
                : undefined
            }
          >
            <Field.Label>Customer</Field.Label>
            <CustomerSelect
              customerId={
                eventData.customerIds?.length
                  ? eventData.customerIds[0]
                  : undefined
              }
              onChange={(value, metadata) => {
                onTouch?.('customerIds');

                const locationOptions = handleSetLocationOptions(
                  undefined,
                  metadata?.customer
                );

                onChange({
                  dealId: undefined,
                  customerIds: value ? [value] : [],
                  location: locationOptions.length
                    ? locationOptions[0]
                    : undefined,
                  includeCustomerInformation: !!value,
                  contactsToNotify: getContactsToNotify({
                    customer: metadata?.customer,
                    locationContacts: locationOptions.length
                      ? locationOptions[0]?.contacts
                      : [],
                  }),
                });
              }}
              disabled={disabledFields.includes('customerIds')}
              showCardIfDisabled={true}
            />
          </Field>
        </PermissionGuard>
      )}
      <Field
        name="name"
        validationState={
          validationState?.['name']?.isValid === false && touchedState?.['name']
            ? {
                isValid: false,
                validationMessages: validationState?.['name']?.errors,
              }
            : undefined
        }
      >
        <Field.Label>Event name</Field.Label>
        <Input
          value={eventData.name}
          onChange={(value) => onChange({ name: value })}
          onBlur={() => onTouch?.('name')}
        />
      </Field>
      <ScheduledEventDescriptionField
        dealId={eventData.dealId}
        description={eventData.description}
        onChange={(value) => onChange({ description: value })}
        onTouch={() => onTouch?.('description')}
        validationState={validationState}
        touchedState={touchedState}
      />
      <div>
        <div className="flex flex-row gap-4">
          <div className="shrink-1 grow-0 basis-1/2">
            <Field
              name="beginAt"
              validationState={
                validationState?.['beginAt']?.isValid === false &&
                touchedState?.['beginAt']
                  ? {
                      isValid: false,
                    }
                  : undefined
              }
            >
              <Field.Label>Start time</Field.Label>
              <DateInput
                showTimeSelect={true}
                value={eventData.beginAt}
                onChange={(value) => {
                  const endTimeTouched = Boolean(touchedState?.endAt);

                  const duration = moment.duration(
                    moment(eventData.endAt).diff(moment(eventData.beginAt))
                  );

                  const newEndAt = moment(value).add(duration).toDate();

                  onChange({
                    beginAt: value,
                    endAt: endTimeTouched ? eventData.endAt : newEndAt,
                  });
                  onTouch?.('beginAt');
                }}
              />
            </Field>
          </div>
          <div className="shrink-1 grow-0 basis-1/2">
            <Field
              name="endAt"
              validationState={
                validationState?.['endAt']?.isValid === false &&
                touchedState?.['endAt']
                  ? {
                      isValid: false,
                    }
                  : undefined
              }
            >
              <Field.Label>End time</Field.Label>
              <DateInput
                showTimeSelect={true}
                value={eventData.endAt}
                onChange={(value) => {
                  onChange({
                    endAt: value,
                  });
                  onTouch?.('endAt');
                }}
              />
            </Field>
          </div>
        </div>
        <ValidationMessages
          validationState={{
            isValid: !(
              validationState?.['beginAt']?.isValid === false ||
              validationState?.['endAt']?.isValid === false
            ),
            validationMessages: [
              ...(validationState?.['beginAt']?.errors || []),
              ...(validationState?.['endAt']?.errors || []),
            ],
          }}
        />
      </div>
      {!hiddenFields.includes('tagIds') && (
        <Field
          name="tagIds"
          validationState={
            validationState?.['tagIds']?.isValid === false &&
            touchedState?.['tagIds']
              ? {
                  isValid: false,
                  validationMessages: validationState?.['tagIds']?.errors,
                }
              : undefined
          }
        >
          <Field.Label>Add Tags</Field.Label>
          <TagSelectCombobox
            multiple={true}
            availableTags={availableTags}
            value={eventData.tagIds || []}
            onChange={(value) => {
              onTouch?.('tagIds');
              onChange({ tagIds: value });
            }}
          />
        </Field>
      )}
      <Field
        name="userAssignments"
        validationState={
          validationState?.['userAssignments']?.isValid === false &&
          touchedState?.['userAssignments']
            ? {
                isValid: false,
                validationMessages:
                  validationState?.['userAssignments']?.errors,
              }
            : undefined
        }
      >
        <Field.Label>Assign to</Field.Label>
        <Select
          options={userAssignmentOptions}
          multiple={true}
          value={eventData.userAssignments}
          onChange={(value) => {
            onTouch?.('userAssignments');
            onChange({ userAssignments: value });
          }}
        />
      </Field>
      <Field>
        <Field.Label>Event address</Field.Label>
        <ScheduledEventLocationControl
          location={eventData.location}
          onChange={(value, natureOfChange) => {
            if (!value) {
              onChange({ location: undefined });
            } else {
              const change: Record<string, any> = {};
              Object.entries(value).forEach(([key, value]) => {
                change[`location.${key}`] = value;
              });

              if (
                'contacts' in value &&
                natureOfChange === 'address-and-contacts'
              ) {
                const existingAccessContactIds: Contact['id'][] =
                  eventData.location?.contacts
                    ?.map((c) => c.id)
                    .filter((id) => id) || [];

                const contactsToNotify =
                  eventData.contactsToNotify?.filter(
                    (c) =>
                      c.type !== 'contact' ||
                      !c.id ||
                      !existingAccessContactIds.includes(+c.id)
                  ) || [];

                contactsToNotify.push(
                  ...getContactsToNotifyFromLocationContacts(value.contacts)
                );

                change.contactsToNotify = contactsToNotify;
              }

              onChange(change);
            }
          }}
          locationOptions={locationOptions}
          validationState={
            validationState
              ? Object.entries(validationState).reduce(
                  (
                    acc: Record<string, FieldValidationResult>,
                    [key, value]
                  ) => {
                    if (key.startsWith(`location.`)) {
                      acc[key.replace(`location.`, '')] = value;
                    }
                    return acc;
                  },
                  {}
                )
              : undefined
          }
          onTouch={(fieldName) => {
            onTouch?.(`location.${fieldName}`);
          }}
          touchedState={
            touchedState
              ? Object.entries(touchedState).reduce(
                  (acc: Record<string, boolean>, [key, value]) => {
                    if (key.startsWith(`location.`)) {
                      acc[key.replace(`location.`, '')] = value;
                    }
                    return acc;
                  },
                  {}
                )
              : undefined
          }
        />
      </Field>
      {
        <ContactsToNotifyControl
          customerId={
            eventData.customerIds?.length
              ? eventData.customerIds[0]
              : deal?.customerId
          }
          locationContacts={eventData.location?.contacts || []}
          contactsToNotify={eventData.contactsToNotify}
          onChange={(value) => onChange({ contactsToNotify: value })}
          onTouch={() => onTouch?.('contactsToNotify')}
          validationState={
            validationState
              ? Object.entries(validationState).reduce(
                  (
                    acc: Record<string, FieldValidationResult>,
                    [key, value]
                  ) => {
                    if (key.startsWith(`contactsToNotify`)) {
                      acc[key.replace(`contactsToNotify.`, '')] = value;
                    }
                    return acc;
                  },
                  {}
                )
              : undefined
          }
          touchedState={
            touchedState
              ? Object.entries(touchedState).reduce(
                  (acc: Record<string, boolean>, [key, value]) => {
                    if (key.startsWith(`contactsToNotify`)) {
                      acc[key.replace(`contactsToNotify.`, '')] = value;
                    }
                    return acc;
                  },
                  {}
                )
              : undefined
          }
        />
      }
      {(!!eventData.dealId || !!eventData.customerIds?.length) && (
        <Field
          name="includeCustomerInformation"
          validationState={
            validationState?.['includeCustomerInformation']?.isValid ===
              false && touchedState?.['includeCustomerInformation']
              ? {
                  isValid: false,
                  validationMessages:
                    validationState?.['includeCustomerInformation']?.errors,
                }
              : undefined
          }
        >
          <div className="flex gap-2">
            <Checkbox
              checked={eventData.includeCustomerInformation}
              onChange={(value) => {
                onTouch?.('includeCustomerInformation');
                onChange({
                  includeCustomerInformation: value.target.checked,
                });
              }}
            />
            <Field.Label className="mb-0">
              Share Customer details on legacy app
            </Field.Label>
          </div>
        </Field>
      )}
    </fieldset>
  );
};

const ContactsToNotifyControl: FC<{
  customerId?: Customer['id'];
  contactsToNotify: ScheduledEventData['contactsToNotify'];
  onChange: (value: ScheduledEventData['contactsToNotify']) => void;
  onTouch?: () => void;
  validationState?: {
    [key: string]: FieldValidationResult;
  };
  touchedState?: {
    [key: string]: boolean;
  };
  locationContacts: Contact[];
}> = ({
  customerId,
  contactsToNotify,
  onChange,
  onTouch,
  validationState,
  touchedState,
  locationContacts,
}) => {
  const [isEnteringNewContact, setIsEnteringNewContact] = useState(false);
  const customer = useCustomer(customerId);

  const customerContactOptions = useMemo(() => {
    const o: SelectOption<
      TContactToNotify['id'],
      NonNullable<ScheduledEventData['contactsToNotify']>[0]
    >[] = [];

    if (customer?.contacts?.length) {
      o.push(
        ...customer.contacts.map((c) => ({
          label: c.name,
          value: c.id,
          metadata: {
            id: c.id,
            name: c.name,
            emailAddress: c.emailAddress,
            telephoneNumber: c.telephoneNumber,
            type: 'customerContact' as const,
            description: c.description,
          },
          groupId: 'customer',
        }))
      );
    }

    return o;
  }, [customer?.contacts]);

  const locationContactOptions = locationContacts
    .filter((c) => {
      return (
        c?.name?.length && (c.emailAddress?.length || c.telephoneNumber?.length)
      );
    })
    .map((c, i) => ({
      label: c.name || 'access contact name',
      value: c.id || `tempid-${i}`,
      metadata: {
        id: c.id,
        name: c.name,
        emailAddress: c.emailAddress,
        telephoneNumber: c.telephoneNumber,
        type: 'contact' as const,
        description: c.description,
      },
      groupId: 'site-address',
    }));

  const locationContactOptionValues = locationContactOptions.map(
    (o) => o.value
  );

  const options: SelectOption<
    TContactToNotify['id'] | string,
    NonNullable<ScheduledEventData['contactsToNotify']>[0]
  >[] = [
    ...customerContactOptions,
    ...locationContactOptions,
    {
      value: 'new-contact',
      label: 'Add new contact',
    },
  ];

  const optionGroups = useMemo(() => {
    const og: OptionGroup[] = [];

    if (options.some((x) => x.groupId === 'customer')) {
      og.push({ id: 'customer', label: 'Customer contacts' });
    }

    if (options.some((x) => x.groupId === 'site-address')) {
      og.push({ id: 'site-address', label: 'Site address contacts' });
    }

    return og;
  }, [options]);

  const value = useMemo(() => {
    if (!contactsToNotify?.length) return [];

    return contactsToNotify.map((x) => x.id).filter((x) => !!x) as (
      | TContactToNotify['id']
      | string
    )[];
  }, [contactsToNotify]);

  return (
    <div>
      <Field name="contactsToNotify">
        <Field.Label>Contacts to notify</Field.Label>
        <Select<
          TContactToNotify['id'] | string,
          true,
          NonNullable<ScheduledEventData['contactsToNotify']>[0]
        >
          placeholder={contactsToNotify?.length ? ' ' : undefined}
          multiple={true}
          optionGroups={optionGroups}
          value={value}
          options={options}
          onChange={(change) => {
            if (change.includes('new-contact')) {
              return;
            }

            const existingContacts: ScheduledEventData['contactsToNotify'] =
              contactsToNotify?.filter((c) => !c.id || change.includes(c.id)) ||
              [];
            const newContacts =
              (options
                .filter(
                  (o) => change.includes(o.value) && !value.includes(o.value)
                )
                .map((o) => ({
                  ...o.metadata,
                  id: o.metadata?.id || o.value,
                })) as ScheduledEventData['contactsToNotify']) || [];

            onChange([...existingContacts, ...newContacts]);
          }}
          CustomSelected={() => {
            return (
              <div className="flex flex-wrap gap-1">
                {contactsToNotify?.map((x, i) => {
                  const isAccessContact =
                    x.type === 'contact' &&
                    !!x.id &&
                    locationContactOptionValues.includes(x.id);

                  return (
                    <DismissableSelectTag
                      key={x.id || `noid-${i}`}
                      onDismiss={() => {
                        const contacts = [...contactsToNotify];
                        contacts.splice(i, 1);
                        onChange(contacts);
                      }}
                    >
                      {isAccessContact && (
                        <UntitledIcon
                          name="key-01.3"
                          className="mr-2 h-3 w-3"
                        />
                      )}
                      {x.name?.length ? x.name : 'Access contact name'}
                    </DismissableSelectTag>
                  );
                })}
              </div>
            );
          }}
          CustomOption={({ option, close }) => {
            if (option.value === 'new-contact')
              return (
                <Button
                  variant={EBtnVariant.LinkInline}
                  onClick={() => {
                    setIsEnteringNewContact(true);
                    close?.();
                  }}
                >
                  Add new contact
                </Button>
              );
            return (
              <span>
                <span>{option.label}</span>
                {option.metadata?.description?.length && (
                  <span className="ml-3 text-xs text-gray-500">
                    {option.metadata?.description}
                  </span>
                )}
              </span>
            );
          }}
        />
      </Field>
      {isEnteringNewContact && (
        <div className="mt-2">
          <AddNewContactControl
            onSave={(contact) => {
              onChange([
                ...(contactsToNotify || []),
                { type: 'contact', ...contact },
              ]);
              setIsEnteringNewContact(false);
            }}
            onCancel={() => setIsEnteringNewContact(false)}
          />
        </div>
      )}
    </div>
  );
};

const AddNewContactControl: FC<{
  onSave: (contact: Partial<Contact>) => void;
  onCancel: () => void;
}> = ({ onSave, onCancel }) => {
  const [contact, setContact] = useState<Partial<Contact>>({});
  const isValid =
    contact.name?.length &&
    (contact.emailAddress?.length || contact.telephoneNumber?.length);

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <div className="mb-2 text-base font-medium">
          <span>Create new contact</span>
        </div>
        <ContactFieldset
          contact={contact}
          onChange={(value) => setContact((c) => ({ ...c, ...value }))}
        />
        <div className="mt-4 flex gap-2">
          <Button
            variant={EBtnVariant.Outline}
            disabled={!isValid}
            onClick={() => onSave(contact)}
          >
            Save
          </Button>
          <Button variant={EBtnVariant.Link} onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

const ScheduledEventDescriptionField: FC<{
  dealId?: Deal['id'];
  description: ScheduledEventData['description'];
  onChange: (value: ScheduledEventData['description']) => void;
  onTouch?: () => void;
  validationState?: {
    [key: string]: FieldValidationResult;
  };
  touchedState?: {
    [key: string]: boolean;
  };
}> = ({
  dealId,
  description,
  onChange,
  onTouch,
  validationState,
  touchedState,
}) => {
  const dispatch = useDispatch();
  const [showNoAcceptedItemsCopyFeedback, setShowNoAcceptedItemsCopyFeedback] =
    useState(false);
  const [isGettingAcceptedItemsCopy, setIsGettingAcceptedItemsCopy] =
    useState(false);

  const deal = useDeal(dealId);
  const userRoles = useSelector(getUserRoles);
  const currentUserId = useSelector((state: any) => {
    return state.users.myProfile.id;
  });

  const canShowAddAcceptedItemsButton = useMemo(() => {
    if (!deal || deal.version < 2) return false;

    if (
      userHasRequiredPermission(userRoles, [DealsPermissions.GET_DEAL]) &&
      dealId
    )
      return true;
    else if (
      userHasRequiredPermission(userRoles, [DealsPermissions.GET_MY_DEAL]) &&
      deal?.assignedToUserId === currentUserId
    )
      return true;
    else return false;
  }, [userRoles, deal, dealId, currentUserId]);

  return (
    <Field
      name="description"
      validationState={
        validationState?.['description']?.isValid === false &&
        touchedState?.['description']
          ? {
              isValid: false,
              validationMessages: validationState?.['description']?.errors,
            }
          : undefined
      }
    >
      <div className="flex flex-row items-baseline">
        <Field.Label>Description</Field.Label>
        {canShowAddAcceptedItemsButton && (
          <Button
            className="ml-auto"
            variant={EBtnVariant.LinkInline}
            isProcessing={isGettingAcceptedItemsCopy}
            onClick={() => {
              if (isGettingAcceptedItemsCopy) return;
              if (!dealId) return;
              setShowNoAcceptedItemsCopyFeedback(false);
              setIsGettingAcceptedItemsCopy(true);
              dispatch(
                getDealAcceptedItemsCopy.request({
                  dealId: dealId,
                  callback: (acceptedItemsCopy: string) => {
                    setIsGettingAcceptedItemsCopy(false);
                    if (acceptedItemsCopy.length) {
                      onChange(
                        description?.length
                          ? `${description}\n\n${acceptedItemsCopy}`
                          : acceptedItemsCopy
                      );
                      onTouch?.();
                    } else {
                      setShowNoAcceptedItemsCopyFeedback(true);
                    }
                  },
                })
              );
            }}
          >
            Add accepted items
          </Button>
        )}
      </div>
      {showNoAcceptedItemsCopyFeedback && (
        <div className="mb-2">
          <Alert colour={EAlertColour.SOFT_YELLOW}>
            <p>There are no accepted items to add</p>
          </Alert>
        </div>
      )}
      <Textarea
        rows={6}
        value={description || ''}
        onChange={onChange}
        onBlur={onTouch}
      />
    </Field>
  );
};
