import { FC, useCallback, useMemo, useState } from 'react';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CurrencyField from '@payaca/components/currencyField/CurrencyField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { useDeal } from '@payaca/store/hooks/appState';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import './CreateInvoiceControl.sass';

import { useAccount } from '@/utils/storeHooks';
import { faPercent } from '@fortawesome/free-solid-svg-icons';
import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import SplitBarVisualisation from '@payaca/components/splitBarVisualisation/SplitBarVisualisation';
import { BLUE, GREEN_DARK, YELLOW } from '@payaca/constants/colours';
import { getNumericalRangeFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { currencyPrice, formatPercentage } from '@payaca/helpers/financeHelper';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import * as invoiceActions from '@payaca/store/invoices/invoicesActions';
import { AccountRegions } from '@payaca/types/accountTypes';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import { useDispatch } from 'react-redux';

type Props = {
  onCreateInvoiceSuccess?: (invoiceId: number) => void;
  dealId: number;
};
const CreateInvoiceControl: FC<Props> = ({
  onCreateInvoiceSuccess,
  dealId,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const deal = useDeal(dealId);
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);
  const [createInvoiceErrorMessage, setCreateInvoiceErrorMessage] =
    useState<string>();

  const [inputPercentage, setInputPercentage] = useState(true);

  const account = useAccount();
  const { acceptedValue, invoicedValue } = useMemo(() => {
    if (!deal) {
      return {
        acceptedValue: 0,
        invoicedValue: 0,
      };
    }

    return deal;
  }, [deal]);

  const initialFormState = useMemo(() => {
    const invoiceValue = acceptedValue - invoicedValue;
    return {
      invoiceValue: invoiceValue,
      invoicePercentage:
        Math.round((invoiceValue / acceptedValue) * 10000) / 100,
    };
  }, [acceptedValue, invoicedValue]);

  const fieldValidators = useMemo(() => {
    const maxValue = acceptedValue - invoicedValue;

    return {
      invoiceValue: [
        getNumericalRangeFieldValidator(
          -1,
          maxValue + 1,
          undefined,
          `Invoice value cannot be less than zero`,
          `Invoice value cannot exceed ${currencyPrice(
            maxValue,
            account.region
          )}`
        ),
      ],
    };
  }, [acceptedValue, invoicedValue]);

  const onSubmit = useCallback(
    (invoiceValue: number) => {
      if (isCreatingInvoice) return;
      const maxValue = acceptedValue - invoicedValue;
      setIsCreatingInvoice(true);
      setCreateInvoiceErrorMessage(undefined);
      dispatch(
        invoiceActions.requestCreateInvoice(
          {
            dealId: dealId,
            createForUninvoicedDealValue: invoiceValue === maxValue,
            invoiceValue: invoiceValue,
          },
          (invoiceId) => {
            onCreateInvoiceSuccess?.(invoiceId);
            setIsCreatingInvoice(false);
          },
          () => {
            setCreateInvoiceErrorMessage(
              'Something went wrong creating the invoice'
            );
            setIsCreatingInvoice(false);
          }
        )
      );
    },
    [onCreateInvoiceSuccess, acceptedValue, invoicedValue, isCreatingInvoice]
  );

  const currencySymbol = getRegionalTextString(
    account.region || AccountRegions.UK,
    RegionalStrings.CURRENCY_SYMBOL
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <div className="form-contents">
            <SplitBarVisualisation
              data={[
                {
                  label: 'Invoiced',
                  value: invoicedValue,
                  id: 'invoiced',
                  colour: GREEN_DARK,
                },
                {
                  label: 'This invoice',
                  value: formState.invoiceValue,
                  id: 'this-invoice',
                  colour: YELLOW,
                },
                {
                  label: 'Remaining',
                  value: acceptedValue - invoicedValue - formState.invoiceValue,
                  id: 'remaining',
                  colour: BLUE,
                },
              ]}
              formatValue={(value) => {
                return `${formatPercentage((value / acceptedValue) * 100)}%`;
              }}
              renderPopperContent={(dataElement) => (
                <dl className="split-bar-popper-content">
                  <dt>{dataElement.label}:</dt>
                  <dd>
                    <strong>
                      {formatPercentage(
                        (dataElement.value / acceptedValue) * 100
                      )}
                      % ({currencyPrice(dataElement.value, account.region)})
                    </strong>
                  </dd>
                </dl>
              )}
            />
            <div>
              <div className="invoice-value-label-container">
                <label
                  htmlFor="invoice-percentage-field"
                  className="invoice-value-label"
                >
                  Invoice value:
                </label>
              </div>

              <ValidatedFieldWrapper
                validationResult={validationState?.invoiceValue}
              >
                <div className="value-control">
                  {inputPercentage && (
                    <>
                      <BasicField
                        className="invoice-percentage-field"
                        styleVariant={InputStyleVariant.STANDARD}
                        value={formState.invoicePercentage}
                        name="invoicePercentage"
                        onChange={(value) => {
                          const invoicePercentage = +value.invoicePercentage;
                          onFieldChange({
                            invoicePercentage: invoicePercentage,
                            invoiceValue: Math.round(
                              (acceptedValue * invoicePercentage) / 100
                            ),
                          });
                        }}
                        type="number"
                        additionalInputProps={{
                          step: 0.1,
                          id: 'invoice-percentage-field',
                        }}
                        iconAfter={faPercent}
                      />
                      <span>
                        of Project value{' '}
                        {currencyPrice(acceptedValue, account.region)}
                      </span>
                      <div className="connector"></div>
                      <span className="invoice-value">
                        {currencyPrice(formState.invoiceValue, account.region)}
                      </span>
                    </>
                  )}
                  {!inputPercentage && (
                    <>
                      <span>
                        {formState.invoicePercentage}% of Project value{' '}
                        {currencyPrice(acceptedValue, account.region)}
                      </span>
                      <div className="connector"></div>

                      <CurrencyField
                        styleVariant={InputStyleVariant.STANDARD}
                        value={formState.invoiceValue}
                        name="invoiceValue"
                        onChange={(value) => {
                          const invoiceValue = value.invoiceValue;

                          const invoicePercentage =
                            Math.round((invoiceValue / acceptedValue) * 10000) /
                            100;

                          onFieldChange({
                            invoicePercentage: invoicePercentage,
                            invoiceValue: invoiceValue,
                          });
                        }}
                      />
                    </>
                  )}
                </div>
              </ValidatedFieldWrapper>
              <div className="button-container">
                <Button
                  styleVariant={ButtonStyleVariant.ANCHOR}
                  onClick={() => setInputPercentage((x) => !x)}
                >
                  Input {inputPercentage ? currencySymbol : '%'}
                </Button>
              </div>
            </div>
          </div>
          <div className="actions-container">
            <Button
              className="create-invoice-button"
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isDisabled={!isValid}
              isProcessing={isCreatingInvoice}
              onClick={() => onSubmit(formState.invoiceValue)}
            >
              Create for {currencyPrice(formState.invoiceValue, account.region)}
            </Button>
            {!!createInvoiceErrorMessage?.length && (
              <FeedbackBlock feedbackLevel={FeedbackLevel.ERROR}>
                <span>{createInvoiceErrorMessage}</span>
              </FeedbackBlock>
            )}
          </div>
        </>
      );
    },
    [acceptedValue, invoicedValue, onSubmit, isCreatingInvoice, inputPercentage]
  );

  return (
    <div className="create-invoice-control">
      {acceptedValue - invoicedValue === 0 ? (
        <>
          <div className="actions-container">
            <Button
              className="create-invoice-button"
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isProcessing={isCreatingInvoice}
              onClick={() => onSubmit(0)}
            >
              Create for {currencyPrice(0, account.region)}
            </Button>
            {!!createInvoiceErrorMessage?.length && (
              <FeedbackBlock feedbackLevel={FeedbackLevel.ERROR}>
                <span>{createInvoiceErrorMessage}</span>
              </FeedbackBlock>
            )}
          </div>
        </>
      ) : (
        <>
          <ValidatedForm<{ [key: string]: any }>
            initialFormState={initialFormState}
            renderFormContents={renderFormContents}
            fieldValidators={fieldValidators}
          />
        </>
      )}
    </div>
  );
};
export default CreateInvoiceControl;
