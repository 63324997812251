import { AccountRegions } from '@payaca/types/accountTypes';
import {
  DataType,
  MetricConfiguration,
  MetricType,
  RangeData,
  TagMetricAnalyticsData,
  TagMetricConfigurations,
} from '@payaca/types/tagAnalyticsTypes';
import { TaggableEntityType } from '@payaca/types/tagTypes';
import { format } from 'd3-format';
import moment from 'moment-timezone';
import { currencySymbol } from './financeHelper';

const integerValueFormatter = (x: number) => Math.round(x).toString();
const currencyValueFormatter = (x: number, region?: AccountRegions) => {
  const cs = currencySymbol(region);
  return `${cs}${format(`.2s`)(x / 100)}`;
};
const durationFormatter = (x: number) => {
  const d = moment.duration(x);

  if (d.months() > 0) {
    return `${format(`.2`)(d.asMonths())}mnths`;
  } else if (d.days() > 0) {
    return `${format(`.2`)(d.asDays())}days`;
  } else if (d.hours() > 0) {
    return `${format(`.2`)(d.asHours())}hrs`;
  } else {
    return `${format(`.2`)(d.asMinutes())}mins`;
  }
};

const tagMetricConfiguration: TagMetricConfigurations = {
  [TaggableEntityType.DEAL]: {
    [MetricType.TAG_USAGE_SUMMARY]: {
      name: 'Tag usage summary',
      description: 'How many Projects use each Tag?',
      valueFormatter: integerValueFormatter,
      isDiscrete: true,
    },
    [MetricType.DEAL_VALUE]: {
      name: 'Project value',
      description: 'What is the value range of Projects using each Tag?',
      valueFormatter: currencyValueFormatter,
      axisTickFormatter: currencyValueFormatter,
      isDiscrete: false,
    },
    [MetricType.TIME_TO_ACCEPTED]: {
      name: 'Time to acceptance',
      description:
        'How long do Projects using each Tag take to go from the Proposal being sent to the Proposal being accepted?',
      valueFormatter: durationFormatter,
      axisTickFormatter: durationFormatter,
      isDiscrete: false,
    },
  },
  [TaggableEntityType.SCHEDULED_EVENT]: {
    [MetricType.TAG_USAGE_SUMMARY]: {
      name: 'Tag usage summary',
      description: 'How many Events use each Tag?',
      valueFormatter: integerValueFormatter,
      isDiscrete: true,
    },
  },
  [TaggableEntityType.CUSTOMER]: {
    [MetricType.TAG_USAGE_SUMMARY]: {
      name: 'Tag usage summary',
      description: 'How many Customers use each Tag?',
      valueFormatter: integerValueFormatter,
      isDiscrete: true,
    },
  },
  [TaggableEntityType.LINE_ITEM]: {
    [MetricType.TAG_USAGE_SUMMARY]: {
      name: 'Tag usage summary',
      description: 'How many Line Items use each Tag?',
      valueFormatter: integerValueFormatter,
      isDiscrete: true,
    },
  },
  [TaggableEntityType.MATERIAL]: {
    [MetricType.TAG_USAGE_SUMMARY]: {
      name: 'Tag usage summary',
      description: 'How many Materials use each Tag?',
      valueFormatter: integerValueFormatter,
      isDiscrete: true,
    },
  },
  [TaggableEntityType.SUPPLIER]: {
    [MetricType.TAG_USAGE_SUMMARY]: {
      name: 'Tag usage summary',
      description: 'How many Suppliers use each Tag?',
      valueFormatter: integerValueFormatter,
      isDiscrete: true,
    },
  },
};

export const getTagMetricAnalyticsConfig = (
  taggableEntityType: TaggableEntityType,
  metricType: MetricType
) => {
  return tagMetricConfiguration[taggableEntityType]?.[metricType];
};

export const isTagMetricAnalyticsAvailable = (
  taggableEntityType: TaggableEntityType,
  metricType: MetricType
) => {
  return !!getTagMetricAnalyticsConfig(taggableEntityType, metricType);
};

export const getAvailableTagMetricsForEntityType = (
  taggableEntityType: TaggableEntityType
) => {
  const t = tagMetricConfiguration[taggableEntityType];
  if (!t) return [];

  return Object.keys(t);
};

export const getAvailableTagMetricAnalyticsConfigsForEntityType = (
  taggableEntityType: TaggableEntityType
): {
  [key in MetricType]?: MetricConfiguration;
} => {
  const t = tagMetricConfiguration[taggableEntityType];
  return t || {};
};

export const isRangeMetricAnalyticsData = (
  analyticsData: TagMetricAnalyticsData<DataType>
): analyticsData is TagMetricAnalyticsData<RangeData> => {
  if (
    analyticsData.datapoints.length &&
    typeof analyticsData.datapoints[0].data === 'object' &&
    'min' in analyticsData.datapoints[0].data
  ) {
    return true;
  }

  return false;
};

export const isValueMetricAnalyticsData = (
  analyticsData: TagMetricAnalyticsData<DataType>
): analyticsData is TagMetricAnalyticsData<number> => {
  if (
    analyticsData.datapoints.length &&
    typeof analyticsData.datapoints[0].data === 'number'
  ) {
    return true;
  }
  return false;
};
