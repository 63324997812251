import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { DeleteUploadInput, DeleteUploadMutation } from '@/gql/graphql';

const DELETE_UPLOAD = graphql(`
  mutation DeleteUpload($input: DeleteUploadInput!) {
    deleteUpload(input: $input)
  }
`);

const useDeleteUpload = (
  options?: UseMutationOptions<DeleteUploadMutation, unknown, DeleteUploadInput>
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(DELETE_UPLOAD, { input });
    },
    ...options,
  });
};

export default useDeleteUpload;
