import { Supplier } from '@payaca/types/supplierTypes';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import useGetSupplierPurchaseOrders from '@/api/queries/suppliers/useGetSupplierPurchaseOrders';
import { usePaginationState } from '@/ui/pages/dealPage/components/MaterialsPanel/materialsPanelHooks';
import Button from '@payaca/components/plButton/Button';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import { createIsolatedPurchaseOrder } from '@payaca/store/materialsList/materialsListActions';
import { useDebounce } from 'react-use';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { PurchaseOrdersManageableItemsListTable } from '../purchaseOrdersManageableItemsListTable/PurchaseOrdersManageableItemsListTable';

type Props = {
  supplierId: Supplier['id'];
};

const SupplierPurchaseOrders: FC<Props> = ({
  supplierId,
}: Props): JSX.Element => {
  const pagination = usePaginationState(20);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>();

  const [, cancel] = useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm);
    },
    300,
    [searchTerm]
  );

  const { purchaseOrders } = useGetSupplierPurchaseOrders({
    supplierId,
    pagination,
    input: {
      searchTerm: debouncedSearchTerm,
    },
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const [isCreatingPurchaseOrder, setIsCreatingPurchaseOrder] = useState(false);

  return (
    <ManageableItemsList>
      <ManageableItemsList.HeaderBar
        heading="Purchase Orders"
        buttons={
          <PermissionGuard
            renderIfHasPermissions={[
              MaterialsListPermissions.PERSIST_ISOLATED_PURCHASE_ORDER,
            ]}
          >
            <div className="ml-auto shrink">
              <Button
                isProcessing={isCreatingPurchaseOrder}
                size={EBtnSize.Small}
                onClick={() => {
                  if (isCreatingPurchaseOrder) return;
                  setIsCreatingPurchaseOrder(true);
                  dispatch(
                    createIsolatedPurchaseOrder.request({
                      payload: { supplierId: supplierId },
                      callback: (purchaseOrder) => {
                        setIsCreatingPurchaseOrder(false);
                        history.push(
                          `/purchaseOrders/${purchaseOrder.id}/edit`
                        );
                      },
                      onErrorCallback: () => {
                        setIsCreatingPurchaseOrder(false);
                      },
                    })
                  );
                }}
              >
                Create Purchase Order
              </Button>
            </div>
          </PermissionGuard>
        }
      />
      <ManageableItemsList.ActionBar>
        <ManageableItemsList.ActionBar.SearchInput onChange={setSearchTerm} />
      </ManageableItemsList.ActionBar>
      <PurchaseOrdersManageableItemsListTable
        purchaseOrders={purchaseOrders?.items || []}
        isLoading={!purchaseOrders}
        includedColumns={[
          'ref',
          'status',
          'project',
          'project-customer',
          'purchase-cost',
          'forecast-cost',
        ]}
      ></PurchaseOrdersManageableItemsListTable>
      {purchaseOrders && (
        <ManageableItemsList.PaginationBar
          totalItems={purchaseOrders.totalCount}
          pageSize={purchaseOrders.limit}
          currentPage={purchaseOrders.offset / purchaseOrders.limit + 1}
          onPageChange={pagination.setCurrentPage}
        />
      )}
    </ManageableItemsList>
  );
};

export default SupplierPurchaseOrders;
