import { FC, useMemo, useState } from 'react';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import CollapsiblePanel from '@payaca/components/collapsiblePanel/CollapsiblePanel';
import DateField from '@payaca/components/dateField/DateField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import Modal from '@payaca/components/modal/Modal';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';

import FormSignatureCaptureControl from '@payaca/components/formSignatureCaptureControl/FormSignatureCaptureControl';
import { distributionBoardFieldLabels } from '@payaca/helpers/electrical-forms-helpers';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { FieldValidator } from '@payaca/types/fieldValidationTypes';

import {
  completionStatuses,
  currents,
  DistributionBoard,
  ElectricalFormVersion,
  overcurrentProtectionDeviceBss,
  overcurrentProtectionNominalVoltages,
  ratings,
  rcdBss,
  rcdTypes,
} from '@payaca/types/electrical-forms-types';
import './CreateEditDistributionBoardModal.sass';

type CreateEditDistributionBoardModalProps = {
  distributionBoard?: DistributionBoard;
  isOpen: boolean;
  onClose: () => void;
  onPersist: (db: DistributionBoard) => void;
  version: ElectricalFormVersion;
};

const validators: Record<
  Exclude<keyof DistributionBoard, 'circuits'>,
  Array<FieldValidator>
> = {
  location: [
    getIsRequiredFieldValidator({
      readableName: distributionBoardFieldLabels.location,
    }),
  ],
  designation: [
    getIsRequiredFieldValidator({
      readableName: distributionBoardFieldLabels.designation,
    }),
  ],
  prospectiveFaultCurrent: [],
  testEngineerName: [
    getIsRequiredFieldValidator({
      readableName: distributionBoardFieldLabels.testEngineerName,
    }),
  ],
  testEngineerPosition: [
    getIsRequiredFieldValidator({
      readableName: distributionBoardFieldLabels.testEngineerPosition,
    }),
  ],
  testEngineerSignatureBase64: [
    getIsRequiredFieldValidator({
      readableName: distributionBoardFieldLabels.testEngineerSignatureBase64,
    }),
  ],
  testEngineerDate: [
    getIsRequiredFieldValidator({
      readableName: distributionBoardFieldLabels.testEngineerDate,
    }),
  ],
  multiFunctionTestInstrumentSerialNumber: [],
  continuityTestInstrumentSerialNumber: [],
  insulationResistanceTestInstrumentSerialNumber: [],
  earthFaultLoopImpedanceTestInstrumentSerialNumber: [],
  earthElectrodeResistanceTestInstrumentSerialNumber: [],
  rcdTestInstrumentSerialNumber: [],

  zDb: [],
  confirmationOfSupplyPolarity: [],
  phaseSequenceConfirmed: [],
  spdDetailsT1: [],
  spdDetailsT2: [],
  spdDetailsT3: [],
  spdDetailsT4: [],
  statusIndicatorChecked: [],
  isNotConnectedToOrigin: [],
  supplyIsFrom: [],
  overcurrentProtectionDeviceBs: [],
  overcurrentProtectionNominalVoltage: [],
  overcurrentProtectionRating: [],
  overcurrentProtectionNoOfPhases: [],
  associatedRcdBs: [],
  associatedRcdType: [],
  associatedRcdResidualCurrent: [],
  associatedRcdNoOfPoles: [],
  associatedRcdOperatingTime: [],
};

export const CreateEditDistributionBoardModal: FC<
  CreateEditDistributionBoardModalProps
> = ({ distributionBoard, isOpen, onClose, onPersist, version }) => {
  const [isTestedByPanelOpen, setIsTestedByPanelOpen] = useState(false);
  const [
    isOvercurrentProtectionDeviceOpen,
    setIsOvercurrentProtectionDeviceOpen,
  ] = useState(false);
  const [isAssociatedRcdOpen, setIsAssociatedRcdOpen] = useState(false);
  const [isTestInstrumentsPanelOpen, setIsTestInstrumentsPanelOpen] =
    useState(false);

  const initialFormState = useMemo(() => {
    if (!distributionBoard) {
      return { circuits: [] };
    }
    return distributionBoard;
  }, [distributionBoard]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Add distribution board"
      className="create-edit-distribution-board-modal"
    >
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        fieldValidators={validators}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => {
          const handleClickSave = () => {
            if (isValid) {
              onPersist(formState as DistributionBoard);
              onClose();
            }
          };

          return (
            <>
              <ValidatedFieldWrapper
                validationResult={validationState.location}
                isTouched={touchedState.location ?? false}
              >
                <BasicField
                  label={distributionBoardFieldLabels.location}
                  name="location"
                  value={formState.location}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  isRequired
                />
              </ValidatedFieldWrapper>
              <ValidatedFieldWrapper
                validationResult={validationState.designation}
                isTouched={touchedState.designation ?? false}
              >
                <BasicField
                  label={distributionBoardFieldLabels.designation}
                  name="designation"
                  value={formState.designation}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  isRequired
                />
              </ValidatedFieldWrapper>
              {version === '18.2' && (
                <ValidatedFieldWrapper
                  validationResult={validationState.zDb}
                  isTouched={touchedState.zDb ?? false}
                >
                  <BasicField
                    type="number"
                    label={distributionBoardFieldLabels.zDb}
                    name="zDb"
                    value={formState.zDb}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                  />
                </ValidatedFieldWrapper>
              )}
              <ValidatedFieldWrapper
                validationResult={validationState.prospectiveFaultCurrent}
                isTouched={touchedState.prospectiveFaultCurrent ?? false}
              >
                <BasicField
                  type="number"
                  label={distributionBoardFieldLabels.prospectiveFaultCurrent}
                  name="prospectiveFaultCurrent"
                  value={formState.prospectiveFaultCurrent}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                />
              </ValidatedFieldWrapper>
              {version === '18.2' && (
                <ValidatedFieldWrapper
                  validationResult={
                    validationState.confirmationOfSupplyPolarity
                  }
                  isTouched={touchedState.confirmationOfSupplyPolarity ?? false}
                >
                  <DropdownField
                    label={
                      distributionBoardFieldLabels.confirmationOfSupplyPolarity
                    }
                    name="confirmationOfSupplyPolarity"
                    value={formState.confirmationOfSupplyPolarity}
                    options={completionStatuses.map((x) => ({
                      label: x,
                      value: x,
                    }))}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                  />
                </ValidatedFieldWrapper>
              )}
              {version === '18.2' && (
                <ValidatedFieldWrapper
                  validationResult={validationState.phaseSequenceConfirmed}
                  isTouched={touchedState.phaseSequenceConfirmed ?? false}
                >
                  <DropdownField
                    label={distributionBoardFieldLabels.phaseSequenceConfirmed}
                    name="phaseSequenceConfirmed"
                    value={formState.phaseSequenceConfirmed}
                    options={completionStatuses.map((x) => ({
                      label: x,
                      value: x,
                    }))}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                  />
                </ValidatedFieldWrapper>
              )}

              {version === '18.2' && (
                <div>
                  <strong>SPD Details</strong>
                  <div className="spd-details">
                    <ValidatedFieldWrapper
                      validationResult={validationState.spdDetailsT1}
                      isTouched={touchedState.spdDetailsT1 ?? false}
                    >
                      <DropdownField
                        label={distributionBoardFieldLabels.spdDetailsT1}
                        name="spdDetailsT1"
                        value={formState.spdDetailsT1}
                        options={completionStatuses.map((x) => ({
                          label: x,
                          value: x,
                        }))}
                        onChange={onFieldChange}
                        onTouch={onFieldTouch}
                      />
                    </ValidatedFieldWrapper>

                    <ValidatedFieldWrapper
                      validationResult={validationState.spdDetailsT2}
                      isTouched={touchedState.spdDetailsT2 ?? false}
                    >
                      <DropdownField
                        label={distributionBoardFieldLabels.spdDetailsT2}
                        name="spdDetailsT2"
                        value={formState.spdDetailsT2}
                        options={completionStatuses.map((x) => ({
                          label: x,
                          value: x,
                        }))}
                        onChange={onFieldChange}
                        onTouch={onFieldTouch}
                      />
                    </ValidatedFieldWrapper>
                    <ValidatedFieldWrapper
                      validationResult={validationState.spdDetailsT3}
                      isTouched={touchedState.spdDetailsT3 ?? false}
                    >
                      <DropdownField
                        label={distributionBoardFieldLabels.spdDetailsT3}
                        name="spdDetailsT3"
                        value={formState.spdDetailsT3}
                        options={completionStatuses.map((x) => ({
                          label: x,
                          value: x,
                        }))}
                        onChange={onFieldChange}
                        onTouch={onFieldTouch}
                      />
                    </ValidatedFieldWrapper>
                    <ValidatedFieldWrapper
                      validationResult={validationState.spdDetailsT4}
                      isTouched={touchedState.spdDetailsT4 ?? false}
                    >
                      <DropdownField
                        label={distributionBoardFieldLabels.spdDetailsT4}
                        name="spdDetailsT4"
                        value={formState.spdDetailsT4}
                        options={completionStatuses.map((x) => ({
                          label: x,
                          value: x,
                        }))}
                        onChange={onFieldChange}
                        onTouch={onFieldTouch}
                      />
                    </ValidatedFieldWrapper>
                  </div>
                </div>
              )}
              {version === '18.2' && (
                <ValidatedFieldWrapper
                  validationResult={validationState.statusIndicatorChecked}
                  isTouched={touchedState.statusIndicatorChecked ?? false}
                >
                  <DropdownField
                    label={distributionBoardFieldLabels.statusIndicatorChecked}
                    name="statusIndicatorChecked"
                    value={formState.statusIndicatorChecked}
                    options={completionStatuses.map((x) => ({
                      label: x,
                      value: x,
                    }))}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                  />
                </ValidatedFieldWrapper>
              )}
              {version === '18.2' && (
                <div className="not-connected-to-origin-details">
                  <ValidatedFieldWrapper
                    validationResult={validationState.isNotConnectedToOrigin}
                    isTouched={touchedState.isNotConnectedToOrigin ?? false}
                  >
                    <CheckboxField
                      label={
                        distributionBoardFieldLabels.isNotConnectedToOrigin
                      }
                      name="isNotConnectedToOrigin"
                      value={formState.isNotConnectedToOrigin}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                    />
                  </ValidatedFieldWrapper>
                  {!!formState.isNotConnectedToOrigin && (
                    <>
                      <ValidatedFieldWrapper
                        validationResult={validationState.supplyIsFrom}
                        isTouched={touchedState.supplyIsFrom ?? false}
                      >
                        <BasicField
                          label={distributionBoardFieldLabels.supplyIsFrom}
                          name="supplyIsFrom"
                          value={formState.supplyIsFrom}
                          onChange={onFieldChange}
                          onTouch={onFieldTouch}
                        />
                      </ValidatedFieldWrapper>
                      <div className="overcurrent-protection-device">
                        <CollapsiblePanel
                          isOpen={isOvercurrentProtectionDeviceOpen}
                          onOpen={() =>
                            setIsOvercurrentProtectionDeviceOpen(true)
                          }
                          onClose={() =>
                            setIsOvercurrentProtectionDeviceOpen(false)
                          }
                          title="Overcurrent protection device for the distribution
                          circuit"
                        >
                          <div className="overcurrent-protection-device-fields">
                            <ValidatedFieldWrapper
                              validationResult={
                                validationState.overcurrentProtectionDeviceBs
                              }
                              isTouched={
                                touchedState.overcurrentProtectionDeviceBs ??
                                false
                              }
                            >
                              <DropdownField
                                label={
                                  distributionBoardFieldLabels.overcurrentProtectionDeviceBs
                                }
                                name="overcurrentProtectionDeviceBs"
                                value={formState.overcurrentProtectionDeviceBs}
                                onChange={onFieldChange}
                                onTouch={onFieldTouch}
                                options={overcurrentProtectionDeviceBss.map(
                                  (x) => ({
                                    label: x,
                                    value: x,
                                  })
                                )}
                              />
                            </ValidatedFieldWrapper>
                            <ValidatedFieldWrapper
                              validationResult={
                                validationState.overcurrentProtectionNominalVoltage
                              }
                              isTouched={
                                touchedState.overcurrentProtectionNominalVoltage ??
                                false
                              }
                            >
                              <DropdownField
                                label={
                                  distributionBoardFieldLabels.overcurrentProtectionNominalVoltage
                                }
                                name="overcurrentProtectionNominalVoltage"
                                value={
                                  formState.overcurrentProtectionNominalVoltage
                                }
                                onChange={onFieldChange}
                                onTouch={onFieldTouch}
                                options={overcurrentProtectionNominalVoltages.map(
                                  (x) => ({
                                    label: x,
                                    value: x,
                                  })
                                )}
                              />
                            </ValidatedFieldWrapper>
                            <ValidatedFieldWrapper
                              validationResult={
                                validationState.overcurrentProtectionRating
                              }
                              isTouched={
                                touchedState.overcurrentProtectionRating ??
                                false
                              }
                            >
                              <DropdownField
                                label={
                                  distributionBoardFieldLabels.overcurrentProtectionRating
                                }
                                name="overcurrentProtectionRating"
                                value={formState.overcurrentProtectionRating}
                                onChange={onFieldChange}
                                onTouch={onFieldTouch}
                                options={ratings.map((x) => ({
                                  label: x.toString(),
                                  value: x.toString(),
                                }))}
                              />
                            </ValidatedFieldWrapper>

                            <ValidatedFieldWrapper
                              validationResult={
                                validationState.overcurrentProtectionNoOfPhases
                              }
                              isTouched={
                                touchedState.overcurrentProtectionNoOfPhases ??
                                false
                              }
                            >
                              <BasicField
                                type="number"
                                label={
                                  distributionBoardFieldLabels.overcurrentProtectionNoOfPhases
                                }
                                name="overcurrentProtectionNoOfPhases"
                                value={
                                  formState.overcurrentProtectionNoOfPhases
                                }
                                onChange={onFieldChange}
                                onTouch={onFieldTouch}
                              />
                            </ValidatedFieldWrapper>
                          </div>
                        </CollapsiblePanel>
                      </div>
                      <div className="associated-rcd">
                        <CollapsiblePanel
                          isOpen={isAssociatedRcdOpen}
                          onOpen={() => setIsAssociatedRcdOpen(true)}
                          onClose={() => setIsAssociatedRcdOpen(false)}
                          title="Associated RCD (if any)"
                        >
                          <div className="associated-rcd-fields">
                            <ValidatedFieldWrapper
                              validationResult={validationState.associatedRcdBs}
                              isTouched={touchedState.associatedRcdBs ?? false}
                            >
                              <DropdownField
                                label={
                                  distributionBoardFieldLabels.associatedRcdBs
                                }
                                name="associatedRcdBs"
                                value={formState.associatedRcdBs}
                                onChange={onFieldChange}
                                onTouch={onFieldTouch}
                                options={rcdBss.map((x) => ({
                                  label: x,
                                  value: x,
                                }))}
                              />
                            </ValidatedFieldWrapper>
                            <ValidatedFieldWrapper
                              validationResult={
                                validationState.associatedRcdType
                              }
                              isTouched={
                                touchedState.associatedRcdType ?? false
                              }
                            >
                              <DropdownField
                                label={
                                  distributionBoardFieldLabels.associatedRcdType
                                }
                                name="associatedRcdType"
                                value={formState.associatedRcdType}
                                onChange={onFieldChange}
                                onTouch={onFieldTouch}
                                options={rcdTypes.map((x) => ({
                                  label: x,
                                  value: x,
                                }))}
                              />
                            </ValidatedFieldWrapper>
                            <ValidatedFieldWrapper
                              validationResult={
                                validationState.associatedRcdResidualCurrent
                              }
                              isTouched={
                                touchedState.associatedRcdResidualCurrent ??
                                false
                              }
                            >
                              <DropdownField
                                label={
                                  distributionBoardFieldLabels.associatedRcdResidualCurrent
                                }
                                name="associatedRcdResidualCurrent"
                                value={formState.associatedRcdResidualCurrent}
                                onChange={onFieldChange}
                                onTouch={onFieldTouch}
                                options={currents.map((x) => ({
                                  label: x.toString(),
                                  value: x.toString(),
                                }))}
                              />
                            </ValidatedFieldWrapper>

                            <ValidatedFieldWrapper
                              validationResult={
                                validationState.associatedRcdNoOfPoles
                              }
                              isTouched={
                                touchedState.associatedRcdNoOfPoles ?? false
                              }
                            >
                              <BasicField
                                type="number"
                                label={
                                  distributionBoardFieldLabels.associatedRcdNoOfPoles
                                }
                                name="associatedRcdNoOfPoles"
                                value={formState.associatedRcdNoOfPoles}
                                onChange={onFieldChange}
                                onTouch={onFieldTouch}
                              />
                            </ValidatedFieldWrapper>
                            <ValidatedFieldWrapper
                              validationResult={
                                validationState.associatedRcdOperatingTime
                              }
                              isTouched={
                                touchedState.associatedRcdOperatingTime ?? false
                              }
                            >
                              <BasicField
                                type="number"
                                label={
                                  distributionBoardFieldLabels.associatedRcdOperatingTime
                                }
                                name="associatedRcdOperatingTime"
                                value={formState.associatedRcdOperatingTime}
                                onChange={onFieldChange}
                                onTouch={onFieldTouch}
                              />
                            </ValidatedFieldWrapper>
                          </div>
                        </CollapsiblePanel>
                      </div>
                    </>
                  )}
                </div>
              )}

              <CollapsiblePanel
                title="Tested by"
                isOpen={isTestedByPanelOpen}
                onOpen={() => setIsTestedByPanelOpen(true)}
                onClose={() => setIsTestedByPanelOpen(false)}
              >
                <div>
                  <ValidatedFieldWrapper
                    validationResult={validationState.testEngineerName}
                    isTouched={touchedState.testEngineerName ?? false}
                  >
                    <BasicField
                      label={distributionBoardFieldLabels.testEngineerName}
                      name="testEngineerName"
                      value={formState.testEngineerName}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                      isRequired
                    />
                  </ValidatedFieldWrapper>
                  <ValidatedFieldWrapper
                    validationResult={validationState.testEngineerPosition}
                    isTouched={touchedState.testEngineerPosition ?? false}
                  >
                    <BasicField
                      label={distributionBoardFieldLabels.testEngineerPosition}
                      name="testEngineerPosition"
                      value={formState.testEngineerPosition}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                      isRequired
                    />
                  </ValidatedFieldWrapper>
                  <ValidatedFieldWrapper
                    validationResult={
                      validationState.testEngineerSignatureBase64
                    }
                    isTouched={
                      touchedState.testEngineerSignatureBase64 ?? false
                    }
                  >
                    <div className="form-signature-container">
                      <FormSignatureCaptureControl
                        label={
                          distributionBoardFieldLabels.testEngineerSignatureBase64
                        }
                        currentSignature={
                          formState.testEngineerSignatureBase64 ?? ''
                        }
                        onResponseCaptureComplete={(
                          testEngineerSignatureBase64
                        ) => {
                          onFieldChange({
                            testEngineerSignatureBase64,
                            testEngineerDate:
                              formState.testEngineerDate ||
                              new Date().toISOString(),
                          });
                          onFieldTouch('testEngineerSignatureBase64');
                        }}
                      />
                    </div>
                  </ValidatedFieldWrapper>
                  <ValidatedFieldWrapper
                    validationResult={validationState.testEngineerDate}
                    isTouched={touchedState.testEngineerDate ?? false}
                  >
                    <DateField
                      label={distributionBoardFieldLabels.testEngineerDate}
                      name="testEngineerDate"
                      value={
                        formState.testEngineerDate
                          ? new Date(formState.testEngineerDate)
                          : undefined
                      }
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                      isRequired
                    />
                  </ValidatedFieldWrapper>
                </div>
              </CollapsiblePanel>
              <CollapsiblePanel
                title="Test instruments"
                isOpen={isTestInstrumentsPanelOpen}
                onOpen={() => setIsTestInstrumentsPanelOpen(true)}
                onClose={() => setIsTestInstrumentsPanelOpen(false)}
              >
                <div>
                  <p>Enter serial number against each instrument used</p>
                  <ValidatedFieldWrapper
                    validationResult={
                      validationState.multiFunctionTestInstrumentSerialNumber
                    }
                    isTouched={
                      touchedState.multiFunctionTestInstrumentSerialNumber ??
                      false
                    }
                  >
                    <BasicField
                      label={
                        distributionBoardFieldLabels.multiFunctionTestInstrumentSerialNumber
                      }
                      name="multiFunctionTestInstrumentSerialNumber"
                      value={formState.multiFunctionTestInstrumentSerialNumber}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                    />
                  </ValidatedFieldWrapper>
                  <ValidatedFieldWrapper
                    validationResult={
                      validationState.continuityTestInstrumentSerialNumber
                    }
                    isTouched={
                      touchedState.continuityTestInstrumentSerialNumber ?? false
                    }
                  >
                    <BasicField
                      label={
                        distributionBoardFieldLabels.continuityTestInstrumentSerialNumber
                      }
                      name="continuityTestInstrumentSerialNumber"
                      value={formState.continuityTestInstrumentSerialNumber}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                    />
                  </ValidatedFieldWrapper>
                  <ValidatedFieldWrapper
                    validationResult={
                      validationState.insulationResistanceTestInstrumentSerialNumber
                    }
                    isTouched={
                      touchedState.insulationResistanceTestInstrumentSerialNumber ??
                      false
                    }
                  >
                    <BasicField
                      label={
                        distributionBoardFieldLabels.insulationResistanceTestInstrumentSerialNumber
                      }
                      name="insulationResistanceTestInstrumentSerialNumber"
                      value={
                        formState.insulationResistanceTestInstrumentSerialNumber
                      }
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                    />
                  </ValidatedFieldWrapper>
                  <ValidatedFieldWrapper
                    validationResult={
                      validationState.earthFaultLoopImpedanceTestInstrumentSerialNumber
                    }
                    isTouched={
                      touchedState.earthFaultLoopImpedanceTestInstrumentSerialNumber ??
                      false
                    }
                  >
                    <BasicField
                      label={
                        distributionBoardFieldLabels.earthFaultLoopImpedanceTestInstrumentSerialNumber
                      }
                      name="earthFaultLoopImpedanceTestInstrumentSerialNumber"
                      value={
                        formState.earthFaultLoopImpedanceTestInstrumentSerialNumber
                      }
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                    />
                  </ValidatedFieldWrapper>
                  <ValidatedFieldWrapper
                    validationResult={
                      validationState.earthElectrodeResistanceTestInstrumentSerialNumber
                    }
                    isTouched={
                      touchedState.earthElectrodeResistanceTestInstrumentSerialNumber ??
                      false
                    }
                  >
                    <BasicField
                      label={
                        distributionBoardFieldLabels.earthElectrodeResistanceTestInstrumentSerialNumber
                      }
                      name="earthElectrodeResistanceTestInstrumentSerialNumber"
                      value={
                        formState.earthElectrodeResistanceTestInstrumentSerialNumber
                      }
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                    />
                  </ValidatedFieldWrapper>
                  <ValidatedFieldWrapper
                    validationResult={
                      validationState.rcdTestInstrumentSerialNumber
                    }
                    isTouched={
                      touchedState.rcdTestInstrumentSerialNumber ?? false
                    }
                  >
                    <BasicField
                      label={
                        distributionBoardFieldLabels.rcdTestInstrumentSerialNumber
                      }
                      name="rcdTestInstrumentSerialNumber"
                      value={formState.rcdTestInstrumentSerialNumber}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                    />
                  </ValidatedFieldWrapper>
                </div>
              </CollapsiblePanel>

              <div className="actions-container">
                <Button
                  isDisabled={!isValid}
                  onClick={handleClickSave}
                  styleVariant={ButtonStyleVariant.OUTSIZE}
                >
                  Save
                </Button>
              </div>
            </>
          );
        }}
      />
    </Modal>
  );
};
