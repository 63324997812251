import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { FC } from 'react';

import '@payaca/components/invoiceBody/InvoiceBody.sass';
import {
  invoiceLineItemGroupPositionIndexSort,
  invoiceLineItemPositionIndexSort,
} from '@payaca/helpers/invoiceHelper';
import {
  useInvoiceLinesForDeal,
  useInvoicesForDeal,
} from '@payaca/store/hooks/appState';
import { InvoiceLine } from '@payaca/types/invoiceTypes';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import { flatMap } from 'lodash-es';
import EditInvoiceLineControlRow from './EditInvoiceLineControlRow';
import './EditInvoiceLinesControl.sass';

type Props = {
  dealId: number;
  invoiceLines: Pick<
    InvoiceLine,
    'id' | 'value' | 'jobLineItemId' | 'jobLineItemPercentage'
  >[];
  onChange: (
    invoiceLines: Pick<
      InvoiceLine,
      'id' | 'value' | 'jobLineItemId' | 'jobLineItemPercentage'
    >[]
  ) => void;
  onSaveRequired?: () => void;
  jobLineItems: JobLineItem[];
};

const EditInvoiceLinesControl: FC<Props> = ({
  dealId,
  invoiceLines,
  onChange,
  onSaveRequired,
  jobLineItems,
}: Props): JSX.Element | null => {
  const invoiceLinesForDeal = useInvoiceLinesForDeal(dealId);
  const existingInvoices = useInvoicesForDeal(dealId);
  const unvoidedInvoices = existingInvoices.filter((x) => !x.voidedAt);
  const unvoidedInvoiceLinesForDeal = flatMap(unvoidedInvoices, (x) =>
    x.invoiceLineIds
      .map((y) => invoiceLinesForDeal.find((z) => z.id === y))
      .filter(Boolean)
  ) as InvoiceLine[];

  const parseItemToSort = (
    invoiceLine: Pick<InvoiceLine, 'id' | 'value' | 'jobLineItemId'>
  ) => {
    const jli = jobLineItems.find((y) => y.id === invoiceLine.jobLineItemId);
    return {
      ...invoiceLine,
      jobLineItem: {
        positionIndex: jli?.positionIndex,
        jobLineItemGroupIndex: jli?.jobLineItemGroupIndex,
      },
    };
  };

  return (
    <ResponsiveViewWrapper
      className="edit-invoice-lines-control invoice-body"
      downBreakpointSm={650}
    >
      <div>
        <table>
          <thead>
            <tr>
              <th className="description-table-cell"></th>
              <th className="total-table-cell">Total</th>
              <th className="due-percentage-table-cell">% Due</th>
              <th className="due-amount-table-cell">Due</th>
            </tr>
          </thead>
          {invoiceLines
            .sort((a, b) =>
              invoiceLineItemPositionIndexSort(
                parseItemToSort(a),
                parseItemToSort(b)
              )
            )
            .sort((a, b) =>
              invoiceLineItemGroupPositionIndexSort(
                parseItemToSort(a),
                parseItemToSort(b)
              )
            )
            .map((invoiceLine, i) => {
              return (
                <EditInvoiceLineControlRow
                  key={i}
                  invoiceLine={invoiceLine}
                  invoiceLinesForDeal={unvoidedInvoiceLinesForDeal}
                  onChange={({ value, percentage }) => {
                    const il = invoiceLines.map((x) => {
                      if (x.id != invoiceLine.id) return x;
                      return {
                        ...x,
                        jobLineItemPercentage: percentage,
                        value: value,
                      };
                    });
                    onChange(il);
                  }}
                  onSaveRequired={onSaveRequired}
                />
              );
            })}
        </table>
      </div>
    </ResponsiveViewWrapper>
  );
};
export default EditInvoiceLinesControl;
