import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import CreateEditProposalTemplateControl from '@/ui/components/createEditProposalTemplateControl/CreateEditProposalTemplateControl';
import CreateEditScheduledEventTemplateControl from '@/ui/components/createEditScheduledEventTemplateControl/CreateEditScheduledEventTemplateControl';
import CreateEditTaskTemplateControl from '@/ui/components/createEditTaskTemplateControl/CreateEditTaskTemplateControl';
import LoaderOverlay from '@payaca/components/loaderOverlay/LoaderOverlay';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';
import {
  createTemplate,
  getTemplate,
  updateTemplate,
} from '@payaca/store/templates/templatesActions';
import {
  EntityTemplate,
  PublicEntityTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

const CreateEditEntityTemplatePage: FC<{
  templatePublicId?: EntityTemplate<TemplatableEntity>['publicId'];
}> = ({ templatePublicId }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [template, setTemplate] = useState<EntityTemplate<TemplatableEntity>>();

  const [entityType, setEntityType] = useState(
    getUrlSearchParam(location.search, 'entityType')
  );

  useEffect(() => {
    if (templatePublicId) {
      dispatch(
        getTemplate.request({
          publicId: templatePublicId,
          callback: (t) => {
            setTemplate(t);
            setEntityType(t.entityType);
          },
          onErrorCallback: () => {
            history.goBack();
          },
        })
      );
    }
  }, [templatePublicId]);

  useEffect(() => {
    if (!entityType && !templatePublicId) {
      history.goBack();
    }
  }, [templatePublicId, entityType]);

  const title = useMemo(() => {
    return `${templatePublicId ? 'Edit' : 'Create'} ${
      entityType == 'task'
        ? 'Task'
        : entityType == 'scheduledEvent'
          ? 'Event'
          : 'Proposal'
    } Template`;
  }, [entityType, templatePublicId]);

  const onRequestSave = useCallback(
    (t: Omit<PublicEntityTemplate<TemplatableEntity>, 'publicId'>) => {
      if (templatePublicId) {
        dispatch(
          updateTemplate.request({
            publicId: templatePublicId,
            template: {
              ...t,
            },
            callback: () => {
              history.goBack();
            },
          })
        );
      } else {
        dispatch(
          createTemplate.request({
            template: t,
            callback: () => {
              history.goBack();
            },
          })
        );
      }
    },
    [templatePublicId]
  );

  return (
    <>
      <AuthenticatedPageWrapper
        title={title}
        previousPageNavigationConfig={{
          route: `/templates/${entityType?.toLowerCase()}-templates`,
        }}
      >
        {!template && templatePublicId && <LoaderOverlay />}
        <div className="max-w-[1200px] p-6">
          {entityType === 'task' && (
            <CreateEditTaskTemplateControl
              taskTemplate={
                template as Omit<PublicEntityTemplate<'task'>, 'publicId'>
              }
              allowModifyTemplateNameDescription={true}
              onRequestSave={onRequestSave}
            />
          )}
          {entityType === 'scheduledEvent' && (
            <CreateEditScheduledEventTemplateControl
              allowModifyTemplateNameDescription={true}
              scheduledEventTemplate={
                template as Omit<
                  PublicEntityTemplate<'scheduledEvent'>,
                  'publicId'
                >
              }
              onRequestSave={onRequestSave}
            />
          )}
          {entityType === 'proposal' && (
            <CreateEditProposalTemplateControl
              allowModifyTemplateNameDescription={true}
              proposalTemplate={
                template as Omit<PublicEntityTemplate<'proposal'>, 'publicId'>
              }
              onRequestSave={onRequestSave}
            />
          )}
        </div>
      </AuthenticatedPageWrapper>
    </>
  );
};

export default CreateEditEntityTemplatePage;
