import {
  ProductPrice,
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';
import Stripe from 'stripe';

export const recurringIntervalLabelMap: Record<RecurringInterval, string> = {
  [RecurringInterval.MONTH]: 'monthly',
  [RecurringInterval.YEAR]: 'yearly',
};

export const getProductPriceCostExcludingVat = (
  price: ProductPrice,
  additionalUserSeats: number
) => {
  return (
    price.basicCost + (price.additionalUserSeatCost || 0) * additionalUserSeats
  );
};

export const getSubscriptionCostExcludingVat = (
  product: SubscriptionProduct,
  recurringInterval: RecurringInterval,
  additionalUserSeats: number
) => {
  const price =
    recurringInterval === 'month' ? product.monthlyPrice : product.annualPrice;

  if (!price) return;

  return getProductPriceCostExcludingVat(price, additionalUserSeats);
};

export const getSubscriptionCostIncludingVat = (
  product: SubscriptionProduct,
  recurringInterval: RecurringInterval,
  additionalUserSeats: number,
  taxRatePercentage: number
) => {
  const subscriptionCostExcludingVat = getSubscriptionCostExcludingVat(
    product,
    recurringInterval,
    additionalUserSeats
  );
  if (!subscriptionCostExcludingVat) return;

  const vat = subscriptionCostExcludingVat * (taxRatePercentage / 100);

  return subscriptionCostExcludingVat + vat;
};

export const getProductCostsFromStripePrice = (stripePrice: Stripe.Price) => {
  const isTieredPricing = stripePrice.billing_scheme === 'tiered';

  const basicCost =
    isTieredPricing && stripePrice.tiers && stripePrice.tiers[0]
      ? stripePrice.tiers[0].flat_amount
      : stripePrice.unit_amount;
  const additionalUserSeatCost =
    isTieredPricing && stripePrice.tiers && stripePrice.tiers[1]
      ? stripePrice.tiers[1].unit_amount
      : null;

  return {
    stripeId: stripePrice.id,
    basicCost: basicCost || 0,
    additionalUserSeatCost: additionalUserSeatCost || undefined,
    canBuyAdditionalUserSeats: isTieredPricing && !!additionalUserSeatCost,
  };
};

export const hasUnlimitedUsers = (numberOfUserSeats?: number): boolean =>
  !!numberOfUserSeats && numberOfUserSeats >= 999;
