import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import SendJob from '../sendJob/SendJob';

import * as customerActions from '@payaca/store/customer/customerActions';
import * as dealsActions from '@payaca/store/deals/dealsActions';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as jobPaymentsActions from '@payaca/store/jobPayments/jobPaymentsActions';
import * as jobsStoreActions from '@payaca/store/jobs/jobsActions';

import { useSelector } from '@/api/state';
import { getJobContentByJobId } from '@/utils/stateAccessors';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import './ResendJobModal.css';

type Props = {
  isOpen: boolean;
  jobId: number;
  onClose: () => void;
};
const ResendJobModal: FC<Props> = ({
  isOpen,
  jobId,
  onClose,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const isResendingJob = useSelector((state) => {
    return state.jobsStore.isResendingJob;
  });

  const resendJobErrorMessage = useSelector((state) => {
    return state.jobsStore.resendJobErrorMessage;
  });

  const isResentJobSuccessfully = useSelector((state) => {
    return state.jobsStore.isResentJobSuccessfully;
  });

  const [resendEmailState, setResendEmailState] = useState<{
    preButtonEmailText: string;
    postButtonEmailText: string;
    sendMeACopy: boolean;
    isSendJobDisabled: boolean;
  }>({
    preButtonEmailText: '',
    postButtonEmailText: '',
    sendMeACopy: true,
    isSendJobDisabled: true,
  });

  const onResendJob = useCallback(() => {
    dispatch(
      jobsStoreActions.requestResendJob(jobId, {
        preButtonEmailText: resendEmailState.preButtonEmailText,
        postButtonEmailText: resendEmailState.postButtonEmailText,
        sendMeACopy: resendEmailState.sendMeACopy,
      })
    );
  }, [dispatch, jobId, onClose, resendEmailState]);

  useEffect(() => {
    // resent successfully and
    if (!isResendingJob && isResentJobSuccessfully) {
      dispatch(jobsStoreActions.clearResendJob());
      onClose();
    }
  }, [dispatch, isResendingJob, isResentJobSuccessfully, onClose]);

  useEffect(() => {
    dispatch(jobsStoreActions.requestGetJob(jobId));
  }, [jobId]);

  const job = useSelector((state) => {
    return state.jobsStore.jobs && state.jobsStore.jobs[jobId]?.entity;
  });

  useEffect(() => {
    if (!job?.jobContentId) return;
    dispatch(jobContentActions.requestGetJobContent(job.jobContentId));
  }, [job]);

  const jobContent = useSelector((state) => {
    return getJobContentByJobId(state, jobId);
  });

  const deal = useSelector((state) => {
    if (!job?.dealId) return;
    return state.deals.deals && state.deals.deals[job.dealId]?.entity;
  });

  useEffect(() => {
    if (job) {
      dispatch(jobPaymentsActions.requestGetJobPaymentsForDeal(job?.dealId));
      dispatch(dealsActions.requestGetDeal(job.dealId));
    }
  }, [job?.dealId]);

  useEffect(() => {
    if (deal?.customerId) {
      dispatch(
        customerActions.requestGetAndSetCurrentCustomer(deal.customerId)
      );
    }
  }, [deal?.customerId]);

  const jobPayments: JobPayment[] = useSelector((state) => {
    if (!job?.dealId) return [];

    const deal = state.deals.deals && state.deals.deals[job?.dealId]?.entity;
    const jobPaymentIds = deal?.jobPaymentIds || [];

    const payments = jobPaymentIds.map((jobPaymentId: number) => {
      return state.jobPayments.jobPayments[jobPaymentId]?.entity;
    });

    return payments.filter((x?: JobPayment) => !!x);
  });

  const customer = useSelector((state) => state.customer.currentCustomer);

  return (
    <Modal
      isOpen={isOpen}
      title="Resend job"
      size="md"
      onClose={onClose}
      className="resend-job-modal"
      actions={
        <Button
          onClick={() => !isResendingJob && onResendJob()}
          isDisabled={resendEmailState.isSendJobDisabled}
          iconAfter={faChevronRight}
          isProcessing={isResendingJob}
          styleVariant={ButtonStyleVariant.OUTSIZE}
        >
          Send
        </Button>
      }
    >
      {job && jobContent && (
        <SendJob
          job={job}
          jobContent={jobContent}
          jobPayments={jobPayments}
          customer={customer! /* FIXME */}
          preButtonEmailText={resendEmailState.preButtonEmailText || ''}
          postButtonEmailText={resendEmailState.postButtonEmailText || ''}
          sendMeACopy={!!resendEmailState.sendMeACopy}
          onUpdate={(change: any) => {
            setResendEmailState((resendEmailState) => ({
              ...resendEmailState,
              ...change,
            }));
          }}
          onSetIsSendJobDisabled={(isSendJobDisabled: boolean) => {
            setResendEmailState((resendEmailState) => {
              return {
                ...resendEmailState,
                isSendJobDisabled: isSendJobDisabled,
              };
            });
          }}
        />
      )}
      {resendJobErrorMessage && (
        <div className="error">
          Sorry, there was an error resending your job. Please try again.
        </div>
      )}
    </Modal>
  );
};

export default ResendJobModal;
