import { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@payaca/components/modal/Modal';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import * as proposalsActions from '@payaca/store/proposals/proposalsActions';

import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';

type Props = {
  isOpen: boolean;
  changeProposalId: number;
  onClose: () => void;
  markAsSentCallback?: () => void;
};

const MarkChangeProposalAsSentModal: FunctionComponent<Props> = ({
  isOpen,
  changeProposalId,
  onClose,
  markAsSentCallback,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [errorMessages, setErrorMessages] = useState<string[]>();

  const [isProcessing, setIsProcessing] = useState(false);

  const onMarkAsSent = useCallback(() => {
    setIsProcessing(true);
    dispatch(
      proposalsActions.markChangeProposalAsSent.request({
        changeProposalId: changeProposalId,
        callback: () => {
          setIsProcessing(false);
          markAsSentCallback && markAsSentCallback();
          setErrorMessages([]);
          onClose();
        },
        onErrorCallback: (errorMessages: string[]) => {
          setErrorMessages(errorMessages);
          setIsProcessing(false);
        },
      })
    );
  }, [changeProposalId, markAsSentCallback, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      title="Mark change proposal as sent"
      size="xs"
      onClose={onClose}
      className="mark-as-sent-modal"
      actions={
        <>
          <Button
            onClick={onMarkAsSent}
            iconAfter={faChevronRight}
            isProcessing={isProcessing}
            styleVariant={ButtonStyleVariant.OUTSIZE}
          >
            Mark as sent without sending
          </Button>
          {!!errorMessages?.length && (
            <FeedbackBlock feedbackLevel={FeedbackLevel.ERROR}>
              <strong>This change proposal could not be marked as sent:</strong>
              <ul>
                {errorMessages.map((x, i) => (
                  <li key={i}>{x}</li>
                ))}
              </ul>
            </FeedbackBlock>
          )}
        </>
      }
    >
      <p>
        This option is intended to be used in situations where you have sent the{' '}
        change proposal offline. For example - where you have printed off the
        change proposal and handed it to your customer on paper, in person.
      </p>
      <p>When an change proposal has been marked as sent:</p>
      <ul>
        <li>No emails or other communication will go to the customer</li>
        <li>
          You will not be able to retrospectively send this change proposal to
          the customer using the standard Payaca process
        </li>
        <li>
          If your account is connected to any third party services (Zapier,
          Xero, Quickbooks), they will treat this change proposal as they would
          any other sent change proposal
        </li>
      </ul>
    </Modal>
  );
};

export default MarkChangeProposalAsSentModal;
