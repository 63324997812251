import {
  CustomFieldDefinition,
  CustomFieldType,
} from '@payaca/custom-fields/types/index';
import { Nullable, Nullish } from '@payaca/utilities/types';
import { IndustryTypes, NumberOfEmployees } from './companyInformationTypes';
import { Features } from './featureTypes';
import { Integrations } from './integrationTypes';
import { Pipeline } from './pipelineTypes';
import { BusinessHours } from './scheduleTypes';
import { ScheduledEventColourCoding } from './scheduledEventsTypes';
import { Tag } from './tagTypes';

export enum AccountRegions {
  AUSTRALIA = 'au',
  CANADA = 'ca',
  NEW_ZEALAND = 'nz',
  SOUTH_AFRICA = 'za',
  UK = 'uk',
  US = 'us',
}

export interface Account {
  accountName: string;
  activeSubscription: boolean;
  address?: string;
  city?: string;
  companyName: string;
  connectedToStripe: boolean;
  contactNumber?: string;
  createdAt: string;
  email?: string;
  id: number;
  logoUrl?: string;
  // @deprecated
  paymentTerms?: string;
  proposalPaymentTerms?: Nullish<string>;
  invoicePaymentTerms?: Nullish<string>;
  postcode?: Nullish<string>;
  stripeCustomerId?: Nullish<string>;
  stripePaymentMethodBrand?: string;
  stripeCurrentPeriodEndsAt?: string;
  stripePaymentMethodExpMonth?: string;
  stripePaymentMethodExpYear?: string;
  stripePaymentMethodLast4?: string;
  stripeConnectUserId?: Nullable<string>;
  updatedAt: string;
  uuid: string;
  vatNumber?: string;
  accountNumberRaw?: string;
  accountNameRaw?: string;
  sortCodeRaw?: string;
  businessNotes?: string;
  brandColour?: string;
  integrations: Integrations;
  isCisSubcontractor: boolean;
  cisDeductionRate: null | number;
  legalBusinessName: string;
  region: AccountRegions;
  propositionValidForDays?: number;
  invoiceDueInDays?: number;
  gasSafeRegNo?: string;
  niceicRegNo?: string;
  allowStripePaymentDefault?: boolean;
  allowBankTransferPaymentDefault?: boolean;
  smsSenderHandle?: string;
  industryType?: IndustryTypes | string;
  numberOfEmployees?: NumberOfEmployees | string;
  companyRegistrationNumber?: string;
  registeredCountryName?: string;
  quickbooksRealmId: Nullable<string>;
  quickbooksAccessToken: Nullable<string>;
  quickbooksRefreshToken: Nullable<string>;
  xeroRefreshToken: Nullable<string>;
  preferredTimezone?: Nullable<string>;
  businessHours?: Nullable<BusinessHours>;
  scheduledEventColourCoding?: Nullable<ScheduledEventColourCoding>;
  primaryPipelineId?: Nullable<Pipeline['id']>;
  subdomain: Nullable<string>;
  latitude?: Nullable<number>;
  longitude?: Nullable<number>;
}

export interface BusinessAttachment {
  attachmentUrl: string;
  createdAt: string;
  fileName: string;
  id: string;
  updatedAt: string;
  account_id: string;
}

export interface AccountAccessInformation {
  hasActiveSubscription: boolean;
  hasExceededActiveSubscription: boolean;
  hasExceededActiveSubscriptionUserSeats: boolean;
  hasInactiveSubscription: boolean;
  isTrialPeriodExpired: boolean;
  trialPeriodDaysRemaining: number | null;
  onboardingRequestedFor: Date | null;
  features: Features;
  temporarySubscription: null | {
    name: string;
    expiresAt: string;
  };
  discountPercentage?: Nullish<number>;
  discountAvailableUntil?: Nullish<number>;
}

// decorated account interface for customer preview
export interface AccountForPreview {
  id: number;
  address?: string;
  postcode?: string;
  companyName: string;
  city?: string;
  contactNumber?: string;
  email?: string;
  fallbackEmail: string;
  logoUrl?: string;
  brandColour?: string;
  businessNotes?: string;
  uuid: string;
  legalBusinessName: string;
  region: AccountRegions;

  // @deprecated
  paymentTerms?: string;
  proposalPaymentTerms?: Nullish<string>;
  invoicePaymentTerms?: Nullish<string>;
  accountNumberRaw?: string;
  accountNameRaw?: string;

  sortCodeRaw?: string;
  vatNumber?: string;

  /* eslint-disable-next-line @typescript-eslint/ban-types */
  integrations: {};
}

export interface AccountAccessInformationForPreview {
  /* eslint-disable-next-line @typescript-eslint/ban-types */
  features: {};
}

export interface EmailTemplates {
  sendQuote: string;
  sendInvoice: string;
  sendEstimate: string;
}
export interface AccountEmailTemplates extends EmailTemplates {
  defaults?: EmailTemplates;
}

export interface CreateAccountRequestData {
  legalBusinessName: string;
  email: string;
  contactNumber: string;
  numberOfEmployees: string;
  industryType: string;
  industryTypeOther?: string;
}

export type AdditionalWorkRequestProjectBehaviour =
  | 'new-project'
  | 'update-existing-project';

export type AdditionalWorkRequestConfig = {
  targetPipelineId?: Nullish<Pipeline['id']>;
  newProjectTags?: Tag['tagText'][];
  projectBehaviour: AdditionalWorkRequestProjectBehaviour[];
  fields: CustomFieldDefinition<CustomFieldType>[];
};

export type AdditionalWorkRequestConfigOption = {
  id: string;
  name: string;
  config: AdditionalWorkRequestConfig;
};
