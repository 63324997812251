import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  ActionType,
  PersistSupplierRequestData,
  SagaConfig,
} from './suppliersTypes';

import {
  archiveSuppliersFailure,
  archiveSuppliersSuccess,
  clearSuppliers,
  getSupplierFailure,
  getSuppliersForMaterialSuccess,
  getSupplierSuccess,
  persistSupplierFailure,
  persistSupplierSuccess,
} from './suppliersActions';

import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const suppliersSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);
  function* handlePersistSupplier(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_SUPPLIER,
      {
        persistSupplierRequestData: PersistSupplierRequestData;
        callback?: (supplierId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          persistSupplier,
          action.payload.persistSupplierRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistSupplierFailure(new Error('Persist supplier timed out.'))
        );
      } else {
        yield put(persistSupplierSuccess());
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(persistSupplierFailure(error as Error));
    }
  }

  const persistSupplier = async (
    persistSupplierRequestData: PersistSupplierRequestData
  ) => {
    const response = await req.post(`/suppliers`, persistSupplierRequestData);

    return await response.json();
  };

  function* handleGetSupplier(
    action: PayloadAction<
      ActionType.REQUEST_GET_SUPPLIER,
      {
        supplierId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getSupplier, action.payload.supplierId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get supplier timed out.';
        yield put(
          getSupplierFailure(action.payload.supplierId, new Error(errorMessage))
        );
      } else {
        yield put(getSupplierSuccess(response.id, response));
      }
    } catch (error: any) {
      yield put(getSupplierFailure(action.payload.supplierId, error));
    }
  }

  const getSupplier = async (supplierId: number) => {
    const response = await req.get(`/suppliers/${supplierId}`);
    return response.json();
  };

  function* handleGetSuppliersForMaterial(
    action: PayloadAction<
      ActionType.REQUEST_GET_SUPPLIERS_FOR_MATERIAL,
      {
        materialId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getSuppliersForMaterial, action.payload.materialId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getSuppliersForMaterial request timed out');
      }

      yield put(getSuppliersForMaterialSuccess(response));
    } catch (error: any) {}
  }

  const getSuppliersForMaterial = async (materialId: number) => {
    const response = await req.get(`/suppliers/material/${materialId}`);
    return response.json();
  };

  function* handleArchiveSuppliers(
    action: PayloadAction<
      ActionType.REQUEST_ARCHIVE_SUPPLIERS,
      {
        supplierIds: number[];
        onArchiveSuccess?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(archiveSuppliers, action.payload.supplierIds),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          archiveSuppliersFailure(new Error('Archive suppliers timed out.'))
        );
      } else {
        action.payload.onArchiveSuccess && action.payload.onArchiveSuccess();
        yield put(archiveSuppliersSuccess());
      }
    } catch (error: any) {
      yield put(archiveSuppliersFailure(error));
    }
  }

  const archiveSuppliers = async (supplierIds: number[]) => {
    const response = await req.put('/suppliers/bulk_archive', {
      supplierIds,
    });
    return response;
  };

  function* handleLogout() {
    yield put(clearSuppliers());
  }

  return function* () {
    yield takeLatest(
      ActionType.REQUEST_PERSIST_SUPPLIER,
      handlePersistSupplier
    );
    yield takeEvery(ActionType.REQUEST_GET_SUPPLIER, handleGetSupplier);
    yield takeEvery(
      ActionType.REQUEST_GET_SUPPLIERS_FOR_MATERIAL,
      handleGetSuppliersForMaterial
    );
    yield takeEvery(
      ActionType.REQUEST_ARCHIVE_SUPPLIERS,
      handleArchiveSuppliers
    );

    yield takeEvery('auth.logout', handleLogout);
  };
};

export default suppliersSagaCreator;
