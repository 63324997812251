import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getEmailFieldValidator,
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
  getRegexMatchFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import {
  PayacaPagesQuestionOptions,
  PayacaPagesQuestionTypes,
  PayacaPageTemplateProps,
} from '@payaca/types/payacaPagesTypes';
import React, { FC, useCallback, useMemo } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import ValidatedFieldWrapper from '../validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '../validatedForm/ValidatedForm';

const RECAPTCHA_SITE_KEY =
  import.meta.env.VITE_RECAPTCHA_SITE_KEY ||
  '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

const initialFormState = {};

export const PayacaPagesNewLead: FC<PayacaPageTemplateProps> = ({
  onSubmit,
  isComplete,
  isLoading,
  questions = [],
}): JSX.Element => {
  const fieldValidators = useMemo(() => {
    const baseValidation = {
      name: [
        getIsRequiredFieldValidator(),
        getLengthFieldValidator({ min: 0, max: 255 }),
      ],
      email: [getIsRequiredFieldValidator(), getEmailFieldValidator()],
      phone: [
        getRegexMatchFieldValidator(/^[0-9+() -]{3,14}$/, {
          customErrorMessage: 'This must be a valid contact number',
        }),
      ],
      recaptcha: [getIsRequiredFieldValidator()],
    };
    // do we have additional questions?
    if (!questions.length) return baseValidation;
    return questions.reduce((acc, questionId) => {
      const validators = [getIsRequiredFieldValidator()];
      switch (
        PayacaPagesQuestionOptions.find(({ id }) => id === questionId)?.type
      ) {
        case PayacaPagesQuestionTypes.TEXTAREA:
          validators.push(getLengthFieldValidator({ min: 0, max: 8000 }));
          break;
        case PayacaPagesQuestionTypes.TEXT:
          validators.push(getLengthFieldValidator({ min: 0, max: 180 }));
          break;
      }
      return { ...acc, [questionId]: validators };
    }, baseValidation);
  }, [questions]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      const renderQuestion = (
        question: any,
        questionId: string,
        onFieldChange: (value: { [key: string]: any }) => void,
        onFieldTouch: (fieldName: string) => void,
        placeholder?: string
      ) => {
        switch (question.type) {
          case PayacaPagesQuestionTypes.RADIO_GROUP:
            return (
              <div className={'form-section'}>
                <ul className={'radio-list'}>
                  {question?.options.map((label: string, i: number) => (
                    <li key={i}>
                      <label className="radio-button">
                        <input
                          type="radio"
                          name={questionId}
                          value={label}
                          onChange={(event) => {
                            onFieldChange({
                              [questionId]: event.target.value,
                            });
                          }}
                        />
                        <span className="label-visible">
                          <span className="fake-radiobutton" />
                          {label}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            );
          case PayacaPagesQuestionTypes.CHECKBOX:
            return (
              <div className={'form-section'}>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name={questionId}
                    onChange={(event) => {
                      onFieldChange({
                        [questionId]: event.target.value,
                      });
                    }}
                  />
                  <span className="label-visible">
                    <span className="fake-checkbox"></span>
                    {placeholder || 'I confirm'}
                  </span>
                </label>
              </div>
            );
          case PayacaPagesQuestionTypes.TEXT:
            return (
              <input
                name={questionId}
                onBlur={() => onFieldTouch(questionId)}
                onChange={(event) => {
                  onFieldChange({ [questionId]: event.target.value });
                }}
              />
            );
          case PayacaPagesQuestionTypes.NUMBER:
            return question.suffix ? (
              <div className={'number-field'}>
                <input
                  type={'number'}
                  name={questionId}
                  onBlur={() => onFieldTouch(questionId)}
                  onChange={(event) =>
                    onFieldChange({ [questionId]: event.target.value })
                  }
                />
                {question.suffix}
              </div>
            ) : (
              <input
                type={'number'}
                name={questionId}
                onBlur={() => onFieldTouch(questionId)}
                onChange={(event) =>
                  onFieldChange({ [questionId]: event.target.value })
                }
              />
            );
          case PayacaPagesQuestionTypes.TEXTAREA:
            return (
              <textarea
                name={questionId}
                onBlur={() => onFieldTouch(questionId)}
                onChange={(event) => {
                  onFieldChange({ [questionId]: event.target.value });
                }}
              />
            );
          case PayacaPagesQuestionTypes.ADDRESS:
            return (
              <div className="form-section address-input">
                <div>
                  <label>Line 1</label>
                  <input
                    name={`${questionId}.line1`}
                    onBlur={() => onFieldTouch(questionId)}
                    onChange={(event) =>
                      onFieldChange({
                        [`${questionId}.line1`]: event.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <label>Line 2</label>
                  <input
                    name={`${questionId}.line2`}
                    onBlur={() => onFieldTouch(questionId)}
                    onChange={(event) =>
                      onFieldChange({
                        [`${questionId}.line2`]: event.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <label>Town / City</label>
                  <input
                    name={`${questionId}.city`}
                    onBlur={() => onFieldTouch(questionId)}
                    onChange={(event) =>
                      onFieldChange({
                        [`${questionId}.city`]: event.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <label>Postcode</label>
                  <input
                    name={`${questionId}.postcode`}
                    onBlur={() => onFieldTouch(questionId)}
                    onChange={(event) =>
                      onFieldChange({
                        [`${questionId}.postcode`]: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
            );
          default:
            return <></>;
        }
      };

      return (
        <>
          <p>{`Add your details and we'll be in touch.`}</p>
          <div className={'form-section'}>
            <label>Name</label>
            <ValidatedFieldWrapper
              validationResult={validationState.name}
              isTouched={touchedState.name || false}
            >
              <input
                name={'name'}
                type={'text'}
                onChange={(event: any) =>
                  onFieldChange({ name: event.target.value })
                }
                onBlur={() => onFieldTouch('name')}
              />
            </ValidatedFieldWrapper>
          </div>
          <div className={'form-section'}>
            <label>E-mail</label>
            <ValidatedFieldWrapper
              validationResult={validationState.email}
              isTouched={touchedState.email || false}
            >
              <input
                name={'email'}
                type={'email'}
                onChange={(event: any) =>
                  onFieldChange({ email: event.target.value })
                }
                onBlur={() => onFieldTouch('email')}
              />
            </ValidatedFieldWrapper>
          </div>
          <div className={'form-section'}>
            <label>Phone</label>
            <ValidatedFieldWrapper
              validationResult={validationState.phone}
              isTouched={touchedState.phone || false}
            >
              <input
                name={'phone'}
                type={'phone'}
                onChange={(event: any) =>
                  onFieldChange({ phone: event.target.value })
                }
                onBlur={() => onFieldTouch('phone')}
              />
            </ValidatedFieldWrapper>
          </div>
          <>
            {PayacaPagesQuestionOptions.map((question, i) =>
              !questions.includes(question.id) ? (
                <React.Fragment key={question.id || i} />
              ) : (
                <div key={question.id || i} className={'form-section'}>
                  <label>{question.label}</label>
                  <ValidatedFieldWrapper
                    validationResult={validationState[question.id]}
                    isTouched={touchedState[question.id] || false}
                  >
                    {renderQuestion(
                      question,
                      question.id,
                      onFieldChange,
                      onFieldTouch,
                      question.placeholder
                    )}
                  </ValidatedFieldWrapper>
                  {question.placeholder && (
                    <div className={'form-helper'}>{question.placeholder}</div>
                  )}
                </div>
              )
            )}
          </>
          <div className={'form-section'}>
            <div className={'recaptcha-container'}>
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={(recaptcha: string | null) =>
                  onFieldChange({ recaptcha })
                }
              />
            </div>
          </div>
          <div className={'form-section'}>
            <button
              type="button"
              disabled={!isValid || isLoading}
              onClick={() => onSubmit(formState)}
            >
              Submit
              {isLoading && (
                <>
                  {' '}
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                </>
              )}
            </button>
          </div>
        </>
      );
    },
    [questions, isLoading]
  );

  return (
    <div className="payaca-pages-new-lead">
      {isComplete ? (
        <div className={'submission-success'}>
          <h3>Thanks!</h3>
          <p>Your details have been added successfully.</p>
        </div>
      ) : (
        <ValidatedForm<{ [key: string]: any }>
          initialFormState={initialFormState}
          renderFormContents={renderFormContents}
          fieldValidators={fieldValidators}
        />
      )}
    </div>
  );
};
