import { FC, useEffect, useMemo, useState } from 'react';

import type { CreateServicePlanDiscountCodeInput } from '@payaca/types/service-plans';

import { faPercent } from '@fortawesome/free-solid-svg-icons';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import DateField from '@payaca/components/dateField/DateField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import DurationInputControl from '@payaca/components/durationInputControl/DurationInputControl';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { InputWidth } from '@payaca/components/inputWrapper/InputWrapper';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import type {
  TFieldValidators,
  TOnFormSubmit,
  TRenderFormContents,
} from '@payaca/components/validatedForm/ValidatedForm';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { VALID_CLIENT_FACING_DISCOUNT_CODE } from '@payaca/constants/regexConstants';
import {
  getConditionalValidator,
  getDateRangeFieldValidator,
  getIsRequiredFieldValidator,
  getIsRequiredIfTrueConditionValidator,
  getLengthFieldValidator,
  getRegexMatchFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { useSelector } from '@payaca/store/hooks/appState';
import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';
import { DurationUnit } from '@payaca/utilities/timeUtilities';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

type TFormState = Omit<
  CreateServicePlanDiscountCodeInput,
  'expiryDate' | 'durationInMonths'
> & {
  displayExpiryDate: boolean;
  expiryDate: Date | null;
  // ISO 8601 standard @see https://momentjs.com/docs/#/durations/as-iso-string/
  durationInMonths?: string;
};

interface IProps {
  onCreateSuccess?: () => void;
}

const CreateServicePlanDiscountCodeControl: FC<IProps> = (props) => {
  const { onCreateSuccess } = props;
  const dispatch = useDispatch();
  const [showError, setShowError] = useState<false | string>(false);
  const [isCreatingDiscountCode, setIsCreatingDiscountCode] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(servicePlansActions.getListedServicePlans.request({}));
  }, [dispatch]);

  const listedServicePlans = useSelector(
    (state) => state.servicePlans.listedServicePlans
  );

  const servicePlanOptions = useMemo(
    () =>
      listedServicePlans.map((servicePlan) => ({
        label: servicePlan.name,
        value: servicePlan.publicId,
      })),
    [listedServicePlans]
  );

  const initialFormState = useMemo<TFormState>(
    () => ({
      name: '',
      percentOff: 25,
      duration: 'repeating',
      durationInMonths: moment.duration(3, 'months').toISOString(),
      displayExpiryDate: false,
      expiryDate: null,
      clientFacingCode: '',
      appliesToServicePlanPublicIds: [],
    }),
    []
  );

  const onFormSubmit: TOnFormSubmit<TFormState> = (formState) => {
    const { displayExpiryDate, ...augmentedFormState } = formState;

    const requestPayload = {
      ...augmentedFormState,
      expiryDate: formState.expiryDate
        ? moment(formState.expiryDate).utc().startOf('date').toISOString()
        : null,
      durationInMonths: formState.durationInMonths
        ? moment.duration(formState.durationInMonths).asMonths()
        : undefined,
    };

    setIsCreatingDiscountCode(true);

    dispatch(
      servicePlansActions.createDiscountCode.request({
        createDiscountCodeRequestData: requestPayload,
        callback: () => {
          onCreateSuccess?.();
          setIsCreatingDiscountCode(false);
        },
        onErrorCallback: () => {
          setIsCreatingDiscountCode(false);
          setShowError(
            'Sorry, there was an error creating your Service Plan Discount Code. Please try again.'
          );
        },
      })
    );
  };

  const renderFormContents: TRenderFormContents<TFormState> = (
    isValid,
    formState,
    validationState,
    touchedState,
    onFieldChange,
    onFieldTouch
  ) => {
    return (
      <>
        <BasicField
          label="Name"
          inputWidth={InputWidth.MD}
          name="name"
          isRequired
          value={formState.name}
          onChange={onFieldChange}
        />

        <p>This will appear on customers&apos; receipts and invoices.</p>

        <ValidatedFieldWrapper
          validationResult={
            touchedState.percentOff ? validationState.percentOff : undefined
          }
        >
          <BasicField
            type="number"
            inputWidth={InputWidth.XSM}
            label="Percentage Off"
            placeholder="Percentage Off"
            name="percentOff"
            iconAfter={faPercent}
            isRequired
            value={formState.percentOff}
            onChange={onFieldChange}
            onTouch={onFieldTouch}
          />
        </ValidatedFieldWrapper>

        <DropdownField
          inputWidth={InputWidth.SM}
          label="Duration"
          name="duration"
          options={[
            { value: 'forever', label: 'Forever' },
            { value: 'once', label: 'Once' },
            { value: 'repeating', label: 'Multiple months' },
          ]}
          isRequired
          value={formState.duration}
          onChange={(newFormState: Partial<TFormState>) => {
            const mutatedFormState = { ...newFormState };

            if (mutatedFormState.duration === 'repeating') {
              mutatedFormState.durationInMonths = moment
                .duration(3, 'months')
                .toISOString();
            } else {
              mutatedFormState.durationInMonths = undefined;
            }

            onFieldChange(mutatedFormState);
          }}
        />

        <p>
          This determines how long this discount code will apply once redeemed.
        </p>

        {formState.duration === 'repeating' && (
          <ValidatedFieldWrapper
            validationResult={
              touchedState.durationInMonths
                ? validationState.durationInMonths
                : undefined
            }
          >
            <DurationInputControl
              inputWidth={InputWidth.SM}
              label="Duration in months"
              name="durationInMonths"
              value={formState.durationInMonths}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              units={[DurationUnit.MONTHS]}
              isRequired
            />
          </ValidatedFieldWrapper>
        )}

        {/* Create Toggle to display this field or not */}
        <CheckboxField
          label="Limit the date range when customers can redeem this discount code"
          name="displayExpiryDate"
          value={formState.displayExpiryDate}
          onChange={(newFormState: Partial<TFormState>) => {
            const mutatedFormState = { ...newFormState };

            if (mutatedFormState.displayExpiryDate === false) {
              mutatedFormState.expiryDate = null;
            }

            onFieldChange(mutatedFormState);
          }}
        />
        {formState.displayExpiryDate && (
          <ValidatedFieldWrapper
            validationResult={
              touchedState.expiryDate ? validationState.expiryDate : undefined
            }
          >
            <DateField
              inputWidth={InputWidth.MD}
              label="Expiries on"
              name="expiryDate"
              value={formState.expiryDate ?? undefined}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              isRequired
            />
          </ValidatedFieldWrapper>
        )}

        <DropdownField
          inputWidth={InputWidth.MD}
          label="Apply to specific service plans"
          name="appliesToServicePlanPublicIds"
          options={servicePlanOptions}
          multiple
          displayCheckboxes
          value={formState.appliesToServicePlanPublicIds}
          onChange={onFieldChange}
          emptyText="All"
        />

        <ValidatedFieldWrapper
          validationResult={
            touchedState.clientFacingCode
              ? validationState.clientFacingCode
              : undefined
          }
        >
          <BasicField
            inputWidth={InputWidth.MD}
            label="Customer facing code"
            name="clientFacingCode"
            isRequired
            value={formState.clientFacingCode}
            onChange={onFieldChange}
            onTouch={onFieldTouch}
          />
        </ValidatedFieldWrapper>

        <p>
          Customers will see this code if applied or be able to type it in to
          apply it.
        </p>

        {showError && <ErrorMessage message={showError} />}

        <span>
          <Button
            isDisabled={!isValid || isCreatingDiscountCode}
            isProcessing={isCreatingDiscountCode}
            type="submit"
            styleVariant={ButtonStyleVariant.OUTSIZE}
          >
            Create Discount Code
          </Button>
        </span>
      </>
    );
  };

  const fieldValidators = useMemo<TFieldValidators<TFormState>>(
    () => ({
      name: [getIsRequiredFieldValidator()],
      percentOff: [
        getIsRequiredFieldValidator(),
        (fieldName, formState: TFormState) => {
          const value = formState[fieldName as 'percentOff'];

          const isValid = value > 0 && value <= 100;

          return {
            isValid,
            errors: isValid ? [] : ['This field must be between 0 and 100'],
          };
        },
      ],
      duration: [getIsRequiredFieldValidator()],
      durationInMonths: [
        getIsRequiredIfTrueConditionValidator(
          (formState) => (formState as TFormState).duration === 'repeating'
        ),
        getConditionalValidator(
          (formState) => (formState as TFormState).duration === 'repeating',
          (fieldName, formState: TFormState) => {
            const value = formState[fieldName as 'durationInMonths'];

            const isValid = !!value && moment.duration(value).asMonths() > 0;

            return {
              isValid,
              errors: isValid ? [] : ['Duration in months must be positive'],
            };
          }
        ),
      ],
      expiryDate: [
        getIsRequiredIfTrueConditionValidator(
          (formState) => (formState as TFormState).displayExpiryDate
        ),
        getConditionalValidator(
          (formState) => (formState as TFormState).displayExpiryDate,
          getDateRangeFieldValidator(new Date())
        ),
      ],
      clientFacingCode: [
        getIsRequiredFieldValidator(),
        getRegexMatchFieldValidator(VALID_CLIENT_FACING_DISCOUNT_CODE, {
          customErrorMessage: 'Must contain no spaces or special characters',
        }),
        getLengthFieldValidator({ min: 4, max: 255 }),
      ],
      appliesToServicePlanPublicIds: [],
    }),
    []
  );

  return (
    <>
      <ValidatedForm<TFormState>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
        onFormSubmit={onFormSubmit}
      />
    </>
  );
};

export default CreateServicePlanDiscountCodeControl;
