import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { LogoutMutation as _LogoutMutation } from '@/gql/graphql';

const LogoutMutation = graphql(`
  mutation Logout {
    logout
  }
`);

const useLogout = (options?: UseMutationOptions<_LogoutMutation>) => {
  return useMutation({
    mutationFn: () => {
      return gqlClient.request(LogoutMutation);
    },
    ...options,
  });
};

export default useLogout;
