import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UpdateProjectNoteInput,
  UpdateProjectNoteMutation as _UpdateProjectNoteMutation,
} from '@/gql/graphql';

const UpdateProjectNoteMutation = graphql(`
  mutation UpdateProjectNote($input: UpdateProjectNoteInput!) {
    updateProjectNote(input: $input) {
      id
    }
  }
`);

const useUpdateProjectNote = (
  options?: UseMutationOptions<
    _UpdateProjectNoteMutation,
    unknown,
    UpdateProjectNoteInput
  >
) => {
  return useMutation({
    mutationFn: (input: UpdateProjectNoteInput) => {
      return gqlClient.request(UpdateProjectNoteMutation, { input });
    },
    ...options,
  });
};

export default useUpdateProjectNote;
