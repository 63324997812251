import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import { SearchDevice } from '@payaca/types/deviceTypes';
import { FC } from 'react';

export interface IProps {
  selectedDevice: SearchDevice;
}

const SearchedDeviceDetailsCard: FC<IProps> = (props) => {
  const { selectedDevice } = props;

  const labels = [
    {
      label: 'Device reference',
      value: selectedDevice.reference,
    },
    {
      label: 'Device model number',
      value: selectedDevice.model,
    },
    {
      label: 'Number of phases',
      value: selectedDevice.phases,
    },
    {
      label: 'Manufacturer',
      value: selectedDevice.manufacturer,
    },
  ];
  if (selectedDevice.deviceSize) {
    labels.push({
      label: selectedDevice.deviceSize.name,
      value: `${selectedDevice.deviceSize.size} ${selectedDevice.deviceSize.unit}`,
    });
  }

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <h4>Selected device details</h4>

        {labels.map(({ label, value }) => (
          <LabelValuePair key={value} label={label} value={value} />
        ))}
      </Card.Body>
    </Card>
  );
};

export default SearchedDeviceDetailsCard;
