import { format, isPast } from 'date-fns';

type Props = {
  date: Date;
  includeYear?: boolean;
};
const DateTab = ({ date, includeYear = false }: Props) => {
  return (
    <div
      className={
        'flex flex-shrink-0 flex-col text-center' +
        (includeYear ? ' w-20' : ' w-12')
      }
    >
      <span
        className={
          'supporting-body py-1 font-semibold text-white' +
          (isPast(date) ? ' bg-gray-500' : ' bg-red-600')
        }
      >
        {format(date, includeYear ? 'MMM yyyy' : 'MMM').toUpperCase()}
      </span>
      <span className="bg-gray-100 font-semibold">{format(date, 'dd')}</span>
    </div>
  );
};

export default DateTab;
