import { FC } from 'react';

import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';

type Props = {
  className?: string;
  title?: string;
  value?: any;
  valuePrefix?: string;
};
const TotalsFixedValue: FC<Props> = ({
  className,
  title,
  value,
  valuePrefix,
}: Props): JSX.Element => {
  return (
    <LabelValuePair
      label={title || ''}
      value={value ? `${valuePrefix || ''}${value}` : null}
      suffixLabelWith=""
      className={className}
    />
  );
};
export default TotalsFixedValue;
