import {
  Dialog,
  DialogPanelProps,
  Transition,
  TransitionEvents,
} from '@headlessui/react';
import { forwardRef, Fragment, PropsWithChildren } from 'react';
import { clstx } from '../utils';

export interface ISidebarCoreProps extends TransitionEvents {
  isOpen: boolean;
  onClose?: () => void;
  className?: string;
  disableBackdropClick?: boolean;
  panelProps?: DialogPanelProps<'div'>;
  size?: 'standard' | 'lg';
  behind?: boolean;
  zIndexLevel?: 1 | 2;
}

const SidebarCore = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ISidebarCoreProps>
>((props, ref) => {
  const {
    className,
    children,
    isOpen,
    onClose,
    disableBackdropClick,
    panelProps,
    size = 'standard',
    behind = false,
    zIndexLevel = 1,
    ...rest
  } = props;

  return (
    <Transition show={isOpen} appear as={Fragment} {...rest}>
      <Dialog onClose={disableBackdropClick ? () => null : () => onClose?.()}>
        {/*Backdrop*/}
        <Transition.Child
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300 delay-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as={Fragment}
        >
          <div
            className={clstx(
              'del fixed inset-0 bg-gray-900 bg-opacity-50 dark:bg-opacity-80',
              zIndexLevel === 1 && 'z-sidebarBackdrop1',
              zIndexLevel === 2 && 'z-sidebarBackdrop2'
            )}
            aria-hidden="true"
          />
        </Transition.Child>

        <Transition.Child
          enter="transition duration-300"
          enterFrom="transform translate-x-full opacity-0"
          enterTo="transform translate-100 opacity-100"
          leave="transition duration-300"
          leaveFrom="transform translate-100 opacity-100"
          leaveTo="transform translate-x-full opacity-0"
          as={Fragment}
        >
          <div
            className={clstx(
              'fixed inset-y-0 end-0 flex size-full transform flex-col border-s bg-white transition-all duration-300',
              size === 'standard' && 'sm:w-[500px]',
              size === 'lg' && 'lg:w-[1000px]',
              zIndexLevel === 1 && 'z-sidebar1',
              zIndexLevel === 2 && 'z-sidebar2'
            )}
          >
            <Dialog.Panel
              ref={ref}
              className={clstx(
                className,
                'transition-transform duration-300',
                behind && size === 'standard' && '-translate-x-36',
                behind && size === 'lg' && '-translate-x-52'
              )}
              {...panelProps}
            >
              {children}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
});

export default SidebarCore;
