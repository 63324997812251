import { FC, useCallback } from 'react';

import HeaderInformationResourcesNavigation from '../headerInformationResourcesNavigation/HeaderInformationResourcesNavigation';
import ResponsivePopperAndTrigger from '../responsivePopperAndTrigger/ResponsivePopperAndTrigger';
import './HeaderInformationResourcesControl.sass';

import UntitledIcon from '@payaca/untitled-icons';

type Props = {
  isSmallView?: boolean;
};
const HeaderInformationResourcesControl: FC<Props> = ({
  isSmallView = false,
}: Props): JSX.Element => {
  const renderTriggerContent = useCallback(() => {
    return (
      <UntitledIcon name="help-circle.3" className="text-blue-900 h-6 w-6" />
    );
  }, []);

  const renderPopperContent = useCallback((isSmallView?: boolean) => {
    return <HeaderInformationResourcesNavigation />;
  }, []);

  return (
    <div className="header-information-resources-control">
      <ResponsivePopperAndTrigger
        isSmallView={isSmallView}
        useDrawerWhenInSmallView={false}
        popperPlacement={'bottom'}
        renderTriggerContent={renderTriggerContent}
        renderPopperContent={renderPopperContent}
        popperClassName="header-information-resources-control-popper"
        drawerClassName="header-information-resources-control-drawer"
      />
    </div>
  );
};

export default HeaderInformationResourcesControl;
