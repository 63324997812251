import { nanoid } from 'nanoid';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useContext,
  useRef,
} from 'react';
import { FieldContext } from '../plField/Field';
import UntitledLoading from '../plUntitledLoading/UntitledLoading';

export interface ICheckboxProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'type' | 'id' | 'name'> {
  hiddenLabel?: boolean;
  label?: string;
  isProcessing?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>((props, ref) => {
  const {
    className,
    hiddenLabel = false,
    isProcessing = false,
    disabled,
    label,
    ...rest
  } = props;
  const { id, name, validationState } = useContext(FieldContext);
  const { current: uId } = useRef(id || nanoid());

  return (
    <div className="prose flex">
      {isProcessing && <UntitledLoading className="mt-[.44rem] h-4 w-4" />}
      <input
        ref={ref}
        id={uId}
        name={name}
        className={
          'pl-form-checkbox mt-[.44rem]' +
          (className ? ' ' + className : '') +
          (isProcessing ? ' hidden' : '') +
          (disabled ? ' cursor-not-allowed' : ' cursor-pointer')
        }
        type="checkbox"
        disabled={disabled}
        {...rest}
      />
      {label && (
        <label
          htmlFor={uId}
          className={
            'ms-3' +
            (hiddenLabel ? ' sr-only' : '') +
            (disabled ? ' cursor-not-allowed' : ' cursor-pointer')
          }
        >
          {label}
        </label>
      )}
    </div>
  );
});

export default Checkbox;
