import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipUI from '@material-ui/core/Tooltip';
import React, { FC } from 'react';

import './DashboardActionRequiredItem.sass';

interface Props {
  isFlagged?: boolean;
  tooltipContent?: string;
  content?: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

const DashboardActionRequiredItem: FC<Props> = ({
  isFlagged,
  tooltipContent,
  content,
  onClick,
  className,
}: Props) => {
  return (
    <div
      className={`dashboard-action-required-item ${
        className ? className : ''
      } ${isFlagged ? 'flagged' : ''} ${onClick ? 'clickable' : ''}`}
      onClick={onClick}
    >
      <TooltipUI
        title={tooltipContent || ''}
        arrow
        placement="top"
        PopperProps={{
          className: `dashboard-action-required-item-tooltip MuiTooltip-popper MuiTooltip-popperArrow ${
            isFlagged ? 'flagged' : ''
          } ${onClick ? 'clickable' : ''}`,
        }}
      >
        <div>
          {isFlagged && (
            <FontAwesomeIcon
              className="alert-icon"
              icon={faExclamationTriangle}
            />
          )}

          <div className="content-wrapper">{content}</div>
        </div>
      </TooltipUI>
    </div>
  );
};

export default DashboardActionRequiredItem;
