import { FunctionComponent, useMemo } from 'react';

import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
  getRegionalFormattedValue,
  getRegionalTextString,
  RegionalFormattingTypes,
} from '@payaca/helpers/internationalHelper';
import {
  getJobType,
  isDepositPaymentRequired,
  isInvoicePaymentRequired,
} from '@payaca/helpers/jobHelperV2';
import { DocumentSenderContext } from '@payaca/types/accountBrandTypes';
import {
  Customer,
  CustomerAddress,
  CustomerContact,
} from '@payaca/types/customerTypes';
import { DealAddress } from '@payaca/types/dealTypes';
import { Job } from '@payaca/types/jobTypesV2';
import moment from 'moment-timezone';

import { getBrandingColourContext } from '@payaca/helpers/brandingColourContextHelper';
import { isQuoteOrEstimate } from '@payaca/helpers/jobStatusHelper';
import { PaymentMethodConfig } from '@payaca/types/clientTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import {
  JobContent,
  JobLineItem,
  JobLineItemGroup,
} from '@payaca/types/jobContentTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import { JobLineItemAttachment } from '@payaca/types/jobTypes';
import { PaymentSchedule } from '@payaca/types/payment-schedule';
import { Nullish } from '@payaca/utilities/types';
import ClientDocument from '../clientDocument/ClientDocument';
import JobDocumentAcceptedSignature from '../clientDocument/DocumentAcceptedSignature';
import DocumentCard from '../clientDocument/DocumentCard';
import Alert, { EAlertColour } from '../plAlert/Alert';
import { TBreadcrumbItem } from '../plBreadcrumb/Breadcrumb';
import './JobDocument.sass';
import JobDocumentItemGroups from './JobDocumentItemGroups';
import JobDocumentTotals from './JobDocumentTotals';

type Props = {
  documentSenderContext: DocumentSenderContext;
  paymentMethodConfig: PaymentMethodConfig;
  job: Job;
  dealReference?: string;
  clientV2ProjectsURL?: Nullish<string>;
  clientV2ProjectURL?: Nullish<string>;
  customer: Pick<Customer, 'name'> & {
    addresses: Pick<CustomerAddress, 'address' | 'isBillingAddress'>[];
    contacts: Pick<
      CustomerContact,
      'name' | 'emailAddress' | 'isPrimaryContact' | 'telephoneNumber' | 'id'
    >[];
  };
  jobContent: JobContent;
  jobPayments: JobPayment[];
  siteAddresses: DealAddress[];
  jobLineItemAttachments: JobLineItemAttachment[];
  jobLineItems: JobLineItem[];
  jobLineItemGroups: JobLineItemGroup[];
  jobAttachments?: any[];
  hideItemPrices?: boolean;
  hideTaxBreakdown?: boolean;
  forceDisplayAsProposition?: boolean;
  acceptedSignatureImageData?: string;
  isEditable?: boolean;
  onUpdate?: (payload: {
    jobLineItems: { jobLineItemId: number; isSelected: boolean }[];
  }) => void;
  callToAction?: JSX.Element;
  dealVersion?: number;
  pdfFileName?: string;
  pdfData?: string;
  terms?: any[];
  paymentSchedule?: PaymentSchedule;
  hasMaxWidth?: boolean;
  stickyTopClassName?: string;
};

const JobDocument: FunctionComponent<Props> = ({
  documentSenderContext,
  paymentMethodConfig,
  job,
  dealReference,
  clientV2ProjectsURL,
  clientV2ProjectURL,
  customer,
  siteAddresses,
  jobContent,
  jobPayments,
  jobLineItemAttachments,
  jobLineItems,
  jobLineItemGroups,
  jobAttachments = [],
  acceptedSignatureImageData,
  hideItemPrices = false,
  hideTaxBreakdown = false,
  forceDisplayAsProposition = false,
  isEditable = false,
  onUpdate,
  callToAction,
  dealVersion = 1,
  pdfFileName,
  pdfData,
  terms,
  paymentSchedule,
  hasMaxWidth,
  stickyTopClassName,
}: Props): JSX.Element | null => {
  const jobType = useMemo(() => getJobType(job), [job]);

  const brandingColourContext = useMemo(() => {
    return getBrandingColourContext(documentSenderContext.brandColour);
  }, [documentSenderContext.brandColour]);

  const contact = useMemo(() => {
    if (!job || !customer) return;
    let c = customer.contacts.find((x) => x.id === job.contactId);
    if (c) return c;
    c = customer.contacts.find((x) => x.isPrimaryContact);
    if (c) return c;
    return customer.contacts[0];
  }, [customer, job]);

  const { documentOriginAddressPart1, documentOriginAddressPart2 } =
    useMemo(() => {
      const part1 = [
        documentSenderContext.address?.line1,
        documentSenderContext.address?.line2,
      ].filter((x) => !!x?.length);
      const part2 = [
        documentSenderContext.address?.city,
        documentSenderContext.address?.postcode,
      ].filter((x) => !!x?.length);

      return {
        documentOriginAddressPart1: part1.length ? part1.join(', ') : undefined,
        documentOriginAddressPart2: part2.length ? part2.join(', ') : undefined,
      };
    }, [documentSenderContext]);

  const shortDateRegionalFormat = useMemo(
    () =>
      getInternationalMomentDateFormatByRegion(
        DateFormats.SHORT,
        documentSenderContext.region
      ),
    [documentSenderContext]
  );

  const isJobQuoteOrEstimate: boolean = useMemo(() => {
    return isQuoteOrEstimate(job.status);
  }, [job.status]);

  const displayAsProposition: boolean = useMemo(() => {
    return isJobQuoteOrEstimate || forceDisplayAsProposition;
  }, [forceDisplayAsProposition, isJobQuoteOrEstimate]);

  const hasAnyReverseChargeVat = useMemo(() => {
    // hide vat if no items have any vat included
    return jobLineItems.some((jli: JobLineItem) => !!jli.isReverseChargeVat);
  }, [jobLineItems]);

  const vatRegNoRegionalLabel = useMemo(
    () =>
      getRegionalTextString(
        documentSenderContext.region,
        RegionalStrings.VALUE_ADDED_TAX_NUMBER
      ),
    [documentSenderContext]
  );

  const hideTax = hideTaxBreakdown;

  const isPaymentRequired = useMemo(() => {
    if (!job) {
      return false;
    }
    return displayAsProposition
      ? isDepositPaymentRequired(job, jobContent)
      : isInvoicePaymentRequired(job, jobContent);
  }, [job, displayAsProposition, jobContent]);

  const keyInformation = useMemo(() => {
    if (!job) return [];
    const ki = [
      {
        label: `Reference`,
        value: `#${job.customReference || job.reference.toString()}`,
      },
    ];

    if (job.sentAt) {
      ki.push({
        label: 'Issued',
        value: moment(job.sentAt).format(shortDateRegionalFormat),
      });
    }

    if (job.validUntil) {
      ki.push({
        label: 'Valid until',
        value: moment(job.validUntil).format(shortDateRegionalFormat),
      });
    }

    if (
      job.dueAt &&
      dealVersion < 2 &&
      isPaymentRequired &&
      (displayAsProposition ? job.acceptedAt : true)
    ) {
      ki.push({
        label: 'Due date',
        value: moment(job.dueAt).format(shortDateRegionalFormat),
      });
    }

    return ki;
  }, [
    dealVersion,
    displayAsProposition,
    isPaymentRequired,
    job,
    shortDateRegionalFormat,
  ]);

  const breadcrumbItems = useMemo(() => {
    if (clientV2ProjectsURL === undefined && clientV2ProjectURL === undefined) {
      return undefined;
    }

    const _breadcrumbItems: TBreadcrumbItem[] = [];

    if (clientV2ProjectsURL) {
      _breadcrumbItems.push({
        label: 'All Projects',
        href: clientV2ProjectsURL,
      });
    }

    if (clientV2ProjectURL) {
      _breadcrumbItems.push({
        label: `Project #${dealReference} overview`,
        href: clientV2ProjectURL,
      });
    }

    _breadcrumbItems.push({
      label: `${jobType} #${job.customReference || job.reference.toString()}`,
    });

    return _breadcrumbItems;
  }, [clientV2ProjectsURL, clientV2ProjectURL, dealReference, job, jobType]);

  return (
    <ClientDocument
      breadcrumbItems={breadcrumbItems}
      jobType={jobType}
      pdfData={pdfData}
      pdfFileName={pdfFileName}
      brandingColourContext={brandingColourContext}
      jobDescription={job.jobDescription}
      siteAddresses={siteAddresses}
      companyName={documentSenderContext.companyName}
      customer={customer as Customer}
      contact={contact}
      keyInformation={keyInformation}
      logoUrl={documentSenderContext.logoUrl}
      notes={job.jobNotes}
      paymentTerms={documentSenderContext.proposalPaymentTerms}
      region={documentSenderContext.region}
      jobContentTotal={jobContent.total}
      paymentSchedule={paymentSchedule}
      businessTerms={documentSenderContext.businessTerms}
      jobAttachments={jobAttachments}
      documentOriginAddressPart1={documentOriginAddressPart1}
      documentOriginAddressPart2={documentOriginAddressPart2}
      contactSection={{
        email: documentSenderContext.emailAddress,
        phoneNumber: documentSenderContext.phoneNumber,
        registeredCountryName: documentSenderContext.registeredCountryName,
        companyRegistrationNumber:
          documentSenderContext.companyRegistrationNumber,
        vatNumber: documentSenderContext.vatNumber,
        vatRegNoRegionalLabel,
      }}
      SidebarContent={
        <DocumentCard>
          <JobDocumentTotals
            account={documentSenderContext}
            jobContent={jobContent}
            job={job}
            jobPayments={jobPayments}
            jobLineItems={jobLineItems}
            jobLineItemGroups={jobLineItemGroups}
            isQuote={displayAsProposition}
            hasAnyReverseChargeVat={hasAnyReverseChargeVat}
            hideItemPrices={hideItemPrices}
            hideVat={hideTax}
            brandingColourContext={brandingColourContext}
            paymentSchedule={paymentSchedule}
          />
          {callToAction}
          {!!terms?.length && !job.acceptedAt && !job.declinedAt && (
            <div className="mt-4 text-base">
              By signing to accept you’re agreeing to our{' '}
              {terms.length === 1 ? (
                <a
                  className="text-pc-job-brand"
                  href={terms[0].termUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Business
                </a>
              ) : (
                terms.map((term, index) => {
                  return (
                    <span key={`terms-${index}`}>
                      <a
                        className="text-pc-job-brand"
                        href={term.termUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{term.fileName}</span>
                      </a>
                      {index !== terms.length - 1 && <span>, </span>}
                    </span>
                  );
                })
              )}
            </div>
          )}
          {job.acceptedAt && contact && (
            <>
              <hr className="hr my-4" />
              <JobDocumentAcceptedSignature
                jobType={jobType}
                jobAcceptedAt={job.acceptedAt}
                jobContact={contact}
                acceptedSignatureImageData={acceptedSignatureImageData}
              />
            </>
          )}
          {job.declinedAt && !job.acceptedAt && (
            <>
              <hr className="hr my-4" />
              <Alert colour={EAlertColour.SOFT_RED}>
                Declined on{' '}
                {moment(job.declinedAt).format('Do MMMM YYYY [at] HH:mm')}
              </Alert>
            </>
          )}
          {dealVersion < 2 &&
            isPaymentRequired &&
            paymentMethodConfig.allowBankTransferPayment && (
              <div className="hidden print:table">
                <hr className="hr my-4" />
                <table className="w-full text-right text-base">
                  <tbody>
                    <tr>
                      <td className="font-semibold">Payable to:</td>
                      <td>
                        {
                          paymentMethodConfig.bankTransferPaymentSettings
                            .accountName
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold">
                        {getRegionalTextString(
                          documentSenderContext.region,
                          RegionalStrings.SORT_CODE
                        )}
                        :
                      </td>
                      <td>
                        {getRegionalFormattedValue(
                          documentSenderContext.region,
                          RegionalFormattingTypes.SORT_CODE,
                          paymentMethodConfig.bankTransferPaymentSettings
                            .sortCode || ''
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold">Account number:</td>
                      <td>
                        {getRegionalFormattedValue(
                          documentSenderContext.region,
                          RegionalFormattingTypes.ACCOUNT_NUMBER,
                          paymentMethodConfig.bankTransferPaymentSettings
                            .accountNumber || ''
                        )}
                      </td>
                    </tr>
                    {documentSenderContext.vatNumber && (
                      <tr>
                        <td className="font-semibold">
                          {getRegionalTextString(
                            documentSenderContext.region,
                            RegionalStrings.VALUE_ADDED_TAX_NUMBER
                          )}
                          :
                        </td>
                        <td>{documentSenderContext.vatNumber}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
        </DocumentCard>
      }
      hasMaxWidth={hasMaxWidth}
      stickyTopClassName={stickyTopClassName}
    >
      <JobDocumentItemGroups
        account={documentSenderContext}
        isEditable={isEditable}
        hideItemPrices={hideItemPrices}
        jobLineItemGroups={jobLineItemGroups}
        jobLineItems={jobLineItems}
        jobLineItemAttachments={jobLineItemAttachments}
        isProposition={displayAsProposition}
        onUpdate={onUpdate ? onUpdate : () => null}
        hideVat={hideTax}
        isAnyVatIncluded={jobContent.isAnyVatIncluded}
        brandingColourContext={brandingColourContext}
        dealVersion={dealVersion}
      />
    </ClientDocument>
  );
};

export default JobDocument;
