import { FunctionComponent, useMemo } from 'react';

import {
  useAcceptedJobContentForDeal,
  useChangeProposal,
  useChangeProposalsForDeal,
  useCustomerForDeal,
  useDeal,
  useJobLineItemsForDeal,
  useUploads,
} from '@payaca/store/hooks/appState';

import { useSelector } from '@/api/state';
import ChangeProposalDocumentComponent from '@payaca/components/changeProposalDocument/ChangeProposalDocument';
import ClientDocumentLoading from '@payaca/components/clientDocument/ClientDocumentLoading';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import { getDocumentSenderContextFromAccount } from '@payaca/helpers/documentSenderContextHelper';
import moment from 'moment-timezone';

type Props = {
  changeProposalId: number;
  isPreview?: boolean;
};

const ChangeProposalDocument: FunctionComponent<Props> = ({
  changeProposalId,
  isPreview = false,
}: Props): JSX.Element | null => {
  const changeProposal = useChangeProposal(changeProposalId);

  const deal = useDeal(changeProposal?.dealId);
  const customer = useCustomerForDeal(changeProposal?.dealId);
  const changeProposals = useChangeProposalsForDeal(changeProposal?.dealId);
  const jobContent = useAcceptedJobContentForDeal(changeProposal?.dealId);
  const jobLineItems = useJobLineItemsForDeal(changeProposal?.dealId);
  const uploads = useUploads(changeProposal?.attachmentUploadIds);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  // If the change proposal is not sent yet, set the sentAt and expiresAt dates assuming it would be sent today
  const cps = useMemo(() => {
    if (!changeProposal) return [];

    if (!changeProposal?.sentAt) {
      const sentAt = new Date();
      const expiresAt = moment(sentAt)
        .add(changeProposal?.expiresInDays, 'days')
        .toDate();

      return changeProposals.map((cp) => {
        if (cp.id === changeProposal.id) {
          return {
            ...cp,
            sentAt,
            expiresAt,
          };
        }

        return cp;
      });
    }

    return changeProposals;
  }, [changeProposal]);

  if (
    !changeProposal ||
    !deal ||
    !customer ||
    !changeProposals?.length ||
    !jobContent
  ) {
    return <ClientDocumentLoading />;
  }

  return (
    <ChangeProposalDocumentComponent
      isPreview={isPreview}
      attachments={uploads}
      acceptedJobContent={jobContent}
      changeProposals={cps}
      changeProposalId={changeProposalId}
      siteAddresses={deal.siteAddresses || []}
      customer={customer}
      jobLineItems={jobLineItems}
      documentSenderContext={getDocumentSenderContextFromAccount(account)}
      hideItemPrices={deal.hideItemPrices}
      hideVatBreakdown={deal.hideVatBreakdown}
      CTA={
        <div className="mt-4 empty:hidden print:hidden">
          {changeProposal &&
            !changeProposal.acceptedAt &&
            !changeProposal.declinedAt && (
              <>
                <Button
                  className="mb-2 w-full"
                  colour={EBtnColour.JobBrand}
                  disabled
                >
                  Sign to accept
                </Button>
                <Button
                  className="-mb-2 w-full"
                  variant={EBtnVariant.Link}
                  colour={EBtnColour.JobBrand}
                  disabled
                >
                  Decline
                </Button>
              </>
            )}
        </div>
      }
    />
  );
};

export default ChangeProposalDocument;
