import { JobLineItem } from '@payaca/types/jobContentTypes';
import UntitledIcon, { TIconName } from '@payaca/untitled-icons';
import { FC, PropsWithChildren, ReactNode, useCallback } from 'react';
import Card from '../plCard/Card';

export interface ISectionProps {
  title?: string;
  icon?: TIconName;
  iconColourClassName?: string;
  items: JobLineItem[];
  children: (item: JobLineItem) => ReactNode;
}

const DocumentCardSection: FC<ISectionProps> = (props) => {
  const {
    title,
    icon,
    iconColourClassName = 'text-pc-job-brand',
    items,
    children,
  } = props;

  const TitleBlock = useCallback(
    () => (
      <>
        {icon && (
          <UntitledIcon
            name={icon}
            className={
              'mt-1 h-5 w-5 shrink-0 print:mr-3 ' + iconColourClassName
            }
          />
        )}
        <div>
          {title && <h3 className="mb-0 text-lg font-bold">{title}</h3>}
        </div>
      </>
    ),
    [icon, title]
  );

  if (items.length === 0) return null;

  return (
    <div className="print:mb-6">
      <div className="mb-5 flex gap-3 print:hidden">
        <TitleBlock />
      </div>

      <div className="flex flex-col gap-y-3 print:block">
        {items.map((item, index) => (
          <div key={item.id} className="break-inside-avoid">
            {index === 0 && (
              <div className="mb-5 hidden print:flex">
                <TitleBlock />
              </div>
            )}
            {children(item)}
          </div>
        ))}
      </div>
    </div>
  );
};

export interface IProps {
  icon?: TIconName;
  title?: string;
  bandColour?: string;
  avoidPrintBreak?: boolean;
}

const DocumentCard: FC<PropsWithChildren<IProps>> = (props) => {
  const { title, icon, avoidPrintBreak = true, children } = props;

  return (
    <Card
      className={
        'border-t-pc-job-brand !rounded-[.5rem] border-t-[.5rem] print:mb-8 print:block' +
        (avoidPrintBreak ? ' break-inside-avoid' : '')
      }
    >
      <Card.Body>
        {title && (
          <>
            <Card.Title className="mb-0 flex items-center !font-bold">
              {icon && (
                <UntitledIcon
                  className="text-pc-job-brand mr-2 h-5 w-5"
                  name={icon}
                />
              )}
              {title}
            </Card.Title>
            <hr className="hr my-4" />
          </>
        )}

        {children}
      </Card.Body>
    </Card>
  );
};

export default Object.assign(DocumentCard, { Section: DocumentCardSection });
