import {
  RichTextEditorVariables,
  ServicePlanPeriodReminderTemplateVariables,
} from '@payaca/types/richTextEditorVariables';

export const messageVariables: RichTextEditorVariables<ServicePlanPeriodReminderTemplateVariables> =
  [
    {
      name: ServicePlanPeriodReminderTemplateVariables.ACCOUNT_NAME,
      displayName: 'Company name',
    },
    {
      name: ServicePlanPeriodReminderTemplateVariables.CUSTOMER_NAME,
      displayName: 'Primary contact name',
    },
    {
      name: ServicePlanPeriodReminderTemplateVariables.SERVICE_PLAN_NAME,
      displayName: 'Service plan name',
    },
    {
      name: ServicePlanPeriodReminderTemplateVariables.SERVICE_PLAN_PERIOD_START,
      displayName: 'Service period start',
    },
    {
      name: ServicePlanPeriodReminderTemplateVariables.SERVICE_PLAN_PERIOD_END,
      displayName: 'Service period end',
    },
  ];

export const channelOptions = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'SMS',
    value: 'sms',
  },
];

export const timeOffsetOptions = [
  {
    label: '30 days before renewal',
    value: 'P-30D',
  },
  {
    label: '14 days before renewal',
    value: 'P-14D',
  },
  {
    label: '7 days before renewal',
    value: 'P-7D',
  },
  {
    label: '3 days before renewal',
    value: 'P-3D',
  },
  {
    label: '2 days before renewal',
    value: 'P-2D',
  },
  {
    label: '1 day before renewal',
    value: 'P-1D',
  },
  {
    label: 'On period start day',
    value: 'P0D',
  },
];

export const defaultMessage = [
  {
    type: 'paragraph',
    children: [
      {
        text: 'Hi ',
      },
      {
        type: 'variable',
        name: 'customer.primaryContact.name',
        displayName: 'Primary contact name',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        text: ',',
      },
    ],
  },
  {
    type: 'paragraph',
    children: [
      {
        text: 'Thank you for your continued subscription to our ',
      },
      {
        type: 'variable',
        name: 'servicePlan.name',
        displayName: 'Service plan name',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        text: ' plan!',
      },
    ],
  },
  {
    type: 'paragraph',
    children: [
      {
        text: 'This is a reminder that your next service period begins on ',
      },
      {
        type: 'variable',
        name: 'servicePlanPeriod.startsAt',
        displayName: 'Service period start',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        text: '.',
      },
    ],
  },
  {
    type: 'paragraph',
    children: [
      {
        text: "We'll be in touch soon to arrange our next inspection and service!",
      },
    ],
  },
  {
    type: 'paragraph',
    children: [
      {
        text: 'Regards,',
      },
    ],
  },
  {
    type: 'paragraph',
    children: [
      {
        text: '',
      },
      {
        type: 'variable',
        name: 'account.companyName',
        displayName: 'Company name',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        text: '',
      },
    ],
  },
];
