import { actions as usersActions } from '@/api/users';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { getUrlSearchParam } from '@payaca/helpers/urlHelper';

import Modal from '@payaca/components/modal/Modal';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { CityPlumbingConnectionControl } from './cityPlumbingConnectionControl/CityPlumbingConnectionControl';
import QuickbooksConnectionControl from './quickbooksConnectionControl/QuickbooksConnectionControl';
import StripeConnectionControl from './stripeConnectionControl/StripeConnectionControl';
import XeroConnectionControl from './xeroConnectionControl/XeroConnectionControl';
import ZapierConnectionControl from './zapierConnectionControl/ZapierConnectionControl';

const ConnectionsPage: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch();

  const location = useLocation();
  const params = useParams<{ service: string }>();
  const history = useHistory();
  const [
    showStripeConnectionSuccessModal,
    setShowStripeConnectionSuccessModal,
  ] = useState(false);

  const [isCompletingXeroConnection, setIsCompletingXeroConnection] =
    useState(false);
  const [
    isCompletingQuickbooksConnection,
    setIsCompletingQuickbooksConnection,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const code = useMemo(() => {
    return getUrlSearchParam(location.search, 'code');
  }, [location]);

  const realmId = useMemo(() => {
    return getUrlSearchParam(location.search, 'realmId');
  }, [location]);

  const isStripeConfirmationSuccessRedirect = useMemo(() => {
    return (
      getUrlSearchParam(
        location.search,
        'isStripeConnectionSuccessRedirect'
      ) === 'true'
    );
  }, [location]);

  const service = useMemo(() => {
    return params.service;
  }, [params]);

  useEffect(() => {
    if (isStripeConfirmationSuccessRedirect) {
      setShowStripeConnectionSuccessModal(true);
    }
  }, [isStripeConfirmationSuccessRedirect]);

  const connectWithXero = useCallback(() => {
    setIsCompletingXeroConnection(true);
    dispatch(
      usersActions.connectWithXero(code, (error: string) => {
        if (!error) {
          dispatch(
            usersActions.getProfile(() => {
              dispatch(
                usersActions.getXeroAccounts(() => {
                  history.push('/connections');
                  setIsCompletingXeroConnection(false);
                })
              );
            })
          );
        } else {
          setErrorMessage('Unable to connect with Xero');
          setIsCompletingXeroConnection(false);
        }
      })
    );
  }, [dispatch, history, code]);

  const connectWithQuickbooks = useCallback(() => {
    setIsCompletingQuickbooksConnection(true);
    dispatch(
      usersActions.connectWithQuickbooks(
        {
          code: code,
          realmId: realmId,
        },
        (error: string) => {
          if (!error) {
            dispatch(
              usersActions.getProfile(() => {
                history.push('/connections');
                setIsCompletingQuickbooksConnection(false);
              })
            );
          } else {
            setErrorMessage('Unable to connect with Quickbooks');
            setIsCompletingQuickbooksConnection(false);
          }
        }
      )
    );
  }, [dispatch, history, code]);

  useEffect(() => {
    if (code) {
      if (service === 'xero') {
        connectWithXero();
      } else if (service === 'quickbooks') {
        connectWithQuickbooks();
      }
    }
  }, []);

  return (
    <AuthenticatedPageWrapper
      title="Connections"
      previousPageNavigationConfig={{
        route: '/personalSettings',
      }}
    >
      <div className="m-4 grid gap-4 lg:grid-cols-2">
        <CityPlumbingConnectionControl />
        <QuickbooksConnectionControl />
        <StripeConnectionControl />
        <XeroConnectionControl />
        <ZapierConnectionControl />
      </div>
      <Modal
        className="stripe-connection-success-modal"
        isOpen={showStripeConnectionSuccessModal}
        size={'xs'}
        onClose={() => setShowStripeConnectionSuccessModal(false)}
      >
        <h3>
          You‘ve successfully connected your Stripe account to Payaca and can
          now accept card payments.
        </h3>
      </Modal>
    </AuthenticatedPageWrapper>
  );
};

export default ConnectionsPage;
