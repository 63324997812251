import { action } from 'typesafe-actions';

import { JobPayment } from '@payaca/types/jobPaymentTypes';

import { createAsyncAction } from '../utils';

import {
  ActionType,
  CancelJobPaymentIntent,
  ConfirmJobPaymentRequestData,
  PayInvoices,
  PersistJobPayment,
  PersistJobPaymentIntent,
  RecordJobPaymentRequestData,
} from './jobPaymentsTypes';

export const requestRecordJobPayment = (
  recordJobPaymentRequestData: RecordJobPaymentRequestData,
  callback?: () => void
) =>
  action(ActionType.REQUEST_RECORD_JOB_PAYMENT, {
    recordJobPaymentRequestData,
    callback,
  });

export const recordJobPaymentSuccess = () =>
  action(ActionType.RECORD_JOB_PAYMENT_SUCCESS);

export const recordJobPaymentFailure = (error: Error) =>
  action(ActionType.RECORD_JOB_PAYMENT_FAILURE, null, null, error);

export const requestConfirmJobPayment = (
  confirmJobPaymentRequestData: ConfirmJobPaymentRequestData,
  callback?: () => void
) =>
  action(ActionType.REQUEST_CONFIRM_JOB_PAYMENT, {
    confirmJobPaymentRequestData,
    callback,
  });

export const confirmJobPaymentSuccess = () =>
  action(ActionType.CONFIRM_JOB_PAYMENT_SUCCESS);

export const confirmJobPaymentFailure = (error: Error) =>
  action(ActionType.CONFIRM_JOB_PAYMENT_FAILURE, null, null, error);

export const requestGetJobPaymentsForDeal = (dealId: number) =>
  action(ActionType.REQUEST_GET_JOB_PAYMENTS_FOR_DEAL, {
    dealId,
  });

export const getJobPaymentsForDealSuccess = (jobPayments: Array<JobPayment>) =>
  action(ActionType.GET_JOB_PAYMENTS_FOR_DEAL_SUCCESS, jobPayments);

export const requestGetJobPayment = (jobPaymentId: number) =>
  action(ActionType.REQUEST_GET_JOB_PAYMENT, {
    jobPaymentId,
  });

export const getJobPaymentSuccess = (
  jobPaymentId: number,
  jobPayment: JobPayment
) =>
  action(ActionType.GET_JOB_PAYMENT_SUCCESS, {
    jobPaymentId,
    jobPayment,
  });

export const getJobPaymentFailure = (jobPaymentId: number, error: Error) =>
  action(
    ActionType.GET_JOB_PAYMENT_FAILURE,
    {
      jobPaymentId,
    },
    null,
    error
  );

export const clearJobPayment = (jobPaymentId: number) =>
  action(ActionType.CLEAR_JOB_PAYMENT, {
    jobPaymentId,
  });

export const clearJobPayments = () => action(ActionType.CLEAR_JOB_PAYMENTS);

export const persistJobPaymentIntent =
  createAsyncAction<PersistJobPaymentIntent>({
    request: ActionType.PERSIST_JOB_PAYMENT_INTENT_REQUEST,
    success: ActionType.PERSIST_JOB_PAYMENT_INTENT_SUCCESS,
    failure: ActionType.PERSIST_JOB_PAYMENT_INTENT_FAILURE,
  })((payload: PersistJobPaymentIntent['request']['payload']) => ({ payload }));

export const cancelJobPaymentIntent = createAsyncAction<CancelJobPaymentIntent>(
  {
    request: ActionType.CANCEL_JOB_PAYMENT_INTENT_REQUEST,
    success: ActionType.CANCEL_JOB_PAYMENT_INTENT_SUCCESS,
    failure: ActionType.CANCEL_JOB_PAYMENT_INTENT_FAILURE,
  }
)((payload: CancelJobPaymentIntent['request']['payload']) => ({ payload }));

export const persistJobPayment = createAsyncAction<PersistJobPayment>({
  request: ActionType.PERSIST_JOB_PAYMENT_REQUEST,
  success: ActionType.PERSIST_JOB_PAYMENT_SUCCESS,
  failure: ActionType.PERSIST_JOB_PAYMENT_FAILURE,
})((payload: PersistJobPayment['request']['payload']) => ({ payload }));

export const payInvoices = createAsyncAction<PayInvoices>({
  request: ActionType.PAY_INVOICES_REQUEST,
})((payload: PayInvoices['request']['payload']) => ({ payload }));
