import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import DropdownField from '@payaca/components/dropdownField/DropdownField';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import Button from '@payaca/components/plButton/Button';
import Modal from '@payaca/components/plModal/Modal';
import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';
import AddCustomerControl from '../addCustomerControl/AddCustomerControl';
import { PipelineBadge } from '../pipelineBadge/PipelineBadge';

import { useSelector } from '../../../api/state';

import * as customerActions from '@payaca/store/customer/customerActions';
import * as dealTemplateActions from '@payaca/store/dealTemplates/dealTemplateActions';
import * as dealActions from '@payaca/store/deals/dealsActions';

import { DealTemplate } from '@payaca/types/dealTemplateTypes';
import { Pipeline } from '@payaca/types/pipelineTypes';

import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { requestGetAccountPipelines } from '@payaca/store/pipelines/pipelineActions';
import { isNullish } from '@payaca/utilities/guards';
import AssignmentDropdown from '../assignmentDropdown/AssignmentDropdown';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  initialPipelineId: number | null; // pipeline the modal was open on
  initialCustomerId?: number | null; // customer modal was open on
};
const CreateDealModal = ({
  isOpen,
  onClose,
  initialCustomerId,
  initialPipelineId,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const myProfile = useSelector((state: any) => state.users.myProfile);

  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    null
  );
  const [templateTitle, setTemplateTitle] = useState('');
  const [isAddingNewDeal, setIsAddingNewDeal] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(
    null
  );
  const [selectedPipelineId, setSelectedPipelineId] = useState<number | null>(
    initialPipelineId
  );
  const [selectedAssignedUserId, setSelectedAssignedUserId] = useState<
    number | null
  >(myProfile.id); // default to current user

  useEffect(() => {
    if (isOpen) {
      setSelectedPipelineId(initialPipelineId);
      initialCustomerId && setSelectedCustomerId(initialCustomerId);
      dispatch(requestGetAccountPipelines());
    }
  }, [isOpen]);

  const pipelines = useSelector((state) => state.pipelines.pipelines);
  const searchResults = useSelector(
    (state: any) => state.dealTemplates.dealTemplates
  );
  const isGettingDealTemplates = useSelector(
    (state: any) => state.dealTemplates.isGettingDealTemplates
  );

  const handleSearch = useCallback((searchTerms: string) => {
    dispatch(dealTemplateActions.requestGetDealTemplates(searchTerms));
  }, []);

  const createDeal = useCallback(() => {
    if (selectedCustomerId) {
      setIsAddingNewDeal(true);

      dispatch(
        dealActions.requestCreateDeal(
          {
            pipelineId: isNullish(selectedPipelineId)
              ? undefined
              : selectedPipelineId,
            customerId: isNullish(selectedCustomerId)
              ? undefined
              : selectedCustomerId,
            templateId: isNullish(selectedTemplateId)
              ? undefined
              : selectedTemplateId,
            assignedToUserId: isNullish(selectedAssignedUserId)
              ? undefined
              : selectedAssignedUserId,
          },
          (dealId: number) => {
            setIsAddingNewDeal(false);
            history.push(`/deals/${dealId}?new=true`);
          }
        )
      );
    }
  }, [
    history,
    selectedCustomerId,
    selectedPipelineId,
    selectedTemplateId,
    selectedAssignedUserId,
  ]);

  const onChangePipeline = useCallback(
    ({ pipelineId }: { [key: string]: any }) => {
      // we're loading an existing pipeline
      setSelectedPipelineId(pipelineId);
    },
    [selectedPipelineId]
  );

  const onCloseModal = () => {
    // reset state
    setSelectedTemplateId(null);
    setSelectedCustomerId(null);
    setSelectedPipelineId(null);
    setSelectedAssignedUserId(null);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} title="Create Project">
      <Modal.Body className="space-y-4">
        {/* choose customer */}
        <div>
          <FieldLabel label="Customer" isRequired></FieldLabel>
          <AddCustomerControl
            customerId={selectedCustomerId}
            onSelectCustomer={(customerId?: number) => {
              customerId &&
                dispatch(customerActions.requestGetCustomer(customerId));
              setSelectedCustomerId(customerId || null);
            }}
          />
        </div>

        {/* pipeline */}
        <DropdownField
          label="Pipeline"
          isRequired
          name={'pipelineId'}
          additionalSelectProps={{
            className: 'pipeline-select-dropdown',
            renderValue: () => {
              if (!selectedPipelineId) {
                return <PipelineBadge />;
              } else {
                return (
                  <div className={'pipeline-select-container'}>
                    <PipelineBadge pipelineId={selectedPipelineId} />
                  </div>
                );
              }
            },
          }}
          value={selectedPipelineId}
          onChange={onChangePipeline}
          options={pipelines.map(({ id, title }: Pipeline) => ({
            label: title,
            value: id,
          }))}
          renderOption={(option) => {
            if (option.value === 0) {
              return <PipelineBadge />;
            } else {
              return <PipelineBadge pipelineId={option.value} />;
            }
          }}
        />
        <PermissionGuard
          renderIfHasPermissions={[AccountsPermissions.GET_USERS]}
        >
          <AssignmentDropdown
            currentAssignedUser={selectedAssignedUserId}
            onAssignedUserChange={(selectedUserId) =>
              setSelectedAssignedUserId(selectedUserId ? +selectedUserId : null)
            }
            label="Owner"
            assigneeRequiredPermission={[DealsPermissions.GET_MY_DEAL]}
          />
        </PermissionGuard>

        {/* choose template */}
        <TypeToSearchField
          label="Apply template"
          isLoadingOptions={isGettingDealTemplates}
          onSelectOption={(option: any) =>
            option?.value && setSelectedTemplateId(option.value)
          }
          onSearchTermChange={(searchTerms: string) => {
            const existingDealTemplate = searchResults.find(
              (dealTemplate: DealTemplate) => dealTemplate.title === searchTerms
            );
            if (existingDealTemplate) {
              setSelectedTemplateId(existingDealTemplate.id);
            } else {
              setSelectedTemplateId(null);
            }
            // check for title in templates and set id
            setTemplateTitle(searchTerms);
            handleSearch(searchTerms);
          }}
          onOpen={() => handleSearch(templateTitle)}
          options={[
            ...(searchResults || []).map(({ id, title }: DealTemplate) => ({
              label: title,
              value: id,
            })),
          ]}
          styleVariant={InputStyleVariant.OUTSIZE}
          noOptionsText="No templates found"
        />
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            onClick={() => !isAddingNewDeal && createDeal()}
            isProcessing={isAddingNewDeal}
            disabled={!selectedCustomerId}
          >
            Create Project
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateDealModal;
