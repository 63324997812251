import { useSelector } from '@/api/state';
import { getDeal, getTask, getUserRoles } from '@/utils/stateAccessors';
import QuickActionsElement from '@payaca/components/quickActionsTableCell/QuickActionsElement';
import UserIndicator from '@payaca/components/userIndicator/UserIndicator';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';
import { requestDeleteTask } from '@payaca/store/tasks/tasksActions';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import CreateEditTaskModal from '../createEditTaskModal/CreateEditTaskModal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import './TaskDetailsAndActionsHeader.sass';

type Props = {
  taskId: number;
  hideDealLink?: boolean;
  onDeleteTaskSuccess?: () => void;
  onUpdateTaskSuccess?: () => void;
};
const TaskDetailsAndActionsHeader: FC<Props> = ({
  taskId,
  hideDealLink,
  onDeleteTaskSuccess,
  onUpdateTaskSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);

  const task = useSelector((state) => getTask(state, taskId));
  const profile = useSelector((state: any) => state.users.myProfile);

  const linkedDeal = useSelector((state) =>
    task?.dealId ? getDeal(state, task.dealId) : undefined
  );

  const accountUsers: any[] = useSelector(
    (state: any) => state.users.accountUsers
  );

  const userRoles = useSelector(getUserRoles);

  const assignedUser = useMemo(() => {
    if (!task?.assignedToUserId || !accountUsers?.length) return;
    return accountUsers.find((x) => x.id === task.assignedToUserId);
  }, [task, accountUsers]);

  const deleteTask = useCallback(() => {
    if (task) {
      dispatch(requestDeleteTask(taskId, onDeleteTaskSuccess));
    }
  }, [onDeleteTaskSuccess, dispatch, taskId]);

  const isDeletingTask = useSelector((state) => {
    return state.tasks.isDeletingTask;
  });

  const quickActions = useMemo(() => {
    const quickActions: any[] = [];

    if (task?.type === 'materials-list') {
      return quickActions;
    }

    if (
      userHasRequiredPermission(userRoles, [TasksPermissions.PERSIST_TASK]) ||
      (userHasRequiredPermission(userRoles, [
        TasksPermissions.PERSIST_SELF_CREATED_TASK,
      ]) &&
        task?.createdByUserId == profile.id)
    ) {
      quickActions.push({
        actionName: 'Edit',
        actionBehaviour: () => setShowEditTaskModal(true),
      });
    }

    if (
      userHasRequiredPermission(userRoles, [TasksPermissions.DELETE_TASK]) ||
      (userHasRequiredPermission(userRoles, [
        TasksPermissions.DELETE_SELF_CREATED_TASK,
      ]) &&
        task?.createdByUserId == profile.id)
    ) {
      quickActions.push({
        isProcessing: isDeletingTask,
        actionName: 'Delete',
        actionBehaviour: deleteTask,
      });
    }

    return quickActions;
  }, [userRoles, isDeletingTask, deleteTask, profile]);

  const dealLinkElement = useMemo(() => {
    if (!linkedDeal || hideDealLink) return;
    return (
      <PermissionGuard
        renderIfHasPermissions={[
          DealsPermissions.GET_DEAL,
          DealsPermissions.GET_MY_DEAL,
        ]}
      >
        <div className="flex-grow">
          <Link
            to={`/deals/${linkedDeal.id}`}
            className="btn--soft btn--blue rounded-md px-2 py-1 hover:no-underline"
          >
            Project {linkedDeal.customReference || linkedDeal.reference}
          </Link>
        </div>
      </PermissionGuard>
    );
  }, [linkedDeal, hideDealLink]);

  const userIndicatorElement = useMemo(() => {
    if (!assignedUser) return;
    return (
      <PermissionGuard
        renderIfHasPermissions={[
          AccountsPermissions.GET_USERS,
          TasksPermissions.GET_TASKS,
        ]}
        requireAllPermissions={true}
      >
        <div>
          <UserIndicator
            user={{
              firstName: assignedUser.firstname,
              lastName: assignedUser.lastname,
              emailAddress: assignedUser.email,
              userColour: assignedUser.userColour,
              imgSrc: assignedUser.avatarUrl,
            }}
          />
        </div>
      </PermissionGuard>
    );
  }, [assignedUser]);

  const quickActionsElement = useMemo(() => {
    return (
      <QuickActionsElement recordId={taskId} quickActions={quickActions} />
    );
  }, [taskId, quickActions]);

  if (!task) return null;

  if (!dealLinkElement && !userIndicatorElement && !quickActionsElement)
    return null;

  return (
    <div
      className={
        'task-details-and-actions-container flex-container flex-center'
      }
    >
      {dealLinkElement}
      {userIndicatorElement}
      {quickActionsElement}
      <CreateEditTaskModal
        dealId={task.dealId}
        task={task}
        isOpen={showEditTaskModal}
        onClose={() => setShowEditTaskModal(false)}
        onPersistTaskSuccess={() => {
          setShowEditTaskModal(false);
          if (onUpdateTaskSuccess) {
            onUpdateTaskSuccess();
          }
        }}
      />
    </div>
  );
};
export default TaskDetailsAndActionsHeader;
