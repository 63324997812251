import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import CustomerEmailControl from '../customerEmailControl/CustomerEmailControl';
import CustomerEmailPreview from './CustomerEmailPreview';

import { Customer } from '@payaca/types/customerTypes';
import { JobContent } from '@payaca/types/jobContentTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import { Job } from '@payaca/types/jobTypesV2';

import { getSpecificJobTypeText } from '@/helpers/jobHelper';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import {
  isEstimate,
  isInvoice,
  isQuote,
} from '@payaca/helpers/jobStatusHelper';

import { actions as jobActions } from '@/api/jobs';
import { actions as usersActions } from '@/api/users';
import * as accountActions from '@payaca/store/account/accountActions';

import { useSelector } from '@/api/state';
import { buildEmailSubjectPrefixForProjectFromProject } from '@payaca/helpers/dealHelper';
import { useDeal } from '@payaca/store/hooks/appState';
import './SendJob.sass';

type Props = {
  job: Job;
  jobContent: JobContent;
  jobPayments: JobPayment[];
  customer: Customer;
  preButtonEmailText: string;
  postButtonEmailText: string;
  sendMeACopy: boolean;
  onUpdate: (value?: { [key: string]: any }) => void;
  onSetIsSendJobDisabled: (isSendJobDisabled: boolean) => void;
};
const SendJob: FC<Props> = ({
  job,
  jobContent,
  jobPayments,
  customer,
  preButtonEmailText,
  postButtonEmailText,
  sendMeACopy,
  onUpdate,
  onSetIsSendJobDisabled,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [blockedEmails, setBlockedEmails] = useState<string[]>([]);

  const deal = useDeal(job.dealId);

  useEffect(() => {
    dispatch(
      usersActions.getBlockedEmails(
        (error: Error, _blockedEmails: string[]) => {
          setBlockedEmails(_blockedEmails);
        }
      )
    );
  }, []);

  const currentAccount = useSelector(
    (state: any) => state.users?.myProfile?.accounts[0]
  );

  const sendJobError = useSelector((state: any) => {
    return state.jobs.sendInvoiceToCustomerError;
  });

  useEffect(() => {
    dispatch(jobActions.storeSendInvoiceToCustomerError(null));
    dispatch(accountActions.requestGetEmailTemplates(currentAccount.id, true));

    return () => {
      dispatch(accountActions.clearEmailTemplates());
    };
  }, []);

  const emailTemplates = useSelector((state) => state.account.emailTemplates);

  const companyName = useSelector(
    (state: any) => state.users.myProfile.accounts[0].companyName
  );

  const emailTemplate = useMemo(() => {
    const jobStatus = job.status;
    if (emailTemplates) {
      if (isQuote(jobStatus)) {
        return emailTemplates.sendQuote;
      } else if (isEstimate(jobStatus)) {
        return emailTemplates.sendEstimate;
      } else if (isInvoice(jobStatus)) {
        return emailTemplates.sendInvoice;
      }
    }
    return '';
  }, [job, emailTemplates]);

  const customerContactEmail = useMemo(() => {
    return getJobContactFromCustomer(customer, job.contactId)?.emailAddress;
  }, [customer?.contacts]);

  const isCustomerEmailBlocked = useMemo(() => {
    return (
      !!customerContactEmail &&
      !!blockedEmails &&
      blockedEmails.includes(customerContactEmail)
    );
  }, [blockedEmails?.length, customerContactEmail]);

  useEffect(() => {
    // ensure everything has loaded before enabling the "Send" button
    if (
      !!customerContactEmail &&
      !isCustomerEmailBlocked &&
      typeof postButtonEmailText === 'string' &&
      typeof preButtonEmailText === 'string'
    ) {
      return onSetIsSendJobDisabled(false);
    }
  }, [
    isCustomerEmailBlocked,
    postButtonEmailText,
    preButtonEmailText,
    customerContactEmail,
  ]);

  return (
    <div className="send-job">
      <div className="addressee-and-subject-container">
        <div className="addressee-container">
          {customer && (
            <>
              <div>
                <strong>To:</strong>
              </div>
              {job?.bouncedAt ? (
                <CustomerEmailControl
                  blockedEmails={blockedEmails}
                  customer={customer}
                  onPersistCustomer={(customerId: number) => {
                    onSetIsSendJobDisabled(false);
                  }}
                  contactId={job.contactId}
                />
              ) : (
                <span>{customerContactEmail}</span>
              )}
            </>
          )}
        </div>
        <div className="subject-container">
          <div>
            <strong>Subject:</strong>
          </div>
          <div>
            {deal && buildEmailSubjectPrefixForProjectFromProject(deal)}
            Your {getSpecificJobTypeText(job.status)} from {companyName}
          </div>
        </div>
      </div>
      <div className="email-options-container">
        <CheckboxField
          name="sendMeACopy"
          label="Send me a copy"
          onChange={onUpdate}
          value={sendMeACopy}
        />
      </div>
      <ContentPanel className="email-content-container">
        <CustomerEmailPreview
          emailTemplate={emailTemplate}
          job={job}
          jobContent={jobContent}
          jobPayments={jobPayments}
          customer={customer}
          preButtonEmailText={preButtonEmailText}
          postButtonEmailText={postButtonEmailText}
          onChange={onUpdate}
        />
      </ContentPanel>
    </div>
  );
};

export default SendJob;
