import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UnlinkSiteAddressFromProjectMutation as _UnlinkSiteAddressFromProjectMutation,
  UnlinkSiteAddressFromProjectInput,
} from '@/gql/graphql';

const UnlinkSiteAddressFromProjectMutation = graphql(`
  mutation UnlinkSiteAddressFromProject(
    $input: UnlinkSiteAddressFromProjectInput!
  ) {
    unlinkSiteAddressFromProject(input: $input) {
      id
    }
  }
`);

const useUnlinkSiteAddressFromProject = (
  options?: UseMutationOptions<
    _UnlinkSiteAddressFromProjectMutation,
    unknown,
    UnlinkSiteAddressFromProjectInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UnlinkSiteAddressFromProjectMutation, { input });
    },
    ...options,
  });
};

export default useUnlinkSiteAddressFromProject;
