import { useSelector } from '@/api/state';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import CollapsiblePanel from '@payaca/components/collapsiblePanel/CollapsiblePanel';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Modal from '@payaca/components/modal/Modal';
import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';
import * as dealsActions from '@payaca/store/deals/dealsActions';
import * as dealTemplateActions from '@payaca/store/dealTemplates/dealTemplateActions';
import * as documentActions from '@payaca/store/documents/documentActions';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as jobPaymentActions from '@payaca/store/jobPayments/jobPaymentsActions';
import * as jobActions from '@payaca/store/jobs/jobsActions';
import * as scheduledEventActions from '@payaca/store/scheduledEvents/scheduledEventsActions';
import * as tasksActions from '@payaca/store/tasks/tasksActions';
import {
  DealTemplate,
  DealTemplateGroups,
  DealTemplateItem,
} from '@payaca/types/dealTemplateTypes';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import './DealTemplateModal.sass';

export enum DealTemplateModalViews {
  CREATE = 'Create Template',
  LOAD = 'Choose a Template',
}

type Props = {
  dealId?: number;
  view: DealTemplateModalViews;
  isOpen: boolean;
  onClose: () => void;
};
const DealTemplateModal: FC<Props> = ({
  dealId,
  view = DealTemplateModalViews.LOAD,
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [templateTitle, setTemplateTitle] = useState('');
  const [expandedGroup, setExpandedGroup] = useState(DealTemplateGroups.DEAL);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    null
  );
  const [selectedTemplateItemIds, setSelectedTemplateItemIds] = useState<
    string[]
  >([]);
  const [feedbackMessage, setFeedbackMessage] = useState<
    JSX.Element | string | null
  >(null);
  const [hasConfirmedOverwrite, setHasConfirmedOverwrite] = useState(false);
  const [showConfirmOverwrite, setShowConfirmOverwrite] = useState(false);

  const isGettingDealTemplates = useSelector(
    (state: any) => state.dealTemplates.isGettingDealTemplates
  );
  const isGettingDealTemplateItems = useSelector(
    (state: any) => state.dealTemplates.isGettingDealTemplateItems
  );
  const isPersistingDealTemplate = useSelector(
    (state: any) => state.dealTemplates.isPersistingDealTemplate
  );
  const isApplyingDealTemplate = useSelector(
    (state: any) => state.dealTemplates.isApplyingDealTemplate
  );
  const searchResults = useSelector(
    (state: any) => state.dealTemplates.dealTemplates
  );
  const dealTemplateItems = useSelector((state: any) => {
    return state.dealTemplates.dealTemplateItems;
  });
  const handleSearch = useCallback(
    (searchTerms: string) => {
      dispatch(dealTemplateActions.requestGetDealTemplates(searchTerms));
    },
    [dispatch]
  );

  const applyDealTemplate = useCallback(() => {
    if (!dealId) return;
    dispatch(dealsActions.requestGetDeal(+dealId));
    dispatch(jobActions.requestGetJobsForDeal(+dealId));

    dispatch(jobContentActions.requestGetJobContentsForDeal(+dealId));

    dispatch(jobPaymentActions.requestGetJobPaymentsForDeal(+dealId));
    dispatch(scheduledEventActions.requestGetScheduledEventsForDeal(+dealId));
    dispatch(documentActions.requestGetDocumentsForDeal(+dealId));
    dispatch(tasksActions.requestGetTasksForDeal(+dealId));

    onClose();
  }, [dealId]);

  const getTemplateItemUid = useCallback((group: string, value: any) => {
    if (Object.keys(value)?.length) {
      // stringify object i.e. task checklist items
      return `${group}-${JSON.stringify(value)}`;
    }
    return `${group}-${value}`;
  }, []);

  const selectedTemplateItems = useMemo(
    () =>
      dealTemplateItems.reduce(
        (acc: DealTemplateItem[], templateItem: DealTemplateItem) => {
          if (
            selectedTemplateItemIds.includes(
              getTemplateItemUid(templateItem.group, templateItem.value)
            )
          ) {
            return [...acc, templateItem];
          }
          return acc;
        },
        []
      ),
    [getTemplateItemUid, selectedTemplateItemIds, dealTemplateItems]
  );

  // when we open our modal in "Create" view, we'll fetch the latest possible template items from our deal
  useEffect(() => {
    if (dealId && view === DealTemplateModalViews.CREATE) {
      dispatch(dealTemplateActions.requestGetDealTemplateItems(dealId));
    }
  }, [dispatch, dealId, view]);

  const isLoadView = useMemo(
    () => view === DealTemplateModalViews.LOAD,
    [view]
  );

  const dealTemplateGroups = useMemo(
    () =>
      dealTemplateItems.reduce(
        (acc: any, { group, label, value }: DealTemplateItem) => {
          acc[group]
            ? acc[group].options.push({ group, label, value })
            : (acc[group] = {
                selected: 0,
                options: [{ group, label, value }],
              });
          if (
            selectedTemplateItemIds.includes(getTemplateItemUid(group, value))
          ) {
            acc[group].selected++;
          }
          return acc;
        },
        {}
      ),
    [dealTemplateItems, getTemplateItemUid, selectedTemplateItemIds]
  );

  const isEmptyDeal = useMemo(() => {
    return !Object.keys(dealTemplateGroups).length;
  }, [dealTemplateGroups]);

  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);

  return (
    <Modal
      className="deal-template-modal"
      isOpen={isOpen}
      onClose={onClose}
      title={view}
      actions={
        <>
          {!!feedbackMessage && (
            <FeedbackBlock
              feedbackLevel={FeedbackLevel.ALERT}
              isDismissable={true}
              onDismissed={() => setFeedbackMessage(null)}
            >
              {feedbackMessage}
            </FeedbackBlock>
          )}
          {!isLoadView ? (
            !isEmptyDeal ? (
              <Button
                styleVariant={ButtonStyleVariant.OUTSIZE}
                isProcessing={isPersistingDealTemplate}
                onClick={() => {
                  if (!selectedTemplateItemIds?.length) {
                    setFeedbackMessage(
                      'There is nothing selected to create a template from. Please select some items to template.'
                    );
                  } else if (!templateTitle) {
                    setFeedbackMessage(
                      'There is no template title set. Please set a title to create a new template or overwrite an existing template.'
                    );
                  } else if (selectedTemplateId && !hasConfirmedOverwrite) {
                    setShowConfirmOverwrite(true);
                  } else {
                    dispatch(
                      dealTemplateActions.requestPersistDealTemplate(
                        templateTitle,
                        selectedTemplateItems,
                        selectedTemplateId,
                        () => {
                          showDynamicFeedbackMessage({
                            title: 'Project Template created',
                            body: 'You can now apply this Template to new Projects going forwards.',
                            isCancellable: true,
                            lifespanMs: DynamicFeedbackLifespanMs.LONG,
                            feedbackLevel: FeedbackLevel.INFORMATION,
                          });
                          onClose();
                        }
                      )
                    );
                  }
                }}
                isDisabled={isGettingDealTemplateItems}
              >
                {selectedTemplateId
                  ? `Overwrite existing Template`
                  : `Save Template`}
              </Button>
            ) : (
              <></>
            )
          ) : (
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isProcessing={isApplyingDealTemplate}
              onClick={() => {
                if (!selectedTemplateId || !dealId) return onClose();
                dispatch(
                  dealTemplateActions.requestApplyDealTemplate(
                    dealId,
                    selectedTemplateId,
                    applyDealTemplate
                  )
                );
              }}
              isDisabled={isGettingDealTemplates || !selectedTemplateId}
            >
              Apply template
            </Button>
          )}
        </>
      }
    >
      {isGettingDealTemplateItems ? (
        <div className="loader-container">
          <MiniLoader />
        </div>
      ) : (
        <>
          {isEmptyDeal && !isLoadView ? (
            <p>
              {`You haven't added anything to your Project that can be templated.
            Please add some more elements and try again.`}
            </p>
          ) : (
            <>
              <TypeToSearchField
                label={isLoadView ? `Choose a Template` : `Enter a title`}
                description={
                  !isLoadView
                    ? 'Choose an existing Template to overwrite it with updated information.'
                    : ''
                }
                isLoadingOptions={isGettingDealTemplates}
                onSelectOption={(option: any) =>
                  option?.value && setSelectedTemplateId(option.value)
                }
                onSearchTermChange={(searchTerms: string) => {
                  setFeedbackMessage(null);
                  setShowConfirmOverwrite(false);
                  const existingDealTemplate = searchResults.find(
                    (dealTemplate: DealTemplate) =>
                      dealTemplate.title === searchTerms
                  );
                  if (existingDealTemplate) {
                    setSelectedTemplateId(existingDealTemplate.id);
                  } else {
                    setSelectedTemplateId(null);
                  }
                  // check for title in templates and set id
                  setTemplateTitle(searchTerms);
                  handleSearch(searchTerms);
                }}
                onOpen={() => handleSearch(templateTitle)}
                options={[
                  ...(searchResults || []).map(
                    ({ id, title }: DealTemplate) => ({
                      label: title,
                      value: id,
                    })
                  ),
                ]}
                styleVariant={InputStyleVariant.OUTSIZE}
              />
              {!isLoadView && (
                <div>
                  {Object.values(DealTemplateGroups).map(
                    (group, i) =>
                      dealTemplateGroups[group]?.options.length && (
                        <div className={'template-element'} key={i}>
                          <CollapsiblePanel
                            key={group}
                            title={`${group} (${dealTemplateGroups[group].selected}/${dealTemplateGroups[group].options.length})`}
                            onOpen={() => setExpandedGroup(group)}
                            isOpen={expandedGroup === group}
                            watchSize={true}
                          >
                            {dealTemplateGroups[group].options.map(
                              (dealTemplateItem: DealTemplateItem) => {
                                const uid = getTemplateItemUid(
                                  group,
                                  dealTemplateItem.value
                                );
                                return (
                                  <CheckboxField
                                    key={uid}
                                    label={dealTemplateItem.label}
                                    name={uid}
                                    value={selectedTemplateItemIds.includes(
                                      uid
                                    )}
                                    onChange={() => {
                                      setFeedbackMessage(null);
                                      setShowConfirmOverwrite(false);

                                      setSelectedTemplateItemIds(
                                        selectedTemplateItemIds.includes(uid)
                                          ? [
                                              ...selectedTemplateItemIds.filter(
                                                (i: string) => i !== uid
                                              ),
                                            ]
                                          : [...selectedTemplateItemIds, uid]
                                      );
                                    }}
                                  />
                                );
                              }
                            )}
                          </CollapsiblePanel>
                        </div>
                      )
                  )}
                </div>
              )}
              {showConfirmOverwrite && (
                <CheckboxField
                  name="confirmOverwrite"
                  label={`I confirm I am overwriting existing Template "${templateTitle}"`}
                  onChange={() => {
                    setHasConfirmedOverwrite(!hasConfirmedOverwrite);
                  }}
                  value={hasConfirmedOverwrite}
                />
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default DealTemplateModal;
