import { FunctionComponent, PropsWithChildren } from 'react';

import EmptyStateImage from './empty-state.svg';

/**
 *
 * Usage:
 * <EmptyState>
 *   <EmptyState.Image/>
 *   <EmptyState.Body>
 *      <p>Body text</p>
 *   </EmptyState.Body>
 * </EmptyState>
 *
 */

const EmptyState: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return <div className="flex flex-col items-center gap-4">{children}</div>;
};

const Body: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return <div className="text-base">{children}</div>;
};

const Image: FunctionComponent = () => {
  return <img src={EmptyStateImage} className="max-w-20"></img>;
};

export default Object.assign(EmptyState, {
  Body,
  Image,
});
