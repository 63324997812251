import { Customer } from '@payaca/types/customerTypes';
import {
  GetListedCustomersRequestData,
  ListedCustomersListViewPage,
  PersistCustomerData,
} from '@payaca/types/listedCustomerTypes';
import { action } from 'typesafe-actions';

import { createAsyncAction } from '../utils';
import {
  ActionType,
  ExportCustomersData,
  GetCustomerAnalytics,
  PatchCustomer,
} from './customerTypes';

export const requestPersistCustomer = (
  persistCustomerData: PersistCustomerData,
  onPersistSuccess?: (customerId: number) => void
) => {
  return action(
    ActionType.REQUEST_PERSIST_CUSTOMER,
    {
      persistCustomerData,
      onPersistSuccess,
    },
    {
      retry: true,
    }
  );
};

export const persistCustomerSuccess = () => {
  return action(ActionType.PERSIST_CUSTOMER_SUCCESS);
};

export const persistCustomerFailure = (error: Error) => {
  return action(ActionType.PERSIST_CUSTOMER_FAILURE, null, null, error);
};

export const requestGetAndSetCurrentCustomer = (customerId: number) => {
  return action(ActionType.REQUEST_GET_AND_SET_CURRENT_CUSTOMER, {
    customerId,
  });
};

export const requestGetCustomerForScheduledEvent = (
  scheduledEventId: number,
  callback?: (customer: Customer) => void
) => {
  return action(ActionType.REQUEST_GET_CUSTOMER_FOR_SCHEDULED_EVENT, {
    scheduledEventId,
    callback,
  });
};

export const requestGetCustomer = (
  customerId: number,
  callback?: (customer: Customer) => void
) => {
  return action(ActionType.REQUEST_GET_CUSTOMER, {
    customerId,
    callback,
  });
};

export const getCustomerSuccess = (customer: Customer) => {
  return action(ActionType.GET_CUSTOMER_SUCCESS, {
    customer,
  });
};

export const setCurrentCustomer = (customer: Customer) => {
  return action(ActionType.SET_CURRENT_CUSTOMER, {
    customer,
  });
};

export const getCustomerFailure = (customerId: number, error: Error) => {
  return action(ActionType.GET_CUSTOMER_FAILURE, { customerId }, null, error);
};

export const clearCurrentCustomer = () => {
  return action(ActionType.CLEAR_CURRENT_CUSTOMER);
};

export const clearCustomers = () => {
  return action(ActionType.CLEAR_CUSTOMERS);
};

export const requestGetListedCustomersPage = (
  getListedCustomersRequestData: GetListedCustomersRequestData,
  callback?: (listedCustomersPage: ListedCustomersListViewPage) => void
) => {
  return action(ActionType.REQUEST_GET_LISTED_CUSTOMERS_PAGE, {
    getListedCustomersRequestData,
    callback,
  });
};

export const clearListedCustomersPage = () => {
  return action(ActionType.CLEAR_LISTED_CUSTOMERS_PAGE);
};

export const getListedCustomersPageSuccess = (
  listedCustomersPage: ListedCustomersListViewPage
) => {
  return action(ActionType.GET_LISTED_CUSTOMERS_PAGE_SUCCESS, {
    listedCustomersPage,
  });
};

export const getListedCustomersPageFailure = (error: Error) => {
  return action(
    ActionType.GET_LISTED_CUSTOMERS_PAGE_FAILURE,
    null,
    null,
    error
  );
};

export const requestArchiveCustomers = (
  customerIds: number[],
  onArchiveSuccess?: () => void
) => {
  return action(ActionType.REQUEST_ARCHIVE_CUSTOMERS, {
    customerIds,
    onArchiveSuccess,
  });
};

export const archiveCustomersSuccess = () => {
  return action(ActionType.ARCHIVE_CUSTOMERS_SUCCESS);
};

export const archiveCustomersFailure = (error: Error) => {
  return action(ActionType.ARCHIVE_CUSTOMERS_FAILURE, null, null, error);
};

export const getCustomerAnalytics = createAsyncAction<GetCustomerAnalytics>({
  request: ActionType.GET_CUSTOMER_ANALYTICS_REQUEST,
  success: ActionType.GET_CUSTOMER_ANALYTICS_SUCCESS,
  failure: ActionType.GET_CUSTOMER_ANALYTICS_FAILURE,
})((payload: GetCustomerAnalytics['request']['payload']) => ({ payload }));

export const patchCustomer = createAsyncAction<PatchCustomer>({
  request: ActionType.PATCH_CUSTOMER_REQUEST,
  success: ActionType.PATCH_CUSTOMER_SUCCESS,
  failure: ActionType.PATCH_CUSTOMER_FAILURE,
})((payload: PatchCustomer['request']['payload']) => ({ payload }));

export const exportCustomersData = createAsyncAction<ExportCustomersData>({
  request: ActionType.EXPORT_CUSTOMERS_DATA_REQUEST,
})((payload: ExportCustomersData['request']['payload']) => ({ payload }));
