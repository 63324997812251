import { call, delay, put, race, takeEvery } from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import {
  clearListedTasksPage,
  getListedTasksPageFailure,
  getListedTasksPageSuccess,
} from './listedTasksActions';
import {
  ActionType,
  GetListedTasksRequestData,
  SagaConfig,
} from './listedTasksTypes';

const listedTasksSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  function* handleGetListedTasksPage(
    action: PayloadAction<
      ActionType.REQUEST_GET_LISTED_TASKS_PAGE,
      {
        getListedTasksRequestData: GetListedTasksRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getListedTasksPage,
          action.payload.getListedTasksRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getListedTasksPageFailure(
            new Error('Get listed users page timed out.')
          )
        );
      } else {
        yield put(getListedTasksPageSuccess(response));
      }
    } catch (error: any) {
      yield put(getListedTasksPageFailure(error));
    }
  }

  function* handleAppLogout() {
    yield put(clearListedTasksPage());
  }

  const getListedTasksPage = async (
    getListedTasksRequestData: GetListedTasksRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/tasks/list?pageNumber=${
        getListedTasksRequestData.pageNumber
      }&pageSize=${getListedTasksRequestData.pageSize}&deadlineStates=${
        getListedTasksRequestData.deadlineStates
          ? getListedTasksRequestData.deadlineStates.join(',')
          : ''
      }&completionStates=${
        getListedTasksRequestData.completionStates
          ? getListedTasksRequestData.completionStates.join(',')
          : ''
      }&searchTerm=${getListedTasksRequestData.searchTerm || ''}&assignedTo=${
        getListedTasksRequestData.assignedTo
          ? getListedTasksRequestData.assignedTo.join(',')
          : ''
      }&createdBy=${
        getListedTasksRequestData.createdBy
          ? getListedTasksRequestData.createdBy.join(',')
          : ''
      }&sortDirection=${getListedTasksRequestData.sortDirection || ''}&sortBy=${
        getListedTasksRequestData.sortBy || ''
      }&excludeTasksCompletedOverXHoursAgo=${
        getListedTasksRequestData.excludeTasksCompletedOverXHoursAgo || ''
      }${
        getListedTasksRequestData.dealId
          ? `&dealId=${getListedTasksRequestData.dealId}`
          : ''
      }`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Task': 'true',
          'X-Native-App': `${isNativeApp}`,
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getListedTasksPage failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  return function* () {
    yield takeEvery(
      ActionType.REQUEST_GET_LISTED_TASKS_PAGE,
      handleGetListedTasksPage
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default listedTasksSagaCreator;
