import {
  faClock,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FunctionComponent, useMemo } from 'react';

import moment from 'moment-timezone';
import FeedbackMessage from '../feedbackMessage/FeedbackMessage';
import './DueMessage.sass';

type Props = {
  renderMessage?: (dueIndays: number, message: string) => JSX.Element;
  dueDate: Date;
};

const DueMessage: FunctionComponent<Props> = ({
  renderMessage,
  dueDate,
}: Props): JSX.Element => {
  const dueInDays = useMemo(() => {
    const deadlineMoment = moment(dueDate);
    const nowMoment = moment().startOf('day');

    const daysBetween = deadlineMoment.diff(nowMoment, 'days');
    return daysBetween;
  }, [dueDate]);

  const message = useMemo(() => {
    const abs = Math.abs(dueInDays);
    const dayOrDays = abs === 1 ? 'day' : 'days';

    if (dueInDays === 0) return 'Due today';
    if (dueInDays < 0) return `Due ${-dueInDays} ${dayOrDays} ago`;
    return `Due in ${dueInDays} ${dayOrDays}`;
  }, [dueInDays]);

  if (renderMessage) return renderMessage(dueInDays, message);

  return (
    <FeedbackMessage
      className={`due-message ${
        dueInDays === 0 ? 'today' : dueInDays < 0 ? 'past' : 'future'
      }`}
      message={message}
      iconBefore={
        dueInDays != null && dueInDays <= 0 ? faTriangleExclamation : faClock
      }
    />
  );
};

export default DueMessage;
