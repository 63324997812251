import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  CreateOrUpdateSupplierMaterialInput,
  CreateOrUpdateSupplierMaterialMutation,
} from '@/gql/graphql';

const CREATE_OR_UPDATE_SUPPLIER_MATERIAL = graphql(`
  mutation CreateOrUpdateSupplierMaterial(
    $input: CreateOrUpdateSupplierMaterialInput!
  ) {
    createOrUpdateSupplierMaterial(input: $input) {
      id
    }
  }
`);

const useCreateOrUpdateSupplierMaterial = (
  options?: UseMutationOptions<
    CreateOrUpdateSupplierMaterialMutation,
    unknown,
    CreateOrUpdateSupplierMaterialInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(CREATE_OR_UPDATE_SUPPLIER_MATERIAL, { input });
    },
    ...options,
  });
};

export default useCreateOrUpdateSupplierMaterial;
