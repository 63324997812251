import qs from 'qs';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { actions as appActions } from '@/api/app';

import useLogout from '@/hooks/auth/useLogout';
import LoginForm from '@/ui/components/loginForm/LoginForm';
import ResetPasswordForm from '@/ui/components/resetPasswordForm/ResetPasswordForm';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';
import './ResetPasswordPage.sass';

enum PageState {
  RESET_PASSWORD = 'reset-password',
  RESET_PASSWORD_SUCCESS = 'reset-password-success',
  INVALID_TOKEN = 'invalid-token',
}

type Props = {
  match: any;
  location: any;
  history: any;
  isAuthed: boolean;
};
const ResetPasswordPage: FunctionComponent<Props> = ({
  match,
  location,
  history,
  isAuthed,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [pageState, setPageState] = useState(PageState.RESET_PASSWORD);
  const [linkToNativeApp, setLinkToNativeApp] = useState(false);

  const goToRegisterPage = useCallback(() => {
    history.push('/register');
  }, [history]);

  const logout = useLogout();

  useEffect(() => {
    if (isAuthed) {
      logout();
    } else {
      validateToken();
    }
  }, []);

  const validateToken = useCallback(() => {
    const token = match.params.token;
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    setLinkToNativeApp(query.linkToNativeApp === 'true');

    dispatch(
      appActions.validResetToken(token, (err: any) => {
        if (err) {
          setPageState(PageState.INVALID_TOKEN);
        }
      })
    );
  }, [dispatch, location.search, match.params.token]);

  const titleContent = useMemo(() => {
    if (pageState === PageState.RESET_PASSWORD) {
      return (
        <>
          <h2>Reset your password</h2>
          <p>Please enter and confirm your new password below</p>
        </>
      );
    } else if (pageState === PageState.INVALID_TOKEN) {
      return <h2>Invalid password reset link</h2>;
    } else if (pageState === PageState.RESET_PASSWORD_SUCCESS) {
      return (
        <>
          <h2>Your password has been reset</h2>
          <p>You can now log in with your new password.</p>
        </>
      );
    }
  }, [pageState]);

  return (
    <UnauthenticatedPageWrapper
      titleContent={titleContent}
      className="reset-password-page"
    >
      {pageState === PageState.RESET_PASSWORD && (
        <ResetPasswordForm
          resetToken={match.params.token}
          onSuccessCallback={() =>
            setPageState(PageState.RESET_PASSWORD_SUCCESS)
          }
        />
      )}
      {pageState === PageState.INVALID_TOKEN && (
        <Button
          styleVariant={ButtonStyleVariant.OUTSIZE}
          colourVariant={ButtonColourVariant.PRIMARY}
          hasBoxShadow={true}
          onClick={() => history.push('/forgotPassword')}
        >
          Request a new one
        </Button>
      )}
      {pageState === PageState.RESET_PASSWORD_SUCCESS && (
        <>
          {linkToNativeApp && (
            <a href={'Payaca://'} className="mobile-app-link">
              <Button
                styleVariant={ButtonStyleVariant.OUTSIZE}
                colourVariant={ButtonColourVariant.PRIMARY}
                hasBoxShadow={true}
              >
                Return to login page
              </Button>
            </a>
          )}
          {!linkToNativeApp && (
            <LoginForm
              onLoginCallback={() => history.push('/')}
              hideForgotPassword={true}
            />
          )}
        </>
      )}
    </UnauthenticatedPageWrapper>
  );
};

export default ResetPasswordPage;
