import SortableColumnHeaderTableCell from '@payaca/components/sortableColumnHeaderTableCell/SortableColumnHeaderTableCell';
import { FC, useMemo } from 'react';

import { SortBy } from '@payaca/types/listedDealTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

type Props = {
  onChangeSortOrder: (sortBy: SortBy, sortDirection: SortDirection) => void;
  sortBy: SortBy;
  sortDirection: SortDirection;
  collapse?: boolean;
  hideCustomerColumn?: boolean;
};
const ListedDealsTableHeader: FC<Props> = ({
  onChangeSortOrder,
  sortBy,
  sortDirection,
  collapse,
  hideCustomerColumn,
}: Props): JSX.Element => {
  const renderCollapsedTableHeader = useMemo(() => {
    return (
      <thead>
        <tr>
          <SortableColumnHeaderTableCell
            align="center"
            columnName="Reference"
            selectedSortDirection={
              sortBy === SortBy.DEAL_REFERENCE ? sortDirection : undefined
            }
            onChangeSortDirection={(sortDirection: SortDirection) =>
              onChangeSortOrder(SortBy.DEAL_REFERENCE, sortDirection)
            }
          />
          <th className="align-right">Value</th>
          <th className="align-left">Pipeline</th>
          <th className="align-left">Stage</th>
        </tr>
      </thead>
    );
  }, [onChangeSortOrder, sortBy, sortDirection]);

  const renderTableHeader = useMemo(() => {
    return (
      <thead>
        <tr>
          <SortableColumnHeaderTableCell
            align="center"
            columnName="Project ref"
            selectedSortDirection={
              sortBy === SortBy.DEAL_REFERENCE ? sortDirection : undefined
            }
            onChangeSortDirection={(sortDirection: SortDirection) =>
              onChangeSortOrder(SortBy.DEAL_REFERENCE, sortDirection)
            }
          />
          {!hideCustomerColumn && (
            <SortableColumnHeaderTableCell
              columnName="Customer"
              selectedSortDirection={
                sortBy === SortBy.CUSTOMER_NAME ? sortDirection : undefined
              }
              onChangeSortDirection={(sortDirection: SortDirection) =>
                onChangeSortOrder(SortBy.CUSTOMER_NAME, sortDirection)
              }
            />
          )}
          <th className="align-center">Customer address</th>
          <th className="align-center">Description</th>
          <SortableColumnHeaderTableCell
            align="center"
            columnName="Updated"
            selectedSortDirection={
              sortBy === SortBy.LAST_UPDATED ? sortDirection : undefined
            }
            onChangeSortDirection={(sortDirection: SortDirection) =>
              onChangeSortOrder(SortBy.LAST_UPDATED, sortDirection)
            }
          />
          <th className="align-right">Value</th>
          <th className="align-left">Pipeline</th>
          <th className="align-left">Stage</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
    );
  }, [onChangeSortOrder, sortBy, sortDirection]);

  return collapse ? renderCollapsedTableHeader : renderTableHeader;
};

export default ListedDealsTableHeader;
