import { FC } from 'react';
import {
  CollectionFulfillmentOption,
  DeliveryFulfillmentOption,
  FulfillmentOption,
} from './fulfillment';

type Props = {
  deliveryOptions: DeliveryFulfillmentOption[];
  collectionOptions: CollectionFulfillmentOption[];
  getFulfillmentOptionText: (f: FulfillmentOption) => string;
};
const LiveAvailabilityMaterialSupplierBadgeTooltipContent: FC<Props> = (
  props
) => {
  const { deliveryOptions, collectionOptions, getFulfillmentOptionText } =
    props;

  if (!deliveryOptions.length && !collectionOptions.length) {
    return 'This product appears to be out of stock.';
  }

  return (
    <>
      {!!deliveryOptions.length && (
        <div className="p-2">
          <span>Delivery</span>
          <ul className="m-1">
            {deliveryOptions.map((f, i) => (
              <li key={`delivery-${i}`}>{getFulfillmentOptionText(f)}</li>
            ))}
          </ul>
        </div>
      )}
      {!!collectionOptions.length && (
        <div className="p-2">
          <span>Collection</span>
          <ul className="m-1">
            {collectionOptions.map((f, i) => (
              <li key={`collection-${i}`}>{getFulfillmentOptionText(f)}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default LiveAvailabilityMaterialSupplierBadgeTooltipContent;
