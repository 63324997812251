/**
 * Overview tab of service plans screen - displaying the list of service plan account has set up
 */
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import DropdownField from '@payaca/components/dropdownField/DropdownField';
import {
  InputColourVariant,
  InputStyleVariant,
} from '@payaca/components/inputWrapper/InputWrapper';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import CustomerFilterControl from '../customerFilterControl/CustomerFilterControl';
import ServicePlanPeriodCard from './ServicePlanPeriodCard';

import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';

import { useSelector } from '../../../api/state';

import { GetServicePeriodsRequestData } from '@payaca/store/servicePlans/servicePlansTypes';
import { ServicePlanPeriod } from '@payaca/types/service-plans';

import './ServicePlansServicePeriods.sass';

const servicePeriodStatusOptions: {
  value: ServicePlanPeriod['status'];
  label: string;
}[] = [
  { value: 'unarranged', label: 'Unarranged' },
  { value: 'arranged', label: 'Arranged' },
  { value: 'fulfilled', label: 'Complete' },
];

const ServicePlansServicePeriods = () => {
  const dispatch = useDispatch();
  // servicePlanPublicIds in query to filter by plan
  const servicePlanPublicIds = useMemo(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const planIds = query?.servicePlanPublicIds as string;
    return planIds ? planIds.split(',') : [];
  }, [location]);
  const customerId = useMemo(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (query?.customerId) {
      return Number(query?.customerId);
    }
  }, [location]);

  const [getServicePeriodsRequestData, setGetServicePeriodsRequestData] =
    useState<GetServicePeriodsRequestData>({
      servicePlanPublicIds,
      statuses: ['unarranged', 'arranged'],
      when: [],
      customerId: customerId,
    });

  const listedServicePlans = useSelector(
    (state) => state.servicePlans.listedServicePlans
  );

  const listedServicePlanPeriods = useSelector(
    (state) => state.servicePlans.listedServicePlanPeriods
  );

  useEffect(() => {
    dispatch(
      servicePlansActions.getServicePeriods.request(
        getServicePeriodsRequestData
      )
    );
  }, [getServicePeriodsRequestData]);

  const isGettingServicePeriods = useSelector(
    (state) => state.servicePlans.isGettingServicePeriods
  );

  const servicePlanOptions = useMemo(() => {
    return (
      listedServicePlans?.map((listedServicePlan) => ({
        value: listedServicePlan.publicId,
        label: listedServicePlan.name,
      })) || []
    );
  }, [listedServicePlans]);

  const hasFiltersApplied = useMemo(() => {
    return (
      getServicePeriodsRequestData?.servicePlanPublicIds?.length ||
      getServicePeriodsRequestData?.customerId ||
      getServicePeriodsRequestData.statuses.length !== 0 ||
      getServicePeriodsRequestData.statuses.length !==
        servicePeriodStatusOptions.length
    );
  }, [getServicePeriodsRequestData]);

  return (
    <div className="service-plans-service-periods">
      {/* Filter */}
      <div className="service-periods-filters">
        <div className="filter-container">
          <DropdownField
            label="Plan"
            value={getServicePeriodsRequestData?.servicePlanPublicIds || []}
            name="servicePlanPublicIds"
            options={servicePlanOptions}
            onChange={(value) => {
              setGetServicePeriodsRequestData(
                (
                  getServicePeriodsRequestData: GetServicePeriodsRequestData
                ) => {
                  return {
                    ...getServicePeriodsRequestData,
                    ...value,
                  };
                }
              );
            }}
            emptyText="All Plans"
            multiple={true}
            additionalSelectProps={{
              displayEmpty: true,
            }}
            styleVariant={InputStyleVariant.STANDARD}
            colourVariant={InputColourVariant.WHITE}
            hasBoxShadow={false}
            displayCheckboxes={true}
          />
        </div>
        <div className="filter-container">
          <CustomerFilterControl
            selectedCustomerId={
              getServicePeriodsRequestData.customerId || undefined
            }
            onChange={(customerId) => {
              setGetServicePeriodsRequestData(
                (
                  getServicePeriodsRequestData: GetServicePeriodsRequestData
                ) => {
                  return {
                    ...getServicePeriodsRequestData,
                    customerId,
                  };
                }
              );
            }}
            displayValue={
              getServicePeriodsRequestData.customerId
                ? undefined
                : 'All Customers'
            }
          />
        </div>
        <div className="filter-container">
          <DropdownField
            label="Status"
            value={getServicePeriodsRequestData?.statuses || []}
            name="statuses"
            options={servicePeriodStatusOptions}
            onChange={(value) => {
              setGetServicePeriodsRequestData(
                (getServicePeriodsRequestData: any) => {
                  return {
                    ...getServicePeriodsRequestData,
                    ...value,
                  };
                }
              );
            }}
            emptyText="All statuses"
            multiple={true}
            additionalSelectProps={{
              displayEmpty: true,
            }}
            styleVariant={InputStyleVariant.STANDARD}
            colourVariant={InputColourVariant.WHITE}
            hasBoxShadow={false}
            displayCheckboxes={true}
          />
        </div>
        <div className="filter-container">
          <DropdownField
            label="When"
            value={getServicePeriodsRequestData?.when || []}
            name="when"
            options={[
              { label: 'Past', value: 'past' },
              { label: 'Upcoming', value: 'upcoming' },
              { label: 'Current', value: 'current' },
            ]}
            onChange={(value) => {
              setGetServicePeriodsRequestData(
                (getServicePeriodsRequestData: any) => {
                  return {
                    ...getServicePeriodsRequestData,
                    ...value,
                  };
                }
              );
            }}
            emptyText="Any time"
            multiple={true}
            additionalSelectProps={{
              displayEmpty: true,
            }}
            styleVariant={InputStyleVariant.STANDARD}
            colourVariant={InputColourVariant.WHITE}
            hasBoxShadow={false}
            displayCheckboxes={true}
          />
        </div>
      </div>
      {isGettingServicePeriods ? (
        <div className="loader-container">
          <MiniLoader />
        </div>
      ) : (
        <ResponsiveViewWrapper className="service-periods-container">
          {listedServicePlanPeriods?.length ? (
            listedServicePlanPeriods.map((servicePlanPeriod, i) => (
              <ServicePlanPeriodCard
                servicePlanPeriod={servicePlanPeriod}
                key={`service-plan-period-${i}`}
              />
            ))
          ) : hasFiltersApplied ? (
            <span className="no-results">No results found</span>
          ) : (
            <></>
          )}
        </ResponsiveViewWrapper>
      )}
    </div>
  );
};

export default ServicePlansServicePeriods;
