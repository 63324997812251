import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { Action, PayloadAction } from 'typesafe-actions';

import {
  ActionType,
  PersistMaterialRequestData,
  PersistSupplierMaterialRequestData,
  SagaConfig,
} from './materialsTypes';

import {
  archiveMaterialsFailure,
  archiveMaterialsSuccess,
  clearLineItemMaterials,
  clearMaterialCategories,
  clearMaterialSuppliers,
  clearMaterials,
  clearSupplierMaterials,
  deleteSupplierMaterialFailure,
  deleteSupplierMaterialSuccess,
  getMaterialCategoriesFailure,
  getMaterialCategoriesSuccess,
  getMaterialSuppliersFailure,
  getMaterialSuppliersSuccess,
  getSupplierMaterialFailure,
  getSupplierMaterialSuccess,
  persistMaterialFailure,
  persistMaterialSuccess,
  persistSupplierMaterialFailure,
  persistSupplierMaterialSuccess,
} from './materialsActions';

import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const materialsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);
  function* handlePersistMaterial(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_MATERIAL,
      {
        persistMaterialRequestData: PersistMaterialRequestData;
        callback?: (materialId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          persistMaterial,
          action.payload.persistMaterialRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistMaterialFailure(new Error('Persist material timed out.'))
        );
      } else {
        yield put(persistMaterialSuccess());
        action.payload.callback?.(response);
      }
    } catch (error: any) {
      yield put(persistMaterialFailure(error as Error));
    }
  }

  const persistMaterial = async (
    persistMaterialRequestData: PersistMaterialRequestData
  ) => {
    const response = await req.post(`/materials`, persistMaterialRequestData);

    return await response.json();
  };

  function* handlePersistSupplierMaterial(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_SUPPLIERMATERIAL,
      {
        persistSupplierMaterialRequestData: PersistSupplierMaterialRequestData;
        callback?: (supplierMaterialId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          persistSupplierMaterial,
          action.payload.persistSupplierMaterialRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistSupplierMaterialFailure(
            new Error('Persist supplier material timed out.')
          )
        );
      } else {
        yield put(persistSupplierMaterialSuccess());
        action.payload.callback?.(response);
      }
    } catch (error: any) {
      yield put(persistSupplierMaterialFailure(error as Error));
    }
  }

  const persistSupplierMaterial = async (
    persistSupplierMaterialRequestData: PersistSupplierMaterialRequestData
  ) => {
    const response = await req.post(
      `/supplier_materials`,
      persistSupplierMaterialRequestData
    );

    return await response.json();
  };

  function* handleGetMaterialCategories(
    action: Action<ActionType.REQUEST_GET_MATERIALCATEGORIES>
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getMaterialCategories),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get material categories timed out.';
        yield put(getMaterialCategoriesFailure(new Error(errorMessage)));
      } else {
        yield put(getMaterialCategoriesSuccess(response));
      }
    } catch (error: any) {
      yield put(getMaterialCategoriesFailure(error));
    }
  }

  const getMaterialCategories = async () => {
    const response = await req.get(`/materials/categories`);
    return response.json();
  };

  function* handleGetMaterialSuppliers(
    action: Action<ActionType.REQUEST_GET_MATERIALSUPPLIERS>
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getMaterialSuppliers),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get material suppliers timed out.';
        yield put(getMaterialSuppliersFailure(new Error(errorMessage)));
      } else {
        yield put(getMaterialSuppliersSuccess(response));
      }
    } catch (error: any) {
      yield put(getMaterialSuppliersFailure(error));
    }
  }

  const getMaterialSuppliers = async () => {
    const response = await req.get(`/materials/suppliers`);
    return response.json();
  };

  function* handleDeleteSupplierMaterial(
    action: PayloadAction<
      ActionType.REQUEST_DELETE_SUPPLIERMATERIAL,
      {
        supplierMaterialId: number;
        callback: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          deleteSupplierMaterial,
          action.payload.supplierMaterialId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Delete supplierMaterial timed out.';
        yield put(deleteSupplierMaterialFailure(new Error(errorMessage)));
      } else {
        yield put(deleteSupplierMaterialSuccess());
        action.payload.callback?.();
      }
    } catch (error: any) {
      yield put(deleteSupplierMaterialFailure(error));
    }
  }

  const deleteSupplierMaterial = async (supplierMaterialId: number) => {
    return req.del(`/supplier_materials/${supplierMaterialId}`);
  };

  function* handleGetSupplierMaterial(
    action: PayloadAction<
      ActionType.REQUEST_GET_SUPPLIERMATERIAL,
      {
        supplierMaterialId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getSupplierMaterial, action.payload.supplierMaterialId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get supplierMaterial timed out.';
        yield put(
          getSupplierMaterialFailure(
            action.payload.supplierMaterialId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(getSupplierMaterialSuccess(response.id, response));
      }
    } catch (error: any) {
      yield put(
        getSupplierMaterialFailure(action.payload.supplierMaterialId, error)
      );
    }
  }

  const getSupplierMaterial = async (supplierMaterialId: number) => {
    const response = await req.get(`/supplier_materials/${supplierMaterialId}`);
    return response.json();
  };

  function* handleArchiveMaterials(
    action: PayloadAction<
      ActionType.REQUEST_ARCHIVE_MATERIALS,
      {
        materialIds: number[];
        onArchiveSuccess?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(archiveMaterials, action.payload.materialIds),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          archiveMaterialsFailure(new Error('Archive materials timed out.'))
        );
      } else {
        action.payload.onArchiveSuccess?.();
        yield put(archiveMaterialsSuccess());
      }
    } catch (error: any) {
      yield put(archiveMaterialsFailure(error));
    }
  }

  const archiveMaterials = async (materialIds: number[]) => {
    const response = await req.put('/materials/bulk_archive', {
      materialIds,
    });
    return response;
  };

  function* handleLogout() {
    yield put(clearMaterials());
    yield put(clearSupplierMaterials());
    yield put(clearLineItemMaterials());
    yield put(clearMaterialCategories());
    yield put(clearMaterialSuppliers());
  }

  return function* () {
    yield takeLatest(
      ActionType.REQUEST_PERSIST_MATERIAL,
      handlePersistMaterial
    );
    yield takeEvery(
      ActionType.REQUEST_ARCHIVE_MATERIALS,
      handleArchiveMaterials
    );

    yield takeLatest(
      ActionType.REQUEST_PERSIST_SUPPLIERMATERIAL,
      handlePersistSupplierMaterial
    );
    yield takeEvery(
      ActionType.REQUEST_GET_SUPPLIERMATERIAL,
      handleGetSupplierMaterial
    );
    yield takeEvery(
      ActionType.REQUEST_DELETE_SUPPLIERMATERIAL,
      handleDeleteSupplierMaterial
    );
    yield takeLatest(
      ActionType.REQUEST_GET_MATERIALCATEGORIES,
      handleGetMaterialCategories
    );
    yield takeLatest(
      ActionType.REQUEST_GET_MATERIALSUPPLIERS,
      handleGetMaterialSuppliers
    );

    yield takeEvery('auth.logout', handleLogout);
  };
};

export default materialsSagaCreator;
