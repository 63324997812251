import React, { FC, useCallback, useMemo } from 'react';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import * as customerActions from '@payaca/store/customer/customerActions';

import useGetExistingCustomerContactEmails from '@/api/queries/customers/useGetExistingCustomerContactEmails';
import { useSelector } from '@/api/state';
import { useBlockedEmails } from '@/utils/customHooks';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Modal from '@payaca/components/modal/Modal';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import { getCustomerContactEmailFieldValidator } from '@payaca/helpers/customerValidationHelper';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { CustomerContact } from '@payaca/types/customerTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { useDispatch } from 'react-redux';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';

type Props = {
  isOpen?: boolean;
  customer: any;
  customerUpdateCallback: () => void;
  contactId: number | null;
  onClose: () => void;
};
const AddCustomerEmailModal: FC<Props> = ({
  isOpen = true,
  customer,
  customerUpdateCallback,
  contactId,
  onClose,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const blockedEmails = useBlockedEmails();

  const isPersistingCustomer = useSelector(
    (state) => state.customer.isPersistingCustomer
  );

  const contact = useMemo(
    () => getJobContactFromCustomer(customer, contactId),
    [customer, contactId]
  );

  const onSubmit = useCallback(
    (customerEmail: string) => {
      const contacts = customer.contacts.map(
        (customerContact: CustomerContact, index: number) => {
          if (customerContact.id === contact?.id) {
            // update email on job contact
            return {
              ...customerContact,
              emailAddress: customerEmail,
            };
          } else {
            return customerContact;
          }
        }
      );

      const customerToPersist = {
        ...customer,
        contacts: contacts,
      };

      dispatch(
        customerActions.requestPersistCustomer(
          customerToPersist,
          (customerId: number) => {
            customerUpdateCallback && customerUpdateCallback();
          }
        )
      );
    },
    [customerUpdateCallback, customer, contact]
  );

  const { data: existingCustomerContactEmails, isLoading } =
    useGetExistingCustomerContactEmails();

  const fieldValidators = useMemo(() => {
    const customerContactEmailFieldValidator =
      getCustomerContactEmailFieldValidator({
        blockedEmails,
        existingCustomerContactEmails,
      });

    return {
      emailAddress: [
        getIsRequiredFieldValidator(),
        customerContactEmailFieldValidator,
      ],
    };
  }, [blockedEmails, existingCustomerContactEmails]);

  const initialFormState = useMemo(() => {
    return {
      emailAddress: contact?.emailAddress,
    };
  }, [customer, contact]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <React.Fragment>
          <ValidatedFieldWrapper
            validationResult={validationState['emailAddress']}
            isTouched={touchedState['emailAddress'] || false}
          >
            <LoqateAdvisoryEmailInputField
              label={
                'To send this via email please add a customer email address.'
              }
              value={formState.emailAddress}
              name="emailAddress"
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              disableValidation={
                validationState['emailAddress']
                  ? !validationState['emailAddress'].isValid
                  : false
              }
              styleVariant={InputStyleVariant.OUTSIZE}
            />
          </ValidatedFieldWrapper>
          <div
            className="actions-container"
            // style={{
            //   marginTop: '1rem',
            //   display: 'flex',
            //   justifyContent: 'flex-end',
            // }}
          >
            <Button
              onClick={() =>
                !isPersistingCustomer && onSubmit(formState.emailAddress)
              }
              isDisabled={!isValid}
              isProcessing={isPersistingCustomer}
              iconAfter={faChevronRight}
              styleVariant={ButtonStyleVariant.OUTSIZE}
            >
              Ok
            </Button>
          </div>
        </React.Fragment>
      );
    },
    [onSubmit, isPersistingCustomer]
  );

  return (
    <Modal isOpen={isOpen} title="Missing customer email" onClose={onClose}>
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
        initialFormState={initialFormState}
      />
    </Modal>
  );
};

export default AddCustomerEmailModal;
