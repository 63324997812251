import { Transition } from '@headlessui/react';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';

import useCreateUploadIntent from '@/api/mutations/file/useCreateUploadIntent';
import useGetIncompleteUploadIntents from '@/api/queries/files/useGetIncompleteUploadIntents';
import Progress from '@payaca/components/plProgress/Progress';
import Toast from '@payaca/components/plToast/Toast';
import { capitaliseFirstLetter } from '@payaca/utilities/stringUtilities';
import { useQueryClient } from '@tanstack/react-query';
import { createPortal } from 'react-dom';

export type FileToUploadStatus =
  | 'not-started'
  | 'created-intent'
  | 'uploading'
  | 'finalising'
  | 'failed';

const readableFileToUploadStatus: Record<FileToUploadStatus, string> = {
  'not-started': 'Uploading',
  'created-intent': 'Uploading',
  uploading: 'Uploading',
  finalising: 'Finalising',
  failed: 'Failed',
};

export type UploadIntent = {
  id: string;
};

export type FileToUpload = {
  id: string;
  file: File;
  status: FileToUploadStatus;
  uploadIntent?: UploadIntent;
  signedUploadUrl?: string;
  signedUploadUrlHeaders?: [string, string][];
  progress?: number;
  invalidateQueryKey?: any;
};
type UploadToEnqueue = {
  file: File;
  invalidateQueryKey?: any;
};
export type FileUploadContext = {
  filesToUpload: FileToUpload[];
  enqueueUploads: (uploads: UploadToEnqueue[]) => Promise<UploadIntent[]>;
};

export const FileUploadContext = createContext<FileUploadContext>({
  filesToUpload: [],
  enqueueUploads: () => Promise.resolve([]),
});

export const useFileUploadContext = () => {
  return useContext(FileUploadContext);
};

const FileUploadContextProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const queryClient = useQueryClient();

  const [uploadIntentsInFlight, setUploadIntentsInFlight] = useState<string[]>(
    []
  );
  const [filesToUpload, setFilesToUpload] = useState<
    FileUploadContext['filesToUpload']
  >([]);

  useGetIncompleteUploadIntents(uploadIntentsInFlight, {
    onSuccess: (data) => {
      const uploadIntents = data.uploadIntents;

      const failedUploadIntentIds = uploadIntents
        .filter((i) => i.status === 'error')
        .map((i) => i.id);

      if (failedUploadIntentIds.length > 0) {
        setUploadIntentsInFlight((prev) =>
          prev.filter((i) => !failedUploadIntentIds.includes(i))
        );

        setFilesToUpload((prev) => {
          return prev.map((f) => {
            if (
              f.uploadIntent &&
              failedUploadIntentIds.includes(f.uploadIntent.id)
            ) {
              return {
                ...f,
                status: 'failed',
              };
            }

            return f;
          });
        });
      } else {
        const uploadIntentsStillIncomplete = uploadIntents.map(
          (intent) => intent.id
        );

        if (
          uploadIntentsStillIncomplete.length !== uploadIntentsInFlight.length
        ) {
          const nowComplete = uploadIntentsInFlight.filter(
            (i) => !uploadIntentsStillIncomplete.includes(i)
          );

          setUploadIntentsInFlight(uploadIntentsStillIncomplete);

          nowComplete.forEach((u) => {
            const upload = filesToUpload.find((f) => f.uploadIntent?.id === u);
            if (upload?.invalidateQueryKey) {
              void queryClient.invalidateQueries({
                queryKey: upload.invalidateQueryKey,
              });
            }
          });
          setFilesToUpload((prev) => {
            return prev.filter(
              (f) => !nowComplete.includes(f.uploadIntent?.id || '')
            );
          });
        }
      }
    },
  });
  const { mutateAsync: createUploadIntentMutation } = useCreateUploadIntent();

  const updateFileState = (id: string, updatedState: Partial<FileToUpload>) => {
    setFilesToUpload((prevState) => {
      return prevState.map((fileToUpload) => {
        if (fileToUpload.id !== id) {
          return fileToUpload;
        }

        return {
          ...fileToUpload,
          ...updatedState,
        };
      });
    });
  };

  const startBucketUploadForFile = (
    fileToUpload: FileToUpload,
    options: {
      onProgress?: (progress: number) => void;
      onCompleted?: () => void;
      onError?: (errorMessage: string) => void;
    } = {}
  ) => {
    const { onProgress, onCompleted, onError } = options;

    if (!fileToUpload.signedUploadUrl) {
      onError?.('No signed upload URL');
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.open('PUT', fileToUpload.signedUploadUrl);
    fileToUpload.signedUploadUrlHeaders?.forEach((header) => {
      xhr.setRequestHeader(header[0], header[1]);
    });

    // Monitor progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        // This doesn't work correctly in development mode:
        // "The onprogress function is reporting on the status of the
        // upload of the file to the metro proxy rather than to
        // its final destination on the net"
        const percentComplete = (event.loaded / event.total) * 100;
        onProgress?.(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        onCompleted?.();
      } else {
        onError?.(
          `Error uploading file to bucket (onload) with status: ${xhr.status} - ${xhr.statusText}`
        );
      }
    };

    xhr.onerror = () => {
      onError?.(
        `Error uploading file to bucket (onerror) with status ${xhr.status} - ${xhr.responseText}`
      );
    };

    xhr.send(fileToUpload.file);
  };

  /**
   * Enqueues files to be uploaded.
   * @param files
   */
  const enqueueUploads = async (uploadsToEnqueue: UploadToEnqueue[]) => {
    const newFilesToUpload = uploadsToEnqueue.map((uploadToEnqueue) => ({
      id: uuid(),
      file: uploadToEnqueue.file,
      status: 'not-started' as FileToUploadStatus,
      invalidateQueryKey: uploadToEnqueue.invalidateQueryKey,
    }));
    setFilesToUpload((prevState) => [...prevState, ...newFilesToUpload]);

    const res = await createUploadIntentMutation({
      files: newFilesToUpload.map((file) => ({
        publicId: file.id,
        fileName: file.file.name,
        mimeType: file.file.type,
        // todo: pass these are arguments to `enqueueUploads`
        linkedEntities: [],
      })),
    }).catch((error) => {
      console.error(error);
    });

    if (!res) {
      return [];
    }

    const updatedFilesToUpload = newFilesToUpload.map<FileToUpload>(
      (fileToUpload) => {
        const createdUploadIntent = res.createUploadIntent.find(
          ({ uploadIntent }) => uploadIntent.publicId === fileToUpload.id
        );

        if (!createdUploadIntent) {
          // todo: handle error
          return fileToUpload;
        }

        return {
          ...fileToUpload,
          status: 'created-intent',
          signedUploadUrl: createdUploadIntent.signedUploadUrl,
          signedUploadUrlHeaders:
            createdUploadIntent.signedUploadUrlHeaders as [string, string][],
          uploadIntent: createdUploadIntent.uploadIntent,
        };
      }
    );

    setFilesToUpload((prevState) => {
      return prevState.map((fileToUpload) => {
        const updatedFileToUpload = updatedFilesToUpload.find(
          ({ id }) => id === fileToUpload.id
        );

        if (!updatedFileToUpload) {
          return fileToUpload;
        }

        return updatedFileToUpload;
      });
    });

    updatedFilesToUpload.forEach((updateFileToUpload) => {
      startBucketUploadForFile(updateFileToUpload, {
        onProgress: (progress) => {
          updateFileState(updateFileToUpload.id, {
            status: 'uploading',
            progress,
          });
        },
        onCompleted: () => {
          updateFileState(updateFileToUpload.id, {
            status: 'finalising',
          });

          if (updateFileToUpload.uploadIntent) {
            setUploadIntentsInFlight((prevState) => [
              ...prevState,
              updateFileToUpload.uploadIntent!.id,
            ]);
          }
        },
        onError: () => {
          updateFileState(updateFileToUpload.id, {
            status: 'failed',
          });
        },
      });
    });

    return res.createUploadIntent.map(({ uploadIntent }) => ({
      id: uploadIntent.publicId,
    }));
  };

  return (
    <FileUploadContext.Provider value={{ filesToUpload, enqueueUploads }}>
      {children}

      {!!filesToUpload.length && (
        <>
          {createPortal(
            <div className="z-toast fixed bottom-0 right-0 space-y-4 p-8">
              {filesToUpload.map((fileToUpload, index) => (
                <Transition
                  key={index}
                  show
                  appear
                  enter="transition-opacity transition-transform duration-75"
                  enterFrom="opacity-0 translate-x-full"
                  enterTo="opacity-100"
                >
                  <Toast
                    variant="white"
                    icon={{
                      iconName: 'upload-cloud-01.3',
                      className: 'mt-1 mr-0.5',
                    }}
                    message={
                      <div className="min-w-48 space-y-1">
                        <p>Uploading file</p>

                        <p className="supporting-body">
                          {fileToUpload.progress} % -{' '}
                          {capitaliseFirstLetter(
                            readableFileToUploadStatus[fileToUpload.status]
                          )}
                        </p>

                        <Progress
                          bars={[
                            {
                              progress: fileToUpload.progress || 0,
                              total: 100,
                              colour: 'blue',
                            },
                          ]}
                        />
                      </div>
                    }
                  />
                </Transition>
              ))}
            </div>,
            document.body
          )}
        </>
      )}
    </FileUploadContext.Provider>
  );
};

export default FileUploadContextProvider;
