import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SortDirection } from '@payaca/types/listViewTypes';
import {
  ScheduledEvent,
  ScheduledEventStatus,
  SortBy,
} from '@payaca/types/scheduledEventsTypes';

import UpcomingScheduledEventsList from '../upcomingScheduledEventsList/UpcomingScheduledEventsList';

import { useSelector } from '@/api/state';

import * as scheduledEventActions from '@payaca/store/scheduledEvents/scheduledEventsActions';

const DashboardScheduledEventsList: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const [scheduledEvents, setScheduledEvents] = useState<ScheduledEvent[]>([]);

  const isGettingScheduledEvents = useSelector((state) => {
    return state.scheduledEvents.isGettingScheduledEvents;
  });

  const getScheduledEvents = useCallback(() => {
    dispatch(
      scheduledEventActions.requestGetListedScheduledEvents(
        {
          pageSize: 15,
          pageNumber: 0,
          statuses: [
            ScheduledEventStatus.CURRENT,
            ScheduledEventStatus.UPCOMING,
          ],
          sortDirection: SortDirection.ASCENDING,
          sortBy: SortBy.START_AT,
        },
        setScheduledEvents
      )
    );
  }, []);

  useEffect(() => {
    getScheduledEvents();
  }, []);

  return (
    <div className="dashboard-scheduled-events-list">
      <UpcomingScheduledEventsList
        scheduledEvents={scheduledEvents}
        getScheduledEvents={getScheduledEvents}
        isGettingScheduledEvents={isGettingScheduledEvents}
      />
    </div>
  );
};

export default DashboardScheduledEventsList;
