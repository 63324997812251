import { ClientChangeProposal } from '@payaca/types/clientTypes';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import { Reducer } from 'redux';

import { ActionType, State } from './clientTypes';

export const initialState: State = {
  isGettingDocumentSenderContext: false,
  documentSenderContext: undefined,

  isGettingDeal: false,
  deal: undefined,

  isGettingPaymentMethodConfig: false,
  paymentMethodConfig: undefined,

  isGettingCustomer: false,
  customer: undefined,

  isCreatingStripePaymentIntent: false,
  isCancellingStripePaymentIntent: false,
  isCreatingPaymentRecord: false,

  isGettingJobContents: false,

  invoices: {},
  proposals: {},
  jobContents: {},
  jobPayments: {},
  paymentReconciliationRecords: {},

  jobLineItems: {},
  jobLineItemGroups: {},
  invoiceLines: {},
  changeProposals: {},
};

const customerReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.CLEAR_DOCUMENT_ORIGIN_CONTEXT:
      return {
        ...state,
        documentSenderContext: undefined,
      };
    case ActionType.GET_DOCUMENT_ORIGIN_CONTEXT_REQUEST:
      return {
        ...state,
        isGettingDocumentSenderContext: true,
        documentSenderContext: undefined,
      };
    case ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_PAYLOAD_SUCCESS:
    case ActionType.GET_SERVICE_PLAN_PAYLOAD_SUCCESS:
    case ActionType.GET_DOCUMENT_ORIGIN_CONTEXT_SUCCESS:
      return {
        ...state,
        isGettingDocumentSenderContext: false,
        documentSenderContext: action.payload.documentSenderContext,
      };
    case ActionType.GET_DOCUMENT_ORIGIN_CONTEXT_FAILURE:
      return {
        ...state,
        isGettingDocumentSenderContext: false,
        documentSenderContext: undefined,
      };

    case ActionType.CLEAR_PAYMENT_METHOD_CONFIG:
      return {
        ...state,
        paymentMethodConfig: undefined,
      };
    case ActionType.GET_PAYMENT_METHOD_CONFIG_REQUEST:
      return {
        ...state,
        isGettingPaymentMethodConfig: true,
        paymentMethodConfig: undefined,
      };
    case ActionType.GET_PAYMENT_METHOD_CONFIG_SUCCESS:
      return {
        ...state,
        isGettingPaymentMethodConfig: false,
        paymentMethodConfig: action.payload.paymentMethodConfig,
      };
    case ActionType.GET_PAYMENT_METHOD_CONFIG_FAILURE:
      return {
        ...state,
        isGettingPaymentMethodConfig: false,
        paymentMethodConfig: undefined,
      };

    case ActionType.CLEAR_DEAL:
      return {
        ...state,
        deal: undefined,
      };
    case ActionType.GET_DEAL_REQUEST:
      return {
        ...state,
        isGettingDeal: true,
        deal: undefined,
      };
    case ActionType.GET_DEAL_SUCCESS:
      return {
        ...state,
        isGettingDeal: false,
        deal: action.payload.deal,
      };
    case ActionType.GET_DEAL_FAILURE:
      return {
        ...state,
        isGettingDeal: false,
        deal: undefined,
      };

    case ActionType.CLEAR_CUSTOMER:
      return {
        ...state,
        customer: undefined,
      };
    case ActionType.GET_CUSTOMER_REQUEST:
      return {
        ...state,
        isGettingCustomer: true,
        customer: undefined,
      };
    case ActionType.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        isGettingCustomer: false,
        customer: action.payload.customer,
      };
    case ActionType.GET_CUSTOMER_FAILURE:
      return {
        ...state,
        isGettingCustomer: false,
        customer: undefined,
      };

    case ActionType.CLEAR_INVOICES:
      return {
        ...state,
        invoices: {},
      };
    case ActionType.GET_INVOICE_SUCCESS: {
      const fetchedEntity =
        state.invoices && state.invoices[action.payload.invoiceId];

      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.payload.invoiceId]: {
            ...fetchedEntity,
            entity: action.payload.invoice,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.CLEAR_PROPOSALS:
      return {
        ...state,
        proposals: {},
      };
    case ActionType.GET_PROPOSAL_SUCCESS: {
      const fetchedEntity =
        state.proposals && state.proposals[action.payload.proposalId];

      return {
        ...state,
        proposals: {
          ...state.proposals,
          [action.payload.proposalId]: {
            ...fetchedEntity,
            entity: action.payload.proposal,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.GET_JOB_CONTENTS_REQUEST:
      return {
        ...state,
        isGettingJobContents: true,
      };
    case ActionType.GET_JOB_CONTENTS_SUCCESS:
    case ActionType.GET_JOB_CONTENTS_FAILURE:
      return {
        ...state,
        isGettingJobContents: false,
      };

    case ActionType.CLEAR_JOB_CONTENTS:
      return {
        ...state,
        jobContents: {},
      };
    case ActionType.GET_JOB_CONTENT_SUCCESS: {
      const fetchedEntity =
        state.jobContents && state.jobContents[action.payload.jobContentId];

      return {
        ...state,
        jobContents: {
          ...state.jobContents,
          [action.payload.jobContentId]: {
            ...fetchedEntity,
            entity: action.payload.jobContent,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.CLEAR_JOB_PAYMENTS:
      return {
        ...state,
        jobPayments: {},
      };
    case ActionType.GET_JOB_PAYMENT_SUCCESS: {
      const fetchedEntity =
        state.jobPayments && state.jobPayments[action.payload.jobPaymentId];

      return {
        ...state,
        jobPayments: {
          ...state.jobPayments,
          [action.payload.jobPaymentId]: {
            ...fetchedEntity,
            entity: action.payload.jobPayment,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.CLEAR_PAYMENT_RECONCILIATION_RECORDS:
      return {
        ...state,
        paymentReconciliationRecords: {},
      };
    case ActionType.GET_PAYMENT_RECONCILIATION_RECORD_SUCCESS: {
      const fetchedEntity =
        state.paymentReconciliationRecords &&
        state.paymentReconciliationRecords[
          action.payload.paymentReconciliationRecordId
        ];

      return {
        ...state,
        paymentReconciliationRecords: {
          ...state.paymentReconciliationRecords,
          [action.payload.paymentReconciliationRecordId]: {
            ...fetchedEntity,
            entity: action.payload.paymentReconciliationRecord,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.CLEAR_JOB_LINE_ITEMS:
      return {
        ...state,
        jobLineItems: {},
      };
    case ActionType.GET_JOB_LINE_ITEM_SUCCESS: {
      const fetchedEntity =
        state.jobLineItems && state.jobLineItems[action.payload.jobLineItemId];

      return {
        ...state,
        jobLineItems: {
          ...state.jobLineItems,
          [action.payload.jobLineItemId]: {
            ...fetchedEntity,
            entity: action.payload.jobLineItem,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.CLEAR_JOB_LINE_ITEM_GROUPS:
      return {
        ...state,
        jobLineItemGroups: {},
      };
    case ActionType.GET_JOB_LINE_ITEM_GROUP_SUCCESS: {
      const fetchedEntity =
        state.jobLineItemGroups &&
        state.jobLineItemGroups[action.payload.jobLineItemGroupId];

      return {
        ...state,
        jobLineItemGroups: {
          ...state.jobLineItemGroups,
          [action.payload.jobLineItemGroupId]: {
            ...fetchedEntity,
            entity: action.payload.jobLineItemGroup,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.CLEAR_INVOICE_LINES:
      return {
        ...state,
        invoiceLines: {},
      };
    case ActionType.GET_INVOICE_LINE_SUCCESS: {
      const fetchedEntity =
        state.invoiceLines && state.invoiceLines[action.payload.invoiceLineId];

      return {
        ...state,
        invoiceLines: {
          ...state.invoiceLines,
          [action.payload.invoiceLineId]: {
            ...fetchedEntity,
            entity: action.payload.invoiceLine,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.CREATE_STRIPE_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        isCreatingStripePaymentIntent: true,
      };
    case ActionType.CREATE_STRIPE_PAYMENT_INTENT_FAILURE:
    case ActionType.CREATE_STRIPE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        isCreatingStripePaymentIntent: false,
      };

    case ActionType.CANCEL_STRIPE_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        isCancellingStripePaymentIntent: true,
      };
    case ActionType.CANCEL_STRIPE_PAYMENT_INTENT_FAILURE:
    case ActionType.CANCEL_STRIPE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        isCancellingStripePaymentIntent: false,
      };

    case ActionType.CREATE_PAYMENT_RECORD_REQUEST:
      return {
        ...state,
        isCreatingPaymentRecord: true,
      };
    case ActionType.CREATE_PAYMENT_RECORD_FAILURE:
    case ActionType.CREATE_PAYMENT_RECORD_SUCCESS:
      return {
        ...state,
        isCreatingPaymentRecord: false,
      };

    case ActionType.CLEAR_CHANGE_PROPOSALS:
      return {
        ...state,
        changeProposals: {},
      };
    case ActionType.GET_CHANGE_PROPOSAL_SUCCESS: {
      const fetchedEntity =
        state.changeProposals &&
        state.changeProposals[action.payload.changeProposal.id];

      return {
        ...state,
        changeProposals: {
          ...state.changeProposals,
          [action.payload.changeProposal.id]: {
            ...fetchedEntity,
            entity: action.payload.changeProposal,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.GET_CHANGE_PROPOSALS_SUCCESS: {
      const changeProposals = { ...state.changeProposals };

      action.payload.changeProposals.forEach(
        (changeProposal: ClientChangeProposal) => {
          const fetchedEntity =
            changeProposals && changeProposals[changeProposal.id];

          changeProposals[changeProposal.id] = {
            ...fetchedEntity,
            entity: changeProposal,
            fetchSucceededAt: new Date(),
            isFetching: false,
          };
        }
      );

      return {
        ...state,
        changeProposals,
      };
    }

    case ActionType.GET_JOB_LINE_ITEMS_SUCCESS:
    case ActionType.GET_JOB_LINE_ITEMS_FOR_INVOICE_SUCCESS: {
      const jobLineItems = { ...state.jobLineItems };

      action.payload.jobLineItems.forEach((jobLineItem: JobLineItem) => {
        const fetchedEntity = jobLineItems && jobLineItems[jobLineItem.id];

        jobLineItems[jobLineItem.id] = {
          ...fetchedEntity,
          entity: jobLineItem,
          fetchSucceededAt: new Date(),
          isFetching: false,
        };
      });

      return {
        ...state,
        jobLineItems,
      };
    }

    default:
      return state;
  }
};

export default customerReducer;
