import { Tag, TaggableEntityType } from '@payaca/types/tagTypes';
import { action } from 'typesafe-actions';

import { ActionType } from './tagsTypes';

export const requestGetTags = () => {
  return action(ActionType.REQUEST_GET_TAGS);
};

export const getTagsSuccess = (tags: Tag[]) => {
  return action(ActionType.GET_TAGS_SUCCESS, {
    tags,
  });
};

export const getTagsFailure = (error: Error) => {
  return action(ActionType.GET_TAGS_FAILURE, null, null, error);
};

export const clearTags = () => {
  return action(ActionType.CLEAR_TAGS);
};

export const requestRemoveTagFromEntity = (
  tagId: number,
  entityId: number,
  entityType: TaggableEntityType,
  callback?: () => void
) => {
  return action(ActionType.REQUEST_REMOVE_TAG_FROM_ENTITY, {
    tagId,
    entityId,
    entityType,
    callback,
  });
};

export const removeTagFromEntitySuccess = () => {
  return action(ActionType.REMOVE_TAG_FROM_ENTITY_SUCCESS);
};

export const removeTagFromEntityFailure = (error: Error) => {
  return action(ActionType.REMOVE_TAG_FROM_ENTITY_FAILURE, null, null, error);
};

export const requestAddExistingTagToEntity = (
  tagId: number,
  entityId: number,
  entityType: TaggableEntityType,
  callback?: () => void
) => {
  return action(ActionType.REQUEST_ADD_EXISTING_TAG_TO_ENTITY, {
    tagId,
    entityId,
    entityType,
    callback,
  });
};

export const addExistingTagToEntitySuccess = () => {
  return action(ActionType.ADD_EXISTING_TAG_TO_ENTITY_SUCCESS);
};

export const addExistingTagToEntityFailure = (error: Error) => {
  return action(
    ActionType.ADD_EXISTING_TAG_TO_ENTITY_FAILURE,
    null,
    null,
    error
  );
};
