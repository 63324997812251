import { FC, useState } from 'react';

import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';

import ScheduledEventChip from '../scheduledEventChip/ScheduledEventChip';
import ScheduledEventReadDrawer from '../scheduledEventReadDrawer/ScheduledEventReadDrawer';

type Props = {
  scheduledEvent: ScheduledEvent;
  onScheduledEventUpdateSuccess?: () => void;
  showLocation?: boolean;
};
const UpcomingScheduledEvent: FC<Props> = ({
  scheduledEvent,
  onScheduledEventUpdateSuccess,
  showLocation,
}: Props): JSX.Element => {
  const [showScheduledEventModal, setShowScheduledEventModal] = useState(false);

  return (
    <div>
      <ScheduledEventChip
        onClick={() => setShowScheduledEventModal(true)}
        scheduledEvent={scheduledEvent}
        showLocation={showLocation}
      />

      <ScheduledEventReadDrawer
        isOpen={showScheduledEventModal}
        onClose={() => setShowScheduledEventModal(false)}
        onDeleteScheduledEventSuccess={onScheduledEventUpdateSuccess}
        onUpdateScheduledEventSuccess={() => {
          onScheduledEventUpdateSuccess && onScheduledEventUpdateSuccess();
        }}
        scheduledEventId={scheduledEvent.id}
      />
    </div>
  );
};

export default UpcomingScheduledEvent;
