import { BrandingColourContext } from '@payaca/types/accountBrandTypes';
import { FC, useMemo } from 'react';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import clsx from 'clsx';
import Conditional from '../conditional/Conditional';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';
import './PurchaseOrderBody.sass';

export type PurchaseOrderLine = {
  materialName?: string;
  thumbnailUrl?: string;
  predictedMaterialQuantity: number;
  materialQuantity?: number;
  supplierReference?: string;
  predictedUnitPrice?: number;
  predictedTotalPrice?: number;
  unitPrice?: number;
  totalPrice?: number;
  strikeThrough?: boolean;
};

type Props = {
  purchaseOrderLines: PurchaseOrderLine[];
  brandingColourContext?: BrandingColourContext;
  region?: AccountRegions;

  showMaterialPrices?: boolean;
};
const PurchaseOrderBody: FC<Props> = ({
  purchaseOrderLines,
  brandingColourContext,
  region,
  showMaterialPrices = false,
}: Props): JSX.Element | null => {
  const anyPurchaseOrderLinesHaveSupplierReference = useMemo(() => {
    return purchaseOrderLines.some((x) => !!x.supplierReference);
  }, [purchaseOrderLines]);

  const predictedTotalExcTax = useMemo(() => {
    return purchaseOrderLines.reduce((acc, line) => {
      return acc + (line.predictedTotalPrice || 0);
    }, 0);
  }, [purchaseOrderLines]);

  const totalExcTax = useMemo(() => {
    return purchaseOrderLines.reduce((acc, line) => {
      return acc + (line.totalPrice || 0);
    }, 0);
  }, [purchaseOrderLines]);

  return (
    <div className="purchase-order-body">
      <table
        className="data-table"
        style={{
          borderColor: brandingColourContext?.brandColour,
        }}
      >
        <thead>
          <tr>
            {anyPurchaseOrderLinesHaveSupplierReference && (
              <td
                style={{
                  backgroundColor: brandingColourContext?.brandColour,
                  color: brandingColourContext?.brandReadableTextColour,
                }}
                className="supplier-ref-table-cell"
              >
                Ref
              </td>
            )}
            <td
              style={{
                backgroundColor: brandingColourContext?.brandColour,
                color: brandingColourContext?.brandReadableTextColour,
              }}
            >
              Material
            </td>
            {showMaterialPrices && (
              <td
                align="right"
                style={{
                  backgroundColor: brandingColourContext?.brandColour,
                  color: brandingColourContext?.brandReadableTextColour,
                }}
              >
                Unit price (ex tax)
              </td>
            )}
            <td
              style={{
                backgroundColor: brandingColourContext?.brandColour,
                color: brandingColourContext?.brandReadableTextColour,
              }}
              className="quantity-table-cell"
            >
              Qty
            </td>
            {showMaterialPrices && (
              <td
                align="right"
                style={{
                  backgroundColor: brandingColourContext?.brandColour,
                  color: brandingColourContext?.brandReadableTextColour,
                }}
              >
                Total (ex tax)
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {purchaseOrderLines.map((purchaseOrderLine, i) => {
            return (
              <tr
                key={`purchase-order-line-${i}`}
                className={clsx(
                  purchaseOrderLine.strikeThrough && 'line-through'
                )}
              >
                {anyPurchaseOrderLinesHaveSupplierReference && (
                  <td className="supplier-ref-table-cell">
                    {purchaseOrderLine.supplierReference}
                  </td>
                )}
                <td>{purchaseOrderLine.materialName}</td>
                {showMaterialPrices && (
                  <td align="right">
                    <StrikeThroughPair
                      a={
                        purchaseOrderLine.predictedUnitPrice
                          ? currencyPrice(
                              purchaseOrderLine.predictedUnitPrice,
                              region
                            )
                          : '-'
                      }
                      b={
                        purchaseOrderLine.unitPrice
                          ? currencyPrice(purchaseOrderLine.unitPrice, region)
                          : undefined
                      }
                    />
                  </td>
                )}
                <td className="quantity-table-cell">
                  <StrikeThroughPair
                    a={purchaseOrderLine.predictedMaterialQuantity.toString()}
                    b={purchaseOrderLine.materialQuantity?.toString()}
                  />
                </td>
                {showMaterialPrices && (
                  <td align="right">
                    <StrikeThroughPair
                      a={
                        purchaseOrderLine.predictedTotalPrice
                          ? currencyPrice(
                              purchaseOrderLine.predictedTotalPrice,
                              region
                            )
                          : '-'
                      }
                      b={
                        purchaseOrderLine.totalPrice
                          ? currencyPrice(purchaseOrderLine.totalPrice, region)
                          : undefined
                      }
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="mt-4 text-right">
        <span>Total (ex tax):</span>
        <strong className="ml-4">
          <StrikeThroughPair
            a={currencyPrice(predictedTotalExcTax, region)}
            b={
              totalExcTax !== 0 ? currencyPrice(totalExcTax, region) : undefined
            }
          />
        </strong>
      </div>
    </div>
  );
};

export default PurchaseOrderBody;

const StrikeThroughPair: FC<{ a: string; b?: string }> = (props) => {
  const { a, b } = props;

  return (
    <>
      <ConditionalWrapper
        condition={!!b && a !== b}
        Wrapper={'s'}
        wrapperProps={{
          className: 'pe-2',
        }}
      >
        {a}
      </ConditionalWrapper>
      <Conditional condition={a !== b}>{b}</Conditional>
    </>
  );
};
