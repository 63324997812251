import {
  autoPlacement,
  autoUpdate,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { Popover, Transition } from '@headlessui/react';
import Conditional from '@payaca/components/conditional/Conditional';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnShapeVariant,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { useObserveIntersection } from '@payaca/hooks/useObserveIntersection';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, useCallback, useMemo, useState } from 'react';
import useMarkNotificationsAsRead from '../../../../api/mutations/notifications/useMarkNotificationsAsRead';
import useFetchNotifications, {
  useFetchUnreadNotificationCount,
} from '../../../../api/queries/notifications/useFetchNotifications';
import { useNotificationContext } from '../../contextProviders/NotificationContextProvider';
import ListedNotification, { TLinkedEntity } from './ListedNotification';

const paginationLimit = 20;

const HeaderNotificationsControl: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { unreadNotificationCount } = useNotificationContext();
  const {
    data,
    hasNextPage,
    fetchNextPage,
    refetch: refetchNotifications,
  } = useFetchNotifications({
    offset: 0,
    limit: paginationLimit,
  });
  const loaderRef = useObserveIntersection(
    () => hasNextPage && void fetchNextPage()
  );

  const { refetch: refetchNotificationCount } =
    useFetchUnreadNotificationCount();
  const { mutate: markNotificationsAsReadMutation, isLoading } =
    useMarkNotificationsAsRead({
      onSuccess: () => {
        void refetchNotifications();
        void refetchNotificationCount();
      },
    });

  const notifications = useMemo(() => {
    return data?.pages.flatMap((page) => page.me.notifications.items) || [];
  }, [data]);

  const count = useMemo(() => {
    if (unreadNotificationCount > 99) {
      return '99+';
    }
    return unreadNotificationCount;
  }, [unreadNotificationCount]);

  const markAllNotificationsAsRead = useCallback(() => {
    const notificationIds = notifications
      .filter((notification) => !notification.viewedAt)
      .map((notification) => notification.id);
    markNotificationsAsReadMutation(notificationIds);
  }, [notifications]);

  const { refs, floatingStyles, context } = useFloating({
    transform: false,
    whileElementsMounted: autoUpdate,
    middleware: [
      autoPlacement({
        allowedPlacements: ['bottom'],
      }),
      offset({
        mainAxis: 15,
      }),
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  return (
    <Popover>
      {({ open }: { open: boolean }) => (
        <>
          <Popover.Button
            as={Button}
            variant={EBtnVariant.Ghost}
            shape={EBtnShapeVariant.PILL}
            colour={EBtnColour.White}
            className="h-[40px] w-[40px] p-0"
            ref={refs.setReference}
            {...getReferenceProps()}
          >
            <UntitledIcon name="bell-01.3" className="h-6 w-6 text-blue-900" />
            {!!count && (
              <span className="absolute end-0 top-0 -me-1.5 -mt-1.5 flex">
                <span className="absolute inline-flex size-full animate-ping rounded-full bg-red-400 opacity-75 dark:bg-red-600"></span>
                <span className="relative inline-flex min-h-[18px] min-w-[18px] items-center justify-center rounded-full bg-cyan-700 px-1 text-[10px] leading-normal text-white">
                  {count}
                </span>
              </span>
            )}
          </Popover.Button>

          <Transition
            show={open}
            appear
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Popover.Panel
              static
              className="z-dropdown border-t border-gray-200 bg-white shadow-md sm:w-96 sm:rounded-lg sm:border-t-0 sm:shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              ref={refs.setFloating}
              focus={true}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {/* header */}
              <div className="flex items-center justify-between border-b border-gray-200 px-5 pt-3 dark:border-neutral-800">
                <nav>
                  <Button
                    variant={EBtnVariant.Ghost}
                    colour={EBtnColour.Gray}
                    className="relative mb-2 inline-flex items-center justify-center gap-x-2 rounded-lg px-2.5 py-1.5 text-sm text-gray-500 after:pointer-events-none after:absolute after:inset-x-2.5 after:-bottom-2 after:z-10 after:h-0.5 hover:bg-gray-100 hover:text-gray-800 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                  >
                    All
                  </Button>
                </nav>
              </div>

              {/* content */}
              <div className="h-[480px] overflow-hidden overflow-y-auto [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 [&::-webkit-scrollbar-track]:bg-gray-100 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 [&::-webkit-scrollbar]:w-2">
                <ul className="divide-y divide-gray-200 p-0 dark:divide-neutral-800">
                  {notifications.map((notification) => (
                    <ListedNotification
                      key={notification.id}
                      markNotificationAsRead={() =>
                        markNotificationsAsReadMutation([notification.id])
                      }
                      {...notification}
                      linkedEntity={notification.linkedEntity as TLinkedEntity}
                    />
                  ))}
                  <Conditional condition={hasNextPage}>
                    <div ref={loaderRef}>
                      <SkeletonLoader.Notification />
                    </div>
                  </Conditional>
                </ul>
              </div>

              {/* footer */}
              <div className="cursor-pointer border-t border-gray-200 text-center dark:border-neutral-800">
                <Button
                  variant={EBtnVariant.LinkInline}
                  className="w-full p-4 !no-underline"
                  onClick={() => markAllNotificationsAsRead()}
                >
                  Mark all as read
                </Button>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default HeaderNotificationsControl;
