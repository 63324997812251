import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { useSelector } from '@/api/state';
import { getUserRoles } from '@/utils/stateAccessors';
import { useAccountUsers } from '@/utils/storeHooks';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import ButtonRadioGroup from '@payaca/components/plButtonRadioGroup/ButtonRadioGroup';
import { InputSizeVariant } from '@payaca/components/plInput/Input';
import InputGroup from '@payaca/components/plInputGroup/InputGroup';
import Select, {
  SelectOption,
  SelectSizeVariant,
} from '@payaca/components/plSelect/Select';
import Tooltip, {
  TooltipPositionVariant,
} from '@payaca/components/plTooltip/Tooltip';
import Tag from '@payaca/components/tag/Tag';
import TagSelectCombobox from '@payaca/components/tagSelectCombobox/TagSelectCombobox';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { PipelinesPermissions } from '@payaca/permissions/pipelines/pipelines.permissions';
import { usePipelines } from '@payaca/store/hooks/appState';
import {
  DealSearchTarget,
  ListedDealViews,
} from '@payaca/types/listedDealTypes';
import UntitledIcon from '@payaca/untitled-icons';
import { isNullish } from '@payaca/utilities/guards';
import { Nullable } from '@payaca/utilities/types';
import { Link } from 'react-router-dom';
import { ProjectsContext } from '../contextProviders/ProjectsContextProvider';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { PipelineBadge } from '../pipelineBadge/PipelineBadge';

const ProjectFilters: FC = () => {
  const { onUpdate, createDeal } = useContext(ProjectsContext);
  return (
    <div className="flex w-full flex-col items-baseline gap-4">
      <div className="flex w-full flex-row flex-wrap gap-4">
        <div className="flex grow flex-row flex-wrap items-start gap-2">
          <PermissionGuard
            renderIfHasPermissions={[PipelinesPermissions.EDIT_PIPELINE]}
          >
            <div className="shrink grow-0">
              <CustomizePipelineButton />
            </div>
          </PermissionGuard>
          <div className="min-w-0 shrink-0 grow-0 basis-40">
            <PipelineFilter />
          </div>
          <div className="min-w-0 shrink-0 grow-0 basis-40">
            <PipelineStagesFilter />
          </div>
          <div className="min-w-0 shrink-0 grow-0 basis-40">
            <AssignedUserFilter />
          </div>
          <div className="min-w-0 shrink-0 grow-0 basis-40">
            <TagsFilter />
          </div>
        </div>
        <div className="ml-auto flex shrink grow-0 flex-row items-start gap-2">
          <Button
            size={EBtnSize.Small}
            variant={EBtnVariant.White}
            onClick={() => {
              onUpdate({
                tagIds: undefined,
                assignedUserIds: undefined,
                searchTerm: undefined,
                searchTarget: undefined,
                pipelineStages: undefined,
                displayType: ListedDealViews.TABLE,
                pipelineId: undefined,
              });
            }}
          >
            View all
          </Button>
          <DisplayTypeControl />
          <Button
            size={EBtnSize.Small}
            onClick={createDeal}
            colour={EBtnColour.Blue}
          >
            Create Project
          </Button>
        </div>
      </div>
      <div className="w-full max-w-[450px]">
        <Search />
      </div>
    </div>
  );
};

const PipelineFilter: FC = () => {
  const {
    onUpdate,
    requestData: { pipelineId },
    displayType,
    createPipeline,
  } = useContext(ProjectsContext);

  const pipelines = usePipelines();

  const userRoles = useSelector(getUserRoles);

  const pipelineDropdownOptions = useMemo(() => {
    const options: SelectOption<Nullable<number>>[] = [
      ...pipelines.map((p) => ({
        label: p.title,
        value: p.id,
      })),
    ];

    if (displayType === ListedDealViews.TABLE) {
      options.unshift({
        value: null,
        label: 'All projects',
      });
    }

    if (
      userHasRequiredPermission(userRoles, [PipelinesPermissions.ADD_PIPELINE])
    ) {
      options.push({
        label: 'Create custom pipeline',
        value: -1,
      });
    }

    return options;
  }, [pipelines, userRoles, displayType]);

  return (
    <PermissionGuard
      renderIfHasPermissions={[PipelinesPermissions.GET_PIPELINES_FOR_ACCOUNT]}
    >
      <Select
        sizeVariant={SelectSizeVariant.SM}
        value={isNullish(pipelineId) ? null : pipelineId}
        onChange={(value) => {
          if (value === -1) {
            createPipeline();
          } else {
            onUpdate({
              pipelineId: value === null ? undefined : +value,
              pipelineStages: undefined,
            });
          }
        }}
        options={pipelineDropdownOptions}
        CustomOption={(option) => {
          if (option.option.value === 0) {
            return <PipelineBadge />;
          } else if (
            option.option.value === null ||
            option.option.value === -1
          ) {
            return <span>{option.option.label}</span>;
          } else {
            return <PipelineBadge pipelineId={option.option.value} />;
          }
        }}
        CustomSelected={({ selectedOptions }) => {
          const option = selectedOptions?.length ? selectedOptions[0] : null;
          if (!option) return null;

          if (option.value === 0) {
            return <PipelineBadge />;
          } else if (option.value === null) {
            return <>{option.label}</>;
          } else {
            return <PipelineBadge pipelineId={option.value} />;
          }
        }}
        placeholder={'Pipeline'}
      />
    </PermissionGuard>
  );
};

const PipelineStagesFilter: FC = () => {
  const {
    onUpdate,
    requestData: { pipelineStages, pipelineId },
  } = useContext(ProjectsContext);

  const pipelines = usePipelines();
  const pipeline = pipelines.find((p) => p.id === pipelineId);

  const pipelineStageDropdownOptions = useMemo(() => {
    if (!pipeline) {
      const stages = pipelines.flatMap((p) => p.stages.map((x) => x.title));

      return [...new Set(stages)].map((s) => ({
        label: s,
        value: s,
      }));
    } else {
      return pipeline.stages.map((s) => ({
        label: s.title,
        value: s.title,
      }));
    }
  }, [pipeline, pipelines]);

  return (
    <PermissionGuard
      renderIfHasPermissions={[PipelinesPermissions.GET_PIPELINES_FOR_ACCOUNT]}
    >
      <Select
        sizeVariant={SelectSizeVariant.SM}
        value={pipelineStages}
        onChange={(value) => {
          onUpdate({ pipelineStages: value });
        }}
        multiple
        options={pipelineStageDropdownOptions}
        placeholder={'Stage'}
        CustomSelected={({ selectedOptions }) => {
          if (selectedOptions.length > 1) {
            return <span>Stage - {selectedOptions.length} selected</span>;
          }
          return <span>{selectedOptions?.[0]?.label}</span>;
        }}
      />
    </PermissionGuard>
  );
};

const AssignedUserFilter: FC = () => {
  const {
    onUpdate,
    requestData: { assignedUserIds },
  } = useContext(ProjectsContext);

  const accountUsers = useAccountUsers();
  const userAssignmentOptions = useMemo(() => {
    const options: { label: string; value: any }[] = accountUsers
      .filter((x: any) => {
        return !x.deactivatedAt || x.inviteToken;
      })
      .map((accountUser: any) => {
        return {
          label: `${accountUser.firstname} ${accountUser.lastname}`,
          value: accountUser.id,
        };
      });

    options.unshift({
      label: 'Unassigned',
      value: -1,
    });

    return options;
  }, [accountUsers]);

  return (
    <PermissionGuard
      renderIfHasPermissions={[
        AccountsPermissions.GET_USERS,
        DealsPermissions.GET_LISTED_DEALS,
      ]}
      requireAllPermissions={true}
    >
      <Select
        sizeVariant={SelectSizeVariant.SM}
        options={userAssignmentOptions}
        multiple
        placeholder="Owner"
        value={assignedUserIds || []}
        onChange={(value) => onUpdate({ assignedUserIds: value })}
        CustomSelected={({ selectedOptions }) => {
          if (selectedOptions.length > 1) {
            return <span>Owner - {selectedOptions.length} selected</span>;
          }
          return <span>{selectedOptions?.[0]?.label}</span>;
        }}
      />
    </PermissionGuard>
  );
};

const TagsFilter: FC = () => {
  const {
    onUpdate,
    requestData: { tagIds },
  } = useContext(ProjectsContext);

  const availableTags = useSelector((state) => state.tags.tags);

  return (
    <TagSelectCombobox
      sizeVariant={SelectSizeVariant.SM}
      availableTags={availableTags}
      multiple
      placeholder="Tags"
      value={tagIds || []}
      onChange={(value) => {
        onUpdate({ tagIds: value });
      }}
      CustomSelected={({ selectedOptions }) => {
        if (selectedOptions.length === 0) return null;
        if (selectedOptions.length > 1) {
          return <span>Tags - {selectedOptions.length} selected</span>;
        }
        return (
          <span className="align-center flex">
            <Tag
              tagText={selectedOptions?.[0]?.metadata?.tagText || ''}
              colour={selectedOptions?.[0]?.metadata?.tagColour}
            />
          </span>
        );
      }}
    />
  );
};

const searchTargetOptions: { label: string; value: DealSearchTarget | null }[] =
  [
    {
      label: 'Search all',
      value: null,
    },
    {
      label: 'Project',
      value: 'deal',
    },
    {
      label: 'Site addresses',
      value: 'dealAddresses',
    },
    {
      label: 'Customer',
      value: 'customer',
    },
    {
      label: 'Proposal ref',
      value: 'proposals',
    },
    {
      label: 'Change Proposal ref',
      value: 'changeProposals',
    },
    {
      label: 'Invoice ref',
      value: 'invoices',
    },
  ];

const Search: FC = () => {
  const {
    onUpdate,
    isLoading,
    requestData: { searchTarget, searchTerm: externalSearchTerm },
  } = useContext(ProjectsContext);

  const [searchTerm, setSearchTerm] = useState(externalSearchTerm);

  useEffect(() => {
    setSearchTerm(externalSearchTerm);
  }, [externalSearchTerm]);

  return (
    <InputGroup sizeVariant={InputSizeVariant.SM}>
      <InputGroup.Select
        options={searchTargetOptions}
        value={searchTarget || null}
        onChange={(value) => {
          onUpdate({
            searchTarget: value ? (value as DealSearchTarget) : undefined,
          });
        }}
        className="shrink-0 grow-0 basis-40"
      />
      <InputGroup.Input
        value={searchTerm}
        onChange={setSearchTerm}
        placeholder="Search"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onUpdate({ searchTerm });
          }
        }}
      />
      {!!searchTerm && (
        <InputGroup.Button
          variant={EBtnVariant.White}
          disabled={isLoading}
          onClick={() => {
            setSearchTerm('');
            onUpdate({ searchTerm: '' });
          }}
        >
          <UntitledIcon className="h-3 w-3" name="x-close" />
        </InputGroup.Button>
      )}
      <InputGroup.Button
        variant={EBtnVariant.White}
        onClick={() => onUpdate({ searchTerm })}
        disabled={isLoading}
        isProcessing={isLoading}
        replaceContentWhenProcessing
      >
        <UntitledIcon className="h-4 w-4" name="search-lg.3" />
      </InputGroup.Button>
    </InputGroup>
  );
};

const DisplayTypeControl: FC = () => {
  const {
    onUpdate,
    displayType,
    requestData: { pipelineId },
  } = useContext(ProjectsContext);

  const pipelines = usePipelines();

  return (
    <ButtonRadioGroup
      value={displayType}
      onChange={(displayType) => {
        if (displayType === ListedDealViews.PIPELINE && isNullish(pipelineId)) {
          onUpdate({ displayType, pipelineId: pipelines[0].id });
        } else {
          onUpdate({ displayType });
        }
      }}
      options={[
        { label: 'Pipeline', value: ListedDealViews.PIPELINE },
        { label: 'Table', value: ListedDealViews.TABLE },
      ]}
      sizeVariant={EBtnSize.Small}
      CustomOption={({ option }) => {
        return (
          <UntitledIcon
            className="h-6 w-6"
            name={
              option.value == ListedDealViews.PIPELINE ? 'columns-03.3' : 'list'
            }
          />
        );
      }}
    />
  );
};

const CustomizePipelineButton: FC = () => {
  const {
    requestData: { pipelineId },
  } = useContext(ProjectsContext);

  return (
    <>
      {/* if pipelineid is zero or undefined... */}
      {!pipelineId ? (
        <Tooltip
          tooltipContent={
            <div style={{ maxWidth: 100 }}>
              To edit pipeline stages, choose or create a custom pipeline
            </div>
          }
          showArrow
          positionVariant={TooltipPositionVariant.BOTTOM}
        >
          <Button
            size={EBtnSize.Small}
            variant={EBtnVariant.White}
            disabled={true}
          >
            <UntitledIcon className="h-5 w-5" name="settings-01.3" />
          </Button>
        </Tooltip>
      ) : (
        <Link to={`/deals/pipeline/${pipelineId}`} tabIndex={-1}>
          <Button
            size={EBtnSize.Small}
            variant={EBtnVariant.White}
            disabled={false}
          >
            <UntitledIcon className="h-5 w-5" name="settings-01.3" />
          </Button>
        </Link>
      )}
    </>
  );
};

export default ProjectFilters;
