import { DocumentsPermissions } from '@payaca/permissions/documents/documents.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { DocumentActionType } from '@payaca/types/documentActionsTypes';
import { Document } from '@payaca/types/documentTypes';

export const getAvailableActionsForDocument = (
  userRoles: string[],
  document?: Document
): DocumentActionType[] => {
  const documentActionTypes: DocumentActionType[] = [];

  if (!document || !!document?.archivedAt) {
    return documentActionTypes;
  }

  documentActionTypes.push(DocumentActionType.PRINT);

  if (document?.revisedAt) {
    return documentActionTypes;
  }

  documentActionTypes.push(DocumentActionType.EDIT);
  if (
    userHasRequiredPermission(userRoles, [DocumentsPermissions.SEND_DOCUMENT])
  ) {
    documentActionTypes.push(DocumentActionType.SEND_A_COPY);
  }
  return documentActionTypes;
};
