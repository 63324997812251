import { FC, useMemo } from 'react';

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PopupMenu from '../popupMenu/PopupMenu';

type Props = {
  anchor: any;
  isOpen: boolean;
  quickActions: {
    actionName: string;
    actionBehaviour: (recordId: any) => void;
    isActionProcessing?: boolean;
  }[];
  onClose: () => void;
  recordId: any;
  renderDisabledIfNoActions?: boolean;
};
const QuickAction: FC<Props> = ({
  anchor,
  isOpen,
  quickActions,
  onClose,
  recordId,
  renderDisabledIfNoActions = false,
}: Props): JSX.Element => {
  const popupMenuOptions = useMemo(() => {
    return quickActions.map((quickAction) => {
      return {
        name: quickAction.actionName,
        onClick: () => {
          quickAction.actionBehaviour(recordId);
          onClose();
        },
        isProcessing: quickAction.isActionProcessing || false,
      };
    });
  }, [quickActions, recordId, onClose]);

  if (!!quickActions.length || renderDisabledIfNoActions) {
    return (
      <>
        <FontAwesomeIcon icon={faEllipsisV} />
        {!!quickActions.length && (
          <PopupMenu
            options={popupMenuOptions}
            isOpen={isOpen}
            onClose={onClose}
            anchorElement={anchor.current}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default QuickAction;
