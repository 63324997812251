import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  DeleteProposalLineItemMutation,
  ProposalLineItemIdInput,
} from '@/gql/graphql';

const DELETE_PROPOSAL_LINE_ITEM_MUTATION = graphql(`
  mutation DeleteProposalLineItem($input: ProposalLineItemIdInput!) {
    deleteProposalLineItem(input: $input)
  }
`);

const useDeleteProposalLineItem = (
  options?: UseMutationOptions<
    DeleteProposalLineItemMutation,
    unknown,
    ProposalLineItemIdInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(DELETE_PROPOSAL_LINE_ITEM_MUTATION, { input });
    },
    ...options,
  });
};

export default useDeleteProposalLineItem;
