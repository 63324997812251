import moment from 'moment-timezone';
import { FC, useMemo } from 'react';

import CompanyLogo from '../companyLogo/CompanyLogo';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import { useCommonFormAccountData } from './hooks';

import { isNotNullish } from '@payaca/utilities/guards';

import './DomesticElectricalInstallationConditionReport.sass';
import './FormTemplateElements.sass';

import NiceicLogo from './niceic-logo.png';

interface Props {
  data: any;
  account: any;
}

const createAddressString = (addressComponents: any) =>
  [
    addressComponents.line_1,
    addressComponents.line_2,
    addressComponents.line_3,
    addressComponents.town,
    addressComponents.county,
    addressComponents.postcode,
  ]
    .filter(Boolean)
    .join(', ');
const defaultToNa = (value: string) =>
  isNotNullish(value) && value !== '' ? value : 'N/A';

const DomesticElectricalInstallationCertificate: FC<Props> = ({
  data,
  account,
}) => {
  const { brandColour } = useCommonFormAccountData(account);

  const certificateNumber = useMemo(() => {
    if (data) {
      return `${data.serial_no}${data.revision ? `-${data.revision}` : ''}`;
    }
    return '';
  }, [data]);

  if (!data || !account) return <></>;

  const contractorAddressString = createAddressString(data.contractor_address);
  const clientAddressString = createAddressString(data.client_address);
  const installationAddressString = createAddressString(
    data.installation_address
  );

  return (
    <div
      className={
        'form-template-output minor-electrical-works-certificate-template'
      }
    >
      <div className={'form-template-header'}>
        <div>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3>
              Domestic Electrical Installation Certificate (Single phase, up to
              100A)
            </h3>
            <p>
              Issued in accordance with BS 7671, as amended - Requirements for
              Electrical Installations
            </p>
          </div>
        </div>
        <div className={'flex-container flex-center'}>
          <div className="header-details-container">
            <LabelValuePair label="Cert No" value={certificateNumber} />
          </div>
        </div>
        {data.niceic_reg_number && (
          <img className="niceic-logo" src={NiceicLogo} alt={'NICEIC Logo'} />
        )}
      </div>
      <div className="form-template-row company-inspection-landlord-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          className="company-details-content-panel"
          title={'PART 1a: Details of approved contractor'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="company-details-content-panel-body">
            <LabelValuePair
              label="Registration number"
              value={data.contractor_reg_no}
            />
            <LabelValuePair
              label="Trading Title"
              value={data.contractor_trading_title}
            />
            {data.niceic_reg_number && (
              <LabelValuePair
                label="NICEIC Reg. Number"
                value={data.niceic_reg_number}
              />
            )}
            <LabelValuePair label="Address" value={contractorAddressString} />
            <LabelValuePair label="Tel No." value={data?.contractor_tel_no} />
          </div>
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 1b: Details of the client'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Contractor Reference Number (CRN)"
            value={data.client_contractor_ref_no}
          />
          <LabelValuePair label="Name" value={data.client_name} />
          <LabelValuePair label="Address" value={clientAddressString} />
          <LabelValuePair label="Tel No." value={data.client_tel_no} />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 1c: Details of the installation'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Occupier" value={`${data.occupier_name}`} />
          <LabelValuePair label="Address" value={installationAddressString} />
          <LabelValuePair label="Tel No." value={data.occupier_tel_no} />
        </OutlinedContentPanel>
      </div>

      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 3: Next inspection of the electrical installation"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="I RECOMMEND that this installation is further inspected and tested after an interval of not more than"
            value={`${data.next_inspection_quantity} ${data.next_inspection_interval}`}
          />
        </OutlinedContentPanel>
      </div>

      <div className="form-template-row test-results-declaration no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 4: Declaration for thr electrical installation work'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
          className="signatures"
        >
          <div className="label-values-in-row-title">
            Design, construction, inspection & testing
          </div>
          <p>
            I, being the person responsible for the design, construction,
            inspection and testing of the electrical installation, particulars
            of which are described in PART 2, having exercised reasonable skill
            and care when carrying out the design and additionally where this
            certificate applies to an alteration or addition, having confirmed
            that the safety of the existing installation is not impaired, hereby
            CERTIFY that the design, construction, inspection and testing for
            which I have been responsible is to the best of my knowledge and
            belief in accordance with BS 7671: 2018, as amended, except for the
            departures, if any, identified.
          </p>
          <div className="label-values-in-row">
            <LabelValuePair
              label="Name"
              value={data.declaration_engineer_name.toLocaleUpperCase()}
            />
            <LabelValuePair
              label="Signature"
              value={
                <div className={'form-template-signature'}>
                  {data.declaration_engineer_signature && (
                    <img src={data.declaration_engineer_signature} />
                  )}
                </div>
              }
            />
            <LabelValuePair
              label="Date"
              value={moment(data.declaration_engineer_date).format(
                'DD/MM/YYYY'
              )}
            />
          </div>

          <div className="label-values-in-row-title">
            Reviewed by qualified supervisor
          </div>
          <div className="label-values-in-row">
            <LabelValuePair
              label="Name"
              value={data.declaration_reviewer_name?.toLocaleUpperCase()}
            />
            <LabelValuePair
              label="Signature"
              value={
                <div className={'form-template-signature'}>
                  {data.declaration_reviewer_signature && (
                    <img src={data.declaration_reviewer_signature} />
                  )}
                </div>
              }
            />
            <LabelValuePair
              label="Date"
              value={moment(data.declaration_reviewer_date).format(
                'DD/MM/YYYY'
              )}
            />
          </div>
        </OutlinedContentPanel>
      </div>

      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 5: Comments on the existing installation"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          {/* <LabelValuePair
            label="I RECOMMEND that this installation is further inspected and tested after an interval of not more than"
            value={`${data.next_inspection_interval.value} ${data.next_inspection_interval.unit}`}
          /> */}
        </OutlinedContentPanel>
      </div>

      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 6: Supply characteristics and earthing arrangements"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="columns-3">
            <LabelValuePair
              label="System type and earthing arrangements"
              value={data.supply_characteristics_system_type}
            />
            <LabelValuePair
              label="Other (state)"
              value={data.supply_characteristics_system_type_other}
            />
            <section className="no-column-break">
              <h2>Supply protective device</h2>
              <LabelValuePair
                label="BS (EN)"
                value={
                  data.supply_characteristics_supplier_protective_device_bsen
                }
              />
              <LabelValuePair
                label="Type"
                value={
                  data.supply_characteristics_supplier_protective_device_type
                }
              />
              <LabelValuePair
                label="Rated current"
                value={`${data.supply_characteristics_supplier_protective_device_rated_current} A`}
              />
            </section>

            <LabelValuePair
              label="Number and type of live conductors (AC)"
              value={data.supply_characteristics_number_type_live_conductors}
            />

            <LabelValuePair
              label="Other (state)"
              value={
                data.supply_characteristics_number_type_live_conductors_other
              }
            />
            <LabelValuePair
              label="Confirmation of supply polarity"
              value={data.supply_characteristics_supply_polarity_confirmation}
            />
            {!!data.supply_characteristics_other_sources_of_supply?.length && (
              <section className="no-column-break">
                <h2>Other sources of supply</h2>

                {data.supply_characteristics_other_sources_of_supply?.map(
                  (x: any, i: number) => (
                    <LabelValuePair
                      key={`supply-characteristics-other-sources-of-supply-${i}`}
                      label={`${i + 1}`}
                      value={x.description}
                    />
                  )
                )}
              </section>
            )}
            <section className="no-column-break">
              <h2>Nature of supply parameters</h2>

              <LabelValuePair
                label="Nominal line voltage to Earth, U0 (V)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .nominal_line_voltage_to_earth
                }
              />
              <LabelValuePair
                label="Nominal frequency, f (Hz)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .nominal_frequency
                }
              />
              <LabelValuePair
                label="Prospective fault current, Ipf (kA)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .prospective_fault_current
                }
              />
              <LabelValuePair
                label="External loop impedance, Ze (Ohms)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .external_loop_impedance
                }
              />
              <LabelValuePair
                label="Tick if Ze is by Enquiry"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .by_enquiry
                    ? '✓'
                    : '✗'
                }
              />
            </section>
          </div>
        </OutlinedContentPanel>
      </div>

      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 7: Particulars of installation referred to in this report"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="columns-4">
            <section className="no-column-break">
              <h2>Means of Earthing</h2>

              <LabelValuePair
                label="Means of Earthing"
                value={data.particulars_means_of_earthing}
              />
              <LabelValuePair
                label="Type"
                value={data.particulars_earth_electrode.type}
              />
              <LabelValuePair
                label="Location"
                value={data.particulars_earth_electrode.location}
              />
              <LabelValuePair
                label="Electrode resistance to Earth"
                value={data.particulars_earth_electrode.resistance}
              />
            </section>
            <section className="no-column-break">
              <h2>Main protective conductors</h2>
              <LabelValuePair
                label="Material"
                value={
                  data.particulars_main_protective_conductors_earthing_conductor
                    .material
                }
              />
              <LabelValuePair
                label="CSA"
                value={
                  data.particulars_main_protective_conductors_earthing_conductor
                    .csa
                }
              />
              <LabelValuePair
                label="Connection / continuity verified"
                value={
                  data.particulars_main_protective_conductors_earthing_conductor
                    .connection_verified
                }
              />
            </section>
            <section className="no-column-break">
              <h2>Main protective bonding conductors</h2>

              <LabelValuePair
                label="Material"
                value={
                  data
                    .particulars_main_protective_conductors_main_protective_bonding_conductors
                    .material
                }
              />
              <LabelValuePair
                label="CSA"
                value={
                  data
                    .particulars_main_protective_conductors_main_protective_bonding_conductors
                    .csa
                }
              />
              <LabelValuePair
                label="Connection / continuity verified"
                value={
                  data
                    .particulars_main_protective_conductors_main_protective_bonding_conductors
                    .connection_verified
                }
              />
            </section>
            <section className="no-column-break">
              <h2>Main protective bonding connections</h2>
              <LabelValuePair
                label="Water installation pipes"
                value={
                  data.particulars_main_protective_bonding_connections.water
                }
              />
              <LabelValuePair
                label="Gas installation pipes"
                value={data.particulars_main_protective_bonding_connections.gas}
              />
              <LabelValuePair
                label="Structural steel"
                value={
                  data.particulars_main_protective_bonding_connections.steel
                }
              />
              <LabelValuePair
                label="Oil installation pipes"
                value={data.particulars_main_protective_bonding_connections.oil}
              />
              <LabelValuePair
                label="Lightning protection"
                value={
                  data.particulars_main_protective_bonding_connections.lightning
                }
              />
              <LabelValuePair
                label="Other"
                value={
                  data.particulars_main_protective_bonding_connections.other
                }
              />
            </section>
            <section className="no-column-break">
              <h2>Main switch / Switch-fuse / Circuit-breaker / RCD</h2>

              <LabelValuePair
                label="Type (BS (EN))"
                value={data.particulars_main_switch_type}
              />
              <LabelValuePair
                label="Location"
                value={data.particulars_main_switch_location}
              />
              <LabelValuePair
                label="No. of poles"
                value={data.particulars_main_switch_num_poles}
              />
              <LabelValuePair
                label="Current rating"
                value={data.particulars_main_switch_current_rating}
              />
              <LabelValuePair
                label="Rating / setting of device"
                value={data.particulars_main_switch_rating_setting}
              />
              <LabelValuePair
                label="Voltage rating"
                value={data.particulars_main_switch_voltage_rating}
              />
              <LabelValuePair
                label="RCD rated residual operating current, IΔn"
                value={
                  data.particulars_main_switch_rcd.residual_operating_current
                }
              />
              <LabelValuePair
                label="Measured operating time"
                value={data.particulars_main_switch_rcd.measured_operating_time}
              />
              <LabelValuePair
                label="Rated time delay"
                value={data.particulars_main_switch_rcd.rated_time_delay}
              />
            </section>
          </div>
        </OutlinedContentPanel>
      </div>

      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 8: Schedule of items inspected"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="columns-3">
            <section>
              <h2>
                1. External condition of electrical intake equipment (visual
                inspection only)
              </h2>
              <LabelValuePair
                label="1.1 Service cable"
                value={data['schedule__intake-equipment__service-cable']}
              />
              <LabelValuePair
                label="1.2 Service head"
                value={data['schedule__intake-equipment__service-head']}
              />
              <LabelValuePair
                label="1.3 Earthing arrangement"
                value={
                  data['schedule__intake-equipment__earthing-arrangements']
                }
              />
              <section>
                <h2>1.4 Meter tails</h2>
                <LabelValuePair
                  label="a) Cutout fuse to meter"
                  value={
                    data['schedule__intake-equipment__meter-tails'][
                      'cutout-to-meter'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) Meter to consumer unit"
                  value={
                    data['schedule__intake-equipment__meter-tails'][
                      'meter-to-cu'
                    ]
                  }
                />
              </section>
              <LabelValuePair
                label="1.5 Metering equipment"
                value={data['schedule__intake-equipment__metering-equipment']}
              />
              <LabelValuePair
                label="1.6 Isolator (where present)"
                value={data['schedule__intake-equipment__isolator']}
              />
            </section>
            <section>
              <h2>2. Presence of adequate arrangements for other sources</h2>
              <LabelValuePair
                label="2.1 Adequate arrangements where a generating set operates as a switched alternative to the public supply"
                value={
                  data[
                    'schedule-of-items__other-source-arrangements__generating-set-switched-alternative'
                  ]
                }
              />
              <LabelValuePair
                label="2.2 Adequate arrangements where generating set operates in parallel with the public supply"
                value={
                  data[
                    'schedule-of-items__other-source-arrangements__generating-set-parallel'
                  ]
                }
              />
              <LabelValuePair
                label="2.3 Presence of alternative / additional supply warning notices"
                value={
                  data[
                    'schedule-of-items__other-source-arrangements__alternative-supply-notices'
                  ]
                }
              />
            </section>
            <section>
              <h2>3. Earthing and bonding arrangements</h2>
              <LabelValuePair
                label="3.1 Presence and condition of distributor’s earthing arrangement"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__distributor-earthing'
                  ]
                }
              />
              <LabelValuePair
                label="3.2 Presence and condition of earth electrode connection, where appropriate"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__earth-electrode'
                  ]
                }
              />
              <LabelValuePair
                label="3.3 Confirmation of adequate earthing conductor size"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__earthing-conductor-size'
                  ]
                }
              />
              <LabelValuePair
                label="3.4 Accessibility and condition of earthing conductor at Main Earthing Terminal (MET)"
                value={data['schedule-of-items__earthing-and-bonding__met']}
              />
              <LabelValuePair
                label="3.5 Confirmation of adequate main protective bonding conductor sizes"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__bonding-conductor-size'
                  ]
                }
              />
              <LabelValuePair
                label="3.6 Accessibility and condition of main protective bonding conductor connections"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__bonding-connections'
                  ]
                }
              />
              <LabelValuePair
                label="3.7 Accessibility and condition of other protective bonding connections"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__other-bonding-connections'
                  ]
                }
              />
              <LabelValuePair
                label="3.8 Provision of earthing and bonding labels at all appropriate locations"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__earthing-bonding-labels'
                  ]
                }
              />
            </section>
            <section>
              <h2>4. Consumer unit(s) / Distribution board(s)</h2>
              <LabelValuePair
                label="4.1 Adequacy of working space / accessibility to consumer unit / distribution board"
                value={data['schedule-of-items__consumer-units__accessibility']}
              />
              <LabelValuePair
                label="4.2 Security of fixing"
                value={data['schedule-of-items__consumer-units__security']}
              />
              <LabelValuePair
                label="4.3 Condition of enclosure(s) in terms of IP rating"
                value={data['schedule-of-items__consumer-units__enclosure-ip']}
              />
              <LabelValuePair
                label="4.4 Condition of enclosure(s) in terms of fire rating"
                value={
                  data['schedule-of-items__consumer-units__enclosure-fire']
                }
              />
              <LabelValuePair
                label="4.5 Enclosure not damaged / deteriorated so as to impair safety"
                value={
                  data['schedule-of-items__consumer-units__enclosure-damage']
                }
              />
              <LabelValuePair
                label="4.6 Presence of linked main switch"
                value={
                  data['schedule-of-items__consumer-units__linked-main-switch']
                }
              />
              <LabelValuePair
                label="4.7 Operation of main switch(es) (functional check)"
                value={
                  data[
                    'schedule-of-items__consumer-units__operation-main0-switches'
                  ]
                }
              />
              <LabelValuePair
                label="4.8 Main switch capable of being secured in the OFF position"
                value={
                  data[
                    'schedule-of-items__consumer-units__main-switch-capable-secured-off'
                  ]
                }
              />
              <LabelValuePair
                label="4.9 Operation of circuit-breakers and RCDs to prove disconnection (functional check)"
                value={
                  data['schedule-of-items__consumer-units__breaker-operation']
                }
              />
              <LabelValuePair
                label="4.10 Correct identification of circuits and protective devices"
                value={
                  data[
                    'schedule-of-items__consumer-units__circuit-identification'
                  ]
                }
              />
              <section>
                <h2>
                  4.11 Presence of appropriate circuit charts, warning and other
                  notices
                </h2>
                <LabelValuePair
                  label="a) Provision of circuit charts/schedules or equivalent forms of information"
                  value={
                    data[
                      'schedule-of-items__consumer-units__appropriate-circuit-charts'
                    ]['provision']
                  }
                />
                <LabelValuePair
                  label="b) Warning notice of method of isolation where live parts not capable of being isolated by a single device"
                  value={
                    data[
                      'schedule-of-items__consumer-units__appropriate-circuit-charts'
                    ]['isolation-warning-notice']
                  }
                />
                <LabelValuePair
                  label="c) Periodic inspection and testing notice"
                  value={
                    data[
                      'schedule-of-items__consumer-units__appropriate-circuit-charts'
                    ]['inspection-notice']
                  }
                />
                <LabelValuePair
                  label="d) Presence of RCD six-monthly notice, where required"
                  value={
                    data[
                      'schedule-of-items__consumer-units__appropriate-circuit-charts'
                    ]['rcd-notice']
                  }
                />
                <LabelValuePair
                  label="e) Warning notice of non-standard (mixed) colours of conductors present"
                  value={
                    data[
                      'schedule-of-items__consumer-units__appropriate-circuit-charts'
                    ]['non-standard-colours-notice']
                  }
                />
                <LabelValuePair
                  label="f) All other required labelling provided"
                  value={
                    data[
                      'schedule-of-items__consumer-units__appropriate-circuit-charts'
                    ]['other-labelling']
                  }
                />
              </section>
              <LabelValuePair
                label="4.12 Compatibility of protective device(s), base(s) and other components; correct type and rating (no signs of unacceptable thermal damage, arcing or overheating)"
                value={
                  data[
                    'schedule-of-items__consumer-units__compatibility-of-devices'
                  ]
                }
              />
              <LabelValuePair
                label="4.13 Single-pole switching or protective devices in the line conductors only"
                value={
                  data[
                    'schedule-of-items__consumer-units__single-pole-switching-line-conductors'
                  ]
                }
              />
              <LabelValuePair
                label="4.14 Protection against mechanical damage where cables enter consumer unit / distribution board"
                value={
                  data[
                    'schedule-of-items__consumer-units__mechanical-damage-protection'
                  ]
                }
              />
              <LabelValuePair
                label="4.15 Protection against electromagnetic effects where cables enter metallic consumer unit / enclosure"
                value={
                  data[
                    'schedule-of-items__consumer-units__electromagnetic-effects'
                  ]
                }
              />
              <LabelValuePair
                label="4.16 RCDs provided for fault protection – includes RCBOs"
                value={data['schedule-of-items__consumer-units__rcds-provided']}
              />
              <LabelValuePair
                label="4.17 RCDs provided for additional protection – includes RCBOs"
                value={
                  data[
                    'schedule-of-items__consumer-units__rcds-provided-additional'
                  ]
                }
              />
              <LabelValuePair
                label="4.18 Confirmation of indication that SPD is functional"
                value={
                  data['schedule-of-items__consumer-units__spd-confirmation']
                }
              />
              <LabelValuePair
                label="4.19 Adequacy of AFDD(s), where specified"
                value={data['schedule-of-items__consumer-units__afdd-adequacy']}
              />
              <LabelValuePair
                label="4.20 Confirmation that conductor connections, including connections to busbars, are correctly located in terminals and are tight and secure"
                value={
                  data[
                    'schedule-of-items__consumer-units__connection-locations'
                  ]
                }
              />
            </section>
            <section>
              <h2>5. Distribution / final circuits</h2>
              <LabelValuePair
                label="5.1 Identification of conductors"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__conductor-identification'
                  ]
                }
              />
              <LabelValuePair
                label="5.2 Cables correctly supported throughout"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__cables-supproted'
                  ]
                }
              />
              <LabelValuePair
                label="5.3 Condition of insulation of live parts"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__insulation-condition'
                  ]
                }
              />
              <LabelValuePair
                label="5.4 Non-sheathed live conductors protected by enclosure in conduit, ducting or trunking (including confirmation of the integrity of conduit and trunking systems)"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__non-sheathed-live'
                  ]
                }
              />
              <LabelValuePair
                label="5.5 Adequacy of cables for current-carrying capacity with regard to the type and nature of installation"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__cable-adequacy'
                  ]
                }
              />
              <LabelValuePair
                label="5.6 Adequacy of protective devices; type and rated current for fault protection"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__protective-device-adequacy'
                  ]
                }
              />
              <LabelValuePair
                label="5.7 Presence and adequacy of circuit protective conductors"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__protective-conductor-adequacy'
                  ]
                }
              />
              <LabelValuePair
                label="5.8 Co-ordination between conductors and overload protection devices"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__device-coordination'
                  ]
                }
              />
              <LabelValuePair
                label="5.9 Wiring system(s) appropriate for the type and nature of the installation and external influences"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__appropriate-wiring-systems'
                  ]
                }
              />
              <LabelValuePair
                label="5.10 Cables adequately protected against mechanical damage and abrasion"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__cables-protected'
                  ]
                }
              />
              <section>
                <h2>5.11 Provision of additional protection by 30 ma RCD</h2>

                <LabelValuePair
                  label="a) For all socket-outlets with a rated current not exceeding 32 A"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection'
                    ][
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection__all-sockets'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) For mobile equipment not exceeding a rating of 32 A for use outdoors"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection'
                    ][
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection__mobile-equipment'
                    ]
                  }
                />
                <LabelValuePair
                  label="c) For cables installed in walls / partitions at a depth of less than 50 mm"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection'
                    ][
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection__cables-plastered'
                    ]
                  }
                />
                <LabelValuePair
                  label="d) For cables installed in walls / partitions containing metal parts regardless of depth"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection'
                    ][
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection__walls-metal-parts'
                    ]
                  }
                />
                <LabelValuePair
                  label="e) For all AC final circuits supplying luminaires"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection'
                    ][
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection__all-luminaire-circuits'
                    ]
                  }
                />
              </section>
              <LabelValuePair
                label="5.12 Provision of fire barriers, sealing arrangements and protection against thermal effects"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__fire-barriers'
                  ]
                }
              />
              <LabelValuePair
                label="5.13 Band II cables segregated / separated from Band I cables"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__band-separation'
                  ]
                }
              />
              <LabelValuePair
                label="5.14 Cables segregated / separated from communications cabling"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__comms-segregation'
                  ]
                }
              />
              <LabelValuePair
                label="5.15 Cables segregated / separated from non-electrical services"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__non-electrical-segregation'
                  ]
                }
              />
              <section>
                <h2>
                  5.16 Termination of cables at enclosures (extent of sampling
                  indicated in PART 7 of the report)
                </h2>
                <LabelValuePair
                  label="a) Connections soundly made and under no undue strain"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination'
                    ][
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination__connections-sound'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) No basic insulation of a conductor visible outside enclosure"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination'
                    ][
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination__no-insulation-visible'
                    ]
                  }
                />
                <LabelValuePair
                  label="c) Connection of live conductors adequately enclosed"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination'
                    ][
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination__connection-enclosed'
                    ]
                  }
                />
                <LabelValuePair
                  label="d) Adequately connected at point of entry to enclosure"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination'
                    ][
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination__adequately-connected'
                    ]
                  }
                />
              </section>
              <LabelValuePair
                label="5.17 Condition of accessories including socket-outlets, switches and joint boxes"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__condition-of-accessories'
                  ]
                }
              />
            </section>
            <section>
              <h2>
                6. Isolation and switching (isolation, switching off for
                mechanical maintenance and functional switching)
              </h2>
              <section>
                <h2>6.1 In general:</h2>

                <LabelValuePair
                  label="a) Presence and condition of appropriate devices"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-switching__presence'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) Correct operation verified"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-switching__operation-verified'
                    ]
                  }
                />
              </section>
              <section>
                <h2>
                  6.2 For isolation and switching for mechanical maintenance
                  only:
                </h2>
                <LabelValuePair
                  label="a) Capable of being secured in the OFF position, where appropriate"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-switching__mechanical__capable-secured-off'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) Acceptable location (local / remote)"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-switching__mechanical__acceptable-location'
                    ]
                  }
                />
                <LabelValuePair
                  label="c) Clearly identified by position and / or durable marking(s)"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-switching__mechanical__durable-markings'
                    ]
                  }
                />
              </section>
              <section>
                <h2>6.3 For isolation only:</h2>
                <LabelValuePair
                  label="a) Warning label(s) posted in situations where live parts cannot be isolated by the operation of a single device"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-switching__isolation-only__warning-labels'
                    ]
                  }
                />
              </section>
            </section>
            <section>
              <h2>7. Current-using equipment (permanently connected)</h2>
              <LabelValuePair
                label="7.1 Condition of equipment in terms of IP rating"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__ip'
                  ]
                }
              />
              <LabelValuePair
                label="7.2 Equipment does not constitute a fire hazard"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__fire-hazard'
                  ]
                }
              />
              <LabelValuePair
                label="7.3 Enclosure not damaged / deteriorated so as to impair safety"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__enclosure-not-damaged'
                  ]
                }
              />
              <LabelValuePair
                label="7.4 Suitability for the environment and external influences"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__suitability'
                  ]
                }
              />
              <LabelValuePair
                label="7.5 Security of fixing"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__fixing-security'
                  ]
                }
              />
              <LabelValuePair
                label="7.6 Cable entry holes in ceiling above luminaires, sized or sealed so as to restrict the spread of fire"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__entry-holes-sealed'
                  ]
                }
              />
              <section>
                <h2>7.7 Recessed luminaires (downlighters):</h2>
                <LabelValuePair
                  label="a) Correct type of lamps fitted"
                  value={
                    data[
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires'
                    ][
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires__correct-lamp'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) Installed to minimise build-up of heat"
                  value={
                    data[
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires'
                    ][
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires__minimise-heat'
                    ]
                  }
                />
                <LabelValuePair
                  label="c) No signs of overheating to surrounding building fabric"
                  value={
                    data[
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires'
                    ][
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires__overheating-signs'
                    ]
                  }
                />
                <LabelValuePair
                  label="d) No signs of overheating to conductors / terminations"
                  value={
                    data[
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires'
                    ][
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires_overheating-terminations'
                    ]
                  }
                />
              </section>
            </section>
            <section>
              <h2>8. Location(s) containing a bath or shower</h2>
              <section>
                <h2>8.1 Additional protection by RCD not exceeding 30 mA:</h2>
                <LabelValuePair
                  label="a) For low voltage circuits serving the location"
                  value={
                    data[
                      'schedule-of-items__consumer-units__wet-locations__rcd'
                    ][
                      'schedule-of-items__consumer-units__wet-locations__rcd-low-voltage'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) For low voltage circuits passing through Zone 1 and Zone 2 not serving the location"
                  value={
                    data[
                      'schedule-of-items__consumer-units__wet-locations__rcd'
                    ][
                      'schedule-of-items__consumer-units__wet-locations__rcd-passing-through'
                    ]
                  }
                />
              </section>
              <LabelValuePair
                label="8.2 Where used as a protective measure, requirements for SELV or PELV are met"
                value={
                  data[
                    'schedule-of-items__consumer-units__wet-locations__selv-pelv'
                  ]
                }
              />
              <LabelValuePair
                label="8.3 Shaver sockets comply with BS EN 61558-2-5 (formerly BS 3535)"
                value={
                  data[
                    'schedule-of-items__consumer-units__wet-locations__shaver-sockets'
                  ]
                }
              />
              <LabelValuePair
                label="8.4 Presence of supplementary bonding conductors unless not required by BS 7671: 2018"
                value={
                  data[
                    'schedule-of-items__consumer-units__wet-locations__sup-bonding'
                  ]
                }
              />
              <LabelValuePair
                label="8.5 Low voltage (e.g. 230 volts) socket-outlets sited at least 3 m from Zone 1"
                value={
                  data[
                    'schedule-of-items__consumer-units__wet-locations__lv-at-least-3m'
                  ]
                }
              />
              <LabelValuePair
                label="8.6 Suitability of equipment for external influences for installed location in terms of IP rating"
                value={
                  data[
                    'schedule-of-items__consumer-units__wet-locations__suitability-ip'
                  ]
                }
              />
              <LabelValuePair
                label="8.7 Suitability of equipment for installation in a particular zone"
                value={
                  data[
                    'schedule-of-items__consumer-units__wet-locations__suitability-installation'
                  ]
                }
              />
            </section>
            <section>
              <h2>9. Other Part 7 special installations or locations</h2>
              {(data[
                'schedule-of-items__consumer-units__other-special-installations'
              ] && (
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>Location</th>
                      <th>Outcome</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data[
                      'schedule-of-items__consumer-units__other-special-installations'
                    ]?.map((x: any, i: number) => (
                      <tr
                        key={`schedule-of-items-consumer-units-other-special-installations-${i}`}
                      >
                        <td>{x.location}</td>
                        <td>{x.outcome}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )) || <p>N/A</p>}
            </section>
          </div>
        </OutlinedContentPanel>
      </div>

      {data.distributionBoards && (
        <div className="form-template-row circuit-details no-page-break">
          <OutlinedContentPanel
            colourOverride={brandColour}
            title="PART 9: Schedule of circuit details and test results"
            titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
            paddingVariant={ContentPanelPaddingVariant.SMALL}
          >
            {data.distributionBoards?.map((db: any, i: number) => (
              <section key={`distribution-boards-${i}`}>
                <h5>Distribution board {db.designation}</h5>
                <div className="form-template-row circuit-details no-page-break">
                  <LabelValuePair
                    label="Location of consumer unit"
                    value={db.location}
                  />
                  <LabelValuePair label="Designation" value={db.designation} />
                  <LabelValuePair
                    label="Prospective fault current at consumer unit (where applicable)"
                    value={db.prospectiveFaultCurrent}
                  />
                </div>

                {db.circuits && (
                  <table className="electrical-circuits">
                    <thead>
                      <tr>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Circuit number</span>
                        </th>
                        <th rowSpan={3}>Circuit description</th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Type of wiring (see Codes)</span>
                        </th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Reference Method (BS 7671)</span>
                        </th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Number of points served</span>
                        </th>
                        <th colSpan={2}>Circuit conductor csa</th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Max. disconnection time (BS 7671)</span>
                        </th>
                        <th colSpan={4}>Protective device</th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>RCD Operating current I∆n (mA)</span>
                        </th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>
                            Maximum permissted Zs for installed protective
                            device
                          </span>
                        </th>
                        <th colSpan={5}>Circuit impedances (Ohms)</th>
                        <th colSpan={3}>Insulation resistance</th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Polarity</span>
                        </th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>
                            Max. measured earth fault loop impedance, Zs
                          </span>
                        </th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>RCD operating time (ms)</span>
                        </th>
                        <th rowSpan={2} colSpan={2}>
                          Test buttons
                        </th>
                        <th rowSpan={3} className="table-header--vertical">
                          Vulnerable to damage
                        </th>
                      </tr>
                      <tr>
                        <td rowSpan={2}>Live (mm2)</td>
                        <td rowSpan={2}>cpc (mm2)</td>
                        <td rowSpan={2}>BS (EN)</td>
                        <td rowSpan={2}>Type</td>
                        <td rowSpan={2}>Rating (A)</td>
                        <td rowSpan={2}>Short-circuit capacity (kA)</td>
                        <td colSpan={3}>
                          Ring final circuits only (measured end to end)
                        </td>
                        <td colSpan={2}>
                          All circuits (complete at least one column)
                        </td>
                        <td rowSpan={2}>Live / Live (MegaOhms)</td>
                        <td rowSpan={2}>Live / Earth (MegaOhms)</td>
                        <td rowSpan={2}>Test voltage DC (V)</td>
                      </tr>
                      <tr>
                        <th>(Line) r1</th>
                        <th>(Neutral) rn</th>
                        <th>(cpc) r2</th>
                        <th>(R1 + R2)</th>
                        <th>R2</th>
                        <td>RCD</td>
                        <td>AFDD</td>
                      </tr>
                    </thead>
                    <tbody>
                      {db.circuits?.map((circuit: any, i: number) => (
                        <tr key={`db-circuits-${i}`}>
                          <td>{circuit.number}</td>
                          <td>{circuit.description}</td>
                          <td>
                            {circuit.wiringType ?? circuit.wiringTypeOther}
                          </td>
                          <td>{circuit.referenceMethod}</td>
                          <td>{circuit.numPointsServed}</td>
                          <td>{circuit.liveConductorCsa}</td>
                          <td>{circuit.cpcConductorCsa}</td>
                          <td>{circuit.maxDisconnectionTime}</td>
                          <td>{circuit.overcurrentProtectiveDevice}</td>
                          <td>
                            {defaultToNa(
                              circuit.overcurrentProtectiveDeviceType
                            )}
                          </td>
                          <td>{circuit.currentRating}</td>
                          <td>{circuit.shortCircuitCapacity}</td>
                          <td>{circuit.rcdOperatingCurrent}</td>
                          <td>{defaultToNa(circuit.maxPermittedZs)}</td>
                          <td>{defaultToNa(circuit.lineImpedance)}</td>
                          <td>{defaultToNa(circuit.neutralImpedance)}</td>
                          <td>{defaultToNa(circuit.cpcImpedance)}</td>
                          <td>{defaultToNa(circuit.r1PlusR2Impedance)}</td>
                          <td>{defaultToNa(circuit.r2Impedance)}</td>
                          <td>
                            {defaultToNa(circuit.liveLiveInsulationResistance)}
                          </td>
                          <td>
                            {defaultToNa(circuit.liveEarthInsulationResistance)}
                          </td>
                          <td>{circuit.testVoltage}</td>
                          <td>{circuit.polarity}</td>
                          <td>
                            {defaultToNa(
                              circuit.maxMeasuredEarthFaultLoopImpedance
                            )}
                          </td>
                          <td>{defaultToNa(circuit.rcdOperatingTime)}</td>
                          <td>{circuit.rcdTestButtonOperation}</td>
                          <td>{circuit.afddTestButtonOperation}</td>
                          <td>{circuit.vulnerableToDamage ? '✓' : '✗'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <h5>Tested by</h5>
                <div className="form-template-row circuit-details no-page-break">
                  <LabelValuePair
                    label="Name"
                    value={db.testEngineerName.toLocaleUpperCase()}
                  />
                  <LabelValuePair
                    label="Position"
                    value={db.testEngineerPosition}
                  />
                  <LabelValuePair
                    label="Signature"
                    value={
                      <div className={'form-template-signature'}>
                        {db.testEngineerSignatureBase64 && (
                          <img src={db.testEngineerSignatureBase64} />
                        )}
                      </div>
                    }
                  />
                  <LabelValuePair
                    label="Date"
                    value={moment(db.testEngineerDate).format('DD/MM/YYYY')}
                  />
                </div>
                <h5>Test instruments</h5>
                <div className="form-template-row circuit-details no-page-break">
                  <LabelValuePair
                    label="Multi-function"
                    value={db.multiFunctionTestInstrumentSerialNumber || 'N/A'}
                  />
                  <LabelValuePair
                    label="Continuity"
                    value={db.continuityTestInstrumentSerialNumber || 'N/A'}
                  />
                  <LabelValuePair
                    label="Insulation resistance"
                    value={
                      db.insulationResistanceTestInstrumentSerialNumber || 'N/A'
                    }
                  />
                  <LabelValuePair
                    label="Earth fault loop impedance"
                    value={
                      db.earthFaultLoopImpedanceTestInstrumentSerialNumber ||
                      'N/A'
                    }
                  />
                  <LabelValuePair
                    label="Earth electrode resistance"
                    value={
                      db.earthElectrodeResistanceTestInstrumentSerialNumber ||
                      'N/A'
                    }
                  />
                  <LabelValuePair
                    label="RCD"
                    value={db.rcdTestInstrumentSerialNumber || 'N/A'}
                  />
                </div>
              </section>
            )) ?? <p>N/A</p>}
          </OutlinedContentPanel>
        </div>
      )}
    </div>
  );
};

export default DomesticElectricalInstallationCertificate;
