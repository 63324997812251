import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import useDeleteCustomerContact from '@/api/mutations/customers/useDeleteCustomerContact';
import customerKeys from '@/api/queries/customers/keyFactory';
import {
  CustomerQuery,
  DeleteCustomerContactInput,
  DeleteCustomerContactMutation as _DeleteCustomerAddressMutation,
} from '@/gql/graphql';

const useOptimisticDeleteCustomerContactOnCustomer = (
  customerId: string,
  options?: UseMutationOptions<
    _DeleteCustomerAddressMutation,
    unknown,
    DeleteCustomerContactInput
  >
) => {
  const queryClient = useQueryClient();

  return useDeleteCustomerContact({
    ...options,
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: customerKeys.customer(customerId),
      });

      // Snapshot the previous value
      const previousCustomer = queryClient.getQueryData(
        customerKeys.customer(customerId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<CustomerQuery>(
        customerKeys.customer(customerId),
        (old) => {
          if (!old) return;

          return {
            ...old,
            customer: {
              ...old.customer,
              contacts: old.customer.contacts.filter(
                (contact) => contact.id !== variables.customerContactId
              ),
            },
          };
        }
      );

      options?.onMutate?.(variables);

      // Return a context object with the snapshotted value
      return { previousCustomer };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        customerKeys.customer(customerId),
        // @ts-ignore
        context.previousCustomer
      );

      options?.onError?.(err, newTodo, context);
    },
    // Always refetch after error or success:
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: customerKeys.customer(customerId),
      });

      options?.onSettled?.(...args);
    },
  });
};

export default useOptimisticDeleteCustomerContactOnCustomer;
