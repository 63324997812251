import * as d3 from 'd3';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import './SparkLine.sass';

const ANIMATION_DURATION_MS = 1000;

type Props = {
  data: number[] | null;
  isSmooth?: boolean;
  hasArea?: boolean;
};

const SparkLine: FunctionComponent<Props> = ({
  data,
  isSmooth = false,
  hasArea = false,
}: Props): JSX.Element => {
  const [svgElement, setSvgElement] = useState<SVGElement | null>(null);
  const [pathElement, setPathElement] = useState<SVGPathElement | null>(null);
  const [areaPathElement, setAreaPathElement] = useState<SVGPathElement | null>(
    null
  );
  const [requiresUpdateVisualisationData, setRequiresUpdateVisualisationData] =
    useState(true);

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  const uniqueIdSuffix = useMemo(() => {
    return `${new Date().getTime()}-${Math.random()}`;
  }, []);

  const getDimensions = useCallback(() => {
    const height = svgElement?.clientHeight || 0;
    const width = svgElement?.clientWidth || 0;
    return { width, height };
  }, [svgElement]);

  const pathSelection = useMemo(() => {
    if (pathElement) {
      return d3.select(pathElement);
    }
  }, [pathElement]);

  const areaPathSelection = useMemo(() => {
    if (pathElement) {
      return d3.select(areaPathElement);
    }
  }, [areaPathElement]);

  const yScale = useMemo(() => {
    return d3.scaleLinear().domain([0, 0]).range([0, 0]);
  }, []);

  const xScale = useMemo(() => {
    return d3.scaleLinear().domain([0, 0]).range([0, 0]);
  }, []);

  const lineGen = useMemo(() => {
    return d3
      .line<number>()

      .x((d: any, i: number) => xScale(i) || 0)
      .y((d) => {
        return yScale(d) || 0;
      })
      .curve(isSmooth ? d3.curveBasis : d3.curveLinear);
  }, [xScale, yScale, isSmooth]);

  const areaGen = useMemo(() => {
    return d3
      .area<number>()

      .x((d: any, i: number) => xScale(i) || 0)
      .y0(dimensions.height)
      .y1((d) => {
        return yScale(d) || 0;
      })
      .curve(isSmooth ? d3.curveBasis : d3.curveLinear);
  }, [xScale, yScale, isSmooth, dimensions.height]);

  const updateVisualisationDimensions = useCallback(() => {
    const dimensions = getDimensions();
    setDimensions(dimensions);
    const { width, height } = dimensions;
    yScale.range([0, height]);
    xScale.range([0, width]);

    if (pathSelection) {
      // @ts-ignore
      pathSelection.datum(data).attr('d', lineGen);
    }

    if (areaPathSelection) {
      // @ts-ignore
      areaPathSelection.datum(data).attr('d', areaGen);
    }
  }, [xScale, yScale, pathSelection, lineGen, areaGen, areaPathSelection]);

  const updateVisualisationData = useCallback(() => {
    if (!data) return;

    const maxValue = d3.max(data) || 0;
    const minValue = d3.min(data) || 0;
    const count = data?.length ? data.length - 1 : 0;

    yScale.domain([maxValue, minValue]);
    xScale.domain([0, count]);

    if (pathSelection) {
      pathSelection.datum(data).attr('d', lineGen);
    }

    if (areaPathSelection) {
      areaPathSelection.datum(data).attr('d', areaGen);
    }
  }, [
    data,
    xScale,
    yScale,
    dimensions,
    areaGen,
    lineGen,
    pathSelection,
    areaPathSelection,
  ]);

  useEffect(() => {
    window.addEventListener('resize', updateVisualisationDimensions);

    return () => {
      window.removeEventListener('resize', updateVisualisationDimensions);
    };
  }, [updateVisualisationDimensions]);

  useEffect(() => {
    if (pathSelection) updateVisualisationDimensions();
  }, [pathSelection, updateVisualisationDimensions]);

  useEffect(() => {
    if (data && requiresUpdateVisualisationData) {
      updateVisualisationData();
      updateVisualisationDimensions();
      setRequiresUpdateVisualisationData(false);
    }
  }, [
    data,
    requiresUpdateVisualisationData,
    updateVisualisationDimensions,
    updateVisualisationData,
  ]);

  useEffect(() => {
    if (data) {
      setRequiresUpdateVisualisationData(true);
    }
  }, [data]);

  return (
    <div className="spark-line">
      <div className="spark-line-inner">
        <svg
          ref={(node) => {
            if (node !== null) {
              setSvgElement(node);
            }
          }}
        >
          <defs>
            <mask id={`mask-${uniqueIdSuffix}`}>
              <linearGradient
                id={`mask-gradient-${uniqueIdSuffix}`}
                x1="0%"
                x2="100%"
              >
                <stop offset="0" stopColor="#000"></stop>
                <stop offset="0.1" stopColor="#fff"></stop>
                <stop offset="0.9" stopColor="#fff"></stop>
                <stop offset="1" stopColor="#000"></stop>
              </linearGradient>
              <rect
                x="0"
                y="-5"
                width={dimensions.width}
                height={dimensions.height + 10}
                fill={`url(#mask-gradient-${uniqueIdSuffix})`}
              />
            </mask>
            <linearGradient
              id={`gradient-${uniqueIdSuffix}`}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop className="gradient-stop-1" offset="0" />
              <stop className="gradient-stop-2" offset="1" />
            </linearGradient>
          </defs>
          <g
            style={{
              mask: `url(#mask-${uniqueIdSuffix})`,
            }}
          >
            {hasArea && (
              <rect
                className="gradient-rect"
                width={dimensions.width}
                height={dimensions.height}
                clipPath={`url(#area-${uniqueIdSuffix})`}
                style={{ fill: `url(#gradient-${uniqueIdSuffix})` }}
              />
            )}
            <path
              ref={(node) => {
                if (node !== null) {
                  setPathElement(node);
                }
              }}
            ></path>

            {hasArea && (
              <clipPath id={`area-${uniqueIdSuffix}`}>
                <path
                  ref={(node) => {
                    if (node !== null) {
                      setAreaPathElement(node);
                    }
                  }}
                ></path>
              </clipPath>
            )}
          </g>
        </svg>
      </div>
    </div>
  );
};

export default SparkLine;
