import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  CreateUploadIntentInput,
  CreateUploadIntentMutation,
} from '@/gql/graphql';

const CREATE_UPLOAD_INTENT_MUTATION = graphql(`
  mutation CreateUploadIntent($input: CreateUploadIntentInput!) {
    createUploadIntent(input: $input) {
      signedUploadUrl
      signedUploadUrlHeaders
      uploadIntent {
        id
        publicId
      }
    }
  }
`);

const useCreateUploadIntent = (
  options?: UseMutationOptions<
    CreateUploadIntentMutation,
    unknown,
    CreateUploadIntentInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(CREATE_UPLOAD_INTENT_MUTATION, { input });
    },
    ...options,
  });
};

export default useCreateUploadIntent;
