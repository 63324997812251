import { FC, PropsWithChildren } from 'react';
import PageWrapper from '../pageWrapper/PageWrapper';

import './BasicPageWrapper.sass';

type Props = {
  className?: string;
};
const BasicPageWrapper: FC<PropsWithChildren<Props>> = ({
  className,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <PageWrapper className={`basic-page-wrapper ${className ? className : ''}`}>
      <div className="page-content">
        <div className="header-content">
          <img
            className="payaca-logo"
            src="https://storage.googleapis.com/payaca-prod-assets/payaca-logo.png"
          />
        </div>
        <div className="body-content">{children}</div>
      </div>
    </PageWrapper>
  );
};

export default BasicPageWrapper;
