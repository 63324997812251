import UntitledIcon from '@payaca/untitled-icons';
import { ComponentPropsWithoutRef, FC } from 'react';
import Card from '../plCard/Card';

export interface IProps extends ComponentPropsWithoutRef<'button'> {
  text?: string;
}

const AddCardButton: FC<IProps> = (props) => {
  const { className, text, type = 'button', ...rest } = props;

  return (
    <button
      className={
        'group cursor-pointer bg-transparent p-0' +
        (className ? ' ' + className : '')
      }
      type={type}
      {...rest}
    >
      <Card className="h-full items-center justify-center group-disabled:cursor-not-allowed group-disabled:opacity-50 group-[:not(:disabled):hover]:bg-gray-50">
        <Card.Body>
          <div className="flex items-center gap-3 text-left">
            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-50 group-[:not(:disabled):hover]:bg-gray-200">
              <UntitledIcon name="plus.3" className="h-6 w-6" />
            </div>

            <div>
              <h4>{text}</h4>
            </div>
          </div>
        </Card.Body>
      </Card>
    </button>
  );
};

export default AddCardButton;
