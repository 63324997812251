import { useMagicSelector } from '@/api/magicSelector';
import { useSelector } from '@/api/state';
import { getProjectPipelineCollapseSettingsLocalStorageKey } from '@/helpers/localStorageKeyHelper';
import { RequestData } from '@/ui/components/contextProviders/ProjectsContextProvider';
import { getRegion } from '@/utils/stateAccessors';
import AssignedUsersIndicator from '@payaca/components/assignedUsersIndicator/AssignedUsersIndicator';
import CollapsibleBlock from '@payaca/components/collapsibleBlock/CollapsibleBlock';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import TagControl from '@payaca/components/tagControl/TagControl';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import { currencySymbol } from '@payaca/helpers/financeHelper';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import * as dealActions from '@payaca/store/deals/dealsActions';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { ListedDeal, PipelineListedDeals } from '@payaca/types/listedDealTypes';
import { defaultPipelineStages, Pipeline } from '@payaca/types/pipelineTypes';
import { User } from '@payaca/types/userTypes';
import { format } from 'd3-format';
import { get, pick, set } from 'lodash-es';
import React, {
  FC,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Location } from 'react-iconly';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import ListedDealInfoIcons from '../listedDealInfoIcons/ListedDealInfoIcons';
import { PipelineBadge } from '../pipelineBadge/PipelineBadge';

// @ts-ignore
import Board from 'react-trello';

import './ListedDealsPipeline.sass';

interface Props {
  getListedDealsRequestData: RequestData;
  selectedPipelineId: number | null;
}
{
  /*
  {
    // keyed by pipelineId
    0: {
      // keyed by pipelineStage
      'New Deal': true,
      'Proposal sent': false,
      ...
    }
  }

*/
}
type PipelineCollapseSettings = Record<number, Record<string, boolean>>;

const pipelineCollapseSettingsKey =
  getProjectPipelineCollapseSettingsLocalStorageKey();

const emptyArray: Array<any> = [];

const ListedDealsPipeline: FunctionComponent<Props> = ({
  getListedDealsRequestData,
  selectedPipelineId,
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const [activeColumn, setActiveColumn] = useState<number | undefined>();
  const [isFetchingDeals, setIsFetchingDeals] = useState(false);

  const [pipelineListedDeals, setPipelineListedDeals] =
    useState<PipelineListedDeals>();

  useEffect(() => {
    setIsFetchingDeals(true);
    const { assignedUserIds, ...rd } = getListedDealsRequestData;
    dispatch(
      dealActions.getListedPipelineDeals.request({
        args: {
          ...rd,
          userAssignments: assignedUserIds?.map((x: number) =>
            x === -1 ? 'unassigned' : x
          ),
          pageNumber: 1,
          pageSize: 20,
        },
        callback: (pipelineListedDeals: PipelineListedDeals) => {
          setIsFetchingDeals(false);
          setPipelineListedDeals(pipelineListedDeals);
        },
        errorCallback: (error: unknown) => {
          setIsFetchingDeals(false);
          console.error(error);
        },
      })
    );
  }, [getListedDealsRequestData]);

  const pipelines = useSelector(
    (state) => state.pipelines?.pipelines ?? emptyArray
  );
  const selectedPipeline = useMemo(() => {
    const pipeline = pipelines.find(
      ({ id }: Pipeline) => id === selectedPipelineId
    );
    return pipeline || pipelines[0];
  }, [pipelines, selectedPipelineId]);

  const columnGroupMaps: Record<number, number[]> = useMemo(() => {
    if (!selectedPipeline) return {};
    const groupTracker: any = {};
    let store: any = [];
    selectedPipeline?.stages?.forEach((stage: any, i: number) => {
      // does this stage appear in the default pipeline (i.e it's the end of a moveable group)
      if (i > 0 && defaultPipelineStages.includes(stage.title)) {
        store.forEach((i: number) => (groupTracker[i] = store));
        store = [];
      }
      store.push(i);
    });
    if (store.length) {
      store.forEach((i: number) => (groupTracker[i] = store));
    }

    return groupTracker;
  }, [selectedPipeline]);

  return (
    <ResponsiveViewWrapper
      className={
        'listed-deals-workflow-container' +
        (isFetchingDeals ? ' loading' : '') +
        (typeof activeColumn === 'number'
          ? ` workflow-is-dragging ${columnGroupMaps[activeColumn]
              ?.map((i: number) => `workflow-active-column-${i}`)
              .join(' ')}`
          : '')
      }
      downBreakpointSm={800}
    >
      <div className="loader-container">
        <MiniLoader />
      </div>
      <PipelineBoard
        pipelineListedDeals={pipelineListedDeals}
        selectedPipelineId={selectedPipelineId}
        columnGroupMaps={columnGroupMaps}
        setActiveColumn={setActiveColumn}
        fetchMore={(nextPageNumber, pipelineStageTitle) => {
          return new Promise((resolve) => {
            const { assignedUserIds, ...rd } = getListedDealsRequestData;
            dispatch(
              dealActions.getListedPipelineDeals.request({
                args: {
                  ...rd,
                  userAssignments: assignedUserIds?.map((x: number) =>
                    x === -1 ? 'unassigned' : x
                  ),
                  pageSize: 20,
                  pageNumber: nextPageNumber,
                  pipelineStages: [pipelineStageTitle],
                },
                callback: (pipelineListedDeals: PipelineListedDeals) => {
                  setIsFetchingDeals(false);
                  resolve(
                    pipelineListedDeals[pipelineStageTitle]?.items || emptyArray
                  );
                },
                errorCallback: (error: any) => {
                  setIsFetchingDeals(false);
                  console.error(error);
                },
              })
            );
          });
        }}
      />
    </ResponsiveViewWrapper>
  );
};

export default ListedDealsPipeline;

const PipelineBoard: FC<{
  pipelineListedDeals?: PipelineListedDeals;
  selectedPipelineId: number | null;
  columnGroupMaps: Record<number, number[]>;
  setActiveColumn: (activeColumn?: number) => void;
  fetchMore: (requestedPage: number, laneId: string) => any;
}> = ({
  pipelineListedDeals,
  selectedPipelineId,
  columnGroupMaps,
  setActiveColumn,
  fetchMore,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const pipelines = useSelector(
    (state) => state.pipelines?.pipelines ?? emptyArray
  );
  const selectedPipeline = useMemo(() => {
    const pipeline = pipelines.find(
      ({ id }: Pipeline) => id === selectedPipelineId
    );
    return pipeline || pipelines[0];
  }, [pipelines, selectedPipelineId]);

  const getCardsForPipelineStage = (stage: string) => {
    return (
      pipelineListedDeals?.[stage]?.items?.map((listedDeal: any) => {
        return {
          id: listedDeal.dealId,
          listedDeal: listedDeal,
        };
      }) || emptyArray
    );
  };

  const boardData = useMemo(() => {
    const lanes = selectedPipeline?.stages?.map((stage, i) => {
      return {
        cards: getCardsForPipelineStage(stage.title),
        id: stage.title,
        title: stage.title,
        totalItemCount: pipelineListedDeals?.[stage.title]?.totalItemCount,
        totalDealValue: pipelineListedDeals?.[stage.title]?.totalDealValue,
      };
    });

    return {
      lanes,
    };
  }, [pipelineListedDeals, selectedPipeline]);

  const handleCardClick = useCallback((dealId: number) => {
    history.push(`/deals/${dealId}`);
  }, []);
  const handleDragStart = useCallback(
    (dealId: number, columnTitle: string) => {
      const columnIndex = selectedPipeline.stages.findIndex(
        (x) => x.title === columnTitle
      );
      setActiveColumn(columnIndex);
      return true;
    },
    [selectedPipeline]
  );

  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);

  const handleDragEnd = useCallback(
    (
      dealId: number,
      startColumnTitle: string,
      endColumnTitle: string,
      startIndex: number,
      endIndex: number
    ) => {
      const startColumnIndex = selectedPipeline.stages.findIndex(
        (x) => x.title === startColumnTitle
      );
      const endColumnIndex = selectedPipeline.stages.findIndex(
        (x) => x.title === endColumnTitle
      );
      setActiveColumn(undefined);

      if (
        startColumnIndex !== endColumnIndex &&
        columnGroupMaps[endColumnIndex].includes(startColumnIndex)
      ) {
        return dispatch(
          dealActions.requestUpdateDeal(dealId, {
            pipelineStage: selectedPipeline.stages[endColumnIndex].title,
          })
        );
      }

      switch (true) {
        case startColumnIndex < endColumnIndex:
          showDynamicFeedbackMessage({
            title: "We'll move this Project when it's ready",
            body: 'Your proposal or invoice is not in the right state to appear in this column yet.',
            isCancellable: true,
            lifespanMs: DynamicFeedbackLifespanMs.LONG,
            feedbackLevel: FeedbackLevel.INFORMATION,
          });
          break;
        case startColumnIndex > endColumnIndex:
          showDynamicFeedbackMessage({
            title: "You can't move a Project backwards",
            body: 'Make changes to your proposals and invoices if need to update this Project.',
            isCancellable: true,
            lifespanMs: DynamicFeedbackLifespanMs.LONG,
            feedbackLevel: FeedbackLevel.INFORMATION,
          });
          break;
      }
      return false;
    },
    [dispatch, columnGroupMaps, pipelineListedDeals, selectedPipeline]
  );

  const onLaneScroll = useCallback(
    async (nextPageNumber: number, pipelineStageTitle: string) => {
      if (
        !pipelineListedDeals ||
        pipelineListedDeals[pipelineStageTitle]?.items.length >=
          pipelineListedDeals[pipelineStageTitle]?.totalItemCount
      ) {
        return [];
      }

      const more = await fetchMore(nextPageNumber, pipelineStageTitle);

      pipelineListedDeals[pipelineStageTitle].items.push(...more);

      return (
        more?.map((listedDeal: any) => {
          return {
            id: listedDeal.dealId,
            listedDeal: listedDeal,
          };
        }) || emptyArray
      );
    },
    [pipelineListedDeals]
  );

  const renderBoard = useMemo(() => {
    if (!boardData?.lanes) {
      return;
    }
    return (
      <Board
        key={new Date().getTime()}
        data={boardData}
        laneDraggable={false}
        cardDraggable={true}
        hideCardDeleteIcon={true}
        onCardClick={handleCardClick}
        handleDragStart={handleDragStart}
        handleDragEnd={handleDragEnd}
        onLaneScroll={onLaneScroll}
        cardStyle={{ borderBottom: 'none', maxWidth: '100%' }}
        components={{
          Section: (props: any) => {
            return (
              <Section {...props} selectedPipelineId={selectedPipelineId} />
            );
          },
          LaneHeader: LaneHeader,
          Card: PipelineCard,
        }}
      />
    );
  }, [
    boardData,
    handleCardClick,
    handleDragStart,
    handleDragEnd,
    onLaneScroll,
  ]);

  return <>{renderBoard}</>;
};

const Section: FC<
  PropsWithChildren<{
    selectedPipelineId: null | number;
    title: string;
    totalItemCount?: number;
    id: string;
  }>
> = ({ selectedPipelineId, ...props }) => {
  const [sectionHeight, setSectionHeight] = useState(0);
  const sectionRef = useCallback((node: any) => {
    if (node != null) {
      setSectionHeight(node.offsetHeight);
    }
  }, []);

  const pipelines = useMagicSelector(
    (state) => state.pipelines?.pipelines ?? emptyArray
  );

  const pipelineCollapseSettings: PipelineCollapseSettings = JSON.parse(
    localStorage.getItem(pipelineCollapseSettingsKey) || '{}'
  );

  const [isExpanded, setIsExpanded] = useState(
    !get(pipelineCollapseSettings, [selectedPipelineId || 0, props.title])
  );

  return (
    <section
      data-id={props.id}
      ref={sectionRef}
      className="bg-pc-blue-lighter relative m-1 inline-flex h-full max-h-full rounded-md align-top"
    >
      <div>
        <CollapsibleBlock
          isExpanded={isExpanded}
          setIsExpanded={(x) => {
            setIsExpanded(x);

            const _pipelineCollapseSettings: {
              [key: number]: { [key: string]: boolean };
            } = JSON.parse(
              localStorage.getItem(pipelineCollapseSettingsKey) || '{}'
            );

            // update current settings object with change
            const newPipelineSettings = set(
              _pipelineCollapseSettings,
              [selectedPipelineId || 0, props.title],
              !x
            );

            // filter out any pipelines that may not exist anymore
            const filteredNewPipelineSettings = pick(newPipelineSettings, [
              ...pipelines.map((x) => x.id),
            ]);

            localStorage.setItem(
              pipelineCollapseSettingsKey,
              JSON.stringify(filteredNewPipelineSettings)
            );
          }}
          CustomCollapsedView={() => {
            return (
              <div className="flex flex-col items-center gap-3 p-2">
                <CollapsibleBlock.ExpandButton />
                <CollapsibleBlock.CollapsedTitle>
                  {props.title}
                </CollapsibleBlock.CollapsedTitle>
                <span className="rounded-full bg-white px-3 py-0.5">
                  {props.totalItemCount || 0}
                </span>
              </div>
            );
          }}
        >
          <div
            className="section-inner flex w-[300px] flex-col items-stretch p-2"
            style={{
              height: sectionHeight,
            }}
          >
            {props.children}
          </div>
        </CollapsibleBlock>
      </div>
    </section>
  );
};

const LaneHeader: FC<{
  title: string;
  totalItemCount?: number;
  totalDealValue?: number;
}> = (props) => {
  const formatted = format('.2~s')((props.totalDealValue || 0) / 100);
  const region = useMagicSelector(getRegion);
  const cs = currencySymbol(region);

  return (
    <header>
      <div className="flex flex-row items-center gap-2">
        <span className="truncate text-base font-semibold">{props.title}</span>
        <span className="mr-auto rounded-full bg-white px-3 py-0.5">
          {props.totalItemCount || 0}
        </span>
        <CollapsibleBlock.CollapseButton />
      </div>
      <div>
        <span className="text-gray-500">
          Value: {cs}
          {formatted}
        </span>
      </div>
    </header>
  );
};

const PipelineCard: FC<{
  listedDeal: ListedDeal;
  id: string;
  onClick: () => void;
  ref?: React.RefObject<HTMLDivElement>;
}> = (props) => {
  return (
    <article
      ref={props.ref}
      data-id={props.id}
      className="react-trello-card relative mb-2 w-full cursor-pointer rounded-sm bg-white transition-all hover:bg-gray-50"
    >
      <Link
        draggable={false}
        to={`/deals/${props.id}`}
        className="block p-2 no-underline hover:no-underline"
      >
        <header className="mb-1">
          <WorkflowCardHeader listedDeal={props.listedDeal} />
        </header>
        <WorkflowCardBody listedDeal={props.listedDeal} />
      </Link>
    </article>
  );
};

const WorkflowCardHeader: FC<{ listedDeal: ListedDeal }> = ({
  listedDeal,
  ...props
}) => {
  const accountUsers: User[] = useMagicSelector((state: any) => {
    return state.users?.accountUsers ?? emptyArray;
  });

  const assignedUser = listedDeal.assignedToUserId
    ? accountUsers.find((user) => user.id === listedDeal.assignedToUserId)
    : null;

  return (
    <div className={'listed-deals-workflow-card-header'} {...props}>
      <PipelineBadge pipelineId={listedDeal.pipelineId} showTitle={false} />
      <span className="listed-deals-workflow-reference">
        {listedDeal.reference}{' '}
      </span>
      <div className="assigned-users-info-icons-wrapper">
        {assignedUser && (
          <AssignedUsersIndicator
            size="sm"
            assignedUsers={[
              {
                firstName: assignedUser.firstname,
                lastName: assignedUser.lastname,
                emailAddress: assignedUser.email,
                userColour: assignedUser.userColour,
                imgSrc: assignedUser.avatarUrl,
              },
            ]}
          />
        )}
        <ListedDealInfoIcons listedDeal={listedDeal} />
      </div>
    </div>
  );
};

const WorkflowCardBody: FC<{ listedDeal: ListedDeal }> = ({
  listedDeal,
  ...props
}) => {
  const tags = listedDeal.tags;

  return (
    <React.Fragment {...props}>
      <div className={'listed-deals-workflow-card-customer truncate'}>
        <span className={'bold-weight'}>{listedDeal.customerName}</span>
      </div>
      {listedDeal?.siteAddresses?.[0] && (
        <div className={'listed-deals-workflow-card-site-address truncate'}>
          <Location size={'small'} />
          <span>{getAddressAsString(listedDeal?.siteAddresses?.[0])}</span>
        </div>
      )}
      <div
        className={`listed-deals-workflow-card-statuses${
          tags.length ? '' : ' empty'
        }`}
      >
        <TagControl tags={tags.slice(0, 5)} />
        {tags.length > 5 && (
          <Tooltip
            text={tags
              .slice(5)
              .map((tag: any) => tag.tagText)
              .join(', ')}
          >
            <span className="more-tags">
              +{listedDeal.tags.length - 5} more
            </span>
          </Tooltip>
        )}
      </div>
    </React.Fragment>
  );
};
