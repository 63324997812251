import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { UpdateLineItemInput, UpdateLineItemMutation } from '@/gql/graphql';

const UPDATE_LINE_ITEM_MUTATION = graphql(`
  mutation UpdateLineItem($input: UpdateLineItemInput!) {
    updateLineItem(input: $input) {
      id
    }
  }
`);

const useUpdateLineItem = (
  options?: UseMutationOptions<
    UpdateLineItemMutation,
    unknown,
    UpdateLineItemInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UPDATE_LINE_ITEM_MUTATION, { input });
    },
    ...options,
  });
};

export default useUpdateLineItem;
