import { withStyles } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';

const styles = (theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  popoverText: {
    width: 200,
  },
});
const HoverPopover = ({
  children,
  options,
  anchorEl,
  onOpen,
  onClose,
  open,
  classes,
}) => {
  const doOpen = !!anchorEl && open;
  return (
    <div>
      <div
        aria-owns={doOpen ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        {children}
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={doOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        <div className={classes.popoverText}>{options.popoverText}</div>
      </Popover>
    </div>
  );
};

export default withStyles(styles)(HoverPopover);
