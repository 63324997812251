import SideDrawer from '@payaca/components/sideDrawer/SideDrawer';
import { FC, useMemo } from 'react';
import './EntityTimelogsDrawer.sass';

import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimelogLinkedEntity } from '@payaca/types/timelogs';
import EntityTimelogsControl from '../entityTimelogsControl/EntityTimelogsControl';

type Props = {
  newTimelogsInitialStartTime?: Date;
  isOpen: boolean;
  onClose?: () => void;
  entity: Pick<
    TimelogLinkedEntity,
    'entityType' | 'entityId' | 'entityReadableIdentifier'
  >;
};

const EntityTimelogsDrawer: FC<Props> = ({
  isOpen,
  onClose,
  entity,
  newTimelogsInitialStartTime,
}: Props): JSX.Element | null => {
  const readableType = useMemo(() => {
    switch (entity.entityType) {
      case 'deal':
        return 'Project';
      case 'task':
        return 'Task';
      case 'scheduledEvent':
        return 'Event';

      case 'customer':
        return 'Customer';
      default:
        return 'Entity';
    }
  }, [entity.entityType]);

  return (
    <SideDrawer
      isOpen={isOpen}
      onClose={onClose}
      className="entity-timelogs-drawer"
    >
      <h1>
        <FontAwesomeIcon icon={faClock} /> Timelogs for {readableType}
        {!!entity.entityReadableIdentifier?.length && (
          <>: {entity.entityReadableIdentifier}</>
        )}
      </h1>
      <EntityTimelogsControl
        entity={entity}
        newTimelogsInitialStartTime={newTimelogsInitialStartTime}
      />
    </SideDrawer>
  );
};
export default EntityTimelogsDrawer;
