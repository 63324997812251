import { FC } from 'react';
import StandardFormTemplate from './StandardFormTemplate';
import UnventedHotWaterCylinderCertificateTemplateStructure from './UnventedHotWaterCylinderCertificateTemplate';

interface Props {
  data: any;
  account: any;
}
const UnventedHotWaterCylinderCertificateTemplate: FC<Props> = ({
  data,
  account,
}) => {
  return (
    <StandardFormTemplate
      data={data}
      account={account}
      formTemplateStructure={
        UnventedHotWaterCylinderCertificateTemplateStructure
      }
    />
  );
};

export default UnventedHotWaterCylinderCertificateTemplate;
