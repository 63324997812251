import { loadStripe } from '@stripe/stripe-js';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAccount } from '@/utils/storeHooks';

import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import Button from '@payaca/components/plButton/Button';
import StripePaymentControl from '@payaca/components/stripePaymentControl/StripePaymentControl';
import SubscriptionPaymentPreviewView from '../manageSubscription/subscriptionPaymentPreview/SubscriptionPaymentPreview';

import { getCostsForPriceAndQuantity } from '@payaca/helpers/addOnProductsHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';

import {
  createAddOnSubscription,
  getAddOnSubscriptionPaymentPreview,
  updateAddOnSubscription,
} from '@payaca/store/subscription/subscriptionActions';

import {
  PublicAddOnProduct,
  PublicAddOnProductPrice,
} from '@payaca/types/add-on-products';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { SubscriptionPaymentPreview } from '@payaca/types/subscriptionTypes';

import { isNullish } from '@payaca/utilities/guards';

import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import LabelValuePair from '../manageSubscription/LabelValuePair';
import './CreateAddOnSubscriptionControl.sass';

const STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY || '';
const stripePromise = loadStripe(STRIPE_KEY);

type Props = {
  existingAddOnSubscriptionId?: string;
  addOnProduct: PublicAddOnProduct;
  price: PublicAddOnProductPrice;
  onSuccess?: (subscriptionId: string) => void;
};
const CreateAddOnSubscriptionControl: FC<Props> = ({
  existingAddOnSubscriptionId,
  addOnProduct,
  price,
  onSuccess,
}: Props): JSX.Element => {
  const account = useAccount();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [isPersistingSubscription, setIsPersistingSubscription] =
    useState(false);
  const [isGettingPaymentPreview, setIsGettingPaymentPreview] = useState(false);
  const [subscriptionPaymentPreview, setSubscriptionPaymentPreview] =
    useState<SubscriptionPaymentPreview>();

  const [
    { addOnSubscriptionId, stripeClientSecret },
    setAddOnSubscriptionInformation,
  ] = useState<{
    addOnSubscriptionId?: string;
    stripeClientSecret?: string;
  }>({});

  const handleCreateAddOnSubscription = useCallback(() => {
    setIsPersistingSubscription(true);
    dispatch(
      createAddOnSubscription.request({
        addOnProductPriceId: price.publicId,
        callback: (payload) => {
          setIsPersistingSubscription(false);
          if (!payload.stripeClientSecret) {
            onSuccess?.(payload.addOnSubscriptionId);
          }
          setAddOnSubscriptionInformation(payload);
        },
        onErrorCallback: () => {
          setErrorMessage(
            'Sorry, there was an error creating your add-on subscription. Please try again later or contact support.'
          );
          setIsPersistingSubscription(false);
        },
      })
    );
  }, [price.publicId, onSuccess]);

  const handleUpdateAddOnSubscription = useCallback(() => {
    if (!existingAddOnSubscriptionId) return;
    setIsPersistingSubscription(true);
    dispatch(
      updateAddOnSubscription.request({
        addOnProductPriceId: price.publicId,
        addOnSubscriptionId: existingAddOnSubscriptionId,
        callback: (payload) => {
          setIsPersistingSubscription(false);
          if (!payload.stripeClientSecret) {
            onSuccess?.(payload.addOnSubscriptionId);
          }
          setAddOnSubscriptionInformation(payload);
        },
        onErrorCallback: () => {
          setErrorMessage(
            'Sorry, there was an error updating your add-on subscription. Please try again later or contact support.'
          );
          setIsPersistingSubscription(false);
        },
      })
    );
  }, [price.publicId, onSuccess, existingAddOnSubscriptionId]);

  const handleGetPaymentPreview = useCallback(() => {
    setIsGettingPaymentPreview(true);
    setSubscriptionPaymentPreview(undefined);
    dispatch(
      getAddOnSubscriptionPaymentPreview.request({
        addOnProductPriceId: price.publicId,
        callback: (payload) => {
          setIsGettingPaymentPreview(false);
          setSubscriptionPaymentPreview(payload);
        },
        onErrorCallback: () => {
          setIsGettingPaymentPreview(false);
        },
      })
    );
  }, [price.publicId]);

  useEffect(() => {
    if (existingAddOnSubscriptionId) {
      handleGetPaymentPreview();
    }
  }, [existingAddOnSubscriptionId]);

  const submitStripeAttempted = useCallback(
    async (confirmPayment: () => Promise<void>) => {
      setErrorMessage(undefined);
      try {
        await confirmPayment();
      } catch (err: any) {
        setErrorMessage(err?.message as string);
      }
    },
    []
  );

  const costs = useMemo(
    () => getCostsForPriceAndQuantity(price, price.initialQuantity ?? 0),
    [price]
  );

  return (
    <div className="create-add-on-subscription-control">
      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body>
          <LabelValuePair
            label="Cost"
            value={currencyPrice(costs.priceExcludingTax, account.region)}
          />
          {!isNullish(price.taxPercentage) && (
            <LabelValuePair
              label={`VAT (${price.taxPercentage}%)`}
              value={currencyPrice(costs.taxAmount, account.region)}
            />
          )}
          <hr />
          <LabelValuePair
            label={`Total cost recurring ${price.billingInterval}ly`}
            value={currencyPrice(costs.priceIncludingTax, account.region)}
            className="text-lg"
          />
        </Card.Body>
      </Card>

      {!!existingAddOnSubscriptionId && (
        <SubscriptionPaymentPreviewView
          isLoading={isGettingPaymentPreview}
          subscriptionPaymentPreview={subscriptionPaymentPreview}
        />
      )}
      {!addOnSubscriptionId && (
        <div className="flex justify-center">
          <Button
            onClick={() =>
              existingAddOnSubscriptionId
                ? handleUpdateAddOnSubscription()
                : handleCreateAddOnSubscription()
            }
            isProcessing={isPersistingSubscription}
          >
            Continue
          </Button>
        </div>
      )}
      {stripeClientSecret && (
        <StripePaymentControl
          stripePaymentIntentClientSecret={stripeClientSecret}
          stripePromise={stripePromise}
          submitStripeAttempted={submitStripeAttempted}
        />
      )}
      {errorMessage && (
        <FeedbackBlock feedbackLevel={FeedbackLevel.ERROR}>
          {errorMessage}
        </FeedbackBlock>
      )}
    </div>
  );
};

export default CreateAddOnSubscriptionControl;
