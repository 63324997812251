import { DocumentSenderContext } from '@payaca/types/accountBrandTypes';
import {
  ClientChangeProposal,
  ClientCustomer,
  ClientDeal,
  ClientInvoice,
  ClientProposal,
  PaymentMethodConfig,
} from '@payaca/types/clientTypes';
import { ClientIdentifier } from '@payaca/types/clientViewLogTypes';
import { EntityType } from '@payaca/types/entity-type';
import {
  InvoiceLine,
  PaymentReconciliationRecord,
} from '@payaca/types/invoiceTypes';
import {
  JobContent,
  JobLineItem,
  JobLineItemGroup,
} from '@payaca/types/jobContentTypes';
import { JobPayment, PaymentMethodType } from '@payaca/types/jobPaymentTypes';
import {
  ClientServicePlan,
  ClientServicePlanDiscountCode,
  ClientServicePlanSubscriptionPayload,
  ServicePlanSubscription,
} from '@payaca/types/service-plans';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { Nullish } from '@payaca/utilities/types';
import { DeclareAsyncAction } from '../types';

export interface State {
  isGettingDocumentSenderContext: boolean;
  documentSenderContext?: DocumentSenderContext;

  isGettingDeal: boolean;
  deal?: ClientDeal;

  isGettingPaymentMethodConfig: boolean;
  paymentMethodConfig?: PaymentMethodConfig;

  isGettingCustomer: boolean;
  customer?: ClientCustomer;

  isGettingJobContents: boolean;
  proposals: { [key: number]: FetchedEntity<ClientProposal> };
  invoices: { [key: number]: FetchedEntity<ClientInvoice> };
  jobContents: { [key: number]: FetchedEntity<JobContent> };
  jobPayments: { [key: number]: FetchedEntity<JobPayment> };
  paymentReconciliationRecords: {
    [key: number]: FetchedEntity<PaymentReconciliationRecord>;
  };

  invoiceLines: { [key: number]: FetchedEntity<InvoiceLine> };
  jobLineItems: { [key: number]: FetchedEntity<JobLineItem> };
  changeProposals: { [key: number]: FetchedEntity<ClientChangeProposal> };
  jobLineItemGroups: { [key: number]: FetchedEntity<JobLineItemGroup> };

  isCreatingStripePaymentIntent: boolean;
  isCancellingStripePaymentIntent: boolean;
  isCreatingPaymentRecord: boolean;
}

export enum ActionType {
  GET_CLIENT_CONTEXT_REQUEST = 'client.getClientContext:request',
  CLEAR_CLIENT_CONTEXT = 'client.clearClientContext',

  GET_DOCUMENT_ORIGIN_CONTEXT_REQUEST = 'client.getDocumentSenderContext:request',
  GET_DOCUMENT_ORIGIN_CONTEXT_SUCCESS = 'client.getDocumentSenderContext:success',
  GET_DOCUMENT_ORIGIN_CONTEXT_FAILURE = 'client.getDocumentSenderContext:failure',
  CLEAR_DOCUMENT_ORIGIN_CONTEXT = 'client.clearDocumentSenderContext',

  GET_PAYMENT_METHOD_CONFIG_REQUEST = 'client.getPaymentMethodConfig:request',
  GET_PAYMENT_METHOD_CONFIG_SUCCESS = 'client.getPaymentMethodConfig:success',
  GET_PAYMENT_METHOD_CONFIG_FAILURE = 'client.getPaymentMethodConfig:failure',
  CLEAR_PAYMENT_METHOD_CONFIG = 'client.clearPaymentMethodConfig',

  GET_DEAL_REQUEST = 'client.getDeal:request',
  GET_DEAL_SUCCESS = 'client.getDeal:success',
  GET_DEAL_FAILURE = 'client.getDeal:failure',
  CLEAR_DEAL = 'client.clearDeal',

  GET_CUSTOMER_REQUEST = 'client.getCustomer:request',
  GET_CUSTOMER_SUCCESS = 'client.getCustomer:success',
  GET_CUSTOMER_FAILURE = 'client.getCustomer:failure',
  CLEAR_CUSTOMER = 'client.clearCustomer',

  GET_PROPOSALS_REQUEST = 'client.getProposals:request',
  GET_INVOICES_REQUEST = 'client.getInvoices:request',
  GET_INVOICE_REQUEST = 'client.getInvoice:request',
  GET_PROPOSALS_SUCCESS = 'client.getProposals:success',
  GET_INVOICES_SUCCESS = 'client.getInvoices:success',
  GET_PROPOSAL_SUCCESS = 'client.getProposal:success',
  GET_INVOICE_SUCCESS = 'client.getInvoice:success',
  GET_PROPOSALS_FAILURE = 'client.getProposals:failure',
  GET_INVOICES_FAILURE = 'client.getInvoices:failure',
  CLEAR_PROPOSALS = 'client.clearProposals',
  CLEAR_INVOICES = 'client.clearInvoices',

  GET_JOB_CONTENTS_REQUEST = 'client.getJobContents:request',
  GET_JOB_CONTENTS_SUCCESS = 'client.getJobContents:success',
  GET_JOB_CONTENTS_FAILURE = 'client.getJobContents:sailure',
  GET_JOB_CONTENT_SUCCESS = 'client.getJobContent:success',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  CLEAR_JOB_CONTENTS = 'client.clearProposals',

  GET_JOB_PAYMENTS_WITH_RECONCILIATION_RECORDS_REQUEST = 'client.getJobPaymentsWithReconciliationRecords:request',
  GET_PAYMENT_RECONCILIATION_RECORD_SUCCESS = 'client.getPaymentReconciliationRecord:success',
  CLEAR_PAYMENT_RECONCILIATION_RECORDS = 'client.clearPaymentReconciliationRecords',
  GET_JOB_PAYMENT_SUCCESS = 'client.getJobPayment:success',
  CLEAR_JOB_PAYMENTS = 'client.clearJobPayments',

  GET_JOB_LINE_ITEMS_FOR_JOB_CONTENT_REQUEST = 'client.getJobLineItemsForJobContent:request',
  GET_JOB_LINE_ITEM_SUCCESS = 'client.getJobLineItem:uccess',
  CLEAR_JOB_LINE_ITEMS = 'client.clearJobLineItems',

  GET_JOB_LINE_ITEM_GROUPS_FOR_JOB_CONTENT_REQUEST = 'client.getJobLineItemGroupsForJobContent:request',
  GET_JOB_LINE_ITEM_GROUP_SUCCESS = 'client.getJobLineItemGroup:success',
  CLEAR_JOB_LINE_ITEM_GROUPS = 'client.clearJobLineItemGroups',

  GET_INVOICE_LINES_FOR_INVOICE_REQUEST = 'client.getInvoiceLinesForInvoice:request',
  GET_INVOICE_LINE_SUCCESS = 'client.getInvoiceLine:success',
  CLEAR_INVOICE_LINES = 'client.clearInvoiceLines',

  CREATE_STRIPE_PAYMENT_INTENT_REQUEST = 'client.createStripePaymentIntent:request',
  CREATE_STRIPE_PAYMENT_INTENT_SUCCESS = 'client.createStripePaymentIntent:success',
  CREATE_STRIPE_PAYMENT_INTENT_FAILURE = 'client.createStripePaymentIntent:failure',

  CANCEL_STRIPE_PAYMENT_INTENT_REQUEST = 'client.cancelStripePaymentIntent:request',
  CANCEL_STRIPE_PAYMENT_INTENT_FAILURE = 'client.cancelStripePaymentIntent:failure',
  CANCEL_STRIPE_PAYMENT_INTENT_SUCCESS = 'client.cancelStripePaymentIntent:success',

  CREATE_PAYMENT_RECORD_REQUEST = 'client.createPaymentRecord:request',
  CREATE_PAYMENT_RECORD_FAILURE = 'client.createPaymentRecord:failure',
  CREATE_PAYMENT_RECORD_SUCCESS = 'client.createPaymentRecord:success',

  GET_CHANGE_PROPOSALS_REQUEST = 'client.getChangeProposals:request',
  GET_CHANGE_PROPOSALS_SUCCESS = 'client.getChangeProposals:success',
  GET_CHANGE_PROPOSALS_FAILURE = 'client.getChangeProposals:failure',

  GET_CHANGE_PROPOSAL_REQUEST = 'client.getChangeProposal:request',
  GET_CHANGE_PROPOSAL_SUCCESS = 'client.getChangeProposal:success',
  GET_CHANGE_PROPOSAL_FAILURE = 'client.getChangeProposal:failure',

  GET_JOB_LINE_ITEMS_REQUEST = 'client.getJobLineItems:request',
  GET_JOB_LINE_ITEMS_SUCCESS = 'client.getJobLineItems:success',
  GET_JOB_LINE_ITEMS_FAILURE = 'client.getJobLineItems:failure',

  GET_GENERATED_PDF_URL_FOR_INVOICE_REQUEST = 'client.getGeneratedPdfUrlForInvoice:request',
  GET_JOB_LINE_ITEMS_FOR_INVOICE_REQUEST = 'client.getJobLineItemsForInvoice:request',
  GET_JOB_LINE_ITEMS_FOR_INVOICE_SUCCESS = 'client.getJobLineItemsForInvoice:success',
  GET_JOB_LINE_ITEMS_FOR_INVOICE_FAILURE = 'client.getJobLineItemsForInvoice:failure',

  ACCEPT_CHANGE_PROPOSAL_REQUEST = 'client.acceptChangeProposal:request',
  DECLINE_CHANGE_PROPOSAL_REQUEST = 'client.declineChangeProposal:request',

  CLEAR_CHANGE_PROPOSALS = 'client.clearChangeProposals',

  RECORD_VIEW_REQUEST = 'client.recordView:request',

  GET_SERVICE_PLAN_PAYLOAD_REQUEST = 'client.getServicePlanPayload:request',
  GET_SERVICE_PLAN_PAYLOAD_SUCCESS = 'client.getServicePlanPayload:success',
  GET_SERVICE_PLAN_PAYLOAD_FAILURE = 'client.getServicePlanPayload:failure',

  GET_DISCOUNT_CODE_BY_CLIENT_CODE_REQUEST = 'client.getDiscountCodeByClientCode:request',
  GET_DISCOUNT_CODE_BY_CLIENT_CODE_SUCCESS = 'client.getDiscountCodeByClientCode:success',
  GET_DISCOUNT_CODE_BY_CLIENT_CODE_FAILURE = 'client.getDiscountCodeByClientCode:failure',

  CREATE_SERVICE_PLAN_SUBSCRIPTION_REQUEST = 'client.createServicePlanSubscription:request',

  SEND_SERVICE_PLAN_SUBSCRIPTION_MANAGE_BILLING_EMAIL_REQUEST = 'client.sendServicePlanSubscriptionManageBillingEmail:request',

  GET_SERVICE_PLAN_SUBSCRIPTION_PAYLOAD_REQUEST = 'client.getServicePlanSubscriptionPayload:request',
  GET_SERVICE_PLAN_SUBSCRIPTION_PAYLOAD_SUCCESS = 'client.getServicePlanSubscriptionPayload:success',

  UPDATE_SERVICE_PLAN_SUBSCRIPTION_STATUS_REQUEST = 'servicePlans.updateServicePlanSubscriptionStatus:request',
  UPDATE_SERVICE_PLAN_SUBSCRIPTION_STATUS_SUCCESS = 'servicePlans.updateServicePlanSubscriptionStatus:success',
  UPDATE_SERVICE_PLAN_SUBSCRIPTION_STATUS_FAILURE = 'servicePlans.updateServicePlanSubscriptionStatus:failure',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
}

export interface CreatePaymentRecordRequestData {
  token: string;
  stripeAccountId?: string;
  paymentIntentId?: string;
  paymentMethod: PaymentMethodType;
  invoiceId?: number;
  paymentValue: number;
}

export type GetChangeProposalsAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_CHANGE_PROPOSALS_REQUEST;
      payload: {
        previewToken: string;
      };
    };
    success: {
      type: ActionType.GET_CHANGE_PROPOSALS_SUCCESS;
      payload: { changeProposals: ClientChangeProposal[] };
    };
    failure: {
      type: ActionType.GET_CHANGE_PROPOSALS_FAILURE;
    };
  }
>;

export type GetChangeProposalAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_CHANGE_PROPOSAL_REQUEST;
      payload: {
        previewToken: string;
        changeProposalId: number;
        callback?: (changeProposal: ClientChangeProposal) => void;
      };
    };
    success: {
      type: ActionType.GET_CHANGE_PROPOSAL_SUCCESS;
      payload: { changeProposal: ClientChangeProposal };
    };
    failure: {
      type: ActionType.GET_CHANGE_PROPOSAL_FAILURE;
    };
  }
>;

export type GetJobLineItemsAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_JOB_LINE_ITEMS_REQUEST;
      payload: {
        previewToken: string;
      };
    };
    success: {
      type: ActionType.GET_JOB_LINE_ITEMS_SUCCESS;
      payload: { jobLineItems: JobLineItem[] };
    };
    failure: {
      type: ActionType.GET_JOB_LINE_ITEMS_FAILURE;
    };
  }
>;

export type GetJobLineItemsForInvoiceAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_JOB_LINE_ITEMS_FOR_INVOICE_REQUEST;
      payload: {
        previewToken: string;
        invoiceId: number;
      };
    };
    success: {
      type: ActionType.GET_JOB_LINE_ITEMS_FOR_INVOICE_SUCCESS;
      payload: {
        jobLineItems: JobLineItem[];
      };
    };
    failure: {
      type: ActionType.GET_JOB_LINE_ITEMS_FOR_INVOICE_FAILURE;
    };
  }
>;

export type GetGeneratedPdfUrlForInvoice = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_GENERATED_PDF_URL_FOR_INVOICE_REQUEST;
      payload: {
        previewToken: string;
        invoiceId: number;
        callback: (pdfUrl: string) => void;
      };
    };
  }
>;

export type AcceptChangeProposalAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.ACCEPT_CHANGE_PROPOSAL_REQUEST;
      payload: {
        previewToken: string;
        changeProposalId: number;
        signatureImage: string;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type DeclineChangeProposalAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.DECLINE_CHANGE_PROPOSAL_REQUEST;
      payload: {
        previewToken: string;
        changeProposalId: number;
        declineReason?: string;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type GetInvoiceAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_INVOICE_REQUEST;
      payload: {
        previewToken: string;
        invoiceId: number;
        callback?: (invoice: ClientInvoice) => void;
      };
    };
    success: {
      type: ActionType.GET_INVOICE_SUCCESS;
      payload: {
        invoiceId: number;
        invoice: ClientInvoice;
      };
    };
  }
>;

export type RecordViewAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.RECORD_VIEW_REQUEST;
      payload: {
        token: string;
        entityType: EntityType;
        entityId: number;
        clientIdentifier?: ClientIdentifier;
      };
    };
  }
>;

type ServicePlanPayload = {
  documentSenderContext: DocumentSenderContext;
  customer: Pick<ClientCustomer, 'name' | 'id'>;
  servicePlan: ClientServicePlan;
};

export type GetServicePlanPayloadAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_SERVICE_PLAN_PAYLOAD_REQUEST;
      payload: {
        offerToken?: string;
        servicePlanId: string;
        callback?: (payload: ServicePlanPayload) => void;
        onErrorCallback?: () => void;
      };
    };
    success: {
      type: ActionType.GET_SERVICE_PLAN_PAYLOAD_SUCCESS;
      payload: ServicePlanPayload;
    };
  }
>;

export type GetServicePlanDiscountCodeByClientCode = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_DISCOUNT_CODE_BY_CLIENT_CODE_REQUEST;
      payload: {
        publicId: string;
        clientCode: string;
        callback?: (discountCode: ClientServicePlanDiscountCode) => void;
        onErrorCallback?: () => void;
      };
      meta: {
        publicId: string;
        clientCode: string;
      };
    };
    success: {
      type: ActionType.GET_DISCOUNT_CODE_BY_CLIENT_CODE_SUCCESS;
      payload: ClientServicePlanDiscountCode;
    };
    failure: { type: ActionType.GET_DISCOUNT_CODE_BY_CLIENT_CODE_FAILURE };
  }
>;

export type CreateServicePlanSubscriptionAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_SERVICE_PLAN_SUBSCRIPTION_REQUEST;
      payload: {
        offerToken?: Nullish<string>;
        servicePlanPriceId: string;
        servicePlanDiscountCodePublicId?: Nullish<string>;
        customer?: Nullish<{
          name: string;
          emailAddress: string;
          telephoneNumber: string;
        }>;
        callback?: (payload: {
          servicePlanSubscriptionId: string;
          stripeClientSecret: string;
          stripeAccountId: string;
        }) => void;
      };
    };
  }
>;

export type SendServicePlanSubscriptionManageBillingEmailAction =
  DeclareAsyncAction<
    ActionType,
    {
      request: {
        type: ActionType.SEND_SERVICE_PLAN_SUBSCRIPTION_MANAGE_BILLING_EMAIL_REQUEST;
        payload: {
          servicePlanSubscriptionId: ServicePlanSubscription['id'];
          callback?: () => void;
          onErrorCallback?: () => void;
        };
      };
    }
  >;

export type GetServicePlanSubscriptionPayloadAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_PAYLOAD_REQUEST;
      payload: {
        servicePlanSubscriptionId: ServicePlanSubscription['id'];
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
    success: {
      type: ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_PAYLOAD_SUCCESS;
      payload: ClientServicePlanSubscriptionPayload;
    };
  }
>;

export type UpdateServicePlanSubscriptionStatus = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_SERVICE_PLAN_SUBSCRIPTION_STATUS_REQUEST;
      payload: {
        servicePlanSubscriptionPublicId: ServicePlanSubscription['publicId'];
        status: 'pending';
      };
    };
  }
>;
