import MomentUtils from '@date-io/moment';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { FunctionComponent } from 'react';

import FieldLabel from '../fieldLabel/FieldLabel';
import InputWrapper, {
  InputStyleVariant,
  InputWidth,
} from '../inputWrapper/InputWrapper';

import { useFieldId } from '@payaca/hooks/useFieldId';

import './DateField.sass';

type TProps = {
  styleVariant?: InputStyleVariant;
  inputWidth?: InputWidth;
  name: string;
  value?: Date;
  label?: string | JSX.Element;
  description?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  iconBefore?: IconDefinition;
  iconAfter?: IconDefinition;
  onChange?: (value: { [key: string]: Date }) => void;
  onTouch?: (fieldName: string) => void;
  additionalPickerProps?: { [key: string]: any };
};

const DateField: FunctionComponent<React.PropsWithChildren<TProps>> = ({
  styleVariant,
  inputWidth,
  name,
  value,
  label,
  description,
  isDisabled = false,
  isRequired = false,
  iconBefore = faCalendarAlt,
  iconAfter,
  onChange,
  onTouch,
  additionalPickerProps = {},
}: React.PropsWithChildren<TProps>): JSX.Element => {
  const id = useFieldId(name);

  return (
    <div className="date-field">
      <FieldLabel
        label={label}
        isRequired={isRequired}
        description={description}
        id={id}
      />
      <InputWrapper
        isDisabled={isDisabled}
        inputWidth={inputWidth}
        styleVariant={styleVariant}
      >
        {iconBefore && (
          <FontAwesomeIcon
            className={'input-wrapper__pre-icon'}
            icon={iconBefore}
          />
        )}
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            disabled={isDisabled}
            value={value ?? null}
            onChange={(date: any) => {
              onChange && onChange({ [name]: new Date(date) });
              onTouch && onTouch(name);
            }}
            format="DD MMMM YYYY"
            fullWidth
            InputProps={{
              disableUnderline: true,
              id: `${id}`,
              className: `input-wrapper__input`,
            }}
            {...additionalPickerProps}
          />
        </MuiPickersUtilsProvider>
        {iconAfter && (
          <FontAwesomeIcon
            className={'input-wrapper__post-icon'}
            icon={iconAfter}
          />
        )}
      </InputWrapper>
    </div>
  );
};

export default DateField;
