import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { FunctionComponent, PropsWithChildren, useState } from 'react';

import { Collapse } from '@material-ui/core';
import Button from '../button/Button';
import { ButtonStyleVariant } from '../button/enums';
import './FeedbackBlock.sass';

type Props = {
  feedbackLevel?: FeedbackLevel;
  className?: string;
  isDismissable?: boolean;
  onDismissed?: () => void;
};

const FeedbackBlock: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  feedbackLevel = FeedbackLevel.NEUTRAL,
  isDismissable,
  className,
  onDismissed,
}: PropsWithChildren<Props>): JSX.Element | null => {
  const [isDismissRequired, setIsDismissRequired] = useState(false);

  return (
    <Collapse
      className="collapsible-feedback-block"
      appear={true}
      in={!isDismissRequired}
      onExited={onDismissed}
    >
      <div
        className={`feedback-block feedback-level-${feedbackLevel} ${
          className ? className : ''
        }`}
      >
        <div>{children}</div>
        {isDismissable && (
          <Button
            className="dismiss-trigger"
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => setIsDismissRequired(true)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </div>
    </Collapse>
  );
};

export default FeedbackBlock;
