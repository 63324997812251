import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { UpdateJobLineItemRequestData } from '@payaca/store/jobContent/jobContentTypes';

import BasicField from '@payaca/components/basicField/BasicField';
import JobLineItemCard from '../jobLineItemCard/JobLineItemCard';

import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import {
  useAcceptedAndDraftJobLineItemAndAmendments,
  useChangeProposal,
  useJobLineItem,
} from '@payaca/store/hooks/appState';
import {
  clearJobLineItem,
  createJobLineItemFromAmendmentParent,
  requestDeleteJobLineItem,
  requestGetJobLineItem,
  requestUpdateJobLineItem,
} from '@payaca/store/jobContent/jobContentActions';
import { requestGetChangeProposal } from '@payaca/store/proposals/proposalsActions';

type Props = {
  jobLineItemId: number;
  changeProposalId: number;
};

const AcceptedJobLineItemEditControl: FC<Props> = ({
  jobLineItemId,
  changeProposalId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const jobLineItem = useJobLineItem(jobLineItemId);
  const changeProposal = useChangeProposal(changeProposalId);

  const jobLineItemAndAmendments = useAcceptedAndDraftJobLineItemAndAmendments(
    jobLineItemId,
    changeProposalId
  );
  const [quantityAmendment, setQuantityAmendment] = useState(0);
  const [requiresPersist, setRequiresPersist] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const currentAmendmentJobLineItem = useMemo(() => {
    return jobLineItemAndAmendments.find((x) => {
      return x.changeProposalId === changeProposalId;
    });
  }, [jobLineItemAndAmendments, changeProposalId]);

  const acceptedCumulativeQuantity = useMemo(() => {
    return jobLineItemAndAmendments.reduce((acc, jli) => {
      if (jli.changeProposalId === changeProposalId) return acc;
      return acc + jli.quantity;
    }, 0);
  }, [jobLineItemAndAmendments]);

  const cumulativeQuantity = useMemo(() => {
    return jobLineItemAndAmendments.reduce((acc, jli) => {
      return acc + jli.quantity;
    }, 0);
  }, [jobLineItemAndAmendments]);

  useEffect(() => {
    if (currentAmendmentJobLineItem) {
      setQuantityAmendment(currentAmendmentJobLineItem.quantity);
    }
  }, [currentAmendmentJobLineItem?.id]);

  useEffect(() => {
    if (!isProcessing) {
      setQuantityAmendment(currentAmendmentJobLineItem?.quantity || 0);
    }
  }, [currentAmendmentJobLineItem?.quantity]);

  const handlePersist = useCallback(() => {
    if (quantityAmendment === 0) {
      if (currentAmendmentJobLineItem) {
        setIsProcessing(true);
        dispatch(
          requestDeleteJobLineItem(currentAmendmentJobLineItem.id, () => {
            setIsProcessing(false);
            dispatch(requestGetChangeProposal(changeProposalId));
            dispatch(clearJobLineItem(currentAmendmentJobLineItem.id));
            changeProposal && dispatch(requestGetDeal(changeProposal.dealId));
          })
        );
      }
    } else if (currentAmendmentJobLineItem) {
      setIsProcessing(true);
      dispatch(
        requestUpdateJobLineItem(
          {
            jobLineItemId: currentAmendmentJobLineItem.id,
            quantity: quantityAmendment,
            description: currentAmendmentJobLineItem.description,
            reference: currentAmendmentJobLineItem.name,
            price: currentAmendmentJobLineItem.price,
            taxRateId: currentAmendmentJobLineItem.taxRateId,
            cisApplies: !!currentAmendmentJobLineItem.cisDeductionRate,
            lineItemId: currentAmendmentJobLineItem.lineItemId,
          } as UpdateJobLineItemRequestData,
          () => {
            dispatch(
              requestGetJobLineItem(currentAmendmentJobLineItem.id, () =>
                setIsProcessing(false)
              )
            );
            dispatch(requestGetChangeProposal(changeProposalId));
            setIsProcessing(false);
          }
        )
      );
    } else {
      setIsProcessing(true);
      dispatch(
        createJobLineItemFromAmendmentParent.request({
          data: {
            changeProposalId: changeProposalId,
            quantity: quantityAmendment,
            amendmentParentJobLineItemId: jobLineItemId,
          },
          callback: (jliId: number) => {
            setIsProcessing(false);
            dispatch(requestGetJobLineItem(jliId));
            dispatch(requestGetChangeProposal(changeProposalId));
            changeProposal && dispatch(requestGetDeal(changeProposal.dealId));
          },
        })
      );
    }
  }, [
    currentAmendmentJobLineItem?.id,
    quantityAmendment,
    changeProposalId,
    jobLineItemId,
    changeProposal?.dealId,
  ]);

  useEffect(() => {
    if (requiresPersist && !isProcessing) {
      setRequiresPersist(false);
      handlePersist();
    }
  }, [requiresPersist, isProcessing]);

  if (!jobLineItem) return null;

  return (
    <div>
      <JobLineItemCard
        jobLineItem={{ ...jobLineItem, quantity: cumulativeQuantity }}
        quantitySectionComponent={
          <div>
            <span>Qty</span>
            <BasicField
              // need to keep this element uncontrolled by passing defaultValue rather than value so that it will work in safari and ff
              // value={acceptedCumulativeQuantity + quantityAmendment}
              name="quantity"
              type="number"
              onChange={(value) => {
                if (value.quantity?.length) {
                  setQuantityAmendment(
                    Number(value.quantity) - acceptedCumulativeQuantity
                  );
                }
              }}
              additionalInputProps={{
                defaultValue:
                  acceptedCumulativeQuantity +
                  (currentAmendmentJobLineItem?.quantity || 0),
                step: 1,
              }}
              onChangeTimeout={() => setRequiresPersist(true)}
            />
          </div>
        }
      />
    </div>
  );
};
export default AcceptedJobLineItemEditControl;
