// ======================================================================================================
// Social login button
// - Button for socials, on register/login pages
// ======================================================================================================

import { useGoogleLogin } from '@react-oauth/google';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';

import { LoginProvider } from '@/helpers/providerHelper';

import * as authActions from '@payaca/store/auth/authActions';

import {
  IndustryTypes,
  NumberOfEmployees,
} from '@payaca/types/companyInformationTypes';

import { useSelector } from '@/api/state';

import './SocialAuthButtons.sass';

const XERO_CLIENT_ID = import.meta.env.VITE_XERO_CLIENT_ID;

interface Props {
  providers?: string[];
  isLogin: boolean;
  additionalPayload?: {
    numberOfEmployees?: NumberOfEmployees;
    industryType?: IndustryTypes | null;
    industryTypeOther?: string;
    referralToken?: string | null;
  };
}

const SocialAuthButtons: FunctionComponent<Props> = ({
  providers = [LoginProvider.GOOGLE, LoginProvider.APPLE, LoginProvider.XERO],
  isLogin,
  additionalPayload = {},
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isSubmittedProvider, setIsSubmittedProvider] = useState<string | null>(
    null
  );
  const [error, setError] = useState<null | string>(null);
  const isLoggingIn = useSelector((state) => state.auth.isLoggingIn);
  const isSigningUp = useSelector((state) => state.auth.isSigningUp);
  const isLoggedInSuccessfully = useSelector(
    (state) => state.auth.isLoggedInSuccessfully
  );

  useEffect(() => {
    if (
      isLogin &&
      isSubmittedProvider &&
      !isLoggingIn &&
      !isLoggedInSuccessfully
    ) {
      setError(
        `Sorry, there was an error logging you in with ${isSubmittedProvider}`
      );
    }
  }, [isLogin, isSubmittedProvider, isLoggingIn, isLoggedInSuccessfully]);

  const isProcessing = useCallback(
    (provider?: string) =>
      (provider ? isSubmittedProvider === provider : true) &&
      (isLogin ? isLoggingIn : isSigningUp),
    [isLogin, isSubmittedProvider, isLoggingIn, isSigningUp]
  );

  const loginWithGoogle = useCallback(
    (data: any) => {
      setError(null);
      setIsSubmittedProvider(null);

      const payload = {
        accessToken: data.accessToken,
        ...additionalPayload,
      };
      setIsSubmittedProvider(LoginProvider.GOOGLE);
      if (isLogin) {
        dispatch(authActions.requestLoginWithGoogle(payload));
      } else {
        dispatch(
          authActions.requestSignUpWithGoogle(payload, (error) => {
            if (error) {
              setError(`Sorry, there was an error signing you up with Google`);
            }
          })
        );
      }
    },
    [isLogin, additionalPayload, dispatch]
  );

  const onClickGoogleButton = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      loginWithGoogle({
        accessToken: tokenResponse.access_token,
      });
    },
  });

  const renderGoogleButton = useMemo(() => {
    return (
      <Button
        isProcessing={isProcessing(LoginProvider.GOOGLE)}
        hasBoxShadow={true}
        styleVariant={ButtonStyleVariant.STANDARD}
        colourVariant={ButtonColourVariant.WHITE}
        onClick={() => !isProcessing() && onClickGoogleButton()}
      >
        {LoginProvider.GOOGLE}
      </Button>
    );
  }, [isProcessing, loginWithGoogle]);

  const renderAppleButton = useMemo(() => {
    return (
      isLogin && (
        <Button
          isProcessing={isProcessing(LoginProvider.APPLE)}
          hasBoxShadow={true}
          styleVariant={ButtonStyleVariant.STANDARD}
          colourVariant={ButtonColourVariant.WHITE}
          // @ts-ignore
          onClick={() => !isProcessing() && window.AppleID.auth.signIn()}
          // TODO: include industry and number of employees
        >
          {LoginProvider.APPLE}
        </Button>
      )
    );
  }, [isLogin, isProcessing]);

  const renderXeroButton = useMemo(() => {
    const state = Object.keys(additionalPayload)
      .map((key: string) => {
        return `${encodeURIComponent(key)}:${encodeURIComponent(
          (additionalPayload as any)[key]
        )}`;
      })
      .join(',');
    return (
      <Button
        isProcessing={isProcessing(LoginProvider.XERO)}
        hasBoxShadow={true}
        styleVariant={ButtonStyleVariant.STANDARD}
        colourVariant={ButtonColourVariant.WHITE}
        onClick={() =>
          (window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${XERO_CLIENT_ID}&redirect_uri=${
            window.location.origin
          }/xero/callback&scope=openid profile email accounting.contacts accounting.settings accounting.transactions offline_access&state=${state}${
            isLogin ? '_login' : '_register'
          }`)
        }
      >
        {LoginProvider.XERO}
      </Button>
    );
  }, [additionalPayload, isLogin, isProcessing]);

  return (
    <div className="social-auth-buttons-container">
      <div className="social-auth-buttons-inner-container">
        {providers.find((p) => p === LoginProvider.GOOGLE) &&
          renderGoogleButton}
        {providers.find((p) => p === LoginProvider.XERO) && renderXeroButton}
        {providers.find((p) => p === LoginProvider.APPLE) && renderAppleButton}
      </div>
      {error && <ErrorMessage message={error} />}
    </div>
  );
};

export default SocialAuthButtons;
