import { FC, useCallback, useMemo } from 'react';

import { getAddressAsString } from '@payaca/helpers/locationHelper';

import { Address } from '@payaca/types/locationTypes';

import Badge from '@payaca/components/badge/Badge';
import { Contact } from '@payaca/types/contactTypes';
import ContactDetailInfo from '../contactDetailInfo/ContactDetailInfo';

import './AddressInfo.sass';

type Props = {
  address: Address;
  contacts: Contact[];
  description?: string;
  badgeLabel?: string | null;
};
const AddressInfo: FC<Props> = ({
  address,
  contacts,
  description,
  badgeLabel,
}: Props): JSX.Element => {
  const stringAddress = useMemo(() => {
    return getAddressAsString(address);
  }, [address]);

  const renderAddressContact = useCallback((contact: Contact) => {
    if (Object.values(contact)?.length) {
      return <ContactDetailInfo contact={contact} />;
    }
  }, []);

  return (
    <div className="address-info">
      <a
        href={`https://maps.google.com/?q=${stringAddress}`}
        target="_blank"
        rel="noreferrer"
      >
        {description && (
          <span>
            {description}
            {' | '}
          </span>
        )}
        {stringAddress || '-'}
        {badgeLabel && <Badge>{badgeLabel}</Badge>}
      </a>
      {!!contacts?.length && contacts.map(renderAddressContact)}
    </div>
  );
};

export default AddressInfo;
