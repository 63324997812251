// ======================================================================================================
// Routes and redirects
// - Creates multiple routes and redirects
// ======================================================================================================

import { withSentryRouting } from '@sentry/react';
import { values } from 'lodash-es';
import { Redirect, Switch, Route as UntrackedRoute } from 'react-router';

const Route = withSentryRouting(UntrackedRoute);

const RoutesAndRedirects = ({
  availableRedirects,
  availableRoutes,
  matchPath,
}) => {
  const fullPath = (path) => `${matchPath}${path}`;
  return (
    <Switch>
      {/** Routes */}
      {values(availableRoutes).map((route) => {
        const key = `route-${route.path}`;
        return route.component ? (
          <Route
            key={key}
            exact={route.exact === undefined ? true : route.exact}
            path={fullPath(route.path)}
            component={route.component}
          />
        ) : (
          <Route
            key={key}
            exact={route.exact === undefined ? true : route.exact}
            path={fullPath(route.path)}
            render={route.renderComponent}
          />
        );
      })}
      {/** Redirects */}
      {values(availableRedirects).map((redirect) => {
        return redirect.fromPaths.map((path) => {
          const key = `redirect-${path}`;
          const toPath = fullPath(redirect.toPath);
          const fromPath = fullPath(path);
          return redirect.component ? (
            <Route
              key={key}
              from={fromPath}
              to={toPath}
              component={redirect.component}
            />
          ) : (
            <Route exact path={fromPath} key={key}>
              <Redirect to={toPath} />
            </Route>
          );
        });
      })}
    </Switch>
  );
};

export default RoutesAndRedirects;
