import { useAccount } from '@/utils/storeHooks';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { StatusBadgeState } from '@payaca/helpers/statusBadgeHelper';
import { PublicHydratedAddOnSubscription } from '@payaca/types/add-on-products';
import { isNullish } from '@payaca/utilities/guards';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import SubscriptionProductControl from '../subscriptionProductControl/SubscriptionProductControl';

import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import { getCostsForPriceAndQuantity } from '@payaca/helpers/addOnProductsHelper';
import * as subscriptionActions from '@payaca/store/subscription/subscriptionActions';
import { restoreAddOnSubscription } from '@payaca/store/subscription/subscriptionActions';
import ConfirmModal from '../modal/ConfirmModal';

interface Props {
  addOnSubscription: PublicHydratedAddOnSubscription;
  onUpdateSubscriptionSuccess?: () => void;
}

const AddOnSubscriptionControl: FC<Props> = ({
  addOnSubscription,
  onUpdateSubscriptionSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const product = addOnSubscription.addOnProduct;
  const price = addOnSubscription.addOnProductPrice;
  const account = useAccount();

  const [showConfirmDisableAddOnModal, setShowConfirmDisableAddOnModal] =
    useState(false);

  const productCostInformation = useMemo(() => {
    if (!price) return [];

    const costs = getCostsForPriceAndQuantity(
      price,
      addOnSubscription.quantity ?? 0
    );

    return [
      `${currencyPrice(costs.priceExcludingTax, account.region)} + VAT${
        !isNullish(price.billingInterval) ? ` per ${price.billingInterval}` : ''
      }`,
    ];
  }, [price, addOnSubscription.quantity, account.region]);

  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);

  const handleCancelAddOnSubscription = useCallback(() => {
    setIsUpdatingSubscription(true);
    dispatch(
      subscriptionActions.cancelAddOnSubscription.request({
        addOnSubscriptionId: addOnSubscription.publicId,
        callback: () => {
          onUpdateSubscriptionSuccess?.();
          setIsUpdatingSubscription(false);
        },
        onErrorCallback: () => {
          showDynamicFeedbackMessage({
            body: 'Something went wrong cancelling your add-on subscription. Please try again later or contact support.',
          });
          setIsUpdatingSubscription(false);
        },
      })
    );
  }, [addOnSubscription.publicId, onUpdateSubscriptionSuccess]);

  const handleRestoreAddOnSubscription = useCallback(() => {
    setIsUpdatingSubscription(true);
    dispatch(
      restoreAddOnSubscription.request({
        addOnSubscriptionPublicId: addOnSubscription.publicId,
        callback: (err) => {
          if (err) {
            showDynamicFeedbackMessage({
              body: 'Something went wrong restoring your add-on subscription. Please try again later or contact support.',
            });
          } else {
            onUpdateSubscriptionSuccess?.();
          }
          setIsUpdatingSubscription(false);
        },
      })
    );
  }, [addOnSubscription.publicId, onUpdateSubscriptionSuccess]);

  const [isUpdatingSubscription, setIsUpdatingSubscription] = useState(false);

  return (
    <ContentPanel>
      <SubscriptionProductControl
        statusBadgeConfig={
          addOnSubscription.status === 'pending'
            ? {
                status: 'pending',
                state: StatusBadgeState.AMBER_PENDING,
              }
            : undefined
        }
        isBusy={isUpdatingSubscription}
        title={product?.name || ''}
        onCancel={() => setShowConfirmDisableAddOnModal(true)}
        onRestoreSubscription={handleRestoreAddOnSubscription}
        subscriptionEndsAt={addOnSubscription.validTo || undefined}
        cancelText="Disable add-on"
        productFeatures={productCostInformation}
      />
      <ConfirmModal
        title="Disable Service Plans"
        text="When the current subscription ends, you will lose access to the Service Plans page and the ability to manage Commitments. Are you sure you want to disable Service Plans?"
        onClose={() => setShowConfirmDisableAddOnModal(false)}
        primaryText="Yes, disable Service Plans"
        primaryAction={() => handleCancelAddOnSubscription()}
        secondaryText="Don't disable Service Plans"
        secondaryAction={() => setShowConfirmDisableAddOnModal(false)}
        open={showConfirmDisableAddOnModal}
      />
    </ContentPanel>
  );
};

export default AddOnSubscriptionControl;
