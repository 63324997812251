import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import supplierKeys from './keyFactory';

const GetSupplier = graphql(`
  query GetSupplier($id: ID!) {
    supplier(id: $id) {
      id
      name
    }
  }
`);

const useGetSupplier = (supplierId?: string) => {
  const { data, ...rest } = useQuery({
    queryKey: supplierKeys.supplier(supplierId!),
    queryFn: () => {
      return gqlClient.request(GetSupplier, {
        id: supplierId!,
      });
    },
    enabled: !!supplierId,
  });

  return { supplier: data?.supplier, data, ...rest };
};

export default useGetSupplier;
