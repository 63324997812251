import { FC } from 'react';
import './PaidStamp.sass';

const PaidStamp: FC = (): JSX.Element => {
  return (
    <div className="paid-stamp">
      <div className="stamp">
        <div className="stamp-inner">PAID</div>
      </div>
    </div>
  );
};

export default PaidStamp;
