import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import * as dealActions from '@payaca/store/deals/dealsActions';

import ListedDealsPipeline from '@/ui/components/listedDealsPipeline/ListedDealsPipeline';
import ListedDealsTable from '@/ui/components/listedDealsTable/ListedDealsTable';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import { ListedDealViews, SortBy } from '@payaca/types/listedDealTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

import { useTranslation } from '@/i18n';
import ProjectsContextProvider, {
  ProjectContextType,
  ProjectsContext,
} from '@/ui/components/contextProviders/ProjectsContextProvider';
import ProjectFilters from '@/ui/components/projectFilters/ProjectFilters';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';
import { Helmet } from 'react-helmet';
import './ListedDealsPage.sass';

type Props = {
  showArchivedDeals?: boolean;
};

const ListedDealsPage: FC<Props> = ({
  showArchivedDeals,
}: Props): JSX.Element => {
  const history = useHistory();
  const translate = useTranslation();
  const location = useLocation();

  const initialRequestDataSearchParam = getUrlSearchParam(
    location.search,
    'initialRequestData'
  );

  const initialRequestData = useMemo(() => {
    if (!initialRequestDataSearchParam?.length) return;

    try {
      return JSON.parse(
        decodeURIComponent(initialRequestDataSearchParam)
      ) as ProjectContextType['requestData'];
    } catch (e) {
      return;
    }
  }, [initialRequestDataSearchParam]);

  return (
    <>
      <ProjectsContextProvider
        archived={showArchivedDeals}
        initialRequestData={initialRequestData}
      >
        <Helmet title={translate('pages.deals.title')} />
        <AuthenticatedPageWrapper
          pageBanners={
            showArchivedDeals
              ? [
                  <div key="0" className="archived-deals-banner">
                    <span>
                      You are currently viewing your archived Projects
                    </span>
                    <Button
                      onClick={() => history.push('/deals')}
                      styleVariant={ButtonStyleVariant.ANCHOR}
                      colourVariant={ButtonColourVariant.SECONDARY}
                    >
                      Go back
                    </Button>
                  </div>,
                ]
              : undefined
          }
          className="listed-deals-page"
          title={<ProjectFilters />}
        >
          <div className="listed-deals-table-container">
            <Body />
          </div>
        </AuthenticatedPageWrapper>
      </ProjectsContextProvider>
    </>
  );
};

export default ListedDealsPage;

const Body: FC = () => {
  const { displayType } = useContext(ProjectsContext);

  if (displayType === ListedDealViews.TABLE) {
    return <ProjectsTable />;
  } else {
    return <ProjectsPipeline />;
  }
};

const ProjectsPipeline: FC = () => {
  const { requestData: baseRequestData } = useContext(ProjectsContext);

  const requestData = useMemo(() => {
    return {
      ...baseRequestData,
      sortBy: SortBy.LAST_UPDATED,
      sortDirection: SortDirection.DESCENDING,
    };
  }, [baseRequestData]);

  return (
    <ListedDealsPipeline
      getListedDealsRequestData={requestData}
      selectedPipelineId={baseRequestData.pipelineId || null}
    />
  );
};

const ProjectsTable: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { requestData: baseRequestData } = useContext(ProjectsContext);

  const [requestData, setRequestData] = useState({
    ...baseRequestData,
    sortBy: SortBy.LAST_UPDATED,
    sortDirection: SortDirection.DESCENDING,
    pageNumber: 1,
    pageSize: 20,
  });

  useEffect(() => {
    setRequestData((rd) => ({ ...rd, ...baseRequestData, pageNumber: 1 }));
  }, [baseRequestData]);

  useEffect(() => {
    const { assignedUserIds, ...rd } = requestData;

    dispatch(
      dealActions.requestGetListedDealsPage({
        ...rd,
        userAssignments: assignedUserIds?.map((x) =>
          x === -1 ? 'unassigned' : x
        ),
      })
    );
  }, [requestData]);

  return (
    <ListedDealsTable
      sortBy={requestData.sortBy}
      sortDirection={requestData.sortDirection}
      onClickRow={(dealId) => history.push(`/deals/${dealId}`)}
      onSelectPage={(pageNumber) =>
        setRequestData((rd) => ({ ...rd, pageNumber }))
      }
      onChangeSortOrder={(sortBy, sortDirection) =>
        setRequestData((rd) => ({ ...rd, sortBy, sortDirection }))
      }
      onSelectPageSize={(pageSize) =>
        setRequestData((rd) => ({ ...rd, pageSize }))
      }
    />
  );
};
