import flatMap from 'lodash.flatmap';

import { ChangeProposal } from '@payaca/types/changeProposalTypes';
import { Deal } from '@payaca/types/dealTypes';
import { Invoice, InvoiceLine } from '@payaca/types/invoiceTypes';
import {
  JobContentTotals,
  JobLineItemBase,
} from '@payaca/types/jobContentTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import { BaseJob, Job } from '@payaca/types/jobTypesV2';
import { isAnyUninvoicedValue } from './invoiceHelper';
import { getTotalCompletedPaymentValueFromJobPayments } from './jobPaymentHelper';
import { isInvoice } from './jobStatusHelper';

const canCreateInvoiceForProposal = (proposal: BaseJob) => {
  if (proposal.inactivatedAt || proposal.archivedAt) return false;
  if (isInvoice(proposal.status)) return false;

  if (!proposal) return false;
  return true;
};

const getTotalRequestedPaymentValueForDeal = (
  jobsBelongingToDeal: BaseJob[],
  activeJobContent: JobContentTotals
) => {
  const activeDealJobs = jobsBelongingToDeal.filter(
    (x: BaseJob) => !x.inactivatedAt && !x.archivedAt
  );
  const activeInvoices = activeDealJobs.filter((x: BaseJob) =>
    isInvoice(x.status)
  );

  if (
    activeInvoices.length &&
    activeInvoices.some((x: BaseJob) => !x.isPartialInvoice)
  ) {
    return activeJobContent.total;
  }

  const depositAmount = activeJobContent.calculatedDepositAmount || 0;
  const invoicedAmount = activeInvoices.reduce(
    (accumulator, x: BaseJob) => accumulator + (x.invoiceValue || 0),
    0
  );

  return depositAmount + invoicedAmount;
};

export const canSetCustomerForDeal = (jobsBelongingToDeal: BaseJob[]) => {
  return !jobsBelongingToDeal.some((job: BaseJob) => !!job.sentAt);
};

export const getActiveJobForDeal = (jobsBelongingToDeal: BaseJob[]) => {
  const filteredJobs = jobsBelongingToDeal.filter(
    (x) => !x.inactivatedAt && !x.archivedAt
  );

  if (!filteredJobs || !filteredJobs.length) return;
  //...sort jobs by createdat date, most recently created first
  const sortedJobs = filteredJobs.sort((j1: BaseJob, j2: BaseJob) =>
    j1.createdAt > j2.createdAt ? -1 : 1
  );
  const invoice = sortedJobs.find((x) => isInvoice(x.status));
  if (invoice) return invoice;

  return sortedJobs[0];
};

export const getActiveProposalForDeal = (jobsBelongingToDeal: BaseJob[]) => {
  const activeDealJobs = jobsBelongingToDeal.filter(
    (x: BaseJob) => !x.inactivatedAt && !x.archivedAt
  );
  return activeDealJobs.find((x: BaseJob) => !isInvoice(x.status));
};

export const canCreateFullInvoiceForDeal = (jobsBelongingToDeal: BaseJob[]) => {
  const invoices = jobsBelongingToDeal.filter((x: BaseJob) =>
    isInvoice(x.status)
  );
  /* eslint-disable-next-line */
  if (!!invoices.length) return false;

  const activeProposal = getActiveProposalForDeal(jobsBelongingToDeal);
  return !!activeProposal && canCreateInvoiceForProposal(activeProposal);
};

export const canCreatePartialInvoiceForDeal = (
  jobsBelongingToDeal: BaseJob[],
  activeJobContent: JobContentTotals,
  jobPayments: JobPayment[] = []
) => {
  const activeProposal = getActiveProposalForDeal(jobsBelongingToDeal);
  if (!activeProposal) return false;

  // If a deal has any unsent invoices, don't allow a partial invoice to be raised
  if (
    jobsBelongingToDeal.some(
      (x: BaseJob) =>
        !x.sentAt && isInvoice(x.status) && !x.inactivatedAt && !x.archivedAt
    )
  )
    return false;

  const totalRequestedPaymentValue = getTotalRequestedPaymentValueForDeal(
    jobsBelongingToDeal,
    activeJobContent
  );

  // If the full value of deal has already been invoiced for, don't allow creation of any additional partial invoices
  if (totalRequestedPaymentValue >= activeJobContent.total) return false;

  // If the requested value of the deal so far has not been paid, don't allow the creation of any additional partial invoices
  const totalCompletedPaymentValue =
    getTotalCompletedPaymentValueFromJobPayments(jobPayments);
  if (totalCompletedPaymentValue < totalRequestedPaymentValue) return false;

  return canCreateInvoiceForProposal(activeProposal);
};

export const canCreateInvoiceForDeal = (
  acceptedJobLineItems: JobLineItemBase[],
  existingInvoices: Invoice[],
  existingInvoiceLines: InvoiceLine[]
) => {
  const unvoidedInvoices = existingInvoices.filter((x) => !x.voidedAt);
  const unvoidedInvoiceLines = flatMap(unvoidedInvoices, (x) =>
    x.invoiceLineIds
      .map((y) => existingInvoiceLines.find((z) => z.id === y))
      .filter(Boolean)
  ) as InvoiceLine[];

  return isAnyUninvoicedValue(acceptedJobLineItems, unvoidedInvoiceLines);
};

export const canAddProposalToDeal = (deal?: Deal) => {
  if (!deal) {
    return false;
  }
  if (deal.version < 2) {
    return !deal?.archivedAt && !deal.propositionIds?.length;
  } else {
    return (
      !!deal &&
      !deal.archivedAt &&
      !deal.propositionIds?.length &&
      !deal.invoiceIds?.length
    );
  }
};

export const canAddChangeProposalToDeal = (
  deal: Deal | undefined,
  proposals: Job[],
  changeProposals: ChangeProposal[]
) => {
  if (!deal) {
    return false;
  }
  if (deal.version >= 2) {
    if (deal.archivedAt) return false;
    const acceptedProposal = acceptedProposalOnDeal(proposals);

    if (!acceptedProposal) return false;

    const pendingChangeProposal = changeProposals.find(
      (x) => !x.acceptedAt && !x.declinedAt && !x.voidedAt
    );

    if (pendingChangeProposal) return false;

    return true;
  } else {
    return false;
  }
};

export const acceptedProposalOnDeal = (proposals: Job[]) => {
  return proposals.find(
    (x) => x.acceptedAt && !x.archivedAt && !x.inactivatedAt
  );
};

export const buildEmailSubjectPrefixForProjectFromProject = (project: Deal) => {
  return buildEmailSubjectPrefixForProject({
    reference: project.customReference || project.reference?.toString() || '',
    siteAddress: {
      line1: project.siteAddresses?.[0]?.address?.line1 || undefined,
      line2: project.siteAddresses?.[0]?.address?.line2 || undefined,
    },
  });
};

export const buildEmailSubjectPrefixForProject = (project: {
  reference: string;
  siteAddress?: { line1?: string; line2?: string };
}) => {
  const addressLine = [project.siteAddress?.line1, project.siteAddress?.line2]
    .filter((elem) => {
      return !!elem?.length;
    })
    .join(', ');

  return `Ref: ${project.reference}${
    addressLine.length ? ` - ${addressLine}` : ``
  } - `;
};
