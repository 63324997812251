import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FC, PropsWithChildren } from 'react';

import Button from '../button/Button';
import { ButtonStyleVariant } from '../button/enums';
import IconButton from '../button/IconButton';

import './ProgressiveDisclosure.sass';

type Props = {
  isRevealed: boolean;
  setIsRevealed: () => void;
  showCloseButton?: boolean;
  title: string;
};
const ProgressiveDisclosure: FC<PropsWithChildren<Props>> = ({
  children,
  isRevealed,
  setIsRevealed,
  showCloseButton = false,
  title,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <div className={`progressive-disclosure${isRevealed ? ' revealed' : ''}`}>
      <Button
        styleVariant={ButtonStyleVariant.ANCHOR}
        onClick={setIsRevealed}
        className="reveal-button"
      >
        {title}
      </Button>
      <div className="body">
        {showCloseButton && <IconButton icon={faTimes} />}
        {children}
      </div>
    </div>
  );
};

export default ProgressiveDisclosure;
