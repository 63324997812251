import moment from 'moment-timezone';
import { FC, useCallback } from 'react';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getValueFromPath } from '@payaca/helpers/formHelper';
import CompanyLogo from '../companyLogo/CompanyLogo';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import {
  useCertificateNumber,
  useCommonFormAccountData,
  useInspectionFormAddresses,
} from './hooks';

import './FormTemplateElements.sass';
import './GasWarningNoticeTemplate.sass';

import GasSafeLogo from './gas-safe.png';

interface Props {
  data: any;
  account: any;
}

const GasWarningNoticeTemplate: FC<Props> = ({ data, account }) => {
  const { brandColour } = useCommonFormAccountData(account);
  const {
    accountAddressString,
    inspectionAddressString,
    landlordAddressString,
  } = useInspectionFormAddresses(account, data);
  const certificateNumber = useCertificateNumber(data);

  const renderAppliancePanel = useCallback(
    (appliance: any, applianceIndex: number) => {
      const getDefects = (defects: any) => {
        const identifiedDefectKeys = Object.keys(defects);
        const identifiedDefectStrings = identifiedDefectKeys
          .map((string) => string.replace(/_/g, ' '))
          .map((string) => string.charAt(0).toUpperCase() + string.slice(1))
          .filter((s) => !(s === 'Other specify'))
          .toString();
        const otherOption = defects.other_specify;

        return (
          <>
            <LabelValuePair
              label={'Defects identified'}
              value={identifiedDefectStrings}
            />
            {otherOption && (
              <LabelValuePair label={'Other'} value={otherOption} />
            )}
          </>
        );
      };

      const getSafetyCategoryClassification = (selection: any) => {
        const selectedCategoryKey = Object.keys(selection).toString();

        switch (selectedCategoryKey) {
          case 'immediately_dangerous':
            return (
              <>
                <LabelValuePair
                  label={'Safety Category Classification'}
                  value={'Immediately Dangerous'}
                />
                <p>
                  The gas appliance/ installation has been disconnected from the
                  gas supply and labelled Danger Do Not Use (with permission of
                  the user/ responsible person.)
                </p>
              </>
            );
          case 'at_risk_danger_do':
            return (
              <>
                <LabelValuePair
                  label={'Safety Category Classification'}
                  value={'At Risk'}
                />
                <p>
                  The gas appliance/ installation has been turned off to be made
                  safe and labelled Danger Do Not Use (with the permission of
                  the user/ reponsible person.)
                </p>
              </>
            );
          case 'at_risk':
            return (
              <>
                <LabelValuePair
                  label={'Safety Category Classification'}
                  value={'At Risk'}
                />
                <p>
                  The gas appliance/ installation has been classified as At
                  Risk, however turning it off will not remove the risk. Refer
                  to the appropriate organisation for resolution.
                </p>
              </>
            );
        }
      };

      return (
        <OutlinedContentPanel
          colourOverride={brandColour}
          className="appliance-content-panel"
          key={`appliance-${applianceIndex}`}
          title={`Appliance ${applianceIndex + 1}`}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="appliance-content-panel-body">
            <div>
              {' '}
              <LabelValuePair
                label={'Type'}
                value={getValueFromPath(
                  appliance,
                  'gas_appliance_installation_type'
                )}
              />
              <LabelValuePair
                label={'Location'}
                value={getValueFromPath(
                  appliance,
                  'gas_appliance_installation_location'
                )}
              />
              <div className="safety-category">
                {getSafetyCategoryClassification(
                  appliance.safety_category_classification
                )}
              </div>
              <LabelValuePair
                label={
                  'Reference number supplied by the gas supplier, if permission denied'
                }
                value={appliance.defects_identified.permission_denied || 'N/A'}
              />
            </div>
            <div className="fault-descriptions">
              {getDefects(appliance.defects_identified)}
              <LabelValuePair
                label={'Description of faults found'}
                value={getValueFromPath(
                  appliance,
                  'description_of_faults_found'
                )}
              />
              <LabelValuePair
                label={
                  'Remedial action required to rectify the unsafe situation'
                }
                value={getValueFromPath(appliance, 'remedial_action_required')}
              />
            </div>
          </div>
        </OutlinedContentPanel>
      );
    },
    []
  );

  if (!data || !account) return <></>;

  return (
    <div className={'form-template-output gas-warning-notice-template'}>
      <div className={'form-template-header'}>
        <div>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Gas Warning Notice</h3>
        </div>
        <div className={'flex-container flex-center'}>
          <div className="header-details-container">
            <LabelValuePair label="Cert No" value={certificateNumber} />
            <LabelValuePair
              label="No of Appliances"
              value={data.defective_appliances?.length}
            />
          </div>
          {data.gas_safe_reg_number && (
            <img
              className="gas-safe-logo"
              src={GasSafeLogo}
              alt={'Gas Safe Logo'}
            />
          )}
        </div>
      </div>
      <div className="form-template-row company-inspection-landlord-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          className="company-details-content-panel"
          title={'Company/Installer'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="company-details-content-panel-body">
            <LabelValuePair label="Engineer" value={data.completed_by} />
            <LabelValuePair label="Company" value={account?.companyName} />
            <LabelValuePair label="Address" value={accountAddressString} />
            <LabelValuePair label="Contact" value={account?.contactNumber} />
            {data.gas_safe_reg_number && (
              <LabelValuePair
                label="Gas Safe Reg"
                value={data.gas_safe_reg_number}
              />
            )}
            {data.id_card_number && (
              <LabelValuePair
                label="ID Card Number"
                value={data.id_card_number}
              />
            )}
            <LabelValuePair label="E-Mail" value={account?.email} />
          </div>
        </OutlinedContentPanel>

        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Inspection/Installation Address'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.name} />
          <LabelValuePair label="Address" value={inspectionAddressString} />
          <LabelValuePair label="E-Mail" value={data.email} />
          <LabelValuePair label="Contact" value={data.contact} />
        </OutlinedContentPanel>

        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Landlord/Agent Details'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.landlord_name} />
          <LabelValuePair label="Address" value={landlordAddressString} />
          <LabelValuePair label="E-mail" value={data.landlord_email} />
          <LabelValuePair label="Contact" value={data.landlord_contact} />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row appliances-details">
        <div className="appliances-details-content-panel-body">
          {data?.defective_appliances?.map(renderAppliancePanel)}
        </div>
      </div>
      <div className="form-template-row further-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Further details'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Details of Gas Emergency Service Provider"
            value={getValueFromPath(data, 'details_of_gas_emergency')}
          />
          <LabelValuePair
            label="Details of RIDDOR situations identified and reported"
            value={getValueFromPath(data, 'details_of_riddor_situations')}
          />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row signatures no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Completed by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.completed_by} />
          <LabelValuePair
            label="Signed"
            value={
              <div className={'form-template-signature'}>
                {data.engineer_signature && (
                  <img src={data.engineer_signature} />
                )}
              </div>
            }
          />
          <LabelValuePair
            label="Inspection conducted"
            value={moment(data.issue_date).format('DD/MM/YYYY')}
            noBreak={true}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Received by"
          className="received-by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div>
            <LabelValuePair label="Name" value={data.customer_signature_name} />
            <LabelValuePair
              label="Signed"
              value={
                <div className={'form-template-signature'}>
                  {data.customer_signature && (
                    <img src={data.customer_signature} />
                  )}
                </div>
              }
            />
          </div>
          <p>
            I have received this Warning/Advice notice. I understand, having
            been advised, that to use an appliance/installation categorised as
            Immediately Dangerous or At Risk presents a safety hazard which may
            place me in breach of the current Gas Safety (Installation and Use)
            Regulations.
          </p>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row no-gas-user-in-attendance no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <span className="checkmark-wrapper">
            {data.no_gas_user_in_attendance && (
              <FontAwesomeIcon icon={faCheck} />
            )}
          </span>
          <span>
            No gas user was in attendance at the time of the inspection. Where
            necessary the installation/ appliance was made safe. This Warning/
            Advice Notice was left at the inspection location.
          </span>
        </OutlinedContentPanel>
      </div>
    </div>
  );
};

export default GasWarningNoticeTemplate;
