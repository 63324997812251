import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  CreateMaterialMutation as _CreateMaterialMutation,
  CreateMaterialInput,
} from '../../../gql/graphql';

const CreateMaterialMutation = graphql(`
  mutation CreateMaterial($input: CreateMaterialInput!) {
    createMaterial(input: $input) {
      id
      name
      internalId
      category {
        id
        name
      }
      image {
        id
        thumbnailUrl
      }
      suppliedBy {
        supplier {
          id
          name
        }
        price {
          taxRate {
            id
          }
          unitPrice {
            value
            currency {
              id
              code
              exponent
            }
          }
          unitPriceExcTax {
            value
            currency {
              id
              code
              exponent
            }
          }
        }
      }
    }
  }
`);

const useCreateMaterial = (
  options?: UseMutationOptions<
    _CreateMaterialMutation,
    unknown,
    CreateMaterialInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(CreateMaterialMutation, {
        input,
      });
    },
    ...options,
  });
};

export default useCreateMaterial;
