import { FunctionComponent } from 'react';
import './ContentPanel.sass';

type Props = {
  children: string | JSX.Element | JSX.Element[];
  className?: string;
  hasBoxShadow?: boolean;
};

const ContentPanel: FunctionComponent<Props> = ({
  children,
  className,
  hasBoxShadow = true,
}: Props): JSX.Element => {
  return (
    <div
      className={`content-panel${className ? ` ${className}` : ''}${
        hasBoxShadow ? ' box-shadow' : ''
      }`}
    >
      {children}
    </div>
  );
};

export default ContentPanel;
