import moment from 'moment-timezone';
import { FC } from 'react';

import { formatAnswer } from '@payaca/helpers/formHelper';
import CompanyLogo from '../companyLogo/CompanyLogo';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import {
  useCertificateNumber,
  useCommonFormAccountData,
  useInspectionFormAddresses,
} from './hooks';

import './CommercialCateringInspectionRecord.sass';
import './FormTemplateElements.sass';

import GasSafeLogo from './gas-safe.png';

interface Props {
  data: any;
  account: any;
}

const CommercialCateringInspectionRecord: FC<Props> = ({ data, account }) => {
  const { brandColour } = useCommonFormAccountData(account);
  const {
    accountAddressString,
    inspectionAddressString,
    landlordAddressString,
  } = useInspectionFormAddresses(account, data);
  const certificateNumber = useCertificateNumber(data);

  if (!data || !account) return <></>;

  return (
    <div
      className={
        'form-template-output commerical-catering-inspection-record-template'
      }
    >
      <div className={'form-template-header'}>
        <div>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Commercial Catering Inspection Record</h3>
        </div>
        <div className={'flex-container flex-center'}>
          <div className="header-details-container">
            <LabelValuePair label="Cert No" value={certificateNumber} />
          </div>
          {data.gas_safe_reg_number && (
            <img
              className="gas-safe-logo"
              src={GasSafeLogo}
              alt={'Gas Safe Logo'}
            />
          )}
        </div>
      </div>
      <div className="form-template-row company-inspection-landlord-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          className="company-details-content-panel"
          title={'Company/Installer'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="company-details-content-panel-body">
            <LabelValuePair label="Engineer" value={data.completed_by} />
            <LabelValuePair label="Company" value={account?.companyName} />
            <LabelValuePair label="Address" value={accountAddressString} />
            <LabelValuePair label="Contact" value={account?.contactNumber} />
            {data.gas_safe_reg_number && (
              <LabelValuePair
                label="Gas Safe Reg"
                value={data.gas_safe_reg_number}
              />
            )}
            {data.id_card_number && (
              <LabelValuePair
                label="ID Card Number"
                value={data.id_card_number}
              />
            )}

            <LabelValuePair label="E-Mail" value={account?.email} />
          </div>
        </OutlinedContentPanel>

        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Inspection/Installation Address'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.name} />
          <LabelValuePair label="Address" value={inspectionAddressString} />
          <LabelValuePair label="E-Mail" value={data.email} />
          <LabelValuePair label="Contact" value={data.contact} />
        </OutlinedContentPanel>

        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Landlord/Agent Details'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.landlord_name} />
          <LabelValuePair label="Address" value={landlordAddressString} />
          <LabelValuePair label="E-mail" value={data.landlord_email} />
          <LabelValuePair label="Contact" value={data.landlord_contact} />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row gas-pipework-safety-risk-ventilation-details">
        <div className="form-template-column">
          <OutlinedContentPanel
            colourOverride={brandColour}
            title={'Gas installation Details'}
            titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
            paddingVariant={ContentPanelPaddingVariant.SMALL}
          >
            <LabelValuePair
              label="Emergency isolation for catering area"
              value={formatAnswer(data.emergency_isolation_for_catering_area)}
            />
            <LabelValuePair
              label="Satisfactory location"
              value={formatAnswer(data.satisfactory_location)}
            />
            <LabelValuePair
              label="Accessible"
              value={formatAnswer(data.accessible)}
            />
            <LabelValuePair
              label="Suitable valve type"
              value={formatAnswer(data.suitable_valve_type)}
            />
            <LabelValuePair
              label="Secure valve handle"
              value={formatAnswer(data.secure_valve_handle)}
            />
            <LabelValuePair
              label="Emergency notice present"
              value={formatAnswer(data.emergency_notice_present)}
            />
            <LabelValuePair
              label="Gas isolation via automatic electric system"
              value={formatAnswer(
                data.gas_isolation_via_automatic_electric_system
              )}
            />
            <LabelValuePair
              label="Automatic pressure proving fitted"
              value={formatAnswer(data.automatic_pressure_proving_fitted)}
            />
            <LabelValuePair
              label="Manual reset facility"
              value={formatAnswer(data.manual_reset_facility)}
            />
            <LabelValuePair
              label="Reset notice attached"
              value={formatAnswer(data.reset_notice_attached)}
            />
            <LabelValuePair
              label="Full flame safeguard on appropriate appliances"
              value={formatAnswer(
                data.full_flame_safeguard_on_appropriate_appliances
              )}
            />
            <LabelValuePair
              label="Gas pipework corrosion evidence"
              value={formatAnswer(data.gas_pipework_corrosion_evidence)}
            />
            <LabelValuePair
              label="Action required"
              value={formatAnswer(data.action_required)}
            />
            <LabelValuePair
              label="Gas installation tightness tested"
              value={formatAnswer(data.gas_installation_tightness_tested)}
            />
            <LabelValuePair
              label="Satisfactory gas installation tightness"
              value={formatAnswer(data.satisfactory_gas_installation_tightness)}
            />
          </OutlinedContentPanel>
          <OutlinedContentPanel
            colourOverride={brandColour}
            title={'Pipework within the catering area'}
            titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
            paddingVariant={ContentPanelPaddingVariant.SMALL}
          >
            <LabelValuePair
              label="Correct materials used"
              value={formatAnswer(data.correct_materials_used)}
            />
            <LabelValuePair
              label="Correctly identified and labelled"
              value={formatAnswer(data.correctly_identified_and_labelled)}
            />
            <LabelValuePair
              label="Correctly supported throughout pipe run"
              value={formatAnswer(data.correctly_supported_throughout_pipe_run)}
            />
            <LabelValuePair
              label="Sleeves extended through walls/floors"
              value={formatAnswer(data.sleeves_extended_through_walls_floors)}
            />
            <LabelValuePair
              label="Suitable purge points fitted"
              value={formatAnswer(data.suitable_purge_points_fitted)}
            />
            <LabelValuePair
              label="Suitable test points fitted"
              value={formatAnswer(data.suitable_test_points_fitted)}
            />
            <LabelValuePair
              label="Electrical protective bonding fitted where required"
              value={formatAnswer(data.electrical_protective_bonding_fitted)}
            />
          </OutlinedContentPanel>

          <OutlinedContentPanel
            colourOverride={brandColour}
            title={'Safety information'}
            titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
            paddingVariant={ContentPanelPaddingVariant.SMALL}
          >
            <LabelValuePair
              label="Warning record issued"
              value={formatAnswer(data.warning_record_issued)}
            />
            <LabelValuePair
              label="Warning labels affixed"
              value={formatAnswer(data.warning_labels_affixed)}
            />
            <LabelValuePair
              label="Responsible person advised"
              value={formatAnswer(data.responisble_person_advised)}
            />
            <LabelValuePair
              label="Warning notice classification"
              value={formatAnswer(
                data.warning_notice_classification_and_serial_no?.classification
              )}
            />
            <LabelValuePair
              label="Warning notice serial no"
              value={formatAnswer(
                data.warning_notice_classification_and_serial_no?.serial_no
              )}
            />
          </OutlinedContentPanel>
          <OutlinedContentPanel
            colourOverride={brandColour}
            title={'Risk analysis of kitchen ventilation/extract system'}
            titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
            paddingVariant={ContentPanelPaddingVariant.SMALL}
          >
            <LabelValuePair
              label="Risk assesment in accordance with IGEM/UP/19"
              value={formatAnswer(data.risk_assessment_compliant)}
            />
            <LabelValuePair
              label="Risk assesment outcome"
              value={formatAnswer(data.risk_assesment_outcome)}
            />
          </OutlinedContentPanel>
        </div>

        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Ventilation/extract system and air quality systems'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Canopy system installed"
            value={formatAnswer(data.canopy_system_installed)}
          />
          <LabelValuePair
            label="If yes - Canopy overhang correct"
            value={formatAnswer(data.canopy_overhang_correct)}
          />
          <LabelValuePair
            label="Canopy filtration type"
            value={formatAnswer(data.canopy_filtration_type)}
          />
          <LabelValuePair
            label="Adequately maintained canopy filtration"
            value={formatAnswer(data.canopy_filtration_adequately_maintained)}
          />
          <LabelValuePair
            label="Mechanical extract provided"
            value={formatAnswer(data.mechanical_extract_provided)}
          />
          <LabelValuePair
            label="If yes - Flow rate (litres/sec)"
            value={formatAnswer(data.mechanical_extract_flow_rate)}
          />
          <LabelValuePair
            label="If yes - Adequate flow rate where appropriate"
            value={formatAnswer(data.mechanical_extract_adequate_flow_rate)}
          />
          <LabelValuePair
            label="Mechanical extract interlocked with gas supply"
            value={formatAnswer(
              data.mechanical_extract_interlocked_with_gas_supply
            )}
          />
          <LabelValuePair
            label="Interlock fitted with manual override"
            value={formatAnswer(
              data.mechanical_extract_interlock_fitted_with_manual_override
            )}
          />
          <LabelValuePair
            label="If yes - Override disabled"
            value={formatAnswer(
              data.mechanical_extract_manual_override_disabled
            )}
          />
          <LabelValuePair
            label="If no - Report provided against use"
            value={formatAnswer(data.mechanical_extract_manual_override_report)}
          />
          <LabelValuePair
            label="Mechanical ventilation fitted"
            value={formatAnswer(data.mechanical_ventilation_fitted)}
          />
          <LabelValuePair
            label="If yes - Flow rate (litres/sec)"
            value={formatAnswer(data.mechanical_ventilation_flow_rate)}
          />
          <LabelValuePair
            label="If yes - Adequate flow rate where appropriate"
            value={formatAnswer(data.mechanical_ventilation_adequate_flow_rate)}
          />
          <LabelValuePair
            label="Mechanical ventilation interlock provided"
            value={formatAnswer(data.mechanical_ventilation_interlock_provided)}
          />
          <LabelValuePair
            label="If yes - Correctly working interlock"
            value={formatAnswer(data.mechanical_ventilation_interlock_work)}
          />

          <LabelValuePair
            label="Natural ventilation provided where required"
            value={formatAnswer(data.natural_ventilation_provided)}
          />
          <LabelValuePair
            label="Natural ventilation details"
            value={
              <div>
                <LabelValuePair
                  label="High level (cm2)"
                  value={formatAnswer(
                    data.natural_ventilation_readings
                      ?.natural_ventilation_high_level
                  )}
                />
                <LabelValuePair
                  label="Low level (cm2)"
                  value={formatAnswer(
                    data.natural_ventilation_readings
                      ?.natural_ventilation_low_level
                  )}
                />
                <LabelValuePair
                  label="Adequate"
                  value={formatAnswer(
                    data.natural_ventilation_readings
                      ?.natural_ventilation_adequate
                  )}
                />
              </div>
            }
          />
          <LabelValuePair
            label="Detection interlocked with gas supply"
            value={formatAnswer(data.detection_interlocked_with_gas_supply)}
          />
          <LabelValuePair
            label="Air quality testing (IGEM/UP/19)"
            value={
              <div>
                <LabelValuePair
                  label="1st CO2 reading (ppm)"
                  value={formatAnswer(
                    data.air_quality_testing.air_quality_testing_1
                  )}
                />
                <LabelValuePair
                  label="2nd CO2 reading (ppm)"
                  value={formatAnswer(
                    data.air_quality_testing.air_quality_testing_2
                  )}
                />
                <LabelValuePair
                  label="3rd CO2 reading (ppm)"
                  value={formatAnswer(
                    data.air_quality_testing.air_quality_testing_3
                  )}
                />
              </div>
            }
          />
          <LabelValuePair
            label="Average of above 3 readings (ppm)"
            value={formatAnswer(
              data.air_quality_testing.air_quality_testing_average
            )}
          />
          <LabelValuePair
            label="Air quality testing results"
            value={formatAnswer(data.air_quality_testing_results)}
          />
          <LabelValuePair
            label="Air quality recording instrument(s)"
            value={
              <div>
                <LabelValuePair
                  label="1 - Make/model"
                  value={formatAnswer(
                    data.air_quality_testing_instruments
                      .air_quality_testing_instrument_make_model_1
                  )}
                />
                <LabelValuePair
                  label="1 - Calibration date"
                  value={moment(
                    data.air_quality_testing_instruments
                      .air_quality_testing_instrument_calibration_date_1
                  ).format('DD/MM/YYYY')}
                />
                <LabelValuePair
                  label="2 - Make/model"
                  value={formatAnswer(
                    data.air_quality_testing_instruments
                      .air_quality_testing_instrument_make_model_2
                  )}
                />
                <LabelValuePair
                  label="2 - Calibration date"
                  value={moment(
                    data.air_quality_testing_instruments
                      .air_quality_testing_instrument_calibration_date_2
                  ).format('DD/MM/YYYY')}
                />
              </div>
            }
          />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Additional works required'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <span>{data.additional_works || 'None'}</span>
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Comments'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <span>{data.comments || 'None'}</span>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Completed by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.completed_by} />
          <LabelValuePair
            label="Signed"
            value={
              <div className={'form-template-signature'}>
                {data.engineer_signature && (
                  <img src={data.engineer_signature} />
                )}
              </div>
            }
          />
          <LabelValuePair
            label="Inspection conducted"
            value={moment(data.issue_date).format('DD/MM/YYYY')}
            noBreak={true}
          />
          <LabelValuePair
            label="Next Inspection Date"
            value={moment(data.next_inspection_due_date).format('DD/MM/YYYY')}
            noBreak={true}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Received by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
          className="customer-signature"
        >
          <div>
            <LabelValuePair label="Name" value={data.name} />
            <LabelValuePair
              label="Signed"
              value={
                <div className={'form-template-signature'}>
                  {data.customer_signature && (
                    <img src={data.customer_signature} />
                  )}
                </div>
              }
            />
          </div>
          {data.customer_not_present && <p>Customer not present</p>}
        </OutlinedContentPanel>
      </div>
    </div>
  );
};

export default CommercialCateringInspectionRecord;
