import { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import ForgotPasswordForm from '@/ui/components/forgotPasswordForm/ForgotPasswordForm';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';

import './ForgotPasswordPage.sass';

const ForgotPasswordPage: FunctionComponent = (): JSX.Element => {
  const history = useHistory();

  const goToLoginPage = useCallback(() => {
    history.push('/login');
  }, [history]);

  return (
    <UnauthenticatedPageWrapper
      className="forgot-password-page"
      titleContent={<h2>Forgotten password?</h2>}
      footerContent={
        <>
          <span className="login-prompt">
            Remembered it?{' '}
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={goToLoginPage}
              colourVariant={ButtonColourVariant.WHITE}
            >
              Log in
            </Button>
          </span>
        </>
      }
    >
      <ForgotPasswordForm />
    </UnauthenticatedPageWrapper>
  );
};

export default ForgotPasswordPage;
