import {
  call,
  delay,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  GetListedCustomersRequestData,
  ListedCustomersListViewPage,
  PersistCustomerData,
} from '@payaca/types/listedCustomerTypes';
import {
  ActionType,
  ExportCustomersData,
  GetCustomerAnalytics,
  PatchCustomer,
  SagaConfig,
} from './customerTypes';

import { Req } from '@payaca/helpers/storeHelper';
import { Customer } from '@payaca/types/customerTypes';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { handleAsyncAction } from '../utils';
import {
  archiveCustomersFailure,
  archiveCustomersSuccess,
  clearCurrentCustomer,
  clearCustomers,
  clearListedCustomersPage,
  exportCustomersData,
  getCustomerAnalytics,
  getCustomerFailure,
  getCustomerSuccess,
  getListedCustomersPageFailure,
  getListedCustomersPageSuccess,
  patchCustomer,
  persistCustomerFailure,
  persistCustomerSuccess,
  requestGetCustomer,
  setCurrentCustomer,
} from './customerActions';

const customerSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);
  const providerReq = Req(
    `${apiBaseurl}/provider/rest`,
    getAuthHeader,
    isNativeApp
  );

  function* handleArchiveCustomers(
    action: PayloadAction<
      ActionType.REQUEST_ARCHIVE_CUSTOMERS,
      {
        customerIds: number[];
        onArchiveSuccess?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    const isSingleCustomer = action.payload.customerIds?.length === 1;

    try {
      const { response, timeout } = yield race({
        response: isSingleCustomer
          ? call(archiveCustomer, action.payload.customerIds[0])
          : call(archiveCustomers, action.payload.customerIds),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          archiveCustomersFailure(new Error('Archive customers timed out.'))
        );
      } else {
        action.payload.onArchiveSuccess && action.payload.onArchiveSuccess();
        yield put(archiveCustomersSuccess());
      }
    } catch (error: any) {
      yield put(persistCustomerFailure(error));
    }
  }

  function* handlePersistCustomer(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_CUSTOMER,
      {
        persistCustomerData: PersistCustomerData;
        onPersistSuccess?: (customerId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(persistCustomer, action.payload.persistCustomerData),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistCustomerFailure(new Error('Persist customer timed out.'))
        );
      } else {
        action.payload.onPersistSuccess &&
          action.payload.onPersistSuccess(response);
        yield put(persistCustomerSuccess());
      }
    } catch (error: any) {
      yield put(persistCustomerFailure(error));
    }
  }

  function* handleGetCustomer(
    action: PayloadAction<
      ActionType.REQUEST_GET_CUSTOMER,
      {
        customerId: number;
        callback: (customer: Customer) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getCustomer, action.payload.customerId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getCustomerFailure(
            action.payload.customerId,
            new Error('Get customer timed out.')
          )
        );
      } else {
        yield put(getCustomerSuccess(response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(getCustomerFailure(action.payload.customerId, error));
    }
  }

  function* handleGetCustomerForScheduledEvent(
    action: PayloadAction<
      ActionType.REQUEST_GET_CUSTOMER_FOR_SCHEDULED_EVENT,
      {
        scheduledEventId: number;
        callback: (customer: Customer) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getCustomerForScheduledEvent,
          action.payload.scheduledEventId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        //
      } else {
        yield put(getCustomerSuccess(response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error) {
      //
    }
  }

  const getCustomerForScheduledEvent = async (scheduledEventId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/customer/scheduled_event/${scheduledEventId}`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get customer for scheduled event failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const archiveCustomer = async (customerId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/customer/${customerId}/archive`, {
      method: 'PUT',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `Archive customer failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const archiveCustomers = async (customerIds: number[]) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/customers/bulk_archive`, {
      method: 'PUT',
      body: JSON.stringify({
        customerIds: customerIds,
      }),
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `Archive customers failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const persistCustomer = async (persistCustomerData: PersistCustomerData) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/customer`, {
      method: 'POST',
      body: JSON.stringify(persistCustomerData),
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Persist customer failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetListedCustomersPage(
    action: PayloadAction<
      ActionType.REQUEST_GET_LISTED_CUSTOMERS_PAGE,
      {
        getListedCustomersRequestData: GetListedCustomersRequestData;
        callback?: (listedCustomersPage: ListedCustomersListViewPage) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(
          getListedCustomersPage,
          action.payload.getListedCustomersRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getListedCustomersPageFailure(
            new Error('Get listed customers request timed out.')
          )
        );
      } else {
        action.payload.callback?.(response);
        yield put(getListedCustomersPageSuccess(response));
      }
    } catch (error: any) {
      yield put(getListedCustomersPageFailure(error));
    }
  }

  const getListedCustomersPage = async (
    getListedCustomersRequestData: GetListedCustomersRequestData
  ) => {
    return fetch(
      `${apiBaseurl}/api/listed_customers?pageNumber=${
        getListedCustomersRequestData.pageNumber
      }&pageSize=${getListedCustomersRequestData.pageSize}&searchTerm=${
        getListedCustomersRequestData.searchTerm || ''
      }`,
      {
        method: 'GET',
        headers: {
          Authorization: await getAuthHeader(),
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetListedCustomersPage failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getCustomer = async (customerId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/customer/${customerId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get customer failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleRequestGetAndSetCurrentCustomer(
    action: PayloadAction<
      ActionType.REQUEST_GET_AND_SET_CURRENT_CUSTOMER,
      {
        customerId: number;
      }
    >
  ): any {
    yield put(requestGetCustomer(action.payload.customerId));
    const getCustomerResult = yield take([
      ActionType.GET_CUSTOMER_SUCCESS,
      ActionType.GET_CUSTOMER_FAILURE,
    ]);

    if (getCustomerResult.type === ActionType.GET_CUSTOMER_SUCCESS) {
      yield put(setCurrentCustomer(getCustomerResult.payload.customer));
    }
  }

  const handleGetCustomerAnalyticsRequest =
    handleAsyncAction<GetCustomerAnalytics>(
      getCustomerAnalytics,
      async (payload) => {
        const response = await req.get(
          `/customer/analytics/${payload.customerId}`
        );
        return response.json();
      },
      (data, payload) => {
        payload.payload?.callback?.(data);
      }
    );

  const handlePatchCustomer = handleAsyncAction<PatchCustomer>(
    patchCustomer,
    async (payload) => {
      const response = await req.patch(
        `/customer/${payload.customerId}`,
        payload.body
      );
      return response.json();
    },
    (data, payload) => {
      payload.payload?.callback?.(data);
    }
  );

  const handleExportCustomersData = handleAsyncAction<ExportCustomersData>(
    exportCustomersData,
    async (payload) => {
      const response = await providerReq.post(`/customers/data-export`, {});
    },
    (data, payload) => {
      payload.payload?.callback?.();
    }
  );

  function* handleLogout() {
    yield put(clearCurrentCustomer());
    yield put(clearListedCustomersPage());
    yield put(clearCustomers());
  }

  return function* () {
    yield takeEvery(
      ActionType.REQUEST_ARCHIVE_CUSTOMERS,
      handleArchiveCustomers
    );
    yield takeEvery(ActionType.REQUEST_PERSIST_CUSTOMER, handlePersistCustomer);
    yield takeEvery(ActionType.REQUEST_GET_CUSTOMER, handleGetCustomer);
    yield takeEvery(
      ActionType.REQUEST_GET_CUSTOMER_FOR_SCHEDULED_EVENT,
      handleGetCustomerForScheduledEvent
    );
    yield takeLatest(
      ActionType.REQUEST_GET_AND_SET_CURRENT_CUSTOMER,
      handleRequestGetAndSetCurrentCustomer
    );
    yield takeLatest(
      ActionType.REQUEST_GET_LISTED_CUSTOMERS_PAGE,
      handleGetListedCustomersPage
    );
    yield takeEvery(
      ActionType.GET_CUSTOMER_ANALYTICS_REQUEST,
      handleGetCustomerAnalyticsRequest
    );
    yield takeEvery(ActionType.PATCH_CUSTOMER_REQUEST, handlePatchCustomer);
    yield takeEvery(
      ActionType.EXPORT_CUSTOMERS_DATA_REQUEST,
      handleExportCustomersData
    );

    yield takeEvery('auth.logout', handleLogout);
  };
};

export default customerSagaCreator;
