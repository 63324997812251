import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Search } from 'react-iconly';
import { useDispatch } from 'react-redux';

import IconButton from '@payaca/components/button/IconButton';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';
import * as customerActions from '@payaca/store/customer/customerActions';
import SelectCustomerModal from '../selectCustomerModal/SelectCustomerModal';

import { useSelector } from '../../../api/state';

import {
  GetListedCustomersRequestData,
  ListedCustomer,
} from '@payaca/types/listedCustomerTypes';

import CreateCustomerModalMutation from '@/ui/components/createCustomerModalMutation/CreateCustomerModalMutation';
import { getCustomer } from '../../../utils/stateAccessors';

interface Option {
  id: number;
  name: string;
  primaryContactEmailAddress?: string;
}

type Props = {
  customerId?: number | null;
  onSelectCustomer?: (customerId?: number) => void;
};
const AddCustomerControl = ({ customerId, onSelectCustomer }: Props) => {
  const dispatch = useDispatch();
  const [getListedCustomersRequestData, setGetListedCustomersRequestData] =
    useState<GetListedCustomersRequestData>({
      pageSize: 20,
      pageNumber: 1,
      searchTerm: '',
    });
  const [showCreateEditJobCustomerModal, setShowCreateEditJobCustomerModal] =
    useState(false);
  const [showSearchCustomerTableModal, setShowSearchCustomerTableModal] =
    useState(false);

  const [customerName, setCustomerName] = useState<string>('');

  useEffect(() => {
    requestGetListedCustomersPage();
  }, [getListedCustomersRequestData]);

  const requestGetListedCustomersPage = useCallback(() => {
    dispatch(
      customerActions.requestGetListedCustomersPage(
        getListedCustomersRequestData
      )
    );
  }, [getListedCustomersRequestData, dispatch]);

  const listedCustomersPage = useSelector((state) => {
    return state.customer?.listedCustomersPage;
  });
  const isGettingListedCustomersPage = useSelector(
    (state) => state.customer.isGettingListedCustomersPage
  );
  const customer = useSelector((state) =>
    customerId ? getCustomer(state, customerId) : undefined
  );

  useEffect(() => {
    if (customerId && customer) {
      setCustomerName(customer.name);
    }
  }, [customerId, customer]);

  const handleOpenCreateCustomerModal = useCallback((customerName?: string) => {
    customerName && setCustomerName(customerName);
    setShowCreateEditJobCustomerModal(true);
  }, []);

  const handleOpenAdvancedSearchCustomerModal = () => {
    // TODO: Open customer search modal (with search term?)
    setShowSearchCustomerTableModal(true);
  };

  const onSearchTermChange = useCallback((searchTerm: string) => {
    setCustomerName(searchTerm);
    // clear a selected customer
    onSelectCustomer?.(undefined);

    setGetListedCustomersRequestData(
      (getListedCustomersRequestData: GetListedCustomersRequestData) => {
        return {
          ...getListedCustomersRequestData,
          pageNumber: 1,
          searchTerm: searchTerm,
        };
      }
    );
  }, []);

  const initialCreateCustomerFormState = useMemo(() => {
    return {
      name: customerName,
    };
  }, [customerName]);

  const renderOption = (option: Option) => {
    return (
      <div className="listed-job-customer-option flex-container flex-center">
        {option.id === -1 && <IconButton size={'xs'} icon={faPlus} />}
        {option.id === -2 && <Search size={21} />}
        <div className="listed-job-customer-name-email-wrapper">
          <span>{option.name}</span>
          {option.primaryContactEmailAddress && (
            <span className="listed-job-customer-email">
              - {option.primaryContactEmailAddress}
            </span>
          )}
        </div>
      </div>
    );
  };

  let customerResults: Array<{
    id: ListedCustomer['customerId'];
    name: ListedCustomer['customerName'];
    primaryContactEmailAddress?: ListedCustomer['primaryContactEmailAddress'];
  }> = [];
  // only start showing customer results when started typing
  if (getListedCustomersRequestData.searchTerm) {
    customerResults = (listedCustomersPage?.items || []).map(
      (c: ListedCustomer) => ({
        id: c.customerId,
        name: c.customerName,
        primaryContactEmailAddress: c.primaryContactEmailAddress,
      })
    );
  }

  return (
    <>
      <TypeToSearchField
        value={customerName}
        styleVariant={InputStyleVariant.OUTSIZE}
        options={[
          ...customerResults,
          {
            id: -1,
            name: 'Create a new Customer',
          },
          {
            id: -2,
            name: 'Advanced search',
          },
        ]}
        renderOption={renderOption}
        disableFilteringInComponent={true}
        isLoadingOptions={isGettingListedCustomersPage}
        onSelectOption={(option?: Option) => {
          if (option?.id === -1) {
            handleOpenCreateCustomerModal(
              getListedCustomersRequestData.searchTerm
            );
          } else if (option?.id === -2) {
            handleOpenAdvancedSearchCustomerModal();
          } else if (option?.id) {
            // selected existing customer
            option?.name && setCustomerName(option.name);
            onSelectCustomer && onSelectCustomer(option.id);
          }
        }}
        placeholder="Type here to search Customers"
        onSearchTermChange={onSearchTermChange}
      />
      {/* create customer modal */}
      <CreateCustomerModalMutation
        zIndexLevel={2}
        isOpen={showCreateEditJobCustomerModal && !showSearchCustomerTableModal}
        onClose={() => {
          setShowCreateEditJobCustomerModal(false);
        }}
        initialFormState={initialCreateCustomerFormState}
        mutationOptions={{
          onSuccess: (data) => {
            onSelectCustomer && onSelectCustomer(+data.createCustomer.id);
            setShowCreateEditJobCustomerModal(false);
          },
        }}
      />
      <SelectCustomerModal
        isOpen={showSearchCustomerTableModal}
        onClose={() => setShowSearchCustomerTableModal(false)}
        onSelectCustomer={(customerId: number) => {
          setShowSearchCustomerTableModal(false);
          onSelectCustomer && onSelectCustomer(customerId);
        }}
      />
    </>
  );
};

export default AddCustomerControl;
