import EmptyArrow from '@/assets/images/empty-arrow.png';
import EmptyPlaceholder from '@/assets/images/empty-placeholder.png';
import { get } from 'lodash-es';

import './EmptyState.css';

const EmptyState = ({ configName, arrowIndication }) => {
  const Empty = {
    automations: {
      text: 'No automations here yet',
    },
    deals: {
      text: 'No Projects here yet',
    },
    quotes: {
      text: 'No quotes or estimates here yet.',
    },
    invoices: {
      text: 'No invoices here yet.',
    },
    customers: {
      text: 'No customers here yet.',
    },
    items: {
      text: 'No items here yet.',
    },
    users: {
      text: 'Please contact an admin user to amend account users.',
    },
    itemgroups: {
      text: 'No item groups here yet',
    },
    payaca_credit: {
      text: `You've not earned any credit yet!`,
    },
    payaca_credit_incomplete: {
      text: `You have no pending/declined credit`,
    },
    suppliers: {
      text: `No suppliers here yet`,
    },
    materials: {
      text: 'No materials here yet',
    },
    templates: {
      text: 'No templates here yet',
    },
    timelogs: {
      text: 'No timelogs here yet',
    },
  };

  const emptyStateContent = get(Empty, configName, {});
  const icon = emptyStateContent.icon || EmptyPlaceholder;
  const arrowDirection = emptyStateContent.direction || 'up';

  return (
    <div className="empty-state">
      {arrowIndication && (
        <img
          src={EmptyArrow}
          alt="Arrow"
          className={`arrow-icon ${arrowDirection}`}
        />
      )}
      <img src={icon} alt="Empty state" />
      <div className="text">
        <div>{emptyStateContent.text || 'Nothing here yet'}</div>
        {arrowIndication && <div>Get started by adding one above.</div>}
      </div>
    </div>
  );
};

export default EmptyState;
