import { RichTextEditorVariable } from '@payaca/types/richTextEditorVariables';
import { Node } from '@tiptap/react';

declare module '@tiptap/react' {
  interface Commands<ReturnType> {
    customExtension: {
      /**
       * Comments will be added to the autocomplete.
       */
      addVariable: (attributes: RichTextEditorVariable) => ReturnType;
    };
  }
}

export const VariableNode = Node.create({
  name: 'variable',
  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      name: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-name'),
        renderHTML: (attributes) => {
          if (!attributes.name) {
            return {};
          }

          return {
            'data-name': attributes.name,
          };
        },
      },
      displayName: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-display-name'),
        renderHTML: (attributes) => {
          if (!attributes.displayName) {
            return {};
          }

          return {
            'data-display-name': attributes.displayName,
          };
        },
      },
    };
  },

  // https://tiptap.dev/docs/editor/guide/custom-extensions#parse-html
  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const htmlAttributes = Object.assign(HTMLAttributes, {
      class: 'rich-text-editor-variable',
    });
    htmlAttributes['data-display-name'] = node.attrs.displayName;
    htmlAttributes['data-name'] = node.attrs.name;

    return ['span', htmlAttributes, `${node.attrs.displayName}`];
  },

  renderText({ node }) {
    return `${node.attrs.id}`;
  },

  addCommands() {
    return {
      addVariable:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs,
          });
        },
    };
  },
});
