import moment from 'moment-timezone';
import { FunctionComponent } from 'react';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import type { SubscriptionPaymentPreview } from '@payaca/types/subscriptionTypes';

import { useSelector } from '@/api/state';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';

type Props = {
  subscriptionPaymentPreview?: SubscriptionPaymentPreview;
  isLoading?: boolean;
};

const SubscriptionPaymentPreview: FunctionComponent<Props> = ({
  subscriptionPaymentPreview,
  isLoading = false,
}: Props): JSX.Element | null => {
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  if (isLoading) {
    return (
      <div className="py-4 flex flex-col items-center gap-y-4">
        <SkeletonLoader.Text className="h-4 w-1/2" />
        <SkeletonLoader.Text className="h-4 w-2/3" />
      </div>
    );
  } else if (subscriptionPaymentPreview) {
    return (
      <div className="py-4 text-center">
        <p>
          If you proceed, you will be charged{' '}
          <strong>
            {currencyPrice(
              subscriptionPaymentPreview.immediateTotal,
              account.region
            )}
          </strong>{' '}
          today
          {!!subscriptionPaymentPreview.immediateTotal &&
            !!subscriptionPaymentPreview.immediateDiscountPercentage &&
            ` (including a ${subscriptionPaymentPreview.immediateDiscountPercentage}% discount)`}
        </p>
        <p>
          Your next payment of{' '}
          <strong>
            {currencyPrice(
              subscriptionPaymentPreview.nextInvoiceTotal,
              account.region
            )}
          </strong>{' '}
          {!!subscriptionPaymentPreview.nextInvoiceTotal &&
            !!subscriptionPaymentPreview.nextInvoiceDiscountPercentage &&
            `(including a ${subscriptionPaymentPreview.nextInvoiceDiscountPercentage}% discount) `}
          will be taken on{' '}
          <strong>
            {moment(subscriptionPaymentPreview.nextInvoiceAt).format(
              'DD MMM YYYY'
            )}
          </strong>
        </p>
      </div>
    );
  }

  return null;
};

export default SubscriptionPaymentPreview;
