import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import TextareaField from '@payaca/components/textareaField/TextareaField';
import { JobLineItemGroupBase } from '@payaca/types/jobContentTypes';
import { FC, useCallback, useEffect, useState } from 'react';
import './ItemGroupHeader.sass';
type Props = {
  canDeleteItemGroup: boolean;
  isDeleting?: boolean;
  itemGroup: JobLineItemGroupBase;
  onDeleteItemGroup?: () => void;
  onUpdateItemGroup: (value: { [key: string]: any }) => void;
  itemGroupExternalUpdateTimestamp?: Date;
};
const ItemGroupHeader: FC<Props> = ({
  canDeleteItemGroup = true,
  isDeleting = false,
  itemGroup,
  onDeleteItemGroup,
  onUpdateItemGroup,
  itemGroupExternalUpdateTimestamp,
}: Props): JSX.Element => {
  const [showGroupDescriptionTextField, setShowGroupDescriptionTextField] =
    useState<boolean>(false);

  const [description, setDescription] = useState(itemGroup.description);
  const [isUpdateRequired, setIsUpdateRequired] = useState(false);

  const updateItemGroup = useCallback(() => {
    onUpdateItemGroup({
      description: description,
    });
  }, [onUpdateItemGroup, description]);

  useEffect(() => {
    setDescription(itemGroup.description);
  }, [itemGroupExternalUpdateTimestamp]);

  useEffect(() => {
    if (isUpdateRequired) {
      updateItemGroup();
      setIsUpdateRequired(false);
    }
  }, [isUpdateRequired, updateItemGroup]);

  return (
    <div className="item-group-header">
      {/* group description */}
      <div className="item-group-header-description">
        {showGroupDescriptionTextField || itemGroup.description ? (
          <TextareaField
            name="description"
            value={description}
            placeholder="Item group description"
            rows={1}
            onChange={(value: { [key: string]: any }) => {
              setDescription(value.description);
            }}
            autoSize={true}
            onBlur={() => {
              setIsUpdateRequired(true);
              if (!description) {
                setShowGroupDescriptionTextField(false);
              }
            }}
            onChangeTimeout={() => setIsUpdateRequired(true)}
          />
        ) : (
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => setShowGroupDescriptionTextField(true)}
          >
            Add group description
          </Button>
        )}
      </div>
      {canDeleteItemGroup && onDeleteItemGroup && (
        <div className="delete-container">
          {isDeleting ? (
            <MiniLoader />
          ) : (
            <FontAwesomeIcon icon={faTimes} onClick={onDeleteItemGroup} />
          )}
        </div>
      )}
    </div>
  );
};

export default ItemGroupHeader;
