import { Disclosure } from '@headlessui/react';
import UntitledIcon from '@payaca/untitled-icons';
import { Children, FC, PropsWithChildren, useEffect } from 'react';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';
import Badge from '../plBadge/Badge';
import Tooltip from '../plTooltip/Tooltip';

export interface IProps {
  title: string;
  titleTooltip?: string;
  // Memorise!
  onToggle?: (open: boolean) => void;
  panelClassName?: string;
  defaultOpen?: boolean;
  hideCount?: boolean;
  count?: number | string;
}

const AccordionWithChildCount: FC<PropsWithChildren<IProps>> = (props) => {
  const {
    title,
    titleTooltip,
    onToggle,
    children,
    panelClassName,
    defaultOpen = false,
    hideCount = false,
    count,
  } = props;

  const shouldDisplayChevron =
    (typeof count === 'number' && count > 0) ||
    (typeof count !== 'number' && Children.count(children) > 0);

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => {
        useEffect(() => {
          onToggle?.(open);
        }, [onToggle, open]);

        return (
          <div>
            <ConditionalWrapper
              condition={!!titleTooltip}
              Wrapper={Tooltip}
              wrapperProps={{
                className: '!block',
                tooltipContent: titleTooltip,
              }}
            >
              <Disclosure.Button
                className={
                  'prose flex w-full items-center justify-between bg-transparent p-0' +
                  (Children.count(children) > 0 ? ' cursor-pointer' : '')
                }
                disabled={Children.count(children) === 0}
              >
                <h4 className="font-bold">
                  {title}
                  {!hideCount && (
                    <Badge className="ml-2" variant="white" size="sm" rounded>
                      {count !== undefined ? count : Children.count(children)}
                    </Badge>
                  )}
                </h4>
                {shouldDisplayChevron && (
                  <UntitledIcon
                    name={open ? 'chevron-up' : 'chevron-down'}
                    className="h-7 w-7"
                  />
                )}
              </Disclosure.Button>
            </ConditionalWrapper>
            <Disclosure.Panel className={panelClassName}>
              {children}
            </Disclosure.Panel>
          </div>
        );
      }}
    </Disclosure>
  );
};

export default AccordionWithChildCount;
