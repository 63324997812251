import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { CreateLineItemInput, CreateLineItemMutation } from '@/gql/graphql';

const CREATE_LINE_ITEM_MUTATION = graphql(`
  mutation CreateLineItem($input: CreateLineItemInput!) {
    createLineItem(input: $input) {
      id
    }
  }
`);

const useCreateLineItem = (
  options?: UseMutationOptions<
    CreateLineItemMutation,
    unknown,
    CreateLineItemInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(CREATE_LINE_ITEM_MUTATION, { input });
    },
    ...options,
  });
};

export default useCreateLineItem;
