import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UpdateMaterialMutation as _UpdateMaterialMutation,
  UpdateMaterialInput,
} from '../../../gql/graphql';

const UpdateMaterialMutation = graphql(`
  mutation UpdateMaterial($input: UpdateMaterialInput!) {
    updateMaterial(input: $input) {
      id
    }
  }
`);

const useUpdateMaterial = (
  options?: UseMutationOptions<
    _UpdateMaterialMutation,
    unknown,
    UpdateMaterialInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UpdateMaterialMutation, {
        input,
      });
    },
    ...options,
  });
};

export default useUpdateMaterial;
