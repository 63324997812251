import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { useMutation } from '@tanstack/react-query';

const ImportEmailServiceSignatureMutation = graphql(`
  mutation ImportEmailServiceSignature(
    $input: ImportEmailServiceSignatureInput!
  ) {
    importEmailServiceSignature(input: $input)
  }
`);

export const useImportEmailServiceSignature = () => {
  return useMutation({
    mutationFn: () => {
      return gqlClient.request(ImportEmailServiceSignatureMutation, {
        input: { service: 'google' }, // only google service is supported now
      });
    },
  });
};
