import { actions as userActions } from '@/api/users';
import { useIsMounted } from '@/hooks/useIsMounted';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

function useBlockedEmails() {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const [blockedEmails, setBlockedEmails] = useState<Array<string>>([]);
  useEffect(() => {
    dispatch(
      userActions.getBlockedEmails((err: any, blockedEmails: any) => {
        if (blockedEmails) {
          isMounted.current && setBlockedEmails(blockedEmails);
        }
      })
    );
  }, []);
  return blockedEmails;
}

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useHashFragment = (hash: string): [boolean, () => void] => {
  const [isActive, setIsActive] = useState(location.hash === hash);

  useEffect(() => {
    setIsActive(location.hash === hash);
  }, [location.hash, hash]);

  const toggleHashFragment = isActive
    ? () => {
        location.hash = '';
      }
    : () => {
        location.hash = hash;
      };

  return [isActive, toggleHashFragment];
};

export { useBlockedEmails, usePrevious };
