import ServicePlanInviteCustomerModal from '@/ui/components/servicePlanInviteCustomerModal/ServicePlanInviteCustomerModal';
import ServicePlansSubscriptionsTable from '@/ui/components/servicePlansSubscriptionsTable/ServicePlansSubscriptionsTable';
import LinkButton from '@payaca/components/plButton/LinkButton';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';
import { FC } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

const CustomerServicePlansTab: FC = () => {
  /**
   * Routing
   */
  const { customerId } = useParams<{ customerId: string }>();
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const isInvitingCustomerToServicePlan = useRouteMatch({
    path: `${path}/invite`,
    exact: true,
  });

  return (
    <>
      <ServicePlansSubscriptionsTable
        headerBar={{
          heading: 'Customer Service Plans subscriptions',
          buttons: (
            <>
              <LinkButton size={EBtnSize.Small} to={`${url}/invite`}>
                Invite to Service Plan
              </LinkButton>
            </>
          ),
        }}
        customerIds={[customerId]}
      />

      <ServicePlanInviteCustomerModal
        isOpen={!!isInvitingCustomerToServicePlan}
        onClose={() => history.push(url)}
        customerId={+customerId}
      />
    </>
  );
};

export default CustomerServicePlansTab;
