import CreateCustomerControl, {
  IProps as ICreateCustomerControlProps,
} from '@/ui/components/createCustomerControl/CreateCustomerControl';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import { FC } from 'react';

export interface IProps
  extends Omit<IModalProps, 'title'>,
    Omit<ICreateCustomerControlProps, 'isInModal' | 'onCancel'> {
  title?: string;
  isProcessing?: boolean;
}

const CreateCustomerModal: FC<IProps> = (props) => {
  const {
    onClose,
    onSubmit,
    isProcessing,
    initialFormState,
    title = 'Create Customer',
    ...rest
  } = props;

  return (
    <Modal title={title} onClose={onClose} {...rest}>
      <CreateCustomerControl
        isInModal
        onCancel={onClose}
        isProcessing={isProcessing}
        onSubmit={onSubmit}
        initialFormState={initialFormState}
      />
    </Modal>
  );
};

export default CreateCustomerModal;
