import { FunctionComponent, useCallback, useState } from 'react';
import SubscriptionProductsSelectionControl from './SubscriptionProductsSelectionControl';

import { useSelector } from '@/api/state';
import Badge from '@payaca/components/plBadge/Badge';
import Tabs from '@payaca/components/plTabs/Tabs';
import {
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';
import { Nullish } from '@payaca/utilities/types';
import UpdateSubscriptionAdditionalUserSeatsModal from '../../../components/manageSubscription/updateSubscriptionAdditionalUserSeatsModal/UpdateSubscriptionAdditionalUserSeatsModal';
import UpdateSubscriptionModal from '../../../components/manageSubscription/updateSubscriptionModal/UpdateSubscriptionModal';
import Arrow from './arrow-icon.svg';

const SegmentTabLabels = ['Monthly', 'Yearly'];
type Props = {
  onUpdateSubscriptionSuccess?: (info: {
    subscriptionProduct: Nullish<SubscriptionProduct>;
    recurringInterval: RecurringInterval;
    additionalUserSeats: number;
  }) => void;
};

const SelectSubscriptionControl: FunctionComponent<Props> = ({
  onUpdateSubscriptionSuccess,
}: Props): JSX.Element => {
  const accountSubscription = useSelector(
    (state) => state.subscription.accountSubscription
  );

  const [priceId, setPriceId] = useState<string>();
  const [productId, setProductId] = useState<string>();
  const [showUpdateSubscriptionModal, setShowUpdateSubscriptionModal] =
    useState(false);
  const [recurringInterval, setRecurringInterval] = useState<RecurringInterval>(
    accountSubscription?.recurringInterval || RecurringInterval.MONTH
  );
  const [
    showUpdateSubscriptionAdditionalUserSeatsModal,
    setShowUpdateSubscriptionAdditionalUserSeatsModal,
  ] = useState(false);
  const [subscriptionProduct, setSubscriptionProduct] = useState(
    accountSubscription?.subscriptionProduct
  );

  const toggleMonthlyPricing = useCallback(() => {
    setRecurringInterval(
      recurringInterval === RecurringInterval.YEAR
        ? RecurringInterval.MONTH
        : RecurringInterval.YEAR
    );
  }, [recurringInterval]);

  return (
    <div className="flex flex-col items-center">
      <div className="absolute flex items-center ml-[225px]">
        <img src={Arrow} alt="Arrow" className="h-8 mb-4 -mr-7" />
        <Badge colour="purple" className="flex-shrink-0">
          Get 2 months free
        </Badge>
      </div>
      <Tabs
        variant="segment"
        onChange={toggleMonthlyPricing}
        className="mt-12"
        defaultIndex={recurringInterval === RecurringInterval.MONTH ? 0 : 1}
      >
        {SegmentTabLabels.map((label, i) => (
          <Tabs.Panel
            key={i}
            label={label}
            render={() => (
              <SubscriptionProductsSelectionControl
                recurringInterval={recurringInterval}
                selectedPriceId={accountSubscription?.productPrice?.stripeId}
                onChange={(value) => {
                  setPriceId(value.priceId);
                  setProductId(value.productId);
                  setShowUpdateSubscriptionModal(true);
                  setSubscriptionProduct(value.subscriptionProduct);
                }}
                onUpdateExistingSubscription={() =>
                  setShowUpdateSubscriptionAdditionalUserSeatsModal(true)
                }
              />
            )}
          />
        ))}
      </Tabs>
      {priceId && productId && (
        <UpdateSubscriptionModal
          recurringInterval={recurringInterval}
          isOpen={showUpdateSubscriptionModal}
          stripePriceId={priceId}
          stripeProductId={productId}
          onClose={() => setShowUpdateSubscriptionModal(false)}
          onUpdateSubscriptionSuccess={(additionalUserSeats) =>
            onUpdateSubscriptionSuccess?.({
              subscriptionProduct,
              recurringInterval,
              additionalUserSeats,
            })
          }
        />
      )}
      <UpdateSubscriptionAdditionalUserSeatsModal
        isOpen={showUpdateSubscriptionAdditionalUserSeatsModal}
        onClose={() => setShowUpdateSubscriptionAdditionalUserSeatsModal(false)}
        onUpdateSubscriptionSuccess={(additionalUserSeats) =>
          onUpdateSubscriptionSuccess?.({
            subscriptionProduct,
            recurringInterval,
            additionalUserSeats,
          })
        }
      />
    </div>
  );
};

export default SelectSubscriptionControl;
