import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  RemoveAcceptedMutation as _RemoveAcceptedMutation,
  RemoveAcceptedInput,
} from '@/gql/graphql';

const RemoveAcceptedMutation = graphql(`
  mutation RemoveAccepted($input: RemoveAcceptedInput!) {
    removeAccepted(input: $input)
  }
`);

const useRemoveAccepted = (
  options?: UseMutationOptions<
    _RemoveAcceptedMutation,
    unknown,
    RemoveAcceptedInput
  >
) => {
  return useMutation({
    mutationFn: (input: RemoveAcceptedInput) => {
      return gqlClient.request(RemoveAcceptedMutation, { input });
    },
    ...options,
  });
};

export default useRemoveAccepted;
