import { useSelector } from '@/api/state';
import ConfirmDeleteCustomerReminderModal from '@/ui/components/servicePlanCustomerReminderControl/ConfirmDeleteCustomerReminderModal';
import { getUserRoles } from '@/utils/stateAccessors';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import Table, { ITableProps } from '@payaca/components/plTable/Table';
import SwitchField from '@payaca/components/switchField/SwitchField';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { ServicePlanPermissions } from '@payaca/permissions/service-plans/service-plans.permissions';
import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';
import {
  getServicePlansCustomerReminders,
  toggleServicePlansCustomerReminder,
} from '@payaca/store/servicePlans/servicePlansActions';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { PublicHydratedServicePlanCustomerReminderTemplate } from '@payaca/types/service-plans';
import moment from 'moment-timezone';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

export interface IProps {}

const ServicePlansCustomerRemindersListed: FC<IProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const userRoles = useSelector(getUserRoles);
  const [showDeleteCustomerReminderModal, setShowDeleteCustomerReminderModal] =
    useState<
      false | PublicHydratedServicePlanCustomerReminderTemplate['publicId']
    >(false);
  const [isDeletingCustomerReminder, setIsDeletingCustomerReminder] =
    useState(false);
  const [
    togglingCustomerReminderPublicId,
    setTogglingCustomerReminderPublicId,
  ] = useState<string | undefined>();

  const isGettingListedServicePlanCustomerReminders = useSelector(
    (state) => state.servicePlans.isGettingListedServicePlanCustomerReminders
  );
  const listedCustomerReminders = useSelector(
    (state) => state.servicePlans.listedServicePlanCustomerReminders
  );

  useEffect(() => {
    dispatch(getServicePlansCustomerReminders.request({}));
  }, []);

  const toggleCustomerReminder = (publicId: string, isEnabled: boolean) => {
    if (togglingCustomerReminderPublicId) return;

    setTogglingCustomerReminderPublicId(publicId);

    dispatch(
      toggleServicePlansCustomerReminder.request({
        publicId,
        body: {
          isEnabled,
        },
        onSuccess: () => setTogglingCustomerReminderPublicId(undefined),
        onError: () => {
          showDynamicFeedbackMessage({
            lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
            title:
              'Failed to toggle customer reminder, try again later or contact support.',
            feedbackLevel: FeedbackLevel.NEUTRAL,
            isCancellable: true,
          });
          setTogglingCustomerReminderPublicId(undefined);
        },
      })
    );
  };

  const tableQuickActions = useMemo(() => {
    const actions: ITableProps<PublicHydratedServicePlanCustomerReminderTemplate>['quickActions'] =
      [];

    if (
      userHasRequiredPermission(userRoles, [
        ServicePlanPermissions.PERSIST_SERVICE_PLAN,
      ])
    ) {
      actions.push({
        name: 'Edit',
        onClick: (row) =>
          history.push(`/service-plans/customer-reminders/${row.publicId}`),
      });

      actions.push({
        name: 'Delete',
        onClick: (row) => {
          setShowDeleteCustomerReminderModal(row.publicId);
        },
      });
    }

    return actions;
  }, [userRoles]);

  return (
    <>
      <Table
        uniqueKey="publicId"
        heading="Customer Reminders"
        subHeading="Set up reminders to let your customers know when their service plans are ready for renewal"
        isLoading={isGettingListedServicePlanCustomerReminders}
        data={listedCustomerReminders}
        createButton={{
          label: 'Create Customer Reminder',
          onClick: () => history.push('/service-plans/customer-reminders/new'),
        }}
        quickActions={tableQuickActions}
      >
        <Table.Column header="Name" field="name" />
        <Table.Column<
          PublicHydratedServicePlanCustomerReminderTemplate,
          'channels'
        >
          header="Type"
          field="channels"
          render={(value) =>
            value.map((i) => i.charAt(0).toUpperCase() + i.slice(1)).join(', ')
          }
        />
        <Table.Column<
          PublicHydratedServicePlanCustomerReminderTemplate,
          'timeOffset'
        >
          header="Trigger"
          field="timeOffset"
          render={(value) =>
            Math.abs(moment.duration(value).asDays()) + ' days before renewal'
          }
        />
        <Table.Column<
          PublicHydratedServicePlanCustomerReminderTemplate,
          'isEnabled'
        >
          header="State"
          field="isEnabled"
          render={(value, row) => (
            <SwitchField
              isDisabled={row.publicId === togglingCustomerReminderPublicId}
              name="isEnabled"
              label="Off/On"
              value={value}
              onChange={(e) =>
                toggleCustomerReminder(row.publicId, e.isEnabled)
              }
            />
          )}
        />
      </Table>

      <ConfirmDeleteCustomerReminderModal
        isOpen={!!showDeleteCustomerReminderModal}
        isBusy={isDeletingCustomerReminder}
        onClose={() => setShowDeleteCustomerReminderModal(false)}
        onConfirmDelete={() => {
          if (!showDeleteCustomerReminderModal) return;

          setIsDeletingCustomerReminder(true);

          dispatch(
            servicePlansActions.deleteServicePlansCustomerReminder.request({
              publicId: showDeleteCustomerReminderModal,
              onSuccess: () => {
                dispatch(
                  servicePlansActions.getServicePlansCustomerReminders.request(
                    {}
                  )
                );
                setIsDeletingCustomerReminder(false);
                setShowDeleteCustomerReminderModal(false);
              },
              onError: () => {
                showDynamicFeedbackMessage({
                  lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
                  title:
                    'Failed to delete customer reminder, try again later or contact support.',
                  feedbackLevel: FeedbackLevel.NEUTRAL,
                  isCancellable: true,
                });
                setIsDeletingCustomerReminder(false);
                setShowDeleteCustomerReminderModal(false);
              },
            })
          );
        }}
      />
    </>
  );
};

export default ServicePlansCustomerRemindersListed;
