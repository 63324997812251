import { FC } from 'react';

import CompanyLogo from '../companyLogo/CompanyLogo';
import './FormTemplateElements.sass';
import './SalvumTemplate.sass';

interface Props {
  data: any;
  account: any;
}

const SalvumLegionellaDesktopStudyTemplate: FC<Props> = ({ data, account }) => {
  if (!data || !account) return <></>;
  return (
    <div className={'form-template-output salvum'}>
      <div className={'form-template-header'}>
        <div>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Legionella - Desktop Study</h3>
        </div>
        <div>
          <span>Doc No</span>: {data.certificate_number}
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <div
          className={'form-template-bubble'}
          style={{ borderColor: account?.brandColour }}
        >
          <ul>
            <li>
              <span>Client:</span> {data.customer_name}
            </li>
            <li>
              <span>Client Address:</span> {data.customer_address}
            </li>
            <li>
              <span>Client E-mail:</span> {data.customer_email}
            </li>
            <li>
              <span>Client Telephone:</span> {data.customer_telephone}
            </li>
            <li>
              <span>Main Contact:</span> {data.contact_name}
            </li>
          </ul>
        </div>
        <div
          className={'form-template-bubble'}
          style={{ borderColor: account?.brandColour }}
        >
          <ul>
            <li>
              <span>Site Address:</span> {data.site_address}
            </li>
            <li>
              <span>Work Type:</span> {data.work_type}
            </li>
            <li>
              <span>Details of Work:</span> {data.work_details}
            </li>
            <li>
              <span>Price:</span> {data.work_price}
            </li>
          </ul>
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <div
          className={'form-template-bubble'}
          style={{ borderColor: account?.brandColour }}
        >
          <ul>
            <li>
              <span>Scope of Works:</span> {data.scope_of_works}
            </li>
            <li>
              <span>Exclusions:</span> {data.exclusions}
            </li>
            <li>
              <span>Notes, site and safety arrangements:</span>{' '}
              {data.notes_and_safety_arrangements}
            </li>
            <li>
              <span>Site Location:</span> {data.site_location}
            </li>
            <li>
              <span>Size of Building (m2):</span> {data.building_size_m2}
            </li>
            <li>
              <span>Number of Floors:</span> {data.num_floors}
            </li>
            <li>
              <span>Number of Occupants:</span> {data.num_occupants}
            </li>
            <li>
              <span>Number of Washrooms:</span> {data.num_washrooms}
            </li>
            <li>
              <span>Number of Kitchens/Kitchenettes:</span> {data.num_kitchens}
            </li>
            <li>
              <span>Number of Showers:</span> {data.num_showers}
            </li>
            <li>
              <span>Number of Cold Water Storage Tanks:</span>{' '}
              {data.num_cold_water_storage_tanks}
            </li>
            <li>
              <span>Number of Water Heaters:</span> {data.num_water_heaters}
            </li>
          </ul>
        </div>{' '}
        <div
          className={'form-template-bubble'}
          style={{ borderColor: account?.brandColour }}
        >
          <ul>
            <li>
              <span>System Type:</span> {data.system_type}
            </li>
            <li>
              <span>Any other water systems present on site?:</span>{' '}
              {data.water_systems_present}
            </li>
            <li>
              <span>Water features:</span> {data.water_features_present}
            </li>
            <li>
              <span>Cooling towers:</span> {data.cooling_towers_present}
            </li>
            <li>
              <span>Fire systems:</span> {data.fire_systems_present}
            </li>
            <li>
              <span>Spa baths:</span> {data.spa_baths_present}
            </li>
            <li>
              <span>Dental systems:</span> {data.dental_systems_present}
            </li>
            <li>
              <span>Other systems:</span> {data.other_systems_present}
            </li>
            <li>
              <span>Other Systems Notes:</span> {data.other_systems_notes}
            </li>
            <li>
              <span>Additional Information:</span> {data.additional_information}
            </li>
            <li>
              <span>Online Chat Reference Number:</span>{' '}
              {data.online_chat_reference_num}
            </li>
            <li>
              <span>Purchase Order Number:</span> {data.purchase_order_number}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SalvumLegionellaDesktopStudyTemplate;
