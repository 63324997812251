import {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useRef,
} from 'react';

import { nanoid } from 'nanoid';
import CollapsibleSidebar from '../collapsibleSidebar/CollapsibleSidebar';
import NavigationMenuItem from '../navigationMenuItem/NavigationMenuItem';

export enum CollapseDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

export { NavigationMenuItem };

type Props = {
  isCollapsed: boolean;
  isHidden: boolean;
  isSmallView?: boolean;
  onToggleIsCollapsed: (isCollapsed: boolean) => void;
  onToggleIsHidden: (isHidden: boolean) => void;
};

const NavigationSidebar: FunctionComponent<PropsWithChildren<Props>> = ({
  isCollapsed,
  isHidden,
  isSmallView = false,
  children,
  onToggleIsCollapsed,
  onToggleIsHidden,
}: PropsWithChildren<Props>): JSX.Element => {
  const { current: uId } = useRef(nanoid());
  const onToggleCollapse = useCallback(() => {
    if (isSmallView) {
      onToggleIsCollapsed(false);
      onToggleIsHidden(!isHidden);
    } else {
      onToggleIsHidden(false);
      onToggleIsCollapsed(!isCollapsed);
    }
  }, [
    isCollapsed,
    isHidden,
    onToggleIsCollapsed,
    onToggleIsHidden,
    isSmallView,
  ]);

  return (
    <CollapsibleSidebar
      isCollapsed={isCollapsed}
      isHidden={isHidden}
      onToggleCollapse={onToggleCollapse}
      config={{
        collapsedWidthPx: 48,
        expandedWidthPx: 220,
      }}
    >
      <nav aria-labelledby={uId} className="flex h-full w-full flex-col">
        <h1 id={uId} className="sr-only">
          Main navigation
        </h1>
        {children}
      </nav>
    </CollapsibleSidebar>
  );
};
export default NavigationSidebar;
