export type MarkupType = 'profitPercentage' | 'profitAmount' | 'price';

export const calculatePredictedMaterialCosts = (
  lineItemMaterials: {
    materialQuantity: number;
    price: number;
  }[]
) => {
  return lineItemMaterials.reduce((acc, { materialQuantity, price }) => {
    return acc + price * materialQuantity;
  }, 0);
};

export const calculateMarkupChange = (
  markupType: MarkupType,
  value: number,
  predictedPrice?: number
) => {
  if (!predictedPrice) {
    return {
      profitPercentage: 0,
      profitAmount: 0,
      price: value,
    };
  }

  switch (markupType) {
    case 'price': {
      const price = value;

      const profitAmount = price - predictedPrice;
      const profitPercentage = price !== 0 ? (profitAmount / price) * 100 : 0;

      return {
        profitPercentage: Number(profitPercentage.toFixed(2)),
        profitAmount: Math.round(profitAmount),
        price: Math.round(price),
      };
    }
    case 'profitAmount': {
      const profitAmount = value;

      const price = predictedPrice + profitAmount;
      const profitPercentage = price !== 0 ? (profitAmount / price) * 100 : 0;

      return {
        profitPercentage: Number(profitPercentage.toFixed(2)),
        profitAmount: Math.round(profitAmount),
        price: Math.round(price),
      };
    }
    case 'profitPercentage': {
      const profitPercentage = value;

      const price = predictedPrice / (1 - profitPercentage / 100);
      const profitAmount = price - predictedPrice;

      return {
        profitPercentage: Number(profitPercentage.toFixed(2)),
        profitAmount: Math.round(profitAmount),
        price: Math.round(price),
      };
    }
    default: {
      return {
        profitPercentage: 0,
        profitAmount: 0,
        price: 0,
      };
    }
  }
};
