import useOptimisticAddNewCustomerAddress from '@/api/mutations/customers/optimistic/useOptimisticAddNewCustomerAddress';
import useOptimisticDeleteCustomerAddress from '@/api/mutations/customers/optimistic/useOptimisticDeleteCustomerAddress';
import useOptimisticSetCustomerBillingAddress from '@/api/mutations/customers/optimistic/useOptimisticSetCustomerBillingAddress';
import useOptimisticUpdateCustomerAddress from '@/api/mutations/customers/optimistic/useOptimisticUpdateCustomerAddress';
import useOptimisticUpdateCustomerContactOnCustomer from '@/api/mutations/customers/optimistic/useOptimisticUpdateCustomerContactOnCustomer';
import useGetCustomer from '@/api/queries/customers/useGetCustomer';
import { Address } from '@/gql/graphql';
import AddEditContactModal, {
  IProps as IAddEditContactModalProps,
} from '@/ui/components/addEditContactControl/AddEditContactModal';
import AddAddressModal, {
  IProps as IAddAddressModalProps,
} from '@/ui/components/addressModals/AddAddressModal';
import SelectEditAddressAndAddressContactsModal, {
  IProps as ISelectEditAddressAndAddressContactsModalProps,
} from '@/ui/components/addressModals/SelectEditAddressAndAddressContactsModal';
import AddressComponent from '@payaca/components/address/Address';
import GoogleMapAnchor from '@payaca/components/googleMapAnchor/GoogleMapAnchor';
import AdvancedTable, {
  TTableRowAction,
} from '@payaca/components/plAdvancedTable/AdvancedTable';
import Button from '@payaca/components/plButton/Button';
import LinkButton from '@payaca/components/plButton/LinkButton';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Modal from '@payaca/components/plModal/Modal';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, useCallback, useMemo } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

type TableData = Pick<
  Address,
  | 'id'
  | 'line1'
  | 'line2'
  | 'city'
  | 'country'
  | 'postalCode'
  | 'fullLocalAddress'
  | 'contacts'
>;

const CustomerAddressTable: FC = () => {
  const { customerId } = useParams<{ customerId: string }>();

  /**
   * React router
   */
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const isAddingAddress = useRouteMatch({
    path: `${path}/new`,
    exact: true,
  });
  const isEditingAddress = useRouteMatch<{
    addressId: string;
  }>({
    path: `${path}/:addressId/edit`,
    exact: true,
  });
  const isDeletingAddress = useRouteMatch<{
    addressId: string;
  }>({
    path: `${path}/:addressId/delete`,
    exact: true,
  });
  const isEditingAddressAccessContact = useRouteMatch<{
    addressId: string;
    contactId: string;
  }>({
    path: `${path}/:addressId/contact/:contactId/edit`,
    exact: true,
  });

  /**
   * Queries
   */
  const { data: customerData } = useGetCustomer(customerId);

  /**
   * Mutations
   */
  const { mutate: createNewCustomerAddress } =
    useOptimisticAddNewCustomerAddress(customerId);
  const { mutate: updateAddress } =
    useOptimisticUpdateCustomerAddress(customerId);
  const { mutate: deleteAddress } =
    useOptimisticDeleteCustomerAddress(customerId);
  const { mutate: setBillingAddress } =
    useOptimisticSetCustomerBillingAddress(customerId);
  const { mutate: updateCustomerContact } =
    useOptimisticUpdateCustomerContactOnCustomer(customerId);

  /**
   * Callbacks
   */
  const handleCreateAddress = useCallback<
    Required<IAddAddressModalProps>['onSave']
  >(
    async (address) => {
      createNewCustomerAddress({
        address: {
          ...address,
        },
        customerId,
      });

      history.push(url);
    },
    [createNewCustomerAddress, customerId, history, url]
  );
  const handleUpdateAddress = useCallback<
    Required<ISelectEditAddressAndAddressContactsModalProps>['onChange']
  >(
    async (address) => {
      const _address = { ...address };

      delete _address.fullLocalAddress;

      updateAddress({
        ..._address,
      });
    },
    [updateAddress]
  );
  const handleDeleteAddress = useCallback(() => {
    if (!isDeletingAddress) {
      // should never get here
      return;
    }

    deleteAddress({
      addressId: isDeletingAddress?.params.addressId,
      customerId,
    });

    history.push(url);
  }, [isDeletingAddress, history, deleteAddress, url, customerId]);
  const handleEditAddressAccessContact = useCallback<
    Required<IAddEditContactModalProps>['onSave']
  >(
    (contact) => {
      if (!isEditingAddressAccessContact) {
        return;
      }

      const { id, ...rest } = contact;

      const address = customerData?.customer.addresses.find(
        (address) =>
          address.id === isEditingAddressAccessContact.params.addressId
      );

      if (id && address) {
        delete address.fullLocalAddress;

        updateAddress({
          ...address,
          contacts: address.contacts.map((contact) =>
            contact.id === id ? { ...contact, ...rest } : contact
          ),
        });
      }

      history.push(url);
    },
    [updateCustomerContact, history, url, isEditingAddressAccessContact]
  );

  const rowActions = useMemo<(row: TableData) => TTableRowAction<TableData>[]>(
    () => (row) => {
      const actions: TTableRowAction<TableData>[] = [
        {
          label: 'Edit',
          onClick: (row) => history.push(`${url}/${row.id}/edit`),
        },
        {
          label: 'Delete',
          onClick: (row) => history.push(`${url}/${row.id}/delete`),
        },
      ];

      if (customerData?.customer.billingAddress?.id !== row.id) {
        actions.splice(1, 0, {
          label: 'Make billing Address',
          onClick: () => setBillingAddress({ addressId: row.id, customerId }),
        });
      }

      return actions;
    },
    [history, url, customerData, setBillingAddress, customerId]
  );

  const addressBeingEdited = useMemo(() => {
    if (isEditingAddress) {
      return customerData?.customer.addresses.find(
        (address) => address.id === isEditingAddress.params.addressId
      );
    }
  }, [isEditingAddress]);

  return (
    <>
      <AdvancedTable<TableData>
        data={customerData?.customer.addresses || []}
        uniqueKey={'id'}
        searchField="fullLocalAddress"
        headerContent={{
          heading: 'Customer Addresses',
          buttons: (
            <Button
              className="md:whitespace-nowrap"
              size={EBtnSize.Small}
              onClick={() => history.push(`${url}/new`)}
            >
              Add Address
            </Button>
          ),
        }}
        rowActions={rowActions}
      >
        <AdvancedTable.Column<TableData, 'id'>
          field="id"
          header="Address"
          render={(field, row) => {
            return (
              <GoogleMapAnchor
                fullLocalAddress={row.fullLocalAddress || undefined}
              >
                <AddressComponent
                  address={{
                    line1: row.line1,
                    line2: row.line2,
                    city: row.city,
                    postcode: row.postalCode,
                  }}
                />
              </GoogleMapAnchor>
            );
          }}
        />

        <AdvancedTable.Column<TableData, 'contacts'>
          field="contacts"
          header="Access Contacts"
          render={(contacts, row) => {
            return (
              <div className="flex flex-wrap gap-3">
                {contacts.map((contact) => (
                  <LinkButton
                    key={contact.id}
                    to={`${url}/${row.id}/contact/${contact.id}/edit`}
                    variant={EBtnVariant.White}
                    size={EBtnSize.XSmall}
                  >
                    {contact.name}
                  </LinkButton>
                ))}
              </div>
            );
          }}
        />

        <AdvancedTable.Column<TableData, 'id'>
          field="id"
          header="Billing Address"
          render={(id, row) => {
            if (customerData?.customer.billingAddress?.id === id) {
              return <UntitledIcon className="h-6 w-6" name="check" />;
            }
          }}
        />
      </AdvancedTable>

      <AddEditContactModal
        isOpen={!!isEditingAddressAccessContact}
        title="Edit Access Contact"
        contact={
          isEditingAddressAccessContact
            ? customerData?.customer.addresses
                .find(
                  (address) =>
                    address.id ===
                    isEditingAddressAccessContact.params.addressId
                )
                ?.contacts.find(
                  (contact) =>
                    contact.id ===
                    isEditingAddressAccessContact.params.contactId
                )
            : undefined
        }
        onClose={() => history.push(url)}
        onSave={handleEditAddressAccessContact}
      />

      <AddAddressModal
        isOpen={!!isAddingAddress}
        onClose={() => history.push(url)}
        onSave={handleCreateAddress}
      />

      <SelectEditAddressAndAddressContactsModal
        isOpen={!!isEditingAddress}
        onClose={() => history.push(url)}
        onDone={() => history.push(url)}
        selectedAddress={addressBeingEdited}
        disableAddressChange
        onChange={handleUpdateAddress}
        addresses={
          isEditingAddress
            ? [
                {
                  value: addressBeingEdited?.id || '',
                  label: addressBeingEdited?.fullLocalAddress || '',
                },
              ]
            : []
        }
      />

      <Modal
        title="Delete Address"
        isOpen={!!isDeletingAddress}
        onClose={() => history.push(url)}
      >
        <Modal.Body>
          <p>Are you sure you want to delete this Address?</p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Actions>
            <Button
              variant={EBtnVariant.Outline}
              onClick={() => history.push(url)}
            >
              Cancel
            </Button>
            <Button colour={EBtnColour.Red} onClick={handleDeleteAddress}>
              Delete
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerAddressTable;
