import { FC, useMemo } from 'react';

import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Modal, { IProps as ModalProps } from '@payaca/components/plModal/Modal';
import useGetTask from '../../../api/queries/tasks/useGetTask';
import CreateEditTaskControl from '../createEditTaskControl/CreateEditTaskControl';

import './CreateEditTaskModal.sass';

type Props = {
  onPersistTaskSuccess?: (taskId: number) => void;
  taskId?: number;
} & Pick<ModalProps, 'isOpen' | 'onClose'>;
const EditTaskModal: FC<Props> = (props) => {
  const { isOpen, onClose, onPersistTaskSuccess, taskId } = props;
  const { task: gqlTask } = useGetTask(taskId);

  const task = useMemo(() => {
    if (!gqlTask) {
      return;
    }
    return {
      id: +gqlTask.id,
      dealId: gqlTask.project?.id ? +gqlTask.project.id : undefined,
      name: gqlTask.name,
      assignedToUserId: gqlTask.assignee?.id ? +gqlTask.assignee.id : undefined,
      deadlineDate: gqlTask?.deadline ? new Date(gqlTask.deadline) : undefined,
      checklistItems:
        gqlTask.__typename === 'ChecklistTask'
          ? gqlTask.checklistItems.map((c) => ({
              id: +c.id,
              name: c.name!,
            }))
          : undefined,
      scheduledEventIds: gqlTask.events.map((event) => +event.id),
      formTemplateId:
        gqlTask.__typename === 'FormTask'
          ? +gqlTask.formTemplate.id
          : undefined,
      documentId:
        gqlTask.__typename === 'LegacyFormTask'
          ? +gqlTask.documentId
          : undefined,
    };
  }, [gqlTask]);

  return (
    <Modal
      isOpen={isOpen}
      className="create-edit-task-modal"
      title="Edit Task"
      onClose={onClose}
      disableBackdropClick={true}
    >
      {!task ? (
        <div className="flex flex-grow justify-center">
          <MiniLoader />
        </div>
      ) : (
        <CreateEditTaskControl
          dealId={gqlTask?.project?.id ? +gqlTask.project.id : undefined}
          task={task}
          onPersistTaskSuccess={onPersistTaskSuccess}
        />
      )}
    </Modal>
  );
};
export default EditTaskModal;
