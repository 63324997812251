import { FC, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { usePermissions } from '@/hooks/usePermissions';
import { useTranslation } from '@/i18n';
import EntityTemplatesControl from '@/ui/components/entityTemplatesControl/EntityTemplatesControl';
import { TemplatesPermissions } from '@payaca/permissions/templates/templates.permissions';
import { Helmet } from 'react-helmet';
import TabbedContentPageWrapper from '../pageWrappers/tabbedContentPageWrapper/TabbedContentPageWrapper';

const ListedTemplatesPage: FC = (): JSX.Element => {
  const history = useHistory();
  const translate = useTranslation();

  const { userHasRequiredPermission } = usePermissions();

  const tabs = useMemo(() => {
    const t = [
      {
        content: <EntityTemplatesControl entityType="scheduledEvent" />,
        title: 'Event Templates',
        label: 'event-templates',
        urlSlug: 'scheduledevent-templates',
      },

      // Commenting out temporarily - we will add this back in when task templates are meaningfully usable
      // {
      //   content: <EntityTemplatesControl entityType="task" />,
      //   title: 'Task Templates',
      //   label: 'task-templates',
      //   urlSlug: 'task-templates',
      // },
    ];

    if (
      userHasRequiredPermission([TemplatesPermissions.USE_PROPOSAL_TEMPLATES])
    ) {
      t.push({
        content: <EntityTemplatesControl entityType="proposal" />,
        title: 'Proposal Templates',
        label: 'proposal-templates',
        urlSlug: 'proposal-templates',
      });
    }

    return t;
  }, []);

  const { tab } = useParams<{ tab?: string }>();
  const activeTabLabel = tabs.find((x) => x.urlSlug === tab)?.label;

  return (
    <>
      <Helmet title={translate('pages.templates.title')} />
      <TabbedContentPageWrapper
        className="listed-templates-page"
        title={'Templates'}
        tabbedContentConfig={{
          tabs: tabs,
          activeTabLabel: activeTabLabel || tabs[0].label,
          onChangeTab: (label: string) => {
            const newTabSlug = tabs.find((x) => x.label === label)?.urlSlug;
            if (newTabSlug) {
              history.push(`/templates/${newTabSlug}`);
            }
          },
        }}
      />
    </>
  );
};

export default ListedTemplatesPage;
