import { FC, useMemo, useState } from 'react';

import './EntityTimelogsControl.sass';

import Button from '@payaca/components/plButton/Button';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';
import Modal from '@payaca/components/plModal/Modal';
import { TimelogsPermissions } from '@payaca/permissions/timelogs/timelogs.permissions';
import { TimelogLinkedEntity } from '@payaca/types/timelogs';
import AssigneeGroupedListedTimelogsControl from '../assigneeGroupedListedTimelogsControl/AssigneeGroupedListedTimelogsControl';
import CreateTimelogControl from '../createTimelogControl/CreateTimelogControl';
import ListedTimelogsControl from '../listedTimelogsControl/ListedTimelogsControl';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';

type Props = {
  newTimelogsInitialStartTime?: Date;
  entity: Pick<TimelogLinkedEntity, 'entityType' | 'entityId'>;
  listedTimelogsViewType?: 'basic' | 'assignee-grouped';
  onDealUpdateSuccess?: () => void;
};
const EntityTimelogsControl: FC<Props> = ({
  newTimelogsInitialStartTime,
  entity,
  listedTimelogsViewType = 'basic',
  onDealUpdateSuccess,
}: Props): JSX.Element | null => {
  const [showCreateTimelogModal, setShowCreateTimelogModal] = useState(false);
  const [triggerRefreshDataAt, setTriggerRefreshDataAt] = useState<
    Date | undefined
  >();

  const initialFormState = useMemo(() => {
    return {
      primaryLinkedEntity: {
        entityId: entity.entityId,
        entityType: entity.entityType,
      },
      startTime: newTimelogsInitialStartTime,
    };
  }, []);

  return (
    <div className="entity-timelogs-control">
      <PermissionGuard
        renderIfHasPermissions={[
          TimelogsPermissions.CREATE_SELF_ASSIGNED_TIMELOGS,
          TimelogsPermissions.CREATE_TIMELOGS,
        ]}
      >
        <div className="flex flex-row justify-end">
          <Button
            className="record-timelog-control"
            size={EBtnSize.Small}
            onClick={() => setShowCreateTimelogModal(true)}
          >
            Record timelog
          </Button>
          <Modal
            title="Record a timelog"
            isOpen={showCreateTimelogModal}
            onClose={() => setShowCreateTimelogModal(false)}
          >
            <CreateTimelogControl
              initialFormState={initialFormState}
              onCreateTimelogSuccess={() => {
                setShowCreateTimelogModal(false);
                setTriggerRefreshDataAt(new Date());
                onDealUpdateSuccess?.();
              }}
            />
          </Modal>
        </div>
      </PermissionGuard>
      {listedTimelogsViewType === 'basic' && (
        <ListedTimelogsControl
          entity={entity}
          disableFilters={['periodStart', 'periodEnd']}
          triggerRefreshDataAt={triggerRefreshDataAt}
        />
      )}
      {listedTimelogsViewType === 'assignee-grouped' && (
        <AssigneeGroupedListedTimelogsControl
          entity={entity}
          disableFilters={['periodStart', 'periodEnd']}
          triggerRefreshDataAt={triggerRefreshDataAt}
        />
      )}
    </div>
  );
};
export default EntityTimelogsControl;
