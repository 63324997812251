import { FC, useCallback, useMemo, useState } from 'react';

import useArchiveTag from '@/api/mutations/tag/useArchiveTag';
import useUnarchiveTag from '@/api/mutations/tag/useUnarchiveTag';
import useGetMyPaginatedAvailableTags from '@/api/queries/me/useGetMyPaginatedAvailableTags';
import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import { GetTagsInput, PaginationInput, UpdateTagInput } from '@/gql/graphql';
import { CreateTagModal } from '@/ui/components/createTagModal/CreateTagModal';
import { EditTagModal } from '@/ui/components/editTagModal/EditTagModal';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import Button from '@payaca/components/plButton/Button';
import { EBtnColour } from '@payaca/components/plButton/useButtonClassName';
import { TTableRowAction } from '@payaca/components/plManageableItemsList/components/Table';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import Modal from '@payaca/components/plModal/Modal';
import Tag from '@payaca/components/tag/Tag';
import {
  DateFormats,
  getInternationalDateFormatByLocale,
} from '@payaca/helpers/internationalHelper';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { requestGetTags } from '@payaca/store/tags/tagsActions';
import { format as formatDate } from 'date-fns';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

const filterOptions = [
  {
    label: 'Active',
    value: 'active',
    disabled: true,
  },
  {
    label: 'Archived',
    value: 'archived',
  },
];

type InputState = {
  input: GetTagsInput;
  searchTerm?: string;
};

export const TagsPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const [inputState, setInputState] = useState<InputState>({
    input: {
      searchTerm: null,
      status: ['active'],
    },
    searchTerm: '',
  });

  const [pagination, setPagination] = useState<PaginationInput>({
    offset: 0,
    limit: 30,
  });

  const { data, isLoading, refetch } = useGetMyPaginatedAvailableTags(
    inputState.input,
    pagination
  );
  const { data: accountRegionPreferences } = useGetMyRegionPreferences();

  const [showCreateTagModal, setShowCreateTagModal] = useState(false);
  const [tagToEdit, setTagToEdit] = useState<UpdateTagInput>();
  const [tagIdToArchive, setTagIdToArchive] = useState<string>();

  const { mutateAsync: unarchiveTagMutation, isLoading: isUnarchiving } =
    useUnarchiveTag();

  const itemActions = useMemo(() => {
    type ListedTagType = NonNullable<typeof data>['items'][0];

    const actions: TTableRowAction<ListedTagType>[] = [];

    if (
      inputState.input.status?.includes('active') ||
      !inputState.input.status?.length
    ) {
      actions.push(
        {
          label: 'Edit',
          onClick: (tag) => {
            setTagToEdit({
              tagId: tag.id,
              name: tag.name,
              colour: tag.colour,
            });
          },
          isDisabled: (tag) => !!tag.archivedAt,
        },
        {
          label: 'Archive',
          onClick: (tag) => {
            setTagIdToArchive(tag.id);
          },
          isDisabled: (tag) => !!tag.archivedAt,
        }
      );
    }

    if (inputState.input.status?.includes('archived')) {
      actions.push({
        label: 'Unarchive',
        isProcessing: isUnarchiving,
        onClick: (tag) => {
          unarchiveTagMutation({ tagId: tag.id }).then(() => {
            dispatch(requestGetTags());
            refetch();
          });
        },
        isDisabled: (tag) => !tag.archivedAt,
      });
    }

    return actions;
  }, [inputState.input.status, isUnarchiving, unarchiveTagMutation, refetch]);

  return (
    <>
      <AuthenticatedPageWrapper
        className="bg-gray-50"
        title={
          <div className="flex flex-grow flex-row">
            <h1 className="flex-grow">Tags</h1>
            <PermissionGuard
              renderIfHasPermissions={[AccountsPermissions.MANAGE_TAGS]}
            >
              <Button onClick={() => setShowCreateTagModal(true)}>
                Create Tag
              </Button>
            </PermissionGuard>
          </div>
        }
      >
        <div className="p-4 pt-0">
          <ManageableItemsList>
            <ManageableItemsList.ActionBar>
              <ManageableItemsList.ActionBar.SearchInput
                value={inputState.searchTerm}
                onChange={(value) => {
                  setInputState((x) => ({
                    ...x,
                    searchTerm: value,
                  }));
                }}
                onChangeTimeout={() => {
                  setInputState((x) => ({
                    ...x,
                    input: {
                      ...x.input,
                      searchTerm: x.searchTerm,
                    },
                  }));
                }}
                onBlur={() => {
                  setInputState((x) => ({
                    ...x,
                    input: {
                      ...x.input,
                      searchTerm: x.searchTerm,
                    },
                  }));
                }}
                changeTimeoutMs={300}
              />
              <ManageableItemsList.ActionBar.BasicFilter
                options={filterOptions}
                value={inputState.input.status || []}
                onChange={(value) => {
                  setInputState((x) => ({
                    ...x,
                    input: {
                      ...x.input,
                      status: value,
                    },
                  }));
                }}
              />
            </ManageableItemsList.ActionBar>
            <ManageableItemsList.Table
              uniqueKey={'id'}
              items={data?.items || []}
              isLoading={isLoading}
              itemActions={itemActions}
            >
              <ManageableItemsList.Table.Column
                header=""
                field="name"
                render={(name, tag) => (
                  <>
                    <Tag tagText={tag.name} colour={tag.colour} />
                  </>
                )}
              />
              <ManageableItemsList.Table.Column
                header="Created"
                field="createdAt"
                render={(createdAt, tag) =>
                  createdAt
                    ? formatDate(
                        createdAt,
                        getInternationalDateFormatByLocale(
                          DateFormats.SHORT,
                          accountRegionPreferences?.locale
                        )
                      )
                    : ''
                }
              />
              <ManageableItemsList.Table.Column
                className="text-right"
                header="Projects"
                field=""
                render={(_, tag) => {
                  const initialRequestData = {
                    tagIds: [+tag.id],
                    pipelineId: null,
                    pipelineStages: [],
                    assignedUserIds: [],
                  };

                  return tag.usageSummary?.projectsCount ? (
                    <Link
                      to={`deals?initialRequestData=${encodeURIComponent(
                        JSON.stringify(initialRequestData)
                      )}`}
                    >
                      {tag.usageSummary?.projectsCount}
                    </Link>
                  ) : (
                    tag.usageSummary?.projectsCount
                  );
                }}
              />
              <ManageableItemsList.Table.Column
                className="text-right"
                header="Events"
                field=""
                render={(_, tag) => tag.usageSummary?.eventsCount}
              />
              <ManageableItemsList.Table.Column
                className="text-right"
                header="Customers"
                field=""
                render={(_, tag) => tag.usageSummary?.customersCount}
              />
              {inputState.input.status?.includes('archived') && (
                <ManageableItemsList.Table.Column
                  header="Archived"
                  field="archivedAt"
                  render={(archivedAt, tag) =>
                    archivedAt
                      ? formatDate(
                          archivedAt,
                          getInternationalDateFormatByLocale(
                            DateFormats.SHORT,
                            accountRegionPreferences?.locale
                          )
                        )
                      : ''
                  }
                />
              )}
            </ManageableItemsList.Table>
            <ManageableItemsList.PaginationBar
              pageSize={data?.limit || 30}
              currentPage={
                Math.ceil((data?.offset || 0) / (data?.limit || 30)) + 1
              }
              totalItems={data?.totalCount || 0}
              onPageChange={(page) =>
                setPagination((x) => ({
                  ...x,
                  offset: (page - 1) * (pagination?.limit || 30),
                }))
              }
            />
          </ManageableItemsList>
        </div>
        <CreateTagModal
          isOpen={showCreateTagModal}
          onClose={() => setShowCreateTagModal(false)}
          onSuccess={() => {
            dispatch(requestGetTags());
            refetch();
          }}
        />
        {tagToEdit && (
          <EditTagModal
            isOpen={!!tagToEdit}
            onClose={() => setTagToEdit(undefined)}
            tag={tagToEdit}
            onSuccess={() => {
              dispatch(requestGetTags());
              refetch();
              setTagToEdit(undefined);
            }}
          />
        )}
        {tagIdToArchive && (
          <ArchiveTagModal
            isOpen={!!tagIdToArchive}
            onClose={() => {
              setTagIdToArchive(undefined);
            }}
            tagId={tagIdToArchive}
            onSuccess={() => {
              dispatch(requestGetTags());
              refetch();
              setTagIdToArchive(undefined);
            }}
          />
        )}
      </AuthenticatedPageWrapper>
    </>
  );
};

const ArchiveTagModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  tagId: string;
  onSuccess: () => void;
}> = ({ isOpen, onClose, tagId, onSuccess }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { mutateAsync: archiveTagMutation } = useArchiveTag();

  const onSubmit = useCallback(() => {
    setIsProcessing(true);
    archiveTagMutation({ tagId })
      .then(() => {
        onClose();
        onSuccess?.();
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, [onSuccess, archiveTagMutation, onClose, tagId]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Archive Tag">
      <Modal.Body>Are you sure you want to archive this Tag?</Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            colour={EBtnColour.Red}
            isProcessing={isProcessing}
            onClick={() => !isProcessing && onSubmit()}
          >
            Yes, archive
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};
