import { useQueryClient } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useUpdateProject from '@/api/mutations/project/useUpdateProject';
import projectKeys from '@/api/queries/project/keyFactory';
import useGetProjectOverview from '@/api/queries/project/useGetProjectOverview';
import { ProjectQuery } from '@/gql/graphql';
import Button from '@payaca/components/plButton/Button';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import { Textarea } from '@payaca/components/plTextarea/Textarea';

export interface IProps extends Omit<IModalProps, 'title'> {}

const EditProjectModal: FC<IProps> = (props) => {
  const { ...rest } = props;
  const { dealId } = useParams<{ dealId: string }>();
  const queryClient = useQueryClient();
  const { data: projectData } = useGetProjectOverview(+dealId);
  const { mutateAsync: updateProjectMutation } = useUpdateProject({
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: projectKeys.overview(+dealId),
      });

      // Snapshot the previous value
      const previousProjectOver = queryClient.getQueryData(
        projectKeys.overview(+dealId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<ProjectQuery>(
        projectKeys.overview(+dealId),
        (old) => {
          if (!old) return;

          if (!variables.customReference && !variables.description) {
            // Should never get here
            return old;
          }

          return {
            ...old,
            project: {
              ...old.project,
              reference: variables.customReference || old.project.reference,
              description:
                variables.description?.length !== 0
                  ? variables.description
                  : null,
            },
          };
        }
      );

      // Return a context object with the snapshotted value
      return { previousProjectOver };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        projectKeys.overview(+dealId),
        // @ts-ignore
        context.previousProjectOver
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.project(+dealId),
      });
    },
  });
  const [projectReference, setProjectReference] = useState(
    projectData?.project.reference
  );
  const [projectDescription, setProjectDescription] = useState(
    projectData?.project.description || undefined
  );

  useEffect(() => {
    setProjectReference(projectData?.project.reference);
    setProjectDescription(projectData?.project.description || undefined);
  }, [projectData]);

  const handleSave = () => {
    updateProjectMutation({
      projectId: dealId,
      customReference: projectReference,
      description: projectDescription?.length === 0 ? null : projectDescription,
    });
    rest.onClose?.();
  };

  return (
    <Modal title="Edit Project" {...rest}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        <Modal.Body className="space-y-4">
          <Field>
            <Field.Label>Project reference</Field.Label>
            <Input value={projectReference} onChange={setProjectReference} />
          </Field>
          <Field>
            <Field.Label>Project description</Field.Label>
            <Textarea
              value={projectDescription}
              onChange={setProjectDescription}
            />
          </Field>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Actions>
            <Button type="submit">Save</Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditProjectModal;
