import { Tooltip } from '@material-ui/core';
import * as d3 from 'd3';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
} from 'react';
import HorizontalChart, {
  RangeDatapoint,
} from '../horizontalChart/HorizontalChart';
import './HorizontalBoxPlot.sass';

type Props = {
  datapoints: RangeDatapoint[];
  valueFormatter?: (value: number) => string;
  axisTickFormatter?: (value: number) => string;
  renderLabel?: (datapoint: RangeDatapoint) => React.ReactNode;
};
const HorizontalBoxPlot: FunctionComponent<PropsWithChildren<Props>> = ({
  datapoints,
  valueFormatter = (value) => value.toString(),
  axisTickFormatter = (value) => value.toString(),
  renderLabel = (datapoint) => <span>{datapoint.label}</span>,
}: PropsWithChildren<Props>): JSX.Element | null => {
  const renderDatapoint = useCallback(
    (
      datapoint: RangeDatapoint,
      i: number,
      xScale: d3.ScaleLinear<number, number, never>
    ) => {
      const data = datapoint.data;
      const range = data.max - data.min;
      const iqRange = data.q3 - data.q1;

      const barColor = datapoint.colour || d3.schemeTableau10[i % 10];
      return (
        <div>
          <div
            className="range"
            style={{
              width: `${xScale(range)}%`,
              left: `${xScale(data.min)}%`,
            }}
          />
          <Tooltip
            title={
              <>
                <ul>
                  {data.q1 != data.q3 && (
                    <li>
                      The coloured box spans from{' '}
                      <strong>
                        {valueFormatter(data.q1)} to {valueFormatter(data.q3)}
                      </strong>{' '}
                      - 50% of values fall within this range
                    </li>
                  )}
                  <li>
                    The line{' '}
                    {data.q1 != data.q3 && <>in the middle of the box </>}
                    represents a value of{' '}
                    <strong>{valueFormatter(data.q2)}</strong> - this is the
                    median value
                  </li>
                  {data.min != data.max && (
                    <>
                      <li>
                        The bottom whisker extends to{' '}
                        <strong>{valueFormatter(data.min)}</strong> - this is
                        the smallest value{' '}
                        {data.outliers?.length && <>(excluding any outliers)</>}
                      </li>
                      <li>
                        The top whisker extends to{' '}
                        <strong>{valueFormatter(data.max)}</strong> - this is
                        the largest value{' '}
                        {data.outliers?.length && <>(excluding any outliers)</>}
                      </li>
                    </>
                  )}
                </ul>
              </>
            }
            arrow
          >
            <div
              className="iq-range"
              style={{
                width: `${xScale(iqRange)}%`,
                backgroundColor: barColor,
                left: `${xScale(data.q1)}%`,
              }}
            />
          </Tooltip>
          <div
            className="median"
            style={{
              left: `${xScale(data.q2)}%`,
            }}
          />
          {data.outliers?.map((outlier, i) => {
            return (
              <Tooltip
                title={
                  <>
                    Outlier: <strong>{valueFormatter(outlier)}</strong>
                  </>
                }
                key={`outlier-tooltip-${i}`}
                arrow
              >
                <div
                  key={`outlier-${i}`}
                  className="outlier"
                  style={{
                    left: `${xScale(outlier)}%`,
                  }}
                />
              </Tooltip>
            );
          })}
        </div>
      );
    },
    [valueFormatter]
  );

  return (
    <HorizontalChart
      className="horizontal-box-plot"
      datapoints={datapoints}
      axisTickFormatter={axisTickFormatter}
      renderLabel={renderLabel}
      isDiscrete={true}
      renderDatapoint={renderDatapoint}
    />
  );
};

export default HorizontalBoxPlot;

//

type PropsA<T extends string | number> = {
  data: T[];
  onChange: (t: T) => void;
};

const A = <T extends string | number>({
  data,
  onChange,
}: PropsA<T>): React.ReactElement | null => {
  data.length && onChange(data[0]);

  return <p>hi</p>;
};

<A data={['string']} onChange={(t: string) => console.log(t)}></A>;
