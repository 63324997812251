import { faPercent } from '@fortawesome/free-solid-svg-icons';
import { FC, PropsWithChildren, useContext, useMemo, useRef } from 'react';
import { Danger } from 'react-iconly';
import { IntercomAPI } from 'react-intercom';
import { useDispatch } from 'react-redux';

import BasicField from '@payaca/components/basicField/BasicField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import SwitchField from '@payaca/components/switchField/SwitchField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';

import { actions as usersActions } from '@/api/users';
import { parseCustomerAddress } from '@/helpers/customerHelper';
import { useCompanyInfoFormValidators } from '@/hooks/useCompanyInfoValidators';
import { useFormState } from '@/hooks/useFormState';
import { useBlockedEmails } from '@/utils/customHooks';
import { useAccount } from '@/utils/storeHooks';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import Card from '@payaca/components/plCard/Card';
import {
  cansupportTwilioSenderHandle,
  getRegionalTextString,
} from '@payaca/helpers/internationalHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';

type Props = {
  readOnly: boolean;
};

type FormState = {
  legalBusinessName: string;
  businessName: string;
  smsSenderHandle: string;
  email: string;
  contactNumber: string;
  firstLineAddress: string;
  secondLineAddress: string;
  city: string;
  postcode: string;
  registeredCountryName: string;
  companyRegistrationNumber: string;
  propositionValidForDays: number | null;
  invoiceDueInDays: number | null;
  gasSafeRegNo: number | null;
  niceicRegNo: string | null;
  hideItemPrices: boolean;
  hideVatBreakdown: boolean;
  isCisSubcontractor: boolean;
  cisDeductionRate: number | null;
  defaultDepositPercentage: number | null;
};

type FormValidationResult = Partial<
  Record<keyof FormState, FieldValidationResult>
>;
const BusinessSettingsCompanyDetails: FC<Props> = ({
  readOnly,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const account = useAccount();

  const isConnectedToQuickbooks = account.integrations?.quickbooks;
  const isConnectedToXero = account.integrations?.xero;

  const { postcodeRegionalString, showCIS, showGasSafe, showNiceic } =
    useMemo(() => {
      const isUkAccount = [AccountRegions.UK].includes(account.region);
      return {
        postcodeRegionalString: getRegionalTextString(
          account.region,
          RegionalStrings.POSTCODE
        ),
        showCIS: isUkAccount,
        showGasSafe: isUkAccount,
        showNiceic: isUkAccount,
      };
    }, [account]);

  const { firstLineAddress, secondLineAddress } = parseCustomerAddress(
    account.address
  );

  const initialState: FormState = {
    legalBusinessName: account.legalBusinessName ?? '',
    businessName: account.companyName ?? '',
    smsSenderHandle: account.smsSenderHandle ?? '',
    email: account.email ?? '',
    contactNumber: account.contactNumber ?? '',
    firstLineAddress: firstLineAddress ?? '',
    secondLineAddress: secondLineAddress ?? '',
    city: account.city ?? '',
    postcode: account.postcode ?? '',
    registeredCountryName: account.registeredCountryName ?? '',
    companyRegistrationNumber: account.companyRegistrationNumber ?? '',
    propositionValidForDays: account.propositionValidForDays,
    invoiceDueInDays: account.invoiceDueInDays,
    gasSafeRegNo: account.gasSafeRegNo ?? '',
    niceicRegNo: account.niceicRegNo ?? '',
    hideItemPrices: account.hideItemPrices ?? false,
    hideVatBreakdown: account.hideVatBreakdown ?? false,
    isCisSubcontractor: account.isCisSubcontractor ?? false,
    cisDeductionRate: account.cisDeductionRate ?? 0,
    defaultDepositPercentage: account.defaultDepositPercentage ?? 0,
  };
  const blockedEmails = useBlockedEmails();
  const fieldValidators = useCompanyInfoFormValidators(blockedEmails);

  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const clearDynamicFeedbackMessage = useRef<() => void>();
  const saveFormData = useMemo(
    () => async (state: FormState) => {
      clearDynamicFeedbackMessage.current?.();
      return new Promise<void>((resolve, reject) => {
        dispatch(
          usersActions.updateBusinessAccount(
            account.id,
            state,
            (err: unknown, response: any) => {
              if (err) {
                clearDynamicFeedbackMessage.current =
                  showDynamicFeedbackMessage({
                    title: `We couldn't save your changes`,
                    isCancellable: true,
                    feedbackLevel: FeedbackLevel.ERROR,
                  });
                reject(err);
              } else {
                clearDynamicFeedbackMessage.current =
                  showDynamicFeedbackMessage({
                    title: 'Your changes have been saved',
                    lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
                    feedbackLevel: FeedbackLevel.SUCCESS,
                  });
                resolve();
              }
            }
          )
        );
      });
    },
    []
  );

  const {
    formState,
    updateFormFields,
    formValidationResult,
    setFormValidationResult,
  } = useFormState<FormState, FormValidationResult>(
    initialState,
    fieldValidators,
    {
      autoSaveFn: saveFormData,
      autoSaveDebounceMillis: 1500,
    }
  );

  const showTwilioSenderHandle = useMemo(
    () => cansupportTwilioSenderHandle(account.region),
    [account.region]
  );

  return (
    <div className="flex flex-col gap-y-4">
      <Card>
        <Card.Body className="flex flex-col gap-2">
          <div>
            <span>
              Your registered business name is stored as{' '}
              <strong>{formState.legalBusinessName}</strong>. If this is
              incorrect or you wish to change it,{' '}
              <span onClick={() => IntercomAPI('show')} className="clickable">
                please contact us directly
              </span>
              .
            </span>
          </div>
          <ValidatedFieldWrapper
            validationResult={formValidationResult.businessName}
          >
            <BasicField
              name="businessName"
              label="Trading name"
              value={formState.businessName}
              isRequired={true}
              styleVariant={InputStyleVariant.OUTSIZE}
              onChange={updateFormFields}
            />
          </ValidatedFieldWrapper>
          {showTwilioSenderHandle && (
            <ValidatedFieldWrapper
              validationResult={formValidationResult.smsSenderHandle}
            >
              <BasicField
                name="smsSenderHandle"
                label={
                  'Send SMS messages from (Alphanumeric, 4-11 characters, must contain 1 letter)'
                }
                value={formState.smsSenderHandle}
                isRequired={true}
                styleVariant={InputStyleVariant.OUTSIZE}
                onChange={updateFormFields}
              />
            </ValidatedFieldWrapper>
          )}
          <ValidatedFieldWrapper validationResult={formValidationResult.email}>
            <LoqateAdvisoryEmailInputField
              name="email"
              label={'Email address'}
              value={formState.email}
              styleVariant={InputStyleVariant.OUTSIZE}
              onChange={updateFormFields}
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={formValidationResult.contactNumber}
          >
            <BasicField
              name="contactNumber"
              label={'Telephone number'}
              value={formState.contactNumber}
              styleVariant={InputStyleVariant.OUTSIZE}
              onChange={updateFormFields}
            />
          </ValidatedFieldWrapper>
          <BasicField
            name="firstLineAddress"
            label="Address line 1"
            value={formState.firstLineAddress}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={updateFormFields}
          />
          <BasicField
            name="secondLineAddress"
            label="Address line 2"
            value={formState.secondLineAddress}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={updateFormFields}
          />
          <BasicField
            name="city"
            label="Town"
            value={formState.city}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={updateFormFields}
          />
          <BasicField
            name="postcode"
            label={postcodeRegionalString}
            value={formState.postcode}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={updateFormFields}
          />
          <BasicField
            name="registeredCountryName"
            label="Registered country name"
            value={formState.registeredCountryName ?? ''}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={updateFormFields}
          />
          <BasicField
            name="companyRegistrationNumber"
            label="Company registration number"
            value={formState.companyRegistrationNumber ?? ''}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={updateFormFields}
          />
        </Card.Body>
      </Card>

      {/* Default quoteValidForDays/invoiceDueInDays */}
      <Card>
        <Card.Body className="flex flex-col gap-2">
          <h2>Proposals and invoices</h2>
          <p>
            Default behaviour for number of days a proposal is valid for and
            invoice is due until. These can be changed on a per job basis.
          </p>
          <ValidatedFieldWrapper
            validationResult={formValidationResult.propositionValidForDays}
          >
            <BasicField
              label={'Default days proposal is valid for'}
              name="propositionValidForDays"
              value={formState.propositionValidForDays}
              type="number"
              onChange={(value: {
                [propositionValidForDays: string]: string;
              }) => {
                if (
                  Number(value.propositionValidForDays) &&
                  value.propositionValidForDays !== ''
                ) {
                  updateFormFields({
                    propositionValidForDays: Math.round(
                      Number(value.propositionValidForDays)
                    ),
                  });
                } else {
                  updateFormFields({
                    propositionValidForDays: null,
                  });
                }
              }}
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={formValidationResult.invoiceDueInDays}
          >
            <BasicField
              label={'Default days invoice is due in'}
              name="invoiceDueInDays"
              value={formState.invoiceDueInDays}
              type="number"
              onChange={(value: { [invoiceDueInDays: string]: string }) => {
                if (
                  Number(value.invoiceDueInDays) >= 0 &&
                  value.invoiceDueInDays !== ''
                ) {
                  updateFormFields({
                    invoiceDueInDays: Math.round(
                      Number(value.invoiceDueInDays)
                    ),
                  });
                } else {
                  updateFormFields({
                    invoiceDueInDays: null,
                  });
                }
              }}
            />
          </ValidatedFieldWrapper>
        </Card.Body>
      </Card>

      {/* Professional accreditations */}
      {(showGasSafe || showNiceic) && (
        <Card>
          <Card.Body className="flex flex-col gap-2">
            <h2>Professional accreditations</h2>
            <p>
              If your business is registered with any professional accreditation
              bodies, please enter your registration numbers below. These will
              automatically be entered into any forms you complete using Payaca.
            </p>
            {showGasSafe && (
              <BasicField
                label={'Gas Safe Reg. Number'}
                name="gasSafeRegNo"
                value={formState.gasSafeRegNo}
                type="number"
                onChange={(value: { [gasSafeRegNo: string]: string }) => {
                  if (Number(value.gasSafeRegNo) && value.gasSafeRegNo !== '') {
                    updateFormFields({
                      gasSafeRegNo: Number(value.gasSafeRegNo),
                    });
                  } else {
                    updateFormFields({
                      gasSafeRegNo: null,
                    });
                  }
                }}
              />
            )}
            {showNiceic && (
              <BasicField
                label={'NICEIC Reg. Number'}
                name="niceicRegNo"
                value={formState.niceicRegNo}
                type="text"
                onChange={(value: { [niceicRegNo: string]: string }) => {
                  if (value.niceicRegNo) {
                    updateFormFields({
                      niceicRegNo: value.niceicRegNo,
                    });
                  } else {
                    updateFormFields({
                      niceicRegNo: null,
                    });
                  }
                }}
              />
            )}
          </Card.Body>
        </Card>
      )}

      {/*Deal settings*/}
      <Card>
        <Card.Body className="flex flex-col gap-2">
          <h2>Project Settings</h2>
          <div>
            <SwitchField
              name="hideItemPrices"
              value={formState.hideItemPrices}
              onChange={updateFormFields}
              label="Hide item prices"
              isDisabled={readOnly}
            />
            <p>
              Default behaviour for hiding item prices on your quotes, estimates
              and invoices. This can be changed on a per-Project basis.
            </p>
          </div>
          <div>
            <SwitchField
              name="hideVatBreakdown"
              value={formState.hideVatBreakdown}
              onChange={updateFormFields}
              label={`Hide tax breakdown`}
              isDisabled={readOnly}
            />
            <p>
              Default behaviour for hiding tax values on your quotes, estimates
              and change proposals. This can be changed on a per-Project basis.
              Invoices will not be affected by this setting and will always show
              the tax breakdown.
            </p>
          </div>
          <div className="default-deposit-percentage-wrapper">
            <BasicField
              styleVariant={InputStyleVariant.OUTSIZE}
              value={formState.defaultDepositPercentage}
              name={'defaultDepositPercentage'}
              onChange={(value: { [key: string]: any }) => {
                updateFormFields({
                  defaultDepositPercentage: value.defaultDepositPercentage
                    ? Math.abs(
                        Math.round(value.defaultDepositPercentage * 100) / 100
                      )
                    : value.defaultDepositPercentage,
                });
              }}
              type="number"
              additionalInputProps={{
                pattern: '[0-9]*',
                min: 0,
                max: 100,
                step: 1,
              }}
              iconAfter={faPercent}
              label="Default deposit percentage"
            />
          </div>
        </Card.Body>
      </Card>

      {/* CIS */}
      {showCIS && (
        <Card>
          <Card.Body className="flex flex-col gap-2">
            <h2>Construction industry scheme</h2>
            <SwitchField
              name="isCisSubcontractor"
              value={formState.isCisSubcontractor}
              onChange={(value: { [isCisSubcontractor: string]: boolean }) => {
                updateFormFields({
                  ...value,
                  cisDeductionRate:
                    value.isCisSubcontractor === true ? 20 : null,
                });
              }}
              label="I am a CIS subcontractor"
              isDisabled={readOnly}
            />
            {formState.isCisSubcontractor && (
              <DropdownField
                name="cisDeductionRate"
                isDisabled={readOnly}
                value={formState.cisDeductionRate}
                label={'CIS deduction rate'}
                onChange={updateFormFields}
                options={[
                  {
                    label: '0%',
                    value: 0,
                  },
                  {
                    label: '20%',
                    value: 20,
                  },
                  {
                    label: '30%',
                    value: 30,
                  },
                ]}
              />
            )}
            {formState.isCisSubcontractor && (
              <CisError>
                <p>
                  If you change the CIS deduction rate for this company, CIS
                  deduction rates will be updated against all applicable line
                  items and against any quotes and invoices which have not been
                  sent. The old CIS deduction rate will remain on any sent
                  quotes and invoices.
                </p>
              </CisError>
            )}
            {isConnectedToQuickbooks && formState.isCisSubcontractor && (
              <CisError>
                <p>
                  Your account is connected to Quickbooks, but our Quickbooks
                  integration does not currently support CIS behaviours.
                </p>
              </CisError>
            )}
            {isConnectedToXero && formState.isCisSubcontractor && (
              <CisError>
                <p>
                  Your account is connected to Xero. You must have CIS
                  behaviours enabled in Xero for the integration to function.
                  Please{' '}
                  <strong>
                    <a
                      href="https://central.xero.com/s/article/Enable-CIS-in-your-organisation"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      follow these instructions
                    </a>
                  </strong>{' '}
                  to enable CIS for your organisation in Xero if you haven&#39;t
                  already.
                </p>
                <p>
                  Make sure your CIS subcontractor deduction rates in Xero and
                  Payaca match.
                </p>
              </CisError>
            )}
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

type CisErrorProps = {
  doShow?: boolean;
};
export const CisError: FC<PropsWithChildren<CisErrorProps>> = ({
  children,
  doShow,
}: PropsWithChildren<CisErrorProps>): JSX.Element => {
  return (
    <div className={`cis-error${doShow ? ' show' : ''}`}>
      <Danger size="small" />
      <div>{children}</div>
    </div>
  );
};

export default BusinessSettingsCompanyDetails;
