import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import customerKeys from '@/api/queries/customers/keyFactory';
import { graphql } from '@/gql';

const GetCustomerQuery = graphql(`
  query Customer($customerId: Int!) {
    customer(id: $customerId) {
      id
      name
      tags {
        id
        name
        colour
      }
      fileSharingSettings {
        attachToEmails
      }
      billingAddress {
        id
        line1
        line2
        city
        country
        postalCode
        fullLocalAddress
      }
      primaryContact {
        id
        name
        description
        email
        phone
      }
      contacts {
        id
        name
        description
        email
        phone
        isPrimary
      }
      totals {
        projectValue {
          value
          currency {
            code
            exponent
          }
        }
        acceptedValue {
          value
          currency {
            code
            exponent
          }
        }
        sentInvoicedValue {
          value
          currency {
            code
            exponent
          }
        }
        completedPaymentValue {
          value
          currency {
            code
            exponent
          }
        }
      }
      addresses {
        id
        line1
        line2
        city
        country
        postalCode
        fullLocalAddress
        contacts {
          id
          name
          description
          email
          phone
        }
      }
    }
  }
`);

const useGetCustomer = (customerId?: string) => {
  return useQuery({
    enabled: !!customerId,
    queryKey: customerKeys.customer(customerId!),
    queryFn: () => {
      return gqlClient.request(GetCustomerQuery, {
        customerId: parseInt(customerId!),
      });
    },
  });
};

export default useGetCustomer;
