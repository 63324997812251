// ======================================================================================================
// Setup account form
// - Set up business details
// ======================================================================================================

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import FileUploadPersistRemoveControl from '@payaca/components/fileUploadPersistRemoveControl/FileUploadPersistRemoveControl';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';

import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';

import { IndustryTypes } from '@payaca/types/companyInformationTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import { actions as usersActions } from '@/api/users';

import { useSelector } from '@/api/state';
import {
  INDUSTRY_OPTIONS,
  NUMBER_OF_EMPLOYEES_OPTIONS,
} from '@payaca/constants/companySetupConstants';
import { ImageFileExtensions } from '@payaca/helpers/fileHelper';
import './SetupBusinessForm.sass';

interface Props {
  onSubmitBusiness: (companyInfo: { [key: string]: any }) => void;
  isCreatingUpdatingAccount: boolean;
  createUpdateBusinessError: string | null;
}
const SetupBusinessForm: FC<Props> = ({
  createUpdateBusinessError,
  isCreatingUpdatingAccount,
  onSubmitBusiness,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [triggerValidationAt, setTriggerValidationAt] = useState<Date>();
  const [uploadedAccountLogo, setUploadedAccountLogo] = useState<any>();
  const [isUploadingAccountLogo, setIsUploadingAccountLogo] =
    useState<boolean>(false);
  const [uploadLogoErrorMessage, setUploadLogoErrorMessage] = useState<
    string | null
  >(null);

  const account = useSelector(
    (state: any) => state.users.myProfile?.accounts?.[0]
  );

  const accountId = useMemo(() => {
    return account?.id;
  }, [account]);

  useEffect(() => {
    if (!accountId) {
      dispatch(usersActions.getProfile());
    }
  }, [accountId]);

  const initialFormState = useMemo(() => {
    if (!account) {
      return {};
    }
    const params = new URLSearchParams(location.search);
    return {
      legalBusinessName: params.get('company'),
    };
  }, [account, location]);

  const fieldValidators = useMemo(() => {
    const isRequiredFieldValidator = getIsRequiredFieldValidator();

    const validators: any = {
      legalBusinessName: [isRequiredFieldValidator],
    };
    if (!account?.numberOfEmployees) {
      validators.numberOfEmployees = [isRequiredFieldValidator];
    }
    if (!account?.industryType) {
      validators.industryType = [isRequiredFieldValidator];
    }
    return validators;
  }, [account]);

  const onSetupBusinessError = useCallback(() => {
    setUploadLogoErrorMessage(
      'Sorry, there was an error updating your account, please try again.'
    );
  }, []);

  const uploadLogo = useCallback(
    (logoFile: File) => {
      setIsUploadingAccountLogo(true);
      return new Promise<void>((resolve, reject) => {
        // upload account logo
        dispatch(
          usersActions.uploadAccountLogo(
            accountId,
            {
              file: logoFile,
              fileName: logoFile.name,
            },
            (uploadLogoError: Error, accountLogo: any) => {
              setIsUploadingAccountLogo(false);
              if (uploadLogoError) {
                onSetupBusinessError();
              } else {
                setUploadedAccountLogo({
                  ...accountLogo,
                  fileName: logoFile.name,
                });
                resolve();
              }
            }
          )
        );
      });
    },
    [accountId, dispatch]
  );
  const removeLogo = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        usersActions.uploadAccountLogo(
          accountId,
          null,
          (uploadLogoError: Error) => {
            setIsUploadingAccountLogo(false);
            if (uploadLogoError) {
              onSetupBusinessError();
            } else {
              setUploadedAccountLogo(null);
              resolve();
            }
          }
        )
      );
    });
  }, [accountId, dispatch]);

  const uploadStatus = useMemo(() => {
    if (uploadedAccountLogo) {
      return 'Upload complete!';
    } else if (isUploadingAccountLogo) {
      return 'Uploading file...';
    }
  }, [isUploadingAccountLogo, uploadedAccountLogo]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          {/* Registered business name */}
          <ValidatedFieldWrapper
            validationResult={validationState.legalBusinessName}
            isTouched={touchedState.legalBusinessName || false}
          >
            <BasicField
              name="legalBusinessName"
              isRequired={true}
              label="Registered business name"
              onChange={(change: any) => {
                onFieldChange(change);
              }}
              onTouch={onFieldTouch}
              styleVariant={InputStyleVariant.OUTSIZE}
              value={formState.legalBusinessName || ''}
            />
          </ValidatedFieldWrapper>

          {/* Number of employees - only if not set on account (user registered on mobile or industry landing from marketing) */}
          {!account?.numberOfEmployees && (
            <ValidatedFieldWrapper
              validationResult={validationState.numberOfEmployees}
              isTouched={touchedState.numberOfEmployees || false}
            >
              <DropdownField
                label="Number of employees"
                isRequired={true}
                name="numberOfEmployees"
                onChange={(change: any) => {
                  onFieldChange(change);
                }}
                options={NUMBER_OF_EMPLOYEES_OPTIONS}
                styleVariant={InputStyleVariant.OUTSIZE}
                value={formState.numberOfEmployees}
              />
            </ValidatedFieldWrapper>
          )}

          {/* Industry - only if not set on account (user registered on mobile) */}
          {!account?.industryType && (
            <>
              <ValidatedFieldWrapper
                validationResult={validationState.industryType}
                isTouched={touchedState.industryType || false}
              >
                <DropdownField
                  label="Industry"
                  isRequired={true}
                  name="industryType"
                  onChange={(change: any) => {
                    const update = { ...change };
                    // clear industryTypeOther if picking predefind industry type
                    if (change.industryType !== IndustryTypes.OTHER) {
                      update.industryTypeOther = null;
                    }
                    onFieldChange(update);
                  }}
                  options={INDUSTRY_OPTIONS}
                  styleVariant={InputStyleVariant.OUTSIZE}
                  value={formState.industryType}
                />
              </ValidatedFieldWrapper>
              {formState.industryType === IndustryTypes.OTHER && (
                <ValidatedFieldWrapper
                  validationResult={validationState.industryTypeOther}
                  isTouched={touchedState.industryTypeOther || false}
                >
                  <BasicField
                    name="industryTypeOther"
                    isRequired={true}
                    onChange={(change: any) => {
                      onFieldChange(change);
                    }}
                    styleVariant={InputStyleVariant.OUTSIZE}
                    value={formState.industryTypeOther}
                    additionalInputProps={{ placeholder: 'Enter industry...' }}
                  />
                </ValidatedFieldWrapper>
              )}
            </>
          )}

          {/* Business logo - only if account has been setup (web only) */}
          {account?.id && (
            <>
              {uploadStatus && (
                <div className="upload-status">{uploadStatus}</div>
              )}
              <FileUploadPersistRemoveControl
                label="Company logo"
                acceptFileTypes={ImageFileExtensions}
                enableDragAndDrop={true}
                persistFile={uploadLogo}
                removePersistedFile={removeLogo}
                persistedFiles={
                  uploadedAccountLogo
                    ? [
                        {
                          fileName: uploadedAccountLogo.fileName,
                          identifier: uploadedAccountLogo.logoUrl,
                          thumbnailUrl: uploadedAccountLogo.logoUrl,
                        },
                      ]
                    : []
                }
                showFileNames={false}
                allowMultipleUploads={false}
              />
              {/* Error message */}
              {uploadLogoErrorMessage && (
                <div className="error">{uploadLogoErrorMessage}</div>
              )}
            </>
          )}

          {/* Continue button */}
          <div className="button-wrapper">
            <Button
              isDisabled={!isValid}
              onClickDisabled={() => setTriggerValidationAt(new Date())}
              onClick={() => {
                if (!isCreatingUpdatingAccount) {
                  onSubmitBusiness({
                    ...formState,
                    preferredTimezone: getBrowserTimezone(),
                    preferredLocale: getBrowserLocale(),
                  });
                }
              }}
              isProcessing={isCreatingUpdatingAccount}
              styleVariant={ButtonStyleVariant.OUTSIZE}
              colourVariant={ButtonColourVariant.PRIMARY}
            >
              Go to my dashboard
            </Button>
            {createUpdateBusinessError && (
              <ErrorMessage message={createUpdateBusinessError} />
            )}
          </div>
        </>
      );
    },
    [
      account,
      createUpdateBusinessError,
      isCreatingUpdatingAccount,
      onSubmitBusiness,
      uploadStatus,
      uploadLogoErrorMessage,
    ]
  );

  return (
    <div className="setup-business-form">
      <ValidatedForm<{ [key: string]: any }>
        triggerValidationAt={triggerValidationAt}
        fieldValidators={fieldValidators}
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
      />
    </div>
  );
};

const getBrowserLocale = () =>
  (typeof window !== 'undefined' && window.navigator?.language) || undefined;

const getBrowserTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (err) {
    return undefined;
  }
};

export default SetupBusinessForm;
