import Field from '@payaca/components/plField/Field';
import Select from '@payaca/components/plSelect/Select';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Props as DyanmicFieldProps, fieldProps } from './DynamicField';
import FieldSet from './FieldSet';
import {
  getFieldLabelFromFieldSchema,
  getSchemaDataForDiscriminatorField,
} from './utils';

type Props = Omit<DyanmicFieldProps, 'dependentFields'>;
const DiscriminatorField: FC<Props> = (props) => {
  const { definitions, fieldSchema, name: namespace } = props;
  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();
  // discriminator object containing property and mapping refs
  const discriminatorObj = fieldSchema.discriminator;

  // name of discriminator property
  const discriminatorPropertyName = discriminatorObj.propertyName;

  // select options to choose discriminator property value
  const discriminatorOptions: string[] = Object.keys(discriminatorObj.mapping);

  // name for the nested field
  const nestedName = `${namespace}.${discriminatorPropertyName}`;

  // get the value of the discriminator property
  const discriminatorPropertyValue = watch(nestedName);

  // get the nested schema for the selected discriminator value
  const nestedSchemaData = getSchemaDataForDiscriminatorField(
    fieldSchema.discriminator,
    definitions,
    discriminatorPropertyValue
  );

  const fieldLabel = getFieldLabelFromFieldSchema(fieldSchema);

  return (
    <>
      <Field {...fieldProps(errors, nestedName)}>
        <Field.Label>{fieldLabel}</Field.Label>
        <Controller
          control={control}
          name={nestedName}
          render={({ field: { ref, value, onChange, ...rest } }) => (
            <Select
              {...rest}
              value={value || null}
              options={discriminatorOptions.map((option) => ({
                label: option,
                value: option,
              }))}
              placeholder={fieldSchema.json_schema_extra.placeholderText}
              onChange={(change) => {
                const newNestedSchemaData = getSchemaDataForDiscriminatorField(
                  fieldSchema.discriminator,
                  definitions,
                  change
                );
                if (
                  discriminatorPropertyValue !== change &&
                  newNestedSchemaData
                ) {
                  // discriminator has changed - reset the other fields
                  Object.keys(newNestedSchemaData.properties).forEach((key) => {
                    setValue(`${namespace}.${key}`, null);
                  });
                }

                // update the discriminator value
                onChange(change);
              }}
            />
          )}
        />
      </Field>

      {/* conditional fields based on discriminator */}
      {nestedSchemaData && (
        <FieldSet
          namespace={namespace}
          data={nestedSchemaData}
          definitions={definitions}
        />
      )}
    </>
  );
};

export default DiscriminatorField;
