import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import projectKeys from '@/api/queries/project/keyFactory';
import { graphql } from '@/gql';
import { GetTasksInput, PaginationInput } from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';

const GetProjectTasks = graphql(`
  query ProjectTasks(
    $projectId: Int!
    $pagination: PaginationInput!
    $input: GetTasksInput
  ) {
    project(id: $projectId) {
      tasks(pagination: $pagination, input: $input) {
        items {
          __typename
          ... on IEntity {
            id
          }
          createdBy {
            id
          }
          name
          deadline
          completionStatus
          assignee {
            id
            firstName
            lastName
            fullName
            colour
            email
            avatarUrl
          }
          events {
            id
            name
          }
          ... on ChecklistTask {
            name
            completedAt
            checklistItems {
              id
              completedAt
            }
          }
          ... on FormTask {
            name
            completedAt
            formRevisions {
              id
            }
          }
          ... on LegacyFormTask {
            name
            completedAt
            formRevisions {
              id
            }
          }
        }
        totalCount
      }
    }
  }
`);

const useGetProjectTasks = (
  projectId?: number,
  input?: GetTasksInput,
  pagination?: PaginationInput
) => {
  const { data, ...rest } = useQuery({
    enabled: !!projectId,
    queryKey: projectKeys.tasks(projectId!, input, pagination),
    queryFn: () => {
      return gqlClient.request(GetProjectTasks, {
        projectId: projectId!,
        input,
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
      });
    },
  });

  return { tasks: data?.project.tasks, data, ...rest };
};

export default useGetProjectTasks;
