import { FC, useCallback, useEffect } from 'react';

import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { PurchaseOrdersManageableItemsListTable } from '@/ui/components/purchaseOrdersManageableItemsListTable/PurchaseOrdersManageableItemsListTable';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import { Deal } from '@payaca/types/dealTypes';
import { MaterialsList } from '@payaca/types/materialsListTypes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useGetProjectPurchaseOrders from '../../../../../api/queries/project/useGetProjectPurchaseOrders';
import { usePaginationState } from './materialsPanelHooks';

const PAGE_SIZE = 25;

export const PurchaseOrdersPanel: FC<{
  projectId: Deal['id'];
  materialsListId: MaterialsList['id'];
}> = ({ projectId, materialsListId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleGetMaterialsListWithRelatedEntities = useCallback(
    (materialsListId: number) => {
      dispatch(
        materialsListActions.requestGetMaterialsListWithRelatedEntities(
          materialsListId
        )
      );
    },
    []
  );

  useEffect(() => {
    handleGetMaterialsListWithRelatedEntities(materialsListId);
  }, []);

  const pagination = usePaginationState(PAGE_SIZE);

  const { purchaseOrders } = useGetProjectPurchaseOrders({
    projectId,
    pagination,
  });

  return (
    <div className="@container">
      <PermissionGuard
        renderIfHasPermissions={[MaterialsListPermissions.GET_PURCHASE_ORDERS]}
      >
        <PurchaseOrdersManageableItemsListTable
          purchaseOrders={purchaseOrders?.items || []}
          isLoading={!purchaseOrders}
          includedColumns={[
            'ref',
            'status',
            'supplier',
            'purchase-cost',
            'forecast-cost',
          ]}
          returnToProjectOnBackNav
        ></PurchaseOrdersManageableItemsListTable>
        {purchaseOrders && (
          <ManageableItemsList.PaginationBar
            totalItems={purchaseOrders.totalCount}
            pageSize={purchaseOrders.limit}
            currentPage={purchaseOrders.offset / purchaseOrders.limit + 1}
            onPageChange={pagination.setCurrentPage}
          />
        )}
      </PermissionGuard>
    </div>
  );
};
