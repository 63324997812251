import moment from 'moment-timezone';
import React, { FC } from 'react';

import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import Button from '@payaca/components/plButton/Button';
import StatusBadge from '@payaca/components/statusBadge/StatusBadge';

import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { StatusBadgeState } from '@payaca/helpers/statusBadgeHelper';

import { useAccount } from '../../../utils/storeHooks';

import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import './SubscriptionProductControl.sass';

interface Props {
  statusBadgeConfig?: {
    status: string;
    state: StatusBadgeState;
  };
  title: string;
  productFeatures?: string[];
  paymentMethod?: {
    type: 'card' | 'bacs_debit';
    card?: {
      brand: string;
      expiresMonth: string;
      expiresYear: string;
      last4: string;
    };
  };
  productBreakdown?: { label: string; value: string }[];
  bonuses?: string[];
  subscriptionEndsAt?: Date;
  onUpdate?: () => void;
  onUpdatePaymentMethod?: () => void;
  onCancel?: () => void;
  onRestoreSubscription?: () => void;
  cancelText?: string;
  updateText?: string;
  isBusy?: boolean;
}

const SubscriptionProductControl: FC<Props> = ({
  statusBadgeConfig,
  title,
  productFeatures,
  productBreakdown,
  paymentMethod,
  bonuses,
  subscriptionEndsAt,
  onUpdate,
  onUpdatePaymentMethod,
  onCancel,
  onRestoreSubscription,
  cancelText = 'Cancel',
  updateText = 'Update',
  isBusy,
}: Props): JSX.Element | null => {
  const account = useAccount();

  return (
    <div className="subscription-product-control">
      {statusBadgeConfig && (
        <div>
          <StatusBadge state={statusBadgeConfig.state}>
            {statusBadgeConfig.status}
          </StatusBadge>
        </div>
      )}
      <div className="title-update-container">
        <h4 className="title">{title}</h4>

        {onUpdate && (
          <Button
            disabled={isBusy}
            onClick={onUpdate}
            variant={EBtnVariant.LinkInline}
          >
            {updateText}
          </Button>
        )}
      </div>
      {productFeatures && (
        <ul className="features">
          {productFeatures.map((f, i) => {
            return <li key={`product-feature-${i}`}>{f}</li>;
          })}
        </ul>
      )}

      {!!productBreakdown?.length && (
        <dl className="product-breakdown">
          {productBreakdown.map((info, i) => (
            <React.Fragment key={`product-breakdown-${i}`}>
              {info.label && <dt>{info.label}</dt>}
              <dd>{info.value}</dd>
            </React.Fragment>
          ))}
        </dl>
      )}
      {!!bonuses?.length && (
        <div className="bonuses">
          <strong>Bonuses</strong>
          <ul>
            {bonuses.map((f, i) => {
              return <li key={`bonus-${i}`}>{f}</li>;
            })}
          </ul>
        </div>
      )}
      {paymentMethod && paymentMethod.type === 'card' && paymentMethod.card && (
        <div className="payment-method card">
          <span>{paymentMethod.card.brand}</span>
          <span>
            Exp {paymentMethod.card.expiresMonth}/
            {paymentMethod.card.expiresYear}
          </span>
          <span>•••• •••• •••• {paymentMethod.card.last4}</span>
        </div>
      )}
      {subscriptionEndsAt && (
        <>
          <ErrorMessage
            message={`Subscription ends at ${moment(subscriptionEndsAt).format(
              getInternationalMomentDateFormatByRegion(
                DateFormats.SHORT_READABLE,
                account.region
              )
            )}`}
          />
          {onRestoreSubscription && (
            <Button
              disabled={isBusy}
              onClick={onRestoreSubscription}
              variant={EBtnVariant.LinkInline}
            >
              Restore subscription
            </Button>
          )}
        </>
      )}
      {onUpdatePaymentMethod && (
        <Button
          disabled={isBusy}
          onClick={onUpdatePaymentMethod}
          variant={EBtnVariant.LinkInline}
        >
          Update payment method
        </Button>
      )}
      {onCancel && !subscriptionEndsAt && (
        <Button
          disabled={isBusy}
          onClick={onCancel}
          variant={EBtnVariant.LinkInline}
          className="cancel-button"
        >
          {cancelText}
        </Button>
      )}
    </div>
  );
};

export default SubscriptionProductControl;
