import useOptimisticAddNewSiteAddressToProject from '@/api/mutations/project/optimistic/useOptimisticAddNewSiteAddressToProject';
import useOptimisticLinkSiteAddressToProject from '@/api/mutations/project/optimistic/useOptimisticLinkSiteAddressToProject';
import useOptimisticUnlinkSiteAddressToProject from '@/api/mutations/project/optimistic/useOptimisticUnlinkedSiteAddressFromProject';
import useOptimisticUpdateProjectAddress from '@/api/mutations/project/optimistic/useOptimisticUpdateProjectAddress';
import useGetProjectOverview from '@/api/queries/project/useGetProjectOverview';
import AddAddressModal, {
  IProps as IAddAddressModalProps,
} from '@/ui/components/addressModals/AddAddressModal';
import EditMultipleAddressesAndAddressContactsModal from '@/ui/components/addressModals/EditMultipleAddressesAndAddressContactsModal';
import SelectEditAddressAndAddressContactsModal, {
  IProps as ISelectEditAddressAndAddressContactsModalProps,
} from '@/ui/components/addressModals/SelectEditAddressAndAddressContactsModal';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export interface IProps extends Pick<IModalProps, 'isOpen' | 'onClose'> {
  onDealUpdateSuccessLegacy?: () => void;
}

const SiteAddressModal: FC<IProps> = (props) => {
  const { isOpen, onClose, onDealUpdateSuccessLegacy } = props;

  const { dealId } = useParams<{ dealId: string }>();
  const { data: projectData, isLoading: isLoadingProjectOverview } =
    useGetProjectOverview(+dealId);

  /**
   * Mutations
   */
  const { mutate: linkSiteAddressToProjectMutation } =
    useOptimisticLinkSiteAddressToProject(+dealId, {
      onSuccess: () => {
        onDealUpdateSuccessLegacy?.();
      },
    });
  const { mutate: updateAddressMutation } = useOptimisticUpdateProjectAddress(
    +dealId,
    {
      onSuccess: () => {
        onDealUpdateSuccessLegacy?.();
      },
    }
  );
  const { mutate: addNewSiteAddressToProjectMutation } =
    useOptimisticAddNewSiteAddressToProject(+dealId, {
      onSuccess: () => {
        onDealUpdateSuccessLegacy?.();
      },
    });
  const { mutate: unlinkedSiteAddressFromProjectMutation } =
    useOptimisticUnlinkSiteAddressToProject(+dealId, {
      onSuccess: () => {
        onDealUpdateSuccessLegacy?.();
      },
    });

  const [modalScreen, setModalScreen] = useState<
    'customerAddress' | 'addAddress' | 'newAddressOnly' | 'multipleAddresses'
  >('newAddressOnly');

  useEffect(() => {
    switch (true) {
      case projectData?.project?.addresses?.site?.length &&
        projectData.project.addresses.site.length > 1:
        // Project has multiple site addresses
        setModalScreen('multipleAddresses');
        break;
      case !!projectData?.project?.customer?.addresses?.length ||
        projectData?.project.addresses.site.length === 1:
        // Project's customer has addresses to choose from
        setModalScreen('customerAddress');
        break;
      default:
        setModalScreen('newAddressOnly');
    }
  }, [projectData, isOpen]);

  /**
   * Callbacks
   */
  const handleAddressSelectChange = useCallback(
    (value: string) => {
      const newAddress = projectData?.project?.customer?.addresses.find(
        (i) => i.id === value
      );

      if (!newAddress) return;

      const currentSelectedAddress = projectData?.project.addresses.site[0];
      linkSiteAddressToProjectMutation({
        unlinkSiteAddressFromProjectInput: currentSelectedAddress
          ? {
              projectId: dealId,
              addressId: currentSelectedAddress.id,
            }
          : undefined,
        linkSiteAddressToProjectInput: {
          projectId: dealId,
          addressId: newAddress.id,
        },
      });
    },
    [projectData, dealId, linkSiteAddressToProjectMutation]
  );

  const handleAddressChange = useCallback<
    Required<ISelectEditAddressAndAddressContactsModalProps>['onChange']
  >(
    (updatedAddress) => {
      const input = {
        ...updatedAddress,
      };

      delete input.fullLocalAddress;

      updateAddressMutation(input);
    },
    [updateAddressMutation]
  );

  const handleAddAddress = useCallback<
    Required<IAddAddressModalProps>['onSave']
  >(
    (updatedAddress) => {
      const selectedAddress = projectData?.project.addresses.site[0];

      addNewSiteAddressToProjectMutation({
        unlinkSiteAddressFromProjectInput: selectedAddress
          ? {
              projectId: dealId,
              addressId: selectedAddress.id,
            }
          : {
              projectId: dealId,
              addressId: '',
            },
        addNewSiteAddressToProjectInput: {
          projectId: dealId,
          linkAddressToProjectCustomer: true,
          address: updatedAddress,
        },
      });
    },
    [addNewSiteAddressToProjectMutation, projectData, dealId]
  );

  const handleUnlinkSiteAddressFromProject = useCallback(
    (addressId: string) => {
      unlinkedSiteAddressFromProjectMutation({
        projectId: dealId,
        addressId,
      });
    },
    [unlinkedSiteAddressFromProjectMutation, dealId]
  );

  if (!isOpen) {
    return null;
  }

  if (isLoadingProjectOverview) {
    return (
      <Modal title="Site Addresses" isOpen onClose={onClose}>
        <Modal.Body>
          <div className="flex flex-col gap-4">
            <SkeletonLoader.Title />
            <SkeletonLoader.Text className="w-1/4" />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <EditMultipleAddressesAndAddressContactsModal
        title="Site Addresses"
        isOpen={modalScreen === 'multipleAddresses'}
        onClose={onClose}
        addresses={projectData?.project.addresses.site || []}
        onChange={handleAddressChange}
        onRemoveAddress={handleUnlinkSiteAddressFromProject}
      />

      <SelectEditAddressAndAddressContactsModal
        title="Site Addresses"
        isOpen={modalScreen === 'customerAddress'}
        onClose={onClose}
        onDone={onClose}
        selectedAddress={projectData?.project.addresses.site[0]}
        addresses={
          projectData?.project.customer?.addresses.map((address) => ({
            label: address.fullLocalAddress || '',
            value: address.id,
          })) || []
        }
        onAddNewAddress={() => setModalScreen('addAddress')}
        onAddressChange={handleAddressSelectChange}
        onChange={handleAddressChange}
      />

      <AddAddressModal
        title="Add a new Customer Address"
        onBack={
          modalScreen !== 'newAddressOnly'
            ? () => setModalScreen('customerAddress')
            : undefined
        }
        isOpen={
          modalScreen === 'newAddressOnly' || modalScreen === 'addAddress'
        }
        onClose={
          modalScreen === 'newAddressOnly'
            ? onClose
            : () => setModalScreen('customerAddress')
        }
        onSave={handleAddAddress}
      />
    </>
  );
};

export default SiteAddressModal;
