import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  SendServicePlanInvitationMutation as _SendServicePlanInvitationMutation,
  SendServicePlanInvitationInput,
} from '@/gql/graphql';

const SendServicePlanInvitationMutation = graphql(`
  mutation SendServicePlanInvitation($input: SendServicePlanInvitationInput!) {
    sendServicePlanInvitation(input: $input) {
      id
    }
  }
`);

const useSendServicePlanInvitation = (
  options?: UseMutationOptions<
    _SendServicePlanInvitationMutation,
    unknown,
    SendServicePlanInvitationInput
  >
) => {
  return useMutation({
    mutationFn: (input: SendServicePlanInvitationInput) => {
      return gqlClient.request(SendServicePlanInvitationMutation, { input });
    },
    ...options,
  });
};

export default useSendServicePlanInvitation;
