import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import Button from '@payaca/components/plButton/Button';
import { User } from '@payaca/types/userTypes';
import SelectedProductOverview from '../selectedProductOverview/SelectedProductOverview';
import SubscriptionPaymentPreview from '../subscriptionPaymentPreview/SubscriptionPaymentPreview';
import UserSeatsExceededWarning from '../userSeatsExceededWarning/UserSeatsExceededWarning';

import * as subscriptionActions from '@payaca/store/subscription/subscriptionActions';

import { useSelector } from '@/api/state';
import { getRegion } from '@/utils/stateAccessors';
import { getCurrencyIsoCodeLegacy } from '@payaca/helpers/internationalHelper';
import { getRegionalPayacaVATPercentage } from '@payaca/helpers/vatHelper';
import { isNullish } from '@payaca/utilities/guards';

type Props = {
  onUpdateSubscriptionSuccess?: (additionalUserSeats: number) => void;
};

const UpdateSubscriptionAdditionalUserSeatsControl: FunctionComponent<
  Props
> = ({ onUpdateSubscriptionSuccess }: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [additionalUserSeats, setAdditionalUserSeats] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const region = useSelector(getRegion);

  const accountSubscription = useSelector(
    (state) => state.subscription.accountSubscription
  );
  const isSubscriptionUpdatedSuccessfully = useSelector(
    (state) =>
      state.subscription.isSubscriptionUpdatedSuccessfully ||
      state.subscription.isSubscriptionCreatedSuccessfully
  );
  const subscriptionPaymentPreview = useSelector(
    (state) => state.subscription.subscriptionPaymentPreview
  );
  const isGettingSubscriptionPaymentPreview = useSelector(
    (state) => state.subscription.isGettingSubscriptionPaymentPreview
  );

  const paymentMethodDescription = useMemo(() => {
    if (!accountSubscription) return null;

    const subscriptionInformation = accountSubscription.subscriptionInformation;

    return `${subscriptionInformation.stripePaymentMethodBrand}   •••• ${subscriptionInformation.stripePaymentMethodLast4}   ${subscriptionInformation.stripePaymentMethodExpiryMonth}/${subscriptionInformation.stripePaymentMethodExpiryYear}`;
  }, [accountSubscription]);

  const isUpdatingSubscription = useSelector(
    (state) =>
      state.subscription.isUpdatingSubscription ||
      state.subscription.isCreatingSubscription
  );

  const activeUsers = useSelector((state: any) => {
    return state.users.accountUsers.filter(
      (accountUser: User) =>
        !accountUser.deactivatedAt || accountUser.inviteToken
    );
  });

  const selectedUserSeats = useMemo(() => {
    if (isNullish(accountSubscription?.subscriptionProduct?.numberOfUserSeats))
      return 0;
    return (
      (accountSubscription?.subscriptionProduct?.numberOfUserSeats || 0) +
      additionalUserSeats
    );
  }, [accountSubscription, additionalUserSeats]);

  const activeUsersExceedsSelectedUserSeats = useMemo(() => {
    if (!accountSubscription) return false;
    return (
      selectedUserSeats +
        accountSubscription.subscriptionInformation.bonusAdditionalUserSeats <
      (activeUsers?.length || 0)
    );
  }, [activeUsers, accountSubscription, selectedUserSeats]);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  const taxRatePercentage = useMemo(
    () => getRegionalPayacaVATPercentage(account.region),
    [account]
  );

  const updateSubscriptionPaymentPreview = useCallback(() => {
    if (!accountSubscription) return;

    dispatch(
      subscriptionActions.requestGetSubscriptionPaymentPreview({
        stripeSubscriptionId:
          accountSubscription.subscriptionInformation.stripeSubscriptionId,
        stripePriceId: accountSubscription.productPrice.stripeId,
        additionalUserSeats: additionalUserSeats,
      })
    );
  }, [
    dispatch,
    subscriptionPaymentPreview,
    accountSubscription,
    additionalUserSeats,
  ]);

  const onSubmit = useCallback(() => {
    if (accountSubscription) {
      const updateSubscriptionRequestData = {
        stripePriceId: accountSubscription.productPrice.stripeId,
        stripePaymentMethodId:
          accountSubscription.subscriptionInformation.stripePaymentMethodId,
        stripeSubscriptionId:
          accountSubscription.subscriptionInformation.stripeSubscriptionId,
        additionalUserSeats: additionalUserSeats,
        analytics: {
          price: accountSubscription?.productPrice?.basicCost,
          currency: getCurrencyIsoCodeLegacy(region),
          productName: accountSubscription?.subscriptionProduct?.name,
        },
      };

      dispatch(
        subscriptionActions.requestUpdateSubscription(
          updateSubscriptionRequestData
        )
      );

      setIsSubmitted(true);
    }
  }, [dispatch, additionalUserSeats, region]);

  useEffect(() => {
    if (accountSubscription) {
      setAdditionalUserSeats(
        accountSubscription.subscriptionInformation.additionalUserSeats
      );
    }
  }, [accountSubscription]);

  useEffect(() => {
    updateSubscriptionPaymentPreview();
  }, [additionalUserSeats]);

  useEffect(() => {
    if (
      isSubmitted &&
      !isUpdatingSubscription &&
      isSubscriptionUpdatedSuccessfully
    ) {
      onUpdateSubscriptionSuccess?.(additionalUserSeats);
    }
  }, [
    isSubmitted,
    isUpdatingSubscription,
    isSubscriptionUpdatedSuccessfully,
    onUpdateSubscriptionSuccess,
  ]);

  if (!accountSubscription) return null;

  return (
    <div>
      <div>
        <SelectedProductOverview
          additionalUserSeats={additionalUserSeats}
          recurringInterval={accountSubscription.recurringInterval}
          taxRatePercentage={taxRatePercentage}
          selectedProduct={accountSubscription.subscriptionProduct}
          selectedPrice={accountSubscription.productPrice}
          onChangeAdditionalUserSeats={setAdditionalUserSeats}
        />
      </div>
      <SubscriptionPaymentPreview
        subscriptionPaymentPreview={subscriptionPaymentPreview}
        isLoading={isGettingSubscriptionPaymentPreview}
      />
      {activeUsersExceedsSelectedUserSeats && (
        <UserSeatsExceededWarning
          selectedUserSeats={selectedUserSeats}
          bonusAdditionalUserSeats={
            accountSubscription.subscriptionInformation.bonusAdditionalUserSeats
          }
          activeUsersCount={activeUsers.length}
        />
      )}

      {!activeUsersExceedsSelectedUserSeats && (
        <div className="flex flex-col space-y-4 items-center">
          <p>
            Payment method: <strong>{paymentMethodDescription}</strong>
          </p>
          <div className="actions-container">
            <Button
              isProcessing={isUpdatingSubscription}
              disabled={
                additionalUserSeats ===
                accountSubscription.subscriptionInformation.additionalUserSeats
              }
              onClick={!isUpdatingSubscription ? () => onSubmit() : undefined}
            >
              Update subscription
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateSubscriptionAdditionalUserSeatsControl;
