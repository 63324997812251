import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '@payaca/components/modal/Modal';
import BulkCsvErrors from './BulkCsvErrors';
import BulkCsvGuidelines from './BulkCsvGuidleLines';
import BulkCsvWarnings from './BulkCsvWarnings';
import BulkUploadInstruction from './BulkUploadInstruction';
import DownloadTemplateButton from './DownloadTemplateButton';
import UploadTemplateSection from './UploadTemplateSection';

import * as csvUploadActions from '@payaca/store/csvUpload/csvUploadActions';

import { usePrevious } from '@/utils/customHooks';

import {
  CsvUploadDataType,
  CsvUploadMetadata,
} from '@payaca/types/csvUploadTypes';

import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { Link } from 'react-router-dom';
import './BulkUploadModal.sass';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUploadSuccess?: () => void;
  title: string;
  dataType: CsvUploadDataType;
  metadata?: CsvUploadMetadata[CsvUploadDataType];
};
const BulkUploadModal: FC<Props> = ({
  isOpen,
  onClose,
  onUploadSuccess,
  title,
  dataType,
  metadata,
}: Props) => {
  const dispatch = useDispatch();

  const isGettingCsvTemplate = useSelector(
    (state: any) => state.csvUpload.isGettingCsvTemplate
  );
  const csvDownloadUrl = useSelector(
    (state: any) => state.csvUpload.csvDownloadUrl
  );
  const csvGuidelines = useSelector(
    (state: any) => state.csvUpload.csvGuidelines
  );
  const csvTemplateError = useSelector(
    (state: any) => state.csvUpload.csvTemplateError
  );
  const isUploadingCsv = useSelector(
    (state: any) => state.csvUpload.isUploadingCsv
  );
  const uploadCsvError = useSelector(
    (state: any) => state.csvUpload.uploadCsvError
  );
  const uploadCsvWarning = useSelector(
    (state: any) => state.csvUpload.uploadCsvWarning
  );
  const [showUploadSuccessMessage, setShowUploadSuccessMessage] =
    useState<boolean>(false);
  const [pendingFile, setPendingFile] = useState<File | null>();

  const previousIsUploadingCsv = usePrevious(isUploadingCsv);

  useEffect(() => {
    if (isOpen) {
      dispatch(csvUploadActions.requestGetCsvTemplate(dataType));
    }
  }, [isOpen, dataType]);

  useEffect(() => {
    if (
      previousIsUploadingCsv &&
      !isUploadingCsv &&
      !uploadCsvError &&
      !uploadCsvWarning
    ) {
      // upload completed successfully
      setShowUploadSuccessMessage(true);
      onUploadSuccess?.();
      setPendingFile(null);
    }
  }, [
    previousIsUploadingCsv,
    isUploadingCsv,
    onUploadSuccess,
    uploadCsvError,
    uploadCsvWarning,
  ]);

  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);

  const uploadFile = useCallback(
    (file: File, warningsAcknowleged = false) => {
      setShowUploadSuccessMessage(false);
      dispatch(
        csvUploadActions.requestUploadCsv(dataType, file, metadata, () => {
          onClose();
          showDynamicFeedbackMessage({
            title: 'Processing CSV Upload',
            body: (
              <>
                <p>
                  Your file is being queued for processing and should be ready
                  soon. Please refresh the page in a few minutes.
                </p>
                <p>
                  <Link to="/csv-uploads">View details</Link>
                </p>
              </>
            ),
            isCancellable: true,
            lifespanMs: DynamicFeedbackLifespanMs.LONG,
            feedbackLevel: FeedbackLevel.INFORMATION,
          });
        })
      );
    },
    [metadata, dataType, onClose]
  );

  const instructions = useMemo(() => {
    return [
      {
        title: 'Download the template',
        content: <DownloadTemplateButton downloadUrl={csvDownloadUrl} />,
      },
      {
        title: 'Fill out the template considering the following:',
        content: (
          <ul className="guidelines-wrapper">
            <li key={`bulk-upload-instruction-guideline-limit`}>
              <span>
                File must use UTF-8 encoding, without the unicode byte order
                mark (BOM)
              </span>
            </li>

            <BulkCsvGuidelines csvGuidelines={csvGuidelines} />
          </ul>
        ),
      },
      {
        title: 'Upload the completed template',
        content: (
          <UploadTemplateSection
            isUploadingCsv={isUploadingCsv}
            onUploadFile={(file) => {
              setPendingFile(file);
              uploadFile(file);
            }}
          />
        ),
      },
    ].map((instruction: { title: string; content: JSX.Element }, i: number) => {
      return (
        <BulkUploadInstruction
          key={`bulk-upload-instruction-${i}`}
          instructionNumber={i + 1}
          instructionTitle={instruction.title}
        >
          {instruction.content}
        </BulkUploadInstruction>
      );
    });
  }, [
    csvGuidelines,
    csvDownloadUrl,
    isUploadingCsv,
    dataType,
    metadata,
    uploadFile,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      size="sm"
      onClose={() => {
        setShowUploadSuccessMessage(false);
        onClose();
        dispatch(csvUploadActions.clearCsv());
      }}
      className="bulk-upload-modal"
    >
      <div>
        <div className="upload-instructions-wrapper">{instructions}</div>
        {/* Upload error  */}
        <BulkCsvErrors uploadCsvError={uploadCsvError} />
        <BulkCsvWarnings
          uploadCsvWarning={uploadCsvWarning}
          onConfirmContinueUpload={() =>
            pendingFile && uploadFile(pendingFile, true)
          }
        />
      </div>
    </Modal>
  );
};

export default BulkUploadModal;
