import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import useGetMyTaxRates from '@/api/queries/me/useGetMyTaxRates';
import { MaterialAndSuppliers } from '@/api/queries/project/useGetProjectMaterialListSummaryItems';
import useCreateMaterialPurchaseRecord from '@/api/rest/materials/useCreateMaterialPurchaseRecord';
import {
  registerFieldBuilder,
  zodHumanFriendlyFormErrorMap,
} from '@/utils/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import CurrencyInput from '@payaca/components/plInput/CurrencyInput';
import RawInput from '@payaca/components/plInput/RawInput';
import Modal from '@payaca/components/plModal/Modal';
import Select from '@payaca/components/plSelect/Select';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { MaterialPurchaseRecordSupplierSelectionControl } from './MaterialPurchaseRecordSupplierSelectionControl';

const purchaseSchema = z.object({
  supplierId: z.string().min(1),
  unitPrice: z.number().int().positive(),
  taxRateId: z.string().nullable(),
  quantity: z.number().gt(0),
});
type PurchaseSchema = z.infer<typeof purchaseSchema>;

export type PurchaseFormDefaultValues = Partial<PurchaseSchema>;

export const RecordPurchaseModal: FC<{
  materialsListId: number;
  materialAndSuppliers: MaterialAndSuppliers;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({
  materialsListId,
  materialAndSuppliers,
  isOpen,
  onSuccess,
  onClose,
}) => {
  const { data: regionPreferences } = useGetMyRegionPreferences();
  const { taxRates, isLoading: isLoadingTaxRates } = useGetMyTaxRates();

  const firstSupplier = materialAndSuppliers.material.suppliedBy.find(
    (supplierMaterial) => supplierMaterial.supplier
  );
  const defaultValues: PurchaseFormDefaultValues = {
    supplierId: firstSupplier?.supplier.id,
    unitPrice: firstSupplier?.price.unitPriceExcTax.value,
    taxRateId: null,
    quantity:
      materialAndSuppliers.quantity - materialAndSuppliers.quantityOrdered,
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PurchaseSchema>({
    defaultValues,
    resolver: zodResolver(purchaseSchema, {
      errorMap: zodHumanFriendlyFormErrorMap,
    }),
  });
  const registerField = registerFieldBuilder(errors);

  const { mutateAsync, isLoading } = useCreateMaterialPurchaseRecord();

  const onSubmit = async (data: PurchaseSchema) => {
    await mutateAsync({
      materialsListMaterialId: +materialAndSuppliers.id,
      materialsListId: materialsListId,
      supplierId: +data.supplierId,
      price: data.unitPrice,
      taxRateId: data.taxRateId?.length && +data.taxRateId,
      materialQuantity: data.quantity,
    }).then(() => {
      onSuccess();
    });
  };
  return (
    <Modal title="Record purchase" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Field {...registerField('supplierId')}>
            <Field.Label>Supplier</Field.Label>
            <Controller
              name="supplierId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MaterialPurchaseRecordSupplierSelectionControl
                  materialAndSuppliers={materialAndSuppliers}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <span className="text-sm text-gray-500">
              Choose a known supplier, or type to search across all existing
              suppliers
            </span>
          </Field>
          <div className="flex gap-4">
            <Field className="flex-1" {...registerField('unitPrice')}>
              <Field.Label>Unit Price</Field.Label>
              <Controller
                name="unitPrice"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CurrencyInput
                    value={value}
                    onChange={onChange}
                    currency={regionPreferences?.currency}
                  />
                )}
              />
            </Field>
            <Field className="flex-1" {...registerField('taxRateId')}>
              <Field.Label>Tax</Field.Label>
              <Controller
                name="taxRateId"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    options={
                      taxRates
                        ?.filter((taxRate) => !taxRate.isReverseCharge)
                        .map((taxRate) => ({
                          label: taxRate.label,
                          value: taxRate.id,
                        })) || []
                    }
                    value={
                      value ||
                      taxRates?.find((taxRate) => taxRate.isDefault)?.id
                    }
                    onChange={onChange}
                    disabled={isLoadingTaxRates}
                  />
                )}
              />
            </Field>
            <Field className="flex-1" {...registerField('quantity')}>
              <Field.Label>Quantity</Field.Label>
              <RawInput {...register('quantity', { valueAsNumber: true })} />
            </Field>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-end gap-2">
          <Button onClick={onClose} variant={EBtnVariant.Outline}>
            Cancel
          </Button>
          <Button type="submit" isProcessing={isLoading}>
            Record purchase
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
