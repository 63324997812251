import { Paper, PaperUpload } from 'react-iconly';

import { Document } from '@payaca/types/documentTypes';

import FormDarkIcon from '@/ui/components/dealListedDocuments/form-dark.svg';
import FormIcon from '@/ui/components/dealListedDocuments/form.svg';

export const getDocumentThumbnail = (
  dealDocument: Document,
  isDark = false
) => {
  switch (true) {
    case dealDocument?.bucketKey?.endsWith('.png'):
    case dealDocument?.bucketKey?.endsWith('.jpg'):
    case dealDocument?.bucketKey?.endsWith('.jpeg'):
    case dealDocument?.bucketKey?.endsWith('.gif'):
    case dealDocument?.bucketKey?.endsWith('.bmp'):
    case dealDocument?.bucketKey?.endsWith('.heic'):
    case dealDocument?.bucketKey?.endsWith('.webp'):
      return (
        <img
          src={`${
            import.meta.env.VITE_DOCUMENT_UPLOAD_STORAGE_BUCKET_URL
          }/thumbnail_${dealDocument.bucketKey}`}
          alt={'Thumbnail'}
        />
      );
    case !!dealDocument?.bucketKey:
      return <PaperUpload size={'large'} />;
    case !!dealDocument?.completedAt:
      return <Paper size={'large'} />;
    default:
      return (
        <img src={isDark ? FormDarkIcon : FormIcon} style={{ width: 25 }} />
      );
  }
};
