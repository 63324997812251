import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { actions as appActions } from '@/api/app';

import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';

import useLogout from '@/hooks/auth/useLogout';
import UserInviteForm from '@/ui/components/userInviteForm/UserInviteForm';
import './UserInvitePage.sass';

enum PageState {
  USER_INVITE_FORM = 'user-invite-form',
  INVALID_TOKEN = 'invalid-token',
}

type Props = {
  match: any;
  location: any;
  history: any;
  isAuthed: boolean;
};
const UserInvitePage: FunctionComponent<Props> = ({
  match,
  location,
  isAuthed,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const logout = useLogout();
  const [pageState, setPageState] = useState(PageState.USER_INVITE_FORM);

  useEffect(() => {
    if (isAuthed) {
      logout();
    } else {
      validateToken();
    }
  }, []);

  const validateToken = useCallback(() => {
    const token = match.params.token;
    // check valid invite token
    dispatch(
      appActions.validUserInviteToken(token, (error: any) => {
        if (error) {
          setPageState(PageState.INVALID_TOKEN);
        }
      })
    );
  }, [dispatch, location.search, match.params.token]);

  return (
    <UnauthenticatedPageWrapper className="user-invite-page">
      {pageState === PageState.USER_INVITE_FORM && (
        <>
          <div className="title-introduction-container">
            <h2>Join Payaca</h2>
          </div>
          <UserInviteForm inviteToken={match.params.token} />
        </>
      )}
      {pageState === PageState.INVALID_TOKEN && (
        <>
          <div className="title-introduction-container">
            <h2>You have used an invalid invitation link.</h2>
            <p>Please contact your account owner to send a new invitation.</p>
          </div>
        </>
      )}
    </UnauthenticatedPageWrapper>
  );
};

export default UserInvitePage;
