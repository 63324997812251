import { FunctionComponent } from 'react';

import ContentPanel from '../contentPanel/ContentPanel';

import './TitledContentPanel.sass';

type Props = {
  title: string | JSX.Element | JSX.Element[];
  children: string | JSX.Element | JSX.Element[];
  className?: string;
  hasBoxShadow?: boolean;
};

const TitledContentPanel: FunctionComponent<Props> = ({
  title,
  children,
  className,
  hasBoxShadow = true,
}: Props): JSX.Element => {
  return (
    <ContentPanel
      className={`titled-content-panel ${className ? className : ''}`}
      hasBoxShadow={hasBoxShadow}
    >
      <div className="title">
        {typeof title === 'string' ? <h4>{title}</h4> : title}
      </div>
      <hr />
      <div className="content">{children}</div>
    </ContentPanel>
  );
};

export default TitledContentPanel;
