import { FunctionComponent } from 'react';

import './DashboardPage.sass';

import { useTranslation } from '@/i18n';
import { KeyIndicatorsData } from '@/ui/components';
import ActionRequiredDraftJobsCarousel from '@/ui/components/actionRequiredDraftJobsCarousel/ActionRequiredDraftJobsCarousel';
import ActionRequiredPaymentOutstandingInvoicesCarousel from '@/ui/components/actionRequiredPaymentOutstandingInvoicesCarousel/ActionRequiredPaymentOutstandingInvoicesCarousel';
import ActionRequiredPendingPaymentsCarousel from '@/ui/components/actionRequiredPendingPaymentsCarousel/ActionRequiredPendingPaymentsCarousel';
import ActionRequiredPendingProposalsCarousel from '@/ui/components/actionRequiredPendingProposalsCarousel/ActionRequiredPendingProposalsCarousel';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import DashboardScheduledEventsList from '../../components/dashboardScheduledEventsList/DashboardScheduledEventsList';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

const DashboardPage: FunctionComponent = (): JSX.Element => {
  const history = useHistory();
  const translate = useTranslation();
  return (
    <>
      <Helmet title={translate('pages.dashboard.title')} />
      <AuthenticatedPageWrapper className="dashboard-page">
        <ResponsiveViewWrapper
          className="dashboard-content-wrapper"
          downBreakpointSm={900}
        >
          <div className="key-indicators-data-container">
            <KeyIndicatorsData />
          </div>
          <div className="events-container-outer">
            <div className="events-container">
              <ContentPanel hasBoxShadow={true}>
                <DashboardScheduledEventsList />
              </ContentPanel>
            </div>
          </div>

          <div className="requires-action-container-outer">
            <div className="requires-action-container">
              <ContentPanel
                className="requires-action-container-inner"
                hasBoxShadow={true}
              >
                <ActionRequiredPendingPaymentsCarousel />
                <ActionRequiredPaymentOutstandingInvoicesCarousel />
                <ActionRequiredPendingProposalsCarousel />
                <ActionRequiredDraftJobsCarousel />
              </ContentPanel>
            </div>
          </div>
        </ResponsiveViewWrapper>
      </AuthenticatedPageWrapper>
    </>
  );
};

export default DashboardPage;
