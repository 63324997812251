import Button from '@payaca/components/plButton/Button';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ValidationFeedbackBlock from '@payaca/components/validationFeedbackBlock/ValidationFeedbackBlock';
import {
  getArrayMustNotBeEmptyFieldValidator,
  getIsRequiredFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { getModeElement } from '@payaca/shared-isomorphic/arrays';
import {
  useMaterials,
  useMaterialsListMaterialsForMaterialsList,
  useSupplier,
} from '@payaca/store/hooks/appState';
import { requestPersistPurchaseOrder } from '@payaca/store/materialsList/materialsListActions';
import { PersistPurchaseOrderRequestData } from '@payaca/store/materialsList/materialsListTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Material } from '@payaca/types/materialTypes';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import PurchaseOrderMaterialPurchaseIntentsControl from '../purchaseOrderMaterialPurchaseIntentsControl/PurchaseOrderMaterialPurchaseIntentsControl';
import SupplierSelectionControl from '../supplierSelectionControl/SupplierSelectionControl';
import './CreatePurchaseOrderControl.sass';

import { DateInput } from '@payaca/components/plDateInput/DateInput';
import Field from '@payaca/components/plField/Field';
import { Textarea } from '@payaca/components/plTextarea/Textarea';

import useCreateAddress from '@/api/mutations/addresses/useCreateAddress';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import Modal from '@payaca/components/plModal/Modal';
import { Deal } from '@payaca/types/dealTypes';
import { PartialAddress } from '@payaca/types/locationTypes';
import { PurchaseOrderDeliveryAddressControl } from '../purchaseOrderDeliveryAddressControl/PurchaseOrderDeliveryAddressControl';

export enum FormSection {
  SUPPLIER_SELECTION = 'supplierSelection',
  MATERIALS_SELECTION = 'materialsSelection',
}

type Props = {
  projectId?: Deal['id'];
  materialsListId: number;
  onCreatePurchaseOrderSuccess?: (purchaseOrderId: number) => void;
  onProgressFormSection?: (formSection: FormSection) => void;
};
const CreatePurchaseOrderControl: FC<Props> = ({
  projectId,
  materialsListId,
  onCreatePurchaseOrderSuccess,
  onProgressFormSection,
}: PropsWithChildren<Props>): JSX.Element => {
  const dispatch = useDispatch();
  const [showValidationFeedback, setShowValidationFeedback] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const { mutateAsync: mutateCreateAddress } = useCreateAddress();

  const fieldValidators = useMemo(() => {
    return {
      supplierId: [
        getIsRequiredFieldValidator({
          customErrorMessage: 'You must select a supplier',
        }),
      ],
      materialPurchaseIntents: [
        getArrayMustNotBeEmptyFieldValidator({
          customErrorMessage: 'You cannot create an empty purchase order',
        }),
      ],
    };
  }, []);

  const materialsListMaterials =
    useMaterialsListMaterialsForMaterialsList(materialsListId);

  const purchasableMaterialsListMaterials = useMemo(() => {
    return materialsListMaterials.filter(
      (x) => !(x.isFullyPurchaseIntended || x.isFullyPurchaseIntended)
    );
  }, [materialsListMaterials]);

  const materialIds = useMemo(() => {
    return purchasableMaterialsListMaterials.map((x) => x.materialId);
  }, [purchasableMaterialsListMaterials]);

  const materials = useMaterials(materialIds);

  const initialSupplierId = useMemo(() => {
    const preferredSupplierIds = materials
      .map((x: Material) => x.preferredSupplierId || x.cheapestSupplierId)
      .filter((x?: number) => !!x) as number[];

    return getModeElement(preferredSupplierIds) as number | undefined;
  }, [materials]);

  const [supplierId, setSupplierId] = useState<number | undefined>(
    initialSupplierId
  );

  const supplier = useSupplier(supplierId);

  useEffect(() => {
    if (onProgressFormSection) {
      onProgressFormSection(FormSection.SUPPLIER_SELECTION);
    }
  }, []);

  const initialFormState = useMemo(() => {
    return {
      materialsListId: materialsListId,
      formSection: FormSection.SUPPLIER_SELECTION,
      supplierId: initialSupplierId,
      materialPurchaseIntents: [],
      additionalNotes: '',
      showMaterialPrices: false,
    };
  }, [materialsListId, initialSupplierId]);

  const getMaterialPurchaseIntentsForSupplier = useCallback(
    (supplierId: number) => {
      return purchasableMaterialsListMaterials
        .filter((x) => {
          const material = materials.find(
            (y: Material) => y.id === x.materialId
          );
          const preferredSupplierId =
            material?.preferredSupplierId || material?.cheapestSupplierId;
          return preferredSupplierId === supplierId;
        })
        .map((x) => {
          return {
            materialsListMaterialId: x.id,
            materialQuantity:
              x.outstandingMaterialQuantity -
              x.purchaseIntendedMaterialQuantity,
          };
        });
    },
    [purchasableMaterialsListMaterials, materials]
  );

  const onSubmit = useCallback(
    async (
      requestData: PersistPurchaseOrderRequestData & {
        newDeliveryAddress?: PartialAddress;
      }
    ) => {
      setIsProcessing(true);

      let deliveryAddressId: number | undefined = requestData.deliveryAddressId;

      // create the address if it doesn't exist already
      if (requestData.newDeliveryAddress) {
        const {
          createAddress: { id: addressId },
        } = await mutateCreateAddress({
          line1: requestData.newDeliveryAddress.line1,
          line2: requestData.newDeliveryAddress.line2,
          city: requestData.newDeliveryAddress.city,
          postalCode: requestData.newDeliveryAddress.postcode,
          country: requestData.newDeliveryAddress.country,
        });

        if (addressId) {
          deliveryAddressId = +addressId;
        }
      }

      dispatch(
        requestPersistPurchaseOrder(
          { ...requestData, deliveryAddressId: deliveryAddressId },
          (purchaseOrderId: number) => {
            if (onCreatePurchaseOrderSuccess) {
              onCreatePurchaseOrderSuccess(purchaseOrderId);
            }
            setIsProcessing(false);
          }
        )
      );
    },
    [onCreatePurchaseOrderSuccess]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      if (formState.formSection === FormSection.SUPPLIER_SELECTION) {
        return (
          <>
            <Modal.Body>
              <div className="form-body">
                <div>
                  <FieldLabel label="Supplier" />
                  <SupplierSelectionControl
                    selectedSupplierId={formState.supplierId}
                    onChange={(selectedSupplierId) => {
                      onFieldChange({ supplierId: selectedSupplierId });
                      setSupplierId(selectedSupplierId || undefined);
                    }}
                  />
                </div>
                {showValidationFeedback && (
                  <ValidationFeedbackBlock
                    validationResults={[validationState.supplierId]}
                    isDismissable={true}
                    onDismissed={() => setShowValidationFeedback(false)}
                  />
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.Footer.Actions>
                <Button
                  onClick={() => {
                    if (validationState.supplierId?.isValid) {
                      onFieldChange({
                        formSection: FormSection.MATERIALS_SELECTION,
                        materialPurchaseIntents:
                          getMaterialPurchaseIntentsForSupplier(
                            formState.supplierId
                          ),
                      });
                      if (onProgressFormSection) {
                        onProgressFormSection(FormSection.MATERIALS_SELECTION);
                      }
                    } else {
                      setShowValidationFeedback(true);
                    }
                  }}
                >
                  Next
                </Button>
              </Modal.Footer.Actions>
            </Modal.Footer>
          </>
        );
      }

      return (
        <>
          <Modal.Body>
            <div className="form-body">
              <p className="selected-supplier-indicator">
                Supplier: <strong>{supplier?.name}</strong>
              </p>
              <PurchaseOrderMaterialPurchaseIntentsControl
                supplierId={formState.supplierId}
                materialsListId={formState.materialsListId}
                materialPurchaseIntents={formState.materialPurchaseIntents}
                onChange={(materialPurchaseIntents) =>
                  onFieldChange({
                    materialPurchaseIntents: materialPurchaseIntents,
                  })
                }
              />
              {showValidationFeedback && (
                <ValidationFeedbackBlock
                  validationResults={[validationState.materialPurchaseIntents]}
                  isDismissable={true}
                  onDismissed={() => setShowValidationFeedback(false)}
                />
              )}

              <Field name="dueAt">
                <Field.Label>Due by</Field.Label>
                <DateInput
                  value={formState.dueAt}
                  onChange={(value) => {
                    onFieldChange({ dueAt: value });
                    onFieldTouch('dueAt');
                  }}
                />
              </Field>

              <Field name="deliveryAddressId">
                <Field.Label>Deliver to</Field.Label>
                <PurchaseOrderDeliveryAddressControl
                  supplierId={supplierId}
                  projectId={projectId}
                  onChange={onFieldChange}
                  value={{
                    deliveryAddressId: formState.deliveryAddressId,
                    newDeliveryAddress: formState.newDeliveryAddress,
                  }}
                />
              </Field>

              <Field name="additionalNotes">
                <Field.Label>Additional notes</Field.Label>
                <Textarea
                  onChange={(value) => {
                    onFieldChange({ additionalNotes: value });
                  }}
                  onTouch={() => onFieldTouch('additionalNotes')}
                  value={formState.additionalNotes}
                />
              </Field>
              <Field name="showMaterialPrices">
                <Checkbox
                  checked={formState.showMaterialPrices}
                  onChange={() =>
                    onFieldChange({
                      showMaterialPrices: !formState.showMaterialPrices,
                    })
                  }
                  label="Show Material prices on Purchase Order"
                />
              </Field>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Footer.Actions>
              <Button
                isProcessing={isProcessing}
                onClick={() =>
                  isValid
                    ? !isProcessing &&
                      onSubmit(
                        formState as PersistPurchaseOrderRequestData & {
                          newDeliveryAddress?: PartialAddress;
                        }
                      )
                    : setShowValidationFeedback(true)
                }
              >
                Create Purchase Order
              </Button>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </>
      );
    },
    [
      showValidationFeedback,
      getMaterialPurchaseIntentsForSupplier,
      supplier?.name,
      onSubmit,
      isProcessing,
    ]
  );

  return (
    <div className="create-purchase-order-control">
      <ValidatedForm<
        PersistPurchaseOrderRequestData & {
          newDeliveryAddress?: PartialAddress;
        }
      >
        fieldValidators={fieldValidators}
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
      />
    </div>
  );
};

export default CreatePurchaseOrderControl;
