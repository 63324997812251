import { FC, PropsWithChildren, useMemo, useState } from 'react';

import { useSelector } from '@/api/state';
import { getRegion } from '@/utils/stateAccessors';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import CurrencyField from '@payaca/components/currencyField/CurrencyField';
import { LabelStyleVariant } from '@payaca/components/fieldLabel/FieldLabel';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { useTaxRate } from '@payaca/store/hooks/appState';
import { ConfirmPurchaseOrderMaterialPurchaseRecordRequestData } from '@payaca/store/materialsList/materialsListTypes';
import { HydratedMaterialPurchaseIntent } from '@payaca/types/materialsListTypes';
import SalesTaxSettingsField from '../salesTaxSettingsField/SalesTaxSettingsField';
import './PurchaseOrderMaterialPurchaseRecordControl.sass';

type Props = {
  materialPurchaseIntent: HydratedMaterialPurchaseIntent;
  materialPurchaseRecord?: ConfirmPurchaseOrderMaterialPurchaseRecordRequestData;
  onChange: (value: { [key: string]: any }) => void;
  onAdd: (
    materialPurchaseRecord: ConfirmPurchaseOrderMaterialPurchaseRecordRequestData
  ) => void;
  onRemove: () => void;
};
const PurchaseOrderMaterialPurchaseRecordControl: FC<Props> = ({
  materialPurchaseIntent,
  materialPurchaseRecord,
  onChange,
  onAdd,
  onRemove,
}: PropsWithChildren<Props>): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);

  const region = useSelector(getRegion);

  const { price, taxRateId } = useMemo(() => {
    const price = materialPurchaseRecord
      ? materialPurchaseRecord.price
      : materialPurchaseIntent?.predictedUnitPriceExcludingTax;

    return {
      price: price === undefined ? null : price,
      taxRateId: materialPurchaseRecord
        ? materialPurchaseRecord.taxRateId
        : materialPurchaseIntent?.predictedTaxRateId,
    };
  }, [materialPurchaseRecord, materialPurchaseIntent]);

  const materialQuantity = useMemo(() => {
    return materialPurchaseRecord
      ? materialPurchaseRecord.materialQuantity
      : materialPurchaseIntent?.materialQuantity;
  }, [materialPurchaseRecord, materialPurchaseIntent]);

  const taxRate = useTaxRate(taxRateId);

  const label = useMemo(() => {
    return (
      <span>
        <strong>{materialQuantity}</strong> purchased
        {price !== null && (
          <>
            {' '}
            for <strong>{currencyPrice(price, region)}</strong> per unit
            {taxRate && (
              <>
                {' '}
                <strong>+ {taxRate.name}</strong>
              </>
            )}
          </>
        )}
      </span>
    );
  }, [materialQuantity, price, region, taxRate]);

  return (
    <div className="purchase-order-material-purchase-record-control">
      <CheckboxField
        name="isSelected"
        value={!!materialPurchaseRecord}
        onChange={(value) => {
          setIsEditing(false);

          if (value.isSelected) {
            if (!materialPurchaseIntent) return;
            onAdd({
              materialsListMaterialId:
                materialPurchaseIntent?.materialsListMaterialId,
              materialQuantity: materialPurchaseIntent?.materialQuantity,
              price: materialPurchaseIntent.predictedUnitPriceExcludingTax,
              taxRateId: materialPurchaseIntent.predictedTaxRateId || null,
            });
          } else {
            onRemove();
          }
        }}
      />
      <div className="control-body-container">
        <span className="material-name">
          {materialPurchaseIntent.material.name}
        </span>
        <div className="material-purchase-record-control-container">
          {!isEditing && (
            <>
              {label}
              {materialPurchaseRecord && (
                <Button
                  styleVariant={ButtonStyleVariant.ANCHOR}
                  onClick={() => setIsEditing(true)}
                  className="edit-button"
                >
                  Edit
                </Button>
              )}
            </>
          )}
          {isEditing && (
            <>
              <div className="price-field-container">
                <CurrencyField
                  label="Unit price"
                  name="price"
                  value={materialPurchaseRecord?.price || 0}
                  onChange={onChange}
                  styleVariant={InputStyleVariant.STANDARD}
                  labelStyleVariant={LabelStyleVariant.LIGHT}
                />
              </div>
              <div className="tax-field-container">
                <SalesTaxSettingsField
                  excludeReverseChargeOptions={true}
                  styleVariant={InputStyleVariant.OUTSIZE}
                  taxRateId={
                    materialPurchaseRecord?.taxRateId
                      ? materialPurchaseRecord.taxRateId
                      : null
                  }
                  onChange={(value: { [key: string]: any }) => {
                    onChange({
                      taxRateId: value.taxRateId,
                    });
                  }}
                  labelStyleVariant={LabelStyleVariant.LIGHT}
                />
              </div>
              <div className="quantity-field-container">
                <BasicField
                  label="Qty"
                  name="materialQuantity"
                  value={materialPurchaseRecord?.materialQuantity || 0}
                  onChange={onChange}
                  type={'number'}
                  additionalInputProps={{
                    placeholder: 'Qty',
                    min: 0,
                    max: materialPurchaseIntent?.materialQuantity,
                  }}
                  styleVariant={InputStyleVariant.STANDARD}
                  labelStyleVariant={LabelStyleVariant.LIGHT}
                  hideSpinnerArrows={true}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrderMaterialPurchaseRecordControl;
