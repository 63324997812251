import UntitledIcon from '@payaca/untitled-icons';
import { FC, PropsWithChildren, useState } from 'react';
import Button from '../plButton/Button';
import {
  EBtnColour,
  EBtnShapeVariant,
  EBtnVariant,
} from '../plButton/useButtonClassName';
import Modal from '../plModal/Modal';
import { clstx } from '../utils';
import SidebarCore, { ISidebarCoreProps } from './SidebarCore';

export type Props = {
  className?: string;
  isOpen: boolean;
  showCloseControl?: boolean;
  onClose?: () => void;
  title?: string;
  size?: ISidebarCoreProps['size'];
  behind?: ISidebarCoreProps['behind'];
  zIndexLevel?: ISidebarCoreProps['zIndexLevel'];
  // will display a warning before allowing the user to close the sidebar
  warnUserOnClose?: boolean;
  warnUserContent?: {
    title?: string;
    body?: string;
    confirm?: string;
  };
};

const Sidebar: FC<PropsWithChildren<Props>> = (props) => {
  const {
    className,
    isOpen,
    showCloseControl = true,
    warnUserOnClose = false,
    warnUserContent = {},
    onClose,
    children,
    title,
    size,
    behind,
    zIndexLevel,
  } = props;

  const [isBlockCloseModalOpen, setIsBlockCloseModalOpen] = useState(false);

  const handleOnClose =
    warnUserOnClose && !!onClose
      ? () => setIsBlockCloseModalOpen(true)
      : onClose;

  return (
    <>
      <SidebarCore
        className={clstx(
          'prose flex max-h-full flex-grow flex-col bg-white shadow-sm',
          className
        )}
        isOpen={isOpen}
        onClose={handleOnClose}
        size={size}
        behind={behind}
        zIndexLevel={zIndexLevel}
      >
        {/* header */}
        <div className="flex items-center justify-between border-b px-4 py-3">
          <h3 className="text-gray-800">{title}</h3>
          {showCloseControl && (
            <Button
              onClick={handleOnClose}
              variant={EBtnVariant.Soft}
              shape={EBtnShapeVariant.PILL}
              colour={EBtnColour.Gray}
              className="p-[12px]"
            >
              <UntitledIcon name="x-close" className="h-2.5 w-2.5" />
            </Button>
          )}
        </div>

        {/* content */}
        {children}
      </SidebarCore>

      {warnUserOnClose && (
        <Modal
          title={warnUserContent.title || 'Are you sure?'}
          isOpen={isBlockCloseModalOpen}
          onClose={() => setIsBlockCloseModalOpen(false)}
        >
          <Modal.Body>
            <p>{warnUserContent.body}</p>
          </Modal.Body>

          <Modal.Footer>
            <Modal.Footer.Actions>
              <Button
                variant={EBtnVariant.Outline}
                onClick={() => setIsBlockCloseModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colour={EBtnColour.Red}
                onClick={() => {
                  setIsBlockCloseModalOpen(false);
                  onClose?.();
                }}
              >
                {warnUserContent.confirm || 'Discard'}
              </Button>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const Body: FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex-grow overflow-y-auto">{children}</div>;
};

const Footer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="border-t border-stone-200 bg-white p-5">{children}</div>
  );
};

export default Object.assign(Sidebar, {
  Body,
  Footer,
});
