import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { actions as userActions } from '@/api/users';
import { ContactUsCallout } from '@/ui/components';

import { useSelector } from '@/api/state';
import { getUserRoles } from '@/utils/stateAccessors';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { DefaultSystemRoles } from '@payaca/permissions/default-role.config';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import './TrialPeriodExpiryCallout.css';

const TrialPeriodExpiryCallout: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch();

  const accountAccessInformation = useSelector((state) => {
    return state.account.accountAccessInformation;
  });

  const userRoles = useSelector(getUserRoles);
  const isCurrentUserAdmin = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      AccountsPermissions.UPDATE_SUBSCRIPTION,
    ]);
  }, [userRoles]);

  const adminUserEmails: string[] = useSelector((state: any) => {
    if (!state?.users?.accountUsers) return [];
    return state.users.accountUsers
      .filter(
        (user: any) =>
          [DefaultSystemRoles.ADMIN, DefaultSystemRoles.SUPER_ADMIN].includes(
            user.systemRole
          ) &&
          !!user.verifiedAt &&
          !user.deactivatedAt
      )
      .map((user: any) => user.email);
  });

  const companyName: string = useSelector((state: any) => {
    if (!state?.users?.myProfile?.accounts) return '';
    return state.users.myProfile.accounts[0]?.companyName;
  });

  useEffect(() => {
    dispatch(userActions.getAccountUsers());
  }, []);

  const nonAdminContent = useMemo(() => {
    return (
      <React.Fragment>
        <span>
          Contact one of the administrators of the {companyName} account to
          continue using Payaca:
        </span>
        <ul>
          {adminUserEmails.map((adminUserEmail, index) => (
            <li key={index}>{adminUserEmail}</li>
          ))}
        </ul>
      </React.Fragment>
    );
  }, [adminUserEmails, companyName]);

  return (
    <ContactUsCallout
      title={
        accountAccessInformation?.hasInactiveSubscription
          ? 'Your account does not have an active subscription'
          : 'Your demo has ended!'
      }
    >
      {isCurrentUserAdmin
        ? 'Please choose a subscription plan to continue using Payaca.'
        : nonAdminContent}
    </ContactUsCallout>
  );
};

export default TrialPeriodExpiryCallout;
