import {
  Automation,
  AutomationActionTypes,
  AutomationTemplateType,
  DefaultAutomationDefinition,
  ListedAutomationsListViewPage,
  PublicHydratedDefaultAutomationDefinition,
  UpdateDefaultAutomationData,
} from '@payaca/types/automationTypes';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { DeclareAsyncAction } from '../types';
export interface State {
  automations: {
    [key: number]: FetchedEntity<Automation<AutomationActionTypes>>;
  };

  automationTemplates: any[];
  isCreatingAutomation: boolean;
  isGettingAutomationTemplates: boolean;

  isGettingListedAutomationsPage: boolean;
  listedAutomationsPage: ListedAutomationsListViewPage | null;

  isPerformingAutomationAction: boolean;

  isArchivingAutomations: boolean;
}

export enum ActionType {
  REQUEST_GET_LISTED_AUTOMATIONS_PAGE = 'automations.requestGetListedAutomationsPage',
  GET_LISTED_AUTOMATIONS_PAGE_SUCCESS = 'automations.getListedAutomationsPageSuccess',
  GET_LISTED_AUTOMATIONS_PAGE_FAILURE = 'automations.getListedAutomationsPageFailure',

  REQUEST_GET_AUTOMATION_TEMPLATES = 'automation.requestGetAutomationTemplates',
  GET_AUTOMATION_TEMPLATES_SUCCESS = 'automation.getAutomationTemplatesSuccess',
  GET_AUTOMATION_TEMPLATES_FAILURE = 'automation.getAutomationTemplatesFailure',

  REQUEST_GET_AUTOMATION = 'automations.requestGetAutomation',
  GET_AUTOMATION_SUCCESS = 'automations.getAutomationSuccess',
  GET_AUTOMATION_FAILURE = 'automations.getAutomationFailure',

  REQUEST_CREATE_AUTOMATION = 'automations.requestCreateAutomation',
  CREATE_AUTOMATION_SUCCESS = 'automations.createAutomationSuccess',
  CREATE_AUTOMATION_FAILURE = 'automations.createAutomationFailure',

  REQUEST_UPDATE_AUTOMATION = 'automations.requestUpdateAutomation',
  UPDATE_AUTOMATION_SUCCESS = 'automations.updateAutomationSuccess',
  UPDATE_AUTOMATION_FAILURE = 'automations.updateAutomationFailure',

  REQUEST_ARCHIVE_AUTOMATIONS = 'automations.requestArchiveAutomations',
  ARCHIVE_AUTOMATIONS_SUCCESS = 'automations.archiveAutomationsSuccess',
  ARCHIVE_AUTOMATIONS_FAILURE = 'automations.archiveAutomationsFailure',

  GET_DEFAULT_AUTOMATION_DEFINITION_REQUEST = 'automations.getDefaultAutomationDefinition:request',
  GET_DEFAULT_AUTOMATION_DEFINITIONS_REQUEST = 'automations.getDefaultAutomationDefinitions:success',
  UPDATE_DEFAULT_AUTOMATION_REQUEST = 'automations.updateDefaultAutomation:request',

  CLEAR_AUTOMATIONS = 'automations.clearAutomations',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}

export type CreateAutomationPayload = {
  automationTemplateType?: AutomationTemplateType;
};

export type GetDefaultAutomationDefinition = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_DEFAULT_AUTOMATION_DEFINITION_REQUEST;
      payload: {
        defaultAutomationDefinitionPublicId: DefaultAutomationDefinition['publicId'];
        callback?: (
          defaultAutomationDefinition: PublicHydratedDefaultAutomationDefinition<AutomationActionTypes>
        ) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type GetDefaultAutomationDefinitions = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_DEFAULT_AUTOMATION_DEFINITIONS_REQUEST;
      payload: {
        callback?: (
          defaultAutomationDefinitions: PublicHydratedDefaultAutomationDefinition<AutomationActionTypes>[]
        ) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type UpdateDefaultAutomation = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_DEFAULT_AUTOMATION_REQUEST;
      payload: {
        defaultAutomation: UpdateDefaultAutomationData<AutomationActionTypes> & {
          definitionPublicId: DefaultAutomationDefinition['publicId'];
        };
        callback?: (automation: Automation<AutomationActionTypes>) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;
