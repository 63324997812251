import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import React, { FunctionComponent } from 'react';

import './PreviewControlWrapper.sass';

type Props = {
  className?: string;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  previewContent?: React.ReactNode;
  sidebarContent?: React.ReactNode;
};

const PreviewControlWrapper: FunctionComponent<Props> = ({
  className,
  title,
  previewContent,
  sidebarContent,
  footer,
}: Props): JSX.Element => {
  return (
    <ResponsiveViewWrapper
      downBreakpointSm={700}
      className={`preview-control-wrapper ${className ? className : ''}`}
    >
      <>
        <div className="lhs">
          {title && <div className="title-bar">{title}</div>}
          {previewContent && (
            <div className="preview-content-outer">
              <div className="preview-content">{previewContent}</div>
            </div>
          )}
          {footer && <div className="footer-bar">{footer}</div>}
        </div>
        {sidebarContent && (
          <div className="rhs">
            <div className="sidebar-content">{sidebarContent}</div>
          </div>
        )}
      </>
    </ResponsiveViewWrapper>
  );
};

export default PreviewControlWrapper;
