import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import React, { FC, PropsWithChildren } from 'react';

import './Modal.sass';

export type Props = {
  className?: string;
  isOpen: boolean;
  showCloseControl?: boolean;
  actions?: React.ReactNode;
  size?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
  title?: React.ReactNode;
  onClose?: () => void;
  disableBackdropClick?: boolean;
};
const Modal: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  isOpen,
  showCloseControl = true,
  actions,
  size,
  title,
  onClose,
  disableBackdropClick = false,
}: PropsWithChildren<Props>): JSX.Element => {
  const onCloseInternal: DialogProps['onClose'] = (event, reason) => {
    if (!disableBackdropClick || reason !== 'backdropClick') {
      onClose?.();
    }
  };
  return (
    <Dialog
      disableEnforceFocus
      open={isOpen}
      onClose={onCloseInternal}
      fullWidth={true}
      maxWidth={size || 'sm'}
      classes={{
        root: `modal z-modal ${className ? className : ''}`,
      }}
    >
      {showCloseControl && (
        <div className="close-control" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
      <DialogContent>
        {title && (
          <div className="title-container">
            {typeof title === 'string' ? (
              <h1 className="title">{title}</h1>
            ) : (
              title
            )}
          </div>
        )}
        {children && <div className="body-content">{children}</div>}
        {actions && <div className="actions-container">{actions}</div>}
      </DialogContent>
    </Dialog>
  );
};
export default Modal;
