import { FC } from 'react';

import StatusBadge, {
  StatusBadgeState,
} from '@payaca/components/statusBadge/StatusBadge';

import {
  ServicePlanPeriod,
  ServicePlanPeriodCommitment,
} from '@payaca/types/service-plans';

interface Props {
  status: ServicePlanPeriod['status'];
}

const stateMap: {
  [key in ServicePlanPeriodCommitment['status']]: StatusBadgeState;
} = {
  unarranged: StatusBadgeState.GREY_INITIAL,
  arranged: StatusBadgeState.AMBER_PENDING,
  fulfilled: StatusBadgeState.GREEN_SUCCESS,
};
const statusMap: {
  [key in ServicePlanPeriodCommitment['status']]: string;
} = {
  unarranged: 'Unarranged',
  arranged: 'Arranged',
  fulfilled: 'Complete',
};

export const ServicePlanPeriodStatusBadge: FC<Props> = ({ status }: Props) => {
  return (
    <StatusBadge state={stateMap[status]}>{statusMap[status]}</StatusBadge>
  );
};
