import React, { FC, useEffect, useMemo, useState } from 'react';

import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import EmptyState from '../emptyState/EmptyState';
import ListedDealsTableHeader from './ListedDealsTableHeader';
import ListedDealsTableRow from './ListedDealsTableRow';

import { ListedDeal, SortBy } from '@payaca/types/listedDealTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

import { useSelector } from '@/api/state';

import './ListedDealsTable.sass';

type Props = {
  sortBy: SortBy;
  sortDirection: SortDirection;
  onClickRow: (DealId: number) => void;
  onSelectPage: (pageNumber: number) => void;
  onSelectPageSize?: (pageSize: number) => void;
  onChangeSortOrder: (sortBy: SortBy, sortDirection: SortDirection) => void;
  hideCustomerColumn?: boolean;
};

const ListedDealsTable: FC<Props> = ({
  sortBy,
  sortDirection,
  onClickRow,
  onSelectPage,
  onChangeSortOrder,
  onSelectPageSize,
  hideCustomerColumn = false,
}: Props): JSX.Element | null => {
  const [collapse, setCollapse] = useState<boolean>(false);
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    if (width && width <= 550) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [width]);

  const listedDealsPage = useSelector((state) => {
    return state.deals?.listedDealsPage;
  });

  const isGettingListedDealsPage = useSelector(
    (state) => state.deals.isGettingListedDealsPage
  );

  const paginationControl = useMemo(() => {
    if (!listedDealsPage) return null;
    return (
      <PaginationControl
        pageNumber={listedDealsPage.pageNumber}
        pageSize={listedDealsPage.pageSize}
        totalItemCount={listedDealsPage.totalItemCount}
        onSelectPage={onSelectPage}
        allowPageSizeSelection={true}
        availablePageSizes={[5, 20, 30, 50]}
        onSelectPageSize={onSelectPageSize}
      />
    );
  }, [listedDealsPage, onSelectPage]);

  return (
    <ResponsiveViewWrapper
      className="listed-deals-table"
      downBreakpointSm={800}
      downBreakpointXs={550}
      onElementWidthChange={setWidth}
    >
      <>
        <div className="list-view-table-wrapper">
          <table className="list-view-table">
            <ListedDealsTableHeader
              sortBy={sortBy}
              sortDirection={sortDirection}
              onChangeSortOrder={onChangeSortOrder}
              collapse={collapse}
              hideCustomerColumn={hideCustomerColumn}
            />
            {isGettingListedDealsPage && (
              <tbody>
                <tr>
                  <td colSpan={10} className="tr-exclude">
                    <div className="loader-container">
                      <MiniLoader />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {!isGettingListedDealsPage && !listedDealsPage?.totalItemCount && (
              <tbody>
                <tr>
                  <td colSpan={10} className="tr-exclude">
                    <EmptyState configName={'deals'} arrowIndication={false} />
                  </td>
                </tr>
              </tbody>
            )}
            {!isGettingListedDealsPage && !!listedDealsPage?.items?.length && (
              <React.Fragment>
                {listedDealsPage.items.map(
                  (listedDeal: ListedDeal, index: number) => {
                    return (
                      <ListedDealsTableRow
                        key={`${listedDeal.dealId}-${index}`}
                        listedDeal={listedDeal}
                        listedDealIndex={index}
                        onClickRow={onClickRow}
                        collapse={collapse}
                        hideCustomerColumn={hideCustomerColumn}
                      />
                    );
                  }
                )}
              </React.Fragment>
            )}
          </table>
        </div>
        {listedDealsPage && (
          <PaginationControl
            pageNumber={listedDealsPage.pageNumber}
            pageSize={listedDealsPage.pageSize}
            totalItemCount={listedDealsPage.totalItemCount}
            onSelectPage={onSelectPage}
            allowPageSizeSelection={true}
            availablePageSizes={[5, 20, 30, 50]}
            onSelectPageSize={onSelectPageSize}
          />
        )}
      </>
    </ResponsiveViewWrapper>
  );
};

export default ListedDealsTable;
