import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import { UploadAttachableEntityType } from '@payaca/types/uploadTypes';

const GetUploads = graphql(`
  query Query($pagination: PaginationInput!, $input: GetPaginatedUploadsInput) {
    uploads(pagination: $pagination, input: $input) {
      limit
      offset
      totalCount
      items {
        id
        extension
        fileName
        mimeType
        size
        thumbnailUrl
        url
      }
    }
  }
`);

const useGetUploads = (
  input: {
    sourceEntities?: {
      type: UploadAttachableEntityType;
      id: string;
    }[];
  },
  pagination?: { offset?: number; limit?: number }
) => {
  return useQuery({
    queryKey: ['uploads', input, pagination],
    queryFn: () => {
      return gqlClient.request(GetUploads, {
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
        input: input,
      });
    },
  });
};

export default useGetUploads;
