import UntitledIcon, { TIconName } from '@payaca/untitled-icons';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import Conditional from '../conditional/Conditional';
import Button from '../plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '../plButton/useButtonClassName';

type TSharedProps = {
  message?: string | JSX.Element;
  dismissible?: boolean;
  onDismiss?: () => void;
};

export type TProps =
  | ({
      variant: 'soft' | 'solid';
      colour: 'black' | 'gray' | 'teal' | 'blue' | 'red' | 'yellow';
    } & TSharedProps)
  | ({
      variant: 'white';
      icon?:
        | 'success'
        | 'error'
        | 'warning'
        | 'info'
        | { iconName: TIconName; className?: string };
    } & TSharedProps);

const Toast: FC<TProps> = (props) => {
  if (props.variant === 'white') {
    const { message, icon, dismissible = false, onDismiss } = props;
    return (
      <div
        className="prose z-toast rounded-xl border border-gray-200 bg-white shadow-lg dark:border-neutral-700 dark:bg-neutral-800"
        role="alert"
        // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-live#polite
        aria-live="polite"
      >
        <div className="flex gap-2 p-4">
          {icon && (
            <div className="flex-shrink-0">
              <UntitledIcon
                name={(() => {
                  switch (icon) {
                    case 'success':
                      return 'check-circle.3';
                    case 'error':
                      return 'x-circle.3';
                    case 'warning':
                      return 'info-octagon.3';
                    case 'info':
                      return 'info-circle.3';
                    default:
                      return icon.iconName;
                  }
                })()}
                className={twMerge(
                  'h-5 w-5 flex-shrink-0',
                  icon === 'success' && 'text-teal-500',
                  icon === 'error' && 'text-red-500',
                  icon === 'warning' && 'text-yellow-600',
                  icon === 'info' && 'text-blue-500',
                  typeof icon !== 'string' && icon.className
                )}
              />
            </div>
          )}

          <Conditional condition={!!message}>
            {typeof message === 'string' ? (
              <p>{message}</p>
            ) : (
              <div>{message}</div>
            )}
          </Conditional>

          <Conditional condition={dismissible}>
            <Button
              className="ml-auto"
              variant={EBtnVariant.Ghost}
              colour={EBtnColour.Gray}
              size={EBtnSize.XSmall}
              onClick={onDismiss}
            >
              <span className="sr-only">Dismiss</span>
              <UntitledIcon name="x-close" className="h-3 w-3" />
            </Button>
          </Conditional>
        </div>
      </div>
    );
  }

  const {
    variant = 'solid',
    message,
    colour = 'black',
    dismissible = false,
    onDismiss,
  } = props;

  return (
    <div
      className={twMerge(
        'prose rounded-xl text-white shadow-lg',
        // Solid
        variant === 'solid' &&
          colour === 'black' &&
          'bg-gray-800 dark:bg-neutral-900',
        variant === 'solid' &&
          colour === 'gray' &&
          'bg-gray-500 dark:bg-neutral-700',
        variant === 'solid' && colour === 'teal' && 'bg-teal-500',
        variant === 'solid' && colour === 'blue' && 'bg-blue-500',
        variant === 'solid' && colour === 'red' && 'bg-red-500',
        variant === 'solid' && colour === 'yellow' && 'bg-yellow-500',
        // Soft
        variant === 'soft' &&
          colour === 'black' &&
          'border border-gray-200 bg-gray-100 text-gray-800 dark:border-white/20 dark:bg-white/10 dark:text-white',
        variant === 'soft' &&
          colour === 'gray' &&
          'border border-gray-200 bg-gray-50 text-gray-600 dark:border-white/10 dark:bg-white/10 dark:text-neutral-400',
        variant === 'soft' &&
          colour === 'teal' &&
          'border border-teal-200 bg-teal-100 text-teal-800 dark:border-teal-900 dark:bg-teal-800/10 dark:text-teal-500',
        variant === 'soft' &&
          colour === 'blue' &&
          'border border-blue-200 bg-blue-100 text-blue-800 dark:border-blue-900 dark:bg-blue-800/10 dark:text-blue-500',
        variant === 'soft' &&
          colour === 'red' &&
          'border border-red-200 bg-red-100 text-red-800 dark:border-red-900 dark:bg-red-800/10 dark:text-red-500',
        variant === 'soft' &&
          colour === 'yellow' &&
          'border border-yellow-200 bg-yellow-100 text-yellow-800 dark:border-yellow-900 dark:bg-yellow-800/10 dark:text-yellow-500'
      )}
      role="alert"
    >
      <div className="flex p-4">
        <p>{message}</p>

        <div className="ms-auto">
          <Conditional condition={dismissible}>
            <Button
              variant={EBtnVariant.Ghost}
              colour={variant === 'solid' ? EBtnColour.White : EBtnColour.Black}
              size={EBtnSize.XSmall}
              onClick={onDismiss}
            >
              <span className="sr-only">Dismiss</span>
              <UntitledIcon name="x-close" className="h-3 w-3" />
            </Button>
          </Conditional>
        </div>
      </div>
    </div>
  );
};

export default Toast;
