import { FC, useCallback, useState } from 'react';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import * as proposalsActions from '@payaca/store/proposals/proposalsActions';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ValidationResultModal from '../validationResultModal/ValidationResultModal';

type Props = {
  changeProposalId: number;
  proceedToPreview: () => void;
};
const EditChangeProposalPreviewButton: FC<Props> = ({
  changeProposalId,
  proceedToPreview,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [isGettingValidationResult, setIsGettingValidationResult] =
    useState(false);
  const [showValidationResultModal, setShowValidationResultModal] =
    useState(false);
  const [validationResult, setValidationResult] = useState<{
    isValid: boolean;
    errors: string[];
  }>();

  const isProcessing = useMemo(
    () => {
      return false;
      // TODO implement this when looking at change proposal save feedback
      // return isUpdatingChangeProposal || isPersistingCustomer;
    },
    [
      /*isUpdatingChangeProposal, isPersistingCustomer*/
    ]
  );

  const isRequestPreviewEnabled = useMemo(() => {
    return !isProcessing;
  }, [isProcessing]);

  const onGetChangeProposalValidationResultSuccess = useCallback(
    (validationResult: { isValid: boolean; errors: string[] }) => {
      setValidationResult(validationResult);
      if (!validationResult.isValid) {
        setShowValidationResultModal(true);
      } else {
        proceedToPreview();
      }
    },
    [proceedToPreview]
  );

  const onRequestPreview = useCallback(() => {
    setIsGettingValidationResult(true);
    dispatch(
      proposalsActions.getChangeProposalValidationResult.request(
        changeProposalId,
        (validationResult) => {
          setIsGettingValidationResult(false);
          onGetChangeProposalValidationResultSuccess(validationResult);
        },
        () => setIsGettingValidationResult(false)
      )
    );
  }, [onGetChangeProposalValidationResultSuccess, changeProposalId, dispatch]);

  return (
    <>
      <Button
        className="edit-invoice-preview-button"
        styleVariant={ButtonStyleVariant.OUTSIZE}
        isProcessing={isGettingValidationResult}
        isDisabled={!isRequestPreviewEnabled}
        onClick={() => !isGettingValidationResult && onRequestPreview()}
      >
        Preview
      </Button>
      {validationResult && showValidationResultModal && (
        <ValidationResultModal
          title={'Preview not available'}
          validationResult={validationResult}
          isOpen={showValidationResultModal}
          onClose={() => setShowValidationResultModal(false)}
        />
      )}
    </>
  );
};

export default EditChangeProposalPreviewButton;
