import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import meKeys from './keyFactory';

const GetMyAccountSubdomain = graphql(`
  query MyAccountSubdomain {
    me {
      user {
        account {
          subdomain {
            url
            isActive
            status
          }
        }
      }
    }
  }
`);

const useGetMyAccountSubdomain = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountSubdomain(),
    queryFn: () => {
      return gqlClient.request(GetMyAccountSubdomain);
    },
  });

  return { data: data?.me.user.account.subdomain, ...rest };
};

export default useGetMyAccountSubdomain;
