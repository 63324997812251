import useGetUploads from '@/api/queries/useGetUploads';
import LinkFilesModal from '@/ui/components/linkFilesModal/LinkFilesModal';
import * as uploadsActions from '@payaca/store/uploads/uploadsActions';
import { UploadAttachableEntityType } from '@payaca/types/uploadTypes';
import { Nullish } from '@payaca/utilities/types';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

export interface IProps {
  disabledUploadIds?: string[];
  sourceEntities?: Nullish<
    {
      type: UploadAttachableEntityType;
      id: number;
    }[]
  >;
  targetEntity: {
    type: UploadAttachableEntityType;
    id: number;
  };
  onLinkUploadsSuccess?: () => void;
  isOpen: boolean;
  onClose: () => void;
}

const LinkUploads: FC<IProps> = (props) => {
  const {
    sourceEntities,
    targetEntity,
    onLinkUploadsSuccess,
    isOpen,
    onClose,
    disabledUploadIds,
  } = props;

  const dispatch = useDispatch();

  const { data, isLoading } = useGetUploads(
    {
      sourceEntities: sourceEntities?.map((x) => ({
        ...x,
        id: x.id.toString(),
      })),
    },
    {
      offset: 0,
      limit: 100,
    }
  );

  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <LinkFilesModal
      files={data?.uploads?.items || []}
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      isProcessing={isProcessing}
      disabledFileIds={disabledUploadIds}
      onSubmit={(selectedUploads) => {
        setIsProcessing(true);

        dispatch(
          uploadsActions.linkUploadsToEntity.request({
            payload: {
              uploadIds: selectedUploads.map((x) => +x.id),
              entityType: targetEntity.type,
              entityId: targetEntity.id,
            },
            callback: () => {
              setIsProcessing(false);
              onLinkUploadsSuccess?.();
            },
          })
        );
      }}
    />
  );
};

export default LinkUploads;
