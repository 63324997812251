import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IButtonProps } from '../plButton/Button';

import { dedupe } from '@payaca/shared-isomorphic/arrays';
import { ManageableItemsList } from '../plManageableItemsList/ManageableItemsList';

export type TTableBulkAction<T extends Record<string, any>> = Omit<
  IButtonProps,
  'onClick' | 'size'
> & {
  label: string;
  onClick: (rows: T[]) => void;
  disabledTooltip?: string;
};

export type TTableFilter<T extends Record<string, any>> = {
  id: string;
  label: string;
  filterFn: (rows: T[]) => T[];
};

export type TTableRowAction<T extends Record<string, any>> = {
  label: string;
  onClick: (row: T) => void;
};

export type TTableShouldDisableRow<T extends Record<string, any>> = (
  row: T
) => { disabled: boolean; tooltip?: string };

export interface IAdvancedTableProps<T extends Record<string, any>> {
  data: T[];
  uniqueKey: keyof T;
  bulkActions?: TTableBulkAction<T>[];
  filters?: TTableFilter<T>[];
  searchField?: keyof T;
  pageLimit?: number;
  isLoading?: boolean;
  // todo: maybe a function that doesn't fire for every row and every render?
  shouldDisableRow?: TTableShouldDisableRow<T>;
  rowActions?: TTableRowAction<T>[] | ((row: T) => TTableRowAction<T>[]);
  headerContent?: {
    heading: string;
    subHeading?: string;
    buttons?: ReactNode;
  };
  onClickRow?: (row: T) => void;
  emptyText?: string;
}

const AdvancedTable = <T extends Record<string, any>>(
  props: PropsWithChildren<IAdvancedTableProps<T>>
) => {
  const {
    data,
    uniqueKey,
    isLoading = false,
    bulkActions,
    pageLimit,
    filters,
    searchField,
    shouldDisableRow,
    headerContent,
    rowActions,
    children,
    onClickRow,
    emptyText,
  } = props;
  const [pageInfo, setPageInfo] = useState({
    offset: 0,
    limit: pageLimit || data.length,
    totalCount: data.length,
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState<T[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const filteredData = useMemo(() => {
    let visibleData = data;

    if (filters && selectedFilters.length) {
      const filtered = selectedFilters.reduce((acc, filterId) => {
        const filter = filters.find((i) => i.id === filterId);

        if (!filter) {
          return acc;
        }

        acc.push(...filter.filterFn(data));

        return acc;
      }, [] as T[]);

      visibleData = dedupe<T, T>((i) => i[uniqueKey])(filtered);
    }

    // Search by the search query
    if (searchField && searchQuery) {
      visibleData = visibleData.filter((row) =>
        String(row[searchField])
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }

    return visibleData;
  }, [data, filters, searchField, searchQuery, selectedFilters, uniqueKey]);

  const paginatedData = useMemo(
    () => filteredData.slice(pageInfo.offset, pageInfo.offset + pageInfo.limit),
    [filteredData, pageInfo]
  );

  useEffect(() => {
    // Reset the pagination, if the filtered data's length has changed
    setPageInfo((prevState) =>
      prevState.totalCount !== filteredData.length ||
      prevState.limit !== pageLimit
        ? {
            offset: 0,
            limit: pageLimit || filteredData.length,
            totalCount: filteredData.length,
          }
        : prevState
    );
  }, [filteredData, pageLimit]);

  useEffect(() => {
    // Remove any selected rows that are no longer in the paginated data (data that is visible on the screen)
    setSelectedRows((s) =>
      s.filter((i) => !!paginatedData.find((j) => j[uniqueKey] === i))
    );
  }, [paginatedData, uniqueKey]);

  return (
    <>
      <ManageableItemsList>
        {headerContent && (
          <ManageableItemsList.HeaderBar
            heading={headerContent.heading}
            subHeading={headerContent.subHeading}
            buttons={headerContent.buttons}
          />
        )}
        <ManageableItemsList.ActionBar>
          {searchField && (
            <ManageableItemsList.ActionBar.SearchInput
              value={searchQuery}
              onChange={setSearchQuery}
              aria-label={`Search by ${String(searchField)}`}
            />
          )}
          {!!filters?.length && (
            <ManageableItemsList.ActionBar.BasicFilter
              options={filters.map((filter) => ({
                value: filter.id,
                label: filter.label,
              }))}
              value={selectedFilters}
              onChange={setSelectedFilters}
            />
          )}
          {!!bulkActions?.length &&
            bulkActions.map((action, index) => (
              <ManageableItemsList.ActionBar.GlobalAction
                key={index}
                tooltipContent={action.disabledTooltip}
                enabled={selectedRows.length === 0}
                onClick={() => {
                  action.onClick(selectedRows);
                }}
                disabled={selectedRows.length === 0}
              >
                {action.label}
              </ManageableItemsList.ActionBar.GlobalAction>
            ))}
        </ManageableItemsList.ActionBar>
        <ManageableItemsList.Table
          items={paginatedData}
          enableSelectAll={!!bulkActions?.length}
          selectedItems={selectedRows}
          setSelectedItems={bulkActions?.length ? setSelectedRows : undefined}
          uniqueKey={uniqueKey}
          isLoading={isLoading}
          itemActions={rowActions}
          shouldDisableItem={shouldDisableRow}
          onClickRow={onClickRow}
          emptyText={emptyText}
        >
          {children}
        </ManageableItemsList.Table>
        {!isLoading && !!filteredData.length && !!pageLimit && (
          <ManageableItemsList.PaginationBar
            pageSize={pageLimit}
            currentPage={pageInfo.offset / pageInfo.limit + 1}
            totalItems={filteredData.length}
            onPageChange={(page) =>
              setPageInfo((s) => ({ ...s, offset: (page - 1) * s.limit }))
            }
          />
        )}
      </ManageableItemsList>
    </>
  );
};

export default Object.assign(AdvancedTable, {
  Column: ManageableItemsList.Table.Column,
});
