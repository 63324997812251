import { FC } from 'react';

import { FIELD_OPTIONS } from '@/ui/pages/automationsPageOld/automation.utils';
import BasicField from '@payaca/components/basicField/BasicField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import TextareaFieldFormatter from '@payaca/components/textareaField/TextareaFieldFormatter';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import VariableInsertControl from '@payaca/components/variableInsertControl/VariableInsertControl';
import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';
import {
  AutomationEntityTypes,
  SMSActionConfig,
} from '@payaca/types/automationTypes';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import './SmsNotificationAutomationActionConfigFieldset.sass';
import { useActionVariablesForAutomationEntityType } from './useActionVariablesForAutomationEntityType';

const sendToDataAccessTypes = [
  AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER,
  AutomationDataAccessType.SCHEDULEDEVENT_ASSIGNEDUSERS_TELEPHONENUMBER_ARRAY,
  AutomationDataAccessType.CUSTOM_TELEPHONENUMBER,
  AutomationDataAccessType.DEAL_SITEADDRESSES_CONTACTS_TELEPHONENUMBER_ARRAY,
  AutomationDataAccessType.SCHEDULEDEVENT_LOCATION_CONTACTS_TELEPHONENUMBER_ARRAY,
];

type Props = {
  automationEntityType: AutomationEntityTypes;
  config: SMSActionConfig;
  onChange: (changeValue: { [key: string]: any }) => void;
  isDefaultAutomation?: boolean;
  validationState?: Record<string, FieldValidationResult>;
  touchedState?: Record<string, boolean>;
  onTouch?: (fieldName: string) => void;
  showFieldValidationMessagesUnconditionally?: boolean;
};

const SmsNotificationAutomationActionConfigFieldset: FC<Props> = ({
  automationEntityType,
  config,
  onChange,
  isDefaultAutomation,
  validationState,
  touchedState,
  onTouch,
  showFieldValidationMessagesUnconditionally,
}) => {
  // TODO: Apply some additional filtering here - not all variables should be available on default automations
  const { variables: allowableTextVariables, variableReadableNameMap } =
    useActionVariablesForAutomationEntityType(automationEntityType);

  const sendToOptions = FIELD_OPTIONS.filter((o) => {
    if (automationEntityType in o.entityColumns) {
      const dataAccessType = o.entityColumns[automationEntityType];
      if (dataAccessType) {
        return sendToDataAccessTypes.includes(dataAccessType);
      }
    }
  }).map((o) => {
    return {
      label: o.label,
      value: o.entityColumns[automationEntityType],
    };
  });

  return (
    <fieldset className="automation-action-config-fieldset sms-notification-automation-action-config-fieldset">
      <ValidatedFieldWrapper
        validationResult={validationState?.sendToNumber}
        isTouched={
          !!touchedState?.sendToNumber ||
          showFieldValidationMessagesUnconditionally
        }
      >
        <DropdownField
          label={'To'}
          name={`sendToNumber`}
          value={config.sendToNumber}
          options={sendToOptions}
          onChange={onChange}
          isDisabled={isDefaultAutomation}
        />
      </ValidatedFieldWrapper>
      {config.sendToNumber ===
        AutomationDataAccessType.CUSTOM_TELEPHONENUMBER &&
        !isDefaultAutomation && (
          <ValidatedFieldWrapper
            validationResult={validationState?.customNumber}
            isTouched={
              !!touchedState?.customNumber ||
              showFieldValidationMessagesUnconditionally
            }
          >
            <BasicField
              label={'Custom number'}
              name={`customNumber`}
              value={config.customNumber}
              onChange={onChange}
              onTouch={onTouch}
              isDisabled={isDefaultAutomation}
            />
          </ValidatedFieldWrapper>
        )}
      <VariableInsertControl
        availableVariables={allowableTextVariables}
        variableEnabledCopy={[config.messageBody]}
        variableReadableNameMap={variableReadableNameMap}
        enableVariableSearch={true}
        onUsedVariablesChange={(usedVariables) => {
          onChange({
            messageVariables: usedVariables,
          });
        }}
      >
        {({ setEventHandlers }) => (
          <div className="variable-insert-input-fields">
            <ValidatedFieldWrapper
              validationResult={validationState?.messageBody}
              isTouched={
                !!touchedState?.messageBody ||
                showFieldValidationMessagesUnconditionally
              }
            >
              <TextareaFieldFormatter
                label="Your message"
                value={config.messageBody}
                name="messageBody"
                bindEventHandlers={(x) => {
                  setEventHandlers(x);
                }}
                onChange={onChange}
                onTouch={onTouch}
              />
            </ValidatedFieldWrapper>
          </div>
        )}
      </VariableInsertControl>
    </fieldset>
  );
};

export default SmsNotificationAutomationActionConfigFieldset;
