import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  AddNewSiteAddressToProjectMutation as _AddNewSiteAddressToProjectMutation,
  AddNewSiteAddressToProjectMutationVariables,
} from '@/gql/graphql';

const AddNewSiteAddressToProjectMutation = graphql(`
  mutation AddNewSiteAddressToProject(
    $addNewSiteAddressToProjectInput: AddNewSiteAddressToProjectInput!
    $unlinkSiteAddressFromProjectInput: UnlinkSiteAddressFromProjectInput!
  ) {
    unlinkSiteAddressFromProject(input: $unlinkSiteAddressFromProjectInput) {
      id
    }
    addNewSiteAddressToProject(input: $addNewSiteAddressToProjectInput) {
      id
    }
  }
`);

const useAddNewSiteAddressToProject = (
  options?: UseMutationOptions<
    _AddNewSiteAddressToProjectMutation,
    unknown,
    {
      unlinkSiteAddressFromProjectInput?: AddNewSiteAddressToProjectMutationVariables['unlinkSiteAddressFromProjectInput'];
      addNewSiteAddressToProjectInput: AddNewSiteAddressToProjectMutationVariables['addNewSiteAddressToProjectInput'];
    }
  >
) => {
  return useMutation({
    mutationFn: (variables) => {
      return gqlClient.request(AddNewSiteAddressToProjectMutation, {
        unlinkSiteAddressFromProjectInput:
          variables.unlinkSiteAddressFromProjectInput || {
            projectId: '',
            addressId: '',
          },
        addNewSiteAddressToProjectInput:
          variables.addNewSiteAddressToProjectInput,
      });
    },
    ...options,
  });
};

export default useAddNewSiteAddressToProject;
