import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useArchiveProject from '@/api/mutations/project/useArchiveProject';
import Button from '@payaca/components/plButton/Button';
import { EBtnColour } from '@payaca/components/plButton/useButtonClassName';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';

export interface IProps extends Omit<IModalProps, 'title'> {}

const DeleteProjectModal: FC<IProps> = (props) => {
  const { ...rest } = props;
  const history = useHistory();
  const { dealId } = useParams<{ dealId: string }>();
  const { mutateAsync: archiveProjectMutation, isLoading } =
    useArchiveProject();

  const handleDelete = async () => {
    await archiveProjectMutation(dealId);

    history.push(`/deals`);
  };

  return (
    <Modal title="Delete Project" {...rest}>
      <Modal.Body>
        <p>
          Deleting this Project will also delete/archive any Proposals,
          Invoices, Events, Tasks, Forms and Payments you have created under
          this Project.
        </p>
        <p className="mt-2 font-semibold">
          This action will not be reflected in any third-party integrations.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            colour={EBtnColour.Red}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteProjectModal;
