import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import useGetMyCisDeductionRate from '@/api/queries/me/useGetMyCisDeductionRate';
import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import useGetMyTaxRates from '@/api/queries/me/useGetMyTaxRates';
import Conditional from '@payaca/components/conditional/Conditional';
import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Select, { SelectSizeVariant } from '@payaca/components/plSelect/Select';

export type TLineItemTaxesFormState = {
  cis: string;
  taxRateId: string;
  hasDiscount: boolean;
  discountPercentage?: number;
};

export const LINE_ITEM_TAXES_FORM_SCHEMA = z.object({
  cis: z.string(),
  taxRateId: z.string(),
  hasDiscount: z.boolean(),
  discountPercentage: z.number().min(0).max(100).optional(),
});

export interface IProps {
  unitPriceExcTax: number;
}

const LineItemTotalsCard: FC<IProps> = (props) => {
  const { unitPriceExcTax } = props;

  /**
   * Queries
   */
  const { taxRates } = useGetMyTaxRates();
  const { isCisSubcontractor, cisDeductionRate } = useGetMyCisDeductionRate();
  const { data: regionPreferences, isLoading: isLoadingRegionPreferences } =
    useGetMyRegionPreferences();

  /**
   * use form hook
   */
  const formMethods = useFormContext<TLineItemTaxesFormState>();

  const selectedCis = formMethods.watch('cis');
  const selectedTaxRate = formMethods.watch('taxRateId');
  const hasDiscount = formMethods.watch('hasDiscount');
  const discountPercentage = formMethods.watch('discountPercentage') || 0;

  const [unitPriceIncludingDiscount, discountAmount] = (() => {
    if (!hasDiscount) {
      return [unitPriceExcTax, 0];
    }

    return [
      Math.round(unitPriceExcTax * (1 - discountPercentage / 100)),
      Math.round(unitPriceExcTax * (discountPercentage / 100)),
    ];
  })();

  const taxAmount = (() => {
    const selctedTaxRate = taxRates?.find(
      (taxRate) => taxRate.id === selectedTaxRate
    );

    if (!selctedTaxRate || selctedTaxRate.isReverseCharge) return 0;

    return Math.round(
      (unitPriceIncludingDiscount * selctedTaxRate.percentage) / 100
    );
  })();

  const cisTotal = (() => {
    if (selectedCis === 'no' || !cisDeductionRate) {
      return 0;
    }

    return Math.round((unitPriceIncludingDiscount * cisDeductionRate) / 100);
  })();

  const taxRatesOrdered = useMemo(() => {
    return [
      ...(taxRates
        ?.filter((taxRate) => !taxRate.isReverseCharge)
        .map((taxRate) => ({
          label: taxRate.label,
          value: taxRate.id,
        })) || []),
      ...(taxRates
        ?.filter((taxRate) => taxRate.isReverseCharge)
        .map((taxRate) => ({
          label: taxRate.label,
          value: taxRate.id,
        })) || []),
    ];
  }, [taxRates]);

  const priceIncludingTax = unitPriceIncludingDiscount + taxAmount - cisTotal;

  if (isLoadingRegionPreferences || !regionPreferences) {
    return null;
  }

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <h4 className="mb-2.5">Totals</h4>

        <div className="grid grid-cols-[60px_160px_auto] items-center gap-2.5">
          <Conditional condition={hasDiscount}>
            <div className="col-span-2 pb-3">
              <p>Discount ({discountPercentage}%)</p>
            </div>

            <div className="text-right pb-3 ">
              <p>
                <CurrencyValue
                  value={-discountAmount}
                  currency={regionPreferences.currency}
                />
              </p>
            </div>
          </Conditional>

          <Conditional condition={isCisSubcontractor}>
            <div>
              <p>CIS</p>
            </div>

            <div>
              <Controller
                name="cis"
                defaultValue="no"
                control={formMethods.control}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <Select
                      sizeVariant={SelectSizeVariant.SM}
                      // Todo: fix me?
                      value={value as 'yes' | 'no'}
                      onChange={onChange}
                      options={[
                        {
                          label: `Yes (${cisDeductionRate}%)`,
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ]}
                    />
                  );
                }}
              />
            </div>

            <div className="text-right">
              <p>
                <CurrencyValue
                  value={-cisTotal}
                  currency={regionPreferences.currency}
                />
              </p>
            </div>
          </Conditional>

          <div className="pr-4">
            <p>Tax</p>
          </div>

          <div>
            <Controller
              name="taxRateId"
              defaultValue={taxRates?.find((taxRate) => taxRate.isDefault)?.id}
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <Select
                    sizeVariant={SelectSizeVariant.SM}
                    value={value}
                    onChange={onChange}
                    options={taxRatesOrdered}
                  />
                );
              }}
            />
          </div>

          <div className="text-right">
            <p>
              <CurrencyValue
                value={taxAmount}
                currency={regionPreferences.currency}
              />
            </p>
          </div>
        </div>

        <div className="mt-4 flex justify-between">
          <h4>Total sales price (inc Tax)</h4>

          <p className="font-medium">
            <CurrencyValue
              value={priceIncludingTax}
              currency={regionPreferences.currency}
            />
          </p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LineItemTotalsCard;
