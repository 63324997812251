import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo } from 'react';

import Tooltip from '@payaca/components/tooltip/Tooltip';

import './AutomationConfigBlock.sass';

import ActionIcon from './icons/action-icon.svg';
import ConditionIcon from './icons/condition-icon.svg';
import TriggerIcon from './icons/trigger-icon.svg';

export enum AutomationConfigBlockType {
  TRIGGER = 'Trigger',
  CONDITIONS = 'Conditions',
  ACTION = 'Action',
}

const configMap: any = {
  [AutomationConfigBlockType.TRIGGER]: {
    icon: TriggerIcon,
    description: 'Choose your automation and when it should run',
    explainer:
      'Choosing a type will narrow down the options in the later steps, making it easier for you to create your desired behaviours.',
  },
  [AutomationConfigBlockType.CONDITIONS]: {
    icon: ConditionIcon,
    description: 'Add any conditions which must be met',
    explainer:
      'There are some automations which only need to run in certain circumstances. Adding Conditions ensures that this automation will only run when specific requirements are met.',
  },
  [AutomationConfigBlockType.ACTION]: {
    icon: ActionIcon,
    description: 'What would you like to happen?',
    explainer: `You can add as many Actions as you like. When an Action is finished, you'll see a record in the "Activity" section of any corresponding Projects.`,
  },
};

interface Props {
  type: AutomationConfigBlockType;
  isExpanded: boolean;
  onToggleExpanded: () => void;
  title?: string;
  children: any;
  expandedTitle?: JSX.Element;
  additionalDescription?: JSX.Element;
  stopTitleDescriptionPropagation?: boolean;
}

export const AutomationConfigBlock: FC<Props> = ({
  type,
  isExpanded,
  onToggleExpanded,
  title,
  children,
  expandedTitle,
  additionalDescription,
  stopTitleDescriptionPropagation = false,
}) => {
  const config = useMemo(() => configMap[type], [type]);

  const configBlockTitle = useMemo(() => {
    if (isExpanded) {
      return expandedTitle || <h2>{type}</h2>;
    } else {
      return <h2>{title || type}</h2>;
    }
  }, [expandedTitle, title, type]);

  const configBlockDescription = useMemo(() => {
    if (isExpanded) {
      return (
        <>
          <p>
            {config.description} <Tooltip text={config.explainer} />
          </p>
          {additionalDescription}
        </>
      );
    }
  }, [additionalDescription, config, isExpanded]);

  return (
    <div className={'automation-config-block'}>
      <div
        className={
          'automation-config-block-header' + (isExpanded ? ' expanded' : '')
        }
        onClick={onToggleExpanded}
      >
        <div className={'automation-config-type'}>
          <img
            className={'config-icon' + (isExpanded ? ' expanded' : '')}
            src={config.icon}
          />
          <div
            className="automation-config-title-and-description"
            onClick={
              stopTitleDescriptionPropagation && isExpanded
                ? (e) => {
                    e.stopPropagation();
                  }
                : undefined
            }
          >
            {configBlockTitle}
            <div className="automation-config-description">
              {configBlockDescription}
            </div>
          </div>
        </div>
        <div className={'automation-config-block-toggle'}>
          <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
        </div>
      </div>
      {isExpanded && (
        <div className={'automation-config-block-body'}>{children}</div>
      )}
    </div>
  );
};
