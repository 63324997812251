import { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import BasicField from '@payaca/components/basicField/BasicField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import PaymentMethodIcons from '@payaca/components/paymentMethodIcons/PaymentMethodIcons';
import SwitchField from '@payaca/components/switchField/SwitchField';
import TextareaFieldFormatter, {
  ToolbarColourVariant,
} from '@payaca/components/textareaField/TextareaFieldFormatter';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import AccountNameField from '../bacsFields/AccountNameField';
import AccountNumberField from '../bacsFields/AccountNumberField';
import SortCodeField from '../bacsFields/SortCodeField';

import { getModal } from '@/helpers/modalHelper';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import { get } from 'lodash-es';

import { actions as appActions } from '@/api/app';
import { actions as usersActions } from '@/api/users';
import { useGettingPaidFormValidators } from '@/hooks/useCompanyInfoValidators';
import { useFormState } from '@/hooks/useFormState';
import { useAccount } from '@/utils/storeHooks';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import Card from '@payaca/components/plCard/Card';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

type Props = {
  readOnly: boolean;
};

type FormState = {
  allowStripePaymentDefault: boolean;
  allowBankTransferPaymentDefault: boolean;
  accountName: string;
  sortCode: string;
  accountNumber: string;
  vatNumber: number;
  proposalPaymentTerms: string;
  invoicePaymentTerms: string;
};

type FormValidationResult = Partial<
  Record<keyof FormState, FieldValidationResult>
>;

const CompanySettingsGettingPaid: FC<Props> = ({
  readOnly,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const account = useAccount();

  const initialState: FormState = {
    allowStripePaymentDefault: account.allowStripePaymentDefault,
    allowBankTransferPaymentDefault: account.allowBankTransferPaymentDefault,
    accountName: account.accountNameRaw ?? '',
    sortCode: account.sortCodeRaw ?? '',
    accountNumber: account.accountNumberRaw ?? null,
    vatNumber: account.vatNumber ?? null,
    proposalPaymentTerms: account.proposalPaymentTerms ?? '',
    invoicePaymentTerms: account.invoicePaymentTerms ?? '',
  };

  const fieldValidators = useGettingPaidFormValidators(account.region);
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const clearDynamicFeedbackMessage = useRef<() => void>();

  const saveFormData = useMemo(
    () => async (state: FormState) => {
      clearDynamicFeedbackMessage.current?.();
      return new Promise<void>((resolve, reject) => {
        dispatch(
          usersActions.updateBusinessAccount(
            account.id,
            state,
            (err: unknown, response: any) => {
              if (err) {
                clearDynamicFeedbackMessage.current =
                  showDynamicFeedbackMessage({
                    title: `We couldn't save your changes`,
                    isCancellable: true,
                    feedbackLevel: FeedbackLevel.ERROR,
                  });
                reject(err);
              } else {
                clearDynamicFeedbackMessage.current =
                  showDynamicFeedbackMessage({
                    title: 'Your changes have been saved',
                    lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
                    feedbackLevel: FeedbackLevel.SUCCESS,
                  });
                resolve();
              }
            }
          )
        );
      });
    },
    []
  );

  const { formState, updateFormFields, formValidationResult } = useFormState<
    FormState,
    FormValidationResult
  >(initialState, fieldValidators, {
    autoSaveFn: saveFormData,
  });

  const isConnectedToStripe = useMemo(
    () => account && account.connectedToStripe,
    [account]
  );

  const interval = useRef<any>(null);

  useEffect(() => {
    return () => clearInterval(interval.current);
  }, []);

  const setCheckStripeStatusInterval = () => {
    clearInterval(interval.current);
    interval.current = setInterval(() => checkStripeStatus(), 3000);
  };

  const checkStripeStatus = () => {
    dispatch(
      usersActions.getProfile((err: any, response: any) => {
        const connectedToStripe = get(response, [
          'accounts',
          0,
          'connectedToStripe',
        ]);

        if (connectedToStripe) {
          updateFormFields({
            // allowStripePaymentDefaultChange: true,
            allowStripePaymentDefault: true, // not sure if this is right - not sure how to test it
          });
          clearInterval(interval.current);
        } else {
          updateFormFields({
            allowStripePaymentDefault: false,
          });
        }
      })
    );
  };

  const onAllowStripePaymentDefaultChange = useCallback(
    (allowStripePaymentDefault: boolean) => {
      if (isConnectedToStripe) {
        updateFormFields({
          allowStripePaymentDefault: allowStripePaymentDefault,
        });
      } else {
        setCheckStripeStatusInterval();
        if (allowStripePaymentDefault) {
          dispatch(
            appActions.showModal(
              getModal('ACCEPT_PAYMENTS_WITH_STRIPE', {
                onClose: () => dispatch(appActions.hideModal()),
                tertiaryAction: () => dispatch(appActions.hideModal()),
              })
            )
          );
        } else {
          updateFormFields({
            allowStripePaymentDefault: false,
          });
        }
      }
    },
    [isConnectedToStripe, updateFormFields, dispatch]
  );

  const vatRegionalLabel = useMemo(
    () =>
      getRegionalTextString(
        account.region,
        RegionalStrings.VALUE_ADDED_TAX_NUMBER
      ),
    [account]
  );

  const allowBankTransferPaymentDefaultAvailable = useMemo(() => {
    return (
      !!formState.sortCode &&
      !!formState.accountName &&
      !!formState.accountNumber
    );
  }, [formState]);

  useEffect(() => {
    if (!allowBankTransferPaymentDefaultAvailable) {
      updateFormFields({
        allowBankTransferPaymentDefault: false,
      });
    }
  }, [allowBankTransferPaymentDefaultAvailable]);

  useEffect(() => {
    if (!account.connectedToStripe) {
      updateFormFields({
        allowStripePaymentDefault: false,
      });
    }
  }, [account?.connectedToStripe]);

  return (
    <div className="flex flex-col gap-y-4">
      <Card>
        <Card.Body className="flex flex-col gap-2">
          <h2>Payment method defaults</h2>
          <SwitchField
            name="allowStripePaymentDefault"
            value={formState.allowStripePaymentDefault}
            onChange={(value) => {
              onAllowStripePaymentDefaultChange(
                value.allowStripePaymentDefault
              );
            }}
            label={
              <span className="flex flex-row flex-wrap gap-2">
                <span>Card payment with Stripe </span>
                <PaymentMethodIcons />
              </span>
            }
            isDisabled={readOnly}
          />
          <div className="bank-transfer-switch-field-container flex-container flex-center">
            <SwitchField
              name="allowBankTransferPaymentDefault"
              value={formState.allowBankTransferPaymentDefault}
              onChange={updateFormFields}
              label="Bank transfer"
              isDisabled={readOnly || !allowBankTransferPaymentDefaultAvailable}
            />
            {!allowBankTransferPaymentDefaultAvailable && (
              <small className="enter-bank-details-prompt">
                Enter bank details below to enable this option
              </small>
            )}
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body className="flex flex-col gap-2">
          <h2>Bank details</h2>
          <AccountNameField
            name="accountName"
            value={formState.accountName}
            onChange={updateFormFields}
            isReadOnly={readOnly}
          />
          <ValidatedFieldWrapper
            validationResult={formValidationResult.sortCode}
          >
            <SortCodeField
              name="sortCode"
              value={formState.sortCode}
              onChange={updateFormFields}
              isReadOnly={readOnly}
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={formValidationResult.accountNumber}
          >
            <AccountNumberField
              name="accountNumber"
              value={formState.accountNumber}
              onChange={updateFormFields}
              isReadOnly={readOnly}
            />
          </ValidatedFieldWrapper>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body className="flex flex-col gap-2">
          <h2>VAT and payment terms</h2>
          <ValidatedFieldWrapper
            validationResult={formValidationResult.vatNumber}
          >
            <BasicField
              name="vatNumber"
              label={vatRegionalLabel}
              value={formState.vatNumber}
              styleVariant={InputStyleVariant.OUTSIZE}
              onChange={updateFormFields}
              textBefore={
                [AccountRegions.UK].includes(account.region) ? 'GB-' : undefined
              }
              isDisabled={readOnly}
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={formValidationResult.proposalPaymentTerms}
          >
            <TextareaFieldFormatter
              label="Payment terms shown on Proposals"
              value={formState.proposalPaymentTerms}
              name="proposalPaymentTerms"
              onChange={updateFormFields}
              isDisabled={readOnly}
              placeholder="You can describe additional payment terms here. This will be added to the bottom of your Proposals."
              toolbarColourVariant={ToolbarColourVariant.SECONDARY}
            />
          </ValidatedFieldWrapper>

          <ValidatedFieldWrapper
            validationResult={formValidationResult.invoicePaymentTerms}
          >
            <TextareaFieldFormatter
              label="Payment terms shown on Invoices"
              value={formState.invoicePaymentTerms}
              name="invoicePaymentTerms"
              onChange={updateFormFields}
              isDisabled={readOnly}
              placeholder="You can describe additional payment terms here. This will be added to the bottom of your Invoices."
              toolbarColourVariant={ToolbarColourVariant.SECONDARY}
            />
          </ValidatedFieldWrapper>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CompanySettingsGettingPaid;
