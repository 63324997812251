import Page, { Content } from '@/ui/components/Page';
import LoaderOverlay from '@payaca/components/loaderOverlay/LoaderOverlay';

const SplashPage = () => {
  return (
    <Page>
      <Content>
        <LoaderOverlay />
      </Content>
    </Page>
  );
};

export default SplashPage;
