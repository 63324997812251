import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { getPaymentScheduleStagesWithValues } from '@payaca/helpers/paymentScheduleHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import { PaymentSchedule } from '@payaca/types/payment-schedule';
import { Nullish } from '@payaca/utilities/types';
import { useMemo } from 'react';
import RichTextarea from '../plRichTextarea/RichTextarea';
import Table from '../plTable/Table';

type Props = {
  paymentSchedule: Nullish<PaymentSchedule>;
  projectValue?: number;
  region?: AccountRegions;
};
const PaymentScheduleSummary = ({
  paymentSchedule,
  projectValue,
  region,
}: Props) => {
  const stages = useMemo(
    () =>
      paymentSchedule?.stages
        ? getPaymentScheduleStagesWithValues(paymentSchedule, projectValue || 0)
        : [],
    [paymentSchedule, projectValue]
  );

  const currencySymbol = useMemo(() => {
    return getRegionalTextString(region, RegionalStrings.CURRENCY_SYMBOL);
  }, [region]);

  return (
    <>
      <Table
        data={stages}
        hideEmptyState
        footerRow={
          paymentSchedule?.explainer ? (
            <div className="mx-6 my-2">
              <RichTextarea readOnly value={paymentSchedule.explainer} />
            </div>
          ) : undefined
        }
      >
        <Table.Column
          field="description"
          header="Stage"
          headerClassName="uppercase"
          className="!whitespace-normal"
        />
        <Table.Column
          field="percentageDue"
          header="% Due"
          headerClassName="uppercase"
          className="text-right"
          render={(field) => field && `${field.toFixed(1)}%`}
        />
        {!!projectValue && (
          <Table.Column
            field="value"
            className="text-right"
            header={`${currencySymbol} Due`}
            headerClassName="uppercase"
            render={(field) => field && currencyPrice(field, region)}
          />
        )}
      </Table>
    </>
  );
};

export default PaymentScheduleSummary;
