import Tooltip from '@payaca/components/plTooltip/Tooltip';
import { differenceInDays } from 'date-fns';
import { FC } from 'react';
import useGetMyRegionPreferences from '../../../../../../api/queries/me/useGetMyRegionPreferences';
import usePreferredNumberFormat from '../../../../../../hooks/usePreferredNumberFormat';
import {
  DeliveryFulfillmentOption,
  FulfillmentOption,
  getFromAvailabilityDate,
  getFulfillmentDate,
  isCollectionFulfillment,
  LiveMaterialSupplierBadgeProps,
} from './fulfillment';
import LiveAvailabilityMaterialSupplierBadgeTooltipContent from './LiveAvailabilityMaterialSupplierBadgeTooltipContent';
import LiveAvailabilitySupplierBadge from './LiveAvailabiltySupplierBadge';

/**
 * Get delivery options - soonest delivery options and any other options in future with higher branch stock
 */
const getBranchStockDeliveryOptions = (fulfillments: FulfillmentOption[]) => {
  const deliveryFulfillments = fulfillments
    .sort(
      (a, b) =>
        getFulfillmentDate(a).getTime() - getFulfillmentDate(b).getTime()
    )
    .filter((f) => !isCollectionFulfillment(f)) as DeliveryFulfillmentOption[];
  const options: DeliveryFulfillmentOption[] = [];
  // filter out fulfillments with branch stock <= anything already in array
  deliveryFulfillments.forEach((f) => {
    if (
      !options.length ||
      options.find(
        (g) => f.branchStock && g.branchStock && f.branchStock >= g.branchStock
      )
    ) {
      options.push(f);
    }
    if (options.length) {
      const sameDayFulfillmentWithLessStock = options.find(
        (g) =>
          f.branchStock &&
          g.branchStock &&
          f.branchStock > g.branchStock &&
          differenceInDays(f.deliveryFromDate, g.deliveryFromDate) === 0
      );
      if (sameDayFulfillmentWithLessStock) {
        // replace with higher stock option
        options[options.indexOf(sameDayFulfillmentWithLessStock)] = f;
      }
    }
  });

  return options;
};

const getBranchStockFulfillmentOptionText = (
  f: FulfillmentOption,
  compactNumberFormat: Intl.NumberFormat,
  locale?: string
) => {
  const isCollection = isCollectionFulfillment(f);
  const dateString = getFromAvailabilityDate(
    getFulfillmentDate(f),
    isCollection,
    locale
  );
  const branchLocationString = isCollection ? `at ${f.branch.name} ` : '';
  return `${
    f.branchStock ? compactNumberFormat.format(f.branchStock) : 'Unknown amount'
  } available ${branchLocationString}${dateString}`;
};

const LiveBranchStockMaterialSupplierBadge: FC<
  LiveMaterialSupplierBadgeProps
> = (props) => {
  const { supplier, fulfillmentOptions } = props;
  const { data: regionPreferences } = useGetMyRegionPreferences();
  const compactNumberFormat = usePreferredNumberFormat({
    notation: 'compact',
    compactDisplay: 'short',
  });
  const deliveryOptions = getBranchStockDeliveryOptions(fulfillmentOptions);
  const collectionOptions = fulfillmentOptions.filter(isCollectionFulfillment);

  return (
    <Tooltip
      className="mr-auto"
      tooltipContent={
        <LiveAvailabilityMaterialSupplierBadgeTooltipContent
          deliveryOptions={deliveryOptions}
          collectionOptions={collectionOptions}
          getFulfillmentOptionText={(f) =>
            getBranchStockFulfillmentOptionText(
              f,
              compactNumberFormat,
              regionPreferences?.locale
            )
          }
        />
      }
    >
      <LiveAvailabilitySupplierBadge
        supplier={{
          id: supplier.id,
          name: supplier.name,
        }}
        deliveryOptions={deliveryOptions}
        collectionOptions={collectionOptions}
      />
    </Tooltip>
  );
};

export default LiveBranchStockMaterialSupplierBadge;
