import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';

import { Document } from '@payaca/types/documentTypes';
import { Task } from '@payaca/types/taskTypes';

import * as documentActions from '@payaca/store/documents/documentActions';

import { getDocumentThumbnail } from '../dealListedDocuments/dealDocumentUtils';

import { useUserHasPermission } from '@/hooks/usePermissions';
import { DocumentsPermissions } from '@payaca/permissions/documents/documents.permissions';
import { useHistory } from 'react-router';
import { DocumentPrefillPreferencesModal } from '../documentPrefillPreferencesControl/DocumentPrefillPreferencesModal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import './TaskFormCompletionControl.sass';

type Props = {
  task: Task;
  document: Document;
  includeTaskIdInUrl?: boolean;
};
const TaskFormCompletionControl: FC<Props> = ({
  task,
  document,
  includeTaskIdInUrl = false,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreatingFormInstance, setIsCreatingFormInstance] = useState(false);
  const [
    showDocumentPrefillPreferencesControl,
    setShowDocumentPrefillPreferencesControl,
  ] = useState(false);

  const userCanManagePrefillPreferences = useUserHasPermission({
    permissions: [DocumentsPermissions.MANAGE_PREFILL_PREFERENCES],
  });

  return (
    <div
      onClick={() => {
        if (task.completedAt) {
          // go to completed form

          history.push(
            `/documents/${document.id}?taskId=${
              includeTaskIdInUrl ? task.id : ''
            }`
          );
        } else {
          if (document.formInstances?.[0]?.previewToken) {
            // form exists - go to edit form
            history.push(
              `/forms/${document.formInstances[0].previewToken}/menu?dealId=${
                task.dealId
              }&taskId=${includeTaskIdInUrl ? task.id : ''}`
            );
          } else if (
            document.template?.type === 'pdf' &&
            userCanManagePrefillPreferences
          ) {
            setShowDocumentPrefillPreferencesControl(true);
          } else {
            if (!isCreatingFormInstance) {
              setIsCreatingFormInstance(true);
              dispatch(
                documentActions.createFormInstanceForDocument.request({
                  documentId: document.id,
                  callback: (formInstancePreviewToken: string) => {
                    setIsCreatingFormInstance(false);
                    history.push(
                      `/forms/${formInstancePreviewToken}/menu?dealId=${
                        task.dealId
                      }&taskId=${includeTaskIdInUrl ? task.id : ''}`
                    );
                  },
                  onErrorCallback: () => {
                    setIsCreatingFormInstance(false);
                  },
                })
              );
            }
          }
        }
      }}
      className={`task-form-completion-control`}
    >
      <CheckboxField
        isDisabled={true}
        name="completedAt"
        value={!!task.completedAt}
        label={
          <div className="checkbox-field-label-wrapper">
            <strong>{document.title}</strong>
            {isCreatingFormInstance ? (
              <MiniLoader />
            ) : (
              getDocumentThumbnail(document, true)
            )}
          </div>
        }
      />
      {task.documentId && (
        <PermissionGuard
          renderIfHasPermissions={[
            DocumentsPermissions.MANAGE_PREFILL_PREFERENCES,
          ]}
        >
          <DocumentPrefillPreferencesModal
            title={document?.title || 'Document Pre-fill Preferences'}
            isOpen={showDocumentPrefillPreferencesControl}
            onClose={() => setShowDocumentPrefillPreferencesControl(false)}
            documentId={task.documentId}
            contextTaskId={task.id}
          />
        </PermissionGuard>
      )}
    </div>
  );
};

export default TaskFormCompletionControl;
