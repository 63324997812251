export enum CustomersPermissions {
  GET_COLLECTION_MANAGEMENT_VIEW = 'customers.getCollectionManagementView',
  GET_LISTED_CUSTOMERS = 'customers.getListedCustomers',
  GET_CUSTOMERS = 'customers.getCustomers',
  DELETE_CUSTOMER = 'customers.deleteCustomer',
  ADD_CUSTOMER = 'customers.addCustomer',
  BULK_IMPORT = 'customers.bulkImport',
  BULK_EXPORT = 'customers.bulkExport',
  CLIENT_PORTAL_SHARE_SETTINGS = 'customers.clientPortalShareSettings',
}
