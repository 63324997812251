import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import { PaymentMethod, PaymentRequest, StripeError } from '@stripe/stripe-js';

import { getCurrencyIsoCodeLegacy } from '@payaca/helpers/internationalHelper';
import { AccountRegions } from '@payaca/types/accountTypes';

type Props = {
  paymentAmount?: number; // only necessary for apple pay
  onPaymentMethodChange: (paymentMethod: PaymentMethod | null) => void;
  renderActions?: (
    handleCreatePaymentMethod: () => void,
    applePayButton?: JSX.Element
  ) => JSX.Element[] | JSX.Element;
  accountRegion?: AccountRegions;
};

const SubscriptionPaymentMethodControl: FunctionComponent<Props> = ({
  paymentAmount,
  onPaymentMethodChange,
  renderActions,
  accountRegion = AccountRegions.UK,
}: Props): JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [error, setError] = useState<StripeError>();

  const applePayButton = useMemo(() => {
    if (paymentRequest) {
      return (
        <PaymentRequestButtonElement
          options={{
            paymentRequest: paymentRequest,
          }}
          key={Math.random()}
          className="w-1/2 grow"
        />
      );
    }
  }, [paymentRequest]);

  useEffect(() => {
    const cardElement = elements?.getElement(CardElement);
    if (cardElement) {
      cardElement.on('change', function () {
        setError(undefined);
      });
    }
  }, [elements]);

  useEffect(() => {
    if (stripe && paymentAmount !== undefined) {
      const pr = stripe.paymentRequest({
        country: 'GB',
        currency: getCurrencyIsoCodeLegacy(accountRegion),
        total: {
          label: 'subscription payment',
          amount: paymentAmount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      void pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
          pr.on('paymentmethod', (ev) => {
            onPaymentMethodChange(ev.paymentMethod);
          });
        }
      });
    }
  }, [stripe, paymentAmount, onPaymentMethodChange]);

  const createPaymentMethod = useCallback(
    async (cardElement: any) => {
      if (!stripe) return;

      return stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        .then((result) => {
          if (result.error) {
            setError(result.error);
            onPaymentMethodChange(null);
          } else {
            if (result?.paymentMethod) {
              onPaymentMethodChange(result.paymentMethod);
            }
          }
        });
    },
    [stripe, onPaymentMethodChange]
  );

  const handleCreatePaymentMethod = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }
    await createPaymentMethod(elements.getElement(CardElement));
  }, [createPaymentMethod, stripe, elements]);

  return (
    <div>
      <div className="relative overflow-hidden focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500 bg-white border-solid  border  border-gray-200 rounded-lg">
        <CardElement className="border-0 bg-transparent py-3 px-4  block w-full text-base focus-visible:outline-0 disabled:pointer-events-none disabled:opacity-50" />
      </div>

      {error && <p className="text-red-500">{error.message}</p>}
      {renderActions && (
        <div className="flex items-center justify-center mt-6 gap-x-4">
          {renderActions(
            () => void handleCreatePaymentMethod(),
            applePayButton
          )}
        </div>
      )}
    </div>
  );
};

export default SubscriptionPaymentMethodControl;
