import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

import Card from '@payaca/components/plCard/Card';
import { TimelogsPermissions } from '@payaca/permissions/timelogs/timelogs.permissions';
import { getTimelogTypes } from '@payaca/store/timelogs/timelogsActions';
import {
  PublicTimelogType,
  TimelogTypeRequiredSystemCategories,
} from '@payaca/types/timelogs';
import CreateEditTimelogTypeControl from '../createEditTimelogTypeControl/CreateEditTimelogTypeControl';
import ManageableTimelogType from '../manageableTimelogType/ManageableTimelogType';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import './CompanySettingsTimeTracking.sass';

type Props = {
  readOnly: boolean;
};

const CompanySettingsTimeTracking: FC<Props> = ({
  readOnly,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [timelogTypes, setTimelogTypes] = useState<PublicTimelogType[]>([]);
  const [isInputtingNewType, setIsInputtingNewType] = useState(false);
  const [isGettingTimelogTypes, setIsGettingTimelogTypes] = useState(false);

  const handleGetTimelogTypes = useCallback(() => {
    setIsGettingTimelogTypes(true);
    dispatch(
      getTimelogTypes.request({
        callback: (tt) => {
          setTimelogTypes(tt);
          setIsGettingTimelogTypes(false);
        },
      })
    );
  }, []);

  useEffect(() => {
    handleGetTimelogTypes();
  }, []);

  return (
    <Card>
      <Card.Body className="company-settings-time-tracking">
        <h2>Timelog types</h2>
        <span>
          Users can choose from this list of types when they record a timelog.
        </span>
        {!!timelogTypes?.length && (
          <ul className="timelog-types-list">
            {timelogTypes.map((tt, i) => {
              const isLastTimelogTypeOfRequiredCategory =
                timelogTypes.filter(
                  (timelogType) =>
                    tt.category !== 'uncategorised' &&
                    timelogType.category === tt.category &&
                    TimelogTypeRequiredSystemCategories.includes(tt.category)
                ).length === 1;

              return (
                <li key={i}>
                  <ManageableTimelogType
                    timelogType={tt}
                    isReadOnly={readOnly}
                    onArchiveSuccess={handleGetTimelogTypes}
                    onUpdateSuccess={handleGetTimelogTypes}
                    isArchivable={!isLastTimelogTypeOfRequiredCategory}
                  />
                </li>
              );
            })}
          </ul>
        )}
        {!isGettingTimelogTypes && !timelogTypes?.length && (
          <span>{"You haven't added any timelog types yet."}</span>
        )}
        <PermissionGuard
          renderIfHasPermissions={[TimelogsPermissions.MANAGE_TIMELOG_TYPES]}
        >
          <>
            {isInputtingNewType && (
              <div>
                <h5>Adding a new type</h5>
                <CreateEditTimelogTypeControl
                  onCreateEditSuccess={() => {
                    handleGetTimelogTypes();
                    setIsInputtingNewType(false);
                  }}
                />
              </div>
            )}
            {!isInputtingNewType && (
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => setIsInputtingNewType(true)}
              >
                Add a new type
              </Button>
            )}
          </>
        </PermissionGuard>
      </Card.Body>
    </Card>
  );
};

export default CompanySettingsTimeTracking;
