import { faImage } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import FileUploadPersistRemoveControl from '@payaca/components/fileUploadPersistRemoveControl/FileUploadPersistRemoveControl';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import RadioButton from '@payaca/components/radioButton/RadioButton';
import TextareaFieldFormatter, {
  ToolbarColourVariant,
} from '@payaca/components/textareaField/TextareaFieldFormatter';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import { FormState } from '../../pages/jobLineItemPage/JobLineItemPage';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import { JobLineItemAttachment } from '@payaca/types/jobTypes';

import { useSelector } from '../../../api/state';

import { getJobLineItem, getUserRoles } from '../../../utils/stateAccessors';

import { ImageFileExtensions } from '@payaca/helpers/fileHelper';

import { LineItemsPermissions } from '@payaca/permissions/lineItems/line-items.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import './EditItemInformation.sass';

export enum RequiredSettingsType {
  REQUIRED = 'required',
  MULTIPLE_CHOICE = 'multiple-choice',
  OPTIONAL = 'optional',
}
export const getRequiredSettingsType = (formState: any) => {
  return formState.isOptional
    ? RequiredSettingsType.OPTIONAL
    : formState.isMultipleChoice
      ? RequiredSettingsType.MULTIPLE_CHOICE
      : RequiredSettingsType.REQUIRED;
};

type Props = {
  jobLineItemId: JobLineItem['id'];
  formState: FormState;
  updateFormFields: (changedFields: Partial<FormState>) => void;
  formValidationResult: Partial<Record<keyof FormState, FieldValidationResult>>;
  persistLineItem: (
    state: FormState,
    updateLineItem?: boolean,
    onSaveFinished?: () => void
  ) => Promise<void>;
  saveAndFlush?: () => void;
  isAlwaysRequired?: boolean;
};
const EditJobLineItemInformation = ({
  jobLineItemId,
  formState,
  updateFormFields,
  formValidationResult,
  persistLineItem,
  saveAndFlush,
  isAlwaysRequired = false,
}: Props) => {
  const userRoles = useSelector(getUserRoles);

  const jobLineItem = useSelector((state) =>
    getJobLineItem(state, jobLineItemId)
  );
  const [isSavingExistingItem, setIsSavingExistingItem] = useState(false);

  const userCanEdit = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      LineItemsPermissions.UPDATE_LINE_ITEM,
    ]);
  }, [userRoles]);

  const requiredSettingsType = useMemo(() => {
    return formState.isOptional
      ? RequiredSettingsType.OPTIONAL
      : formState.isMultipleChoice
        ? RequiredSettingsType.MULTIPLE_CHOICE
        : RequiredSettingsType.REQUIRED;
  }, [formState]);

  const updateRequiredSettingsType = (value: RequiredSettingsType) => {
    updateFormFields({
      isMultipleChoice: value === RequiredSettingsType.MULTIPLE_CHOICE,
      isOptional: value === RequiredSettingsType.OPTIONAL,
      isSelected: value !== RequiredSettingsType.REQUIRED,
    });
    saveAndFlush?.();
  };
  if (!jobLineItem) {
    return null;
  }

  return (
    <ContentPanel className="edit-item-information">
      <>
        <div className="item-title-ref-image">
          <div className="flex-grow">
            <div className="item-ref-quantity-wrapper">
              <BasicField
                className="item-ref-field"
                styleVariant={InputStyleVariant.STANDARD}
                label={'Item reference'}
                value={formState?.name || ''}
                name="name"
                onChange={updateFormFields}
                placeholder="Internal use only"
                isDisabled={!userCanEdit}
                onBlur={saveAndFlush}
              />
              <BasicField
                className="item-ref-quantity"
                isRequired={true}
                value={formState.quantity}
                name="quantity"
                label="Qty"
                type="number"
                onChange={updateFormFields}
                onBlur={saveAndFlush}
              />
            </div>
          </div>
          {/* attachments */}
          <FileUploadPersistRemoveControl
            persistedFiles={
              formState?.attachments?.map((a: JobLineItemAttachment) => {
                return {
                  identifier: a.id,
                  fileName: a.fileName,
                  thumbnailUrl: a.attachmentUrl,
                };
              }) || []
            }
            persistFile={(file: File) => {
              return new Promise<void>((resolve, reject) => {
                updateFormFields({
                  attachments: [
                    {
                      file: file,
                      fileName: file.name,
                      identifier: file.name,
                    },
                  ] as any,
                });
                saveAndFlush?.();
                resolve();
              });
            }}
            removePersistedFile={(fileIdentifier) => {
              return new Promise<void>((resolve, reject) => {
                updateFormFields({
                  attachments: formState?.attachments?.filter(
                    (x: any) => x.id !== fileIdentifier
                  ),
                });
                saveAndFlush?.();
                resolve();
              });
            }}
            allowFileNameModification={false}
            allowMultipleUploads={false}
            acceptFileTypes={ImageFileExtensions}
            showFileNames={false}
            uploadTriggerIcon={faImage}
            enableDragAndDrop={true}
            isDisabled={!userCanEdit}
          />
        </div>
        <div>
          <ValidatedFieldWrapper
            validationResult={formValidationResult.description}
          >
            <TextareaFieldFormatter
              styleVariant={InputStyleVariant.STANDARD}
              label="Title & description"
              isRequired={true}
              value={formState?.description}
              name="description"
              onChange={updateFormFields}
              toolbarColourVariant={ToolbarColourVariant.WHITE}
              isDisabled={!userCanEdit}
              onBlur={saveAndFlush}
            />
          </ValidatedFieldWrapper>
        </div>
        {!isAlwaysRequired && (
          <div className="required-settings-type-wrapper">
            <RequiredSettingsTypeRadio
              isSelected={
                requiredSettingsType === RequiredSettingsType.REQUIRED
              }
              onClick={() =>
                updateRequiredSettingsType(RequiredSettingsType.REQUIRED)
              }
              label="Required"
              key="required"
            />
            <RequiredSettingsTypeRadio
              isSelected={
                requiredSettingsType === RequiredSettingsType.MULTIPLE_CHOICE
              }
              onClick={() =>
                updateRequiredSettingsType(RequiredSettingsType.MULTIPLE_CHOICE)
              }
              label="Multiple choice"
              key="multiple-choice"
            />
            <RequiredSettingsTypeRadio
              isSelected={
                requiredSettingsType === RequiredSettingsType.OPTIONAL
              }
              onClick={() =>
                updateRequiredSettingsType(RequiredSettingsType.OPTIONAL)
              }
              label="Optional extra"
              key="optional-extra"
            />
          </div>
        )}

        {/* update line item as well as jli */}
        {jobLineItem.lineItemId && (
          <PermissionGuard
            renderIfHasPermissions={[LineItemsPermissions.UPDATE_LINE_ITEM]}
          >
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              isProcessing={isSavingExistingItem}
              onClick={() => {
                if (jobLineItem.lineItemId) {
                  setIsSavingExistingItem(true);
                  // update existing line item with jli values
                  persistLineItem(formState, true, () =>
                    setIsSavingExistingItem(false)
                  );
                }
              }}
              className="update-existing-line-item"
            >
              Update existing saved item
            </Button>
          </PermissionGuard>
        )}
      </>
    </ContentPanel>
  );
};

const RequiredSettingsTypeRadio = ({
  isSelected,
  onClick,
  label,
}: {
  isSelected: boolean;
  onClick: () => void;
  label: string;
}) => {
  return (
    <div className="required-settings-type-radio">
      <RadioButton isSelected={isSelected} onClick={onClick} />
      <span onClick={onClick}>{label}</span>
    </div>
  );
};

export default EditJobLineItemInformation;
