import { call, delay, put, race, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions';

import {
  clearListedSuppliersPage,
  getListedSuppliersPageFailure,
  getListedSuppliersPageSuccess,
} from './listedSuppliersActions';
import {
  ActionType,
  GetListedSuppliersRequestData,
  SagaConfig,
} from './listedSuppliersTypes';

import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const listedSuppliersSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetListedSuppliersPage(
    action: PayloadAction<
      ActionType.REQUEST_GET_LISTED_SUPPLIERS_PAGE,
      {
        getListedSuppliersRequestData: GetListedSuppliersRequestData;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getListedSuppliersPage,
          action.payload.getListedSuppliersRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getListedSuppliersPageFailure(
            new Error('Get listed suppliers page timed out.')
          )
        );
      } else {
        yield put(getListedSuppliersPageSuccess(response));
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(getListedSuppliersPageFailure(error));
    }
  }

  function* handleAppLogout() {
    yield put(clearListedSuppliersPage());
  }

  const getListedSuppliersPage = async (
    getListedSuppliersRequestData: GetListedSuppliersRequestData
  ) => {
    const response = await req.get(
      `/suppliers/list?pageNumber=${
        getListedSuppliersRequestData.pageNumber
      }&pageSize=${getListedSuppliersRequestData.pageSize}&searchTerm=${
        getListedSuppliersRequestData.searchTerm || ''
      }&sortDirection=${
        getListedSuppliersRequestData.sortDirection || ''
      }&sortBy=${
        getListedSuppliersRequestData.sortBy || ''
      }&excludeSystemManaged=${
        getListedSuppliersRequestData.excludeSystemManaged
      }`
    );
    return await response.json();
  };

  return function* () {
    yield takeEvery(
      ActionType.REQUEST_GET_LISTED_SUPPLIERS_PAGE,
      handleGetListedSuppliersPage
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default listedSuppliersSagaCreator;
