import UntitledIcon from '@payaca/untitled-icons';
import { FC } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

export type TBreadcrumbItem = {
  label: string;
  To?: LinkProps['to'];
  href?: string;
};

export interface IProps {
  className?: string;
  items: TBreadcrumbItem[];
}

const Breadcrumb: FC<IProps> = (props) => {
  const { className, items } = props;

  if (items.length === 0) return null;

  return (
    <ol
      className={
        'responsive-breadcrumbs m-0 flex items-center whitespace-nowrap p-0' +
        (className ? ' ' + className : '')
      }
      aria-label="Breadcrumb"
    >
      {items.map((i, index) => {
        const isLast = index === items.length - 1;
        let Element: any = 'a';
        if (isLast) {
          Element = 'span';
        } else if (i.To) {
          Element = Link;
        }

        return (
          <li key={index} className="inline-flex items-center">
            <Element
              className={
                !isLast
                  ? 'flex items-center text-base font-normal text-gray-500 hover:text-blue-600 hover:no-underline focus:text-blue-600 focus:outline-none dark:focus:text-blue-500'
                  : 'inline-flex items-center truncate text-base font-semibold text-gray-800 dark:text-gray-200'
              }
              to={i.To}
              href={i.href}
              aria-current={isLast && 'page'}
            >
              {i.label}
            </Element>
            {!isLast && (
              <UntitledIcon
                name="chevron-right"
                className="mx-2 h-4 w-4 flex-shrink-0 overflow-visible text-gray-400 dark:text-neutral-600"
              />
            )}
          </li>
        );
      })}
    </ol>
  );
};

export default Breadcrumb;
