import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

import { useSelector } from '@/api/state';
import CreateEditNoteModal from '@/ui/components/createEditNoteModal/CreateEditNoteModal';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import {
  useNotesForScheduledEvent,
  useScheduledEvent,
} from '@payaca/store/hooks/appState';
import * as notesActions from '@payaca/store/notes/notesActions';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';
import ConfirmDeleteNoteModal from '../confirmDeleteNoteModal/ConfirmDeleteNoteModal';
import NoteControl from '../noteControl/NoteControl';
import './ScheduledEventReadView.sass';
import { SectionDivider, SectionTitle } from './SectionComponents';

export const NotesSection: FC<{
  scheduledEventId: ScheduledEvent['id'];
  onUpdateNotesSuccess?: () => void;
}> = ({ scheduledEventId, onUpdateNotesSuccess }) => {
  const dispatch = useDispatch();
  const [noteToEdit, setNoteToEdit] = useState<{ publicId?: string } | null>(
    null
  );
  const [confirmDeleteNote, setConfirmDeleteNote] = useState<number | null>(
    null
  );
  const [isArchivingNote, setIsArchivingNote] = useState(false);
  const [archiveNoteErrorMessage, setArchiveNoteErrorMessage] =
    useState<string>();

  const isGettingNotesForScheduledEvent = useSelector(
    (state) => state.notes.isGettingNotesForScheduledEvent
  );

  const notes = useNotesForScheduledEvent(scheduledEventId);
  const scheduledEvent = useScheduledEvent(scheduledEventId);

  const onNoteSuccess = useCallback(() => {
    setNoteToEdit(null);
    dispatch(
      notesActions.getNotesForScheduledEvent.request({
        scheduledEventId,
        callback: onUpdateNotesSuccess,
      })
    );
  }, [scheduledEventId, onUpdateNotesSuccess]);

  const archiveNote = useCallback(
    (noteId: number) => {
      setIsArchivingNote(true);
      dispatch(
        notesActions.archiveScheduledEventNote.request({
          noteId,
          scheduledEventId,
          callback: () => {
            setIsArchivingNote(false);
            setConfirmDeleteNote(null);
            onNoteSuccess?.();
          },
          onErrorCallback: () => {
            setIsArchivingNote(false);
            setArchiveNoteErrorMessage(
              'Sorry, there was an error deleting your Note.'
            );
          },
        })
      );
    },
    [scheduledEventId, onNoteSuccess]
  );

  if (!scheduledEvent) return null;

  return (
    <div>
      <div className="flex flex-row justify-between">
        <SectionTitle>Notes</SectionTitle>
        <Button
          styleVariant={ButtonStyleVariant.ANCHOR}
          onClick={() => setNoteToEdit({})}
        >
          Add Note
        </Button>
      </div>
      <SectionDivider />

      {isGettingNotesForScheduledEvent && !notes?.length ? (
        <div className="flex items-center justify-center">
          <MiniLoader />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          {scheduledEvent.noteIds.map((noteId) => {
            return (
              <NoteControl
                key={`scheduled-event-notes-${noteId}`}
                noteId={noteId}
                onPersistNoteSuccess={onNoteSuccess}
                editNote={(note) => setNoteToEdit({ publicId: note.publicId })}
                archiveNote={(id) => setConfirmDeleteNote(id)}
              />
            );
          })}
        </div>
      )}
      <CreateEditNoteModal
        isOpen={!!noteToEdit}
        eventId={scheduledEventId.toString()}
        notePublicId={noteToEdit?.publicId}
        onClose={() => setNoteToEdit(null)}
        onSuccess={onNoteSuccess}
        onSuccessDelete={onNoteSuccess}
      />

      <ConfirmDeleteNoteModal
        onClose={() => setConfirmDeleteNote(null)}
        isOpen={!!confirmDeleteNote}
        onConfirm={() => {
          confirmDeleteNote && archiveNote(confirmDeleteNote);
        }}
        isProcessing={isArchivingNote}
      />
    </div>
  );
};
