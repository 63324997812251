import { FC, useMemo } from 'react';

import Badge from '@payaca/components/plBadge/Badge';
import { BadgeColourVariant } from '@payaca/types/plBadge';
import { ScheduledEventTaskStatus } from '@payaca/types/scheduledEventsTypes';

type Props = {
  status?: ScheduledEventTaskStatus;
};

const ScheduledEventTaskStatusBadge: FC<Props> = ({
  status,
}: Props): JSX.Element | null => {
  const state = useMemo<BadgeColourVariant>(() => {
    switch (status) {
      case 'No tasks':
        return 'gray';
      case 'Completed':
        return 'teal';
      case 'Partially completed':
        return 'yellow';
      case 'Not started':
        return 'blue';
      default:
        return 'gray';
    }
  }, [status]);

  if (!status) return null;

  return (
    <Badge variant="soft" colour={state} rounded>
      {status}
    </Badge>
  );
};

export default ScheduledEventTaskStatusBadge;
