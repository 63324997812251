import qs from 'qs';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import * as authActions from '@payaca/store/auth/authActions';

import useLogout from '@/hooks/auth/useLogout';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';

const XeroCallbackPage: FunctionComponent = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();

  const query = useMemo(() => {
    return qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
  }, [window?.location?.search]);

  const logout = useLogout();

  useEffect(() => {
    logout();
  }, []);

  const parseQueryParam = useCallback((param: any) => {
    const splitParam = param.split(':');
    const value = splitParam?.length ? splitParam[1] : 'null';
    return value === 'null' ? null : value;
  }, []);

  const registerQueryProperties = useMemo(() => {
    const propertiesObject: any = {};
    if (
      query.state &&
      typeof query.state === 'string' &&
      query.state !== '_register' &&
      query.state.endsWith('_register')
    ) {
      const splitState = query.state.split('_register');

      const properties = splitState[0].split(',');
      const numberOfEmployeesProperty = properties.find((p) =>
        p.includes('numberOfEmployees')
      );
      const industryTypeProperty = properties.find((p) =>
        p.includes('industryType')
      );
      const industryTypeOtherProperty = properties.find((p) =>
        p.includes('industryTypeOther')
      );
      const referralTokenProperty = properties.find((p) =>
        p.includes('referralToken')
      );
      propertiesObject.numberOfEmployees = parseQueryParam(
        numberOfEmployeesProperty
      );
      propertiesObject.industryType = parseQueryParam(industryTypeProperty);
      propertiesObject.industryTypeOther = parseQueryParam(
        industryTypeOtherProperty
      );
      propertiesObject.referralToken = parseQueryParam(referralTokenProperty);
    }
    return propertiesObject;
  }, [parseQueryParam, query?.state]);

  const registerQuery = useMemo(() => {
    return Object.keys(registerQueryProperties)
      .filter((f) => !!registerQueryProperties[f]) // filter out any null properties
      .map((key: string) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          (registerQueryProperties)[key]
        )}`;
      })
      .join('&');
  }, [registerQueryProperties]);

  useEffect(() => {
    // TODO: generate state query before login, passed in params to xero and returned to check no modifications
    if (
      query.state &&
      typeof query.state === 'string' &&
      query.state.endsWith('_nativeRegister')
    ) {
      // If state includes native - redirect to mobile and login there with returned code
      //@ts-expect-error opps
      window.location = `Payaca://register/xero=${query.code}`;
    } else if (
      query.state &&
      typeof query.state === 'string' &&
      (query.state.endsWith('_nativeLogin') || query.state.endsWith('_native'))
    ) {
      //@ts-expect-error opps
      window.location = `Payaca://login/xero=${query.code}`;
    } else {
      if (query.code) {
        const payload: any = {
          code: query.code,
        };
        if (
          query.state &&
          typeof query.state === 'string' &&
          query.state.endsWith('_register')
        ) {
          // register - pass additional properties into request
          dispatch(
            authActions.requestSignUpWithXero(
              {
                ...payload,
                ...registerQueryProperties,
              },
              (error) => {
                if (error) {
                  history.push(
                    `/register?${registerQuery}&error=An error occurred signing up with Xero, please try again or use an alternative method.`
                  );
                } else {
                  history.push(`/register?${registerQuery}`);
                }
              }
            )
          );
        } else {
          dispatch(
            authActions.requestLoginWithXero(payload, (error) => {
              if (error) {
                history.push(
                  '/?error=An error occurred logging into Xero, please try again or use an alternative method.'
                );
              } else {
                history.push('/');
              }
            })
          );
        }
      }
    }
  }, [query, registerQueryProperties, registerQuery]);

  return (
    <UnauthenticatedPageWrapper
      hideBody={true}
      footerContent={
        <div className="loader-container">
          <MiniLoader />
        </div>
      }
    ></UnauthenticatedPageWrapper>
  );
};

export default XeroCallbackPage;
