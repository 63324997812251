import { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import ContactUsModal from '../contactUsModal/ContactUsModal';

import { clearLocalStorage } from '../../../helpers/localStorageKeyHelper';

import './HeaderInformationResourcesNavigation.sass';

const HeaderInformationResourcesNavigation: FC = (): JSX.Element => {
  const history = useHistory();
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  const renderNavigationItem = useCallback(
    (title: string | JSX.Element, onClick?: () => void, isDisabled = false) => {
      return (
        <div
          role="menuitem"
          className={`navigation-item ${isDisabled ? 'disabled' : ''}`}
          onClick={() => !isDisabled && onClick && onClick()}
        >
          {title}
        </div>
      );
    },
    [history]
  );

  return (
    <>
      <nav
        className="header-information-resources-navigation"
        aria-label="tertiary-header"
      >
        {renderNavigationItem(
          'Help articles',
          () => window.open('https://help.payaca.com/en/'),
          false
        )}
        {renderNavigationItem('Contact us', () => setShowContactUsModal(true))}
        <hr />
        {renderNavigationItem(
          'Clear cache',
          async () => {
            clearLocalStorage();
            window.location.reload();
          },
          false
        )}
      </nav>
      <ContactUsModal
        isOpen={showContactUsModal}
        onClose={() => setShowContactUsModal(false)}
      />
    </>
  );
};

export default HeaderInformationResourcesNavigation;
