/**
 * @file ENA Connection Application Page
 * Feature docs: https://www.notion.so/payaca/ENA-DNO-integration-106dc0fd6e60807299f8e1c6a059f096
 */

import { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { UseFieldArrayReturn } from 'react-hook-form';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import useGetConnectionApplication from '@/api/queries/connectionApplication/useGetConnectionApplication';
import useGetProjectOverview from '@/api/queries/project/useGetProjectOverview';
import useGetConnectionApplicationSchema, {
  FormAndJSONSchema,
} from '@/api/rest/connectionApplication/useGetConnectionApplicationSchema';
import ConnectionApplicationForm from '@/ui/pages/connectionApplicationPage/ConnectionApplicationForm';
import ConnectionApplicationReadonly from '@/ui/pages/connectionApplicationPage/ConnectionApplicationReadonly';
import ConnectionApplicationSkeleton from '@/ui/pages/connectionApplicationPage/ConnectionApplicationSkeleton';
import { useParams } from 'react-router';
import { prepareFormSchema, prepareJSONSchema } from './utils';

export type UnionFieldArray = { fieldArray: UseFieldArrayReturn };

const ConnectionApplication: FC = () => {
  /**
   * Router
   */
  const { projectId, connectionApplicationId } = useParams<{
    projectId: string;
    connectionApplicationId: string;
  }>();

  /**
   * Queries
   */
  const { data: persistedFormStateData } = useGetConnectionApplication(
    connectionApplicationId
  );
  const { data: projectData } = useGetProjectOverview(+projectId);

  const lcts = useMemo(
    () => persistedFormStateData?.connectionApplication.data.lcts,
    [persistedFormStateData]
  );
  const { data: formSchema } = useGetConnectionApplicationSchema(lcts);

  return (
    <>
      <Helmet title={'DNO application'} />

      <AuthenticatedPageWrapper
        className="flex flex-col bg-gray-50"
        breadcrumbItems={[
          {
            label: `Projects`,
            To: `/deals`,
          },
          {
            label: `Project: #${projectData?.project.reference || ''}`,
            To: `/deals/${projectId}/compliance`,
          },
          {
            label: 'DNO application',
          },
        ]}
      >
        <div className="mx-auto max-w-6xl px-4">
          {formSchema && persistedFormStateData ? (
            <ConnectionForm formSchema={formSchema} />
          ) : (
            <ConnectionApplicationSkeleton />
          )}
        </div>
      </AuthenticatedPageWrapper>
    </>
  );
};

const ConnectionForm: FC<{
  formSchema: FormAndJSONSchema;
}> = (props) => {
  const { formSchema: unenhancedFormSchema } = props;

  /**
   * Router
   */
  const { connectionApplicationId } = useParams<{
    projectId: string;
    connectionApplicationId: string;
  }>();

  /**
   * Queries
   */
  const { data: persistedFormStateData } = useGetConnectionApplication(
    connectionApplicationId,
    false
  );

  // This is 5k+ line JSON schema
  const [jsonSchema, formSchema] = useMemo(() => {
    return [
      prepareJSONSchema(unenhancedFormSchema),
      prepareFormSchema(unenhancedFormSchema),
    ];
  }, [unenhancedFormSchema]);

  const subSections = Object.entries<any>(formSchema.properties).sort(
    ([_k, v], [_k2, v2]) => v.order - v2.order
  );

  const readonly = !!persistedFormStateData?.connectionApplication.submittedAt;

  if (readonly) {
    return (
      <ConnectionApplicationReadonly
        jsonSchema={jsonSchema}
        formSchema={formSchema}
        subSections={subSections}
      />
    );
  }

  return (
    <ConnectionApplicationForm
      jsonSchema={jsonSchema}
      formSchema={formSchema}
      subSections={subSections}
    />
  );
};

export default ConnectionApplication;
