import { FunctionComponent, useEffect, useState } from 'react';

import { useSelector } from '@/api/state';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import { LabelStyleVariant } from '@payaca/components/fieldLabel/FieldLabel';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { TaxRate } from '@payaca/types/taxRateTypes';
import { isNullish } from '@payaca/utilities/guards';
import { Nullish } from '@payaca/utilities/types';

type Props = {
  styleVariant?: InputStyleVariant;
  labelStyleVariant?: LabelStyleVariant;
  taxRateId: Nullish<TaxRate['id']>;
  excludeReverseChargeOptions?: boolean;
  onChange?: (value: { [key: string]: any }) => void;
  onTouch?: (fieldName: string) => void;
  hideLabel?: boolean;
  isDisabled?: boolean;
};

const SalesTaxSettingsField: FunctionComponent<Props> = ({
  styleVariant,
  labelStyleVariant,
  taxRateId,
  excludeReverseChargeOptions = false,
  onChange,
  onTouch,
  hideLabel = false,
  isDisabled = false,
}: Props): JSX.Element | null => {
  const [selectedTaxRateId, setSelectedTaxRateId] = useState<
    TaxRate['id'] | null
  >(taxRateId ?? null);
  const taxRates = useSelector((state) => state.taxRates.store);
  const options = Object.values(taxRates)
    .filter((x) => (excludeReverseChargeOptions ? !x.isReverseCharge : true))
    .sort((a, b) => a.id - b.id)
    .map((x) => {
      let label = `${x.name}`;
      if (!x.isIncluded) {
        label = `No ${label}`;
      } else {
        if (x.isReverseCharge) {
          label = `Reverse charge ${label}`;
        }
        label = `${x.percentage}% ${label}`;
      }
      return {
        label,
        value: String(x.id),
        ...(excludeReverseChargeOptions
          ? {}
          : { optionGroupId: x.isReverseCharge ? 1 : 0 }),
      };
    });

  useEffect(() => {
    setSelectedTaxRateId(taxRateId ?? null);
  }, [taxRates, taxRateId]);

  return (
    <DropdownField
      styleVariant={styleVariant}
      labelStyleVariant={labelStyleVariant}
      label={!hideLabel ? 'Tax' : undefined}
      value={isNullish(selectedTaxRateId) ? '' : String(selectedTaxRateId)}
      name="taxRateId"
      onChange={(value: { [key: string]: any }) => {
        onChange && onChange(value);
      }}
      onTouch={() => {
        onTouch && onTouch('taxRateId');
      }}
      optionGroups={
        excludeReverseChargeOptions
          ? undefined
          : [
              {
                name: 'Reverse charge',
                id: 1,
              },
            ]
      }
      options={options}
      isDisabled={isDisabled}
    />
  );
};

export default SalesTaxSettingsField;
