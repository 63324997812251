import { JobStatus } from '@payaca/types/jobTypes';
import moment from 'moment-timezone';

const invoiceStatuses: JobStatus[] = [
  JobStatus.NEW_INVOICE,
  JobStatus.INVOICED,
  JobStatus.PAID,
];
const quoteStatuses: JobStatus[] = [
  JobStatus.NEW_QUOTE,
  JobStatus.QUOTED,
  JobStatus.ACCEPTED,
];
const estimateStatuses: JobStatus[] = [
  JobStatus.NEW_ESTIMATE,
  JobStatus.ESTIMATED,
];

const sentStatuses: JobStatus[] = [
  JobStatus.QUOTED,
  JobStatus.ESTIMATED,
  JobStatus.INVOICED,
  JobStatus.ACCEPTED,
  JobStatus.PAID,
];

export const isInvoice = (jobStatus: JobStatus): boolean => {
  return invoiceStatuses.indexOf(jobStatus) > -1;
};

export const isQuote = (jobStatus: JobStatus): boolean => {
  return quoteStatuses.indexOf(jobStatus) > -1;
};

export const isEstimate = (jobStatus: JobStatus): boolean => {
  return estimateStatuses.indexOf(jobStatus) > -1;
};

export const isQuoteOrEstimate = (jobStatus: JobStatus): boolean => {
  return !isInvoice(jobStatus);
};

export const jobHasSent = (jobStatus: JobStatus): boolean => {
  return sentStatuses.indexOf(jobStatus) > -1;
};

export const isSentQuoteEstimate = (jobStatus: JobStatus): boolean =>
  jobStatus === JobStatus.QUOTED || jobStatus === JobStatus.ESTIMATED;

export const isInvoiceOverdue = (
  invoiceDue: Date | null,
  status: JobStatus
): boolean =>
  !!invoiceDue &&
  moment(invoiceDue).isBefore(moment()) &&
  status !== JobStatus.PAID &&
  status !== JobStatus.NEW_INVOICE;
