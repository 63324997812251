import Autocomplete, {
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import BasicField from '../basicField/BasicField';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Search } from 'react-iconly';
import { LabelStyleVariant } from '../fieldLabel/FieldLabel';
import { InputStyleVariant } from '../inputWrapper/InputWrapper';
import MiniLoader from '../miniLoader/MiniLoader';
import './TypeToSearchField.sass';

type Props<T> = {
  options?: T[];
  isLoadingOptions?: boolean;
  disableFilteringInComponent?: boolean;
  disableShowOptions?: boolean;
  // use if multiple is false
  onSelectOption?: (option?: T) => void;
  // use if multiple is true
  onSelectOptions?: (options?: T[]) => void;
  onSearchTermChange?: (searchTerm: string) => void;
  onSearchTermChangeTimeout?: () => void;
  onEnter?: () => void;
  renderOption?: (option: T) => React.ReactNode;
  getOptionLabel?: (option: T) => string;
  styleVariant?: InputStyleVariant;
  labelStyleVariant?: LabelStyleVariant;
  changeTimeoutMs?: number;
  placeholder?: string;
  label?: string;
  description?: string;
  noOptionsText?: React.ReactNode;
  value?: string;
  onOpen?: () => void;
  isRequired?: boolean;
  iconAfter?: React.ReactNode | IconDefinition | null;
  iconBefore?: React.ReactNode | IconDefinition | null;
  autofocus?: boolean;
  onBlur?: () => void;
  additionalInputProps?: any;
  getOptionDisabled?: (option: T) => boolean;
  autoHighlight?: boolean;
  disableCloseOnSelect?: boolean;
  blockInputChangeReasons?: AutocompleteInputChangeReason[];
  openOnFocus?: boolean;
  multiple?: boolean;
  defaultValue?: any;
  isDisabled?: boolean;
};

const TypeToSearchField = <T extends object>({
  options,
  isLoadingOptions = false,
  disableFilteringInComponent = false,
  disableShowOptions = false,
  onSelectOption,
  onSelectOptions,
  onSearchTermChangeTimeout,
  onSearchTermChange,
  renderOption,
  getOptionLabel,
  onEnter,
  styleVariant,
  labelStyleVariant,
  changeTimeoutMs = 1000,
  placeholder,
  label,
  description,
  noOptionsText,
  value,
  multiple,
  onOpen,
  isRequired = false,
  iconAfter,
  iconBefore,
  autofocus,
  onBlur,
  additionalInputProps = {},
  getOptionDisabled,
  disableCloseOnSelect = false,
  blockInputChangeReasons = [],
  openOnFocus,
  autoHighlight,
  defaultValue,
  isDisabled,
}: Props<T>): JSX.Element => {
  const [changeTimeout, setChangeTimeout] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="type-to-search-field">
      <Autocomplete
        multiple={multiple}
        disableCloseOnSelect={disableCloseOnSelect}
        getOptionDisabled={getOptionDisabled}
        autoHighlight={autoHighlight}
        openOnFocus={openOnFocus}
        disabled={isDisabled}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && onEnter) {
            // Prevent's default 'Enter' behavior.
            // @ts-ignore
            event.defaultMuiPrevented = true;
            onEnter();
          }
        }}
        id="combo-box-demo"
        inputValue={value}
        open={isOpen && !disableShowOptions && !isDisabled}
        onOpen={() => {
          setIsOpen(true);
          onOpen && onOpen();
        }}
        onClose={() => setIsOpen(false)}
        options={options || []}
        classes={{ paper: 'type-to-search-field-paper' }}
        getOptionLabel={(option: any) => {
          return getOptionLabel
            ? getOptionLabel(option as T)
            : option?.label || '';
        }}
        renderOption={(option: any, state: any) => {
          return renderOption ? (
            renderOption(option)
          ) : (
            <span>{option.label}</span>
          );
        }}
        filterOptions={
          disableFilteringInComponent ? (options: any) => options : undefined
        }
        onChange={(event: any, option: T | string | (string | T)[] | null) => {
          if (!option) {
            onSelectOption && onSelectOption();
          } else {
            if (multiple) {
              onSelectOptions && onSelectOptions(option as T[]);
            } else {
              onSelectOption && onSelectOption(option as T);
            }
          }
        }}
        noOptionsText={noOptionsText}
        onInputChange={(
          event: any,
          value: string,
          reason: AutocompleteInputChangeReason
        ) => {
          if (!event) return;
          if (!!onEnter && event?.key === 'Enter') return;
          if (blockInputChangeReasons.includes(reason)) {
            return;
          }
          onSearchTermChange && onSearchTermChange(value);
          changeTimeout && clearTimeout(changeTimeout);
          setChangeTimeout(
            setTimeout(() => {
              onSearchTermChangeTimeout && onSearchTermChangeTimeout();
            }, changeTimeoutMs)
          );
        }}
        defaultValue={defaultValue}
        freeSolo={!noOptionsText}
        onBlur={onBlur}
        renderInput={(params: AutocompleteRenderInputParams) => {
          return (
            <div ref={params.InputProps.ref}>
              <BasicField
                label={label}
                labelStyleVariant={labelStyleVariant}
                styleVariant={styleVariant}
                name="searchTerm"
                isDisabled={params.disabled}
                isRequired={isRequired}
                description={description}
                additionalInputProps={{
                  ...params.inputProps,
                  autoComplete: 'off',
                  autoFocus: autofocus,
                  ...additionalInputProps,
                }}
                placeholder={placeholder}
                iconAfter={
                  iconAfter === undefined ? (
                    isLoadingOptions ? (
                      <MiniLoader />
                    ) : (
                      <Search set="light" size={18} />
                    )
                  ) : (
                    iconAfter
                  )
                }
                iconBefore={iconBefore}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default TypeToSearchField;
