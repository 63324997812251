import { createReducer } from '../utils';

import {
  ActionType,
  ActiveTimelogData,
  AnyAction,
  State,
} from './timelogsTypes';

export const initialState: State = {
  timelogsToCreate: [],
  activeTimelog: undefined,
};

const timelogsReducer = createReducer<State, ActionType, AnyAction>(
  initialState,
  {
    [ActionType.INITIATE_ACTIVE_TIMELOG]: (state, action) => ({
      ...state,
      activeTimelog: action.payload,
    }),
    [ActionType.UPDATE_ACTIVE_TIMELOG]: (state, action) => ({
      ...state,
      activeTimelog: {
        ...state.activeTimelog,
        ...action.payload,
      } as ActiveTimelogData,
    }),
    [ActionType.CLEAR_ACTIVE_TIMELOG]: (state, action) => ({
      ...state,
      activeTimelog: undefined,
    }),
    [ActionType.CLEAR_TIMELOGS_TO_CREATE]: (state, action) => ({
      ...state,
      timelogsToCreate: [],
    }),
    [ActionType.ENQUEUE_TIMELOG_TO_CREATE]: (state, action) => ({
      ...state,
      timelogsToCreate: [
        ...state.timelogsToCreate,
        {
          timelog: action.payload.timelog,
          identifier: new Date().getTime().toString(),
        },
      ],
    }),
    [ActionType.DEQUEUE_TIMELOGS_TO_CREATE]: (state, action) => ({
      ...state,
      timelogsToCreate: [
        ...state.timelogsToCreate.filter(
          (x) => !action.payload.identifiers.includes(x.identifier)
        ),
      ],
    }),
  }
);

export default timelogsReducer;
