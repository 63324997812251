import Card from '@payaca/components/plCard/Card';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { FC } from 'react';

export interface IProps {}

const PurchaseOrderPageSkeletonLoader: FC<IProps> = (props) => {
  return (
    <div className="space-y-4">
      <Card>
        <Card.Body>
          <SkeletonLoader className="h-96 rounded-lg" />
        </Card.Body>
      </Card>
    </div>
  );
};

export default PurchaseOrderPageSkeletonLoader;
