import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useCallback, useMemo } from 'react';

import { ListedCustomer } from '@payaca/types/listedCustomerTypes';

import Checkbox from '@payaca/components/checkbox/Checkbox';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';
import QuickActionsTableCell from '@payaca/components/quickActionsTableCell/QuickActionsTableCell';
import RowSelectionTableCell from '@payaca/components/rowSelectionTableCell/RowSelectionTableCell';
import EmptyState from '../emptyState/EmptyState';

import QuickbooksLogo from '../../../assets/images/quickbooks.svg';
import XeroLogo from '../../../assets/images/xero.svg';

import { formatPhoneNumber } from '@payaca/helpers/phoneNumberHelper';

import { useSelector } from '@/api/state';
import './ListedCustomersTable.sass';

type Props = {
  selectedCustomerIds?: number[];
  onClickRow?: (customerId: number) => void;
  onSelectCustomers?: (customerIds: number[]) => void;
  onUnselectCustomers?: (customerIds: number[]) => void;
  onSelectPage: (pageNumber: number) => void;
  quickActionDefinitions?: {
    edit?: {
      actionName: string;
      actionBehaviour: (id: number) => void;
      isActionProcessing?: boolean;
    };
  };
};

const ListedCustomersTable: FunctionComponent<Props> = ({
  selectedCustomerIds = [],
  onClickRow,
  onSelectCustomers,
  onUnselectCustomers,
  onSelectPage,
  quickActionDefinitions,
}: Props): JSX.Element | null => {
  const listedCustomersPage = useSelector((state) => {
    return state.customer?.listedCustomersPage;
  });

  const canSelectCustomers = useMemo(() => {
    return !!(onSelectCustomers && onUnselectCustomers);
  }, [onUnselectCustomers, onSelectCustomers]);

  const isGettingListedCustomersPage = useSelector(
    (state) => state.customer.isGettingListedCustomersPage
  );

  const accountHasAnyIntegration = useSelector((state: any) => {
    const integrations = state.users.myProfile.accounts[0].integrations;
    return !!(integrations?.xero || integrations?.quickbooks);
  });

  const columnCount = useMemo(() => {
    let count = 5;
    if (accountHasAnyIntegration) count++;
    if (canSelectCustomers) count++;
    if (quickActionDefinitions?.edit) count++;
    return count;
  }, [accountHasAnyIntegration, canSelectCustomers, quickActionDefinitions]);

  const allCustomerIds = useMemo(() => {
    if (!listedCustomersPage?.items) return [];
    return listedCustomersPage.items.map(
      (listedCustomer: ListedCustomer) => listedCustomer.customerId
    );
  }, [listedCustomersPage]);

  const isAllCustomersSelected = useMemo(() => {
    return (
      allCustomerIds.sort().join(',') === selectedCustomerIds.sort().join(',')
    );
  }, [allCustomerIds, selectedCustomerIds]);

  const renderTableHead = useMemo(() => {
    return (
      <thead>
        <tr>
          {canSelectCustomers && (
            <th className="row-selection-table-cell">
              <Checkbox
                isChecked={isAllCustomersSelected}
                onChange={() => {
                  isAllCustomersSelected
                    ? onUnselectCustomers && onUnselectCustomers(allCustomerIds)
                    : onSelectCustomers && onSelectCustomers(allCustomerIds);
                }}
              />
            </th>
          )}
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Address</th>
          <th className="align-center">Multiple contacts?</th>
          {accountHasAnyIntegration && <th className="align-center">Import</th>}
          {quickActionDefinitions?.edit && <th></th>}
        </tr>
      </thead>
    );
  }, [
    canSelectCustomers,
    isAllCustomersSelected,
    allCustomerIds,
    accountHasAnyIntegration,
    onUnselectCustomers,
    onSelectCustomers,
    quickActionDefinitions,
  ]);

  const renderTableRow = useCallback(
    (listedCustomer: ListedCustomer, index: number) => {
      const customerIsSelected = selectedCustomerIds.includes(
        listedCustomer.customerId
      );

      const phoneNumber =
        listedCustomer.primaryContactTelephoneNumber &&
        formatPhoneNumber(listedCustomer.primaryContactTelephoneNumber);

      const editQuickAction = quickActionDefinitions?.edit;

      return (
        <tr
          key={index}
          className={`${customerIsSelected ? 'selected' : ''} ${
            onClickRow ? 'clickable' : ''
          }`}
          onClick={() => onClickRow && onClickRow(listedCustomer.customerId)}
        >
          {canSelectCustomers && (
            <RowSelectionTableCell
              isSelected={customerIsSelected}
              onChange={() => {
                customerIsSelected
                  ? onUnselectCustomers &&
                    onUnselectCustomers([listedCustomer.customerId])
                  : onSelectCustomers &&
                    onSelectCustomers([listedCustomer.customerId]);
              }}
            />
          )}
          <td className="tr-start">
            <strong>{listedCustomer.customerName}</strong>
          </td>
          <td>{listedCustomer.primaryContactEmailAddress}</td>
          <td>{phoneNumber}</td>
          <td>{listedCustomer.address}</td>
          <td
            className={`has-multiple-contacts-table-cell ${
              accountHasAnyIntegration ? '' : 'tr-end'
            }`}
          >
            {listedCustomer.contactsCount > 1 && (
              <FontAwesomeIcon icon={faCheck} />
            )}
          </td>
          {accountHasAnyIntegration && (
            <td className="synced-with-integrations-table-cell tr-end">
              <div className="synced-with-integrations-container">
                {listedCustomer.syncedWithIntegrations.includes('Xero') && (
                  <img alt="Xero" src={XeroLogo} />
                )}
                {listedCustomer.syncedWithIntegrations.includes(
                  'Quickbooks'
                ) && <img alt="Quickbooks" src={QuickbooksLogo} />}
              </div>
            </td>
          )}
          {editQuickAction && (
            <QuickActionsTableCell
              recordId={listedCustomer.customerId}
              quickActions={[editQuickAction]}
            />
          )}
        </tr>
      );
    },
    [
      onClickRow,
      canSelectCustomers,
      selectedCustomerIds,
      onSelectCustomers,
      onUnselectCustomers,
    ]
  );

  const paginationControl = useMemo(() => {
    if (!listedCustomersPage) return;
    return (
      <PaginationControl
        pageNumber={listedCustomersPage.pageNumber}
        pageSize={listedCustomersPage.pageSize}
        totalItemCount={listedCustomersPage.totalItemCount}
        onSelectPage={onSelectPage}
      />
    );
  }, [listedCustomersPage, onSelectPage]);

  return (
    <div className="listed-customers-table">
      <div className="list-view-table-wrapper">
        <table className="list-view-table">
          {renderTableHead}
          <tbody>
            {isGettingListedCustomersPage && (
              <tr>
                <td colSpan={columnCount} className="tr-exclude">
                  <div className="loader-container">
                    <MiniLoader />
                  </div>
                </td>
              </tr>
            )}
            {!isGettingListedCustomersPage &&
              !listedCustomersPage?.totalItemCount &&
              !listedCustomersPage?.totalUnfilteredItemCount && (
                <tr>
                  <td colSpan={columnCount} className="tr-exclude">
                    <EmptyState
                      configName={'customers'}
                      arrowIndication={false}
                    />
                  </td>
                </tr>
              )}
            {!isGettingListedCustomersPage &&
              !listedCustomersPage?.totalItemCount &&
              !!listedCustomersPage?.totalUnfilteredItemCount && (
                <tr>
                  <td colSpan={columnCount} className="tr-exclude">
                    <div className="no-results-container">
                      <span>No results found</span>
                    </div>
                  </td>
                </tr>
              )}
            {!isGettingListedCustomersPage &&
              !!listedCustomersPage?.items?.length && (
                <React.Fragment>
                  {listedCustomersPage.items.map(renderTableRow)}
                </React.Fragment>
              )}
          </tbody>
        </table>
      </div>
      {!isGettingListedCustomersPage &&
        !!listedCustomersPage?.totalItemCount &&
        paginationControl}
    </div>
  );
};

export default ListedCustomersTable;
