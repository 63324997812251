import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Card from '@payaca/components/plCard/Card';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import Field, { FieldValidationState } from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import RichTextarea from '@payaca/components/plRichTextarea/RichTextarea';
import Table from '@payaca/components/plTable/Table';
import {
  PaymentSchedule,
  PaymentScheduleStage,
} from '@payaca/types/payment-schedule';
import UntitledIcon from '@payaca/untitled-icons';
import React, { FC, useMemo, useState } from 'react';

export type PaymentScheduleStageInput = Omit<
  PaymentScheduleStage,
  'percentageDue'
> & {
  percentageDue: string | number;
};

export type PaymentScheduleInput = Omit<PaymentSchedule, 'stages'> & {
  stages: PaymentScheduleStageInput[];
};

type Props = {
  paymentSchedule?: PaymentScheduleInput;
  onChange: (change: PaymentScheduleInput) => void;
  fieldValidationState?: FieldValidationState;
};

const CreatePaymentScheduleControl: FC<Props> = ({
  paymentSchedule,
  onChange,
  fieldValidationState,
}: Props) => {
  const [newStageIndex, setNewStageIndex] = React.useState<number>();
  const [showExplainerInput, setShowExplainerInput] = useState<boolean>(
    !!paymentSchedule?.explainer
  );

  const onRemovePaymentScheduleStage = (rowIndex: number) => {
    // remove row from payment schedule
    onChange({
      stages:
        paymentSchedule?.stages.filter(
          (_r: PaymentScheduleStageInput, i: number) => i !== rowIndex
        ) || [],
      explainer: paymentSchedule?.explainer,
    });
  };

  const onAddPaymentScheduleStage = () => {
    // add row to payment schedule
    onChange({
      stages: [
        ...(paymentSchedule?.stages || []),
        {
          description: '',
          percentageDue: 0,
        },
      ],
      explainer: paymentSchedule?.explainer,
    });
    setNewStageIndex(paymentSchedule?.stages.length || 0);
  };

  const onUpdatePaymentScheduleStage = (
    change: {
      [K in keyof PaymentScheduleStageInput]?: PaymentScheduleStageInput[K];
    },
    rowIndex: number
  ) => {
    // update a change on specific row
    onChange({
      stages:
        paymentSchedule?.stages.map(
          (r: PaymentScheduleStageInput, i: number) => {
            return rowIndex === i ? { ...r, ...change } : r;
          }
        ) || [],
      explainer: paymentSchedule?.explainer,
    });
  };

  const isPercentagesSumMoreThan100 = useMemo(
    () =>
      paymentSchedule &&
      paymentSchedule.stages.reduce(
        (acc, curr) => acc + +curr.percentageDue,
        0
      ) > 100,
    [paymentSchedule]
  );
  const isPercentageDueLessThanZero = (percentageDue: number) =>
    percentageDue < 0;
  const isPercentageDueEmpty = (percentageDue: number) => !percentageDue;

  return (
    <>
      <Field
        validationState={fieldValidationState}
        name="paymentScheduleStages"
      >
        <Field.Label>Payment Schedule</Field.Label>
        <Card>
          <Table<PaymentScheduleStageInput>
            data={paymentSchedule?.stages || []}
            hideEmptyState
            footerRow={
              <>
                <Button
                  variant={EBtnVariant.Link}
                  onClick={onAddPaymentScheduleStage}
                  className="focus:ring-0"
                >
                  <UntitledIcon name="plus.3" className="2-4 h-4" />
                  Add stage
                </Button>
              </>
            }
          >
            <Table.Column<PaymentScheduleStage, 'description'>
              className="w-full"
              field="description"
              header="Stage"
              headerClassName="uppercase"
              render={(field, _row, rowIndex) => {
                return (
                  <Field
                    validationState={
                      !field && newStageIndex !== rowIndex
                        ? {
                            isValid: false,
                          }
                        : undefined
                    }
                  >
                    <Input
                      value={field}
                      onChange={(value) =>
                        onUpdatePaymentScheduleStage(
                          { description: value },
                          rowIndex
                        )
                      }
                    />
                  </Field>
                );
              }}
            />
            <Table.Column<PaymentScheduleStageInput, 'percentageDue'>
              field="percentageDue"
              header="Percentage"
              headerClassName="uppercase"
              render={(field, _row, rowIndex) => {
                return (
                  <Field
                    validationState={
                      isPercentagesSumMoreThan100 ||
                      isPercentageDueLessThanZero(+field) ||
                      (isPercentageDueEmpty(+field) &&
                        newStageIndex !== rowIndex)
                        ? {
                            isValid: false,
                          }
                        : undefined
                    }
                  >
                    <Input
                      className="w-[100px]"
                      value={field ? `${field}` : ''}
                      onChange={(value) => {
                        onUpdatePaymentScheduleStage(
                          { percentageDue: value },
                          rowIndex
                        );
                      }}
                      type="number"
                    />
                  </Field>
                );
              }}
            />
            <Table.Column<PaymentScheduleStageInput, 'isDeposit'>
              field="isDeposit"
              header="Deposit?"
              headerClassName="uppercase"
              render={(field, _row, rowIndex) => {
                // deposit checkbox only shown on first row - only one and first payment
                if (rowIndex === 0) {
                  return (
                    <Checkbox
                      checked={!!field}
                      onChange={() =>
                        onUpdatePaymentScheduleStage(
                          { isDeposit: !field },
                          rowIndex
                        )
                      }
                    />
                  );
                }
              }}
            />
            <Table.Column
              field="remove"
              header="Remove"
              className="p-0"
              headerClassName="uppercase"
              hiddenHeader
              render={(_field, _row, rowIndex) => {
                return (
                  <Button
                    variant={EBtnVariant.Link}
                    onClick={() => onRemovePaymentScheduleStage(rowIndex)}
                  >
                    Remove
                  </Button>
                );
              }}
            />
          </Table>
        </Card>
      </Field>
      <Field name="paymentScheduleExplainer">
        <div className="mt-4">
          {!showExplainerInput && (
            <Button
              variant={EBtnVariant.Link}
              onClick={() => setShowExplainerInput(true)}
              className="focus:ring-0"
            >
              <UntitledIcon name="plus.3" className="2-4 h-4" />
              Add explainer
            </Button>
          )}
          {showExplainerInput && (
            <>
              <Field.Label>Payment Schedule explainer text</Field.Label>
              <RichTextarea
                value={paymentSchedule?.explainer || ''}
                onChange={(value) =>
                  onChange({
                    ...paymentSchedule,
                    stages: paymentSchedule?.stages || [],
                    explainer: value,
                  })
                }
              />
            </>
          )}
        </div>
      </Field>
    </>
  );
};

export default CreatePaymentScheduleControl;
