import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import projectKeys from '@/api/queries/project/keyFactory';
import { graphql } from '@/gql';
import {
  GetMaterialsListAuditLogsInput,
  GetProjectMaterialsListAuditLogsQuery,
  PaginationInput,
} from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';

const GetProjectMaterialsListAuditLogs = graphql(`
  query GetProjectMaterialsListAuditLogs(
    $projectId: Int!
    $pagination: PaginationInput!
    $input: GetMaterialsListAuditLogsInput
  ) {
    project(id: $projectId) {
      materialsList {
        auditLogs(pagination: $pagination, input: $input) {
          totalCount
          limit
          offset
          items {
            id
            actingUser {
              fullName
              firstName
              lastName
              email
              colour
              avatarUrl
            }
            material {
              name
              internalId
            }
            quantityChange
            timestamp
          }
        }
      }
    }
  }
`);

export type MaterialsListAuditLogsPage = NonNullable<
  GetProjectMaterialsListAuditLogsQuery['project']['materialsList']
>['auditLogs'];

export type MaterialsListAuditLog =
  NonNullable<MaterialsListAuditLogsPage>['items'][0];

const useGetProjectMaterialsListAuditLogs = (
  projectId: number,
  input?: GetMaterialsListAuditLogsInput,
  pagination?: PaginationInput
) => {
  const { data, ...rest } = useQuery({
    queryKey: projectKeys.materialsListAuditLogs(projectId, input, pagination),
    queryFn: () => {
      return gqlClient.request(GetProjectMaterialsListAuditLogs, {
        projectId: projectId,
        input,
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
      });
    },
  });

  return {
    auditLogs: data?.project.materialsList?.auditLogs,
    data,
    ...rest,
  };
};

export default useGetProjectMaterialsListAuditLogs;
