import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnShapeVariant,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import UntitledIcon from '@payaca/untitled-icons';
import clsx from 'clsx';
import { getMonth } from 'date-fns';
import { FC, useState } from 'react';

import './FestiveEasterEgg.sass';

export const FestiveEasterEggControl: FC = () => {
  const [isSnowing, setIsSnowing] = useState(false);
  const currentMonthIndex = getMonth(new Date());

  const shouldShow = currentMonthIndex === 12;

  if (!shouldShow) {
    return <></>;
  }

  return (
    <div
      className={`festive-easter-egg-container ${
        isSnowing ? ' snow-active' : ''
      }`}
    >
      <Button
        onClick={() => setIsSnowing(!isSnowing)}
        variant={EBtnVariant.Ghost}
        shape={EBtnShapeVariant.PILL}
        colour={EBtnColour.White}
        className={clsx('h-[40px] w-[40px] p-0', isSnowing && 'bg-gray-100')}
      >
        <UntitledIcon name="snowflake-01.3" className="h-6 w-6 text-blue-900" />
      </Button>
      {!isSnowing && (
        <div
          className={'festive-tooltip'}
        >{`Season's greetings from Payaca!`}</div>
      )}
      {isSnowing && (
        <div className="snowflakes" aria-hidden="true">
          {Array(30)
            .fill(0)
            .map((_, i) => (
              <SnowflakeEl key={i} />
            ))}
        </div>
      )}
    </div>
  );
};

const SnowflakeEl = () => (
  <div className="snowflake">
    <div className="inner">❅</div>
  </div>
);
