import { useCallback, useEffect, useMemo, useState } from 'react';
import { TickSquare } from 'react-iconly';
import { IntercomAPI } from 'react-intercom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { SendSimpleCustomEmailControl } from '@/ui/components/sendSimpleCustomEmailControl/SendSimpleCustomEmailControl';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import {
  InputColourVariant,
  InputStyleVariant,
} from '@payaca/components/inputWrapper/InputWrapper';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';

import * as customerActions from '@payaca/store/customer/customerActions';
import * as dealActions from '@payaca/store/deals/dealsActions';
import * as documentActions from '@payaca/store/documents/documentActions';
import * as formActions from '@payaca/store/forms/formsActions';

import { getUrlSearchParam } from '@payaca/helpers/urlHelper';

import { getCustomer, getDeal } from '@/utils/stateAccessors';

import { FormCategoryType } from '@payaca/types/formTypes';

import { useSelector } from '@/api/state';
import './FormPage.sass';

const optionGroups = [
  { name: FormCategoryType.GENERAL, id: 1 },
  { name: FormCategoryType.HEATING, id: 2 },
  { name: FormCategoryType.ELECTRICAL, id: 3 },
];
const SEND_FORM_EMAIL_TEMPLATE = `
Hi,

Here is a form for you to complete. Please work through all the questions.

[button]

When you're done, there is a button you can press to send it back to us.

Any problems, feel free to get in touch directly.

Thanks,
[company_name]
`;

const FormCreatePage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [dealId, setDealId] = useState(0);
  const [selectedFormId, setSelectedFormId] = useState(2); // default initial job report
  const [sendForm, setSendForm] = useState(false);
  const [formRecipientData, setFormRecipientData] = useState<any>();
  const [canSendForm, setCanSendForm] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);

  const isCreatingDocument = useSelector(
    (state) => state.documents.isCreatingDocument
  );
  const deal = useSelector((state) => getDeal(state, dealId));
  const customer = useSelector((state) =>
    deal?.customerId ? getCustomer(state, deal.customerId) : undefined
  );
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const isFromMobile = useMemo(
    () => !!getUrlSearchParam(location.search, 'oneTimeLoginToken'),
    [location]
  );

  useEffect(() => {
    // get available forms
    dispatch(formActions.requestGetAvailableForms());
  }, []);

  const availableForms = useSelector((state) => state.forms.availableForms);

  const regionalFormOptions = useMemo(
    () =>
      availableForms
        .filter((form) => !form.archivedAt)
        .filter((form: any) => form?.regions.includes(account.region))
        .filter((form: any) => {
          if (
            import.meta.env.VITE_APP_ENV === 'development' ||
            (import.meta.env.VITE_ENV === 'staging' && account.id === 1020) // forms@payaca.com
          ) {
            return true;
          }
          return form?.accounts?.length
            ? form?.accounts?.includes(account.id)
            : true;
        })
        .map(({ label, value, category }) => {
          const optionGroup = optionGroups.find(
            (optionGroup) => optionGroup.name === category
          );
          return {
            label,
            value,
            optionGroupId: optionGroup ? optionGroup.id : 1,
          };
        }),
    [account, availableForms]
  );

  useEffect(() => {
    const dealId = getUrlSearchParam(location.search, 'dealId');
    if (!dealId) {
      history.push('/dashboard');
    }
    setDealId(Number(dealId));
  }, [location, history]);
  useEffect(() => {
    IntercomAPI('update', {
      hide_default_launcher: true,
    });

    dispatch(documentActions.clearDocuments());
    if (dealId) {
      dispatch(dealActions.requestGetDeal(dealId));
    }
  }, [dealId]);
  useEffect(() => {
    if (deal?.customerId) {
      dispatch(
        customerActions.requestGetAndSetCurrentCustomer(deal.customerId)
      );
    }
  }, [deal, dealId]);

  const handleFormCreated = useCallback(
    (formInstance: any) => {
      if (sendForm) {
        dispatch(
          formActions.requestSendForm(
            formInstance.id,
            formRecipientData.sendToEmail,
            formRecipientData.emailBody,
            formRecipientData.sendACopy
          )
        );
        return setIsFormSent(true);
      }
      history.push(
        `/forms/${formInstance.previewToken}/0?dealId=${dealId}${
          isFromMobile ? '&isFromMobile=true' : ''
        }`
      );
    },
    [history, dealId, isFromMobile, sendForm, formRecipientData]
  );

  const handleCreateOrSendForm = useCallback(() => {
    dispatch(
      documentActions.requestCreateDocument(
        selectedFormId,
        dealId,
        sendForm,
        handleFormCreated
      )
    );
  }, [selectedFormId, dealId, handleFormCreated, sendForm]);

  const selectedForm = availableForms.find(
    (form) => form.value === selectedFormId
  );

  return (
    <ResponsiveViewWrapper
      className={'form-page-container filled'}
      downBreakpointSm={900}
    >
      <div className={'form-page-wrapper'}>
        <div className={'form-page-header'}>
          <h3>Forms</h3>
          <div className={'company-brand'}>
            <img className="logo" src={account.logoUrl} />{' '}
            <span>{account.companyName}</span>
          </div>
        </div>
        <div className={'form-body'}>
          <div className={'form-page-section-container'}>
            <div className={'form-page-section-content'}>
              <ContentPanel className={'form-page-create-container'}>
                <div>
                  {isFormSent ? (
                    <div className={'form-sent-container'}>
                      <TickSquare />
                      <h4>Your form has been sent!</h4>
                      <p>{`We'll send you an e-mail when it has been completed. In the meantime, you can review how it's progressing by checking your Project.`}</p>
                      <Button
                        styleVariant={ButtonStyleVariant.OUTSIZE}
                        colourVariant={ButtonColourVariant.PRIMARY}
                        onClick={() => history.push(`/deals/${dealId}`)}
                      >
                        Return to Deal
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div className={'form-element'}>
                        <h4>Which form would you like to complete:</h4>
                        <DropdownField
                          value={selectedFormId}
                          name="selectedFormId"
                          options={regionalFormOptions}
                          onChange={({ selectedFormId }: any) => {
                            setSelectedFormId(selectedFormId);
                            const selectedForm = availableForms.find(
                              (form) => form.value === selectedFormId
                            );
                            if (selectedForm?.type === 'pdf') {
                              setSendForm(false);
                            }
                          }}
                          styleVariant={InputStyleVariant.OUTSIZE}
                          colourVariant={InputColourVariant.SECONDARY}
                          hasBoxShadow={false}
                          optionGroups={optionGroups}
                        />
                      </div>
                      {selectedForm?.type === 'legacy' && (
                        <div className={'form-element'}>
                          <h4>How will this form be completed?</h4>
                          <div className={'form-send-config-container'}>
                            <div className={'form-radio-options-container'}>
                              <div
                                className={
                                  'form-radio-option ' +
                                  (!sendForm ? 'selected' : '')
                                }
                                onClick={() => setSendForm(false)}
                              >
                                I will complete this form
                              </div>
                              <div
                                className={
                                  'form-radio-option ' +
                                  (sendForm ? 'selected' : '')
                                }
                                onClick={() => {
                                  const selectedForm = availableForms.find(
                                    (form) => form.value === selectedFormId
                                  );
                                  if (selectedForm?.type === 'legacy') {
                                    setSendForm(true);
                                  }
                                }}
                              >
                                Send to someone else
                              </div>
                            </div>
                            <div
                              className={
                                'form-send-config-control ' +
                                (sendForm ? '' : 'hidden')
                              }
                            >
                              <SendSimpleCustomEmailControl
                                customer={customer}
                                allowCustomEmailTemplate={true}
                                requiredEmailBodyVariables={['button']}
                                initialSubject={`${account.companyName} has sent you a form`}
                                initialEmailBody={SEND_FORM_EMAIL_TEMPLATE.replace(
                                  '[company_name]',
                                  account.companyName
                                ).trim()}
                                onChange={(data) => setFormRecipientData(data)}
                                onValidityChange={(isValid: boolean) =>
                                  setCanSendForm(isValid)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </ContentPanel>
            </div>
          </div>
          {!isFormSent && (
            <div className={'form-navigation-options'}>
              <Button
                isProcessing={isCreatingDocument}
                onClick={handleCreateOrSendForm}
                styleVariant={ButtonStyleVariant.OUTSIZE}
                isDisabled={sendForm && !canSendForm}
              >
                {sendForm ? 'Send Form' : 'Create Form'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </ResponsiveViewWrapper>
  );
};

export default FormCreatePage;
