import { FC, useMemo } from 'react';

import Select, {
  OptionGroup,
  SelectOption,
} from '@payaca/components/plSelect/Select';
import { Address, PartialAddress } from '@payaca/types/locationTypes';
import { AddressLookupOrInput } from '../addressLookupOrInput/AddressLookupOrInput';

export const AddressSelectExistingOrInputNew: FC<{
  addressOptions: SelectOption<number, PartialAddress>[];
  addressOptionGroups?: OptionGroup[];
  onChange?: (value: {
    existingAddressId?: Address['id'];
    newAddress?: PartialAddress;
  }) => void;
  value: {
    existingAddressId?: Address['id'];
    newAddress?: PartialAddress;
  };
}> = ({ addressOptions, addressOptionGroups, onChange, value }) => {
  const options: SelectOption<Address['id'] | string>[] = useMemo(() => {
    return [
      ...addressOptions,
      {
        value: 'add-new',
        label: 'Add a different address',
        groupId: '',
      },
    ];
  }, [addressOptions]);

  if (value.newAddress) {
    return (
      <AddressLookupOrInput
        address={value.newAddress}
        onChange={(v) => {
          onChange?.({
            newAddress: { ...value.newAddress, ...v },
          });
        }}
      />
    );
  }

  return (
    <Select
      value={value.existingAddressId}
      options={options}
      optionGroups={addressOptionGroups}
      onChange={(value) => {
        if (value === 'add-new') {
          onChange?.({
            existingAddressId: undefined,
            newAddress: {},
          });
        } else {
          onChange?.({ existingAddressId: value as number });
        }
      }}
    />
  );
};
