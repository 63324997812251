import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useCallback, useEffect, useRef, useState } from 'react';
import Intercom from 'react-intercom';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router';

import MaintenancePage from '@/ui/pages/maintenancePage/MaintenancePage';
import Splash from '@/ui/pages/splashPage/SplashPage';

import PrivateRouting from '@/routes/PrivateRouting';
import PublicRouting from '@/routes/PublicRouting';

import history from '@/services/history';

import { actions as appActions, selectors as appSelectors } from '@/api/app';
import FileUploadContextProvider from '@/context/FileUploadContext';
import { DynamicFeedbackContextProvider } from '@payaca/components/context/DynamicFeedbackContext';
import ToastContextProvider from '@payaca/components/plToast/ToastContext';
import { Helmet } from 'react-helmet';
import NotificationProvider from './ui/components/contextProviders/NotificationContextProvider';

const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'Inter',
  },
  palette: {
    type: 'light',
    primary: {
      main: '#263e59',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
      containedSecondary: {
        color: '#fff',
      },
    },
  },
  zIndex: {
    // Copy packages/styles/tailwind.config.js
    drawer: 102,
    modal: 108,
    tooltip: 115,
  },
});

const titleEnv =
  import.meta.env.VITE_APP_ENV === 'local'
    ? ' local'
    : import.meta.env.VITE_APP_ENV === 'development'
      ? ' dev'
      : import.meta.env.VITE_APP_ENV === 'staging'
        ? ' staging'
        : '';

// Create a client
const queryClient = new QueryClient();

const App = () => {
  const dispatch = useDispatch();
  const loaded = useSelector((state) => appSelectors.isLoaded(state.app));
  const loggedIn = useSelector((state) => appSelectors.isLoggedIn(state.app));

  const [lastUnfocusAt, setLastUnfocusAt] = useState(0);

  const lastUnfocusAtRef = useRef(lastUnfocusAt);

  const setLastUnfocusAtRef = (time) => {
    lastUnfocusAtRef.current = time;
    setLastUnfocusAt(time);
  };

  useEffect(() => {
    dispatch(appActions.start());

    setLastUnfocusAtRef(new Date().getTime());
    // Listeners to reload page on focus if page hasnt been focused on for 24 hours
    window.addEventListener('blur', handleBlur); // set timer
    window.addEventListener('focus', handleFocus); // check timer - reload if last click was > day ago
    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  // TODO: Remove after redirect has been widely adopted
  useEffect(() => {
    if (window.location.href.includes('new.payaca')) {
      window.location.href = window.location.href.replace(
        'new.payaca',
        'web.payaca'
      );
      return;
    }
    if (window.location.href.includes('new-ui.payaca')) {
      window.location.href = window.location.href.replace(
        'new-ui.payaca',
        'staging.webapp.payaca'
      );
      return;
    }
  }, []);

  const handleBlur = () => setLastUnfocusAtRef(new Date().getTime());

  const handleFocus = () => {
    if (
      new Date().getTime() - lastUnfocusAtRef.current >=
      1000 * 60 * 60 * 24
    ) {
      // reload page if page hasnt been focused on for 24 hours
      window.location.replace(window.location.href);
    }
  };

  const currentMaintenanceSchedule = useSelector((state) => {
    return state.app.maintenanceSchedule?.current;
  });

  const Maintenance = useCallback(
    ({ children }) =>
      currentMaintenanceSchedule ? <Switch>{children}</Switch> : null,
    [currentMaintenanceSchedule]
  );

  const Loading = useCallback(
    ({ children }) => (!loaded ? <Switch>{children}</Switch> : null),
    [loaded]
  );

  const Unauthorized = useCallback(
    ({ children }) =>
      loaded && !loggedIn && !currentMaintenanceSchedule ? (
        <Switch>{children}</Switch>
      ) : null,
    [currentMaintenanceSchedule, loaded, loggedIn]
  );

  const Authorized = useCallback(
    ({ children }) =>
      loaded && loggedIn && !currentMaintenanceSchedule ? children : null,
    [currentMaintenanceSchedule, loaded, loggedIn]
  );

  return (
    <>
      <DynamicFeedbackContextProvider>
        <QueryClientProvider client={queryClient}>
          <ToastContextProvider>
            <NotificationProvider>
              <Helmet
                defaultTitle={`Payaca${titleEnv}`}
                titleTemplate={`Payaca${titleEnv} | %s`}
              />
              <Router history={history}>
                <MuiThemeProvider theme={theme}>
                  <div className="App">
                    <Route
                      exact
                      path="/redirectToMobile"
                      render={() => {
                        window.location = 'Payaca://';
                      }}
                    />

                    <Loading>
                      <Route path="*" component={Splash} />
                    </Loading>
                    <Maintenance>
                      <Route path="*" component={MaintenancePage} />
                    </Maintenance>
                    <Unauthorized>
                      <Route path="/" component={PublicRouting} />
                    </Unauthorized>
                    <Authorized>
                      <FileUploadContextProvider>
                        <Route path="/" component={PrivateRouting} />
                      </FileUploadContextProvider>
                    </Authorized>
                    {INTERCOM_APP_ID && <Intercom appID={INTERCOM_APP_ID} />}
                  </div>
                </MuiThemeProvider>
              </Router>
            </NotificationProvider>
          </ToastContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </DynamicFeedbackContextProvider>
    </>
  );
};

export default App;
