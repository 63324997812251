import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  ActionType,
  PersistTaskRequestData,
  SagaConfig,
  UpdateTaskCompletionRequestData,
} from './tasksTypes';

import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import {
  clearTasks,
  deleteTaskFailure,
  deleteTaskSuccess,
  getTaskFailure,
  getTasksForDealFailure,
  getTasksForDealSuccess,
  getTasksForScheduledEventFailure,
  getTasksForScheduledEventSuccess,
  getTaskSuccess,
  persistTaskFailure,
  persistTaskSuccess,
  updateTaskCompletionFailure,
  updateTaskCompletionSuccess,
} from './tasksActions';

const tagsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  function* handlePersistTask(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_TASK,
      {
        persistTaskRequestData: PersistTaskRequestData;
        callback?: (taskId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(persistTask, action.payload.persistTaskRequestData),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(persistTaskFailure(new Error('Persist task timed out.')));
      } else {
        yield put(persistTaskSuccess());
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(persistTaskFailure(error as Error));
    }
  }

  const persistTask = async (
    persistTaskRequestData: PersistTaskRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/tasks`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
      body: JSON.stringify(persistTaskRequestData),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Persist task failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleDeleteTask(
    action: PayloadAction<
      ActionType.REQUEST_DELETE_TASK,
      {
        taskId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(deleteTask, action.payload.taskId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(deleteTaskFailure(new Error('Delete task timed out.')));
      } else {
        yield put(deleteTaskSuccess(action.payload.taskId));
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(deleteTaskFailure(error as Error));
    }
  }

  const deleteTask = async (taskId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/tasks/${taskId}`, {
      method: 'DELETE',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `Add new task to entity failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleUpdateTaskCompletion(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_TASK_COMPLETION,
      {
        updateTaskCompletionRequestData: UpdateTaskCompletionRequestData;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          updateTaskCompletion,
          action.payload.updateTaskCompletionRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          updateTaskCompletionFailure(
            new Error('Update task completion timed out.')
          )
        );
      } else {
        yield put(updateTaskCompletionSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(updateTaskCompletionFailure(error as Error));
    }
  }

  const updateTaskCompletion = async (
    updateTaskCompletionRequestData: UpdateTaskCompletionRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/tasks/${updateTaskCompletionRequestData.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
          'X-Native-App': `${isNativeApp}`,
        },
        body: JSON.stringify(updateTaskCompletionRequestData),
      }
    ).then((response) => {
      if (response.ok) {
        return response;
      } else {
        throw new Error(
          `Update task completion failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetTasksForDeal(
    action: PayloadAction<
      ActionType.REQUEST_GET_TASKS_FOR_DEAL,
      {
        dealId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getTasksForDeal, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getTasksForDeal request timed out');
      }

      yield put(getTasksForDealSuccess(response));
    } catch (error: any) {
      yield put(getTasksForDealFailure());
    }
  }

  const getTasksForDeal = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/tasks/deal/${dealId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get tasks for Project failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetTask(
    action: PayloadAction<
      ActionType.REQUEST_GET_TASK,
      {
        taskId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getTask, action.payload.taskId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get task timed out.';
        yield put(
          getTaskFailure(action.payload.taskId, new Error(errorMessage))
        );
      } else {
        yield put(getTaskSuccess(response.id, response));
      }
    } catch (error: any) {
      yield put(getTaskFailure(action.payload.taskId, error));
    }
  }

  const getTask = async (taskId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/tasks/${taskId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get task failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetTasksForScheduledEvent(
    action: PayloadAction<
      ActionType.REQUEST_GET_TASKS_FOR_SCHEDULED_EVENT,
      {
        scheduledEventId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getTasksForScheduledEvent,
          action.payload.scheduledEventId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getTasksForScheduledEvent request timed out');
      }

      yield put(getTasksForScheduledEventSuccess(response));
    } catch (error: any) {
      yield put(getTasksForScheduledEventFailure());
    }
  }

  const getTasksForScheduledEvent = async (scheduledEventId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/tasks/scheduled_event/${scheduledEventId}`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
          'X-Native-App': `${isNativeApp}`,
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get tasks for scheduled event failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleLogout() {
    yield put(clearTasks());
  }

  return function* () {
    yield takeEvery(ActionType.REQUEST_PERSIST_TASK, handlePersistTask);
    yield takeLatest(ActionType.REQUEST_DELETE_TASK, handleDeleteTask);
    yield takeEvery(
      ActionType.REQUEST_UPDATE_TASK_COMPLETION,
      handleUpdateTaskCompletion
    );
    yield takeLatest(
      ActionType.REQUEST_GET_TASKS_FOR_DEAL,
      handleGetTasksForDeal
    );
    yield takeLatest(
      ActionType.REQUEST_GET_TASKS_FOR_SCHEDULED_EVENT,
      handleGetTasksForScheduledEvent
    );
    yield takeEvery(ActionType.REQUEST_GET_TASK, handleGetTask);

    yield takeEvery('auth.logout', handleLogout);
  };
};

export default tagsSagaCreator;
