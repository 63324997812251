import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import { requestUpdateDeal } from '@payaca/store/deals/dealsActions';
import { useDeal } from '@payaca/store/hooks/appState';

interface Props {
  dealId: number;
}

export const HideVatBreakdownControl: FC<Props> = ({ dealId }: Props) => {
  const dispatch = useDispatch();

  const [hideVatBreakdown, setHideVatBreakdown] = useState(false);

  const deal = useDeal(dealId);

  useEffect(() => {
    if (deal) {
      setHideVatBreakdown(!!deal.hideVatBreakdown);
    }
  }, [deal?.id]);

  const handlePersist = useCallback((hideVatBreakdown: boolean) => {
    dispatch(requestUpdateDeal(dealId, { hideVatBreakdown }));
  }, []);

  if (!deal) return null;

  return (
    <div className="hide-vat-breakdown-control">
      <CheckboxField
        name="hideVatBreakdown"
        value={hideVatBreakdown}
        label={`Hide tax breakdown`}
        onChange={(value) => {
          setHideVatBreakdown(value.hideVatBreakdown);
          handlePersist(value.hideVatBreakdown);
        }}
      />
    </div>
  );
};
