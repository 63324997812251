import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UpdateCustomFieldValuesForProjectMutation as _UpdateCustomFieldValuesForProjectMutation,
  UpdateCustomFieldValuesForProjectInput,
} from '@/gql/graphql';

const UpdateCustomFieldValuesForProjectMutation = graphql(`
  mutation UpdateCustomFieldValuesForProject(
    $input: UpdateCustomFieldValuesForProjectInput!
  ) {
    updateCustomFieldValuesForProject(input: $input) {
      customFields {
        field {
          identifier
          isRequired
          label
          type
          options
        }
        value
      }
    }
  }
`);

const useUpdateCustomFieldValuesForProject = (
  options?: UseMutationOptions<
    _UpdateCustomFieldValuesForProjectMutation,
    unknown,
    UpdateCustomFieldValuesForProjectInput
  >
) => {
  return useMutation({
    mutationFn: (variables) => {
      return gqlClient.request(UpdateCustomFieldValuesForProjectMutation, {
        input: variables,
      });
    },
    ...options,
  });
};

export default useUpdateCustomFieldValuesForProject;
