import {
  getInvoiceLinesByInvoiceId,
  getJobLineItemsByInvoiceId,
} from '@/utils/stateAccessors';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import * as invoiceActions from '@payaca/store/invoices/invoicesActions';
import { UpdateInvoiceRequestData } from '@payaca/store/invoices/invoicesTypes';
import { Deal } from '@payaca/types/dealTypes';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import JobCustomerControl from '../editJob/JobCustomerControl';

import { useSelector } from '@/api/state';
import { useAccount } from '@/utils/storeHooks';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import InvoiceBody from '@payaca/components/invoiceBody/InvoiceBody';
import TextareaFieldFormatter from '@payaca/components/textareaField/TextareaFieldFormatter';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { useDeal, useInvoice } from '@payaca/store/hooks/appState';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Invoice, InvoiceUploadRoles } from '@payaca/types/invoiceTypes';
import AttachmentUploadsControl from '../attachmentUploadsControl/AttachmentUploadsControl';
import EditInvoiceLinesControl from '../editInvoiceLinesControl/EditInvoiceLinesControl';
import EditJobSection from '../editJob/EditJobSection';
import ReferenceControl from '../referenceControl/ReferenceControl';
import './EditInvoiceControl.sass';

type Props = {
  invoiceId: number;
};

const EditInvoiceControl: FC<Props> = ({
  invoiceId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isSaveRequired, setIsSaveRequired] = useState(false);
  const [editInvoiceLines, setEditInvoiceLines] = useState(false);

  const invoice: Invoice | undefined = useInvoice(invoiceId);

  const deal: Deal | undefined = useDeal(invoice?.dealId);

  const invoiceLines = useSelector((state) => {
    return getInvoiceLinesByInvoiceId(state, invoiceId);
  });

  const account = useAccount();

  const jobLineItems = useSelector((state) => {
    return getJobLineItemsByInvoiceId(state, invoiceId);
  });

  const handleUpdateInvoice = useCallback(
    (requestData: UpdateInvoiceRequestData) => {
      dispatch(
        invoiceActions.requestUpdateInvoice(requestData, () =>
          dispatch(invoiceActions.requestGetInvoice(invoiceId))
        )
      );
    },
    [invoiceId]
  );

  const initialFormState = useMemo(() => {
    if (!invoice) return {};
    return {
      invoiceId: invoice.id,
      customReference: invoice.customReference || invoice.reference.toString(),
      reference: invoice.reference,
      notes: invoice.notes,
      dueInDays: invoice.dueInDays,
      assignedToUserId: invoice.assignedToUserId,
      contactId: invoice.contactId,
      invoiceLines: invoiceLines || [],
    };
  }, [invoice?.id, invoiceLines?.length]);

  const renderFormContents = (
    isValid: boolean,
    formState: {
      [key: string]: any;
    },
    validationState: {
      [key: string]: FieldValidationResult;
    },
    touchedState: {
      [key: string]: boolean;
    },
    onFieldChange: (value: { [key: string]: any }) => void,
    onFieldTouch: (fieldName: string) => void
  ) => {
    if (isSaveRequired) {
      handleUpdateInvoice(formState as UpdateInvoiceRequestData);
      setIsSaveRequired(false);
    }
    return (
      <div className="edit-invoice-control-form-contents">
        <ReferenceControl
          reference={formState.reference}
          customReference={formState.customReference}
          referencedEntityName={'Invoice'}
          onChange={(value) => {
            onFieldChange(value);
            setIsSaveRequired(true);
          }}
        />
        <JobCustomerControl
          canSelectCustomer={false}
          customerId={deal?.customerId}
          jobContactId={formState.contactId || null}
          onSelectContact={(contactId: number) => {
            onFieldChange({ contactId: contactId });
            setIsSaveRequired(true);
          }}
        />
        <EditJobSection
          title={
            <div className="invoice-lines-title">
              <h2>Invoice lines</h2>
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => setEditInvoiceLines(!editInvoiceLines)}
              >
                {editInvoiceLines ? 'Back to summary' : 'Edit % due by line'}
              </Button>
            </div>
          }
        >
          {!editInvoiceLines && (
            <InvoiceBody
              invoiceLines={formState.invoiceLines || []}
              jobLineItems={jobLineItems || []}
              region={account.region}
              showZeroValueLines={true}
              showCancelledLines={true}
            />
          )}
          {editInvoiceLines && invoice?.dealId && (
            <EditInvoiceLinesControl
              dealId={invoice?.dealId}
              invoiceLines={formState.invoiceLines}
              jobLineItems={jobLineItems || []}
              onChange={(invoiceLines) => {
                onFieldChange({
                  invoiceLines,
                });
              }}
              onSaveRequired={() => setIsSaveRequired(true)}
            />
          )}
        </EditJobSection>
        <EditJobSection title={'Notes'}>
          <TextareaFieldFormatter
            name="notes"
            value={formState.notes}
            onChange={onFieldChange}
            placeholder={`Give yourself an edge over your competitors by adding additional information to your invoice.`}
            changeTimeoutMs={2000}
            onChangeTimeout={() => setIsSaveRequired(true)}
            onBlur={() => setIsSaveRequired(true)}
          />
        </EditJobSection>
      </div>
    );
  };

  if (!invoice) return null;

  return (
    <ResponsiveViewWrapper
      className="edit-invoice-control"
      downBreakpointSm={700}
      downBreakpointXs={450}
    >
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
      />
      <AttachmentUploadsControl
        entityType="invoice"
        entityId={invoiceId}
        prompt={
          'Attach images of completed work or documents relating to the works completed.'
        }
        attachmentUploadRole={InvoiceUploadRoles.ATTACHMENT}
        attachmentUploadIds={invoice.attachmentUploadIds}
        onPersistUploadSuccess={() =>
          dispatch(invoiceActions.requestGetInvoice(invoiceId))
        }
      />
    </ResponsiveViewWrapper>
  );
};
export default EditInvoiceControl;
