import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  SetCustomerBillingAddressMutation as _SetCustomerBillingAddressMutation,
  SetCustomerBillingAddressInput,
} from '@/gql/graphql';

const SetCustomerBillingAddressMutation = graphql(`
  mutation SetCustomerBillingAddress($input: SetCustomerBillingAddressInput!) {
    setCustomerBillingAddress(input: $input) {
      id
    }
  }
`);

const useSetCustomerBillingAddress = (
  options?: UseMutationOptions<
    _SetCustomerBillingAddressMutation,
    unknown,
    SetCustomerBillingAddressInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(SetCustomerBillingAddressMutation, { input });
    },
    ...options,
  });
};

export default useSetCustomerBillingAddress;
