import UserAvatar from '@payaca/components/userAvatar/UserAvatar';
import { FC } from 'react';

import { useAccountUsers } from '@/utils/storeHooks';
import Avatar, {
  AvatarBorderColourVariant,
  AvatarSizeVariant,
} from '@payaca/components/plAvatar/Avatar';
import AvatarGroup from '@payaca/components/plAvatarGroup/AvatarGroup';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import { User } from '@payaca/types/userTypes';

const UserAvatarSelectionControl: FC<{
  selectedUserIds: User['id'][];
  onChange: (selectedUserIds: User['id'][]) => void;
  sizeVariant?: AvatarSizeVariant;
  allowSelectNone?: boolean;
}> = ({ selectedUserIds, onChange, sizeVariant, allowSelectNone = false }) => {
  const accountUsers = useAccountUsers().filter((u) => !u.deactivatedAt);
  const isNoneSelected = selectedUserIds?.includes(-1);

  return (
    <AvatarGroup>
      {accountUsers.map((user) => {
        const isSelected = selectedUserIds.includes(user.id);

        return (
          <button
            className="m-0 cursor-pointer border-none bg-transparent bg-none p-0"
            key={user.id}
            onClick={() => {
              isSelected
                ? onChange(selectedUserIds.filter((id) => id != user.id))
                : onChange([...selectedUserIds, user.id]);
            }}
          >
            <UserAvatar
              sizeVariant={sizeVariant}
              user={{
                firstName: user.firstname,
                lastName: user.lastname,
                emailAddress: user.email,
                userColour: user.userColour,
                imgSrc: user.avatarUrl,
              }}
              enableUserInfoTooltip={true}
              borderColourVariant={
                isSelected
                  ? AvatarBorderColourVariant.BLUE
                  : AvatarBorderColourVariant.WHITE
              }
            />
          </button>
        );
      })}
      {allowSelectNone && (
        <button
          className="m-0 cursor-pointer border-none bg-transparent bg-none p-0"
          onClick={() => {
            isNoneSelected
              ? onChange(selectedUserIds.filter((id) => id != -1))
              : onChange([...selectedUserIds, -1]);
          }}
        >
          <Tooltip tooltipContent="Unassigned">
            <Avatar
              iconName="user-x-01"
              sizeVariant={sizeVariant}
              colour="#CCCCCC"
              borderColourVariant={
                isNoneSelected
                  ? AvatarBorderColourVariant.BLUE
                  : AvatarBorderColourVariant.WHITE
              }
            />
          </Tooltip>
        </button>
      )}
    </AvatarGroup>
  );
};

export default UserAvatarSelectionControl;
