import { PayacaPagesConfig } from '@payaca/types/payacaPagesTypes';
import { FC, PropsWithChildren, useMemo } from 'react';
import tinycolor from 'tinycolor2';
import CompanyLogo from '../companyLogo/CompanyLogo';
import './PayacaPages.sass';

export const PayacaPageRenderer: FC<PropsWithChildren<PayacaPagesConfig>> = ({
  isEmbedded,
  logoURL,
  color,
  cellPadding,
  cellSpacing,
  borderWidth,
  borderRadius,
  children,
}): JSX.Element => {
  const buttonTextColour = useMemo(
    () =>
      !isEmbedded
        ? '#fff'
        : tinycolor
            .mostReadable(color, ['#263e59', 'white'], {
              includeFallbackColors: true,
            })
            .toHexString(),
    [color]
  );

  const textColor = useMemo(
    () => (isEmbedded ? color : '#263e59'),
    [isEmbedded, color]
  );

  return (
    <>
      <style type={'text/css'}>{`
        .payaca-pages-output .form-section {
            margin-bottom: ${cellSpacing}px;
        }
        
        .payaca-pages-output .form-section label, .payaca-pages-output .form-section .form-helper, .payaca-pages-output .form-section .number-field {
            color: ${textColor};
        }
        
        .payaca-pages-output input, .payaca-pages-output textarea {
            padding: ${cellPadding}px;
            color: ${textColor};
            border: ${borderWidth}px solid ${textColor};
            border-radius: ${borderRadius}px;
        }
        
        .payaca-pages-output .radio-list li {
            color: ${textColor};
        }
        
        .payaca-pages-output .radio-list li label {
            color: ${textColor};
        }
        
        .payaca-pages-output .fake-radiobutton,
        .payaca-pages-output .fake-checkbox {
          border: ${borderWidth}px solid ${textColor};
        }
        .payaca-pages-output .fake-radiobutton:after,
        .payaca-pages-output .fake-checkbox:after {
          background: ${textColor};
        }
      
        .payaca-pages-output h3, .payaca-pages-output h4, .payaca-pages-output p, .payaca-pages-output input::placeholder, .payaca-pages-output textarea::placeholder { 
            color: ${textColor}
        }
        
        .payaca-pages-output button {
            color: ${buttonTextColour};
            background-color: ${textColor};
            padding: calc(${cellPadding}px + 8px);
            border-radius: ${borderRadius}px;
        }
        
        .payaca-pages-output-wrapper {
            background-color: ${color};
        }
      `}</style>
      <div className={'payaca-pages-output'}>
        {!isEmbedded ? (
          <div className={'payaca-pages-output-wrapper'}>
            <div className={'payaca-pages-output-card'}>
              <CompanyLogo logoUrl={logoURL} />
              {children}
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    </>
  );
};
