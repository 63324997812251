import {
  Account,
  AccountAccessInformation,
  AccountEmailTemplates,
} from '@payaca/types/accountTypes';
import { Nullable } from '@payaca/utilities/types';

import {
  AccountingIntegrationType,
  ConnectedAccountingIntegrationData,
} from '@payaca/types/accountingIntegrationTypes';
import { PublicScheduledEventReminderConfig } from '@payaca/types/scheduledEventReminderConfigTypes';
import { DeclareAsyncAction } from '../types';

export interface AccountState {
  accountAccessInformation: AccountAccessInformation | null;
  isGettingAccountAccessInformation: boolean;

  requiresUpdateBusinessSettingsPrompt: boolean;

  emailTemplates: AccountEmailTemplates | null;
  isGettingEmailTemplates: boolean;
  gettingEmailTemplatesError: string | null;

  isSendingDemoQuote: boolean;
  isDemoQuoteSentSuccessfully?: boolean;

  accountUsers: any;
  isGettingUsers: boolean;
}

export enum AccountActionTypes {
  REQUEST_GET_ACCOUNT_ACCESS_INFORMATION = 'account.requestGetAccountAccessInformation',
  GET_ACCOUNT_ACCESS_INFORMATION_SUCCESS = 'account.getAccountAccessInformationSuccess',
  GET_ACCOUNT_ACCESS_INFORMATION_FAILURE = 'account.getAccountAccessInformationFailure',
  CLEAR_ACCOUNT_ACCESS_INFORMATION = 'account.clearAccountAccessInformation',

  SET_REQUIRES_UPDATE_BUSINESS_SETTINGS_PROMPT = 'account.setRequiresUpdateBusinessSettingsPrompt',

  REQUEST_BOOK_DEMO = 'account.requestBookDemo',
  BOOK_DEMO_SUCCESS = 'account.bookDemoSuccess',
  BOOK_DEMO_FAILURE = 'account.bookDemoFailure',

  REQUEST_GET_EMAIL_TEMPLATES = 'account.requestGetEmailTemplates',
  REQUEST_UPDATE_EMAIL_TEMPLATES = 'account.requestUpdateEmailTemplates',
  GET_EMAIL_TEMPLATES_SUCCESS = 'account.getEmailTemplatesSuccess',
  GET_EMAIL_TEMPLATES_FAILURE = 'account.getEmailTemplatesFailure',
  CLEAR_EMAIL_TEMPLATES = 'account.clearEmailTemplates',
  STORE_EMAIL_TEMPLATES = 'account.storeEmailTemplates',

  REQUEST_GET_ACCOUNT_USERS = 'account.requestGetAccountUsers',
  GET_ACCOUNT_USERS_SUCCESS = 'account.getAccountUsersSuccess',
  GET_ACCOUNT_USERS_FAILURE = 'account.getAccountUsersFailure',

  ADD_OUTBOUND_EMAIL_ADDRESS_REQUEST = 'account.addOutboundEmailAddress:request',

  DELETE_OUTBOUND_EMAIL_ADDRESS_REQUEST = 'account.deleteOutboundEmailAddress:request',
  DELETE_OUTBOUND_EMAIL_DOMAIN_REQUEST = 'account.deleteOutboundEmailDomain:request',

  RESEND_OUTBOUND_EMAIL_ADDRESS_CONFIRMATION_EMAIL_REQUEST = 'account.resendOutboundEmailAddressConfirmationEmail:request',

  CHECK_OUTBOUND_EMAIL_ADDRESS_CONFIRMATION_REQUEST = 'account.checkOutboundEmailAddressConfirmaton:request',
  CHECK_OUTBOUND_EMAIL_DOMAIN_DKIM_VERIFICATION_REQUEST = 'account.checkOutboundEmailDomainDkimVerification:request',

  SET_DEFAULT_OUTBOUND_EMAIL_ADDRESS_REQUEST = 'account.setDefaultOutboundEmailAddress:request',
  SET_PRIMARY_PIPELINE_REQUEST = 'account.setPrimaryPipeline:request',

  GET_CONNECTED_ACCOUNTING_INTEGRATION_DATA_REQUEST = 'account.getConnectedAccountingIntegrationData:request',
  GET_SCHEDULED_EVENT_REMINDERS_CONFIG_REQUEST = 'account.getScheduledEventRemindersConfig:request',
  UPDATE_SCHEDULED_EVENT_REMINDERS_CONFIG_REQUEST = 'account.updateScheduledEventRemindersConfig:request',
}

export interface AccountSagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export type GetConnectedAccountingIntegrationData = DeclareAsyncAction<
  AccountActionTypes,
  {
    request: {
      type: AccountActionTypes.GET_CONNECTED_ACCOUNTING_INTEGRATION_DATA_REQUEST;
      payload: {
        integrationType: AccountingIntegrationType;
        callback?: (
          integrationData: ConnectedAccountingIntegrationData
        ) => void;
        onErrorCallback?: (err?: Error) => void;
      };
    };
  }
>;

export type GetScheduledEventRemindersConfig = DeclareAsyncAction<
  AccountActionTypes,
  {
    request: {
      type: AccountActionTypes.GET_SCHEDULED_EVENT_REMINDERS_CONFIG_REQUEST;
      payload: {
        callback?: (
          remindersConfig: PublicScheduledEventReminderConfig[]
        ) => void;
        onErrorCallback?: (err?: Error) => void;
      };
    };
  }
>;

export type UpdateScheduledEventRemindersConfigData = (Omit<
  PublicScheduledEventReminderConfig,
  'publicId'
> & {
  publicId?: PublicScheduledEventReminderConfig['publicId'];
})[];

export type UpdateScheduledEventRemindersConfig = DeclareAsyncAction<
  AccountActionTypes,
  {
    request: {
      type: AccountActionTypes.UPDATE_SCHEDULED_EVENT_REMINDERS_CONFIG_REQUEST;
      payload: {
        remindersConfig: UpdateScheduledEventRemindersConfigData;
        callback?: (
          remindersConfig: PublicScheduledEventReminderConfig[]
        ) => void;
        onErrorCallback?: (err?: Error) => void;
      };
    };
  }
>;

export type SetPrimaryPipeline = DeclareAsyncAction<
  AccountActionTypes,
  {
    request: {
      type: AccountActionTypes.SET_PRIMARY_PIPELINE_REQUEST;
      payload: {
        pipelineId: Nullable<Account['primaryPipelineId']>;
        callback?: () => void;
        onErrorCallback?: (err?: Error) => void;
      };
    };
  }
>;

export type AddOutboundEmailAddressAction = DeclareAsyncAction<
  AccountActionTypes,
  {
    request: {
      type: AccountActionTypes.ADD_OUTBOUND_EMAIL_ADDRESS_REQUEST;
      payload: {
        email: string;
        cb?: (err?: Error) => void;
      };
    };
  }
>;

export type DeleteOutboundEmailAddressAction = DeclareAsyncAction<
  AccountActionTypes,
  {
    request: {
      type: AccountActionTypes.DELETE_OUTBOUND_EMAIL_ADDRESS_REQUEST;
      payload: {
        email: string;
        cb?: (err?: Error) => void;
      };
    };
  }
>;

export type DeleteOutboundEmailDomainAction = DeclareAsyncAction<
  AccountActionTypes,
  {
    request: {
      type: AccountActionTypes.DELETE_OUTBOUND_EMAIL_DOMAIN_REQUEST;
      payload: {
        domain: string;
        cb?: (err?: Error) => void;
      };
    };
  }
>;

export type ResendOutboundEmailAddressConfirmationEmailAction =
  DeclareAsyncAction<
    AccountActionTypes,
    {
      request: {
        type: AccountActionTypes.RESEND_OUTBOUND_EMAIL_ADDRESS_CONFIRMATION_EMAIL_REQUEST;
        payload: {
          email: string;
          cb?: (err?: Error) => void;
        };
      };
    }
  >;

export type CheckOutboundEmailAddressConfirmation = DeclareAsyncAction<
  AccountActionTypes,
  {
    request: {
      type: AccountActionTypes.CHECK_OUTBOUND_EMAIL_ADDRESS_CONFIRMATION_REQUEST;
      payload: {
        email: string;
        cb?: (err?: Error) => void;
      };
    };
  }
>;

export type CheckOutboundEmailDomainDkimVerification = DeclareAsyncAction<
  AccountActionTypes,
  {
    request: {
      type: AccountActionTypes.CHECK_OUTBOUND_EMAIL_DOMAIN_DKIM_VERIFICATION_REQUEST;
      payload: {
        domain: string;
        cb?: (err?: Error) => void;
      };
    };
  }
>;

export type SetDefaultOutboundEmailAddressAction = DeclareAsyncAction<
  AccountActionTypes,
  {
    request: {
      type: AccountActionTypes.SET_DEFAULT_OUTBOUND_EMAIL_ADDRESS_REQUEST;
      payload: {
        purpose: string;
        email: string;
        cb?: (err?: Error) => void;
      };
    };
  }
>;
