import { takeLatest } from 'redux-saga/effects';

import {
  ActionType,
  AssignUserAction,
  SagaConfig,
} from './userAssignmentsTypes';

import { assignUser } from './userAssignmentsActions';

import { Req } from '@payaca/helpers/storeHelper';
import { handleAsyncAction } from '../utils';

const usersAssignmentsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  const handleAssignUser = handleAsyncAction<AssignUserAction>(
    assignUser,
    async (payload) => {
      if (payload.assigneeUserId) {
        const response = req.post('/user_assignments_entities', payload);
        return response;
      } else {
        const response = req.post(
          '/user_assignments_entities/unassign',
          payload
        );
        return response;
      }
    },
    (_, payload) => {
      payload.payload?.callback?.();
    }
  );

  function* handleAppLogout() {
    // yield put(clearDeals());
  }

  return function* () {
    yield takeLatest(ActionType.ASSIGN_USER_REQUEST, handleAssignUser);
  };
};

export default usersAssignmentsSagaCreator;
