import DateTimeField from '@payaca/components/dateTimeField/DateTimeField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import DatePeriodControl from '@payaca/components/datePeriodControl/DatePeriodControl';

import { actions as usersActions } from '@/api/users';
import { useAccountUsers } from '@/utils/storeHooks';
import { TimelogsPermissions } from '@payaca/permissions/timelogs/timelogs.permissions';
import { getTimelogTypes } from '@payaca/store/timelogs/timelogsActions';
import { GetListedTimelogsQueryParams } from '@payaca/types/listedTimelogTypes';
import { PublicTimelogType, TimelogAssignee } from '@payaca/types/timelogs';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import './TimelogFilters.sass';

type Props = {
  disableFilters?: ('periodStart' | 'periodEnd')[];
  appliedFilters: Pick<
    GetListedTimelogsQueryParams,
    'periodStart' | 'periodEnd' | 'assignees' | 'typePublicIds'
  >;
  onChange: (filters: Partial<GetListedTimelogsQueryParams>) => void;
};

const TimelogFilters: FC<Props> = ({
  disableFilters,
  appliedFilters,
  onChange,
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const [timelogTypes, setTimelogTypes] = useState<PublicTimelogType[]>([]);

  const accountUsers = useAccountUsers();

  useEffect(() => {
    dispatch(usersActions.getAccountUsers());
    dispatch(
      getTimelogTypes.request({
        callback: (tt) => {
          setTimelogTypes(tt);
        },
      })
    );
  }, []);

  const userAssigneeOptions = useMemo(() => {
    const options: {
      label: string;
      value: {
        publicId: TimelogAssignee['publicId'];
        type: TimelogAssignee['type'];
      };
    }[] = accountUsers
      .filter((x: any) => {
        return !x.deactivatedAt || x.inviteToken;
      })
      .map((accountUser: any) => {
        return {
          label: `${accountUser.firstname} ${accountUser.lastname}`,
          value: { publicId: accountUser.id.toString(), type: 'user' },
        };
      });

    return options;
  }, [accountUsers]);

  return (
    <div className="timelog-filters">
      {!disableFilters?.includes('periodStart') &&
        !disableFilters?.includes('periodEnd') && (
          <DatePeriodControl
            label="Date period"
            value={{
              periodStart: appliedFilters.periodStart,
              periodEnd: appliedFilters.periodEnd,
            }}
            onChange={onChange}
          />
        )}
      {!disableFilters?.includes('periodStart') &&
        disableFilters?.includes('periodEnd') && (
          <DateTimeField
            name="periodStart"
            value={appliedFilters.periodStart}
            onChange={onChange}
            label={'From'}
          />
        )}
      {!disableFilters?.includes('periodEnd') &&
        disableFilters?.includes('periodStart') && (
          <DateTimeField
            name="periodEnd"
            value={appliedFilters.periodEnd}
            onChange={onChange}
            label={'To'}
            placeholder={'Today'}
          />
        )}
      <PermissionGuard
        renderIfHasPermissions={[TimelogsPermissions.GET_TIMELOG_TYPES]}
      >
        <DropdownField
          name="typePublicIds"
          label="Type"
          multiple
          options={timelogTypes.map((timelogType) => {
            return {
              label: timelogType.type,
              value: timelogType.publicId,
            };
          })}
          value={appliedFilters.typePublicIds || []}
          onChange={onChange}
          displayCheckboxes={true}
          emptyText={'Any type'}
        />
      </PermissionGuard>
      <PermissionGuard
        renderIfHasPermissions={[TimelogsPermissions.GET_TIMELOGS]}
      >
        <DropdownField
          name="assignees"
          label="Assignee"
          multiple
          options={userAssigneeOptions}
          value={appliedFilters.assignees || []}
          onChange={onChange}
          displayCheckboxes={true}
          emptyText={'Any assignee'}
        />
      </PermissionGuard>
    </div>
  );
};

export default TimelogFilters;
