import CreateServicePlanDiscountCodeControl from '@/ui/components/createServicePlanDiscountCode/CreateServicePlanDiscountCodeControl';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

const NewDiscountCodePage: FC = () => {
  const history = useHistory();

  return (
    <>
      <Helmet title={'New Discount Code'} />

      <AuthenticatedPageWrapper
        className="service-plan-page"
        title={<h1>New Service Plan Discount Code</h1>}
        previousPageNavigationConfig={{
          route: `/service-plans/discount-codes`,
          navigationPromptName: 'Back',
        }}
      >
        <CreateServicePlanDiscountCodeControl
          onCreateSuccess={() => history.push(`/service-plans/discount-codes`)}
        />
      </AuthenticatedPageWrapper>
    </>
  );
};

export default NewDiscountCodePage;
