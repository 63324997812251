import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import Modal from '@payaca/components/plModal/Modal';
import { RecurringInterval } from '@payaca/types/subscriptionProductTypes';
import UntitledIcon from '@payaca/untitled-icons';
import useGetAvailableProducts from '../../../../api/queries/subscriptions/useGetAvailableProducts';
import { parseSubscriptionProduct } from '../../../pages/manageSubscriptionPage/components/SubscriptionProductsSelectionControl';
import UpdateSubscriptionControl from './UpdateSubscriptionControl';

type Props = {
  isOpen: boolean;
  recurringInterval?: RecurringInterval;
  stripeProductId: string;
  stripePriceId: string;
  onClose: () => void;
  onUpdateSubscriptionSuccess?: (additionalUserSeats: number) => void;
};

const UpdateSubscriptionModal: FunctionComponent<Props> = ({
  isOpen,
  recurringInterval = RecurringInterval.YEAR,
  stripeProductId,
  stripePriceId,
  onClose,
  onUpdateSubscriptionSuccess,
}: Props): JSX.Element => {
  const [subscriptionIsUpdated, setSubscriptionIsUpdated] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setSubscriptionIsUpdated(false);
    }
  }, [isOpen]);

  const { data } = useGetAvailableProducts();
  const subscriptionAvailableProducts = data?.availableProducts;

  const product = useMemo(() => {
    const _product = subscriptionAvailableProducts?.find(
      (x) => x.stripeId === stripeProductId
    );
    if (!_product) return null;
    return parseSubscriptionProduct(_product);
  }, [subscriptionAvailableProducts, stripeProductId]);

  return (
    <Modal
      isOpen={isOpen}
      title={product ? `Subscribe to ${product.name}` : 'Product not found'}
      size="md"
      onClose={onClose}
    >
      <Modal.Body>
        {!product ? (
          <p>Sorry there was an error - product not found.</p>
        ) : !subscriptionIsUpdated ? (
          <UpdateSubscriptionControl
            recurringInterval={recurringInterval}
            onUpdateSubscriptionSuccess={(additionalUserSeats) => {
              setSubscriptionIsUpdated(true);
              onUpdateSubscriptionSuccess?.(additionalUserSeats);
            }}
            product={product}
            stripePriceId={stripePriceId}
          />
        ) : (
          <div className="flex justify-center items-center">
            <UntitledIcon name="check" className="h-6 w-6 text-green-600" />
            <p>Success!</p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UpdateSubscriptionModal;
