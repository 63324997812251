import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faDollarSign,
  faPoundSign,
  faR,
} from '@fortawesome/free-solid-svg-icons';
import {
  Account,
  AccountForPreview,
  AccountRegions,
} from '@payaca/types/accountTypes';
import { isNullish } from '@payaca/utilities/guards';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import BasicField from '../basicField/BasicField';
import { LabelStyleVariant } from '../fieldLabel/FieldLabel';
import { InputStyleVariant } from '../inputWrapper/InputWrapper';
import './CurrencyField.sass';

type Props = {
  styleVariant?: InputStyleVariant;
  labelStyleVariant?: LabelStyleVariant;
  name: string;
  value?: number;
  label?: string;
  description?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  iconBefore?: IconDefinition;
  iconAfter?: IconDefinition;
  allowPennies?: boolean;
  additionalInputProps?: { [key: string]: any };
  changeTimeoutMs?: number;
  onChange?: (value: { [key: string]: number | any }) => void;
  onTouch?: (fieldName: string) => void;
  onBlur?: () => void;
  onChangeTimeout?: () => void;
  accountPreview?: Account | AccountForPreview;
};

const CurrencyField: FunctionComponent<React.PropsWithChildren<Props>> = ({
  styleVariant,
  name,
  value,
  label,
  description,
  isDisabled = false,
  isRequired = false,
  iconBefore,
  iconAfter,
  allowPennies = true,
  additionalInputProps = {},
  changeTimeoutMs = 1000,
  onChange,
  onTouch,
  onBlur,
  onChangeTimeout,
  accountPreview,
  labelStyleVariant,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const [formattedValue, setFormattedValue] = useState<any>();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!isFocused) {
      // set formatted value when no focus
      const initialValue = isNullish(value)
        ? value
        : Number(value / 100).toFixed(allowPennies ? 2 : 0);
      setFormattedValue(initialValue);
    }
  }, [isFocused, value]);

  const account = useSelector(
    (state: any) => accountPreview || state?.users?.myProfile?.accounts?.[0]
  );
  const currencyIcon = useMemo(() => {
    if (iconBefore) return iconBefore;

    switch (account?.region) {
      case AccountRegions.AUSTRALIA:
      // fall-through

      case AccountRegions.CANADA:
      // fall-through

      case AccountRegions.NEW_ZEALAND:
      // fall-through

      case AccountRegions.US:
        return faDollarSign;
      case AccountRegions.SOUTH_AFRICA:
        return faR;
      case AccountRegions.UK:
      // fall-through

      default:
        return faPoundSign;
    }
  }, [iconBefore, account]);

  return (
    <BasicField
      labelStyleVariant={labelStyleVariant}
      className="currency-field"
      styleVariant={styleVariant}
      name={name}
      value={formattedValue}
      label={label}
      description={description}
      isDisabled={isDisabled}
      isRequired={isRequired}
      iconBefore={currencyIcon}
      iconAfter={iconAfter}
      type="number"
      hideSpinnerArrows={true}
      additionalInputProps={{
        pattern: '^[+-]?[0-9]*',
        min: 0,
        onFocus: () => {
          setIsFocused(true);
          // clear input to be able type straigt away
          if (formattedValue === '0.00') {
            setFormattedValue('');
          }
        },
        ...additionalInputProps,
      }}
      onChange={(change: { [key: string]: any }) => {
        const update = change[name];
        onChange?.({
          [name]: update ? Math.round(update * 100) : null,
        });
        setFormattedValue(update || '');
      }}
      onTouch={(fieldName: string) => {
        onTouch && onTouch(fieldName);
      }}
      onBlur={() => {
        setIsFocused(false);
        onBlur?.();
      }}
      onChangeTimeout={onChangeTimeout}
      changeTimeoutMs={changeTimeoutMs}
    />
  );
};

export default CurrencyField;
