import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import UntitledIcon from '@payaca/untitled-icons';
import { FC } from 'react';

type NotificationActionButtonsProps = {
  markNotificationAsRead: () => void;
};
const NotificationActionButtons: FC<NotificationActionButtonsProps> = (
  props
) => {
  const { markNotificationAsRead } = props;
  return (
    <div className="cursor-pointer sm:group-hover/listed-notification:opacity-100 sm:opacity-0 sm:absolute sm:top-5 sm:end-5 ">
      <div className="inline-block p-0.5 bg-white border border-gray-200 rounded-lg shadow-sm transition ease-out dark:bg-neutral-800 dark:border-neutral-700">
        <div className="flex items-center">
          <Tooltip
            tooltipContent="Mark this notification as read"
            className="@screen-lg:max-w-[400px] max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap text-left"
          >
            <Button
              onClick={(e) => {
                e.stopPropagation();
                markNotificationAsRead();
              }}
              variant={EBtnVariant.Ghost}
              size={EBtnSize.XSmall}
              colour={EBtnColour.Gray}
              className="w-[28px] h-[28px] p-0 focus:shadow-none focus:ring-0"
            >
              <UntitledIcon
                name="check-square-broken.1"
                className="h-[16px] w-[16px]"
              />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default NotificationActionButtons;
