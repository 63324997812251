import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import materialKeys from '@/api/queries/materials/keyFactory';
import { graphql } from '@/gql';

const GetMaterial = graphql(`
  query Material($materialId: ID!) {
    material(id: $materialId) {
      id
      internalId
      name
      description
      category {
        id
        name
      }
      image {
        id
        thumbnailUrl
      }
      source
      preferredSupplierId
      suppliedBy {
        id
        supplier {
          id
          name
        }
        price {
          taxRate {
            id
          }
          unitPrice {
            value
            currency {
              id
              code
              exponent
            }
          }
          unitPriceExcTax {
            value
            currency {
              id
              code
              exponent
            }
          }
        }
        reference
        url
        isSystemManaged
      }
    }
  }
`);

const useGetMaterial = (id?: string | number) => {
  const { data, ...rest } = useQuery({
    enabled: !!id,
    queryKey: materialKeys.material(id?.toString() || ''),
    queryFn: () => {
      return gqlClient.request(GetMaterial, {
        materialId: id?.toString() || '',
      });
    },
  });
  return { material: data?.material, data, ...rest };
};

export default useGetMaterial;
