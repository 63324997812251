import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { actions as userActions } from '@/api/users';
import { getSubscriptionCostIncludingVat } from '@payaca/helpers/subscriptionHelper';
import * as subscriptionActions from '@payaca/store/subscription/subscriptionActions';
import { useHistory } from 'react-router-dom';
import SelectedProductOverview from '../selectedProductOverview/SelectedProductOverview';
import SubscriptionPaymentMethodControl from '../subscriptionPaymentMethodControl/SubscriptionPaymentMethodControl';

import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import SubscriptionPaymentPreview from '../subscriptionPaymentPreview/SubscriptionPaymentPreview';
import UserSeatsExceededWarning from '../userSeatsExceededWarning/UserSeatsExceededWarning';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import {
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';
import { User } from '@payaca/types/userTypes';

import { useSelector } from '@/api/state';
import { getRegion } from '@/utils/stateAccessors';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import { getCurrencyIsoCodeLegacy } from '@payaca/helpers/internationalHelper';
import { getRegionalPayacaVATPercentage } from '@payaca/helpers/vatHelper';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY;

const stripePromise = loadStripe(STRIPE_KEY || '');

type Props = {
  onUpdateSubscriptionSuccess?: (additionalUserSeats: number) => void;
  product: SubscriptionProduct;
  stripePriceId: string;
  recurringInterval?: RecurringInterval;
};

const UpdateSubscriptionControl: FunctionComponent<Props> = ({
  onUpdateSubscriptionSuccess,
  product,
  stripePriceId,
  recurringInterval = RecurringInterval.YEAR,
}: Props): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [updatePaymentMethod, setUpdatePaymentMethod] = useState(false);
  const [isCreatingPaymentMethod, setIsCreatingPaymentMethod] = useState(false);
  const [
    initialPaymentPreviewInitialised,
    setInitialPaymentPreviewInitialised,
  ] = useState(false);
  const isUpdatingSubscription = useSelector(
    (state) =>
      state.subscription.isUpdatingSubscription ||
      state.subscription.isCreatingSubscription
  );
  const accountSubscription = useSelector(
    (state) => state.subscription.accountSubscription
  );
  const subscriptionPaymentPreview = useSelector(
    (state) => state.subscription.subscriptionPaymentPreview
  );
  const isGettingSubscriptionPaymentPreview = useSelector(
    (state) => state.subscription.isGettingSubscriptionPaymentPreview
  );
  const region = useSelector(getRegion);

  const bonusAdditionalUserSeats = useMemo(() => {
    if (!accountSubscription?.subscriptionInformation) return 0;
    return accountSubscription.subscriptionInformation.bonusAdditionalUserSeats;
  }, [accountSubscription]);

  const isSubscriptionUpdatedSuccessfully = useSelector(
    (state) =>
      state.subscription.isSubscriptionUpdatedSuccessfully ||
      state.subscription.isSubscriptionCreatedSuccessfully
  );

  const productPrice = useMemo(() => {
    if (recurringInterval === RecurringInterval.MONTH) {
      return product.monthlyPrice;
    } else {
      return product.annualPrice;
    }
  }, [product, recurringInterval]);

  const canBuyAdditionalUserSeats = useMemo(() => {
    return !!productPrice?.canBuyAdditionalUserSeats;
  }, [productPrice]);

  const monthlyAdditionalUserSeatCost = useMemo(() => {
    if (recurringInterval === RecurringInterval.MONTH) {
      return productPrice?.additionalUserSeatCost;
    } else {
      return productPrice?.additionalUserSeatCost
        ? productPrice.additionalUserSeatCost / 12
        : undefined;
    }
  }, [productPrice, recurringInterval]);

  const activeUsers = useSelector((state: any) => {
    return state.users.accountUsers.filter(
      (accountUser: User) =>
        !accountUser.isDemo &&
        (!accountUser.deactivatedAt || accountUser.inviteToken)
    );
  });

  const isProcessing = useMemo(() => {
    return isUpdatingSubscription || isCreatingPaymentMethod;
  }, [isUpdatingSubscription, isCreatingPaymentMethod]);

  const requiredAdditionalUserSeats = useMemo(() => {
    return Math.max(
      (activeUsers.length || 0) -
        bonusAdditionalUserSeats -
        (product?.numberOfUserSeats ?? 0) -
        (product?.minRequiredUserSeats ?? 0),
      0
    );
  }, [activeUsers, bonusAdditionalUserSeats]);

  const initialFormState = useMemo(() => {
    return {
      recurringInterval: recurringInterval,
      productId: product.stripeId,
      priceId: stripePriceId,
      additionalUserSeats:
        (product.minRequiredUserSeats || 0) +
        (canBuyAdditionalUserSeats ? requiredAdditionalUserSeats : 0),
      subscriptionId:
        accountSubscription?.subscriptionInformation?.stripeSubscriptionId,
      paymentMethodId:
        accountSubscription?.subscriptionInformation?.stripePaymentMethodId,
    };
  }, [
    accountSubscription,
    canBuyAdditionalUserSeats,
    product,
    stripePriceId,
    recurringInterval,
    requiredAdditionalUserSeats,
  ]);

  const paymentMethodDescription = useMemo(() => {
    if (!accountSubscription) return null;

    const subscriptionInformation = accountSubscription.subscriptionInformation;

    return `${subscriptionInformation.stripePaymentMethodBrand}   •••• ${subscriptionInformation.stripePaymentMethodLast4}   ${subscriptionInformation.stripePaymentMethodExpiryMonth}/${subscriptionInformation.stripePaymentMethodExpiryYear}`;
  }, [accountSubscription]);

  const [numPurchasedAdditionalUserSeats, setNumPurchasedAdditionalUserSeats] =
    useState(0);

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      setNumPurchasedAdditionalUserSeats(formState.additionalUserSeats ?? 0);
      if (formState.subscriptionId) {
        const updateSubscriptionRequestData = {
          stripePriceId: formState.priceId,
          stripePaymentMethodId: formState.paymentMethodId,
          stripeSubscriptionId: formState.subscriptionId,
          additionalUserSeats: formState.additionalUserSeats,
          analytics: {
            price: productPrice?.basicCost || 0,
            currency: getCurrencyIsoCodeLegacy(region),
            productName: product.name,
          },
        };

        dispatch(
          subscriptionActions.requestUpdateSubscription(
            updateSubscriptionRequestData
          )
        );
      } else {
        const createSubscriptionRequestData = {
          stripePriceId: formState.priceId,
          stripePaymentMethodId: formState.paymentMethodId,
          additionalUserSeats: formState.additionalUserSeats,
          analytics: {
            price: productPrice?.basicCost || 0,
            currency: getCurrencyIsoCodeLegacy(region),
            productName: product.name,
          },
        };

        dispatch(
          subscriptionActions.requestCreateSubscription(
            createSubscriptionRequestData
          )
        );
      }

      setIsSubmitted(true);
    },
    [
      dispatch,
      productPrice,
      region,
      product,
      setNumPurchasedAdditionalUserSeats,
    ]
  );

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  const taxRatePercentage = useMemo(
    () => getRegionalPayacaVATPercentage(account.region),
    [account]
  );

  const calculateCostIncludingVat = useCallback(
    (recurringInterval: RecurringInterval, additionalUserSeats: number) => {
      return getSubscriptionCostIncludingVat(
        product,
        recurringInterval,
        additionalUserSeats,
        taxRatePercentage
      );
    },
    [product, taxRatePercentage]
  );

  useEffect(() => {
    if (
      isSubmitted &&
      !isUpdatingSubscription &&
      isSubscriptionUpdatedSuccessfully
    ) {
      onUpdateSubscriptionSuccess?.(numPurchasedAdditionalUserSeats);
    }
  }, [
    isSubmitted,
    isUpdatingSubscription,
    isSubscriptionUpdatedSuccessfully,
    onUpdateSubscriptionSuccess,
  ]);

  useEffect(() => {
    dispatch(userActions.getAccountUsers());
    dispatch(subscriptionActions.clearSubscriptionPaymentPreview());

    return () => {
      dispatch(subscriptionActions.clearSubscriptionPaymentPreview());
    };
  }, []);

  const subscriptionPaymentPreviewElement = useMemo(() => {
    return (
      <SubscriptionPaymentPreview
        subscriptionPaymentPreview={subscriptionPaymentPreview}
        isLoading={isGettingSubscriptionPaymentPreview}
      />
    );
  }, [subscriptionPaymentPreview, isGettingSubscriptionPaymentPreview]);

  const updateSubscriptionPaymentPreview = useCallback(
    (
      stripeSubscriptionId: string,
      stripePriceId: string,
      additionalUserSeats: number
    ) => {
      dispatch(
        subscriptionActions.requestGetSubscriptionPaymentPreview({
          stripeSubscriptionId: stripeSubscriptionId,
          stripePriceId: stripePriceId,
          additionalUserSeats: additionalUserSeats,
        })
      );
    },
    [dispatch, subscriptionPaymentPreview]
  );

  useEffect(() => {
    if (initialFormState?.subscriptionId && !initialPaymentPreviewInitialised) {
      updateSubscriptionPaymentPreview(
        initialFormState.subscriptionId,
        initialFormState.priceId,
        initialFormState.additionalUserSeats
      );
      // we only want this initial preview to generate onComponentMount
      // there is a separate update flow for formState changes
      setInitialPaymentPreviewInitialised(true);
    }
  }, [initialFormState, updateSubscriptionPaymentPreview]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      if (!productPrice) return <></>;
      const selectedUserSeats = product
        ? product.numberOfUserSeats + formState.additionalUserSeats
        : undefined;

      const activeUsersExceedsSelectedUserSeats =
        selectedUserSeats + bonusAdditionalUserSeats <
        (activeUsers?.length || 0);

      const getSubscriptionPaymentMethodControl = (buttonCopy: string) => (
        <Elements stripe={stripePromise}>
          <SubscriptionPaymentMethodControl
            paymentAmount={calculateCostIncludingVat(
              formState.recurringInterval,
              formState.additionalUserSeats
            )}
            onPaymentMethodChange={(paymentMethod) => {
              setIsCreatingPaymentMethod(false);
              if (paymentMethod) {
                onSubmit({
                  ...formState,
                  paymentMethodId: paymentMethod?.id,
                });
              }
            }}
            accountRegion={account.region}
            renderActions={(
              handleCreatePaymentMethod: () => void,
              applePayButton?: JSX.Element
            ) => {
              return (
                <>
                  {applePayButton}
                  <Button
                    onClick={
                      !isProcessing
                        ? () => {
                            setIsCreatingPaymentMethod(true);
                            handleCreatePaymentMethod();
                          }
                        : undefined
                    }
                    isProcessing={isProcessing}
                    className="w-1/2 grow"
                  >
                    {buttonCopy}
                  </Button>
                </>
              );
            }}
          />
        </Elements>
      );

      return (
        <React.Fragment>
          <div className="flex flex-col gap-y-4">
            {formState.productId && (
              <div className="selected-product-overview-container">
                <SelectedProductOverview
                  additionalUserSeats={formState.additionalUserSeats}
                  recurringInterval={formState.recurringInterval}
                  taxRatePercentage={taxRatePercentage}
                  selectedProduct={product}
                  selectedPrice={productPrice}
                  onChangeAdditionalUserSeats={(
                    additionalUserSeats: number
                  ) => {
                    if (formState.subscriptionId) {
                      updateSubscriptionPaymentPreview(
                        formState.subscriptionId,
                        formState.priceId,
                        additionalUserSeats
                      );
                    }
                    onFieldChange({
                      additionalUserSeats: additionalUserSeats,
                    });
                  }}
                />
              </div>
            )}
            {subscriptionPaymentPreviewElement}
          </div>

          {activeUsersExceedsSelectedUserSeats && (
            <UserSeatsExceededWarning
              selectedUserSeats={selectedUserSeats}
              bonusAdditionalUserSeats={bonusAdditionalUserSeats}
              activeUsersCount={activeUsers.length}
            />
          )}
          {!activeUsersExceedsSelectedUserSeats && formState.productId && (
            <div>
              {/* Create new subscription */}
              {!formState.subscriptionId && (
                <>
                  <h3 className="mb-2 mt-4">Provide a payment method</h3>
                  <div>
                    {getSubscriptionPaymentMethodControl('Create subscription')}
                  </div>
                </>
              )}

              {/* Update existing subscription */}
              {!!formState.subscriptionId && (
                <div className="border-t-2 border-gray-200">
                  <h3 className="my-2">Review your payment method</h3>
                  {!updatePaymentMethod && (
                    <div className="flex flex-col text-center gap-y-4">
                      <p>
                        Would you like to continue using your current payment
                        method
                        <span className="block">
                          <strong>{paymentMethodDescription}</strong> ?
                        </span>
                      </p>
                      <div className="flex justify-center gap-x-4">
                        <Button
                          onClick={() => setUpdatePaymentMethod(true)}
                          variant={EBtnVariant.Outline}
                        >
                          Change payment method
                        </Button>
                        <Button
                          isProcessing={isProcessing}
                          onClick={
                            !isProcessing
                              ? () => onSubmit(formState)
                              : undefined
                          }
                        >
                          Update subscription
                        </Button>
                      </div>
                    </div>
                  )}
                  {updatePaymentMethod && (
                    <div>
                      {getSubscriptionPaymentMethodControl(
                        'Update subscription'
                      )}
                    </div>
                  )}
                </div>
              )}

              {isSubscriptionUpdatedSuccessfully === false && isSubmitted && (
                <p className="text-red-500 mt-4">
                  Failed to {formState.subscriptionId ? 'update' : 'create'}{' '}
                  your subscription. Please check that the payment method you
                  have provided is correct.
                </p>
              )}
            </div>
          )}
        </React.Fragment>
      );
    },
    [
      monthlyAdditionalUserSeatCost,
      product,
      bonusAdditionalUserSeats,
      history,
      activeUsers,
      isSubmitted,
      isProcessing,
      updatePaymentMethod,
      paymentMethodDescription,
      isSubscriptionUpdatedSuccessfully,
      subscriptionPaymentPreviewElement,
      onSubmit,
      calculateCostIncludingVat,
      updateSubscriptionPaymentPreview,
    ]
  );

  return (
    <div className="update-subscription-control">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
      />
    </div>
  );
};

export default UpdateSubscriptionControl;
