import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router';

import { useCanUpdateAccount } from '@/hooks/useCanUpdateAccount';
import CompanySettingsBranding from '@/ui/components/companySettings/CompanySettingsBranding';
import CompanySettingsCompanyInfo from '@/ui/components/companySettings/CompanySettingsCompanyInfo';
import CompanySettingsEmailCustomisation from '@/ui/components/companySettings/CompanySettingsEmailCustomisation';
import CompanySettingsGettingPaid from '@/ui/components/companySettings/CompanySettingsGettingPaid';
import CompanySettingsSchedule from '@/ui/components/companySettings/CompanySettingsSchedule';
import CompanySettingsTaxRates from '@/ui/components/companySettings/CompanySettingsTaxRates';
import CompanySettingsTerms from '@/ui/components/companySettings/CompanySettingsTerms';
import TabbedContentPageWrapper from '../pageWrappers/tabbedContentPageWrapper/TabbedContentPageWrapper';

import CompanySettingsPrimaryPipeline from '@/ui/components/companySettings/CompanySettingsPrimaryPipeline';
import { CompanySettingsProjectCustomFields } from '@/ui/components/companySettings/CompanySettingsProjectCustomFields';
import CompanySettingsTimeTracking from '@/ui/components/companySettings/CompanySettingsTimeTracking';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import './CompanySettingsPage.sass';

type TabDescriptor = React.ComponentProps<
  typeof TabbedContentPageWrapper
>['tabbedContentConfig']['tabs'][number] & {
  urlSlug: string;
};

const CompanySettingsPage: FC = (): JSX.Element => {
  const isAdmin = useCanUpdateAccount();

  const tabs: Array<TabDescriptor> = [
    {
      title: 'Company info',
      label: 'Company info',
      content: <CompanySettingsCompanyInfo readOnly={!isAdmin} />,
      urlSlug: 'company-info',
    },
    {
      title: 'Branding',
      label: 'Branding',
      content: <CompanySettingsBranding readOnly={!isAdmin} />,
      urlSlug: 'branding',
    },
    {
      title: 'Getting paid',
      label: 'Getting paid',
      content: <CompanySettingsGettingPaid readOnly={!isAdmin} />,
      urlSlug: 'getting-paid',
    },
    {
      title: 'Tax rates',
      label: 'Tax rates',
      content: <CompanySettingsTaxRates readOnly={!isAdmin} />,
      urlSlug: 'tax-rates',
    },
    {
      title: 'Terms',
      label: 'Terms',
      content: <CompanySettingsTerms readOnly={!isAdmin} />,
      urlSlug: 'terms',
    },
    {
      title: 'Schedule',
      label: 'Schedule',
      content: <CompanySettingsSchedule readOnly={!isAdmin} />,
      urlSlug: 'schedule',
    },
    {
      title: 'Email customisation',
      label: 'Email customisation',
      content: <CompanySettingsEmailCustomisation readOnly={!isAdmin} />,
      urlSlug: 'email-customisation',
    },
    {
      title: 'Custom fields',
      label: 'Custom fields',
      urlSlug: 'custom-fields',
      content: (
        <div className="flex flex-col gap-4">
          <CompanySettingsPrimaryPipeline readOnly={!isAdmin} />
          <PermissionGuard
            renderIfHasPermissions={[
              AccountsPermissions.MANAGE_CUSTOM_FIELD_GROUPS,
            ]}
          >
            <CompanySettingsProjectCustomFields />
          </PermissionGuard>
          <CompanySettingsTimeTracking readOnly={!isAdmin} />
        </div>
      ),
    },
  ];

  const { tab: activeTabRouteParam } = useParams<{
    tab?: string;
  }>();

  const history = useHistory();

  const { searchParams: urlSearchParams } = new URL(window.location.href);

  if (!activeTabRouteParam) {
    history.replace(
      `/companySettings/company-info?${urlSearchParams.toString()}`
    );
  }

  const activeTabLabel = tabs.find((tab) => tab.urlSlug === activeTabRouteParam)
    ?.label;

  const handleChangeTab = (newActiveTabLabel: string) => {
    const urlSlug = tabs.find((tab) => tab.label === newActiveTabLabel)
      ?.urlSlug;
    if (urlSlug) {
      history.push(`/companySettings/${urlSlug}?${urlSearchParams.toString()}`);
    }
  };

  return (
    <TabbedContentPageWrapper
      title="My Company"
      className="company-settings-page"
      tabbedContentConfig={{
        tabs: tabs,
        onChangeTab: handleChangeTab,
        activeTabLabel: activeTabLabel,
      }}
    />
  );
};

export default CompanySettingsPage;
