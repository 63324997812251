import { FunctionComponent, MutableRefObject } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import SignHerePrompt from '../signHerePrompt/SignHerePrompt';

import './SignatureCapture.sass';

export type TSignatureCanvas = {
  resizeCanvas: () => void;
} & SignatureCanvas;

type Props = {
  displaySignHerePrompt?: boolean;
  signatureCanvasReference: MutableRefObject<TSignatureCanvas | undefined>;
  setIsSignaturePresent: (isSignaturePresent: boolean) => void;
};

const SignatureCapture: FunctionComponent<Props> = ({
  displaySignHerePrompt = false,
  signatureCanvasReference,
  setIsSignaturePresent,
}: Props): JSX.Element => {
  const handleRef = (node: SignatureCanvas) => {
    if (!node) return;

    signatureCanvasReference.current = node as TSignatureCanvas;

    /**
     * Resize the canvas to fit the parent element.
     * Useful when the parent element has changed size since initial render
     */
    signatureCanvasReference.current.resizeCanvas = () => {
      const signaturePad = node.getSignaturePad();
      const canvas = node.getCanvas();
      if (!signaturePad || !canvas) return;

      canvas.width = canvas.parentElement?.clientWidth || 0;
      canvas.height = canvas.parentElement?.clientHeight || 0;
      signaturePad.clear();
    };
  };

  return (
    <div
      className={`signature-capture ${
        displaySignHerePrompt ? 'display-sign-here-prompt' : ''
      }`}
      style={{ position: 'relative' }}
    >
      <SignatureCanvas
        ref={handleRef}
        penColor="black"
        canvasProps={{
          className: 'sigCanvas rounded-lg',
        }}
        clearOnResize={false}
        onEnd={() => setIsSignaturePresent(true)}
      />
      <div
        className="clear-signature"
        onClick={() => {
          signatureCanvasReference.current?.clear();
          setIsSignaturePresent(false);
        }}
      >
        Clear
      </div>
      <SignHerePrompt />
    </div>
  );
};

export default SignatureCapture;
