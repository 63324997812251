import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import LineItemPriceField from '@/ui/components/addEditItemSidebar/LineItemPriceField';
import { MarkupType } from '@/ui/components/addEditItemSidebar/utils';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import Conditional from '@payaca/components/conditional/Conditional';
import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Switch from '@payaca/components/plSwitch/Switch';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { z } from 'zod';

export type TLineItemPriceCardFormState = {
  predictedMaterialCost?: number;
  markupTotals: {
    markupType: MarkupType;
    profitPercentage: number;
    profitAmount: number;
    price: number;
  };
  autoUpdatePrice: boolean;
};

export const LINE_ITEM_PRICE_FORM_SCHEMA = z.object({
  predictedMaterialCost: z.number().optional(),
  markupTotals: z.object({
    markupType: z.string(),
    profitPercentage: z.number(),
    profitAmount: z.number(),
    price: z.number(),
  }),
  autoUpdatePrice: z.boolean(),
});

type TProps = {
  hasLinkedMaterials: boolean;
  readonlyMarkupValues?: boolean;
};

const LineItemPriceCard: FC<TProps> = (props) => {
  const { hasLinkedMaterials, readonlyMarkupValues } = props;

  /**
   * Queries
   */
  const { data: regionPreferences, isLoading: isLoadingRegionPreferences } =
    useGetMyRegionPreferences();

  const formMethods = useFormContext<TLineItemPriceCardFormState>();

  const { markupType } = formMethods.watch('markupTotals');
  const predictedMaterialCost = formMethods.watch('predictedMaterialCost');

  if (isLoadingRegionPreferences || !regionPreferences) {
    return null;
  }

  return (
    <>
      <Conditional condition={hasLinkedMaterials}>
        <Card sizeVariant={CardSizeVariant.SM}>
          <Card.Body>
            <div className="flex justify-between">
              <label>Materials (ex Tax)</label>

              <p>
                <CurrencyValue
                  value={predictedMaterialCost || 0}
                  currency={regionPreferences.currency}
                />
              </p>
            </div>
          </Card.Body>
        </Card>
      </Conditional>

      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body>
          <Controller
            control={formMethods.control}
            name="markupTotals"
            render={({ field: { onChange, value } }) => {
              return (
                <LineItemPriceField
                  markupTotals={value}
                  hasLinkedMaterials={hasLinkedMaterials}
                  predictedMaterialCost={predictedMaterialCost}
                  onMarkupTotalChange={onChange}
                  readonlyMarkupValues={readonlyMarkupValues}
                  currency={regionPreferences.currency}
                />
              );
            }}
          />
        </Card.Body>
      </Card>

      <Conditional condition={markupType !== 'price'}>
        <HeadlessSwitch.Group>
          <HeadlessSwitch.Label className="block">
            <Card sizeVariant={CardSizeVariant.SM}>
              <Card.Body>
                <div className="flex justify-between">
                  <div>
                    <h4>Auto update</h4>

                    <p>When Material costs change update my sale price</p>
                  </div>

                  <Controller
                    name="autoUpdatePrice"
                    control={formMethods.control}
                    render={({ field: { onChange, value } }) => {
                      return <Switch value={value} onChange={onChange} />;
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </HeadlessSwitch.Label>
        </HeadlessSwitch.Group>
      </Conditional>
    </>
  );
};

export default LineItemPriceCard;
