import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import LoqateAdvisoryPhoneInputField from '../loqateAdvisoryPhoneInputField/LoqateAdvisoryPhoneInputField';

import { actions as usersActions } from '@/api/users';
import * as authActions from '@payaca/store/auth/authActions';

import { getFormServiceError } from '@/helpers/formHelper';
import {
  getEmailFieldValidator,
  getIsRequiredFieldValidator,
  getPhoneNumberFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import { marketingSiteUrl } from '@/helpers/urlHelper';
import './UserRegisterForm.sass';

type Props = {
  initialFormState?: { [key: string]: any };
  onRegisterCallback?: (error: any) => void;
  referralToken: string | null;
};

const UserRegisterForm: FC<Props> = ({
  initialFormState,
  onRegisterCallback,
  referralToken,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [registerErrorMessage, setRegisterErrorMessage] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);

  const fieldValidators = useMemo(() => {
    const isRequiredFieldValidator = getIsRequiredFieldValidator();

    return {
      firstName: [isRequiredFieldValidator],
      lastName: [isRequiredFieldValidator],
      emailAddress: [isRequiredFieldValidator, getEmailFieldValidator()],
      contactNumber: [isRequiredFieldValidator, getPhoneNumberFieldValidator()],
    };
  }, []);

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      setIsProcessing(true);
      setRegisterErrorMessage(undefined);
      const registerPayload = {
        firstName: formState.firstName,
        lastName: formState.lastName,
        email: formState.emailAddress,
        contactNumber: formState.contactNumber,
        referralToken: referralToken,
        industryType: formState.industryType,
        numberOfEmployees: formState.numberOfEmployees,
      };
      dispatch(
        authActions.requestSignUpWithEmailV2(registerPayload, (error: any) => {
          dispatch(
            usersActions.getProfile(() => {
              setIsProcessing(false);
              if (error) {
                setRegisterErrorMessage(getFormServiceError('register', error));
              }
              onRegisterCallback && onRegisterCallback(error);
            })
          );
        })
      );
    },
    [dispatch, referralToken]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <div className="first-last-names-wrapper">
            <ValidatedFieldWrapper
              validationResult={validationState['firstName']}
              isTouched={touchedState['firstName'] || false}
            >
              <BasicField
                styleVariant={InputStyleVariant.OUTSIZE}
                isRequired={true}
                label="First name"
                name="firstName"
                value={formState.firstName}
                onTouch={onFieldTouch}
                onChange={onFieldChange}
              />
            </ValidatedFieldWrapper>
            <ValidatedFieldWrapper
              validationResult={validationState['lastName']}
              isTouched={touchedState['lastName'] || false}
            >
              <BasicField
                styleVariant={InputStyleVariant.OUTSIZE}
                isRequired={true}
                label="Last name"
                name="lastName"
                value={formState.lastName}
                onTouch={onFieldTouch}
                onChange={onFieldChange}
              />
            </ValidatedFieldWrapper>
          </div>
          <ValidatedFieldWrapper
            validationResult={validationState['emailAddress']}
            isTouched={touchedState['emailAddress'] || false}
          >
            <LoqateAdvisoryEmailInputField
              styleVariant={InputStyleVariant.OUTSIZE}
              isRequired={true}
              label="Email"
              name="emailAddress"
              value={formState.emailAddress}
              onTouch={onFieldTouch}
              onChange={onFieldChange}
              disableValidation={
                validationState['emailAddress']
                  ? !validationState['emailAddress'].isValid
                  : false
              }
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={validationState['contactNumber']}
            isTouched={touchedState['contactNumber'] || false}
          >
            <LoqateAdvisoryPhoneInputField
              styleVariant={InputStyleVariant.OUTSIZE}
              isRequired={true}
              label="Phone number"
              name="contactNumber"
              value={formState.contactNumber}
              onTouch={onFieldTouch}
              onChange={onFieldChange}
              disableValidation={
                validationState['contactNumber']
                  ? !validationState['contactNumber'].isValid
                  : false
              }
            />
          </ValidatedFieldWrapper>
          <div className="button-wrapper">
            <Button
              isProcessing={isProcessing}
              onClick={() => !isProcessing && onSubmit(formState)}
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isDisabled={!isValid}
              colourVariant={ButtonColourVariant.PRIMARY}
            >
              Next
            </Button>
          </div>
          {registerErrorMessage && (
            <ErrorMessage message={registerErrorMessage} />
          )}
        </>
      );
    },
    [isProcessing, registerErrorMessage, onSubmit]
  );

  return (
    <div className="user-register-form-container">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
        initialFormState={initialFormState || {}}
      />
      <p className="marketing-and-terms">
        By proceeding, you are agreeing to Payaca&apos;s{' '}
        <a
          href={marketingSiteUrl('/terms-and-conditions')}
          target="_blank"
          rel="noreferrer"
        >
          {'terms & conditions'}
        </a>{' '}
        and{' '}
        <a
          href={marketingSiteUrl('/privacy-policy')}
          target="_blank"
          rel="noreferrer"
        >
          privacy policy
        </a>
      </p>
    </div>
  );
};

export default UserRegisterForm;
