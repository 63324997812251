import { HeaderTagType } from '@payaca/components/headerTag/HeaderTag';
import TabbedView from '@payaca/components/tabbedView/TabbedView';
import { FC } from 'react';

import AuthenticatedPageWrapper, {
  Props as IAuthenticatedPageWrapperProps,
} from '../authenticatedPageWrapper/AuthenticatedPageWrapper';

import './TabbedContentPageWrapper.sass';

interface Props extends IAuthenticatedPageWrapperProps {
  tabbedContentConfig: {
    tabs: {
      content: JSX.Element;
      defaultSelected?: boolean | undefined;
      isDisabled?: boolean | undefined;
      title: string;
      label: string;
    }[];
    activeTabLabel?: string;
    onChangeTab: (label: string) => void;
  };
}

const TabbedContentPageWrapper: FC<Props> = ({
  tabbedContentConfig,
  className,
  ...authenticatedPageWrapperProps
}: Props): JSX.Element | null => {
  return (
    <AuthenticatedPageWrapper
      className={`tabbed-content-page-wrapper ${className ? className : ''}`}
      {...authenticatedPageWrapperProps}
    >
      <TabbedView
        tabs={tabbedContentConfig.tabs}
        titleHeaderTagType={HeaderTagType.H2}
        activeTabLabel={tabbedContentConfig.activeTabLabel}
        onChangeTab={tabbedContentConfig.onChangeTab}
      />
    </AuthenticatedPageWrapper>
  );
};

export default TabbedContentPageWrapper;
