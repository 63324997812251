import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import PopupMenu from '../popupMenu/PopupMenu';

export type TQuickAction<T> = {
  name: string;
  onClick: (row: T) => void;
  isDisabled?: (row: T) => boolean;
};

export type TQuickActions<T> =
  | TQuickAction<T>[]
  | ((row: T) => TQuickAction<T>[]);

interface IQuickActionsCellProps<T extends Record<string, any>> {
  row: T;
  quickActions: TQuickActions<T>;
}

const QuickActions = <T extends Record<string, any>>(
  props: IQuickActionsCellProps<T>
) => {
  const { quickActions, row } = props;
  const quickActionCellRef = useRef<HTMLTableCellElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const actions =
    quickActions instanceof Function ? quickActions(row) : quickActions;

  return (
    <td ref={quickActionCellRef} className="quick-action-table-cell">
      <button
        className="quick-action-table-cell__button"
        type="button"
        onClick={(e) => {
          setIsOpen(true);
          e.stopPropagation();
        }}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      <PopupMenu
        anchorElement={quickActionCellRef.current}
        isOpen={isOpen}
        options={actions.map((action) => ({
          ...action,
          onClick: () => {
            setIsOpen(false);
            action.onClick(row);
          },
          isDisabled: action.isDisabled?.(row),
        }))}
        onClose={() => setIsOpen(false)}
      />
    </td>
  );
};

export default QuickActions;
