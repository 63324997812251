import {
  calculateMarkupChange,
  MarkupType,
} from '@/ui/components/addEditItemSidebar/utils';
import PercentageField from '@/ui/components/percentageField/PercentageField';
import Conditional from '@payaca/components/conditional/Conditional';
import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import Field from '@payaca/components/plField/Field';
import CurrencyInput from '@payaca/components/plInput/CurrencyInput';
import { InputSizeVariant } from '@payaca/components/plInput/RawInput';
import Radio from '@payaca/components/plRadio/Radio';
import { getISOCodeTextString } from '@payaca/helpers/internationalHelper';
import { Currency, CurrencyCodes } from '@payaca/types/currencyTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import { FC } from 'react';

export interface IProps {
  hasLinkedMaterials: boolean;
  predictedMaterialCost?: number;
  markupTotals: MarkupTotals;
  onMarkupTotalChange: (markupTotals: MarkupTotals) => void;
  // Percentage and markup amounts are readonly
  readonlyMarkupValues?: boolean;
  currency: Currency;
}

type MarkupTotals = Record<MarkupType, number> & {
  markupType: MarkupType;
};

const LineItemPriceField: FC<IProps> = (props) => {
  const {
    hasLinkedMaterials,
    predictedMaterialCost,
    markupTotals,
    onMarkupTotalChange,
    readonlyMarkupValues = false,
    currency,
  } = props;

  const handleMarkupChange = (markupType: MarkupType) => (value: number) => {
    onMarkupTotalChange({
      markupType,
      ...calculateMarkupChange(markupType, value, predictedMaterialCost),
    });
  };

  return (
    <>
      <Conditional condition={hasLinkedMaterials && !readonlyMarkupValues}>
        <h4 className="mb-2.5">Set price by</h4>
      </Conditional>

      <div className="grid grid-cols-[auto_150px] items-center gap-2.5">
        <Conditional condition={hasLinkedMaterials}>
          <div>
            <Conditional
              condition={!readonlyMarkupValues}
              fallback={
                <>
                  <h4>Profit %</h4>
                </>
              }
            >
              <Field name="lineItemMarkupType">
                <Radio
                  label="Profit %"
                  value="profitPercentage"
                  checked={markupTotals.markupType === 'profitPercentage'}
                  onChange={(event) => {
                    if (event.target.checked) {
                      onMarkupTotalChange({
                        ...markupTotals,
                        markupType: 'profitPercentage',
                      });
                    }
                  }}
                />
              </Field>
            </Conditional>
          </div>

          <div>
            <Conditional
              condition={!readonlyMarkupValues}
              fallback={
                <p className="text-right px-3 py-2">
                  {markupTotals.profitPercentage || '0'}%
                </p>
              }
            >
              <PercentageField
                sizeVariant={InputSizeVariant.SM}
                inputClassName="text-right mr-2"
                value={markupTotals.profitPercentage || '0'}
                onFocus={() =>
                  onMarkupTotalChange({
                    ...markupTotals,
                    markupType: 'profitPercentage',
                  })
                }
                onChange={(value) => {
                  handleMarkupChange('profitPercentage')(Number(value));
                }}
                minErrorMessages={['Profit percentage must be 0 or more']}
                maxErrorMessages={['Profit percentage must be less than 100']}
                lessThanMax
              />
            </Conditional>
          </div>

          <div>
            <Conditional
              condition={!readonlyMarkupValues}
              fallback={
                <>
                  <h4>
                    Profit{' '}
                    {getISOCodeTextString(
                      currency.code as CurrencyCodes,
                      RegionalStrings.CURRENCY_SYMBOL
                    )}
                  </h4>
                </>
              }
            >
              <Field name="lineItemMarkupType">
                <Radio
                  label={
                    'Profit ' +
                    getISOCodeTextString(
                      currency.code as CurrencyCodes,
                      RegionalStrings.CURRENCY_SYMBOL
                    )
                  }
                  value="profitAmount"
                  checked={markupTotals.markupType === 'profitAmount'}
                  onChange={(event) => {
                    if (event.target.checked) {
                      onMarkupTotalChange({
                        ...markupTotals,
                        markupType: 'profitAmount',
                      });
                    }
                  }}
                />
              </Field>
            </Conditional>
          </div>
          <div>
            <Conditional
              condition={!readonlyMarkupValues}
              fallback={
                <p className="text-right px-3 py-2">
                  <CurrencyValue
                    value={markupTotals.profitAmount || 0}
                    currency={currency}
                  />
                </p>
              }
            >
              <CurrencyInput
                sizeVariant={InputSizeVariant.SM}
                inputClassName="text-right"
                onFocus={() =>
                  onMarkupTotalChange({
                    ...markupTotals,
                    markupType: 'profitAmount',
                  })
                }
                value={markupTotals.profitAmount || 0}
                onChange={handleMarkupChange('profitAmount')}
                currency={currency}
              />
            </Conditional>
          </div>

          <div>
            <Conditional
              condition={!readonlyMarkupValues}
              fallback={
                <>
                  <h4>Price</h4>
                </>
              }
            >
              <Field name="lineItemMarkupType">
                <Radio
                  label="Price"
                  value="price"
                  checked={markupTotals.markupType === 'price'}
                  onChange={(event) => {
                    if (event.target.checked) {
                      onMarkupTotalChange({
                        ...markupTotals,
                        markupType: 'price',
                      });
                    }
                  }}
                />
              </Field>
            </Conditional>
          </div>
        </Conditional>

        <Conditional condition={!hasLinkedMaterials}>
          <h4>Price</h4>
        </Conditional>

        <div>
          <CurrencyInput
            inputClassName="text-right"
            sizeVariant={InputSizeVariant.SM}
            onFocus={() =>
              onMarkupTotalChange({
                ...markupTotals,
                markupType: 'price',
              })
            }
            value={markupTotals.price || 0}
            onChange={handleMarkupChange('price')}
            currency={currency}
          />
        </div>
      </div>
    </>
  );
};

export default LineItemPriceField;
