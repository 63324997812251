import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { actions as appActions } from '@/api/app';

import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import CollapsiblePanel, {
  CollapsiblePanelStyleVariant,
} from '@payaca/components/collapsiblePanel/CollapsiblePanel';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import PrivateField from '@payaca/components/privateField/PrivateField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import PasswordValidationFeedback from '../passwordValidationFeedback/PasswordValidationFeedback';

import { getFormServiceError } from '@/helpers/formHelper';
import {
  getFieldValuesMustMatchValidator,
  getIsRequiredFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import {
  minimumLengthFieldValidator,
  mustContainLetterFieldValidator,
  mustContainNumberFieldValidator,
} from '@payaca/helpers/passwordValidationHelper';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

type Props = {
  resetToken: string;
  onSuccessCallback?: () => void;
};
const ResetPasswordForm: FunctionComponent<Props> = ({
  resetToken,
  onSuccessCallback,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] =
    useState<string>();
  const [showPasswordValidationFeedback, setShowPasswordValidationFeedback] =
    useState(false);

  const onSubmit = useCallback(
    (newPassword: string, confirmNewPassword: string) => {
      setIsResettingPassword(true);
      setResetPasswordErrorMessage(undefined);
      const payload = {
        password: newPassword,
        confirm: confirmNewPassword,
      };
      dispatch(
        appActions.resetPassword(resetToken, payload, (error: any) => {
          if (!error) {
            onSuccessCallback && onSuccessCallback();
          } else {
            setResetPasswordErrorMessage(
              getFormServiceError('resetPassword', error)
            );
          }
          setIsResettingPassword(false);
        })
      );
    },
    [dispatch, resetToken, onSuccessCallback]
  );

  const fieldValidators = useMemo(() => {
    const isRequiredFieldValidator = getIsRequiredFieldValidator();
    return {
      newPassword: [
        isRequiredFieldValidator,
        minimumLengthFieldValidator,
        mustContainLetterFieldValidator,
        mustContainNumberFieldValidator,
      ],

      confirmNewPassword: [
        isRequiredFieldValidator,
        getFieldValuesMustMatchValidator('newPassword', {
          customErrorMessage: 'The passwords entered do not match',
        }),
      ],
    };
  }, []);

  const initialFormState = useMemo(() => {
    return {
      newPassword: '',
      confirmNewPassword: '',
    };
  }, []);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          {/* Show the red outline here if not valid, but don't render any validation errors as these are displayed below the submission button */}
          <ValidatedFieldWrapper
            validationResult={{
              ...validationState['newPassword'],
              errors: [],
            }}
            isTouched={touchedState['newPassword'] || false}
          >
            <PrivateField
              styleVariant={InputStyleVariant.OUTSIZE}
              name="newPassword"
              value={formState.newPassword}
              label={'New password'}
              isRequired={true}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              additionalInputProps={{
                onFocus: () => setShowPasswordValidationFeedback(true),
              }}
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={validationState['confirmNewPassword']}
            isTouched={touchedState['confirmNewPassword'] || false}
          >
            <PrivateField
              styleVariant={InputStyleVariant.OUTSIZE}
              name="confirmNewPassword"
              value={formState.confirmNewPassword}
              label={'Confirm password'}
              isRequired={true}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
            />
          </ValidatedFieldWrapper>
          <CollapsiblePanel
            isOpen={showPasswordValidationFeedback}
            styleVariant={CollapsiblePanelStyleVariant.UNSTYLED}
            shouldRenderHeader={false}
          >
            <PasswordValidationFeedback password={formState.newPassword} />
          </CollapsiblePanel>

          <div className="button-wrapper">
            <Button
              type="submit"
              styleVariant={ButtonStyleVariant.OUTSIZE}
              colourVariant={ButtonColourVariant.PRIMARY}
              isDisabled={!isValid}
              isProcessing={isResettingPassword}
              onClick={() =>
                !isResettingPassword &&
                onSubmit(formState.newPassword, formState.confirmNewPassword)
              }
            >
              Reset Password
            </Button>
          </div>
          {resetPasswordErrorMessage && (
            <ErrorMessage message={resetPasswordErrorMessage} />
          )}
        </>
      );
    },
    [
      isResettingPassword,
      onSubmit,
      resetPasswordErrorMessage,
      showPasswordValidationFeedback,
    ]
  );

  return (
    <div className="reset-password-form-container">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        fieldValidators={fieldValidators}
        renderFormContents={renderFormContents}
      />
    </div>
  );
};

export default ResetPasswordForm;
