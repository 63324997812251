import { Account } from '@payaca/types/accountTypes';
import {
  AccountSubscription,
  SubscriptionPaymentPreview,
} from '@payaca/types/subscriptionTypes';
import { action } from 'typesafe-actions';
import { createAsyncAction } from '../utils';
import {
  CancelAddOnSubscription,
  CancelSubscriptionAction,
  ClearAddOnProducts,
  CreateAddOnSubscription,
  CreateSubscriptionRequestData,
  GetAddOnProducts,
  GetAddOnSubscriptionPaymentPreview,
  GetSubscriptionPaymentPreviewData,
  RestoreAddOnSubscription,
  SubscriptionActionTypes,
  UpdateAddOnSubscription,
  UpdatePaymentMethodRequestData,
  UpdateSubscriptionRequestData,
} from './subscriptionTypes';

export const requestGetAccountSubscription = () => {
  return action(SubscriptionActionTypes.REQUEST_GET_ACCOUNT_SUBSCRIPTION);
};

export const clearAccountSubscription = () => {
  return action(SubscriptionActionTypes.CLEAR_ACCOUNT_SUBSCRIPTION);
};

export const getAccountSubscriptionSuccess = (
  accountSubscription: AccountSubscription
) => {
  return action(SubscriptionActionTypes.GET_ACCOUNT_SUBSCRIPTION_SUCCESS, {
    accountSubscription,
  });
};

export const getAccountSubscriptionFailure = (error: Error) => {
  return action(
    SubscriptionActionTypes.GET_ACCOUNT_SUBSCRIPTION_FAILURE,
    null,
    null,
    error
  );
};

export const requestCreateSubscription = (
  createSubscriptionRequestData: CreateSubscriptionRequestData
) => {
  return action(SubscriptionActionTypes.REQUEST_CREATE_SUBSCRIPTION, {
    createSubscriptionRequestData,
  });
};

export const createSubscriptionSuccess = (
  createSubscriptionRequestData: CreateSubscriptionRequestData
) => {
  return action(
    SubscriptionActionTypes.CREATE_SUBSCRIPTION_SUCCESS,
    createSubscriptionRequestData
  );
};

export const createSubscriptionFailure = (error: Error) => {
  return action(
    SubscriptionActionTypes.CREATE_SUBSCRIPTION_FAILURE,
    null,
    null,
    error
  );
};

export const requestUpdateSubscription = (
  updateSubscriptionRequestData: UpdateSubscriptionRequestData
) => {
  return action(SubscriptionActionTypes.REQUEST_UPDATE_SUBSCRIPTION, {
    updateSubscriptionRequestData,
  });
};

export const updateSubscriptionSuccess = (
  updateSubscriptionRequestData: any
) => {
  return action(
    SubscriptionActionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
    updateSubscriptionRequestData
  );
};

export const updateSubscriptionFailure = (error: Error) => {
  return action(
    SubscriptionActionTypes.UPDATE_SUBSCRIPTION_FAILURE,
    null,
    null,
    error
  );
};

export const requestRestoreSubscription = () => {
  return action(SubscriptionActionTypes.REQUEST_RESTORE_SUBSCRIPTION);
};

export const restoreSubscriptionSuccess = () => {
  return action(SubscriptionActionTypes.RESTORE_SUBSCRIPTION_SUCCESS);
};

export const restoreSubscriptionFailure = (error: Error) => {
  return action(
    SubscriptionActionTypes.RESTORE_SUBSCRIPTION_FAILURE,
    null,
    null,
    error
  );
};

export const requestUpdatePaymentMethod = (
  updatePaymentMethodRequestData: UpdatePaymentMethodRequestData
) => {
  return action(SubscriptionActionTypes.REQUEST_UPDATE_PAYMENT_METHOD, {
    updatePaymentMethodRequestData,
  });
};

export const updatePaymentMethodSuccess = () => {
  return action(SubscriptionActionTypes.UPDATE_PAYMENT_METHOD_SUCCESS);
};

export const updatePaymentMethodFailure = (error: Error) => {
  return action(
    SubscriptionActionTypes.UPDATE_PAYMENT_METHOD_FAILURE,
    null,
    null,
    error
  );
};

export const requestGetSubscriptionPaymentPreview = (
  getSubscriptionPaymentPreviewData: GetSubscriptionPaymentPreviewData
) => {
  return action(
    SubscriptionActionTypes.REQUEST_GET_SUBSCRIPTION_PAYMENT_PREVIEW,
    {
      getSubscriptionPaymentPreviewData,
    }
  );
};

export const getSubscriptionPaymentPreviewSuccess = (
  subscriptionPaymentPreview: SubscriptionPaymentPreview
) => {
  return action(
    SubscriptionActionTypes.GET_SUBSCRIPTION_PAYMENT_PREVIEW_SUCCESS,
    {
      subscriptionPaymentPreview,
    }
  );
};

export const getSubscriptionPaymentPreviewFailure = (error: Error) => {
  return action(
    SubscriptionActionTypes.GET_SUBSCRIPTION_PAYMENT_PREVIEW_FAILURE,
    null,
    null,
    error
  );
};

export const clearSubscriptionPaymentPreview = () => {
  return action(SubscriptionActionTypes.CLEAR_SUBSCRIPTION_PAYMENT_PREVIEW);
};

export const cancelSubscription = createAsyncAction<CancelSubscriptionAction>({
  request: SubscriptionActionTypes.CANCEL_SUBSCRIPTION_REQUEST,
  success: SubscriptionActionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
  failure: SubscriptionActionTypes.CANCEL_SUBSCRIPTION_FAILURE,
})((accountId: Account['id'], callback: (err?: Error) => void) => ({
  payload: accountId,
  meta: callback,
}));

export const getAddOnProducts = createAsyncAction<GetAddOnProducts>({
  request: SubscriptionActionTypes.GET_ADD_ON_PRODUCTS_REQUEST,
  success: SubscriptionActionTypes.GET_ADD_ON_PRODUCTS_SUCCESS,
  failure: SubscriptionActionTypes.GET_ADD_ON_PRODUCTS_FAILURE,
})((payload: GetAddOnProducts['request']['payload']) => ({
  payload,
}));

export const clearAddOnProducts = createAsyncAction<ClearAddOnProducts>({
  request: SubscriptionActionTypes.CLEAR_ADD_ON_PRODUCTS,
})();

export const createAddOnSubscription =
  createAsyncAction<CreateAddOnSubscription>({
    request: SubscriptionActionTypes.CREATE_ADD_ON_SUBSCRIPTION_REQUEST,
  })((payload: CreateAddOnSubscription['request']['payload']) => ({
    payload,
  }));

export const updateAddOnSubscription =
  createAsyncAction<UpdateAddOnSubscription>({
    request: SubscriptionActionTypes.UPDATE_ADD_ON_SUBSCRIPTION_REQUEST,
  })((payload: UpdateAddOnSubscription['request']['payload']) => ({
    payload,
  }));

export const cancelAddOnSubscription =
  createAsyncAction<CancelAddOnSubscription>({
    request: SubscriptionActionTypes.CANCEL_ADD_ON_SUBSCRIPTION_REQUEST,
  })((payload: CancelAddOnSubscription['request']['payload']) => ({
    payload,
  }));

export const getAddOnSubscriptionPaymentPreview =
  createAsyncAction<GetAddOnSubscriptionPaymentPreview>({
    request:
      SubscriptionActionTypes.GET_ADD_ON_SUBSCRIPTION_PAYMENT_PREVIEW_REQUEST,
  })((payload: GetAddOnSubscriptionPaymentPreview['request']['payload']) => ({
    payload,
  }));

export const restoreAddOnSubscription =
  createAsyncAction<RestoreAddOnSubscription>({
    request: SubscriptionActionTypes.RESTORE_ADD_ON_SUBSCRIPTION_REQUEST,
  })((payload: RestoreAddOnSubscription['request']['payload']) => ({
    payload,
  }));
