import flatMap from 'lodash.flatmap';
import { FC } from 'react';

import { BrandingColourContext } from '@payaca/types/accountBrandTypes';
import { Account } from '@payaca/types/accountTypes';
import { JobLineItem, JobLineItemGroup } from '@payaca/types/jobContentTypes';
import { JobLineItemAttachment } from '@payaca/types/jobTypes';

import { Deal } from '@payaca/types/dealTypes';
import { sortByKey } from '@payaca/utilities/sortable';
import JobDocumentItemGroup from './JobDocumentItemGroup';
import './JobDocumentItemGroups.sass';

type Props = {
  account: Pick<Account, 'region'>;
  hideItemPrices: boolean;
  hideVat?: boolean;
  isEditable?: boolean;
  isProposition: boolean;
  jobLineItemGroups: JobLineItemGroup[];
  jobLineItems: JobLineItem[];
  jobLineItemAttachments: JobLineItemAttachment[];
  onUpdate: (payload: {
    jobLineItems: { jobLineItemId: number; isSelected: boolean }[];
  }) => void;
  brandingColourContext?: BrandingColourContext;
  dealVersion: Deal['version'];
  isAnyVatIncluded?: boolean;
};

const JobDocumentItemGroups: FC<Props> = ({
  account,
  hideItemPrices,
  hideVat = false,
  isEditable = false,
  isProposition,
  jobLineItemGroups,
  jobLineItems,
  jobLineItemAttachments,
  onUpdate,
  brandingColourContext,
  dealVersion,
  isAnyVatIncluded = true,
}: Props): JSX.Element => {
  return (
    <>
      {jobLineItemGroups.length > 0 &&
        jobLineItemGroups
          .sort(sortByKey('positionIndex'))
          .map((jobLineItemGroup, i) => {
            const jobLineItemsForGroup = jobLineItems
              .sort(sortByKey('positionIndex'))
              .filter((jobLineItem) =>
                jobLineItemGroup.jobLineItemIds.includes(jobLineItem.id)
              );
            const jobLineItemAttachmentIdsForGroup = flatMap(
              jobLineItems,
              (jobLineItem) => jobLineItem.jobLineItemAttachmentIds
            );
            const jobLineItemAttachmentsForGroup =
              jobLineItemAttachments.filter((jobLineItemAttachment) =>
                jobLineItemAttachmentIdsForGroup.includes(
                  jobLineItemAttachment.id
                )
              );
            return (
              <JobDocumentItemGroup
                key={i}
                jobLineItemGroup={jobLineItemGroup}
                jobLineItems={jobLineItemsForGroup}
                jobLineItemAttachments={jobLineItemAttachmentsForGroup}
                onUpdate={onUpdate}
                isEditable={isEditable}
                isProposition={isProposition}
                hideItemPrices={hideItemPrices}
                hideVat={hideVat}
                account={account}
                brandingColourContext={brandingColourContext}
                dealVersion={dealVersion}
                isAnyVatIncluded={isAnyVatIncluded}
              />
            );
          })}
    </>
  );
};

export default JobDocumentItemGroups;
