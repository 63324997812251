import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';
import {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  getFileType,
  getFileTypeIcon,
  getFileTypeIconDownload,
} from '@payaca/helpers/fileHelper';
import { JobAttachment as JobAttachmentType } from '@payaca/types/jobTypes';

const imageFileExtensions = ['.jpeg', '.jpg', '.png', '.webp'];

type Props = {
  file: JobAttachmentType | any;
  isUpload?: boolean;
  readOnly: boolean;
  onFileChange?: (change: any, remove: boolean) => void;
  isDownloadDisabled: boolean;
};

const JobAttachment: FunctionComponent<Props> = ({
  file,
  isUpload,
  readOnly,
  onFileChange,
  isDownloadDisabled,
}: Props): JSX.Element => {
  const [editingFile, setEditingFile] = useState(null);
  const nodeRef = useRef<HTMLInputElement>(null);
  const fileTypeIcon = useMemo(
    () =>
      isUpload || isDownloadDisabled
        ? getFileTypeIcon(file)
        : getFileTypeIconDownload(file),
    [file, isDownloadDisabled, isUpload]
  );

  const fileExtension = useMemo(
    () => getFileType(file.attachmentUrl || file.termUrl),
    [file]
  );

  const isImage = useMemo(
    () => imageFileExtensions.find((t) => t === fileExtension),
    [fileExtension]
  );

  const exitEditingMode = () => {
    document.removeEventListener('mousedown', handleEditModeClick, false);
    setEditingFile(null);
  };

  const enterEditingMode = (file: any) => {
    document.addEventListener('mousedown', handleEditModeClick, false);
    setEditingFile(file);
  };

  const handleEditModeClick = (e: any) => {
    if (nodeRef.current && nodeRef.current.contains(e.target)) {
      return;
    }
    exitEditingMode();
  };

  const onClickEdit = (file: any) => {
    if (editingFile === file) {
      exitEditingMode();
    } else {
      enterEditingMode(file);
    }
  };

  const onFileNameChange = (e: any, file: any) => {
    if (onFileChange) {
      const newFileName = e.target.value;
      onFileChange(
        {
          ...file,
          fileName: newFileName,
        },
        false
      );
    }
  };

  const keyPressed = (e: any) => {
    if (e.key === 'Enter') {
      exitEditingMode();
    }
  };

  const isEditable: boolean = useMemo(() => !!file.file, [file]); // locally uploaded file

  // locally uploaded
  const editMode: boolean = useMemo(
    () => editingFile === file.file,
    [editingFile, file]
  );

  // downloading and not readonly and not editmode
  const showDeleteIcon: boolean = useMemo(
    () => !editMode && !!isUpload && !readOnly,
    [editMode, isUpload, readOnly]
  );

  // downlaoding, not readony and editable (i.e. not uploaded yet)
  const showEditIcon: boolean = useMemo(
    () => !!isUpload && !readOnly && !!isEditable && !editMode,
    [isUpload, readOnly, isEditable, editMode]
  );

  // editable and in editmode
  const showInputEditInput: boolean = useMemo(
    () => !!isEditable && !!editMode,
    [isEditable, editMode]
  );

  const renderFileName = useCallback((): JSX.Element => {
    const fullFileName = isEditable
      ? file.fileName
      : `${file.fileName}${fileExtension || ''}`;
    return (
      <div className={`file-name-wrapper${showEditIcon ? ' editable' : ''}`}>
        {showInputEditInput ? (
          <input
            ref={nodeRef}
            className="file-label edit"
            type="text"
            value={file.fileName}
            onChange={(e) => onFileNameChange(e, file)}
            onKeyPress={(e) => keyPressed(e)}
          />
        ) : (
          <span>{fullFileName}</span>
        )}
        {showEditIcon && (
          <FontAwesomeIcon
            icon={faPen}
            className="edit-icon"
            onClick={() => {
              onClickEdit(file.file);
            }}
          />
        )}
      </div>
    );
  }, [
    file,
    fileExtension,
    isEditable,
    keyPressed,
    onClickEdit,
    onFileNameChange,
    showEditIcon,
    showInputEditInput,
  ]);

  const renderFile = useCallback((): JSX.Element => {
    return (
      <div className="file-wrapper">
        <div className="image-icon-container">
          {isImage ? (
            <img
              src={file.attachmentUrl}
              alt={`attachment ${file.fileName}`}
            ></img>
          ) : (
            <FontAwesomeIcon icon={fileTypeIcon} className="file-type-icon" />
          )}
        </div>
        {renderFileName()}
      </div>
    );
  }, [file, fileTypeIcon, isImage, renderFileName]);

  if (isUpload) {
    // uploading files and local editable
    return (
      <div className="job-attachment">
        {showDeleteIcon && !readOnly && (
          <FontAwesomeIcon
            icon={faTimes}
            className="delete-icon"
            onClick={() => {
              onFileChange && onFileChange(file, true);
            }}
          />
        )}
        {renderFile()}
      </div>
    );
  } else {
    // viewing and downloading files
    return (
      <div
        onClick={() =>
          !isDownloadDisabled ? FileSaver.saveAs(file.attachmentUrl) : null
        }
        className="job-attachment download"
      >
        <div className="background-colour" />
        {renderFile()}
      </div>
    );
  }
};

export default JobAttachment;
