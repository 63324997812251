import Conditional from '@payaca/components/conditional/Conditional';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import { FC, PropsWithChildren } from 'react';

export interface IProps {
  disabled?: boolean;
  onRemove?: () => void;
}

const DeviceCard: FC<PropsWithChildren<IProps>> = (props) => {
  const { children, onRemove } = props;

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body className="space-y-4">
        {children}

        <Conditional condition={!!onRemove}>
          <Button onClick={onRemove} variant={EBtnVariant.LinkInline}>
            Remove
          </Button>
        </Conditional>
      </Card.Body>
    </Card>
  );
};

export default DeviceCard;
