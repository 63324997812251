import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import './AddressLookupOrInputControl.sass';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import { faChevronRight, faPen } from '@fortawesome/free-solid-svg-icons';
import AddressInputControl from '@payaca/components/addressInputControl/AddressInputControl';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';

import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { Address } from '@payaca/types/locationTypes';
import AddressLookupControl from '../addressLookupControl/AddressLookupControl';

type Props = {
  inputStyleVariant?: InputStyleVariant;
  address: Address;
  addressFieldName?: string;
  touchedState?: { [fieldName: string]: boolean };
  validationState?: { [fieldName: string]: FieldValidationResult };
  onChange: (value: { [key: string]: any }) => void;
  onTouch?: (fieldName: string) => void;
  onComponentRenderChange?: () => void;
  onSelectLookupAddress?: () => void;
};

const AddressLookupOrInputControl: FunctionComponent<Props> = ({
  inputStyleVariant,
  address,
  addressFieldName,
  touchedState,
  validationState,
  onTouch,
  onChange,
  onComponentRenderChange,
  onSelectLookupAddress,
}: Props): JSX.Element | null => {
  // const [isLoadingExistingAddress, setIsLoadingExistingAddress] =
  //   useState(true);
  const [isEnteringAddress, setIsEnteringAddress] = useState(false);
  const [isEnteringAddressManually, setIsEnteringAddressManually] =
    useState(false);

  useEffect(() => {
    onComponentRenderChange && onComponentRenderChange();
  }, [isEnteringAddress, isEnteringAddressManually]);

  const addressAsString = useMemo(() => {
    if (!address) return;

    const addressElements = [];
    addressElements.push(address.line1);
    addressElements.push(address.line2);
    addressElements.push(address.city);
    addressElements.push(address.postcode);

    return addressElements.filter((x) => !!x).join(', ');
  }, [
    address,
    address?.postcode,
    address?.city,
    address?.line1,
    address?.line2,
  ]);

  useEffect(() => {
    if (!addressAsString || addressAsString?.length === 0) {
      setIsEnteringAddress(true);
    }
  }, [addressAsString]);

  return (
    <ResponsiveViewWrapper
      downBreakpointSm={400}
      className="address-lookup-or-input-control flex-container"
    >
      <React.Fragment>
        {!isEnteringAddressManually && !isEnteringAddress && (
          <>
            <div className="existing-address-container">
              <LabelValuePair label="Address" value={addressAsString} />
            </div>
            <div className="address-controls-container">
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => {
                  setIsEnteringAddress(true);
                  setIsEnteringAddressManually(true);
                }}
              >
                <FontAwesomeIcon icon={faPen} />
              </Button>
            </div>
          </>
        )}
        {isEnteringAddress && (
          <div className="address-input-container">
            {!isEnteringAddressManually && (
              <AddressLookupControl
                onChange={(value: { [key: string]: any }) => {
                  onChange(value);
                  setIsEnteringAddress(false);
                  onSelectLookupAddress?.();
                }}
                addressFieldName={addressFieldName}
                inputStyleVariant={inputStyleVariant}
                onEnterManually={() => setIsEnteringAddressManually(true)}
                label="Address"
              />
            )}
            {isEnteringAddressManually && (
              <>
                <AddressInputControl
                  styleVariant={inputStyleVariant}
                  address={address}
                  fieldNamePrefix={addressFieldName}
                  onChange={onChange}
                  onTouch={onTouch}
                  validationState={validationState}
                  touchedState={touchedState}
                />
                <div className="lookup-address-trigger-container">
                  <Button
                    iconAfter={faChevronRight}
                    styleVariant={ButtonStyleVariant.ANCHOR}
                    onClick={() => setIsEnteringAddressManually(false)}
                  >
                    Lookup address
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </React.Fragment>
    </ResponsiveViewWrapper>
  );
};

export default AddressLookupOrInputControl;
