import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import Button from '@payaca/components/button/Button';
import EntityCard from '@payaca/components/entityCard/EntityCard';
import Modal from '@payaca/components/modal/Modal';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';

import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { TimelogsPermissions } from '@payaca/permissions/timelogs/timelogs.permissions';

import { getRegion, getUserRoles } from '@/utils/stateAccessors';

import { useSelector } from '@/api/state';

import { useAccountUsers } from '@/utils/storeHooks';
import StatusBadge, {
  StatusBadgeState,
} from '@payaca/components/statusBadge/StatusBadge';
import UserIndicator from '@payaca/components/userIndicator/UserIndicator';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { archiveTimelog } from '@payaca/store/timelogs/timelogsActions';
import { PublicHydratedTimelog } from '@payaca/types/timelogs';
import {
  DurationUnit,
  getReadableStringFromDurationString,
} from '@payaca/utilities/timeUtilities';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import UpdateTimelogControl from '../updateTimelogControl/UpdateTimelogControl';
import './TimelogCard.sass';

interface Props {
  timelog: PublicHydratedTimelog;
  onUpdateTimelogSuccess?: () => void;
  onArchiveTimelogSuccess?: () => void;
}

const TimelogCard: FC<PropsWithChildren<Props>> = ({
  timelog,
  onUpdateTimelogSuccess,
  onArchiveTimelogSuccess,
}) => {
  const dispatch = useDispatch();
  const userRoles = useSelector(getUserRoles);
  const region = useSelector(getRegion);
  const users = useAccountUsers();
  const user = users.find(
    (x) => timelog.assigneeType == 'user' && x.id === +timelog.assigneePublicId
  );
  const [showUpdateTimelogModal, setShowUpdateTimelogModal] = useState(false);
  const [showArchiveTimelogModal, setShowArchiveTimelogModal] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);

  const archive = useCallback(() => {
    setIsArchiving(true);
    dispatch(
      archiveTimelog.request({
        timelogPublicId: timelog.publicId,
        callback: () => {
          setIsArchiving(false);
          setShowArchiveTimelogModal(false);
          onArchiveTimelogSuccess?.();
        },
      })
    );
  }, [timelog.publicId, onArchiveTimelogSuccess]);

  const currentUserId = useSelector((state: any) => {
    return state.users.myProfile.id;
  });

  const isMultiDay = useMemo(() => {
    if (!timelog.endTime) return false;
    return !moment(timelog.startTime).isSame(timelog.endTime, 'day');
  }, [timelog.startTime, timelog.endTime]);

  const durationString = useMemo(() => {
    return timelog.endTime
      ? moment
          .duration(moment(timelog.endTime).diff(moment(timelog.startTime)))
          .toISOString()
      : null;
  }, [timelog.startTime, timelog.endTime]);

  const quickActions = useMemo(() => {
    const isSelfAssigned =
      timelog.assigneeType === 'user' &&
      timelog.assigneePublicId === currentUserId.toString();

    return [
      ...(userHasRequiredPermission(userRoles, [
        TimelogsPermissions.UPDATE_TIMELOGS,
      ]) ||
      (userHasRequiredPermission(userRoles, [
        TimelogsPermissions.UPDATE_SELF_ASSIGNED_TIMELOGS,
      ]) &&
        isSelfAssigned)
        ? [
            {
              actionName: 'Edit',
              actionBehaviour: () => {
                setShowUpdateTimelogModal(true);
              },
            },
          ]
        : []),
      ...(userHasRequiredPermission(userRoles, [
        TimelogsPermissions.DELETE_TIMELOGS,
      ]) ||
      (userHasRequiredPermission(userRoles, [
        TimelogsPermissions.DELETE_SELF_ASSIGNED_TIMELOGS,
      ]) &&
        isSelfAssigned)
        ? [
            {
              actionName: 'Archive',
              actionBehaviour: () => {
                setShowArchiveTimelogModal(true);
              },
            },
          ]
        : []),
    ];
  }, [userRoles, currentUserId]);

  return (
    <EntityCard
      className="timelog-card"
      quickActionsConfig={{
        recordId: timelog.publicId,
        quickActions,
      }}
    >
      <ResponsiveViewWrapper
        className="timelog-card-content"
        downBreakpointSm={450}
      >
        {user && (
          <PermissionGuard
            renderIfHasPermissions={[AccountsPermissions.GET_USERS]}
          >
            <div className="assignee-indicator">
              <UserIndicator
                user={{
                  firstName: user?.firstname,
                  lastName: user?.lastname,
                  emailAddress: user?.email,
                  userColour: user?.userColour,
                  imgSrc: user?.avatarUrl,
                }}
              />
            </div>
          </PermissionGuard>
        )}
        <div className="time">
          {moment(timelog.startTime).format('DD MMM h:mma')}
          {timelog.endTime ? (
            <>
              {' '}
              -{' '}
              {moment(timelog.endTime).format(
                isMultiDay ? 'DD MMM h:mma' : 'h:mma'
              )}
            </>
          ) : (
            <>
              {' '}
              <StatusBadge state={StatusBadgeState.AMBER_PENDING}>
                Currently recording
              </StatusBadge>
            </>
          )}
        </div>
        <div className="duration">
          {durationString
            ? getReadableStringFromDurationString(durationString, {
                units: [DurationUnit.HOURS, DurationUnit.MINUTES],
                precision: 0,
                abbreviate: true,
                includeUnitsWithZeroValue: true,
                allowSingular: false,
              })
            : '-'}
        </div>
        {timelog.type && (
          <div className="type">
            <StatusBadge state={StatusBadgeState.BLUE_NEUTRAL}>
              {timelog.type.type}
            </StatusBadge>
          </div>
        )}
        <PermissionGuard
          renderIfHasPermissions={[
            TimelogsPermissions.GET_TIMELOG_COST_PER_HOUR,
          ]}
        >
          <>
            {timelog.costPerHour && (
              <div className="cost-per-hour">
                {currencyPrice(timelog.costPerHour, region)}/hr
              </div>
            )}
            {timelog.costPerHour && (
              <div className="calculated-cost">
                {timelog.calculatedCost ? (
                  <>{currencyPrice(timelog.calculatedCost, region)}</>
                ) : (
                  <>-</>
                )}
              </div>
            )}
          </>
        </PermissionGuard>
        {!!timelog.notes?.length && (
          <div className="notes">{timelog.notes}</div>
        )}
      </ResponsiveViewWrapper>

      <Modal
        title="Update timelog"
        isOpen={showUpdateTimelogModal}
        onClose={() => setShowUpdateTimelogModal(false)}
        size="xs"
      >
        <UpdateTimelogControl
          timelog={timelog}
          onUpdateTimelogSuccess={() => {
            setShowUpdateTimelogModal(false);
            onUpdateTimelogSuccess?.();
          }}
        />
      </Modal>
      <Modal
        title="Archive Timelog"
        isOpen={showArchiveTimelogModal}
        onClose={() => setShowArchiveTimelogModal(false)}
        size="xs"
        actions={
          <>
            <Button onClick={archive} isProcessing={isArchiving}>
              Archive
            </Button>
          </>
        }
      >
        <p>Are you sure you want to archive this timelog?</p>
      </Modal>
    </EntityCard>
  );
};

export default TimelogCard;
