import { FC, useState } from 'react';

import ConfirmModal from '../modal/ConfirmModal';

import { getModal } from '@/helpers/modalHelper';
import InfiniteLineItemsList from '@/ui/components/infiniteLineItemsList/InfiniteLineItemsList';
import Sidebar from '@payaca/components/plSidebar/Sidebar';

type Props = {
  isOpen: boolean;
  disabledItemIds: number[];
  onClose: () => void;
  onSelectItem: (itemId: number) => void;
};
const SelectItemModal: FC<Props> = ({
  isOpen,
  disabledItemIds,
  onClose,
  onSelectItem,
}: Props): JSX.Element => {
  const [showRepeatedItemConfirmation, setShowRepeatedItemConfirmation] =
    useState(false);
  const [itemId, setItemId] = useState<number>();

  return (
    <>
      <Sidebar
        title="Select an Item"
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <InfiniteLineItemsList
          onLineItemClick={({ id }) => {
            if (disabledItemIds.includes(+id)) {
              setItemId(+id);
              setShowRepeatedItemConfirmation(true);
              return;
            }

            onSelectItem(+id);
          }}
        />
      </Sidebar>

      <ConfirmModal
        {...getModal('ADD_REPEATED_ITEM')}
        onClose={() => {
          setItemId(undefined);
          setShowRepeatedItemConfirmation(false);
        }}
        secondaryAction={() => {
          setItemId(undefined);
          setShowRepeatedItemConfirmation(false);
        }}
        primaryAction={() => {
          onSelectItem(itemId as number);
          setItemId(undefined);
          setShowRepeatedItemConfirmation(false);
        }}
        open={showRepeatedItemConfirmation}
      />
    </>
  );
};

export default SelectItemModal;
