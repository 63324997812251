import { FC, useEffect, useState } from 'react';

import Modal from '@payaca/components/plModal/Modal';

import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import CurrencyInput from '@payaca/components/plInput/CurrencyInput';
import RawInput, {
  InputSizeVariant,
} from '@payaca/components/plInput/RawInput';
import Select, { SelectSizeVariant } from '@payaca/components/plSelect/Select';
import Switch, { SwitchSizeVariant } from '@payaca/components/plSwitch/Switch';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import { formatUrlWithProtocol } from '@payaca/helpers/urlHelper';
import { useQueryClient } from '@tanstack/react-query';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import useCreateOrUpdateSupplierMaterial from '../../../api/mutations/supplierMaterials/useCreateOrUpdateSupplierMaterial';
import useDeleteSupplierMaterial from '../../../api/mutations/supplierMaterials/useDeleteSupplierMaterial';
import materialKeys from '../../../api/queries/materials/keyFactory';
import useGetMyTaxRates from '../../../api/queries/me/useGetMyTaxRates';
import {
  CreateOrUpdateSupplierMaterialInput,
  SupplierMaterial as GqlSupplierMaterial,
  Material,
  Supplier,
  TaxRate,
} from '../../../gql/graphql';
import MaterialSelectionControl from '../materialSelectionControl/MaterialSelectionControl';
import SupplierSelectionControl from '../supplierSelectionControl/SupplierSelectionControl';
import ConfirmDeleteSupplierMaterialModal from './ConfirmDeleteSupplierMaterialModal';

type CreateOrUpdateSupplierMaterialMutationError = {
  response: {
    errors?: {
      extensions?: {
        code?: string;
      };
    }[];
  };
};
export type TFormState = CreateEditSupplierMaterial & {
  materialInternalId: number;
  price: number;
  taxRateId: string;
};
const materialFormSchema = z.object({
  id: z.string().optional(),
  materialId: z.string().optional(),
  supplierId: z.string().optional(),
  materialInternalId: z.number(),
  supplierInternalId: z.number(),
  price: z.number().int().positive(),
  url: z.string().optional(),
  reference: z.string().optional(),
  taxRateId: z.string(),
  isPreferredSupplier: z.boolean(),
});
const CreateEditSupplierMaterialBody: FC<
  Omit<Props, 'isOpen' | 'onPersistSupplierMaterialSuccess'> & {
    showConfirmDeleteModal: () => void;
    onSuccess: () => void;
  }
> = (props) => {
  const {
    onClose,
    onSuccess,
    supplierMaterial,
    isMaterialSelectionDisabled,
    isSupplierSelectionDisabled,
    showConfirmDeleteModal,
  } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const { defaultTaxRate, taxRates } = useGetMyTaxRates();
  const formMethods = useForm<TFormState>({
    resolver: zodResolver(materialFormSchema),
    defaultValues: {
      id: supplierMaterial?.id,
      materialId: supplierMaterial?.materialId,
      supplierId: supplierMaterial?.supplierId,
      materialInternalId: supplierMaterial?.materialInternalId,
      supplierInternalId: supplierMaterial?.supplierInternalId,
      reference: supplierMaterial?.reference,
      price: supplierMaterial?.price,
      url: supplierMaterial?.url,
      taxRateId: supplierMaterial?.taxRateId,
      isPreferredSupplier: supplierMaterial?.isPreferredSupplier,
    },
  });
  const { pushToast } = useToastContext();
  const { data: regionPreferences } = useGetMyRegionPreferences();

  const { mutateAsync: createOrUpdateSupplierMaterialMutation } =
    useCreateOrUpdateSupplierMaterial({
      onError: (error) => {
        const errorCode = (error as CreateOrUpdateSupplierMaterialMutationError)
          ?.response?.errors?.[0]?.extensions?.code;
        if (errorCode === 'DUPLICATE_SUPPLIER_MATERIAL') {
          pushToast({
            variant: 'white',
            icon: 'error',
            message: 'Failed to save. This Supplier Material already exists.',
          });
        }
      },
      onSettled: () => {
        setIsProcessing(false);
      },
    });

  const onSubmit = async (state: TFormState) => {
    if (isProcessing) {
      return;
    }
    setIsProcessing(true);
    const persistSupplierMaterialData: CreateOrUpdateSupplierMaterialInput = {
      materialId: state.materialInternalId.toString(),
      supplierId: state.supplierInternalId.toString(),
      price: state.price,
      reference: state.reference,
      taxRateId: state.taxRateId,
      isPreferredSupplier: state.isPreferredSupplier,
    };
    if (state.id) {
      persistSupplierMaterialData.id = state.id;
    }
    if (state.url) {
      persistSupplierMaterialData.url = formatUrlWithProtocol(state.url);
    }

    await createOrUpdateSupplierMaterialMutation(persistSupplierMaterialData);
    onSuccess();
    onClose();
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Modal.Body className="flex flex-col gap-4">
          <Field>
            <Field.Label>Supplier</Field.Label>
            <Controller
              name="supplierInternalId"
              render={({ field: { onChange, value } }) => (
                <SupplierSelectionControl
                  selectedSupplierId={value}
                  onChange={onChange}
                  enableSupplierCreation
                  excludeSystemManaged
                  canChangeSupplier={!isSupplierSelectionDisabled}
                />
              )}
            />
          </Field>
          <Field>
            <Field.Label>Material</Field.Label>
            <Controller
              name="materialInternalId"
              render={({ field: { onChange, value } }) => (
                <MaterialSelectionControl
                  selectedMaterialId={value}
                  onChange={onChange}
                  enableMaterialCreation={true}
                  canChangeMaterial={!isMaterialSelectionDisabled}
                />
              )}
            />
          </Field>
          <Field>
            <Field.Label>Material URL</Field.Label>
            <RawInput
              {...formMethods.register('url')}
              disabled={supplierMaterial?.isSystemManaged}
            />
          </Field>
          <Field>
            <Field.Label>Reference</Field.Label>
            <RawInput
              {...formMethods.register('reference')}
              disabled={supplierMaterial?.isSystemManaged}
            />
          </Field>
          <div className="row flex gap-x-4">
            <Field
              validationState={
                formMethods.formState.errors?.price
                  ? {
                      isValid: false,
                      validationMessages: ['Price is required'],
                    }
                  : undefined
              }
            >
              <Field.Label>Price (ex tax)</Field.Label>
              <Controller
                name="price"
                render={({ field: { onChange, value } }) => {
                  return (
                    <CurrencyInput
                      sizeVariant={InputSizeVariant.SM}
                      inputClassName="text-right"
                      value={value}
                      onChange={onChange}
                      disabled={supplierMaterial?.isSystemManaged}
                      currency={regionPreferences?.currency}
                    />
                  );
                }}
              />
            </Field>
            <Field
              validationState={
                formMethods.formState.errors.taxRateId
                  ? {
                      isValid: false,
                      validationMessages: ['Tax is required'],
                    }
                  : undefined
              }
            >
              <Field.Label>Tax</Field.Label>
              <Controller
                name="taxRateId"
                defaultValue={defaultTaxRate?.id}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      sizeVariant={SelectSizeVariant.SM}
                      value={value}
                      onChange={onChange}
                      options={(taxRates || [])
                        ?.filter((taxRate) => !taxRate.isReverseCharge)
                        .map((taxRate) => ({
                          label: taxRate.label,
                          value: taxRate.id,
                        }))}
                      disabled={supplierMaterial?.isSystemManaged}
                    />
                  );
                }}
              />
            </Field>
          </div>
          <Field>
            <Field.Label>Make preferred Supplier</Field.Label>
            <Controller
              name="isPreferredSupplier"
              defaultValue={false}
              render={({ field: { onChange, value } }) => {
                return (
                  <Switch
                    sizeVariant={SwitchSizeVariant.SM}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </Field>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Actions className="space-x-2">
            {!!supplierMaterial?.id && !supplierMaterial.isSystemManaged && (
              <Button
                onClick={() => showConfirmDeleteModal()}
                variant={EBtnVariant.Outline}
                colour={EBtnColour.Red}
              >
                Delete
              </Button>
            )}
            <Button type="submit" isProcessing={isProcessing}>
              Save
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </form>
    </FormProvider>
  );
};

export type CreateEditSupplierMaterial = Pick<
  GqlSupplierMaterial,
  'reference' | 'url'
> & {
  id?: GqlSupplierMaterial['id'];
  supplierId?: Supplier['id'];
  supplierInternalId: number;
  materialId?: Material['id'];
  materialInternalId?: number;
  price?: GqlSupplierMaterial['price']['unitPriceExcTax']['value'];
  taxRateId?: TaxRate['id'];
  isPreferredSupplier?: boolean;
  isSystemManaged: boolean;
};
type Props = {
  isOpen: boolean;
  onClose: () => void;
  onPersistSupplierMaterialSuccess?: () => void;
  supplierMaterial?: Partial<CreateEditSupplierMaterial>;
  isMaterialSelectionDisabled?: boolean;
  isSupplierSelectionDisabled?: boolean;
};
const CreateEditSupplierMaterialModal: FC<Props> = (props): JSX.Element => {
  const { isOpen, onClose, onPersistSupplierMaterialSuccess, ...rest } = props;
  const { mutateAsync: deleteSupplierMaterialMutation } =
    useDeleteSupplierMaterial();
  const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false);
  const queryClient = useQueryClient();

  const onUpdateSupplierMaterialSuccess = () => {
    if (props.supplierMaterial?.materialId) {
      void queryClient.invalidateQueries({
        queryKey: materialKeys.material(props.supplierMaterial.materialId),
      });
    }
    onPersistSupplierMaterialSuccess?.();
  };

  const confirmDelete = async () => {
    if (props.supplierMaterial?.id) {
      await deleteSupplierMaterialMutation({
        supplierMaterialId: props.supplierMaterial.id,
      });
    }

    onUpdateSupplierMaterialSuccess();
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      setIsConfirmDeleteModal(false);
    }
  }, [isOpen]);

  if (isConfirmDeleteModal) {
    return (
      <ConfirmDeleteSupplierMaterialModal
        isOpen={isOpen}
        onConfirm={confirmDelete}
        onClose={onClose}
      />
    );
  }
  return (
    <Modal isOpen={isOpen} title="Supplier Material" onClose={onClose}>
      <CreateEditSupplierMaterialBody
        showConfirmDeleteModal={() => setIsConfirmDeleteModal(true)}
        onClose={onClose}
        onSuccess={onUpdateSupplierMaterialSuccess}
        {...rest}
      />
    </Modal>
  );
};

export default CreateEditSupplierMaterialModal;
