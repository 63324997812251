import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import * as jobsActions from '@payaca/store/jobs/jobsActions';
import { progressProposalToInvoice } from '@payaca/store/proposals/proposalsActions';

import { useProposal } from '@payaca/store/hooks/appState';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import ValidationFeedbackBlock from '@payaca/components/validationFeedbackBlock/ValidationFeedbackBlock';

type Props = {
  proposalId: number;
  isOpen: boolean;
  onClose: () => void;
};

const ProgressToInvoiceModal: FC<Props> = ({
  proposalId,
  isOpen,
  onClose,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [validationResult, setValidationResult] = useState<{
    isValid: boolean;
    errors: string[];
  }>();
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [isProcessing, setIsProcessing] = useState(false);
  const proposal = useProposal(proposalId);

  const progressToInvoice = useCallback(() => {
    dispatch(
      progressProposalToInvoice.request({
        proposalId,
        callback: (invoiceId) => {
          setIsProcessing(false);
          history.push(
            `/deals/${proposal?.dealId}/invoices/${invoiceId}/edit/send`
          );
        },
        onErrorCallback: (errorMessages) => {
          setIsProcessing(false);
          if (errorMessages.length) {
            setErrorMessages(errorMessages);
          } else {
            setErrorMessages(['Something went wrong']);
          }
        },
      })
    );
  }, [proposalId, proposal]);

  const onGetJobValidationResultSuccess = useCallback(
    (validationResult: { isValid: boolean; errors: string[] }) => {
      setValidationResult(validationResult);
      if (validationResult.isValid) {
        progressToInvoice();
      } else {
        setIsProcessing(false);
      }
    },
    [progressToInvoice]
  );

  const handleButtonClick = useCallback(() => {
    setIsProcessing(true);
    setValidationResult(undefined);
    dispatch(
      jobsActions.requestGetJobValidationResult(
        proposalId,
        onGetJobValidationResultSuccess
      )
    );
  }, [onGetJobValidationResultSuccess, proposalId, dispatch]);

  return (
    <Modal
      title={'Are you sure?'}
      isOpen={isOpen}
      onClose={() => {
        setIsProcessing(false);
        setValidationResult(undefined);
        onClose();
      }}
      actions={
        <>
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            isProcessing={isProcessing}
            onClick={() => !isProcessing && handleButtonClick()}
          >
            Continue
          </Button>
          {validationResult && !validationResult.isValid && (
            <ValidationFeedbackBlock validationResults={[validationResult]} />
          )}
          {errorMessages?.length && (
            <ValidationFeedbackBlock
              validationResults={[{ isValid: false, errors: errorMessages }]}
            />
          )}
        </>
      }
    >
      <p>If you progress this proposal directly to an invoice...</p>
      <ul>
        <li>
          The proposal will not be sent to the customer, but will be treated as
          though it has been sent and accepted
        </li>
        <li>
          A draft invoice will be created for the full value of all selected
          items on the proposal
        </li>
      </ul>
    </Modal>
  );
};

export default ProgressToInvoiceModal;
