import { action } from 'typesafe-actions';

import { AuthActionTypes } from './authTypes';

import {
  AuthorisePayload,
  AuthWithAppleData,
  AuthWithGoogleData,
  AuthWithXeroData,
  RegisterOAuthPayload,
  RemoveOAuthPayload,
  SignUpAccountUpdateV2RequestPayload,
  SignUpUserWithEmailPayload,
  SignUpUserWithEmailV2RequestPayload,
  SignUpWithAppleData,
  SignUpWithGoogleData,
  SignUpWithXeroData,
  VerifyOAuthPayload,
  VerifyUserTokenRequestPayload,
} from '@payaca/types/authTypes';

export const authorise = (
  payload: AuthorisePayload,
  allow: boolean,
  callback: (err: any, resp: any) => void
) => {
  return action(AuthActionTypes.AUTHORISE, { payload, allow, callback });
};

export const logout = () => {
  return action(AuthActionTypes.LOGOUT);
};

export const clearAnalyticsUserId = () => {
  return action(AuthActionTypes.CLEAR_ANALYTICS_USER_ID);
};

export const registerOAuthClient = (
  payload: RegisterOAuthPayload,
  callback: (err: any, resp: any) => void
) => {
  return action(AuthActionTypes.REGISTER_O_AUTH_CLIENT, { payload, callback });
};

export const removeOAuthConnection = (
  payload: RemoveOAuthPayload,
  callback: (err: any, resp: any) => void
) => {
  return action(AuthActionTypes.REMOVE_O_AUTH_CONNECTION, {
    payload,
    callback,
  });
};

export const verifyOAuth = (
  payload: VerifyOAuthPayload,
  callback: (err: any, resp: any) => void
) => {
  return action(AuthActionTypes.VERIFY_O_AUTH, { payload, callback });
};

export const requestLogin = (
  email: string,
  password: string,
  isPayacaAdminLogin = false
) => {
  return action(AuthActionTypes.REQUEST_LOGIN, {
    email,
    password,
    isPayacaAdminLogin,
  });
};

export const loginSuccess = (token: string, refreshToken: string) => {
  return action(AuthActionTypes.LOGIN_SUCCESS, {
    token,
    refreshToken,
  });
};

export const loginFailure = (error: Error) => {
  return action(AuthActionTypes.LOGIN_FAILURE, null, null, error);
};

export const signUpSuccess = (token: string, refreshToken: string) => {
  return action(AuthActionTypes.SIGN_UP_SUCCESS, {
    token,
    refreshToken,
  });
};

export const signUpFailure = (error: Error) => {
  return action(AuthActionTypes.SIGN_UP_FAILURE, null, null, error);
};

export const requestRefreshAuthToken = () => {
  return action(AuthActionTypes.REQUEST_REFRESH_AUTH_TOKEN);
};

export const refreshAuthTokenSuccess = (
  token: string,
  refreshToken: string
) => {
  return action(AuthActionTypes.REFRESH_AUTH_TOKEN_SUCCESS, {
    token,
    refreshToken,
  });
};

export const refreshAuthTokenFailure = (error: Error) => {
  return action(AuthActionTypes.REFRESH_AUTH_TOKEN_FAILURE, null, null, error);
};

export const storeTokens = (token: string, refreshToken: string) => {
  return action(AuthActionTypes.STORE_TOKENS, {
    token,
    refreshToken,
  });
};

export const storeTokensSuccess = () => {
  return action(AuthActionTypes.STORE_TOKENS_SUCCESS);
};

export const requestLoginWithApple = (data: AuthWithAppleData) => {
  return action(AuthActionTypes.REQUEST_LOGIN_WITH_APPLE, {
    data,
  });
};

export const requestLoginWithGoogle = (data: AuthWithGoogleData) => {
  return action(AuthActionTypes.REQUEST_LOGIN_WITH_GOOGLE, {
    data,
  });
};

export const requestLoginWithXero = (
  data: AuthWithXeroData,
  callback?: (errorMessage: string | null) => void
) => {
  return action(AuthActionTypes.REQUEST_LOGIN_WITH_XERO, {
    data,
    callback,
  });
};

export const requestLoginWithToken = (token: string) => {
  return action(AuthActionTypes.REQUEST_LOGIN_WITH_TOKEN, {
    token,
  });
};

export const requestSignUpWithAppleV1 = (data: SignUpWithAppleData) => {
  return action(AuthActionTypes.REQUEST_SIGN_UP_WITH_APPLE_V1, {
    data,
  });
};

export const requestSignUpWithGoogleV1 = (data: SignUpWithGoogleData) => {
  return action(AuthActionTypes.REQUEST_SIGN_UP_WITH_GOOGLE_V1, {
    data,
  });
};

export const requestSignUpWithXeroV1 = (data: SignUpWithXeroData) => {
  return action(AuthActionTypes.REQUEST_SIGN_UP_WITH_XERO_V1, {
    data,
  });
};

export const requestSignUpWithGoogle = (
  data: SignUpWithGoogleData,
  callback?: (errorMessage: string | null) => void
) => {
  return action(AuthActionTypes.REQUEST_SIGN_UP_WITH_GOOGLE, {
    data,
    callback,
  });
};

export const requestSignUpWithXero = (
  data: SignUpWithXeroData,
  callback: (errorMessage: string | null) => void
) => {
  return action(AuthActionTypes.REQUEST_SIGN_UP_WITH_XERO, {
    data,
    callback,
  });
};

export const clearAuthLoginSignUp = () => {
  return action(AuthActionTypes.CLEAR_AUTH_LOGIN_SIGN_UP);
};

// RESET PASSWORD
export const requestResetPassword = (email: string) => {
  return action(AuthActionTypes.REQUEST_RESET_PASSWORD, {
    email,
  });
};
export const resetPasswordSuccess = () => {
  return action(AuthActionTypes.RESET_PASSWORD_SUCCESS);
};
export const resetPasswordFailure = (error: Error) => {
  return action(AuthActionTypes.RESET_PASSWORD_FAILURE, null, null, error);
};
export const clearResetPassword = () => {
  return action(AuthActionTypes.CLEAR_RESET_PASSWORD);
};

// RESEND EMAIL VERIFICATION
export const requestResendEmailVerification = (
  email: string,
  callback: (error?: string) => void
) => {
  return action(AuthActionTypes.REQUEST_RESEND_EMAIL_VERIFICATION, {
    email,
    callback,
  });
};
export const resendEmailVerificationSuccess = () => {
  return action(AuthActionTypes.RESEND_EMAIL_VERIFICATION_SUCCESS);
};
export const resendEmailVerificationFailure = (
  error: Error,
  errorMessage: string
) => {
  return action(
    AuthActionTypes.RESEND_EMAIL_VERIFICATION_FAILURE,
    { errorMessage },
    null,
    error
  );
};

// SIGN UP WITH EMAIL
export const requestSignUpWithEmail = (
  signUpUserWithEmailPayload: SignUpUserWithEmailPayload
) => {
  return action(AuthActionTypes.REQUEST_SIGN_UP_WITH_EMAIL, {
    signUpUserWithEmailPayload,
  });
};
export const signUpWithEmailSuccess = () => {
  return action(AuthActionTypes.SIGN_UP_WITH_EMAIL_SUCCESS);
};
export const signUpWithEmailFailure = (error: Error, errorMessage: string) => {
  return action(
    AuthActionTypes.SIGN_UP_WITH_EMAIL_FAILURE,
    { errorMessage },
    null,
    error
  );
};
export const clearSignUpWithEmail = () => {
  return action(AuthActionTypes.CLEAR_SIGN_UP_WITH_EMAIL);
};

// SIGN UP WITH EMAIL V2
export const requestSignUpWithEmailV2 = (
  signUpUserWithEmailV2Payload: SignUpUserWithEmailV2RequestPayload,
  callback: (error?: string) => void
) => {
  return action(AuthActionTypes.REQUEST_SIGN_UP_WITH_EMAIL_V2, {
    signUpUserWithEmailV2Payload,
    callback,
  });
};

// UPDATE ACCOUNT V2
export const requestSignUpAccountUpdateV2 = (
  signUpAccountUpdateV2Payload: SignUpAccountUpdateV2RequestPayload,
  callback: (error?: string) => void
) => {
  return action(AuthActionTypes.REQUEST_SIGN_UP_ACCOUNT_UPDATE_V2, {
    signUpAccountUpdateV2Payload,
    callback,
  });
};
export const signUpAccountUpdateV2Success = () => {
  return action(AuthActionTypes.SIGN_UP_ACCOUNT_UPDATE_V2_SUCCESS);
};
export const signUpAccountUpdateV2Failure = (
  error: Error,
  errorMessage: string
) => {
  return action(
    AuthActionTypes.SIGN_UP_ACCOUNT_UPDATE_V2_FAILURE,
    { errorMessage },
    null,
    error
  );
};

// VALIDATE VERIFICATION TOKEN
export const requestValidateVerificationToken = (
  token: string,
  callback: (error: string | null, isPasswordRequired?: boolean) => void
) => {
  return action(AuthActionTypes.REQUEST_VALIDATE_VERIFICATION_TOKEN, {
    token,
    callback,
  });
};
export const validateVerificationTokenSuccess = () => {
  return action(AuthActionTypes.VALIDATE_VERIFICATION_TOKEN_SUCCESS);
};
export const validateVerificationTokenFailure = (
  error: Error,
  errorMessage: string
) => {
  return action(
    AuthActionTypes.VALIDATE_VERIFICATION_TOKEN_FAILURE,
    { errorMessage },
    null,
    error
  );
};

// VERIFIY USER TOKEN (Optionally with password)
export const requestVerifyUserToken = (
  verifyUserTokenPayload: VerifyUserTokenRequestPayload,
  callback: (error?: string) => void
) => {
  return action(AuthActionTypes.REQUEST_VERIFY_USER_TOKEN, {
    ...verifyUserTokenPayload,
    callback,
  });
};
export const verifyUserTokenSuccess = () => {
  return action(AuthActionTypes.VERIFY_USER_TOKEN_SUCCESS);
};
export const verifyUserTokenFailure = (error: Error, errorMessage: string) => {
  return action(
    AuthActionTypes.VERIFY_USER_TOKEN_FAILURE,
    { errorMessage },
    null,
    error
  );
};
