import { FC } from 'react';

type Props = {
  className?: string;
};
const LinkIcon: FC<Props> = ({ className }: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.22 20.22"
      className={`link-icon ${className ? className : ''}`}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M15.58,0a4.56,4.56,0,0,1,4.31,2.89,4.81,4.81,0,0,1-1.12,5.19c-1.4,1.36-2.79,2.74-4.15,4.15A4.72,4.72,0,0,1,8,12.37.78.78,0,1,1,9,11.24a3.14,3.14,0,0,0,4.14.2c.17-.14.32-.3.48-.45C15,9.6,16.44,8.22,17.81,6.81a3,3,0,0,0,.66-3.25,2.86,2.86,0,0,0-2.54-2,3,3,0,0,0-2.59.9L11,4.82A.78.78,0,1,1,9.85,3.76C10.42,3.18,11,2.6,11.58,2A11.83,11.83,0,0,1,13,.75,4.25,4.25,0,0,1,15.58,0Z" />
          <path d="M0,15.71a4.8,4.8,0,0,1,1.48-3.58Q3.58,10.06,5.64,8a4.71,4.71,0,0,1,6.62-.1A.78.78,0,1,1,11.19,9a3.1,3.1,0,0,0-4.42.07C5.3,10.51,3.82,12,2.39,13.45a2.92,2.92,0,0,0-.63,3.21,2.86,2.86,0,0,0,2.52,2,3,3,0,0,0,2.61-.9c.79-.79,1.58-1.57,2.36-2.36a.78.78,0,0,1,.87-.21.76.76,0,0,1,.3,1.24c-.24.26-.5.51-.75.76C9,17.8,8.44,18.45,7.78,19A4.66,4.66,0,0,1,.1,16.53C0,16.21,0,15.89,0,15.71Z" />
        </g>
      </g>
    </svg>
  );
};

export default LinkIcon;
