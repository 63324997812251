import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import { useSelector } from '@/api/state';
import { BulkMarkInvoicesAsPaidModal } from '@/ui/components/bulkMarkInvoicesAsPaidModal/BulkMarkInvoicesAsPaidModal';
import InvoiceStatusBadge from '@/ui/components/invoiceStatusBadge/InvoiceStatusBadge';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { useAccount } from '@/utils/storeHooks';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import { SelectOption } from '@payaca/components/plSelect/Select';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  DateFormats,
  getInternationalDateFormatByLocale,
} from '@payaca/helpers/internationalHelper';
import { PaymentsPermissions } from '@payaca/permissions/payments/payments.permissions';
import { getListedInvoices } from '@payaca/store/invoices/invoicesActions';
import { GetListedInvoicesRequestData } from '@payaca/store/invoices/invoicesTypes';
import {
  ListedInvoice,
  ReadableInvoiceStatus,
} from '@payaca/types/invoiceTypes';
import { ListViewPage, SortDirection } from '@payaca/types/listViewTypes';
import { format, startOfDay } from 'date-fns';
import { useDispatch } from 'react-redux';
import QuickbooksLogo from '../../../assets/images/quickbooks.svg';
import XeroLogo from '../../../assets/images/xero.svg';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

export const InvoicesPage: FC = (): JSX.Element | null => {
  return (
    <AuthenticatedPageWrapper className="bg-gray-50">
      <div className="p-4 pb-6">
        <List />
      </div>
    </AuthenticatedPageWrapper>
  );
};

const invoiceStatusOptions: SelectOption<ReadableInvoiceStatus>[] =
  Object.entries(ReadableInvoiceStatus).map(([_, status]) => ({
    label: status,
    value: status,
  }));

const List: FC = () => {
  const dispatch = useDispatch();
  const account = useAccount();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requiresPersistSearchTerm, setRequiresPersistSearchTerm] =
    useState<boolean>(false);
  const [selectedInvoices, setSelectedInvoices] = useState<ListedInvoice[]>([]);
  const [showBulkMarkAsPaidModal, setShowBulkMarkAsPaidModal] = useState(false);
  const { data: accountRegionPreferences } = useGetMyRegionPreferences();

  const hasXeroConnection = useSelector((state: any) => {
    return !!state.users.myProfile.accounts[0].integrations?.xero;
  });

  const hasQuickbooksConnection = useSelector((state: any) => {
    return !!state.users.myProfile.accounts[0].integrations?.quickbooks;
  });

  const [requestData, setRequestData] = useState<GetListedInvoicesRequestData>({
    pageSize: 100,
    pageNumber: 1,
    sortBy: 'createdAt',
    sortDirection: SortDirection.DESCENDING,
  });
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [listedInvoicesPage, setListedInvoicesPage] =
    useState<ListViewPage<ListedInvoice>>();

  useEffect(() => {
    requestGetListedInvoices(requestData);
  }, [requestData]);

  const requestGetListedInvoices = useCallback(
    (requestData: GetListedInvoicesRequestData) => {
      setSelectedInvoices([]);
      setIsLoading(true);
      dispatch(
        getListedInvoices.request({
          requestData: requestData,
          callback: (data) => {
            setListedInvoicesPage(data);
            setIsLoading(false);
          },
        })
      );
    },
    []
  );

  const overdueCutoff = useMemo(() => startOfDay(new Date()), []);

  useEffect(() => {
    if (requiresPersistSearchTerm) {
      setRequestData({ ...requestData, searchTerm, pageNumber: 1 });
      setRequiresPersistSearchTerm(false);
    }
  }, [requiresPersistSearchTerm]);

  const shouldDisableItem = useCallback(
    (invoice: ListedInvoice) => {
      if (
        [
          ReadableInvoiceStatus.DRAFT,
          ReadableInvoiceStatus.PAID,
          ReadableInvoiceStatus.VOID,
        ].includes(invoice.readableStatus)
      ) {
        return {
          disabled: true,
        };
      }

      if (
        selectedInvoices.length > 0 &&
        invoice.customer?.id != selectedInvoices[0].customer.id
      ) {
        return {
          disabled: true,
        };
      }

      return { disabled: false };
    },
    [selectedInvoices]
  );

  return (
    <>
      <ManageableItemsList>
        <ManageableItemsList.HeaderBar heading="Invoices" />
        <ManageableItemsList.ActionBar>
          <ManageableItemsList.ActionBar.SearchInput
            value={searchTerm}
            onChange={(value) => setSearchTerm(value)}
            onChangeTimeout={() => setRequiresPersistSearchTerm(true)}
            onBlur={() => setRequiresPersistSearchTerm(true)}
            changeTimeoutMs={300}
          />
          <ManageableItemsList.ActionBar.BasicFilter
            options={invoiceStatusOptions}
            value={requestData.statuses || []}
            onChange={(value) =>
              setRequestData({ ...requestData, statuses: value, pageNumber: 1 })
            }
          />
          <PermissionGuard
            renderIfHasPermissions={[
              PaymentsPermissions.BULK_MARK_INVOICES_PAID,
            ]}
          >
            <ManageableItemsList.ActionBar.GlobalAction
              onClick={() => setShowBulkMarkAsPaidModal(true)}
              tooltipContent="Select some invoices to perform this action"
              disabled={selectedInvoices.length === 0}
              enabled={selectedInvoices.length === 0}
            >
              Mark as paid
            </ManageableItemsList.ActionBar.GlobalAction>
          </PermissionGuard>
        </ManageableItemsList.ActionBar>
        <ManageableItemsList.Table
          items={listedInvoicesPage?.items || []}
          uniqueKey="id"
          isLoading={!listedInvoicesPage && isLoading}
          selectedItems={selectedInvoices}
          setSelectedItems={setSelectedInvoices}
          shouldDisableItem={shouldDisableItem}
          onClickRow={(invoice) =>
            history.push(`/deals/${invoice.deal.id}/invoices/${invoice.id}`)
          }
        >
          <ManageableItemsList.Table.Column
            header="Invoice"
            field="reference"
          />
          <ManageableItemsList.Table.Column
            header="Project"
            field="deal.reference"
            render={(_, invoice) => (
              <div
                className="max-w-[100px] truncate"
                onClick={(e) => e.stopPropagation()}
              >
                <Link to={`/deals/${invoice.deal.id}`}>
                  {invoice.deal.reference}
                </Link>
              </div>
            )}
          />
          <ManageableItemsList.Table.Column
            header="Customer"
            field="customer.name"
            render={(_, invoice) => (
              <div
                className="max-w-[300px] truncate"
                onClick={(e) => e.stopPropagation()}
              >
                <Link to={`/customers/${invoice.customer.id}`}>
                  {invoice.customer.name}
                </Link>
              </div>
            )}
          />
          <ManageableItemsList.Table.Column
            header="Status"
            field="readableStatus"
            render={(_, invoice) =>
              invoice.readableStatus && (
                <InvoiceStatusBadge status={invoice.readableStatus} />
              )
            }
          />
          <ManageableItemsList.Table.Column
            header="Total"
            field="totalValue"
            render={(_, invoice) =>
              currencyPrice(invoice.totalValue, account?.region)
            }
          />
          <ManageableItemsList.Table.Column
            header="Outstanding"
            field="dueValue"
            render={(_, invoice) =>
              [
                ReadableInvoiceStatus.PAID,
                ReadableInvoiceStatus.PARTIALLY_PAID,
                ReadableInvoiceStatus.SENT,
              ].includes(invoice.readableStatus)
                ? currencyPrice(invoice.dueValue, account?.region)
                : '-'
            }
          />
          <ManageableItemsList.Table.Column
            header="Due"
            field="dueAt"
            render={(_, invoice) => {
              return [
                ReadableInvoiceStatus.PARTIALLY_PAID,
                ReadableInvoiceStatus.SENT,
              ].includes(invoice.readableStatus) && invoice.dueAt ? (
                <span
                  className={
                    new Date(invoice.dueAt) < overdueCutoff
                      ? 'text-red-500'
                      : ''
                  }
                >
                  {format(
                    invoice.dueAt,
                    getInternationalDateFormatByLocale(
                      DateFormats.SHORT,
                      accountRegionPreferences?.locale
                    )
                  )}
                </span>
              ) : (
                '-'
              );
            }}
          />
          {/*  */}
          {(hasXeroConnection || hasQuickbooksConnection) && (
            <ManageableItemsList.Table.Column
              header="Synced"
              field=""
              render={(_, invoice) => {
                return (
                  <span className="flex flex-row gap-2">
                    {hasQuickbooksConnection && invoice.quickbooksInvoiceId && (
                      <a
                        href={`https://app.qbo.intuit.com/app/invoice?txnId=${invoice.quickbooksInvoiceId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="Quickbooks"
                          src={QuickbooksLogo}
                          className="max-h-6 w-6"
                        />
                      </a>
                    )}

                    {hasXeroConnection && invoice.xeroInvoiceId && (
                      <a
                        href={`https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${invoice.xeroInvoiceId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="Xero"
                          src={XeroLogo}
                          className="max-h-6 w-6"
                        />
                      </a>
                    )}
                  </span>
                );
              }}
            />
          )}
        </ManageableItemsList.Table>
        {listedInvoicesPage && (
          <ManageableItemsList.PaginationBar
            totalItems={listedInvoicesPage.totalItemCount}
            pageSize={listedInvoicesPage.pageSize}
            currentPage={listedInvoicesPage.pageNumber}
            onPageChange={(newPage) => {
              setRequestData({ ...requestData, pageNumber: newPage });
            }}
          />
        )}
      </ManageableItemsList>
      {!!selectedInvoices.length && (
        <BulkMarkInvoicesAsPaidModal
          invoices={selectedInvoices || []}
          customer={selectedInvoices[0]?.customer}
          isOpen={showBulkMarkAsPaidModal}
          onClose={() => setShowBulkMarkAsPaidModal(false)}
          onSuccess={() => {
            requestGetListedInvoices(requestData);
          }}
        />
      )}
    </>
  );
};
