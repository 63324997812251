import { FC } from 'react';
import GroupFieldBase, { TGroupFieldBaseProps } from './GroupFieldBase';

export type { TOption } from './GroupFieldBase';

export interface IProps extends TGroupFieldBaseProps<false> {}

const RadioGroupField: FC<IProps> = (props) => {
  return <GroupFieldBase {...props} />;
};

export default RadioGroupField;
