import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { useSelector } from '@/api/state';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import * as dashboardActions from '@payaca/store/dashboard/dashboardActions';
import { ActionRequiredPendingProposal } from '@payaca/types/dashboardTypes';
import moment from 'moment-timezone';
import DashboardActionRequiredCarousel from '../dashboardActionRequiredCarousel/DashboardActionRequiredCarousel';
import DashboardJobActionRequiredItem from '../dashboardJobActionRequiredItem/DashboardJobActionRequiredItem';

const ActionRequiredPendingProposalsCarousel: FC = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();

  const actionRequiredPendingProposals = useSelector(
    (state) => state.dashboard.actionRequiredPendingProposals
  );

  const isGettingActionRequiredPendingProposals = useSelector(
    (state) => state.dashboard.isGettingActionRequiredPendingProposals
  );

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  useEffect(() => {
    dispatch(dashboardActions.requestGetActionRequiredPendingProposals());
  }, [dispatch]);

  const actionRequiredItems = useMemo(() => {
    const nowMoment = moment();

    return actionRequiredPendingProposals?.map(
      (
        actionRequiredPendingProposal: ActionRequiredPendingProposal,
        index: number
      ) => {
        const validUntilMoment = moment(
          actionRequiredPendingProposal.validUntil
        );
        const isInvalid = nowMoment.isAfter(validUntilMoment);
        const daysBetween = validUntilMoment.diff(nowMoment, 'days');

        return (
          <DashboardJobActionRequiredItem
            key={index}
            onClick={() =>
              history.push(`deals/${actionRequiredPendingProposal.dealId}`)
            }
            isFlagged={isInvalid}
            tooltipContent={
              daysBetween > 0
                ? `Expires in ${daysBetween} days`
                : `Expired ${-daysBetween} days ago`
            }
            reference={`${actionRequiredPendingProposal.proposalType} ${actionRequiredPendingProposal.proposalReference}`}
            value={currencyPrice(
              actionRequiredPendingProposal.totalValueIncVat,
              account.region,
              false
            )}
            customerName={actionRequiredPendingProposal.customerName}
          />
        );
      }
    );
  }, [actionRequiredPendingProposals, history, account]);

  const title = useMemo(() => {
    return `Pending proposals${
      isGettingActionRequiredPendingProposals
        ? ''
        : ` (${
            actionRequiredItems?.length === 20
              ? '20+'
              : actionRequiredItems?.length || 0
          })`
    }`;
  }, [actionRequiredItems, isGettingActionRequiredPendingProposals]);

  return (
    <div className="action-required-pending-proposals-carousel">
      <DashboardActionRequiredCarousel
        actionRequiredItems={
          isGettingActionRequiredPendingProposals
            ? undefined
            : actionRequiredItems
        }
        title={title}
        description="These proposals have been sent but the customer hasn't accepted them yet - do you need to chase these up before they expire?"
        isLoading={isGettingActionRequiredPendingProposals}
      />
    </div>
  );
};

export default ActionRequiredPendingProposalsCarousel;
