import {
  AggregateValueChartData,
  SubscriptionUsageData,
} from '@payaca/types/analyticsTypes';
import { action } from 'typesafe-actions';
import { createAsyncAction } from '../utils';
import {
  AnalyticsActionTypes,
  GetKeyIndicatorsData,
  GetTagMetricAnalyticsData,
} from './analyticsTypes';

export const requestGetSentQuotesAggregateChartData = () => {
  return action(
    AnalyticsActionTypes.REQUEST_GET_SENT_QUOTES_AGGREGATE_CHART_DATA
  );
};

export const clearSentQuotesAggregateChartData = () => {
  return action(AnalyticsActionTypes.CLEAR_SENT_QUOTES_AGGREGATE_CHART_DATA);
};

export const getSentQuotesAggregateChartDataSuccess = (
  sentQuotesAggregateChartData: AggregateValueChartData
) => {
  return action(
    AnalyticsActionTypes.GET_SENT_QUOTES_AGGREGATE_CHART_DATA_SUCCESS,
    { sentQuotesAggregateChartData }
  );
};

export const getSentQuotesAggregateChartDataFailure = (error: Error) => {
  return action(
    AnalyticsActionTypes.GET_SENT_QUOTES_AGGREGATE_CHART_DATA_FAILURE,
    null,
    null,
    error
  );
};

export const requestGetSentQuoteValueAggregateChartData = () => {
  return action(
    AnalyticsActionTypes.REQUEST_GET_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA
  );
};

export const clearSentQuoteValueAggregateChartData = () => {
  return action(
    AnalyticsActionTypes.CLEAR_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA
  );
};

export const getSentQuoteValueAggregateChartDataSuccess = (
  sentQuoteValueAggregateChartData: AggregateValueChartData
) => {
  return action(
    AnalyticsActionTypes.GET_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA_SUCCESS,
    { sentQuoteValueAggregateChartData }
  );
};

export const getSentQuoteValueAggregateChartDataFailure = (error: Error) => {
  return action(
    AnalyticsActionTypes.GET_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA_FAILURE,
    null,
    null,
    error
  );
};

export const requestGetSentInvoicesAggregateChartData = () => {
  return action(
    AnalyticsActionTypes.REQUEST_GET_SENT_INVOICES_AGGREGATE_CHART_DATA
  );
};

export const clearSentInvoicesAggregateChartData = () => {
  return action(AnalyticsActionTypes.CLEAR_SENT_INVOICES_AGGREGATE_CHART_DATA);
};

export const getSentInvoicesAggregateChartDataSuccess = (
  sentInvoicesAggregateChartData: AggregateValueChartData
) => {
  return action(
    AnalyticsActionTypes.GET_SENT_INVOICES_AGGREGATE_CHART_DATA_SUCCESS,
    { sentInvoicesAggregateChartData }
  );
};

export const getSentInvoicesAggregateChartDataFailure = (error: Error) => {
  return action(
    AnalyticsActionTypes.GET_SENT_INVOICES_AGGREGATE_CHART_DATA_FAILURE,
    null,
    null,
    error
  );
};

export const requestGetSentInvoiceValueAggregateChartData = () => {
  return action(
    AnalyticsActionTypes.REQUEST_GET_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA
  );
};

export const clearSentInvoiceValueAggregateChartData = () => {
  return action(
    AnalyticsActionTypes.CLEAR_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA
  );
};

export const getSentInvoiceValueAggregateChartDataSuccess = (
  sentInvoiceValueAggregateChartData: AggregateValueChartData
) => {
  return action(
    AnalyticsActionTypes.GET_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA_SUCCESS,
    { sentInvoiceValueAggregateChartData }
  );
};

export const getSentInvoiceValueAggregateChartDataFailure = (error: Error) => {
  return action(
    AnalyticsActionTypes.GET_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA_FAILURE,
    null,
    null,
    error
  );
};

export const requestGetSubscriptionUsageData = () => {
  return action(AnalyticsActionTypes.REQUEST_GET_SUBSCRIPTION_USAGE_DATA);
};

export const clearSubscriptionUsageData = () => {
  return action(AnalyticsActionTypes.CLEAR_SUBSCRIPTION_USAGE_DATA);
};

export const getSubscriptionUsageDataSuccess = (
  subscriptionUsageData: SubscriptionUsageData
) => {
  return action(AnalyticsActionTypes.GET_SUBSCRIPTION_USAGE_DATA_SUCCESS, {
    subscriptionUsageData,
  });
};

export const getSubscriptionUsageDataFailure = (error: Error) => {
  return action(
    AnalyticsActionTypes.GET_SUBSCRIPTION_USAGE_DATA_FAILURE,
    null,
    null,
    error
  );
};

export const getTagMetricAnalyticsData =
  createAsyncAction<GetTagMetricAnalyticsData>({
    request: AnalyticsActionTypes.GET_TAG_METRIC_ANALYTICS_DATA_REQUEST,
  })((payload: GetTagMetricAnalyticsData['request']['payload']) => ({
    payload,
  }));

export const getKeyIndicatorsData = createAsyncAction<GetKeyIndicatorsData>({
  request: AnalyticsActionTypes.REQUEST_GET_KEY_INDICATORS_DATA,
})((payload: GetKeyIndicatorsData['request']['payload']) => ({
  payload,
}));
