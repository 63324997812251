import projectKeys from '@/api/queries/project/keyFactory';
import { useSelector } from '@/api/state';
import { getDeal } from '@/utils/stateAccessors';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import {
  TToast,
  useToastContext,
} from '@payaca/components/plToast/ToastContext';
import { clstx } from '@payaca/components/utils';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import UntitledIcon from '@payaca/untitled-icons';
import { singularPlural } from '@payaca/utilities/stringUtilities';
import { useQueryClient } from '@tanstack/react-query';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import './PopulateDealMaterialsListControl.sass';

type Props = {
  dealId: number;
  buttonStyleVariant?: ButtonStyleVariant;
  className?: string;
  onSyncComplete?: () => void;
};

const PopulateDealMaterialsListControl: FunctionComponent<Props> = ({
  dealId,
  className,
  onSyncComplete,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const [isPopulating, setIsPopulating] = useState(false);

  const deal = useSelector((state) => getDeal(state, dealId));
  const { pushToast } = useToastContext();
  const queryClient = useQueryClient();

  const handleSyncAutopopulatedMaterialsListMaterials = useCallback(() => {
    setIsPopulating(true);

    dispatch(
      materialsListActions.syncAutopopulatedMaterialsListMaterialsFromDeal.request(
        {
          dealId,
          callback: (response) => {
            pushToast(toastFromResponse(response));
            if (
              !response.createdMaterialsListMaterialsCount &&
              !response.updatedMaterialsListMaterialsCount &&
              !response.deletedMaterialsListMaterialsCount
            ) {
              setIsPopulating(false);
            } else {
              dispatch(requestGetDeal(dealId));

              if (deal?.materialsListId) {
                dispatch(
                  materialsListActions.requestGetMaterialsListWithRelatedEntities(
                    deal.materialsListId,
                    () => setIsPopulating(false)
                  )
                );
              }
              void queryClient.invalidateQueries(
                projectKeys.materialsList(dealId)
              );
              void queryClient.invalidateQueries(
                projectKeys.profitBreakdown(dealId)
              );

              onSyncComplete?.();
            }
          },
        }
      )
    );
  }, [dealId, deal?.materialsListId]);

  const shouldShowControl = useMemo(() => {
    let shouldShow = false;

    if (deal?.propositionIds?.length) {
      shouldShow = true;
    } else if (deal?.version == 1 && deal.invoiceIds?.length) {
      shouldShow = true;
    }

    return shouldShow;
  }, [deal]);

  if (!shouldShowControl) return null;

  return (
    <PermissionGuard
      renderIfHasPermissions={[
        MaterialsListPermissions.POPULATE_MATERIALS_LIST_FROM_DEAL,
      ]}
    >
      <div
        className={clstx(
          'populate-deal-materials-list-control flex items-center gap-x-2',
          className
        )}
      >
        {deal?.requiresSyncAutopopulatedMaterialsListMaterials && (
          <div className="inline-flex align-middle text-yellow-600">
            <UntitledIcon name="alert-triangle.3" className="mr-2 w-5" />

            <span>Materials list is out of sync</span>
          </div>
        )}
        <Button
          className=""
          variant={EBtnVariant.White}
          size={EBtnSize.Small}
          onClick={() =>
            !isPopulating && handleSyncAutopopulatedMaterialsListMaterials()
          }
        >
          Sync from Items
          <UntitledIcon
            name="refresh-cw-01"
            className={clstx('ml-1 w-5', isPopulating && 'animate-spin')}
          />
        </Button>
      </div>
    </PermissionGuard>
  );
};

export default PopulateDealMaterialsListControl;

const toastFromResponse = (syncResponse: {
  createdMaterialsListMaterialsCount: number;
  updatedMaterialsListMaterialsCount: number;
  deletedMaterialsListMaterialsCount: number;
}): TToast => {
  if (
    !syncResponse.createdMaterialsListMaterialsCount &&
    !syncResponse.updatedMaterialsListMaterialsCount &&
    !syncResponse.deletedMaterialsListMaterialsCount
  ) {
    return {
      colour: 'yellow',
      variant: 'soft',
      message:
        'No changes were made to the Materials required. You can still add Materials manually.',
    };
  } else {
    return {
      colour: 'teal',
      variant: 'soft',
      message: (
        <>
          The following changes were made to the Materials required:
          <ul>
            {!!syncResponse.createdMaterialsListMaterialsCount && (
              <li>
                {singularPlural(
                  syncResponse.createdMaterialsListMaterialsCount,
                  'Material ',
                  'Materials '
                )}
                added
              </li>
            )}
            {!!syncResponse.updatedMaterialsListMaterialsCount && (
              <li>
                {singularPlural(
                  syncResponse.updatedMaterialsListMaterialsCount,
                  'Material ',
                  'Materials '
                )}{' '}
                updated
              </li>
            )}
            {!!syncResponse.deletedMaterialsListMaterialsCount && (
              <li>
                {singularPlural(
                  syncResponse.deletedMaterialsListMaterialsCount,
                  'Material ',
                  'Materials '
                )}{' '}
                removed
              </li>
            )}
          </ul>
        </>
      ),
    };
  }
};
