import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { FunctionComponent } from 'react';

import './PopupMenu.sass';

type Props = {
  isOpen: boolean;
  anchorElement: any;
  options: {
    name: string;
    onClick: () => void;
    isProcessing?: boolean;
    isDisabled?: boolean;
  }[];
  onClose: () => void;
};

const PopupMenu: FunctionComponent<Props> = ({
  anchorElement,
  isOpen,
  onClose,
  options,
}: Props): JSX.Element | null => {
  const renderOptions = () => {
    return options.map((option: any, index: number) => {
      return (
        <MenuItem
          key={index}
          onClick={option.onClick}
          className={`menu-option${option.isProcessing ? ' processing' : ''}`}
          disabled={option.isDisabled}
        >
          {option.isProcessing && (
            <span className={'spinner-container'}>
              <FontAwesomeIcon
                icon={faCircleNotch}
                spin
                style={{ fontSize: '0.8rem' }}
              />
            </span>
          )}
          <span className="option-name">{option.name}</span>
        </MenuItem>
      );
    });
  };

  return (
    <Popper
      open={isOpen && !!anchorElement}
      anchorEl={anchorElement}
      role={undefined}
      transition
      placement="left-end"
      className="popup-menu"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList
                autoFocusItem={isOpen && !!anchorElement}
                id="menu-list-grow"
              >
                {renderOptions()}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default PopupMenu;
