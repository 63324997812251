import { Material } from '@/gql/graphql';
import Conditional from '@payaca/components/conditional/Conditional';
import List from '@payaca/components/list/List';
import EclipseBadge from '@payaca/components/plBadge/EclipseBadge';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import EmptyState from '@payaca/components/plEmptyState/EmptyState';
import { Nullish } from '@payaca/utilities/types';
import { MaterialCardWithTransition } from '../materialCard/MaterialCard';

export type LinkedMaterial = {
  id: Material['id'];
  name: Material['name'];
  suppliedBy: {
    price: {
      unitPriceExcTax: Material['suppliedBy'][number]['price']['unitPriceExcTax'];
    };
    supplier: {
      name: Material['suppliedBy'][number]['supplier']['name'];
    };
  }[];
  image?: Nullish<{
    thumbnailUrl?: Nullish<string>;
  }>;
  quantity: number;
};

export interface IProps<T extends LinkedMaterial> {
  linkedMaterials: T[];
  titleText?: string;
  onLinkMaterialsRequest?: () => void;
  onLinkMaterialsRequestText?: string;
  emptyStateText?: string;
  onChange?: (newLinkedMaterials: T[]) => void;
}

const LinkedMaterialsList = <T extends LinkedMaterial>(props: IProps<T>) => {
  const {
    linkedMaterials,
    onLinkMaterialsRequest,
    titleText = 'Linked Materials',
    onLinkMaterialsRequestText = 'Link Materials to Item',
    emptyStateText = 'No linked Materials',
    onChange,
  } = props;

  const numOfLinkedMaterials = linkedMaterials.reduce((acc, linkedMaterial) => {
    return acc + linkedMaterial.quantity;
  }, 0);

  return (
    <>
      <List
        items={linkedMaterials}
        prefix={
          <>
            <h4>
              {titleText} ({numOfLinkedMaterials})
            </h4>

            <Conditional
              condition={numOfLinkedMaterials > 0 && !!onLinkMaterialsRequest}
            >
              <Button
                className="w-full"
                variant={EBtnVariant.White}
                size={EBtnSize.Small}
                onClick={onLinkMaterialsRequest}
              >
                {onLinkMaterialsRequestText}
              </Button>
            </Conditional>
          </>
        }
        item={(material) => {
          return (
            <MaterialCardWithTransition
              key={material.id}
              show={material.quantity > 0}
              afterLeave={() => {
                onChange?.(linkedMaterials.filter((i) => i.quantity > 0));
              }}
              name={material.name}
              price={material.suppliedBy[0]?.price.unitPriceExcTax}
              thumbnailUrl={material.image?.thumbnailUrl || ''}
              quantity={material.quantity}
              onChangeQuantity={(newQuantity) => {
                onChange?.(
                  linkedMaterials.map((i) =>
                    i.id === material.id ? { ...i, quantity: newQuantity } : i
                  )
                );
              }}
            >
              <EclipseBadge
                badges={material.suppliedBy.map((i) => i.supplier.name)}
                variant="soft"
                colour="gray"
              />
            </MaterialCardWithTransition>
          );
        }}
      />

      <Conditional condition={numOfLinkedMaterials === 0}>
        <div className="my-auto text-center">
          <EmptyState iconName="link-broken-01.3" text={emptyStateText} />

          <Conditional condition={!!onLinkMaterialsRequest}>
            <Button
              className="mt-3.5"
              variant={EBtnVariant.Outline}
              size={EBtnSize.Small}
              onClick={onLinkMaterialsRequest}
            >
              {onLinkMaterialsRequestText}
            </Button>
          </Conditional>
        </div>
      </Conditional>
    </>
  );
};

export default LinkedMaterialsList;
