import { faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar, { SlotLabelContentArg } from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import React, { FC, useMemo } from 'react';

import {
  ScheduledEvent,
  ScheduledEventColourCoding,
} from '@payaca/types/scheduledEventsTypes';

import UserIndicator from '../userIndicator/UserIndicator';
import Calendar from './Calendar';
import SlotLabel from './SlotLabel';

import './UserResourceCalendar.sass';

type Props = {
  additionalCalendarProps?: any;
  onExistingEventSelect: (event: ScheduledEvent) => void;
  onDateSelect: (dateSelectInfo?: {
    beginAt: string;
    endAt?: string;
    userAssignments?: number[];
  }) => void;
  onViewableDateChange?: (dateInfo: any) => void;
  users?: any[];
  scheduledEvents: ScheduledEvent[];
  showUnassigned?: boolean;
  eventDrop?: any;
  eventResize?: any;
  onViewTypeChange?: (viewType: string) => void;
  initialView?: string;
  minTime?: string;
  maxTime?: string;
  hiddenDays?: number[];
  className?: string;
  scheduledEventColourCoding?: ScheduledEventColourCoding;
  calendarRef?: React.RefObject<FullCalendar>;
  isExternallyControlled?: boolean;
};
const UserResourceCalendar: FC<Props> = ({
  additionalCalendarProps = {},
  onExistingEventSelect,
  onDateSelect,
  onViewableDateChange,
  users,
  scheduledEvents,
  showUnassigned = true,
  eventDrop,
  eventResize,
  onViewTypeChange,
  initialView,
  minTime,
  maxTime,
  hiddenDays,
  className,
  scheduledEventColourCoding,
  calendarRef,
  isExternallyControlled,
}: Props): JSX.Element => {
  const parsedEventsWithResources = useMemo(() => {
    const eventsWithResources: any[] = [];
    scheduledEvents.forEach((se) => {
      // add event for each user
      const userAssignments = se.userAssignments;
      if (userAssignments?.length) {
        userAssignments.forEach((ua) => {
          eventsWithResources.push({
            ...se,
            userAssignment: ua,
          });
        });
      } else {
        // add event with unassigned user
        eventsWithResources.push({
          ...se,
          userAssignment: 'unassigned',
        });
      }
    });
    return eventsWithResources;
  }, [scheduledEvents]);

  const resources = useMemo(() => {
    const userResources = [];

    userResources.push(
      ...(users
        ? users.map((u: any, i) => ({
            id: u.id,
            title: u.id,
            sortIndex: i,
          }))
        : [])
    );

    if (showUnassigned) {
      userResources.push({
        id: 'unassigned',
        title: 0,
        sortIndex: userResources.length,
      });
    }

    return userResources;
  }, [users]);

  return (
    <Calendar
      isExternallyControlled={isExternallyControlled}
      additionalCalendarProps={{
        views: {
          day: {
            titleFormat: 'ddd, D MMM YYYY',
          },
        },
        resourceOrder: 'sortIndex',
        resources,
        resourceAreaWidth: '15%',
        resourceAreaHeaderContent: ' ',
        resourceLabelContent: (resource: {
          fieldValue: string;
          resource: any;
          view: any;
        }) => {
          const user = users?.find((u) => u.id === +resource.fieldValue);
          if (user) {
            return (
              <div className="user-resource">
                <UserIndicator
                  user={{
                    firstName: user.firstname,
                    lastName: user.lastname,
                    emailAddress: user.email,
                    userColour: user.userColour,
                    imgSrc: user.avatarUrl,
                  }}
                  size="sm"
                />
                <span> {user.name || user.firstName}</span>
              </div>
            );
          }
          return (
            <div className="user-resource">
              <div className="unassigned-user">
                <FontAwesomeIcon icon={faUserSlash} size={'xs'} />
              </div>
              <span>Unassigned</span>
            </div>
          );
        },
        ...additionalCalendarProps,
      }}
      className={`user-resource-calendar timeline-calendar${
        className ? ` ${className}` : ''
      }`}
      initialView={initialView}
      hasBoxShadow={false}
      headerToolbar={{
        left: 'today,jumpToDate',
        center: 'prev title next',
        right: 'resourceTimelineMonth,resourceTimelineWeek,resourceTimelineDay',
      }}
      onDateSelect={onDateSelect}
      onExistingEventSelect={onExistingEventSelect}
      onViewableDateChange={onViewableDateChange}
      plugins={[interactionPlugin, resourceTimelinePlugin]}
      scheduledEvents={parsedEventsWithResources}
      renderSlotLabelContent={(slotLabelContentArg: SlotLabelContentArg) => {
        return <SlotLabel slotLabelContentArg={slotLabelContentArg} />;
      }}
      eventDrop={eventDrop}
      eventResize={eventResize}
      onViewTypeChange={onViewTypeChange}
      minTime={minTime}
      maxTime={maxTime}
      hiddenDays={hiddenDays}
      scheduledEventColourCoding={scheduledEventColourCoding}
      externalCalendarRef={calendarRef}
    />
  );
};

export default UserResourceCalendar;
