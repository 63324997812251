import useGetConnectionApplication from '@/api/queries/connectionApplication/useGetConnectionApplication';
import { FormSection } from '@/api/rest/connectionApplication/useGetConnectionApplicationSchema';
import DeviceArrayCard from '@/ui/pages/connectionApplicationPage/components/DeviceArrayCard';
import DeviceCard from '@/ui/pages/connectionApplicationPage/components/DeviceCard';
import FieldSet from '@/ui/pages/connectionApplicationPage/FieldSet';
import {
  getDeviceItemForDeviceType,
  getSchemaDataForDevice,
} from '@/ui/pages/connectionApplicationPage/utils';
import Field from '@payaca/components/plField/Field';
import { sentenceCase } from '@payaca/utilities/stringUtilities';
import { get as _get } from 'lodash-es';
import { FC } from 'react';
import { useParams } from 'react-router';

export interface IProps {
  schemaSection: FormSection;
  definitions: Record<string, any>;
  fieldKey: string;
}

const DevicesFieldArrayReadOnly: FC<IProps> = (props) => {
  const { schemaSection, definitions, fieldKey } = props;

  /**
   * Router
   */
  const { connectionApplicationId } = useParams<{
    projectId: string;
    connectionApplicationId: string;
  }>();

  /**
   * Queries
   */
  const { data: persistedFormStateData } = useGetConnectionApplication(
    connectionApplicationId,
    false
  );

  const persistedFormState = persistedFormStateData?.connectionApplication.data;

  const devices = persistedFormState?.[fieldKey] as any[];

  return (
    <DeviceArrayCard>
      {devices.map((device, index) => {
        const deviceType = device.deviceType;

        if (!deviceType) {
          // Should not get here!
          return null;
        }

        const item = getDeviceItemForDeviceType(schemaSection, deviceType);

        const schemaData = getSchemaDataForDevice(
          schemaSection,
          definitions,
          deviceType
        );

        return (
          <DeviceCard key={index}>
            <Field>
              <Field.Label>
                {sentenceCase(item?.properties?.deviceType?.title || '')}
              </Field.Label>

              <p>
                {_get(persistedFormState, `${fieldKey}.${index}.deviceType`)}
              </p>
            </Field>

            {schemaData && (
              <FieldSet
                namespace={`${fieldKey}.${index}`}
                data={schemaData}
                definitions={definitions}
                readonly
              />
            )}
          </DeviceCard>
        );
      })}
    </DeviceArrayCard>
  );
};

export default DevicesFieldArrayReadOnly;
