import { FC } from 'react';

type Props = {
  label: any;
  isSelected: any;
  onClick: any;
};
const RadioButton: FC<Props> = ({
  label,
  isSelected,
  onClick,
}: Props): JSX.Element => {
  return (
    <div
      className={`section-radio-button ${isSelected ? 'selected' : ''}`}
      onClick={() => onClick()}
    >
      <div>{label}</div>
    </div>
  );
};

export default RadioButton;
