import { Scrollbars } from 'react-custom-scrollbars';

const Page = ({ children, pageClass, onClick }) => (
  <div
    className={'page' + (pageClass ? ` ${pageClass}` : '')}
    onClick={() => onClick && onClick()}
  >
    {children}
  </div>
);

export default Page;

const ContentWithScroll = ({ children }) => (
  <Scrollbars className="page-content scroll">{children}</Scrollbars>
);

const ContentNoScroll = ({ children, classes }) => (
  <div className={`page-content${classes ? ' ' + classes : ''}`}>
    {children}
  </div>
);

export const Content = ({ scroll, ...props }) =>
  scroll ? <ContentWithScroll {...props} /> : <ContentNoScroll {...props} />;
