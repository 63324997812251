import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortDirection } from '@payaca/types/listViewTypes';
import { FunctionComponent } from 'react';

import './SortDirectionControl.sass';

type Props = {
  selectedSortDirection?: SortDirection;
  onChange: (sortDirection: SortDirection) => void;
};

const SortDirectionControl: FunctionComponent<Props> = ({
  selectedSortDirection,
  onChange,
}: Props): JSX.Element => {
  return (
    <div className="sort-direction-control">
      <FontAwesomeIcon
        icon={faCaretUp}
        className={
          selectedSortDirection === SortDirection.ASCENDING ? 'active' : ''
        }
        onClick={() => {
          onChange(SortDirection.ASCENDING);
        }}
      />
      <FontAwesomeIcon
        icon={faCaretDown}
        className={
          selectedSortDirection === SortDirection.DESCENDING ? 'active' : ''
        }
        onClick={() => {
          onChange(SortDirection.DESCENDING);
        }}
      />
    </div>
  );
};

export default SortDirectionControl;
