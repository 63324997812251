import { FC, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import * as customerActions from '@payaca/store/customer/customerActions';
import * as dealsActions from '@payaca/store/deals/dealsActions';
import * as invoicesActions from '@payaca/store/invoices/invoicesActions';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as jobPaymentsActions from '@payaca/store/jobPayments/jobPaymentsActions';

import EditInvoiceControl from '@/ui/components/editInvoiceControl/EditInvoiceControl';
import EditInvoicePreviewButton from '@/ui/components/editInvoicePreviewButton/EditInvoicePreviewButton';
import EditInvoiceSaveFeedback from '@/ui/components/editInvoiceSaveFeedback/EditInvoiceSaveFeedback';
import InvoiceSidePanel from '@/ui/components/invoiceSidePanel/InvoiceSidePanel';
import PreviewAndSendInvoiceControl from '@/ui/components/previewAndSendInvoiceControl/PreviewAndSendInvoiceControl';
import Modal from '@payaca/components/modal/Modal';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import { useDeal, useInvoice } from '@payaca/store/hooks/appState';
import { requestGetUploadsForEntity } from '@payaca/store/uploads/uploadsActions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import './EditInvoicePage.sass';

export enum ViewType {
  EDIT = 'edit',
  SEND = 'send',
}

type Props = {
  invoiceId: number;
  dealId: number;
  viewType?: ViewType;
};

const EditInvoicePage: FC<Props> = ({
  invoiceId,
  dealId,
  viewType,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [view, setView] = useState<ViewType>(viewType || ViewType.EDIT);
  const [validationResult, setValidationResult] = useState<{
    isValid: boolean;
    errors: string[];
  }>();

  const invoice = useInvoice(invoiceId);

  const deal = useDeal(invoice?.dealId);

  useEffect(() => {
    if (!!invoice?.sentAt || !!invoice?.voidedAt) {
      history.push(`/deals/${dealId}/invoices/${invoice.id}`);
    }
  }, [invoice?.sentAt, invoice?.voidedAt]);

  useEffect(() => {
    if (invoice && invoice.bouncedAt) {
      setView(ViewType.SEND);
    }
  }, [invoice?.bouncedAt]);

  const navigateToView = useMemo(() => {
    return getUrlSearchParam(location.search, 'view');
  }, [location]);

  useEffect(() => {
    if (
      navigateToView === ViewType.SEND &&
      validationResult &&
      validationResult.isValid
    ) {
      setView(ViewType.SEND);
    }
  }, [navigateToView, validationResult]);

  useEffect(() => {
    dispatch(
      invoicesActions.requestGetInvoiceValidationResult(
        invoiceId,
        (validationResult) => {
          setValidationResult(validationResult);
        }
      )
    );
  }, [invoiceId]);

  //     return () => {
  //       dispatch(jobsActions.clearJobUpdateResults());
  //       dispatch(jobContentActions.clearJobLineItemUpdateResults());
  //       dispatch(jobContentActions.clearJobLineItemGroupUpdateResults());
  //     };
  //   }, []);

  useEffect(() => {
    if (invoiceId) {
      dispatch(invoicesActions.requestGetInvoice(invoiceId));

      dispatch(jobContentActions.requestGetJobLineItemsForInvoice(invoiceId));
      dispatch(requestGetUploadsForEntity(invoiceId, 'invoice'));
    }
  }, [invoiceId]);

  useEffect(() => {
    if (dealId) {
      dispatch(requestGetDeal(dealId));
      dispatch(invoicesActions.requestGetInvoiceLinesForDeal(dealId));
    }
  }, [dealId]);

  //   useEffect(() => {
  //     if (job?.jobContentId) {
  //       dispatch(
  //         jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
  //           job.jobContentId
  //         )
  //       );
  //     }
  //   }, [job?.jobContentId]);

  useEffect(() => {
    if (
      invoice &&
      !invoice.sentAt &&
      validationResult &&
      !validationResult.isValid
    ) {
      setView(ViewType.EDIT);
    }
  }, [validationResult, view, invoice]);

  //   const isJobMarkedAsSentSuccessfully = useSelector((state: any) => {
  //     return state.jobsStore.isJobMarkedAsSentSuccessfully;
  //   });

  //   const markJobAsSentErrorMessage: string = useSelector((state: any) => {
  //     return state.jobsStore.markJobAsSentErrorMessage;
  //   });

  useEffect(() => {
    if (invoice && invoice?.dealId) {
      dispatch(dealsActions.requestGetDeal(invoice?.dealId));
      dispatch(
        invoicesActions.requestGetPaymentReconciliationRecordsForDeal(
          invoice?.dealId
        )
      );
      dispatch(
        jobPaymentsActions.requestGetJobPaymentsForDeal(invoice?.dealId)
      );
    }
  }, [invoice?.dealId]);

  useEffect(() => {
    if (deal?.customerId) {
      dispatch(customerActions.requestGetCustomer(deal?.customerId));
    }
  }, [deal?.customerId]);

  const isInvoiceSentSuccessfully = useMemo(() => {
    if (!invoice) return false;
    return !invoice.bouncedAt && !!invoice.sentAt;
  }, [invoice]);

  //   const handleErrorMessageOnSendAttempt = useCallback(
  //     (errorMessage: string) => {
  //       if (!job || !customer) return;
  //       if (errorMessage === 'DEMO_ENDED') {
  //         dispatch(
  //           appActions.showModal(
  //             getModal('DEMO_ENDED', {
  //               primaryAction: () => {
  //                 dispatch(appActions.hideModal());
  //                 history.push('/upgradeAccount');
  //               },
  //               secondaryAction: () => {
  //                 IntercomAPI('show');
  //                 dispatch(appActions.hideModal());
  //               },
  //               onClose: () => dispatch(appActions.hideModal()),
  //             })
  //           )
  //         );
  //       } else if (errorMessage === 'You have exceeded your subscription plan') {
  //         dispatch(
  //           appActions.showModal(
  //             getModal('EXCEEDED_SUBSCRIPTION', {
  //               primaryAction: () => {
  //                 dispatch(appActions.hideModal());
  //                 history.push('/upgradeAccount');
  //               },
  //               secondaryAction: () => {
  //                 IntercomAPI('show');
  //                 dispatch(appActions.hideModal());
  //               },
  //               onClose: () => dispatch(appActions.hideModal()),
  //             })
  //           )
  //         );
  //       } else if (errorMessage === 'EMAIL_INACTIVE') {
  //         const primaryContact = getJobContactFromCustomer(
  //           customer,
  //           job?.contactId || null
  //         );
  //         dispatch(
  //           appActions.showModal(
  //             getModal('INACTIVE_EMAIL', {
  //               primaryAction: () => {
  //                 dispatch(appActions.hideModal());
  //               },
  //               text: [primaryContact?.emailAddress],
  //             })
  //           )
  //         );
  //       }
  //     },
  //     [dispatch, history, customer]
  //   );

  const navigateToDeal = useCallback(() => {
    history.replace({
      pathname: `/deals/${invoice?.dealId}/invoices-and-payments`,
    });
  }, [invoice]);

  //   const markAsSentCallback = useCallback(() => {
  //     if (isJobMarkedAsSentSuccessfully) {
  //       navigateToDeal();
  //     } else if (!isJobMarkedAsSentSuccessfully && markJobAsSentErrorMessage) {
  //       handleErrorMessageOnSendAttempt(markJobAsSentErrorMessage);
  //     }
  //   }, [
  //     markJobAsSentErrorMessage,
  //     isJobMarkedAsSentSuccessfully,
  //     handleErrorMessageOnSendAttempt,
  //     navigateToDeal,
  //   ]);

  return (
    <AuthenticatedPageWrapper
      className="edit-invoice-page"
      previousPageNavigationConfig={
        invoice
          ? {
              route: `/deals/${dealId}/invoices-and-payments`,
            }
          : undefined
      }
      sidebarContent={
        <div className="edit-invoice-sidebar">
          <EditInvoicePreviewButton
            invoiceId={invoiceId}
            proceedToPreview={() => {
              dispatch(invoicesActions.requestGetInvoiceLinesForDeal(dealId));
              setView(ViewType.SEND);
            }}
          />
          <EditInvoiceSaveFeedback invoiceId={invoiceId} />
          <InvoiceSidePanel invoiceId={invoiceId} />
        </div>
      }
    >
      {invoice && (
        <>
          <EditInvoiceControl invoiceId={invoiceId} />
          <Modal
            className="preview-and-send-invoice-modal"
            isOpen={view === ViewType.SEND}
            size="lg"
            onClose={() => setView(ViewType.EDIT)}
          >
            <PreviewAndSendInvoiceControl invoiceId={invoiceId} />
          </Modal>
        </>
      )}
    </AuthenticatedPageWrapper>
  );
};
export default EditInvoicePage;
