import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  CreateCustomFieldMutation as _CreateCustomFieldMutation,
  CreateCustomFieldInput,
} from '@/gql/graphql';

const CreateCustomFieldMutation = graphql(`
  mutation CreateCustomField($input: CreateCustomFieldInput!) {
    createCustomField(input: $input) {
      role
      id
      customFields {
        archivedAt
        id
        identifier
        isRequired
        label
        type
        options
      }
    }
  }
`);

const useCreateCustomField = (
  options?: UseMutationOptions<
    _CreateCustomFieldMutation,
    unknown,
    CreateCustomFieldInput
  >
) => {
  return useMutation({
    mutationFn: (variables) => {
      return gqlClient.request(CreateCustomFieldMutation, {
        input: variables,
      });
    },
    ...options,
  });
};

export default useCreateCustomField;
