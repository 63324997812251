import { FC } from 'react';

import { useHistory } from 'react-router';

import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';

import { PurchaseOrder } from '@/gql/graphql';
import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import Badge from '@payaca/components/plBadge/Badge';
import { checkUnreachable } from '@payaca/utilities/guards';
import { Link } from 'react-router-dom';

type PoForList = Pick<PurchaseOrder, 'id' | 'reference' | 'status'> & {
  supplier: Pick<PurchaseOrder['supplier'], 'name'>;
  totals: Pick<
    PurchaseOrder['totals'],
    'predictedValueExcTax' | 'purchasedValueExcTax'
  >;
  project?:
    | (Pick<NonNullable<PurchaseOrder['project']>, 'id' | 'reference'> & {
        customer?: Pick<
          NonNullable<NonNullable<PurchaseOrder['project']>['customer']>,
          'id' | 'name'
        > | null;
      })
    | null;
};

export const PurchaseOrdersManageableItemsListTable: FC<{
  purchaseOrders: PoForList[];
  includedColumns?: (
    | 'ref'
    | 'status'
    | 'supplier'
    | 'project'
    | 'project-customer'
    | 'forecast-cost'
    | 'purchase-cost'
  )[];
  isLoading?: boolean;
  returnToProjectOnBackNav?: boolean;
}> = ({
  purchaseOrders,
  includedColumns,
  isLoading,
  returnToProjectOnBackNav = false,
}) => {
  const history = useHistory();

  return (
    <ManageableItemsList.Table
      items={purchaseOrders}
      uniqueKey="id"
      onClickRow={(row) =>
        history.push(
          `/purchaseOrders/${row.id}${
            returnToProjectOnBackNav && row.project?.id
              ? `?projectId=${row.project.id}`
              : ''
          }`
        )
      }
      isLoading={isLoading}
    >
      {(!includedColumns?.length || includedColumns.includes('ref')) && (
        <ManageableItemsList.Table.Column<PoForList, 'reference'>
          header="PO"
          field="reference"
        />
      )}

      {(!includedColumns?.length || includedColumns.includes('status')) && (
        <ManageableItemsList.Table.Column<PoForList, 'status'>
          header="Status"
          field="status"
          render={(status) => {
            switch (status) {
              case 'PURCHASED':
                return (
                  <Badge colour="teal" variant="soft">
                    Purchased
                  </Badge>
                );
              case 'SENT':
                return (
                  <Badge colour="yellow" variant="soft">
                    Sent
                  </Badge>
                );
              case 'VOID':
                return (
                  <Badge colour="red" variant="soft">
                    Void
                  </Badge>
                );
              case 'DRAFT':
                return (
                  <Badge colour="gray" variant="soft">
                    Draft
                  </Badge>
                );
              default:
                checkUnreachable(status);
                return null;
            }
          }}
        />
      )}

      {(!includedColumns?.length || includedColumns.includes('project')) && (
        <ManageableItemsList.Table.Column<PoForList, 'project'>
          header="Project"
          field="project"
          render={(project) => {
            if (!project) return null;
            return (
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/deals/${project.id}`}
              >
                #{project?.reference}
              </Link>
            );
          }}
        />
      )}

      {(!includedColumns?.length ||
        includedColumns.includes('project-customer')) && (
        <ManageableItemsList.Table.Column<PoForList, 'project'>
          header="Customer"
          field="project"
          render={(project) => {
            if (!project?.customer) return null;
            return (
              <Link
                to={`/customers/${project.customer.id}`}
                onClick={(e) => e.stopPropagation()}
              >
                {project.customer.name}
              </Link>
            );
          }}
        />
      )}

      {(!includedColumns?.length || includedColumns.includes('supplier')) && (
        <ManageableItemsList.Table.Column<PoForList, 'supplier'>
          header="Supplier"
          field="supplier"
          render={(supplier) => {
            return (
              <Badge colour="gray" variant="soft">
                {supplier.name}
              </Badge>
            );
          }}
        />
      )}

      {(!includedColumns?.length ||
        includedColumns.includes('forecast-cost')) && (
        <ManageableItemsList.Table.Column<PoForList, 'totals'>
          className="text-right"
          header="Forecast Cost (ex VAT)"
          field="totals"
          render={(totals) => {
            return <CurrencyValue {...totals.predictedValueExcTax} />;
          }}
        />
      )}

      {(!includedColumns?.length ||
        includedColumns.includes('purchase-cost')) && (
        <ManageableItemsList.Table.Column<PoForList, 'totals'>
          className="text-right"
          header="Purchase Cost (ex VAT)"
          field="totals"
          render={(totals) => {
            if (!totals.purchasedValueExcTax) {
              return null;
            }

            return <CurrencyValue {...totals.purchasedValueExcTax} />;
          }}
        />
      )}
    </ManageableItemsList.Table>
  );
};
