import { DayHeaderContentArg } from '@fullcalendar/react';
import { FC, useMemo } from 'react';

import { format } from 'date-fns';

import './DayHeader.sass';

type Props = {
  dayHeaderContentArg: DayHeaderContentArg;
};
const DayHeader: FC<Props> = ({ dayHeaderContentArg }: Props): JSX.Element => {
  const dayOfWeekShort = useMemo(() => {
    return (
      <span className="day-of-week-short">
        {format(dayHeaderContentArg.date, 'EEE')}
      </span>
    );
  }, [dayHeaderContentArg]);

  const dayOfWeekLong = useMemo(() => {
    return (
      <span className="day-of-week-long">
        {format(dayHeaderContentArg.date, 'EEEE')}
      </span>
    );
  }, [dayHeaderContentArg]);

  const dayNumber = useMemo(() => {
    return (
      <span className="day-number">
        {format(dayHeaderContentArg.date, 'd')}
      </span>
    );
  }, [dayHeaderContentArg]);

  return (
    <div className={`day-header ${dayHeaderContentArg.view.type}`}>
      {['dayGridMonth', 'timeGridWeek'].includes(
        dayHeaderContentArg.view.type
      ) && dayOfWeekShort}
      {['timeGridWeek'].includes(dayHeaderContentArg.view.type) && dayNumber}
      {['timeGridDay'].includes(dayHeaderContentArg.view.type) && dayOfWeekLong}
      {['listWeek'].includes(dayHeaderContentArg.view.type) && (
        <div className="flex-container flex-center">
          <span className="flex-container flex-center flex-grow">
            {dayOfWeekLong}
          </span>
          <span className="date flex-container flex-center">
            {dayNumber}
            {format(dayHeaderContentArg.date, 'MMM yyyy')}
          </span>
        </div>
      )}
    </div>
  );
};

export default DayHeader;
