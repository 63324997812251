import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  CreateProjectNoteMutation as _CreateProjectNoteMutation,
  CreateProjectNoteInput,
} from '@/gql/graphql';

const CreateProjectNoteMutation = graphql(`
  mutation CreateProjectNote($input: CreateProjectNoteInput!) {
    createProjectNote(input: $input) {
      id
    }
  }
`);

const useCreateProjectNote = (
  options?: UseMutationOptions<
    _CreateProjectNoteMutation,
    unknown,
    CreateProjectNoteInput
  >
) => {
  return useMutation({
    mutationFn: (input: CreateProjectNoteInput) => {
      return gqlClient.request(CreateProjectNoteMutation, { input });
    },
    ...options,
  });
};

export default useCreateProjectNote;
