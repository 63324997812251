import { faPercent } from '@fortawesome/free-solid-svg-icons';
import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';

import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { setProposalDiscount } from '@payaca/store/proposals/proposalsActions';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Job } from '@payaca/types/jobTypesV2';
import { isNullish } from '@payaca/utilities/guards';
import './SetProposalDiscountModal.sass';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  proposalId: Job['id'];
  onSetProposalDiscountSuccess?: () => void;
};

const initialFormState = {};

const SetProposalDiscountModal: FC<Props> = ({
  isOpen,
  onClose,
  proposalId,
  onSetProposalDiscountSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  const onSubmit = useCallback(
    (discountPercentage: number, discountDescription?: string) => {
      setIsProcessing(true);
      dispatch(
        setProposalDiscount.request({
          proposalId,
          discountPercentage,
          discountDescription,
          callback: () => {
            setIsProcessing(false);
            onClose();
            onSetProposalDiscountSuccess?.();
          },
          onErrorCallback: () => {
            setIsProcessing(false);
          },
        })
      );
    },
    [proposalId]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <div className="set-proposal-discount-form-contents">
            <BasicField
              label="Discount percentage"
              name="discountPercentage"
              value={formState.discountPercentage}
              onChange={(change: { [key: string]: any }) =>
                onFieldChange({
                  discountPercentage: change.discountPercentage
                    ? Math.round(change.discountPercentage * 100) / 100
                    : null,
                })
              }
              iconAfter={faPercent}
              className="discount-percentage"
              type="number"
              additionalInputProps={{
                pattern: '[0-9]*',
                min: 0,
                max: 100,
                step: 1,
              }}
            />
            <BasicField
              label="Reason for discount"
              name="discountDescription"
              value={formState.discountDescription}
              onChange={onFieldChange}
            />
            <FeedbackBlock feedbackLevel={FeedbackLevel.ALERT}>
              Any existing Item discounts will be overwritten
            </FeedbackBlock>
            <div className="actions-container">
              <Button
                styleVariant={ButtonStyleVariant.OUTSIZE}
                isProcessing={isProcessing}
                isDisabled={isNullish(formState.discountPercentage)}
                onClick={() =>
                  onSubmit(
                    formState.discountPercentage,
                    formState.discountDescription
                  )
                }
              >
                Set{' '}
                {isNullish(formState.discountPercentage) ? (
                  ''
                ) : (
                  <>{formState.discountPercentage}% </>
                )}
                discount
              </Button>
            </div>
          </div>
        </>
      );
    },
    [isProcessing, onSubmit]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Set a discount"
      className="set-proposal-discount-modal"
    >
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
      />
    </Modal>
  );
};
export default SetProposalDiscountModal;
