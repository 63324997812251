import Conditional from '@payaca/components/conditional/Conditional';
import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import DonutChart from '@payaca/components/plCharts/Donut';
import { formatPrice } from '@payaca/helpers/financeHelper';
import { FC, useMemo } from 'react';

export interface IProps {
  className?: string;
  title: string;
  financialSummary?: {
    totalProjectValue: number;
    toBeInvoiced?: number;
    toBePaid?: number;
    paid?: number;
    currency: { code: string; exponent: number };
  };
}

const FinancialSummaryCard: FC<IProps> = (props) => {
  const { className, title, financialSummary } = props;

  const financialDonutValues = useMemo(() => {
    if (!financialSummary) return [];
    const values = [];
    const f = formatPrice(navigator.language);

    if (financialSummary.toBeInvoiced && financialSummary.toBeInvoiced > 0) {
      values.push({
        value: financialSummary.toBeInvoiced,
        label: `To be invoiced: ${f({
          priceInMinorCurrencyUnits: financialSummary.toBeInvoiced,
          exponent: financialSummary.currency.exponent,
          currencyCode: financialSummary.currency.code,
        })}`,
        colour: '#0098ff',
        textColour: '#ffffff',
      });
    }

    if (financialSummary.toBePaid && financialSummary.toBePaid > 0) {
      values.push({
        value: financialSummary.toBePaid,
        label: `To be paid: ${f({
          priceInMinorCurrencyUnits: financialSummary.toBePaid,
          exponent: financialSummary.currency.exponent,
          currencyCode: financialSummary.currency.code,
        })}`,
        colour: '#fabb00',
      });
    }

    if (financialSummary.paid && financialSummary.paid > 0) {
      values.push({
        value: financialSummary.paid,
        label: `Paid: ${f({
          priceInMinorCurrencyUnits: financialSummary.paid,
          exponent: financialSummary.currency.exponent,
          currencyCode: financialSummary.currency.code,
        })}`,
        colour: '#4ea83d',
        textColour: '#ffffff',
      });
    }

    return values;
  }, [financialSummary]);

  return (
    <Card className={className} sizeVariant={CardSizeVariant.SM}>
      <Card.Body className="@container/projectValue flex items-start justify-between">
        {financialSummary && (
          <table className="prose @[25rem]/projectValue:max-w-[16.667rem] w-full border-separate">
            <tbody className="supporting-body">
              <tr>
                <td colSpan={2}>
                  <h4>{title}</h4>
                </td>
                <td className="text-right font-semibold">
                  <CurrencyValue
                    value={financialSummary.totalProjectValue}
                    currency={financialSummary.currency}
                  />
                </td>
              </tr>

              <Conditional
                condition={typeof financialSummary.toBeInvoiced !== 'undefined'}
              >
                <tr>
                  <td>
                    <span className="inline-block h-3.5 w-3.5 rounded-full bg-cyan-600 align-middle"></span>
                  </td>
                  <td>To be invoiced</td>
                  <td className="text-right">
                    <CurrencyValue
                      value={financialSummary.toBeInvoiced!}
                      currency={financialSummary.currency}
                    />
                  </td>
                </tr>
              </Conditional>

              <Conditional
                condition={typeof financialSummary.toBePaid !== 'undefined'}
              >
                <tr>
                  <td>
                    <span className="inline-block h-3.5 w-3.5 rounded-full bg-yellow-500 align-middle"></span>
                  </td>
                  <td>To be paid</td>
                  <td className="text-right">
                    <CurrencyValue
                      value={financialSummary.toBePaid!}
                      currency={financialSummary.currency}
                    />
                  </td>
                </tr>
              </Conditional>

              <Conditional
                condition={typeof financialSummary.paid !== 'undefined'}
              >
                <tr>
                  <td>
                    <span className="inline-block h-3.5 w-3.5 rounded-full bg-teal-500 align-middle"></span>
                  </td>
                  <td>Paid</td>
                  <td className="text-right">
                    <CurrencyValue
                      value={financialSummary.paid!}
                      currency={financialSummary.currency}
                    />
                  </td>
                </tr>
              </Conditional>
            </tbody>
          </table>
        )}

        {financialSummary && (
          <div className="@[25rem]/projectValue:block my-auto hidden min-w-[50px] max-w-[100px]">
            <DonutChart
              width={'100%'}
              height={'auto'}
              slices={
                financialDonutValues.length === 0
                  ? [
                      {
                        value: 1,
                        label: 'No data',
                        colour: '#d1d5db',
                      },
                    ]
                  : financialDonutValues
              }
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default FinancialSummaryCard;
