import { FC, useEffect, useState } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { Search } from 'react-iconly';
import SearchField from '../searchField/SearchField';
import './HeaderSearchControl.sass';

type Props = {
  isSmallView?: boolean;
  onSearchTermChange: (searchTerm?: string) => void;
  onToggleSearchBarCollapse?: (isCollapsed: boolean) => void;
  placeholder?: string;
};
const HeaderSearchControl: FC<Props> = ({
  isSmallView = false,
  placeholder,
  onSearchTermChange,
  onToggleSearchBarCollapse,
}: Props): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    onToggleSearchBarCollapse && onToggleSearchBarCollapse(isCollapsed);
    if (isCollapsed) onSearchTermChange();
  }, [isCollapsed]);

  useEffect(() => {
    if (isSmallView) setIsCollapsed(true);
  }, [isSmallView]);

  return (
    <div
      className={`header-search-control ${isSmallView ? 'sm-view' : ''} ${
        isCollapsed ? 'collapsed' : ''
      }`}
    >
      <div className="header-search-control-inner">
        <div
          className="search-icon-container"
          onClick={() => setIsCollapsed(false)}
        >
          <Search set="light" />
        </div>
        {(!isCollapsed || !isSmallView) && (
          <>
            <SearchField
              styleVariant={InputStyleVariant.UNSTYLED}
              placeholder={placeholder}
              onSearchTermChange={onSearchTermChange}
              showSearchIcon={false}
            />
            {isSmallView && (
              <div
                className="close-icon-container"
                onClick={() => setIsCollapsed(true)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderSearchControl;
