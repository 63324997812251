import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';
import { TTableRowAction } from '@payaca/components/plAdvancedTable/AdvancedTable';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import { SortDirection } from '@payaca/types/listViewTypes';
import UntitledIcon from '@payaca/untitled-icons';
import useGetMyTaxRates from '../../../api/queries/me/useGetMyTaxRates';
import { LineItemsQuery } from '../../../gql/graphql';

export type ListedLineItem = LineItemsQuery['lineItems']['items'][number];

type Props = {
  isLoading: boolean;
  lineItems: ListedLineItem[];
  onSort: (field?: string, sortDirection?: SortDirection) => void;
  onClickRow?: (lineItem: ListedLineItem) => void;
  itemActions?: TTableRowAction<ListedLineItem>[];
  selectedLineItems?: ListedLineItem[];
  setSelectedLineItems?: (lineItems: ListedLineItem[]) => void;
};
const ListedLineItemsTable = (props: Props) => {
  const {
    isLoading,
    lineItems,
    onSort,
    onClickRow,
    itemActions,
    selectedLineItems,
    setSelectedLineItems,
  } = props;
  const { taxRates } = useGetMyTaxRates();

  return (
    <ManageableItemsList.Table
      isLoading={isLoading}
      items={lineItems}
      uniqueKey="id"
      onClickRow={onClickRow}
      selectedItems={selectedLineItems}
      setSelectedItems={setSelectedLineItems}
      itemActions={itemActions}
      enableSelectAll
    >
      <ManageableItemsList.Table.Column<ListedLineItem, 'thumbnailUrl'>
        header="Image"
        field="thumbnailUrl"
        render={(thumbnailUrl, row) => {
          if (!thumbnailUrl) {
            return (
              <div className="flex h-14 w-14 items-center justify-center">
                <UntitledIcon
                  name="image-01.3"
                  vectorEffect="non-scaling-stroke"
                  className="h-8 w-8 text-gray-700"
                />
              </div>
            );
          }

          return (
            <img
              className="h-14 w-14 rounded-md"
              alt={(row.name || row.description).slice(0, 50)}
              src={thumbnailUrl}
            />
          );
        }}
        className="w-[100px]"
      />
      <ManageableItemsList.Table.Column<ListedLineItem, 'name'>
        header="Internal reference"
        field="name"
        onSort={onSort}
        sortByName="NAME"
        className="max-w-[200px] truncate"
      />
      <ManageableItemsList.Table.Column<ListedLineItem, 'description'>
        header="Description"
        field="description"
        render={(description) => (
          <MarkdownLabel
            truncate
            limitLines={1}
            hideShowMoreButton
            markdown={description}
          />
        )}
        onSort={onSort}
        sortByName="DESCRIPTION"
        className="truncate"
      />
      <ManageableItemsList.Table.Column<ListedLineItem, 'price'>
        header="Price (ex tax)"
        field="price"
        render={(price) => {
          if (!price) {
            return;
          }
          return (
            <CurrencyValue
              value={price.unitPriceExcTax.value}
              currency={price.unitPriceExcTax.currency}
            />
          );
        }}
        className="w-[160px] min-w-[140px] text-right"
      />
      <ManageableItemsList.Table.Column<ListedLineItem, 'price'>
        header="Tax rate"
        field="price"
        render={(price) => {
          const taxRate = taxRates?.find(
            (taxRate) => taxRate.id === price?.taxRate?.id
          );
          if (!taxRate) {
            return;
          }

          return taxRate.label;
        }}
        className="w-[160px] max-w-[180px] truncate text-right"
      />
      <ManageableItemsList.Table.Column<ListedLineItem, 'price'>
        header="Price (inc tax)"
        field="price"
        render={(price) => {
          if (!price) {
            return;
          }
          return (
            <CurrencyValue
              value={price.unitPrice.value}
              currency={price.unitPrice.currency}
            />
          );
        }}
        className="w-[160px] min-w-[140px] text-right"
      />
    </ManageableItemsList.Table>
  );
};

export default ListedLineItemsTable;
