import { gqlClient } from '@/api/graphql-client';
import meKeys from '@/api/queries/me/keyFactory';
import { graphql } from '@/gql';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ComplianceFormPrefillDataQuery,
  GetMeQuery,
  ProjectQuery,
} from '../../../gql/graphql';
import projectKeys from '../project/keyFactory';
import complianceKeys from './keyFactory';

const GetComplianceFormPrefillData = graphql(`
  query ComplianceFormPrefillData($projectId: Int!) {
    me {
      user {
        account {
          name
        }
      }
    }
    project(id: $projectId) {
      customer {
        name
        primaryContact {
          email
          phone
        }
      }
      addresses {
        site {
          line1
          line2
          city
          country
          postalCode
        }
      }
      owner {
        fullName
        email
        phone
      }
    }
  }
`);

const useGetComplianceFormPrefillData = (projectId: number) => {
  const queryClient = useQueryClient();

  return useQuery({
    enabled: !!projectId,
    queryKey: complianceKeys.formPrefill(projectId),
    queryFn: () => {
      return gqlClient.request(GetComplianceFormPrefillData, {
        projectId,
      });
    },
    initialData: () => {
      const meData = queryClient.getQueryData<GetMeQuery>(meKeys.all);
      const projectData = queryClient.getQueryData<ProjectQuery>(
        projectKeys.overview(projectId)
      );

      if (!meData && !projectData) {
        return;
      }

      const initial: ComplianceFormPrefillDataQuery = {
        me: {
          user: {
            account: {
              ...meData?.me?.user.account,
              name: meData?.me?.user.account.name || '',
            },
          },
        },
        project: {
          ...projectData?.project,
          customer: {
            ...projectData?.project?.customer,
            name: projectData?.project?.customer?.name || '',
          },
          addresses: {
            site: projectData?.project?.addresses?.site || [],
          },
        },
      };
      return initial;
    },
  });
};

export default useGetComplianceFormPrefillData;
