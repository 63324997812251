import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import './RecordPaymentControl.sass';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelector } from '@/api/state';
import { getDeal, getInvoice } from '@/utils/stateAccessors';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Checkbox from '@payaca/components/checkbox/Checkbox';
import CurrencyField from '@payaca/components/currencyField/CurrencyField';
import DateTimeField from '@payaca/components/dateTimeField/DateTimeField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import {
  getAllowEmptyValidator,
  getDateRangeFieldValidator,
  getIsRequiredFieldValidator,
  getNumericalRangeFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import * as jobPaymentsActions from '@payaca/store/jobPayments/jobPaymentsActions';
import { RecordJobPaymentRequestData } from '@payaca/store/jobPayments/jobPaymentsTypes';
import { AccountRegions } from '@payaca/types/accountTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { PaymentMethodType } from '@payaca/types/jobPaymentTypes';

const paymentMethodOptions = [
  {
    label: `Bank transfer`,
    value: PaymentMethodType.BACS,
  },
  {
    label: 'Cash',
    value: PaymentMethodType.CASH,
  },
  {
    label: 'Cheque',
    value: PaymentMethodType.CHEQUE,
  },
  {
    label: 'Card',
    value: PaymentMethodType.CARD,
  },
  {
    label: 'Other',
    value: PaymentMethodType.OTHER,
  },
];

const getFieldValidators = (
  maxPaymentValue: number,
  region: AccountRegions
) => {
  const maxDate = new Date();
  maxDate.setMinutes(maxDate.getMinutes() + 10);

  const timestampDateRegionalFormat = getInternationalMomentDateFormatByRegion(
    DateFormats.TIMESTAMP,
    region
  );

  return {
    paymentValue: [
      getIsRequiredFieldValidator({ readableName: 'payment amount' }),
      getNumericalRangeFieldValidator(
        0,
        maxPaymentValue + 1,
        'payment amount',
        'payment amount must be greater than zero',
        `payment amount must not exceed ${currencyPrice(
          maxPaymentValue,
          region
        )}`
      ),
    ],
    paymentMethod: [
      getIsRequiredFieldValidator({ readableName: 'payment method' }),
    ],
    paymentDate: [
      getAllowEmptyValidator(
        getDateRangeFieldValidator(
          undefined,
          maxDate,
          undefined,
          timestampDateRegionalFormat
        )
      ),
    ],
  };
};

type Props = {
  outstandingPaymentValue: number;
  isDepositPayment: boolean;
  customerName: string;
  dealId: number;
  invoiceId?: number;
  canSendReceipt?: boolean;
  maxPaymentValue?: number;
  recordPaymentCallback?: () => void;
};

const RecordPaymentControl: FunctionComponent<Props> = ({
  outstandingPaymentValue,
  isDepositPayment,
  customerName,
  dealId,
  invoiceId,
  canSendReceipt = true,
  maxPaymentValue,
  recordPaymentCallback,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasCalledCallback, setHasCalledCallback] = useState(false);
  const isRecordingJobPayment = useSelector((state) => {
    return state.jobPayments.isRecordingJobPayment;
  });
  const isJobPaymentRecordedSuccessfully = useSelector((state) => {
    return state.jobPayments.isJobPaymentRecordedSuccessfully;
  });

  const isOutstandingPaymentDue = useMemo(() => {
    return !!outstandingPaymentValue;
  }, [outstandingPaymentValue]);

  const deal = useSelector((state) => {
    return getDeal(state, dealId);
  });

  const invoice = useSelector((state) => {
    if (!invoiceId) return;
    return getInvoice(state, invoiceId);
  });

  const region = useSelector(
    (state: any) => state.users.myProfile.accounts[0].region
  );

  useEffect(() => {
    if (
      isSubmitted &&
      !isRecordingJobPayment &&
      isJobPaymentRecordedSuccessfully &&
      recordPaymentCallback &&
      !hasCalledCallback
    ) {
      setHasCalledCallback(true);
      recordPaymentCallback();
    }
  }, [
    isSubmitted,
    isRecordingJobPayment,
    isJobPaymentRecordedSuccessfully,
    hasCalledCallback,
    recordPaymentCallback,
  ]);

  const onSubmit = useCallback(
    (recordJobPaymentRequestData: RecordJobPaymentRequestData) => {
      dispatch(
        jobPaymentsActions.requestRecordJobPayment(recordJobPaymentRequestData)
      );
      setIsSubmitted(true);
    },
    [dispatch]
  );

  const paymentTarget = useMemo(() => {
    if (invoice)
      return `invoice #${invoice.customReference || invoice.reference}`;
    if (deal) return `Project #${deal.customReference || deal.reference}`;
    return 'this Project';
  }, [deal, invoice]);

  const fieldValidators = useMemo(() => {
    return getFieldValidators(
      maxPaymentValue === undefined ? 10000000 : maxPaymentValue,
      region
    );
  }, [maxPaymentValue]);

  const canRecordPayment = useMemo(() => {
    return maxPaymentValue !== 0;
  }, [maxPaymentValue]);

  const shortDateRegionalFormat = useMemo(
    () => getInternationalMomentDateFormatByRegion(DateFormats.SHORT, region),
    [region]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      const paymentValueExceedsOutstanding =
        formState.paymentValue > outstandingPaymentValue;

      return (
        <React.Fragment>
          <div className="fields-container">
            <div className="flex-container">
              <div>
                <ValidatedFieldWrapper
                  validationResult={validationState.paymentValue}
                  isTouched={touchedState.paymentValue || false}
                >
                  <CurrencyField
                    styleVariant={InputStyleVariant.OUTSIZE}
                    name="paymentValue"
                    value={formState.paymentValue || 0}
                    isRequired={true}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    label="Payment amount"
                  />
                </ValidatedFieldWrapper>
              </div>
              <div>
                <ValidatedFieldWrapper
                  validationResult={validationState.paymentMethod}
                  isTouched={touchedState.paymentMethod || false}
                >
                  <DropdownField
                    styleVariant={InputStyleVariant.OUTSIZE}
                    options={paymentMethodOptions}
                    name="paymentMethod"
                    value={formState.paymentMethod || PaymentMethodType.BACS}
                    isRequired={true}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    label="Payment method"
                  />
                </ValidatedFieldWrapper>
              </div>
              <div>
                <ValidatedFieldWrapper
                  validationResult={validationState.paymentDate}
                  isTouched={touchedState.paymentDate || false}
                >
                  <DateTimeField
                    label="Payment date"
                    styleVariant={InputStyleVariant.OUTSIZE}
                    name="paymentDate"
                    value={formState.paymentDate}
                    isRequired={false}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    additionalPickerProps={{
                      maxDate: new Date(),
                      format: shortDateRegionalFormat,
                    }}
                  />
                </ValidatedFieldWrapper>
              </div>
            </div>
            <ValidatedFieldWrapper
              validationResult={validationState.note}
              isTouched={touchedState.note || false}
            >
              <BasicField
                additionalInputProps={{
                  maxLength: 150,
                }}
                styleVariant={InputStyleVariant.OUTSIZE}
                name="note"
                value={formState.note}
                isRequired={false}
                onChange={onFieldChange}
                onTouch={onFieldTouch}
                label="Internal note"
              />
            </ValidatedFieldWrapper>
          </div>

          {canSendReceipt && (
            <div>
              <div className="checkbox-container flex-container flex-center">
                <div>
                  <Checkbox
                    isChecked={
                      formState.sendReceipt === undefined
                        ? true
                        : formState.sendReceipt
                    }
                    onChange={() => {
                      onFieldChange({
                        sendReceipt: !formState.sendReceipt,
                      });
                      onFieldTouch && onFieldTouch('sendReceipt');
                    }}
                  />
                </div>
                <span>
                  I would like to send a receipt to the customer for this amount
                </span>
              </div>
            </div>
          )}
          <p>
            By continuing I confirm that I have received this{' '}
            {isDepositPayment ? 'deposit payment' : 'payment'} from{' '}
            {customerName} towards {paymentTarget}.
          </p>

          <div className="actions-container">
            {paymentValueExceedsOutstanding && isOutstandingPaymentDue && (
              <ErrorMessage
                message={`A payment of ${currencyPrice(
                  formState.paymentValue,
                  region
                )} exceeds the
              outstanding amount of ${currencyPrice(
                outstandingPaymentValue,
                region
              )}.
              Are you sure you want to record this payment?`}
              />
            )}
            {!isOutstandingPaymentDue && (
              <ErrorMessage
                message="There is no outstanding payment required against this Project. Are
              you sure you want to record a payment?"
              />
            )}
            <Button
              onClick={() => onSubmit(formState as RecordJobPaymentRequestData)}
              isDisabled={!isValid}
              styleVariant={ButtonStyleVariant.OUTSIZE}
            >
              Continue
            </Button>
          </div>
        </React.Fragment>
      );
    },
    [
      onSubmit,
      customerName,
      paymentTarget,
      isDepositPayment,
      outstandingPaymentValue,
      canSendReceipt,
      isOutstandingPaymentDue,
      region,
      shortDateRegionalFormat,
    ]
  );

  const initialFormState = useMemo(() => {
    return {
      paymentValue: outstandingPaymentValue,
      paymentMethod: PaymentMethodType.BACS,
      isDepositPayment: isDepositPayment,
      sendReceipt: canSendReceipt,
      dealId: dealId,
      invoiceId: invoiceId,
      paymentDate: new Date(),
    };
  }, [outstandingPaymentValue, isDepositPayment, canSendReceipt, invoiceId]);

  return (
    <div className="record-payment-control">
      {!isSubmitted && canRecordPayment && (
        <ValidatedForm<{ [key: string]: any }>
          renderFormContents={renderFormContents}
          initialFormState={initialFormState}
          fieldValidators={fieldValidators}
        />
      )}
      {!isSubmitted && !canRecordPayment && (
        <p style={{ textAlign: 'center' }}>
          Unable to record a payment at this time.
        </p>
      )}
      {isSubmitted && isRecordingJobPayment && (
        <div className="loader-container">
          <MiniLoader />
        </div>
      )}

      {isSubmitted && !isRecordingJobPayment && (
        <div className="feedback-container">
          {isJobPaymentRecordedSuccessfully ? (
            <React.Fragment>
              <FontAwesomeIcon icon={faCheck} />
              <p>Payment has been successfully recorded!</p>
            </React.Fragment>
          ) : (
            <p>Something went wrong recording this payment</p>
          )}
        </div>
      )}
    </div>
  );
};

export default RecordPaymentControl;
