import { CustomersPermissions } from './customers.permissions';
import { CustomersRoles } from './customers.roles';

export const customersMappings = {
  [CustomersPermissions.GET_CUSTOMERS]: [CustomersRoles.VIEWER],
  [CustomersPermissions.GET_LISTED_CUSTOMERS]: [CustomersRoles.VIEWER],
  [CustomersPermissions.ADD_CUSTOMER]: [CustomersRoles.EDITOR],
  [CustomersPermissions.DELETE_CUSTOMER]: [CustomersRoles.EDITOR],
  [CustomersPermissions.BULK_IMPORT]: [CustomersRoles.BULK_IMPORTER],
  [CustomersPermissions.GET_COLLECTION_MANAGEMENT_VIEW]: [
    CustomersRoles.COLLECTION_MANAGER,
  ],
  [CustomersPermissions.CLIENT_PORTAL_SHARE_SETTINGS]: [
    CustomersRoles.CLIENT_PORTAL_SHARE_SETTINGS_MANAGER,
  ],
  [CustomersPermissions.BULK_EXPORT]: [CustomersRoles.BULK_EXPORTER],
};
