import { Reducer } from 'redux';

import { AccountActionTypes, AccountState } from './accountTypes';

export const initialState: AccountState = {
  accountAccessInformation: null,
  isGettingAccountAccessInformation: false,

  requiresUpdateBusinessSettingsPrompt: false,

  emailTemplates: null,
  isGettingEmailTemplates: false,
  gettingEmailTemplatesError: null,

  isSendingDemoQuote: false,
  isDemoQuoteSentSuccessfully: undefined,

  accountUsers: [],
  isGettingUsers: false,
};

const accountReducer: Reducer<AccountState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    // ACCOUNT ACCESS INFORMATION
    case AccountActionTypes.REQUEST_GET_ACCOUNT_ACCESS_INFORMATION:
      return { ...state, isGettingAccountAccessInformation: true };
    case AccountActionTypes.GET_ACCOUNT_ACCESS_INFORMATION_FAILURE:
      return {
        ...state,
        accountAccessInformation: null,
        isGettingAccountAccessInformation: false,
      };
    case AccountActionTypes.CLEAR_ACCOUNT_ACCESS_INFORMATION:
      return { ...state, accountAccessInformation: null };
    case AccountActionTypes.GET_ACCOUNT_ACCESS_INFORMATION_SUCCESS:
      return {
        ...state,
        accountAccessInformation: action.payload.accountAccessInformation,
        isGettingAccountAccessInformation: false,
      };

    // UPDATE BUSINESS SETTINGS PROMPT
    case AccountActionTypes.SET_REQUIRES_UPDATE_BUSINESS_SETTINGS_PROMPT:
      return {
        ...state,
        requiresUpdateBusinessSettingsPrompt:
          action.payload.requiresUpdateBusinessSettingsPrompt,
      };

    // EMAIL TEMPLATES
    case AccountActionTypes.REQUEST_GET_EMAIL_TEMPLATES:
      return {
        ...state,
        isGettingEmailTemplates: true,
        gettingEmailTemplatesError: null,
      };
    case AccountActionTypes.GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        isGettingEmailTemplates: false,
        gettingEmailTemplatesError: null,
      };
    case AccountActionTypes.GET_EMAIL_TEMPLATES_FAILURE:
      return {
        ...state,
        isGettingEmailTemplates: false,
        gettingEmailTemplatesError: action.payload.errorMessage,
      };
    case AccountActionTypes.STORE_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: action.payload.emailTemplates,
      };
    case AccountActionTypes.CLEAR_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: null,
        gettingEmailTemplatesError: null,
        isGettingEmailTemplates: false,
      };

    // ACCOUNT USERS
    case AccountActionTypes.REQUEST_GET_ACCOUNT_USERS:
      return {
        ...state,
        isGettingUsers: true,
      };
    case AccountActionTypes.GET_ACCOUNT_USERS_SUCCESS:
      return {
        ...state,
        isGettingUsers: false,
        accountUsers: action.payload.accountUsers,
      };
    case AccountActionTypes.GET_ACCOUNT_USERS_FAILURE:
      return {
        ...state,
        isGettingUsers: false,
      };
    default:
      return state;
  }
};

export default accountReducer;
