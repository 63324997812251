import { useSelector } from '@/api/state';
import { usePermissions } from '@/hooks/usePermissions';
import {
  getDeal,
  getScheduledEvent,
  getUserRoles,
} from '@/utils/stateAccessors';
import AssignedUsersIndicator from '@payaca/components/assignedUsersIndicator/AssignedUsersIndicator';
import QuickActionsElement from '@payaca/components/quickActionsTableCell/QuickActionsElement';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import * as scheduledEventActions from '@payaca/store/scheduledEvents/scheduledEventsActions';
import * as taskActions from '@payaca/store/tasks/tasksActions';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfirmDeleteScheduledEventModal from '../confirmDeleteScheduledEventModal/ConfirmDeleteScheduledEventModal';
import { EditScheduledEventModal } from '../editScheduledEventModal/EditScheduledEventModal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import './ScheduledEventDetailsAndActionsHeader.sass';

type Props = {
  scheduledEventId: number;
  hideDealLink?: boolean;
  onDeleteScheduledEventSuccess?: () => void;
  onUpdateScheduledEventSuccess?: () => void;
};
const ScheduledEventDetailsAndActionsHeader: FC<Props> = ({
  scheduledEventId,
  hideDealLink,
  onDeleteScheduledEventSuccess,
  onUpdateScheduledEventSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [showEditScheduledEventModal, setShowEditScheduledEventModal] =
    useState(false);
  const { userHasRequiredPermission } = usePermissions();
  const userHasGetDealPermission = userHasRequiredPermission([
    DealsPermissions.GET_DEAL,
    DealsPermissions.GET_MY_DEAL,
  ]);
  const currentUserId = useSelector((state: any) => state.users.myProfile.id);

  const scheduledEvent = useSelector((state) =>
    getScheduledEvent(state, scheduledEventId)
  );

  const linkedDeal = useSelector((state) =>
    scheduledEvent?.dealId ? getDeal(state, scheduledEvent.dealId) : undefined
  );

  const accountUsers: any[] = useSelector(
    (state: any) => state.users.accountUsers
  );

  const userRoles = useSelector(getUserRoles);

  const assignedUsers = useMemo(() => {
    if (!scheduledEvent?.userAssignments) return [];
    return scheduledEvent.userAssignments
      .map((userId) => {
        return accountUsers.find((x) => x.id === userId);
      })
      .filter((x) => !!x);
  }, [scheduledEvent, accountUsers]);

  const isArchivingScheduledEvent = useSelector((state) => {
    return state.scheduledEvents.isArchivingScheduledEvent;
  });

  const archiveEvent = useCallback(() => {
    dispatch(
      scheduledEventActions.requestArchiveScheduledEvent(
        scheduledEventId,
        onDeleteScheduledEventSuccess
      )
    );
  }, [dispatch, scheduledEventId, onDeleteScheduledEventSuccess]);

  const [confirmDeleteScheduledEvent, setConfirmDeleteScheduledEvent] =
    useState(false);

  const quickActions = useMemo(() => {
    const quickActions: any[] = [];

    if (
      userHasRequiredPermission([
        ScheduledEventsPermissions.UPDATE_EVENT,
        ...(linkedDeal
          ? [ScheduledEventsPermissions.UPDATE_MY_DEAL_EVENT]
          : []),
        ...(scheduledEvent?.createdByUserId === currentUserId
          ? [ScheduledEventsPermissions.UPDATE_MY_EVENT]
          : []),
      ])
    ) {
      quickActions.push({
        actionName: 'Edit',
        actionBehaviour: () => setShowEditScheduledEventModal(true),
      });
      quickActions.push({
        actionName: 'Delete',
        actionBehaviour: () => setConfirmDeleteScheduledEvent(true),
        isActionProcessing: isArchivingScheduledEvent,
      });
    }

    return quickActions;
  }, [userRoles, isArchivingScheduledEvent]);

  const dealLinkElement = useMemo(() => {
    if (hideDealLink) return;

    if (userHasGetDealPermission && linkedDeal) {
      return (
        <div className="flex-grow">
          <Link
            to={`/deals/${linkedDeal.id}`}
            className="hover:no-underline btn--soft btn--blue rounded-md py-1 px-2"
          >
            Project {linkedDeal.customReference || linkedDeal.reference}
          </Link>
        </div>
      );
    } else if (scheduledEvent?.dealReference) {
      return (
        <div className="flex-grow">
          <div className="btn--soft btn--blue rounded-md py-1 px-2 w-fit">
            Project {scheduledEvent.dealReference}
          </div>
        </div>
      );
    }

    return;
  }, [linkedDeal, hideDealLink, scheduledEvent, userHasGetDealPermission]);

  const assignedUsersElement = useMemo(() => {
    return (
      <PermissionGuard renderIfHasPermissions={[AccountsPermissions.GET_USERS]}>
        <div>
          <AssignedUsersIndicator
            assignedUsers={assignedUsers.map((x) => ({
              firstName: x.firstname,
              lastName: x.lastname,
              emailAddress: x.email,
              userColour: x.userColour,
              imgSrc: x.avatarUrl,
            }))}
          />
        </div>
      </PermissionGuard>
    );
  }, [assignedUsers]);

  const quickActionsElement = useMemo(() => {
    return (
      <QuickActionsElement
        recordId={scheduledEventId}
        quickActions={quickActions}
      />
    );
  }, [scheduledEventId, quickActions]);

  if (!scheduledEvent) return null;

  if (!dealLinkElement && !assignedUsersElement && !quickActionsElement)
    return null;

  return (
    <div
      className={
        'scheduled-event-details-and-actions-container flex-container flex-center'
      }
    >
      {dealLinkElement}
      {assignedUsersElement}
      {quickActionsElement}
      <EditScheduledEventModal
        onClose={() => setShowEditScheduledEventModal(false)}
        event={scheduledEvent}
        isOpen={!!showEditScheduledEventModal}
        onSuccess={() => {
          dispatch(
            scheduledEventActions.requestGetScheduledEvent(scheduledEventId)
          );
          dispatch(
            taskActions.requestGetTasksForScheduledEvent(scheduledEventId)
          );
          setShowEditScheduledEventModal(false);
          onUpdateScheduledEventSuccess && onUpdateScheduledEventSuccess();
        }}
      />
      <ConfirmDeleteScheduledEventModal
        onClose={() => setConfirmDeleteScheduledEvent(false)}
        isOpen={!!confirmDeleteScheduledEvent}
        onConfirmDeleteScheduledEvent={() => {
          archiveEvent();
          setConfirmDeleteScheduledEvent(false);
        }}
      />
    </div>
  );
};
export default ScheduledEventDetailsAndActionsHeader;
