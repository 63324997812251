import moment from 'moment-timezone';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Button from '../button/Button';
import { ButtonStyleVariant } from '../button/enums';
import DateTimeField from '../dateTimeField/DateTimeField';
import DropdownField from '../dropdownField/DropdownField';
import { InputStyleVariant } from '../inputWrapper/InputWrapper';
import './DatePeriodControl.sass';

export enum DatePeriodPresetOption {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'this-week',
  LAST_WEEK = 'last-week',
  LAST_7_DAYS = 'last-7-days',
  THIS_MONTH = 'this-month',
  LAST_MONTH = 'last-month',
  LAST_30_DAYS = 'last-30-days',
}

export const DatePeriodPresetOptionsMap: {
  [key in DatePeriodPresetOption]: {
    readableName: string;
    getPeriodStart: () => Date;
    getPeriodEnd: () => Date;
  };
} = {
  [DatePeriodPresetOption.TODAY]: {
    readableName: 'Today',
    getPeriodStart: () => moment().startOf('day').toDate(),
    getPeriodEnd: () => moment().endOf('day').toDate(),
  },
  [DatePeriodPresetOption.YESTERDAY]: {
    readableName: 'Yesterday',
    getPeriodStart: () => moment().subtract(1, 'day').startOf('day').toDate(),
    getPeriodEnd: () => moment().subtract(1, 'day').endOf('day').toDate(),
  },
  [DatePeriodPresetOption.THIS_WEEK]: {
    readableName: 'This Week',
    getPeriodStart: () => moment().startOf('week').toDate(),
    getPeriodEnd: () => moment().endOf('week').toDate(),
  },
  [DatePeriodPresetOption.LAST_WEEK]: {
    readableName: 'Last Week',
    getPeriodStart: () => moment().subtract(1, 'week').startOf('week').toDate(),
    getPeriodEnd: () => moment().subtract(1, 'week').endOf('week').toDate(),
  },
  [DatePeriodPresetOption.LAST_7_DAYS]: {
    readableName: 'Last 7 Days',
    getPeriodStart: () => moment().subtract(6, 'days').startOf('day').toDate(),
    getPeriodEnd: () => moment().endOf('day').toDate(),
  },
  [DatePeriodPresetOption.THIS_MONTH]: {
    readableName: 'This Month',
    getPeriodStart: () => moment().startOf('month').toDate(),
    getPeriodEnd: () => moment().endOf('month').toDate(),
  },
  [DatePeriodPresetOption.LAST_MONTH]: {
    readableName: 'Last Month',
    getPeriodStart: () =>
      moment().subtract(1, 'month').startOf('month').toDate(),
    getPeriodEnd: () => moment().subtract(1, 'month').endOf('month').toDate(),
  },
  [DatePeriodPresetOption.LAST_30_DAYS]: {
    readableName: 'Last 30 Days',
    getPeriodStart: () => moment().subtract(29, 'days').startOf('day').toDate(),
    getPeriodEnd: () => moment().endOf('day').toDate(),
  },
};

type Props = {
  value?: {
    periodStart?: Date;
    periodEnd?: Date;
  };
  styleVariant?: InputStyleVariant;
  label?: string;
  onChange?: (value: Partial<{ periodStart?: Date; periodEnd?: Date }>) => void;
  onTouch?: (fieldName: 'periodStart' | 'periodEnd') => void;
  datePeriodPresetOptions?: DatePeriodPresetOption[];
};

const DatePeriodControl: FunctionComponent<React.PropsWithChildren<Props>> = ({
  value,
  styleVariant,
  label,
  onChange,
  onTouch,
  datePeriodPresetOptions,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const [isManualInput, setIsManualInput] = useState<boolean>(false);
  const [selectedDatePeriodPreset, setSelectedDatePeriodPreset] = useState<
    DatePeriodPresetOption | undefined
  >();

  const dropdownOptions = useMemo(() => {
    const availableDatePeriodPresetOptions =
      datePeriodPresetOptions || Object.values(DatePeriodPresetOption);

    return availableDatePeriodPresetOptions.map((x: DatePeriodPresetOption) => {
      return {
        value: x,
        label: DatePeriodPresetOptionsMap[x].readableName,
      };
    });
  }, [datePeriodPresetOptions]);

  useEffect(() => {
    if (value?.periodStart && value?.periodEnd) {
      const datePeriodPresetOption = (
        datePeriodPresetOptions || Object.values(DatePeriodPresetOption)
      ).find((x) => {
        const datePeriod = DatePeriodPresetOptionsMap[x];
        return (
          moment(datePeriod.getPeriodStart()).isSame(value?.periodStart) &&
          moment(datePeriod.getPeriodEnd()).isSame(value?.periodEnd)
        );
      });

      if (datePeriodPresetOption) {
        setSelectedDatePeriodPreset(datePeriodPresetOption);
      } else {
        setIsManualInput(true);
      }
    }
  }, []);

  return (
    <div className="date-period-control">
      {!isManualInput && (
        <>
          <DropdownField
            label={label}
            emptyText="Select a date period"
            name="datePeriod"
            value={selectedDatePeriodPreset}
            options={dropdownOptions}
            onChange={(value) => {
              const datePeriod = value.datePeriod as DatePeriodPresetOption;
              setSelectedDatePeriodPreset(datePeriod);
              onChange &&
                onChange({
                  periodStart:
                    DatePeriodPresetOptionsMap[datePeriod].getPeriodStart(),
                  periodEnd:
                    DatePeriodPresetOptionsMap[datePeriod].getPeriodEnd(),
                });
            }}
          />
          {value?.periodStart &&
            value?.periodEnd &&
            selectedDatePeriodPreset && (
              <small>
                {moment(value.periodStart).format(
                  [
                    DatePeriodPresetOption.YESTERDAY,
                    DatePeriodPresetOption.TODAY,
                  ].includes(selectedDatePeriodPreset)
                    ? 'DD MMM h:mma'
                    : 'DD MMM'
                )}{' '}
                -{' '}
                {moment(value.periodEnd).format(
                  [
                    DatePeriodPresetOption.YESTERDAY,
                    DatePeriodPresetOption.TODAY,
                  ].includes(selectedDatePeriodPreset)
                    ? 'h:mma'
                    : 'DD MMM'
                )}
              </small>
            )}

          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => {
              setIsManualInput(true);
              setSelectedDatePeriodPreset(undefined);
            }}
          >
            Enter dates manually
          </Button>
        </>
      )}
      {isManualInput && (
        <div className="manual-input">
          <DateTimeField
            name="periodStart"
            value={value?.periodStart}
            onChange={onChange}
            label={'From'}
          />

          <DateTimeField
            name="periodEnd"
            value={value?.periodEnd}
            onChange={onChange}
            label={'To'}
          />
        </div>
      )}
    </div>
  );
};

export default DatePeriodControl;
