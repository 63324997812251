import { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@payaca/components/modal/Modal';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { sendProtoInvoice } from '@payaca/store/proposals/proposalsActions';
import './MarkProtoInvoiceAsSentModal.sass';

type Props = {
  isOpen: boolean;
  protoInvoiceId: number;
  onClose: () => void;
  markAsSentCallback?: () => void;
};

const MarkProtoInvoiceAsSentModal: FunctionComponent<Props> = ({
  isOpen,
  protoInvoiceId,
  onClose,
  markAsSentCallback,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [errorMessages, setErrorMessages] = useState<string[]>();

  const [isMarkingAsSent, setIsMarkingAsSent] = useState(false);

  const onMarkAsSent = useCallback(() => {
    setIsMarkingAsSent(true);
    dispatch(
      sendProtoInvoice.request({
        protoInvoiceId: protoInvoiceId,
        data: {
          isMarkAsSent: true,
        },
        callback: () => {
          markAsSentCallback && markAsSentCallback();
          setErrorMessages([]);
          onClose();
          setIsMarkingAsSent(false);
        },
        onErrorCallback: (errorMessages) => {
          setErrorMessages(errorMessages);
          setIsMarkingAsSent(false);
        },
      })
    );
  }, [protoInvoiceId, dispatch, markAsSentCallback, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      title="Mark invoice as sent"
      size="xs"
      onClose={onClose}
      className="mark-as-sent-modal"
      actions={
        <>
          <Button
            onClick={onMarkAsSent}
            iconAfter={faChevronRight}
            isProcessing={isMarkingAsSent}
            styleVariant={ButtonStyleVariant.OUTSIZE}
          >
            Mark as sent without sending
          </Button>
          {errorMessages?.map((errorMessage, i) => {
            return (
              <ErrorMessage
                message={errorMessage}
                key={`mark-as-sent-error-message-${i}`}
              />
            );
          })}
        </>
      }
    >
      <p>
        This option is intended to be used in situations where you will send the{' '}
        invoice offline. For example - where you will print off the invoice and
        hand it to your customer on paper, in person.
      </p>
      <p>When an invoice has been marked as sent:</p>
      <ul>
        <li>No emails or other communication will go to the customer</li>
        <li>
          You will not be able to retrospectively send this invoice to the
          customer using the standard Payaca process
        </li>
        <li>
          If your account is connected to any third party services (Zapier,
          Xero, Quickbooks), they will treat this invoice as they would any
          other sent invoice
        </li>
        <li>Invoice reminders will not be sent</li>
      </ul>
    </Modal>
  );
};

export default MarkProtoInvoiceAsSentModal;
