import {
  PublicHydratedEntityTemplate,
  TemplatableEntity,
} from './entity-templates';
import { ListViewPage, SortDirection } from './listViewTypes';
import { Task } from './taskTypes';

export interface ListedTask extends Task {}

export enum SortBy {
  NAME = 'name',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

export type GetListedTemplatesQueryParams = {
  pageNumber: number;
  pageSize: number;
  sortBy: SortBy;
  sortDirection: SortDirection;
  searchTerm?: string;
  includeServicePlanTemplates?: boolean;
  entityTypes?: TemplatableEntity[];
};

export type ListedTemplatesPage = ListViewPage<
  PublicHydratedEntityTemplate<'task' | 'scheduledEvent'>
>;
