import SideDrawer from '@payaca/components/sideDrawer/SideDrawer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import DealMaterialsList from '../dealMaterialsList/DealMaterialsList';
import './DealMaterialsListDrawer.sass';

type Props = {
  dealId: number;
  isOpen: boolean;
  onClose?: () => void;
};

const DealMaterialsListDrawer: FC<Props> = ({
  isOpen,
  onClose,
  dealId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  return (
    <SideDrawer
      isOpen={isOpen}
      onClose={onClose}
      className="deal-materials-list-drawer"
    >
      <h1>Required Materials</h1>
      <DealMaterialsList dealId={dealId} enablePurchaseBehaviour={false} />
    </SideDrawer>
  );
};
export default DealMaterialsListDrawer;
