import { FC } from 'react';
import './CompanyLogo.css';

type Props = {
  logoUrl?: string;
};

const CompanyLogo: FC<Props> = ({ logoUrl }: Props): JSX.Element | null => {
  return logoUrl ? (
    <div className="company-logo">
      <img src={logoUrl} alt="company-logo" />
    </div>
  ) : null;
};

export default CompanyLogo;
