import { FC, useMemo } from 'react';
import './Badge.sass';

type Props = {
  children: string;
  backgroundColour?: string;
  colour?: string;
};

const Badge: FC<Props> = ({
  children,
  backgroundColour,
  colour,
}: Props): JSX.Element => {
  const badgeStyle = useMemo(() => {
    const style: any = {};

    if (backgroundColour) {
      style.backgroundColor = backgroundColour;
    }
    if (colour) {
      style.color = colour;
    }

    return style;
  }, [colour, backgroundColour]);

  return (
    <span className="badge" style={badgeStyle}>
      {children}
    </span>
  );
};

export default Badge;
