import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { Action } from 'typesafe-actions';

import { KeyIndicatorsData } from '@payaca/types/analyticsTypes';
import {
  DataType,
  TagMetricAnalyticsData,
} from '@payaca/types/tagAnalyticsTypes';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { handleAsyncAction } from '../utils';
import {
  getKeyIndicatorsData,
  getSentInvoicesAggregateChartDataFailure,
  getSentInvoicesAggregateChartDataSuccess,
  getSentInvoiceValueAggregateChartDataFailure,
  getSentInvoiceValueAggregateChartDataSuccess,
  getSentQuotesAggregateChartDataFailure,
  getSentQuotesAggregateChartDataSuccess,
  getSentQuoteValueAggregateChartDataFailure,
  getSentQuoteValueAggregateChartDataSuccess,
  getSubscriptionUsageDataFailure,
  getSubscriptionUsageDataSuccess,
  getTagMetricAnalyticsData,
} from './analyticsActions';
import {
  AnalyticsActionTypes,
  AnalyticsSagaConfig,
  GetKeyIndicatorsData,
  GetTagMetricAnalyticsData,
} from './analyticsTypes';

const analyticsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
}: AnalyticsSagaConfig) => {
  const handleGetTagMetricAnalyticsData =
    handleAsyncAction<GetTagMetricAnalyticsData>(
      getTagMetricAnalyticsData,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/performance/tags/${payload.entityType}/${payload.metricType}`,
          {
            method: 'GET',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
          }
        );
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to get tag metric analytics data');
        }
      },
      (response, payload) => {
        payload.payload?.callback?.(
          response as TagMetricAnalyticsData<DataType>
        );
      },
      (error, payload) => {
        payload.payload?.onErrorCallback?.(error);
      }
    );

  function* handleGetSentQuotesAggregateChartData(
    action: Action<AnalyticsActionTypes.REQUEST_GET_SENT_QUOTES_AGGREGATE_CHART_DATA>
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(getSentQuotesAggregateChartData),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getSentQuotesAggregateChartDataFailure(
            new Error('Get sent quotes aggregate chart data request timed out.')
          )
        );
      } else {
        yield put(getSentQuotesAggregateChartDataSuccess(response));
      }
    } catch (error: any) {
      yield put(getSentQuotesAggregateChartDataFailure(error));
    }
  }

  function* handleGetSentQuoteValueAggregateChartData(
    action: Action<AnalyticsActionTypes.REQUEST_GET_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA>
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(getSentQuoteValueAggregateChartData),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getSentQuoteValueAggregateChartDataFailure(
            new Error(
              'Get sent quote value aggregate chart data request timed out.'
            )
          )
        );
      } else {
        yield put(getSentQuoteValueAggregateChartDataSuccess(response));
      }
    } catch (error: any) {
      yield put(getSentQuoteValueAggregateChartDataFailure(error));
    }
  }

  function* handleGetSentInvoicesAggregateChartData(
    action: Action<AnalyticsActionTypes.REQUEST_GET_SENT_INVOICES_AGGREGATE_CHART_DATA>
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(getSentInvoicesAggregateChartData),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getSentInvoicesAggregateChartDataFailure(
            new Error(
              'Get sent invoices aggregate chart data request timed out.'
            )
          )
        );
      } else {
        yield put(getSentInvoicesAggregateChartDataSuccess(response));
      }
    } catch (error: any) {
      yield put(getSentInvoicesAggregateChartDataFailure(error));
    }
  }

  function* handleGetSentInvoiceValueAggregateChartData(
    action: Action<AnalyticsActionTypes.REQUEST_GET_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA>
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(getSentInvoiceValueAggregateChartData),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getSentInvoiceValueAggregateChartDataFailure(
            new Error(
              'Get sent invoice value aggregate chart data request timed out.'
            )
          )
        );
      } else {
        yield put(getSentInvoiceValueAggregateChartDataSuccess(response));
      }
    } catch (error: any) {
      yield put(getSentInvoiceValueAggregateChartDataFailure(error));
    }
  }

  function* handleGetSubscriptionUsageData(
    action: Action<AnalyticsActionTypes.REQUEST_GET_SUBSCRIPTION_USAGE_DATA>
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(getSubscriptionUsageData),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getSubscriptionUsageDataFailure(
            new Error('Get subscription usage data request timed out.')
          )
        );
      } else {
        yield put(getSubscriptionUsageDataSuccess(response));
      }
    } catch (error: any) {
      yield put(getSubscriptionUsageDataFailure(error));
    }
  }

  const handleGetKeyIndicatorsData = handleAsyncAction<GetKeyIndicatorsData>(
    getKeyIndicatorsData,
    async (payload) => {
      const urlSearchParams = new URLSearchParams();
      payload.indicatorTypes?.forEach((indicatorType, index) => {
        urlSearchParams.set(`types[${index}]`, indicatorType);
      });

      const authHeader = await getAuthHeader();

      const response = await fetch(
        `${apiBaseurl}/api/performance/key_indicators_data?${urlSearchParams.toString()}`,
        {
          method: 'GET',
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
            'X-Simple-Job': 'true',
          },
        }
      );

      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to get key indicators data');
      }
    },
    (response, payload) => {
      payload.payload?.callback?.(
        (response as unknown as KeyIndicatorsData).keyIndicatorData
      );
    },
    (error, payload) => {
      payload.payload?.onErrorCallback?.(error);
    }
  );

  const getSentQuotesAggregateChartData = async () => {
    return fetch(
      `${apiBaseurl}/api/performance/sent_quotes/aggregate_chart_data`,
      {
        method: 'GET',
        headers: {
          Authorization: getAuthHeader(),
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetSentQuotesAggregateChartData failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getSentQuoteValueAggregateChartData = async () => {
    return fetch(
      `${apiBaseurl}/api/performance/sent_quote_value/aggregate_chart_data`,
      {
        method: 'GET',
        headers: {
          Authorization: getAuthHeader(),
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetSentQuoteValueAggregateChartData failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getSentInvoicesAggregateChartData = async () => {
    return fetch(
      `${apiBaseurl}/api/performance/sent_invoices/aggregate_chart_data`,
      {
        method: 'GET',
        headers: {
          Authorization: getAuthHeader(),
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetSentInvoicesAggregateChartData failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getSentInvoiceValueAggregateChartData = async () => {
    return fetch(
      `${apiBaseurl}/api/performance/sent_invoice_value/aggregate_chart_data`,
      {
        method: 'GET',
        headers: {
          Authorization: getAuthHeader(),
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetSentInvoiceValueAggregateChartData failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getSubscriptionUsageData = async () => {
    return fetch(`${apiBaseurl}/api/performance/subscription_usage_data`, {
      method: 'GET',
      headers: {
        Authorization: getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetSubscriptionUsageData failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  return function* () {
    yield takeEvery(
      AnalyticsActionTypes.REQUEST_GET_SENT_QUOTES_AGGREGATE_CHART_DATA,
      handleGetSentQuotesAggregateChartData
    );
    yield takeEvery(
      AnalyticsActionTypes.REQUEST_GET_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA,
      handleGetSentQuoteValueAggregateChartData
    );
    yield takeEvery(
      AnalyticsActionTypes.REQUEST_GET_SENT_INVOICES_AGGREGATE_CHART_DATA,
      handleGetSentInvoicesAggregateChartData
    );
    yield takeEvery(
      AnalyticsActionTypes.REQUEST_GET_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA,
      handleGetSentInvoiceValueAggregateChartData
    );
    yield takeEvery(
      AnalyticsActionTypes.REQUEST_GET_SUBSCRIPTION_USAGE_DATA,
      handleGetSubscriptionUsageData
    );
    yield takeEvery(
      AnalyticsActionTypes.REQUEST_GET_KEY_INDICATORS_DATA,
      handleGetKeyIndicatorsData
    );
    yield takeLatest(
      AnalyticsActionTypes.GET_TAG_METRIC_ANALYTICS_DATA_REQUEST,
      handleGetTagMetricAnalyticsData
    );
  };
};

export default analyticsSagaCreator;
