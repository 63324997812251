import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import './ConfirmPaymentControl.css';

import {
  faCheck,
  faChevronRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelector } from '@/api/state';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Checkbox from '@payaca/components/checkbox/Checkbox';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import * as jobPaymentsActions from '@payaca/store/jobPayments/jobPaymentsActions';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';

type Props = {
  jobPayment: JobPayment;
  customerName: string;
  dealReference: string;
  canSendReceipt?: boolean;
  goToRecordPayment?: () => void;
  confirmPaymentCallback?: () => void;
};

const ConfirmPaymentControl: FunctionComponent<Props> = ({
  jobPayment,
  customerName,
  dealReference,
  canSendReceipt = true,
  goToRecordPayment,
  confirmPaymentCallback,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [hasCalledCallback, setHasCalledCallback] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isConfirmingJobPayment = useSelector((state) => {
    return state.jobPayments.isConfirmingJobPayment;
  });
  const isJobPaymentConfirmedSuccessfully = useSelector((state) => {
    return state.jobPayments.isJobPaymentConfirmedSuccessfully;
  });
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const onSubmit = useCallback(
    (jobPaymentId: number, note?: string, isPaymentReceived = true) => {
      dispatch(
        jobPaymentsActions.requestConfirmJobPayment({
          jobPaymentId,
          isPaymentReceived,
          note,
        })
      );
      setIsSubmitted(true);
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      isSubmitted &&
      !isConfirmingJobPayment &&
      isJobPaymentConfirmedSuccessfully &&
      confirmPaymentCallback &&
      !hasCalledCallback
    ) {
      setHasCalledCallback(true);
      confirmPaymentCallback();
    }
  }, [
    isSubmitted,
    isConfirmingJobPayment,
    isJobPaymentConfirmedSuccessfully,
    hasCalledCallback,
    confirmPaymentCallback,
  ]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <React.Fragment>
          <p>
            {customerName} has made a{' '}
            {jobPayment.isDepositPayment ? 'deposit payment' : 'payment'} of{' '}
            {currencyPrice(jobPayment.paymentValue, account.region)} via bank
            transfer towards Project #{dealReference}.
          </p>
          {canSendReceipt && (
            <div>
              <div className="checkbox-container flex-container flex-center">
                <div>
                  <Checkbox
                    isChecked={
                      formState.sendReceipt === undefined
                        ? true
                        : formState.sendReceipt
                    }
                    onChange={() => {
                      onFieldChange({
                        sendReceipt: !formState.sendReceipt,
                      });
                      onFieldTouch && onFieldTouch('sendReceipt');
                    }}
                  />
                </div>
                <span>
                  I would like to send a receipt to the customer for this amount
                </span>
              </div>
            </div>
          )}
          <ValidatedFieldWrapper
            validationResult={validationState.note}
            isTouched={touchedState.note || false}
          >
            <BasicField
              additionalInputProps={{
                maxLength: 150,
              }}
              name="note"
              value={formState.note}
              isRequired={false}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              label="Internal note"
              styleVariant={InputStyleVariant.OUTSIZE}
            />
          </ValidatedFieldWrapper>
          <div className="actions-container">
            <Button
              onClick={() =>
                onSubmit(formState.jobPaymentId, formState.note, true)
              }
              isDisabled={!isValid}
              iconAfter={faChevronRight}
              styleVariant={ButtonStyleVariant.OUTSIZE}
            >
              {currencyPrice(jobPayment.paymentValue, account.region)} received
            </Button>
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              iconAfter={faTimes}
              onClick={() =>
                onSubmit(formState.jobPaymentId, formState.note, false)
              }
            >
              Payment not received
            </Button>
            {goToRecordPayment && (
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                iconAfter={faChevronRight}
                onClick={() => {
                  // fail the original payment made by customer
                  onSubmit(formState.jobPaymentId, undefined, false);
                  goToRecordPayment();
                }}
              >
                I have received another amount
              </Button>
            )}
          </div>
        </React.Fragment>
      );
    },
    [
      jobPayment,
      customerName,
      dealReference,
      canSendReceipt,
      goToRecordPayment,
      account,
    ]
  );

  return (
    <div className="confirm-payment-control">
      {!isSubmitted && (
        <ValidatedForm<{ [key: string]: any }>
          renderFormContents={renderFormContents}
          initialFormState={{
            jobPaymentId: jobPayment.id,
            sendReceipt: canSendReceipt,
          }}
        />
      )}
      {isSubmitted && isConfirmingJobPayment && (
        <div className="loader-container">
          <MiniLoader />
        </div>
      )}

      {isSubmitted && !isConfirmingJobPayment && (
        <div className="feedback-container">
          {isJobPaymentConfirmedSuccessfully ? (
            <React.Fragment>
              <FontAwesomeIcon icon={faCheck} />
              <p>Success!</p>
            </React.Fragment>
          ) : (
            <p>Something went wrong updating this payment record</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ConfirmPaymentControl;
