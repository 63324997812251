import { ListViewPage, SortDirection } from './listViewTypes';

export interface ListedItem {
  itemId: number;
  name: string;
  description: string;
  price: string;
  priceIncludingVat: string;
  vat: string;
  cisApplies: boolean;
  cisDeductionRate?: number;
  syncedWithIntegrations: ('Quickbooks' | 'Xero')[];
  thumbnailUrl: string;
}

export enum SortBy {
  NAME = 'name',
  DESCRIPTION = 'description',
  PRICE = 'price',
  PRICE_INCLUDING_VAT = 'priceIncludingVat',
}

export interface ListedItemsListViewPage extends ListViewPage<ListedItem> {
  totalUnfilteredItemCount: number;
}

export interface GetListedItemsRequestData {
  pageSize: number;
  pageNumber: number;
  searchTerm?: string;
  reference?: string;
  sortDirection?: SortDirection;
  sortBy?: SortBy;
}
