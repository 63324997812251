import * as pdfDocumentDataActions from '@payaca/store/pdfDocumentData/pdfDocumentDataActions';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import InvoiceDocument from '@payaca/components/invoiceDocument/InvoiceDocument';
import { InvoicePdfDocumentData } from '@payaca/types/invoicePdfDocumentTypes';
import { IntercomAPI } from 'react-intercom';
import './InvoicePage.sass';

type Props = {
  previewToken: string;
  invoiceId: number;
};

const InvoicePage: FC<Props> = ({
  previewToken,
  invoiceId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState<InvoicePdfDocumentData>();

  useEffect(() => {
    dispatch(
      pdfDocumentDataActions.getInvoiceData.request({
        previewToken: previewToken,
        invoiceId: invoiceId,
        callback: setInvoiceData,
      })
    );

    IntercomAPI('update', {
      hide_default_launcher: true,
    });

    return () => {
      IntercomAPI('update', {
        hide_default_launcher: false,
      });
    };
  }, []);

  if (!invoiceData) return null;

  return (
    <div className="pdf-invoice-page">
      <InvoiceDocument
        documentSenderContext={invoiceData.documentSenderContext}
        invoiceId={invoiceId}
        jobLineItems={invoiceData.jobLineItems}
        invoices={invoiceData.invoices}
        invoiceLines={invoiceData.invoiceLines}
        customer={invoiceData.customer}
        siteAddresses={invoiceData.siteAddresses}
        dealTotals={invoiceData.dealTotals}
        hideItemPrices={invoiceData.hideItemPrices}
        paymentMethodConfig={invoiceData.paymentMethodConfig}
        isStraightToInvoice={invoiceData.isStraightToInvoice}
      />
    </div>
  );
};

export default InvoicePage;
