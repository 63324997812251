import { FC } from 'react';

import SideDrawer from '@payaca/components/sideDrawer/SideDrawer';
import TaskReadCompleteView from '../taskReadCompleteView/TaskReadCompleteView';

type Props = {
  taskId?: number;
  hideDealLink?: boolean;
  onDeleteTaskSuccess?: () => void;
  onUpdateTaskSuccess?: () => void;
  isOpen: boolean;
  onClose?: () => void;
};

const TaskReadCompleteDrawer: FC<Props> = ({
  taskId,
  hideDealLink,
  onDeleteTaskSuccess,
  onUpdateTaskSuccess,
  isOpen,
  onClose,
}: Props): JSX.Element | null => {
  return (
    <SideDrawer
      isOpen={isOpen}
      onClose={onClose}
      className="task-read-complete-drawer"
    >
      {taskId && (
        <TaskReadCompleteView
          taskId={taskId}
          onDeleteTaskSuccess={() => {
            if (onClose) {
              void onClose();
            }
            if (onDeleteTaskSuccess) {
              void onDeleteTaskSuccess();
            }
          }}
          onUpdateTaskSuccess={onUpdateTaskSuccess}
          hideDealLink={hideDealLink}
        />
      )}
    </SideDrawer>
  );
};
export default TaskReadCompleteDrawer;
