import { useSelector } from '@/api/state';
import { getUserRoles } from '@/utils/stateAccessors';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import QuickActionsElement from '@payaca/components/quickActionsTableCell/QuickActionsElement';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { TemplatesPermissions } from '@payaca/permissions/templates/templates.permissions';
import {
  deleteTemplate,
  updateTemplate,
} from '@payaca/store/templates/templatesActions';
import {
  PublicHydratedEntityTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';
import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import CreateEditEntityTemplateModal from '../createEditEntityTemplateModal/CreateEditEntityTemplateModal';
import './EntityTemplateViewHeader.sass';

type Props = {
  entityTemplate: PublicHydratedEntityTemplate<TemplatableEntity>;
  onDeleteEntityTemplateSuccess?: () => void;
  onUpdateEntityTemplateSuccess?: (
    updatedEntityTemplate: PublicHydratedEntityTemplate<TemplatableEntity>
  ) => void;
  allowModifyTemplateNameDescription?: boolean;
};
const EntityTemplateViewHeader: FC<Props> = ({
  entityTemplate,
  onDeleteEntityTemplateSuccess,
  onUpdateEntityTemplateSuccess,
  allowModifyTemplateNameDescription,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [showEditEntityTemplateModal, setShowEditEntityTemplateModal] =
    useState(false);
  const history = useHistory();

  const userRoles = useSelector(getUserRoles);

  const [isRemoving, setIsRemoving] = useState(false);

  const [confirmDeleteTemplate, setConfirmDeleteTemplate] = useState(false);

  const quickActions = useMemo(() => {
    const quickActions: any[] = [];

    if (
      userHasRequiredPermission(userRoles, [
        TemplatesPermissions.PERSIST_TEMPLATE,
      ])
    ) {
      quickActions.push({
        actionName: 'Edit',
        actionBehaviour: () => {
          if (entityTemplate.entityType === 'proposal') {
            history.push(`/templates/edit/${entityTemplate.publicId}`);
          } else {
            setShowEditEntityTemplateModal(true);
          }
        },
      });
    }

    if (
      userHasRequiredPermission(userRoles, [
        TemplatesPermissions.DELETE_TEMPLATE,
      ]) &&
      !entityTemplate.servicePlans?.length
    ) {
      quickActions.push({
        actionName: 'Delete',
        actionBehaviour: () => setConfirmDeleteTemplate(true),
        isActionProcessing: isRemoving,
      });
    }

    return quickActions;
  }, [
    userRoles,
    isRemoving,
    entityTemplate?.servicePlans?.length,
    entityTemplate?.entityType,
  ]);

  const quickActionsElement = useMemo(() => {
    return (
      <QuickActionsElement
        recordId={entityTemplate?.publicId}
        quickActions={quickActions}
      />
    );
  }, [entityTemplate?.publicId, quickActions]);

  if (
    !entityTemplate?.name &&
    entityTemplate?.description &&
    !quickActionsElement
  )
    return null;

  return (
    <div className={'entity-template-view-header'}>
      {entityTemplate?.name && (
        <span className="name">{entityTemplate.name}</span>
      )}
      {quickActionsElement}
      {entityTemplate?.description && (
        <span className="description">{entityTemplate.description}</span>
      )}

      <CreateEditEntityTemplateModal
        isOpen={showEditEntityTemplateModal}
        onClose={() => setShowEditEntityTemplateModal(false)}
        action="Edit"
        template={entityTemplate}
        onRequestSave={(t) => {
          dispatch(
            updateTemplate.request({
              publicId: entityTemplate.publicId,
              template: t,
              callback: onUpdateEntityTemplateSuccess,
            })
          );
          setShowEditEntityTemplateModal(false);
        }}
        allowModifyTemplateNameDescription={allowModifyTemplateNameDescription}
      />
      <Modal
        isOpen={confirmDeleteTemplate}
        onClose={() => setConfirmDeleteTemplate(false)}
        title="Are you sure you want to delete this Template?"
        actions={
          <>
            <Button
              onClick={() => {
                setIsRemoving(true);

                dispatch(
                  deleteTemplate.request({
                    publicId: entityTemplate.publicId,
                    callback: () => {
                      onDeleteEntityTemplateSuccess?.();
                      setIsRemoving(false);
                      setConfirmDeleteTemplate(false);
                    },
                    onErrorCallback: (error) => {
                      setIsRemoving(false);
                      setConfirmDeleteTemplate(false);
                    },
                  })
                );
              }}
              styleVariant={ButtonStyleVariant.OUTSIZE}
            >
              Delete
            </Button>
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={() => setConfirmDeleteTemplate(false)}
            >
              Cancel
            </Button>
          </>
        }
      ></Modal>
    </div>
  );
};
export default EntityTemplateViewHeader;
