import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@/api/state';

import { useAccount } from '@/utils/storeHooks';

import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';

import { cancelSubscription } from '@payaca/store/subscription/subscriptionActions';

import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';

import UpdateSubscriptionAdditionalUserSeatsModal from '../manageSubscription/updateSubscriptionAdditionalUserSeatsModal/UpdateSubscriptionAdditionalUserSeatsModal';
import SubscriptionProductControl from '../subscriptionProductControl/SubscriptionProductControl';

import { getModal } from '@/helpers/modalHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  getProductPriceCostExcludingVat,
  hasUnlimitedUsers,
  recurringIntervalLabelMap,
} from '@payaca/helpers/subscriptionHelper';

import { actions as appActions } from '@/api/app';
import * as subscriptionActions from '@payaca/store/subscription/subscriptionActions';
import { capitaliseFirstLetter } from '@payaca/utilities/stringUtilities';

interface Props {
  onUpdateSubscriptionSuccess?: () => void;
}

const CurrentSubscriptionControl: FC<Props> = ({
  onUpdateSubscriptionSuccess,
}): JSX.Element | null => {
  const dispatch = useDispatch();

  const account = useAccount();

  const [
    showUpdateSubscriptionAdditionalUserSeatsModal,
    setShowUpdateSubscriptionAdditionalUserSeatsModal,
  ] = useState(false);

  const accountSubscription = useSelector(
    (state) => state.subscription.accountSubscription
  );

  const subscriptionEndsAt = useMemo(() => {
    return accountSubscription?.subscriptionInformation.subscriptionEndDate;
  }, [accountSubscription]);

  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);

  const onCancel = () => {
    //... Any pre-cancel logic
    window
      .profitwell('init_cancellation_flow', {
        subscription_id:
          accountSubscription?.subscriptionInformation.stripeSubscriptionId,
      })
      .then((result: Record<string, any>) => {
        // This means the customer either aborted the flow (i.e.
        // they clicked on "never mind, I don't want to cancel"), or
        // accepted a salvage attempt or salvage offer.
        // Thus, do nothing since they won't cancel.
        if (result.status === 'retained' || result.status === 'aborted') {
          return;
        }
        // At this point, the customer ended deciding to cancel (i.e.
        // they rejected the salvage attempts and the salvage offer).
        // It could also be the case the widget couldn't be shown properly for
        // some reason (for which case, `result.status` will be 'error'), but that
        // shouldn't stop them from cancelling.

        // The normal cancel flow goes here
        if (result.status === 'chose_to_cancel') {
          dispatch(
            cancelSubscription.request(account.id, (err?: Error) => {
              if (err) {
                showDynamicFeedbackMessage({
                  title: 'Error cancelling subscription',
                  body: 'Something went wrong cancelling your subscription',
                  isCancellable: true,
                  lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
                  feedbackLevel: FeedbackLevel.ERROR,
                });
                console.error(err);
              } else {
                showDynamicFeedbackMessage({
                  title: 'Subscription cancelled',
                  body: "We're sorry to see you go. Your subscription will be cancelled at the end of your billing interval",
                  isCancellable: true,
                  lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
                  feedbackLevel: FeedbackLevel.SUCCESS,
                });
              }
            })
          );
        }
      });
  };

  const additionalUserSeats = useMemo(
    () => accountSubscription?.subscriptionInformation.additionalUserSeats || 0,
    [accountSubscription]
  );

  const hasAnyBaseUserSeats = useMemo(() => {
    return !!accountSubscription?.subscriptionProduct?.numberOfUserSeats;
  }, [accountSubscription?.subscriptionProduct]);

  const region = useSelector(
    (state: any) => state.users.myProfile.accounts[0].region
  );

  const costExcludingVat = useMemo(() => {
    if (!accountSubscription?.subscriptionProduct) return null;

    return (
      getProductPriceCostExcludingVat(
        accountSubscription.productPrice,
        additionalUserSeats
      ) || null
    );
  }, [accountSubscription, additionalUserSeats]);

  const tax = useMemo(() => {
    if (!costExcludingVat) return 0;
    return costExcludingVat * ((accountSubscription?.taxPercentage || 0) / 100);
  }, [costExcludingVat, accountSubscription?.taxPercentage]);

  const costIncludingVat = useMemo(() => {
    if (!costExcludingVat) return null;
    return costExcludingVat + tax;
  }, [costExcludingVat, tax]);

  const productBreakdown = useMemo(() => {
    const costInformation: { label: string; value: string }[] = [];

    if (!accountSubscription) return costInformation;

    if (
      !(
        (accountSubscription?.subscriptionProduct?.numberOfJobsPerMonth || 0) >=
        9999
      )
    ) {
      costInformation.push({
        label: 'Projects per month',
        value: `${accountSubscription?.subscriptionProduct.numberOfJobsPerMonth}`,
      });
    }
    if (accountSubscription?.subscriptionProduct?.numberOfSmsPerMonth) {
      costInformation.push({
        label: 'SMS per month',
        value: `${
          accountSubscription?.subscriptionProduct.numberOfSmsPerMonth >= 9999
            ? 'Unlimited'
            : accountSubscription?.subscriptionProduct.numberOfSmsPerMonth
        }`,
      });
    }
    costInformation.push(
      {
        label: 'Users',
        value: `${
          hasUnlimitedUsers(
            accountSubscription?.subscriptionProduct.numberOfUserSeats
          )
            ? 'Unlimited'
            : additionalUserSeats +
              (accountSubscription?.subscriptionProduct.numberOfUserSeats || 0)
        }`,
      },
      {
        label: 'Billing interval',
        value: capitaliseFirstLetter(
          recurringIntervalLabelMap[accountSubscription.recurringInterval]
        ),
      },
      {
        label: 'Subscription cost',
        value: `${currencyPrice(costExcludingVat || 0, region)} + VAT`,
      },
      // TODO: ADDON - get renewal date
      {
        label: 'Renews on',
        value: ``,
      }
    );

    return costInformation;
  }, [
    region,
    costIncludingVat,
    accountSubscription,
    tax,
    hasAnyBaseUserSeats,
    additionalUserSeats,
  ]);

  const bonuses = useMemo(() => {
    const b: string[] = [];

    if (!accountSubscription) return b;

    if (accountSubscription.subscriptionInformation.bonusAdditionalUserSeats) {
      b.push(
        hasUnlimitedUsers(
          accountSubscription.subscriptionInformation.bonusAdditionalUserSeats
        )
          ? 'Unlimited users'
          : `${
              accountSubscription.subscriptionInformation
                .bonusAdditionalUserSeats
            } extra ${
              accountSubscription.subscriptionInformation
                .bonusAdditionalUserSeats === 1
                ? 'user'
                : 'users'
            }`
      );
    }

    if (
      accountSubscription.subscriptionInformation.bonusAdditionalJobsPerMonth
    ) {
      b.push(
        `${
          accountSubscription.subscriptionInformation
            .bonusAdditionalJobsPerMonth
        } extra ${
          accountSubscription.subscriptionInformation
            .bonusAdditionalJobsPerMonth === 1
            ? 'Project'
            : 'Projects'
        } per month`
      );
    }

    return b;
  }, [accountSubscription?.subscriptionInformation]);

  const [restoreSubscriptionAttemptMade, setRestoreSubscriptionAttemptMade] =
    useState(false);

  const handleRestoreSubscriptionAsyncBehaviour = useCallback(() => {
    setRestoreSubscriptionAttemptMade(true);
    setTimeout(
      () => dispatch(subscriptionActions.requestGetAccountSubscription()),
      5000
    );
  }, [dispatch]);

  const restoreSubscription = useCallback(() => {
    dispatch(
      appActions.showModal(
        getModal('RESTORE_SUBSCRIPTION', {
          primaryAction: () => {
            dispatch(subscriptionActions.requestRestoreSubscription());
            handleRestoreSubscriptionAsyncBehaviour();
            dispatch(appActions.hideModal());
          },
          secondaryAction: () => dispatch(appActions.hideModal()),
          onClose: () => dispatch(appActions.hideModal()),
        })
      )
    );
  }, [dispatch, handleRestoreSubscriptionAsyncBehaviour]);

  if (!accountSubscription) return null;

  return (
    <div className="current-subscription-control">
      <SubscriptionProductControl
        title={accountSubscription.subscriptionProduct.name}
        productBreakdown={productBreakdown}
        onUpdate={
          !subscriptionEndsAt &&
          accountSubscription?.productPrice.canBuyAdditionalUserSeats
            ? () => setShowUpdateSubscriptionAdditionalUserSeatsModal(true)
            : undefined
        }
        onCancel={!subscriptionEndsAt ? onCancel : undefined}
        bonuses={bonuses}
        subscriptionEndsAt={subscriptionEndsAt || undefined}
        onRestoreSubscription={restoreSubscription}
        cancelText="Cancel plan"
        updateText="Update plan"
      />
      <UpdateSubscriptionAdditionalUserSeatsModal
        onUpdateSubscriptionSuccess={onUpdateSubscriptionSuccess}
        isOpen={showUpdateSubscriptionAdditionalUserSeatsModal}
        onClose={() => setShowUpdateSubscriptionAdditionalUserSeatsModal(false)}
      />
    </div>
  );
};

export default CurrentSubscriptionControl;
