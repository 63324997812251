import Badge from '@payaca/components/plBadge/Badge';
import UntitledIcon from '@payaca/untitled-icons';
import { differenceInDays } from 'date-fns';
import { FC, useMemo } from 'react';
import {
  DeliveryFulfillmentOption,
  FulfillmentOption,
  getFulfillmentDate,
} from './fulfillment';

const getBadgeColour = (fulfillmentOption?: FulfillmentOption) => {
  const fulfillmentDays = fulfillmentOption
    ? differenceInDays(getFulfillmentDate(fulfillmentOption), new Date())
    : null;

  if (fulfillmentOption?.isFullFulfillment && fulfillmentDays !== null) {
    if (fulfillmentDays === 0) {
      return 'green';
    } else if (fulfillmentDays < 7) {
      return 'yellow';
    } else {
      return 'orange';
    }
  } else {
    if (fulfillmentOption) {
      return 'orange';
    }
    return 'red';
  }
};

type Props = {
  supplier: { id: string; name: string };
  deliveryOptions: DeliveryFulfillmentOption[];
  collectionOptions: FulfillmentOption[];
};

const LiveAvailabilitySupplierBadge: FC<Props> = (props) => {
  const { supplier, collectionOptions, deliveryOptions } = props;

  const dateSortedFulfillments = [
    ...deliveryOptions,
    ...collectionOptions,
  ].sort(
    (a, b) => getFulfillmentDate(a).getTime() - getFulfillmentDate(b).getTime()
  );

  const bestFulfillment = useMemo(() => {
    // prioritise full fulfillment and earliest date
    const fullFulfillments = dateSortedFulfillments.filter(
      (f) => f.isFullFulfillment
    );
    return fullFulfillments[0] || dateSortedFulfillments[0];
  }, [dateSortedFulfillments]);

  return (
    <Badge
      key={supplier.id}
      variant="soft"
      colour={getBadgeColour(bestFulfillment)}
    >
      {supplier.name}{' '}
      {!!deliveryOptions.length && (
        <UntitledIcon name="truck-02.3" className="h-5 w-5" />
      )}
      {!!collectionOptions.length && (
        <UntitledIcon name="building-02.3" className="h-5 w-5" />
      )}
    </Badge>
  );
};

export default LiveAvailabilitySupplierBadge;
