import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  GetMaterialsForSupplierInput,
  GetSupplierMaterialsQuery,
  PaginationInput,
} from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import supplierKeys from './keyFactory';

const GET_SUPPLIER_MATERIALS = graphql(`
  query GetSupplierMaterials(
    $supplierId: ID!
    $pagination: PaginationInput!
    $input: GetMaterialsForSupplierInput
  ) {
    supplier(id: $supplierId) {
      name
      isSystemManaged
      materials(pagination: $pagination, input: $input) {
        limit
        offset
        totalCount
        items {
          id
          reference
          url
          isSystemManaged
          material {
            id
            internalId
            name
            image {
              id
              thumbnailUrl
            }
            preferredSupplierId
          }
          price {
            taxRate {
              id
            }
            unitPriceExcTax {
              value
              currency {
                id
                code
                exponent
              }
            }
          }
        }
      }
    }
  }
`);

export type SupplierPurchaseOrder =
  GetSupplierMaterialsQuery['supplier']['materials']['items'][0];

const useGetSupplierMaterials = (
  opts: {
    supplierId: number;
    input?: GetMaterialsForSupplierInput;
    pagination?: PaginationInput;
  },
  queryOptions?: UseQueryOptions<GetSupplierMaterialsQuery>
) => {
  const pagination = opts.pagination || PAGINATION_ARG_DEFAULTS;
  const { data, ...rest } = useQuery({
    queryKey: supplierKeys.materials(
      opts.supplierId.toString(),
      opts.input,
      pagination
    ),
    queryFn: () => {
      return gqlClient.request(GET_SUPPLIER_MATERIALS, {
        supplierId: opts.supplierId.toString(),
        pagination: pagination || { limit: 10, offset: 0 },
        input: opts.input,
      });
    },
    ...queryOptions,
  });

  return {
    supplierMaterials: data?.supplier.materials,
    data,
    ...rest,
  };
};

export default useGetSupplierMaterials;
