import { actions as usersActions } from '@/api/users';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faCircleNotch,
  faPhone,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import BasicField from '@payaca/components/basicField/BasicField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import PhoneNumberField from '@payaca/components/phoneNumberField/PhoneNumberField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import './LoqateAdvisoryPhoneInputField.sass';

type Props = {
  styleVariant?: InputStyleVariant;
  name: string;
  value?: any;
  label?: string;
  description?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  iconBefore?: IconDefinition;
  onChange?: (value: { [key: string]: string }) => void;
  onTouch?: (fieldName: string) => void;
  disableValidation?: boolean;
  inputType?: 'phone' | 'basic';
  onValidating?: () => void;
  onValidated?: (isValid: boolean) => void;
};

const LoqateAdvisoryPhoneInputField: FunctionComponent<Props> = ({
  styleVariant,
  name,
  value,
  label,
  description,
  isDisabled = false,
  isRequired = false,
  iconBefore,
  onChange,
  onTouch,
  onValidating,
  onValidated,
  disableValidation = false,
  inputType = 'phone',
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [loqateValidationTimeout, setLoqateValidationTimeout] =
    useState<NodeJS.Timeout>();
  const [isValidating, setIsValidating] = useState(false);
  const [isValid, setIsValid] = useState<boolean>();

  useEffect(() => {
    return () => {
      setLoqateValidationTimeout((loqateValidationTimeout) => {
        loqateValidationTimeout && clearTimeout(loqateValidationTimeout);
        return undefined;
      });
    };
  }, []);

  const runLoqateValidation = useCallback(
    (value: any) => {
      if (!value || value === '') {
        setIsValid(undefined);
        return;
      }

      setIsValidating(true);
      onValidating?.();
      dispatch(
        usersActions.validatePhone(value, (error: Error, response: any) => {
          setIsValidating(false);
          if (error) {
            setIsValid(undefined);
            onValidated?.(false);
          } else {
            setIsValid(response);
            onValidated?.(response);
          }
        })
      );
    },
    [dispatch]
  );

  const iconAfter = useMemo(() => {
    if (isValidating) return faCircleNotch;
    if (isValid === true) return faCheck;
    if (isValid === false) return faTimes;
  }, [isValidating, isValid]);

  const validationResult = useMemo(() => {
    if (isValidating || isValid === undefined) return;
    return {
      isValid: isValid,
      errors: isValid
        ? undefined
        : [
            'This phone number looks invalid to us, please double check before continuing',
          ],
    };
  }, [isValidating, isValid]);

  useEffect(() => {
    if (!disableValidation) {
      setLoqateValidationTimeout((loqateValidationTimeout) => {
        loqateValidationTimeout && clearTimeout(loqateValidationTimeout);
        return setTimeout(() => {
          runLoqateValidation(value);
        }, 500);
      });
    }
  }, [value, runLoqateValidation, disableValidation]);

  useEffect(() => {
    if (disableValidation) {
      setIsValidating(false);
      setIsValid(undefined);
      setLoqateValidationTimeout((loqateValidationTimeout) => {
        loqateValidationTimeout && clearTimeout(loqateValidationTimeout);
        return undefined;
      });
    }
  }, [disableValidation]);

  return (
    <div className="loqate-advisory-phone-input-field">
      <ValidatedFieldWrapper
        isTouched={value && value !== ''}
        validationResult={validationResult}
      >
        <>
          {inputType === 'phone' && (
            <PhoneNumberField
              styleVariant={styleVariant}
              name={name}
              value={value}
              label={label}
              description={description}
              isDisabled={isDisabled}
              isRequired={isRequired}
              iconBefore={iconBefore}
              onChange={(data) => {
                // ensure the area code cannot be removed (i.e +44, +61 etc)
                if (data[name].length > 3) {
                  onChange && onChange(data);
                }
              }}
              onTouch={onTouch}
              iconAfter={iconAfter}
            />
          )}
          {inputType === 'basic' && (
            <BasicField
              styleVariant={styleVariant}
              name={name}
              value={value}
              label={label}
              description={description}
              isDisabled={isDisabled}
              isRequired={isRequired}
              iconBefore={iconBefore || faPhone}
              onChange={onChange}
              onTouch={onTouch}
              iconAfter={iconAfter}
              type="tel"
            />
          )}
        </>
      </ValidatedFieldWrapper>
    </div>
  );
};

export default LoqateAdvisoryPhoneInputField;
