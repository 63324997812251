import Card from '@payaca/components/plCard/Card';
import HorizontalBarWithGoals from '@payaca/components/plCharts/HorizontalBarWithGoals';
import { FC, useMemo } from 'react';
import { currencyLabel } from '../../../../../helpers/currency';

type BreakdownItem = {
  actual: {
    currency: {
      code: string;
      exponent: number;
    };
    value: number;
  };
  forecast: {
    currency: {
      code: string;
      exponent: number;
    };
    value: number;
  };
};
export type Props = {
  className?: string;
  profitData?: {
    revenue: BreakdownItem;
    materials: BreakdownItem;
    labour: BreakdownItem;
    profit: BreakdownItem;
  };
};
const ProfitBreakdown: FC<Props> = (props) => {
  const { className, profitData } = props;

  const currencyCode = useMemo(() => {
    const currency =
      profitData &&
      Object.values(profitData).find((item) => item.actual.currency);
    return currency?.actual.currency?.code;
  }, [profitData]);

  const actualProfitMargin = (() => {
    if (profitData?.revenue.actual.value === 0) {
      return '-';
    }

    return Math.round(
      ((profitData?.profit.actual.value || 0) /
        (profitData?.revenue.actual.value || 1)) *
        100
    );
  })();

  const forcastProfitMargin = (() => {
    if (profitData?.revenue.forecast.value === 0) {
      return '-';
    }

    return Math.round(
      ((profitData?.profit.forecast.value || 0) /
        (profitData?.revenue.forecast.value || 1)) *
        100
    );
  })();

  const actualProfitBarColour =
    profitData && profitData.profit.actual.value < 0 ? '#fca5a5' : '#ccedc5';

  const forcastProfitBarColour =
    profitData && profitData.profit.forecast.value < 0 ? '#ba1b1b' : '#316e27';

  const barData = [
    {
      xLabel: 'Revenue',
      label: 'Paid',
      value: profitData?.revenue.actual.value || 0,
      goal: {
        label: 'Forecast',
        value: profitData?.revenue.forecast.value || 0,
      },
    },
    {
      xLabel: 'Materials',
      label: 'Purchased',
      value: -(profitData?.materials.actual.value || 0),
      goal: {
        label: 'Forecast',
        value: -(profitData?.materials.forecast.value || 0),
      },
    },
    {
      xLabel: 'Labour',
      label: 'Tracked',
      value: -(profitData?.labour.actual.value || 0),
      goal: {
        label: 'Scheduled',
        value: -(profitData?.labour.forecast.value || 0),
      },
    },
    {
      xLabel: 'Profit',
      label: 'Actual',
      colourOverride: actualProfitBarColour,
      value: profitData?.profit.actual.value || 0,
      goal: {
        label: 'Forecast',
        value: profitData?.profit.forecast.value || 0,
        strokeColourOverride: forcastProfitBarColour,
      },
      extraTooltipKeys: [
        {
          label: 'Forecast',
          value: `${forcastProfitMargin}%`,
          colour: forcastProfitBarColour,
        },
        {
          label: 'Actual',
          value: `${actualProfitMargin}%`,
          colour: actualProfitBarColour,
        },
      ],
    },
  ];

  return (
    <Card className={className}>
      <HorizontalBarWithGoals
        barData={barData}
        valueFormatter={(val) => currencyLabel(val, currencyCode, false)}
        tooltipFormatter={(val) => currencyLabel(val, currencyCode)}
        height={200}
        width={'100%'}
      />
    </Card>
  );
};

export default ProfitBreakdown;
