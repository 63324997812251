import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { Action, PayloadAction } from 'typesafe-actions';

import {
  addOutboundEmailAddress,
  bookDemoFailure,
  bookDemoSuccess,
  checkOutboundEmailAddressConfirmation,
  checkOutboundEmailDomainDkimVerification,
  clearAccountAccessInformation,
  deleteOutboundEmailAddress,
  deleteOutboundEmailDomain,
  getAccountAccessInformationFailure,
  getAccountAccessInformationSuccess,
  getAccountUsersFailure,
  getAccountUsersSuccess,
  getConnectedAccountingIntegrationData,
  getEmailTemplatesFailure,
  getEmailTemplatesSuccess,
  getScheduledEventRemindersConfig,
  requestGetAccountAccessInformation,
  setDefaultOutboundEmailAddress,
  setPrimaryPipeline,
  storeEmailTemplates,
  updateScheduledEventRemindersConfig,
} from './accountActions';
import { AccountActionTypes, AccountSagaConfig } from './accountTypes';

import { Req } from '@payaca/helpers/storeHelper';
import { ConnectedAccountingIntegrationData } from '@payaca/types/accountingIntegrationTypes';
import { PublicScheduledEventReminderConfig } from '@payaca/types/scheduledEventReminderConfigTypes';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { getCountries } from '../countries/actions';
import { getTaxRates } from '../tax-rates/actions';
import { requestGetUserRoles } from '../userRoles/userRolesActions';
import { handleAsyncAction } from '../utils';

const accountSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: AccountSagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetAccountAccessInformation(
    action: Action<AccountActionTypes.REQUEST_GET_ACCOUNT_ACCESS_INFORMATION>
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(getAccountAccessInformation),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getAccountAccessInformationFailure(
            new Error('Get account access information request timed out.')
          )
        );
      } else {
        yield put(getAccountAccessInformationSuccess(response));
      }
    } catch (error: any) {
      yield put(getAccountAccessInformationFailure(error));
    }
  }

  const getAccountAccessInformation = async () => {
    return fetch(`${apiBaseurl}/api/accounts/access_information`, {
      method: 'GET',
      headers: {
        Authorization: await getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetAccountAccessInformation failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleRequestBookDemo(
    action: PayloadAction<
      AccountActionTypes.REQUEST_BOOK_DEMO,
      { bookDemoFor: Date; isImmediateCallback: boolean }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(
          bookDemo,
          action.payload.bookDemoFor,
          action.payload.isImmediateCallback
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(bookDemoFailure(new Error('Book demo request timed out.')));
      } else {
        yield put(bookDemoSuccess());
      }
    } catch (error: any) {
      yield put(bookDemoFailure(error));
    }
  }

  const bookDemo = async (bookDemoFor: Date, isImmediateCallback: boolean) => {
    return fetch(`${apiBaseurl}/api/accounts/book_demo`, {
      method: 'POST',
      body: JSON.stringify({
        bookDemoFor,
        isImmediateCallback,
      }),
      headers: {
        Authorization: await getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `BookDemo failed failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetEmailTemplates(
    action: PayloadAction<
      AccountActionTypes.REQUEST_GET_EMAIL_TEMPLATES,
      { accountId: number; includeDefaults: boolean }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(
          getEmailTemplates,
          action.payload.accountId,
          action.payload.includeDefaults
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get email templates request timed out.';
        yield put(
          getEmailTemplatesFailure(new Error(errorMessage), errorMessage)
        );
      } else {
        yield put(storeEmailTemplates(response));
        yield put(getEmailTemplatesSuccess(response));
      }
    } catch (error: any) {
      yield put(getEmailTemplatesFailure(error, (error as any).message));
    }
  }
  const getEmailTemplates = async (
    accountId: number,
    includeDefaults: boolean
  ) => {
    const response = await req.get(
      `/accounts/${accountId}/email_templates?includeDefaults=${includeDefaults}`
    );
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(
        `GetEmailTemplates failed: ${response.status} ${response.statusText}`
      );
    }
  };

  function* handleUpdateEmailTemplates(
    action: PayloadAction<
      AccountActionTypes.REQUEST_UPDATE_EMAIL_TEMPLATES,
      {
        accountId: number;
        emailTemplates: {
          sendQuote?: string;
          sendInvoice?: string;
          sendEstimate?: string;
        };
        callback: (err: any) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(
          updateEmailTemplates,
          action.payload.accountId,
          action.payload.emailTemplates
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw 'Updating email templates timed out';
      } else {
        yield put(storeEmailTemplates(response));
        action.payload.callback(null);
      }
    } catch (error) {
      action.payload.callback(error);
    }
  }
  const updateEmailTemplates = async (
    accountId: number,
    emailTemplates: {
      sendQuote?: string;
      sendInvoice?: string;
      sendEstimate?: string;
    }
  ) => {
    const response = await req.put(
      `/accounts/${accountId}/email_templates`,
      emailTemplates
    );
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(
        `GetEmailTemplates failed: ${response.status} ${response.statusText}`
      );
    }
  };
  function* handleGetAccountUsers(
    action: Action<AccountActionTypes.REQUEST_UPDATE_EMAIL_TEMPLATES>
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(getAccountUsers),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Updating email templates timed out';

        yield put(getAccountUsersFailure(new Error(errorMessage)));
      } else {
        yield put(getAccountUsersSuccess(response));
      }
    } catch (error: any) {
      yield put(getAccountUsersFailure(error));
    }
  }

  const getAccountUsers = async () => {
    const response = await req.get(`/accounts/users`);
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(
        `GetEmailTemplates failed: ${response.status} ${response.statusText}`
      );
    }
  };

  function* handleAppLoaded() {
    yield put(requestGetAccountAccessInformation());
    yield put(getCountries.request());
    yield put(getTaxRates.request());
  }

  function* handleAppSaveToken() {
    yield put(requestGetAccountAccessInformation());
  }

  function* handleAppLogout() {
    yield put(clearAccountAccessInformation());
  }

  function* handleLoginSuccess() {
    yield put(requestGetAccountAccessInformation());
    yield put(getCountries.request());
    yield put(getTaxRates.request());
  }

  function* handleBookDemoSuccess() {
    yield put(requestGetAccountAccessInformation());
  }

  function* handleCreateSubscriptionSuccess() {
    yield put(requestGetUserRoles());
    yield put(requestGetAccountAccessInformation());
  }

  function* handleUpdateSubscriptionSuccess() {
    yield put(requestGetUserRoles());
    yield put(requestGetAccountAccessInformation());
  }

  const handleSetPrimaryPipeline = handleAsyncAction(
    setPrimaryPipeline,
    async (args) => {
      return req.put(`/account_primary_pipeline`, {
        pipelineId: args.pipelineId,
      });
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_error, requestData) => {
      requestData.payload.onErrorCallback?.(_error);
    }
  );

  const handleGetConnectedAccountingIntegrationData = handleAsyncAction(
    getConnectedAccountingIntegrationData,
    async (args) => {
      const response = await req.get(
        `/connected_accounting_integrations/${args.integrationType}`
      );
      return await response.json();
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as ConnectedAccountingIntegrationData
      );
    },
    (_error, requestData) => {
      requestData.payload.onErrorCallback?.(_error);
    }
  );

  const handleGetScheduledEventRemindersConfig = handleAsyncAction(
    getScheduledEventRemindersConfig,
    async (args) => {
      const response = await req.get(`/scheduled_event_reminders_config`);
      return await response.json();
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as PublicScheduledEventReminderConfig[]
      );
    },
    (_error, requestData) => {
      requestData.payload.onErrorCallback?.(_error);
    }
  );

  const handleUpdateScheduledEventRemindersConfig = handleAsyncAction(
    updateScheduledEventRemindersConfig,
    async (args) => {
      const response = await req.put(`/scheduled_event_reminders_config`, {
        remindersConfig: args.remindersConfig,
      });
      return await response.json();
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as PublicScheduledEventReminderConfig[]
      );
    },
    (_error, requestData) => {
      requestData.payload.onErrorCallback?.(_error);
    }
  );

  return function* () {
    yield takeEvery(
      AccountActionTypes.ADD_OUTBOUND_EMAIL_ADDRESS_REQUEST,
      handleAsyncAction(addOutboundEmailAddress, async ({ email, cb }) => {
        try {
          await req.post('/outbound-email/emails', { email });
          cb?.();
        } catch (err) {
          cb?.(err as any);
        }
      })
    );
    yield takeEvery(
      AccountActionTypes.DELETE_OUTBOUND_EMAIL_ADDRESS_REQUEST,
      handleAsyncAction(deleteOutboundEmailAddress, async ({ email, cb }) => {
        try {
          await req.del(`/outbound-email/emails/${email}`);
          cb?.();
        } catch (err) {
          cb?.(err as any);
        }
      })
    );
    yield takeEvery(
      AccountActionTypes.DELETE_OUTBOUND_EMAIL_DOMAIN_REQUEST,
      handleAsyncAction(deleteOutboundEmailDomain, async ({ domain, cb }) => {
        try {
          await req.del(`/outbound-email/domains/${domain}`);
          cb?.();
        } catch (err) {
          cb?.(err as any);
        }
      })
    );
    yield takeEvery(
      AccountActionTypes.RESEND_OUTBOUND_EMAIL_ADDRESS_CONFIRMATION_EMAIL_REQUEST,
      handleAsyncAction(addOutboundEmailAddress, async ({ email, cb }) => {
        try {
          await req.post('/outbound-email/resend-confirmation-email', {
            email,
          });
          cb?.();
        } catch (err) {
          cb?.(err as any);
        }
      })
    );
    yield takeEvery(
      AccountActionTypes.CHECK_OUTBOUND_EMAIL_ADDRESS_CONFIRMATION_REQUEST,
      handleAsyncAction(
        checkOutboundEmailAddressConfirmation,
        async ({ email, cb }) => {
          try {
            await req.post(`/outbound-email/emails/${email}/check`, {});
            cb?.();
          } catch (err) {
            cb?.(err as any);
          }
        }
      )
    );
    yield takeEvery(
      AccountActionTypes.CHECK_OUTBOUND_EMAIL_DOMAIN_DKIM_VERIFICATION_REQUEST,
      handleAsyncAction(
        checkOutboundEmailDomainDkimVerification,
        async ({ domain, cb }) => {
          try {
            await req.post(`/outbound-email/domains/${domain}/check`, {});
            cb?.();
          } catch (err) {
            cb?.(err as any);
          }
        }
      )
    );
    yield takeEvery(
      AccountActionTypes.SET_DEFAULT_OUTBOUND_EMAIL_ADDRESS_REQUEST,
      handleAsyncAction(
        setDefaultOutboundEmailAddress,
        async ({ purpose, email, cb }) => {
          try {
            await req.put(`/outbound-email/defaults/${purpose}`, {
              email,
            });
            cb?.();
          } catch (err) {
            cb?.(err as any);
          }
        }
      )
    );

    yield takeLatest(
      AccountActionTypes.REQUEST_GET_ACCOUNT_ACCESS_INFORMATION,
      handleGetAccountAccessInformation
    );
    yield takeLatest(
      AccountActionTypes.REQUEST_BOOK_DEMO,
      handleRequestBookDemo
    );
    yield takeEvery('app.loaded', handleAppLoaded);
    yield takeEvery('auth.logout', handleAppLogout);
    yield takeEvery('auth.loginSuccess', handleLoginSuccess);
    yield takeEvery('app.saveToken', handleAppSaveToken);
    yield takeEvery(
      'subscription.createSubscriptionSuccess',
      handleCreateSubscriptionSuccess
    );
    yield takeEvery(
      'subscription.updateSubscriptionSuccess',
      handleUpdateSubscriptionSuccess
    );
    yield takeEvery(
      AccountActionTypes.BOOK_DEMO_SUCCESS,
      handleBookDemoSuccess
    );
    yield takeLatest(
      AccountActionTypes.REQUEST_GET_EMAIL_TEMPLATES,
      handleGetEmailTemplates
    );
    yield takeLatest(
      AccountActionTypes.REQUEST_UPDATE_EMAIL_TEMPLATES,
      handleUpdateEmailTemplates
    );
    yield takeLatest(
      AccountActionTypes.REQUEST_GET_ACCOUNT_USERS,
      handleGetAccountUsers
    );
    yield takeLatest(
      AccountActionTypes.SET_PRIMARY_PIPELINE_REQUEST,
      handleSetPrimaryPipeline
    );
    yield takeEvery(
      AccountActionTypes.GET_CONNECTED_ACCOUNTING_INTEGRATION_DATA_REQUEST,
      handleGetConnectedAccountingIntegrationData
    );
    yield takeEvery(
      AccountActionTypes.GET_SCHEDULED_EVENT_REMINDERS_CONFIG_REQUEST,
      handleGetScheduledEventRemindersConfig
    );
    yield takeEvery(
      AccountActionTypes.UPDATE_SCHEDULED_EVENT_REMINDERS_CONFIG_REQUEST,
      handleUpdateScheduledEventRemindersConfig
    );
  };
};

export default accountSagaCreator;
