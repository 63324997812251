import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Action, PayloadAction } from 'typesafe-actions';

import { ActionType, SagaConfig } from './tagsTypes';

import { TaggableEntityType } from '@payaca/types/tagTypes';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import {
  addExistingTagToEntityFailure,
  addExistingTagToEntitySuccess,
  clearTags,
  getTagsFailure,
  getTagsSuccess,
  removeTagFromEntityFailure,
  removeTagFromEntitySuccess,
  requestGetTags,
} from './tagsActions';

const tagsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  function* handleGetTags(action: Action) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getTags),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(getTagsFailure(new Error('Get tags timed out.')));
      } else {
        yield put(getTagsSuccess(response));
      }
    } catch (error: any) {
      yield put(getTagsFailure(error as Error));
    }
  }

  const getTags = async () => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/tags`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get tags failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleAddExistingTagToEntity(
    action: PayloadAction<
      ActionType.REQUEST_ADD_EXISTING_TAG_TO_ENTITY,
      {
        tagId: number;
        entityId: number;
        entityType: TaggableEntityType;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          addExistingTagToEntity,
          action.payload.tagId,
          action.payload.entityId,
          action.payload.entityType
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          addExistingTagToEntityFailure(
            new Error('Add existing tag to entity timed out.')
          )
        );
      } else {
        yield put(addExistingTagToEntitySuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(addExistingTagToEntityFailure(error as Error));
    }
  }

  const addExistingTagToEntity = async (
    tagId: number,
    entityId: number,
    entityType: TaggableEntityType
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/tags/entity`, {
      method: 'PUT',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
      body: JSON.stringify({
        tagId,
        entityId,
        entityType,
      }),
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `Add existing tag to entity failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleRemoveTagFromEntity(
    action: PayloadAction<
      ActionType.REQUEST_REMOVE_TAG_FROM_ENTITY,
      {
        tagId: number;
        entityId: number;
        entityType: TaggableEntityType;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          removeTagFromEntity,
          action.payload.tagId,
          action.payload.entityId,
          action.payload.entityType
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          removeTagFromEntityFailure(
            new Error('Remove tag from entity timed out.')
          )
        );
      } else {
        yield put(removeTagFromEntitySuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(removeTagFromEntityFailure(error as Error));
    }
  }

  const removeTagFromEntity = async (
    tagId: number,
    entityId: number,
    entityType: TaggableEntityType
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/tags/entity`, {
      method: 'DELETE',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
      body: JSON.stringify({
        tagId,
        entityId,
        entityType,
      }),
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `Remove tag from entity failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleLogout() {
    yield put(clearTags());
  }

  function* handleLoginSuccess() {
    yield put(requestGetTags());
  }

  return function* () {
    yield takeLatest(ActionType.REQUEST_GET_TAGS, handleGetTags);
    yield takeLatest(
      ActionType.REQUEST_ADD_EXISTING_TAG_TO_ENTITY,
      handleAddExistingTagToEntity
    );
    yield takeLatest(
      ActionType.REQUEST_REMOVE_TAG_FROM_ENTITY,
      handleRemoveTagFromEntity
    );

    yield takeEvery('auth.logout', handleLogout);
    yield takeEvery('auth.loginSuccess', handleLoginSuccess);
  };
};

export default tagsSagaCreator;
