import { Grow, Paper, Popper } from '@material-ui/core';
import { useMemo, useRef, useState } from 'react';
import { User } from 'react-iconly';

import MultiUsersIndicator from '../multiUsersIndicator/MultiUsersIndicator';
import UserIndicator from '../userIndicator/UserIndicator';

import './AssignedUsersIndicator.sass';

type Props = {
  assignedUsers: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    userColour?: string;
    imgSrc?: string;
  }[];
  size?: 'md' | 'sm' | 'xs';
};

const AssignedUsersIndicator = ({
  assignedUsers,
  size = 'md',
}: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [showPopper, setShowPopper] = useState(false);

  const popperContent = useMemo(() => {
    return (
      <div className="popper-content">
        <div className="title">
          <User size={'small'} />
          <span>
            {assignedUsers.length} assigned{' '}
            {assignedUsers.length > 1 ? 'users' : 'user'}
          </span>
        </div>
        {assignedUsers.map((user, i) => {
          return (
            <div key={i} className="flex-container flex-center flex-contiguous">
              <UserIndicator
                user={user}
                isUserInformationHoverPopperEnabled={false}
                size="sm"
              />
              <span>
                {user.firstName} {user.lastName}
              </span>
            </div>
          );
        })}
        {/* <small>
          <strong>
            {user.firstName} {user.lastName}
          </strong>
        </small>
        <small>{user.emailAddress}</small> */}
      </div>
    );
  }, [assignedUsers]);

  return (
    <div
      className={`assigned-users-indicator`}
      ref={ref}
      onMouseEnter={() => setShowPopper(true)}
      onMouseLeave={() => setShowPopper(false)}
    >
      <MultiUsersIndicator
        users={assignedUsers}
        size={size}
        isUserInformationHoverPopperEnabled={false}
      />
      <Popper
        open={showPopper && !!ref.current}
        anchorEl={ref.current}
        role={undefined}
        transition
        placement={'top'}
        className={`assigned-users-indicator-popper`}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <Paper>
              <div>{popperContent}</div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default AssignedUsersIndicator;
