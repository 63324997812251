import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type Props = React.PropsWithChildren<{
  downBreakpointSm?: number;
  downBreakpointXs?: number;
  upBreakpointLg?: number;
  className?: string;
  onElementWidthChange?: (width: number) => void;
}>;

const ResponsiveViewWrapper: FunctionComponent<Props> = ({
  downBreakpointSm,
  downBreakpointXs,
  upBreakpointLg,
  className,
  children,
  onElementWidthChange,
}: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [responsiveViewClassName, setResponsiveViewClassName] = useState('');

  const calculateResponsiveViewClassName = useCallback(() => {
    if (!ref.current) {
      setResponsiveViewClassName('');
      return;
    }

    const width = ref.current.offsetWidth;
    onElementWidthChange && width != undefined && onElementWidthChange(width);

    if (downBreakpointXs && width <= downBreakpointXs) {
      setResponsiveViewClassName('xs-view');
    } else if (downBreakpointSm && width <= downBreakpointSm) {
      setResponsiveViewClassName('sm-view');
    } else if (upBreakpointLg && width >= upBreakpointLg) {
      setResponsiveViewClassName('lg-view');
    } else {
      setResponsiveViewClassName('');
    }
  }, [
    ref,
    downBreakpointSm,
    downBreakpointXs,
    upBreakpointLg,
    onElementWidthChange,
  ]);

  useEffect(() => {
    typeof window !== 'undefined' &&
      window.addEventListener('resize', calculateResponsiveViewClassName);

    calculateResponsiveViewClassName();

    return () => {
      typeof window !== 'undefined' &&
        window.removeEventListener('resize', calculateResponsiveViewClassName);
    };
  }, [ref, calculateResponsiveViewClassName]);

  return (
    <div
      className={`responsive-view-wrapper ${className} ${responsiveViewClassName}`}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default ResponsiveViewWrapper;
