import { Address as _Address } from '@/gql/graphql';
import ConditionalWrapper from '@payaca/components/conditionalWrapper/ConditionalWrapper';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Modal from '@payaca/components/plModal/Modal';
import ValidatedForm, {
  TRenderFormContents,
} from '@payaca/components/validatedForm/ValidatedForm';
import { FC, useCallback, useMemo } from 'react';

type Address = Partial<_Address>;

export interface IProps {
  address?: Address;
  // Memoize this function
  onSave?: (address: Address) => void;
  saveButtonText?: string;
  onClose?: () => void;
  isInModal?: boolean;
}

type TFormState = Address;

const ManuallyAddEditAddressControl: FC<IProps> = (props) => {
  const {
    address,
    onSave,
    saveButtonText = 'Save',
    onClose,
    isInModal = false,
  } = props;

  const initialFormState = useMemo<TFormState>(
    () => ({ ...address }),
    [address]
  );

  const renderFormContents = useCallback<TRenderFormContents<TFormState>>(
    (
      isValid,
      formState,
      validationState,
      touchedState,
      onFieldChange,
      onFieldTouch
    ) => {
      return (
        <>
          <ConditionalWrapper condition={isInModal} Wrapper={Modal.Body}>
            <div className="flex flex-col gap-4">
              <Field name="line1">
                <Field.Label>Address line 1</Field.Label>
                <Input
                  value={formState.line1 || ''}
                  onChange={(value) => onFieldChange({ line1: value })}
                />
              </Field>
              <Field name="line2">
                <Field.Label>Address line 2</Field.Label>
                <Input
                  value={formState.line2 || ''}
                  onChange={(value) => onFieldChange({ line2: value })}
                />
              </Field>
              <Field name="city">
                <Field.Label>Town / city</Field.Label>
                <Input
                  value={formState.city || ''}
                  onChange={(value) => onFieldChange({ city: value })}
                />
              </Field>
              <Field name="postcode">
                <Field.Label>Postcode</Field.Label>
                <Input
                  value={formState.postalCode || ''}
                  onChange={(value) => onFieldChange({ postalCode: value })}
                />
              </Field>
            </div>
          </ConditionalWrapper>

          <ConditionalWrapper condition={isInModal} Wrapper={Modal.Footer}>
            <ConditionalWrapper
              condition={isInModal}
              Wrapper={Modal.Footer.Actions}
            >
              <Button variant={EBtnVariant.White} onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">{saveButtonText}</Button>
            </ConditionalWrapper>
          </ConditionalWrapper>
        </>
      );
    },
    [saveButtonText, onClose, isInModal]
  );

  return (
    <ValidatedForm<TFormState>
      initialFormState={initialFormState}
      renderFormContents={renderFormContents}
      onFormSubmit={onSave}
    />
  );
};

export default ManuallyAddEditAddressControl;
