import { FC } from 'react';

import Modal from '@payaca/components/modal/Modal';
import CreateInvoiceControl from '../createInvoiceControl/CreateInvoiceControl';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCreateInvoiceSuccess?: (invoiceId: number) => void;
  dealId: number;
};
const CreateInvoiceModal: FC<Props> = ({
  isOpen,
  onClose,
  onCreateInvoiceSuccess,
  dealId,
}: Props): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      className="create-invoice-modal"
      title={'Create Invoice'}
      onClose={onClose}
    >
      <CreateInvoiceControl
        dealId={dealId}
        onCreateInvoiceSuccess={onCreateInvoiceSuccess}
      />
    </Modal>
  );
};
export default CreateInvoiceModal;
