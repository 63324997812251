import { FC, PropsWithChildren } from 'react';

import Conditional from '@payaca/components/conditional/Conditional';
import Badge from '@payaca/components/plBadge/Badge';
import Button, { IButtonProps } from '@payaca/components/plButton/Button';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import ScrollSpy, {
  IProps as IScrollSpyProps,
} from '@payaca/components/plScrollSpy/ScrollSpy';
import Tooltip from '@payaca/components/plTooltip/Tooltip';

import { getApplicationStatusBadgeColour } from '@/ui/pages/connectionApplicationPage/utils';

export interface IProps {
  sections: IScrollSpyProps['sections'];
  submitProps?: IButtonProps;
  status?: string;
  connectionApplicationStatus?: string | null;
}

const ConnectionApplicationLayout: FC<PropsWithChildren<IProps>> = (props) => {
  const {
    sections,
    submitProps,
    status,
    connectionApplicationStatus,
    children,
  } = props;

  return (
    <div className="space-y-4">
      <ScrollSpy
        sections={sections}
        scrollElement={document.querySelector('.page-content') || document.body}
        scrollOffset={200}
      >
        <Card
          className="z-sticky sticky top-[60px] my-4"
          sizeVariant={CardSizeVariant.SM}
        >
          <Card.Body>
            <div className="mb-4 flex items-center gap-2">
              <h1>
                <Tooltip tooltipContent="Distribution Network Operator is a company responsible for operating, maintaining, and upgrading the electrical distribution network in a specific geographic region.">
                  DNO
                </Tooltip>{' '}
                application
              </h1>

              <Badge
                colour={getApplicationStatusBadgeColour(status)}
                variant="soft"
                className="capitalize"
              >
                {status}
                {connectionApplicationStatus
                  ? ` (${connectionApplicationStatus})`
                  : ''}
              </Badge>

              <Conditional condition={!!submitProps}>
                <Button
                  type="submit"
                  className="ml-auto"
                  size={EBtnSize.Small}
                  {...submitProps}
                >
                  Submit
                </Button>
              </Conditional>
            </div>

            <ScrollSpy.Nav className="-mx-4 -mb-4 rounded-b-xl px-4 md:-mx-5 md:-mb-5 md:px-5" />
          </Card.Body>
        </Card>

        <ScrollSpy.Sections />

        {children}
      </ScrollSpy>
    </div>
  );
};

export default ConnectionApplicationLayout;
