import {
  call,
  delay,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { Action, PayloadAction } from 'typesafe-actions';

import { Req } from '@payaca/helpers/storeHelper';
import {
  AuthorisePayload,
  AuthWithAppleData,
  AuthWithGoogleData,
  AuthWithXeroData,
  RegisterOAuthPayload,
  RemoveOAuthPayload,
  SignUpAccountUpdateV2RequestPayload,
  SignUpUserWithEmailPayload,
  SignUpUserWithEmailV2RequestPayload,
  SignUpWithAppleData,
  SignUpWithGoogleData,
  SignUpWithXeroData,
  VerifyOAuthPayload,
} from '@payaca/types/authTypes';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { getCountries } from '../countries/actions';
import { getTaxRates } from '../tax-rates/actions';
import {
  clearAnalyticsUserId,
  clearAuthLoginSignUp,
  loginFailure,
  loginSuccess,
  refreshAuthTokenFailure,
  refreshAuthTokenSuccess,
  resendEmailVerificationFailure,
  resendEmailVerificationSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  signUpAccountUpdateV2Failure,
  signUpAccountUpdateV2Success,
  signUpFailure,
  signUpSuccess,
  signUpWithEmailFailure,
  signUpWithEmailSuccess,
  storeTokens,
  storeTokensSuccess,
  validateVerificationTokenFailure,
  validateVerificationTokenSuccess,
  verifyUserTokenFailure,
  verifyUserTokenSuccess,
} from './authActions';
import { AuthActionTypes, AuthSagaConfig } from './authTypes';

const formatContactNumber = (phone: string) =>
  phone && phone[0] !== '+' ? `+${phone}` : phone;

function* handleLoginResult(
  response: { token: string; refreshToken: string },
  timeout: any
) {
  if (timeout) {
    yield put(loginFailure(new Error('Login request timed out')));
  } else {
    yield put(loginSuccess(response.token, response.refreshToken));
  }
}

function* handleSignUpResult(
  response: { token: string; refreshToken: string },
  timeout: any
) {
  if (timeout) {
    yield put(signUpFailure(new Error('Sign up request timed out')));
  } else {
    yield put(signUpSuccess(response.token, response.refreshToken));
  }
}

const authSagaCreator = ({
  apiBaseurl,
  storeAuthTokens,
  getRefreshToken,
  getAuthHeader,
  isNativeApp = false,
}: AuthSagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleLogout() {
    yield call(storeAuthTokens, {
      token: '',
      refreshToken: '',
    });
    yield put(clearAnalyticsUserId());
    yield put(clearAuthLoginSignUp());
  }

  function* handleClearAnalyticsUserId() {
    yield call(resetAnalyticsState);
  }

  function* handleRefreshAuthTokenSuccess(
    action: PayloadAction<
      AuthActionTypes.REFRESH_AUTH_TOKEN_SUCCESS,
      {
        token: string;
        refreshToken: string;
      }
    >
  ) {
    yield put(storeTokens(action.payload.token, action.payload.refreshToken));
  }

  function* handleLoginSuccess(
    action: PayloadAction<
      AuthActionTypes.LOGIN_SUCCESS,
      {
        token: string;
        refreshToken: string;
      }
    >
  ) {
    yield put(storeTokens(action.payload.token, action.payload.refreshToken));
  }

  function* handleSignUpSuccess(
    action: PayloadAction<
      AuthActionTypes.SIGN_UP_SUCCESS,
      {
        token: string;
        refreshToken: string;
      }
    >
  ) {
    yield put(storeTokens(action.payload.token, action.payload.refreshToken));
    yield put(getCountries.request());
    yield put(getTaxRates.request());
  }

  function* handleStoreTokens(
    action: PayloadAction<
      AuthActionTypes.STORE_TOKENS,
      {
        token: string;
        refreshToken: string;
      }
    >
  ) {
    yield call(storeAuthTokens, {
      token: action.payload.token,
      refreshToken: action.payload.refreshToken,
    });
    yield put(storeTokensSuccess());
  }

  function* handleAuthorise(
    action: PayloadAction<
      AuthActionTypes.AUTHORISE,
      {
        payload: AuthorisePayload;
        allow: boolean;
        callback: (resp: any, err: any) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(authorise, action.payload.payload, action.payload.allow),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw 'Authorising connection timed out';
      }
      if (action.payload.callback) {
        action.payload.callback(null, response);
      }
    } catch (error) {
      if (action.payload.callback) {
        action.payload.callback(error, null);
      }
    }
  }

  function* handleLogin(
    action: PayloadAction<
      AuthActionTypes.REQUEST_LOGIN,
      {
        email: string;
        password: string;
        isPayacaAdminLogin: boolean;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(
          login,
          action.payload.email,
          action.payload.password,
          action.payload.isPayacaAdminLogin
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      yield call(handleLoginResult, response, timeout);
    } catch (error: any) {
      yield put(loginFailure(error));
    }
  }

  function* handleLoginWithToken(
    action: PayloadAction<
      AuthActionTypes.REQUEST_LOGIN_WITH_TOKEN,
      {
        token: string;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(loginWithToken, action.payload.token),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      yield call(handleLoginResult, response, timeout);
    } catch (error: any) {
      yield put(loginFailure(error));
    }
  }

  function* handleLoginWithXero(
    action: PayloadAction<
      AuthActionTypes.REQUEST_LOGIN_WITH_XERO,
      {
        data: AuthWithXeroData;
        callback?: (error: string | null) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(loginWithSocial, 'xero', action.payload.data),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Login with xero request timed out';
        yield put(loginFailure(new Error(errorMessage)));
        action.payload.callback && action.payload.callback(errorMessage);
      } else {
        yield put(loginSuccess(response.token, response.refreshToken));
        action.payload.callback && action.payload.callback(null);
      }
    } catch (error: any) {
      yield put(loginFailure(error));
      action.payload.callback && action.payload.callback(error.message);
    }
  }

  function* handleLoginWithGoogle(
    action: PayloadAction<
      AuthActionTypes.REQUEST_LOGIN_WITH_GOOGLE,
      {
        data: AuthWithGoogleData;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(loginWithSocial, 'google', action.payload.data),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      yield call(handleLoginResult, response, timeout);
    } catch (error: any) {
      yield put(loginFailure(error));
    }
  }

  function* handleLoginWithApple(
    action: PayloadAction<
      AuthActionTypes.REQUEST_LOGIN_WITH_APPLE,
      {
        data: AuthWithAppleData;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(loginWithSocial, 'apple', action.payload.data),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      yield call(handleLoginResult, response, timeout);
    } catch (error: any) {
      yield put(loginFailure(error));
    }
  }

  function* handleSignUpWithXeroV1(
    action: PayloadAction<
      AuthActionTypes.REQUEST_SIGN_UP_WITH_XERO_V1,
      {
        data: SignUpWithXeroData;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(registerWithSocialV1, 'xero', action.payload.data),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      yield call(handleSignUpResult, response, timeout);
    } catch (error: any) {
      yield put(signUpFailure(error));
    }
  }

  function* handleSignUpWithGoogleV1(
    action: PayloadAction<
      AuthActionTypes.REQUEST_SIGN_UP_WITH_GOOGLE_V1,
      {
        data: SignUpWithGoogleData;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(registerWithSocialV1, 'google', action.payload.data),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      yield call(handleSignUpResult, response, timeout);
    } catch (error: any) {
      yield put(signUpFailure(error));
    }
  }

  function* handleSignUpWithAppleV1(
    action: PayloadAction<
      AuthActionTypes.REQUEST_SIGN_UP_WITH_APPLE_V1,
      {
        data: SignUpWithAppleData;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(registerWithSocialV1, 'apple', action.payload.data),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      yield call(handleSignUpResult, response, timeout);
    } catch (error: any) {
      yield put(signUpFailure(error));
    }
  }

  function* handleSignUpWithXero(
    action: PayloadAction<
      AuthActionTypes.REQUEST_SIGN_UP_WITH_XERO,
      {
        data: SignUpWithXeroData;
        callback: (error: string | null) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(registerWithSocial, 'xero', action.payload.data),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Sign up with xero request timed out';
        yield put(signUpFailure(new Error(errorMessage)));
        action.payload.callback(errorMessage);
      } else {
        yield put(signUpSuccess(response.token, response.refreshToken));
        action.payload.callback(null);
      }
    } catch (error: any) {
      yield put(signUpFailure(error));
      action.payload.callback(error.message);
    }
  }

  function* handleSignUpWithGoogle(
    action: PayloadAction<
      AuthActionTypes.REQUEST_SIGN_UP_WITH_GOOGLE,
      {
        data: SignUpWithGoogleData;
        callback: (error: string | null) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(registerWithSocial, 'google', action.payload.data),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Sign up with Google request timed out';
        yield put(signUpFailure(new Error(errorMessage)));
        action.payload.callback(errorMessage);
      } else {
        yield put(signUpSuccess(response.token, response.refreshToken));
        action.payload.callback(null);
      }
    } catch (error: any) {
      yield put(signUpFailure(error));
      action.payload.callback(error.message);
    }
  }

  function* handleRefreshAuthToken(
    action: Action<AuthActionTypes.REQUEST_REFRESH_AUTH_TOKEN>
  ) {
    try {
      // @ts-ignore
      const refreshToken = yield call(getRefreshToken);
      const { response, timeout } = yield race({
        response: call(refreshAuthToken, refreshToken),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          refreshAuthTokenFailure(
            new Error('Refresh auth token request timed out')
          )
        );
      } else {
        yield put(
          refreshAuthTokenSuccess(response.token, response.refreshToken)
        );
      }
    } catch (error: any) {
      yield put(refreshAuthTokenFailure(error));
    }
  }

  function* handleRegisterOAuthClient(
    action: PayloadAction<
      AuthActionTypes.REGISTER_O_AUTH_CLIENT,
      { payload: RegisterOAuthPayload; callback: (resp: any, err: any) => void }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(registerOAuthClient, action.payload.payload),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw 'Registering OAuth client timed out';
      }
      if (action.payload.callback) {
        action.payload.callback(null, response);
      }
    } catch (error) {
      if (action.payload.callback) {
        action.payload.callback(error, null);
      }
    }
  }

  function* handleRemoveOAuthClient(
    action: PayloadAction<
      AuthActionTypes.REMOVE_O_AUTH_CONNECTION,
      { payload: RemoveOAuthPayload; callback: (err: any, resp: any) => void }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(removeOAuthConnection, action.payload.payload),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw 'Removing OAuth client timed out';
      }
      if (action.payload.callback) {
        action.payload.callback(null, response);
      }
    } catch (error) {
      if (action.payload.callback) {
        action.payload.callback(error, null);
      }
    }
  }

  function* handleVerifyOAuth(
    action: PayloadAction<
      AuthActionTypes.VERIFY_O_AUTH,
      { payload: VerifyOAuthPayload; callback: (err: any, resp: any) => void }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(verifyOAuth, action.payload.payload),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw 'Verifying oauth timed out';
      }
      if (action.payload.callback) {
        action.payload.callback(null, response);
      }
    } catch (error) {
      if (action.payload.callback) {
        action.payload.callback(error, null);
      }
    }
  }

  function* handleResetPassword(
    action: PayloadAction<
      AuthActionTypes.REQUEST_RESET_PASSWORD,
      {
        email: string;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(resetPassword, action.payload.email),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(resetPasswordFailure(new Error('Reset password timed out.')));
      } else {
        yield put(resetPasswordSuccess());
      }
    } catch (error: any) {
      yield put(resetPasswordFailure(error));
    }
  }

  const resetPassword = async (email: string) => {
    const response = await req.post('/forgot', {
      email,
    });
    return await response;
  };

  function* handleResendEmailVerification(
    action: PayloadAction<
      AuthActionTypes.REQUEST_RESEND_EMAIL_VERIFICATION,
      {
        email: string;
        callback: (error?: string) => void;
      }
    >
  ) {
    try {
      const { timeout } = yield race({
        response: call(resendEmailVerification, action.payload.email),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Resend email verification timed out.';
        yield put(
          resendEmailVerificationFailure(new Error(errorMessage), errorMessage)
        );
        action.payload.callback(errorMessage);
      } else {
        yield put(resendEmailVerificationSuccess());
        action.payload.callback();
      }
    } catch (error: any) {
      yield put(resendEmailVerificationFailure(error, error.message));
      action.payload.callback(error.message);
    }
  }

  const resendEmailVerification = async (email: string) => {
    const response = await req.post('/verify', {
      email,
    });
    return await response;
  };

  function* handleSignUpWithEmailV2(
    action: PayloadAction<
      AuthActionTypes.REQUEST_SIGN_UP_WITH_EMAIL_V2,
      {
        signUpUserWithEmailV2Payload: SignUpUserWithEmailV2RequestPayload;
        callback: (error?: string) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(
          signUpWithEmailV2,
          action.payload.signUpUserWithEmailV2Payload
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Sign up with email v2 timed out.';
        yield put(
          signUpWithEmailFailure(new Error(errorMessage), errorMessage)
        );
        action.payload.callback(errorMessage);
      } else {
        yield put(signUpWithEmailSuccess());
        yield put(storeTokens(response.token, response.refreshToken));
        action.payload.callback();
      }
    } catch (error: any) {
      yield put(signUpWithEmailFailure(error, error.message));
      action.payload.callback(error.message);
    }
  }

  const signUpWithEmailV2 = async ({
    email,
    firstName,
    lastName,
    contactNumber,
    referralToken,
    industryType,
    industryTypeOther,
    numberOfEmployees,
  }: SignUpUserWithEmailV2RequestPayload) => {
    const response = await req.post('/usersV2', {
      email,
      firstname: firstName,
      lastname: lastName,
      contactNumber: formatContactNumber(contactNumber),
      referralToken,
      industryType,
      industryTypeOther,
      numberOfEmployees,
    });
    return await response.json();
  };

  function* handleSignUpWithEmail(
    action: PayloadAction<
      AuthActionTypes.REQUEST_SIGN_UP_WITH_EMAIL,
      {
        signUpUserWithEmailPayload: SignUpUserWithEmailPayload;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(
          signUpWithEmail,
          action.payload.signUpUserWithEmailPayload
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Sign up with email timed out.';
        yield put(
          signUpWithEmailFailure(new Error(errorMessage), errorMessage)
        );
      } else {
        yield put(signUpWithEmailSuccess());
        yield put(storeTokens(response.token, response.refreshToken));
      }
    } catch (error: any) {
      yield put(signUpWithEmailFailure(error, error.message));
    }
  }

  const signUpWithEmail = async ({
    email,
    password,
    firstName,
    lastName,
    contactNumber,
  }: SignUpUserWithEmailPayload) => {
    const response = await req.post('/users', {
      email,
      password,
      newsletter: true,
      firstname: firstName,
      lastname: lastName,
      contactNumber: formatContactNumber(contactNumber),
    });
    return await response.json();
  };

  function* handleSignUpAccountUpdateV2(
    action: PayloadAction<
      AuthActionTypes.REQUEST_SIGN_UP_ACCOUNT_UPDATE_V2,
      {
        signUpAccountUpdateV2Payload: SignUpAccountUpdateV2RequestPayload;
        callback: (error?: string) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(
          signUpAccountUpdateV2,
          action.payload.signUpAccountUpdateV2Payload
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Sign up account update timed out.';
        yield put(
          signUpAccountUpdateV2Failure(new Error(errorMessage), errorMessage)
        );
        action.payload.callback(errorMessage);
      } else {
        yield put(signUpAccountUpdateV2Success());
        action.payload.callback();
      }
    } catch (error: any) {
      yield put(signUpAccountUpdateV2Failure(error, error.message));
      action.payload.callback(error.message);
    }
  }

  const signUpAccountUpdateV2 = async ({
    legalBusinessName,
    numberOfEmployees,
    preferredTimezone,
    preferredLocale,
  }: SignUpAccountUpdateV2RequestPayload) => {
    const response = await req.put('/accountsV2', {
      legalBusinessName,
      numberOfEmployees,
      preferredTimezone,
      preferredLocale,
    });
    return await response.json();
  };

  function* handleValidateVerificationToken(
    action: PayloadAction<
      AuthActionTypes.REQUEST_VALIDATE_VERIFICATION_TOKEN,
      {
        token: string;
        callback?: (error: string | null, isPasswordRequired?: boolean) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(validateVerificationToken, action.payload.token),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Validate verification token timed out.';
        yield put(
          validateVerificationTokenFailure(
            new Error(errorMessage),
            errorMessage
          )
        );
        action.payload?.callback && action.payload.callback(errorMessage);
      } else {
        yield put(validateVerificationTokenSuccess());
        action.payload?.callback &&
          action.payload.callback(null, response.isPasswordRequired);
      }
    } catch (error: any) {
      yield put(validateVerificationTokenFailure(error, error.message));
      action.payload?.callback && action.payload.callback(error.message);
    }
  }

  const validateVerificationToken = async (token: string) => {
    const response = await req.get(`/verify/${token}/validate`);
    return await response.json();
  };

  function* handleVerifyUserToken(
    action: PayloadAction<
      AuthActionTypes.REQUEST_VERIFY_USER_TOKEN,
      {
        token: string;
        newPassword: string;
        callback?: (error: string | null) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(
          verifyUserToken,
          action.payload.token,
          action.payload.newPassword
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Verify user token timed out.';
        yield put(
          verifyUserTokenFailure(new Error(errorMessage), errorMessage)
        );
        action.payload?.callback && action.payload.callback(errorMessage);
      } else {
        yield put(verifyUserTokenSuccess());
        action.payload?.callback && action.payload.callback(null);

        // store user auth tokens
        yield put(storeTokens(response.token, response.refreshToken));
        yield take('auth.storeTokensSuccess');
      }
    } catch (error: any) {
      yield put(verifyUserTokenFailure(error, error.message));
      action.payload?.callback && action.payload.callback(error.message);
    }
  }

  const verifyUserToken = async (token: string, newPassword?: string) => {
    const response = await req.put(`/verify/${token}`, {
      newPassword,
    });
    return await response.json();
  };

  const resetAnalyticsState = () => {
    // we'll un-assign the id of the active user against all analytics activity
    // @ts-ignore
    if (window?.posthog?.reset) {
      // @ts-ignore
      window.posthog.reset();
    }
  };

  const authorise = async (payload: AuthorisePayload, allow: boolean) => {
    return fetch(`${apiBaseurl}/api/oauth/authorise?allow=${allow}`, {
      method: 'POST',
      headers: {
        Authorization: await getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
      body: JSON.stringify({ path: payload.path, scopes: payload.scopes }),
    }).then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw {
          status: response.status,
          ...response.json(),
        };
      }
    });
  };

  const registerOAuthClient = async (payload: RegisterOAuthPayload) => {
    const response = await req.post(`/oauth/register_client`, {
      clientName: payload.clientName,
      redirectUrl: payload.redirectUrl,
    });
    return await response.json();
  };

  const removeOAuthConnection = async (payload: RemoveOAuthPayload) => {
    return req
      .post(`/oauth/revoke_access`, {
        clientId: payload.clientId,
      })
      .then(async (response) => {
        if (response.ok) {
          return;
        } else {
          throw new Error(
            `removeOauthConnection failed: ${response.status} ${response.statusText}`
          );
        }
      });
  };

  const verifyOAuth = async (payload: VerifyOAuthPayload) => {
    return fetch(`${apiBaseurl}/api/oauth/verify`, {
      method: 'POST',
      headers: {
        Authorization: await getAuthHeader(),
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
      body: JSON.stringify({
        redirectUri: payload.redirectUri,
        clientId: payload.clientId,
        responseType: payload.responseType,
        state: payload.state,
        scopes: payload.scope,
      }),
    }).then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw {
          status: response.status,
          ...response.json(),
        };
      }
    });
  };

  const login = async (
    email: string,
    password: string,
    isPayacaAdminLogin: boolean
  ) => {
    const response = await req.post(`/login`, {
      email: email,
      password: password,
      isPayacaAdminLogin: isPayacaAdminLogin,
    });
    return await response.json();
  };

  const loginWithToken = async (token: string) => {
    const response = await req.post(`/login/${token}`, {});
    return await response.json();
  };

  const loginWithSocial = async (
    loginProvider: string,
    data: AuthWithGoogleData | AuthWithAppleData | AuthWithXeroData
  ) => {
    const response = await req.post(`/login`, {
      provider: loginProvider,
      ...data,
    });
    return await response.json();
  };

  const registerWithSocialV1 = async (
    loginProvider: string,
    data: SignUpWithGoogleData | SignUpWithAppleData | SignUpWithXeroData
  ) => {
    const response = await req.post(`/users`, {
      provider: loginProvider,
      ...data,
    });
    return await response.json();
  };
  const registerWithSocial = async (
    loginProvider: string,
    data: SignUpWithGoogleData | SignUpWithAppleData | SignUpWithXeroData
  ) => {
    const response = await req.post(`/usersV2`, {
      provider: loginProvider,
      ...data,
    });
    return await response.json();
  };

  const refreshAuthToken = async (refreshToken: string) => {
    const response = await req.post(`/token`, {
      refreshToken,
    });
    return await response.json();
  };

  return function* () {
    yield takeEvery(AuthActionTypes.AUTHORISE, handleAuthorise);
    yield takeEvery(
      AuthActionTypes.REGISTER_O_AUTH_CLIENT,
      handleRegisterOAuthClient
    );
    yield takeEvery(
      AuthActionTypes.REMOVE_O_AUTH_CONNECTION,
      handleRemoveOAuthClient
    );
    yield takeEvery(AuthActionTypes.VERIFY_O_AUTH, handleVerifyOAuth);
    yield takeEvery(AuthActionTypes.REQUEST_LOGIN, handleLogin);
    yield takeEvery(
      AuthActionTypes.REQUEST_LOGIN_WITH_TOKEN,
      handleLoginWithToken
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_LOGIN_WITH_APPLE,
      handleLoginWithApple
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_LOGIN_WITH_GOOGLE,
      handleLoginWithGoogle
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_LOGIN_WITH_XERO,
      handleLoginWithXero
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_SIGN_UP_WITH_APPLE_V1,
      handleSignUpWithAppleV1
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_SIGN_UP_WITH_GOOGLE_V1,
      handleSignUpWithGoogleV1
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_SIGN_UP_WITH_XERO_V1,
      handleSignUpWithXeroV1
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_SIGN_UP_WITH_GOOGLE,
      handleSignUpWithGoogle
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_SIGN_UP_WITH_XERO,
      handleSignUpWithXero
    );
    yield takeLatest(
      AuthActionTypes.REQUEST_REFRESH_AUTH_TOKEN,
      handleRefreshAuthToken
    );
    yield takeEvery(AuthActionTypes.STORE_TOKENS, handleStoreTokens);
    yield takeLatest(AuthActionTypes.LOGIN_SUCCESS, handleLoginSuccess);
    yield takeLatest(AuthActionTypes.SIGN_UP_SUCCESS, handleSignUpSuccess);
    yield takeLatest(
      AuthActionTypes.REFRESH_AUTH_TOKEN_SUCCESS,
      handleRefreshAuthTokenSuccess
    );

    yield takeLatest(AuthActionTypes.LOGOUT, handleLogout);
    yield takeLatest(
      AuthActionTypes.CLEAR_ANALYTICS_USER_ID,
      handleClearAnalyticsUserId
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_RESET_PASSWORD,
      handleResetPassword
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_SIGN_UP_WITH_EMAIL,
      handleSignUpWithEmail
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_SIGN_UP_WITH_EMAIL_V2,
      handleSignUpWithEmailV2
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_SIGN_UP_ACCOUNT_UPDATE_V2,
      handleSignUpAccountUpdateV2
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_RESEND_EMAIL_VERIFICATION,
      handleResendEmailVerification
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_VALIDATE_VERIFICATION_TOKEN,
      handleValidateVerificationToken
    );
    yield takeEvery(
      AuthActionTypes.REQUEST_VERIFY_USER_TOKEN,
      handleVerifyUserToken
    );
  };
};

export default authSagaCreator;
