import { useSelector } from '@/api/state';
import { useHashFragment } from '@/utils/customHooks';
import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import { TTableRowAction } from '@payaca/components/plAdvancedTable/AdvancedTable';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, useCallback, useMemo, useReducer, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import useGetMyTaxRates from '../../../api/queries/me/useGetMyTaxRates';
import useGetSupplierMaterials from '../../../api/queries/suppliers/useGetSupplierMaterials';
import {
  GetMaterialsForSupplierInput,
  GetSupplierMaterialsQuery,
} from '../../../gql/graphql';
import { getUserRoles } from '../../../utils/stateAccessors';
import { usePaginationState } from '../../pages/dealPage/components/MaterialsPanel/materialsPanelHooks';
import ViewMaterialSidebar from '../../pages/listedMaterialsPage/components/ViewMaterialSidebar';
import BulkUpdateModal from '../bulkUploadDownloadModals/BulkUpdateModal';
import BulkUploadModal from '../bulkUploadDownloadModals/BulkUploadModal';
import CreateEditSupplierMaterialModal from '../createEditSupplierMaterialModal/CreateEditSupplierMaterialModal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';

type ModalState = {
  modal?: 'CREATE_EDIT_SUPPLIER_MATERIAL';
  supplierMaterialId?: string;
};

type ModalAction =
  | {
      type: 'OPEN_CREATE_EDIT_SUPPLIER_MATERIAL';
      payload: { supplierMaterialId?: string };
    }
  | { type: 'RESET' };

const modalStateReducer = (
  state: ModalState,
  action: ModalAction
): ModalState => {
  switch (action.type) {
    case 'OPEN_CREATE_EDIT_SUPPLIER_MATERIAL':
      return {
        modal: 'CREATE_EDIT_SUPPLIER_MATERIAL' as const,
        supplierMaterialId: action.payload.supplierMaterialId,
      };
    case 'RESET':
      return {};
    default:
      return state;
  }
};

type ListedSupplierMaterial =
  GetSupplierMaterialsQuery['supplier']['materials']['items'][number];
type Props = {
  supplierId: number;
  onSupplierUpdateSuccess: () => void;
};
const SupplierSupplierMaterials: FC<Props> = ({
  supplierId,
  onSupplierUpdateSuccess,
}: Props): JSX.Element | null => {
  const history = useHistory();
  const pagination = usePaginationState(20);
  const [modalState, modalStateDispatch] = useReducer(modalStateReducer, {});

  const { path, url } = useRouteMatch();
  const isViewMaterialSidebarOpen = useRouteMatch<{
    materialId: string;
  }>(`${path}/:materialId`);
  const [showBulkUploadModal, toggleBulkUploadModal] =
    useHashFragment('#bulk-upload');
  const [showBulkUpdateModal, toggleBulkUpdateModal] =
    useHashFragment('#bulk-update');
  const userRoles = useSelector(getUserRoles);
  const { defaultTaxRate } = useGetMyTaxRates();

  const [searchTerm, setSearchTerm] = useState<string>();

  const [getMaterialsForSupplierInput, setGetMaterialsForSupplierInput] =
    useState<GetMaterialsForSupplierInput>({});

  const {
    data,
    supplierMaterials,
    refetch: refetchSupplierMaterials,
    isLoading,
  } = useGetSupplierMaterials({
    supplierId,
    pagination,
    input: getMaterialsForSupplierInput,
  });

  const supplier = data?.supplier;

  const isPayacaAdmin = useSelector(
    (state: any) => state.users.myProfile.isPayacaAdmin
  );

  const tableRowActions = useCallback(
    (supplierMaterial: ListedSupplierMaterial) => {
      const actions: TTableRowAction<ListedSupplierMaterial>[] = [];

      if (
        userHasRequiredPermission(userRoles, [
          MaterialsPermissions.PERSIST_SUPPLIER_MATERIAL,
        ]) &&
        !supplierMaterial.isSystemManaged
      ) {
        actions.push({
          label: 'Edit',
          onClick: () =>
            modalStateDispatch({
              type: 'OPEN_CREATE_EDIT_SUPPLIER_MATERIAL',
              payload: { supplierMaterialId: supplierMaterial.id.toString() },
            }),
        });
      }

      return actions;
    },
    [supplierId]
  );

  const supplierMaterialPropForCreateEditSupplierMaterialModal = useMemo(() => {
    if (modalState.modal !== 'CREATE_EDIT_SUPPLIER_MATERIAL') {
      return;
    }
    const supplierMaterial = supplierMaterials?.items.find(
      (item) => item.id === modalState.supplierMaterialId
    );
    if (supplierMaterial) {
      return {
        id: supplierMaterial.id.toString(),
        supplierInternalId: supplierId,
        materialInternalId: +supplierMaterial.material.internalId,
        price: supplierMaterial.price.unitPriceExcTax.value,
        reference: supplierMaterial.reference,
        url: supplierMaterial.url,
        taxRateId: supplierMaterial.price.taxRate?.id?.toString(),
        isPreferredSupplier: supplierMaterial.material.preferredSupplierId
          ? +supplierMaterial.material.preferredSupplierId === supplierId
          : false,
        isSystemManaged: supplierMaterial.isSystemManaged,
      };
    }

    return {
      supplierInternalId: supplierId,
      taxRateId: defaultTaxRate?.id,
      isSystemManaged: false,
    };
  }, [supplierMaterials, modalState, supplierId, defaultTaxRate]);

  return (
    <>
      <ManageableItemsList>
        <ManageableItemsList.HeaderBar
          heading="Materials"
          buttons={
            supplier && (
              <PermissionGuard
                renderIfHasPermissions={[
                  MaterialsPermissions.PERSIST_SUPPLIER_MATERIAL,
                ]}
              >
                <PermissionGuard
                  requireAllPermissions
                  renderIfHasPermissions={[
                    MaterialsPermissions.BULK_IMPORT_SUPPLIER_MATERIALS,
                  ]}
                >
                  <>
                    {(!supplier.isSystemManaged || isPayacaAdmin) && (
                      <Tooltip tooltipContent="Download and update Materials from CSV data">
                        <Button
                          onClick={toggleBulkUpdateModal}
                          className="download-button"
                          size={EBtnSize.Small}
                          variant={EBtnVariant.White}
                          colour={EBtnColour.Black}
                        >
                          <UntitledIcon
                            name="download-01.3"
                            className="h-5 w-5"
                          />
                        </Button>
                      </Tooltip>
                    )}
                    {!supplier.isSystemManaged && (
                      <Tooltip tooltipContent="Upload Materials from CSV data">
                        <Button
                          onClick={toggleBulkUploadModal}
                          className="upload-button"
                          size={EBtnSize.Small}
                          variant={EBtnVariant.White}
                          colour={EBtnColour.Black}
                        >
                          <UntitledIcon
                            name="upload-01.3"
                            className="h-5 w-5"
                          />
                        </Button>
                      </Tooltip>
                    )}
                  </>
                </PermissionGuard>
                {!supplier.isSystemManaged ? (
                  <Button
                    onClick={() =>
                      modalStateDispatch({
                        type: 'OPEN_CREATE_EDIT_SUPPLIER_MATERIAL',
                        payload: {},
                      })
                    }
                    size={EBtnSize.Small}
                  >
                    Add Material
                  </Button>
                ) : (
                  <div />
                )}
              </PermissionGuard>
            )
          }
        />
        <ManageableItemsList.ActionBar>
          <ManageableItemsList.ActionBar.SearchInput
            value={searchTerm}
            onChange={setSearchTerm}
            onChangeTimeout={(value) =>
              setGetMaterialsForSupplierInput((s) => ({
                ...s,
                searchTerm: value,
              }))
            }
            changeTimeoutMs={250}
          />
        </ManageableItemsList.ActionBar>
        <ManageableItemsList.Table
          isLoading={isLoading}
          items={supplierMaterials?.items || []}
          uniqueKey="id"
          onClickRow={(supplierMaterial) => {
            history.push(`materials/${supplierMaterial.material.id}`);
          }}
          itemActions={tableRowActions}
        >
          <ManageableItemsList.Table.Column<ListedSupplierMaterial, 'material'>
            header="Image"
            field="material"
            render={(material) => {
              if (!material.image?.thumbnailUrl) {
                return (
                  <div className="flex h-14 w-14 items-center justify-center">
                    <UntitledIcon
                      name="image-01.3"
                      vectorEffect="non-scaling-stroke"
                      className="h-8 w-8 text-gray-700"
                    />
                  </div>
                );
              }

              return (
                <img
                  className="h-14 w-14 rounded-md"
                  alt={material.name}
                  src={material.image.thumbnailUrl}
                />
              );
            }}
            className="w-[100px]"
          />
          <ManageableItemsList.Table.Column<ListedSupplierMaterial, 'material'>
            header="Material"
            field="material"
            render={(material) => material.name}
          />
          <ManageableItemsList.Table.Column
            header="Reference"
            field="reference"
            render={(_, supplierMaterial) => {
              const supplierMaterialEl = !!supplierMaterial?.reference && (
                <span className="text-sm">
                  Ref: {supplierMaterial.reference}
                </span>
              );
              return (
                <div className="flex flex-col">
                  {supplierMaterial.url ? (
                    <a
                      href={supplierMaterial.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {supplierMaterialEl}
                      <UntitledIcon
                        name="link-external-01.3"
                        className="ml-2 h-3.5 w-3.5"
                      />
                    </a>
                  ) : (
                    supplierMaterialEl
                  )}
                </div>
              );
            }}
          />
          <ManageableItemsList.Table.Column
            header="Price (ex tax)"
            field="price"
            render={(price) => (
              <CurrencyValue
                value={price.unitPriceExcTax.value}
                currency={price.unitPriceExcTax.currency}
              />
            )}
          />
        </ManageableItemsList.Table>
        {!!supplierMaterials && (
          <ManageableItemsList.PaginationBar
            totalItems={supplierMaterials.totalCount}
            pageSize={supplierMaterials.limit}
            currentPage={supplierMaterials.offset / supplierMaterials.limit + 1}
            onPageChange={pagination.setCurrentPage}
          />
        )}
      </ManageableItemsList>
      <CreateEditSupplierMaterialModal
        isOpen={modalState.modal === 'CREATE_EDIT_SUPPLIER_MATERIAL'}
        onClose={() => modalStateDispatch({ type: 'RESET' })}
        supplierMaterial={
          supplierMaterialPropForCreateEditSupplierMaterialModal
        }
        isSupplierSelectionDisabled
        isMaterialSelectionDisabled={!!modalState.supplierMaterialId}
        onPersistSupplierMaterialSuccess={() => {
          modalStateDispatch({ type: 'RESET' });
          onSupplierUpdateSuccess();
          void refetchSupplierMaterials();
        }}
      />
      <ViewMaterialSidebar
        isOpen={!!isViewMaterialSidebarOpen}
        materialId={isViewMaterialSidebarOpen?.params.materialId}
        onClose={() => {
          history.push(url);
          void refetchSupplierMaterials();
        }}
      />

      {supplierId && (
        <>
          <BulkUploadModal
            isOpen={showBulkUploadModal}
            onClose={toggleBulkUploadModal}
            title="Upload materials"
            dataType="supplierMaterials"
            metadata={{ supplierId }}
          ></BulkUploadModal>
          <BulkUpdateModal
            isOpen={showBulkUpdateModal}
            onClose={toggleBulkUpdateModal}
            title="Update materials"
            dataType="supplierMaterials"
            metadata={{ supplierId }}
            additionalMessage={
              supplier?.isSystemManaged && (
                <>
                  <p>
                    <strong>Warning!</strong> Uploading a CSV file will update
                    system-managed supplier materials for all accounts. Please
                    do not proceed unless you are absolutely sure you want to do
                    this!
                  </p>
                </>
              )
            }
          ></BulkUpdateModal>
        </>
      )}
    </>
  );
};

export default SupplierSupplierMaterials;
