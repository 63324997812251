import { FC, useState } from 'react';

import { useSelector } from '@/api/state';
import { usePrevious } from '@/utils/customHooks';
import { getInvoice } from '@/utils/stateAccessors';
import {
  ErrorMessage,
  SuccessMessage,
} from '@payaca/components/feedbackMessage/FeedbackMessage';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import { useEffect, useMemo } from 'react';
import './EditInvoiceSaveFeedback.sass';

type Props = {
  invoiceId: number;
};
const EditInvoiceSaveFeedback: FC<Props> = ({
  invoiceId,
}: Props): JSX.Element => {
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [showSavedMessageTimeout, setShowSavedMessageTimeout] = useState<any>();

  const invoice = useSelector((state) => getInvoice(state, invoiceId));

  const invoiceUpdateResult = useSelector((state) => {
    return (
      state.invoices.invoiceUpdateResults &&
      state.invoices.invoiceUpdateResults[invoiceId]
    );
  });

  const hasInvoiceUpdateError = useMemo(() => {
    return invoiceUpdateResult?.isUpdatedSuccessfully === false;
  }, [invoiceUpdateResult]);

  const hasAnyErrors = useMemo(() => {
    return hasInvoiceUpdateError;
  }, [hasInvoiceUpdateError]);

  const invoiceUpdateErrorMessage = useMemo(() => {
    if (hasAnyErrors) {
      return "Something went wrong - some of your changes haven't been saved";
    }
  }, [hasAnyErrors]);

  const isModifyingInvoiceOrRelatedEntities: boolean = useSelector((state) => {
    return state.invoices.isUpdatingInvoice;
  });

  const isUpdatingInvoice: boolean = useSelector((state) => {
    return state.invoices.isUpdatingInvoice;
  });

  const isPersistingCustomer: boolean = useSelector((state) => {
    return state.customer.isPersistingCustomer;
  });

  const isSettingDealCustomer: boolean = useSelector((state) => {
    return state.deals.isSettingDealCustomer;
  });

  const isProcessing = useMemo(() => {
    return (
      isUpdatingInvoice ||
      isPersistingCustomer ||
      isSettingDealCustomer ||
      isModifyingInvoiceOrRelatedEntities
    );
  }, [
    isUpdatingInvoice,
    isPersistingCustomer,
    isSettingDealCustomer,
    isModifyingInvoiceOrRelatedEntities,
  ]);

  const previousIsProcessing = usePrevious(isProcessing);

  useEffect(() => {
    if (!isProcessing && previousIsProcessing && !hasAnyErrors) {
      setShowSavedMessage(true);
      setShowSavedMessageTimeout(
        setTimeout(() => {
          setShowSavedMessage(false);
        }, 1000)
      );
    }
  }, [isProcessing]);

  return (
    <div className="edit-invoice-save-feedback flex-container flex-center">
      <div
        className={`saved-message-container ${
          showSavedMessage && !isProcessing ? '' : ' hidden'
        }`}
      >
        <SuccessMessage message={'Progress saved!'} />
      </div>

      <div
        className={`error-message-container ${
          invoiceUpdateErrorMessage ? '' : ' hidden'
        }`}
      >
        {invoiceUpdateErrorMessage && (
          <ErrorMessage message={invoiceUpdateErrorMessage} />
        )}
      </div>
      {isProcessing && (
        <div className="processing-container">
          <MiniLoader />
        </div>
      )}
    </div>
  );
};

export default EditInvoiceSaveFeedback;
