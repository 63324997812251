import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UpdateProposalLineItemInput,
  UpdateProposalLineItemMutation,
} from '@/gql/graphql';

const UPDATE_PROPOSAL_LINE_ITEM_MUTATION = graphql(`
  mutation UpdateProposalLineItem($input: UpdateProposalLineItemInput!) {
    updateProposalLineItem(input: $input) {
      id
    }
  }
`);

const useUpdateProposalLineItem = (
  options?: UseMutationOptions<
    UpdateProposalLineItemMutation,
    unknown,
    UpdateProposalLineItemInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UPDATE_PROPOSAL_LINE_ITEM_MUTATION, { input });
    },
    ...options,
  });
};

export default useUpdateProposalLineItem;
