import { FC, useMemo } from 'react';

import StatusBadge from '@payaca/components/statusBadge/StatusBadge';
import { getJobStatusBadgeState } from '@payaca/helpers/jobHelperV2';
import { ReadableJobStatus } from '@payaca/types/jobTypes';

type Props = {
  status?: ReadableJobStatus;
};

const JobStatusBadge: FC<Props> = ({ status }: Props): JSX.Element | null => {
  const state = useMemo(() => getJobStatusBadgeState(status), [status]);

  if (!status) return null;

  return <StatusBadge state={state}>{status}</StatusBadge>;
};

export default JobStatusBadge;
