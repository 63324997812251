import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import useUpdateContact from '@/api/mutations/contacts/useUpdateContact';
import complianceKeys from '@/api/queries/compliance/keyFactory';
import projectKeys from '@/api/queries/project/keyFactory';
import {
  ProjectQuery,
  UpdateContactInput,
  UpdateContactMutation as _UpdateContactMutation,
} from '@/gql/graphql';

const useOptimisticUpdateContactOnProject = (
  projectId: number,
  options?: UseMutationOptions<
    _UpdateContactMutation,
    unknown,
    UpdateContactInput
  >
) => {
  const queryClient = useQueryClient();

  return useUpdateContact({
    ...options,
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: projectKeys.overview(projectId),
      });

      // Snapshot the previous value
      const previousProjectOverview = queryClient.getQueryData(
        projectKeys.overview(projectId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<ProjectQuery>(
        projectKeys.overview(projectId),
        (old) => {
          if (!old) return;

          const updatedProjectSiteAddresses = old.project.addresses.site.map(
            (address) => {
              return {
                ...address,
                contacts: address.contacts.map((contact) => {
                  if (contact.id === variables.contactId) {
                    return {
                      ...contact,
                      name: variables.name,
                      description: variables.description,
                      email: variables.email,
                      phone: variables.phone,
                    };
                  } else {
                    return contact;
                  }
                }),
              };
            }
          );

          if (!updatedProjectSiteAddresses) {
            // Should never get here
            return old;
          }

          return {
            ...old,
            project: {
              ...old.project,
              addresses: {
                site: updatedProjectSiteAddresses,
              },
            },
          };
        }
      );

      options?.onMutate?.(variables);

      // Return a context object with the snapshotted value
      return { previousProjectOverview };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        projectKeys.overview(projectId),
        // @ts-ignore
        context.previousProjectOverview
      );

      options?.onError?.(err, newTodo, context);
    },
    // Always refetch after error or success:
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.overview(projectId),
      });
      queryClient.invalidateQueries({
        queryKey: complianceKeys.formPrefill(projectId),
      });

      options?.onSettled?.(...args);
    },
  });
};

export default useOptimisticUpdateContactOnProject;
