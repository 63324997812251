import * as suppliersActions from '@payaca/store/suppliers/suppliersActions';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import Combobox from '@payaca/components/plCombobox/Combobox';
import Input from '@payaca/components/plInput/Input';
import { SelectOption } from '@payaca/components/plSelect/Select';
import useGetSupplier from '../../../api/queries/suppliers/useGetSupplier';
import useGetSuppliers from '../../../api/queries/suppliers/useGetSuppliers';
import CreateEditSupplierModal from '../createEditSupplierModal/CreateEditSupplierModal';

type ListedSupplier = {
  id: number;
  name: string;
};
interface Props {
  selectedSupplierId?: number;
  onChange: (selectedSupplierId?: number | null) => void;
  enableSupplierCreation?: boolean;
  excludeSystemManaged?: boolean;
  canChangeSupplier?: boolean;
}
const SupplierSelectionControl: FC<Props> = ({
  selectedSupplierId,
  onChange,
  enableSupplierCreation = false,
  excludeSystemManaged = false,
  canChangeSupplier = true,
}: Props): JSX.Element => {
  const [showCreateSupplierModal, setShowCreateSupplierModal] = useState(false);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>();

  const { suppliers, refetch: refetchSuppliers } = useGetSuppliers(
    { searchTerm, managedBy: excludeSystemManaged ? ['SELF'] : undefined },
    { limit: 100 }
  );

  const { supplier: selectedSupplier } = useGetSupplier(
    selectedSupplierId ? `${selectedSupplierId}` : undefined
  );

  const supplierSelectOptions = useMemo(() => {
    const o: SelectOption<number, ListedSupplier>[] =
      suppliers?.items.map((s) => ({
        value: +s.id,
        label: s.name,
        groupId: 'suppliers',
      })) || [];
    if (enableSupplierCreation) {
      o.push({
        groupId: 'other',
        label: 'Add a new Supplier',
        value: -1,
      });
    }
    return o;
  }, [suppliers, enableSupplierCreation]);

  const supplierSelectOptionGroups = [
    { label: 'Suppliers', id: 'suppliers' },
    ...(enableSupplierCreation
      ? [
          {
            label: 'Other',
            id: 'other',
          },
        ]
      : []),
  ];

  const handleCreateSupplier = useCallback((supplierName: string) => {
    dispatch(
      suppliersActions.requestPersistSupplier(
        { name: supplierName },
        (supplierId: number) => {
          onChange(supplierId);
        }
      )
    );
  }, []);

  return (
    <>
      {canChangeSupplier ? (
        <Combobox
          value={selectedSupplierId}
          options={supplierSelectOptions}
          optionGroups={supplierSelectOptionGroups}
          filterFunction={null}
          query={searchTerm}
          setQuery={setSearchTerm}
          placeholder="Start typing to search for a Supplier..."
          inputPlaceholder="Start typing to search for a Supplier..."
          onOptionClick={(_e, option) => {
            if (option?.value === -1 && enableSupplierCreation) {
              if (searchTerm) {
                handleCreateSupplier(searchTerm);
              } else {
                setShowCreateSupplierModal(true);
              }
            } else {
              onChange(option?.value);
              setSearchTerm(undefined);
            }
          }}
        />
      ) : (
        <Input value={selectedSupplier?.name} disabled />
      )}

      <CreateEditSupplierModal
        isOpen={showCreateSupplierModal}
        onClose={() => setShowCreateSupplierModal(false)}
        onPersistSupplierSuccess={(supplierId: number) => {
          void refetchSuppliers();
          onChange(supplierId);
        }}
      />
    </>
  );
};

export default SupplierSelectionControl;
