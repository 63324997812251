import UntitledIcon from '@payaca/untitled-icons';
import { FC } from 'react';
import Badge from '../plBadge/Badge';
import UntitledLoading from '../plUntitledLoading/UntitledLoading';
import './Tag.sass';

type Props = {
  tagText: string;
  colour?: string;
  isProcessing?: boolean;
  isRemovable?: boolean;
  onRemoveTag?: () => void;
  showTagIcon?: boolean;
};

const Tag: FC<Props> = ({
  tagText,
  colour = '#000000',
  isRemovable = false,
  isProcessing = false,
  onRemoveTag,
  showTagIcon = true,
}: Props): JSX.Element => {
  return (
    <Badge
      size="sm"
      variant="custom"
      colour={colour}
      className="group min-w-0 max-w-full truncate"
    >
      {showTagIcon && (
        <UntitledIcon name="tag-01.3" className="h-3 w-3 shrink-0 grow-0" />
      )}
      <span className="min-w-0 truncate">{tagText}</span>

      {isProcessing && (
        <UntitledLoading className="ml-2 h-3 w-3 shrink-0 grow-0" />
      )}
      {!isProcessing && isRemovable && !!onRemoveTag && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onRemoveTag?.();
          }}
          className={`ml-2 flex w-2 shrink-0 grow-0 cursor-pointer items-center border-none bg-transparent p-0 text-inherit outline-none transition-all`}
        >
          <UntitledIcon name="x-close" className="h-2 w-2" />
        </button>
      )}
    </Badge>
  );
};

export default Tag;
