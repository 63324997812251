import { useSelector } from '@/api/state';
import { usePermissions } from '@/hooks/usePermissions';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Badge from '@payaca/components/plBadge/Badge';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import { getSimpleEventContactsWithRoles } from '@payaca/helpers/scheduledEventsHelper';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import {
  useCustomer,
  useDeal,
  useScheduledEvent,
} from '@payaca/store/hooks/appState';
import {
  ScheduledEvent,
  SimpleEventContactWithRoles,
} from '@payaca/types/scheduledEventsTypes';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, useMemo, useState } from 'react';
import { SendEventConfirmationModal } from '../sendEventConfirmationModal/SendEventConfirmationModal';
import {
  SectionBodyBlock,
  SectionDivider,
  SectionTitle,
} from './SectionComponents';

export const ContactsSection: FC<{
  scheduledEventId: ScheduledEvent['id'];
}> = ({ scheduledEventId }) => {
  const scheduledEvent = useScheduledEvent(scheduledEventId);
  const [showEventConfirmationModal, setShowEventConfirmationModal] =
    useState(false);

  const { userHasRequiredPermission } = usePermissions();

  const currentUserId = useSelector((state: any) => state.users.myProfile.id);

  const linkedDeal = useDeal(scheduledEvent?.dealId);
  const linkedCustomer = useCustomer(
    scheduledEvent?.customerIds?.length
      ? scheduledEvent.customerIds[0]
      : undefined
  );

  const userCanUpdateEvent = useMemo(() => {
    return userHasRequiredPermission([
      ScheduledEventsPermissions.UPDATE_EVENT,
      ...(linkedDeal ? [ScheduledEventsPermissions.UPDATE_MY_DEAL_EVENT] : []),
      ...(scheduledEvent?.createdByUserId === currentUserId
        ? [ScheduledEventsPermissions.UPDATE_MY_EVENT]
        : []),
    ]);
  }, []);

  const contacts = useMemo(() => {
    if (scheduledEvent) {
      return getSimpleEventContactsWithRoles(scheduledEvent, linkedCustomer);
    }

    return [];
  }, [linkedCustomer, scheduledEvent]);

  if (!scheduledEvent) return null;

  if (!contacts.length) return null;

  return (
    <div>
      <div className="flex flex-row justify-between gap-4">
        <SectionTitle>Contacts</SectionTitle>
        {userCanUpdateEvent && (
          <>
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={() => setShowEventConfirmationModal(true)}
            >
              Send Event confirmation
            </Button>
            <SendEventConfirmationModal
              scheduledEvent={scheduledEvent}
              contacts={contacts}
              isOpen={showEventConfirmationModal}
              onClose={() => setShowEventConfirmationModal(false)}
            />
          </>
        )}
      </div>
      <SectionDivider />
      <div className="flex flex-col gap-2">
        {contacts.map((c, i) => (
          <Contact contact={c} key={`${c.type}-${c.id}`} />
        ))}
      </div>
    </div>
  );
};

const Contact: FC<{ contact: SimpleEventContactWithRoles }> = ({ contact }) => {
  return (
    <SectionBodyBlock>
      <div>
        <span className="mb-2 flex items-center gap-2">
          <span className="h-4 w-4">
            <UntitledIcon name="user-01" className="h-4 w-4" />
          </span>
          {contact.name}
        </span>
        <div className="flex flex-row flex-wrap gap-x-4 gap-y-2">
          {!!contact.emailAddress?.length && (
            <span style={{ wordBreak: 'break-word' }} className="mr-auto grow">
              <a
                href={`mailto:${contact.emailAddress}`}
                className="flex items-center gap-2"
              >
                <span className="h-4 w-4">
                  <UntitledIcon name="mail-02.3" className="h-4 w-4" />
                </span>
                {contact.emailAddress}
              </a>
            </span>
          )}
          <div className="ml-auto flex shrink-0 grow flex-row gap-x-4">
            {!!contact.telephoneNumber?.length && (
              <span
                style={{ wordBreak: 'break-word' }}
                className="shrink-0 grow-0 basis-[130px]"
              >
                <a
                  href={`tel:${contact.telephoneNumber}`}
                  className="flex items-center gap-2"
                >
                  <span className="h-4 w-4">
                    <UntitledIcon
                      name="phone-outgoing-02.3"
                      className="h-4 w-4"
                    />
                  </span>
                  {contact.telephoneNumber}
                </a>
              </span>
            )}

            <span className="ml-auto flex max-h-[20px] min-w-[80px] shrink-0 grow-0 basis-[80px] items-center justify-self-end">
              {contact.roles.includes('accessContact') && (
                <Badge size="md" colour="blue" variant="soft" rounded>
                  Access <UntitledIcon name="key-01.3" className="h-3 w-3" />
                </Badge>
              )}
            </span>
            <div className="flex max-h-[20px] min-w-[30px] shrink-0 grow-0 basis-[30px] items-center justify-self-end">
              {contact.roles.includes('contactToNotify') && (
                <Tooltip
                  showArrow={true}
                  tooltipContent={
                    <div className="max-w-[250px]">
                      This contact will be notified when you send an Event
                      confirmation
                    </div>
                  }
                >
                  <Badge size="sm" variant="soft" colour="blue">
                    <UntitledIcon
                      name="bell-ringing-01.3"
                      className="my-1 h-4 w-4"
                    />
                  </Badge>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </SectionBodyBlock>
  );
};
