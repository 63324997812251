import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import meKeys from './keyFactory';

const GetMyBusinessHours = graphql(`
  query MyBusinessHours {
    me {
      user {
        account {
          businessHours
        }
      }
    }
  }
`);

const useGetMyBusinessHours = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.businessHours(),
    queryFn: () => {
      return gqlClient.request(GetMyBusinessHours);
    },
    staleTime: Infinity,
  });

  return { data: data?.me.user.account.businessHours, ...rest };
};

export default useGetMyBusinessHours;
