import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import useUpdateCustomerContact from '@/api/mutations/customers/useUpdateCustomerContacts';
import customerKeys from '@/api/queries/customers/keyFactory';
import {
  CustomerContact,
  CustomerQuery,
  UpdateCustomerContactInput,
  UpdateCustomerContactMutation as _UpdateCustomerContactMutation,
} from '@/gql/graphql';

const useOptimisticUpdateCustomerContactOnCustomer = (
  customerId: string,
  options?: UseMutationOptions<
    _UpdateCustomerContactMutation,
    unknown,
    UpdateCustomerContactInput
  >
) => {
  const queryClient = useQueryClient();

  return useUpdateCustomerContact({
    ...options,
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: customerKeys.customer(customerId),
      });

      // Snapshot the previous value
      const previousCustomer = queryClient.getQueryData(
        customerKeys.customer(customerId)
      );

      const newCustomerContact: CustomerContact = {
        ...variables,
        id: variables.customerContactId,
        isPrimary: variables.isPrimary || false,
      };

      // Optimistically update to the new value
      queryClient.setQueryData<CustomerQuery>(
        customerKeys.customer(customerId),
        (old) => {
          if (!old) return;

          return {
            ...old,
            customer: {
              ...old.customer,
              contacts: old.customer.contacts.map((i) =>
                i.id === newCustomerContact.id ? newCustomerContact : i
              ),
            },
          };
        }
      );

      options?.onMutate?.(variables);

      // Return a context object with the snapshotted value
      return { previousCustomer };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        customerKeys.customer(customerId),
        // @ts-ignore
        context.previousCustomer
      );

      options?.onError?.(err, newTodo, context);
    },
    // Always refetch after error or success:
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: customerKeys.customer(customerId),
      });

      options?.onSettled?.(...args);
    },
  });
};

export default useOptimisticUpdateCustomerContactOnCustomer;
