import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Button from '@payaca/components/plButton/Button';
import AddCustomerEmailModal from '../addCustomerEmailModal/AddCustomerEmailModal';
import SendableDocumentEmailControl from '../previewAndSendSendableDocument/SendableDocumentEmailControl';

import * as customerActions from '@payaca/store/customer/customerActions';
import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';

import { useCustomer, useServicePlan } from '@payaca/store/hooks/appState';
import { useAccount } from '../../../utils/storeHooks';

import { getPrimaryContactFromCustomer } from '@payaca/helpers/customerHelper';

import { useSelector } from '../../../api/state';

import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { Nullish } from '@payaca/utilities/types';

import useSendServicePlanInvitation from '@/api/mutations/servicePlans/useSendServicePlanInvitation';
import { CustomerSelect } from '@/ui/components/customerSelect/CustomerSelect';
import Field from '@payaca/components/plField/Field';
import Modal from '@payaca/components/plModal/Modal';
import Select from '@payaca/components/plSelect/Select';
import { getSubscriptionsForCustomer } from '@payaca/store/servicePlans/servicePlansActions';
import {
  PublicHydratedServicePlanDiscountCode,
  PublicHydratedServicePlanSubscription,
} from '@payaca/types/service-plans';
import './ServicePlanInviteCustomerModal.sass';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  servicePlanId?: string;
  customerId?: number;
};
const ServicePlanInviteCustomerModal = ({
  isOpen,
  onClose,
  servicePlanId,
  customerId,
}: Props) => {
  const dispatch = useDispatch();
  const account = useAccount();
  const [isInvitingCustomer, setIsInvitingCustomer] = useState(false);
  const [hasSuccessfullyInvited, setHasSuccessfullyInvited] = useState(false);
  const [servicePlanDiscountCodes, setSericePlanDiscountCodes] = useState<
    | {
        label: PublicHydratedServicePlanDiscountCode['name'];
        value: PublicHydratedServicePlanDiscountCode['publicId'];
      }[]
    | undefined
  >();
  const [selectedDiscountCodeId, setSelectedDiscountCodeId] = useState<
    PublicHydratedServicePlanDiscountCode['publicId'] | undefined
  >();
  const [showError, setShowError] = useState<string | false>(false);

  const [showAddCustomerEmailModal, setShowAddCustomerEmailModal] =
    useState(false);
  const [selectedCustomerId, setSelectedCustomerId] =
    useState<Nullish<number>>(customerId);

  const [selectedServicePlanId, setSelectedServicePlanId] =
    useState(servicePlanId);
  const servicePlan = useServicePlan(selectedServicePlanId);
  const customer = useCustomer(selectedCustomerId || undefined);
  const { mutateAsync: sendServicePlanInvitationMutation } =
    useSendServicePlanInvitation();
  const [customerSubscriptions, setCustomerSubscriptions] =
    useState<PublicHydratedServicePlanSubscription[]>();

  const customerHasSubscriptionForCurrentPlan = useMemo(() => {
    return customerSubscriptions?.some(
      (x) =>
        x.servicePlan.publicId === selectedServicePlanId &&
        x.status !== 'draft' &&
        x.status !== 'cancelled'
    );
  }, [customerSubscriptions, selectedServicePlanId]);

  const listedServicePlans = useSelector(
    (state) => state.servicePlans.listedServicePlans
  );

  const [emailState, setEmailState] = useState<{
    emailCopy: {
      preButton: string;
      postButton: string;
    };
    sendMeACopy: boolean;
  }>({
    emailCopy: {
      preButton: `Hey,\n\nYou are invited to subscribe.`,
      postButton: `Thanks,\n\n${account.companyName}`,
    },
    sendMeACopy: true,
  });

  useEffect(() => {
    setEmailState({
      ...emailState,
      emailCopy: {
        preButton: `Hey ${customer?.name},\n\nYou are invited to subscribe to ${servicePlan?.name}.`,
        postButton: `Thanks,\n\n${account.companyName}`,
      },
    });
  }, [account, customer, servicePlan]);

  useEffect(() => {
    // reset modal
    if (isOpen) {
      if (!servicePlanId) {
        dispatch(servicePlansActions.getListedServicePlans.request({}));
      }
      setIsInvitingCustomer(false);
      setHasSuccessfullyInvited(false);
      setShowError(false);
      setSelectedServicePlanId(servicePlanId);
      setSelectedCustomerId(customerId);
      setSelectedDiscountCodeId(undefined);
    }
  }, [isOpen, servicePlanId, customerId]);

  useEffect(() => {
    if (isOpen && selectedServicePlanId) {
      dispatch(
        servicePlansActions.getServicePlan.request({
          publicId: selectedServicePlanId,
        })
      );
      dispatch(
        servicePlansActions.getServicePlanDiscountCodes.request({
          publicId: selectedServicePlanId,
          callback: (res) =>
            setSericePlanDiscountCodes(
              res.map((i) => ({ label: i.name, value: i.publicId }))
            ),
        })
      );
    }
  }, [isOpen, selectedServicePlanId]);

  const servicePlanOptions = useMemo(() => {
    return (
      listedServicePlans
        ?.filter((listedServicePlan) => listedServicePlan.planCanBeSold)
        .map((listedServicePlan) => ({
          value: listedServicePlan.publicId,
          label: listedServicePlan.name,
        })) || []
    );
  }, [listedServicePlans]);

  useEffect(() => {
    if (selectedCustomerId) {
      dispatch(
        getSubscriptionsForCustomer.request({
          customerId: selectedCustomerId,
          callback: (customerSubscriptions) => {
            setCustomerSubscriptions(customerSubscriptions);
          },
        })
      );
    } else {
      setCustomerSubscriptions(undefined);
    }
  }, [selectedCustomerId]);

  const inviteCustomer = async () => {
    setHasSuccessfullyInvited(false);
    setShowError(false);
    if (selectedServicePlanId && selectedCustomerId && customer) {
      const contact = getPrimaryContactFromCustomer(customer);
      const customerHasEmail = !!contact?.emailAddress;
      if (!customerHasEmail) {
        setShowAddCustomerEmailModal(true);
      } else {
        setIsInvitingCustomer(true);

        try {
          await sendServicePlanInvitationMutation({
            customerId: selectedCustomerId.toString(),
            servicePlanPublicId: selectedServicePlanId,
            servicePlanDiscountCodePublicId: selectedDiscountCodeId,
            preButtonEmailText: emailState.emailCopy.preButton,
            postButtonEmailText: emailState.emailCopy.postButton,
            sendMeACopy: emailState.sendMeACopy,
          });

          setHasSuccessfullyInvited(true);
          setIsInvitingCustomer(false);
        } catch (e) {
          setIsInvitingCustomer(false);
          setShowError(
            `Sorry, there was an error inviting ${customer.name} to your Service Plan. Please try again.`
          );
        }
      }
    }
  };

  return (
    <Modal
      title={'Invite to Service Plan'}
      isOpen={isOpen}
      onClose={onClose}
      className="service-plan-invite-customer-modal"
    >
      <Modal.Body className="space-y-4">
        {hasSuccessfullyInvited ? (
          <p>
            An invitation to your Service Plan has successfully been sent to{' '}
            {customer?.name}.
          </p>
        ) : (
          <>
            {/* choose customer */}
            {!customerId && (
              <Field>
                <Field.Label>Customer</Field.Label>

                <CustomerSelect
                  customerId={selectedCustomerId || undefined}
                  onChange={(value) => {
                    value &&
                      dispatch(customerActions.requestGetCustomer(value));
                    setCustomerSubscriptions(undefined);
                    setSelectedCustomerId(value || null);
                  }}
                />
              </Field>
            )}
            {/* choose service plan */}
            {!servicePlanId && (
              <Field name="servicePlanId">
                <Field.Label>Service Plan</Field.Label>

                <Select<string, false>
                  value={selectedServicePlanId}
                  options={servicePlanOptions}
                  onChange={setSelectedServicePlanId}
                  placeholder="Choose a Service Plan"
                />
              </Field>
            )}

            {/* choose a discount code */}
            {!!servicePlanDiscountCodes?.length && (
              <Field name="discountCodeId">
                <Field.Label>Apply a discount</Field.Label>

                <Select<string, false>
                  value={selectedDiscountCodeId || ''}
                  options={servicePlanDiscountCodes}
                  onChange={setSelectedDiscountCodeId}
                  placeholder="Choose a Discount"
                />
              </Field>
            )}

            {customerHasSubscriptionForCurrentPlan && (
              <FeedbackBlock feedbackLevel={FeedbackLevel.ALERT}>
                The Customer already has a subscription to this Service Plan
              </FeedbackBlock>
            )}
            {/* show loader if getting service plan */}
            {selectedCustomerId &&
              selectedServicePlanId &&
              (servicePlan?.name ? (
                <SendableDocumentEmailControl
                  buttonText={'View invitation'}
                  emailSubject={`${account.companyName} has invited you to "${servicePlan?.name}"`}
                  emailState={emailState}
                  onChange={(change) =>
                    setEmailState({ ...emailState, ...change })
                  }
                />
              ) : (
                <div className="loader-container">
                  <MiniLoader />
                </div>
              ))}
            {/* Preview/edit email */}
            {showError && (
              <div>
                <ErrorMessage message={showError} />
              </div>
            )}
          </>
        )}
        {customer && (
          <AddCustomerEmailModal
            customer={customer}
            isOpen={showAddCustomerEmailModal}
            contactId={null}
            onClose={() => setShowAddCustomerEmailModal(false)}
            customerUpdateCallback={() => {
              dispatch(customerActions.requestGetCustomer(customer.id));
              setShowAddCustomerEmailModal(false);
            }}
          />
        )}
      </Modal.Body>
      {!hasSuccessfullyInvited && (
        <Modal.Footer>
          <Modal.Footer.Actions>
            <Button
              onClick={inviteCustomer}
              isProcessing={isInvitingCustomer}
              disabled={!selectedCustomerId || !selectedServicePlanId}
            >
              Send
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ServicePlanInviteCustomerModal;
