import { PropsWithChildren } from 'react';

import './EditJobSection.sass';

type Props = {
  title: JSX.Element | string;
  description?: JSX.Element | string;
  className?: string;
};
const EditJobSection = ({
  children,
  title,
  description,
  className,
}: PropsWithChildren<Props>) => {
  return (
    <div className={`edit-job-section${className ? ` ${className}` : ''}`}>
      {typeof title === 'string' ? <h2>{title}</h2> : title}
      {description && (
        <div className="description">
          {typeof description === 'string' ? <p>{description}</p> : description}
        </div>
      )}
      {children}
    </div>
  );
};

export default EditJobSection;
