import isHotKey from 'is-hotkey';
import { FC } from 'react';
import { Editable as SlateEditable, useSlate } from 'slate-react';

import { useHotkeys, useRenderLeafs, useToggleFormat } from './hooks';
import { Format, RenderElements } from './types';

export interface IProps {
  id: string;
  className?: string;
  renderElements?: RenderElements;
  formats: Format[];
  readOnly?: boolean;
}

const Editable: FC<IProps> = (props) => {
  const { id, className, formats, renderElements, readOnly } = props;
  const editor = useSlate();
  const renderLeafs = useRenderLeafs(formats);
  const toggleFormat = useToggleFormat();
  const hotkeys = useHotkeys(formats);

  return (
    <SlateEditable
      readOnly={readOnly}
      id={id}
      className={className}
      renderElement={renderElements}
      renderLeaf={renderLeafs}
      onKeyDown={(event) => {
        const formatHotkey = Object.entries(hotkeys).find(([hotkey]) =>
          isHotKey(hotkey, event)
        );

        if (formatHotkey) {
          const [, format] = formatHotkey;
          event.preventDefault();
          toggleFormat(format);
        } else if (isHotKey('shift+enter', event)) {
          event.preventDefault();
          editor.insertText('\n');
        }
      }}
    />
  );
};

export default Editable;
