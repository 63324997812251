import { FC, PropsWithChildren, useMemo } from 'react';

import { useSelector } from '@/api/state';

import Conditional from '@payaca/components/conditional/Conditional';
import Button from '@payaca/components/plButton/Button';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import { Textarea } from '@payaca/components/plTextarea/Textarea';

type Props = {
  onChange: (change: any) => void;
  emailCopy: {
    preButton: string;
    postButton: string;
  };
  buttonText: string;
  hasButton?: boolean;
};

const EmailPreview: FC<PropsWithChildren<Props>> = ({
  onChange,
  emailCopy,
  buttonText,
  hasButton = true,
  children,
}) => {
  const myProfile = useSelector((state: any) => state.users.myProfile);

  const logoUrl = useMemo(() => myProfile?.accounts?.[0]?.logoUrl, [myProfile]);

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <div className="text-center">
          <img
            className="mb-3 h-auto max-w-[200px]"
            src={logoUrl}
            alt="Company Logo"
          />
        </div>

        <Textarea
          className="resize-y text-center"
          value={emailCopy.preButton || ''}
          onChange={(value) => {
            onChange({ preButton: value });
          }}
        />

        {children}

        <Conditional condition={hasButton}>
          <div className="my-4 text-center">
            <Button disabled>{buttonText}</Button>
          </div>
        </Conditional>

        <Textarea
          className="resize-y text-center"
          value={emailCopy.postButton}
          onChange={(value) => {
            onChange({ postButton: value });
          }}
        />
      </Card.Body>
    </Card>
  );
};

export default EmailPreview;
