import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  ActionType,
  ConfirmPurchaseOrderRequestData,
  PersistMaterialPurchaseRecordRequestData,
  PersistMaterialsListMaterialRequestData,
  PersistPurchaseOrderRequestData,
  SagaConfig,
  SendPurchaseOrderRequestData,
} from './materialsListTypes';

import {
  clearMaterialPurchaseIntents,
  clearMaterialPurchaseRecords,
  clearMaterialsList,
  clearMaterialsListMaterials,
  clearMaterialsLists,
  clearPurchaseOrders,
  createIsolatedPurchaseOrder,
  deleteMaterialPurchaseRecordFailure,
  deleteMaterialPurchaseRecordSuccess,
  deleteMaterialsListMaterialFailure,
  deleteMaterialsListMaterialSuccess,
  getHydratedPurchaseOrder,
  getListedPurchaseOrdersPage,
  getMaterialsListFailure,
  getMaterialsListMaterialFailure,
  getMaterialsListMaterialsForMaterialsListSuccess,
  getMaterialsListMaterialSuccess,
  getMaterialsListMaterialWithRelatedEntitiesSuccess,
  getMaterialsListSuccess,
  getMaterialsListWithRelatedEntitiesSuccess,
  getPurchaseOrderFailure,
  getPurchaseOrderSuccess,
  persistMaterialPurchaseRecordFailure,
  persistMaterialPurchaseRecordSuccess,
  persistMaterialsListFailure,
  persistMaterialsListMaterialFailure,
  persistMaterialsListMaterialSuccess,
  persistMaterialsListSuccess,
  persistPurchaseOrderFailure,
  persistPurchaseOrderSuccess,
  syncAutopopulatedMaterialsListMaterialsFromDeal,
  updateIsolatedPurchaseOrder,
} from './materialsListActions';

import { Req } from '@payaca/helpers/storeHelper';
import {
  ListedPurchaseOrdersPage,
  SortBy,
} from '@payaca/types/listedPurchaseOrderTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import {
  HydratedPurchaseOrder,
  PurchaseOrder,
} from '@payaca/types/materialsListTypes';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { handleAsyncAction } from '../utils';

const materialsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetMaterialsListMaterialsForMaterialsList(
    action: PayloadAction<
      ActionType.REQUEST_GET_MATERIALS_LIST_MATERIALS_FOR_MATERIALS_LIST,
      {
        materialsListId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getMaterialsListMaterialsForMaterialsList,
          action.payload.materialsListId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error(
          'getMaterialsListMaterialsForMaterialsList request timed out'
        );
      }

      yield put(getMaterialsListMaterialsForMaterialsListSuccess(response));
    } catch (error: any) {}
  }

  const getMaterialsListMaterialsForMaterialsList = async (
    materialsListId: number
  ) => {
    const response = await req.get(
      `/materials_list_material/materials_list/${materialsListId}`
    );
    return response.json();
  };

  function* handleGetMaterialsList(
    action: PayloadAction<
      ActionType.REQUEST_GET_MATERIALS_LIST,
      {
        materialsListId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getMaterialsList, action.payload.materialsListId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get materialsList timed out.';
        yield put(
          getMaterialsListFailure(
            action.payload.materialsListId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(getMaterialsListSuccess(response.id, response));
      }
    } catch (error: any) {
      yield put(getMaterialsListFailure(action.payload.materialsListId, error));
    }
  }

  const getMaterialsList = async (materialsListId: number) => {
    const response = await req.get(`/materials_list/${materialsListId}`);
    return response.json();
  };

  function* handleGetMaterialsListWithRelatedEntities(
    action: PayloadAction<
      ActionType.REQUEST_GET_MATERIALS_LIST_WITH_RELATED_ENTITIES,
      {
        materialsListId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getMaterialsListWithRelatedEntities,
          action.payload.materialsListId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage =
          'Get materialsList with related entities timed out.';
        yield put(
          getMaterialsListFailure(
            action.payload.materialsListId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(getMaterialsListWithRelatedEntitiesSuccess(response));
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(getMaterialsListFailure(action.payload.materialsListId, error));
    }
  }

  const getMaterialsListWithRelatedEntities = async (
    materialsListId: number
  ) => {
    const response = await req.get(
      `/materials_list/${materialsListId}/with_related_entities`
    );
    return response.json();
  };

  function* handleGetMaterialsListMaterial(
    action: PayloadAction<
      ActionType.REQUEST_GET_MATERIALS_LIST_MATERIAL,
      {
        materialsListMaterialId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getMaterialsListMaterial,
          action.payload.materialsListMaterialId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get materialsListMaterial timed out.';
        yield put(
          getMaterialsListMaterialFailure(
            action.payload.materialsListMaterialId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(
          getMaterialsListMaterialSuccess(
            action.payload.materialsListMaterialId,
            response
          )
        );
      }
    } catch (error: any) {
      yield put(
        getMaterialsListMaterialFailure(
          action.payload.materialsListMaterialId,
          error
        )
      );
    }
  }

  const getMaterialsListMaterial = async (materialsListMaterialId: number) => {
    const response = await req.get(
      `/materials_list_material/${materialsListMaterialId}`
    );
    return response.json();
  };

  function* handleGetMaterialsListMaterialWithRelatedEntities(
    action: PayloadAction<
      ActionType.REQUEST_GET_MATERIALS_LIST_MATERIAL_WITH_RELATED_ENTITIES,
      {
        materialsListMaterialId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getMaterialsListMaterialWithRelatedEntities,
          action.payload.materialsListMaterialId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage =
          'Get materialsListMaterial with related entities timed out.';
        yield put(
          getMaterialsListMaterialFailure(
            action.payload.materialsListMaterialId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(getMaterialsListMaterialWithRelatedEntitiesSuccess(response));
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(
        getMaterialsListMaterialFailure(
          action.payload.materialsListMaterialId,
          error
        )
      );
    }
  }

  const getMaterialsListMaterialWithRelatedEntities = async (
    materialsListMaterialId: number
  ) => {
    const response = await req.get(
      `/materials_list_material/${materialsListMaterialId}/with_related_entities`
    );
    return response.json();
  };

  function* handleGetPurchaseOrder(
    action: PayloadAction<
      ActionType.REQUEST_GET_PURCHASE_ORDER,
      {
        purchaseOrderId: number;
        callback?: (purchaseOrder: PurchaseOrder) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getPurchaseOrder, action.payload.purchaseOrderId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get purchaseOrder timed out.';
        yield put(
          getPurchaseOrderFailure(
            action.payload.purchaseOrderId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(getPurchaseOrderSuccess(response.id, response));
        action.payload.callback?.(response);
      }
    } catch (error: any) {
      yield put(getPurchaseOrderFailure(action.payload.purchaseOrderId, error));
    }
  }

  const getPurchaseOrder = async (purchaseOrderId: number) => {
    const response = await req.get(`/purchase_order/${purchaseOrderId}`);
    return response.json();
  };

  function* handleCreateDealMaterialsList(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_DEAL_MATERIALS_LIST,
      {
        dealId: number;
        callback?: (materialsListId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(createDealMaterialsList, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistMaterialsListFailure(
            new Error('Create Project materials list timed out.')
          )
        );
      } else {
        yield put(persistMaterialsListSuccess());
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(persistMaterialsListFailure(error as Error));
    }
  }

  const createDealMaterialsList = async (dealId: number) => {
    const response = await req.post(`/materials_list/deal/${dealId}`, {});
    return await response.json();
  };

  function* handlePersistMaterialsListMaterial(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_MATERIALS_LIST_MATERIAL,
      {
        persistMaterialsListMaterialRequestData: PersistMaterialsListMaterialRequestData;
        callback?: (materialsListMaterialId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          persistMaterialsListMaterial,
          action.payload.persistMaterialsListMaterialRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistMaterialsListMaterialFailure(
            new Error('Persist materials list material timed out.')
          )
        );
      } else {
        yield put(persistMaterialsListMaterialSuccess());
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(persistMaterialsListMaterialFailure(error as Error));
    }
  }

  const persistMaterialsListMaterial = async (
    persistMaterialsListMaterialRequestData: PersistMaterialsListMaterialRequestData
  ) => {
    const response = await req.post(
      `/materials_list_material`,
      persistMaterialsListMaterialRequestData
    );
    return await response.json();
  };

  function* handleDeleteMaterialsListMaterial(
    action: PayloadAction<
      ActionType.REQUEST_DELETE_MATERIALS_LIST_MATERIAL,
      {
        materialsListMaterialId: number;
        callback?: () => void;
        onErrorCallback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          deleteMaterialsListMaterial,
          action.payload.materialsListMaterialId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          deleteMaterialsListMaterialFailure(
            new Error('Delete materials list material timed out.')
          )
        );
        action.payload.onErrorCallback && action.payload.onErrorCallback();
      } else {
        yield put(deleteMaterialsListMaterialSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(deleteMaterialsListMaterialFailure(error as Error));
      action.payload.onErrorCallback && action.payload.onErrorCallback();
    }
  }

  const deleteMaterialsListMaterial = async (
    materialsListMaterialId: number
  ) => {
    return req.del(`/materials_list_material/${materialsListMaterialId}`);
  };

  function* handleDeleteMaterialPurchaseRecord(
    action: PayloadAction<
      ActionType.REQUEST_DELETE_MATERIALS_LIST_MATERIAL,
      {
        materialPurchaseRecordId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          deleteMaterialPurchaseRecord,
          action.payload.materialPurchaseRecordId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          deleteMaterialPurchaseRecordFailure(
            new Error('Delete material purchase record timed out.')
          )
        );
      } else {
        yield put(deleteMaterialPurchaseRecordSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(deleteMaterialPurchaseRecordFailure(error as Error));
    }
  }

  const deleteMaterialPurchaseRecord = async (
    materialPurchaseRecordId: number
  ) => {
    return req.del(`/material_purchase_record/${materialPurchaseRecordId}`);
  };

  function* handlePersistMaterialPurchaseRecord(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_MATERIAL_PURCHASE_RECORD,
      {
        persistMaterialPurchaseRecordRequestData: PersistMaterialPurchaseRecordRequestData;
        callback?: (materialsListMaterialId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          persistMaterialPurchaseRecord,
          action.payload.persistMaterialPurchaseRecordRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistMaterialPurchaseRecordFailure(
            new Error('Persist material purchase record timed out.')
          )
        );
      } else {
        yield put(persistMaterialPurchaseRecordSuccess());
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(persistMaterialPurchaseRecordFailure(error as Error));
    }
  }

  const persistMaterialPurchaseRecord = async (
    persistMaterialPurchaseRecordRequestData: PersistMaterialPurchaseRecordRequestData
  ) => {
    const response = await req.post(
      `/material_purchase_record`,
      persistMaterialPurchaseRecordRequestData
    );
    return await response.json();
  };

  function* handlePersistPurchaseOrder(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_PURCHASE_ORDER,
      {
        persistPurchaseOrderRequestData: PersistPurchaseOrderRequestData;
        callback?: (purchaseOrderId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          persistPurchaseOrder,
          action.payload.persistPurchaseOrderRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistPurchaseOrderFailure(
            new Error('Persist purchase order timed out.')
          )
        );
      } else {
        yield put(persistPurchaseOrderSuccess());
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(persistPurchaseOrderFailure(error as Error));
    }
  }

  const persistPurchaseOrder = async (
    persistData: PersistPurchaseOrderRequestData
  ) => {
    const response = await req.post(`/purchase_order`, persistData);
    return await response.json();
  };

  function* handleSendPurchaseOrder(
    action: PayloadAction<
      ActionType.REQUEST_SEND_PURCHASE_ORDER,
      {
        sendPurchaseOrderRequestData: SendPurchaseOrderRequestData;
        callback?: () => void;
        errorCallback?: (error: Error) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          sendPurchaseOrder,
          action.payload.sendPurchaseOrderRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Send purchase order timed out.';

        action.payload.errorCallback &&
          action.payload.errorCallback(new Error(errorMessage));
      } else {
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      action.payload.errorCallback && action.payload.errorCallback(error);
    }
  }

  const sendPurchaseOrder = async (
    sendPurchaseOrderRequestData: SendPurchaseOrderRequestData
  ) => {
    return req.post(`/purchase_order/${sendPurchaseOrderRequestData.id}/send`, {
      emailBody: sendPurchaseOrderRequestData.emailBody,
      copyMeIn: sendPurchaseOrderRequestData.copyMeIn,
      emailSubject: sendPurchaseOrderRequestData.emailSubject,
      supplierEmailAddress: sendPurchaseOrderRequestData.supplierEmailAddress,
    });
  };

  function* handleMarkPurchaseOrderAsSent(
    action: PayloadAction<
      ActionType.REQUEST_MARK_PURCHASE_ORDER_AS_SENT,
      {
        purchaseOrderId: number;
        callback?: () => void;
        errorCallback?: (error: Error) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(markPurchaseOrderAsSent, action.payload.purchaseOrderId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Marking purchase order a sent timed out.';

        action.payload.errorCallback &&
          action.payload.errorCallback(new Error(errorMessage));
      } else {
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      action.payload.errorCallback && action.payload.errorCallback(error);
    }
  }

  const markPurchaseOrderAsSent = async (purchaseOrderId: number) => {
    return req.post(`/purchase_order/${purchaseOrderId}/mark_as_sent`, {});
  };

  function* handleVoidPurchaseOrder(
    action: PayloadAction<
      ActionType.REQUEST_VOID_PURCHASE_ORDER,
      {
        purchaseOrderId: number;
        callback?: () => void;
        errorCallback?: (error: Error) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(voidPurchaseOrder, action.payload.purchaseOrderId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Void purchase order timed out.';

        action.payload.errorCallback &&
          action.payload.errorCallback(new Error(errorMessage));
      } else {
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      action.payload.errorCallback && action.payload.errorCallback(error);
    }
  }

  const voidPurchaseOrder = async (purchaseOrderId: number) => {
    return req.post(`/purchase_order/${purchaseOrderId}/void`, {});
  };

  function* handleConfirmPurchaseOrder(
    action: PayloadAction<
      ActionType.REQUEST_CONFIRM_PURCHASE_ORDER,
      {
        confirmPurchaseOrderRequestData: ConfirmPurchaseOrderRequestData;
        callback?: () => void;
        errorCallback?: (error: Error) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          confirmPurchaseOrder,
          action.payload.confirmPurchaseOrderRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Confirm purchase order timed out.';

        action.payload.errorCallback &&
          action.payload.errorCallback(new Error(errorMessage));
      } else {
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      action.payload.errorCallback && action.payload.errorCallback(error);
    }
  }

  const confirmPurchaseOrder = async (
    confirmPurchaseOrderRequestData: ConfirmPurchaseOrderRequestData
  ) => {
    return req.post(
      `/purchase_order/${confirmPurchaseOrderRequestData.id}/confirm`,
      {
        materialPurchaseRecords:
          confirmPurchaseOrderRequestData.materialPurchaseRecords,
      }
    );
  };

  const handleClearAutopopulatedMaterialsListMaterials = handleAsyncAction(
    clearMaterialsList,
    async (payload) => {
      try {
        const response = await req.del(
          `/materials_list/${payload.materialsListId}/clear`,
          {}
        );
        return response.json();
      } catch (err) {
        console.log(
          `Clear materials list materials failed: ${JSON.stringify(err)}`
        );
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as {
          deletedMaterialsListMaterialsCount: number;
          updatedMaterialsListMaterialsCount: number;
        }
      );
    }
  );

  const handleSyncAutopopulatedMaterialsListMaterialsFromDeal =
    handleAsyncAction(
      syncAutopopulatedMaterialsListMaterialsFromDeal,
      async (payload) => {
        try {
          const response = await req.post(
            `/materials_list/sync_autopopulated/${payload.dealId}`,
            {}
          );
          return response.json();
        } catch (err) {
          console.log(
            `Populate materials list from Project failed: ${JSON.stringify(
              err
            )}`
          );
        }
      },
      (_response, requestData) => {
        requestData.payload.callback?.(
          _response as {
            createdMaterialsListMaterialsCount: number;
            updatedMaterialsListMaterialsCount: number;
            deletedMaterialsListMaterialsCount: number;
          }
        );
      }
    );

  const handleGetListedPurchaseOrdersPage = handleAsyncAction(
    getListedPurchaseOrdersPage,
    async (payload) => {
      try {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.set(
          'pageNumber',
          (payload.queryParams.pageNumber || 1).toString()
        );
        urlSearchParams.set(
          'pageSize',
          (payload.queryParams.pageSize || 10).toString()
        );
        if (payload.queryParams.searchTerm?.length) {
          urlSearchParams.set('searchTerm', payload.queryParams.searchTerm);
        }
        urlSearchParams.set(
          'sortBy',
          payload.queryParams.sortBy || SortBy.SENT_AT
        );
        urlSearchParams.set(
          'sortDirection',
          payload.queryParams.sortDirection || SortDirection.DESCENDING
        );
        payload.queryParams.supplierIds?.forEach((supplierId, index) => {
          urlSearchParams.set(`supplierIds[${index}]`, supplierId.toString());
        });
        payload.queryParams.dealAssigneeIds?.forEach((userId, index) => {
          urlSearchParams.set(`dealAssigneeIds[${index}]`, userId.toString());
        });

        const response = await req.get(
          `/purchase_order/listed-purchase-orders?${urlSearchParams.toString()}`
        );
        return response.json();
      } catch (err) {
        console.log(
          `Get listed purchase orders failed: ${JSON.stringify(err)}`
        );
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as ListedPurchaseOrdersPage);
    }
  );

  const handleCreateIsolatedPurchaseOrder = handleAsyncAction(
    createIsolatedPurchaseOrder,
    async (payload) => {
      const response = await req.post(`/purchase_order/isolated`, {
        supplierId: payload.payload.supplierId,
      });
      return response.json();
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as HydratedPurchaseOrder);
    },
    (_error, requestData) => {
      requestData.payload.onErrorCallback?.(_error);
    }
  );

  const handleUpdateIsolatedPurchaseOrder = handleAsyncAction(
    updateIsolatedPurchaseOrder,
    async (payload) => {
      const { purchaseOrderId, ...body } = payload.payload;

      const response = await req.put(
        `/purchase_order/isolated/${purchaseOrderId}`,
        body
      );
      return response.json();
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as HydratedPurchaseOrder);
    },
    (_error, requestData) => {
      requestData.payload.onErrorCallback?.(_error);
    }
  );

  const handleGetHydratedPurchaseOrder = handleAsyncAction(
    getHydratedPurchaseOrder,
    async (payload) => {
      const response = await req.get(
        `/purchase_order/${payload.purchaseOrderId}/hydrated`
      );
      return response.json();
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as HydratedPurchaseOrder);
    }
  );

  function* handleLogout() {
    yield put(clearMaterialsLists());
    yield put(clearMaterialsListMaterials());
    yield put(clearMaterialPurchaseRecords());
    yield put(clearMaterialPurchaseIntents());
    yield put(clearPurchaseOrders());
  }

  return function* () {
    yield takeEvery(
      ActionType.REQUEST_GET_MATERIALS_LIST_MATERIALS_FOR_MATERIALS_LIST,
      handleGetMaterialsListMaterialsForMaterialsList
    );

    yield takeEvery(
      ActionType.REQUEST_GET_MATERIALS_LIST_MATERIAL,
      handleGetMaterialsListMaterial
    );

    yield takeEvery(
      ActionType.REQUEST_GET_MATERIALS_LIST,
      handleGetMaterialsList
    );
    yield takeLatest(
      ActionType.REQUEST_CREATE_DEAL_MATERIALS_LIST,
      handleCreateDealMaterialsList
    );

    yield takeLatest(
      ActionType.REQUEST_GET_MATERIALS_LIST_WITH_RELATED_ENTITIES,
      handleGetMaterialsListWithRelatedEntities
    );
    yield takeLatest(
      ActionType.REQUEST_GET_MATERIALS_LIST_MATERIAL_WITH_RELATED_ENTITIES,
      handleGetMaterialsListMaterialWithRelatedEntities
    );
    yield takeLatest(
      ActionType.GET_HYDRATED_PURCHASE_ORDER_REQUEST,
      handleGetHydratedPurchaseOrder
    );
    yield takeLatest(
      ActionType.REQUEST_GET_PURCHASE_ORDER,
      handleGetPurchaseOrder
    );
    yield takeLatest(
      ActionType.REQUEST_PERSIST_MATERIALS_LIST_MATERIAL,
      handlePersistMaterialsListMaterial
    );
    yield takeLatest(
      ActionType.REQUEST_PERSIST_MATERIAL_PURCHASE_RECORD,
      handlePersistMaterialPurchaseRecord
    );
    yield takeLatest(
      ActionType.REQUEST_PERSIST_PURCHASE_ORDER,
      handlePersistPurchaseOrder
    );
    yield takeLatest(
      ActionType.REQUEST_DELETE_MATERIALS_LIST_MATERIAL,
      handleDeleteMaterialsListMaterial
    );
    yield takeLatest(
      ActionType.REQUEST_DELETE_MATERIAL_PURCHASE_RECORD,
      handleDeleteMaterialPurchaseRecord
    );
    yield takeLatest(
      ActionType.REQUEST_SEND_PURCHASE_ORDER,
      handleSendPurchaseOrder
    );
    yield takeLatest(
      ActionType.REQUEST_MARK_PURCHASE_ORDER_AS_SENT,
      handleMarkPurchaseOrderAsSent
    );
    yield takeLatest(
      ActionType.REQUEST_VOID_PURCHASE_ORDER,
      handleVoidPurchaseOrder
    );
    yield takeLatest(
      ActionType.REQUEST_CONFIRM_PURCHASE_ORDER,
      handleConfirmPurchaseOrder
    );

    yield takeLatest(
      ActionType.CLEAR_MATERIALS_LIST_REQUEST,
      handleClearAutopopulatedMaterialsListMaterials
    );
    yield takeLatest(
      ActionType.REQUEST_SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL,
      handleSyncAutopopulatedMaterialsListMaterialsFromDeal
    );

    yield takeEvery(
      ActionType.GET_LISTED_PURCHASE_ORDERS_PAGE_REQUEST,
      handleGetListedPurchaseOrdersPage
    );
    yield takeEvery(
      ActionType.CREATE_ISOLATED_PURCHASE_ORDER_REQUEST,
      handleCreateIsolatedPurchaseOrder
    );
    yield takeEvery(
      ActionType.UPDATE_ISOLATED_PURCHASE_ORDER_REQUEST,
      handleUpdateIsolatedPurchaseOrder
    );

    yield takeEvery('auth.logout', handleLogout);
  };
};

export default materialsSagaCreator;
