import { FC } from 'react';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Tooltip from '@payaca/components/tooltip/Tooltip';

import EditIcon from '@material-ui/icons/Edit';

type Props = {
  disabled?: boolean;
  editValueTitle?: string;
  emptyValueTitle?: string;
  infoIconText?: string;
  onClick?: () => void;
  value?: any;
  valuePrefix?: string;
};
const TotalsEditableValue: FC<Props> = ({
  disabled,
  editValueTitle,
  emptyValueTitle,
  infoIconText,
  onClick,
  value,
  valuePrefix,
}: Props): JSX.Element => {
  return (
    <div className="detail-container">
      {value ? (
        <div
          className={`detail-title editable-value-title-wrapper ${
            disabled ? 'disabled' : ''
          }`}
          onClick={() => (disabled ? {} : onClick && onClick())}
        >
          <div className={value ? '' : 'editable-value-title'}>
            <strong>{editValueTitle}</strong>
          </div>
          {infoIconText && <Tooltip text={infoIconText} />}
          <EditIcon className="detail-icon" />
        </div>
      ) : (
        <Button
          onClick={onClick}
          styleVariant={ButtonStyleVariant.ANCHOR}
          isDisabled={disabled}
        >
          {emptyValueTitle}
        </Button>
      )}

      <div className="detail-value">
        {value ? `${valuePrefix || ''}${value}` : null}
      </div>
    </div>
  );
};
export default TotalsEditableValue;
