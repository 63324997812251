import Input from '@payaca/components/plInput/Input';
import Select from '@payaca/components/plSelect/Select';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import {
  CustomFieldDefinition,
  CustomFieldType,
  CustomFieldValueHelperType,
} from '@payaca/custom-fields/types/index';

export const CustomFieldInput = <T extends CustomFieldType>({
  definition,
  value,
  onChange,
  onChangeTimeout,
}: {
  definition: CustomFieldDefinition<T>;
  value?: CustomFieldValueHelperType[T] | null;
  onChange: (value?: CustomFieldValueHelperType[T] | null) => void;
  onChangeTimeout?: () => void;
}) => {
  switch (definition.type) {
    case 'textarea': {
      return (
        <Textarea
          value={value as string}
          onChange={onChange as (value: string) => void}
          onChangeTimeout={onChangeTimeout}
        />
      );
    }
    case 'text':
      return (
        <Input
          value={value as string}
          onChange={onChange as (value: string) => void}
          onChangeTimeout={onChangeTimeout}
        />
      );
    case 'email':
      return (
        <Input
          value={value as string}
          onChange={onChange as (value: string) => void}
          onChangeTimeout={onChangeTimeout}
        />
      );
    case 'select':
      return (
        <Select
          value={value as string}
          options={
            definition.options?.map((x) => ({ value: x, label: x })) || []
          }
          multiple={false}
          onChange={(value) => {
            onChange(value as any);
            onChangeTimeout?.();
          }}
        />
      );
    default:
      // not implemented
      return null;
  }
};
