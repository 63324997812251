import { Download } from 'react-iconly';

import Button from '@payaca/components/button/Button';

type Props = {
  downloadUrl?: string;
};
const DownloadTemplateButton = ({ downloadUrl }: Props) => {
  return (
    <div className="download-button-wrapper">
      <a href={downloadUrl} target="_blank" rel="noreferrer" download>
        <Button isDisabled={!downloadUrl}>
          <div className="icon-wrapper">
            <Download set="light" />
          </div>
          <span>Download template</span>
        </Button>
      </a>
    </div>
  );
};

export default DownloadTemplateButton;
