import Combobox from '@payaca/components/plCombobox/Combobox';
import { SelectOption } from '@payaca/components/plSelect/Select';
import * as customerActions from '@payaca/store/customer/customerActions';
import { useCustomer } from '@payaca/store/hooks/appState';
import { Customer } from '@payaca/types/customerTypes';
import { ListedCustomer } from '@payaca/types/listedCustomerTypes';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  customerId?: Customer['id'];
  onChange: (
    customerId?: Customer['id'],
    metadata?: { customer?: Customer }
  ) => void;
  disabled?: boolean;
  showCardIfDisabled?: boolean;
}

export const CustomerSelect: FC<Props> = ({
  customerId,
  onChange,
  disabled,
  showCardIfDisabled,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState<string>();
  const [requiresGetListedCustomersPage, setRequiresGetListedCustomersPage] =
    useState(false);
  const changeTimeout = useRef<NodeJS.Timeout | null>(null);
  const [isChanging, setIsChanging] = useState(false);

  const retrievedCustomer = useCustomer(customerId);
  const [customer, setCustomer] = useState<Customer>();

  const [listedCustomers, setListedCustomers] = useState<ListedCustomer[]>([]);

  useEffect(() => {
    if (changeTimeout?.current) {
      clearTimeout(changeTimeout.current);
    }
    changeTimeout.current = setTimeout(() => {
      setRequiresGetListedCustomersPage(true);
    }, 500);
  }, [query]);

  const requestGetListedCustomersPage = useCallback(() => {
    dispatch(
      customerActions.requestGetListedCustomersPage(
        {
          pageSize: 20,
          pageNumber: 1,
          searchTerm: query,
        },
        (listedCustomers) => {
          setListedCustomers(listedCustomers.items);
        }
      )
    );
    setRequiresGetListedCustomersPage(false);
  }, [query, dispatch]);

  useEffect(() => {
    if (!customerId) {
      setRequiresGetListedCustomersPage(true);
    } else if (retrievedCustomer) {
      setCustomer(retrievedCustomer);
    } else {
      dispatch(
        customerActions.requestGetCustomer(customerId, (customer) => {
          setCustomer(customer);
        })
      );
    }
  }, [customerId]);

  useEffect(() => {
    if (requiresGetListedCustomersPage) {
      requestGetListedCustomersPage();
    }
  }, [requiresGetListedCustomersPage]);

  const options = useMemo(() => {
    const o: SelectOption<number>[] = listedCustomers.map((customer) => {
      return {
        label: customer.customerName,
        value: customer.customerId,
      };
    });

    if (customer && !o.find((x) => x.value === customer.id)) {
      o.push({
        value: customer.id,
        label: customer.name,
      });
    }

    return o;
  }, [listedCustomers, customer, customer]);

  const handleOnChange = useCallback(
    (value: any) => {
      const customerId = value as Customer['id'] | undefined;

      setCustomer(undefined);
      setCustomer(undefined);

      if (!customerId) onChange();
      else {
        setIsChanging(true);
        dispatch(
          customerActions.requestGetCustomer(customerId, (customer) => {
            setCustomer(customer);

            onChange(customerId, { customer });
            setIsChanging(false);
          })
        );
      }
    },
    [onChange]
  );

  if (disabled && customer && showCardIfDisabled)
    return (
      <div className="border py-3 px-4 border-gray-200 rounded-md shadow-sm">
        {customer.name}
      </div>
    );

  return (
    <Combobox
      disabled={disabled}
      onChange={handleOnChange}
      options={options}
      value={isChanging ? undefined : customerId}
      placeholder={isChanging || customerId ? '...' : undefined}
      query={query}
      setQuery={setQuery}
      filterFunction={() => true}
    />
  );
};
