import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import {
  ScheduleContext,
  TScheduleContextProviderLocationState,
} from '@/ui/components/contextProviders/ScheduleContextProvider';
import Map, { MarkerType } from '@/ui/components/map/Map';
import Button from '@payaca/components/plButton/Button';
import Modal from '@payaca/components/plModal/Modal';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { getLocationSearch } from '@payaca/store/dispatch/dispatchActions';

const DispatchMap: FC = () => {
  const [showNoAddressModal, setShowNoAddressModal] = useState(false);
  const {
    scheduledEvents,
    setPendingEventToCreate,
    pendingEventToCreate,
    mapViewData: { location },
    onUpdate,
  } = useContext(ScheduleContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation<TScheduleContextProviderLocationState>();
  const { url } = useRouteMatch();

  const [savedMarkers, setSavedMarkers] = useState<MarkerType[]>([]);
  const [temporaryMarkers, setTemporaryMarkers] = useState<MarkerType[]>([]);

  useEffect(() => {
    if (!scheduledEvents) return;

    setSavedMarkers(
      scheduledEvents
        .filter((e) => {
          return (
            e.location?.address?.latitude && e.location?.address?.longitude
          );
        })
        .map((event) => ({
          dealId: event.dealId || undefined,
          scheduledEventId: event.id,
          userIds: event.userAssignments,
          lat: event.location?.address?.latitude || 0,
          long: event.location?.address?.longitude || 0,
          onClick: () => {
            history.push(`${url}/${event.id}`);
          },
        }))
    );
  }, [scheduledEvents]);

  useEffect(() => {
    if (!state?.eventToCreate) {
      setTemporaryMarkers([]);
    }
  }, [state?.eventToCreate]);

  useEffect(() => {
    if (!location) {
      setTemporaryMarkers([]);
      return;
    }
    const addressString = getAddressAsString(location);
    if (!addressString?.length) {
      if (!state) {
        setShowNoAddressModal(true);
      }
      setTemporaryMarkers([]);
      return;
    }

    dispatch(
      getLocationSearch.request({
        address: addressString,
        callback: (results) => {
          if (results.length) {
            const result = results[0];

            setTemporaryMarkers([
              {
                dealId: pendingEventToCreate?.dealId || undefined,
                scheduledEventId: undefined,
                userIds: pendingEventToCreate?.userAssignments,
                lat: result.lat || 0,
                long: result.long || 0,
                onClick: () => {
                  onUpdate({ location: undefined });
                  history.push(`${url}/new`, {
                    eventToCreate: pendingEventToCreate || {
                      location: { address: location, contacts: [] },
                    },
                  });
                  setPendingEventToCreate(undefined);
                },
              },
            ]);
          } else if (!state) {
            setShowNoAddressModal(true);
          }
        },
      })
    );
  }, [location, pendingEventToCreate, state]);

  const mapCenter = useMemo(() => {
    if (temporaryMarkers?.length) {
      return temporaryMarkers[0];
    }
    if (savedMarkers.length) {
      return savedMarkers[0];
    }
  }, [savedMarkers, temporaryMarkers]);

  const locationAsString = location ? getAddressAsString(location) : undefined;

  return (
    <>
      <Modal
        isOpen={showNoAddressModal}
        title="Failed to locate"
        onClose={() => {
          setShowNoAddressModal(false);
          setPendingEventToCreate(undefined);
          setTemporaryMarkers([]);
        }}
      >
        <Modal.Body>
          <p>
            {' '}
            {!locationAsString?.length ? (
              'Blank address'
            ) : (
              <>Address &#34;{locationAsString}&#34;</>
            )}{' '}
            could not be found on the map.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            <Button
              onClick={() => {
                setShowNoAddressModal(false);
                history.push(`${url}/new`, {
                  eventToCreate: pendingEventToCreate || {
                    location: { address: location || undefined, contacts: [] },
                  },
                });
                setPendingEventToCreate(undefined);
                setTemporaryMarkers([]);
              }}
            >
              Create Event anyway
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </Modal>
      <Map
        clusteredMarkers={savedMarkers}
        center={mapCenter}
        independentMarkers={temporaryMarkers}
      />
    </>
  );
};

export default DispatchMap;
