import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { MarkNotificationsAsReadMutation as _MarkNotificationsAsReadMutation } from '@/gql/graphql';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

const MarkNotificationsAsReadMutation = graphql(`
  mutation MarkNotificationsAsRead($input: MarkNotificationsAsReadInput!) {
    markNotificationsAsRead(input: $input) {
      id
      viewedAt
    }
  }
`);

const useMarkNotificationsAsRead = (
  options?: UseMutationOptions<
    _MarkNotificationsAsReadMutation,
    unknown,
    string[]
  >
) => {
  return useMutation({
    mutationFn: (notificationIds: string[]) => {
      return gqlClient.request(MarkNotificationsAsReadMutation, {
        input: {
          notificationIds,
        },
      });
    },
    ...options,
  });
};

export default useMarkNotificationsAsRead;
