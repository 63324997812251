import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@/api/state';

import BasicField from '@payaca/components/basicField/BasicField';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';

import { currencyPrice } from '@payaca/helpers/financeHelper';

import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import * as dealActions from '@payaca/store/deals/dealsActions';
import { useJobContent, useProposal } from '@payaca/store/hooks/appState';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as jobActions from '@payaca/store/jobs/jobsActions';
import { Job } from '@payaca/types/jobTypesV2';
import DealPaymentOptionsControl from '../dealPaymentOptionsControl/DealPaymentOptionsControl';
import DealPriceDetailControl from '../dealPriceDetailControl/DealPriceDetailControl';
import SetProposalDiscountModal from '../setProposalDiscountModal/SetProposalDiscountModal';
import './ProtoInvoiceSidePanel.sass';

type Props = {
  protoInvoiceId: number;
};

const ProtoInvoiceSidePanel: FC<Props> = ({
  protoInvoiceId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [showSetDiscountModal, setShowSetDiscountModal] = useState(false);

  const protoInvoice: Job | undefined = useProposal(protoInvoiceId);
  const jobContent = useJobContent(protoInvoice?.jobContentId);

  const region = useSelector(
    (state: any) => state.users.myProfile.accounts[0].region
  );

  const [isSaveRequired, setIsSaveRequired] = useState(false);

  const isUpdatingProtoInvoice: boolean = useSelector((state) => {
    return state.jobsStore.isEditingJob;
  });

  const isFetchingProtoInvoice: boolean = useSelector((state) => {
    return (
      state.jobsStore.jobs && state.jobsStore.jobs[protoInvoiceId]?.isFetching
    );
  });

  const onUpdateSuccess = useCallback(() => {
    dispatch(jobActions.requestGetJob(protoInvoiceId));
  }, [dispatch, protoInvoiceId]);

  const [dueInDays, setDueInDays] = useState<number | null>();

  useEffect(() => {
    setDueInDays(protoInvoice?.invoiceConfig?.dueInDays);
  }, [protoInvoice?.invoiceConfig?.dueInDays]);

  const persistDueInDays = useCallback(() => {
    // console.log('about to update proto invoice', formState);
    dispatch(
      jobActions.partialUpdateJob.request({
        jobId: protoInvoiceId,
        data: {
          invoiceConfig: {
            ...protoInvoice?.invoiceConfig,
            dueInDays: dueInDays,
          },
        },
        callback: onUpdateSuccess,
        onErrorCallback: () => {
          console.log();
        },
      })
    );
  }, [
    dispatch,
    dueInDays,
    protoInvoice,
    protoInvoiceId,
    isUpdatingProtoInvoice,
    isFetchingProtoInvoice,
  ]);

  return (
    <div className="proto-invoice-side-panel">
      <div className="invoice-due-days-wrapper">
        Invoice due in{' '}
        <BasicField
          name="dueInDays"
          value={dueInDays}
          type="number"
          onChange={(value: { [key: string]: any }) => {
            setDueInDays(value.dueInDays);
          }}
          changeTimeoutMs={2000}
          onBlur={() => {
            persistDueInDays();
          }}
          onChangeTimeout={() => setIsSaveRequired(true)}
        />{' '}
        days
      </div>
      {!!jobContent && (
        <div className="invoice-totals-container">
          <LabelValuePair
            suffixLabelWith=""
            label="Subtotal"
            value={currencyPrice(jobContent.subtotal, region)}
          />
          {!!jobContent.calculatedMarkupAmount && (
            <LabelValuePair
              suffixLabelWith=""
              label="Markup"
              value={currencyPrice(jobContent.calculatedMarkupAmount, region)}
            />
          )}
          {!jobContent.calculatedDiscountAmount ? (
            <>
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => setShowSetDiscountModal(true)}
              >
                Set discount
              </Button>
            </>
          ) : (
            <>
              <LabelValuePair
                suffixLabelWith=""
                label={
                  <>
                    <span>Discount</span>
                    <Button
                      styleVariant={ButtonStyleVariant.ANCHOR}
                      onClick={() => setShowSetDiscountModal(true)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </>
                }
                value={
                  jobContent.calculatedDiscountAmount
                    ? currencyPrice(jobContent.calculatedDiscountAmount, region)
                    : undefined
                }
              />
            </>
          )}

          <LabelValuePair
            suffixLabelWith=""
            label="Tax"
            value={currencyPrice(jobContent.vatTotal, region)}
          />

          {!!jobContent.cisTotal && (
            <LabelValuePair
              suffixLabelWith=""
              label="CIS suffered"
              value={`${
                jobContent.cisTotal * -1 >= 0 ? '+' : ''
              }${currencyPrice(jobContent.cisTotal * -1, region)}`}
            />
          )}
          <hr />
          <LabelValuePair
            suffixLabelWith=""
            label="Total"
            value={currencyPrice(jobContent.total, region)}
            className="total"
          />
        </div>
      )}
      {protoInvoice && (
        <>
          <DealPaymentOptionsControl dealId={protoInvoice?.dealId} />
          <DealPriceDetailControl
            dealId={protoInvoice.dealId}
            canHideVat={false}
          />
        </>
      )}
      <SetProposalDiscountModal
        proposalId={protoInvoiceId}
        isOpen={showSetDiscountModal}
        onClose={() => setShowSetDiscountModal(false)}
        onSetProposalDiscountSuccess={() => {
          if (protoInvoice) {
            dispatch(
              jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
                protoInvoice.jobContentId
              )
            );
            dispatch(jobActions.requestGetJob(protoInvoice.id));
            dispatch(dealActions.requestGetDeal(protoInvoice.dealId));
          }
        }}
      />
    </div>
  );
};
export default ProtoInvoiceSidePanel;
