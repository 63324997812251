import moment from 'moment-timezone';
import { useEffect, useMemo } from 'react';
import { Calendar } from 'react-iconly';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import EntityCard from '@payaca/components/entityCard/EntityCard';
import { AlertTooltip } from '@payaca/components/iconTooltip/IconTooltip';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import ProgressIndicator from '@payaca/components/progressIndicator/ProgressIndicator';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';

import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';

import {
  useServicePlan,
  useServicePlanSubscription,
} from '@payaca/store/hooks/appState';
import { useAccount } from '../../../utils/storeHooks';

import { PublicHydratedServicePlanPeriod } from '@payaca/types/service-plans';

import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';

import TasksIcon from '../../components/navigationSidebar/icon--tasks.svg?react';
import { ServicePlanPeriodStatusBadge } from '../servicePlanPeriodStatusBadge/ServicePlanPeriodStatusBadge';
import './ServicePlanPeriodCard.sass';

type Props = {
  servicePlanPeriod: PublicHydratedServicePlanPeriod;
};
const ServicePlanPeriodCard = ({ servicePlanPeriod }: Props) => {
  const dispatch = useDispatch();
  const account = useAccount();
  const history = useHistory();
  const servicePlan = useServicePlan(servicePlanPeriod.servicePlanPublicId);
  const servicePlanSubscription = useServicePlanSubscription(
    servicePlanPeriod.servicePlanSubscriptionPublicId
  );

  useEffect(() => {
    if (!servicePlanSubscription) {
      dispatch(
        servicePlansActions.getServicePlanSubscription.request({
          publicId: servicePlanPeriod.servicePlanSubscriptionPublicId,
        })
      );
    }
  }, [servicePlanPeriod.servicePlanSubscriptionPublicId]);

  useEffect(() => {
    if (!servicePlan) {
      // get service plan for service plan period
      dispatch(
        servicePlansActions.getServicePlan.request({
          publicId: servicePlanPeriod.servicePlanPublicId,
        })
      );
    }
  }, [servicePlanPeriod.servicePlanPublicId]);

  const timestampShortDateRegionalFormat = useMemo(
    () =>
      getInternationalMomentDateFormatByRegion(DateFormats.MID, account.region),
    [account.region]
  );

  const isOverdue = useMemo(
    () =>
      moment(servicePlanPeriod.endsAt).isBefore(moment.now()) &&
      servicePlanPeriod.status !== 'fulfilled',
    [servicePlanPeriod.endsAt, servicePlanPeriod.status]
  );

  const isCurrent = useMemo(
    () =>
      moment(servicePlanPeriod.startsAt).isBefore(moment.now()) &&
      moment(servicePlanPeriod.endsAt).isAfter(moment.now()),
    [servicePlanPeriod.startsAt, servicePlanPeriod.endsAt]
  );

  const { events: eventsCommitments, tasks: tasksCommitments } = useMemo(() => {
    const { events, tasks } = servicePlanPeriod.commitments.reduce(
      (
        acc: {
          events: { className: string; publicId: string; sortOrder: number }[];
          tasks: { className: string; publicId: string; sortOrder: number }[];
        },
        commitment
      ) => {
        const details = {
          className: '',
          publicId: commitment.publicId,
          sortOrder: 2,
        };
        if (
          commitment.status === 'fulfilled' ||
          commitment.isFulfilledOverride
        ) {
          // commitment has been fulfilled
          details.className = 'fulfilled';
          details.sortOrder = 0;
        } else if (commitment.status === 'arranged') {
          // commitment has been arranged
          details.className = 'arranged';
          details.sortOrder = 1;
        }
        if (commitment.entityType === 'scheduledEvent') {
          acc.events.push(details);
        } else if (commitment.entityType === 'task') {
          acc.tasks.push(details);
        }
        return acc;
      },
      { events: [], tasks: [] }
    );
    return {
      tasks: tasks.slice().sort((a, b) => a.sortOrder - b.sortOrder),
      events: events.slice().sort((a, b) => a.sortOrder - b.sortOrder),
    };
  }, [servicePlanPeriod.commitments]);

  const servicePlanPeriodCardClassName = useMemo(() => {
    let className = '';
    if (isOverdue) {
      className += ' overdue';
    } else if (isCurrent) {
      className += ' current';
    }
    if (
      (isCurrent || isOverdue) &&
      servicePlanSubscription?.status === 'cancelled'
    ) {
      className += ' subscription-cancelled';
    }
    return className;
  }, [servicePlanPeriod, isOverdue, servicePlanSubscription]);

  return (
    <EntityCard
      className={`service-plan-period-card${servicePlanPeriodCardClassName}`}
      onClick={() =>
        history.push(`service-period/${servicePlanPeriod.publicId}`)
      }
    >
      <ResponsiveViewWrapper
        className="service-period-card-content"
        downBreakpointSm={600}
        downBreakpointXs={400}
      >
        <div className="service-period-card-header">
          <span className="service-plan-name">{servicePlan?.name}</span>
          <h3>{servicePlanPeriod.customerName}</h3>
        </div>

        {/* Due or booked date */}
        <div className="start-end-dates-wrapper">
          <LabelValuePair
            label="Start"
            value={moment(servicePlanPeriod.startsAt).format(
              timestampShortDateRegionalFormat
            )}
            suffixLabelWith=""
          />
          <LabelValuePair
            label="End"
            value={moment(servicePlanPeriod.endsAt).format(
              timestampShortDateRegionalFormat
            )}
            suffixLabelWith=""
          />
        </div>

        {/* commitments progress indicators */}
        <div className="commitments-indicator-wrapper">
          {!!eventsCommitments.length && (
            <div className="indicators-wrapper">
              <Calendar size="small" />
              {eventsCommitments.map((commitmentEvent) => (
                <ProgressIndicator
                  key={`commitment-event-indicator-${commitmentEvent.publicId}`}
                  className={commitmentEvent.className}
                />
              ))}
            </div>
          )}
          {!!tasksCommitments.length && (
            <div className="indicators-wrapper">
              <TasksIcon width={16} />
              {tasksCommitments.map((commitmentTask) => (
                <ProgressIndicator
                  key={`commitment-event-indicator-${commitmentTask.publicId}`}
                  className={commitmentTask.className}
                />
              ))}
            </div>
          )}
        </div>

        <div className="status-and-warnings-wrapper">
          {isOverdue && (
            <AlertTooltip tooltipText={'Service plan period is overdue'} />
          )}
          {(isCurrent || isOverdue) &&
            servicePlanSubscription?.status === 'cancelled' && (
              <AlertTooltip
                tooltipText={'Service plan subscription has ended'}
              />
            )}
          <ServicePlanPeriodStatusBadge status={servicePlanPeriod.status} />
        </div>
      </ResponsiveViewWrapper>
    </EntityCard>
  );
};

export default ServicePlanPeriodCard;
