import useOptimisticUpdateCustomer from '@/api/mutations/customers/optimistic/useOptimisticUpdateCustomer';
import useGetCustomer from '@/api/queries/customers/useGetCustomer';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import UpdateCustomerModal from '@/ui/components/updateCustomerModal/UpdateCustomerModal';
import CustomerAddressTable from '@/ui/pages/customerPage/components/CustomerAddressTable';
import CustomerContactsTable from '@/ui/pages/customerPage/components/CustomerContactsTable';
import CustomerPageHeaders from '@/ui/pages/customerPage/components/CustomerPageHeaders';
import CustomerProjectTable from '@/ui/pages/customerPage/components/CustomerProjectTable';
import CustomerScheduleTab from '@/ui/pages/customerPage/components/CustomerScheduleTab';
import CustomerServicePlansTab from '@/ui/pages/customerPage/components/CustomerServicePlansTab';
import AuthenticatedPageWrapper from '@/ui/pages/pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import RouterTabs, {
  TabPanel,
} from '@payaca/components/plRouterTabs/RouterTabs';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import * as customerActions from '@payaca/store/customer/customerActions';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const CustomerPage: FC = () => {
  /**
   * Routing
   */
  const { customerId } = useParams<{ customerId: string }>();

  /**
   * State
   */
  const [isEditCustomerNameModalOpen, setIsEditCustomerNameModalOpen] =
    useState(false);

  /**
   * Queries
   */
  const { data: customerData } = useGetCustomer(customerId);

  /**
   * Mutations
   */
  const { mutate: updateCustomer } = useOptimisticUpdateCustomer(customerId);

  /**
   * TEMPORARY
   */
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(customerActions.requestGetCustomer(+customerId));
  }, [customerId, dispatch]);
  /**
   * END: TEMPORARY
   */

  const handleEditCustomerName = (name: string) => {
    updateCustomer({ customerId, name });
  };

  const routerTabPanels = useMemo<TabPanel[]>(() => {
    return [
      {
        icon: 'lightbulb-05.3',
        label: 'Projects',
        slug: 'projects',
        render: () => (
          <PermissionGuard
            renderIfHasPermissions={[DealsPermissions.GET_LISTED_DEALS]}
            placeholderMessage="You don't have permission to view customer Projects"
          >
            <CustomerProjectTable />
          </PermissionGuard>
        ),
      },
      {
        icon: 'calendar-check-02.3',
        label: 'Schedule',
        slug: 'schedule',
        render: () => <CustomerScheduleTab />,
      },
      {
        icon: 'home-02.3',
        label: 'Addresses',
        slug: 'addresses',
        render: () => <CustomerAddressTable />,
      },
      {
        icon: 'users-01.3',
        label: 'Contacts',
        slug: 'contacts',
        render: () => <CustomerContactsTable />,
      },
      {
        icon: 'repeat-04.3',
        label: 'Service Plans',
        slug: 'servicePlans',
        render: () => <CustomerServicePlansTab />,
      },
    ];
  }, [customerId]);

  return (
    <AuthenticatedPageWrapper
      className="bg-gray-50"
      breadcrumbItems={[
        { label: 'Customers', To: '/customers' },
        { label: customerData?.customer.name || '' },
      ]}
    >
      <RouterTabs panels={routerTabPanels}>
        <div className="flex flex-col p-6 pb-4">
          <Card sizeVariant={CardSizeVariant.SM}>
            <Card.Body>
              <CustomerPageHeaders
                onEditClick={() => setIsEditCustomerNameModalOpen(true)}
              />

              <RouterTabs.Nav
                className="-mb-4 md:-mb-5"
                defaultTabSlug="projects"
              />
            </Card.Body>
          </Card>
        </div>

        <div className="p-6 pt-0">
          <RouterTabs.Panels />
        </div>
      </RouterTabs>

      <UpdateCustomerModal
        isOpen={isEditCustomerNameModalOpen}
        title="Edit Customer Name"
        onClose={() => setIsEditCustomerNameModalOpen(false)}
        customerName={customerData?.customer.name || ''}
        onSubmit={({ name }) => {
          handleEditCustomerName(name);
          setIsEditCustomerNameModalOpen(false);
        }}
      />
    </AuthenticatedPageWrapper>
  );
};

export default CustomerPage;
