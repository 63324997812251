import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions';

import { ActionType, SagaConfig } from './notesTypes';

import {
  archiveNoteFailure,
  archiveNoteSuccess,
  archiveScheduledEventNote,
  clearNotesStore,
  createNoteFailure,
  createNoteSuccess,
  createScheduledEventNote,
  getNotesForDeal,
  getNotesForScheduledEvent,
  updateNoteFailure,
  updateNoteSuccess,
  updateScheduledEventNote,
} from './notesActions';

import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { handleAsyncAction } from '../utils';

const dealsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);
  function* handleCreateNote(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_NOTE,
      {
        note: any;
        dealId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(createNote, action.payload.note, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Create note request timed out.';
        yield put(createNoteFailure(new Error(errorMessage), errorMessage));
      } else {
        yield put(createNoteSuccess());
      }
    } catch (error: any) {
      yield put(createNoteFailure(error, error.message));
    }
  }
  const createNote = async (note: any, dealId: number) => {
    const response = await req.post(`/notes/deal/${dealId}`, { note });
    return await response;
  };

  function* handleUpdateNote(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_NOTE,
      {
        note: any;
        dealId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(updateNote, action.payload.note, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Update note request timed out.';
        yield put(updateNoteFailure(new Error(errorMessage), errorMessage));
      } else {
        yield put(updateNoteSuccess());
      }
    } catch (error: any) {
      yield put(updateNoteFailure(error, error.message));
    }
  }
  const updateNote = async (note: any, dealId: number) => {
    const response = await req.put(`/notes/deal/${dealId}/note/${note.id}`, {
      note,
    });
    return await response;
  };

  function* handleArchiveNote(
    action: PayloadAction<
      ActionType.REQUEST_ARCHIVE_NOTE,
      {
        noteId: any;
        dealId: number;
        callback?: () => void;
        errorCallback?: (error: unknown) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          archiveNote,
          action.payload.noteId,
          action.payload.dealId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Archive note request timed out.';
        yield put(archiveNoteFailure(new Error(errorMessage), errorMessage));
        action.payload.errorCallback?.(new Error(errorMessage));
      } else {
        yield put(archiveNoteSuccess());
        action.payload.callback?.();
      }
    } catch (error: any) {
      yield put(archiveNoteFailure(error, error.message.message));
      action.payload.errorCallback?.(error);
    }
  }
  const archiveNote = async (noteId: any, dealId: number) => {
    const response = await req.put(
      `/notes/deal/${dealId}/note/${noteId}/archive`
    );
    return await response;
  };

  const handleGetNotesForDeal = handleAsyncAction(
    getNotesForDeal,
    async (payload) => {
      try {
        const response = await req.get(`/notes/deal/${payload.dealId}`);
        return await response.json();
      } catch (err) {
        console.log(`Get notes for Project failed: ${JSON.stringify(err)}`);
        payload.onErrorCallback?.();
      }
    },
    async (_, requestData) => {
      requestData.payload.callback?.();
    },
    async (_, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleGetNotesForScheduledEvent = handleAsyncAction(
    getNotesForScheduledEvent,
    async (payload) => {
      try {
        const response = await req.get(
          `/notes/scheduledEvent/${payload.scheduledEventId}`
        );
        return await response.json();
      } catch (err) {
        console.log(
          `Get Notes for Scheduled Event failed: ${JSON.stringify(err)}`
        );
        payload.onErrorCallback?.();
      }
    },
    async (_, requestData) => {
      requestData.payload.callback?.();
    }
  );

  const handleCreateScheduledEventNote = handleAsyncAction(
    createScheduledEventNote,
    async (payload) => {
      try {
        const response = await req.post(
          `/notes/scheduledEvent/${payload.scheduledEventId}`,
          {
            note: payload.note,
          }
        );
        return await response.json();
      } catch (err) {
        console.log(
          `Create Note for Scheduled Event failed: ${JSON.stringify(err)}`
        );
        payload.onErrorCallback?.();
      }
    },
    async (_, requestData) => {
      requestData.payload.callback?.();
    }
  );
  const handleUpdateScheduledEventNote = handleAsyncAction(
    updateScheduledEventNote,
    async (payload) => {
      try {
        const response = await req.put(
          `/notes/scheduledEvent/${payload.scheduledEventId}/note/${payload.note.id}`,
          {
            note: payload.note,
          }
        );
        return await response;
      } catch (err) {
        console.log(
          `Update Note for Scheduled Event failed: ${JSON.stringify(err)}`
        );
        payload.onErrorCallback?.();
      }
    },
    async (_, requestData) => {
      requestData.payload.callback?.();
    }
  );
  const handleArchiveScheduledEventNote = handleAsyncAction(
    archiveScheduledEventNote,
    async (payload) => {
      try {
        const response = await req.put(
          `/notes/scheduledEvent/${payload.scheduledEventId}/note/${payload.noteId}/archive`
        );
        return await response;
      } catch (err) {
        console.log(
          `Archive Note for Scheduled Event failed: ${JSON.stringify(err)}`
        );
        payload.onErrorCallback?.();
      }
    },
    async (_, requestData) => {
      requestData.payload.callback?.();
    }
  );

  function* handleAppLogout() {
    yield put(clearNotesStore());
  }

  return function* () {
    yield takeLatest(ActionType.REQUEST_CREATE_NOTE, handleCreateNote);
    yield takeLatest(ActionType.REQUEST_UPDATE_NOTE, handleUpdateNote);
    yield takeLatest(ActionType.REQUEST_ARCHIVE_NOTE, handleArchiveNote);
    yield takeEvery(
      ActionType.GET_NOTES_FOR_DEAL_REQUEST,
      handleGetNotesForDeal
    );
    yield takeEvery(
      ActionType.GET_NOTES_FOR_SCHEDULED_EVENT_REQUEST,
      handleGetNotesForScheduledEvent
    );
    yield takeEvery(
      ActionType.CREATE_SCHEDULED_EVENT_NOTE_REQUEST,
      handleCreateScheduledEventNote
    );
    yield takeEvery(
      ActionType.UPDATE_SCHEDULED_EVENT_NOTE_REQUEST,
      handleUpdateScheduledEventNote
    );
    yield takeEvery(
      ActionType.ARCHIVE_SCHEDULED_EVENT_NOTE_REQUEST,
      handleArchiveScheduledEventNote
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default dealsSagaCreator;
