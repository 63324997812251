import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import { Action, PayloadAction } from 'typesafe-actions';
import { ActionType, SagaConfig } from './formsTypes';

import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import {
  getAvailableFormsFailure,
  getAvailableFormsSuccess,
  getFormDataFailure,
  getFormDataSuccess,
  searchFormItemsFailure,
  searchFormItemsSuccess,
  sendFormFailure,
  sendFormSuccess,
  submitFormFailure,
  submitFormSuccess,
  updateFormDataFailure,
  updateFormDataSuccess,
} from './formsActions';

const formsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  function* handleGetAvailableForms(
    action: Action<ActionType.REQUEST_GET_AVAILABLE_FORMS>
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getAvailableForms),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get available forms data timed out.';
        yield put(getAvailableFormsFailure(new Error(errorMessage)));
      } else {
        yield put(getAvailableFormsSuccess(response));
      }
    } catch (error: any) {
      yield put(getAvailableFormsFailure(error));
    }
  }

  const getAvailableForms = async () => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/forms/available`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getFormData failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };
  function* handleGetFormData(
    action: PayloadAction<
      ActionType.REQUEST_GET_FORM_DATA,
      {
        formPreviewToken: string;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getFormData, action.payload.formPreviewToken),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get form data timed out.';
        yield put(getFormDataFailure(new Error(errorMessage)));
      } else {
        yield put(getFormDataSuccess(response.id, response));
      }
    } catch (error: any) {
      yield put(getFormDataFailure(error));
    }
  }

  const getFormData = async (formPreviewToken: string) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/forms/data/${formPreviewToken}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getFormData failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleUpdateFormData(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_FORM_DATA,
      {
        formPreviewToken: string;
        formData: any;
        cb: any;
      }
    >
  ) {
    // add a delay to debounce updates (in conjunction with takeLatest)
    // yield delay(2000);
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          updateFormData,
          action.payload.formPreviewToken,
          action.payload.formData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Update form data timed out.';
        yield put(
          updateFormDataFailure(
            action.payload.formPreviewToken,
            new Error(errorMessage)
          )
        );
      } else {
        action.payload.cb?.();
        yield put(
          updateFormDataSuccess(action.payload.formPreviewToken, response)
        );
      }
    } catch (error: any) {
      yield put(updateFormDataFailure(action.payload.formPreviewToken, error));
    }
  }

  const updateFormData = async (formPreviewToken: string, formData: any) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/forms/data/${formPreviewToken}`, {
      method: 'PUT',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
      body: JSON.stringify(formData),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `updateFormData failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleSubmitForm(
    action: PayloadAction<
      ActionType.REQUEST_SUBMIT_FORM,
      {
        formPreviewToken: string;
        callback: (error?: string) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(submitForm, action.payload.formPreviewToken),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Submit form data timed out.';
        yield put(
          submitFormFailure(
            action.payload.formPreviewToken,
            new Error(errorMessage)
          )
        );
        action.payload.callback(errorMessage);
      } else {
        yield put(submitFormSuccess(action.payload.formPreviewToken));
        action.payload.callback();
      }
    } catch (error: any) {
      yield put(submitFormFailure(action.payload.formPreviewToken, error));
      action.payload.callback(error.message);
    }
  }

  const submitForm = async (formPreviewToken: string) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/forms/data/${formPreviewToken}/submit`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `submitForm failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleSearchFormItems(
    action: PayloadAction<
      ActionType.REQUEST_SEARCH_FORM_ITEMS,
      {
        type: string;
        targetCategory: string;
        one?: string;
        two?: string;
        three?: string;
        four?: string;
        five?: string;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          searchFormItems,
          action.payload.type,
          action.payload.targetCategory,
          action.payload.one,
          action.payload.two,
          action.payload.three,
          action.payload.four,
          action.payload.five
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Search form items timed out.';
        yield put(searchFormItemsFailure(new Error(errorMessage)));
      } else {
        yield put(searchFormItemsSuccess(action.payload.type, response));
      }
    } catch (error: any) {
      yield put(searchFormItemsFailure(error));
    }
  }

  const searchFormItems = async (
    type: string,
    targetCategory: string,
    one = '',
    two = '',
    three = '',
    four = '',
    five = ''
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/forms/search/${type}?distinct=${targetCategory}&one=${one}&two=${two}&three=${three}&four=${four}&five=${five}`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `searchFormItems failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleSendForm(
    action: PayloadAction<
      ActionType.REQUEST_SEND_FORM,
      {
        formInstanceId: number;
        sendToEmail: string;
        emailBody: string;
        sendACopy: boolean;
        callback?: any;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          sendForm,
          action.payload.formInstanceId,
          action.payload.sendToEmail,
          action.payload.emailBody,
          action.payload.sendACopy
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Send form timed out.';
        yield put(sendFormFailure(new Error(errorMessage)));
      } else {
        yield put(sendFormSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(sendFormFailure(error));
    }
  }

  const sendForm = async (
    formInstanceId: number,
    sendToEmail: string,
    emailBody: string,
    sendACopy: boolean
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/forms/${formInstanceId}/send`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
      body: JSON.stringify({
        sendToEmail,
        emailBody,
        sendACopy,
      }),
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `sendForm failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  return function* () {
    yield takeLatest(
      ActionType.REQUEST_GET_AVAILABLE_FORMS,
      handleGetAvailableForms
    );
    yield takeLatest(ActionType.REQUEST_GET_FORM_DATA, handleGetFormData);
    yield takeEvery(ActionType.REQUEST_UPDATE_FORM_DATA, handleUpdateFormData);
    yield takeLatest(ActionType.REQUEST_SUBMIT_FORM, handleSubmitForm);
    yield takeLatest(ActionType.REQUEST_SEND_FORM, handleSendForm);
    yield throttle(
      1200,
      ActionType.REQUEST_SEARCH_FORM_ITEMS,
      handleSearchFormItems
    );
  };
};

export default formsSagaCreator;
