import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import meKeys from './keyFactory';

const GetMyAccountBranding = graphql(`
  query MyAccountBranding {
    me {
      user {
        account {
          branding {
            logoUrl
            theme {
              primaryColour
            }
          }
        }
      }
    }
  }
`);

const useGetMyAccountBranding = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountBranding(),
    queryFn: () => {
      return gqlClient.request(GetMyAccountBranding);
    },
  });

  return { data: data?.me.user.account.branding, ...rest };
};

export default useGetMyAccountBranding;
