import { Req } from '@payaca/helpers/storeHelper';
import { PurchaseOrderPdfDocumentData } from '@payaca/types/purchaseOrderPdfDocumentTypes';
import { call, delay, race, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions';
import {
  ActionType,
  GetChangeProposalDataAction,
  GetInvoiceDataAction,
  GetProtoInvoiceDataAction,
  SagaConfig,
} from './pdfDocumentDataTypes';

import {
  getChangeProposalData,
  getInvoiceData,
  getProtoInvoiceData,
} from './pdfDocumentDataActions';

import { ChangeProposalPdfDocumentData } from '@payaca/types/changeProposalPdfDocumentTypes';
import { InvoicePdfDocumentData } from '@payaca/types/invoicePdfDocumentTypes';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { handleAsyncAction } from '../utils';

const reportsSagaCreator = ({ apiBaseurl }: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`);
  function* handleGetPurchaseOrderData(
    action: PayloadAction<
      ActionType.GET_PURCHASE_ORDER_DATA_REQUEST,
      {
        previewToken: string;
        callback: (data: PurchaseOrderPdfDocumentData) => void;
        errorCallback: (error: Error) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(getPurchaseOrderData, action.payload.previewToken),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        action.payload.errorCallback &&
          action.payload.errorCallback(
            new Error('Get purchaseorder data request timed out.')
          );
      } else {
        action.payload.callback(response);
      }
    } catch (error: any) {
      action.payload.errorCallback &&
        action.payload.errorCallback(error as Error);
    }
  }

  const getPurchaseOrderData = async (previewToken: any) => {
    const response = await req.get(
      `/purchase_order/pdf_document/${previewToken}`
    );
    return response.json();
  };

  const handleGetChangeProposalData =
    handleAsyncAction<GetChangeProposalDataAction>(
      getChangeProposalData,
      async (payload) => {
        const response = await fetch(
          `${apiBaseurl}/api/change_proposals/pdf_document/${payload.previewToken}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
          }
        );
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to get change proposal data');
        }
      },
      (data, payload) => {
        payload.payload?.callback?.(data as ChangeProposalPdfDocumentData);
      },
      (error, payload) => {
        payload.payload?.onErrorCallback?.(error);
      }
    );

  const handleGetInvoiceData = handleAsyncAction<GetInvoiceDataAction>(
    getInvoiceData,
    async (payload) => {
      const response = await fetch(
        `${apiBaseurl}/api/invoices/pdf_document/${payload.previewToken}/${payload.invoiceId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Simple-Job': 'true',
          },
        }
      );
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to get invoice data');
      }
    },
    (data, payload) => {
      payload.payload?.callback?.(data as InvoicePdfDocumentData);
    },
    (error, payload) => {
      payload.payload?.onErrorCallback?.(error);
    }
  );

  const handleGetProtoInvoiceData =
    handleAsyncAction<GetProtoInvoiceDataAction>(
      getProtoInvoiceData,
      async (payload) => {
        const response = await fetch(
          `${apiBaseurl}/api/proposals/proto_invoice/pdf_document/${payload.previewToken}/${payload.protoInvoiceId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
          }
        );
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to get proto invoice data');
        }
      },
      (data, payload) => {
        payload.payload?.callback?.(data as InvoicePdfDocumentData);
      },
      (error, payload) => {
        payload.payload?.onErrorCallback?.(error);
      }
    );

  return function* () {
    yield takeLatest(
      ActionType.GET_PURCHASE_ORDER_DATA_REQUEST,
      handleGetPurchaseOrderData
    );
    yield takeLatest(
      ActionType.GET_CHANGE_PROPOSAL_DATA_REQUEST,
      handleGetChangeProposalData
    );
    yield takeLatest(ActionType.GET_INVOICE_DATA_REQUEST, handleGetInvoiceData);
    yield takeLatest(
      ActionType.GET_PROTO_INVOICE_DATA_REQUEST,
      handleGetProtoInvoiceData
    );
  };
};

export default reportsSagaCreator;
