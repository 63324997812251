import { FunctionComponent, useEffect, useMemo } from 'react';

import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import FeedbackBlock from '../feedbackBlock/FeedbackBlock';
import { ErrorMessage } from '../feedbackMessage/FeedbackMessage';
import './ValidationFeedbackBlock.sass';

type Props = {
  validationResults: FieldValidationResult[];
  className?: string;
  isDismissable?: boolean;
  onDismissed?: () => void;
};

const ValidationFeedbackBlock: FunctionComponent<Props> = ({
  validationResults,
  isDismissable,
  className,
  onDismissed,
}: Props): JSX.Element | null => {
  const validationErrors = useMemo(() => {
    return validationResults
      .filter((x) => !x.isValid)
      .map((x) => x.errors || [])
      .flat();
  }, [validationResults]);

  useEffect(() => {
    if (!validationErrors.length) {
      onDismissed && onDismissed();
    }
  }, [validationErrors?.length]);

  return (
    <FeedbackBlock
      isDismissable={isDismissable}
      className={`validation-feedback-block ${className ? className : ''}`}
      onDismissed={onDismissed}
      feedbackLevel={FeedbackLevel.ERROR}
    >
      <ul className="validation-error-list">
        {validationErrors.map((validationError, i) => {
          return (
            <li key={`validation-error-${i}`}>
              <ErrorMessage message={validationError} />
            </li>
          );
        })}
      </ul>
    </FeedbackBlock>
  );
};

export default ValidationFeedbackBlock;
