import { useMemo } from 'react';
import UserIndicator from '../userIndicator/UserIndicator';
import './MultiUsersIndicator.sass';

type Props = {
  users: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    userColour?: string;
  }[];
  size?: 'md' | 'sm' | 'xs';
  isUserInformationHoverPopperEnabled?: boolean;
  //   userInformationHoverPopperContentOverride?: React.ReactNode;
};

const MultiUsersIndicator = ({
  users,
  size = 'md',
  isUserInformationHoverPopperEnabled = false,
}: //   userInformationHoverPopperContentOverride,

Props): JSX.Element => {
  const dimensionsPx = useMemo(() => {
    switch (size) {
      case 'xs':
        return 15;
      case 'sm':
        return 20;
      default:
        return 30;
    }
  }, [size]);

  return (
    <div className={`multi-users-indicator`}>
      {users.map((user, i) => (
        <div
          className="user-indicator-wrapper"
          key={`user-indicator-${i}`}
          style={{
            marginRight: i === users.length - 1 ? 0 : -(dimensionsPx / 2),
          }}
        >
          <UserIndicator
            user={user}
            size={size}
            isUserInformationHoverPopperEnabled={
              isUserInformationHoverPopperEnabled
            }
          />
        </div>
      ))}
    </div>
  );
};

export default MultiUsersIndicator;
