import { ComponentPropsWithoutRef, forwardRef } from 'react';
import UntitledLoading from '../plUntitledLoading/UntitledLoading';
import useButtonClassName, { TBtnVariantProps } from './useButtonClassName';

export interface IButtonProps
  extends ComponentPropsWithoutRef<'button'>,
    TBtnVariantProps {
  isProcessing?: boolean;
  replaceContentWhenProcessing?: boolean;
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const {
    className,
    type = 'button',
    variant,
    size,
    colour,
    children,
    shape,
    isProcessing = false,
    replaceContentWhenProcessing = false,
    ...rest
  } = props;

  const variantClassName = useButtonClassName({
    variant,
    size,
    colour,
    shape,
  });

  return (
    <button
      ref={ref}
      type={type}
      className={
        variantClassName + ' relative' + (className ? ' ' + className : '')
      }
      {...rest}
    >
      {!(replaceContentWhenProcessing && isProcessing) && children}
      {isProcessing && <UntitledLoading className="h-4 w-4" />}
    </button>
  );
});

export default Button;
