import {
  Dialog,
  DialogPanelProps,
  Transition,
  TransitionEvents,
} from '@headlessui/react';
import clsx from 'clsx';
import { forwardRef, Fragment, PropsWithChildren } from 'react';
import Draggable from 'react-draggable';

export interface IModalCoreProps extends TransitionEvents {
  uId?: string;
  isOpen: boolean;
  onClose?: () => void;
  className?: string;
  disableBackdropClick?: boolean;
  draggable?: boolean;
  panelProps?: DialogPanelProps<'div'>;
  // Allows multiple modals to be stacked on top of each other
  zIndexLevel?: 1 | 2;
}

const ModalCore = forwardRef<
  HTMLDivElement,
  PropsWithChildren<IModalCoreProps>
>((props, ref) => {
  const {
    className,
    uId,
    children,
    isOpen,
    onClose,
    disableBackdropClick,
    draggable,
    panelProps,
    zIndexLevel = 1,
    ...rest
  } = props;

  return (
    <Transition show={isOpen} appear as={Fragment} {...rest}>
      <Dialog onClose={disableBackdropClick ? () => null : () => onClose?.()}>
        {/*Backdrop*/}
        <Transition.Child
          enter="transition-opacity ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as={Fragment}
        >
          <div
            className={clsx(
              'fixed inset-0 bg-gray-900 bg-opacity-30 dark:bg-opacity-80',
              zIndexLevel === 1 && 'z-modalBackdrop1',
              zIndexLevel === 2 && 'z-modalBackdrop2'
            )}
            aria-hidden="true"
          />
        </Transition.Child>

        <Transition.Child
          enter="transition duration-300 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-300 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          as={Fragment}
        >
          <div
            className={clsx(
              'fixed inset-0 flex w-screen items-center justify-center overflow-x-auto p-4',
              zIndexLevel === 1 && 'z-modal1',
              zIndexLevel === 2 && 'z-modal2'
            )}
          >
            <Draggable
              disabled={!draggable}
              handle={`.payaca-modal-handle${uId}`}
              bounds="html"
            >
              <Dialog.Panel ref={ref} className={className} {...panelProps}>
                {children}
              </Dialog.Panel>
            </Draggable>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
});

export default ModalCore;
