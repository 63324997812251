import {
  JobLineItem,
  JobLineItemBase,
  JobLineItemTotals,
} from '@payaca/types/jobContentTypes';

// This calculation is only valid for dealsV2 where we can assume there will never be any markup or discount on the jobContent, and discount is handles on a line-by-line basis
export const getJoblineItemTotalsFromJobLineItemBase = (
  jobLineItem: Pick<
    JobLineItemBase,
    'calculatedPrice' | 'discountPercentage' | 'markupPercentage'
  >
): JobLineItemTotals => {
  const price = jobLineItem.calculatedPrice;

  // Markup is calculated and applied before discount - markup and discount compound.
  let priceMarkupAmount = 0;

  const roundMethod = price >= 0 ? Math.ceil : Math.floor;

  if (jobLineItem.markupPercentage) {
    priceMarkupAmount = roundMethod(
      price * (jobLineItem.markupPercentage / 100)
    );
  }

  const priceIncMarkup = price + priceMarkupAmount;

  let priceDiscountAmount = 0;

  if (jobLineItem.discountPercentage) {
    priceDiscountAmount = roundMethod(
      priceIncMarkup * (jobLineItem.discountPercentage / 100)
    );
  }

  return {
    priceIncMarkup: priceIncMarkup,
    priceIncMarkupDiscount: priceIncMarkup - priceDiscountAmount,
  };
};

// This calculation is only valid for dealsV2 where we can assume there will never be any markup or discount on the jobContent, and discount is handles on a line-by-line basis
export const getUnitJoblineItemTotalsFromJobLineItem = (
  jobLineItem: Pick<
    JobLineItemBase,
    | 'calculatedPrice'
    | 'quantity'
    | 'discountPercentage'
    | 'vatIncluded'
    | 'isReverseChargeVat'
    | 'vatAmount'
    | 'cisDeductionRate'
    | 'markupPercentage'
  >
): Pick<
  JobLineItem,
  | 'vatTotal'
  | 'cisTotal'
  | 'discountAmount'
  | 'markupAmount'
  | 'subtotalIncMarkupDiscount'
  | 'total'
> => {
  const price = jobLineItem.calculatedPrice;

  // Markup is calculated and applied before discount - markup and discount compound.
  let markupAmount = 0;
  const roundMethod = price >= 0 ? Math.ceil : Math.floor;

  if (jobLineItem.markupPercentage) {
    markupAmount = roundMethod(price * (jobLineItem.markupPercentage / 100));
  }

  const priceIncMarkup = price + markupAmount;

  let discountAmount = 0;

  if (jobLineItem.discountPercentage) {
    discountAmount = roundMethod(
      priceIncMarkup * (jobLineItem.discountPercentage / 100)
    );
  }

  const subtotalIncMarkupDiscount = priceIncMarkup - discountAmount;

  const vatTotal = Math.round(
    jobLineItem.vatIncluded && !jobLineItem.isReverseChargeVat
      ? subtotalIncMarkupDiscount * (jobLineItem.vatAmount / 100)
      : 0
  );

  let cisTotal = undefined;
  if (jobLineItem.cisDeductionRate !== null) {
    cisTotal = Math.round(
      subtotalIncMarkupDiscount * (jobLineItem.cisDeductionRate / 100)
    );
  }

  return {
    markupAmount: markupAmount,
    discountAmount: discountAmount,
    subtotalIncMarkupDiscount: subtotalIncMarkupDiscount,
    vatTotal: vatTotal,
    cisTotal: cisTotal,
    total: subtotalIncMarkupDiscount + vatTotal - (cisTotal || 0),
  };
};

export const getSortedRequiredMultipleOptionalItems = (
  items: JobLineItemBase[]
) => {
  return items.reduce(
    (acc: any, curr: any) => {
      if (curr.isMultipleChoice) acc.multiple.push(curr);
      else if (curr.isOptional) acc.optional.push(curr);
      else acc.required.push(curr);
      return acc;
    },
    { required: [], multiple: [], optional: [] }
  );
};
