import { Nullish } from '@payaca/utilities/types';
import { ProposalSummaryMessage } from './jobTypesV2';
import { PaymentSchedule } from './payment-schedule';
import { Tag } from './tagTypes';
import { Upload } from './uploadTypes';

export type TemplatableEntity = 'task' | 'scheduledEvent' | 'proposal';

export type EntityTemplateSource = 'service_plan_commitment';

export type EntityTemplate<TEntityType extends TemplatableEntity> = {
  id: number;
  publicId: string;
  name?: Nullish<string>;
  description?: Nullish<string>;
  entityType: TEntityType;
  data: Partial<EntityTemplateData[TEntityType]>;
  accountId?: number;
  metaData?: {
    source?: EntityTemplateSource;
  };
};

export type HydratedEntityTemplate<TEntityType extends TemplatableEntity> =
  EntityTemplate<TEntityType> & {
    servicePlans: {
      publicId: string;
      name: string;
    }[];
    uploads: Upload[];
  };

export type PublicEntityTemplate<TEntityType extends TemplatableEntity> = Omit<
  EntityTemplate<TEntityType>,
  'id'
>;

export type PublicHydratedEntityTemplate<
  TEntityType extends TemplatableEntity,
> = Omit<HydratedEntityTemplate<TEntityType>, 'id'>;

type EntityTemplateData = {
  task: {
    name: string;
    notes: string;
    checklistItems: Array<string>;
    formTemplateId?: Nullish<string>;
    scheduledEvents?: Nullish<Array<ScheduledEventTemplate>>;
  };
  scheduledEvent: {
    name: string;
    description: string;
    duration?: Nullish<string>; // ISO 8601 duration string
    includeCustomerInformation?: Nullish<boolean>;
    tasks?: Nullish<Array<Omit<PublicEntityTemplate<'task'>, 'publicId'>>>;
    uploadIds?: Nullish<Array<Upload['id']>>;
    tagIds?: Nullish<Array<Tag['id']>>;
  };
  proposal: {
    introduction?: string | object | object[] | null;
    notes?: string | object | object[] | null;
    validForDays?: Nullish<number>;
    paymentSchedule?: Nullish<PaymentSchedule>;
    summaryMessages?: Nullish<ProposalSummaryMessage[]>;
  };
};

export type TaskTemplate = EntityTemplate<'task'>;

export type ScheduledEventTemplate = EntityTemplate<'scheduledEvent'>;

export enum EntityTemplatePlaceholderVariables {
  CUSTOMER_NAME = 'customer.name',
  PROJECT_SITEADDRESSES_ARRAYSTRING = 'deal.siteAddresses.arrayString',
  PROJECT_CUSTOMFIELD_VALUE = 'deal.customField.value',
}

export type ProposalTemplatePlaceholderVariables =
  | EntityTemplatePlaceholderVariables.CUSTOMER_NAME
  | EntityTemplatePlaceholderVariables.PROJECT_SITEADDRESSES_ARRAYSTRING;
