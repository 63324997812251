import AddEditContactControl, {
  IProps as IAddEditContactControlProps,
} from '@/ui/components/addEditContactControl/AddEditContactControl';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import { FC } from 'react';

export interface IProps
  extends IModalProps,
    Pick<IAddEditContactControlProps, 'contact' | 'onSave'> {}

const AddEditContactModal: FC<IProps> = (props) => {
  const { contact, onSave, ...rest } = props;

  return (
    <Modal {...rest}>
      <AddEditContactControl
        isInModal
        contact={contact}
        onSave={onSave}
        onClose={rest.onClose}
      />
    </Modal>
  );
};

export default AddEditContactModal;
