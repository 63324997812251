import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getDeal } from '@/utils/stateAccessors';

import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import PaymentOptionsControl from '../paymentOptionsControl/PaymentOptionsControl';

import { UpdateDealRequestData } from '@payaca/types/dealTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import { useSelector } from '@/api/state';

import * as dealsActions from '@payaca/store/deals/dealsActions';

type Props = {
  dealId: number;
};

const DealPaymentOptionsControl: FC<Props> = ({
  dealId,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const deal = useSelector((state) => getDeal(state, dealId));
  const [isSaveRequired, setIsSaveRequired] = useState(false);

  const initialFormState = useMemo(() => {
    return {
      allowStripePayment: deal?.allowStripePayment,
      allowBankTransferPayment: deal?.allowBankTransferPayment,
      description: deal?.description,
      customReference: deal?.customReference,
    };
  }, [deal]);

  const updateDeal = useCallback(
    (formState: { [key: string]: any }) => {
      dispatch(
        dealsActions.requestUpdateDeal(
          dealId,
          formState as UpdateDealRequestData,
          () => dispatch(dealsActions.requestGetDeal(dealId))
        )
      );
    },
    [dealId, dispatch]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      if (isSaveRequired) {
        updateDeal(formState);
        setIsSaveRequired(false);
      }
      return (
        <PaymentOptionsControl
          showBACSPaymentOption={formState.allowBankTransferPayment}
          showStripePaymentOption={formState.allowStripePayment}
          onChange={(value: any) => {
            const changeObject: { [key: string]: any } = {};
            /* eslint-disable-next-line */
            if (value.hasOwnProperty('showBACSPaymentOption')) {
              changeObject.allowBankTransferPayment =
                value.showBACSPaymentOption;
            }
            /* eslint-disable-next-line */
            if (value.hasOwnProperty('showStripePaymentOption')) {
              changeObject.allowStripePayment = value.showStripePaymentOption;
            }
            onFieldChange(changeObject);
            setIsSaveRequired(true);
          }}
        />
      );
    },
    [updateDeal, isSaveRequired]
  );
  return (
    <div className="deal-payment-options-control">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
      />
    </div>
  );
};

export default DealPaymentOptionsControl;
