import useOptimisticUpdateContactOnProject from '@/api/mutations/project/optimistic/useOptimisticUpdateContactOnProject';
import useOptimisticUpdateCustomerContactOnProject from '@/api/mutations/project/optimistic/useOptimisticUpdateCustomerContactOnProject';
import useGetExistingCustomerContactEmails from '@/api/queries/customers/useGetExistingCustomerContactEmails';
import { Contact, CustomerContact } from '@/gql/graphql';
import { ContactFieldset } from '@/ui/components/contactGroupControl/ContactGroupControl';
import Conditional from '@payaca/components/conditional/Conditional';
import CopyTextIconButton from '@payaca/components/copyTextIconButton/CopyTextIconButton';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Modal from '@payaca/components/plModal/Modal';
import ValidatedForm, {
  TRenderFormContents,
} from '@payaca/components/validatedForm/ValidatedForm';
import {
  allowEmptyCustomerContactPhoneNumberFieldValidator,
  customerContactDescriptionFieldValidator,
  getCustomerContactEmailFieldValidator,
} from '@payaca/helpers/customerValidationHelper';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { Contact as DBContact } from '@payaca/types/contactTypes';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

type TProps = (Contact | CustomerContact) & {
  variant: 'customerContact' | 'accessContact';
};

const ContactCard: FC<TProps> = (props) => {
  const { variant, id, name, description, email, phone } = props;
  const { dealId } = useParams<{ dealId: string }>();
  const { mutateAsync: updateContactMutation } =
    useOptimisticUpdateContactOnProject(+dealId);
  const { mutateAsync: updateCustomerContactMutation } =
    useOptimisticUpdateCustomerContactOnProject(+dealId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      setIsEditMode(false);
    }
  }, [isModalOpen]);

  const { data: existingCustomerContactEmails, isLoading } =
    useGetExistingCustomerContactEmails();

  const fieldValidators = useMemo(() => {
    const emailAddressValidators = [];

    if (variant === 'customerContact') {
      const disallowedEmails =
        existingCustomerContactEmails?.filter((x) => x != email) || [];

      emailAddressValidators.push(
        getCustomerContactEmailFieldValidator({
          existingCustomerContactEmails: disallowedEmails,
        })
      );
    }

    return {
      name: [getIsRequiredFieldValidator()],
      description: [customerContactDescriptionFieldValidator],
      emailAddress: emailAddressValidators,
      telephoneNumber: [allowEmptyCustomerContactPhoneNumberFieldValidator],
    };
  }, [existingCustomerContactEmails]);

  const initialFormState = useMemo(() => {
    return {
      id: parseInt(id),
      name: name || undefined,
      description: description || undefined,
      emailAddress: email || undefined,
      telephoneNumber: phone || undefined,
    };
  }, [isModalOpen, isEditMode, id, name, description, email, phone]);

  const handleSubmit = useCallback(
    (formState: Partial<DBContact>) => {
      if (!formState.id) return;

      const input = {
        name:
          formState.name && formState.name.length > 0
            ? formState.name
            : undefined,
        description:
          formState.description && formState.description.length > 0
            ? formState.description
            : undefined,
        email:
          formState.emailAddress && formState.emailAddress.length > 0
            ? formState.emailAddress
            : undefined,
        phone:
          formState.telephoneNumber && formState.telephoneNumber.length > 0
            ? formState.telephoneNumber
            : undefined,
      };

      if (variant === 'customerContact') {
        updateCustomerContactMutation({
          customerContactId: formState.id + '',
          ...input,
        });
      }

      if (variant === 'accessContact') {
        updateContactMutation({
          contactId: formState.id + '',
          ...input,
        });
      }

      setIsEditMode(false);
    },
    [
      updateContactMutation,
      updateCustomerContactMutation,
      setIsEditMode,
      variant,
    ]
  );

  const renderFormContents = useCallback<
    TRenderFormContents<Partial<DBContact>>
  >(
    (
      isValid,
      formState,
      validationState,
      touchedState,
      onFieldChange,
      onFieldTouch
    ) => {
      return (
        <Modal
          title={
            variant === 'customerContact'
              ? 'Customer Contact details'
              : 'Access Contact details'
          }
          disableBackdropClick={isEditMode}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <Modal.Body>
            <Conditional condition={isEditMode}>
              <ContactFieldset
                contact={formState}
                onChange={onFieldChange}
                validationState={validationState}
                onTouch={onFieldTouch}
                touchedState={touchedState}
              />
            </Conditional>

            <Conditional condition={!isEditMode}>
              <div className="mb-4 space-y-1">
                <h4>{name}</h4>
                <p>{description}</p>
                {email && (
                  <p className="flex justify-between">
                    <a href={`mailto:${email}`}>{email}</a>
                    <CopyTextIconButton textToCopy={email} />
                  </p>
                )}
                {phone && (
                  <p className="flex justify-between">
                    <a href={`tel:${phone}`}>{phone}</a>
                    <CopyTextIconButton textToCopy={phone} />
                  </p>
                )}
              </div>
            </Conditional>
          </Modal.Body>

          <Modal.Footer>
            <Modal.Footer.Actions>
              <Conditional condition={isEditMode}>
                <Button
                  onClick={() => setIsEditMode(false)}
                  variant={EBtnVariant.Outline}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isValid}
                  onClick={() => {
                    handleSubmit(formState);
                  }}
                >
                  Save
                </Button>
              </Conditional>
              <Conditional condition={!isEditMode}>
                <Button
                  onClick={() => setIsEditMode(true)}
                  variant={EBtnVariant.Outline}
                >
                  Edit
                </Button>
              </Conditional>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </Modal>
      );
    },
    [
      isModalOpen,
      setIsModalOpen,
      variant,
      isEditMode,
      setIsEditMode,
      name,
      description,
      email,
      phone,
      handleSubmit,
    ]
  );

  return (
    <>
      <button
        className="prose flex w-full cursor-pointer items-center gap-4 border-b bg-transparent py-3.5"
        onClick={() => setIsModalOpen(true)}
      >
        <UntitledIcon name="user-square.3" className="h-5 w-5 text-blue-500" />
        <span>{name}</span>
      </button>

      <ValidatedForm<Partial<DBContact>>
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
        fieldValidators={fieldValidators}
      />
    </>
  );
};

export default ContactCard;
