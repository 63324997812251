import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

import { useSelector } from '@/api/state';
import {
  getDealDefaultPipelineIdLocalStorageKey,
  getDealFilterAssignedUserIdsLocalStorageKey,
  getDealFilterPipelineStagesLocalStorageKey,
  getDealFilterTagIdsLocalStorageKey,
  getListedDealsViewLocalStorageKey,
} from '@/helpers/localStorageKeyHelper';
import { getUserRoles } from '@/utils/stateAccessors';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { requestGetAccountPipelines } from '@payaca/store/pipelines/pipelineActions';
import { requestGetTags } from '@payaca/store/tags/tagsActions';
import {
  GetListedDealsRequestData,
  ListedDealViews,
  SortBy,
} from '@payaca/types/listedDealTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import { User } from '@payaca/types/userTypes';
import { useDispatch } from 'react-redux';
import { actions as usersActions } from '../../../api/users';
import CreateDealModal from '../createDealModal/CreateDealModal';
import { AddNewPipelineModal } from '../listedDealsPipeline/AddNewPipelineModal';

const dealDefaultPipelineIdLocalStorageKey =
  getDealDefaultPipelineIdLocalStorageKey();

const dealFilterTagIdsLocalStorageKey = getDealFilterTagIdsLocalStorageKey();
const dealFilterPipelineStagesLocalStorageKey =
  getDealFilterPipelineStagesLocalStorageKey();
const dealFilterAssignedUserIdsLocalStorageKey =
  getDealFilterAssignedUserIdsLocalStorageKey();
const listedDealsViewLocalStorageKey = getListedDealsViewLocalStorageKey();

export type DisplayType = ListedDealViews;

export type RequestData = Pick<
  GetListedDealsRequestData,
  | 'pipelineId'
  | 'pipelineStages'
  | 'searchTerm'
  | 'searchTarget'
  | 'sortBy'
  | 'sortDirection'
  | 'tagIds'
  | 'archived'
> & {
  assignedUserIds?: User['id'][];
};

type onUpdateRequestDataProps = Partial<
  RequestData & {
    displayType: DisplayType;
  }
>;

export type ProjectContextType = {
  requestData: RequestData;
  displayType: DisplayType;
  onUpdate: (props: onUpdateRequestDataProps) => void;
  isLoading: boolean;
  createDeal: () => void;
  createPipeline: () => void;
};

const emptyArray: Array<any> = [];

export const ProjectsContext = React.createContext<ProjectContextType>({
  requestData: {
    sortBy: SortBy.LAST_UPDATED,
    sortDirection: SortDirection.DESCENDING,
  },
  displayType: ListedDealViews.TABLE,
  onUpdate: () => null,
  isLoading: false,
  createDeal: () => null,
  createPipeline: () => null,
});

const ProjectsContextProvider: FC<
  PropsWithChildren<{
    archived?: boolean;
    initialRequestData?: ProjectContextType['requestData'];
  }>
> = ({ children, archived, initialRequestData }) => {
  const userRoles = useSelector(getUserRoles);

  const [showCreatePipelineModal, setShowCreatePipelineModal] = useState(false);
  const [showCreateDealModal, setShowCreateDealModal] = useState(false);
  const dispatch = useDispatch();

  const pipelines = useSelector((state) => state.pipelines.pipelines);
  const tags = useSelector((state) => state.tags.tags);
  const accountUsers = useSelector((state: any) => state.users.accountUsers);

  const isGettingListedDealsPage = useSelector(
    (state) => state.deals.isGettingListedDealsPage
  );
  const isGettingListedPipelineDeals = useSelector(
    (state) => state.deals.isGettingListedPipelineDeals
  );

  const initialUserAssignments = userHasRequiredPermission(userRoles, [
    DealsPermissions.GET_LISTED_DEALS,
  ])
    ? localStorage
        .getItem(dealFilterAssignedUserIdsLocalStorageKey)
        ?.split(',')
        .map((x) => (x === 'unassigned' ? -1 : +x))
        .filter((x) => !!x) || []
    : [];

  const initialPipelineId =
    localStorage.getItem(dealDefaultPipelineIdLocalStorageKey) === 'null'
      ? undefined // all projects
      : Number(localStorage.getItem(dealDefaultPipelineIdLocalStorageKey));

  const initialPipelineStages = localStorage
    .getItem(dealFilterPipelineStagesLocalStorageKey)
    ?.split(',')
    .filter((x) => x != '');

  const initialTagIds = localStorage
    .getItem(dealFilterTagIdsLocalStorageKey)
    ?.split(',')
    .filter((x) => x != '')
    .map((x) => +x);

  const [displayType, setDisplayType] = useState(
    (localStorage.getItem(listedDealsViewLocalStorageKey) as ListedDealViews) ||
      ListedDealViews.PIPELINE
  );

  const [requestData, setRequestData] = useState<
    ProjectContextType['requestData']
  >({
    assignedUserIds: initialUserAssignments,
    sortBy: SortBy.LAST_UPDATED,
    sortDirection: SortDirection.DESCENDING,
    pipelineId: initialPipelineId,
    pipelineStages: initialPipelineStages,
    tagIds: initialTagIds,
    archived,
  });

  useEffect(() => {
    if (initialRequestData) {
      setRequestData(initialRequestData);
    }
  }, [initialRequestData]);

  useEffect(() => {
    if (initialRequestData) return;

    if (pipelines?.length) {
      const pipeline = pipelines.find((p) => p.id === initialPipelineId);
      if (!pipeline) {
        // cannot find pipeline id - default to first pipeline
        let pId: GetListedDealsRequestData['pipelineId'] = pipelines[0].id;
        if (initialPipelineId === undefined) {
          // set to all projects, if initialPipelineId was specifed as undefined
          pId = undefined;
        }
        setRequestData((x) => ({
          ...x,
          pipelineId: pId,
          pipelineStages: emptyArray,
        }));
      } else {
        const stages = pipeline.stages.map((x) => x.title);
        setRequestData((x) => ({
          ...x,
          pipelineId: initialPipelineId,
          pipelineStages: initialPipelineStages?.filter((x) =>
            stages.includes(x)
          ),
        }));
      }
    }
  }, [initialPipelineId, pipelines, initialRequestData]);

  const onUpdateRequestData = ({
    displayType,
    ...requestData
  }: onUpdateRequestDataProps) => {
    if (displayType) {
      setDisplayType(displayType);
      localStorage.setItem(listedDealsViewLocalStorageKey, displayType);
    }

    if ('pipelineId' in requestData) {
      localStorage.setItem(
        dealDefaultPipelineIdLocalStorageKey,
        requestData.pipelineId?.toString() || 'null'
      );
    }

    if ('pipelineStages' in requestData) {
      localStorage.setItem(
        dealFilterPipelineStagesLocalStorageKey,
        requestData.pipelineStages?.join(',') || ''
      );
    }

    if ('assignedUserIds' in requestData) {
      localStorage.setItem(
        dealFilterAssignedUserIdsLocalStorageKey,
        requestData.assignedUserIds?.join(',') || ''
      );
    }

    if ('tagIds' in requestData) {
      localStorage.setItem(
        dealFilterTagIdsLocalStorageKey,
        requestData.tagIds?.join(',') || ''
      );
    }

    setRequestData((x) => ({
      ...x,
      ...requestData,
    }));
  };

  useEffect(() => {
    if (!pipelines.length) {
      dispatch(requestGetAccountPipelines());
    }
    if (!tags.length) {
      dispatch(requestGetTags());
    }
    if (
      !accountUsers.length &&
      userHasRequiredPermission(userRoles, [AccountsPermissions.GET_USERS])
    ) {
      dispatch(usersActions.getAccountUsers());
    }
  }, []);

  return (
    <ProjectsContext.Provider
      value={{
        requestData: requestData,
        displayType,
        onUpdate: onUpdateRequestData,
        isLoading: isGettingListedDealsPage || isGettingListedPipelineDeals,
        createDeal: () => setShowCreateDealModal(true),
        createPipeline: () => setShowCreatePipelineModal(true),
      }}
    >
      {children}
      <AddNewPipelineModal
        isOpen={showCreatePipelineModal}
        onClose={() => setShowCreatePipelineModal(false)}
        onPipelineCreated={(id: number) => {
          dispatch(requestGetAccountPipelines());
          onUpdateRequestData({ pipelineId: id });
          setShowCreatePipelineModal(false);
        }}
      />
      <CreateDealModal
        isOpen={showCreateDealModal}
        onClose={() => setShowCreateDealModal(false)}
        initialPipelineId={requestData.pipelineId || null}
      />
    </ProjectsContext.Provider>
  );
};

export default ProjectsContextProvider;
