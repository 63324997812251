import {
  TypedUseSelectorHook,
  useSelector as vendorUseSelector,
} from 'react-redux';

import { AppState, ClientAppState } from '@payaca/store';

// FIXME: Pick only the branches of state that the web app _actually_ uses,
// and define any web app-specific state branches in this typedef:
export type WebAppState = Pick<AppState, keyof AppState> &
  Pick<ClientAppState, keyof ClientAppState>;

export const useSelector: TypedUseSelectorHook<WebAppState> = vendorUseSelector;
