enum LocalStorage {
  NEW_WORLD_TOKEN = 'payaca-new-world-token',
  PROJECT_OVERVIEW_ACCORDION_PREFERENCES = 'project-overview-accordion-preferences',
  PROJECT_OVERVIEW_ACTIVITY_FILTER_PREFERENCES = 'project-overview-activity-filter-preferences',
  MATERIALS_FILTERS = 'payaca-materials-filters',
  MATERIAL_FILTER_CATEGORY_IDS = 'payaca-material-filter-category-ids',
  MATERIAL_FILTER_SUPPLIER_IDS = 'payaca-material-filter-supplier-ids',
  MATERIAL_FILTER_MANAGED_BY = 'payaca-material-managed-by',
  MATERIAL_FILTER_PAGE_SIZE = 'payaca-material-filter-page-size',
  DEAL_DEFAULT_PIPELINE_ID = 'payaca-listed-deal-pipeline-id',
  DEAL_ITEM_STATUS_KEY = 'payaca-listed-deal-status-filter',
  DEAL_FILTER_TAG_IDS_KEY = 'payaca-listed-deal-tag-ids-filter',
  DEAL_FILTER_PIPELINE_STAGES_KEY = 'payaca-listed-deal-pipeline-stages-filter',
  DEAL_USER_ASSIGNMENT_KEY = 'payaca-listed-deal-user-filter',
  DEAL_ITEM_TYPE_KEY = 'payaca-listed-deal-type-filter',
  DEAL_WORKFLOW_VIEW = 'payaca-listed-deal-workflow-view',
  VAT_AMOUNT = 'payaca-vatAmount',
  VAT_INCLUDED = 'payaca-vatIncluded',
  IS_REVERSE_CHARGE_VAT = 'payaca-isReverseChargeVat',
  TAX_RATE_ID = 'payaca-taxRateId',
  GRID_VIEW = 'payaca-calendar-grid-view',
  TIMELINE_VIEW = 'payaca-calendar-timeline-view',
  USER_VIEW = 'payaca-calendar-user-view',
  CALENDAR_VIEW = 'payaca-calendar-view',
  CALENDAR_HIDE_NON_BUSINESS_HOURS = 'payaca-calendar-hide-non-business-hours',
  CALENDAR_USER_ASSIGNMENT_KEY = 'payaca-calendar-user-filter',
  ITEMS_FILTERS = 'payaca-listed-items-filter',
  DISPATCH_DATE_VIEW = 'payaca-dispatch-date-view',
  SCHEDULE_DEFAULT_PIPELINE_ID = 'payaca-schedule-default-pipeline-id',
  SCHEDULE_DEFAULT_PIPELINE_STAGES = 'payaca-schedule-default-pipeline-stages',
  SCHEDULE_DISPLAY_TYPE = 'payaca-schedule-display-type',
  SCHEDULE_RANGE_UNIT = 'payaca-schedule-range-unit',
  SCHEDULE_EXPAND_TO_BE_SCHEDULED = 'payaca-schedule-expand-to-be-scheduled',
  PROJECT_PIPELINE_COLLAPSE_SETTINGS = 'payaca-project-pipeline-collapse-settings',
  TASKS_USER_ASSIGNMENT_KEY = 'payaca-listed-tasks-user-filter',
  TASKS_COMPLETION_STATUSES_KEY = 'payaca-listed-tasks-completion-statuses-filter',
  TASKS_EVENT_RELATIONSHIP_STATUSES_KEY = 'payaca-listed-tasks-event-relationship-statuses-filter',
  TASKS_TYPES_KEY = 'payaca-listed-tasks-type-filter',
}

const getEnvironmentSpecificStorageKey = (storageKey: string) => {
  const isDevelopment = import.meta.env.VITE_APP_ENV === 'development';
  const isStaging = import.meta.env.VITE_ENV === 'staging';

  const environmentPrefix = isDevelopment
    ? 'dev.'
    : isStaging
      ? 'staging.'
      : '';

  return `${environmentPrefix}${storageKey}`;
};

export const getNewWorldTokenLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.NEW_WORLD_TOKEN);
};

export const getProjectOverviewAccordionPreferencesLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.PROJECT_OVERVIEW_ACCORDION_PREFERENCES
  );
};

export const getProjectOverviewActivityFilterPreferencesLocalStorageKey =
  () => {
    return getEnvironmentSpecificStorageKey(
      LocalStorage.PROJECT_OVERVIEW_ACTIVITY_FILTER_PREFERENCES
    );
  };

export const getMaterialsFiltersLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.MATERIALS_FILTERS);
};
export const getItemsFiltersLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.ITEMS_FILTERS);
};

export const getGridViewLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.GRID_VIEW);
};
export const getTimelineViewLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.TIMELINE_VIEW);
};

export const getUserViewLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.USER_VIEW);
};

export const getCalendarViewLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.CALENDAR_VIEW);
};

export const getCalendarHideNonBusinessHoursLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.CALENDAR_HIDE_NON_BUSINESS_HOURS
  );
};
export const getCalendarFilterAssignedUserIdsLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.CALENDAR_USER_ASSIGNMENT_KEY
  );
};

export const getMaterialFilterCategoryIdsLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.MATERIAL_FILTER_CATEGORY_IDS
  );
};
export const getMaterialFilterSupplierIdsLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.MATERIAL_FILTER_SUPPLIER_IDS
  );
};
export const getMaterialFilterManagedByLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.MATERIAL_FILTER_MANAGED_BY
  );
};
export const getMaterialsFilterPageSizeLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.MATERIAL_FILTER_PAGE_SIZE
  );
};

export const getDealDefaultPipelineIdLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.DEAL_DEFAULT_PIPELINE_ID
  );
};

export const getScheduleDefaultPipelineIdLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.SCHEDULE_DEFAULT_PIPELINE_ID
  );
};

export const getScheduleDefaultPipelineStagesLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.SCHEDULE_DEFAULT_PIPELINE_STAGES
  );
};

export const getScheduleDisplayTypeLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.SCHEDULE_DISPLAY_TYPE);
};

export const getScheduleExpandToBeScheduledLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.SCHEDULE_EXPAND_TO_BE_SCHEDULED
  );
};

export const getProjectPipelineCollapseSettingsLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.PROJECT_PIPELINE_COLLAPSE_SETTINGS
  );
};

export const getScheduleRangeUnitStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.SCHEDULE_RANGE_UNIT);
};

export const getDealFilterStatusesLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.DEAL_ITEM_STATUS_KEY);
};

export const getDealFilterAssignedUserIdsLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.DEAL_USER_ASSIGNMENT_KEY
  );
};

export const getDealFilterTagIdsLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.DEAL_FILTER_TAG_IDS_KEY);
};

export const getDealFilterPipelineStagesLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.DEAL_FILTER_PIPELINE_STAGES_KEY
  );
};

export const getDealFilterTypesLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.DEAL_ITEM_TYPE_KEY);
};

export const getListedDealsViewLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.DEAL_WORKFLOW_VIEW);
};

export const getVatAmountLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.VAT_AMOUNT);
};

export const getVatIncludedLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.VAT_INCLUDED);
};

export const getIsReverseChargeVatLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.IS_REVERSE_CHARGE_VAT);
};

export const getTaxRateIdLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.TAX_RATE_ID);
};

export const getDispatchDateViewLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.DISPATCH_DATE_VIEW);
};
export const getTasksUserAssignmentKeyLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.TASKS_USER_ASSIGNMENT_KEY
  );
};
export const getTasksCompletionStatusesLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.TASKS_COMPLETION_STATUSES_KEY
  );
};
export const getTasksEventRelationshipStatusesLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(
    LocalStorage.TASKS_EVENT_RELATIONSHIP_STATUSES_KEY
  );
};
export const getTasksTypesLocalStorageKey = () => {
  return getEnvironmentSpecificStorageKey(LocalStorage.TASKS_TYPES_KEY);
};

export const clearLocalStorage = () => {
  const allLocalStorageKeys = Object.values(LocalStorage);
  allLocalStorageKeys.forEach((localStorageKey: string) => {
    localStorage.removeItem(getEnvironmentSpecificStorageKey(localStorageKey));
  });
};
