import { FC, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { actions as usersActions } from '@/api/users';
import * as customerActions from '@payaca/store/customer/customerActions';
import * as dealsActions from '@payaca/store/deals/dealsActions';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as jobsActions from '@payaca/store/jobs/jobsActions';

import EditJobPreviewButton from '@/ui/components/editJobPreviewButton/EditJobPreviewButton';
import EditJobSaveFeedback from '@/ui/components/editJobSaveFeedback/EditJobSaveFeedback';
import EditProtoInvoiceControl from '@/ui/components/editProtoInvoiceControl/EditProtoInvoiceControl';
import PreviewAndSendProtoInvoiceControl from '@/ui/components/previewAndSendProtoInvoiceControl/PreviewAndSendProtoInvoiceControl';
import ProtoInvoiceSidePanel from '@/ui/components/protoInvoiceSidePanel/ProtoInvoiceSidePanel';
import EditProposalLineItemSidebar from '@/ui/pages/newEditQuotePage/components/EditProposalLineItemSidebar';
import Modal from '@payaca/components/modal/Modal';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';
import { useDeal, useProposal } from '@payaca/store/hooks/appState';
import { getUploads } from '@payaca/store/uploads/uploadsActions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import './EditProtoInvoicePage.sass';

export enum ViewType {
  EDIT = 'edit',
  SEND = 'send',
}

type Props = {
  protoInvoiceId: number;
  dealId: number;
  viewType?: ViewType;
};

const EditProtoInvoicePage: FC<Props> = ({
  protoInvoiceId,
  dealId,
  viewType,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [view, setView] = useState<ViewType>(viewType || ViewType.EDIT);
  const [validationResult, setValidationResult] = useState<{
    isValid: boolean;
    errors: string[];
  }>();

  const match = useRouteMatch();
  const isEditProposalLineItemDrawOpen = useRouteMatch<{
    proposalLineItemId: string;
  }>(`${match.url}/items/:proposalLineItemId`);

  const protoInvoice = useProposal(protoInvoiceId);

  const deal = useDeal(protoInvoice?.dealId);

  // can ignore bounced here... this proposal will only ever get marked as sent.
  // Any bounce handling will happen on the sent invoice.

  useEffect(() => {
    if (
      !!protoInvoice?.sentAt ||
      !!protoInvoice?.archivedAt ||
      !!protoInvoice?.inactivatedAt
    ) {
      history.push(`/deals/${dealId}`);
    }
  }, [
    protoInvoice?.sentAt,
    protoInvoice?.archivedAt,
    protoInvoice?.inactivatedAt,
  ]);

  const navigateToView = useMemo(() => {
    return getUrlSearchParam(location.search, 'view');
  }, [location]);

  useEffect(() => {
    if (
      navigateToView === ViewType.SEND &&
      validationResult &&
      validationResult.isValid
    ) {
      setView(ViewType.SEND);
    }
  }, [navigateToView, validationResult]);

  useEffect(() => {
    dispatch(
      jobsActions.requestGetJobValidationResult(
        protoInvoiceId,
        (validationResult) => {
          setValidationResult(validationResult);
        }
      )
    );
    dispatch(usersActions.getBusinessAttachments());

    return () => {
      dispatch(jobsActions.clearJobUpdateResults());
      dispatch(jobContentActions.clearJobLineItemUpdateResults());
      dispatch(jobContentActions.clearJobLineItemGroupUpdateResults());
    };
  }, []);

  useEffect(() => {
    if (protoInvoiceId) {
      dispatch(jobsActions.requestGetJob(protoInvoiceId));
      dispatch(jobsActions.requestGetJobAttachmentsForJob(protoInvoiceId));
    }
  }, [protoInvoiceId]);

  useEffect(() => {
    if (protoInvoice?.jobContentId) {
      dispatch(
        jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
          protoInvoice.jobContentId
        )
      );
    }
  }, [protoInvoice?.jobContentId]);

  useEffect(() => {
    if (protoInvoice?.invoiceConfig?.attachmentUploadIds?.length) {
      dispatch(
        getUploads.request({
          uploadIds: protoInvoice.invoiceConfig.attachmentUploadIds,
        })
      );
    }
  }, [protoInvoice?.invoiceConfig?.attachmentUploadIds?.length]);

  useEffect(() => {
    if (protoInvoice?.dealId) {
      dispatch(dealsActions.requestGetDeal(protoInvoice?.dealId));
    }
  }, [protoInvoice?.dealId]);

  useEffect(() => {
    if (deal?.customerId) {
      dispatch(customerActions.requestGetCustomer(deal?.customerId));
    }
  }, [deal?.customerId]);

  const handleProposalLineItemPersist = () => {
    if (protoInvoice) {
      dispatch(
        jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
          protoInvoice.jobContentId
        )
      );
    }

    if (isEditProposalLineItemDrawOpen?.params.proposalLineItemId !== 'new') {
      dispatch(
        jobContentActions.requestGetJobLineItemAttachmentsForJobLineItem(
          +isEditProposalLineItemDrawOpen!.params.proposalLineItemId
        )
      );
    }

    history.push(match.url);
  };

  return (
    <>
      <AuthenticatedPageWrapper
        className="edit-proto-invoice-page"
        previousPageNavigationConfig={
          protoInvoice
            ? {
                route: `/deals/${dealId}/invoices-and-payments`,
              }
            : undefined
        }
        sidebarContent={
          <div className="edit-proto-invoice-sidebar">
            <EditJobPreviewButton
              jobId={protoInvoiceId}
              proceedToPreview={() => {
                dispatch(jobsActions.requestGetJob(protoInvoiceId));
                setView(ViewType.SEND);
              }}
            />
            <EditJobSaveFeedback jobId={protoInvoiceId} />
            <ProtoInvoiceSidePanel protoInvoiceId={protoInvoiceId} />
          </div>
        }
      >
        {protoInvoice && (
          <>
            <EditProtoInvoiceControl protoInvoiceId={protoInvoiceId} />
          </>
        )}
        <Modal
          className="preview-and-send-proto-invoice-modal"
          isOpen={view === ViewType.SEND}
          size="lg"
          onClose={() => setView(ViewType.EDIT)}
        >
          <PreviewAndSendProtoInvoiceControl protoInvoiceId={protoInvoiceId} />
        </Modal>
      </AuthenticatedPageWrapper>

      <EditProposalLineItemSidebar
        rootPage="invoice"
        isOpen={!!isEditProposalLineItemDrawOpen}
        proposalLineItemId={
          isEditProposalLineItemDrawOpen?.params.proposalLineItemId &&
          isEditProposalLineItemDrawOpen.params.proposalLineItemId !== 'new'
            ? isEditProposalLineItemDrawOpen.params.proposalLineItemId
            : undefined
        }
        onSaveSuccess={handleProposalLineItemPersist}
        onRemoveSuccess={handleProposalLineItemPersist}
        onClose={() => history.push(match.url)}
      />
    </>
  );
};
export default EditProtoInvoicePage;
