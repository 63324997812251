import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/plButton/Button';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import Modal from '@payaca/components/plModal/Modal';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import { createTaxRate, updateTaxRate } from '@payaca/store/tax-rates/actions';
import { TaxRate } from '@payaca/types/taxRateTypes';
import { isNullish } from '@payaca/utilities/guards';
import { Nullish } from '@payaca/utilities/types';

import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';

const noOp = () => {
  // do nothing
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  taxRate?: Nullish<TaxRate>;
};

type FormData = Pick<TaxRate, 'name' | 'percentage' | 'isReverseCharge'> &
  Partial<Pick<TaxRate, 'id'>>;

const initialState: FormData = {
  name: '',
  percentage: 10,
  isReverseCharge: false,
};

const CreateEditTaxRateModal: React.FC<Props> = ({
  isOpen,
  onClose,
  taxRate,
}) => {
  const dispatch = useDispatch();

  const isCreating = isNullish(taxRate);

  const { title } = useMemo(() => {
    return {
      title: `${isCreating ? 'Create' : 'Edit'} Tax Rate`,
    };
  }, [isCreating]);

  const [formData, setFormData] = useState<FormData>(taxRate ?? initialState);
  const [isBusy, setIsBusy] = useState(false);
  const [persistError, setPersistError] = useState<Error | null>(null);

  useEffect(() => {
    setFormData(taxRate ?? initialState);
  }, [taxRate, isOpen]);

  const handleChange = useCallback((fields: Partial<FormData>) => {
    setFormData((prev) => {
      return {
        ...prev,
        ...fields,
      };
    });
    setPersistError(null);
  }, []);

  const handleSave = useMemo(() => {
    const callback = (err?: Error) => {
      setIsBusy(false);
      setPersistError(err ?? null);
      err || onClose();
    };
    return () => {
      setIsBusy(true);
      dispatch(
        isCreating
          ? createTaxRate.request(
              {
                name: formData.name,
                percentage: formData.percentage,
                isReverseCharge: formData.isReverseCharge,
              },
              callback
            )
          : updateTaxRate.request(
              {
                id: formData.id!,
                name: formData.name,
                percentage: formData.percentage,
                isReverseCharge: formData.isReverseCharge,
              },
              callback
            )
      );
    };
  }, [isCreating, formData, onClose]);

  const validationResult = useMemo(() => {
    const result = {
      isValid: true,
      name: {
        isValid: true,
        errors: [] as Array<string>,
      },
      percentage: {
        isValid: true,
        errors: [] as Array<string>,
      },
    };
    if (!formData.name) {
      result.name.errors.push('Name must not be empty');
      result.name.isValid = false;
      result.isValid = false;
    }
    if (formData.percentage === undefined) {
      result.percentage.errors.push('Percentage is required');
      result.percentage.isValid = false;
      result.isValid = false;
    }
    if (formData.percentage < 0 || formData.percentage > 100) {
      result.percentage.errors.push('Percentage must be between 0 and 100');
      result.percentage.isValid = false;
      result.isValid = false;
    }
    return result;
  }, [formData]);

  return (
    <Modal isOpen={isOpen} onClose={isBusy ? noOp : onClose} title={title}>
      <Modal.Body className="flex flex-col gap-2">
        <ValidatedFieldWrapper validationResult={validationResult.name}>
          <BasicField
            name="name"
            isRequired
            label="Name"
            value={formData?.name}
            additionalInputProps={{
              placeholder: 'Custom (10%)',
            }}
            onChange={handleChange}
          />
        </ValidatedFieldWrapper>
        <ValidatedFieldWrapper validationResult={validationResult.percentage}>
          <BasicField
            name="percentage"
            isRequired={true}
            type="number"
            additionalInputProps={{
              pattern: '[0-9]*',
              min: 0,
              max: 100,
              step: 0.001,
            }}
            label="Percentage"
            value={formData?.percentage}
            onChange={({ percentage }: any) =>
              handleChange({
                percentage:
                  typeof percentage === 'string'
                    ? percentage
                      ? Number(Number(percentage).toFixed(3))
                      : undefined
                    : Number(percentage.toFixed(3)),
              })
            }
          />
        </ValidatedFieldWrapper>
        <Checkbox
          label="Reverse charge"
          checked={formData?.isReverseCharge}
          onChange={() =>
            handleChange({ isReverseCharge: !formData.isReverseCharge })
          }
        />
        {persistError && (
          <FeedbackBlock
            isDismissable={true}
            onDismissed={() => setPersistError(null)}
            feedbackLevel={FeedbackLevel.ERROR}
          >
            <span>{persistError.message}</span>
          </FeedbackBlock>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            onClick={handleSave}
            disabled={isBusy || !validationResult.isValid}
          >
            Save
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateEditTaxRateModal;
