import type { UniqueIdentifier } from '@dnd-kit/core';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import type { ReactNode } from 'react';

import SortableItem from './SortableItem';

interface BaseItem {
  id: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
  items: T[];
  onDragEnd(activeItem: any, destinationIndex: number, items: T[]): void;
  renderItem(item: T): ReactNode;
  id: string;
  isDisabled?: boolean;
}

const SortableList = <T extends BaseItem>({
  items,
  onDragEnd,
  renderItem,
  id,
  isDisabled = false,
}: Props<T>) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8, // min distance in px for drag start to emit (if not set the onClick handle is intercepted and never triggered) https://github.com/clauderic/dnd-kit/issues/591
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const { setNodeRef } = useDroppable({ id });

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id); // index of item being moved
          const overIndex = items.findIndex(({ id }) => id === over.id); // index of item active item is replacing

          onDragEnd(
            items[activeIndex],
            overIndex,
            arrayMove(items, activeIndex, overIndex)
          );
        }
      }}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <div ref={setNodeRef}>
          {items.map((item) => (
            <SortableItem key={item.id} id={item.id} isDisabled={isDisabled}>
              {renderItem(item)}
            </SortableItem>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default SortableList;
