import { useEffect } from 'react';

import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';

import { useDispatch } from 'react-redux';
import './ServicePlansHeader.sass';

const ServicePlansHeader = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      servicePlansActions.getServicePlansAnalytics.request({
        callback: (analytics) => {
          // console.log(
          //   '>>>>>>> ~ file: ServicePlansHeader.tsx:17 ~ useEffect ~ analytics:',
          //   analytics
          // );
        },
      })
    );
  }, []);

  return (
    <div className="service-plans-header">
      <h1>Service Plans</h1>
      {/* TODO: SP - subscriptions and mrr graphs */}
      {/* <div className="service-plans-analytics-wrapper">
        <ContentPanel>
          <div>MRR data</div>
        </ContentPanel>

        <ContentPanel>
          <div>Subscriptions data</div>
        </ContentPanel>
      </div> */}
    </div>
  );
};

export default ServicePlansHeader;
