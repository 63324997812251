import { createReducer } from '../utils';
import { ActionType, AnyAction, State } from './dispatchTypes';

export const initialState: State = {};

const dispatchReducer = createReducer<State, ActionType, AnyAction>(
  initialState,
  {}
);

export default dispatchReducer;
