import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  DeleteSupplierMaterialInput,
  DeleteSupplierMaterialMutation,
} from '@/gql/graphql';

const DELETE_SUPPLIER_MATERIAL = graphql(`
  mutation DeleteSupplierMaterial($input: DeleteSupplierMaterialInput!) {
    deleteSupplierMaterial(input: $input)
  }
`);

const useDeleteSupplierMaterial = (
  options?: UseMutationOptions<
    DeleteSupplierMaterialMutation,
    unknown,
    DeleteSupplierMaterialInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(DELETE_SUPPLIER_MATERIAL, { input });
    },
    ...options,
  });
};

export default useDeleteSupplierMaterial;
