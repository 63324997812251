import '../slateEditor/types';

import { FC, createElement } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import { useIsMarkActive, useToggleFormat } from '../slateEditor/hooks';
import { FORMATS } from './RichTextEditorField';

export interface IProps {}

const Toolbar: FC<IProps> = () => {
  const editor = useSlate();
  const toggleFormat = useToggleFormat();
  const isMarkActive = useIsMarkActive();

  const formats = FORMATS.filter((format) => !!format.toolbarRender);

  if (formats.length === 0) {
    return null;
  }

  return (
    <ul className="m-0 flex list-none gap-x-1 p-0 pb-[0.75rem]">
      {formats.map((format) => (
        <li key={format.type}>
          <button
            className={
              'h-4 w-4 cursor-pointer p-0 ' +
              (isMarkActive(format.type)
                ? 'bg-blue-light-mid'
                : 'bg-transparent')
            }
            type="button"
            onClick={() => {
              toggleFormat(format.type);
              ReactEditor.focus(editor);
            }}
          >
            {createElement(format.toolbarRender!)}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Toolbar;
