import { Currency, CurrencyCodes } from '@payaca/types/currencyTypes';
import { TIconName } from '@payaca/untitled-icons';
import { checkUnreachable, isNullish } from '@payaca/utilities/guards';
import { FC, useEffect, useState } from 'react';
import InputGroup from '../plInputGroup/InputGroup';
import { clstx } from '../utils';
import { TProps as IInputProps, InputIcon } from './Input';

export interface ICurrencyInputProps
  extends Omit<IInputProps, 'value' | 'onChange'> {
  value: number;
  currency?: {
    code: Currency['code'];
  };
  onChange?: (value: number) => void;
  allowPennies?: boolean;
}

const CurrencySymbol: FC<Pick<ICurrencyInputProps, 'currency'>> = (props) => {
  const { currency } = props;

  if (!('currency' in props)) {
    console.error("CurrencyInput: 'currency' prop is required");
  }

  if (!currency) {
    return null;
  }

  const code = currency.code as keyof typeof CurrencyCodes;

  let iconName: TIconName;
  switch (code) {
    case 'GBP':
      iconName = 'currency-pound';
      break;
    case 'USD':
    case 'AUD':
    case 'CAD':
    case 'NZD':
      iconName = 'currency-dollar';
      break;
    case 'ZAR':
      return <span className="w-4 text-center text-base font-semibold">R</span>;
    default:
      checkUnreachable(code);
      return null;
  }

  return <InputIcon name={iconName} />;
};

const CurrencyInput: FC<ICurrencyInputProps> = (props) => {
  const {
    value,
    onChange,
    onTouch,
    allowPennies = true,
    currency,
    onBlur,
    onFocus,
    sizeVariant,
    inputClassName,
    ...rest
  } = props;
  const [formattedValue, setFormattedValue] = useState<any>();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!isFocused) {
      // set formatted value when no focus
      const initialValue = isNullish(value)
        ? value
        : Number(value / 100).toFixed(allowPennies ? 2 : 0);
      setFormattedValue(initialValue);
    }
  }, [isFocused, value]);

  return (
    <InputGroup sizeVariant={sizeVariant}>
      <InputGroup.Text>
        <CurrencySymbol currency={currency} />
      </InputGroup.Text>

      <InputGroup.Input
        type="number"
        step=".01"
        pattern="^[+-]?[0-9]*"
        value={formattedValue}
        onWheel={(e) => e.currentTarget.blur()}
        inputClassName={clstx('pl-form-input--number', inputClassName)}
        onChange={(update) => {
          onChange?.(Math.round(Number(update) * 100));
          setFormattedValue(update || '');
        }}
        onFocus={(e) => {
          setIsFocused(true);
          // clear input to be able to type straight away
          if (formattedValue === '0.00') {
            setFormattedValue('');
          }
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          onBlur?.(e);
        }}
        {...rest}
      />
    </InputGroup>
  );
};

export default CurrencyInput;
