import clsx from 'clsx';
import { nanoid } from 'nanoid';
import React, {
  ComponentPropsWithoutRef,
  FC,
  PropsWithChildren,
  useRef,
} from 'react';
import Card from '../plCard/Card';

type OwnInputProps = 'id' | 'type';

export interface IProps
  extends Omit<ComponentPropsWithoutRef<'input'>, OwnInputProps> {
  multiple?: boolean;
  src?: string;
  label?: string;
}

const ImageGroupFieldInput = React.forwardRef<HTMLInputElement, IProps>(
  (props, ref) => {
    const { className, label, src, multiple = false, ...rest } = props;
    const { current: uId } = useRef(nanoid());

    return (
      <Card
        as="label"
        htmlFor={uId}
        className="group cursor-pointer overflow-hidden has-[:checked]:ring-2 has-[:checked]:ring-blue-600"
      >
        <input
          ref={ref}
          type={multiple ? 'checkbox' : 'radio'}
          className={clsx('hidden', className)}
          id={uId}
          {...rest}
        />
        <img src={src} alt={label} />
        <span className="mt-auto px-2 py-3 font-semibold group-hover:bg-gray-50">
          {label}
        </span>
      </Card>
    );
  }
);

ImageGroupFieldInput.displayName = 'ImageGroupFieldInput';

const ImageGroupField: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <div className="grid grid-cols-2 gap-2 sm:grid-cols-4 sm:gap-4">
      {children}
    </div>
  );
};

export default Object.assign(ImageGroupFieldInput, { Group: ImageGroupField });
