import { FunctionComponent, useMemo } from 'react';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import { BrandingColourContext } from '@payaca/types/accountBrandTypes';
import { Account } from '@payaca/types/accountTypes';
import { Deal } from '@payaca/types/dealTypes';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import { JobLineItemAttachment } from '@payaca/types/jobTypes';
import DocumentItem, { TVariant } from '../clientDocument/DocumentItem';
import './JobDocumentItem.sass';

type Props = {
  account: Pick<Account, 'region'>;
  hideItemPrices: boolean;
  hideVat: boolean;
  isEditable: boolean;
  isProposition?: boolean;
  item: JobLineItem;
  attachments: JobLineItemAttachment[];
  onItemClick: (item: JobLineItem) => void;
  selectedMultipleChoiceItem?: JobLineItem | null;
  brandingColourContext?: BrandingColourContext;
  dealVersion: Deal['version'];
  isAnyVatIncluded?: boolean;
};

const JobDocumentItem: FunctionComponent<Props> = ({
  account,
  hideItemPrices,
  hideVat,
  item,
  isEditable,
  isProposition = false,
  attachments,
  onItemClick,
  selectedMultipleChoiceItem,
  brandingColourContext,
  dealVersion,
  isAnyVatIncluded = true,
}: Props): JSX.Element => {
  const totalExcVat = useMemo(() => {
    return item.subtotalIncMarkupDiscount;
  }, [item]);

  const totalIncVat = useMemo(() => {
    return totalExcVat + item.vatTotal;
  }, [totalExcVat, item]);

  const costDifference = useMemo(() => {
    // must be quote and optional/multiple choice selected item
    if (isProposition) {
      if (item.isOptional && !item.isSelected) {
        return `${currencyPrice(totalIncVat, account?.region)}`;
      } else if (
        item.isMultipleChoice &&
        !item.isSelected &&
        selectedMultipleChoiceItem
      ) {
        // get cost of selected multiple choice item
        const selectedItemTotalExcVat =
          selectedMultipleChoiceItem.subtotalIncMarkupDiscount;
        const selectedItemTotalIncVat =
          selectedItemTotalExcVat + selectedMultipleChoiceItem.vatTotal;

        // difference between item selected multiple choice item
        const difference = totalIncVat - selectedItemTotalIncVat;
        if (difference > 0) {
          return currencyPrice(Math.abs(difference), account?.region);
        }
      }
    }
    return null;
  }, [isProposition, item, selectedMultipleChoiceItem, totalIncVat]);

  const isSelectedNormalised = useMemo(() => {
    if (!item.isMultipleChoice && !item.isOptional) return true;

    return item.isSelected;
  }, [item]);

  const itemVariant = useMemo<TVariant>(() => {
    if (item.isOptional) return 'optional';

    if (item.isMultipleChoice) return 'multipleChoice';

    return 'included';
  }, [item]);

  return (
    <DocumentItem
      tooltipMessage={
        costDifference
          ? `Select this item for only ${costDifference} more`
          : undefined
      }
      variant={itemVariant}
      region={account.region}
      hideItemPrices={hideItemPrices}
      hideVat={hideVat || !isAnyVatIncluded}
      isSelected={isSelectedNormalised}
      isEditable={isEditable}
      isProposition={isProposition}
      item={item}
      attachments={attachments}
      onChange={() => onItemClick(item)}
    />
  );
};

export default JobDocumentItem;
