import { formatPhoneNumber } from '@payaca/helpers/phoneNumberHelper';
import { BrandingColourContext } from '@payaca/types/accountBrandTypes';
import { AccountRegions } from '@payaca/types/accountTypes';
import { Customer, CustomerContact } from '@payaca/types/customerTypes';
import { DealAddress } from '@payaca/types/dealTypes';
import { JobAttachment, JobType } from '@payaca/types/jobTypes';
import { Address as AddressType } from '@payaca/types/locationTypes';
import { PaymentSchedule } from '@payaca/types/payment-schedule';
import { BadgeColourVariant } from '@payaca/types/plBadge';
import UntitledIcon from '@payaca/untitled-icons';
import { downloadFile, downloadPdfData } from '@payaca/utilities/fileUtilities';
import {
  FC,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import Address from '../address/Address';
import FieldLabel from '../fieldLabel/FieldLabel';
import JobDocumentAttachmentTable from '../jobDocument/JobDocumentAttachmentTable';
import MarkdownLabel from '../markdownLabel/MarkdownLabel';
import PaymentScheduleSummary from '../paymentScheduleSummary/PaymentScheduleSummary';
import Badge from '../plBadge/Badge';
import Breadcrumb, { TBreadcrumbItem } from '../plBreadcrumb/Breadcrumb';
import AnchorButton from '../plButton/AnchorButton';
import Button from '../plButton/Button';
import { EBtnColour, EBtnVariant } from '../plButton/useButtonClassName';
import Card from '../plCard/Card';
import DocumentCard from './DocumentCard';

export interface IProps {
  SidebarContent: ReactElement;
  breadcrumbItems?: TBreadcrumbItem[];
  jobType?: JobType | 'Change Proposal';
  documentBadge?: {
    label: string;
    colour?: BadgeColourVariant;
  };
  pdfFileName?: string;
  pdfData?: string;
  pdfUrl?: string;
  pdfGenerator?: () => Promise<string>;
  brandingColourContext?: BrandingColourContext;
  logoUrl?: string;
  jobDescription?: string;
  jobAttachments?: JobAttachment[];
  siteAddresses?: DealAddress[];
  billingAddress?: AddressType;
  companyName?: string;
  customer?: Customer;
  contact?: CustomerContact;
  keyInformation?: { label: string; value: string }[];
  notes?: string;
  paymentTerms?: string;
  paymentSchedule?: PaymentSchedule;
  region?: AccountRegions;
  jobContentTotal?: number;
  businessTerms?: string;
  contactSection?: {
    email?: string;
    phoneNumber?: string;
    registeredCountryName?: string;
    companyRegistrationNumber?: string;
    vatNumber?: string;
    vatRegNoRegionalLabel?: string;
  };
  documentOriginAddressPart1?: string;
  documentOriginAddressPart2?: string;
  hasMaxWidth?: boolean;
  stickyTopClassName?: string;
}

const ClientDocument: FC<PropsWithChildren<IProps>> = (props) => {
  const {
    jobType,
    breadcrumbItems,
    documentBadge,
    pdfData,
    pdfUrl,
    pdfGenerator,
    pdfFileName,
    SidebarContent,
    brandingColourContext,
    children,
    logoUrl,
    jobDescription,
    jobAttachments = [],
    customer,
    contact,
    companyName,
    siteAddresses,
    billingAddress,
    keyInformation,
    notes,
    paymentTerms,
    region,
    jobContentTotal,
    paymentSchedule,
    businessTerms,
    contactSection,
    documentOriginAddressPart1,
    documentOriginAddressPart2,
    hasMaxWidth = true,
    stickyTopClassName = 'top-4',
  } = props;

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  useEffect(() => {
    if (!brandingColourContext) return;

    document.body.style.setProperty(
      '--job-context-brand-colour',
      brandingColourContext.brandColour || null
    );
    document.body.style.setProperty(
      '--job-context-lighter-brand-colour',
      brandingColourContext.lightBrandColour || null
    );
    document.body.style.setProperty(
      '--job-context-darker-brand-colour',
      brandingColourContext.darkerBrandColour || null
    );
    document.body.style.setProperty(
      '--job-context-readable-text-brand-colour',
      brandingColourContext.brandReadableTextColour || null
    );
    document.body.style.setProperty(
      '--job-context-brand-colour-l10',
      brandingColourContext.brandColourL10 || null
    );
    document.body.style.setProperty(
      '--job-context-brand-colour-l95',
      brandingColourContext.brandColourL95 || null
    );

    return () => {
      document.body.style.removeProperty('--job-context-brand-colour');
      document.body.style.removeProperty('--job-context-lighter-brand-colour');
      document.body.style.removeProperty('--job-context-darker-brand-colour');
      document.body.style.removeProperty(
        '--job-context-readable-text-brand-colour'
      );
      document.body.style.removeProperty('--job-context-brand-colour-l10');
      document.body.style.removeProperty('--job-context-brand-colour-l95');
    };
  }, [brandingColourContext]);

  return (
    <div
      className={
        '@container flex flex-col antialiased print:block print:p-0' +
        (hasMaxWidth ? ' p-4' : '')
      }
    >
      {(pdfData || pdfUrl || pdfGenerator || breadcrumbItems) && (
        <div className="@screen-md:grid-cols-5 @screen-lg:grid-cols-3 mx-auto mb-2 grid w-full max-w-[1200px] grid-cols-1 gap-8 print:hidden">
          <div className="@screen-md:col-span-3 @screen-lg:col-span-2 flex flex-wrap items-center justify-between gap-8">
            {!!breadcrumbItems?.length && (
              <Breadcrumb className="py-3" items={breadcrumbItems} />
            )}

            {pdfUrl ? (
              <span className="ml-auto">
                <AnchorButton
                  href={pdfUrl}
                  download={pdfFileName || `${jobType}.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                  colour={EBtnColour.JobBrand}
                  variant={EBtnVariant.Link}
                >
                  <UntitledIcon className="h-4 w-4" name="download-01.3" />{' '}
                  Download as PDF
                </AnchorButton>
              </span>
            ) : pdfData || pdfGenerator ? (
              <span className="ml-auto">
                <Button
                  onClick={() => {
                    if (pdfData) {
                      downloadPdfData(pdfData, pdfFileName || `${jobType}.pdf`);
                    } else if (pdfGenerator) {
                      setIsGeneratingPDF(true);
                      pdfGenerator().then((pdfUrl) => {
                        setIsGeneratingPDF(false);
                        downloadFile(pdfUrl, pdfFileName || `${jobType}.pdf`);
                      });
                    }
                  }}
                  isProcessing={isGeneratingPDF}
                  colour={EBtnColour.JobBrand}
                  variant={EBtnVariant.Link}
                >
                  <UntitledIcon className="h-4 w-4" name="download-01.3" />{' '}
                  Download as PDF
                </Button>
              </span>
            ) : null}
          </div>
        </div>
      )}

      <div
        className={
          '@screen-md:grid-cols-5 @screen-lg:grid-cols-3 mx-auto grid w-full grid-cols-1 gap-8 print:block' +
          (hasMaxWidth ? ' max-w-[1200px]' : '')
        }
      >
        <div className="@screen-md:col-span-3 @screen-lg:col-span-2 flex flex-col gap-8 print:mb-8 print:block">
          {/*Intro Card*/}
          <DocumentCard>
            <div className="flex justify-between">
              {logoUrl && (
                // Only display on print documents
                <div className="mb-2 hidden print:block">
                  <img
                    className="h-full max-h-[60px] w-auto"
                    src={logoUrl}
                    alt="company-logo"
                  />
                </div>
              )}
              <div className="ml-auto">
                <h1 className="m-0 mb-3 flex items-center justify-end gap-2 text-right text-2xl font-bold uppercase">
                  {jobType}
                  {documentBadge && (
                    <Badge variant="soft" colour={documentBadge.colour}>
                      {documentBadge.label}
                    </Badge>
                  )}
                </h1>
                {companyName && (
                  <h2 className="mb-0 text-right text-base font-normal">
                    {companyName}
                  </h2>
                )}
                {documentOriginAddressPart1 && (
                  <p className="m-0 text-right text-base font-normal">
                    {documentOriginAddressPart1}
                  </p>
                )}
                {documentOriginAddressPart2 && (
                  <p className="m-0 text-right text-base font-normal">
                    {documentOriginAddressPart2}
                  </p>
                )}
              </div>
            </div>

            <hr className="hr my-4" />

            <div className="@md:flex-row flex flex-col flex-wrap items-start justify-between">
              <div className="flex-[1_1_0px]">
                {customer && (
                  <h3 className="mb-0 text-base font-bold">{customer.name}</h3>
                )}

                {billingAddress && (
                  <p className="mb-0.5 text-base">
                    <Address address={billingAddress} />
                  </p>
                )}

                {contact && (
                  <>
                    <p className="mb-1 text-base">{contact?.emailAddress}</p>
                    <p className="m-0 text-base">{contact?.telephoneNumber}</p>
                  </>
                )}
              </div>

              {!!keyInformation?.length && (
                <table className="@md:text-right @md:ml-auto @md:mt-0 @md:order-1 @md:border-separate order-last mt-2 border-collapse text-base">
                  <tbody>
                    {keyInformation.map(({ label, value }, index) => (
                      <tr key={index}>
                        <td className="p-0 pr-4">
                          <b className="whitespace-nowrap">{label}</b>:
                        </td>
                        <td className="whitespace-nowrap p-0">{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              <div className="@md:order-2 flex-[0_0_100%]">
                {!!siteAddresses?.length && (
                  <>
                    {siteAddresses.length === 1 ? (
                      <p className="mb-0 mt-1 text-base">
                        <span className="font-semibold">Site address:</span>{' '}
                        <Address address={siteAddresses[0].address} />
                      </p>
                    ) : (
                      <div className="site-addresses">
                        <p className="mb-1 text-base font-semibold">
                          Site addresses:
                        </p>
                        {siteAddresses.map((x, i) => {
                          return (
                            <p key={i} className="m-0 text-base">
                              <Address address={x.address} />
                            </p>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            {jobDescription && (
              <>
                <hr className="hr my-4" />
                <MarkdownLabel
                  className="markdown-font-size-reset"
                  markdown={jobDescription}
                />
              </>
            )}
          </DocumentCard>

          {children}

          <div className="@screen-md:hidden block">{SidebarContent}</div>

          {!!jobAttachments.length && (
            <DocumentCard icon="file-02.3" title="Attachments">
              <JobDocumentAttachmentTable jobAttachments={jobAttachments} />
            </DocumentCard>
          )}
          {!!notes?.length && (
            <DocumentCard icon="file-02.3" title="Notes">
              <MarkdownLabel
                className="markdown-font-size-reset"
                markdown={notes}
              />
            </DocumentCard>
          )}
          {(!!paymentTerms?.length || paymentSchedule) && (
            <DocumentCard icon="file-02.3" title="Payment terms">
              {paymentTerms && (
                <MarkdownLabel
                  className="markdown-font-size-reset"
                  markdown={paymentTerms}
                />
              )}
              {paymentSchedule && (
                <div className="mt-4">
                  <FieldLabel label="Payment schedule" />
                  <Card>
                    <PaymentScheduleSummary
                      paymentSchedule={paymentSchedule}
                      projectValue={jobContentTotal}
                      region={region}
                    />
                  </Card>
                </div>
              )}
            </DocumentCard>
          )}
          {!!businessTerms?.length && (
            <DocumentCard icon="file-02.3" title="Terms of business">
              <MarkdownLabel
                className="markdown-font-size-reset"
                markdown={businessTerms}
              />
            </DocumentCard>
          )}
          {contactSection && (
            <DocumentCard icon="file-02.3" title="Contact us">
              <div className="@screen-sm:flex-row @screen-md:flex-col @screen-lg:flex-row flex flex-col justify-between gap-x-7 gap-y-4 print:block">
                <div className="empty:hidden">
                  {contactSection.email && (
                    <p className="mb-1 text-base">{contactSection.email}</p>
                  )}
                  {contactSection.phoneNumber && (
                    <p className="m-0 text-base">
                      {formatPhoneNumber(contactSection.phoneNumber)}
                    </p>
                  )}
                </div>

                <div className="empty:hidden">
                  {!!contactSection.registeredCountryName && (
                    <p className="mb-1 text-base">
                      Registered in {contactSection.registeredCountryName}
                    </p>
                  )}
                  {!!contactSection.companyRegistrationNumber && (
                    <p className="mb-1 text-base">
                      Company registration no:{' '}
                      {contactSection.companyRegistrationNumber}
                    </p>
                  )}
                  {!!contactSection.vatNumber && (
                    <p className="mb-0 text-base">
                      {contactSection.vatRegNoRegionalLabel}:{' '}
                      {contactSection.vatNumber}
                    </p>
                  )}
                </div>
              </div>
            </DocumentCard>
          )}
        </div>
        <div className="@screen-md:block @screen-md:col-span-2 @screen-lg:col-span-1 hidden print:hidden">
          <aside
            className={
              'job-document-sticky-sidebar sticky' +
              (stickyTopClassName ? ` ${stickyTopClassName}` : '')
            }
          >
            {SidebarContent}
          </aside>
        </div>
      </div>
    </div>
  );
};

export default ClientDocument;
