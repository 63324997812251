import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import { FC } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

import './ColourPickerField.sass';

type Props = {
  colour?: string;
  description?: string;
  label?: string;
  isRequired?: boolean;
  onColourChange: (colour: ColorResult) => void;
};
const ColourPickerField: FC<Props> = ({
  colour = '#ffffff',
  description,
  isRequired = false,
  label,
  onColourChange,
}: Props): JSX.Element => {
  return (
    <div className="colour-picker-field">
      {label && (
        <FieldLabel
          label={label}
          isRequired={isRequired}
          description={description}
        />
      )}
      <div className="flex-container flex-contigious colour-picker-wrapper">
        <SketchPicker
          color={colour}
          onChangeComplete={onColourChange}
          presetColors={[]}
          disableAlpha={true}
        />
      </div>
    </div>
  );
};

export default ColourPickerField;
