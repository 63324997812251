import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import useUnlinkSiteAddressFromProject from '@/api/mutations/project/useUnlinkSiteAddressFromProject';
import complianceKeys from '@/api/queries/compliance/keyFactory';
import projectKeys from '@/api/queries/project/keyFactory';
import {
  ProjectQuery,
  UnlinkSiteAddressFromProjectInput,
  UnlinkSiteAddressFromProjectMutation,
} from '@/gql/graphql';

const useOptimisticUnlinkSiteAddressToProject = (
  projectId: number,
  options?: UseMutationOptions<
    UnlinkSiteAddressFromProjectMutation,
    unknown,
    UnlinkSiteAddressFromProjectInput
  >
) => {
  const queryClient = useQueryClient();

  return useUnlinkSiteAddressFromProject({
    ...options,
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: projectKeys.overview(projectId),
      });

      // Snapshot the previous value
      const previousProjectOverview = queryClient.getQueryData(
        projectKeys.overview(projectId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<ProjectQuery>(
        projectKeys.overview(projectId),
        (old) => {
          if (!old) return;

          return {
            ...old,
            project: {
              ...old.project,
              addresses: {
                ...old.project.addresses,
                site: old.project.addresses.site.filter(
                  (site) => site.id !== variables?.addressId
                ),
              },
            },
          };
        }
      );

      options?.onMutate?.(variables);

      // Return a context object with the snapshotted value
      return { previousProjectOverview };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        projectKeys.overview(projectId),
        // @ts-ignore
        context.previousProjectOverview
      );

      options?.onError?.(err, newTodo, context);
    },
    // Always refetch after error or success:
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.overview(projectId),
      });
      queryClient.invalidateQueries({
        queryKey: complianceKeys.formPrefill(projectId),
      });

      options?.onSettled?.(...args);
    },
  });
};

export default useOptimisticUnlinkSiteAddressToProject;
