import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import EditJobPage from '@/ui/pages/editJobPage/EditJobPage';

import { gqlClient } from '@/api/graphql-client';
import { actions as jobActions } from '@/api/jobs';
import { graphql } from '@/gql';
import { CreateProposalInput } from '@/gql/graphql';
import { useUserHasPermission } from '@/hooks/usePermissions';
import TemplateSelectionControl from '@/ui/components/templateSelectionControl/TemplateSelectionControl';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import LoaderOverlay from '@payaca/components/loaderOverlay/LoaderOverlay';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Modal from '@payaca/components/plModal/Modal';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';
import { TemplatesPermissions } from '@payaca/permissions/templates/templates.permissions';
import { getListedTemplatesPage } from '@payaca/store/templates/templatesActions';
import {
  PublicEntityTemplate,
  PublicHydratedEntityTemplate,
} from '@payaca/types/entity-templates';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { Job } from '@payaca/types/jobTypesV2';
import { SortBy } from '@payaca/types/listedTemplateTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

const CreateProposalMutation = graphql(`
  mutation CreateProposalMutation($input: CreateProposalInput!) {
    createProposal(input: $input) {
      id
    }
  }
`);

type Props = {
  match: any;
  location: any;
};

const NewEditQuotePage: FC<Props> = ({ match }: Props): JSX.Element => {
  const { mutateAsync: mutateCreateProposal } = useMutation({
    mutationFn: (input: CreateProposalInput) => {
      return gqlClient.request(CreateProposalMutation, { input });
    },
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const quoteId = useMemo(() => match?.params?.quoteId, [match]);
  const dealId = useMemo(() => {
    return getUrlSearchParam(location.search, 'dealId');
  }, [location]);
  const [isInitialising, setIsInitialising] = useState(!quoteId);
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const [hasAnyTemplates, setHasAnyTemplates] = useState(false);

  const hasUseProposalTemplatesPermission = useUserHasPermission({
    permissions: [TemplatesPermissions.USE_PROPOSAL_TEMPLATES],
  });

  const goToProposal = useCallback(
    (proposalId: Job['id']) => {
      history.replace({
        pathname: `/quotes/${proposalId}`,
        search: dealId ? `?dealId=${dealId}` : '',
      });
    },
    [dealId, history]
  );

  const raiseErrorFeedback = useCallback(() => {
    showDynamicFeedbackMessage({
      title: 'Something went wrong',
      body: 'Please click the chat button and let us know about it.',
      feedbackLevel: FeedbackLevel.ERROR,
      isCancellable: true,
      lifespanMs: DynamicFeedbackLifespanMs.SHORT,
    });
  }, []);

  const createJobWithoutTemplate = useCallback(() => {
    setIsInitialising(true);
    dispatch(
      jobActions.createJob(
        true,
        (err: any, job: any) => {
          setIsInitialising(false);
          if (job) {
            goToProposal(job.id);
          } else {
            raiseErrorFeedback();
          }
        },
        dealId as any
      )
    );
  }, [dealId, raiseErrorFeedback]);

  const createJobWithTemplate = useCallback(
    async (template: PublicEntityTemplate<'proposal'>) => {
      if (!dealId) return;

      setIsInitialising(true);
      await mutateCreateProposal({
        projectId: dealId,
        templatePublicId: template.publicId,
      })
        .then((res) => {
          const proposalId = res.createProposal.id;
          goToProposal(+proposalId);
        })
        .catch((err) => {
          setIsInitialising(false);
          raiseErrorFeedback();
        });
    },
    [dealId, raiseErrorFeedback]
  );

  useEffect(() => {
    if (quoteId) {
      return;
    }

    if (!hasUseProposalTemplatesPermission) {
      createJobWithoutTemplate();
    }

    dispatch(
      getListedTemplatesPage.request({
        queryParams: {
          pageSize: 1,
          pageNumber: 1,
          entityTypes: ['proposal'],
          sortBy: SortBy.NAME,
          sortDirection: SortDirection.ASCENDING,
        },
        callback: (page) => {
          const hasTemplates = !!page.items.length;
          setHasAnyTemplates(hasTemplates);

          if (!hasTemplates) {
            createJobWithoutTemplate();
          }
        },
      })
    );
  }, [quoteId, createJobWithoutTemplate]);

  // and has template permission...
  if (hasAnyTemplates && !quoteId) {
    return (
      <AuthenticatedPageWrapper>
        <TemplateSelectionModal
          onApplyTemplate={(template) => {
            if (template) {
              void createJobWithTemplate(template);
            } else {
              createJobWithoutTemplate();
            }
          }}
        />
      </AuthenticatedPageWrapper>
    );
  }

  if (isInitialising) {
    return <LoaderOverlay />;
  }

  return (
    <>
      <EditJobPage
        jobId={+quoteId || 0}
        sendJobToCustomer={(
          id: number,
          payload: any,
          cb: (err: any, response: any) => void
        ) => dispatch(jobActions.sendQuoteToCustomer(id, payload, cb))}
      />
    </>
  );
};

export default NewEditQuotePage;

const TemplateSelectionModal: FC<{
  onApplyTemplate: (
    selectedTemplate?: PublicHydratedEntityTemplate<'proposal'>
  ) => void;
}> = ({ onApplyTemplate }) => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<PublicHydratedEntityTemplate<'proposal'>>();

  return (
    <Modal isOpen={true} onClose={() => null} title="Create a proposal">
      <Modal.Body>
        <TemplateSelectionControl<'proposal'>
          selectedTemplate={selectedTemplate}
          label="Choose a template"
          onChange={setSelectedTemplate}
          allowedEntityTypes={['proposal']}
        />
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            variant={EBtnVariant.Outline}
            onClick={() => onApplyTemplate()}
          >
            Start from scratch
          </Button>
          <Button
            disabled={!selectedTemplate}
            onClick={() => {
              onApplyTemplate(selectedTemplate);
            }}
          >
            Apply template
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};
