import { FC } from 'react';

import { Contact } from '@payaca/types/contactTypes';
import { Call, Message, Password, User } from 'react-iconly';

import './ContactDetailInfo.sass';

type Props = {
  contact: Partial<Contact>;
};
const ContactDetailInfo: FC<Props> = ({ contact }: Props) => {
  return (
    <div className="contact-detail-info">
      {contact?.name && (
        <div className="contact-name-wrapper">
          <User set="light" size="small" />
          <span className="contact-detail-name">{contact?.name}</span>
        </div>
      )}
      {contact?.emailAddress && (
        <a href={`mailto:${contact?.emailAddress}`} className="contact-method">
          <Message set="light" size="small" />
          <span>{contact?.emailAddress}</span>
        </a>
      )}
      {contact?.telephoneNumber && (
        <a href={`tel:${contact?.telephoneNumber}`} className="contact-method">
          <Call set="light" size="small" />
          <span>{contact?.telephoneNumber}</span>
        </a>
      )}
      {contact?.description && (
        <div className="contact-notes">
          <Password set="light" size="small" />
          <span>{contact.description}</span>
        </div>
      )}
    </div>
  );
};

export default ContactDetailInfo;
