import { action } from 'typesafe-actions';

import {
  MaterialPurchaseIntent,
  MaterialPurchaseRecord,
  MaterialsList,
  MaterialsListMaterial,
  PurchaseOrder,
} from '@payaca/types/materialsListTypes';

import { createAsyncAction } from '../utils';

import {
  ActionType,
  ClearMaterialsList,
  ConfirmPurchaseOrderRequestData,
  CreateIsolatedPurchaseOrder,
  GetHydratedPurchaseOrder,
  GetListedPurchaseOrdersPage,
  PersistMaterialPurchaseRecordRequestData,
  PersistMaterialsListMaterialRequestData,
  PersistPurchaseOrderRequestData,
  SendPurchaseOrderRequestData,
  PopulateDealMaterialsListFromDeal as SyncAutopopulatedMaterialsListMaterialsFromDeal,
  UpdateIsolatedPurchaseOrder,
} from './materialsListTypes';

export const getHydratedPurchaseOrder =
  createAsyncAction<GetHydratedPurchaseOrder>({
    request: ActionType.GET_HYDRATED_PURCHASE_ORDER_REQUEST,
  })((payload: GetHydratedPurchaseOrder['request']['payload']) => ({
    payload,
  }));

export const createIsolatedPurchaseOrder =
  createAsyncAction<CreateIsolatedPurchaseOrder>({
    request: ActionType.CREATE_ISOLATED_PURCHASE_ORDER_REQUEST,
  })((payload: CreateIsolatedPurchaseOrder['request']['payload']) => ({
    payload,
  }));

export const updateIsolatedPurchaseOrder =
  createAsyncAction<UpdateIsolatedPurchaseOrder>({
    request: ActionType.UPDATE_ISOLATED_PURCHASE_ORDER_REQUEST,
  })((payload: UpdateIsolatedPurchaseOrder['request']['payload']) => ({
    payload,
  }));

export const getListedPurchaseOrdersPage =
  createAsyncAction<GetListedPurchaseOrdersPage>({
    request: ActionType.GET_LISTED_PURCHASE_ORDERS_PAGE_REQUEST,
  })((payload: GetListedPurchaseOrdersPage['request']['payload']) => ({
    payload,
  }));

export const syncAutopopulatedMaterialsListMaterialsFromDeal =
  createAsyncAction<SyncAutopopulatedMaterialsListMaterialsFromDeal>({
    request:
      ActionType.REQUEST_SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL,
    success:
      ActionType.SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL_SUCCESS,
    failure:
      ActionType.SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL_FAILURE,
  })(
    (
      payload: SyncAutopopulatedMaterialsListMaterialsFromDeal['request']['payload']
    ) => ({
      payload,
    })
  );

export const clearMaterialsList = createAsyncAction<ClearMaterialsList>({
  request: ActionType.CLEAR_MATERIALS_LIST_REQUEST,
  success: ActionType.CLEAR_MATERIALS_LIST_SUCCESS,
  failure: ActionType.CLEAR_MATERIALS_LIST_FAILURE,
})((payload: ClearMaterialsList['request']['payload']) => ({
  payload,
}));

export const requestGetMaterialPurchaseRecordsForMaterialsList = (
  materialsListId: number
) =>
  action(ActionType.REQUEST_GET_MATERIAL_PURCHASE_RECORDS_FOR_MATERIALS_LIST, {
    materialsListId,
  });

export const requestGetMaterialPurchaseRecord = (
  materialPurchaseRecordId: number
) =>
  action(ActionType.REQUEST_GET_MATERIAL_PURCHASE_RECORD, {
    materialPurchaseRecordId,
  });

export const getMaterialPurchaseRecordSuccess = (
  materialPurchaseRecordId: number,
  materialPurchaseRecord: MaterialPurchaseRecord
) =>
  action(ActionType.GET_MATERIAL_PURCHASE_RECORD_SUCCESS, {
    materialPurchaseRecordId,
    materialPurchaseRecord,
  });

export const getMaterialPurchaseRecordFailure = (
  materialPurchaseRecordId: number,
  error: Error
) =>
  action(
    ActionType.GET_MATERIAL_PURCHASE_RECORD_FAILURE,
    {
      materialPurchaseRecordId,
    },
    null,
    error
  );

export const requestGetMaterialsListMaterialsForMaterialsList = (
  materialsListId: number
) =>
  action(ActionType.REQUEST_GET_MATERIALS_LIST_MATERIALS_FOR_MATERIALS_LIST, {
    materialsListId,
  });

export const getMaterialsListMaterialsForMaterialsListSuccess = (
  materialsListMaterials: Array<MaterialsListMaterial>
) =>
  action(
    ActionType.GET_MATERIALS_LIST_MATERIALS_FOR_MATERIALS_LIST_SUCCESS,
    materialsListMaterials
  );

export const requestGetMaterialsListMaterial = (
  materialsListMaterialId: number
) =>
  action(ActionType.REQUEST_GET_MATERIALS_LIST_MATERIAL, {
    materialsListMaterialId,
  });

export const requestGetMaterialsListMaterialWithRelatedEntities = (
  materialsListMaterialId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_GET_MATERIALS_LIST_MATERIAL_WITH_RELATED_ENTITIES, {
    materialsListMaterialId,
    callback,
  });

export const getMaterialsListMaterialWithRelatedEntitiesSuccess = (
  response: any
) =>
  action(
    ActionType.GET_MATERIALS_LIST_MATERIAL_WITH_RELATED_ENTITIES_SUCCESS,
    response
  );

export const getMaterialsListMaterialSuccess = (
  materialsListMaterialId: number,
  materialsListMaterial: MaterialsListMaterial
) =>
  action(ActionType.GET_MATERIALS_LIST_MATERIAL_SUCCESS, {
    materialsListMaterialId,
    materialsListMaterial,
  });

export const getMaterialsListMaterialFailure = (
  materialsListMaterialId: number,
  error: Error
) =>
  action(
    ActionType.GET_MATERIALS_LIST_MATERIAL_FAILURE,
    {
      materialsListMaterialId,
    },
    null,
    error
  );

export const requestCreateDealMaterialsList = (
  dealId: number,
  callback?: (materialsListId: number) => void
) =>
  action(ActionType.REQUEST_CREATE_DEAL_MATERIALS_LIST, {
    dealId,
    callback,
  });

export const persistMaterialsListSuccess = () =>
  action(ActionType.PERSIST_MATERIALS_LIST_SUCCESS);

export const persistMaterialsListFailure = (error: Error) =>
  action(ActionType.PERSIST_MATERIALS_LIST_FAILURE, null, null, error);

export const requestGetMaterialsListWithRelatedEntities = (
  materialsListId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_GET_MATERIALS_LIST_WITH_RELATED_ENTITIES, {
    materialsListId,
    callback,
  });

export const getMaterialsListWithRelatedEntitiesSuccess = (response: any) =>
  action(ActionType.GET_MATERIALS_LIST_WITH_RELATED_ENTITIES_SUCCESS, response);

export const requestGetMaterialsList = (materialsListId: number) =>
  action(ActionType.REQUEST_GET_MATERIALS_LIST, {
    materialsListId,
  });

export const getMaterialsListSuccess = (
  materialsListId: number,
  materialsList: MaterialsList
) =>
  action(ActionType.GET_MATERIALS_LIST_SUCCESS, {
    materialsListId,
    materialsList,
  });

export const getMaterialsListFailure = (
  materialsListId: number,
  error: Error
) =>
  action(
    ActionType.GET_MATERIALS_LIST_FAILURE,
    {
      materialsListId,
    },
    null,
    error
  );

export const clearMaterialPurchaseRecords = () =>
  action(ActionType.CLEAR_MATERIAL_PURCHASE_RECORDS);

export const clearMaterialsListMaterials = () =>
  action(ActionType.CLEAR_MATERIALS_LIST_MATERIALS);

export const clearMaterialsLists = () =>
  action(ActionType.CLEAR_MATERIALS_LISTS);

export const requestPersistMaterialsListMaterial = (
  persistMaterialsListMaterialRequestData: PersistMaterialsListMaterialRequestData,
  callback?: (materialsListMaterialId: number) => void
) =>
  action(ActionType.REQUEST_PERSIST_MATERIALS_LIST_MATERIAL, {
    persistMaterialsListMaterialRequestData,
    callback,
  });

export const persistMaterialsListMaterialSuccess = () =>
  action(ActionType.PERSIST_MATERIALS_LIST_MATERIAL_SUCCESS);

export const persistMaterialsListMaterialFailure = (error: Error) =>
  action(ActionType.PERSIST_MATERIALS_LIST_MATERIAL_FAILURE, null, null, error);

export const requestDeleteMaterialsListMaterial = (
  materialsListMaterialId: number,
  callback?: () => void,
  onErrorCallback?: () => void
) =>
  action(ActionType.REQUEST_DELETE_MATERIALS_LIST_MATERIAL, {
    materialsListMaterialId,
    callback,
    onErrorCallback,
  });

export const deleteMaterialsListMaterialSuccess = () =>
  action(ActionType.DELETE_MATERIALS_LIST_MATERIAL_SUCCESS);

export const deleteMaterialsListMaterialFailure = (error: Error) =>
  action(ActionType.DELETE_MATERIALS_LIST_MATERIAL_FAILURE, null, null, error);

export const requestPersistMaterialPurchaseRecord = (
  persistMaterialPurchaseRecordRequestData: PersistMaterialPurchaseRecordRequestData,
  callback?: (materialPurchaseRecordId: number) => void
) =>
  action(ActionType.REQUEST_PERSIST_MATERIAL_PURCHASE_RECORD, {
    persistMaterialPurchaseRecordRequestData,
    callback,
  });

export const persistMaterialPurchaseRecordSuccess = () =>
  action(ActionType.PERSIST_MATERIAL_PURCHASE_RECORD_SUCCESS);

export const persistMaterialPurchaseRecordFailure = (error: Error) =>
  action(
    ActionType.PERSIST_MATERIAL_PURCHASE_RECORD_FAILURE,
    null,
    null,
    error
  );

export const requestDeleteMaterialPurchaseRecord = (
  materialPurchaseRecordId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_DELETE_MATERIAL_PURCHASE_RECORD, {
    materialPurchaseRecordId,
    callback,
  });

export const deleteMaterialPurchaseRecordSuccess = () =>
  action(ActionType.DELETE_MATERIALS_LIST_MATERIAL_SUCCESS);

export const deleteMaterialPurchaseRecordFailure = (error: Error) =>
  action(ActionType.DELETE_MATERIAL_PURCHASE_RECORD_FAILURE, null, null, error);

export const requestPersistPurchaseOrder = (
  persistPurchaseOrderRequestData: PersistPurchaseOrderRequestData,
  callback?: (purchaseOrderId: number) => void
) =>
  action(ActionType.REQUEST_PERSIST_PURCHASE_ORDER, {
    persistPurchaseOrderRequestData,
    callback,
  });

export const persistPurchaseOrderSuccess = () =>
  action(ActionType.PERSIST_PURCHASE_ORDER_SUCCESS);

export const persistPurchaseOrderFailure = (error: Error) =>
  action(ActionType.PERSIST_PURCHASE_ORDER_FAILURE, null, null, error);

export const requestGetPurchaseOrder = (
  purchaseOrderId: number,
  callback?: (purchaseOrder: PurchaseOrder) => void
) =>
  action(ActionType.REQUEST_GET_PURCHASE_ORDER, {
    purchaseOrderId,
    callback,
  });

export const getPurchaseOrderSuccess = (
  purchaseOrderId: number,
  purchaseOrder: PurchaseOrder
) =>
  action(ActionType.GET_PURCHASE_ORDER_SUCCESS, {
    purchaseOrderId,
    purchaseOrder,
  });

export const getPurchaseOrderFailure = (
  purchaseOrderId: number,
  error: Error
) =>
  action(
    ActionType.GET_PURCHASE_ORDER_FAILURE,
    {
      purchaseOrderId,
    },
    null,
    error
  );

export const clearPurchaseOrders = () =>
  action(ActionType.CLEAR_PURCHASE_ORDERS);

export const requestGetMaterialPurchaseIntent = (
  materialPurchaseIntentId: number
) =>
  action(ActionType.REQUEST_GET_MATERIAL_PURCHASE_INTENT, {
    materialPurchaseIntentId,
  });

export const getMaterialPurchaseIntentSuccess = (
  materialPurchaseIntentId: number,
  materialPurchaseIntent: MaterialPurchaseIntent
) =>
  action(ActionType.GET_MATERIAL_PURCHASE_INTENT_SUCCESS, {
    materialPurchaseIntentId,
    materialPurchaseIntent,
  });

export const getMaterialPurchaseIntentFailure = (
  materialPurchaseIntentId: number,
  error: Error
) =>
  action(
    ActionType.GET_MATERIAL_PURCHASE_INTENT_FAILURE,
    {
      materialPurchaseIntentId,
    },
    null,
    error
  );

export const clearMaterialPurchaseIntents = () =>
  action(ActionType.CLEAR_MATERIAL_PURCHASE_INTENTS);

export const requestSendPurchaseOrder = (
  sendPurchaseOrderRequestData: SendPurchaseOrderRequestData,
  callback?: () => void,
  errorCallback?: (error: Error) => void
) =>
  action(ActionType.REQUEST_SEND_PURCHASE_ORDER, {
    sendPurchaseOrderRequestData,
    callback,
    errorCallback,
  });

export const requestMarkPurchaseOrderAsSent = (
  purchaseOrderId: number,
  callback?: () => void,
  errorCallback?: (error: Error) => void
) =>
  action(ActionType.REQUEST_MARK_PURCHASE_ORDER_AS_SENT, {
    purchaseOrderId,
    callback,
    errorCallback,
  });

export const requestVoidPurchaseOrder = (
  purchaseOrderId: number,
  callback?: () => void,
  errorCallback?: (error: Error) => void
) =>
  action(ActionType.REQUEST_VOID_PURCHASE_ORDER, {
    purchaseOrderId,
    callback,
    errorCallback,
  });

export const requestConfirmPurchaseOrder = (
  confirmPurchaseOrderRequestData: ConfirmPurchaseOrderRequestData,
  callback?: () => void,
  errorCallback?: (error: Error) => void
) =>
  action(ActionType.REQUEST_CONFIRM_PURCHASE_ORDER, {
    confirmPurchaseOrderRequestData,
    callback,
    errorCallback,
  });
