import * as customerActions from '@payaca/store/customer/customerActions';
import { GetListedCustomersRequestData } from '@payaca/types/listedCustomerTypes';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ListedCustomersTable from '../listedCustomersTable/ListedCustomersTable';

import BasicField from '@payaca/components/basicField/BasicField';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { Search } from 'react-iconly';
import './SelectCustomerControl.css';

type Props = {
  onSelectCustomer: (customerId: number) => void;
};
const SelectCustomerControl: FunctionComponent<Props> = ({
  onSelectCustomer,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [getListedCustomersRequestData, setGetListedCustomersRequestData] =
    useState<GetListedCustomersRequestData>({
      pageSize: 20,
      pageNumber: 1,
      searchTerm: '',
    });

  useEffect(() => {
    requestGetListedCustomersPage();
  }, [getListedCustomersRequestData]);

  const requestGetListedCustomersPage = useCallback(() => {
    dispatch(
      customerActions.requestGetListedCustomersPage(
        getListedCustomersRequestData
      )
    );
  }, [getListedCustomersRequestData, dispatch]);

  const onSelectPage = useCallback((pageNumber: number) => {
    setGetListedCustomersRequestData(
      (getListedCustomersRequestData: GetListedCustomersRequestData) => {
        return {
          ...getListedCustomersRequestData,
          pageNumber: pageNumber,
        };
      }
    );
  }, []);

  const [searchTermTimeout, setSearchTermTimeout] = useState<NodeJS.Timeout>();

  const onSearchTermChange = useCallback((searchTerm: string) => {
    setGetListedCustomersRequestData(
      (getListedCustomersRequestData: GetListedCustomersRequestData) => {
        return {
          ...getListedCustomersRequestData,
          pageNumber: 1,
          searchTerm: searchTerm,
        };
      }
    );
  }, []);

  const onSearchTermType = useCallback(
    (value: { [key: string]: string }) => {
      searchTermTimeout && clearTimeout(searchTermTimeout);
      setSearchTermTimeout(
        setTimeout(() => {
          onSearchTermChange(value.searchTerm);
        }, 500)
      );
    },
    [searchTermTimeout, onSearchTermChange]
  );

  return (
    <div className="select-customer-control">
      <div className="search-term-field-container">
        <BasicField
          styleVariant={InputStyleVariant.OUTSIZE}
          name="searchTerm"
          onChange={onSearchTermType}
          iconAfter={<Search set="light" size={18} />}
          additionalInputProps={{
            placeholder: 'Search',
          }}
        />
      </div>
      <ContentPanel>
        <ListedCustomersTable
          onClickRow={onSelectCustomer}
          onSelectPage={onSelectPage}
        />
      </ContentPanel>
    </div>
  );
};

export default SelectCustomerControl;
