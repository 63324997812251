import '../slateEditor/types';

import { RichTextEditorVariables } from '@payaca/types/richTextEditorVariables';
import { ComponentPropsWithoutRef } from 'react';
import { useSlate } from 'slate-react';
import FieldLabel from '../fieldLabel/FieldLabel';
import { insertVariable } from './Elements/Variable';

export interface IProps<T = string> extends ComponentPropsWithoutRef<'div'> {
  variables?: RichTextEditorVariables<T>;
}

const VariableList = <T extends string>(props: IProps<T>) => {
  const { variables, className, ...rest } = props;
  const editor = useSlate();

  if (!variables || variables.length === 0) {
    return null;
  }

  return (
    <div className={className} {...rest}>
      <FieldLabel label="Available variables" />
      <div className="border-blue-light-mid max-h-[244px] overflow-y-auto rounded-[6px] border">
        <ul className="m-0 flex list-none flex-col gap-2 p-[0.75rem]">
          {variables.map((variable) => (
            <li key={variable.name}>
              <button
                className="rich-text-editor-variable"
                type="button"
                onClick={() =>
                  insertVariable({
                    ...variable,
                    editor,
                  })
                }
              >
                {variable.displayName}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default VariableList;
