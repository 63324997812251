import UntitledIcon from '@payaca/untitled-icons';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Button from '../plButton/Button';
import { EBtnSize, EBtnVariant } from '../plButton/useButtonClassName';

type CollapsibleBlockContextType = {
  isOpen: boolean;
  setIsExpanded: (isOpen: boolean) => void;
};

export const CollapsibleBlockContext =
  React.createContext<CollapsibleBlockContextType>({
    isOpen: true,
    setIsExpanded: () => null,
  });

type Props = {
  isExpanded?: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
  collapsedTitle?: string;
  CustomCollapsedView?: FunctionComponent;
};

const CollapsibleBlock: FunctionComponent<PropsWithChildren<Props>> = ({
  isExpanded: externalIsExpanded,
  setIsExpanded: externalSetIsExpanded,
  collapsedTitle,
  CustomCollapsedView,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(externalIsExpanded || false);
  const [expandedDimensions, setExpandedDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [collapsedDimensions, setCollapsedDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    externalIsExpanded !== undefined && setIsExpanded(externalIsExpanded);
  }, [externalIsExpanded]);

  const expandedRef = useCallback(
    (node: any) => {
      if (node != null) {
        setExpandedDimensions({
          width: node.offsetWidth,
          height: node.offsetHeight,
        });
      }
    },
    [isExpanded]
  );

  const collapsedRef = useCallback(
    (node: any) => {
      if (node != null) {
        setCollapsedDimensions({
          width: node.offsetWidth,
          height: node.offsetHeight,
        });
      }
    },
    [isExpanded]
  );

  return (
    <CollapsibleBlockContext.Provider
      value={{
        isOpen: isExpanded,
        setIsExpanded: (value) => {
          externalSetIsExpanded
            ? externalSetIsExpanded(value)
            : setIsExpanded(value);
        },
      }}
    >
      <div
        style={{
          width: isExpanded
            ? expandedDimensions.width || 400
            : collapsedDimensions.width || 0,
          minHeight: isExpanded
            ? expandedDimensions.height
            : collapsedDimensions.height,
        }}
        className={`relative inline-block h-full max-h-full overflow-hidden transition-all`}
      >
        <div
          className={`absolute transition-all ${
            isExpanded ? `opacity-1` : `pointer-events-none opacity-0`
          }`}
        >
          <div ref={expandedRef}>{children}</div>
        </div>
        <div className={`absolute ${isExpanded ? `invisible` : `visible`}`}>
          <div ref={collapsedRef}>
            {CustomCollapsedView ? (
              <CustomCollapsedView />
            ) : (
              <div className="flex flex-col items-center gap-5">
                <ExpandButton />
                <CollapsedTitle>{collapsedTitle}</CollapsedTitle>
              </div>
            )}
          </div>
        </div>
      </div>
    </CollapsibleBlockContext.Provider>
  );
};

const CollapseButton: FunctionComponent = () => {
  const { setIsExpanded: setIsOpen } = useContext(CollapsibleBlockContext);

  return (
    <Button
      variant={EBtnVariant.White}
      onClick={() => setIsOpen(false)}
      size={EBtnSize.XSmall}
    >
      <span className="sr-only">Collapse</span>
      <div className="h-5 w-5">
        <UntitledIcon name="chevron-left" className="h-5 w-5" />
      </div>
    </Button>
  );
};

const ExpandButton: FunctionComponent = () => {
  const { setIsExpanded: setIsOpen } = useContext(CollapsibleBlockContext);
  return (
    <Button
      variant={EBtnVariant.White}
      onClick={() => setIsOpen(true)}
      size={EBtnSize.XSmall}
    >
      <span className="sr-only">Expand</span>
      <div className="h-5 w-5">
        <UntitledIcon name="chevron-right" className="h-5 w-5" />
      </div>
    </Button>
  );
};

const CollapsedTitle: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return <h2 className="text-vertical mb-0 text-base">{children}</h2>;
};

export default Object.assign(CollapsibleBlock, {
  CollapseButton,
  CollapsedTitle,
  ExpandButton,
});
