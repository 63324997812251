import { action } from 'typesafe-actions';

import { ResendJobEmailPayload } from '@payaca/types/jobActionsTypes';
import { UpdateJobRequestData } from '@payaca/types/jobRequestTypes';
import { JobAttachment } from '@payaca/types/jobTypes';
import { Job } from '@payaca/types/jobTypesV2';

import { createAsyncAction } from '../utils';

import {
  ActionType,
  PartialUpdateJob,
  UpdateJobAttachmentRequestData,
} from './jobsTypes';

export const requestResendJob = (
  jobId: number,
  params: ResendJobEmailPayload
) =>
  action(ActionType.REQUEST_RESEND_JOB, {
    jobId,
    params,
  });

export const resendJobSuccess = () => action(ActionType.RESEND_JOB_SUCCESS);

export const resendJobFailure = (error: Error, errorMessage: string) =>
  action(ActionType.RESEND_JOB_FAILURE, { errorMessage }, null, error);

export const clearResendJob = () => action(ActionType.CLEAR_RESEND_JOB);

export const requestEditJob = (jobId: number) =>
  action(ActionType.REQUEST_EDIT_JOB, { jobId });

export const editJobSuccess = (newJob: any) =>
  action(ActionType.EDIT_JOB_SUCCESS, { newJob });

export const editJobFailure = (error: Error, errorMessage: string) =>
  action(ActionType.EDIT_JOB_FAILURE, { errorMessage }, null, error);

export const clearEditJob = () => action(ActionType.CLEAR_EDIT_JOB);

export const requestMarkJobAsSent = (jobId: number, callback?: () => void) =>
  action(ActionType.REQUEST_MARK_JOB_AS_SENT, {
    jobId,
    callback,
  });

export const markJobAsSentSuccess = () =>
  action(ActionType.MARK_JOB_AS_SENT_SUCCESS);

export const markJobAsSentFailure = (error: Error, errorMessage: string) =>
  action(ActionType.MARK_JOB_AS_SENT_FAILURE, { errorMessage }, null, error);

export const clearMarkJobAsSent = () =>
  action(ActionType.CLEAR_MARK_JOB_AS_SENT);

export const requestGetJob = (jobId: number, callback?: (job: Job) => void) =>
  action(ActionType.REQUEST_GET_JOB, {
    jobId,
    callback,
  });

export const getJobSuccess = (jobId: number, job: Job) =>
  action(ActionType.GET_JOB_SUCCESS, {
    jobId,
    job,
  });

export const getJobFailure = (jobId: number, error: Error) =>
  action(
    ActionType.GET_JOB_FAILURE,
    {
      jobId,
    },
    null,
    error
  );

export const clearJob = (jobId: number) =>
  action(ActionType.CLEAR_JOB, {
    jobId,
  });

export const clearJobs = () => action(ActionType.CLEAR_JOBS);

export const requestUpdateJob = (
  updateJobRequestData: UpdateJobRequestData,
  callback?: () => void
) =>
  action(ActionType.REQUEST_UPDATE_JOB, {
    updateJobRequestData,
    callback,
  });

export const updateJobSuccess = (jobId: number) =>
  action(ActionType.UPDATE_JOB_SUCCESS, {
    jobId,
  });

export const updateJobFailure = (jobId: number, error: Error) =>
  action(ActionType.UPDATE_JOB_FAILURE, { jobId }, null, error);

export const requestGetJobsForDeal = (dealId: number) =>
  action(ActionType.REQUEST_GET_JOBS_FOR_DEAL, {
    dealId,
  });

export const getJobsForDealSuccess = (jobs: Array<Job>) =>
  action(ActionType.GET_JOBS_FOR_DEAL_SUCCESS, jobs);

export const getJobsForDealFailure = (error: Error) =>
  action(ActionType.GET_JOBS_FOR_DEAL_FAILURE, null, null, error);

export const requestGetJobAttachmentsForJob = (jobId: number) =>
  action(ActionType.REQUEST_GET_JOB_ATTACHMENTS_FOR_JOB, {
    jobId,
  });

export const getJobAttachmentSuccess = (
  jobAttachmentId: string,
  jobAttachment: JobAttachment
) =>
  action(ActionType.GET_JOB_ATTACHMENT_SUCCESS, {
    jobAttachmentId,
    jobAttachment,
  });

export const getJobAttachmentsForJobSuccess = (
  jobAttachments: Array<JobAttachment>
) => action(ActionType.GET_JOB_ATTACHMENTS_FOR_JOB_SUCCESS, jobAttachments);

export const getJobAttachmentFailure = (
  jobAttachmentId: string,
  error: Error
) =>
  action(
    ActionType.GET_JOB_ATTACHMENT_FAILURE,
    {
      jobAttachmentId,
    },
    null,
    error
  );

export const clearJobAttachment = (jobAttachmentId: string) =>
  action(ActionType.CLEAR_JOB_ATTACHMENT, {
    jobAttachmentId,
  });

export const clearJobAttachments = () =>
  action(ActionType.CLEAR_JOB_ATTACHMENTS);

export const requestUpdateJobAttachment = (
  updateJobAttachmentRequestData: UpdateJobAttachmentRequestData,
  callback?: () => void
) =>
  action(ActionType.REQUEST_UPDATE_JOB_ATTACHMENT, {
    updateJobAttachmentRequestData,
    callback,
  });

export const updateJobAttachmentSuccess = (jobAttachmentId: string) =>
  action(ActionType.UPDATE_JOB_ATTACHMENT_SUCCESS, {
    jobAttachmentId,
  });

export const updateJobAttachmentFailure = (
  jobAttachmentId: string,
  error: Error
) =>
  action(
    ActionType.UPDATE_JOB_ATTACHMENT_FAILURE,
    { jobAttachmentId },
    null,
    error
  );

export const requestGetJobValidationResult = (
  jobId: number,
  callback?: (validationResult: { isValid: boolean; errors: string[] }) => void
) =>
  action(ActionType.REQUEST_GET_JOB_VALIDATION_RESULT, {
    jobId,
    callback,
  });

export const getJobValidationResultSuccess = (validationResult: {
  isValid: boolean;
  errors: string[];
}) =>
  action(ActionType.GET_JOB_VALIDATION_RESULT_SUCCESS, {
    validationResult,
  });

export const getJobValidationResultFailure = (error: Error) =>
  action(ActionType.GET_JOB_VALIDATION_RESULT_FAILURE, null, null, error);

export const clearJobUpdateResults = () =>
  action(ActionType.CLEAR_JOB_UPDATE_RESULTS);

export const requestSendQuoteToCustomer = (
  jobId: number,
  sendQuoteRequestData: any,
  callback: (error?: string) => void
) =>
  action(ActionType.REQUEST_SEND_QUOTE_TO_CUSTOMER, {
    jobId,
    sendQuoteRequestData,
    callback,
  });

export const sendQuoteToCustomerSuccess = () =>
  action(ActionType.SEND_QUOTE_TO_CUSTOMER_SUCCESS);

export const sendQuoteToCustomerFailure = (
  error: Error,
  errorMessage: string
) =>
  action(
    ActionType.SEND_QUOTE_TO_CUSTOMER_FAILURE,
    { errorMessage },
    null,
    error
  );

export const requestSendInvoiceToCustomer = (
  jobId: number,
  sendInvoiceRequestData: any,
  callback: (error?: string) => void
) =>
  action(ActionType.REQUEST_SEND_INVOICE_TO_CUSTOMER, {
    jobId,
    sendInvoiceRequestData,
    callback,
  });

export const sendInvoiceToCustomerSuccess = () =>
  action(ActionType.SEND_INVOICE_TO_CUSTOMER_SUCCESS);

export const sendInvoiceToCustomerFailure = (
  error: Error,
  errorMessage: string
) =>
  action(
    ActionType.SEND_INVOICE_TO_CUSTOMER_FAILURE,
    { errorMessage },
    null,
    error
  );

export const requestArchiveJob = (
  jobId: number,
  callback: (error?: string) => void
) =>
  action(ActionType.REQUEST_ARCHIVE_JOB, {
    jobId,
    callback,
  });

export const archiveJobSuccess = () => action(ActionType.ARCHIVE_JOB_SUCCESS);

export const archiveJobFailure = (error: Error, errorMessage: string) =>
  action(ActionType.ARCHIVE_JOB_FAILURE, { errorMessage }, null, error);

export const requestAcceptQuote = (
  jobId: number,
  callback: (error?: string) => void
) =>
  action(ActionType.REQUEST_ACCEPT_QUOTE, {
    jobId,
    callback,
  });

export const acceptQuoteSuccess = () => action(ActionType.ACCEPT_QUOTE_SUCCESS);

export const acceptQuoteFailure = (error: Error, errorMessage: string) =>
  action(ActionType.ACCEPT_QUOTE_FAILURE, { errorMessage }, null, error);

export const requestDeclineJob = (
  jobId: number,
  callback: (error?: string) => void
) =>
  action(ActionType.REQUEST_DECLINE_JOB, {
    jobId,
    callback,
  });

export const declineJobSuccess = () => action(ActionType.DECLINE_JOB_SUCCESS);

export const declineJobFailure = (error: Error, errorMessage: string) =>
  action(ActionType.DECLINE_JOB_FAILURE, { errorMessage }, null, error);

export const requestConvertJobToInvoice = (
  jobId: number,
  callback: (error: string | null, response: any) => void
) =>
  action(ActionType.REQUEST_CONVERT_JOB_TO_INVOICE, {
    jobId,
    callback,
  });

export const convertJobToInvoiceSuccess = () =>
  action(ActionType.CONVERT_JOB_TO_INVOICE_SUCCESS);

export const convertJobToInvoiceFailure = (
  error: Error,
  errorMessage: string
) =>
  action(
    ActionType.CONVERT_JOB_TO_INVOICE_FAILURE,
    { errorMessage },
    null,
    error
  );

export const requestDuplicateJob = (
  jobId: number,
  callback: (error: string | null, response: any) => void
) =>
  action(ActionType.REQUEST_DUPLICATE_JOB, {
    jobId,
    callback,
  });

export const duplicateJobSuccess = () =>
  action(ActionType.DUPLICATE_JOB_SUCCESS);

export const duplicateJobFailure = (error: Error, errorMessage: string) =>
  action(ActionType.DUPLICATE_JOB_FAILURE, { errorMessage }, null, error);

export const requestCreateJob = (
  isProposition: boolean,
  dealId: number,
  callback: (error: string | null, response: any) => void,
  isProtoInvoice?: boolean
) =>
  action(ActionType.REQUEST_CREATE_JOB, {
    isProposition,
    isProtoInvoice,
    dealId,
    callback,
  });

export const createJobSuccess = () => action(ActionType.CREATE_JOB_SUCCESS);

export const createJobFailure = (error: Error, errorMessage: string) =>
  action(ActionType.CREATE_JOB_FAILURE, { errorMessage }, null, error);

export const partialUpdateJob = createAsyncAction<PartialUpdateJob>({
  request: ActionType.PARTIAL_UPDATE_JOB_REQUEST,
  success: ActionType.UPDATE_JOB_SUCCESS,
  failure: ActionType.UPDATE_JOB_FAILURE,
})((payload: PartialUpdateJob['request']['payload']) => ({
  payload,
}));
