import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { FC } from 'react';
import SupplierBasicInformationControl from '../supplierBasicInformationControl/SupplierBasicInformationControl';
import SupplierContactInformationControl from '../supplierContactInformationControl/SupplierContactInformationControl';

import './SupplierHeader.sass';

type Props = {
  supplierId: number;
};
const SupplierHeader: FC<Props> = ({ supplierId }: Props): JSX.Element => {
  return (
    <ResponsiveViewWrapper className="supplier-header" downBreakpointSm={800}>
      <div className="supplier-header-inner flex-container">
        <SupplierBasicInformationControl supplierId={supplierId} />
        <SupplierContactInformationControl supplierId={supplierId} />
      </div>
    </ResponsiveViewWrapper>
  );
};

export default SupplierHeader;
