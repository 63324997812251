import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { twMerge } from 'tailwind-merge';

import { RichTextEditorVariables } from '@payaca/types/richTextEditorVariables';

import MenuBar from './MenuBar';
import { VariableNode } from './nodes/variable';
import VariableList from './VariableList';

const extensions = [
  StarterKit,
  Link.configure({
    openOnClick: 'whenNotEditable',
    autolink: true,
  }),
  Underline,
  VariableNode,
];
type OnChangeType<IsHtmlOutput extends boolean> = IsHtmlOutput extends true
  ? string
  : object | object[] | null;

interface IRichTextareaProps<T extends string, IsHtmlOutput extends boolean> {
  value: Content;
  onChange?: (value: OnChangeType<IsHtmlOutput>) => void;
  variables?: RichTextEditorVariables<T>;
  readOnly?: boolean;
  isHtmlOutput?: IsHtmlOutput;
  className?: string;
}

const RichTextarea: {
  (props: IRichTextareaProps<string, true>): JSX.Element;
  (props: IRichTextareaProps<string, false>): JSX.Element;
} = <T extends string, IsHtmlOutput extends boolean>(
  props: IRichTextareaProps<T, IsHtmlOutput>
) => {
  const {
    variables,
    value,
    onChange,
    readOnly = false,
    isHtmlOutput = false,
    className,
  } = props;

  const editor = useEditor(
    {
      onUpdate: ({ editor }) => {
        const value = isHtmlOutput ? editor.getHTML() : editor.getJSON();
        onChange?.(value as OnChangeType<IsHtmlOutput>);
      },
      extensions,
      content: value,
      editorProps: {
        attributes: {
          class: twMerge(
            'prose focus:outline-none',
            !readOnly ? 'p-3' : '',
            className
          ),
        },
      },
      editable: !readOnly,
    },
    [!readOnly || value]
  );

  return (
    <div className="gap-5 md:flex">
      <div
        className={
          !readOnly
            ? `overflow-hidden rounded-xl border border-gray-200 dark:border-neutral-700 ${
                variables?.length ? 'md:w-3/4' : 'w-full'
              }`
            : 'w-full'
        }
      >
        {!readOnly && <MenuBar editor={editor} />}
        <EditorContent editor={editor} />
      </div>

      {!readOnly && !!variables?.length && (
        <VariableList<T>
          editor={editor}
          variables={variables}
          className="md:w-1/4"
        />
      )}
    </div>
  );
};

export default RichTextarea;
