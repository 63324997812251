import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import './ImageBlock.sass';

type Props = {
  imageSrc?: string;
  renderBlockIfNoImageSrc?: boolean;
};

const ImageBlock: FunctionComponent<Props> = ({
  imageSrc,
  renderBlockIfNoImageSrc = true,
}: Props): JSX.Element | null => {
  if (!renderBlockIfNoImageSrc && !imageSrc) return null;
  return (
    <div className="image-block">
      {!imageSrc && <FontAwesomeIcon icon={faImage} />}
      {imageSrc && <img src={imageSrc} />}
    </div>
  );
};

export default ImageBlock;
