import { AddressLookupOrInput } from '@/ui/components/addressLookupOrInput/AddressLookupOrInput';
import { DateInput } from '@payaca/components/plDateInput/DateInput';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/RawInput';
import Radio from '@payaca/components/plRadio/Radio';
import Select from '@payaca/components/plSelect/Select';
import { FC, InputHTMLAttributes, useEffect } from 'react';
import { Controller, get, useFormContext, useWatch } from 'react-hook-form';
import DeviceSearchField from './DeviceSearchField';
import DiscriminatorField from './DiscriminatorField';
import ProjectFileSelection from './ProjectFileSelection';
import {
  fieldValidationState,
  getAddressValidationState,
  getFieldLabelFromFieldSchema,
} from './utils';

export type Props = {
  name: string;
  fieldSchema: any;
  namespace?: string;
  definitions: Record<string, any>;
  dependentFields: string[];
};
const addressLines = ['line1', 'line2', 'city', 'postcode', 'country'];

export const DynamicField: FC<Props> = ({
  name,
  namespace,
  fieldSchema,
  definitions,
  dependentFields,
}) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const displayIf = fieldSchema.json_schema_extra.displayIf;
  if (displayIf) {
    const namespacedProperty = `${namespace}.${displayIf.property}`;
    if (
      (displayIf.value && watch(namespacedProperty) !== displayIf.value) ||
      (displayIf.valueIsSet && !watch(namespacedProperty))
    ) {
      return null;
    }
  }
  const registerProps = { shouldUnregister: !!displayIf };
  const helperText = fieldSchema.json_schema_extra.tooltip;

  const fieldLabel = getFieldLabelFromFieldSchema(fieldSchema);

  switch (fieldSchema.json_schema_extra.type) {
    case 'USER ACCOUNT DERIVED INPUT':
    case 'TEXT INPUT': {
      const extraInputProps: InputHTMLAttributes<HTMLInputElement> = {};
      if (fieldSchema.type === 'number') extraInputProps.step = '0.01';
      return (
        <Field {...fieldProps(errors, name)}>
          <Field.Label>{fieldLabel}</Field.Label>
          <Input
            type={fieldSchema.type}
            placeholder={fieldSchema.json_schema_extra.placeholderText}
            onWheel={(e) => e.currentTarget.blur()}
            {...extraInputProps}
            {...register(name, {
              ...registerProps,
              setValueAs: (value) => {
                if (fieldSchema.type === 'number')
                  return value ? Number.parseFloat(value) : null;

                return value;
              },
            })}
          />
          <Field.Helper>{helperText}</Field.Helper>
        </Field>
      );
    }

    case 'RADIO INPUT':
      return (
        <Field {...fieldProps(errors, name)}>
          <Field.Label>{fieldLabel}</Field.Label>
          {/* We have to use a controlled component to allow complex radio inputs with differing types for each value */}
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, ...rest } }) => (
              <>
                {Object.entries<any>(fieldSchema.json_schema_extra.options).map(
                  ([key, value]) => (
                    <Radio
                      key={key}
                      label={key}
                      onChange={() => {
                        onChange(value);
                        // clear the fields that are dependent on this field
                        dependentFields.forEach((dependentFieldKey) => {
                          setValue(dependentFieldKey, undefined);
                        });
                      }}
                      checked={rest.value === value}
                      {...rest}
                    />
                  )
                )}
              </>
            )}
          />
          <Field.Helper>{helperText}</Field.Helper>
        </Field>
      );
    case 'SELECT INPUT':
      return (
        <Field {...fieldProps(errors, name)}>
          <Field.Label>{fieldLabel}</Field.Label>
          <Controller
            control={control}
            name={name}
            render={({ field: { ref, value, ...rest } }) => (
              <Select
                {...rest}
                value={value || null}
                options={fieldSchema.json_schema_extra.options.selectOptions.map(
                  (option: any) => {
                    return { label: option, value: option };
                  }
                )}
                placeholder={fieldSchema.json_schema_extra.placeholderText}
              />
            )}
          />
          <Field.Helper>{helperText}</Field.Helper>
        </Field>
      );
    case 'DERIVED INPUT': {
      const { source, sourceOutputMappings } =
        fieldSchema.json_schema_extra.derivedValue;
      const sourceValue = useWatch({ control, name: `${namespace}.${source}` });
      const derivedValue =
        sourceValue && sourceOutputMappings[sourceValue.toString()];
      useEffect(() => {
        setValue(name, derivedValue);
      }, [sourceValue]);

      return (
        <input
          type="hidden"
          {...register(name, {
            ...registerProps,
            value: derivedValue,
          })}
        />
      );
    }
    case 'DATE INPUT':
      return (
        <Field {...fieldProps(errors, name)}>
          <Field.Label>{fieldLabel}</Field.Label>
          <Controller
            control={control}
            name={name}
            render={({ field: { ref, value, onChange, ...rest } }) => (
              <DateInput
                value={value?.length > 0 ? new Date(value) : null}
                onChange={(date) => {
                  onChange(date.toISOString());
                }}
                {...rest}
              />
            )}
          />
          <Field.Helper>{helperText}</Field.Helper>
        </Field>
      );
    case 'FIXED INPUT':
      return <input type="hidden" {...register(name, registerProps)} />;
    case 'ADDRESS RECOGNISED INPUT': {
      return (
        <Field key={name} name={name}>
          <Field.Label>{fieldLabel}</Field.Label>
          <Controller
            control={control}
            name={name}
            render={({ field: { ref, value, ...rest } }) => {
              // The address component won't render validation errors unless it thinks the field has been touched
              const fakeAllTouched: { [fieldName: string]: boolean } =
                Object.fromEntries(addressLines.map((line) => [line, true]));

              return (
                <AddressLookupOrInput
                  {...rest}
                  address={value}
                  touchedState={fakeAllTouched}
                  validationState={getAddressValidationState(errors, name)}
                />
              );
            }}
          />
          <Field.Helper>{helperText}</Field.Helper>
        </Field>
      );
    }
    case 'MPAN RECOGNISED INPUT':
      return (
        <Field {...fieldProps(errors, name)}>
          <Field.Label>{fieldLabel}</Field.Label>
          <Input
            type={fieldSchema.type}
            placeholder="Enter customer's MPAN / MPRN"
            {...register(name, registerProps)}
          />
          <Field.Helper>{helperText}</Field.Helper>
        </Field>
      );
    case 'SEARCH DEVICE RECOGNISED INPUT': {
      const [deviceType] = getValues([`${namespace}.deviceType`]);
      return (
        <DeviceSearchField
          fieldSchema={fieldSchema}
          fieldProps={fieldProps}
          name={name}
          deviceType={deviceType}
        />
      );
    }
    case 'G100_SEARCH_RECOGNISED_INPUT_01': {
      return (
        <DeviceSearchField
          fieldSchema={fieldSchema}
          fieldProps={fieldProps}
          name={name}
          deviceType="G100_DEVICE"
        />
      );
    }
    case 'NESTED INPUT FORM DISCRIMINATED UNION 01': {
      // this should show a select field which on selection would conditionally show further schema
      return (
        <DiscriminatorField
          fieldSchema={fieldSchema}
          name={name}
          namespace={namespace}
          definitions={definitions}
        />
      );
    }

    case 'SINGLE ATTACHMENT INPUT 01': {
      return (
        <Field>
          <Field.Label>{fieldLabel}</Field.Label>
          <ProjectFileSelection name={name} formSection={fieldSchema} />
        </Field>
      );
    }

    default:
      throw new Error(`Unknown type: ${fieldSchema.json_schema_extra.type}`);
  }
};

export const fieldProps = (errors: any, name: string) => {
  const fieldError = get(errors, name);
  return {
    key: name,
    name,
    validationState: fieldError && fieldValidationState(fieldError),
  };
};
