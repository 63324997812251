import {
  useContextTime,
  useContextTimePropGetters,
} from '@rehookify/datepicker';
import { useEffect, useMemo, useState } from 'react';
import Combobox from '../plCombobox/Combobox';
import { SelectSizeVariant } from '../plSelect/Select';
import { HourOptions, MinutesOptions } from './constants';
import { useDatePickerPopoverContext } from './DatePickerPopover';

// Update time when changing day - https://github.com/rehookify/datepicker/issues/31

type Props = {
  selectedDate: Date;
  defaultDate: Date;
};
const TimeSelect = ({ selectedDate, defaultDate }: Props) => {
  const { time } = useContextTime();
  const { timeButton } = useContextTimePropGetters();

  const hours = useMemo(() => selectedDate?.getHours() || 0, [selectedDate]);
  const minutes = useMemo(
    () => selectedDate?.getMinutes() || 0,
    [selectedDate]
  );
  const [hasSetDefaultTime, setHasSetDefaultTime] = useState(false);
  const { businessHours } = useDatePickerPopoverContext();

  const filteredHourOptions = useMemo(() => {
    const defaultFilteredHours = HourOptions.filter(
      (hour) => hour.value >= 9 && hour.value <= 17
    );

    if (!selectedDate) return defaultFilteredHours;

    const dayOfWeek = selectedDate.getDay();

    const businessHoursForDay = businessHours?.[dayOfWeek];

    if (!businessHoursForDay) return defaultFilteredHours;

    const [start, end] = businessHoursForDay;

    if (start === null || end === null) return defaultFilteredHours;

    try {
      const startHour = +start.split(':')[0];
      const endHour = +end.split(':')[0];

      return HourOptions.filter(
        (hour) => hour.value >= startHour && hour.value <= endHour
      );
    } catch (err) {
      return defaultFilteredHours;
    }
  }, [businessHours, selectedDate]);

  useEffect(() => {
    if (!hasSetDefaultTime && defaultDate && selectedDate) {
      // programatically select default time
      // create new date object of selected date with new time
      const value = new Date(
        defaultDate.getFullYear(),
        defaultDate.getMonth(),
        defaultDate.getDate(),
        defaultDate.getHours(),
        defaultDate.getMinutes()
      );
      // get matching time object from context and call onClick
      const contextTime = time.find(
        (t) => t.$date.toString() === value.toString()
      );
      if (contextTime) {
        // @ts-ignore - don't have or need e here
        timeButton(contextTime)?.onClick?.();
      }
      setHasSetDefaultTime(true);
    }
  }, [defaultDate, selectedDate, hasSetDefaultTime]);

  const onChange = (change: { unit: 'hours' | 'minutes'; value: number }) => {
    // create new date object of current date with new time
    const value = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      change.unit === 'hours' ? change.value : hours,
      change.unit === 'minutes' ? change.value : minutes
    );
    // get matching time object from context and call onClick
    const contextTime = time.find(
      (t) => t.$date.toString() === value.toString()
    );
    if (contextTime) {
      // @ts-ignore - don't have or need e here
      timeButton(contextTime)?.onClick?.();
    }
  };

  const [minutesQuery, setMinutesQuery] = useState('');
  const [hoursQuery, setHoursQuery] = useState('');

  return (
    <div className="mt-3 flex items-center justify-center gap-x-2">
      <div className="relative w-[65px]">
        <Combobox
          options={filteredHourOptions}
          sizeVariant={SelectSizeVariant.SM}
          value={hours}
          multiple={false}
          onChange={(value) => onChange({ value, unit: 'hours' })}
          customButtonContent={('00' + hours).slice(-2)}
          query={hoursQuery}
          inputPlaceholder=""
          setQuery={(query) => {
            const value = Math.max(
              Math.min(query.length ? parseInt(query) : 0, 59),
              0
            );
            setHoursQuery(query);
            onChange({ value: value, unit: 'hours' });
          }}
        />
      </div>
      <span className="text-gray-800">:</span>
      <div className="relative w-[65px]">
        <Combobox
          options={MinutesOptions}
          sizeVariant={SelectSizeVariant.SM}
          value={minutes}
          customButtonContent={('00' + minutes).slice(-2)}
          query={minutesQuery}
          inputPlaceholder=""
          setQuery={(query) => {
            const value = Math.max(
              Math.min(query.length ? parseInt(query) : 0, 59),
              0
            );
            setMinutesQuery(query);
            onChange({ value: value, unit: 'minutes' });
          }}
          onChange={(value) => {
            onChange({ value, unit: 'minutes' });
          }}
          multiple={false}
        />
      </div>
    </div>
  );
};

export default TimeSelect;
