import { FC, useEffect, useMemo, useState } from 'react';

import { SelectValueModal } from '@/ui/components';
import TotalsEditableValue from './TotalsEditableValue';
import TotalsFixedValue from './TotalsFixedValue';

import {
  getJobContentByJobId,
  getJobLineItemsByJobContentId,
  getJobPaymentsByDealId,
} from '@/utils/stateAccessors';

import { useSelector } from '@/api/state';

import { currencyPrice } from '@/helpers/financeHelper';
import { isInvoice } from '@/helpers/jobHelper';
import { ValueModal } from '@/helpers/modalHelper';
import { getCompletedDepositPaymentValueFromJobPayments } from '@payaca/helpers/jobPaymentHelper';

import { JobContent, JobLineItem } from '@payaca/types/jobContentTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';

import * as dealActions from '@payaca/store/deals/dealsActions';
import { useDeal } from '@payaca/store/hooks/appState';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as jobActions from '@payaca/store/jobs/jobsActions';

import { getPaymentScheduleStagesWithValues } from '@payaca/helpers/paymentScheduleHelper';
import { useDispatch } from 'react-redux';
import SetProposalDiscountModal from '../setProposalDiscountModal/SetProposalDiscountModal';
import './JobTotalsAndValueModifiersControl.sass';

type Props = {
  jobId: number;
  updateJobFormState: { [key: string]: any };
  onChange: (value: { [key: string]: any }) => void;
  fieldNamePrefix?: string;
};

const JobTotalsAndValueModifiersControl: FC<Props> = ({
  jobId,
  onChange,
  updateJobFormState,
  fieldNamePrefix,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [showSetDiscountModal, setShowSetDiscountModal] = useState(false);
  const [showSelectValueModal, setShowSelectValueModal] = useState(false);
  const [modalOptions, setModalOptions] = useState<Record<string, any>>({});

  const job = useSelector((state) => {
    return state.jobsStore.jobs && state.jobsStore.jobs[jobId]?.entity;
  });

  const jobContent: JobContent | undefined = useSelector((state) => {
    return getJobContentByJobId(state, jobId);
  });

  const dealId = useMemo(() => {
    return job?.dealId;
  }, [job]);

  const deal = useDeal(dealId);

  const jobPayments: JobPayment[] = useSelector((state) => {
    if (!dealId) return [];
    return getJobPaymentsByDealId(state, dealId);
  });

  const jobLineItems: JobLineItem[] = useSelector((state) => {
    if (!jobContent?.id) return [];
    return getJobLineItemsByJobContentId(state, jobContent.id);
  });

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const isProposition: boolean = useMemo(() => {
    if (!job?.status) return false;

    return !isInvoice(job.status);
  }, [job?.status]);

  const completedDepositPaymentValue = useMemo(() => {
    return getCompletedDepositPaymentValueFromJobPayments(jobPayments);
  }, [jobPayments]);

  const hasAnyRequiredItems = useMemo(() => {
    return jobLineItems.find(
      (jobLineItem) => !jobLineItem.isMultipleChoice && !jobLineItem.isOptional
    );
  }, [jobLineItems]);

  // disable markup when job has no required items
  const disableMarkupAmount = useMemo(() => {
    return !hasAnyRequiredItems && isProposition;
  }, [hasAnyRequiredItems, isProposition]);

  const showMarkupModalInfoText = useMemo(() => {
    return (
      jobLineItems.some(
        (jobLineItem) => jobLineItem.isMultipleChoice || jobLineItem.isOptional
      ) && !disableMarkupAmount
    );
  }, [jobLineItems, disableMarkupAmount]);

  const paymentScheduleDeposit = useMemo(() => {
    if (!deal?.paymentSchedule) return null;

    const stages = getPaymentScheduleStagesWithValues(
      deal.paymentSchedule,
      jobContent?.total || 0
    );

    return stages.find((stage) => stage.isDeposit)?.value;
  }, [deal?.paymentSchedule, jobContent?.total]);

  useEffect(() => {
    if (disableMarkupAmount && updateJobFormState.jobContent?.markupAmount) {
      onChange({
        [`${fieldNamePrefix ? `${fieldNamePrefix}.` : ''}markupAmount`]: null,
      });
    }
  }, [
    fieldNamePrefix,
    onChange,
    disableMarkupAmount,
    updateJobFormState.jobContent?.markupAmount,
  ]);

  const jobLineItemsLength = useMemo(
    () => jobLineItems?.length,
    [jobLineItems?.length]
  );

  const jobSubtotal = useMemo(
    () => jobContent?.subtotal,
    [jobContent?.subtotal]
  );

  useEffect(() => {
    // clear markup if jobsubtatotal changes to £0 and there are items on job
    if (!!jobLineItemsLength && !jobSubtotal) {
      onChange({
        [`${fieldNamePrefix ? `${fieldNamePrefix}.` : ''}markupAmount`]: null,
        [`${fieldNamePrefix ? `${fieldNamePrefix}.` : ''}markupPercentage`]:
          null,
      });
    }
  }, [jobLineItemsLength, jobSubtotal, fieldNamePrefix]);

  const valueLines = useMemo(() => {
    if (!jobLineItems?.length || !jobContent || !deal) return [];

    return [
      {
        hide: false,
        separator: false,
        title: 'Subtotal' + '',
        value: currencyPrice(jobContent.subtotal, account.region),
      },
      {
        separator: false,
        hide: !jobContent.calculatedMarkupAmount || deal.version <= 1, // line items are added and job total is 0
        title: 'Markup',
        value:
          jobContent.calculatedMarkupAmount != undefined
            ? currencyPrice(jobContent.calculatedMarkupAmount, account.region)
            : null,
      },
      {
        separator: false,
        hide:
          (!!jobLineItems.length && !jobContent.subtotal && isProposition) ||
          deal.version > 1, // line items are added and job total is 0
        editValueTitle: 'Markup',
        emptyValueTitle: 'Add markup',
        onClick: () => {
          setShowSelectValueModal(true);
          setModalOptions({
            ...ValueModal.MARKUP,
            // disallow markup amount if there are no mandatory items on job #hardmath
            disallowAmount: disableMarkupAmount,
            infoText: showMarkupModalInfoText && ValueModal.MARKUP.infoText,
          });
        },
        value:
          jobContent.calculatedMarkupAmount != undefined
            ? currencyPrice(jobContent.calculatedMarkupAmount, account.region)
            : null,
        infoIconText: ValueModal.MARKUP.informationText,
        valuePrefix: '+',
      },
      {
        hide: !isProposition || deal.version < 2,
        editValueTitle: 'Discount',
        emptyValueTitle: 'Set discount',
        onClick: () => {
          setShowSetDiscountModal(true);
        },
        separator: false,
        value: jobContent.calculatedDiscountAmount
          ? currencyPrice(jobContent.calculatedDiscountAmount, account.region)
          : undefined,
      },
      {
        editValueTitle: 'Discount',
        emptyValueTitle: 'Add discount',
        onClick: () => {
          setShowSelectValueModal(true);
          setModalOptions(ValueModal.DISCOUNT);
        },
        value:
          jobContent.calculatedDiscountAmount != undefined
            ? currencyPrice(jobContent.calculatedDiscountAmount, account.region)
            : null,
        valuePrefix: '-',
        hide: deal.version > 1,
      },
      {
        hide: !jobContent.isAnyVatIncluded,
        title: 'Tax',
        value:
          jobContent.vatTotal != undefined
            ? currencyPrice(jobContent.vatTotal, account.region)
            : null,
        valuePrefix: '+',
      },
      {
        hide: !jobContent.hasAnyCisDeduction,
        title: 'CIS suffered',
        value:
          jobContent.cisTotal != undefined
            ? currencyPrice(jobContent.cisTotal, account.region)
            : null,
        valuePrefix: '-',
      },
      {
        separator: true,
      },
      {
        title: 'Total',
        value:
          jobContent.total != undefined
            ? currencyPrice(jobContent.total, account.region)
            : null,
        className: 'total',
      },
      {
        separator: completedDepositPaymentValue,
      },
      {
        hide: !completedDepositPaymentValue,
        title: 'Amount paid',
        value: currencyPrice(completedDepositPaymentValue, account.region),
        className: 'total',
      },
      {
        separator: isProposition,
      },
      {
        hide: !isProposition || !!deal?.paymentSchedule,
        editValueTitle: 'Deposit',
        emptyValueTitle: 'Request deposit',
        onClick: () => {
          setShowSelectValueModal(true);
          setModalOptions(ValueModal.DEPOSIT);
        },
        value:
          jobContent.calculatedDepositAmount != undefined
            ? currencyPrice(jobContent.calculatedDepositAmount, account.region)
            : null,
        disabled: !!deal?.paymentSchedule,
      },
      {
        hide: !isProposition || !paymentScheduleDeposit,
        title: 'Deposit',
        value: currencyPrice(paymentScheduleDeposit || 0, account.region),
      },
      { separator: true },
    ];
  }, [
    disableMarkupAmount,
    jobContent,
    jobLineItems,
    isProposition,
    completedDepositPaymentValue,
    account,
    deal,
    paymentScheduleDeposit,
  ]);

  const selectValueModal = useMemo(() => {
    return (
      <SelectValueModal
        open={true}
        onClose={() => setShowSelectValueModal(false)}
        valueAmount={
          updateJobFormState.jobContent &&
          updateJobFormState.jobContent[modalOptions.valueAmountName as string]
        }
        valuePercentage={
          updateJobFormState.jobContent &&
          updateJobFormState.jobContent[
            modalOptions.valuePercentageName as string
          ]
        }
        onChange={onChange}
        modalOptions={modalOptions}
        fieldNamePrefix={fieldNamePrefix}
      />
    );
  }, [updateJobFormState, modalOptions, onChange, fieldNamePrefix]);

  if (!valueLines.length) {
    return <></>;
  }

  return (
    <div className="job-totals-and-value-modifiers-control">
      {valueLines.map((line, i) => {
        if (line.separator) {
          return <hr key={i} />;
        } else if (line.hide) {
          return null;
        } else if (line.title && line.value) {
          return <TotalsFixedValue {...line} key={i} />;
        } else {
          return <TotalsEditableValue {...line} key={i} />;
        }
      })}
      {showSelectValueModal && selectValueModal}
      <SetProposalDiscountModal
        proposalId={jobId}
        isOpen={showSetDiscountModal}
        onClose={() => setShowSetDiscountModal(false)}
        onSetProposalDiscountSuccess={() => {
          if (job) {
            dispatch(
              jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
                job.jobContentId
              )
            );
            dispatch(jobActions.requestGetJob(job.id));
            dispatch(dealActions.requestGetDeal(job.dealId));
          }
        }}
      />
    </div>
  );
};

export default JobTotalsAndValueModifiersControl;
