import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import FeedbackMessage from '@payaca/components/feedbackMessage/FeedbackMessage';
import {
  minimumLengthFieldValidator,
  minimumLengthValidationMessage,
  mustContainLetterFieldValidator,
  mustContainLetterValidationMessage,
  mustContainNumberFieldValidator,
  mustContainNumberValidationMessage,
} from '@payaca/helpers/passwordValidationHelper';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { FunctionComponent, useCallback, useMemo } from 'react';
import './PasswordValidationFeedback.sass';

type Props = {
  password: string;
};
const PasswordValidationFeedback: FunctionComponent<Props> = ({
  password,
}: Props): JSX.Element => {
  const renderValidationFeedbackMessage = useCallback(
    (validationFeedbackMessage: string, isValidationPassed: boolean) => {
      return (
        <FeedbackMessage
          message={validationFeedbackMessage}
          feedbackLevel={isValidationPassed ? FeedbackLevel.SUCCESS : undefined}
          iconBefore={isValidationPassed ? faCheck : faTimes}
        />
      );
    },
    []
  );

  const minimumLengthValidationResult = useMemo(
    () =>
      minimumLengthFieldValidator('password', {
        password: password,
      }),
    [password]
  );

  const mustContainNumberValidationResult = useMemo(
    () =>
      mustContainNumberFieldValidator('password', {
        password: password,
      }),
    [password]
  );

  const mustContainLetterValidationResult = useMemo(
    () =>
      mustContainLetterFieldValidator('password', {
        password: password,
      }),
    [password]
  );

  return (
    <div className="password-validation-feedback">
      {renderValidationFeedbackMessage(
        minimumLengthValidationMessage,
        minimumLengthValidationResult.isValid
      )}
      {renderValidationFeedbackMessage(
        mustContainLetterValidationMessage,
        mustContainLetterValidationResult.isValid
      )}
      {renderValidationFeedbackMessage(
        mustContainNumberValidationMessage,
        mustContainNumberValidationResult.isValid
      )}
    </div>
  );
};

export default PasswordValidationFeedback;
